import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
  thick?: number;
}

export const ContentCopy: React.FC<Props> = ({ size = 20, color = '#70707B' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
        <g clipPath="url(#clip0_2695_53669)">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M4.29991 0.833497H10.0254C10.3909 0.833487 
                10.7063 0.833478 10.9674 0.851289C11.2422 0.870043 
                11.5171 0.911296 11.7887 1.0238C12.4013 1.27753 12.888 
                1.76422 13.1417 2.37679C13.2542 2.6484 13.2955 2.92332 
                13.3142 3.19818C13.3321 3.45923 13.332 3.77463 13.332 
                4.14017V4.16683C13.332 4.62707 12.9589 5.00016 12.4987 
                5.00016C12.0385 5.00016 11.6654 4.62707 11.6654 4.16683C11.6654 
                3.76715 11.6649 3.50912 11.6514 3.31163C11.6385 3.12163 11.6163 
                3.04927 11.6019 3.01459C11.5174 2.81041 11.3551 2.64818 11.1509 2.5636C11.1163 2.54923 11.0439 2.52705 10.8539 2.51409C10.6564 
                2.50062 10.3984 2.50017 9.9987 2.50017H4.33203C3.85157 2.50017 3.54134 2.50081 3.30523 2.5201C3.07884 2.5386 2.99491 2.57 2.95371 
                2.59099C2.79691 2.67089 2.66942 2.79837 2.58953 2.95517C2.56853 2.99638 
                2.53714 3.08031 2.51864 3.3067C2.49935 3.54281 2.4987 3.85304 2.4987 4.3335V10.0002C2.4987 
                10.3999 2.49915 10.6579 2.51262 10.8554C2.52559 11.0454 2.54777 11.1177 2.56213 
                11.1524C2.64671 11.3566 2.80894 11.5188 3.01313 11.6034C3.04781 11.6178 3.12017 
                11.6399 3.31017 11.6529C3.50765 11.6664 3.76568 11.6668 4.16537 11.6668C4.6256 
                11.6668 4.9987 12.0399 4.9987 12.5002C4.9987 12.9604 4.6256 13.3335 4.16537
                13.3335H4.13871C3.77317 13.3335 3.45776 13.3335 3.19671 13.3157C2.92186 13.297 
                2.64693 13.2557 2.37532 13.1432C1.76275 12.8895 1.27607 12.4028 1.02233 11.7902C0.909831 11.5186 
                0.868578 11.2437 0.849824 10.9688C0.832013 10.7078 0.832022 10.3924 0.832032 10.0268L0.832032 4.30138C0.832017 
                3.86209 0.832004 3.48312 0.857507 3.17097C0.884425 2.84152 0.943855 2.51384 1.10452 2.19852C1.3442 1.72812 1.72665 1.34566 2.19706 1.10598C2.51237 
                0.945319 2.84005 0.88589 3.16951 0.858973C3.48165 0.833469 3.86062 0.833482 4.29991 0.833497ZM10.1332 6.66683H15.6975C16.1368 6.66681 16.5157 6.6668 
                16.8279 6.69231C17.1573 6.71922 17.485 6.77865 17.8003 6.93932C18.2708 7.179 18.6532 7.56145 
                18.8929 8.03186C19.0535 8.34717 19.113 8.67485 19.1399 9.00431C19.1654 9.31645 19.1654 9.69541 19.1654 10.1347V15.699C19.1654 16.1383 19.1654 16.5172 
                19.1399 16.8294C19.113 17.1588 19.0535 17.4865 18.8929 17.8018C18.6532 18.2722 18.2707 18.6547 17.8003 18.8943C17.485 19.055 17.1573 19.1144 16.8279 
                19.1414C16.5157 19.1669 16.1368 19.1668 15.6975 19.1668H10.1332C9.69394 19.1668 9.31498 19.1669 9.00284 19.1414C8.67339 19.1144 8.34571 19.055 8.03039 
                18.8943C7.55999 18.6547 7.17753 18.2722 6.93785 17.8018C6.77719 17.4865 6.71776 17.1588 6.69084 16.8294C6.66534 16.5172 6.66535 16.1382 6.66537 15.6989V10.1347C6.66535 
                9.69542 6.66534 9.31645 6.69084 9.00431C6.71776 8.67485 6.77719 8.34717 6.93785 8.03186C7.17753 7.56145 7.55998 7.179 8.03039 6.93932C8.34571 6.77865 8.67339 6.71922 
                9.00284 6.69231C9.31499 6.6668 9.69396 6.66681 10.1332 6.66683ZM9.13856 8.35344C8.91218 8.37193 8.82824 8.40333 8.78704 8.42433C8.63024 8.50422 8.50276 8.63171 8.42286 
                8.78851C8.40187 8.82971 8.37047 8.91364 8.35197 9.14003C8.33268 9.37614 8.33203 9.68637 8.33203 10.1668V15.6668C8.33203 16.1473 8.33268 16.4575 8.35197 16.6936C8.37047 
                16.92 8.40187 17.004 8.42286 17.0452C8.50275 17.202 8.63024 17.3294 8.78704 17.4093C8.82824 17.4303 8.91218 17.4617 9.13856 17.4802C9.37468 17.4995 9.68491 17.5002 10.1654
                17.5002H15.6654C16.1458 17.5002 16.4561 17.4995 16.6922 17.4802C16.9186 17.4617 17.0025 17.4303 17.0437 17.4093C17.2005 17.3294 17.328 17.202 17.4079 17.0452C17.4289 17.004 
                17.4603 16.92 17.4788 16.6936C17.4981 16.4575 17.4987 16.1473 17.4987 15.6668V10.1668C17.4987 9.68637 17.4981 9.37614 17.4788 9.14003C17.4603 8.91364 17.4289 8.82971 17.4079 
                8.78851C17.328 8.6317 17.2005 8.50422 17.0437 8.42433C17.0025 8.40333 16.9186 8.37193 16.6922 8.35344C16.4561 8.33415 16.1458 8.3335 15.6654 8.3335H10.1654C9.68491 8.3335 9.37468 
                8.33415 9.13856 8.35344Z" 
            fill={color}
        />
        </g>
        <defs>
            <clipPath id="clip0_2695_53669">
            <rect width="20" height="20" fill='white' />
            </clipPath>
        </defs>
    </svg>
);