import { RevenuesSummary } from 'src/accounting/revenues/domain/RevenueSummary';
import { revenueSources, revenueSourceTypes } from 'src/accounting/revenues/domain/RevenueSource';

export const convertToRevenueSummaryWithAmex = (revenuesSummary: RevenuesSummary): RevenuesSummary => {
  const result: RevenuesSummary = { ...revenuesSummary };

  const pdqRevenues = result.revenues.filter(revenue => revenue.source === revenueSources.PAYMENT_TERMINAL);
  if (pdqRevenues.length === 0) {
    return revenuesSummary;
  }

  const pdqRevenue = pdqRevenues[0];
  const revenuesCardsPaymentTerminal = pdqRevenue.revenuesDetails.filter(
    revenuesDetail => revenuesDetail.source === revenueSourceTypes.CARDS,
  );
  if (revenuesCardsPaymentTerminal.length === 0) {
    return revenuesSummary;
  }

  const cardsRevenues = revenuesCardsPaymentTerminal[0];
  const amexRevenues = cardsRevenues.revenueUnits.filter(
    revenueUnit => revenueUnit.name === 'AMEX',
  );

  if (amexRevenues.length === 0) {
    return revenuesSummary;
  }

  const amexRevenueUnitIndex = cardsRevenues.revenueUnits.findIndex(revenueUnit => revenueUnit.name === 'AMEX');

  cardsRevenues.revenueUnits.splice(amexRevenueUnitIndex, 1);

  const amexRevenue = amexRevenues[0];
  cardsRevenues.revenueAmounts.salesWithSundayFees.amount -= amexRevenue.revenueAmounts.salesWithSundayFees.amount;
  cardsRevenues.revenueAmounts.tips.amount -= amexRevenue.revenueAmounts.tips.amount;
  cardsRevenues.revenueAmounts.sundayFeesVatIncluded.amount -= amexRevenue.revenueAmounts.sundayFeesVatIncluded.amount;
  cardsRevenues.revenueAmounts.totalSalesWithSundayFees.amount -= amexRevenue.revenueAmounts.totalSalesWithSundayFees.amount;
  cardsRevenues.revenueAmounts.totalSalesAndTips.amount -= amexRevenue.revenueAmounts.totalSalesAndTips.amount;
  cardsRevenues.revenueAmounts.totalTips.amount -= amexRevenue.revenueAmounts.totalTips.amount;

  cardsRevenues.revenueAmounts.refunds.sales.amount -= amexRevenue.revenueAmounts.refunds.sales.amount;
  cardsRevenues.revenueAmounts.refunds.tips.amount -= amexRevenue.revenueAmounts.refunds.tips.amount;
  cardsRevenues.revenueAmounts.refunds.gross.amount -= amexRevenue.revenueAmounts.refunds.gross.amount;
  cardsRevenues.revenueAmounts.refunds.charges.serviceCharge.amount -= amexRevenue.revenueAmounts.refunds.charges.serviceCharge.amount;
  cardsRevenues.revenueAmounts.refunds.charges.tax.amount -= amexRevenue.revenueAmounts.refunds.charges.tax.amount;
  cardsRevenues.revenueAmounts.refunds.charges.fee.amount -= amexRevenue.revenueAmounts.refunds.charges.fee.amount;
  cardsRevenues.revenueAmounts.refunds.charges.vat.amount -= amexRevenue.revenueAmounts.refunds.charges.vat.amount;

  cardsRevenues.revenueAmounts.orphanRefunds.sales.amount -= amexRevenue.revenueAmounts.orphanRefunds.sales.amount;
  cardsRevenues.revenueAmounts.orphanRefunds.tips.amount -= amexRevenue.revenueAmounts.orphanRefunds.tips.amount;
  cardsRevenues.revenueAmounts.orphanRefunds.gross.amount -= amexRevenue.revenueAmounts.orphanRefunds.gross.amount;
  cardsRevenues.revenueAmounts.orphanRefunds.charges.serviceCharge.amount -= amexRevenue.revenueAmounts.orphanRefunds.charges.serviceCharge.amount;
  cardsRevenues.revenueAmounts.orphanRefunds.charges.tax.amount -= amexRevenue.revenueAmounts.orphanRefunds.charges.tax.amount;
  cardsRevenues.revenueAmounts.orphanRefunds.charges.fee.amount -= amexRevenue.revenueAmounts.orphanRefunds.charges.fee.amount;
  cardsRevenues.revenueAmounts.orphanRefunds.charges.vat.amount -= amexRevenue.revenueAmounts.orphanRefunds.charges.vat.amount;

  cardsRevenues.revenueAmounts.totalAllCharges.serviceCharge.amount -= amexRevenue.revenueAmounts.totalAllCharges.serviceCharge.amount;
  cardsRevenues.revenueAmounts.totalAllCharges.tax.amount -= amexRevenue.revenueAmounts.totalAllCharges.tax.amount;
  cardsRevenues.revenueAmounts.totalAllCharges.fee.amount -= amexRevenue.revenueAmounts.totalAllCharges.fee.amount;
  cardsRevenues.revenueAmounts.totalAllCharges.vat.amount -= amexRevenue.revenueAmounts.totalAllCharges.vat.amount;

  pdqRevenue.revenuesDetails.push({
    source: revenueSourceTypes.AMEX,
    revenueAmounts: amexRevenue.revenueAmounts,
    revenueUnits: [],
  });

  return result;
};

