import { CountryCode } from '../domain/CountryCode';

export const getCountryCodeAsString = (countryCode: CountryCode): string | undefined => {
  const result = Object.entries(CountryCode).find((name, value) => value === countryCode);
  if (!result) {
    return undefined;
  }

  if (result.length !== 2) {
    return undefined;
  }

  return result[1] as string;
};
