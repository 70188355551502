import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { datadogLogs } from '@datadog/browser-logs';
import { useContext } from 'react';
import { queryKeys } from 'src/app/queries/utils';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { ReportSettings } from '../domain/ReportSettings';
import { PosPaymentMethod } from 'src/domain/venue/PosPaymentMethods';
import { VposVenueRepositoryContext } from 'src/app/contexts/vposvenue/VposVenueRepositoryContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetFirstActivePosConnectionForBusiness } from 'src/pos/queries/getPosConnections';

const invalidateReportSettings = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({
    queryKey: [
      queryKeys.BUSINESSES,
      queryKeys.RECONCILIATION_REPORT_SETTINGS,
      queryKeys.RECONCILIATION_POS_PAYMENT_METHODS,
    ],
  });
};

const openReportSettings = (businessId: BusinessId) => ({
  queryKey: [queryKeys.BUSINESSES, businessId, queryKeys.RECONCILIATION_REPORT_SETTINGS, queryKeys.RECONCILIATION_POS_PAYMENT_METHODS],
  queryFn: async (): Promise<ReportSettings> => {
    const reconciliationRepository = new ReconciliationRepositoryHttp();
    return reconciliationRepository.getReportSettings(businessId).then((response: ReportSettings | undefined) => {
      if (response === undefined) {
        return reconciliationRepository.createDefaultReportSettings(businessId).then((result) => result);
      }
      return response;
    });
  },
});

export const useReportSettings = () => {
  const business = useCurrentBusinessOrThrow();
  const query = openReportSettings(business.id);
  return useQuery({ queryKey: query.queryKey, queryFn: query.queryFn, enabled: !!business });
};

const updateReportSettings = (businessId: BusinessId, reportSettings: ReportSettings) =>
  new ReconciliationRepositoryHttp().updateReportSettings(businessId, reportSettings);

export const useUpdateReportSettings = () => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();

  return useMutation({
    mutationFn: ({ reportSettings }: { reportSettings: ReportSettings }) =>
      updateReportSettings(business.id, reportSettings).then(() => invalidateReportSettings(queryClient)),
    onSuccess: () => invalidateReportSettings(queryClient),
  });
};

export const usePosPaymentMethods = () => {
  const business = useCurrentBusinessOrThrow();
  const { data: posConnection } = useGetFirstActivePosConnectionForBusiness(business.id);

  const { getPosPaymentMethods } = useContext(VposVenueRepositoryContext);

  const { isLoading, data, error } = useQuery({
    queryKey: [queryKeys.BUSINESSES, business.id, queryKeys.RECONCILIATION_POS_PAYMENT_METHODS, posConnection],
    queryFn: async (): Promise<PosPaymentMethod[] | void> =>
      getPosPaymentMethods(posConnection!.id)
        .then((response: PosPaymentMethod[]) => response)
        .catch((e) =>
          datadogLogs.logger.warn(`impossible to retrieve pos payment methods to map in reconciliation ${e}`),
        ),
    enabled: !!business && !!posConnection,
  });

  return {
    isLoading,
    posPaymentMethods: data,
    error,
  };
};
