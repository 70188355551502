import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { LoyaltyRepository } from '../infrastructure/LoyaltyRepository';

const configuration = ConfigurationLoader.load();
const loyaltyRepository = new LoyaltyRepository(configuration.loyaltyApiBaseUrl);

export const useGetBusinessLoyaltyReference = (businessId: string) => {
  return useQuery({
    queryKey: [queryKeys.loyalty.GET_BUSINESS_LOYALTY_REFERENCE, { businessId }],
    queryFn: () => loyaltyRepository.getLoyaltyBusiness(businessId),
  });
};
