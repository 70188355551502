import { TimeRange } from '../browse/domain/TimeRange';
import { Instant, instant, UnitOfTime } from '../../Instant';

export class DateRange {
  private constructor(private start: Instant, private end: Instant) {
  }

  static fromDates(start: Date, end: Date) {
    return new DateRange(instant(start), instant(end));
  }

  static fromInstants(start: Instant, end: Instant) {
    return new DateRange(start, end);
  }

  public allDays(): Instant[] {
    const allDays = [];
    for (let i = 0; i < this.numberOfDays(); i += 1) {
      allDays.push(this.start.add(i, 'd'));
    }
    return allDays;
  }

  public startDateI() {
    return this.start;
  }

  public endDateI() {
    return this.end;
  }

  public startDate() {
    return this.start.toDate();
  }

  public endDate() {
    return this.end.toDate();
  }

  static lastSevenDays() {
    return new DateRange(
      instant()
        .subtract(6, 'd')
        .startOf('day'),
      instant()
        .endOfDay(),
    );
  }

  static previousSevenDays() {
    return new DateRange(
      instant()
        .subtract(7, 'd')
        .startOf('day'),
      instant()
        .startOf('day'),
    );
  }

  static lastTwoWeeks() {
    return new DateRange(
      instant()
        .subtract(13, 'd')
        .startOf('day'),
      instant()
        .endOfDay(),
    );
  }

  static lastThirtyDays() {
    return new DateRange(
      instant()
        .subtract(29, 'd')
        .startOf('day'),
      instant()
        .endOfDay(),
    );
  }

  static lastYear() {
    return new DateRange(
      instant()
        .subtract(1, 'y')
        .startOf('day'),
      instant()
        .endOfDay(),
    );
  }

  static last11Months(now: Instant = instant()) {
    const start = now
      .subtract(11, 'M')
      .startOf('day')
      .startOf('M');
    return new DateRange(start, now);
  }

  static today() {
    return new DateRange(this.startOfToday(), this.endOfToday());
  }

  static yesterday() {
    return new DateRange(this.startOfYesterday(), this.endOfYesterday());
  }

  static startOfToday() {
    return instant()
      .startOf('day');
  }

  static endOfToday() {
    return instant()
      .endOfDay();
  }

  static startOfYesterday() {
    return instant()
      .subtract(1, 'day')
      .startOf('day');
  }

  static endOfYesterday() {
    return instant()
      .subtract(1, 'day')
      .endOfDay();
  }

  label(locale: string) {
    if (locale === ('en-US')) {
      return `${this.start.format('MM/DD')} - ${this.end.format('MM/DD')}
      ${this.start.locale(locale)
    .format('LT')}-${this.end.locale(locale)
  .format('LT')}`;
    }

    return `${this.start.format('DD/MM')} - ${this.end.format('DD/MM')}
      ${this.start.locale(locale)
    .format('HH:mm')}-${this.end.locale(locale)
  .format('HH:mm')}`;
  }

  shortLabel(locale: string) {
    if (locale === ('en-US')) {
      return `${this.start.format('MM/DD')} - ${this.end.format('MM/DD')}`;
    }

    return `${this.start.format('DD/MM')} - ${this.end.format('DD/MM')}`;
  }

  toTimeRange(): TimeRange {
    return {
      startDate: this.start.toDate(),
      endDate: this.end.toDate(),
    };
  }

  contains(creationDate: Instant): boolean {
    return creationDate.isBefore(this.end) && this.start.isBefore(creationDate);
  }

  numberOfDays(): number {
    return this.end.deltaInDays(this.start);
  }

  isYesterday(): boolean {
    const yesterday = DateRange.yesterday();
    return this.start.equals(yesterday.start) && this.end.equals(yesterday.end);
  }

  isLastSevenDays(): boolean {
    const last7days = DateRange.lastSevenDays();
    return this.start.equals(last7days.start) && this.end.equals(last7days.end);
  }

  isLastThirtyDays(): boolean {
    const last30days = DateRange.lastThirtyDays();
    return this.start.equals(last30days.start) && this.end.equals(last30days.end);
  }

  isCustom(): boolean {
    return !this.isYesterday() && !this.isLastSevenDays() && !this.isLastThirtyDays();
  }

  add(n: number, unitOfTime: UnitOfTime): DateRange {
    return new DateRange(this.start.add(n, unitOfTime), this.end.add(n, unitOfTime));
  }
}
