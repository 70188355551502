import { UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { Button, Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';

type Props = {
  onSuccess?: UseGoogleLoginOptionsAuthCodeFlow['onSuccess'],
  onError?: UseGoogleLoginOptionsAuthCodeFlow['onError'],
  onClose?: () => void,
};

export function FakeGoogleLoginPopup(props: Props) {
  return (
    <Dialog open={true} fullWidth onClose={props.onClose}>
      <DialogTitle>
        Select a fake Google account to log in:
      </DialogTitle>
      <DialogContent>
        <Stack p={3} gap={1}>
          <Button variant="outlined" onClick={() => props.onSuccess?.({
            code: 'fakeCode',
            state: 'fakeState',
            scope: 'fakeScope',
          })}>
            Fake valid login
          </Button>
          <Button variant="outlined" onClick={() => props.onError?.({
            error: 'access_denied',
            error_description: 'fake access denied',
            error_uri: 'http://localhost:3000/fake-google-oauth-error',
          })}>
            Fake access denied
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
