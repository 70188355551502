import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const Delete: React.FC<Props> = ({ size = 20, color = '#A50F1E' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M9.30252 0.833497H10.7001C11.1394 0.833482 11.5183 0.833469 
            11.8305 0.858973C12.1599 0.88589 12.4876 0.945319 12.8029 1.10598C13.2733 
            1.34566 13.6558 1.72812 13.8955 2.19852C14.0561 2.51384 14.1156 2.84152 
            14.1425 3.17097C14.1654 3.45079 14.1677 3.7843 14.1679 4.16683H17.5013C17.9615 4.16683 18.3346 4.53993 18.3346 
            5.00016C18.3346 5.4604 17.9615 5.8335 17.5013 5.8335H16.668V14.3679C16.668 15.0387 16.668 15.5924 16.6311 16.0434C16.5929 16.5118 
            16.5107 16.9424 16.3047 17.3468C15.9851 17.974 15.4751 
            18.4839 14.8479 18.8035C14.4435 19.0096 14.0129 
            19.0917 13.5445 19.13C13.0935 19.1668 12.5399 19.1668 11.8691 19.1668H8.13353C7.46273 19.1668 6.90911 19.1668 6.45811 19.13C5.9897 19.0917 5.55911 19.0096 5.15467 
            18.8035C4.52746 18.4839 4.01753 17.974 3.69795 17.3468C3.49187 16.9424 3.40973 16.5118 3.37146 16.0434C3.33462 15.5924 3.33462 
            15.0387 3.33464 14.3679L3.33464 5.8335H2.5013C2.04106 5.8335 1.66797 5.4604 1.66797 5.00016C1.66797 4.53993 2.04106 4.16683 2.5013 
            4.16683H5.83466C5.83489 3.7843 5.83725 3.45079 5.86011 3.17097C5.88703 2.84152 5.94646 2.51384 6.10712 2.19852C6.3468 1.72812 6.72925 1.34566 
            7.19966 1.10598C7.51498 0.945319 7.84266 0.88589 8.17211 0.858973C8.48426 0.833469 8.86322 0.833482 9.30252 0.833497ZM5.0013 5.8335V14.3335C5.0013 
            15.0473 5.00195 15.5326 5.03259 15.9076C5.06244 16.273 5.11655 16.4598 5.18296 16.5901C5.34275 16.9038 5.59771 17.1587 5.91132 17.3185C6.04165 17.3849 6.22849 
            17.439 6.59383 17.4689C6.96891 17.4995 7.45415 17.5002 8.16797 17.5002H11.8346C12.5485 17.5002 13.0337 17.4995 13.4088 17.4689C13.7741 17.439 13.961 17.3849 14.0913 17.3185C14.4049 
            17.1587 14.6599 16.9038 14.8196 16.5901C14.8861 16.4598 14.9402 16.273 14.97 15.9076C15.0007 15.5326 15.0013 15.0473 15.0013 14.3335V5.8335H5.0013ZM12.5012 4.16683H7.50136C7.50173 3.77924 
            7.50428 3.51432 7.52124 3.3067C7.53974 3.08031 7.57114 2.99638 7.59213 2.95517C7.67202 2.79837 7.79951 2.67089 7.95631 2.59099C7.99751 2.57 8.08145 2.5386 8.30783 2.5201C8.54395 2.50081 
            8.85417 2.50017 9.33464 2.50017H10.668C11.1484 2.50017 
            11.4587 2.50081 11.6948 2.5201C11.9212 2.5386 12.0051 2.57 12.0463 2.59099C12.2031 2.67089 12.3306 2.79837 12.4105 2.95517C12.4315 2.99638 12.4629 3.08031 12.4814 3.3067C12.4983 3.51432 
            12.5009 3.77924 12.5012 4.16683Z" 
            fill={color}
        />
    </svg>
);


