import { useTheme } from '@mui/material';
import {
  LineGraphDefinition,
  lineChartData,
  lineChartOptions,
} from 'src/home2/features/home-page/components/Sections/Reviews/ReviewsCharts/Charts/Utils/AverageRatingChartUtils';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { EReputationPageChoicesState } from 'src/store/e-reputation';
import { NoEreputationData } from 'src/sundayplus/components/NoEreputationData';
import { AVERAGE_RATING, RatingsAverageGraphData, StarsDistributionChartData } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { useGraphDataForKpiAndTimeframe } from 'src/sundayplus/reviews/insights/infrastructure/useEReputationKpis';
import { useGetAverageRatingsLabels } from '../../hooks/useGetAverageRatingsContent';
import { KpiTimeframeName } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

const keys = ['withSunday', 'withoutSunday'] as const;

type DataKey = (typeof keys)[number];

export const AverageRatingsChart = ({ selectedTimeFrame }: { selectedTimeFrame: KpiTimeframeName }) => {
  const intl = useIntl();
  const theme = useTheme();
  const business = useCurrentBusinessOrThrow();
  const { data } = useGraphDataForKpiAndTimeframe(business.id, business.timezone, AVERAGE_RATING, 'FOUR_WEEKS');
  const dataValues: RatingsAverageGraphData | any = data;
  const dataTypes = useGetAverageRatingsLabels();

  const withSundayCaption = intl.formatMessage({ id: 'reviews.ereputation.graph.average.caption.with_sunday' });

  // Fallback to today if the subscription date is undefined
  // We're not sure if it can happen, it depends on historical data on the backend
  // We may want to rework the types (once we've checked the data on the backend),
  // /and force always having a date if the Google setup is finished
  // const subscriptionDateOrFallbackToToday = () => subscriptionDate ?? new Date().getTime();

  if (!dataValues || !dataTypes) {
    return <NoEreputationData theme={theme} />;
  }

  const pageStatus: EReputationPageChoicesState = {
    timeFrame: selectedTimeFrame,
    panel: 'AVG_RATING',
  };


  const dataChart: LineGraphDefinition<DataKey> = {
    keys: keys,

    dataTypes: dataTypes,

    data: {
      withSunday: dataValues.fromSunday
        .filter((kpi: StarsDistributionChartData) => kpi.value !== null)
        .map((kpi: StarsDistributionChartData) => kpi.value),
      withoutSunday: dataValues.fromGoogle,
    },
  };

  return (
    <Chart
      type="line"
      options={lineChartOptions(dataValues.fromSunday, withSundayCaption)}
      data={lineChartData(pageStatus, dataChart, intl)}
      plugins={[ChartDataLabels]}
    />
  );
};
