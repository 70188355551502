import { SharedDeviceRepository } from '../domain/SharedDeviceRepository';
import { SharedDevice } from '../domain/SharedDevice';
import axios from 'axios';
import { UserInvitation } from 'src/app-for-restaurants/domain/UserInvitation';

export class HttpSharedDeviceRepository implements SharedDeviceRepository {
  constructor(private waiterAppBackEndUrl: string) {}

  async listSharedDevices(businessId: string): Promise<SharedDevice[]> {
    const response = await axios.get(`${this.waiterAppBackEndUrl}/businesses/${businessId}/sharedDevices`);
    return response.data.map((it: SharedDevice) => SharedDevice.parse(it));
  }

  async deleteSharedDevice(deviceToken: string): Promise<void> {
    await axios.delete(`${this.waiterAppBackEndUrl}/v2/native-notification/devices/${deviceToken}`);
  }

  async updateSharedDeviceName(deviceToken: string, deviceName: string): Promise<void> {
    await axios.put(`${this.waiterAppBackEndUrl}/v2/native-notification/devices/${deviceToken}/deviceName`, {
      deviceName,
    });
  }

  async listInvitations(businessId: string): Promise<UserInvitation[]> {
    const response = await axios.get(`${this.waiterAppBackEndUrl}/businesses-registration/${businessId}/invitations`);
    return response.data;
  }

  async inviteUserWithRole(invitations:UserInvitation[], businessId:string):Promise<void>  {
    await axios.put(`${this.waiterAppBackEndUrl}/businesses-registration/${businessId}/invite`,  invitations);
  }
}
