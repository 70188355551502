/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const CheckIcon: React.FC<IconProps> = ({ size = 14, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.28335 13.0467C5.41918 12.9109 5.41918 12.6907 5.28335 12.5548L1.42666 8.69814C1.29082 8.56231 1.07059 8.56231 0.934755 8.69814C0.79892 8.83397 0.79892 9.05421 0.934755 9.19004L4.79145 13.0467C4.92728 13.1826 5.14751 13.1826 5.28335 13.0467Z"
      fill={color}
    />
    <path
      d="M5.10657 12.7316L1.24988 8.87492L1.60343 8.52136L5.46012 12.3781L5.10657 12.7316ZM1.11153 9.01326L4.96822 12.87L4.61467 13.2235L0.757978 9.36682L1.11153 9.01326ZM4.96822 12.87C5.00643 12.9082 5.06837 12.9082 5.10657 12.87L5.46012 13.2235C5.22666 13.457 4.84813 13.457 4.61467 13.2235L4.96822 12.87ZM1.11153 8.87492C1.07333 8.91312 1.07333 8.97506 1.11153 9.01326L0.757978 9.36682C0.524512 9.13335 0.524512 8.75483 0.757978 8.52136L1.11153 8.87492ZM1.24988 8.87492C1.21168 8.83671 1.14974 8.83671 1.11153 8.87492L0.757978 8.52136C0.991444 8.2879 1.36997 8.2879 1.60343 8.52136L1.24988 8.87492ZM5.46012 12.3781C5.69359 12.6115 5.69359 12.99 5.46012 13.2235L5.10657 12.87C5.14477 12.8318 5.14477 12.7698 5.10657 12.7316L5.46012 12.3781Z"
      fill={color}
    />
    <rect
      width="11.1304"
      height="0.695653"
      rx="0.347826"
      transform="matrix(-0.707107 0.707107 0.707107 0.707107 12.4902 4.82031)"
      fill={color}
      stroke={color}
      strokeWidth="0.5"
    />
  </svg>
);
