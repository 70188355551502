import { useMediaQuery, useTheme } from '@mui/material';

export const useMediaBreakpoints = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('mobile'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.between('mobile', 'tablet'));
  const isDesktopScreen = useMediaQuery(theme.breakpoints.up('tablet'));

  return {
    isMobileScreen,
    isTabletScreen,
    isDesktopScreen,
  };
};
