import {
  Box,
  CSSObject,
  Drawer as MuiDrawer,
  Theme,
  styled,
} from '@mui/material';

export const DRAWER_WIDTH = 264;
const COMPACTED_DRAWER_WIDTH = 64;

const openedMixin = (): CSSObject => ({
  width: DRAWER_WIDTH,
  overflowX: 'hidden',
});
  
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: COMPACTED_DRAWER_WIDTH,

  [theme.breakpoints.up('md')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',

  ...(open && {
    ...openedMixin(),
    '& .MuiDrawer-paper': openedMixin(),
  }),

  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export const DrawerItemsBox = styled(Box)(() => ({
  display: 'flex', 
  flexDirection: 'column', 
  height: '100%',
  justifyContent: 'space-between',
  padding: '8px',
}));

export const DrawerHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px',
  marginBottom: theme.spacing(1),
  flexDirection: 'row',
}));

export const NavItemsBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'itemsLength',
})<{ itemsLength?: number }>(({ theme }) => ({
  padding: theme.spacing(1), 
}));

export const NavBottomBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== '',
})<{ isCompacted?: boolean }>(({ theme }) => ({
  background: 'default.paper',
  padding: theme.spacing(1),
  boxShadow: 'none',
}));
  
  