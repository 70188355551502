import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';

const buildQueryKey = (userId: string, lastNth: number) =>
  [{ scope: 'waiter_tipping_summary' }, userId, lastNth] as const;

export const useStaffTippingSummary = (userId: string, lastNth: number) =>
  useQuery({
    queryKey: buildQueryKey(userId, lastNth),
    queryFn: () => {
      const configuration = ConfigurationLoader.load();
      const waiterPort = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
      return waiterPort.getStaffTippingSummary(userId, lastNth);
    },
  });
