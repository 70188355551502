import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Translation } from 'src/accounting/common/translation';

export const TableLoadingState: FC<{ title: Translation, subtitle?: Translation }> = ({ title, subtitle }) => {
  return (
    <Box display="flex"
         flexDirection="column"
         alignItems="center"
         justifyContent="center"
         gap={2}
         p={5}
         sx={{
           width: '100%',
           background: 'white',
           borderBottomLeftRadius: '16px',
           borderBottomRightRadius: '16px',
         }}>
      <Box pb={2}>
        <CircularProgress color="secondary" />
      </Box>
      <Typography variant="h6">
        <FormattedMessage id={title.id} values={title.values} />
      </Typography>
      {!!subtitle &&
        <Typography variant="body1" color={themeV5.palette.text.secondary}>
          <FormattedMessage id={subtitle.id} values={subtitle.values} />
        </Typography>
      }
    </Box>
  );
};
