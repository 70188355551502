/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const TipsSelectedSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.75 1.66659C11.6789 1.66659 10 3.34552 10 5.41659C10 7.48765 11.6789 9.16659 13.75 9.16659C15.8211 9.16659 17.5 7.48765 17.5 5.41659C17.5 3.34552 15.8211 1.66659 13.75 1.66659Z" fill="#FF17E9"/>
    <path d="M10.0265 1.48263C8.98379 2.46991 8.33334 3.86729 8.33334 5.41659C8.33334 6.41416 8.60301 7.34876 9.07343 8.15144C8.70909 8.26947 8.32033 8.33325 7.91667 8.33325C5.8456 8.33325 4.16667 6.65432 4.16667 4.58325C4.16667 2.51218 5.8456 0.833252 7.91667 0.833252C8.69903 0.833252 9.42543 1.07283 10.0265 1.48263Z" fill="#FF17E9"/>
    <path d="M14.7574 13.2443C14.7574 13.9038 14.3213 14.4615 13.72 14.6479L14.1066 14.63C14.5291 14.63 14.9399 14.4971 15.2775 14.2503L17.6349 12.5304C18.2233 12.1014 19.0468 12.1583 19.5669 12.6642C20.1445 13.2261 20.1445 14.1364 19.5669 14.6973L17.5442 16.665C16.9997 17.1946 16.305 17.5562 15.5497 17.7034L12.6908 18.2596C12.1239 18.3697 11.5394 18.3564 10.9784 18.2197L8.39266 17.5914C8.08335 17.5154 7.76624 17.4775 7.44717 17.4775C7.10842 17.4775 6.93905 17.4775 6.80316 17.4289C6.56778 17.3449 6.38255 17.1597 6.29852 16.9243C6.25001 16.7884 6.25001 16.619 6.25001 16.2803V13.4227C6.25001 13.1845 6.25001 13.0654 6.28059 12.9556C6.30768 12.8584 6.35221 12.7669 6.41201 12.6856C6.47951 12.5938 6.57323 12.5203 6.76068 12.3734L7.74867 11.5988C7.74867 11.5988 9.38597 10.8332 10.2905 10.8332C10.6652 10.8332 11.0379 10.8788 11.4019 10.9691L13.6383 11.525C14.2959 11.688 14.7574 12.276 14.7574 12.9504V13.2443Z" fill="#FF17E9"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.89462 10.8332C2.90196 10.8332 2.90931 10.8332 2.91667 10.8332C2.92403 10.8332 2.93139 10.8332 2.93873 10.8332C3.11377 10.8332 3.28429 10.8332 3.42936 10.8431C3.58801 10.8539 3.77611 10.8793 3.97115 10.9601C4.37953 11.1293 4.70398 11.4537 4.87314 11.8621C4.95392 12.0571 4.97932 12.2452 4.99014 12.4039C5.00004 12.549 5.00002 12.7195 5.00001 12.8945C5.00001 12.9019 5.00001 12.9092 5.00001 12.9166L5.00001 17.1053C5.00002 17.2803 5.00004 17.4509 4.99014 17.5959C4.97932 17.7546 4.95392 17.9427 4.87314 18.1377C4.70398 18.5461 4.37952 18.8706 3.97114 19.0397C3.77611 19.1205 3.588 19.1459 3.42936 19.1567C3.28429 19.1666 3.11377 19.1666 2.93874 19.1666H2.89461C2.71957 19.1666 2.54905 19.1666 2.40398 19.1567C2.24534 19.1459 2.05723 19.1205 1.8622 19.0397C1.45382 18.8706 1.12936 18.5461 0.960206 18.1377C0.87942 17.9427 0.854025 17.7546 0.843201 17.5959C0.833303 17.4509 0.83332 17.2803 0.833337 17.1053L0.833339 12.9166C0.833339 12.9092 0.833338 12.9019 0.833337 12.8945C0.83332 12.7195 0.833303 12.549 0.843201 12.4039C0.854025 12.2452 0.87942 12.0571 0.960206 11.8621C1.12936 11.4537 1.45382 11.1293 1.8622 10.9601C2.05724 10.8793 2.24534 10.8539 2.40398 10.8431C2.54905 10.8332 2.71958 10.8332 2.89462 10.8332Z" 
          fill={color}/>
  </svg>

);

export default TipsSelectedSvg;
