import { styled } from '@mui/material';
import { differenceMoney } from '@sundayapp/web-money';
import { ExpandableTableRow, TableCell, TableRow } from '@sundayapp/b2b-react-component-library';
import { CashPaymentDetails } from './CashPaymentsDetails';
import { Reconciliation } from '../../domain/Reconciliation';
import { PaymentOrigin } from '../../domain/PaymentOrigin';

import { CashPayment } from '../../domain/CashPayment';
import DeltaDetails from '../DeltaDetails';
import { cashPayments, paymentDataAmount } from '../../domain/PaymentData';

import { useAddOrReplaceCashPaymentMutation } from '../../mutations/useAddCashPaymentMutation';
import { PaymentDataCell } from '../amountcell/PaymentDataCell';
import { useRemoveCashPaymentMutation } from '../../mutations/useRemoveCashPaymentMutation';
import { useCurrentReportOrThrow } from '../../hooks/useCurrentReportOrThrow';
import { PosAmountCell } from '../amountcell/PosAmountCell';
import { deltaIsAcceptable } from '../DeltaAcceptable';
import { ReportSettings } from '../../domain/ReportSettings';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const GreyRow = styled(TableRow)`
  background-color: #f6f6f9;

  td {
    background-color: #f6f6f9;
  }
`;

type Props = {
  tender: Reconciliation
  reportSettings: ReportSettings
};

export const CashPaymentRow = ({ tender, reportSettings }: Props) => {
  const { currency } = useCurrentBusinessOrThrow();
  const report = useCurrentReportOrThrow();

  const totalPos = paymentDataAmount(currency, tender.onPos);
  const totalReported = paymentDataAmount(currency, tender.reported);
  const delta = differenceMoney(totalReported, totalPos);

  const background = delta.amount === 0 ? 'white' : '#fff4d1';

  const addCashMutation = useAddOrReplaceCashPaymentMutation(tender.method);
  const removeCashMutation = useRemoveCashPaymentMutation(tender.method);

  const addOrUpdateCash = (origin: PaymentOrigin) => (cashPayment: CashPayment) => {
    addCashMutation.mutate({ origin, cashPayment });
  };

  const removeCash = (origin: PaymentOrigin) => (cashPayment: CashPayment) => {
    removeCashMutation.mutate({ origin, cashPayment });
  };

  const collapsedComponent = (
    <>
      <TableCell>{tender.method.name}</TableCell>
      <TableCell>
        {tender.onPos?.amount && tender.onPos.amount.amount !== 0 ? (
          <PosAmountCell method={tender.method} amount={tender.onPos.amount} />
        ) : (
          !report.totalOnPos.editable ? (<PaymentDataCell tender={tender} origin={PaymentOrigin.OnPos} />)
            : (<div>-</div>)
        )}
      </TableCell>
      <TableCell>
        <PaymentDataCell tender={tender} origin={PaymentOrigin.Reported} />
      </TableCell>
      <TableCell style={{ backgroundColor: background }}>
        <DeltaDetails delta={delta} deltaIsAcceptable={deltaIsAcceptable(delta, reportSettings)} />
      </TableCell>
    </>
  );

  const payments = cashPayments(tender.reported);

  const expandedComponent = (
    <GreyRow>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell>
        {payments.map((p) => (
          <CashPaymentDetails
            cashPayment={p}
            key={p.reporter.id}
            onSave={addOrUpdateCash(PaymentOrigin.Reported)}
            onDelete={removeCash(PaymentOrigin.Reported)}
          />
        ))}
      </TableCell>
      <TableCell />
    </GreyRow>
  );

  if (payments.length)
    return <ExpandableTableRow expandableComponent={expandedComponent}>{collapsedComponent}</ExpandableTableRow>;

  return (
    <TableRow>
      <TableCell />
      {collapsedComponent}
    </TableRow>
  );
};
