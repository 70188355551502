import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore } from 'firebase/firestore';
import { connectStorageEmulator, FirebaseStorage, getStorage } from 'firebase/storage';
import React, { ReactNode, useContext } from 'react';
import {
  AuthProvider, FirestoreProvider, preloadUser, StorageProvider, useFirebaseApp,
} from 'reactfire';
import { ConfigurationContext } from '../contexts/configuration/ConfigurationProvider';

interface Props {
  children: ReactNode;
}

function configureEmulator(auth: Auth, storage: FirebaseStorage, firestore: Firestore) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(firestore, window.location.hostname, 8090);
  connectStorageEmulator(storage, 'localhost', 9199);
}

const DataPreloader = ({
  auth,
  children,
}: { auth: Auth; children: ReactNode }) => {
  preloadUser(async () => auth);
  return <>{children}</>;
};

const FirebasePreloader = ({ children }: Props) => {
  const config = useContext(ConfigurationContext);
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);
  const firestore = getFirestore(firebaseApp);

  if (config.isDevelopment) {
    configureEmulator(auth, storage, firestore);
  }

  return (
    <AuthProvider sdk={auth}>
      <StorageProvider sdk={storage}>
        <FirestoreProvider sdk={firestore}>
          <DataPreloader auth={auth}>{children}</DataPreloader>
        </FirestoreProvider>
      </StorageProvider>
    </AuthProvider>
  );
};

export default FirebasePreloader;
