import { Box, Slide, styled } from '@mui/material';
import { Drawer, DRAWER_WIDTH } from '@styles/Navigation/StyledSideBar';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import FixedMenuDrawerSvg from 'src/app/component/icons/FixedMenuDrawerSvg';
import FloatingMenuDrawerSvg from 'src/app/component/icons/FloatingMenuDrawerSvg';
import {
  findBottomItems,
  findItem,
  findParentMenuItem,
  navigationDrawerItemKey,
  NavigationItem,
} from 'src/app/navigation/navigationMenu/NavigationItems';
import { NavigationGuideExternalLink } from 'src/app/navigation/navigationMenu/component/NavigationGuideExternalLink';
import { NavigationMenuFooter } from 'src/app/navigation/navigationMenu/component/NavigationMenuFooter';
import { NavigationMenuItem } from 'src/app/navigation/navigationMenu/component/NavigationMenuItem';
import { NavigationMenuItems } from 'src/app/navigation/navigationMenu/component/NavigationMenuItems';
import { SubLevelDrawer } from 'src/app/navigation/navigationMenu/subLevel/SubLevelDrawer';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import { isTouchDevice } from 'src/app/theme/ThemeV5';
import { BusinessSelection } from 'src/business/components/BusinessSelection';
import { useMediaBreakpoints } from 'src/home2/shared/hooks/useMediaBreakpoints';

type SideBarMenuMenuProps = {
  menuItems: NavigationItem[];
};

const MainDrawerContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

const NavigationMenuContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '8px',
}));

const DrawerHeader = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  marginBottom: '16px',
  justifyContent: 'space-between',
}));

const DrawerIconContainer = styled(Box)(() => ({
  alignItems: 'center',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'row',
  padding: '6px',
  justifyContent: 'space-between',

  '&:hover': {
    backgroundColor: PaletteTheme.menu.hover,
  },
}));

export const SideBarMenu = ({ menuItems }: SideBarMenuMenuProps) => {
  const { isMobileScreen, isTabletScreen } = useMediaBreakpoints();
  const currentRoute = useLocation().pathname;
  const hasTabletScreenSize = isMobileScreen || isTabletScreen;
  const [ allowFloatingMode, setAllowFloatingMode ] = useState(true);
  const [ isFloatingMode, setFloatingMode ] = useState(false);
  const [ isCompacted, updateCompacted ] = useState(false);
  const [ selectedGroupKey, updateGroupKeySelected ] = useState<string>();
  const [ savedGroupKey, saveGroupKeySelected ] = useState<string>();
  const [ openedGroupKey, updateGroupKeyOpened ] = useState<string>();
  const [ subLevelDrawerOpened, setSubLevelDrawerOpened ] = useState<NavigationItem | undefined>(undefined);
  const currentItem = useMemo(() => findItem(menuItems, currentRoute), [ menuItems, currentRoute ]);
  const bottomItems = useMemo(() => findBottomItems(menuItems), [ menuItems ]);
  const mainDrawerItems = useMemo(() => menuItems.filter(value => !bottomItems.includes(value)), [ menuItems ]);

  useMemo(() => {
    if (!currentItem) {
      return;
    }

    const groupItem = findParentMenuItem(menuItems, currentItem);
    if (!groupItem) {
      updateGroupKeySelected(undefined);
      if (!isFloatingMode) {
        updateGroupKeyOpened(undefined);
      }
      return;
    }

    const itemKey = navigationDrawerItemKey(groupItem);
    updateGroupKeySelected(itemKey);
    if (!isFloatingMode) {
      updateGroupKeyOpened(itemKey);
    }
  }, [ menuItems, currentItem ]);

  const switchToFloatingMode = () => {
    updateCompacted(true);

    // Wait for the animation in order to not re-open the menu with the current mouse hover
    setTimeout(() => {
      setFloatingMode(true);
    }, 100);
  };

  const switchToFixedMode = () => {
    setFloatingMode(false);
    updateCompacted(false);
  };

  const onMenuGroupExpanded = (selection: NavigationItem | undefined) => {
    if (!selection) {
      updateGroupKeyOpened(undefined);
      return;
    }

    const menuItemKey = navigationDrawerItemKey(selection);
    updateGroupKeyOpened(menuItemKey);
    saveGroupKeySelected(menuItemKey);
  };

  const onItemSelected = (item: NavigationItem) => {
    if (item.subLevelItems && item.subLevelItems.length > 0) {
      setAllowFloatingMode(false);
      setSubLevelDrawerOpened(item);
      //setFloatingMode(false);
      updateCompacted(false);
      return;
    } else {
      setAllowFloatingMode(true);
    }

    setTimeout(() => {
      if (isFloatingMode && !isCompacted) {
        updateCompacted(true);
        updateGroupKeyOpened(undefined);
      }
    }, 0);
  };

  const closeSubLevelDrawer = () => {
    setSubLevelDrawerOpened(undefined);
    setAllowFloatingMode(true);
    return;
  };

  const menuIconMode = useMemo(() => {
    if (!allowFloatingMode) {
      return undefined;
    }

    if (hasTabletScreenSize) {
      return undefined;
    }

    if (isCompacted) {
      return undefined;
    }

    if (isFloatingMode) {
      return <FixedMenuDrawerSvg onClick={switchToFixedMode} cursor="pointer" color="#B4B4BB" />;
    }
    return <FloatingMenuDrawerSvg onClick={switchToFloatingMode} cursor="pointer" color="#B4B4BB" />;
  }, [ isCompacted, isFloatingMode, hasTabletScreenSize, allowFloatingMode ]);

  const onMouseOverMenu = () => {
    if (isTouchDevice()) {
      return;
    }
    if (isCompacted && isFloatingMode) {
      updateCompacted(false);
      updateGroupKeyOpened(savedGroupKey);
    }
  };

  const onMouseLeaveMenu = () => {
    if (!isCompacted && isFloatingMode) {
      updateCompacted(true);
      updateGroupKeyOpened(undefined);
      saveGroupKeySelected(openedGroupKey);
    }
  };

  useEffect(() => {
    const onMouseClicked = (event: MouseEvent) => {
      if (!isFloatingMode || isCompacted) {
        return;
      }

      if (event.x > DRAWER_WIDTH) {
        updateCompacted(true);
      }
    };

    window.document.addEventListener('click', onMouseClicked, false);
    return () => {
      window.document.removeEventListener('click', onMouseClicked, false);
    };
  }, [ isFloatingMode, isCompacted ]);

  if (hasTabletScreenSize && !isFloatingMode) {
    updateCompacted(true);
    setFloatingMode(true);
  }

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open={!isCompacted}
        onMouseOver={onMouseOverMenu}
        onMouseLeave={onMouseLeaveMenu}
      >
        <Slide direction="right" in={!subLevelDrawerOpened} mountOnEnter unmountOnExit>
          <MainDrawerContainer>
            <NavigationMenuContainer>
              <Box>
                <DrawerHeader sx={{ justifyContent: isCompacted ? 'center' : 'space-between' }}>
                  <BusinessSelection compact={isCompacted} />
                  {menuIconMode && <DrawerIconContainer>{menuIconMode}</DrawerIconContainer>}
                </DrawerHeader>
                <NavigationMenuItems
                  menuItems={mainDrawerItems}
                  isCompacted={isCompacted}
                  openedGroupKey={openedGroupKey}
                  selectedGroupKey={selectedGroupKey}
                  onItemSelected={onItemSelected}
                  onMenuGroupExpanded={onMenuGroupExpanded}
                />
              </Box>
              <Box>
                <NavigationGuideExternalLink isMobile={false} isCompacted={isCompacted} />
                {bottomItems.map((item) => (
                  <NavigationMenuItem
                    key={navigationDrawerItemKey(item)}
                    menuItem={item}
                    compacted={isCompacted}
                    onItemSelected={onItemSelected}
                    isGeneralItem
                    rootItem
                  />
                ))}
                <NavigationMenuFooter compact={isCompacted} />
              </Box>
            </NavigationMenuContainer>
          </MainDrawerContainer>
        </Slide>
        <Slide direction="left" in={!!subLevelDrawerOpened} unmountOnExit mountOnEnter>
          <NavigationMenuContainer>
            {subLevelDrawerOpened &&
              <SubLevelDrawer
                onItemSelected={() => null}
                closeSubLevelDrawer={closeSubLevelDrawer}
                item={subLevelDrawerOpened!}
              />
            }
          </NavigationMenuContainer>
        </Slide>
      </Drawer>
    </>
  );
};
