import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';

type TimestampProps = { timestampMillis: number } | { date: Date };

type Props = TimestampProps & {
  format?: string;
  tooltip?: boolean;
  tooltipFormat?: string;
};

export function BusinessDate(props: Props) {
  const timestamp = 'timestampMillis' in props ? props.timestampMillis : props.date.getTime();
  const business = useCurrentBusinessOrThrow();
  const localDate = dayjs(timestamp).tz(business.timezone);

  const mainFormattedDate = localDate.format(props.format ?? 'L LTS');
  const tooltipFormattedDate = localDate.format(props.tooltipFormat ?? 'L LTS z');

  const mainElement = <span>{mainFormattedDate}</span>;

  const showTooltip = props.tooltip ?? true;
  if (showTooltip) {
    return (
      <Tooltip title={tooltipFormattedDate} enterDelay={500} >
        {mainElement}
      </Tooltip>
    );
  } else {
    return mainElement;
  }
}
