import { Chip, Grid, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

type Props = {
  content: string;
  kpiType: KpiName;
};

export function PanelFooter({
  content,
  kpiType,
}: Props) {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={0.6}
      marginTop={2}
    >
      <Grid item>
        <Chip label={content} className="chip" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color={theme.palette.text.disabled}>
          <FormattedMessage id={kpiType === 'AVG_RATING' ? 'reviews.ereputation.kpi.origin.average' : 'reviews.ereputation.kpi.origin'} />
        </Typography>
      </Grid>
    </Grid>
  );
}
