import React from 'react';
import { themeV5 } from '../../theme/ThemeV5';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export interface Props {
  size?: number;
  color?: string;
}

const DataAnalyticsPlainSvg: React.FC<Props> = ({
  size = 20,
  color = themeV5.palette.primary.main,
  ...props
}: Props) => (
  <QueryStatsIcon style={{ color: color, fontSize: size }} {...props} />
);

export default DataAnalyticsPlainSvg;
