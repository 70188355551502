// eslint-disable-next-line @typescript-eslint/no-redeclare
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { HttpPaymentTerminalRepository } from '../../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { PayrocConfiguration } from '../../domain/PaymentTerminalRepository';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Card, CardContent, CircularProgress, Input, InputLabel, Stack, Typography } from '@mui/material';

type Props = {
  enrollmentId: EnrollmentId
};

export const PayrocConfigurationForm = ({ enrollmentId }: Props) => {
  const intl = useIntl();
  const repository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);
  const [configuration, setConfiguration] = useState<PayrocConfiguration>();
  const [payrocTid, setPayrocTid] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);

  async function getPayrocConfiguration() {
    const payrocConfiguration = await repository.getPayrocConfiguration(enrollmentId);
    setConfiguration(payrocConfiguration);
    setPayrocTid(payrocConfiguration.tid);

    setLoading(false);
  }

  useEffect(() => {
    getPayrocConfiguration().catch(console.error);
  }, [enrollmentId]);

  const savePayrocConfiguration = (payrocConfiguration: PayrocConfiguration) => {
    repository.savePayrocConfiguration(enrollmentId, payrocConfiguration);
    setConfiguration(payrocConfiguration);
  };

  const updatePayrocConfiguration = () => {
    const updatedConfiguration: PayrocConfiguration = { tid: payrocTid };
    savePayrocConfiguration(updatedConfiguration);
  };

  if (loading || !configuration) return <CircularProgress />;

  return <div>

    <Typography variant="h5" marginBottom="16px">
      {intl.formatMessage({ id: 'payment.terminal.payroc.configuration' })}
    </Typography>

    <Card sx={{ padding: '16px' }}>
      <CardContent>
        <Stack direction="column" justifyContent="space-between" spacing="16px " width="100%">
          <InputLabel>{intl.formatMessage({ id: 'payment.terminal.payroc.tid' })}</InputLabel>
          <Input
            type="text"
            value={payrocTid}
            onChange={(event) => {
              setPayrocTid(event.target.value);
            }}
          />

          <Button
            sx={{ marginLeft: 'auto', marginBottom: '16px' }}
            variant="contained"
            onClick={updatePayrocConfiguration}
          >
            <FormattedMessage id="payment.terminal.payroc.configuration.save" />
          </Button>

        </Stack>
      </CardContent>
    </Card>
  </div>;
};
