import { IconButton, Menu } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Clear, QrCode } from '@mui/icons-material';
import * as React from 'react';
import { useCallback, useState } from 'react';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { usePaymentTerminalDeletionMutation } from '../mutations/usePaymentTerminalDeletionMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { PaymentTerminalWithStatus } from '../domain/PaymentTerminalRepository';
import { ProvisioningQrCodeModal } from './ProvisioningQrCodeModal';
import { PencilSvg } from '@sundayapp/b2b-react-component-library';
import ModifyPaymentTerminalModal from './ModifyPaymentTerminalModal';

interface Props {
  enrollmentId: EnrollmentId;
  paymentTerminal: PaymentTerminalWithStatus;
  callback: () => void;
}

export function PaymentTerminalMenu({ enrollmentId, paymentTerminal, callback }: Props) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const deleteTerminal = usePaymentTerminalDeletionMutation(enrollmentId);
  const useSnackBar = useSnackbar();
  const [provisioningUrl, setProvisioningUrl] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);
  const handleCloseProvisioning = () => setProvisioningUrl(undefined);
  const handleShowModal = () => {
    setAnchorEl(null);
    setProvisioningUrl(paymentTerminal.provisioningUrl);
  };
  const handleModifyModal = () => {
    setAnchorEl(null);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    callback();
  };

  const onDeletePaymentTerminal = useCallback((id: string) => {
    if (window.confirm(intl.formatMessage({ id: 'payment.terminal.manage.list.delete.prompt' }))) {
      deleteTerminal
        .mutateAsync(id)
        .then(() => {
          useSnackBar.addNotification({
            variant: 'success',
            text: intl.formatMessage({ id: 'payment.terminal.manage.list.delete.success' }),
          });
          callback();
        })
        .catch(() =>
          useSnackBar.addNotification({
            variant: 'error',
            text: intl.formatMessage({ id: 'payment.terminal.manage.list.delete.error' }),
          }),
        );
    }
  }, []);

  const handleDelete = () => {
    setAnchorEl(null);
    onDeletePaymentTerminal(paymentTerminal.id);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu id="payment-terminal-menu" anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        {paymentTerminal.serialNumber && (
          <MenuItem onClick={handleModifyModal}>
            <ListItemIcon>
              <PencilSvg fontSize="small" />
            </ListItemIcon>
            {intl.formatMessage({ id: 'payment.terminal.manage.menu.modify' })}
          </MenuItem>
        )}
        {!paymentTerminal.serialNumber && (
          <MenuItem onClick={handleShowModal} disabled={!!paymentTerminal.serialNumber}>
            <ListItemIcon>
              <QrCode fontSize="small" />
            </ListItemIcon>
            {intl.formatMessage({ id: 'payment.terminal.manage.menu.association' })}
          </MenuItem>
        )}
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <Clear fontSize="small" />
          </ListItemIcon>
          {intl.formatMessage({ id: 'payment.terminal.manage.menu.delete' })}
        </MenuItem>
      </Menu>

      <ProvisioningQrCodeModal onClose={handleCloseProvisioning} provisioningUrl={provisioningUrl} enrollmentId = {enrollmentId} paymentTerminalId={paymentTerminal.id}/>
      <ModifyPaymentTerminalModal
        enrollmentId={enrollmentId}
        isOpen={isModalOpen}
        onClose={() => handleCloseModal()}
        terminalName={paymentTerminal.name}
        terminalId={paymentTerminal.id}
        configurationId={paymentTerminal.configurationId}
      />
    </div>
  );
}
