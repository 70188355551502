import React from 'react';
import { useAuthentication } from '../../hooks/useAuthentication';
import { CircularProgress } from '@mui/material';

/* eslint-disable react/jsx-props-no-spreading */
const SignIn = () => {
  const authentication = useAuthentication();
  authentication.logIn();
  return (<CircularProgress color="inherit" size="large" />);
};

export default SignIn;
