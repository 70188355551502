import {
  BimpliCardSvg,
  EdenredCardSvg,
  IconWrapper,
  SodexoCardSvg,
  SwileCardSvg,
  TicketRestaurantCardSvg,
  UpCardSvg,
} from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { PaymentMethodType } from '../domain/Payment';

interface Props {
  methodType: PaymentMethodType,
}

export const ProviderIcon = ({ methodType }: Props) => {
  switch (methodType) {
    case 'edenred':
      return (
        <IconWrapper size="medium">
          <EdenredCardSvg />
        </IconWrapper>
      );
    case 'sodexo':
      return (
        <IconWrapper size="medium">
          <SodexoCardSvg />
        </IconWrapper>
      );
    case 'pluxee':
      return (
        <IconWrapper size="medium">
          <SodexoCardSvg />
        </IconWrapper>
      );
    case 'ticket serviços':
      return (
        <IconWrapper size="medium">
          <TicketRestaurantCardSvg />
        </IconWrapper>
      );
    case 'up':
      return (
        <IconWrapper size="medium">
          <UpCardSvg />
        </IconWrapper>
      );
    case 'swile':
      return (
        <IconWrapper size="medium">
          <SwileCardSvg />
        </IconWrapper>
      );
    case 'bimpli':
      return (
        <IconWrapper size="medium">
          <BimpliCardSvg />
        </IconWrapper>
      );
    default:
      return <></>;
  }
};
