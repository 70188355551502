import axios from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { WeeklyReportOrganization } from '../domain/WeeklyReportOrganization';

export class MerchantAnalyticsRepository {
  private readonly merchantAnalyticsUrl: string;

  constructor() {
    const configuration = ConfigurationLoader.load();
    this.merchantAnalyticsUrl = configuration.merchantAnalyticsUrl;
  }

  async getWeeklyReportOrganizations(venueIds: string[]): Promise<WeeklyReportOrganization[]> {
    const response = await axios.get<WeeklyReportOrganization[]>(`${this.merchantAnalyticsUrl}/weekly-report/organizations`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        venueIds: venueIds.toString(),
      },
    });
    return response.data;
  }
}
