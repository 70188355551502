import React from 'react';
import { styled } from '@mui/material/styles';
import { palette } from 'src/organization-report/design/palette';
import { Typography } from '@mui/material';
import RightArrowSvg from '../../../app/component/icons/RightArrowSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 122px;
  border-radius: 8px;
  background-color: ${palette.primary.electricPink};
  margin-bottom: 16px;
  cursor: pointer;
`;

const Description = styled('div')`
  display: flex;
  flex-direction: column;
  max-width: 180px;
  padding: 16px 0 16px 16px;
`;

const Image = styled('img')`
  width: 112px;
  height: 122px;
`;

const ContactUs = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 5px;
`;

export const PdqAds = () => {
  const business = useCurrentBusinessOrThrow();

  const onClick = () => {
    if (business.address.countryCode === 'FR') {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSdwllfCrtop7R7skLW0S0qmJFFe6Xbhl-3VVavGHqa7Z8mwMA/viewform?usp=sf_link', '_blank');
    } else {
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSeCiTZsA0y1LQ4RT_hebc7jx9j6woQHwkyAIAdl4PoYSKXlew/viewform?usp=pp_url', '_blank');
    }
  };

  return (
    <Container onClick={onClick}>
      <Description>
        <Typography variant="subtitle2" marginBottom="16px">
          <FormattedMessage id="venue.home.revenue.push-pdq"/>
        </Typography>
        <ContactUs>
          <Typography variant="subtitle2"><FormattedMessage id="venue.home.revenue.contact"/></Typography>
          <RightArrowSvg size={18} color={themeV5.palette.text.primary} />
        </ContactUs>
      </Description>
      <Image src="/img/home/Pdq.png"/>

    </Container>
  );
};
