import { ChartData, ChartOptions } from 'chart.js';
import { getChartXAxisLabels } from 'src/sundayplus/reviews/insights/graphs/XAxisLabels';
import { EReputationPageChoicesState } from 'src/store/e-reputation';
import { ChartValue } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { IntlShape } from 'src/app/i18n/TypedIntl';

const GRAPH_BORDER_RADIUS = 8;

export type KeyOption = {
  label: string
  color: string
  order?: number
};

export type BarGraphDefinition<T extends string> = {
  keys: readonly T[];
  dataTypes: Record<T, KeyOption>;
  data: Record<T, ChartValue[]>;
};

export type DataTypes = BarGraphDefinition<string>['dataTypes'];

const datasetBase = {
  datalabels: {
    display: false,
  },
  borderWidth: 2,
  borderColor: 'transparent',
  borderRadius: {
    topLeft: GRAPH_BORDER_RADIUS,
    topRight: GRAPH_BORDER_RADIUS,
    bottomLeft: GRAPH_BORDER_RADIUS,
    bottomRight: GRAPH_BORDER_RADIUS,
  },
  borderSkipped: false,
};

export const barChartData = <T extends string>(pageStatus: EReputationPageChoicesState,
  data: BarGraphDefinition<T>,
  intl: IntlShape): ChartData<'bar'> => ({
    labels: getChartXAxisLabels(pageStatus.timeFrame, Date.now(), intl),
    datasets: [...data.keys.map((key) => ({
      ...datasetBase,
      label: data.dataTypes[key]?.label,
      data: data.data[key],
      backgroundColor: data.dataTypes[key]?.color,
    })),
    ],
  });


export const barChartOptions = (renderTooltipValue?: (value: number) => string): ChartOptions<'bar'> => ({
  interaction: {
    mode: 'index',
  },
  layout: {
    padding: {
      top: 30,
      bottom: 30,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        lineWidth: 0,
      },
    },
    y: {
      stacked: true,
      grid: {
        lineWidth: 0,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
        boxWidth: 60,
      },
      position: 'bottom',
    },
    tooltip: {
      callbacks: {
        label: (item) => {
          const value = renderTooltipValue ? renderTooltipValue(item.raw as number) : item.formattedValue;
          return item.dataset.label + ' : ' + value;
        },
      },
    },
  },
});

export const barChartOptionsV2 = (renderTooltipValue?: (value: number) => string): ChartOptions<'bar'> => ({
  interaction: {
    mode: 'index',
  },
  layout: {
    padding: {
      top: 30,
      bottom: 30,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        lineWidth: 0,
      },
      ticks: {
        maxRotation: 0,
      },
    },
    y: {
      stacked: true,
      grid: {
        lineWidth: 0,
      },
      ticks: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (item) => {
          const value = renderTooltipValue ? renderTooltipValue(item.raw as number) : item.formattedValue;
          return item.dataset.label + ' : ' + value;
        },
      },
    },
  },
});
