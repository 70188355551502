import { Auth0ContextInterface } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { Authentication, CurrentAuthenticatedUser } from '../domain/authentication';
import { RouteDefinitions } from 'src/app/RouteDefinitions';

export type Auth0User = {
  email: string;
  'https://sundayapp.io/sunday_id': string;
};

export class Auth0Authentication implements Authentication {
  constructor(private auth0: Auth0ContextInterface) {}

  isAuthenticated(): boolean {
    return this.auth0.isAuthenticated;
  }

  async logIn() {
    this.auth0.loginWithRedirect();
  }

  async impersonate(redirectPath: string, organization: string, actAsUserId: string, venueId: string) {
    this.auth0.loginWithRedirect(
      {
        appState: { target: `${window.location.origin}${redirectPath}` },
        authorizationParams: {
          organization,
          actAsUserId,
          venueId,
        },
      },
    );
  }

  getToken(): Promise<string> {
    try {
      return this.auth0.getAccessTokenSilently();
    } catch (e) {
      const error = e as Error;
      datadogLogs.logger.warn(`Unable to get access token silently. Trying to relogin. Error: ${error.name}, ${error.message}`, { error });
      this.auth0.loginWithRedirect();
      throw e;
    }
  }

  getUser(): CurrentAuthenticatedUser | undefined {
    const user = this.auth0.user as Auth0User;
    if (!user) {
      return undefined;
    }

    return {
      userId: user['https://sundayapp.io/sunday_id'],
      email: user.email,
    };
  }

  async logOut() {
    this.auth0.logout({
      logoutParams: {
        returnTo: `${window.location.origin}${RouteDefinitions.SignOut.path}`,
      },
    });
  }
}
