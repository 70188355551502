import { Box } from './Box';

export type PickupAddressType = {
  line1?: string;
  line2?: string;
  zipCode?: string;
  city?: string;
};

export enum StatusToSendNotificationDataType {
  SENT_TO_POS = 'SENT_TO_POS',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
  FINALIZED = 'FINALIZED',
  PREPARED = 'PREPARED',
}

export type CustomMessagesJson  = {
  [messageKey: string]: LanguageContent;
};

export type LanguageContent = {
  [languageCode: string]: string;
};

type BoxDetailsMandatoryFields = {
  active: boolean;
  displayName: string;
  description: string;
  imageUrl: string;
  pacingEnabled: boolean;
  pacingInPreparationSlots: number;
  showBoxName: boolean;
  smsNotifications: boolean;
  emailNotifications: boolean;
  waitingTimeThreshold: number;
  enableBoxOrderNotes: boolean;
  displayOrdersQueue: boolean;
  linkId: string;
  customMessagesJson: CustomMessagesJson;
  tags: string;
};

type BoxDetailsOptionalFields = {
  enableClickAndCollect?: boolean;
  minimumMinutesBeforeCollecting?: number;
  maximumDaysBeforeCollecting?: number;
  preparationTime?: number;
  maxOrdersByTimeSlot?: number;
  pickupAddress?: PickupAddressType;
  statusToSendNotification?: StatusToSendNotificationDataType;
  displayOrdersQueue?: boolean;
  pacingStalledDelayMinutes?: number;
  notifyServiceAtTable?: boolean;
  alwaysSendTableName?: boolean;
  automaticallyResetProductsAvailabilities?: boolean;
  sendReminderNotification?: boolean;
  switchOffTableNameInNotesForServiceAtTable?: boolean;
};

export type BoxDetailsFields = BoxDetailsMandatoryFields & BoxDetailsOptionalFields;
export type BoxDetails = Box & BoxDetailsFields;
