import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useState } from 'react';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { downloadBlob } from '../utils';
import { Level } from 'src/utils/logs/plugins';
import { Period } from '../../../revenues/domain/RevenuesDatePresets';
import { PayoutPdqPort } from '../../domain/PayoutPdqPort';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  period: Period;
  onError: () => void;
  payoutPdqPort: PayoutPdqPort;
}

export const DownloadPayoutPdqDetails: React.FC<Props> = ({ period, onError, payoutPdqPort }: Props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const business = useCurrentBusinessOrThrow();
  const pdqEnrollment = business.pdqEnrollment;

  const log = useLog();
  const intl = useIntl();

  useEffect(() => () => {
    setInProgress(false);
  }, []);

  if (pdqEnrollment?.status !== 'ACTIVE') {
    return;
  }
  const enrollmentId = pdqEnrollment.id;

  const downloadExport = () => {
    setInProgress(true);

    payoutPdqPort
      .getPayoutsPdqDetailsExport(enrollmentId, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((blobWithMetadata) => {
        downloadBlob(blobWithMetadata, `${intl.formatMessage({ id: 'accounting.payout_tab.payouts_pdq_details_export_file' })}.csv`);
      })
      .catch((error) => {
        log(Level.ERROR, `Could not retrieve payouts PDQ details export for enrollment id ${enrollmentId} (${period.filter})`, error);
        onError();
      })
      .finally(() => setInProgress(false));
  };
  return (
    <>
      {inProgress ?
        (<CircularProgress/>) :
        (<Button onClick={() => !inProgress && downloadExport()} data-testid="download-payouts-pdq-details-button"
              startIcon={<DownloadIcon />}>
          <FormattedMessage
            id="accounting.payout_tab.download_payouts_pdq_details_report"
          />
          </Button>)}
    </>
  );
};
