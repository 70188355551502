import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, CircularProgress, Drawer, Typography } from '@mui/material';
import { BusinessId } from 'src/business/domain/Business';
import { Product, productTypes } from 'src/menu/dynamic-menu/domain/Product';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { WarningRounded } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { EditIcon } from 'src/app/component/icons/EditIcon';
import { ProductsSelectionModal } from 'src/menu/dynamic-menu/pages/ProductSelectionModal/ProductSelectionModal';
import { ProductSimpleList } from 'src/menu/dynamic-menu/components/products/ProductSimpleList';
import { useGetAllProductsByBusinessId } from 'src/menu/dynamic-menu/queries/product/getAllProductByBusinessIdQuery';

type LinkRelatedProductsDrawerProps = {
  selectedProducts: Product[],
  businessId: BusinessId;
  onClose: () => void;
  onActionConfirmed: (selectedRelatedProductIds: string[]) => void;
  open: boolean;
};

export const LinkRelatedProductsDrawer = ({
  selectedProducts,
  businessId,
  onClose,
  onActionConfirmed,
  open,
}: LinkRelatedProductsDrawerProps) => {
  const intl = useIntl();

  const [selectedRelatedProductIds, setSelectedRelatedProductIds] = useState<string[]>([]);
  const [showProductSelectionModal, setShowProductSelectionModal] = useState<boolean>(false);
  const { data: existingProducts, isLoading: areRelatedProductsLoading } = useGetAllProductsByBusinessId(businessId);

  const onConfirm = () => {
    onActionConfirmed(selectedRelatedProductIds);
    onClose();
  };

  const openProductsList = () => {
    setShowProductSelectionModal(true);
  };

  return <>
    {showProductSelectionModal && (
      <ProductsSelectionModal
        title={intl.formatMessage({ id: 'menus.product.sub_products.manage_button' })}
        productIds={selectedRelatedProductIds}
        onChange={setSelectedRelatedProductIds}
        customFilter={(p) => p.type === productTypes.PRODUCT}
        visible={showProductSelectionModal}
        onClose={() => setShowProductSelectionModal(false)}
        onProductsSelected={(products) => {
          setSelectedRelatedProductIds(products.map((p) => p.id));
          setShowProductSelectionModal(false);
        }}
      />
    )}

    <Drawer
      title={intl.formatMessage({ id: 'menus.products.bulk.modal.link_related_products.title' })}
      open={open}
      onClose={onClose}
      anchor={'right'}
    >
      <Box sx={{ width: '450px' }} role="presentation" display={'flex'} flexDirection={'column'}
           padding={'24px'} gap={3}>
        <Typography variant="h4">
          <FormattedMessage id="menus.products.bulk.modal.link_related_products.title" values={{
            'nb_products': selectedProducts.length,
            'plural': selectedProducts.length > 1 ? 's' : '',
          }} />
        </Typography>
        <Box display="flex" flexDirection={'column'} justifyContent="space-between" gap={3}>
          <Box display="flex"
               sx={{ p: 2 }}
               borderRadius={'1rem'}
               justifyContent="space-between" alignItems="center"
               bgcolor={themeV5.palette.warning.main}
               color={themeV5.palette.warning.light}
               gap={2}>
            <Box display="flex">
              <WarningRounded fontSize={'large'} />
            </Box>
            <Box display="flex" flexDirection={'column'}>
              <Typography variant={'body2'} color={themeV5.palette.warning.light}>
                <FormattedMessage id="menus.products.bulk.modal.link_related_products.content.warning" />
              </Typography>
            </Box>
          </Box>

          <Typography variant="body1" color={themeV5.palette.text.secondary}>
            <FormattedMessage id="menus.products.bulk.modal.link_related_products.content.selection" />
          </Typography>
          <Box display="flex" gap={1} onClick={openProductsList} sx={{ cursor: 'pointer' }}>
            <EditIcon />
            <Typography variant="subtitle1">
              <FormattedMessage id="menus.products.bulk.modal.link_related_products.content.edit_selection" />
            </Typography>
          </Box>

          {areRelatedProductsLoading
            ? <CircularProgress color="inherit" />
            : <ProductSimpleList
              products={selectedRelatedProductIds.map((srp) => existingProducts
                ?.find((v) => srp === v.id))
                ?.filter(p => !!p) || []
              }
              enableNavigate={{ businessId }}
              onSortChanged={setSelectedRelatedProductIds}
              emptyState={<FormattedMessage
                id="menus.products.bulk.modal.link_related_products.content.no_related_products" />} />
          }

          <Box display="flex" flexDirection={'row'} justifyContent="center" alignItems="center" gap={3}>
            <Link
              onClick={onClose}
              sx={{
                cursor: 'pointer',
                color: themeV5.palette.text.primary,
                textDecorationColor: themeV5.palette.text.primary,
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="menus.products.bulk.modal.link_related_products.cancel" />
              </Typography>
            </Link>
            <Button autoFocus variant="contained" onClick={onConfirm}>
              {intl.formatMessage({
                id: 'menus.products.bulk.modal.link_related_products.confirm',
                defaultMessage: 'Save',
              })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  </>;
};
