import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Stack, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import React from 'react';
import { formatDate } from 'src/tips/dispatch/components/formatDate';

export const DispatchedBy = ({ triggeredBy, dispatchedAt }: { triggeredBy: string, dispatchedAt: Date }) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();

  return <Stack direction={'row'} spacing={0.5} justifyContent={'flex-start'}>
    <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
      {`${formatDate(dispatchedAt, business.timezone).date.trim()},`}</Typography>
    <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
      {formatDate(dispatchedAt, business.timezone).time}
    </Typography>
    <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
      {`· ${intl.formatMessage({ id: 'tips.sunday_pooling.dispatch.by' })} ${triggeredBy}`}
    </Typography>
  </Stack>;
};
