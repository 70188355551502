import { Product } from 'src/menu/dynamic-menu/domain/Product';
import React, { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { WarningRounded } from '@mui/icons-material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import {
  SnoozeAction,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/MultiSelectProductsToolbar';

type SnoozeModalProps = {
  selectedProducts: Product[],
  onActionConfirmed: (selectedProducts: Product[]) => void,
  onClose: () => void,
  switchAction: (snoozeAction: SnoozeAction) => void
};

export const SnoozeProductsModal: FC<SnoozeModalProps> = ({
  selectedProducts,
  onActionConfirmed,
  onClose,
  switchAction,
}) => {

  const [snooze, setSnooze] = React.useState(true);

  return (
    <Dialog maxWidth="xs" open={true} onClose={onClose}>
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h6">
          <FormattedMessage id="menus.products.bulk.modal.snooze.title" values={{
            'snooze_unsnooze': snooze ? 'Snooze' : 'Unsnooze',
            'nb_products': selectedProducts.length,
            'plural': selectedProducts.length > 1 ? 's' : '',
          }} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection={'column'} justifyContent="space-between" gap={3}>
          <Box display="flex"
               sx={{ p: 2 }}
               borderRadius={'1rem'}
               justifyContent="space-between" alignItems="center"
               bgcolor={themeV5.palette.warning.main}
               color={themeV5.palette.warning.light}
               gap={2}>
            <Box display="flex">
              <WarningRounded fontSize={'large'} />
            </Box>
            <Box display="flex" flexDirection={'column'}>
              <Typography variant={'body2'} color={themeV5.palette.warning.light}>
                <FormattedMessage id="menus.products.bulk.modal.snooze.content.warning" />
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={2}>
              <Typography>
                <FormattedMessage id="menus.products.bulk.modal.snooze.content" values={{
                  'snooze_unsnooze': snooze ? 'snooze' : 'unsnooze',
                  'nb_products': selectedProducts.length,
                  'plural': selectedProducts.length > 1 ? 's' : '',
                }} />
              </Typography>
            </Box>
            <Box display="flex" gap={2}>
              <Switch checked={snooze} onChange={(e, checked) => {
                switchAction(checked ? 'snooze' : 'unsnooze');
                setSnooze(checked);
              }} />
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Link
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            color: themeV5.palette.text.primary,
            textDecorationColor: themeV5.palette.text.primary,
          }}
        >
          <Typography variant="body1">
            <FormattedMessage id="menus.products.bulk.modal.snooze.cancel" />
          </Typography>
        </Link>
        <Button onClick={() => {
          onActionConfirmed(selectedProducts);
        }} autoFocus color={'error'} variant="contained">
          <FormattedMessage id="menus.products.bulk.modal.snooze.confirm" values={{
            'snooze_unsnooze': snooze ? 'snooze' : 'unsnooze',
            'nb_products': selectedProducts.length,
            'plural': selectedProducts.length > 1 ? 's' : '',
          }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
