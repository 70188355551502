import { Box as MuiBox, Card, CardContent, Switch, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MenuBusiness } from 'src/menu/common/domain/MenuBusiness';
import { BusinessId } from 'src/business/domain/Business';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useUpdateBusinessMutation } from 'src/menu/common/mutations/useBusinessMutation';

export const SwitchEnableOpenBill = ({
  menuBusiness,
  businessId,
}: {
  menuBusiness: MenuBusiness | null | undefined;
  businessId: BusinessId;
}) => {
  const intl = useIntl();

  const snackBar = useSnackbar();
  const updateMenuBusiness = useUpdateBusinessMutation(businessId);

  const toggleOpenOrderingEnabled = (openOrderingEnabled: boolean) => {
    if (!menuBusiness) {
      return;
    }

    updateMenuBusiness
      .mutateAsync({
        orderingAllowed: menuBusiness.orderingAllowed,
        orderingEnabled: menuBusiness.orderingEnabled,
        filterConfiguration: menuBusiness.filterConfiguration,
        openBillEnabled: openOrderingEnabled,
      })
      .then(() => {
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        });
      })
      .catch(() => {
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
        });
      });
  };

  if (!menuBusiness) return null;

  return (
    <Card>
      <CardContent>
        <MuiBox display="flex" flexDirection="row" gap={1} alignItems="center">
          <Switch
            checked={menuBusiness.openBillEnabled}
            onChange={(e) => toggleOpenOrderingEnabled(e.target.checked)}
          />
          <Typography color="#70707B">
            <FormattedMessage id="menus.ordering.enable_open_order" defaultMessage="Autoriser l'ouverture de table" />
          </Typography>
        </MuiBox>
      </CardContent>
    </Card>
  );
};
