import React from 'react';
import { StaffAllocation } from 'src/tips/dispatch/model/StaffAllocation';
import { Stack, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { useRenderMoney } from '@sundayapp/web-money';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const StaffDispatchReport = ({ staffAllocations }: { staffAllocations: StaffAllocation[] }) => {
  const renderMoney = useRenderMoney(true, false);
  return <Stack direction={'column'} spacing={1}>
    {staffAllocations.map((staffAllocation) => (
      <Stack direction={'row'} justifyContent={'flex-start'} alignItems={'center'} spacing={1}
             key={staffAllocation.staffId}>
        <Typography variant={'body1'}>{staffAllocation.staffName}</Typography>
        <Typography variant={'body1'} color={themeV5.palette.text.secondary}>
          <FormattedMessage id={'tips.sunday_pooling.report.staff_receive'} />
        </Typography>
        <Typography variant={'body1'}>{renderMoney(staffAllocation.allocation)}</Typography>
      </Stack>
    ))}
  </Stack>;
};
