import { useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import React from 'react';
import { MenuSchedulesUtils } from '../../../utils/menuSchedules/MenuSchedulesUtils';
import { Schedules } from '../../../domain/Schedules';
import MenuDailySchedule from './MenuDailySchedule';

type MenuScheduleDisplayProps = {
  active: boolean;
  schedules: Schedules;
};

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: nowrap;
  gap: 8px;
`;

const MenuScheduleDisplay = ({ active, schedules }: MenuScheduleDisplayProps) => {
  const intl = useIntl();
  const groupedTimeRanges = MenuSchedulesUtils.optimizeGroupedTimes(
    MenuSchedulesUtils.schedulesToGroupedTimes(schedules),
  );
  const labels = groupedTimeRanges.map((timeRanges) => MenuSchedulesUtils.groupedTimeToString(timeRanges, intl));

  return (
    <LabelContainer>
      {labels.map((label, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuDailySchedule key={String(index)} messages={label} active={active} />
      ))}
    </LabelContainer>
  );
};

export default MenuScheduleDisplay;
