import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { PromoCodeDeletionModal } from '../PromoCodeDeletionModal';
import { PromoCode } from '../../types';
import { useUpdatePromoCode } from '../../hooks';
import { usePushNotification } from '../../../common/components/Notifications.hook';
import { PromoCodeActionButton, PromoCodeButtonContainer, PromoCodeButtonLine } from './PromoCodeEditButtons.style';
import { EnrollmentId } from 'src/business/domain/Enrollment';

interface Props {
  valueChanged: boolean;
  currentPromoCode: PromoCode;
  enrollmentId: EnrollmentId;
  onPromoCodeUpdated: (promoCodeUpdated: PromoCode) => void;
  onPromoCodeDeleted: (voucherId: string) => void;
  closePanel: () => void;
}

export const PromoCodeEditButtons: React.FC<Props> = ({
  valueChanged,
  currentPromoCode,
  enrollmentId,
  onPromoCodeUpdated,
  onPromoCodeDeleted,
  closePanel,
}) => {
  const intl = useIntl();
  const [isDeletePromoCodeModalOpen, setIsDeletePromoCodeModalOpen] = useState<boolean>(false);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [, updatePromoCode] = useUpdatePromoCode(enrollmentId, currentPromoCode.voucherId);
  const [, pushNotification] = usePushNotification();

  const updatePromoCodeDetails = async () => {
    const isNewPromoCode = !currentPromoCode.voucherId;
    try {
      let promoCodeUpdated = { ...currentPromoCode };
      makeUpdating(true);
      promoCodeUpdated = await updatePromoCode(promoCodeUpdated);
      onPromoCodeUpdated(promoCodeUpdated);
      if (isNewPromoCode) {
        closePanel();
      }
      await pushNotification(
        intl.formatMessage(
          isNewPromoCode
            ? { id: 'venue.promoCode.created', defaultMessage: 'voucher code {code} has been successfully created' }
            : { id: 'venue.promoCode.updated', defaultMessage: 'voucher code {code} has been successfully updated' },
          { code: currentPromoCode.code },
        ),
      );
    } catch {
      await pushNotification(
        intl.formatMessage(
          isNewPromoCode
            ? {
              id: 'venue.promoCode.createFailed',
              defaultMessage: 'an error occurred while creating the voucher code',
            }
            : {
              id: 'venue.promoCode.updateFailed',
              defaultMessage: 'an error occurred while updating the voucher code',
            },
        ),
      );
    } finally {
      makeUpdating(false);
    }
  };

  return (
    <>
      <PromoCodeButtonContainer>
        <PromoCodeButtonLine>
          {(valueChanged || !currentPromoCode.voucherId) && (
            <PromoCodeActionButton
              variant="primary"
              size="medium"
              onClick={updatePromoCodeDetails}
              disabled={isUpdating}
            >
              {currentPromoCode.voucherId ? (
                <FormattedMessage id="venue.settings.save" defaultMessage="save" />
              ) : (
                <FormattedMessage id="promocode.details.create" defaultMessage="create voucher code" />
              )}
            </PromoCodeActionButton>
          )}
          {currentPromoCode.voucherId && (
            <PromoCodeActionButton
              variant="warning"
              size="medium"
              onClick={() => {
                setIsDeletePromoCodeModalOpen(true);
              }}
            >
              <FormattedMessage id="promocode.delete" defaultMessage="delete voucher code" />
            </PromoCodeActionButton>
          )}
        </PromoCodeButtonLine>
      </PromoCodeButtonContainer>
      <PromoCodeDeletionModal
        voucherId={currentPromoCode.voucherId}
        voucherCode={currentPromoCode.code}
        isOpen={isDeletePromoCodeModalOpen}
        onClose={() => {
          setIsDeletePromoCodeModalOpen(false);
        }}
        onPromoCodeDeleted={onPromoCodeDeleted}
      />
    </>
  );
};
