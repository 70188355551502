import styled from 'styled-components';
import { colorPalette } from '../../../stylesheet';
import { IconProps } from './interface';

export const MinusIcon = styled.i.attrs<IconProps>(({ color }) => ({
  style: { backgroundColor: color || colorPalette.defaultTextColor },
}))<IconProps>`
  display: inline-block;
  width: 9px;
  height: 1px;
  vertical-align: middle;
  border-radius: 1px;
`;
