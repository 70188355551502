import {
  CodeResponse,
  GoogleOAuthProvider,
  useGoogleLogin as realUseGoogleLogin,
  UseGoogleLoginOptionsAuthCodeFlow,
} from '@react-oauth/google';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PropsWithChildren, useMemo, useState } from 'react';
import { GoogleOauthFacade, GoogleOauthFacadeContext } from './GoogleOauthFacadeContext';
import ReactDOM from 'react-dom';
import { FakeGoogleLoginPopup } from 'src/sundayplus/reviews/settings/setup/google/oauth-facade/FakeGoogleLoginPopup';


const configuration = ConfigurationLoader.load();

export const GoogleOAuthFacadeProvider = (props: PropsWithChildren) => {
  const [googleLoginOptions, setGoogleLoginOptions] = useState<UseGoogleLoginOptionsAuthCodeFlow | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const facade: GoogleOauthFacade = useMemo(() => {
    if (configuration.envName !== 'alpha') {
      return {
        useGoogleLogin: realUseGoogleLogin,
      };
    } else {
      return {
        useGoogleLogin: (options: UseGoogleLoginOptionsAuthCodeFlow) => {
          setGoogleLoginOptions(options);
          return () => setIsOpen(true);
        },
      };
    }
  }, []);

  function callSuccessAndClear(success: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>) {
    googleLoginOptions?.onSuccess?.(success);
    setIsOpen(false);
  }

  function callErrorAndClear(error: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>) {
    googleLoginOptions?.onError?.(error);
    setIsOpen(false);
  }

  return (
    <GoogleOAuthProvider clientId={configuration.googleOauth2ClientId}>
      <GoogleOauthFacadeContext.Provider value={facade}>
        {isOpen && ReactDOM.createPortal(
            <FakeGoogleLoginPopup onSuccess={callSuccessAndClear} onError={callErrorAndClear} onClose={() => setIsOpen(false)} />,
            document.body,
        )}
        {props.children}
      </GoogleOauthFacadeContext.Provider>
    </GoogleOAuthProvider>
  );
};
