import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import axios from 'axios';
import { BoxOrderDetails, BoxOrderSummary, Order, OrderHistory, OrderId, OrderSummary } from '../types';
import {
  AcceptationMode,
  BoxOrdersResults,
  GetOrdersQuery,
  OrderingOrderRepository,
  OrdersResults,
} from './OrderingOrderRepository';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export class OrderingOrderRepositoryHttp extends RepositoryHttp implements OrderingOrderRepository {
  constructor(private orderingBackendUrl: string) {
    super();
  }

  async acceptOrder(orderId: string, acceptationMode: AcceptationMode): Promise<boolean> {
    const response = await axios.post<string>(
      `${this.orderingBackendUrl}/orders/${orderId}/accept`,
      { paymentMethod: acceptationMode },
      this.withDefaultHeader(),
    );

    return response.data === 'OK';
  }

  async cancelOrder(orderId: string, reason: string): Promise<boolean> {
    const response = await axios.post<string>(
      `${this.orderingBackendUrl}/orders/${orderId}/cancel`,
      { reason },
      this.withDefaultHeader(),
    );

    return response.data === 'OK';
  }

  buildOrderQuery(query: GetOrdersQuery, limit?: number): string {
    const base = `afterTime=${query.startDate.getTime()}&beforeTime=${query.endDate.getTime()}`;
    const boxId = query.boxId !== undefined ? `&boxId=${query.boxId}` : '';
    const areaId = query.areaId !== undefined ? `&areaId=${query.areaId}` : '';
    const orderPaymentStatus =
      query.orderPaymentStatus !== undefined ? `&orderPaymentStatus=${query.orderPaymentStatus}` : '';
    const orderStatus = query.orderStatus !== undefined ? `&orderStatus=${query.orderStatus}` : '';
    const limitParam = limit !== undefined ? `&limit=${limit}` : '';
    const orderDisplayIdParam = query.orderDisplayId !== undefined ? `&orderDisplayId=${query.orderDisplayId}` : '';

    return base + boxId + areaId + orderPaymentStatus + orderStatus + limitParam + orderDisplayIdParam;

  }

  async getOrders(enrollmentId: EnrollmentId, query: GetOrdersQuery, limit?: number): Promise<OrderSummary[]> {
    const queryString = this.buildOrderQuery(query, limit);

    const response = await axios.get<OrdersResults>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/orders?${queryString}`,
      this.withDefaultHeader(),
    );

    return response.data.orders;
  }

  async getBoxOrders(boxId: string, query: GetOrdersQuery): Promise<BoxOrderSummary[]> {
    const queryString = this.buildOrderQuery(query, undefined);

    const response = await axios.get<BoxOrdersResults>(
      `${this.orderingBackendUrl}/boxes/${boxId}/box-orders?${queryString}`,
      this.withDefaultHeader(),
    );

    return response.data.orders;
  }

  async getOrder(orderId: string): Promise<Order> {
    const response = await axios.get<Order>(`${this.orderingBackendUrl}/orders/${orderId}`, this.withDefaultHeader());

    return response.data;
  }

  async getOrderHistory(orderId: OrderId): Promise<OrderHistory> {
    const response = await axios.get<OrderHistory>(
      `${this.orderingBackendUrl}/orders/${orderId}/history`,
      this.withDefaultHeader(),
    );

    return response.data;
  }

  async getBoxOrder(orderId: string): Promise<BoxOrderDetails> {
    const response = await axios.get<BoxOrderDetails>(
      `${this.orderingBackendUrl}/box-orders/${orderId}`,
      this.withDefaultHeader(),
    );

    return response.data;
  }
}
