import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Box, Card, CircularProgress, Container } from '@mui/material';
import { useLocation } from 'react-router';
import { PayoutsTable } from './PayoutsTable';
import { PayoutsError } from './PayoutsError';
import { useConfiguration } from './useConfiguration';
import { Level } from 'src/utils/logs/plugins';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { PayoutsFilter } from './filter/PayoutsFilter';
import { Payout } from '../domain/Payout';
import { BusinessId } from 'src/business/domain/Business';
import { extractTimeFrameFilterFromURLParams } from 'src/payments/components/TimeFrameFilter';
import { revenuesDatePresets, revenueTabDatePresets } from '../../revenues/domain/RevenuesDatePresets';

interface Props {
  businessId: BusinessId;
  timezone: string;
}

const PayoutsWrapper = styled(Card)`
  overflow: auto;
  padding: 0;
`;

const Center = styled.div`
  display: flex;
  margin: auto;
  width: 100px;
  height: 70px;
`;

export const Payouts: React.FC<Props> = ({ businessId, timezone }: Props) => {
  const [payouts, setPayouts] = useState<Payout[]>([]);
  const [inError, setInError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const configuration = useConfiguration();
  const log = useLog();
  const location = useLocation();
  const datePresets = revenuesDatePresets(timezone);
  const period = useMemo(
    () =>
      extractTimeFrameFilterFromURLParams<typeof revenueTabDatePresets>(
        location.search,
        datePresets,
        revenueTabDatePresets.LAST_7_DAYS_FILTER,
      ),
    [location.search],
  );
  useEffect(() => {
    setLoading(true);
    configuration.payoutPort
      .getPayouts(businessId, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((data) => {
        setInError(false);
        setLoading(false);
        setPayouts(data);
      })
      .catch((error) => {
        log(Level.ERROR, `Could not retrieve payout list for venue id ${businessId}`, error);
        setLoading(false);
        setInError(true);
      });
  }, [log, businessId, period]);

  return (
    <>
      <PayoutsFilter timezone={timezone} period={period} />
      <PayoutsWrapper>
        <Container disableGutters maxWidth={false}>
          <Box>
            {isLoading && (
              <Center>
                <CircularProgress color="inherit" data-testid="payouts-loader" />
              </Center>
            )}
            {inError && <PayoutsError />}
            {!isLoading && !inError && <PayoutsTable payouts={payouts} timezone={timezone} />}
          </Box>
        </Container>
      </PayoutsWrapper>
    </>
  );
};
