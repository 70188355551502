import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { IntlShape } from 'src/app/i18n/TypedIntl';
import { colorPalette } from 'src/ordering/stylesheet';
import { DateRange } from '../../domain/DateRange';
import { ChartData } from './DimensionsTrendsViewModel';
import { Comparator } from '../../reply/comparator/Comparator';
import { Dimension, dimensionNaturalOrder } from '../../browse/domain/Dimension';

export const basicDimensionsColors = {
  FOOD_AND_DRINKS: '#FFCC80',
  SERVICE: '#F48FB1',
  AMBIANCE: '#B39DDB',
  VALUE_FOR_MONEY: '#80CBC4',
};

interface Props {
  selectedDimension: Dimension | undefined;
  averageDimensionsRatingsChartData: ChartData;
}

const getBorderColor = (aDimension: string, selectedDimension: string | undefined) => {
  const dimensionColor = Object.entries(basicDimensionsColors)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .find(([key, _]) => key === aDimension);
  if (selectedDimension === undefined) return dimensionColor?.pop();
  return aDimension === selectedDimension ? dimensionColor?.pop() : colorPalette.grey300;
};

const getLabels = (locale: string, period: DateRange) => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
  };

  return [
    period.startDate().toLocaleDateString(locale, options),
    ...Array.from(Array(period.numberOfDays() - 2))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map((ignored) => ''),
    period.endDate().toLocaleDateString(locale, options),
  ];
};

const getDataset = (
  intl: IntlShape,
  averageDimensionsRatingsChartData: ChartData,
  selectedDimension: string | undefined,
  aDimension: 'FOOD_AND_DRINKS' | 'SERVICE' | 'AMBIANCE' | 'VALUE_FOR_MONEY',
) => ({
  label: intl.formatMessage({ id: `review.dimensions.${aDimension}` }),
  data: averageDimensionsRatingsChartData.data[aDimension],
  tension: 0.2,
  borderColor: getBorderColor(aDimension, selectedDimension),
  backgroundColor: getBorderColor(aDimension, selectedDimension),
});

function averages(dimensionAverages: {
  FOOD_AND_DRINKS: (number | undefined)[];
  SERVICE: (number | undefined)[];
  AMBIANCE: (number | undefined)[];
  VALUE_FOR_MONEY: (number | undefined)[];
}) {
  return dimensionAverages.AMBIANCE.concat(dimensionAverages.SERVICE)
    .concat(dimensionAverages.FOOD_AND_DRINKS)
    .concat(dimensionAverages.VALUE_FOR_MONEY);
}

function maxValueBelowMinAverage(dimensionAverages: {
  FOOD_AND_DRINKS: (number | undefined)[];
  SERVICE: (number | undefined)[];
  AMBIANCE: (number | undefined)[];
  VALUE_FOR_MONEY: (number | undefined)[];
}) {
  // TODO: bad typing !!!
  const minAverage = Math.min(...averages(dimensionAverages).flatMap((t) => (t === undefined ? [] : [t])));
  return Math.max(0, Math.floor(minAverage * 10) / 10);
}

export const AverageDimensionsRatingsChart = ({ selectedDimension, averageDimensionsRatingsChartData }: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const dimensionsWithSelectedDimensionFirst = Comparator.sortByFirst<Dimension>((s) => s === selectedDimension).sort(
    Object.assign([], dimensionNaturalOrder),
  );

  const data = {
    labels: getLabels(intl.locale, averageDimensionsRatingsChartData.period),
    datasets: [
      ...dimensionsWithSelectedDimensionFirst.map((dimension) =>
        getDataset(intl, averageDimensionsRatingsChartData, selectedDimension, dimension),
      ),
    ],
  };
  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          lineWidth: 0,
        },
      },
      yAxes: {
        display: true,
        ticks: { stepSize: 1 },
        border: {
          color: 'white',
        },
        grid: {
          tickColor: 'white',
        },
        min: maxValueBelowMinAverage(averageDimensionsRatingsChartData.data),
      },
    },
  };

  return (
    <Box
      sx={{
        border: `1px solid ${colorPalette.grey300}`,
        padding: '1rem',
        borderRadius: '1rem',
        height: '100%',
      }}
    >
      <Stack direction="column" gap={2}>
        <Typography variant="subtitle1" color={theme.palette.text.secondary}>
          <FormattedMessage
            id="review.guest_experience.chart.satisfaction.title"
            defaultMessage="Average dimension ratings over time"
          />
        </Typography>
        <Box
          style={{
            position: 'relative',
            height: '16vh',
          }}
        >
          <Line options={options} data={data} plugins={[ChartDataLabels]} />
        </Box>
      </Stack>
    </Box>
  );
};
