import React, { useCallback } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Input from '../../../common/components/Input';
import { PERCENT_TO_RATE } from '../../services/PromoCodeService';
import {
  PromoCodeDetailsLineContainer,
  PromoCodeDetailsLineContent,
  PromoCodeDetailsLineTitle,
} from '../PromoCodeDetails.style';
import { getSpacing } from '../../../stylesheet';

interface Props {
  promoCodeRate: number;
  setPromoCodeRate: (promoCodeRate: number) => void;
}

const RateDetail = styled.div`
  width: 100%;
`;

const Percent = styled.div`
  padding-right: ${getSpacing(2)};
`;

const PromoCodeInput = styled(Input)`
  input {
    padding-right: 0;
  }
`;

export const PromoCodeRate: React.FC<Props> = ({ promoCodeRate, setPromoCodeRate }) => {
  const onPromoCodeRateChange = useCallback(
    (newRate: string) => {
      setPromoCodeRate(Math.min(+newRate * PERCENT_TO_RATE, 10000));
    },
    [setPromoCodeRate],
  );
  const rateToDisplay = (promoCodeRate / 100).toString();
  return (
    <PromoCodeDetailsLineContainer>
      <PromoCodeDetailsLineTitle>
        <FormattedMessage id="promoCode.rateLabel" defaultMessage="rate" />
      </PromoCodeDetailsLineTitle>
      <PromoCodeDetailsLineContent>
        <RateDetail>
          <PromoCodeInput
            value={rateToDisplay}
            onValueChange={onPromoCodeRateChange}
            leftAligned={false}
            type="number"
            endIcon={<Percent>%</Percent>}
            min={0}
            max={100}
          />
        </RateDetail>
      </PromoCodeDetailsLineContent>
    </PromoCodeDetailsLineContainer>
  );
};
