import React from 'react';
import { Stack, Typography } from '@mui/material';
import { dimensionsPx } from '../design/dimensions';

export const WarningMessage = ({ warningMessage }: { warningMessage?: { message: string; imagePath: string } }) => (
  <>
    {warningMessage && (
    <Stack
      display="flex"
      flexDirection="row"
      alignItems="center"
      style={{ backgroundColor: '#fddfdf', padding: dimensionsPx.section_separator.s }}
      borderRadius="8px"
    >
      <img height="24px" src={warningMessage.imagePath} alt="" />
      <Typography paddingLeft="12px">{warningMessage.message}</Typography>
    </Stack>
    )}
  </>
);
