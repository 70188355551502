import { z } from 'zod';
import { CurrencyCode } from '@sundayapp/web-money';

const CurrencyCodeZ = z.nativeEnum(CurrencyCode);

const MoneyZ = z.object({
  amount: z.number(),
  currency: CurrencyCodeZ,
});

export const PayoutZ = z.object({
  id: z.string(),
  amount: MoneyZ,
  createdAt: z.number(),
  arrivalDate: z.optional(z.string()),
  status: z.string(),
  operationDates: z.optional(z.array(z.string())),
});

export type Payout = z.infer<typeof PayoutZ>;
