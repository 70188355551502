import React, { useMemo, useState } from 'react';
import { Avatar, Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import { Menu } from '../../../domain/Menu';
import { Money, renderMoney } from '@sundayapp/web-money';
import { isProduct } from 'src/menu/dynamic-menu/repositories/ProductDto';
import { ProductSummary } from 'src/menu/dynamic-menu/domain/ProductSummary';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { isEqual } from 'lodash';
import { useUpdateMenuMutation } from 'src/menu/dynamic-menu/mutations/menu/useMenuMutation';
import { BusinessId } from 'src/business/domain/Business';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { MoneyForm } from 'src/menu/dynamic-menu/pages/ProductPage/components/MoneyForm';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

type MenuImagePickerProps = {
  businessId: BusinessId;
  menu: Menu;
};


export const MenuProductPriceOverrides = ({ businessId, menu }: MenuImagePickerProps) => {
  const intl = useIntl();
  const updateMenu = useUpdateMenuMutation(businessId, menu.id);
  const menuCategoriesProductElements = menu.categories.flatMap((c) => c.elements).filter((p) => isProduct(p.type));
  const menuProductsDict: { [key in string]: ProductSummary } = useMemo(() => menuCategoriesProductElements.reduce((dict, p) => ({
    ...dict,
    [p.id]: p,
  }), {}), menuCategoriesProductElements);

  const snackBar = useSnackbar();
  const [form, setForm] = useState(menu.productPriceOverrides);

  const hasOverridesChanged = !isEqual(form, menu.productPriceOverrides);

  const productPriceOverrides = form
    .filter((override) => !!menuProductsDict[override.productId])
    .map((override) => ({
      product: menuProductsDict[override.productId],
      productPrice: override.productPrice,
    }));


  if (!productPriceOverrides.length) {
    return <></>;
  }

  const updateOverride = (productId: string, price: Money) => setForm((f) => f.map((override) =>
    override.productId === productId ? { ...override, productPrice: price } : override,
  ));

  const deleteOverride = (productId: string) => setForm((f) => f.filter((override) => override.productId !== productId));

  const onSave = () => {
    updateMenu.mutateAsync({
      productPriceOverrides: form,
    }).then(() => {
      snackBar.addNotification({
        text: intl.formatMessage({ id: 'digital_menus.menu_detail.overrides.notification.success' }),
        variant: 'success',
      });
    }).catch(() => {
      snackBar.addNotification({
        text: intl.formatMessage({ id: 'digital_menus.menu_detail.overrides.notification.error' }),
        variant: 'error',
      });
    });
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" pb={3}>
          <FormattedMessage id="digital_menus.menu_detail.overrides.price.title" />
        </Typography>

        <Box display="flex" flexDirection="column" gap={2}>
          {productPriceOverrides.map(({ product, productPrice }) => <Box key={product.id} display="flex" gap={3}
                                                                         alignItems="center">
            <Avatar>
              <img src={product.pictureUrl} style={{ maxWidth: '100%' }} />
            </Avatar>
            <Box display="flex" flexDirection="column" width={300} gap={1}>
              <Typography>{product.name}</Typography>
              <Typography variant="subtitle2" color={themeV5.palette.text.secondary}>sku: {product.sku}</Typography>
              <Typography variant="subtitle2" color={themeV5.palette.text.secondary}
                          sx={{ textDecoration: 'line-through' }}>{renderMoney(product.basePrice, intl.locale)}</Typography>
            </Box>
            <Box display="flex" flexDirection="column">
              <MoneyForm value={productPrice} disabled={!menu.sundayMenu}
                         label={intl.formatMessage({ id: 'menus.product.details.price' })}
                         onChange={(value) => updateOverride(product.id, value)} />
            </Box>
            {menu.sundayMenu && <Box display="flex" flexDirection="column">
              <IconButton size="large" sx={{ height: 'fit-content' }} onClick={() => deleteOverride(product.id)}>
                <CrossIcon fontSize="inherit" />
              </IconButton>
            </Box>}
          </Box>)}
          {hasOverridesChanged &&
            <Button variant="contained" size="small" onClick={onSave}>
              <FormattedMessage id="digital_menus.menu_detail.overrides.save.label" />
            </Button>}
        </Box>

      </CardContent>
    </Card>
  );
};
