import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EnrollmentConfiguration } from '../domain/EnrollmentConfiguration';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpEnrollmentConfigurationRepository } from '../infrastructure/HttpEnrollmentConfigurationRepository';
import { TipsConfiguration } from '../component/TipsConfiguration';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  max-width: 900px;
  background-color: white;
  padding: 32px 24px 32px 24px;
  border-radius: 16px;
`;

export const SundayForRestaurantsAppSettings = () => {
  const configuration = ConfigurationLoader.load();
  const repository = new HttpEnrollmentConfigurationRepository(configuration.waiterAppBackendUrl);
  const [appConfig, setAppConfig] = useState<EnrollmentConfiguration>();
  const businessId = useBusinessIdOrThrow();
  useEffect(() => {
    repository.enrollmentConfiguration(businessId).then((config) => {
      setAppConfig(config);
    });
  }, []);

  const updateLeaderboard = async (enrollmentConfiguration: EnrollmentConfiguration) => {
    await repository.save(businessId, enrollmentConfiguration);
    setAppConfig(enrollmentConfiguration);
  };

  return (
    <Container>
        <Typography variant={'h5'}>
          <FormattedMessage id="sunday_for_restaurants.settings.performance" />
        </Typography>
      {appConfig && <TipsConfiguration appConfig={appConfig} updateLeaderBoard={updateLeaderboard} />}
    </Container>
  );
};
