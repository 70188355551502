import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Business, BusinessId } from '../domain/Business';
import { businessQuery } from '../queries/businessQuery';

export const useBusinessQuery = (
  businessId: BusinessId,
  options?: Omit<UseQueryOptions<Business>, 'queryKey' | 'queryFn' | 'initialData'>,
) => {
  const query = businessQuery(businessId);
  return useQuery<Business>({  queryKey: query.queryKey, queryFn: query.queryFn, ...options, enabled: !!businessId });
};
