import { TagDto } from './TagDto';
import { Tag } from '../domain/Tag';

export class TagDtoConverter {
  static toTag(dto: TagDto): Tag {
    return {
      id: dto.id,
      name: dto.name,
    };
  }
}
