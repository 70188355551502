import { useQuery } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuSummary } from '../../domain/MenuSummary';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useEffect, useState } from 'react';
import { useRefreshMenuMutation } from 'src/menu/dynamic-menu/mutations/menu/useRefreshMenuMutation';

export const QUERYKEY_MENUS = 'menu_menus';
export const findBySourceQueryKey = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource | undefined) =>
  [QUERYKEY_MENUS, businessId, menuAssetsSource?.posConnectionId, menuAssetsSource?.externalRevenueCenterId] as const;

export const findMenusBySourceQuery = async (businessId: BusinessId, menuAssetsSource: MenuAssetsSource | undefined): Promise<MenuSummary[]> => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return menuRepository.findByMenuAssetsSource(businessId, menuAssetsSource);
};

export const useFindMenusBySourceOrBusinessId = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource | undefined) =>
  useQuery({
    queryKey: findBySourceQueryKey(businessId, menuAssetsSource),
    queryFn: () => findMenusBySourceQuery(businessId, menuAssetsSource),
  });

export const usePollMenusBySource = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource, enabled: boolean) =>
  useQuery({
    queryKey: findBySourceQueryKey(businessId, menuAssetsSource),
    queryFn: () => findMenusBySourceQuery(businessId, menuAssetsSource),
    refetchInterval: 5000,
    enabled,
  });

export const useRefreshMenusBySource = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource, onMenusRefreshed: ((menus: MenuSummary[]) => void) | undefined) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const [fetchingMenus, setFetchingMenus] = useState(false);
  const [fetchingTimeout, setFetchingTimeout] = useState<number>();
  const refreshMenus = useRefreshMenuMutation(businessId, menuAssetsSource);
  const { data: fetchedMenus } = usePollMenusBySource(businessId, menuAssetsSource, fetchingMenus);

  const hasRefreshedMenus = (menus: MenuSummary[]): boolean => {
    const now = Date.now();
    return menus
      .filter((menu) => !menu.sundayMenu)
      .map((menu) => new Date(menu.lastModifiedAt).getTime())
      .some((lastModified) => now - lastModified < 60000); // modified in the last minute
  };
  const stopFetching = () => {
    if (fetchingTimeout) {
      window.clearTimeout(fetchingTimeout);
      setFetchingTimeout(undefined);
    }
    setFetchingMenus(false);
  };

  useEffect(() => {
    if (fetchingMenus && fetchedMenus && hasRefreshedMenus(fetchedMenus)) {
      stopFetching();

      if (onMenusRefreshed) {
        onMenusRefreshed(fetchedMenus);
      }

      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.sync.finished.success' }),
      });
    }
  }, [fetchedMenus, fetchingMenus]);

  const onSync = async () => {
    if (fetchingMenus) {
      stopFetching();
      return;
    }

    await refreshMenus.mutateAsync();

    setFetchingMenus(true);
    const timeout = window.setTimeout(() => {
      stopFetching();
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.sync.finished.no_update' }),
      });
    }, 60000);

    setFetchingTimeout(timeout);
  };

  return { loading: fetchingMenus, refreshMenus: onSync };
};
