import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Allergen } from '../../domain/Allergen';
import { AllergenRepositoryHttp } from '../../repositories/allergen/AllergenRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_ALLERGENS = 'menu_allergens';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_MENU_ALLERGENS, businessId] as const;
export const getAllAllergensByBusinessIdQuery = async ({
  queryKey: [, businessId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Allergen[]> => {
  const configuration = ConfigurationLoader.load();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return allergenRepository.findAllByBusinessId(businessId);
};

export const useGetAllAllergensByBusinessId = (businessId: BusinessId) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: getAllAllergensByBusinessIdQuery });
