import React from 'react';
import { SundayPoolingRepository } from 'src/tips/dispatch/model/SundayPoolingRepository';
import { SundayPoolingRepositoryHttp } from 'src/tips/dispatch/infrastructure/SundayPoolingRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

interface Dependencies {
  sundayPoolingRepository: SundayPoolingRepository;
}

export const DependenciesContext = React.createContext<Dependencies>({
  sundayPoolingRepository: new SundayPoolingRepositoryHttp(ConfigurationLoader.load().bookkeepingApiBaseUrl),
});
