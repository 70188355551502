import { colorPalette, IconWrapper, InfoSvg, Tooltip } from '@sundayapp/b2b-react-component-library';
import { Money, useRenderMoney } from '@sundayapp/web-money';
import { useIntl } from 'src/app/i18n/TypedIntl';

export type InconsistencyWarningProps = {
  inconsistency?: Money
};

export const InconsistencyWarning = ({ inconsistency }: InconsistencyWarningProps) => {
  const { formatMessage } = useIntl();
  const renderMoney = useRenderMoney();
  if (inconsistency === undefined || inconsistency.amount === 0) return <></>;
  return (
    <Tooltip
      direction="left"
      title={
        formatMessage({ id: 'accounting.inconsistency_details' }, { amount: renderMoney(inconsistency) })
      }
    >
      <IconWrapper size="medium" color={colorPalette.black}>
        <InfoSvg title="inconsistency" />
      </IconWrapper>
    </Tooltip>
  );
};
