import React, { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const AddProductsButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return <Button type="button" size="small" variant="contained" onClick={onClick}>
    <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
      <AddIcon sx={{ width: '20px', height: '20px' }} />
      <Typography variant="subtitle1">
        <FormattedMessage id="menus.catalog.products.form.variant_group.add_variants.button" />
      </Typography>
    </Box>
  </Button>;
};
