import { Button, Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import CloseIcon from '@mui/icons-material/Close';
import { colorPalette } from 'src/ordering/stylesheet';
import { SortElement } from './SortElement';
import { ElementItem } from './ElementItem';

const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: ${colorPalette.defaultBackgroundColor};
`;
const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export type Props = {
  title: string,
  elements: SortElement[],
  onElementReorderFinished: (elements: SortElement[]) => void,
  isOpen: boolean,
  setIsOpen: (value: boolean) => void
};

export const ElementSortingModal = ({
  title,
  elements,
  onElementReorderFinished,
  isOpen,
  setIsOpen,
}: Props) => {
  const [sortedElements, setSortedElements] = useState(elements);

  useEffect(() => {
    setSortedElements(elements ?? []);
  }, [elements]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: any) {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = sortedElements!.findIndex((e) => e.id === active.id);
      const newIndex = sortedElements!.findIndex((e) => e.id === over.id);
      const newOrderedElements = arrayMove(sortedElements, oldIndex, newIndex);
      setSortedElements(newOrderedElements);
    }
  }

  const onClose = () => {
    setIsOpen(false);
    setSortedElements(elements);
  };
  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open={isOpen}
      onClose={onClose}
    >
      <DialogContent>
        <Header>
          <Typography fontSize="large" sx={{ padding: '16px' }}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ height: 'fit-content' }}
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </Header>

        <ListContainer>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={sortedElements}
              strategy={verticalListSortingStrategy}
            >
              {sortedElements.map((element) => (
                <ElementItem element={element} key={element.id} />
              ))}
            </SortableContext>
          </DndContext>
        </ListContainer>
        <Footer>
          <Button variant="contained" onClick={() => onElementReorderFinished(sortedElements)}>
            <FormattedMessage id="menus.sort-elements.confirm" />
          </Button>
        </Footer>

      </DialogContent>
    </Dialog>
  );
};
