import { Business } from 'src/business/domain/Business';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { OnboardingStep } from 'src/onboarding/domain/OnboardingStep';
import { OnboardingBusinessInfo } from 'src/intercom/domain/OnboardingBusinessInfo';

export const computeSteps = (business: Business, onboardingBusinessInfo: OnboardingBusinessInfo): OnboardingStep[] => [
  {
    labelKey: 'onboarding.stepper.payment.label',
    descriptionKey: 'onboarding.stepper.payment.description',
    buttonLabelKey: 'onboarding.stepper.payment.button',
    buttonUri: venuePath(business.id) + RouteDefinitions.PaymentsSettings.path,
    isCompleted: onboardingBusinessInfo.canAcceptPayments,
  },
  {
    labelKey: 'onboarding.stepper.google.label',
    descriptionKey: 'onboarding.stepper.google.description',
    buttonLabelKey: 'onboarding.stepper.google.button',
    buttonUri: venuePath(business.id) + RouteDefinitions.ReviewsSettings.path,
    isCompleted: onboardingBusinessInfo.isGoogleAccountSetupDone,
  },
  {
    labelKey: 'onboarding.stepper.team.label',
    descriptionKey: 'onboarding.stepper.team.description',
    buttonLabelKey: 'onboarding.stepper.team.button',
    buttonUri: venuePath(business.id) + RouteDefinitions.UserPermissions.path,
    isCompleted: onboardingBusinessInfo.hasUsers,
  },
  {
    labelKey: 'onboarding.stepper.training.label',
    descriptionKey: 'onboarding.stepper.training.description',
    buttonLabelKey: 'onboarding.stepper.training.button',
    buttonUri: 'https://locker.sunday.cloud/p/training-landing?businessid=' + business.id,
    isCompleted: onboardingBusinessInfo.isTrainingDone,
    isExternalUri: true,
    isShareable: true,
  },
  {
    labelKey: 'onboarding.stepper.customization.label',
    descriptionKey: 'onboarding.stepper.customization.description',
    buttonLabelKey: 'onboarding.stepper.customization.button',
    buttonUri: venuePath(business.id) + RouteDefinitions.Customization.path,
    isCompleted: onboardingBusinessInfo.isCustomizationDone,
  },
  {
    labelKey: 'onboarding.stepper.digital_menu.label',
    descriptionKey: 'onboarding.stepper.digital_menu.description',
    buttonLabelKey: 'onboarding.stepper.digital_menu.button',
    buttonUri: venuePath(business.id) + RouteDefinitions.DigitalMenus.path,
    isCompleted: onboardingBusinessInfo.hasDigitalMenus,
  },
];
