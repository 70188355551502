// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import styled from 'styled-components';

type HourLineProps = {
  time: string;
  onSelection: (hour: string) => void;
};

const HourLineContainer = styled.div`
  padding-top: ${spaceUsages.mediumSmall};
  padding-bottom: ${spaceUsages.mediumSmall};
  padding-left: ${spaceUsages.mediumLarge};
  padding-right: ${spaceUsages.mediumLarge};
  cursor: pointer;

  &:hover {
    background-color: ${colorUsage.quaternaryBackground};
  }
`;

const HourLine = ({ time, onSelection }: HourLineProps) => (
  <HourLineContainer onClick={() => onSelection(time)}>
    <Text size="medium">{time}</Text>
  </HourLineContainer>
);

export default HourLine;
