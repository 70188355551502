import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

export const PowerOfAttorney = ({ representativeIndex }: { representativeIndex: number }) => {
  const { formState: { errors }, register, watch, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.certifiedAuthorisedSignatoryDocument;

  const frontDocumentId = watch(`representatives.${representativeIndex}.certifiedAuthorisedSignatoryDocument`);

  const onFileUploaded = (documentId: string) => {
    setValue(`representatives.${representativeIndex}.certifiedAuthorisedSignatoryDocument`, documentId);
  };
  const onDelete = () => {
    setValue(`representatives.${representativeIndex}.certifiedAuthorisedSignatoryDocument`, '');
  };
  const intl = useIntl();
  return <Stack spacing={3}>
    <Typography variant={'h6'}><FormattedMessage id={'settings.payments.checkout.onboarding.representatives.certifiedAuthorisedSignatoryDocument.title'} /></Typography>
    <UploadDocument
      onRegistered={register(`representatives.${representativeIndex}.certifiedAuthorisedSignatoryDocument`,
        { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.certifiedAuthorisedSignatoryDocument.error' }) })}
      onFileUploaded={onFileUploaded}
      onDelete={onDelete}
      documentId={frontDocumentId}
      error={error}
      purpose={'certified_authorised_signatory'}
      documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.certifiedAuthorisedSignatoryDocument' })} />
  </Stack>;
};
