import { ReconciliationReport } from './ReconciliationReport';
import { RestaurantShiftTimesOptions } from '../../restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { chain } from 'lodash';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { reportShiftNameFromShiftType } from './ReconciliationReportRestaurantShiftRequest';

export type AllowedCreateReportDate = {
  maxAllowedReportDate: Date,
  lastClosedReport?: ReconciliationReport,
  lastReportClosedTheBusinessDay?: boolean,
};

export function getAllowedCreateReportDate(existingReports: ReconciliationReport[], shiftDefinitions: RestaurantShiftTimesOptions): AllowedCreateReportDate {
  const lastReport = chain(existingReports)
    .sortBy((report) => report.shift.dateOfService)
    .last()
    .value();

  if (!shiftDefinitions || !lastReport) {
    return {
      maxAllowedReportDate: new Date(),
    };
  }

  const sortedShiftDefinitions = chain(shiftDefinitions)
    .sortBy((shift) => shift.startAt.hour)
    .value();

  const lastShiftAndAllDayDefinitions = chain(sortedShiftDefinitions)
    .takeRight(2)
    .value();

  // should take into account old report names
  const lastReportClosedTheBusinessDay = lastShiftAndAllDayDefinitions.map((d) => reportShiftNameFromShiftType[d.type]).includes(lastReport.shift.name);
  const maxAllowedReportDate = lastReportClosedTheBusinessDay
    ? addDays(lastReport.shift.dateOfService, 1)
    : lastReport.shift.dateOfService;

  return {
    maxAllowedReportDate,
    lastClosedReport: lastReport,
    lastReportClosedTheBusinessDay,
  };
}

export function createReportNeedsConfirmation(dateOfService: Date, allowedCreateReportDate: AllowedCreateReportDate) {
  return differenceInCalendarDays(dateOfService, allowedCreateReportDate.maxAllowedReportDate) > 0;
}
