import styled from 'styled-components';
import React, { useCallback } from 'react';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import IconButton from '../../../../common/components/IconButton';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { CheckIcon, PlusIcon } from '../../../../common/components/Icons';
import { getFulfillmentMethodDescription, getFulfillmentMethodLabel } from '../../../formatting';

type FulfillmentPanelProps = {
  service: FulfillmentType;
  selected: boolean;
  disabled: boolean;
  onToggle: (service: FulfillmentType, selected: boolean) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${getSpacing(3)};
  border: 1px solid #dddde4;
  border-radius: 16px;
  align-items: center;
  margin-bottom: ${getSpacing(1)};
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: ${getSpacing(3)};
`;

type DisabledProps = {
  disabled: boolean;
};

const Title = styled.div<DisabledProps>`
  font-size: 21px;
  line-height: 22px;
  margin-bottom: ${getSpacing(1)};
  color: ${(props) => (props.disabled ? colorPalette.grey400 : '')};
`;

const Description = styled.div<DisabledProps>`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
  color: ${(props) => (props.disabled ? colorPalette.grey400 : '')};
`;

export const IconButtonWrapper = styled.div`
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
`;

export const FulfillmentPanel = ({
  service, selected, disabled, onToggle,
}: FulfillmentPanelProps) => {
  const iconButton = useCallback(() => {
    if (disabled) {
      return <></>;
    }

    if (selected) {
      return (
        <IconButton
          Icon={CheckIcon}
          active
          onClick={() => {
            onToggle(service, false);
          }}
          disabled={disabled}
        />
      );
    }

    return (
      <IconButton
        Icon={PlusIcon}
        onClick={() => {
          onToggle(service, true);
        }}
        disabled={disabled}
      />
    );
  }, [disabled, onToggle, selected, service]);

  return (
    <Container>
      <Information>
        <Title disabled={disabled}>{getFulfillmentMethodLabel(service)}</Title>
        <Description disabled={disabled}>{getFulfillmentMethodDescription(service)}</Description>
      </Information>
      <IconButtonWrapper>{iconButton()}</IconButtonWrapper>
    </Container>
  );
};
