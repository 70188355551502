import { InvoicePort } from '../domain/InvoicePort';
import { BusinessId } from 'src/business/domain/Business';

export const InvoiceInMemoryPort = (): InvoicePort => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getInvoices: (businessId) => Promise.resolve({ invoicesPerYearMonth: [], cateringInvoicesPerYearMonth: [] }),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getInvoice: (businessId, invoiceId) => Promise.resolve(new Blob(['export;csv;fake;'])),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  downloadAllInvoicesOfMonth: (businessId: BusinessId, year: number, month: number) => Promise.resolve(new Blob(['export;csv;fake;'])),

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getInvoicesForYear: (businessId: BusinessId, year: number) => Promise.resolve({ invoicesPerYearMonth: [], cateringInvoicesPerYearMonth: [] }),
});
