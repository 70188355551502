import { PageSection } from '@organisms/Body/Section/PageSection';
import { GridConfigurable } from '@molecules/Grid/GridConfigurable';
import { ColumnWidth } from 'src/home2/types/grid_layout_types';
import { InfoCard } from '@molecules/Card/InfoCard';
import React from 'react';
import { ReviewsCharts } from '../../../components/Sections/Reviews/ReviewsCharts/ReviewsCharts';
import { HomeSectionProps } from '../HomeContent';

export const ReviewSection = ({
  venueKpi,
  selectedTimeFrame,
}: HomeSectionProps) => {
  return (
    <PageSection title="menu.reviews">
      <GridConfigurable grid={{
        columnDefs: [
          {
            key: 1,
            components: [
              <ReviewsCharts key="col-reviews" 
                venueKpi={venueKpi} 
                selectedTimeFrame={selectedTimeFrame} 
              />,
            ],
            order: 1,
            layout: {
              direction: 'row',
              size: ColumnWidth.LARGE,
            },
          },
          {
            key: 2,
            components: [
              <InfoCard key="key21" subTitle={''} trend={NaN} />,
              <InfoCard key="key22" subTitle={''} trend={NaN} />,
              <InfoCard key="key23" subTitle={''} trend={NaN} />,
            ],
            order: 2,
            layout: {
              direction: 'column',
              size: ColumnWidth.SMALL,
            },
          },
        ],
      }} />
    </PageSection>

  );
};
