/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const RightArrowSvg: React.FC<Props> = ({ size = 16, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.53266 3.52861C5.79301 3.26826 6.21512 3.26826 6.47547 3.52861L10.4755 7.52861C10.7358 7.78896 10.7358 8.21107 10.4755 8.47141L6.47547 12.4714C6.21512 12.7318 5.79301 12.7318 5.53266 12.4714C5.27231 12.2111 5.27231 11.789 5.53266 11.5286L9.06126 8.00001L5.53266 4.47141C5.27231 4.21106 5.27231 3.78896 5.53266 3.52861Z" fill={color} />
  </svg>

);

export default RightArrowSvg;
