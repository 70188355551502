/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const BurgerMenuSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M14 18C14 17.4477 14.4477 17 15 17H33C33.5523 17 34 17.4477 34 18C34 18.5523 33.5523 19 33 19H15C14.4477 19 14 18.5523 14 18ZM14 24C14 23.4477 14.4477 23 15 23H33C33.5523 23 34 23.4477 34 24C34 24.5523 33.5523 25 33 25H15C14.4477 25 14 24.5523 14 24ZM14 30C14 29.4477 14.4477 29 15 29H33C33.5523 29 34 29.4477 34 30C34 30.5523 33.5523 31 33 31H15C14.4477 31 14 30.5523 14 30Z" fill={color} />
  </svg>
);

export default BurgerMenuSvg;
