/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const ValidatedDocumentSvg: React.FC<Props> = ({ size = 16, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.6663 10.4167V5.66675C16.6663 4.26662 16.6663 3.56655 16.3939 3.03177C16.1542 2.56137 15.7717 2.17892 15.3013 1.93923C14.7665 1.66675 14.0665 1.66675 12.6663 1.66675H7.33301C5.93288 1.66675 5.23281 1.66675 4.69803 1.93923C4.22763 2.17892 3.84517 2.56137 3.60549 3.03177C3.33301 3.56655 3.33301 4.26662 3.33301 5.66675V14.3334C3.33301 15.7335 3.33301 16.4336 3.60549 16.9684C3.84517 17.4388 4.22763 17.8212 4.69803 18.0609C5.23281 18.3334 5.93288 18.3334 7.33301 18.3334H9.99967M11.6663 9.16675H6.66634M8.33301 12.5001H6.66634M13.333 5.83341H6.66634M12.083 15.8334L13.7497 17.5001L17.4997 13.7501" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default ValidatedDocumentSvg;
