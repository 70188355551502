/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BoxOrderDetails,
  BoxOrderSummary,
  Order,
  OrderId,
  OrderPaymentStatus,
  OrderStatus,
  OrderSummary,
} from '../types';
import { Refund } from '../../payment/types';

export interface OrdersState {
  orders: OrderSummary[];
  boxOrders: BoxOrderSummary[];
  selectedOrder?: Order;
  selectedBoxOrder?: BoxOrderDetails;
  selectedForRefundOrderId?: OrderId;
  selectedForDetailsOrderId?: OrderId;
}

const initialState: OrdersState = {
  orders: [],
  boxOrders: [],
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<OrderSummary[]>) => {
      state.orders = action.payload;
    },
    addOrders: (state, action: PayloadAction<OrderSummary[]>) => {
      const orders = [...state.orders];
      orders.push(...action.payload);
      state.orders = orders;
    },
    addFreshOrders: (state, action: PayloadAction<OrderSummary[]>) => {
      const orders = [...action.payload];
      orders.push(...state.orders);
      state.orders = orders;
    },
    setBoxOrders: (state, action: PayloadAction<BoxOrderSummary[]>) => {
      state.boxOrders = action.payload;
    },
    setSelectedOrder: (state, action: PayloadAction<Order | undefined>) => {
      state.selectedOrder = action.payload;
    },
    setSelectedBoxOrder: (state, action: PayloadAction<BoxOrderDetails | undefined>) => {
      state.selectedBoxOrder = action.payload;
    },
    setSelectedForRefund: (state, action: PayloadAction<OrderId | undefined>) => {
      state.selectedForRefundOrderId = action.payload;
    },
    setSelectedForDetails: (state, action: PayloadAction<OrderId | undefined>) => {
      state.selectedForDetailsOrderId = action.payload;
      state.selectedForRefundOrderId = undefined;
    },
    addRefundToOrder: (state, action: PayloadAction<Refund>) => {
      if (!state.selectedOrder) return;
      state.selectedOrder.payments = state.selectedOrder.payments.map((payment) =>
        (payment.id === action.payload.paymentId
          ? {
            ...payment,
            refunds: [...payment.refunds, action.payload],
          }
          : payment));
    },
    setOrderStatus: (state, action: PayloadAction<{ orderId: OrderId; status: OrderStatus }>) => {
      if (state.selectedOrder && state.selectedOrder.id === action.payload.orderId) {
        state.selectedOrder.status = action.payload.status;
        state.orders = state.orders.map((order) =>
          (order.id === action.payload.orderId ? { ...order, status: action.payload.status } : order));
      }
      if (state.selectedBoxOrder && state.selectedBoxOrder.orderId === action.payload.orderId) {
        state.selectedBoxOrder.status = action.payload.status;
        const boxOrderId = state.selectedBoxOrder.id;
        const { orderId } = state.selectedBoxOrder;
        state.orders = state.orders.map((order) =>
          (order.id === orderId ? { ...order, status: action.payload.status } : order));
        state.boxOrders = state.boxOrders.map((order) =>
          (order.id === boxOrderId ? { ...order, status: action.payload.status } : order));
      }
    },
    setOrderPaymentStatus: (state, action: PayloadAction<{ orderId: OrderId; status: OrderPaymentStatus }>) => {
      state.orders = state.orders.map((order) =>
        (order.id === action.payload.orderId
          ? {
            ...order,
            paymentStatus: action.payload.status,
          }
          : order));

      state.boxOrders = state.boxOrders.map((boxOrder) =>
        (boxOrder.orderId === action.payload.orderId
          ? {
            ...boxOrder,
            paymentStatus: action.payload.status,
          }
          : boxOrder));
    },
  },
});

export const {
  setOrders,
  addOrders,
  addFreshOrders,
  setSelectedOrder,
  setBoxOrders,
  setSelectedBoxOrder,
  setSelectedForRefund,
  setSelectedForDetails,
  addRefundToOrder,
  setOrderStatus,
  setOrderPaymentStatus,
} = ordersSlice.actions;
export default ordersSlice.reducer;
