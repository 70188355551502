import { Business } from 'src/business/domain/Business';
import { MenuSummary } from 'src/menu/dynamic-menu/domain/MenuSummary';

export type IntroduceFlow = {
  step: 'introduce flow';
};

export type SelectMenuMigration = {
  step: 'select menu migration';
  origin: Business | undefined;
  menus: Array<MenuSummary> | undefined;
  targets: Array<Business> | undefined;
};

export type MenuMigrationReview = {
  step: 'menu review';
  origin: Business;
  menus: Array<MenuSummary>;
  targets: Array<Business>;
};

export type MenuReplicationState =
  | IntroduceFlow
  | SelectMenuMigration
  | MenuMigrationReview;

export const displayMenuMigration = (
  origin?: Business,
  menus?: Array<MenuSummary>,
  targets?: Array<Business>,
): SelectMenuMigration => {
  return {
    step: 'select menu migration',
    origin,
    menus,
    targets,
  };
};

export const displayMenuResume = (
  origin: Business,
  menus: Array<MenuSummary>,
  targets: Array<Business>,
): MenuMigrationReview => {
  return {
    step: 'menu review',
    origin,
    menus,
    targets,
  };
};
