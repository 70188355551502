import React from 'react';
import { themeV5 } from '../../theme/ThemeV5';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

export interface Props {
  size?: number;
  color?: string;
}

const DataAnalyticsSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.text.primary, ...props }: Props) => (
  <QueryStatsIcon style={{ color: color, fontSize: size }} {...props} />
);

export default DataAnalyticsSvg;
