import React from 'react';
import styled from 'styled-components';
import Spinner from '../../../common/components/Spinner';
import { Order } from '../../types';
import { getSpacing } from '../../../stylesheet';
import { OrderDetailsPanel } from './OrderDetailsPanel';
import { BusinessId } from 'src/business/domain/Business';

interface Props {
  businessId: BusinessId
  order?: Order | undefined;
}

const OrderDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(6)} ${getSpacing(3)} ${getSpacing(3)};
`;

export const OrderDetails: React.FC<Props> = ({ businessId, order }) => {
  if (!order) {
    return (
      <div>
        <OrderDetailsContainer>
          <Spinner />
        </OrderDetailsContainer>
      </div>
    );
  }

  return <OrderDetailsPanel businessId={businessId} order={order} />;
};

export default OrderDetails;
