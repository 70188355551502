import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ReplyTemplate } from '../../reply-template/domain/ReplyTemplate';
import { HttpReplyTemplateRepository } from '../../reply-template/infrastructure/HttpReplyTemplateRepository';

const minutes = (n: number) => n * 60 * 1000;

const hours = (n: number) => n * minutes(60);
export const listReplyTemplates = (businessId: string) => ({
  queryKey: [queryKeys.LIST_OF_REPLY_TEMPLATES, {
    businessId,
  }],
  queryFn: (): Promise<ReplyTemplate[]> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpReplyTemplateRepository(configuration.venueFeedbackBaseUrl);
    return repository.list(businessId);
  },
  staleTime: minutes(2),
  cacheTime: hours(2),
});

export const useListReplyTemplates = (businessId: string) => useQuery(listReplyTemplates(businessId));
