// eslint-disable-next-line @typescript-eslint/no-redeclare
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import Box from '@mui/material/Box';
import { Alert, styled } from '@mui/material';

const ErrorWrapper = styled('div')`
  display: flex;
  margin-top: 16px;
  width: 100%;
`;

const Center = styled('div')`
  display: flex;
  margin: auto;
`;

export const PayoutsError: React.FC = () => (
  <ErrorWrapper>
    <Center>
      <Box pb={2}>
        <Alert severity="error">
          <FormattedMessage id="accounting.payout_tab.payout_loading_error" />
        </Alert>
      </Box>
    </Center>
  </ErrorWrapper>
);
