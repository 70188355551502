import React, { PropsWithChildren } from 'react';
import { Grid, TableCell, Typography, useTheme } from '@mui/material';

export const RevenueTableCellHead = ({
  name,
  align = 'right',
  children,
}: PropsWithChildren<{ name: string; align?: 'right' | 'center' }>) => {
  const theme = useTheme();
  const doNotBreakNumbers = { whiteSpace: 'nowrap' };
  return (
    <TableCell align={align}>
      <Grid container rowSpacing={0.5}>
        <Grid item xs={12}>
          <Typography
            sx={doNotBreakNumbers}
            variant="subtitle1"
            color={theme.palette.text.secondary}
          >
            {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color={theme.palette.text.secondary}
            sx={doNotBreakNumbers}
          >
            {children}
          </Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};
