import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { Button } from '@sundayapp/b2b-react-component-library';
import { BoxDetails } from '../../domain/BoxDetails';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { usePushNotification } from '../../../common/components/Notifications.hook';
import { useUpdateBoxDetails } from '../../hooks';
import Input from '../../../common/components/Input';
import { SettingLine } from '../../../common/components/Settings/SettingLine';
import Textarea from '../../../common/components/Textarea';
import { Container } from '../../../common/components/Settings/SettingPanel';
import { BoxTags } from './BoxTags';
import { Switch } from '@mui/material';

type DisplayVenueNameSettingProps = {
  box: BoxDetails;
  setBox: (venue: BoxDetails) => void;
};

type Settings = {
  displayName: string;
  showBoxName: boolean;
  description: string;
};

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const Title = styled.div`
  margin-bottom: ${getSpacing(1)};
`;

const MainTitle = styled(Title)`
  font-size: 18px;
  line-height: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${getSpacing(2)};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${getSpacing(1)};
  padding-bottom: ${getSpacing(2)};
`;

export const DisplayBoxInfoSetting = ({ box, setBox }: DisplayVenueNameSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [boxName, setBoxName] = useState<string>(box.displayName);
  const [description, setDescription] = useState<string>(box.description ?? '');

  const nameChanged = boxName !== box.displayName;
  const descriptionChanged = description !== box.description;

  const currentSetting: Settings = {
    displayName: boxName,
    showBoxName: box.showBoxName,
    description: box.description ?? '',
  };

  const updateBoxSettings = async (setting: Settings) => {
    makeUpdating(true);
    await updateBoxDetails({
      ...setting,
    });
    setBox({
      ...box,
      ...setting,
    });
    pushNotification(intl.formatMessage({ id: 'box.settings.updated', defaultMessage: 'box settings updated' }));
    makeUpdating(false);
  };

  return (
    <Container>
      <MainTitle>
        <FormattedMessage id="box.settings.app-box-name.title" defaultMessage="box name" />
      </MainTitle>
      <Description>
        <FormattedMessage id="box.settings.app-box-name.description" defaultMessage="name of the box to display" />
      </Description>
      <Content>
        <Input value={boxName} onValueChange={setBoxName} disabled={isUpdating} />
        {nameChanged && (
          <Line>
            <SaveButton
              variant="primary"
              size="xSmall"
              onClick={() => updateBoxSettings({ ...currentSetting, displayName: boxName })}
              disabled={isUpdating}
            >
              <FormattedMessage id="box.settings.save" defaultMessage="Save" />
            </SaveButton>
          </Line>
        )}
      </Content>
      <BoxTags box={box} setBox={setBox} />
      <SettingLine
        title={intl.formatMessage({
          id: 'box.settings.app-box-name.home-display.title',
          defaultMessage: 'display box name',
          description: 'title of the setting to display the box name on the page where the customers land',
        })}
        description={intl.formatMessage({
          id: 'box.settings.app-box-name.home-display.description',
          defaultMessage: 'when active, the box name will be displayed below the picture',
          description: 'description of the setting to display the box name',
        })}
      >
        <Switch
          checked={box.showBoxName}
          onChange={(e, value: boolean) => updateBoxSettings({ ...currentSetting, showBoxName: value })}
          disabled={isUpdating}
        />
      </SettingLine>
      <SettingLine
        title={intl.formatMessage({
          id: 'box.settings.app-venue.description.title',
          defaultMessage: 'description',
        })}
        description={intl.formatMessage({
          id: 'box.settings.app-venue.description.description',
          defaultMessage: 'The description will be displayed under the box picture',
        })}
        direction="column"
      >
        <TextAreaWrapper>
          <Textarea value={description} onValueChange={setDescription} disabled={isUpdating} />
        </TextAreaWrapper>
        {descriptionChanged && (
          <Line>
            <SaveButton
              variant="primary"
              size="xSmall"
              onClick={() => updateBoxSettings({ ...currentSetting, description })}
              disabled={isUpdating}
            >
              <FormattedMessage id="box.settings.save" defaultMessage="Save" />
            </SaveButton>
          </Line>
        )}
      </SettingLine>
    </Container>
  );
};
