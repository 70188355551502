import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { queryKeys } from 'src/app/queries/utils';
import { HttpEReputationRepository } from './HttpEReputationRepository';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { extractGraphDataForGivenKpiAndTimeframe } from './EReputationViewTypeModel';
import { EReputationKpisViewModelByTimeframe, fromDomain, RatingsAverageGraphData, RatingsCountGraphData } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { Kpi } from 'src/sundayplus/reviews/insights/domain/Kpi';

const SixHours = 60 * 60 * 6000;

export const eReputationKpisQueryBuilder = (
  businessId: string,
  timezone: string,
): FetchQueryOptions<EReputationKpisViewModelByTimeframe[] | null> => ({
  queryKey: [
    queryKeys.REVIEWS_E_REPUTATION,
    {
      businessId,
      timezone,
    },
  ],
  queryFn: async (): Promise<EReputationKpisViewModelByTimeframe[] | null> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpEReputationRepository(configuration.venueFeedbackBaseUrl);
    const kpis = await repository.getKpis(businessId, timezone);
    const timeframes = kpis.eReputationKpisByTimeframes;
    return timeframes ? fromDomain(timeframes) : null;
  },
  staleTime: SixHours,
  gcTime: SixHours,
});

export const useEReputationKpis = (businessId: string, timezone: string) => {
  return useQuery(eReputationKpisQueryBuilder(businessId, timezone));
};

export const graphDataForKpiAndTimeframeQuery = (
  businessId: string,
  timezone: string,
  kpi: Kpi<RatingsAverageGraphData | RatingsCountGraphData>,
  timeframe: string,
): FetchQueryOptions<RatingsAverageGraphData | RatingsCountGraphData | null> => ({
  queryKey: [queryKeys.HOME.GET_REVIEWS_GRAPH_DATA, businessId, timezone, kpi, timeframe],

  queryFn: async (): Promise<RatingsAverageGraphData | RatingsCountGraphData | null> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpEReputationRepository(configuration.venueFeedbackBaseUrl);
    const eReputationKpis = await repository.getKpis(businessId, timezone);

    return eReputationKpis ? extractGraphDataForGivenKpiAndTimeframe(eReputationKpis, kpi, timeframe) ?? null : null;
  },
  staleTime: SixHours,
  gcTime: SixHours,
});

export const useGraphDataForKpiAndTimeframe = (businessId: string, timezone: string, kpi: Kpi<RatingsAverageGraphData | RatingsCountGraphData>, timeframe: string) => {
  return useQuery(graphDataForKpiAndTimeframeQuery(businessId, timezone, kpi, timeframe));
};
