import * as React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useReportOrganization } from '../hooks/useReportingOrganization';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { dimensionsPx } from '../design/dimensions';
import { OrganizationHeader } from '../components/OrganizationHeader';
import { FinancialPerformance } from '../components/FinancialPerformance';
import { buildFinancialData, buildOperationalData, buildSocialData } from '../domain/TemplatesHelper';
import { SocialMediaPerformance } from '../components/SocialMediaPerfomance';
import { OperationalPerformance } from '../components/OperationalPerformance';
import { OrganizationFooter } from '../components/OrganizationFooter';

export const OrganizationReport = () => {
  const user = useAuthenticatedUserOrThrow();

  const venueIds = user.claims.map((claim) => claim.businessId);
  const { data, isLoading } = useReportOrganization(venueIds);
  if (isLoading) {
    return <CircularProgress />;
  }
  if (!data || data[0] === null) {
    return (
      <Box style={{ padding: dimensionsPx.section_separator.l }}>
        <p>No data available</p>
      </Box>
    );
  }

  const startDate = new Date(data[0].week.lastWeek);
  const endDate = new Date(data[0].week.lastWeek);
  endDate.setDate(startDate.getDate() + 6);
  return (
    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      {data.map((weeklyReport) => (
        <>
          <Box style={{ padding: dimensionsPx.section_separator.l }}>
            <OrganizationHeader weeklyReport={weeklyReport} startDate={startDate} endDate={endDate} />
            <FinancialPerformance
              financialData={buildFinancialData(weeklyReport.weeklyOrganizationVenueKpis)}
              countryCode={weeklyReport.countryCode}
            />
            <SocialMediaPerformance
              socialData={buildSocialData(weeklyReport.weeklyOrganizationVenueKpis)}
              countryCode={weeklyReport.countryCode}
            />
            <OperationalPerformance
              operationalData={buildOperationalData(weeklyReport.weeklyOrganizationVenueKpis)}
              countryCode={weeklyReport.countryCode}
            />
            <OrganizationFooter />
          </Box>
        </>
      ))}

    </Box>
  );
};
