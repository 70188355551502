import { RepresentativeDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  needsNationalIdNumber,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/NationalIdNumberIfUS';


const checkIfDocumentIdComplete = (representative: RepresentativeDraft) => {
  if (!representative.identificationType) return false;
  if (representative.identificationType == 'passport') {
    return !!representative.identificationFrontDocument;
  }
  return !!representative.identificationFrontDocument && !!representative.identificationBackDocument;
};
export const isUboComplete = ({ businessCountryCode, representative, containsErrors }: {
  businessCountryCode: string,
  representative: RepresentativeDraft,
  containsErrors: boolean
}): boolean => {
  const isLegalRepresentative = !!representative.roles?.legalRepresentative;
  const isAddressComplete = !!representative.address?.city && !!representative.address?.country && !!representative.address?.addressLine1 && !!representative.address?.zip;
  const isLegalRepresentativeExtraFieldsComplete = !isLegalRepresentative || (!!representative.companyPosition);
  const isEmailComplete = !!representative.email || businessCountryCode !== 'US';
  const isNationalIdNumberComplete = !!representative?.nationalIdNumber || !needsNationalIdNumber(businessCountryCode, representative?.address?.country ?? '');

  if (containsErrors || !representative) return false;
  if (!checkIfDocumentIdComplete(representative)) return false;
  return !!representative!.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    !!representative.ownershipPercentage &&
    isNationalIdNumberComplete &&
    isEmailComplete &&
    isAddressComplete &&
    isLegalRepresentativeExtraFieldsComplete;
};
