import styled from 'styled-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Stack, Typography } from '@mui/material';
import { GoogleBusinessLocation } from '../domain/GoogleBusinessLocation';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { colorPalette } from 'src/ordering/stylesheet';

const LocationButton = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  height: fit-content;
  border: 1px solid #000;

`;

const Locations = styled.div`
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
  display: flex;
  gap: 10px
`;

type Props = {
  locations: GoogleBusinessLocation[];
  onFinish: (location: GoogleBusinessLocation) => void;
};

export const GoogleSetupSelectLocation = ({
  locations,
  onFinish,
}: Props) => {
  const intl = useIntl();
  const configuration = ConfigurationLoader.load();
  const url = intl.locale.startsWith('fr') ? configuration.frenchReviewHintUrl : configuration.englishReviewHintUrl;

  return (
    <>
      {locations.length === 0 && (
        <Typography>
          <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.google.step2.no_location.description" />
          <ul>
            <li>
              <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.google.step2.no_location.resolution" />
              &nbsp;
              <a href="https://business.google.com/" target="_blank" rel="noreferrer" style={{ color: 'inherit' }}>Google
                My Business</a>
            </li>
            <li>
              <FormattedMessage
                id="settings.reviews_and_loyalty.platform_setup.google.step2.no_location.resolution.more_help" />
              &nbsp;
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                style={{ color: 'inherit' }}
              >
                support
              </a>
            </li>
          </ul>
        </Typography>
      )}
      {locations.length !== 0
        && (
          <>
            <Typography marginBottom="1rem"><FormattedMessage
              id="settings.reviews_and_loyalty.platform_setup.google.step2.description" /></Typography>
            <Locations>
              {locations.map((l) => (
                <LocationButton key={l.id} role="button" tabIndex={0} onClick={() => onFinish(l)}
                                disabled={l.isDuplicatedLocation}>
                  <div>
                    <Typography variant="h6">
                      {l.name}
                    </Typography>
                    <Typography>
                      {l.address}
                    </Typography>
                    {l.isNearbyVenue && <Typography variant={'subtitle2'} marginTop={1} color={colorPalette.pink}>
                      <FormattedMessage
                        id="settings.reviews_and_loyalty.platform_setup.google.step2.suggested_location" />
                    </Typography>}
                    {l.isDuplicatedLocation ?
                      <Typography variant={'subtitle2'} marginTop={1}>
                        <FormattedMessage
                          id="settings.reviews_and_loyalty.platform_setup.google.step2.duplicate_location.description" />
                      </Typography>
                      : <Stack direction={'row'} gap={1}>
                      </Stack>}
                  </div>
                </LocationButton>
              ))}
            </Locations>
          </>
        )}
    </>
  );
};
