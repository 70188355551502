import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const LastName = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[legalRepresentativeIndex]?.lastName;
  return <TextField
    fullWidth
    required
    error={!!error}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.lastName' })}
    InputLabelProps={{ shrink: true }}
    {...register(`representatives.${legalRepresentativeIndex}.lastName`, { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.lastName.error.required' }) })}
    helperText={!!error ? error?.message : intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.lastName.helper' })}
  />;
};
