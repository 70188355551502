import { Product } from 'src/menu/dynamic-menu/domain/Product';
import React, { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch, Typography } from '@mui/material';
import Link from '@mui/material/Link';
import { themeV5 } from 'src/app/theme/ThemeV5';

import { WarningRounded } from '@mui/icons-material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import {
  AppOrderingAction,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/MultiSelectProductsToolbar';


type AppOrderingModalProps = {
  selectedProducts: Product[],
  onActionConfirmed: (selectedProducts: Product[]) => void,
  onClose: () => void,
  switchAction: (enableAppOrderingAction: AppOrderingAction) => void,
};

export const AppOrderingProductsModal: FC<AppOrderingModalProps> = ({
  selectedProducts,
  onActionConfirmed,
  onClose,
  switchAction,
}) => {
  const [appOrderingChecked, setAppOrderingChecked] = React.useState(true);

  return (
    <Dialog maxWidth="xs" open={true} onClose={onClose}>
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h6"><FormattedMessage id="menus.products.bulk.modal.app_ordering.title" /></Typography>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection={'column'} justifyContent="space-between" alignItems="center" gap={3}>
          <Box display="flex"
               sx={{ p: 2 }}
               borderRadius={'1rem'}
               justifyContent="space-between" alignItems="center"
               bgcolor={themeV5.palette.warning.main}
               color={themeV5.palette.warning.light}
               gap={2}>
            <Box display="flex">
              <WarningRounded fontSize={'large'} />
            </Box>
            <Box display="flex" flexDirection={'column'}>
              <Typography variant={'body2'} color={themeV5.palette.warning.light}>
                <FormattedMessage id="menus.products.bulk.modal.app_ordering.content.warning" />
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" gap={2}>
              <div style={{ width: '90%' }}>
                <Typography>
                  <FormattedMessage id="menus.products.bulk.modal.app_ordering.content.1"
                                    values={{ 'plural': selectedProducts.length > 1 ? 's' : '' }} />
                </Typography>
                <Typography fontSize={'0.875rem'} color={themeV5.palette.text.secondary}>
                  {appOrderingChecked ?
                    (<FormattedMessage id="menus.products.bulk.modal.app_ordering.content.2.available" />) :
                    (<FormattedMessage id="menus.products.bulk.modal.app_ordering.content.2.unavailable" />)
                  }
                </Typography>
              </div>
            </Box>
            <Box display="flex" gap={2}>
              <Switch checked={appOrderingChecked} onChange={(e, checked) => {
                switchAction(checked ? 'enableAppOrdering' : 'disableAppOrdering');
                setAppOrderingChecked(checked);
              }} />
            </Box>
          </Box>
        </Box>

      </DialogContent>
      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Link
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            color: themeV5.palette.text.primary,
            textDecorationColor: themeV5.palette.text.primary,
          }}
        >
          <Typography variant="body1">
            <FormattedMessage id="menus.products.bulk.modal.app_ordering.cancel" />
          </Typography>
        </Link>
        <Button onClick={() => onActionConfirmed(selectedProducts)} autoFocus variant="contained">
          <FormattedMessage id="menus.products.bulk.modal.app_ordering.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
