import { ScheduleRange } from 'src/menu/dynamic-menu/domain/ScheduleRange';
import { TimePeriod } from '../../common/domain/TimePeriod';

export const convertTimePeriodToScheduleRange = (timePeriod: TimePeriod): ScheduleRange => ({
  day: timePeriod.weekDay.valueOf() - 1,
  startTime: timePeriod.startTime,
  endTime: timePeriod.endTime,
});

export const convertScheduleRangeToTimePeriod = (scheduleRange: ScheduleRange): TimePeriod => ({
  weekDay: scheduleRange.day.valueOf() + 1,
  startTime: scheduleRange.startTime,
  endTime: scheduleRange.endTime,
});
