import { useQuery } from '@tanstack/react-query';
import { WaiterWithHistory } from '../domain/AllWaiters';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { WaiterHttpPort } from '../infrastructure/WaiterHttpPort';

export const QUERYKEY_WAITER = { scope: 'waiter' };

const buildQueryKey = (businessId: string, waiterId: string | undefined) =>
  [QUERYKEY_WAITER, businessId, waiterId] as const;

export const getWaiterQuery = (businessId: string, waiterId: string | undefined): Promise<WaiterWithHistory> => {
  const configuration = ConfigurationLoader.load();
  const waiterPort = new WaiterHttpPort(configuration.bookkeepingApiBaseUrl);
  if (!waiterId) {
    return Promise.resolve({
      waiter: {
        waiterId: '',
        name: '',
        phoneNumber: '',
        rates: {},
      },
      lastUpdate: undefined,
    });
  }
  return waiterPort.getWaiterWithHistory(businessId, waiterId);
};

export const useGetWaiter = (businessId: string, waiterId: string | undefined) =>
  useQuery({ queryKey: buildQueryKey(businessId, waiterId), queryFn: () => getWaiterQuery(businessId, waiterId) });
