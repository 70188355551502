import styled from 'styled-components';

export const ProductName = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: auto;
  cursor: pointer;
`;
