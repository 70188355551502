import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box, Button as MuiButton, CircularProgress, Modal, Stack, Typography } from '@mui/material';
import { GoogleSetupSelectLocation } from './GoogleSetupSelectLocation';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { GoogleBusinessLocation } from '../domain/GoogleBusinessLocation';
import { styleButton, styleModal } from '../../../../../modal.style';
import { HttpGoogleSetup } from '../infrastruscture/HttpGoogleSetup';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { sortBusinessLocations } from '../domain/GoogleBusinessLocationSort';
import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';
import {
  useSelectGoogleLocationMutation,
} from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupLocation';

type Props = {
  close: () => void;
};

enum WizardSetupStage {
  LOADING,
  SELECT_LOCATION,
  DONE,
}

export const GoogleSetupWizard = ({ close }: Props) => {
  const configuration = ConfigurationLoader.load();
  const repository = new HttpGoogleSetup(configuration.venueFeedbackBaseUrl);
  const businessId = useBusinessIdOrThrow();
  const [ locations, setLocations ] = useState<GoogleBusinessLocation[]>([]);
  const [ setupStage, setSetupStage ] = useState(WizardSetupStage.LOADING);
  const { isMobileScreen } = useMediaBreakpoints();

  const selectGoogleLocationMutation = useSelectGoogleLocationMutation(businessId);

  useEffect(() => {
    repository.listAvailableLocations(businessId).then((locationsFound) => {
      setLocations(sortBusinessLocations(locationsFound));
      setSetupStage(WizardSetupStage.SELECT_LOCATION);
    });
  }, []);


  const onLocationSelected = async (location: GoogleBusinessLocation) => {
    setSetupStage(WizardSetupStage.LOADING);
    await selectGoogleLocationMutation.mutateAsync(location);
    setSetupStage(WizardSetupStage.DONE);
    close();
  };

  return (
    <Modal open onClose={close} aria-labelledby="modal-title">
      <Box marginTop={2} sx={{ ...styleModal, width: isMobileScreen ? '90%' : 600 }}>
        <Stack direction="column" gap={2}>
          <Typography id="modal-title" variant="h5">
            <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.google.wizard_title" />
          </Typography>
          <Stack direction="column" justifyContent="start" gap={3}>
            {setupStage === WizardSetupStage.LOADING && (
              <div style={{ textAlign: 'center' }}>
                <CircularProgress />
              </div>
            )}
            {setupStage === WizardSetupStage.SELECT_LOCATION && (
              <GoogleSetupSelectLocation locations={locations} onFinish={onLocationSelected} />
            )}
          </Stack>
          <Stack direction="column" gap={1.5}>
            <MuiButton
              size="large"
              variant="outlined"
              style={{ ...styleButton }}
              onClick={close}
            >
              <FormattedMessage id="modal.cancel" defaultMessage="cancel" />
            </MuiButton>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
