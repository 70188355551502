import { sumMoneys, useRenderMoney } from '@sundayapp/web-money';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { computeEvolutionPercentage } from 'src/home/components/HomeTools';
import { RevenueContentProps } from '../types/revenue_types';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useTheme } from '@mui/material';
import { isPaymentTerminalAvailable } from 'src/business/domain/Business';

export const useGetRevenueContent = (props: RevenueContentProps) => {
  const theme = useTheme();
  const business = useCurrentBusinessOrThrow();
  const {
    totalRevenue,
    totalAmountPaymentTerminal,
    totalAmountPaymentTerminalLastWeek,
    totalRevenueLastWeek,
    adoptionRate,
  } = props;

  const intl = useIntl();
  const renderMoney = useRenderMoney(true, true);

  const totalSundayRevenue = sumMoneys(totalRevenue, totalAmountPaymentTerminal);
  const totalSundayRevenueLastWeek = sumMoneys(totalRevenueLastWeek, totalAmountPaymentTerminalLastWeek);

  const revenue = renderMoney(totalSundayRevenue);
  const revenueTrend = computeEvolutionPercentage(totalSundayRevenue.amount, totalSundayRevenueLastWeek.amount);

  const qrCodeRevenue = renderMoney(totalRevenue);
  const pdqRevenue = renderMoney(totalAmountPaymentTerminal);

  const revenueTitle = intl.formatMessage({ id: 'venue.home.revenue.title' });

  const paymentTerminalData = isPaymentTerminalAvailable(business) ? [{
    label: intl.formatMessage({ id: 'venue.home.revenue.qr_code' }),
    value: qrCodeRevenue,
    color: theme.palette.primary.main,
    order: 1,
    icon: 'circle',
    isCentered: true,
  },
  {
    label: intl.formatMessage({ id: 'venue.home.revenue.pdq' }),
    value: pdqRevenue,
    color: '#8E0B81',
    order: 2,
    icon: 'circle',
    isCentered: true,
  }] : [];

  const subItems =
    typeof adoptionRate !== 'undefined'
      ? [
        {
          label: intl.formatMessage({ id: 'venue.home.revenue.cash' }),
          value: `${adoptionRate.toFixed(2)}%`,
          color: theme.palette.grey[300],
          order: 3,
          icon: 'circle',
          isCentered: true,
        },
        {
          label: intl.formatMessage({ id: 'venue.settings.category.other' }),
          value: `${adoptionRate.toFixed(2)}%`,
          color: theme.palette.grey[500],
          order: 4,
          icon: 'circle',
          isCentered: true,
        },
        ...paymentTerminalData,
      ]
      : paymentTerminalData;

  return {
    revenueTitle,
    revenueTrend,
    revenue,
    subItems,
  };
};
