import { Navigate } from 'react-router';
import { venueHomePath } from '../pathHelpers';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { CircularProgress } from '@mui/material';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';

export const RedirectToHomePage = () => {
  const businessId = useBusinessIdOrThrow();
  const { isLoading, data: business } = useBusinessQuery(businessId);

  if (isLoading || !business) {
    return <CircularProgress />;
  }

  return <Navigate to={venueHomePath(businessId)} />;
};
