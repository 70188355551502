import { FormControl, InputLabel, MenuItem as MuiMenuItem, Select, SelectChangeEvent } from '@mui/material';
import { MenuAssetsSource, menuAssetsSourceId, MenuAssetsSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export type MenuAssetsSourceSelectorProps = {
  menuAssetsSources: MenuAssetsSources;
  allowAllSourcesOption?: boolean;
  value: MenuAssetsSource | undefined;
  onChange: (selectedMenuAssetsSource: MenuAssetsSource | undefined) => void;
};
export const MenuAssetsSourceSelector = ({
  menuAssetsSources,
  allowAllSourcesOption,
  value,
  onChange,
}: MenuAssetsSourceSelectorProps) => {
  if (!menuAssetsSources.sources.length) {
    return <></>;
  }

  const menuAssetsSourceChanged = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    const selectedMenuAssetsSource = newValue === 'ALL_MENU_ASSETS_SOURCES'
      ? undefined
      : menuAssetsSources.sources.find((mas) => menuAssetsSourceId(mas) === newValue);
    onChange(selectedMenuAssetsSource);
  };

  const sourceAssetTypeLabel = () => {
    if (menuAssetsSources.forEnrollment === 'PAT' && menuAssetsSources.sources.length > 1 &&
      menuAssetsSources.sources
        .map((mas) => mas.externalRevenueCenterId)
        .filter((rc): rc is string => !!rc).length > 0) {
      return 'revenue-centers';
    }
    if (menuAssetsSources.forEnrollment === 'PAT') {
      return 'pos-connections';
    }
    return 'boxes';
  };

  return (<FormControl sx={{ minWidth: 300 }}>
      <InputLabel id="menu-assets-source">
        <FormattedMessage id={`menus.menu-assets-${sourceAssetTypeLabel()}-label`} />
      </InputLabel>

      <Select
        labelId="menu-assets-source"
        value={value ? menuAssetsSourceId(value) : 'ALL_MENU_ASSETS_SOURCES'}
        label={<FormattedMessage id={`menus.menu-assets-${sourceAssetTypeLabel()}-label`} />}
        onChange={menuAssetsSourceChanged}
      >
        {allowAllSourcesOption && (<MuiMenuItem key={'ALL_MENU_ASSETS_SOURCES'}
                                                value={'ALL_MENU_ASSETS_SOURCES'}>
          <FormattedMessage id={`menus.menu-assets-${sourceAssetTypeLabel()}.default_value`} />
        </MuiMenuItem>)}
        {menuAssetsSources.sources.map((mas) => {
          return <MuiMenuItem key={menuAssetsSourceId(mas)}
                              value={menuAssetsSourceId(mas)}>{mas.name}</MuiMenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
