import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { AllowedValue } from 'src/domain/venue/PosPaymentMethods';
import { FormValues } from './types';
import { getLabel } from './utils';

export const SelectMappedValues = ({
  allowedValues, getValues, setValue, index, value,
}: {
  allowedValues: AllowedValue[],
  getValues: UseFormGetValues<FormValues>,
  setValue: UseFormSetValue<FormValues>,
  index: number,
  value: string[],
}) => {
  const { formatMessage } = useIntl();

  const onChangeMappedValues = (e: SelectChangeEvent<string[]>) => {
    const selected = e.target.value;
    setValue(`paymentMethods.${index}.mappedValues`, typeof selected === 'string' ? selected.split(',') : selected);
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="allowed-value">
          <Typography><FormattedMessage id="settings.posPaymentMethod.allowedValues" /></Typography>
        </InputLabel>
        <Select
          labelId="allowed-value"
          label={formatMessage({ id: 'settings.posPaymentMethod.allowedValues' })}
          multiple
          renderValue={(selected: string[]) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((method: string) => (
                <Chip key={`mappedValues-${index}-${method}}`} label={getLabel(method, allowedValues)} />
              ))}
            </Box>
          )}
          onChange={(e: SelectChangeEvent<string[]>) => onChangeMappedValues(e)}
          value={value}
          defaultValue={getValues(`paymentMethods.${index}.mappedValues`)}
          sx={{ minWidth: '130px' }}
        >
          {allowedValues.map((allowed: AllowedValue) => (
            <MenuItem
              key={`mappedValues.${allowed.value}`}
              value={allowed.value}
              disabled={getValues(`paymentMethods.${index}.preferredValue`) === allowed.value}
            >
              <Checkbox checked={getValues(`paymentMethods.${index}.mappedValues`).indexOf(allowed.value) > -1} />
              <ListItemText primary={`${allowed.label} (${allowed.value})`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
