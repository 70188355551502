import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { Business } from 'src/business/domain/Business';
import { useWhiteLabelSaveCustomization } from 'src/infrastructure/venue/useWhiteLabelSaveCustomization';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

import { ButtonContainer, CustomizationForm } from 'src/pages/Settings/Customization/Customization.styles';
import { Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { InputColor } from 'src/pages/Settings/Customization/components/Pictures/CoverBackgroundColorHandler.styles';
import { useForm } from 'react-hook-form';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import LoadingPreview from 'src/pages/Settings/Customization/components/LoadingPreview';
import { PreviewContainer } from 'src/pages/Settings/Customization/components/ColorCustomizers/PreviewContainer';


export const LandingPageColorCustomizer = ({ business, whiteLabelCustomization }: {
  business: Business, whiteLabelCustomization: WhiteLabelCustomization;
}) => {
  const [isDirtyFromColorInputs, setIsDirtyFromColorInputs] = React.useState(false);
  const { saveWhiteLabelCustomization } = useWhiteLabelSaveCustomization();
  const snackBar = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const onSaveWhiteLabelCustomization = async (colorToSave: WhiteLabelCustomization) => {
    await saveWhiteLabelCustomization(business.patEnrollment?.id!, colorToSave);
    snackBar.addNotification({
      text: intl.formatMessage({ id: 'settings.customization.changes.saved' }),
      variant: 'success',
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<{
    loadingPage: string;
  }>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      loadingPage: whiteLabelCustomization.color.loadingPage ?? '#FFFFFF',
    },
  });

  const watchLoadingPage = watch('loadingPage');
  const onSubmit = async (inputs: { loadingPage: string }) => {
    onSaveWhiteLabelCustomization({
      ...whiteLabelCustomization,
      color: {
        ...whiteLabelCustomization?.color,
        loadingPage: inputs.loadingPage,
      },
    });
    reset(inputs, { keepDirty: false });
    setIsDirtyFromColorInputs(false);
  };
  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));
  return (
    <CustomizationForm onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <PreviewContainer>
          <Stack alignItems={'center'} spacing={2}>
            <LoadingPreview
              loadingImgUrl={whiteLabelCustomization.loadingImgUrl}
              customizationColors={{ ...whiteLabelCustomization.color, loadingPage: watchLoadingPage }}
            />
            <Typography variant={'caption'} color={theme.palette.text.secondary}>
              <FormattedMessage id={'settings.customization.preview.title'} />
            </Typography>
          </Stack>
        </PreviewContainer>
        <Stack spacing={2}>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'settings.customization.color.loadingPage' })}
              placeholder={'#FFFFFF'} InputLabelProps={{ shrink: true }}
              error={!!errors.loadingPage}
              {...register('loadingPage', {
                deps: [],
                required: false,
              })} />
            <Box display={'flex'} alignItems={'center'} gap={1} flexDirection={'row'}>
              <InputColor
                type="color"
                value={watchLoadingPage}
                onChange={(e) => {
                  setValue('loadingPage', e.target.value);
                  setIsDirtyFromColorInputs(true);
                }}
              />
            </Box>
          </Stack>
          {isValid && (isDirty || isDirtyFromColorInputs) && (
            <ButtonContainer>
              <Button type="submit" variant="contained" size={'small'}
                      disabled={!isValid || isSubmitting || (!isDirty && !isDirtyFromColorInputs)}>
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </ButtonContainer>
          )}
        </Stack>
      </Stack>
    </CustomizationForm>
  );

};
