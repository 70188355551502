import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useLogoUseCase } from './useLogoUseCase';
import PictureContainer from './PictureContainer';
import { CropPictureData } from 'src/domain/venue/CropperService';
import { EnrollmentId } from 'src/business/domain/Enrollment';

interface LogoHandlerProps {
  venueId: string;
}
export function LogoHandler({ venueId }: LogoHandlerProps) {
  const {
    deleteLogos,
    uploadLogo,
    tempLogoPicture,
    removeTempLogoPicture,
    saveCroppedPicture,
    editLogoPicture,
    logoUrl,
    loading,
  } = useLogoUseCase(venueId as EnrollmentId);
  return (
    <PictureContainer
      inputId="logo"
      uploadFile={(file: File) => uploadLogo(file)}
      title={<FormattedMessage id="settings.app_settings.logo" />}
      url={logoUrl}
      onCloseCropTool={removeTempLogoPicture}
      tempPicture={tempLogoPicture}
      editPicture={editLogoPicture}
      deletePicture={() => deleteLogos()}
      saveCroppedPicture={(crop: CropPictureData) => saveCroppedPicture(crop)}
      colorSelector
      aspect={1}
      cropShape="round"
      loading={loading}
    />
  );
}
