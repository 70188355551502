import { collection, doc, Firestore, getDoc, updateDoc } from 'firebase/firestore';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const VENUE_FOR_MENU_COLLECTION_NAME = 'venues';

export class MenuRepositoryFirestore {
  constructor(private db: Firestore) {}

  async getMenuLabels(
    enrollmentId: EnrollmentId,
  ): Promise<{ pdfMenuLabel: string | undefined; websiteMenuLabel: string | undefined }> {
    const venueDoc = await getDoc(doc(collection(this.db, VENUE_FOR_MENU_COLLECTION_NAME), enrollmentId));
    const venue = venueDoc.data();
    return { pdfMenuLabel: venue?.pdfMenuLabel, websiteMenuLabel: venue?.websiteMenuLabel };
  }

  async updateMenuLabels(enrollmentId: EnrollmentId, pdfMenuLabel: string, websiteMenuLabel: string) {
    await updateDoc(doc(collection(this.db, VENUE_FOR_MENU_COLLECTION_NAME), enrollmentId), {
      pdfMenuLabel,
      websiteMenuLabel,
    });
  }
}
