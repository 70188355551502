import {
  CurrencyCode, money, Money, sumMoneys,
} from '@sundayapp/web-money';
import { datadogLogs } from '@datadog/browser-logs';

export function isValidMoney(moneyToCheck: Money | undefined): moneyToCheck is Money {
  const currencyIsValid = ![CurrencyCode.UNSPECIFIED, CurrencyCode.UNKNOWN, undefined].includes(moneyToCheck?.currency);
  if (!!moneyToCheck?.amount && !currencyIsValid) {
    // This should never happen, but do ensure we do not have bugs which will remains silent, we prefer log in case of
    datadogLogs.logger.error(`A money with a non-null amount and a currrency code [${moneyToCheck?.currency}] has been detected. This should never happen.`, { money: moneyToCheck });
  }
  return currencyIsValid;
}

// sometimes currency code in given moneys may be UNSPECIFIED and will make the sumMoneys fail
// (ex. when calling revenue summary on bookkeeping and there is still no revenue)
// eslint-disable-next-line max-len
export const safeSumMoney = (moneys: Money[], venueCurrency: CurrencyCode): Money => moneys
  .filter((revenue) => isValidMoney(revenue))
  .reduce((a, b) => sumMoneys(a, b), money(0, venueCurrency));
