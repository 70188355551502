import { FieldError, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

const allPersonalIds = ['passport', 'national_identity_card', 'driving_license'] as const;
type SupportedPersonalIdType = typeof allPersonalIds[number];

export const PersonalIdentityType = ({ legalRepresentativeIndex }: { legalRepresentativeIndex: number }) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[legalRepresentativeIndex]?.identificationType as FieldError | undefined;
  const personalIdType = watch(`representatives.${legalRepresentativeIndex}.identificationType`);
  const intl = useIntl();
  const helperText = personalIdType && personalIdType in allPersonalIds 
    ? intl.formatMessage({
      id: `settings.payments.checkout.onboarding.representatives.identificationType.${personalIdType as SupportedPersonalIdType}.helper`,
    })
    : '';
  return (
    <TextField
      fullWidth
      select
      required
      defaultValue={personalIdType || ''}
      error={!!error}
      id="country-of-birth-select"
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationType' })}
      InputLabelProps={{ shrink: true }}
      inputProps={register(`representatives.${legalRepresentativeIndex}.identificationType`, {
        required: intl.formatMessage({
          id: 'settings.payments.checkout.onboarding.representatives.identificationType.error.required',
        }),
      })}
      helperText={error?.message || helperText}
    >
      {allPersonalIds.map((type) => (
        <MenuItem key={type} value={type}>
          <FormattedMessage id={`settings.payments.checkout.onboarding.representatives.identificationType.${type}`} />
        </MenuItem>
      ))}
    </TextField>
  );
};
