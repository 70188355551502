import { z } from 'zod';

const EnrollmentConfigurationSchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('PosPooled') }),
  z.object({
    type: z.literal('SundayTipping'),
    waiterRate: z.number(),
  }),
  z.object({
    type: z.literal('DirectTipping'),
    waiterRate: z.number(),
    onboardingStrategy: z.string(),
  }),
]);

export type EnrollmentTippingConfiguration = z.infer<typeof EnrollmentConfigurationSchema>;

const PosPooledConfigurationSchema = z.object({
  type: z.literal('PosPooled'),
});

export type PosPooledConfiguration = z.infer<typeof PosPooledConfigurationSchema>;

const SundayTippingConfigurationSchema = z.object({
  type: z.literal('SundayTipping'),
  waiterRate: z.number(),
});

export type SundayTippingConfiguration = z.infer<typeof SundayTippingConfigurationSchema>;

const POCTippingConfigurationSchema = z.object({
  type: z.literal('POC'),
  pat: EnrollmentConfigurationSchema.optional(),
  pdq: EnrollmentConfigurationSchema.optional(),
});

export type POCTippingConfiguration = z.infer<typeof POCTippingConfigurationSchema>;

export const TippingConfigurationSchema = z.discriminatedUnion('type', [
  PosPooledConfigurationSchema,
  SundayTippingConfigurationSchema,
  POCTippingConfigurationSchema,
]);

export type TippingConfiguration = z.infer<typeof TippingConfigurationSchema>;
