import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { getSpacing } from 'src/ordering/stylesheet';
import Input from '../../../../../common/components/Input';

type UpdatePacingSlotsProps = {
  pacingSlots: number,
  setPacingSlots: (value: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(2)};
`;

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

export const UpdatePacingInPreparationSlots = ({ pacingSlots, setPacingSlots } : UpdatePacingSlotsProps) => {
  const [value, setValue] = useState<string>(pacingSlots.toString());

  const isUpdated = value !== pacingSlots.toString();

  return (
    <Container>
      <Input type="number" value={value} onValueChange={setValue} />
      {isUpdated && (
        <Line>
          <SaveButton variant="primary" size="xSmall" onClick={() => setPacingSlots(value)}>
            <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
          </SaveButton>
        </Line>
      )}
    </Container>
  );
};
