import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { getSpacing } from '../../../../stylesheet';
import { useUpdateAreaConfigurations } from '../../../hooks';
import { Area } from '../../../domain/Area';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { DeleteServiceWarningModal } from './DeleteServiceWarningModal';

type DeleteServiceButtonProps = {
  area: Area;
  setArea: (area: Area) => void;
  currentService: FulfillmentType;
  onServiceDeleted: () => void;
  hasMenus: boolean;
};

const WarningButtonAnchor = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${getSpacing(3)};
  padding-right: ${getSpacing(3)};
`;

export const DeleteServiceButton = ({
  area,
  setArea,
  currentService,
  onServiceDeleted,
  hasMenus,
}: DeleteServiceButtonProps) => {
  const [updateAreaConfigurationState, updateAreaConfigurations] = useUpdateAreaConfigurations();
  const [showModal, updateModalVisibility] = useState<boolean>(false);

  const onRemoveService = async () => {
    const otherConfigurations = area.configurations.filter((config) => config.fulfillmentMethod !== currentService);
    await updateAreaConfigurations(area.id, otherConfigurations);

    setArea({ ...area, configurations: otherConfigurations });
    onServiceDeleted();
    updateModalVisibility(false);
  };

  if (!hasMenus) {
    return <></>;
  }

  return (
    <WarningButtonAnchor>
      <Button
        variant="warning"
        size="medium"
        fullWidth
        onClick={() => updateModalVisibility(true)}
        disabled={updateAreaConfigurationState.loading}
      >
        <FormattedMessage id="settings.area.details.service-menus.delete-btn" defaultMessage="delete service" />
      </Button>
      {showModal && (
        <DeleteServiceWarningModal
          isOpen
          onConfirm={onRemoveService}
          onCancel={() => updateModalVisibility(false)}
        />
      )}
    </WarningButtonAnchor>
  );
};
