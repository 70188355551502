import React, { ReactNode } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { palette } from '../design/palette';
import { dimensionsPx } from '../design/dimensions';

const SectionTitle = ({ title, imagePath }: { title: string; imagePath: string }) => (
  <>
    <Stack display="flex" flexDirection="row" alignItems="center" gap={2}>
      <img height={dimensionsPx.line_height.xl} width={dimensionsPx.line_height.xl} src={imagePath} alt="" />
      <Typography fontSize={dimensionsPx.fonts.xl}>{title}</Typography>
    </Stack>
  </>
);

export const SectionComponent = ({
  title,
  imagePath,
  children,
  legend,
  breakBefore = false,
}: {
  title: string;
  imagePath: string;
  legend?: string;
  children: ReactNode;
  breakBefore?: boolean;
}) => (
  <>
    <Box
      style={{ backgroundColor: `${palette.primary.white}`, breakBefore: breakBefore ? 'page' : 'avoid' }}
      pt={dimensionsPx.section_separator.l}
    >
      <SectionTitle title={title} imagePath={imagePath} />
      {legend && (
      <Typography fontSize={dimensionsPx.fonts.s} color={palette.neutral.grey500} paddingBottom="8px">
        {legend}
      </Typography>
      )}
      {children}
    </Box>
  </>
);
