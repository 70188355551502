import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Stack, Typography, useTheme } from '@mui/material';

import { extractSiren } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/extractSiren';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const ShareholderStructureDocument = () => {
  const {
    formState: { errors },
    register,
    watch,
    setValue,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const business = useCurrentBusinessOrThrow();
  const error = errors.shareholderStructureDocument;
  const shareholderStructureDocument = watch('shareholderStructureDocument');
  const intl = useIntl();
  const theme = useTheme();
  const siret = watch('businessRegistrationNumber');

  const url = `https://www.infogreffe.fr/entreprise/${extractSiren(siret)}`;

  const onFileUploaded = (documentId: string) => {
    setValue('shareholderStructureDocument', documentId);
  };
  const onDelete = () => {
    setValue('shareholderStructureDocument', '');
  };

  return (
    <Stack>
      <UploadDocument
        onRegistered={register('shareholderStructureDocument', {
          required: {
            value: business.address.countryCode === 'GB',
            message: intl.formatMessage({
              id: 'settings.payments.checkout.onboarding.shareholderStructureDocument.error',
            }),
          },
        })}
        onFileUploaded={onFileUploaded}
        onDelete={onDelete}
        documentId={shareholderStructureDocument}
        error={error}
        documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.shareholderStructureDocument' })}
        purpose={'shareholder_structure'}
        helperText={''}
      />
      <Typography variant={'body2'} color={theme.palette.text.secondary}>
        <FormattedMessage
          id={'settings.payments.checkout.onboarding.shareholderStructureDocument.helperText'}
          values={{
            link: (
              <a href={url} target="_blank" rel="noreferrer">
                {url}
              </a>
            ),
          }}
        />
      </Typography>
    </Stack>
  );
};
