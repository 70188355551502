import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { Card, CardContent, Select, SelectChangeEvent, Stack, Typography, useTheme } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { SettingContainer, TextContainer } from './ReviewsSettings.styles';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useUpdateGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useUpdateGlobalConfiguration';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { GoogleReviewSetupTile } from './setup/google/components/GoogleReviewSetupTile';

export const ExternalReviewsSettings = () => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const { data: venueConfiguration } = useGlobalConfiguration();
  const externalSiteRatingThreshold =
    venueConfiguration?.entries.get(VenueConfigurationKey.externalSiteRatingThreshold) ?? '1';

  const { updateGlobalConfiguration } = useUpdateGlobalConfiguration();
  const theme = useTheme();

  const onChange = (thresholdChanged: string) => {
    updateGlobalConfiguration(
      new Map<VenueConfigurationKey, any>([[VenueConfigurationKey.externalSiteRatingThreshold, thresholdChanged]]),
    ).then(() =>
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.reviews_and_loyalty.success_saving' }),
      }),
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Stack direction="column" gap={2}>
            <GoogleReviewSetupTile />
            <SettingContainer>
              <TextContainer>
                <Typography variant="h6">
                  <FormattedMessage id="settings.reviews_and_loyalty.minimum_rating" />
                </Typography>
                <Typography variant="caption" color={theme.palette.text.disabled}>
                  <FormattedMessage id="settings.reviews_and_loyalty.minimum_rating.description" />
                </Typography>
              </TextContainer>
              <Select
                value={externalSiteRatingThreshold}
                onChange={(event: SelectChangeEvent) => onChange(event.target.value)}
              >
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
              </Select>
            </SettingContainer>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
