import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { QRCodeSVG } from 'qrcode.react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Table } from '../../../../venues/domain/Table';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { SundayDevice } from '../../../domain/SundayDevice';
import { AreaDetailsTableModal } from './AreaDetailsTableModal';

type AreaDetailsQRTableDisplayProps = {
  table: Table;
  device: SundayDevice | undefined;
};

type ContainerProps = {
  hasDevice: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  padding-right: ${getSpacing(2)};
  flex-direction: row;
  margin-bottom: ${getSpacing(3)};
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  cursor: ${(props) => (props.hasDevice ? 'pointer' : '')};
`;

const TableInfo = styled.div`
  display: flex;
  align-items: center;
`;

const QRCodeContainer = styled.div`
  margin-right: ${getSpacing(1)};
`;

const TableName = styled.div``;

const Status = styled.div`
  color: ${colorPalette.grey500};
`;

export const AreaDetailsQRTableDisplay = ({ table, device }: AreaDetailsQRTableDisplayProps) => {
  const intl = useIntl();
  const [openModal, updateModal] = useState<boolean>(false);

  const statusMessage = useMemo(() => {
    if (device) {
      return intl.formatMessage({
        id: 'settings.area.details.service-at-table.see-all-table.status.linked',
        defaultMessage: 'linked',
      });
    }

    return intl.formatMessage({
      id: 'settings.area.details.service-at-table.see-all-table.status.not-linked',
      defaultMessage: 'no qr code',
    });
  }, [intl, device]);

  const onItemClick = () => {
    if (device) {
      updateModal(true);
    }
  };

  return (
    <Container hasDevice={!!device} onClick={onItemClick}>
      <TableInfo>
        {device && (
          <QRCodeContainer>
            <QRCodeSVG value={device.url} size={32} fgColor="#000000" level="L" />
          </QRCodeContainer>
        )}
        <TableName>{table.name}</TableName>
      </TableInfo>
      <Status>{statusMessage}</Status>
      {openModal && device && (
        <AreaDetailsTableModal tableName={table.name} device={device} onClosed={() => updateModal(false)} />
      )}
    </Container>
  );
};
