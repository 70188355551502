import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import CircleIcon from '@mui/icons-material/Circle';
import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import { formatNumber } from '../../../components/formatNumbers';
import { colorPalette } from 'src/ordering/stylesheet';
import { DimensionIcon } from '../components/DimensionIcon';
import { basicDimensionsColors } from './AverageDimensionsRatingsChart';

interface Props {
  dimension: DimensionAnalysis;
  onSelectedDimension: () => void;
  selected: boolean;
}

interface DimensionCardContainerProps {
  selected: boolean;
}
const DimensionCardContainer = styled(Box)<DimensionCardContainerProps>`
  padding: 1rem;
  border: ${(props) => (props.selected ? 'none' : `1px solid ${colorPalette.grey300}`)};
  border-radius: 1rem;
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? props.theme.palette.background.default : props.theme.palette.background.paper};
`;

export const DimensionCard = ({ dimension, onSelectedDimension, selected }: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <DimensionCardContainer onClick={onSelectedDimension} selected={selected}>
      <Stack direction="column" justifyContent="space-between">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" marginBottom="1rem" alignItems="center" gap={1}>
            <DimensionIcon dimension={dimension.name} />
            <Typography variant="subtitle1" color={theme.palette.text.secondary}>
              <FormattedMessage id={`review.dimensions.${dimension.name}`} />
            </Typography>
          </Stack>
          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
          <CircleIcon
            sx={{
              color: Object.entries(basicDimensionsColors).find(([key]) => key === dimension.name),
              width: '5%',
            }}
          />
        </Stack>

        <Stack direction="column" gap="0.5rem">
          <Typography variant="h5">
            {formatNumber(dimension.averageRating ?? 0, 2)}
            /5
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            {dimension.numberOfReviews} {intl.formatMessage({ id: 'review.dimensions.avis' })} |{' '}
            {dimension.badReviews.length} {intl.formatMessage({ id: 'review.dimensions.avis_with_problems' })}
            {dimension.badReviews.length > 1 ? 's' : ''}
          </Typography>
        </Stack>
      </Stack>
    </DimensionCardContainer>
  );
};
