import React, { ReactNode, useCallback } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { colorPalette, getSpacing, zIndex } from '../../../stylesheet';

export type ContextMenuLabelItem = {
  type: 'label';
  icon: ReactNode;
  label: string;
  variant: 'default' | 'danger';
  onClick: () => void;
};

export type ContextMenuSeparator = {
  type: 'separator';
};

export type ContextMenuItem = ContextMenuLabelItem | ContextMenuSeparator;

export type ContextMenuProps = {
  isOpened: boolean;
  onClosed: () => void;
  items: ContextMenuItem[];
};

const Panel = styled.div`
  position: absolute;
  padding-top: ${getSpacing(2)};
  padding-bottom: ${getSpacing(1)};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
  z-index: ${zIndex.SELECT_OPTIONS};
  right: 100%;
  top: ${getSpacing(4)};
`;

const Anchor = styled.div`
  position: relative;
`;

type OptionLineProps = {
  variant: 'default' | 'danger';
};

const OptionLine = styled.div<OptionLineProps>`
  display: flex;
  padding-left: ${getSpacing(2)};
  padding-right: ${getSpacing(2)};
  color: ${(props) => (props.variant === 'danger' ? colorPalette.red : '')};
  white-space: nowrap;
  align-items: center;
  cursor: pointer;

  :hover {
    background-color: ${colorPalette.grey100};
  }
`;

const Label = styled.div`
  display: flex;
`;

const Separator = styled.div`
  margin-left: ${getSpacing(2)};
  margin-right: ${getSpacing(2)};
  border-bottom: 1px solid ${colorPalette.grey300};
  margin-bottom: ${getSpacing(1)};
  padding-bottom: ${getSpacing(1)};
`;

const IconWrapper = styled.div`
  display: flex;
  margin-right: ${getSpacing(1)};
  align-items: center;
  justify-content: center;
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
`;

const OutsideDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  left: 0;
  top: 0;
`;

export const ContextMenu = ({ isOpened, onClosed, items }: ContextMenuProps) => {
  const renderLabelItem = useCallback((item: ContextMenuLabelItem) => (
    <OptionLine key={uuidv4()} variant={item.variant ?? 'default'} onClick={item.onClick}>
      {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
      <Label>{item.label}</Label>
    </OptionLine>
  ), []);

  const renderSeparatorItem = useCallback(() => <Separator />, []);

  const renderItem = useCallback(
    (item: ContextMenuItem) => {
      if (item.type === 'separator') {
        return renderSeparatorItem();
      }

      return renderLabelItem(item);
    },
    [renderLabelItem, renderSeparatorItem],
  );

  if (!isOpened) {
    return <></>;
  }

  return (
    <>
      <OutsideDiv onClick={onClosed} />
      <Anchor>
        <Panel>{items.map((item) => renderItem(item))}</Panel>
      </Anchor>
    </>
  );
};
