import { useFixedLocalStorage } from 'src/store/localStorage/useFixedLocalStorage';

export interface DebugLogger {
  log: (message: string, ...args: any[]) => void;
}

const nullLogger = {
  log: () => {},
};

export function useDebugLogger(): DebugLogger {
  const [isDebugLoggerEnabled] = useFixedLocalStorage<boolean>('debug_logger', false);
  if (!isDebugLoggerEnabled) {
    return nullLogger;
  }
  return console;
}
