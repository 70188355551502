import styled from 'styled-components';
import {
  colorUsage,
  fontSize,
  IconWrapper,
  PlusSvg,
  spaceUsages,
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  Text,
  UpDownArrowSvg,
} from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

type MenuTableHeaderProps = {
  showCreateButton: boolean;
  showDragButton: boolean;
  onCreateMenu: () => void;
  onUpdateMenuPriority: () => void;
};

const MenuHeaderItemLine = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  color: ${colorUsage.tertiaryText};
`;

const MenuHeaderColumn = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  font-size: ${fontSize.large};
`;

const MenuHeaderToolColumn = styled.div`
  display: flex;
  justify-content: end;
  min-width: ${spaceUsages.xLarge};
  max-width: ${spaceUsages.xLarge};
  gap: ${spaceUsages.mediumXLarge};
  padding-right: ${spaceUsages.largeSmall};
`;

const MenuTableHeader = ({
  showCreateButton, showDragButton, onCreateMenu, onUpdateMenuPriority,
}: MenuTableHeaderProps) => (
  <MenuHeaderItemLine>
    <MenuHeaderColumn>
      <Text size="large" marginBottom={spaceUsages.medium}>
        <FormattedMessage id="menus.table.header.pdf.title" defaultMessage="menus with pdf" />
      </Text>
    </MenuHeaderColumn>
    <MenuHeaderToolColumn>
      {showDragButton && (
        <IconWrapper size="small" cursor="pointer" onClick={() => onUpdateMenuPriority()}>
          <UpDownArrowSvg />
        </IconWrapper>
      )}
      {showCreateButton && (
        <IconWrapper size="small" cursor="pointer" onClick={() => onCreateMenu()}>
          <PlusSvg />
        </IconWrapper>
      )}
    </MenuHeaderToolColumn>
  </MenuHeaderItemLine>
);

export default MenuTableHeader;
