import React from 'react';
import { useRenderMoney } from '@sundayapp/web-money';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Grid, Stack, Typography } from '@mui/material';
import { hasOrderInfo, Review } from '../browse/domain/Review';
import { isValidMoney } from 'src/utils/MoneyUtils';

interface Props {
  review: Review;
}

export function ReviewOrderInfo({ review }: Props) {
  const renderMoney = useRenderMoney();

  return (
    <>
      {hasOrderInfo(review)
        && (
          <Grid container direction="row" spacing={1}>
            {review.tableName
              && (
                <Grid item>
                  <Stack direction="row" spacing={1}>
                    <Typography color="textSecondary">
                      <FormattedMessage id="venue.reviews.reply.table" defaultMessage="table" />
                      :
                    </Typography>
                    <Typography color="textPrimary">{review.tableName}</Typography>
                  </Stack>
                </Grid>
              )}
            <Grid item>
              {isValidMoney(review.paidAmount)
                && (
                  <Grid item>
                    <Stack direction="row" spacing={1}>
                      <Typography color="textSecondary">
                        <FormattedMessage id="venue.reviews.reply.amount_paid" defaultMessage="amount paid" />
                        :
                      </Typography>
                      <Typography color="textPrimary">{renderMoney(review.paidAmount)}</Typography>
                    </Stack>
                  </Grid>
                )}
            </Grid>
            <Grid item>
              {review.waiterName
                && (
                  <Grid item>
                    <Stack direction="row" spacing={1}>
                      <Typography color="textSecondary">
                        <FormattedMessage id="venue.reviews.reply.waiter_name" defaultMessage="served by" />
                        :
                      </Typography>
                      <Typography color="textPrimary">{review.waiterName}</Typography>
                    </Stack>
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
    </>
  );
}
