import MenuItem from './MenuItem';
import { StaticMenu } from '../../domain/StaticMenus';

export type MenuItemsProps = {
  menus: StaticMenu[];
};

const MenuItems = ({ menus = [] }: MenuItemsProps) => (
  <>
    {menus.map((menu) => (
      <MenuItem menu={menu} key={menu.id} />
    ))}
  </>
);

export default MenuItems;
