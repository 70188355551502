import { FulfillmentType } from '../domain/FulfillmentType';
import { OrderingMenu } from 'src/ordering/orderingMenus/domain/OrderingMenu';

export type ServiceMenuAssociation = {
  service: FulfillmentType;
  menus: OrderingMenu[];
};

export type ServiceMenuAssociations = {
  associations: ServiceMenuAssociation[];
};

export const EMPTY_MENU_ASSOCIATIONS: ServiceMenuAssociations = {
  associations: [],
};
