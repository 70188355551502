import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../../stylesheet';

export const AreaDetailsBoxSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(3)};
  padding-bottom: ${getSpacing(1)};
  border-radius: ${getSpacing(2)};
  border: 1px solid ${colorPalette.grey300};
  background-color: ${colorPalette.defaultBackgroundColor};
  margin-bottom: ${getSpacing(1)};
`;
