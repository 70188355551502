// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { Translation, translation } from '../../common/translation';

export type DateRange = {
  startDate: Moment;
  endDate: Moment;
};

export const revenueTabDatePresets = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS_FILTER: 'LAST_7_DAYS_FILTER',
  LAST_WEEK_FILTER: 'LAST_WEEK_FILTER',
  MONTH_TO_DATE_FILTER: 'MONTH_TO_DATE_FILTER',
  LAST_MONTH_FILTER: 'LAST_MONTH_FILTER',
  CURRENT_YEAR_FILTER: 'CURRENT_YEAR_FILTER',
  PREVIOUS_YEAR_FILTER: 'PREVIOUS_YEAR_FILTER',
  CUSTOM: 'CUSTOM',

} as const;

export type RevenueTabDatePreset = ValuesOf<typeof revenueTabDatePresets>;

export type Period = {
  filter: string;
  dateRange: DateRange;
};

export type RevenuePeriod = Period & {
  filter: RevenueTabDatePreset;
  dateRange: DateRange;
};
export const revenuesDatePresets: (timezone?: string) => Record<RevenueTabDatePreset, {
  range: DateRange,
  translation: Translation
}> = (timezone = Intl.DateTimeFormat()
  .resolvedOptions().timeZone) => {
  const now = (): Moment => moment()
    .tz(timezone);
  const shiftStartOf = (aMoment: Moment) => aMoment.hour(4);
  const shiftEndOf = (aMoment: Moment) => aMoment.hour(3)
    .minute(59)
    .second(59);

  return ({
    [revenueTabDatePresets.TODAY]: {
      range: {
        startDate: shiftStartOf(now()
          .startOf('day')),
        endDate: shiftEndOf(now()
          .add(1, 'days')),
      },
      translation: translation('accounting.revenue_tab.today_filter'),
    },
    [revenueTabDatePresets.YESTERDAY]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'd')
          .startOf('day')),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.revenue_tab.yesterday_filter'),
    },
    [revenueTabDatePresets.LAST_7_DAYS_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(7, 'd')
          .startOf('day')),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.payout_tab.last_7_days_filter'),
    },
    [revenueTabDatePresets.LAST_WEEK_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'week')
          .startOf('isoWeek')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .subtract(1, 'week')
          .endOf('isoWeek')
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.last_week_filter'),
    },
    [revenueTabDatePresets.MONTH_TO_DATE_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .startOf('month')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.month_to_date_filter'),
    },
    [revenueTabDatePresets.LAST_MONTH_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'month')
          .startOf('month')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .subtract(1, 'month')
          .endOf('month')
          .endOf('day')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.last_month_filter'),
    },
    [revenueTabDatePresets.CURRENT_YEAR_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .startOf('year')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .endOf('day')),
      },
      translation: translation('accounting.payout_tab.current_year_filter', {
        currentYear: now()
          .year(),
      }),
    },
    [revenueTabDatePresets.PREVIOUS_YEAR_FILTER]: {
      range: {
        startDate: shiftStartOf(now()
          .subtract(1, 'year')
          .startOf('year')
          .startOf('day')),
        endDate: shiftEndOf(now()
          .subtract(1, 'year')
          .endOf('year')
          .add(1, 'days')),
      },
      translation: translation('accounting.payout_tab.previous_year_filter', {
        previousYear: now()
          .subtract(1, 'year')
          .year(),
      }),
    },
    [revenueTabDatePresets.CUSTOM]: {
      range: {
        startDate: shiftStartOf(now()),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.revenue_tab.custom_filter'),
    },
  });
};
