import { styled } from '@mui/material';

export type MultiLocationsItemProps = {
  isCurrent?: boolean;
  isCompact?: boolean;
  iconSize?: number;
};
type ItempProp = {
  isCurrent: boolean;
};
export const Item = styled('div')<ItempProp>(({ isCurrent }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  gap: '12px',
  alignItems: 'center',
  maxWidth: isCurrent ? '160px' : '100%',
}));

export const Name = styled('span')`
  white-space: break-spaces;
`;

export const CurrentName = styled(Name)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
