import { CurrencyCode, Money } from '@sundayapp/web-money';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { zero } from './Payments';
import { products, Products } from './products';

export interface Reporting {
  name: string;
  numberOfPayment: number;
  product?: Products;
  revenue: Money;
  tips: Money;
  sundayTips: Money;
  serviceCharges: Money;
  total: Money;
  details: Reporting[];
}

export const oneReporting = (name: string, currency: CurrencyCode, one: Reporting): Reporting => {
  return {
    name,
    numberOfPayment: one.numberOfPayment,
    product: one.product,
    revenue: one.revenue,
    tips: one.tips,
    sundayTips: one.sundayTips,
    serviceCharges: one.serviceCharges,
    total: safeSumMoney([one.revenue, one.serviceCharges, one.tips], currency),
    details: [one],
  };
};

export const sumReportings = (name: string, currency: CurrencyCode, reportings: Reporting[]): Reporting => {
  const reportingsRevenues = reportings.map(reporting => {
    return reporting.revenue;
  });
  const reportingsTips = reportings.map(reporting => {
    return reporting.tips;
  });
  const reportingsSundayTips = reportings.map(reporting => {
    return reporting.sundayTips;
  });
  const reportingsServiceCharges = reportings.map(reporting => {
    return reporting.serviceCharges;
  });
  const numberOfPayment = reportings.reduce((acc, reporting) => acc + reporting.numberOfPayment, 0);

  const revenues = safeSumMoney(reportingsRevenues, currency);
  const tips = safeSumMoney(reportingsTips, currency);
  const sundayTips = safeSumMoney(reportingsSundayTips, currency);
  const serviceCharges = safeSumMoney(reportingsServiceCharges, currency);

  return {
    name,
    numberOfPayment,
    product: reportings[0].product,
    revenue: revenues,
    tips,
    sundayTips,
    serviceCharges,
    total: safeSumMoney([revenues, serviceCharges, tips], currency),
    details: reportings,
  };
};

export type ReportingDigest = {
  patPaymentMethod: Reporting;
  tpePaymentMethod: Reporting;
  waitersProducts: Reporting[];
};

export const emptyPdqReporting = (name: string, currency: CurrencyCode): Reporting => ({
  name,
  numberOfPayment: 0,
  product: products.PDQ,
  revenue: zero(currency),
  tips: zero(currency),
  sundayTips: zero(currency),
  serviceCharges: zero(currency),
  total: zero(currency),
  details: [],
});

export const emptyPatReporting = (name: string, currency: CurrencyCode): Reporting => ({
  name,
  numberOfPayment: 0,
  product: products.PAT,
  revenue: zero(currency),
  tips: zero(currency),
  sundayTips: zero(currency),
  serviceCharges: zero(currency),
  total: zero(currency),
  details: [],
});

export const emptyOapReporting = (name: string, currency: CurrencyCode): Reporting => ({
  name,
  numberOfPayment: 0,
  product: products.OAP,
  revenue: zero(currency),
  tips: zero(currency),
  sundayTips: zero(currency),
  serviceCharges: zero(currency),
  total: zero(currency),
  details: [],
});
