import { useDeleteCoverPicture } from './useDeleteCoverPicture';
import { useDeleteCoverBackgroundColor } from './useDeleteCoverBackgroundColor';
import { useDeleteLogo } from './useDeleteLogo';
import { useSaveCoverBackgroundColor } from './useSaveCoverBackgroundColor';
import { useSaveCoverPicture } from './useSaveCoverPicture';
import { useSaveCoverPictureOriginalUrl } from './useSaveCoverPictureOriginalUrl';
import { useSaveLogo } from './useSaveLogo';
import { useSaveLogoOriginalUrl } from './useSaveLogoOriginalUrl';
import { CustomizationUpdater } from '../domain/CustomizationUpdater';

export const useCustomizationUpdater: () => CustomizationUpdater = () => {
  const { deleteCoverBackgroundColor } = useDeleteCoverBackgroundColor();
  const { deleteCoverPicture } = useDeleteCoverPicture();
  const { deleteLogo } = useDeleteLogo();
  const { saveCoverBackgroundColor } = useSaveCoverBackgroundColor();
  const { saveCoverPicture } = useSaveCoverPicture();
  const { saveCoverPictureOriginalUrl } = useSaveCoverPictureOriginalUrl();
  const { saveLogo } = useSaveLogo();
  const { saveLogoOriginalUrl } = useSaveLogoOriginalUrl();
  return {
    deleteCoverBackgroundColor,
    deleteCoverPicture,
    deleteLogo,
    saveCoverBackgroundColor,
    saveCoverPicture,
    saveCoverPictureOriginalUrl,
    saveLogo,
    saveLogoOriginalUrl,
  };
};
