import { MenuRepository } from 'src/menu/dynamic-menu/repositories/MenuRepository';
import { BoxRepository } from 'src/ordering/box/repositories/BoxRepository';
import { MenuMigrationReview } from 'src/enterprise/configuration-replication/domain/menu/MenuReplicationState';

export class ApplyMenuChanges {

  constructor(
    private boxRepository: BoxRepository,
    private menuRepository: MenuRepository,
  ) {
  }

  async apply(currentState: MenuMigrationReview) {

    if (currentState.origin.oapEnrollment === undefined) {
      return ['Not O&P venue'];
    }

    const result = await Promise.all(
      currentState.targets.map(async targetBusiness => {

        const targetBoxes = targetBusiness.oapEnrollment ? await this.boxRepository.getVenueBoxes(targetBusiness.oapEnrollment.id) : [];

        if (targetBoxes.length > 1) {
          return ['Only one source box is allowed'];
        }

        const box = targetBoxes.length === 0 ? undefined : targetBoxes[0];
        const posConnectionId = box && box.posIntegration.type === 'DIRECT_INTEGRATION' ? box.posIntegration.posConnectionId : undefined;
        const revenueCenterId = box && box.posIntegration.type === 'DIRECT_INTEGRATION' ? box.posIntegration.externalRevenueCenterId : undefined;

        return this.menuRepository.migrateMenu(
          currentState.origin.id,
          currentState.menus.map(menu => menu.id),
          targetBusiness.id,
          posConnectionId,
          revenueCenterId,
        )
          .then(() => {
            return '';
          })
          .catch(() => {
            return 'Error while migrating menu(s) : [' + currentState.menus.map(menu => menu.name) + '] to ' + targetBusiness.name;
          });
      }),
    ).then(errors => {
      console.log(errors);
      return errors.flat();
    });

    return result;
  }
}
