/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function GenericCardIcon(props: PaymentMethodIconProps) {
  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="39" height="23" rx="2.5" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.834 7.66667C10.834 6.47005 11.804 5.5 13.0007 5.5H27.0006C28.1973 5.5 29.1673 6.47005 29.1673 7.66667V16.6667C29.1673 17.8633 28.1973 18.8333 27.0006 18.8333H13.0007C11.804 18.8333 10.834 17.8633 10.834 16.6667V7.66667ZM11.834 7.66667C11.834 7.02233 12.3563 6.5 13.0007 6.5H27.0006C27.645 6.5 28.1673 7.02233 28.1673 7.66667V9.5H11.834V7.66667ZM28.1673 10.5V16.6667C28.1673 17.311 27.645 17.8333 27.0006 17.8333H13.0007C12.3563 17.8333 11.834 17.311 11.834 16.6667V10.5H28.1673Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.334 7.66667C10.334 6.19391 11.5279 5 13.0007 5H27.0006C28.4734 5 29.6673 6.19391 29.6673 7.66667V16.6667C29.6673 18.1394 28.4734 19.3333 27.0006 19.3333H13.0007C11.5279 19.3333 10.334 18.1394 10.334 16.6667V7.66667ZM13.0007 7C12.6325 7 12.334 7.29848 12.334 7.66667V9H27.6673V7.66667C27.6673 7.29848 27.3688 7 27.0006 7H13.0007ZM11.834 10C11.5578 10 11.334 9.77614 11.334 9.5V10.5C11.334 10.2239 11.5578 10 11.834 10ZM28.6673 9.5C28.6673 9.77614 28.4435 10 28.1673 10C28.4435 10 28.6673 10.2239 28.6673 10.5V9.5ZM12.334 11V16.6667C12.334 17.0349 12.6325 17.3333 13.0007 17.3333H27.0006C27.3688 17.3333 27.6673 17.0349 27.6673 16.6667V11H12.334Z"
        fill="black"
      />
    </svg>

  );
}
