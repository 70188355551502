import React, { createContext, ReactNode, useMemo } from 'react';
import { BoxConfig } from './BoxConfig';
import { BoxRepositoryHttp } from '../repositories/BoxRepositoryHttp';

type BoxContextProviderProps = {
  orderingBackendUrl: string;
  children: ReactNode;
};

export const BoxConfigContext = createContext<Partial<BoxConfig>>({});

type ProviderConfig = BoxConfig & {
  children: ReactNode;
};

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => <BoxConfigContext.Provider value={config}>{children}</BoxConfigContext.Provider>;

export const BoxContextProvider = ({ orderingBackendUrl, children }: BoxContextProviderProps) => {
  // Repo
  const boxRepository = useMemo(() => new BoxRepositoryHttp(orderingBackendUrl), [orderingBackendUrl]);

  return <Provider boxRepository={boxRepository}>{children}</Provider>;
};
