import { pixels, Size, sizeInCss } from './Size';

export type Spacing = AllAround | XYSpacing | SpecificSpacing;

enum SpacingType {
  AllAround = 'AllAround',
  XYSpacing = 'XYSpacing',
  SpecificSpacing = 'SpecificSpacing',
}

type AllAround = {
  readonly type: SpacingType.AllAround;
  readonly value: Size;
};
type XYSpacing = {
  readonly type: SpacingType.XYSpacing;
  readonly vertical: Size;
  readonly horizontal: Size;
};
type SpecificSpacing = {
  readonly type: SpacingType.SpecificSpacing;
  readonly top: Size;
  readonly bottom: Size;
  readonly left: Size;
  readonly right: Size;
};

const allAroundInCss = (spacing: AllAround) => sizeInCss(spacing.value);

const xySpacingInCss = (spacing: XYSpacing) => `${sizeInCss(spacing.vertical)} ${sizeInCss(spacing.horizontal)}`;

const specificSpacingInCss = (spacing: SpecificSpacing) => `${sizeInCss(spacing.top)} ${sizeInCss(spacing.right)} ${sizeInCss(spacing.bottom)} ${sizeInCss(spacing.left)}`;

export const spacingInCss = (spacing: Spacing): string => {
  switch (spacing.type) {
    case SpacingType.AllAround:
      return allAroundInCss(spacing);
    case SpacingType.XYSpacing:
      return xySpacingInCss(spacing);
    case SpacingType.SpecificSpacing:
      return specificSpacingInCss(spacing);
  }
};
export const topSpacing = (top: Size): Spacing => ({
  type: SpacingType.SpecificSpacing,
  top,
  bottom: pixels(0),
  left: pixels(0),
  right: pixels(0),
});
