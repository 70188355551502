import React from 'react';
import { navigationDrawerItemKey, NavigationItem } from '../NavigationItems';
import { NavigationMenuGroup } from './NavigationMenuGroup';
import { NavigationMenuItem } from './NavigationMenuItem';

type NavigationMenuItemsProps = {
  menuItems: NavigationItem[];

  isMobile?: boolean;
  isCompacted: boolean;
  openedGroupKey: string | undefined;
  selectedGroupKey: string | undefined;

  onMenuGroupExpanded: (selection: NavigationItem | undefined) => void;
  onItemSelected: (item: NavigationItem) => void;
};

export const NavigationMenuItems = ({
  menuItems, isMobile = false, isCompacted, openedGroupKey, selectedGroupKey, onMenuGroupExpanded, onItemSelected,
}: NavigationMenuItemsProps) => {
  const buildItem = (menuItem: NavigationItem) => {
    const menuItemKey = navigationDrawerItemKey(menuItem);
    if (menuItem.subItems && menuItem.subItems?.length > 0) {
      return (
        <NavigationMenuGroup
          key={menuItemKey}
          menuItem={menuItem}
          compacted={isCompacted}
          isMobile={isMobile}
          isOpened={openedGroupKey === menuItemKey}
          isSelected={selectedGroupKey === menuItemKey}
          onGroupExpanded={onMenuGroupExpanded}
          onItemSelected={onItemSelected}
        />
      );
    }
    return (
      <NavigationMenuItem
        key={menuItemKey}
        menuItem={menuItem}
        compacted={isCompacted}
        isMobile={isMobile}
        rootItem
        onItemSelected={onItemSelected}
      />
    );
  };

  return (
    <>
      {menuItems.map((menuItem) => buildItem(menuItem))}
    </>
  );
};
