import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Row } from '../../Row';
import { formatCreationDate } from '../browse/allReviews/components/ReviewDates';

export function SundaySubscriptionDate({ subscriptionDate }: { subscriptionDate: number }) {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <Row alignItems="baseline">
        <Typography color="textSecondary">
          <FormattedMessage id="reviews.overview.start.date" />
        </Typography>
        &nbsp;
        <Typography>
          {formatCreationDate(subscriptionDate, intl.locale)}
        </Typography>
      </Row>
    </Grid>
  );
}
