import React from 'react';
import { CircularProgress, Stack } from '@mui/material';
import GuestConsentCSVExporterButton from './GuestConsentCSVExporterButton';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { CustomerData } from '../domain/CustomerData';
import { ConsentRepositoryHttp } from '../infrastructure/ConsentRepositoryHttp';
import { GuestConsentDataGrid } from '../components/GuestConsentDataGrid';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ConsentCollectionToggle } from 'src/marketing/customer-data/components/ConsentCollectionToggle';
import { useGuestDataCollectionQuery } from 'src/marketing/customer-data/hooks/useGuestDataCollectionQuery';
import { OptInLevelDefinition } from '../components/OptInLevelDefinition';
import { useAsync } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';

const configuration = ConfigurationLoader.load();
const repository = new ConsentRepositoryHttp(configuration.consentManagerBaseUrl);

export const CustomerDataPage = () => {
  const queryClient = useQueryClient();
  const { id: businessId, name } = useCurrentBusinessOrThrow();
  const { data: consentCollection, isLoading, isFetching } = useGuestDataCollectionQuery(businessId);

  const updateConsentCollection = async (active: boolean) => {
    if (active) await repository.activateGuestDataCollection(businessId);
    if (!active) await repository.deactivateGuestDataCollection(businessId);
    await queryClient.invalidateQueries({ queryKey: [queryKeys.GUEST_DATA_COLLECTION, businessId] });
  };

  const { value: customerData, loading: loadingCustomerData } = useAsync(async (): Promise<CustomerData[]> => {
    if (!consentCollection) return [];
    return repository.listVenueConsent(businessId);
  }, [consentCollection]);

  if (isLoading || !consentCollection) return <CircularProgress color={'secondary'} />;

  return (
    <Stack spacing={2}>
      <ConsentCollectionToggle toggleActivated={consentCollection.active}
                               toggleConsentCollection={updateConsentCollection}
                               processing={isFetching}
      />

      {consentCollection.active && (
        <>
          <OptInLevelDefinition />
          <GuestConsentDataGrid customerData={customerData} loading={loadingCustomerData} />
          <GuestConsentCSVExporterButton venueName={name} customerDataList={customerData || []} />
        </>
      )}
    </Stack>
  );
};

