import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { GenericCardIcon } from './GenericCardIcon';
import { MerchantCreditIcon } from './MerchantCreditIcon';
import { AccountingAdjustmentIcon } from './AccountingAdjustmentIcon';

const otherRevenueNames = {
  merchantCredit: 'merchantCredit',
  adjustment: 'adjustment',
  generic: 'generic',
} as const;

export type OtherRevenueName = ValuesOf<typeof otherRevenueNames>;

const isOtherRevenues = (name: string): name is OtherRevenueName => name in otherRevenueNames;

type OtherRevenueSize = 'm' | 'l' | 'xl';

function getRatioFromSize(size: OtherRevenueSize) {
  // eslint-disable-next-line no-nested-ternary
  return size === 'm' ? 1 : size === 'l' ? 1.2 : 1.5;
}

interface WrapperOtherRevenueIconProps {
  name: OtherRevenueName;
  size?: OtherRevenueSize;
}

type OtherRevenueComponent = React.FC<{ width: number; height: number }>;

const OtherRevenuesIcons: Record<OtherRevenueName, { component: OtherRevenueComponent; width: number; height: number }> = {
  merchantCredit: {
    component: MerchantCreditIcon,
    width: 40,
    height: 24,
  },
  adjustment: {
    component: AccountingAdjustmentIcon,
    width: 40,
    height: 24,
  },
  generic: {
    component: GenericCardIcon,
    width: 40,
    height: 24,
  },
};

const iconFromName = (name: string): { component: OtherRevenueComponent, width: number, height: number } => {
  if (isOtherRevenues(name)) {
    return OtherRevenuesIcons[name];
  }
  return OtherRevenuesIcons.generic;
};

const Wrapper = ({ size = 'm', children }: PropsWithChildren<{ size: WrapperOtherRevenueIconProps['size'] }>) => (
  <Box sx={{
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32 * (size === 'm' ? 1 : getRatioFromSize(size)),
    height: 20 * (size === 'm' ? 1 : getRatioFromSize(size)),
    borderRadius: '3px',
    border: '1px solid #eff0f4',
    backgroundColor: 'white',
  }}
  >
    {children}
  </Box>
);

export function OtherRevenueIcon({ name, size = 'm' }: WrapperOtherRevenueIconProps) {
  const icon = iconFromName(name);
  const Icon = icon.component;
  const ratio = getRatioFromSize(size);
  return <Wrapper size={size}><Icon width={icon.width * ratio} height={icon.height * ratio} /></Wrapper>;
}
