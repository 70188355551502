import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { BillReceipt, BillReceiptRepository } from 'src/domain/payment/BillReceipt';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { HttpOrderingReceiptRepository } from './HttpOrderingReceiptRepository';
import { Payment } from 'src/payments/domain/Payment';
import { BusinessId } from 'src/business/domain/Business';
import { ReceiptDisplaySettings } from 'src/payments/domain/ReceiptDisplaySettings';

class RestBillReceiptRepository implements BillReceiptRepository {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios;
  }

  async downloadBillReceipt(paymentId: string): Promise<BillReceipt> {
    const response = await this.httpClient.post(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/receipts/${paymentId}`,
      [],
      {
        responseType: 'blob',
      },
    );

    return new Blob([response?.data], { type: 'application/pdf' });
  }

  async downloadBillReceiptV2(paymentId: string): Promise<BillReceipt> {
    const response = await this.httpClient.get(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/receipts/v2/payments/${paymentId}`,
      { responseType: 'blob' },
    );

    return new Blob([response?.data], { type: 'application/pdf' });
  }

  async sendBillReceipt(paymentId: string, email: string, promotionMessage: string): Promise<void> {
    const encodedEmail = encodeURIComponent(email);
    return this.httpClient.post(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/receipts/${paymentId}/send-by-email-v2?email=${encodedEmail}`,
      {
        formData: [],
        promotion: { message: promotionMessage },
      },
    );
  }

  async getReceiptDisplaySettings(businessId: BusinessId): Promise<ReceiptDisplaySettings> {
    return this.httpClient.get(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/businesses/${businessId.toString()}/display-settings`,
    ).then((response: AxiosResponse) => ({
      shouldForceCollapseTaxesOnReceipt: response.data.shouldCollapseTaxes,
    }));
  }

  async updateReceiptDisplaySettings(businessId: BusinessId, updatedSettings: Partial<ReceiptDisplaySettings>): Promise<void> {
    return this.httpClient.post(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/businesses/${businessId.toString()}/display-settings`,
      {
        shouldCollapseTaxes: updatedSettings?.shouldForceCollapseTaxesOnReceipt,
      },
    );
  }

  async sendBillReceiptV2(
    paymentId: string,
    email: string,
    guestsNumber: number | undefined,
    guestsNames: string | undefined,
    businessPurpose: string | undefined,
    companyName: string | undefined,
    companyAddress: string | undefined,
    companyZipCode: string | undefined,
    companyCity: string | undefined,
    companyCountry: string | undefined,
  ): Promise<void> {
    const encodedEmail = encodeURIComponent(email);
    return this.httpClient.post(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/receipts/v2/payments/${paymentId}/send-expense-note-to-email?email=${encodedEmail}`,
      {
        guestsNumber,
        guestsNames,
        businessPurpose,
        company: {
          name: companyName,
          address: {
            streetName: companyAddress,
            zipCode: companyZipCode,
            city: companyCity,
            country: companyCountry,
          },
        },
      },
    );
  }

  async sendRefundReceiptV2(businessId: string, email: string, issuedAt: number, amount: number, currency: string): Promise<void> {
    return this.httpClient.post(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/receipts/v2/refunds/send-to-email`,
      {
        businessId: businessId,
        email: email,
        issuedAt: issuedAt,
        amount: {
          amount: amount,
          currency: currency,
        },
      },
    );
  }
}

export const httpBillReceiptRepository = new RestBillReceiptRepository();
export const httpOrderingReceiptRepository = new HttpOrderingReceiptRepository();

export const useSendBillReceipt = (serviceType: ServiceType) => {
  if (serviceType === ServiceType.ORDER_AND_PAY) {
    return (
      payment: Payment | null,
      email: string,
      guestsNumber: number | undefined,
      guestsNames: string | undefined,
      businessPurpose: string | undefined,
      companyName: string | undefined,
      companyAddress: string | undefined,
      companyZipCode: string | undefined,
      companyCity: string | undefined,
      companyCountry: string | undefined,
      orderId ?: string,
    ): Promise<void> => {
      const opOrderId = payment?.orderId ?? orderId;
      if (!opOrderId) {
        return Promise.reject();
      }
      return httpOrderingReceiptRepository.sendBillReceipt(opOrderId, email);
    };
  }

  return (
    payment: Payment | null,
    email: string,
    guestsNumber: number | undefined,
    guestsNames: string | undefined,
    businessPurpose: string | undefined,
    companyName: string | undefined,
    companyAddress: string | undefined,
    companyZipCode: string | undefined,
    companyCity: string | undefined,
    companyCountry: string | undefined,
  ): Promise<void> => {
    if (!payment) {
      return Promise.reject();
    }
    return httpBillReceiptRepository.sendBillReceiptV2(
      payment.id,
      email,
      guestsNumber,
      guestsNames,
      businessPurpose,
      companyName,
      companyAddress,
      companyZipCode,
      companyCity,
      companyCountry,
    );
  };
};

export const useSendRefundReceipt = () => {
  return (businessId: BusinessId, email: string, issuedAt: number, amount: number, currency: string): Promise<void> =>
    httpBillReceiptRepository.sendRefundReceiptV2(businessId.toString(), email, issuedAt, amount, currency);
};
