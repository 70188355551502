import React from 'react';
import { EReputationLoader } from './EReputationLoader';
import { RequireConnectedGoogleAccount } from '../settings/setup/google/components/RequireConnectedGoogleAccount';

export function Insights() {
  return (
    <RequireConnectedGoogleAccount>
      <EReputationLoader />
    </RequireConnectedGoogleAccount>
  );
}
