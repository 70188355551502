import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Paper, Stack, TextField, Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { LoadingButton } from '@mui/lab';
import { Author } from './Author';
import { Platform, Review } from '../browse/domain/Review';
import { ReviewStarRating } from '../ReviewStarRating';
import { ReviewDate } from './ReviewDate';
import { ReplyTemplate } from '../reply-template/domain/ReplyTemplate';
import { ReviewOrderInfo } from './ReviewOrderInfo';
import { SuggestReplyBasedOnOpenAI } from './SuggestReplyBasedOnOpenAI';
import { SuggestReplyBasedOnTemplate } from './SuggestReplyBasedOnTemplate';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { ampli, GoogleReviewRepliedProperties, SundayReviewRepliedProperties } from 'src/ampli';
import { Instant } from '../../Instant';
import { DimensionRating } from '../browse/domain/DimensionRating';
import { PlatformLogo } from '../browse/allReviews/components/reviewCard/PlatformLogo';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

const ratingOf = (review: Review, dimension: string) =>
  review.dimensionRatings.find((d: DimensionRating) => d.dimension === dimension)?.rating;

const trackInAmplitude = (
  platform: Platform,
  properties: GoogleReviewRepliedProperties & SundayReviewRepliedProperties,
) => {
  if (platform === 'google') {
    ampli.googleReviewReplied(properties);
  } else {
    ampli.sundayReviewReplied(properties);
  }
};
const MAX_SIZE_OF_REPLY = 4000;

export const SelectedReview = ({
  review,
  onReply,
}: {
  review: Review;
  onReply: (review: Review, reply: string) => Promise<void>;
}) => {
  const intl = useIntl();
  const [reply, setReply] = useState(review.reply);
  const [loadingOnReply, setLoadingOnReply] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<ReplyTemplate | undefined>(undefined);
  const business = useCurrentBusinessOrThrow();
  const snackbar = useSnackbar();
  const theme = useTheme();

  const reset = () => {
    setSelectedTemplate(undefined);
    setReply('');
  };

  useEffect(() => {
    reset();
  }, [review]);

  const hasEditedTemplate = (): boolean => reply !== selectedTemplate?.content;

  const trackReplySubmissionEvent = () => {
    const templateContentEdited = selectedTemplate && hasEditedTemplate();
    const templateId = selectedTemplate?.id;
    trackInAmplitude(review.platform, {
      templateId,
      templateContentEdited,
      reviewId: review.id,
      venueId: business.id,
      reviewGlobalRating: review.rating,
      creationDate: Instant.fromEpoch(review.creationDate).toISOString(),
      foodRating: ratingOf(review, 'FOOD_AND_DRINKS'),
      serviceRating: ratingOf(review, 'SERVICE'),
      ambianceRating: ratingOf(review, 'AMBIANCE'),
      valueForMoneyRating: ratingOf(review, 'VALUE_FOR_MONEY'),
    });
  };

  const notifyReplySent = () => {
    snackbar.addNotification({
      text: intl.formatMessage({ id: 'venue.reviews.reply.success' }),
      variant: 'success',
    });
  };

  const notifyReplyInError = () => {
    snackbar.addNotification({
      text: intl.formatMessage({ id: 'venue.reviews.reply.error' }),
      variant: 'error',
    });
  };

  const sendReply = () => {
    setLoadingOnReply(true);
    onReply(review, reply)
      .then(notifyReplySent)
      .catch(notifyReplyInError)
      .then(trackReplySubmissionEvent)
      .finally(() => setLoadingOnReply(false));
  };

  return (
    <Paper sx={{ padding: '1rem' }}>
      <Stack direction="row" justifyContent="space-between">
        <ReviewStarRating rating={review.rating} />
        <ReviewDate creationDate={review.creationDate} />
      </Stack>
      <Card sx={{ boxShadow: 'none' }}>
        <CardHeader
          avatar={<PlatformLogo review={review} />}
          title={<Author review={review} />}
          subheader={<ReviewOrderInfo review={review} />}
        />
        <CardContent>
          <Typography color="textSecondary">{review.feedback}</Typography>
        </CardContent>
      </Card>
      <Stack spacing={2}>
        <Stack direction="row" spacing={2}>
          <SuggestReplyBasedOnOpenAI review={review} onSuggest={setReply} />
          <SuggestReplyBasedOnTemplate
            review={review}
            onSuggest={setReply}
            selectedTemplate={selectedTemplate}
            selectTemplate={setSelectedTemplate}
            reset={reset}
          />
        </Stack>
        <TextField
          multiline
          rows={10}
          placeholder={intl.formatMessage({ id: 'venue.reviews.reply.placeholder' })}
          onChange={(e) => setReply(e.target.value)}
          value={reply}
        />
        <Typography color={theme.palette.text.secondary}>
          {reply.length}/{MAX_SIZE_OF_REPLY}
        </Typography>
        <LoadingButton
          loading={loadingOnReply}
          fullWidth
          variant="contained"
          onClick={sendReply}
          disabled={reply.length === 0 || reply.length > MAX_SIZE_OF_REPLY}
        >
          <FormattedMessage id="venue.reviews.reply.action" />
        </LoadingButton>
      </Stack>
    </Paper>
  );
};
