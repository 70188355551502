import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { UpdateMessageLine } from './UpdateMessageLine';
import { FulfillmentType } from 'src/ordering/area/domain/FulfillmentType';
import { useUpdateBoxDetails } from '../../../../hooks';

import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type ConfirmationMessageBoxSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const ConfirmationMessageBoxSetting = ({ box, setBox }: ConfirmationMessageBoxSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const onMessageUpdated = async (serviceType: FulfillmentType, updatedMessage: string) => {
    const keyName = `finalPageMessage${FulfillmentType[serviceType]}`;
    const newCustomMessagesJson = { ...box.customMessagesJson, [ keyName ] : { und : updatedMessage } };

    makeUpdating(true);
    await updateBoxDetails({
      customMessagesJson: newCustomMessagesJson,
    });
    setBox({
      ...box,
      customMessagesJson: newCustomMessagesJson,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  const getMessage = (fullFillmentType: string) => {
    return box.customMessagesJson[`finalPageMessage${fullFillmentType}`]?.und || '';
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.confirmationMessageTitle',
        defaultMessage: 'confirmation message',
        description: 'Settings to configure the confirmation messages',
      })}
      description={intl.formatMessage({
        id: 'venue.confirmationMessage.generalHelper',
        // eslint-disable-next-line max-len
        defaultMessage: 'this message is shown in the confirmation page to guide users based on how you operate in the restaurant. if you add this message, the default message sunday uses won’t be shown. you can define a message for each service types.',
        description: 'Description of the confirmation message settings',
      })}
      expandable
      disabled={isUpdating}
    >
      <UpdateMessageLine
        serviceType={FulfillmentType.EAT_IN_SERVICE_AT_TABLE}
        message={getMessage(FulfillmentType.EAT_IN_SERVICE_AT_TABLE)}
        setMessage={onMessageUpdated}
        disabled={isUpdating}
      />
      <UpdateMessageLine
        serviceType={FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER}
        message={getMessage(FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER)}
        setMessage={onMessageUpdated}
        disabled={isUpdating}
      />
      <UpdateMessageLine
        serviceType={FulfillmentType.TAKE_AWAY}
        message={getMessage(FulfillmentType.TAKE_AWAY)}
        setMessage={onMessageUpdated}
        disabled={isUpdating}
      />
      <UpdateMessageLine
        serviceType={FulfillmentType.CLICK_AND_COLLECT}
        message={getMessage(FulfillmentType.CLICK_AND_COLLECT)}
        setMessage={onMessageUpdated}
        disabled={isUpdating}
      />
    </SettingPanel>
  );
};
