import React from 'react';
import { Typography } from '@mui/material';
import { renderNumber } from '../domain/TemplatesHelper';
import { palette } from '../design/palette';

type FluctuationProps = {
  value: string | null;
  fluctuation: number | null;
  countryCode: string;
  fluctuationType: ' pts' | '%' | '';
};
export const FluctuationComponent = ({
  value, fluctuation, fluctuationType, countryCode,
}: FluctuationProps) => {
  if (!!value && !!fluctuation) {
    return (
      <>
        {fluctuation > 0 && (
          <Typography color={palette.progress.positive}>
            +
            {renderNumber(fluctuation, countryCode)}
            {fluctuationType}
          </Typography>
        )}
        {fluctuation < 0 && (
          <Typography color={palette.progress.negative}>
            {renderNumber(fluctuation, countryCode)}
            {fluctuationType}
          </Typography>
        )}
        {fluctuation === 0 && <Typography color={palette.progress.positive}>=</Typography>}
      </>
    );
  }
};
