import { Alert, CardHeader, Stack } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const MenuMigrationHeader = ({
  details,
  infoMessage,
  warningMessage,
}: {
  details: string
  infoMessage?: string
  warningMessage?: string
}) => {

  const intl = useIntl();

  return (
    <Stack direction={'row'}
           sx={{
             justifyContent: 'space-between',
             alignItems: 'center',
           }}>
      <CardHeader
        title={intl.formatMessage({ id: 'configuration_replication.menu.title' })}
        subheader={details}
        sx={{
          padding: '0rem',
          '& .MuiCardHeader-subheader': {
            'color': 'black',
            'paddingTop': '1.5rem',
          },
        }}
      />
      {infoMessage &&
        <Alert severity="info" sx={{ width: '20rem', padding: '0.38rem 1rem' }}>
          {infoMessage}
        </Alert>
      }
      {warningMessage &&
        <Alert severity="error" sx={{ width: '20rem', padding: '0.38rem 1rem' }}>
          {warningMessage}
        </Alert>
      }
    </Stack>
  );
};
