import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type CutleryVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const CutleryVenueSetting = ({ venue, setVenue }: CutleryVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateOrderNotes = async (enableCutlery: boolean) => {
    makeUpdating(true);
    await updateVenueDetails({
      enableCutlery,
    });
    setVenue({
      ...venue,
      enableCutlery,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.enableCutlery',
        defaultMessage: 'enable cutlery',
        description: 'enable cutlery setting title',
      })}
      description={intl.formatMessage({
        id: 'venue.enableCutleryDescription',
        defaultMessage:
          'This allows you to offer the customer the option to ask for cutlery. This only appears in take away orders.',
        description: 'enable cutlery setting description',
      })}
      toggleValue={venue.enableCutlery}
      toggleOnChange={updateOrderNotes}
      disabled={isUpdating}
    />
  );
};
