import React, { useEffect, useMemo, useState } from 'react';
import { useRouting } from '../../../common/hooks';
import { OrderingMenu } from '../../../orderingMenus/domain/OrderingMenu';
import { useGetMenus } from '../../../orderingMenus/hooks';
import { Table } from '../../../venues/domain/Table';
import { EMPTY_MENU_ASSOCIATIONS, ServiceMenuAssociations } from '../ServiceMenuAssociation';
import { FulfillmentType } from '../../domain/FulfillmentType';
import { AreaBoxCreationNameSettings } from './components/AreaBoxCreationNameSettings';
import { AreaBoxCreationServiceSelection } from './components/AreaBoxCreationServiceSelection';
import { AreaBoxCreationMenuSelection } from './components/AreaBoxCreationMenuSelection';
import { AreaBoxCreationTableSelection } from './components/AreaBoxCreationTableSelection';
import { useGetVenueTables } from '../../../venues/hook';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';

type AreaCreationSettingsProps = {
  businessId: BusinessId;
  enrollmentId: EnrollmentId;
  boxId: string;
};

enum AreaCreationSteps {
  AREA_CREATION,
  SERVICE_SELECTION,
  MENU_SELECTION,
  TABLE_SELECTION,
}

function hasTables(service: FulfillmentType): boolean {
  return service === FulfillmentType.EAT_IN_SERVICE_AT_TABLE || service === FulfillmentType.HYBRID_ORDERING;
}

export const AreaCreationSettings = ({ businessId, enrollmentId, boxId }: AreaCreationSettingsProps) => {
  const navigation = useRouting();
  const [areaId, setAreaId] = useState<string>('');
  const [menus, setMenus] = useState<OrderingMenu[]>([]);
  const [, getMenus] = useGetMenus();

  const [tables, setTables] = useState<Table[]>([]);
  const [, getTables] = useGetVenueTables();
  const [selectedTables, updateSelectedTables] = useState<string[]>([]);

  const [selectedMenus, updateMenuSelection] = useState<ServiceMenuAssociations>(EMPTY_MENU_ASSOCIATIONS);
  const [currentStep, setCurrentStep] = useState<AreaCreationSteps>(AreaCreationSteps.AREA_CREATION);
  const [currentServices, setCurrentServices] = useState<FulfillmentType[]>([]);

  const hasServiceAtTable = useMemo(() => currentServices.some((service) => hasTables(service)), [currentServices]);

  useEffect(() => {
    getMenus(boxId).then(setMenus);
  }, [getMenus, boxId]);

  useEffect(() => {
    getTables(enrollmentId).then(setTables);
  }, [getTables, enrollmentId]);

  const onAreaCreated = (createdAreaId: string) => {
    setAreaId(createdAreaId);
    setCurrentStep(AreaCreationSteps.SERVICE_SELECTION);
  };

  const onServiceSelected = (services: FulfillmentType[]) => {
    setCurrentStep(AreaCreationSteps.MENU_SELECTION);
    setCurrentServices(services.sort((s1, s2) => s1.localeCompare(s2)));
  };

  const onMenuConfigured = () => {
    if (hasServiceAtTable) {
      setCurrentStep(AreaCreationSteps.TABLE_SELECTION);
      return;
    }

    navigation.goToArea(businessId, boxId, areaId);
  };

  const onTableSelected = (tableIds: string[]) => {
    updateSelectedTables([...tableIds]);
  };

  if (currentStep === AreaCreationSteps.AREA_CREATION) {
    return <AreaBoxCreationNameSettings businessId={businessId} boxId={boxId} onAreaCreated={onAreaCreated} />;
  }

  if (currentStep === AreaCreationSteps.SERVICE_SELECTION) {
    return (
      <AreaBoxCreationServiceSelection
        businessId={businessId}
        initialServices={currentServices}
        onServiceSelected={onServiceSelected}
      />
    );
  }

  if (currentStep === AreaCreationSteps.MENU_SELECTION) {
    return (
      <AreaBoxCreationMenuSelection
        areaId={areaId}
        services={currentServices}
        menus={menus}
        selectedMenus={selectedMenus}
        onMenuSelectionUpdated={updateMenuSelection}
        onBackButton={() => setCurrentStep(AreaCreationSteps.SERVICE_SELECTION)}
        onMenuConfigured={onMenuConfigured}
      />
    );
  }

  if (currentStep === AreaCreationSteps.TABLE_SELECTION) {
    return (
      <AreaBoxCreationTableSelection
        businessId={businessId}
        boxId={boxId}
        areaId={areaId}
        tables={tables}
        selectedTables={selectedTables}
        onBackButton={() => setCurrentStep(AreaCreationSteps.MENU_SELECTION)}
        onTablesSelected={onTableSelected}
      />
    );
  }

  return <></>;
};
