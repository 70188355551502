import styled from 'styled-components';
import React from 'react';
import { getSpacing } from '../../../../stylesheet';
import IconButton from '../../../../common/components/IconButton';
import { CheckIcon, PlusIcon } from '../../../../common/components/Icons';
import { Table } from '../../../../venues/domain/Table';

type AreaBoxTableProps = {
  table: Table;
  selected: boolean;
  disabled: boolean;
  onToggled: (tableId: string, selected: boolean) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${getSpacing(3)};
  border: 1px solid #dddde4;
  border-radius: 16px;
  align-items: center;
  margin-bottom: ${getSpacing(2)};
  justify-content: space-between;
`;

const TableName = styled.div``;

export const AreaBoxTable = ({
  table, selected, disabled, onToggled,
}: AreaBoxTableProps) => {
  const buttons = () => {
    if (disabled) {
      return <></>;
    }

    if (selected) {
      return (
        <IconButton
          Icon={CheckIcon}
          active
          onClick={() => {
            onToggled(table.id, false);
          }}
        />
      );
    }

    return (
      <IconButton
        Icon={PlusIcon}
        onClick={() => {
          onToggled(table.id, true);
        }}
      />
    );
  };

  return (
    <Container>
      <TableName>{table.name}</TableName>
      {buttons()}
    </Container>
  );
};
