import { z } from 'zod';

export const ReconciliationConfiguration = z.object({
  isActivated: z.boolean(),
  orphanPosLinesDisplayed: z.boolean().optional(),
},
);

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReconciliationConfiguration = z.infer<typeof ReconciliationConfiguration>;
