import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { VenueDetails } from '../../../../types';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { useUpdateVenueDetails } from '../../../../hook';
import Input from '../../../../../common/components/Input';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { Container, SettingPanelContent, Title } from 'src/ordering/common/components/Settings/SettingPanel';

type VenueEmailSettingsProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
`;

const ErrorMessage = styled.div`
  display: flex;
  color: ${colorPalette.red};
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
`;

export const VenueEmailSetting = ({ venue, setVenue }: VenueEmailSettingsProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [venueEmail, setVenueEmail] = useState<string>(venue.email ?? '');

  const trimmedVenueEmail = venueEmail.trim();

  const venueEmailChanged = trimmedVenueEmail !== venue.email;

  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const hasVenueEmailError = trimmedVenueEmail.length > 0
    && !validateEmail(trimmedVenueEmail);

  const updateVenueSettings = async () => {
    const updatedVenueEmail = venueEmail.trim();

    makeUpdating(true);
    await updateVenueDetails({
      email: updatedVenueEmail,
    });
    setVenue({
      ...venue,
      email: updatedVenueEmail,
    });

    setVenueEmail(updatedVenueEmail);

    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="venue.settings.app-venue.email.title" defaultMessage="Venue email address" />
      </Title>
      <Description>
        <FormattedMessage
          id="venue.settings.app-venue.email.description"
          defaultMessage="Used when sending orders confirmations (ex for pre-ordering)."
        />
      </Description>
      <SettingPanelContent>
        <Input value={venueEmail} onValueChange={setVenueEmail} disabled={isUpdating} placeholder="email@venue.com..." />
        {hasVenueEmailError && (
          <ErrorMessage>
            <FormattedMessage
              id="venue.settings.app-venue.email.error"
              defaultMessage="Must be a valid email address"
            />
          </ErrorMessage>
        )}
        {venueEmailChanged && (
          <Line>
            <SaveButton
              variant="primary"
              size="xSmall"
              onClick={updateVenueSettings}
              disabled={isUpdating || hasVenueEmailError}
            >
              <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
            </SaveButton>
          </Line>
        )}
      </SettingPanelContent>
    </Container>
  );
};
