import { AxiosInstance, AxiosResponse } from 'axios';
import { BusinessId } from 'src/business/domain/Business';

export type PatOrchestratorPaymentResponse = {
  id: string;
  billId: string;
  state: string;
  cause?: string;
  bill: {
    billId: string;
    tab:
    | {
      tabId: string;
      customerFullName: string | undefined;
      displayId: string;
    }
    | undefined;
    staffName: string;
  };
};

export type PatOrchestratorTabResponse = {
  tabId: string;
  paymentId: string;
  displayId: string;
  customerFullName?: string;
};

export class PatOrchestratorRepositoryHttp {
  constructor(
    private httpClient: AxiosInstance,
    private patOrchestratorUrl: string,
  ) {
  }

  async searchPayments(businessId: BusinessId, paymentIds: string[]): Promise<PatOrchestratorPaymentResponse[]> {
    if (paymentIds.length === 0) {
      return [];
    }

    return this.httpClient
      .post(`${this.patOrchestratorUrl}/businesses/${businessId}/payments/search`, paymentIds)
      .then((response: AxiosResponse) => response.data);
  }

  async searchTabsByPaymentIds(businessId: BusinessId, paymentIds: string[]): Promise<PatOrchestratorTabResponse[]> {
    if (paymentIds.length === 0) {
      return [];
    }

    return this.httpClient
      .post(`${this.patOrchestratorUrl}/businesses/${businessId}/tabs/search-by-payment-ids`, paymentIds)
      .then((response: AxiosResponse) => response.data);
  }
}
