import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  BoxOrderHistory, BoxOrderId, Event, OrderHistory, OrderId,
} from '../../types';
import OrderHistoryEvent from './OrderHistoryEvent';

interface Props {
  history: OrderHistory | undefined;
}

const TimelineContainer = styled.div`
  max-height: 70vh;
  overflow-y: auto;
`;

type TypedEvent =
  | {
    event: Event;
    boxOrderId: BoxOrderId;
  }
  | {
    event: Event;
    orderId: OrderId;
  };

const getBoxOrderEvents = (acc: TypedEvent[], boxOrderHistory: BoxOrderHistory) => acc.concat(
  boxOrderHistory.events.events.map((event) => ({
    boxOrderId: boxOrderHistory.boxOrderId,
    event,
  })),
);

const sortTypedEventByDate = (e1: TypedEvent, e2: TypedEvent) => e1.event.atEpochMilli - e2.event.atEpochMilli;

const renderOrderHistoryEvent = (event: TypedEvent) => (
  <OrderHistoryEvent key={event.event.eventId} event={event.event} />
);

const OrderHistoryTimeline: React.FC<Props> = ({ history }) => {
  const allEvents = useMemo<TypedEvent[]>(
    () =>
      (history
        ? history.boxOrders
          .reduce(
            getBoxOrderEvents,
            history.events.events.map((event) => ({
              orderId: history.orderId,
              event,
            })),
          )
          .sort(sortTypedEventByDate)
        : []),
    [history],
  );
  if (!history) return null;
  return <TimelineContainer>{allEvents.map(renderOrderHistoryEvent)}</TimelineContainer>;
};

export default OrderHistoryTimeline;
