import styled, { css } from 'styled-components';
import { baseStackStyle, StackProps } from './Stack';
import { sizeInCss } from './Size';
import { Spacing, spacingInCss } from './Spacing';

type Props = {
  fullWidth?: boolean;
  margin?: Spacing;
  marginTop?: Spacing;
  verticalAlign?: 'center' | 'top' | 'bottom';
};

export const Row = styled.div<StackProps & Props>`
  ${baseStackStyle};
  flex-direction: row;

  ${({ verticalAlign }) => (
    verticalAlign ? css`
          vertical-align: ${verticalAlign};
        `
      : '')};

  ${({ margin }) => (
    margin ? css`
          margin: ${spacingInCss(margin)};
        `
      : '')};

  ${({ fullWidth }) => (
    fullWidth ? css`
          width: 100%;
        `
      : '')};

  > :not(:last-child) {
    margin-right: ${({ gap }) => (gap ? sizeInCss(gap) : null)};
  }
`;
