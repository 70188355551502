import { Configuration } from 'src/configuration/Configuration';
import { MetabaseDashboard } from '../domain/MetabaseDashboard';
import axios from 'axios';

export class MetabaseDashboardUrlBuilder {
  constructor(private configuration: Configuration) {
  }

  async build(dashboard: MetabaseDashboard, businessIds: string[]): Promise<string> {
    const response = await axios.post<string>(`${this.configuration.merchantAccountsBaseUrl}/metabase/token`, {
      businessIds,
      dashboardId: dashboard,
    });

    const token = response.data;
    return `${this.configuration.metabaseUrl}/embed/dashboard/${token}/#theme=transparent&bordered=false&titled=false`;
  }
}
