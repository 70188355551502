import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { GroupOfInvoices } from 'src/accounting/invoices/domain/Invoice';
import { BusinessId } from 'src/business/domain/Business';
import { useConfiguration } from 'src/accounting/invoices/pages/useConfiguration';
import { InvoicesError } from 'src/accounting/invoices/pages/InvoicesError';
import { FilteredInvoices } from 'src/accounting/invoices/pages/FilteredInvoices';
import { NoInvoices } from 'src/accounting/invoices/pages/NoInvoices';

export const InvoicesLoader = ({ businessId }: { businessId: BusinessId }) => {
  const configuration = useConfiguration();

  const [invoices, setInvoices] = useState<GroupOfInvoices>();
  const [inError, setInError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    configuration.invoicePort.getInvoices(businessId)
      .then((result) => {
        setInError(false);
        setLoading(false);
        setInvoices(result);
      })
      .catch(() => {
        setLoading(false);
        setInError(true);
      });
  }, [configuration.invoicePort, businessId, businessId]);


  if (isLoading) return  <CircularProgress color="inherit" data-testid="invoices-loader" />;

  if (inError) return <InvoicesError onClose={() => setInError(false)} />;

  if (invoices && invoices!!.invoicesPerYearMonth.length === 0) return <NoInvoices/>;

  return <FilteredInvoices businessId={businessId} invoices={invoices!!}/>;
};
