import { ExposedMenuType } from './ExposedMenuType';
import { Language } from 'src/app/i18n/AllSupportedLanguage';
import { BusinessId } from 'src/business/domain/Business';
import { ValuesOf } from 'src/utils/typescript/valuesOf';

export interface MenuBusiness {
  id: BusinessId;
  dynamicMenuAllowed: boolean;
  defaultLanguage: Language;
  translatedLanguages: Language[];
  exposedMenuType: ExposedMenuType;
  externalMenuUrl?: string;
  orderingAllowed: boolean;
  orderingEnabled: boolean;
  filterConfiguration: OrderingFilterConfiguration;
  openBillEnabled: boolean;
}

export const isOrderingEnabled = (menuBusiness: MenuBusiness | null | undefined): boolean => {
  if (!menuBusiness) {
    return false;
  }
  return menuBusiness.orderingAllowed && menuBusiness.orderingEnabled;
};

export const orderingFilterConfiguration = {
  HIDDEN: 'HIDDEN',
  PRODUCTS_AVAILABLE_FOR_ORDER: 'PRODUCTS_AVAILABLE_FOR_ORDER',
  ALL_PRODUCTS: 'ALL_PRODUCTS',
} as const;

export type OrderingFilterConfiguration = ValuesOf<typeof orderingFilterConfiguration>;
