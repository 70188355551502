import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  AverageTransactionValue,
} from 'src/payments/components/Checkout/CheckoutOnboarding/ProcessingDetails/AverageTransactionValue';
import {
  AnnualProcessingVolume,
} from 'src/payments/components/Checkout/CheckoutOnboarding/ProcessingDetails/AnnualProcessingVolume';
import {
  HighestTransactionValue,
} from 'src/payments/components/Checkout/CheckoutOnboarding/ProcessingDetails/HighestTransactionValue';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Business } from 'src/business/domain/Business';

export const currencyLabel = (business: Business) => {
  switch (business.currency) {
    case 'GBP': return '£';
    case 'USD': return '$';
    default: return '€';
  }
};

export const ProcessingDetails = () => {
  const theme = useTheme();
  return <Stack direction="column" spacing={3}>
    <Stack spacing={1}>

      <Typography variant={'h5'}>
        2 · <FormattedMessage id={'settings.payments.checkout.onboarding.processingDetails'} />
      </Typography>
      <Typography variant={'caption'} color={theme.palette.grey.A700}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.processingDetails.helperText'} />
      </Typography>
    </Stack>
    <AnnualProcessingVolume />
    <AverageTransactionValue />
    <HighestTransactionValue />
  </Stack>;
};
