import React from 'react';
import { Stack } from '@mui/material';
import { colorForRating, ratingColors } from '../../../../RatingColor';
import StarIconSvg from './StarIconSvg';

interface Props {
  rating: number
}

export const ReviewStarRating = ({ rating }: Props) => {
  const roundRating = Math.round(rating);
  return (
    <Stack direction="row">
      {ratingColors.map((ratingColor) => (
        <StarIconSvg
          size={20}
          key={ratingColor.rating}
          fill={roundRating < ratingColor.rating ? '#DDDDE4' : colorForRating(roundRating)}
        />
      ))}
    </Stack>
  );
};
