import {
  allSelectableConfigurations,
  displayTargetBusinesses,
  SelectableConfigurations,
  selectableConfigurationsLabels,
  SelectConfigurations,
  SelectTargetBusinesses,
} from '../domain/ConfigurationReplicationState';
import { useState } from 'react';
import { Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ampli } from 'src/ampli';

export const SelectConfigurationsStep = ({
  currentState,
  onNext,
}: {
  currentState: SelectConfigurations
  onNext: (nextState: SelectTargetBusinesses) => void
}) => {

  const [configurations, setConfigurations] = useState<Array<SelectableConfigurations>>([]);

  const hasConfiguration = (configuration: SelectableConfigurations) => configurations.includes(configuration);

  const updateConfigurations = (configuration: SelectableConfigurations, checked: boolean) => {
    if (checked) {
      setConfigurations([...configurations, configuration]);
    } else {
      setConfigurations(configurations.filter((c) => c !== configuration));
    }
  };

  const next = () => {
    ampli.configurationReplicationChangesSelected({ numberOfReplicationConfigurationChanges: configurations.length });
    onNext(displayTargetBusinesses(currentState, configurations));
  };

  return (
    <Stack padding="1.5rem" gap="2rem">
      <Typography variant="h5">
        <FormattedMessage id="configuration_replication.select_configurations.title" />
      </Typography>
      <Typography>
        <FormattedMessage id="configuration_replication.select_configurations.details" />
      </Typography>
      <Stack gap="1rem">
        <Typography variant="h6">
          <FormattedMessage id="configuration_replication.select_configurations.categories.customization" />
        </Typography>
        <FormGroup>
          { allSelectableConfigurations.map((configuration) => (
            <FormControlLabel
              key={configuration}
              control={
                <Checkbox
                  checked={hasConfiguration(configuration)}
                  onChange={(event) => updateConfigurations(configuration, event.target.checked)}
                />
              }
              label={<FormattedMessage id={selectableConfigurationsLabels[configuration]} />}
            />
          ))}
        </FormGroup>
      </Stack>
      <Stack direction="row-reverse">
        <Button variant="contained" onClick={next} disabled={configurations.length < 1}>
          <FormattedMessage id="configuration_replication.select_configurations.next" />
        </Button>
      </Stack>
    </Stack>
  );
};
