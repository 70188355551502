import { Kpi } from 'src/sundayplus/reviews/insights/domain/Kpi';
import { getKpiTimeframe, KpiTimeframe } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { getEReputationKpisByTimeframeType, RatingsAverageGraphData, RatingsCountGraphData } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { EReputationKpis, EReputationKpisByTimeframe, getEReputationKpiByType } from 'src/sundayplus/reviews/insights/domain/EReputationKpis';

const eReputationKpisViewModel = (
  kpi: Kpi<RatingsAverageGraphData | RatingsCountGraphData>,
  eReputationKpisByTimeframes: EReputationKpisByTimeframe[],
  timeframe: KpiTimeframe,
): RatingsAverageGraphData | RatingsCountGraphData | undefined => {
  const eReputationKpiByTimeFrame = getEReputationKpisByTimeframeType(eReputationKpisByTimeframes, timeframe);

  if (!eReputationKpiByTimeFrame) return undefined;

  const eReputationKpiByType = getEReputationKpiByType(eReputationKpiByTimeFrame, kpi.type);
  return eReputationKpiByType
    ? eReputationKpiByType
      ? kpi.computeGraphData(timeframe, eReputationKpiByType)
      : undefined
    : undefined;
};

export const extractGraphDataForGivenKpiAndTimeframe = (
  kpis: EReputationKpis,
  kpi: Kpi<RatingsAverageGraphData | RatingsCountGraphData>,
  timeframe: string,
): RatingsAverageGraphData | RatingsCountGraphData | undefined =>
  kpis.eReputationKpisByTimeframes
    ? eReputationKpisViewModel(kpi, kpis.eReputationKpisByTimeframes, getKpiTimeframe(timeframe))
    : undefined;
