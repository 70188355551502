export const DEMO_THE_FOUNDRY_NCR = '3f7e8954-7de1-4fb8-ae65-2a4e9e6b662d';

export const shouldMockDataForBusiness = (businessId: string) => {
  return [
    '09e5777e-f1ce-4dc1-aa90-233b8d645235', // Chez Aurélien & Floris - alpha
    'e54b78ff-d6ff-45bc-bd01-49b1026222b9', // Mock Donald - alpha
    '3fe0c683-217d-429d-902a-881755013f14', // The Foundry (US Foods) - DEMO
    DEMO_THE_FOUNDRY_NCR, // The Foundry (NCR) - DEMO
    'ad69d6e3-132f-4105-822d-4cac0f180794', // Drafts Bar and Grill - DEMO
    '3c1cebb2-494b-4ddc-8ed5-5ab6dc60a520', // La Parilla - DEMO
  ].includes(businessId);
};

export const shouldHidePdqAdsForMocks = (businessId: string) => {
  return [
    '09e5777e-f1ce-4dc1-aa90-233b8d645235', // Chez Aurélien & Floris (alpha)
    DEMO_THE_FOUNDRY_NCR, // The Foundry (NCR)
  ].includes(businessId);
};
