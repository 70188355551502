import styled from 'styled-components';
import React from 'react';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type NoAreaPanelProps = {
  onNewArea: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(3)};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
  min-height: ${getSpacing(43)};
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
`;

const Title = styled.div`
  margin-bottom: ${getSpacing(2)};
  font-size: 32px;
  line-height: 32px;
  color: ${colorPalette.defaultTextColor};
`;

const Description = styled.div`
  margin-bottom: ${getSpacing(4)};
  font-size: 18px;
  line-height: 20px;
  color: ${colorPalette.grey500};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoAreaPanel = ({ onNewArea }: NoAreaPanelProps) => (
  <Container>
    <Content>
      <Title>
        <FormattedMessage
          id="settings.area.no-area.title"
          defaultMessage="Add your first area to your venue"
          description="title of the panel when no area are created yet"
        />
      </Title>
      <Description>
        <FormattedMessage
          id="settings.area.no-area.description"
          defaultMessage="create your area, select what type of service you want to offer and start servicing your customers."
          description="description of the panel when no area are created yet"
        />
      </Description>
      <ButtonWrapper>
        <Button variant="primary" size="large" marginTop="8px" onClick={onNewArea}>
          <FormattedMessage id="settings.area.create-btn" defaultMessage="Create a new area" />
        </Button>
      </ButtonWrapper>
    </Content>
  </Container>
);
