import React, { useContext } from 'react';
import { PayoutPort } from '../../../payout/domain/PayoutPort';
import { PayoutInMemoryPort } from '../../../payout/infrastructure/PayoutInMemoryPort';

interface ConfigurationContainer {
  payoutPort: PayoutPort;
}

export const ConfigurationContainerContext = React.createContext<ConfigurationContainer>({
  payoutPort: PayoutInMemoryPort(),
});

export const useConfiguration = () => {
  const container = useContext(ConfigurationContainerContext);
  if (!container) {
    throw new Error('Please add a valid configuration');
  }
  return container;
};
