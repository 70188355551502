import { Box, Stack, Step, StepContent, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';


export const FormStepper = () => {
  const intl = useIntl();
  const steps = [
    {
      label: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessDetails' }),
      description: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessDetails.estimation' }),
    },
    {
      label: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.processingDetails' }),
      description: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.processingDetails.estimation' }),
    },
    {
      label: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.keyStakeholders' }),
      description: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.keyStakeholders.estimation' }),
    },
    {
      label: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount' }),
      description: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.estimation' }),

    },
    {
      label: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.supportingDocuments' }),
      description: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.supportingDocuments.estimation' }),
    },
  ];

  const theme = useTheme();

  return (
    <Box sx={{ position: 'absolute', top: '20%', left: '5%' }}>
      <Stepper
        sx={{
          '.MuiSvgIcon-root': {
            color: theme.palette.primary.main,
            width: 22,
            height: 22,
          },
          '.MuiStepLabel-label': {
            color: theme.palette.text.primary,
          },
          '.MuiStepIcon-text': {
            fill: 'white',
          },
        }}
        activeStep={-1} orientation="vertical">
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>
              <Stack>
                <Typography>{step.label}</Typography>
                <Typography variant={'caption'}>{step.description}</Typography>
              </Stack>

            </StepLabel>
            <StepContent>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
