import { queryKeys } from 'src/app/queries/utils';
import { useQuery } from '@tanstack/react-query';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Customization } from '../domain/Customization';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { BusinessId } from 'src/business/domain/Business';
import { CustomizationRepositoryHttp } from './CustomizationRepositoryHttp';

const getCustomizationQuery = (customizationRepository: CustomizationRepositoryHttp, enrollmentId: EnrollmentId | undefined) => {
  return {
    queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION, { enrollmentId }],
    queryFn: (): Promise<Customization> => {
      if (!enrollmentId) {
        return Promise.resolve({
          logo: undefined,
          cover: undefined,
          shortWelcome: undefined,
          description: undefined,
        });
      }
      return customizationRepository.getCustomization(enrollmentId);
    },
  };
};

export const usePatCustomization = (businessId: BusinessId) => {
  const { data: business } = useBusinessQuery(businessId);
  const customizationRepository = useCustomizationRepositoryHttp();

  const query = getCustomizationQuery(customizationRepository, business?.patEnrollment?.id);
  return useQuery({
    ...query,
    enabled: !!business,
  });
};

export const useCustomization = (enrollmentId: EnrollmentId) => {
  const customizationRepository = useCustomizationRepositoryHttp();

  return useQuery(getCustomizationQuery(customizationRepository, enrollmentId));
};
