import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCustomizationRepositoryHttp } from 'src/pages/Settings/Customization/infrastructure/useCustomizationRepositoryHttp';
import { ChargesDisplay } from 'src/pages/Settings/Customization/domain/ChargesDisplay';
import { QUERY_KEY_CHARGES_DISPLAY } from 'src/ops-tooling/infrastructure/queries/useGetChargesDisplay';

export const useSaveChargesDisplay = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();

  return useMutation({
    mutationFn: ({ enrollmentId, chargesDisplay }: { enrollmentId: EnrollmentId; chargesDisplay: ChargesDisplay }) =>
      customizationRepository.saveChargesDisplay(enrollmentId, chargesDisplay),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY_CHARGES_DISPLAY] }),
  });
};
