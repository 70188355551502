import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import IconButton, { IconButtonVariants } from '../../../common/components/IconButton';
import { DownArrowIcon } from '../../../common/components/Icons';
import Line, { AlignItems } from '../../../common/components/Line';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Event } from '../../types';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  event: Event;
}

const UpArrowIcon = styled(DownArrowIcon)`
  transform: rotate(180deg);
`;

const EventContainer = styled.div`
  margin: 0 0 ${getSpacing(2)};
`;

const EventDate = styled.div`
  color: ${colorPalette.grey600};
  flex: 1;
  text-align: right;
`;

const EventName = styled.div``;
const EventPayload = styled.div``;

const OrderHistoryEvent: React.FC<Props> = ({ event }) => {
  const [displayPayload, setDisplayPayload] = useState<boolean>(false);
  const toggleDisplayPayload = useCallback(() => {
    setDisplayPayload(!displayPayload);
  }, [setDisplayPayload, displayPayload]);
  const date = new Date(event.atEpochMilli);
  return (
    <EventContainer>
      <Line align={AlignItems.CENTER} onClick={toggleDisplayPayload}>
        <EventName>{event.eventName}</EventName>
        <EventDate>
          {date.toLocaleString(undefined, {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })}
        </EventDate>
        <IconButton variant={IconButtonVariants.BORDERLESS} Icon={displayPayload ? UpArrowIcon : DownArrowIcon} />
      </Line>
      {displayPayload && <EventPayload>{event.payload}</EventPayload>}
    </EventContainer>
  );
};

export default OrderHistoryEvent;
