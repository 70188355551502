import { ReactNode, useMemo } from 'react';
import { ReviewContext } from './ReviewContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpEReputationRepository } from '../insights/infrastructure/HttpEReputationRepository';

export const ReviewProvider = ({ children }: { children: ReactNode }) => {
  const configuration = useMemo(() => ConfigurationLoader.load(), []);

  const eReputationRepository = useMemo(() => new HttpEReputationRepository(configuration.venueFeedbackBaseUrl), [configuration]);

  return (
    <ReviewContext.Provider value={{
      eReputationRepository,
    }}
    >
      {children}
    </ReviewContext.Provider>
  );
};
