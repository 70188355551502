import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from './domain/MetabaseDashboard';
import { useEffect } from 'react';
import { ampli } from '../ampli';
import { useCurrentBusinessOrThrow } from '../business/hooks/useCurrentBusinessOrThrow';
import { language } from '../app/i18n/I18nProvider';
import { shouldMockDataForBusiness } from '../mocks/shouldMockDataForBusiness';


export const DataAnalyticsReviews = () => {
  const business = useCurrentBusinessOrThrow();

  useEffect(() => {
    ampli.dataAnalyticsReviewsClicked({
      businessId: business.id,
      businessName: business.name,
    });
  }, []);

  if (shouldMockDataForBusiness(business.id)) {
    return <img width="100%" height="auto" src="/img/mocks/analytics/KYC-Overview-Tab-Mocked.png"
                alt="overview mocked" />;
  }

  return (
    <DataAnalyticsIframeComponent
      dashboard={language() === 'fr' ? MetabaseDashboard.REVIEWS_FRENCH : MetabaseDashboard.REVIEWS_ENGLISH} />

  );
};
