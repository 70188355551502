import { CurrencyCode } from '@sundayapp/web-money';
import { PayoutPort } from '../domain/PayoutPort';
import { Payout } from '../domain/Payout';
import { aPayout } from '../domain/PayoutFixture';
import { BlobWithMetadata } from '../domain/BlobWithMetadata';
import { BusinessId } from 'src/business/domain/Business';

export const PayoutInMemoryPort = (): PayoutPort => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPayouts: (businessId: BusinessId) =>
    Promise.resolve<Payout[]>([
      {
        ...aPayout,
        amount: {
          amount: 15000_00000,
          currency: CurrencyCode.EUR,
        },
        id: 'po_1234',
        status: 'in transit',
        createdAt: 1669905301000,
        arrivalDate: '2023-01-02',
      },
      {
        ...aPayout,
        amount: {
          amount: 30000_00000,
          currency: CurrencyCode.EUR,
        },
        id: 'po_5678',
        status: 'paid',
        operationDates: ['2023-01-01', '2023-01-02'],
      },
      {
        ...aPayout,
        amount: {
          amount: 25000_00000,
          currency: CurrencyCode.EUR,
        },
        id: 'po_1235',
        status: 'paid',
        createdAt: 1669905301000,
        arrivalDate: undefined,
        operationDates: undefined,
      },
      {
        ...aPayout,
        amount: {
          amount: 43000_00000,
          currency: CurrencyCode.EUR,
        },
        id: 'po_7235',
        status: 'paid',
        createdAt: 1669905301000,
        arrivalDate: '2023-01-02',
        operationDates: [],
      },
    ]),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPayoutsExport: (businessId: BusinessId, startDate: Date, endDate: Date) =>
    Promise.resolve<BlobWithMetadata>(
      {
        blob: new Blob(['export;csv;fake;']),
        contentDisposition: 'attachment; filename="export.csv"',
      },
    ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPayoutsDetailsExport: (businessId: BusinessId, startDate: Date, endDate: Date) =>
    Promise.resolve<BlobWithMetadata>(
      {
        blob: new Blob(['export;csv;fake;']),
        contentDisposition: 'attachment; filename="payouts-details-export.csv"',
      },
    ),
});
