import DataAnalyticsIframeComponent from 'src/multi-locations/pages/data-analytics/DataAnalyticsIframeComponent';
import { language } from 'src/app/i18n/I18nProvider';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsBusinessPerformance = () => {
  // useEffect(() => {
  //   ampli.dataAnalyticsBusinessPerformanceClicked({
  //     businessId: business.id,
  //     businessName: business.name,
  //     clickDate: new Date().toISOString(),
  //   });
  // }, []);

  return (
    <DataAnalyticsIframeComponent
      dashboard={
        language() === 'fr'
          ? MetabaseDashboard.BUSINESS_PERFORMANCE_FRENCH
          : MetabaseDashboard.BUSINESS_PERFORMANCE_ENGLISH
      }
    />
  );
};
