import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import axios from 'axios';
import { CheckoutOnboardingInformationDto } from 'src/payments/infrastructure/CheckoutOnboardingInformationDto';
import { IntlShape } from 'src/app/i18n/TypedIntl';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { mapCheckoutInformationDtoToDomain } from 'src/payments/infrastructure/mapper/mapCheckoutInformationDtoToDomain';

export const getCheckoutOnboardingInformation = (intl: IntlShape) => async (businessId: string): Promise<CheckoutOnboardingInformationDraft> => {
  const configuration = ConfigurationLoader.load();
  try {
    const response = await axios.get<CheckoutOnboardingInformationDto>(`${configuration.paymentApiBaseUrl}/checkout/onboarding/${businessId}`, {
      headers: {
        contentType: 'application/json',
      },
    });

    return mapCheckoutInformationDtoToDomain(intl)(response.data);
  } catch (ex) {
    return Promise.reject('Checkout onboarding information could not be found');
  }

};
