import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

export const today = () => new Date(Date.now());

export const firstDayOfCurrentMonth = (): Date => {
  const date = today();
  date.setDate(1);
  return date;
};

const getWeekNumber = (date: Date) => dayjs(date).week();

export const datesAreEqual = (a: Date, b: Date) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

export const weeksAreEqual = (date: Date, otherDate: Date) => getWeekNumber(date) === getWeekNumber(otherDate);

export const previousNDay = (date: Date, number: number): Date => {
  const copy = new Date(date);
  copy.setDate(date.getDate() - number);
  return copy;
};

export const previousNMonth = (date: Date, number: number): Date => {
  const copy = new Date(date);
  copy.setDate(1);
  copy.setMonth(date.getMonth() - number);
  return copy;
};

export const previousNWeek = (date: Date, number: number): Date => {
  const copy = new Date(date);
  copy.setDate(date.getDate() - number * 7);
  return copy;
};

export const last4Weeks = () => {
  const now = today();
  const fourWeeks = [...Array(4)
    .keys()].map((i) => i);

  return fourWeeks.map((week) => previousNWeek(now, week));
};

export const last12Months = () => {
  const firstDayOfCurrentMonth1 = firstDayOfCurrentMonth();
  const twelveMonths = [...Array(12)
    .keys()].map((i) => i);

  return twelveMonths.map((months) => previousNMonth(firstDayOfCurrentMonth1, months));
};
export const lastWeekDays = () => {
  const now = today();
  const sevenDays = [...Array(7)
    .keys()].map((i) => i + 1);

  return sevenDays.map((day) => previousNDay(now, day));
};

export const monthIndexComparedToLastYearInterval = (date: Date) => {
  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const lastYear = last12Months().reverse();

  if (firstDayOfMonth.getTime() < lastYear[0].getTime()) return 0;
  if (firstDayOfMonth.getTime() > lastYear[lastYear.length - 1].getTime()) return 11;

  const matchedMonth = lastYear.find((d) => datesAreEqual(d, firstDayOfMonth));

  return lastYear.indexOf(matchedMonth!);
};
