import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Block } from '../../../domain/Block';
import { UpdateBlockDto } from '../../../repositories/block/BlockDto';

type Props = {
  block: Block
  blockUpdate: UpdateBlockDto
  setBlockUpdate: (blockDto: UpdateBlockDto) => void
};
export const BlockDescription = ({
  block, blockUpdate, setBlockUpdate,
}: Props) => {
  const intl = useIntl();

  const [value, setValue] = useState(block.description);

  useLayoutEffect(() => {
    setValue(block.description ?? '');
  }, [block.description]);

  const updateValue = (newValue: string) => {
    let newBlockDto = blockUpdate;
    if (newValue !== (block.description ?? '')) {
      newBlockDto = {
        ...newBlockDto,
        description: newValue,
      };
    } else {
      newBlockDto = {
        ...newBlockDto,
        description: undefined,
      };
    }
    setBlockUpdate(newBlockDto);
    setValue(newValue);
  };

  return (
    <Box component="div" display="flex" flexDirection="row" alignItems="center" width="100%">
      <TextField
        variant="outlined"
        size="medium"
        multiline
        fullWidth
        minRows={3}
        label={intl.formatMessage({ id: 'menus.block.details.description' })}
        value={value}
        onChange={(e) => updateValue(e.target.value)}
      />
    </Box>
  );
};
