import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import { VenueDetails } from '../../../../types';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { useUpdateVenueDetails } from '../../../../hook';
import { Container } from 'src/ordering/common/components/Settings/SettingPanel';
import Input from '../../../../../common/components/Input';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import Textarea from '../../../../../common/components/Textarea';
import { Switch } from '@mui/material';

type DisplayVenueNameSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

type Settings = {
  displayName: string;
  showVenueName: boolean;
  description: string;
};

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const Title = styled.div`
  margin-bottom: ${getSpacing(1)};
`;

const MainTitle = styled(Title)`
  font-size: 18px;
  line-height: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
`;

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${getSpacing(2)};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${getSpacing(1)};
  padding-bottom: ${getSpacing(2)};
`;

export const DisplayVenueInfoSetting = ({ venue, setVenue }: DisplayVenueNameSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [venueName, setVenueName] = useState<string>(venue.displayName);
  const [description, setDescription] = useState<string>(venue.description ?? '');

  const nameChanged = venueName !== venue.displayName;
  const descriptionChanged = description !== venue.description;

  const currentSetting: Settings = {
    displayName: venueName,
    showVenueName: venue.showVenueName,
    description: venue.description ?? '',
  };

  const updateVenueSettings = async (setting: Settings) => {
    makeUpdating(true);
    await updateVenueDetails({
      ...setting,
    });
    setVenue({
      ...venue,
      ...setting,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };
  return (
    <Container>
      <MainTitle>
        <FormattedMessage id="venue.settings.app-venue-name.title" defaultMessage="venue name" />
      </MainTitle>
      <Description>
        <FormattedMessage
          id="venue.settings.app-venue-name.description"
          defaultMessage="name of the venue to display"
        />
      </Description>
      <Content>
        <Input value={venueName} onValueChange={setVenueName} disabled={isUpdating} />
        {nameChanged && (
          <Line>
            <SaveButton
              variant="primary"
              size="xSmall"
              onClick={() => updateVenueSettings({ ...currentSetting, displayName: venueName })}
              disabled={isUpdating}
            >
              <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
            </SaveButton>
          </Line>
        )}
      </Content>
      <SettingLine
        title={intl.formatMessage({
          id: 'venue.settings.app-venue-name.home-display.title',
          defaultMessage: 'display venue name in the home page',
          description: 'title of the setting to display the venue name on the page where the customers land',
        })}
        description={intl.formatMessage({
          id: 'venue.settings.app-venue-name.home-display.description',
          defaultMessage: 'when active, the venue name will be displayed on the home page',
          description: 'description of the setting to display the venue name on the page where the customers land',
        })}
      >
        <Switch
          checked={venue.showVenueName}
          onChange={(_, value: boolean) => updateVenueSettings({ ...currentSetting, showVenueName: value })}
          disabled={isUpdating}
        />
      </SettingLine>
      <SettingLine
        title={intl.formatMessage({
          id: 'venue.settings.app-venue.description.title',
          defaultMessage: 'description',
        })}
        description={intl.formatMessage({
          id: 'venue.settings.app-venue.description.description',
          defaultMessage: 'The description will be displayed on the home page',
        })}
        direction="column"
      >
        <TextAreaWrapper>
          <Textarea value={description} onValueChange={setDescription} disabled={isUpdating} />
        </TextAreaWrapper>
        {descriptionChanged && (
          <Line>
            <SaveButton
              variant="primary"
              size="xSmall"
              onClick={() => updateVenueSettings({ ...currentSetting, description })}
              disabled={isUpdating}
            >
              <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
            </SaveButton>
          </Line>
        )}
      </SettingLine>
    </Container>
  );
};
