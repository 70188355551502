import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { QRCodeSVG } from 'qrcode.react';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { CircularProgress } from '@mui/material';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Step } from 'src/app-for-restaurants/component/step/Step';
import { useIntl } from 'src/app/i18n/TypedIntl';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: black;
  width: 355px;
  height: 152px;
  border-radius: ${spaceUsages.largeSmall};
  align-items: center;
  justify-content: flex-start;
  gap: ${spaceUsages.mediumSmall};
`;

const ScanToLink = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${spaceUsages.mediumLarge};
  margin-right: ${spaceUsages.mediumLarge};
`;

const QrCodeViewer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: ${spaceUsages.mediumLarge};
  background-color: white;
  margin: ${spaceUsages.small};
  width: 130px;
  height: 130px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const useQrCodeForRegistrationOnSfsApp = (businessId: string) => {
  const configuration = ConfigurationLoader.load();
  const url = `${configuration.waiterAppBackendUrl}/business-registration/${businessId}`;

  return useQuery<{ id: string; expiresAt: Date }>({
    queryKey: [queryKeys.waiters.CODE_FOR_REGISTRATION_ON_SFS_APP, businessId, url],
    queryFn: async () => {
      const response = await axios.get<{ id: string; expiresAt: Date }>(url);
      return response.data;
    },
  });
};

const LinkStep = ({ qrCodeId, isLoading }: { qrCodeId: string | undefined; isLoading: boolean }) => {
  return (
    <Container>
      <ScanToLink>
        <img src="/img/sunday-for-restaurants/scan-to-link.svg" alt="scan to link image" />
      </ScanToLink>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <QrCodeViewer>
          <QRCodeSVG value={qrCodeId!} size={110} level="L" />
        </QrCodeViewer>
      )}
    </Container>
  );
};

export function LinkStepWithQrCode() {
  const businessId = useBusinessIdOrThrow();
  const { data, isLoading, refetch } = useQrCodeForRegistrationOnSfsApp(businessId);
  const [timeLeft, setTimeLeft] = useState('');
  useEffect(() => {
    if (data) {
      const currentDate = new Date();
      const expiresAt = new Date(data.expiresAt);
      const delay = expiresAt.getTime() - currentDate.getTime();
      const timeoutId = setTimeout(refetch, delay);
      const intervalId = setInterval(() => {
        const now = new Date();
        const fullTimeLeft = Math.max(0, expiresAt.getTime() - now.getTime());
        const secondsLeft = Math.floor(fullTimeLeft / 1000);

        setTimeLeft(`${secondsLeft}`); // Display the remaining time in seconds
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalId);
      };
    }
    return () => undefined;
  }, [data, refetch]);

  const intl = useIntl();
  return (
    <Step
      title="sunday_for_restaurants.access.step.two.title"
      details={intl.formatMessage({ id: 'sunday_for_restaurants.access.step.two.details' })}
      moreDetails={
        timeLeft.length > 0
          ? intl.formatMessage(
            { id: 'sunday_for_restaurants.access.step.two.moreDetails' },
            { numberOfSeconds: timeLeft },
          )
          : ''
      }
      stepNumber={2}
      image={<LinkStep isLoading={isLoading} qrCodeId={data?.id} />}
    />
  );
}
