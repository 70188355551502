import { Variant } from '@mui/material/styles/createTypography';
import { MoneyView } from '@sundayapp/web-money';
import { SxProps, TableCell, Theme, Typography } from '@mui/material';
import React from 'react';
import { RevenueAmounts } from '../../../domain/RevenueSummary';

export type CellVariant = { variant: Variant; color: string };

export const RevenueAmountsCells = ({
  amounts,
  isBusinessWithServiceCharge,
  sx,
  th,
  td,
  isPaymentTerminal,
}: { amounts: RevenueAmounts, sx?: SxProps<Theme>, isBusinessWithServiceCharge: boolean, isPaymentTerminal: boolean, th: CellVariant; td: CellVariant }) => {
  const doNotBreakNumbers = { whiteSpace: 'nowrap' };
  const salesWithoutServiceCharge = {
    amount: amounts.totalSalesWithSundayFees.amount - amounts.totalAllCharges.serviceCharge.amount,
    currency: amounts.totalSalesWithSundayFees.currency,
  };

  return (
    <>
      <TableCell align="right" sx={sx}>
        <Typography variant={th.variant} color={th.color} sx={doNotBreakNumbers}>
          <MoneyView value={amounts.totalSalesAndTips} />
        </Typography>
      </TableCell>
      <TableCell align="right" sx={sx}>
        <Typography variant={td.variant} color={td.color} sx={doNotBreakNumbers}>
          <MoneyView
            value={isBusinessWithServiceCharge ? salesWithoutServiceCharge : amounts.totalSalesWithSundayFees} />
        </Typography>
      </TableCell>
      <TableCell align="right" sx={sx}>
        <Typography variant={td.variant} color={td.color} sx={doNotBreakNumbers}>
          <MoneyView value={amounts.totalTips} />
        </Typography>
      </TableCell>
      {isBusinessWithServiceCharge && (
        <TableCell align="right" sx={sx}>
          <Typography variant={td.variant} color={td.color} sx={doNotBreakNumbers}>
            {isPaymentTerminal ? '-' : <MoneyView value={amounts.totalAllCharges.serviceCharge} />}
          </Typography>
        </TableCell>
      )}
    </>
  );
};
