import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const DeleteRepresentativeDialog = ({ onConfirm, onClose }: {
  onConfirm: () => void,
  onClose: () => void
}) =>
  <Dialog onClose={onClose} open={true}>
    <DialogTitle sx={{ paddingTop: '24px' }}><FormattedMessage id='settings.payments.checkout.onboarding.representatives.delete.title' /></DialogTitle>
    <DialogActions sx={{ padding: '24px 16px' }}>
      <Button onClick={onClose}><FormattedMessage id='settings.payments.checkout.onboarding.representatives.delete.cancel' /></Button>
      <Button color={'error'} variant={'contained'} onClick={(event) => {
        onConfirm();
        event.stopPropagation();
      }}><FormattedMessage id='settings.payments.checkout.onboarding.representatives.delete.confirm' /></Button>
    </DialogActions>
  </Dialog>;
