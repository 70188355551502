import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { Business } from 'src/business/domain/Business';
import { useWhiteLabelSaveCustomization } from 'src/infrastructure/venue/useWhiteLabelSaveCustomization';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Customization } from 'src/pages/Settings/Customization/domain/Customization';

import { ButtonContainer, CustomizationForm } from 'src/pages/Settings/Customization/Customization.styles';
import MenuPreview from 'src/pages/Settings/Customization/components/MenuPreview';
import PostPaymentPreview from 'src/pages/Settings/Customization/components/PostPaymentPreview';
import { Box, Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { InputColor } from 'src/pages/Settings/Customization/components/Pictures/CoverBackgroundColorHandler.styles';
import { useForm } from 'react-hook-form';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { PreviewContainer } from 'src/pages/Settings/Customization/components/ColorCustomizers/PreviewContainer';


export const MarketingBannersColorCustomizer = ({ business, whiteLabelCustomization, customization }: {
  business: Business, whiteLabelCustomization: WhiteLabelCustomization;
  customization: Customization | undefined;
}) => {
  const [isDirtyFromColorInputs, setIsDirtyFromColorInputs] = React.useState(false);
  const { saveWhiteLabelCustomization } = useWhiteLabelSaveCustomization();
  const snackBar = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();
  const onSaveWhiteLabelCustomization = async (colorToSave: WhiteLabelCustomization) => {
    await saveWhiteLabelCustomization(business.patEnrollment?.id!, colorToSave);
    snackBar.addNotification({
      text: intl.formatMessage({ id: 'settings.customization.changes.saved' }),
      variant: 'success',
    });
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<{
    marketingBanners: string;
    appBackground: string;
  }>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      marketingBanners: whiteLabelCustomization.color.marketingBanners ?? '#FFFFFF',
      appBackground: whiteLabelCustomization.color.appBackground ?? '#FFFFFF',
    },
  });

  const watchMarketingBanners = watch('marketingBanners');
  const watchAppBackground = watch('appBackground');
  const onSubmit = async (inputs: { marketingBanners: string, appBackground: string }) => {
    onSaveWhiteLabelCustomization({
      ...whiteLabelCustomization,
      color: {
        ...whiteLabelCustomization?.color,
        marketingBanners: inputs.marketingBanners,
        appBackground: inputs.appBackground,
      },
    });
    reset(inputs, { keepDirty: false });
    setIsDirtyFromColorInputs(false);
  };
  usePrompt(isDirty || isDirtyFromColorInputs, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));
  return (
    <CustomizationForm onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <PreviewContainer>
          <Stack alignItems={'center'} spacing={2}>
            <MenuPreview
              customizationColors={{
                ...whiteLabelCustomization?.color,
                marketingBanners: watchMarketingBanners,
                appBackground: watchAppBackground,
              }}
              customizationBanner={whiteLabelCustomization?.burgerBanner}
              logoUrl={customization?.logo?.logoUrls?.noResize}
              width={115}
              height={231} />
            <Typography variant={'caption'} color={theme.palette.text.secondary}>
              <FormattedMessage id={'settings.customization.color.collapsable_menu'} />
            </Typography>
          </Stack>
          <Stack alignItems={'center'} spacing={2}>
            <PostPaymentPreview
              customizationColors={{
                ...whiteLabelCustomization?.color,
                marketingBanners: watchMarketingBanners,
                appBackground: watchAppBackground,
              }}
              customizationBanner={whiteLabelCustomization?.postPaymentBanner}
              logoUrl={customization?.logo?.logoUrls?.noResize}
              width={115}
              height={231} />
            <Typography variant={'caption'} color={theme.palette.text.secondary}>
              <FormattedMessage id={'settings.customization.color.post_payment'} />
            </Typography>
          </Stack>
        </PreviewContainer>
        <Stack spacing={2}>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'settings.customization.color.marketingBanners' })}
              placeholder={'#FFFFFF'} InputLabelProps={{ shrink: true }}
              error={!!errors.marketingBanners}
              {...register('marketingBanners', {
                deps: [],
                required: false,
              })} />
            <Box display={'flex'} alignItems={'center'} gap={1} flexDirection={'row'}>
              <InputColor
                value={watchMarketingBanners}
                onChange={(e) => {
                  setValue('marketingBanners', e.target.value);
                  setIsDirtyFromColorInputs(true);
                }}
                type="color"
              />
            </Box>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <TextField
              fullWidth
              label={intl.formatMessage({ id: 'settings.customization.color.appBackground' })}
              placeholder={'#FFFFFF'} InputLabelProps={{ shrink: true }}
              error={!!errors.appBackground}
              {...register('appBackground', {
                deps: [],
                required: false,
              })} />
            <Box display={'flex'} alignItems={'center'} gap={1} flexDirection={'row'}>
              <InputColor
                type="color"
                value={watchAppBackground}
                onChange={(e) => {
                  setValue('appBackground', e.target.value);
                  setIsDirtyFromColorInputs(true);
                }}
              />
            </Box>
          </Stack>
          {isValid && (isDirty || isDirtyFromColorInputs) && (
            <ButtonContainer>
              <Button variant="contained" size={'small'} type="submit"
                      disabled={!isValid || isSubmitting || (!isDirty && !isDirtyFromColorInputs)}>
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </ButtonContainer>
          )}
        </Stack>
      </Stack>
    </CustomizationForm>
  );

};
