import { BusinessId } from 'src/business/domain/Business';
import {
  useCopyProductMutation,
  useDeleteProductMutation,
  useSnoozeProductMutation,
  useUnSnoozeProductMutation,
} from 'src/menu/dynamic-menu/mutations/product/useProductMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { venueMenuProductPath } from 'src/app/navigation/pathHelpers';
import { Product } from 'src/menu/dynamic-menu/domain/Product';
import { useNavigate } from 'react-router';
import { useIntl } from 'src/app/i18n/TypedIntl';
import {
  ProductAction,
  ProductActionName,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';

export const useProductActions = (
  businessId: BusinessId,
  handleClose: () => void,
): ((action: ProductAction) => void) => {
  const intl = useIntl();
  const navigateTo = useNavigate();
  const snackBar = useSnackbar();

  const copyProduct = useCopyProductMutation(businessId);
  const deleteProduct = useDeleteProductMutation(businessId);
  const snoozeProduct = useSnoozeProductMutation(businessId);
  const unSnoozeProduct = useUnSnoozeProductMutation(businessId);

  const showActionError = (action: ProductActionName) => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({
        id: `menus.product.${action}.notification.error`,
      }),
    });
  };

  const showActionSuccess = (action: ProductActionName) => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({
        id: `menus.product.${action}.notification.success`,
      }),
    });
  };

  return (action: { product: Product; actionName: ProductActionName }) => {
    const mutations: { [key in ProductActionName]: () => Promise<void | Product> } = {
      copy: () => copyProduct.mutateAsync(action.product.id),
      delete: () => deleteProduct.mutateAsync(action.product.id),
      snooze: () => snoozeProduct.mutateAsync(action.product.sku),
      unsnooze: () => unSnoozeProduct.mutateAsync(action.product.sku),
    };

    const mutate = mutations[action.actionName];

    mutate()
      .then((newProduct) => {
        showActionSuccess(action.actionName);
        handleClose();
        if (newProduct) {
          navigateTo(venueMenuProductPath(businessId, newProduct.id));
        }
      })
      .catch(() => showActionError(action.actionName));
  };
};
