import { Box, Modal, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { useGetPaymentTerminalOnboardingCode } from 'src/payment-terminal/queries/getPaymentTerminalOnboardingCodeQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  pt: 2,
  px: 4,
  pb: 3,
};

type ProvisioningQrCodeProps = {
  provisioningUrl?: string | null;
  onClose: () => void;
  enrollmentId: EnrollmentId;
  paymentTerminalId?: string;
};

export function ProvisioningQrCodeModal({ onClose, provisioningUrl = null, enrollmentId, paymentTerminalId }: ProvisioningQrCodeProps) {
  const { isPending, error, data } = useGetPaymentTerminalOnboardingCode(enrollmentId, paymentTerminalId, !!provisioningUrl);

  return (
    <Modal open={!!provisioningUrl} onClose={onClose}>
      <Box sx={{ ...styleModal, width: 400 }}>
        <Typography variant="h4" sx={{ marginBottom: '40px' }}>
          <FormattedMessage id="payment.terminal.manage.new.modal.title" />
        </Typography>
        {provisioningUrl && <QRCodeSVG size={250} value={provisioningUrl!} />}

        {isPending && <Typography variant="h6" sx={{ marginTop: '40px' }}><FormattedMessage id="payment.terminal.onboarding.code.generating"/></Typography>}
        {error && <Typography variant="h6" sx={{ marginTop: '40px' }}>Error: {error.message}</Typography>}
        {data && <Typography variant="h6" sx={{ marginTop: '40px' }}>{data}</Typography>}
        {data && <Typography variant="subtitle1"><FormattedMessage id="payment.terminal.onboarding.code.expiry"/></Typography>}

      </Box>
    </Modal>
  );
}
