import { DateRange } from '../domain/DateRange';
import { DimensionsAnalysis } from './domain/DimensionsAnalysis';
import { Review } from '../browse/domain/Review';
import { Instant } from '../../Instant';

function averageForReviewsForDay(reviewsForDay: Review[]): number | undefined {
  function average(numbers: number[]) {
    const sum = numbers.reduce((a, b) => a + b, 0);
    return Math.round((sum / numbers.length) * 100) / 100 || undefined;
  }

  return average(reviewsForDay
    .flatMap((review) => review.rating));
}

export const convertRatingsToTimeseriesData = (ratings: (number | undefined)[], period: DateRange) => {
  return ratings.map((value, index) => ({
    x: period.startDateI()
      .add(index, 'day')
      .valueOf(),
    y: value,
  }));
};

function averagesPerDay(reviewsParDays: Review[][], period: DateRange) {
  return convertRatingsToTimeseriesData(reviewsParDays.map(reviewsParDay => averageForReviewsForDay(reviewsParDay)), period);
}

function datesAreAtTheSameDay(instant1: Instant, instant2: Instant) {
  return instant1.endOfDay()
    .equals(instant2.endOfDay());
}

function groupReviewsPerDay(period: DateRange, reviews: Review[]) {
  return period.allDays()
    .map((dayInPeriod) => reviews.filter((review) => datesAreAtTheSameDay(Instant.fromEpoch(review.creationDate), dayInPeriod)));
}

export type LackOfData = 'LACK_OF_DATA';

export type TimeFrameTooShort = 'TIME_FRAME_TOO_SHORT';

export type AverageRatingData = {
  period: DateRange;
  data: {
    averageRating: { x: number, y: number | undefined }[],
  }
};

export type AverageRatingsViewModel = AverageRatingData | LackOfData | TimeFrameTooShort;

export const averageRatingsViewModel = (dimensionsAnalysis: DimensionsAnalysis, period: DateRange): AverageRatingsViewModel => {
  if (period.numberOfDays() < 5) return 'TIME_FRAME_TOO_SHORT';

  if (dimensionsAnalysis.reviews.length === 0) return 'LACK_OF_DATA';

  const reviewsPerDay = groupReviewsPerDay(period, dimensionsAnalysis.reviews);
  const averageRating = averagesPerDay(reviewsPerDay, period);
  return {
    period,
    data: {
      averageRating,
    },
  };
};

export const isLackOfData = (viewModel: AverageRatingsViewModel): viewModel is LackOfData => viewModel === 'LACK_OF_DATA';
export const isTimeFrameTooShort = (viewModel: AverageRatingsViewModel): viewModel is TimeFrameTooShort => viewModel === 'TIME_FRAME_TOO_SHORT';

export const isTimeFrameTooLong = (viewModel: AverageRatingData): boolean => viewModel.period.numberOfDays() > 14;
