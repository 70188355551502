import { EReputationKpisViewModel, GraphData, RatingsAverageGraphData, RatingsCountGraphData } from '../EReputationViewModel';
import { filterSundayStarsDistributionBySubscriptionDate } from './filterSundayStarsDistributionBySubscriptionDate';
import { KpiTimeframeName } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

const graphDataByType: (eReputationKpisViewModels: EReputationKpisViewModel[], kpiType: KpiName) => GraphData | undefined = (eReputationKpisViewModels: EReputationKpisViewModel[], kpiType: KpiName) =>
  eReputationKpisViewModels.find((d) => d.kpiType === kpiType)?.graphKpis;

export const totalReviewsKpis = (eReputationKpisViewModels: EReputationKpisViewModel[]): RatingsCountGraphData | undefined => {
  const totalReviewsGraphData = graphDataByType(eReputationKpisViewModels, 'TOTAL_REVIEWS');
  return totalReviewsGraphData ? (totalReviewsGraphData as RatingsCountGraphData) : undefined;
};

export const totalFiveStarsKpis = (eReputationKpisViewModels: EReputationKpisViewModel[]): RatingsCountGraphData | undefined => {
  const totalFiveStarsGraphData = graphDataByType(eReputationKpisViewModels, 'TOTAL_FIVE_STARS');
  return totalFiveStarsGraphData ? (totalFiveStarsGraphData as RatingsCountGraphData) : undefined;
};

export const averageRatingKpis: (eReputationKpisViewModels: EReputationKpisViewModel[], subscriptionDate: Date, timeframe: KpiTimeframeName) => RatingsAverageGraphData | undefined
  = (eReputationKpisViewModels: EReputationKpisViewModel[], subscriptionDate: Date, timeframe: KpiTimeframeName) => {
    const averageRatingGraphData = graphDataByType(eReputationKpisViewModels, 'AVG_RATING');
    const ratingsAverageData = averageRatingGraphData ? (averageRatingGraphData as RatingsAverageGraphData) : undefined;
    return ratingsAverageData ? {
      fromGoogle: ratingsAverageData.fromGoogle,
      fromSunday: filterSundayStarsDistributionBySubscriptionDate(ratingsAverageData.fromSunday, subscriptionDate, timeframe),
    } : undefined;
  };
