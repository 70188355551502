import { Money } from '@sundayapp/web-money';
import { Instant } from 'src/sundayplus/Instant';
import { BusinessId } from 'src/business/domain/Business';

export interface Bill {
  billId: string;
  totalBillAmount: Money;
  issuedAt?: Date;
  order: Order;
  leftToPay: Money;
  tab?: Tab;
  sundayBillSplits: SundayBillSplit[];
  updateAt?: Date;
}

export interface Order {
  orderId: string;
  businessId: BusinessId;
  tableId?: string;
  checkNumber?: string;
  staffName?: string;
}

export interface Tab {
  id: string;
  displayId: string;
  userName: string;
  chargeLeftToPayAmountRequestedAt?: Instant;
  nominalDigitalFeeRateForCapture: number;
}
export interface Rate {
  value: number;
}

export interface IssuedSundayBillSplit  {
  discriminant: 'Issued';
}
export interface PendingSundayBillSplit  {
  discriminant: 'Pending';
}

export interface PaidSundayBillSplit  {
  discriminant: 'Paid';
}

export interface RegisteredSundayBillSplit  {
  discriminant: 'Registered';
}

export type SundayBillSplit =
  | IssuedSundayBillSplit
  | PendingSundayBillSplit
  | PaidSundayBillSplit
  | RegisteredSundayBillSplit;

export function isPaid(split: SundayBillSplit): split is PaidSundayBillSplit {
  return split.discriminant === 'Paid';
}

export function isRegistered(split: SundayBillSplit): split is RegisteredSundayBillSplit {
  return split.discriminant === 'Registered';
}

export enum BillStatus {
  NOT_PAID,
  PARTIALLY_PAID,
  SETTLED,
  OVERPAID,
  EMPTY,
}

export const billStatus = (bill: Bill): BillStatus => {
  if (bill.totalBillAmount.amount === 0) {
    return BillStatus.EMPTY;
  }

  if (bill.leftToPay.amount < 0) {
    return BillStatus.OVERPAID;
  }
  if (bill.leftToPay.amount === 0) {
    return BillStatus.SETTLED;
  }

  if (bill.leftToPay.amount < bill.totalBillAmount.amount) {
    return BillStatus.PARTIALLY_PAID;
  }
  return BillStatus.NOT_PAID;
};
