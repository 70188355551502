import { Box, Stack, TableBody, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { EmailCell, EmailTable } from '../EmailTable';
import { KpiString } from '../KpiString';
import { palette } from '../../design/palette';
import { StaffData } from '../../domain/TemplatesData';

export const StaffPerformance = ({
  staffVenueKpis,
  countryCode,
}: {
  staffVenueKpis: StaffData;
  countryCode: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <EmailTable
        legend={{
          title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.staff_performance' }),
          legends: [
            {
              imagePath: staffVenueKpis.p10ImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.staff_performance.p10' }),
            },
            {
              imagePath: staffVenueKpis.medianImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.staff_performance.median' }),
            },
            {
              imagePath: staffVenueKpis.p90ImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.staff_performance.p90' }),
            },
          ],
          additional: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.staff_performance.legend' })
            .split('{linebreak}')
            .map((s) => s),
        }}
        headCellTitle={[
          { title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.sunday_share' }), width: '40%' },
          { title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.basket_size' }) },
          { title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.sunday_rating' }) },
        ]}
      >
        <TableBody>
          {staffVenueKpis.staffs.map((data, index) => (
            <TableRow key={data.name} style={{ breakInside: 'avoid' }}>
              <EmailCell align="bottom">
                <Stack display="flex" flexDirection="column">
                  <Stack display="flex" flexDirection="row" gap={2}>
                    <Typography color={palette.primary.electricPink}>{`${index + 1}`.padStart(2, '0')}</Typography>
                    <Typography color={palette.primary.black} position="absolute" paddingLeft="24px">
                      {data.name}
                      {' '}
                      <span style={{ whiteSpace: 'nowrap' }}>
                        (
                        {formatMessage({ id: 'venuekpi.weeklyreport.multi.email.server' })}
                        {' '}
                        {data.waiterCount}
                        )
                      </span>
                    </Typography>
                  </Stack>
                  <Box>
                    <KpiString
                      imagePath={staffVenueKpis.p10ImgPath}
                      value={data.p10AdoptionWaiter}
                      valueType="%"
                      fluctuation={data.p10AdoptionWaiterFluctuation}
                      countryCode={countryCode}
                      color={palette.primary.black}
                      fluctuationType=" pts"
                    />
                    <KpiString
                      imagePath={staffVenueKpis.medianImgPath}
                      value={data.p50AdoptionWaiter}
                      valueType="%"
                      fluctuation={data.p50AdoptionWaiterFluctuation}
                      countryCode={countryCode}
                      color={palette.primary.black}
                      fluctuationType=" pts"
                    />
                    <KpiString
                      imagePath={staffVenueKpis.p90ImgPath}
                      value={data.p90AdoptionWaiter}
                      valueType="%"
                      fluctuation={data.p90AdoptionWaiterFluctuation}
                      countryCode={countryCode}
                      color={palette.primary.black}
                      fluctuationType=" pts"
                    />
                  </Box>
                </Stack>
              </EmailCell>
              <EmailCell align="bottom">
                <Box>
                  <KpiString
                    imagePath={staffVenueKpis.p10ImgPath}
                    value={data.p10BasketWaiter}
                    fluctuation={data.p10BasketWaiterFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                  <KpiString
                    imagePath={staffVenueKpis.medianImgPath}
                    value={data.p50BasketWaiter}
                    fluctuation={data.p50BasketWaiterFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                  <KpiString
                    imagePath={staffVenueKpis.p90ImgPath}
                    value={data.p90BasketWaiter}
                    fluctuation={data.p90BasketWaiterFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                </Box>
              </EmailCell>
              <EmailCell align="bottom">
                <Box>
                  <KpiString
                    imagePath={staffVenueKpis.p10ImgPath}
                    value={data.p10RatingWaiter}
                    fluctuation={data.p10RatingWaiterFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType=" pts"
                  />
                  <KpiString
                    imagePath={staffVenueKpis.medianImgPath}
                    value={data.p50RatingWaiter}
                    fluctuation={data.p50RatingWaiterFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType=" pts"
                  />
                  <KpiString
                    imagePath={staffVenueKpis.p90ImgPath}
                    value={data.p90RatingWaiter}
                    fluctuation={data.p90RatingWaiterFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType=" pts"
                  />
                </Box>
              </EmailCell>
            </TableRow>
          ))}
        </TableBody>
      </EmailTable>
    </>
  );
};
