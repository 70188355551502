// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ConfigurationItemColumn } from './ConfigurationItemColumn';


export type ConfigurationItemLastUpdatedProps = {
  lastUpdated: Date;
  active: boolean;
};

const ConfigurationItemLastUpdated = ({ lastUpdated, active }: ConfigurationItemLastUpdatedProps) => {
  const intl = useIntl();

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;

  return (
    <ConfigurationItemColumn>
      {lastUpdated && (
        <Text size="medium" color={active ? colorUsage.secondaryText : colorUsage.disabledText}>
          {intl.formatMessage({ id: 'payment.terminal.settings.last_updated' }) + ' ' + lastUpdated.toLocaleDateString(intl.locale, options)}
        </Text>
      )}
    </ConfigurationItemColumn>
  );
};
export default ConfigurationItemLastUpdated;
