import { Box, Paper, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

type PropositionValueProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  image: {
    src: string;
    alt: string;
  };
};

const PropositionValue = ({ title, description, image }: PropositionValueProps) => (
  <Stack display="flex" flexDirection="row" gap="1.2rem">
    <Box>
      <img src={image.src} alt={image.alt} height={24} />
    </Box>
    <Box display="flex" flexDirection="column" gap="0.25rem">
      <Typography variant="h6">{title}</Typography>
      <Typography color="text.secondary">{description}</Typography>
    </Box>
  </Stack>
);

export const TipsPromotionPage = () => {
  return (
    <Paper style={{ padding: '2rem', borderRadius: '24px' }}>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap="2rem">
        <Stack>
          <Box display="flex" flexDirection="column" gap="3rem">
            <Typography variant="h3">
              <FormattedMessage id="sunday_tipping.promotion.values.title" />
            </Typography>
            <PropositionValue
              title={<FormattedMessage id="sunday_tipping.promotion.value1.title" />}
              description={<FormattedMessage id="sunday_tipping.promotion.value1.description" />}
              image={{
                src: '/img/tips/lightning-02.svg',
                alt: 'lightning bolt',
              }}
            />
            <PropositionValue
              title={<FormattedMessage id="sunday_tipping.promotion.value2.title" />}
              description={<FormattedMessage id="sunday_tipping.promotion.value2.description" />}
              image={{
                src: '/img/tips/safe.svg',
                alt: 'safe',
              }}
            />
            <PropositionValue
              title={<FormattedMessage id="sunday_tipping.promotion.value3.title" />}
              description={<FormattedMessage id="sunday_tipping.promotion.value3.description" />}
              image={{
                src: '/img/tips/file-check-02.svg',
                alt: 'file check',
              }}
            />
            <PropositionValue
              title={<FormattedMessage id="sunday_tipping.promotion.value4.title" />}
              description={<FormattedMessage id="sunday_tipping.promotion.value4.description" />}
              image={{
                src: '/img/tips/face-smile.svg',
                alt: 'face smile',
              }}
            />
          </Box>
        </Stack>
        <Stack>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="2rem">
            <img src="/signup-illustration.jpeg" alt="illustration" height="600px" />
          </Box>
        </Stack>
      </Box>
    </Paper>
  );
};
