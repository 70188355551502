import { Card, CardHeader, Switch, Typography } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { PsoConnectionConfiguration } from '../../domain/PaymentTerminalConfiguration';

export type PosConnectionSettingsProps = {
  posConnectionConfig: PsoConnectionConfiguration;
  onPosConnectionConfigUpdated: (configuration: PsoConnectionConfiguration) => void;
};

function PosConnectionForm({ posConnectionConfig, onPosConnectionConfigUpdated }: PosConnectionSettingsProps) {
  const intl = useIntl();
  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader
        title={intl.formatMessage({ id: 'payment.terminal.settings.scan.title' })}
        subheader={
          <Typography paddingTop="8px">
            {intl.formatMessage({ id: 'payment.terminal.settings.scan.subtitle' })}
          </Typography>
        }
        action={
          <Switch
            checked={posConnectionConfig.enabled}
            onChange={(e) => onPosConnectionConfigUpdated({ ...posConnectionConfig, enabled: e.target.checked })}
          />
        }
      />
    </Card>
  );
}

export default PosConnectionForm;
