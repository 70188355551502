import { IntlShape } from 'src/app/i18n/TypedIntl';
import { useAsync } from 'react-use';
import React from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { Reviews } from '../../domain/Reviews';
import { Review } from '../../domain/Review';
import { ReplyTemplate } from '../../../reply-template/domain/ReplyTemplate';
import { HttpReplyTemplateRepository } from '../../../reply-template/infrastructure/HttpReplyTemplateRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ALLOWED_VARIABLE_CUSTOMER_NAME } from '../../../reply-template/domain/ReplyTemplateUtils';
import { ReviewCard } from './reviewCard/ReviewCard';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

interface Props {
  reviews: Reviews;
}

export const defaultReplyTemplate = (venueName: string, intl: IntlShape) => {
  const author = ALLOWED_VARIABLE_CUSTOMER_NAME;

  const defaultReplyTemplates: ReplyTemplate[] = [
    {
      id: 'happy-client-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.happy-client-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.happy-client-1.content' },
        {
          customer_name: author,
          venue_name: venueName,
        },
      ),
      applicableRatings: [4, 5],
      prefilled: false,
    },
    {
      id: 'happy-client-4',
      name: intl.formatMessage({ id: 'reviews.reply.template.happy-client-4.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.happy-client-4.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [4, 5],
      prefilled: false,
    },
    {
      id: 'great-cuisine-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.great-cuisine-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.great-cuisine-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [4, 5],
      prefilled: false,
    },
    {
      id: 'great-service-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.great-service-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.great-service-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [4, 5],
      prefilled: false,
    },
    {
      id: 'nice-atmosphere-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.nice-atmosphere-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.nice-atmosphere-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [4, 5],
      prefilled: false,
    },
    {
      id: 'bad-rating-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.bad-rating-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.bad-rating-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [1, 2, 3],
      prefilled: false,
    },
    {
      id: 'bad-service-staff-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.bad-service-staff-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.bad-service-staff-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [1, 2, 3],
      prefilled: false,
    },
    {
      id: 'bad-service-waiting-food-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.bad-service-waiting-food-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.bad-service-waiting-food-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [1, 2, 3],
      prefilled: false,
    },
    {
      id: 'bad-service-unwelcoming-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.bad-service-unwelcoming-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.bad-service-unwelcoming-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [1, 2, 3],
      prefilled: false,
    },
    {
      id: 'poor-atmosphere-1',
      name: intl.formatMessage({ id: 'reviews.reply.template.poor-atmosphere-1.title' }),
      content: intl.formatMessage(
        { id: 'reviews.reply.template.poor-atmosphere-1.content' },
        { customer_name: author, venue_name: venueName },
      ),
      applicableRatings: [1, 2, 3],
      prefilled: false,
    },
  ];

  return defaultReplyTemplates;
};

export function ReviewCards({ reviews }: Props) {
  const business = useCurrentBusinessOrThrow();
  const replyTemplateRepository = new HttpReplyTemplateRepository(ConfigurationLoader.load().venueFeedbackBaseUrl);

  async function getReplyTemplates(): Promise<ReplyTemplate[]> {
    return replyTemplateRepository.list(business.id);
  }

  const templates = useAsync(async () => getReplyTemplates()).value;

  return (
    <>
      {templates && (
        <Stack direction="column" gap={1.5}>
          {reviews.asArray().map((review: Review) => (
            <Card key={review.id}>
              <CardContent>
                <ReviewCard review={review} />
              </CardContent>
            </Card>
          ))}
        </Stack>
      )}
    </>
  );
}
