import * as React from 'react';
import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';

type ImageContainerProps = {
  backgroundColor: string;
};
export const ImageContainer = styled.div<ImageContainerProps>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${spaceUsages.largeSmall};
  display: flex;
  justify-content: center;
`;

export const ReadyStep = () => (
  <ImageContainer backgroundColor="#FF17E9">
    <img src="/img/sunday-for-restaurants/ready.svg" height={150} alt="Ready image" />
  </ImageContainer>
);
