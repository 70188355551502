import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';

type BoxDeletionConfirmationModalProps = {
  boxName: string;
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const MessageContainer = styled.div`
  color: ${colorPalette.placeholderColor};
  margin: 0 0 ${getSpacing(3)};
`;

export const BoxDeletionConfirmationModal = ({
  boxName,
  isOpen,
  onCancel,
  onConfirm,
}: BoxDeletionConfirmationModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      maxWidth="407px"
      isOpened={isOpen}
      onClose={onCancel}
      title={intl.formatMessage({
        id: 'foodcourt.box.deletion.title',
        defaultMessage: 'delete box',
      })}
      cancelButtonMessage={intl.formatMessage({ id: 'foodcourt.box.deletion.cancel', defaultMessage: 'cancel' })}
    >
      <MessageContainer>
        <FormattedMessage
          id="foodcourt.box.deletion.description"
          defaultMessage="are you sure you want to delete {boxName}"
          values={{ boxName }}
        />
      </MessageContainer>
      <Button variant="warning" size="medium" fullWidth onClick={onConfirm}>
        <FormattedMessage id="foodcourt.box.deletion.confirm" defaultMessage="yes, i want to delete this box" />
      </Button>
    </Modal>
  );
};
