import { HeadActions } from 'src/home2/types/layout_types';
import { ActionsWrapper, PageActions } from '@styles/Page/PageLayoutStyle';

export const ListActions = (headActions: HeadActions) => {
  if (!headActions.children) {
    return null;
  }
  
  return (
    <PageActions>
      <ActionsWrapper>
        {headActions.children}
      </ActionsWrapper>
    </PageActions>
  );
};
