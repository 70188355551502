
import { PaymentButton } from './Actions/ActionPayment';
import { InvoiceButton } from './Actions/ActionInvoice';
import { ReportingButton } from './Actions/ActionReporting';
import { OrderQrCodeButton } from './Actions/ActionOrderQrCode';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Box } from '@mui/material';

export const HomeActions = () => {
  const business = useCurrentBusinessOrThrow();

  const isUSVenue = business.address.countryCode === 'US';

  return (
    <Box display="flex" gap={2} marginBottom={2}>
      <PaymentButton business={business} />
      {!isUSVenue && (  <InvoiceButton business={business} />   )}
      <ReportingButton business={business} />
      <OrderQrCodeButton business={business} />
    </Box>
  );
};