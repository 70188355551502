import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { breakpoints, colorUsage, spaceUsages, Stats, Text } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { Money, MoneyView } from '@sundayapp/web-money';
import { CustomMoneyView } from './CustomMoneyView';
import { SchemeSummary } from '../infrastructure/SummaryOnAPeriod';
import { Card, CardContent } from '@mui/material';

const PaymentTerminalWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

const ContentWrapper = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface SchemeSummaryViewProps {
  schemeSummary: SchemeSummary;
}

const SchemeSummaryView = ({ schemeSummary }: SchemeSummaryViewProps) => {
  const { formatMessage } = useIntl();
  return (
    <PaymentTerminalWrapper>
      <Stats
        dataLabel={schemeSummary.scheme}
        amount={<CustomMoneyView value={schemeSummary.grossRevenue} />}
        padding={spaceUsages.mediumLarge}
        data-testid={`payment-terminal-${schemeSummary.scheme}-revenue`}
      />
      <Stats
        dataLabel={`${schemeSummary.scheme} ${formatMessage({ id: 'accounting.tips' })}`}
        amount={<CustomMoneyView value={schemeSummary.tipRevenue} />}
        padding={spaceUsages.mediumLarge}
        data-testid={`payment-terminal-${schemeSummary.scheme}-tips`}
      />
    </PaymentTerminalWrapper>
  );
};

interface PaymentTerminalSummaryProps {
  schemeSummaries: SchemeSummary[];
  total: Money;
}

export const PaymentTerminalSummary = ({ schemeSummaries, total }: PaymentTerminalSummaryProps) => (
  <Card>
    <CardContent>
      <Text
        size="medium"
        color={colorUsage.secondaryText}
        mr={spaceUsages.small}
        marginBottom={spaceUsages.mediumLarge}
        data-testid="payment-terminal-scheme-stats"
      >
        <FormattedMessage id="accounting.reporting.nepting.details" defaultMessage="payment terminals" />
      </Text>

      <Text size="xxlarge" marginBottom={spaceUsages.mediumLarge}>
        <MoneyView value={total} data-testid="payment-terminal-total-revenue" />
      </Text>

      <ContentWrapper>
        {schemeSummaries.map((schemeSummary) => (
          <SchemeSummaryView schemeSummary={schemeSummary} key={schemeSummary.scheme} />
        ))}
      </ContentWrapper>
    </CardContent>
  </Card>
);
