import { Dialog, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import React from 'react';
import { CheckCircle } from '@mui/icons-material';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const DispatchSuccessDialog = ({ opened, onClose }: {
  opened: boolean;
  onClose: () => void
}) => {
  return <Dialog open={opened} fullWidth maxWidth={'xs'} onClose={onClose}>
    <DialogTitle>
      <Stack sx={{ cursor: 'pointer' }} onClick={onClose} direction={'row'} justifyContent={'flex-end'}>
        <CrossIcon />
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Stack direction={'column'} alignItems={'center'} spacing={2} paddingBottom={2}>
        <CheckCircle style={{ fontSize: 64 }} color={'success'} />
        <Typography variant={'h4'}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.success'} />
        </Typography>
        <Typography variant={'body1'} align={'center'}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.explain'} />
        </Typography>
      </Stack>
    </DialogContent>
  </Dialog>;
};
