import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { Logo } from '../domain/Logo';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';

export const useSaveLogo = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();
  const mutation = useMutation({
    mutationFn: ({ enrollmentId, logo }: { enrollmentId: EnrollmentId; logo: Logo }) =>
      customizationRepository.saveLogo(enrollmentId, logo),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION] }),
  });
  return {
    mutation,
    saveLogo: (enrollmentId: EnrollmentId, logo: Logo) => mutation.mutateAsync({ enrollmentId, logo }),
  };
};
