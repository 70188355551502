import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { LocalisationKey } from 'src/lang/en';

export class LocalisedError extends Error {
  localizationKey: LocalisationKey;

  constructor(localizationKey: LocalisationKey) {
    super(`error with localization key: ${localizationKey}`);
    this.localizationKey = localizationKey;
  }
}

interface Props {
  error?: Error;
}

const ErrorContainerElement = styled.div`
  padding: ${getSpacing(2)} ${getSpacing(3)};
  margin: ${getSpacing(1)} ${getSpacing(-3)} ${getSpacing(3)};
  background-color: ${colorPalette.lightRed};
`;

const ErrorContainer: React.FC<Props> = ({ error }) => {
  if (!error) {
    return null;
  }
  if (error instanceof LocalisedError) {
    return <ErrorContainerElement><FormattedMessage id={error.localizationKey} /></ErrorContainerElement>;  
  }
  return <ErrorContainerElement>{error.message}</ErrorContainerElement>;
};

export default ErrorContainer;
