import React from 'react';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { PaymentList } from './PaymentList';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

import { isPaymentTerminalAvailable } from 'src/business/domain/Business';

export const Payments = () => {
  const business = useCurrentBusinessOrThrow();
  // react query to check if venue has TPE feature (would be nice to use venue-referential for that)
  if (business.posReference === 'NO_POS') {
    return <NoPos />;
  }

  return (
    <PaymentList business={business} tpeVenue={isPaymentTerminalAvailable(business)} />
  );
};
