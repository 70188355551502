import { NoPos } from 'src/pages/NoPos/NoPos';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { OPOrdersList } from 'src/ordering/orders';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const OrderListPage = () => {
  const business = useCurrentBusinessOrThrow();

  const venueIsNoPos = useBusinessIsNoPos();
  if (venueIsNoPos) return <NoPos />;

  if (!business.oapEnrollment) {
    return <>Order and pay not enabled</>;
  }

  return <OPOrdersList enrollmentId={business.oapEnrollment.id} />;
};
