import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Grid, Stack, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Divider from '@mui/material/Divider';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { isApplicableTo5Stars, ReplyTemplate } from '../../reply-template/domain/ReplyTemplate';
import { Review } from '../../browse/domain/Review';
import { useReplyToReview } from '../domain/useReplyToReview';
import { generateReplyTextFromTemplate } from '../../reply-template/domain/ReplyTemplateUtils';
import { failure, isFailure, isSuccess, Result, success } from '../../../lib/Result';
import { BulkReplyViewModel, selectedTemplates, unSelectedTemplates } from './BulkReplyViewModel';
import { TimeframeSelection } from '../TimeframePicker';
import { ampli } from 'src/ampli';
import { useListReplyTemplatesForBusiness } from '../domain/useListReplyTemplatesForBusiness';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const useLoadBulkReplyViewModel: () => Promise<BulkReplyViewModel> = () =>
  useListReplyTemplatesForBusiness()
    .then((t) => t.filter(isApplicableTo5Stars))
    .then((t) => ({ replyTemplates: t, selected: t.map(() => true) }));

export const BulkReply = ({
  reviews,
  onCancel,
  onBulkCompleted,
  selectedTimeFrame,
}: {
  reviews: Review[];
  onCancel: () => void;
  onBulkCompleted: (result: Result<Review, Error>[]) => void;
  selectedTimeFrame: TimeframeSelection;
}) => {
  const business = useCurrentBusinessOrThrow();
  const [viewModel, setViewModel] = useState<BulkReplyViewModel | undefined>(undefined);
  const [replying, setReplying] = useState<boolean>(false);
  const loadViewModel = useLoadBulkReplyViewModel();
  const theme = useTheme();

  useEffect(() => {
    loadViewModel.then(setViewModel);
  }, []);

  const replyToReview = useReplyToReview();

  if (!viewModel) return <CircularProgress />;

  const replyToReviewsWithTemplates = (temps: ReplyTemplate[]) => (rs: Review[]) => {
    const offset: number = Math.floor(Math.random() * temps.length);
    return Promise.all(
      rs.map((review, i) =>
        replyToReview(
          review,
          generateReplyTextFromTemplate(temps[(i + offset) % temps.length], {
            review,
            business,
          }),
        )
          .then((r) => success(r) as Result<Review, Error>)
          .catch((e: Error) => failure(e) as Result<Review, Error>),
      ),
    ).then((results) => {
      onBulkCompleted(results);
      return results;
    });
  };

  const switchSelectionAtIndex = (bulkReplyViewModel: BulkReplyViewModel, index: number): BulkReplyViewModel => ({
    replyTemplates: bulkReplyViewModel.replyTemplates,
    selected: [
      ...bulkReplyViewModel.selected.slice(0, index),
      !bulkReplyViewModel.selected[index],
      ...bulkReplyViewModel.selected.slice(index + 1),
    ],
  });

  const replyToReviews = replyToReviewsWithTemplates(selectedTemplates(viewModel));

  const onClick = () => {
    setReplying(true);
    ampli.bulkReplyClicked({
      numberOfDays: selectedTimeFrame.numberOfDays,
      numberOfSelectedTemplates: selectedTemplates(viewModel).length,
      numberOfUnselectedTemplates: unSelectedTemplates(viewModel).length,
    });
    return replyToReviews(reviews)
      .then((results) => {
        ampli.bulkReplyCompleted({
          numberOfRepliesSent: results.filter(isSuccess).length,
          numberOfRepliesInError: results.filter(isFailure).length,
        });
      })
      .finally(() => setReplying(false));
  };

  return (
    <>
      <Stack direction="column" gap="1rem" padding="1.5rem 1.5rem 1rem 1.5rem" marginBottom="1rem">
        <div>
          <Typography variant="h6" marginBottom="1rem">
            <FormattedMessage id="venue.reviews.reply.bulk.modal.title" />
          </Typography>
          <Divider />
        </div>
        <Stack gap="0.5rem" marginBottom="0.5rem">
          <Typography variant="subtitle1">
            <FormattedMessage
              id="venue.reviews.reply.bulk.modal.subtitle"
              values={{ number_of_reviews: reviews.length }}
            />
          </Typography>
          <Typography variant="caption" color={theme.palette.text.secondary}>
            <FormattedMessage id="venue.reviews.reply.bulk.modal.caption" />
          </Typography>
        </Stack>
        <Stack maxHeight="50vh" direction="column" overflow="auto">
          {viewModel.replyTemplates.map((template, i) => (
            <Box
              key={template.id}
              onClick={() => setViewModel(switchSelectionAtIndex(viewModel!, i))}
              sx={{ cursor: 'pointer' }}
            >
              <Grid container alignItems="center">
                <Grid item xs={1}>
                  <Checkbox
                    checked={viewModel.selected[i]}
                    onChange={() => setViewModel(switchSelectionAtIndex(viewModel!, i))}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>{template.name}</Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <Typography
                    variant="body2"
                    color={viewModel.selected[i] ? theme.palette.text.secondary : theme.palette.text.disabled}
                  >
                    {template.content}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Stack>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          backgroundColor: 'background.default',
          borderRadius: '0 0 1rem 1rem',
          padding: '1rem 1.5rem 1rem 1.5rem ',
        }}
      >
        <LoadingButton
          variant="contained"
          onClick={onClick}
          disabled={viewModel.selected.filter((s) => s).length === 0}
          loading={replying}
        >
          <FormattedMessage id="venue.reviews.reply.bulk.modal.button" />
        </LoadingButton>
        <Button onClick={onCancel}>
          <FormattedMessage id="venue.reviews.reply.bulk.modal.cancel" />
        </Button>
      </Stack>
    </>
  );
};
