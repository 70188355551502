import React, { forwardRef, useImperativeHandle } from 'react';
import { StepProps } from 'src/initial-setup/components/steps/StepProps';
import { StepRef } from 'src/initial-setup/components/steps/StepRef';
import { GoogleReviewSetupTile } from 'src/sundayplus/reviews/settings/setup/google/components/GoogleReviewSetupTile';
import { Box } from '@mui/material';
import { useGoogleSetupStage } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStage';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';


const ConnectGoogleAccountStep = forwardRef<StepRef, StepProps>(({ onValidChanged }, ref) => {

  useImperativeHandle(ref, () => ({
    submit: () => null,
  }));

  const businessId = useBusinessIdOrThrow();
  const { status } = useGoogleSetupStage(businessId);

  if (status === 'success') {
    onValidChanged(true);
  }

  return (
    <Box m={2}>
      <GoogleReviewSetupTile isInitialSetup={true} />
    </Box>);
});

ConnectGoogleAccountStep.displayName = 'ConnectGoogleAccountStep';

export default ConnectGoogleAccountStep;
