import { Order, OrderSummary } from '../types';
import { getEndOfDay } from '../../common/utils/getEndOfDay';
import { OrderingNavigation } from '../../common/OrderingNavigation';
import { BusinessId } from 'src/business/domain/Business';

export const getPathToOrderPayments = (businessId: BusinessId, order: OrderSummary | Order, navigation: OrderingNavigation) => {
  const startDate = new Date(order.placedAtEpochMilli!);
  startDate.setMilliseconds(0);
  startDate.setSeconds(0);
  startDate.setMinutes(0);
  startDate.setHours(0);
  const endDate = getEndOfDay(startDate);

  navigation.goToPayments(businessId, order.id, order.displayId!, startDate.getTime(), endDate.getTime());
};
