import { isOnPdq, Payment, Payments, serviceChargesOrZero } from './Payments';
import { CurrencyCode } from '@sundayapp/web-money';
import { Reporting } from './ReportingDigest';
import { paymentHasRevenueCenter } from './createReportingDigest';
import { chain } from 'lodash';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { EndOfServiceConfiguration } from './EndOfServiceConfiguration';
import { isCnp } from './products';

function nameSort(a: Reporting, b: Reporting): number {
  if (a.name === 'sales_summary.no_waiter') {
    return 1;
  }
  if (b.name === 'sales_summary.no_waiter') {
    return -1;
  }
  if (a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()) {
    return -1;
  }
  if (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()) {
    return 1;
  }
  return 0;
}

function filterPaymentsByProductPredicate(eosConfiguration: EndOfServiceConfiguration) {
  return (payment: Payment) =>
    eosConfiguration.displayPaymentTerminal
      ? isCnp(payment.origin) || isOnPdq(payment)
      : isCnp(payment.origin);
}

export const toWaitersProductsReporting = (
  eosConfiguration: EndOfServiceConfiguration,
  payments: Payments,
  currency: CurrencyCode,
  revenueCentersSelected: string[] = [],
  detailsByProductMapper: (
    eosConfiguration: EndOfServiceConfiguration,
    payments: Payment[],
    currency: CurrencyCode,
  ) => Reporting[] = () => [],
): Reporting[] => {
  const filteredPayments = payments.payments
    .filter((payment) => paymentHasRevenueCenter(revenueCentersSelected, payment))
    .filter(filterPaymentsByProductPredicate(eosConfiguration))
    .map(
      (payment): Payment => ({
        ...payment,
        waiterName:
          !payment.waiterName || payment.waiterName === 'null' ? 'sales_summary.no_waiter' : payment.waiterName.trim(),
      }),
    );

  return chain(filteredPayments)
    .groupBy('waiterName')
    .toPairs()
    .map(([waiterName, waiterPayments]) => {
      const waiterRevenue = safeSumMoney(
        waiterPayments.map((payment) => payment.salesAmount),
        currency,
      );
      const waiterTips = safeSumMoney(
        waiterPayments.map((payment) => payment.tipsAmount),
        currency,
      );
      const sundayTips = safeSumMoney(
        waiterPayments.map((payment) => payment.sundayTipsAmount),
        currency,
      );
      const waiterServiceCharges = safeSumMoney(
        waiterPayments.map((payment) => serviceChargesOrZero(payment, currency)),
        currency,
      );
      return {
        name: waiterName,
        numberOfPayment: waiterPayments.length,
        revenue: waiterRevenue,
        tips: waiterTips,
        sundayTips: sundayTips,
        serviceCharges: waiterServiceCharges,
        total: safeSumMoney([waiterRevenue, waiterServiceCharges, waiterTips], currency),
        details: detailsByProductMapper(eosConfiguration, waiterPayments, currency),
      };
    })
    .value()
    .sort(nameSort);
};
