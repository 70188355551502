import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Stack } from '@mui/material';
import { TimeframeChoice } from './TimeframeChoice';
import { getEReputationPageStatus, setPanelChosen, setTimeframeChosen } from 'src/store/e-reputation';
import { PanelsAndGraph } from './PanelsAndGraph';
import { SundaySubscriptionDate } from './SundaySubscriptionDate';
import { EReputationKpisViewModelByTimeframe } from './EReputationViewModel';
import { ampli } from 'src/ampli';
import { KpiTimeframeName } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

type Props = {
  subscriptionDate?: number;
  eReputationKpisViewModelByTimeframe: EReputationKpisViewModelByTimeframe[];
};
export const EReputation = ({
  subscriptionDate,
  eReputationKpisViewModelByTimeframe,
}: Props) => {
  const eReputationPageChoices = useSelector(getEReputationPageStatus);
  const dispatch = useDispatch();

  const trackGraphSelected = (period: KpiTimeframeName, kpiType: KpiName) => {
    const graphSelected = `${(period)}_${kpiType}`;
    ampli.eReputationGraphSelected({
      graphSelected,
      kpiType,
      period: period,
    });
  };

  useEffect(() => {
    trackGraphSelected(eReputationPageChoices.timeFrame, eReputationPageChoices.panel);
  }, []);

  const onTimeFrameChange = (newTimeframe: KpiTimeframeName) => {
    trackGraphSelected(newTimeframe, eReputationPageChoices.panel);
    return dispatch(setTimeframeChosen(newTimeframe));
  };

  const onKpiChange = (newKpi: KpiName) => {
    trackGraphSelected(eReputationPageChoices.timeFrame, newKpi);
    return dispatch(setPanelChosen(newKpi));
  };

  // Fallback to today if the subscription date is undefined
  // We're not sure if it can happen, it depends on historical data on the backend
  // We may want to rework the types (once we've checked the data on the backend),
  // /and force always having a date if the Google setup is finished
  const subscriptionDateOrFallbackToToday = () => subscriptionDate ?? new Date().getTime();

  return (
    <Grid container direction="column" spacing={3}>
      {subscriptionDate && (
        <Grid item>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
          >
            <SundaySubscriptionDate subscriptionDate={subscriptionDate} />
          </Stack>
        </Grid>
      )}
      <Grid item>
        <TimeframeChoice
          timeframeKpiType={eReputationPageChoices.timeFrame}
          onChange={(newTimeframe) => onTimeFrameChange(newTimeframe)}
        />
        <PanelsAndGraph
          kpiType={eReputationPageChoices.panel}
          onChange={(newKpiType) => onKpiChange(newKpiType)}
          eReputationKpisViewModels={
            eReputationKpisViewModelByTimeframe!.find((i: EReputationKpisViewModelByTimeframe) => i.timeframe.name() === eReputationPageChoices.timeFrame)!
              .eReputationKpisViewModels
          }
          subscriptionDate={subscriptionDateOrFallbackToToday()}
        />
      </Grid>
    </Grid>
  );
};
