import React, { useEffect } from 'react';
import { Box, Switch } from '@mui/material';
import { useMenuRepository } from 'src/menu/dynamic-menu/configuration/MenuContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

interface Props {
  businessId: BusinessId;
  menuId: string;
}

export const MenuFeatureConfiguration = ({
  businessId,
  menuId,
}: Props) => {

  const repo = useMenuRepository();

  const [payAtTableEnabled, setPayAtTableEnabled] = React.useState<boolean | undefined>(undefined);

  useEffect(() => {
    repo.findMenuFeatureConfiguration(businessId, menuId)
      .then((conf) => setPayAtTableEnabled(conf?.sundayFeatures.some((feat) => feat === 'PAY_AT_TABLE')));
  }, [repo.findMenuFeatureConfiguration, businessId, menuId]);

  if (payAtTableEnabled === undefined) {
    return null;
  }

  return (
    <Box flexDirection="row" display="flex" rowGap={1} columnGap={1}>
        <Switch
          checked={payAtTableEnabled}
          onChange={(e) => {
            return (e.target.checked
              ? repo.enablePayAtTable(businessId, menuId)
              : repo.disablePayAtTable(businessId, menuId))
              .then(features => setPayAtTableEnabled(features.some((feat) => feat === 'PAY_AT_TABLE')));
          }}
        />
      <FormattedMessage id="menus.settings.active_on_PAT" defaultMessage="Visible on Pay At Table" />
    </Box>
  );
};
