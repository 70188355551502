import { useQuery } from '@tanstack/react-query';
import { datadogLogs } from '@datadog/browser-logs';
import { MerchantAnalyticsRepository } from '../infrastructure/MerchantAnalyticsRepository';
import { WeeklyReportOrganization } from '../domain/WeeklyReportOrganization';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { weeklyReportOrganizationFixture } from '../domain/WeeklyReportOrganizationFixture';

const getWeeklyReportOrganizationQuery = (weeklyReportOrganization: Promise<WeeklyReportOrganization[]>) => ({
  queryKey: ['organizationReport'],
  queryFn: (): Promise<WeeklyReportOrganization[]> => weeklyReportOrganization
    .then((r) => r)
    .catch((e) => {
      datadogLogs.logger.warn(`impossible to fetch weekly report organizations ${e}`);
      return [];
    }),
});

export const useReportOrganization = (venueIds: string[]) => {
  const configuration = ConfigurationLoader.load();
  if (configuration.envName !== 'production') {
    return { data: [weeklyReportOrganizationFixture], isLoading: false };
  }

  const weeklyReportOrganizationsPromise = new MerchantAnalyticsRepository().getWeeklyReportOrganizations(venueIds);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useQuery(getWeeklyReportOrganizationQuery(weeklyReportOrganizationsPromise));
};
