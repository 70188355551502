export const fontFamily = "'Helvetica Neue', HelveticaNeue, Roboto, 'Liberation Sans', 'Nimbus Sans L', Helvetica, 'Arial', sans-serif";

export const colorPalette = {
  pageBackground: '#fcfcfd',
  primary: '#FFB9F8',
  colorOnPrimaryBackground: '#000000',
  defaultTextColor: '#000000',
  defaultBackgroundColor: '#FFFFFF',
  lightBorderColor: '#EBECF1',
  toggleBackgroundColor: '#E5E5E5',
  primaryBackgroundColor: '#000000',
  primaryForegroundColor: '#FFFFFF',
  overlayShadow: 'rgba(76,76,76, 0.6)',
  disabledBackgroundColor: '#9898A1',
  inputBorderColor: '#f9f9fa',
  placeholderColor: '#9898A1',
  lightPrimary: '#FFF5FE',
  grey: '#F9F9FA',
  grey100: '#F6F6F9',
  grey200: '#EDEDF2',
  grey300: '#DDDDE4',
  grey400: '#B4B4BB',
  grey500: '#70707B',
  grey600: '#424248',
  lightGrey: '#EDEDF2',
  lightText: '#00000066',
  lightGreen: '#D1FFDD',
  lightBlue: '#D1F1FF',
  peach: '#FFF4D1',
  lightRed: '#FFD1DC',
  darkMask: 'rgba(0, 0, 0, 0.75)',
  blue: '#0288D1',
  green: '#336B42',
  red: '#D11023',
  orange: '#AD5900',
  lightOrange: '#fdb88f',
  stripeColor: '#4462C9',
  snow: '#FCFCFD',
  fog: '#F9F9FA',
  cement: '#EBECF1',
  rhino: '#9898A1',
  dust: '#828282',
  shadow: '#4C4C4C',
  pink: '#ff17e9',
};

export const getSpacing = (i: number) => `${i * 8}px`;

export const TRANSITION_DEFAULT_DELAY_IN_MS = 300;
export const transitions = {
  default: `${TRANSITION_DEFAULT_DELAY_IN_MS}ms ease-in-out`,
  delayed: `0s linear ${TRANSITION_DEFAULT_DELAY_IN_MS}ms`,
};

export const textStyles = {
  big: `
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.03em;
  `,
  default: `
    font-size: 18px;
    line-height: 18px;
    letter-spacing: -0.03em;
  `,
  small: `
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.03em;
  `,
};

export const borderRadius = {
  default: '12px',
  big: '24px',
};

export const zIndex = {
  HIDDEN: -1,
  DEFAULT: 0,
  SELECT_OPTIONS: 1,
  SELECT_VALUE: 5,
  ACTIVE_SELECT: 10,
  SIDE_PANEL: 15,
  MAIN_MENU: 20,
  MODAL: 100,
};

export const MOBILE_WIDTH_THRESHOLD = '1024px';
export const MOBILE_WIDTH_THRESHOLD_NUMBER = 1024;
export const CONTENT_MAX_WIDTH = '813px';
