import axios, { AxiosInstance } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { PromotionMessage } from 'src/pages/Settings/AppSettings/components/PromotionHandler';

class PromotionMessageRepositoryHttp {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios;
  }

  async save(businessId: string, message: string): Promise<void> {
    return this.httpClient.post(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/businesses/${businessId}/promotion-message`,
      { message },
    );
  }

  async get(businessId: string): Promise<PromotionMessage> {
    return this.httpClient.get<PromotionMessage>(
      `${ConfigurationLoader.load().receiptApiBaseUrl}/businesses/${businessId}/promotion-message`)
      .then((response) => response.data);
  }
}

export const promotionMessageRepositoryHttp = new PromotionMessageRepositoryHttp();

export const useSavePromotionMessage = () => {
  return (businessId: BusinessId, message: string): Promise<void> =>
    new Promise((success, failure) => {
      promotionMessageRepositoryHttp.save(businessId.toString(), message)
        .then(success)
        .catch(failure);
    });
};

export const useGetPromotionMessage = () => {
  return (businessId: BusinessId): Promise<PromotionMessage> =>
    new Promise((success, failure) => {
      promotionMessageRepositoryHttp.get(businessId.toString())
        .then(success)
        .catch(failure);
    });
};
