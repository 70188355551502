import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Translation } from 'src/accounting/common/translation';

export const TableEmptyState: FC<{ title: Translation, subtitle?: Translation }> = ({ title, subtitle }) => {
  return (
    <Box display="flex"
         flexDirection="column"
         alignItems="center"
         justifyContent="end"
         gap={2}
         p={5}
         sx={{
           width: '100%',
           background: 'white',
           borderBottomLeftRadiusRadius: '16px',
           borderBottomRightRadiusRadius: '16px',
         }}>
      <Typography variant="h6">
        <FormattedMessage id={title.id} values={title.values} />
      </Typography>
      {subtitle &&
        <Typography variant="body1" color={themeV5.palette.text.secondary}>
          <FormattedMessage id={subtitle.id} values={subtitle.values} />
        </Typography>
      }
    </Box>
  );
};
