import React from 'react';
import { Business } from 'src/business/domain/Business';
import { MenuSummary } from 'src/menu/dynamic-menu/domain/MenuSummary';
import { useFindMenusBySourceOrBusinessId } from 'src/menu/dynamic-menu/queries/menu/findMenusBySourceQuery';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

type Props = {
  selectedBusiness: Business
  selectedMenus: MenuSummary[],
  onSelectMenu: (selectedMenus: MenuSummary, checked: boolean) => void
};

export const SelectMenuSelectionStep = (
  {
    selectedBusiness,
    selectedMenus,
    onSelectMenu,
  }: Props,
) => {

  const { data: menus, isLoading } = useFindMenusBySourceOrBusinessId(selectedBusiness.id, undefined);

  if (isLoading || !menus) {
    return <CircularProgress />;
  }

  if (menus.length === 0) {
    return (
      <Typography>
        <FormattedMessage id="menus.no-menus-message" />
      </Typography>);
  }

  return (
    <FormGroup>
      {menus.map((menu) => (
        <FormControlLabel
          key={menu.id}
          control={
            <Checkbox
              checked={selectedMenus.includes(menu)}
              onChange={(event) => onSelectMenu(menu, event.target.checked)}
            />
          }
          label={menu.name}
        />
      ))}
    </FormGroup>
  );
};
