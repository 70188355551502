import { CircularProgress, Typography } from '@mui/material';
import React, { FC } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import RefreshSvg from '../../../../../app/component/icons/RefreshSvg';
import { BusinessId } from 'src/business/domain/Business';
import { MenuSummary } from '../../../domain/MenuSummary';
import { useRefreshMenusBySource } from '../../../queries/menu/findMenusBySourceQuery';
import { MenuToolBox } from './MenuToolBox';
import { canRefreshMenusFromPos, MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

type MenuRefreshButtonProps = {
  businessId: BusinessId;
  menuAssetsSource: MenuAssetsSource | undefined;
  onMenusRefreshed?: (menus: MenuSummary[]) => void;
};


const LoadingRefreshButton = () => {
  return (
    <MenuToolBox>
      <CircularProgress size="medium" />
      <Typography fontSize="medium" sx={{ opacity: 0.6 }}>
        <FormattedMessage id="menus.sync.button.in-progress" defaultMessage="syncing..." />
      </Typography>
    </MenuToolBox>
  );
};

const EnabledRefreshButton: FC<MenuRefreshButtonProps & { menuAssetsSource: MenuAssetsSource }> = ({
  businessId,
  menuAssetsSource,
  onMenusRefreshed,
}) => {
  const { loading, refreshMenus } = useRefreshMenusBySource(businessId, menuAssetsSource, onMenusRefreshed);

  if (loading) {
    return <LoadingRefreshButton />;
  }

  return (
    <MenuToolBox onClick={refreshMenus}>
      <RefreshSvg size={24} fontSize="large" />
      <Typography fontSize="medium">
        <FormattedMessage id="menus.sync.button" defaultMessage="sync all menus" />
      </Typography>
    </MenuToolBox>
  );
};

const DisabledRefreshButton = () => {
  return (
    <MenuToolBox sx={{ opacity: 0.5 }}>
      <RefreshSvg size={24} fontSize="large" opacity={0.5} />
      <Typography fontSize="medium" sx={{ opacity: 0.6 }}>
        <FormattedMessage id="menus.sync.button" defaultMessage="sync all menus" />
      </Typography>
    </MenuToolBox>
  );
};


export const MenuRefreshButton: FC<MenuRefreshButtonProps> = ({
  businessId,
  menuAssetsSource,
  onMenusRefreshed,
}) => {
  if (!menuAssetsSource || !canRefreshMenusFromPos(menuAssetsSource)) {
    return <DisabledRefreshButton />;
  }

  return <EnabledRefreshButton businessId={businessId}
                               menuAssetsSource={menuAssetsSource}
                               onMenusRefreshed={onMenusRefreshed} />;
};
