import { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export const OpsToolingSvg = ({ color = themeV5.palette.primary.main, ...props }: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M6.7587 2H17.2413C18.0463 1.99999 18.7106 1.99998 19.2518 2.04419C19.8139 2.09012 20.3306 2.18868 20.816 2.43597C21.5686 2.81947 22.1805 3.43139 22.564 4.18404C22.8113 4.66937 22.9099 5.18608 22.9558 5.74817C23 6.28936 23 6.95372 23 7.75868V12.2413C23 13.0463 23 13.7106 22.9558 14.2518C22.9099 14.8139 22.8113 15.3306 22.564 15.816C22.1805 16.5686 21.5686 17.1805 20.816 17.564C20.3306 17.8113 19.8139 17.9099 19.2518 17.9558C18.7106 18 18.0463 18 17.2413 18H13V20H16C16.5523 20 17 20.4477 17 21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21C7 20.4477 7.44772 20 8 20H11V18H6.75873C5.95374 18 5.28938 18 4.74817 17.9558C4.18608 17.9099 3.66937 17.8113 3.18404 17.564C2.43139 17.1805 1.81947 16.5686 1.43597 15.816C1.18868 15.3306 1.09012 14.8139 1.04419 14.2518C0.999977 13.7106 0.999988 13.0463 1 12.2413V7.7587C0.999988 6.95373 0.999977 6.28937 1.04419 5.74818C1.09012 5.18608 1.18868 4.66937 1.43597 4.18404C1.81947 3.43139 2.43139 2.81947 3.18404 2.43597C3.66937 2.18868 4.18608 2.09012 4.74817 2.04419C5.28937 1.99998 5.95373 1.99999 6.7587 2ZM17.2 16C18.0566 16 18.6389 15.9992 19.089 15.9624C19.5274 15.9266 19.7516 15.8617 19.908 15.782C20.2843 15.5903 20.5903 15.2843 20.782 14.908C20.8617 14.7516 20.9266 14.5274 20.9624 14.089C20.9992 13.6389 21 13.0566 21 12.2V7.8C21 6.94342 20.9992 6.36113 20.9624 5.91104C20.9266 5.47262 20.8617 5.24842 20.782 5.09202C20.5903 4.7157 20.2843 4.40973 19.908 4.21799C19.7516 4.1383 19.5274 4.07337 19.089 4.03755C18.6389 4.00078 18.0566 4 17.2 4H6.8C5.94342 4 5.36113 4.00078 4.91104 4.03755C4.47262 4.07337 4.24842 4.1383 4.09202 4.21799C3.7157 4.40973 3.40973 4.7157 3.21799 5.09202C3.1383 5.24842 3.07337 5.47262 3.03755 5.91104C3.00078 6.36113 3 6.94342 3 7.8V12.2C3 13.0566 3.00078 13.6389 3.03755 14.089C3.07337 14.5274 3.1383 14.7516 3.21799 14.908C3.40973 15.2843 3.7157 15.5903 4.09202 15.782C4.24842 15.8617 4.47262 15.9266 4.91104 15.9624C5.36113 15.9992 5.94342 16 6.8 16H17.2Z"
        fill={color}
      />
    </svg>
  );
};
