/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const RightDownArrowIcon: React.FC<IconProps> = ({ size = 16, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 16 18" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1464 6.64645C10.3417 6.45118 10.6583 6.45118 10.8536 6.64645L15.8536 11.6464C16.0488 11.8417 16.0488 12.1583 15.8536 12.3536L10.8536 17.3536C10.6583 17.5488 10.3417 17.5488 10.1464 17.3536C9.95118 17.1583 9.95118 16.8417 10.1464 16.6464L14.7929 12L10.1464 7.35355C9.95118 7.15829 9.95118 6.84171 10.1464 6.64645Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M-0.5 0.5C-0.223858 0.5 0 0.723858 0 1V8C0 8.92826 0.368749 9.8185 1.02513 10.4749C1.6815 11.1313 2.57174 11.5 3.5 11.5H15.5C15.7761 11.5 16 11.7239 16 12C16 12.2761 15.7761 12.5 15.5 12.5H3.5C2.30653 12.5 1.16193 12.0259 0.318019 11.182C-0.525894 10.3381 -1 9.19347 -1 8V1C-1 0.723858 -0.776142 0.5 -0.5 0.5Z" fill={color} />
  </svg>
);
