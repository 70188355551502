import { LocalisationKey } from 'src/lang/en';

export type Translation = {
  id: LocalisationKey;
  values: Record<string, string | number>;
  defaultMessage?: string;
};

export const translation = (id: LocalisationKey, values: Record<string, string | number> = {}, defaultMessage?: string) => ({
  id,
  values,
  defaultMessage,
});
