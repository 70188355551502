import { IntlShape } from 'src/app/i18n/TypedIntl';
import { Language } from 'src/app/i18n/AllSupportedLanguage';
import { AllergenTranslations } from 'src/menu/dynamic-menu/domain/AllergenTranslations';
import { GridCellParams, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { EmptyTableCellValue } from 'src/payments/components/EmptyTableCellValue';
import { Typography } from '@mui/material';
import { Action, ActionsButton } from 'src/components/actions-button/ActionsButton';
import React from 'react';
import { AllergenAction } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/AllergensList';
import { ProductName } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductName';
import { ProductNameInfo } from '../products/ProductNameInfo';
import { EditOutlinedSvg } from 'src/app/component/icons/EditOutlinedSvg';
import { Delete } from 'src/app/component/icons/DeleteSvg';

export function buildAllergensColumns(
  intl: IntlShape,
  supportedLanguages: Language[],
  onActionClicked: (allergen: AllergenTranslations, actionName: AllergenAction['actionName']) => void,
): GridColDef<AllergenTranslations>[] {
  const languagesColumns: GridColDef<AllergenTranslations>[] = supportedLanguages.map((language) => ({
    field: language.id,
    display: 'flex',
    headerName: language.id,
    flex: 1,
    disableColumnMenu: true,
    renderCell: ({ row }: GridCellParams<AllergenTranslations>) => {
      const allergenName =
        row.translations.find((translation) => translation.language === language.id)?.name ?? row.name;
      if (!allergenName) {
        return <EmptyTableCellValue />;
      }
      return <>{allergenName}</>;
    },
  }));

  return [
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'menus.allergens.header.allergen' }),
      disableColumnMenu: true,
      flex: 1,
      display: 'flex',
      sortComparator: gridStringOrNumberComparator,
      renderCell: ({ row }: GridCellParams<AllergenTranslations>) => (
        <ProductName>
          <ProductNameInfo>
            <Typography>{row.name}</Typography>
          </ProductNameInfo>
        </ProductName>
      ),
    },
    ...languagesColumns,
    {
      field: 'actions',
      headerAlign: 'right',
      sortable: false,
      align: 'right',
      display: 'flex',
      headerName: intl.formatMessage({ id: 'menus.products.header.actions' }),
      flex: 1,
      renderCell: ({ row }) => {
        const actions: Action[] = [
          {
            icon: <EditOutlinedSvg />,
            visible: true,
            intlKey: 'menus.allergens.edit.cta',
            action: (event) => {
              event.stopPropagation();
              onActionClicked(row, 'edit');
            },
          },
          {
            icon: <Delete />,
            visible: true,
            intlKey: 'menus.product.delete.cta',
            action: (event) => {
              event.stopPropagation();
              onActionClicked(row, 'delete');
            },
          },
        ];
        return <ActionsButton actions={actions} popperPlacement='left-end' sx={{ paddingRight: '10px' }} />;
      },
    },
  ];
}
