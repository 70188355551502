import { Money } from '@sundayapp/web-money';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BoxDetails } from '../box/domain/BoxDetails';

export enum CustomerRequiredDataType {
  NONE = 'NONE',
  EMAIL_ONLY = 'EMAIL_ONLY',
  PHONE_ONLY = 'PHONE_ONLY',
  EMAIL_AND_PHONE = 'EMAIL_AND_PHONE',
  AT_LEAST_EMAIL_OR_PHONE = 'AT_LEAST_EMAIL_OR_PHONE',
}

export enum TimezoneType {
  America_New_York = 'America/New_York',
  America_Chicago = 'America/Chicago',
  America_Denver = 'America/Denver',
  America_Los_Angeles = 'America/Los_Angeles',
  America_Anchorage = 'America/Anchorage',
  America_Adak = 'America/Adak',
  Canada_Atlantic = 'Canada/Atlantic',
  Canada_Central = 'Canada/Central',
  Canada_Eastern = 'Canada/Eastern',
  Canada_Mountain = 'Canada/Mountain',
  Canada_Newfoundland = 'Canada/Newfoundland',
  Canada_Pacific = 'Canada/Pacific',
  Canada_Saskatchewan = 'Canada/Saskatchewan',
  Canada_Yukon = 'Canada/Yukon',
  France_AST = 'America/Martinique',
  France_Paris = 'Europe/Paris',
  NONE = '',
}

export enum PayWithCashLabel {
  PAY_WITH_CASH = 'payWithCash',
  PAY_TO_STAFF = 'payToStaff',
  PAY_AT_CHECKOUT = 'payAtCheckout',
}

export enum ServiceChargeType {
  RATE = 'RATE',
  AMOUNT = 'AMOUNT',
  NONE = 'NONE',
}

export enum Crm {
  NONE = 'NONE',
  ZOHO = 'ZOHO',
}

export type CrmAuthenticationDetails = {
  clientId: string;
  clientSecret: string;
  temporaryCode: string;
};

export type Venue = {
  id: EnrollmentId;
  name: string;
  isFoodCourt: boolean;
};

type VenueDetailsMandatoryFields = {
  displayName: string;
  showVenueName: boolean;
  description: string | null;
  imageUrl: string | null;
  landingBackgroundColor: string | null;
  foodCourtMapUrl: string | null;
  address: string | null;
  sundayPaymentMandatory: boolean;
  customerRequiredData: CustomerRequiredDataType;
  enableCutlery: boolean;
  serviceChargeType: ServiceChargeType;
  serviceChargeMandatory: boolean;
  serviceChargeAmount: Money;
  serviceChargeRate: number;
  currency: string;
  enableOrderGrouping: boolean;
  orderGroupingTimeout: number;
  foldableCategories: boolean;
  boxes: { boxes: BoxDetails[] };
};

type VenueDetailsOptionalFields = {
  websiteUrl?: string;
  email?: string;
  requireOrderApproval?: boolean;
  isFoodCourt?: boolean;
  boxesPriorities?: string[];
  payWithCashLabel?: string;
  timezone?: string;
  countryCode?: string;
  enableClickAndCollect?: boolean;
  tipsRoundingMode?: string;
  crm?: Crm;
  crmClientId?: string;
  askForBirthday?: boolean;
  multiboxOrdersForbidden?: boolean;
  sortBoxesByWaitingTime?: boolean;
  enableBoxWaitingTime?: boolean;
  orderGroupingEnableSendNow?: boolean;
};

export type VenueDetailsFields = VenueDetailsMandatoryFields & VenueDetailsOptionalFields;
export type VenueDetails = Venue & VenueDetailsFields;
