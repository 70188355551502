import React, { useState } from 'react';
import { BusinessId } from 'src/business/domain/Business';
import { GroupOfInvoices, invoicesYears } from 'src/accounting/invoices/domain/Invoice';
import { Invoices } from 'src/accounting/invoices/pages/Invoices';
import { PeriodFilter } from 'src/accounting/invoices/pages/PeriodFilter';
import { Stack } from '@mui/material';
import { CateringFilter } from 'src/accounting/invoices/pages/CateringFilter';
import { applyInvoiceFilters, defaultInvoiceFilters } from 'src/accounting/invoices/domain/InvoiceFilters';

export const FilteredInvoices = ({ businessId, invoices }:
{
  businessId: BusinessId,
  invoices: GroupOfInvoices
}) => {
  const [filters, setFilters] = useState(defaultInvoiceFilters);
  const [filteredInvoices, setFilteredInvoices] = useState(invoices.invoicesPerYearMonth);

  const onPeriodChanged = (year: string | undefined) => {
    const newFilters = { ...filters, year };
    setFilters(newFilters);
    setFilteredInvoices(applyInvoiceFilters(invoices, newFilters));
  };

  const onShowCatering = (show: boolean) => {
    const newFilters = { ...filters, showCatering: show };
    setFilters(newFilters);
    setFilteredInvoices(applyInvoiceFilters(invoices, newFilters));
  };

  return (
    <Stack direction="column" paddingTop={2} spacing={2}>
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <PeriodFilter years={invoicesYears(invoices.invoicesPerYearMonth)} onPeriodChanged={onPeriodChanged} />
        <CateringFilter onShowCatering={onShowCatering} />
      </Stack>
      <Invoices businessId={businessId} invoicesPerYearMonth={filteredInvoices} />
    </Stack>
  );
};
