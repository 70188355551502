import { useAsync } from 'react-use';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { DateRange } from '../../domain/DateRange';
import { HttpReviewRepository } from '../infrastructure/HttpReviewRepository';
import { DateFilter } from '../BrowseReviewsViewModel';
import { Business } from 'src/business/domain/Business';

const configuration = ConfigurationLoader.load();
const repository = new HttpReviewRepository(configuration.venueFeedbackBaseUrl);
const getTimeRange = (dateFilter: DateFilter) => {
  if (dateFilter === 'TODAY') return DateRange.today();
  if (dateFilter === 'YESTERDAY') return DateRange.yesterday();
  if (dateFilter === 'LAST_7_DAYS') return DateRange.lastSevenDays();
  if (dateFilter === 'LAST_30_DAYS') return DateRange.lastThirtyDays();
  if (dateFilter === 'PREVIOUS_7_DAYS') return DateRange.previousSevenDays();
  return DateRange.lastSevenDays();
};
export const useReviews = (business: Business, dateFilter: DateFilter) =>
  useAsync(() => repository.listReviews(business.id, getTimeRange(dateFilter)
    .toTimeRange()));
