export const CardContentTheme = {
  styleOverrides: {
    root: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      gap: '16px',
      padding:'24px',
    },
  },
};
