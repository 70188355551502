import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import RightArrowSvg from '../../app/component/icons/RightArrowSvg';

type HomeCtaButtonProps = {
  icon: ReactNode;
  label: string;
  value: string;
  onClick: () => void;
};

const Container = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 8px 16px 8px 16px;
  justify-content: space-between;
  background-color: ${theme.palette.background.default};
  border-radius: 8px;
  cursor: pointer;
`,
);

const LeftPart = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const RightPart = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LabelWrapper = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: black;
`;

const ValueWrapper = styled('div')`
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: black;
`;

export const HomeCtaButton = ({ icon, label, value, onClick }: HomeCtaButtonProps) => {
  return (
    <Container onClick={onClick}>
      <LeftPart>
        {icon}
        <LabelWrapper>{label}</LabelWrapper>
      </LeftPart>
      <RightPart>
        <ValueWrapper>{value}</ValueWrapper>
        <RightArrowSvg size={18} color={themeV5.palette.text.primary} />
      </RightPart>
    </Container>
  );
};
