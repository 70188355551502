import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { countries } from 'src/domain/countries';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { getZipCodeRegex } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/Address';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { states } from 'src/payments/components/Checkout/CheckoutOnboarding/States';

export const RepresentativeAddress = ({ representativeIndex }: { representativeIndex: number }) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();

  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const addressErrors = errors?.representatives?.[representativeIndex]?.address;
  const country = watch(`representatives.${representativeIndex}.address.country`, business.address.countryCode);
  const zipCodePostalAddress = getZipCodeRegex(country);
  const isUS = country === 'US';
  const state = watch(`representatives.${representativeIndex}.address.state`, '');

  return (
    <Stack direction="column" spacing={3}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.representatives.address'} />
      </Typography>
      <TextField
        required
        InputLabelProps={{ shrink: true }}
        error={!!addressErrors?.addressLine1}
        {...register(`representatives.${representativeIndex}.address.addressLine1`, {
          required: intl.formatMessage({
            id: 'settings.payments.checkout.onboarding.address.addressLine1.error.required',
          }),
          minLength: {
            value: 5,
            message: intl.formatMessage({
              id: 'settings.payments.checkout.onboarding.address.addressLine1.error.invalid',
            }),
          },
        })}
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.addressLine1' })}
        helperText={addressErrors?.addressLine1?.message || ''}
      />
      <TextField
        required
        InputLabelProps={{ shrink: true }}
        error={!!addressErrors?.city}
        {...register(`representatives.${representativeIndex}.address.city`, {
          required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.city.error' }),
        })}
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.city' })}
        helperText={addressErrors?.city?.message || ''}
      />
      {isUS && (
        <TextField
          select
          required
          InputLabelProps={{ shrink: true }}
          defaultValue={state}
          error={!!addressErrors?.state}
          id="state-select"
          label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.state' })}
          inputProps={register(`representatives.${representativeIndex}.address.state`, {
            required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.state.error' }),
          })}
          helperText={addressErrors?.state?.message || ''}
        >
          {states.map(({ code, name }) => (
            <MenuItem key={code} value={code}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        required
        InputLabelProps={{ shrink: true }}
        error={!!addressErrors?.zip}
        {...register(`representatives.${representativeIndex}.address.zip`, {
          required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.zip.error.required' }),
          pattern: {
            value: zipCodePostalAddress,
            message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.zip.error.invalid' }),
          },
        })}
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.zip' })}
        helperText={addressErrors?.zip?.message || ''}
      />
      <TextField
        select
        required
        defaultValue={country}
        error={!!addressErrors?.country}
        InputLabelProps={{ shrink: true }}
        id="country-select"
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.country' })}
        inputProps={register(`representatives.${representativeIndex}.address.country`, {
          required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.country.error' }),
        })}
        helperText={addressErrors?.country?.message || ''}
      >
        {countries.map(({ name, code }) => (
          <MenuItem key={code} value={code}>
            {name}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
};
