import { useContext } from 'react';
import { OrderingConfigContext } from '../../OrderingContextProvider';
import { useAsyncFn } from '../utils/useAsyncFn';
import { NotificationToAddType } from 'src/app/contexts/snackbars/SnackBarContext';

export const usePushNotification = () => {
  const { onPushNotification } = useContext(OrderingConfigContext);

  return useAsyncFn<void, [message: string, variant?: NotificationToAddType['variant']]>(async (message, variant = 'success') => {
    onPushNotification!(message, variant);
  }, []);
};
