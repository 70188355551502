import { RevenueSource, RevenueSourceType } from './RevenueSource';
import { CurrencyCode, Money } from '@sundayapp/web-money';

export type RevenuesSummary = {
  revenues: Revenue[]
};

export const isRevenuesSummary = (response: unknown): response is RevenuesSummary => response !== null && typeof response === 'object'
  && 'revenues' in response;

export type Revenue = {
  source: RevenueSource,
  currency: CurrencyCode,
  revenuesDetails: RevenueDetails[],
  totalSalesAndTips: Money,
  totalSalesWithSundayFees: Money,
  totalTips: Money,
  totalCharges: Charges
};

export type RevenueDetails = {
  source: RevenueSourceType,
  revenueAmounts: RevenueAmounts,
  revenueUnits: RevenueUnit[]
};

export type RevenueAmounts = {
  salesWithSundayFees: Money,
  tips: Money,
  sundayFeesVatIncluded: Money,
  totalSalesAndTips: Money,
  totalTips: Money,
  totalSalesWithSundayFees: Money,
  refunds: RefundAmounts,
  orphanRefunds: RefundAmounts,
  totalAllCharges: Charges
};

export type RefundAmounts = {
  sales: Money,
  tips: Money,
  gross: Money,
  charges: Charges,
};

export type Charges = {
  fee: Money,
  tax: Money,
  vat: Money,
  serviceCharge: Money
};

export type RevenueUnit = {
  name: string,
  revenueAmounts: RevenueAmounts
};

export const localisedRevenueNames = [
  'MANUAL',
  'MERCHANT_CREDIT',
  'VOUCHER_ON_MEAL_VOUCHER',
  'adjustments',
  'autogratuity',
  'fee',
  'grossRevenueTotal',
  'moreDetails',
  'moreDetailsExplanation',
  'orphan_refunds',
  'otherRevenues',
  'refunds',
  'revenues_details_source',
  'sales',
  'salesNet',
  'summary',
  'sundayFeesVatIncluded',
  'tax',
  'tips',
  'totalPaidOutBySunday',
  'vat',
  'walkouts',
] as const;
