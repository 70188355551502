import axios from 'axios';
import { Till } from '../domain/Till';
import { Bill } from '../domain/Bill';
import { BillResponse } from './BillResponse';
import { mapBills } from './Mapper';
import { TabCaptureStatusResponse } from './TabCaptureStatusResponse';
import { TabCaptureStatus } from '../domain/tab/TabCaptureStatus';
import { BusinessId } from 'src/business/domain/Business';

export function init(patOrchestratorUrl: string): Till {
  return {
    async searchByDate(businessId: BusinessId, from: Date, to: Date): Promise<(Bill | null)[]> {
      const queryParams = new URLSearchParams({ from: from.toISOString(), to: to.toISOString(), count: '0' });
      const bills = await axios.get<BillResponse[]>(
        `${patOrchestratorUrl}/businesses/${businessId}/bills?${queryParams}`,
      );

      return mapBills(bills.data);
    },
    async tabCaptureStatus(
      businessId: BusinessId,
      tabId: string,
    ): Promise<(typeof TabCaptureStatus)[keyof typeof TabCaptureStatus]> {
      const status = await axios.get<TabCaptureStatusResponse>(
        `${patOrchestratorUrl}/admin/businesses/${businessId}/tabs/${tabId}/charge`,
      );
      switch (status.data.status) {
        case 'PROCESSING':
          return TabCaptureStatus.PROCESSING;
        case 'FAILURE':
          return TabCaptureStatus.FAILURE;
        case 'PARTIAL_SUCCESS':
          return TabCaptureStatus.PARTIAL_SUCCESS;
        case 'SUCCESS':
          return TabCaptureStatus.SUCCESS;
        default:
          return TabCaptureStatus.NOT_STARTED;
      }
    },
    async captureTabLeftToPay(businessId: BusinessId, tabId: string): Promise<void> {
      return axios
        .put<void>(`${patOrchestratorUrl}/admin/businesses/${businessId}/tabs/${tabId}/charge`)
        .then(() => {});
    },
  } as Till;
}
