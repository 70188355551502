import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { roleForBusiness } from 'src/domain/user/Role';
import { UserIcon } from './UserIcon';
import { useBusinessId } from 'src/business/hooks/useBusinessId';
import { userRoleLocalisations } from 'src/pages/Settings/UserPermissions/i18n/UserRoleLocalisation';
import { Box } from '@mui/material';

type UserNameProps = {
  maxWidth: number;
};
const User = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;
const UserDetail = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: start;
  margin-left: 12px;
  justify-content: space-between;
`;
const UserName = styled(Box)<UserNameProps>(({ maxWidth }) => ({
  textTransform: 'capitalize',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '20px',
  maxWidth,
})) as typeof Box;
const UserSubtitle = styled(Box)<UserNameProps>(({ maxWidth }) => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  lineHeight: '16px',
  fontSize: '12px',
  color: '#70707B',
  maxWidth,
})) as typeof Box;

export type UserInfoProps = {
  compact?: boolean;
  userNameMaxWidth?: number;
  emailAsSubtitle?: boolean;
};

export function UserInfo({ compact = false, userNameMaxWidth = 140, emailAsSubtitle = false }: UserInfoProps) {
  const intl = useIntl();
  const businessId = useBusinessId();
  const user = useAuthenticatedUserOrThrow();
  const roleForSelectedVenue = businessId && roleForBusiness(user.claims, businessId);
  const userName = `${user.firstName ?? ''} ${user.lastName ?? ''}`.trim();
  const displayName = userName || user.email;
  const roleName = roleForSelectedVenue
    ? intl.formatMessage({ id: userRoleLocalisations[roleForSelectedVenue] })
    : '';
  const subTitle = () => {
    if (emailAsSubtitle && displayName !== user.email) {
      return user.email;
    }

    if (!emailAsSubtitle) {
      return roleName;
    }
  };

  return (
    <User>
      <UserIcon userName={displayName} />
      {!compact && (
        <UserDetail>
          <UserName component="span" maxWidth={userNameMaxWidth}>{user ? displayName : 'unknown user'}</UserName>
          {subTitle && <UserSubtitle component="span" maxWidth={userNameMaxWidth}>{subTitle()}</UserSubtitle>}
        </UserDetail>
      )}
    </User>
  );
}
