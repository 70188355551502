import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BlockRepositoryHttp } from '../../repositories/block/BlockRepositoryHttp';
import { CreateBlockDto, UpdateBlockDto } from '../../repositories/block/BlockDto';
import { QUERYKEY_MENU_BLOCKS } from '../../queries/block/getAllBlocksByBusinessIdQuery';
import { QUERYKEY_MENU_BLOCK } from '../../queries/block/getBlockByIdQuery';
import { BusinessId } from 'src/business/domain/Business';

export const useCreateBlockMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (block: CreateBlockDto) => blockRepository.createBlock(businessId, block),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_BLOCKS] });
    },
  });
};

export const useBlockMutation = (businessId: BusinessId, blockId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (updateBlock: UpdateBlockDto) => blockRepository.updateBlock(businessId, blockId, updateBlock),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_BLOCK] });
    },
  });
};

export const useBlockPictureMutation = (businessId: BusinessId, blockId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (base64Picture: string) => fetch(base64Picture)
      .then((response) => response.arrayBuffer())
      .then((buffer) => blockRepository.updateBlockPicture(businessId, blockId, buffer)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_BLOCK] });
    },
  });
};

export const useCopyBlockMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (blockId: string) => blockRepository.copyBlock(businessId, blockId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_BLOCKS] });
    },
  });
};

export const useDeleteBlockMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (blockId: string) => blockRepository.deleteBlock(businessId, blockId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_BLOCKS] });
    },
  });
};
