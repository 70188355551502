import React, { useMemo } from 'react';
import styled from 'styled-components';
import { getSpacing } from '@sundayapp/b2b-react-component-library';
import { useParams } from 'react-router';
import { AreaDetailsSettings as AreaDetails } from '../../../ordering/area/pages/AreaDetailsSettings/AreaDetailsSettings';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { CircularProgress } from '@mui/material';

const OrderingSettingPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  width: 100%;
`;

export const AreaDetailsSettings = () => {
  const business = useCurrentBusinessOrThrow();
  const { boxId, areaId } = useParams();

  const areaDetailComponent = useMemo(() => {
    if (!business.oapEnrollment!.id || !areaId || !boxId) {
      return <CircularProgress size="medium" />;
    }

    return <AreaDetails businessId={business.id} enrollmentId={business.oapEnrollment!.id} boxId={boxId}
                        areaId={areaId} />;
  }, [business.oapEnrollment!.id, areaId]);

  return (
    <OrderingSettingPanel>{areaDetailComponent}</OrderingSettingPanel>
  );
};
