import { z } from 'zod';

export const ConfigurationZ = z.object({
  leaderboardEnabled: z.boolean(),
  tipsAmountOnly: z.boolean(),
  tipsEnabled: z.boolean(),
});
export const EnrollmentConfiguration = z.object({
  configuration: ConfigurationZ,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type EnrollmentConfiguration = z.infer<typeof EnrollmentConfiguration>;
