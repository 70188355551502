import { styled } from '@mui/material';
import { Button, spaceUsages } from '@sundayapp/b2b-react-component-library';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { PaymentMethodSettings, ReportSettings } from '../domain/ReportSettings';
import { ReportSettingsGeneralConfiguration } from '../component/settings/ReportSettingsGeneralConfiguration';
import { DocumentationPanel } from '../component/settings/DocumentationPanel';
import { ReportSettingsInputs } from '../component/settings/ReportSettingsInputs';
import { ForceCommentIfDelta } from '../component/settings/ForceCommentIfDelta';
import { PaymentMethodsSettings } from '../component/settings/PaymentMethodsSettings';
import { SettingsPageContainer } from 'src/pages/Settings/components/SettingsPageContainer';
import { ReconciliationConfigurationContext } from '../providers/ReconciliationConfigurationContext';

const ReportSettingsForm = styled('form')`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;

  margin-bottom: 15px;
  align-items: start;

  input[type="text"] {
    border-radius: 5px;
    height: 50px;
  }
`;

const FloatingButton = styled(Button)`
  align-self: flex-start;
  position: sticky;
  bottom: 20px;
`;

const newDefaultPaymentMethod = () => ({
  id: uuidv4(),
  name: '',
  type: 'OTHER',
  activated: true,
  removable: true,
  onPos: {
    editable: false,
    paymentMethodSettingType: 'Simple',
  },
  reported: {
    editable: true,
    paymentMethodSettingType: 'Simple',
  },
  splitByDigitalMealVoucher: false,
  splitByAmericanExpress: false,
  posPaymentMethodIds: [],
});

function mergeFormValues(
  currentSettings: PaymentMethodSettings[],
  formValues: { [id: string]: PaymentMethodSettings },
)
  : PaymentMethodSettings[] {
  return currentSettings.map((settings) => ({
    ...settings,
    ...formValues[settings.id],
  }));
}

function toFormValues(settings: ReportSettings): ReportSettingsInputs {
  return {
    totalEditableOnPos: settings.totalOnPosEditable,
    documentationText: settings.documentation ?? '',
    documentationVisible: settings.documentationActivated,
    sundayFetchMode: settings.sundayFetchMode,
    paymentMethodsById: _.keyBy(settings.paymentMethodSettings, (m) => m.id),
    forceCommentIfDelta: {
      active: settings.forceCommentIfDelta.active,
      allowedAmountBeforeForcing: settings.forceCommentIfDelta.allowedAmountBeforeForcing / 100000,
    },
  };
}

function fromFormValues(currentSettings: ReportSettings, formValues: ReportSettingsInputs): ReportSettings {
  return {
    paymentMethodSettings: mergeFormValues(currentSettings.paymentMethodSettings, formValues.paymentMethodsById),
    totalOnPosEditable: formValues.totalEditableOnPos,
    documentation: formValues.documentationText,
    documentationActivated: formValues.documentationVisible,
    sundayFetchMode: formValues.sundayFetchMode,
    forceCommentIfDelta: {
      active: formValues.forceCommentIfDelta.active,
      allowedAmountBeforeForcing: formValues.forceCommentIfDelta.allowedAmountBeforeForcing * 100000,
    },
  };
}

const ReportSettingsPanel = () => {
  const { saveReportSettings, reportSettings } = useContext(ReconciliationConfigurationContext);

  const [currentSettings, setCurrentSettings] = useState(reportSettings);

  const form = useForm<ReportSettingsInputs>({
    defaultValues: toFormValues(currentSettings),
  });
  const {
    register,
    handleSubmit,
    reset,
    formState,
    getValues,
  } = form;

  const submitForm = (reportSettingsInputs: ReportSettingsInputs) => {
    const reportSettingsToSave = fromFormValues(currentSettings, reportSettingsInputs);
    saveReportSettings(reportSettingsToSave);
    setCurrentSettings(reportSettingsToSave);
  };

  const addPaymentMethod = () => {
    const upToDateSettings = fromFormValues(currentSettings, getValues());

    const newPaymentMethod = newDefaultPaymentMethod();
    const updated = produce(upToDateSettings, (settings) => {
      settings.paymentMethodSettings.push(newPaymentMethod);
    });
    setCurrentSettings(updated);
    reset(toFormValues(updated));
  };

  function onReorderPaymentMethods(recipe: (methods: PaymentMethodSettings[]) => PaymentMethodSettings[]) {
    const updatedSettings = produce(currentSettings, (draft) => {
      // eslint-disable-next-line no-param-reassign
      draft.paymentMethodSettings = recipe(draft.paymentMethodSettings);
    });
    setCurrentSettings(updatedSettings);
  }

  const deletePaymentMethod = (methodId: string) => {
    const updatedSettings = produce(currentSettings, (draft) => {
      const index = draft.paymentMethodSettings.findIndex((m) => m.id === methodId);
      draft.paymentMethodSettings.splice(index, 1);
    });

    setCurrentSettings(updatedSettings);
    reset(toFormValues(updatedSettings));
  };
  console.log('panel');
  return (
    <SettingsPageContainer>
        <ReportSettingsForm onSubmit={handleSubmit(submitForm)} id="updateReportSettingsForm">
          <ReportSettingsGeneralConfiguration register={register} />
          <ForceCommentIfDelta form={form} />
          <DocumentationPanel register={register} />
          <PaymentMethodsSettings
            paymentMethods={currentSettings.paymentMethodSettings}
            form={form}
            addPaymentMethod={addPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            onReorderMethods={onReorderPaymentMethods}
          />

          <FloatingButton
            type="submit"
            variant="primary"
            disabled={!formState.isValid}
            size="large"
            form="updateReportSettingsForm"
            marginTop={spaceUsages.large}
          >
            <FormattedMessage id="settings.save_changes" />
          </FloatingButton>
        </ReportSettingsForm>
    </SettingsPageContainer>
  );
};

export default ReportSettingsPanel;
