import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { RestaurantShiftTimes } from 'src/domain/venue/RestaurantShiftTimes';
import { VenueReferentialRepositoryHttp } from 'src/infrastructure/venue/VenueReferentialRepositoryHttp';
import { ShiftsInputs } from './constants';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export function useShiftSettings() {
  const business = useCurrentBusinessOrThrow();
  const queryClient = useQueryClient();

  const { venueReferentialUrl } = ConfigurationLoader.load();
  const client: VenueReferentialRepositoryHttp = new VenueReferentialRepositoryHttp(venueReferentialUrl);

  const { isLoading, data } = useQuery({
    queryKey: ['venueShifts', business.id],
    queryFn: (): Promise<RestaurantShiftTimes> => client.getRestaurantShiftTimes(business.id),
  });

  const mutation = useMutation({
    mutationFn: (form: ShiftsInputs) =>
      client.putShifts(business.id, {
        lunch: {
          from: form.lunchFrom,
          to: form.lunchTo,
        },
        dinner: {
          from: form.dinnerFrom,
          to: form.dinnerTo,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['venueShifts'] });
    },
  });

  return {
    isLoading,
    venueShifts: data,
    mutation,
  };
}
