import { PAGE_CONTENT_WIDTH } from 'src/app/pages/constants';
import { styled } from '@mui/material';
import * as React from 'react';
import navigationItems, { findItem, findParentMenuItem } from '../../../app/navigation/navigationMenu/NavigationItems';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useLocation } from 'react-router';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

type Props = {
  children?: React.ReactNode;
  minWidth?: string;
};

const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 66%;
  min-width: ${PAGE_CONTENT_WIDTH}px;
`;

const ParentContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
const ItemsContainer = styled('div')`
  gap: 16px;
  display: flex;
  flex-direction: column;
  width:100%;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-bottom: 12px;
`;

const Title = styled('span')`
  font-size: 22px;
`;

const Section = styled('span')`
  font-size: 12px;
  text-transform: uppercase;
  color: #70707b;
`;

export const SettingsPageContainer = ({ children, minWidth }: Props) => {
  const businessId = useBusinessIdOrThrow();
  const currentRoute = useLocation().pathname;
  const navigationItem = findItem(navigationItems(businessId).items, currentRoute);
  const parentItem = navigationItem ? findParentMenuItem(navigationItems(businessId).items, navigationItem) : undefined;
  return (
    <ParentContainer>
      <PageContainer sx={{ minWidth: minWidth ? minWidth : PAGE_CONTENT_WIDTH + 'px' }}>
        <>
          <Header>
            {parentItem && (
              <Section>
                <FormattedMessage id={parentItem.text}></FormattedMessage>
              </Section>
            )}
            {navigationItem && (
              <Title>
                <FormattedMessage id={navigationItem.text}></FormattedMessage>
              </Title>
            )}
          </Header>
          <ItemsContainer>
          {children}
          </ItemsContainer>
        </>
      </PageContainer>
    </ParentContainer>
  );
};
