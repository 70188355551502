import { useContext } from 'react';
import { OrderingNavigation } from './OrderingNavigation';
import { OrderingConfigContext } from '../OrderingContextProvider';
import { FileStorage } from './FileStorage';
import { OrderingRoutingConfigContext } from '../OrderingRoutingContextProvider';

export const useRouting = (): OrderingNavigation => {
  const { routing } = useContext(OrderingRoutingConfigContext);
  return routing!;
};

export const useFileStorage = (): FileStorage => {
  const { fileStorage } = useContext(OrderingConfigContext);
  return fileStorage!;
};
