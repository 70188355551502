import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { TrendDownIcon } from 'src/home/components/icons/TrendDownIcon';
import { TrendUpIcon } from 'src/home/components/icons/TrendUpIcon';
import { LocalisationKey } from 'src/lang/en';

type TrendProps = {
  trend: 'UP' | 'DOWN',
  value: string | number,
  label: LocalisationKey,
};

export const StatTrend = (props: TrendProps) => {
  return (
    <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        <Box component="span" sx={{ width: '20px', height: '20px' }}>
            {props.trend === 'UP' && <TrendUpIcon component="span" sx={{ width: 'inherit' }} />}
            {props.trend === 'DOWN' && <TrendDownIcon sx={{ width: 'inherit' }} />}
        </Box>
        {props.value}
        <Box component="span">
            <FormattedMessage id={props.label} />
        </Box>
    </Typography>
  );
};