import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import React from 'react';
import { ProductImagePicker } from './components/ProductImagePicker';
import { ProductDetailsPanel } from './ProductDetailsPanel';
import { RelatedMenuPanel } from './components/RelatedMenuPanel';
import { ProductHeader } from './ProductHeader';
import { useGetMenuBusiness } from '../../../common/queries/getMenuBusinessQuery';
import { useGetProductById } from '../../queries/product/getProductByIdQuery';
import { RelatedProductPanel } from './components/RelatedProductPanel';
import { ProductSource, productTypes } from '../../domain/Product';
import { ProductPartnerInformation } from './ProductPartnerInformation';
import { ProductSubProducts } from './components/ProductSubProducts';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { VariantGroupInfoForm } from 'src/menu/dynamic-menu/pages/ProductPage/VariantGroupInfoForm';
import {
  VariantGroupVariantsSelection,
} from 'src/menu/dynamic-menu/pages/ProductPage/components/VariantGroupVariantsSelection';
import { ProductPromotions } from 'src/menu/dynamic-menu/pages/ProductPage/components/ProductPromotions';

const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
const ProductGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 350px;
  grid-template-rows: auto auto auto auto;
  gap: 32px 0;
  column-gap: 32px;
  grid-template-areas:
    'pos_product_details pos_product_details '
    'left_pane right_pane '
    'left_pane related_products '
    'modifiers .';
`;

const PosProductDetails = styled('div')`
  grid-area: pos_product_details;
`;

const LeftPane = styled('div')`
  grid-area: left_pane;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RightPane = styled('div')`
  grid-area: right_pane;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ProductPage = () => {
  const { productId } = useParams();
  const business = useCurrentBusinessOrThrow();
  const { data: menuBusiness } = useGetMenuBusiness(business.id);
  const { state } = useLocation();
  const contextProductList = state?.contextProductList ?? [];
  const menuId: string | undefined = state?.menuId;

  // some business in menu-backend do not have a menu config attached
  const noMenuConfigAttachedToBusiness = menuBusiness === null;
  const supportedLanguages = noMenuConfigAttachedToBusiness || !menuBusiness ? [] : menuBusiness.translatedLanguages;

  const { data: product } = useGetProductById(business.id, productId!, undefined, { enabled: true });

  if (menuBusiness === undefined) {
    return <CircularProgress />;
  }

  if (!product) {
    return <CircularProgress />;
  }

  return (
    // this key allows to hard reload the comp aggregate on product change
    <PageContainer key={product.id}>
      <ProductHeader
        product={product}
        supportedLanguages={supportedLanguages}
        contextProductList={contextProductList ?? []}
        menuId={menuId}
      />
      <ProductGrid>
        <PosProductDetails>
          <Box component="div" display="flex" flexDirection="row" width="100%" gap="8px" sx={{ opacity: 0.5 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 'small' }}>Type:</Typography>
            <Typography sx={{ fontSize: 'small' }}>{product.type}</Typography>
          </Box>
          {product.source === ProductSource.PARTNER && <ProductPartnerInformation product={product} />}
        </PosProductDetails>

        <LeftPane>
          {product.type === productTypes.VARIANT_GROUP
            ? <>
              <VariantGroupInfoForm
                businessId={business.id}
                orderingAllowed={!!menuBusiness?.orderingAllowed}
                product={product}
              />
              <VariantGroupVariantsSelection businessId={business.id} product={product} />
            </>
            : <>
              <ProductDetailsPanel
                businessId={business.id}
                orderingAllowed={!!menuBusiness?.orderingAllowed}
                product={product}
              />
              <ProductPromotions pricingStrategies={product.pricingStrategies || []}/>
              <ProductSubProducts businessId={business.id} product={product} />
            </>}
          <RelatedProductPanel businessId={business.id} product={product} />
        </LeftPane>
        <RightPane>
          <ProductImagePicker businessId={business.id!} product={product} />
          <RelatedMenuPanel businessId={business.id} productId={product.id} />
        </RightPane>
      </ProductGrid>
    </PageContainer>
  );
};
