import React, { useState } from 'react';
import { differenceMoney, Money, MoneyView } from '@sundayapp/web-money';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { fontSize } from '@sundayapp/b2b-react-component-library';
import { Order } from '../../types';
import Button, { ButtonVariant } from '../../../common/components/Button';
import Drawer from '../../../common/components/Drawer';
import Input from '../../../common/components/Input';
import { getSpacing } from '../../../stylesheet';
import { AcceptationMode } from '../../repositories/OrderingOrderRepository';

interface Props {
  order: Order;
  accept: (a: AcceptationMode) => void;
  close: () => void;
}

const CashChangeMessageContainer = styled.div`
  margin: ${getSpacing(2)} 0;
`;
const CashChangeTitle = styled.div`
  margin-bottom: ${getSpacing(1)};
  font-size: ${fontSize.large};
`;
const CashChangeMessage = styled.div`
  margin: ${getSpacing(1)} ${getSpacing(1)} 0;
  display: inline-block;
  font-size: ${fontSize.large};
`;
const CashChangeDrawerContainer = styled.div`
  margin: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(1)};
`;

export const CashChangeCalculator: React.FC<Props> = ({ order, accept, close }) => {
  const [ cashMissingMoney, setCashMissingMoney ] = useState<Money>();
  const [ cashChangeMoney, setCashChangeMoney ] = useState<Money>();

  const discountMoney = order.discount ? order.discount : { amount: 0, currency: order.totalPrice.currency };
  const priceToPayWithDiscount = differenceMoney(order.totalPrice, discountMoney);

  const computeCashChangeMoney = (val: string) => {
    let valNumber = Number(val.trim());
    if (Number.isNaN(valNumber)) {
      valNumber = 0;
    }
    const cashChange = valNumber * 100000 - priceToPayWithDiscount.amount;
    if (cashChange < 0) {
      // Not paid enough
      setCashMissingMoney({
        amount: -cashChange,
        currency: order.totalPrice.currency,
      });
      setCashChangeMoney(undefined);
    } else {
      // Should return cash ("rendre la monnaie")
      setCashChangeMoney({
        amount: cashChange,
        currency: order.totalPrice.currency,
      });
      setCashMissingMoney(undefined);
    }
  };

  return (
    <Drawer visible>
      <CashChangeDrawerContainer>
        <CashChangeMessageContainer>
          <CashChangeTitle>
            <FormattedMessage id="orders.cash.paid" defaultMessage="Paid:" />
          </CashChangeTitle>
          <Input type="number" onValueChange={computeCashChangeMoney} />
          {cashChangeMoney ? (
            <>
              <CashChangeMessage>
                <FormattedMessage id="orders.cash.change" defaultMessage="Change:" />
              </CashChangeMessage>
              <CashChangeMessage>
                <MoneyView value={cashChangeMoney} />
              </CashChangeMessage>
            </>
          ) : (
            <>
              <CashChangeMessage>
                <FormattedMessage id="orders.cash.leftToPay" defaultMessage="Left to pay:" />
              </CashChangeMessage>
              <CashChangeMessage>
                <MoneyView value={cashMissingMoney || priceToPayWithDiscount} />
              </CashChangeMessage>
            </>
          )}
        </CashChangeMessageContainer>
        <Button variant={ButtonVariant.PRIMARY} onClick={() => accept(AcceptationMode.CASH)}>
          <FormattedMessage id="orders.acceptOrder.confirm" defaultMessage="Confirm" />
        </Button>
        <Button variant={ButtonVariant.LIGHT} onClick={() => close()}>
          <FormattedMessage id="orders.acceptOrder.cancel" defaultMessage="Cancel" />
        </Button>
      </CashChangeDrawerContainer>
    </Drawer>
  );
};
