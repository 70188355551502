import React from 'react';
import { Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PicturePicker } from 'src/ordering/common/components/PicturePicker/PicturePicker';
import { Block } from '../../../domain/Block';
import { useBlockPictureMutation } from '../../../mutations/block/useBlockMutation';
import { BusinessId } from 'src/business/domain/Business';

type BlockImagePickerProps = {
  businessId: BusinessId;
  block: Block;
  disabled: boolean
};

const ImageCard = styled(Card)`
  width: 100%;
  cursor: pointer;
  padding: 24px;
`;

const ImageCardContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled('div')`
  margin-bottom: 24px;
`;

export const BlockImagePicker = ({
  businessId, block, disabled,
}: BlockImagePickerProps) => {
  const updateBlockPicture = useBlockPictureMutation(businessId, block.id);

  const onImageChanged = (canvasBase64: string) => {
    updateBlockPicture.mutateAsync(canvasBase64);
  };
  return (
    <ImageCard>
      <Title>
        <Typography variant="h6" component="div">
          <FormattedMessage id="menus.block.image.label" defaultMessage="picture" />
        </Typography>
      </Title>
      <ImageCardContent>
        <PicturePicker
          imageUrl={block.imageUrl ?? ''}
          updating={false}
          disabled={disabled}
          onImageChanged={onImageChanged}
          width={200}
          height={200}
          exportWidth={272}
          exportHeight={272}
        />
      </ImageCardContent>
    </ImageCard>
  );
};
