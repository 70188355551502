import React from 'react';
import { useTheme } from '@mui/material';
import { RequireConnectedGoogleAccount } from '../settings/setup/google/components/RequireConnectedGoogleAccount';
import { EReputation } from './EReputation';
import { LoadingOrError } from '../../components/LoadingOrError';
import { useEReputationKpis } from './infrastructure/eReputationKpisQueryBuilder';
import { NoEreputationData } from '../../components/NoEreputationData';
import { useGoogleSetupStage } from '../settings/setup/google/infrastruscture/useGoogleSetupStage';
import { GoogleSetupStage } from '../settings/setup/google/domain/GoogleSetupStage';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const EReputationLoader = () => {
  const business = useCurrentBusinessOrThrow();
  const theme = useTheme();

  const {
    data: googleSetupStatus,
    isLoading: googleSetupStatusLoading,
    error: googleSetupStatusError,
  } = useGoogleSetupStage(business.id);

  const {
    data: eReputationKpis,
    isLoading: eReputationKpisLoading,
    error: eReputationKpisError,
  } = useEReputationKpis(business.id, business.timezone);

  const state = () => {
    if (eReputationKpisError || googleSetupStatusError) return 'error';
    if (eReputationKpisLoading || googleSetupStatusLoading) return 'loading';
    return 'loaded';
  };

  if (!googleSetupStatusLoading && googleSetupStatus?.status !== GoogleSetupStage.Finished) {
    return <RequireConnectedGoogleAccount />;
  }

  return (
    <LoadingOrError state={state()} intlKeys={{ loading: 'reviews.ereputation.loading.loading' }}>
      {eReputationKpis?.length ? (
        <EReputation
          eReputationKpisViewModelByTimeframe={eReputationKpis}
          subscriptionDate={googleSetupStatus?.firstConnectionDate}
        />
      ) : (
        <NoEreputationData theme={theme} />
      )}
    </LoadingOrError>
  );
};
