import { CurrencyCode, Money } from '@sundayapp/web-money';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type QRCode = {
  qrCodeId: string;
  businessId: string;
  enrollmentId: string;
  shortURLId: string;
  businessName: string;
  description: string;
  title: string;
  amountCents: number;
  currency: CurrencyCode;
  qrCodeURL: string;
  eventDate: Date | undefined;
};

export type NewPaymentLink = {
  enrollmentId: EnrollmentId;
  title: string;
  description: string;
  amountInCents: number;
  eventDate: string | undefined;
};

export type NewPaymentLinkResponse = {
  shortLink: string;
};

export type PaymentLinkPayment = {
  paymentId: string;
  last4: string;
  brand: string;
  total: Money;
  sales: Money;
  digitalFee: Money;
};

export const paymentLinkStatuses = {
  CREATED: 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY: 'READY',
  DONE: 'DONE',
  DROPPED: 'DROPPED',
} as const;

export type PaymentLinkStatus = ValuesOf<typeof paymentLinkStatuses>;

export type PaymentLink = {
  sessionId: string;
  qrCode: QRCode;
  status: PaymentLinkStatus;
  createdAt: Date;
  payment?: PaymentLinkPayment;
  customerEmail?: string;
};

export const canArchive = (paymentLink: PaymentLink) => paymentLink.status !== paymentLinkStatuses.DROPPED && paymentLink.status !== paymentLinkStatuses.DONE;
export const canSendEmail = (paymentLink: PaymentLink) => paymentLink.status !== paymentLinkStatuses.DROPPED && paymentLink.status !== paymentLinkStatuses.DONE;
export const canDownloadReceipt = (paymentLink: PaymentLink) => !!paymentLink.payment;
