import { useIntl } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import RefreshSvg from 'src/app/component/icons/RefreshSvg';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Business } from 'src/business/domain/Business';
import { LinkButton } from '@atoms/LinkButton.tsx/LinkButton';

type OrderQrCodeProps = {
  business: Business;
};

export const OrderQrCodeButton = ({ business }: OrderQrCodeProps) => {
  const intl = useIntl();

  const reorderQRCodePath = (businessId: string, countryCode: string): string => {
    const configuration = ConfigurationLoader.load();
    const reorderQRCodesFRUrl = `${configuration.reorderQRCodesFRUrl}`;
    const reorderQRCodesUSUrl = `${configuration.reorderQRCodesUSUrl}`;
    const reorderQRCodesUKUrl = `${configuration.reorderQRCodesUKUrl}`;
    let reorderQRCodesPath = reorderQRCodesFRUrl;
    if (countryCode === 'US') {
      reorderQRCodesPath = reorderQRCodesUSUrl;
    }
    if (countryCode === 'GB') {
      reorderQRCodesPath = reorderQRCodesUKUrl;
    }
    return `${reorderQRCodesPath}${businessId}`;
  };

  return (
    <LinkButton
      icon={<RefreshSvg size={20} color={themeV5.palette.text.secondary} />}
      title={intl.formatMessage({ id: 'venue.home.actions.reorder_qr_code' })}
      onClick={() => {
        window.open(reorderQRCodePath(business.id, business.address.countryCode), '_blank');
      }}
    />
  );
};
