import styled, { css } from 'styled-components';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';
import IconButton from '../IconButton';
import { CheckIcon } from '../Icons';
import TimePicker from '../TimePicker';

export const OpeningHourContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 ${getSpacing(1)};
  ${textStyles.small}
`;

export const WarningContainer = styled.div`
  padding: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colorPalette.peach};
  border-radius: 16px;
`;

export const WarningMessage = styled.div`
  color: ${colorPalette.shadow};
`;

export const RoundWarningIcon = styled.div`
  width: 19px;
  height: 19px;
  background-color: ${colorPalette.defaultBackgroundColor};
  border-radius: 100%;
  margin-right: ${getSpacing(1)};
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const OpeningDay = styled.div`
  color: ${colorPalette.shadow};
`;

export const OpeningTimes = styled.div`
  color: ${colorPalette.shadow};
`;
export const ClosedTime = styled.div`
  color: ${colorPalette.placeholderColor};
`;
export const StyledIconButton = styled(IconButton)`
  margin: -12px 0 -4px;
`;

export const Line = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const OpeningHourLine = styled(Line)`
  margin-bottom: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
`;

export const DayLabel = styled.div`
  margin: 0 0 0 ${getSpacing(2)};
  flex: 0 1 ${getSpacing(20)};
  padding-top: ${getSpacing(0.5)};
`;

interface DayOpeningStatusProps {
  isOpen: boolean;
}

export const DayOpeningStatus = styled.div<DayOpeningStatusProps>`
  flex: 1;
  padding-top: ${getSpacing(0.5)};
  justify-content: space-between;
  color: ${colorPalette.placeholderColor};
  ${({ isOpen }) =>
    isOpen
    && css`
      color: ${colorPalette.defaultTextColor};
    `}
`;

export const StyledCheckIcon = styled(CheckIcon)`
  margin-top: -2px;
`;

export const TimePeriodAndIcon = styled(Line)`
  margin-top: ${getSpacing(2)};
  align-items: center;
`;

export const TimePeriodContainer = styled(Line)`
  justify-content: flex-start;
  flex: 1;
`;

export const StyledTimePicker = styled(TimePicker)`
  flex: 0 1 ${getSpacing(19)};
  margin-right: ${getSpacing(2)};
  height: ${getSpacing(5)} !important;
  padding: 0 ${getSpacing(2.5)} !important;
`;

export const OpeningHourLineHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const OpeningHourPeriods = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 0;
  box-sizing: border-box;
`;

export const OpeningHourStatusContainer = styled.span`
  display: flex;
`;

export const OpeningHourLabelLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const IconButtonWrapper = styled.div`
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
`;
