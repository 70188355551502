import { styled } from '@mui/material';

export const SignUpSubtitle = styled('div')`
  display: flex;
  justify-content: center;
  text-align: center;
`;


export const SignUpFormBody = styled('div')`
  padding: 24px;
  width: 100%;
  max-width: 576px;
  background: white;
  margin: 64px 0 24px;
  border-radius: 24px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(50, 50, 93, 0.06);
`;
