import { ValuesOf } from 'src/utils/typescript/valuesOf';

export const userRoles = {
  ADMIN: 'ADMIN',
  GENERAL_MANAGER: 'GENERAL_MANAGER',
  MANAGER: 'MANAGER',
  WAITER: 'WAITER',
  STAFF: 'STAFF',
} as const;

const roleHierarchy: UserRole[] = [
  userRoles.STAFF,
  userRoles.WAITER,
  userRoles.MANAGER,
  userRoles.GENERAL_MANAGER,
  userRoles.ADMIN,
];

export const isRoleAboveOrEqual = (role1: UserRole, role2: UserRole) => roleHierarchy.indexOf(role1) >= roleHierarchy.indexOf(role2);

export type UserRole = ValuesOf<typeof userRoles>;
