import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Block } from '../../domain/Block';
import { BlockRepositoryHttp } from '../../repositories/block/BlockRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_BLOCK = 'menu_block';
const buildQueryKey = (businessId: BusinessId, blockId: string, language?: string) =>
  [QUERYKEY_MENU_BLOCK, businessId, blockId, language] as const;
export const getBlockByIdQuery = async ({
  queryKey: [, businessId, blockId, language],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Block> => {
  const configuration = ConfigurationLoader.load();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return blockRepository.findByBlockId(businessId, blockId, language);
};

export const useGetBlockById = (businessId: BusinessId, blockId: string, language?: string) =>
  useQuery({ queryKey: buildQueryKey(businessId, blockId, language), queryFn: getBlockByIdQuery });
