import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../../../stylesheet';
import { AreaServiceContainer } from './EmptyAreaSummaryService.style';

const Container = styled(AreaServiceContainer)`
  color: ${colorPalette.red};
`;

export const EmptyAreaSummaryService = () => <Container>select type of service is required</Container>;
