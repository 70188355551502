import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { usePaymentAdapter } from '../page/usePaymentAdapter';
import { PaymentAdapter } from './PaymentAdapter';
import { EnrollmentForPayment } from '../domain/EnrollmentForPayment';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useFirstEnrollmentOfCurrentBusinessOrThrow } from 'src/business/hooks/useFirstEnrollmentOfCurrentBusinessOrThrow';

type MaybeEnrollmentId = EnrollmentId | undefined;
const getEnrollmentForPaymentQuery = (paymentAdapter: PaymentAdapter, enrollmentId: MaybeEnrollmentId) => ({
  queryKey: [queryKeys.GET_ENROLLMENT_FOR_PAYMENT, { enrollmentId }],
  queryFn: (): Promise<EnrollmentForPayment> => paymentAdapter.getEnrollment(enrollmentId!),
  enabled: !!enrollmentId,
});

export const useEnrollmentForPayment = () => {
  const enrollment = useFirstEnrollmentOfCurrentBusinessOrThrow();
  const paymentAdapter = usePaymentAdapter();
  const enrollmentId: MaybeEnrollmentId = enrollment?.id;
  return useQuery(getEnrollmentForPaymentQuery(paymentAdapter, enrollmentId));
};
