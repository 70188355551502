import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const OwnershipPercentage = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.ownershipPercentage;

  return <TextField
    fullWidth
    required
    type={'number'}
    error={!!error}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ownershipPercentage' })}
    InputLabelProps={{ shrink: true }}
    {...register(`representatives.${representativeIndex}.ownershipPercentage`, {
      setValueAs: (value) => value ? parseInt(value) : undefined,
      onChange: (e) => {
        if (e.target.value) {
          setValue(`representatives.${representativeIndex}.ownershipPercentage`, parseInt(e.target.value));
        }
      },
      required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ownershipPercentage.error.required' }),
      pattern: {
        value: /^[0-9]*$/,
        message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ownershipPercentage.error.invalid.format' }),
      },
      max: { value: 100, message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ownershipPercentage.error.invalid.max' }) },
      min: { value: 25, message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ownershipPercentage.error.invalid.min' }) },
    })}
    helperText={!!error ? error.message : ''}
  />;
};
