export const CardHeaderTheme = {
  styleOverrides: {
    root: {
      gap:'16px',
    },
    subheader: {
      paddingTop: '0.5rem',
    },
  },
};
