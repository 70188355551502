import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { TitleContent, TitleEmphasis } from './HomeTitle';

export const HomeTitleWithoutAdoption = () => (
  <TitleContent>
    <span>
      <FormattedMessage
        id="venue.home.no-adoption.title.part1"
        defaultMessage="More sunday payments means"
      />
    </span>
    <TitleEmphasis>
      <FormattedMessage
        id="venue.home.no-adoption.title.emphasis"
        defaultMessage="more tips and reviews"
      />
    </TitleEmphasis>
    {'. '}
  </TitleContent>
);
