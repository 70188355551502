import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Typography, useTheme } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import React from 'react';
import { formatNumber } from './formatNumbers';

type Props = {
  differenceWithPreviousTimeframe: number;
};
export const Variation = ({
  differenceWithPreviousTimeframe,
}: Props) => {
  const theme = useTheme();
  const greenColor = theme.palette.success.main;
  const redColor = theme.palette.error.dark;

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '0.2rem' }}>
      {differenceWithPreviousTimeframe === 0
        && (
          <>
          </>
        )}
      {differenceWithPreviousTimeframe > 0
        && (
          <>
            <ArrowUpwardIcon style={{ color: greenColor }} />
            <Typography variant="subtitle1" color={greenColor}>
              +
              {formatNumber(differenceWithPreviousTimeframe, 2)}
            </Typography>
          </>
        )}
      {differenceWithPreviousTimeframe < 0
        && (
          <>
            <ArrowDownwardIcon style={{ color: redColor }} />
            <Typography variant="subtitle1" color={redColor}>
              {formatNumber(differenceWithPreviousTimeframe, 2)}
            </Typography>
          </>
        )}
    </div>
  );
};
