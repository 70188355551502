import styled from 'styled-components';

export const ReviewsHeader = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  height: 64px;
`;

export const ReviewListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  width: 100%;
`;
export const DatePickerContainer = styled.div`
  margin-top: 50px;
`;
