import { useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { venueAccountingInvoicesPath } from 'src/app/navigation/pathHelpers';
import ValidatedDocumentSvg from 'src/app/component/icons/ValidatedDocumentSvg';
import { Business } from 'src/business/domain/Business';
import { LinkButton } from 'src/home2/components/atoms/LinkButton.tsx/LinkButton';

type InvoiceButtonProps = {
  business: Business;
};

export const InvoiceButton = ({ business }: InvoiceButtonProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <LinkButton
      icon={<ValidatedDocumentSvg size={20} color={themeV5.palette.text.secondary} />}
      title={intl.formatMessage({ id: 'venue.home.actions.download.invoices' })}
      onClick={() => {
        navigate(venueAccountingInvoicesPath(business.id));
      }}
    />
  );
};
