import { GetApp } from '@mui/icons-material';
import CsvDownloader from 'react-csv-downloader';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button } from '@mui/material';
import { CustomerData } from 'src/marketing/customer-data/domain/CustomerData';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

interface CustomerDataCSVExporterButtonProps {
  venueName: string;
  customerDataList: CustomerData[];
}

function GuestConsentCSVExporterButton({ venueName, customerDataList }: CustomerDataCSVExporterButtonProps) {
  const business = useCurrentBusinessOrThrow();

  function buildFileName() {
    const isoFormattedDate = new Date(Date.now()).toISOString();
    const fileNameFormattedVenueName = venueName.toLowerCase().replace(' ', '-');

    return `${isoFormattedDate}-${fileNameFormattedVenueName}`;
  }

  function formatCustomerData() {
    return customerDataList.map((customerData) => ({
      optInDate: new Date(customerData.optInDate).toISOString(),
      firstName: customerData.firstName != null ? customerData.firstName : '',
      lastName: customerData.lastName != null ? customerData.lastName : '',
      email: customerData.email != null ? customerData.email : '',
      phoneNumber: customerData.email != null ? customerData.phoneNumber : '',
      birthDate: customerData.email != null ? customerData.birthDate : '',
      waiterName: customerData.waiterName != null ? customerData.waiterName : '',
      tableNumber: customerData.tableNumber != null ? customerData.tableNumber : '',
      totalAmount: customerData.totalAmount != null ? String(customerData.totalAmount / 100000) : '',
      individualSplitAmount:
        customerData.individualSplitAmount != null ? String(customerData.individualSplitAmount / 100000) : '',
      currency: customerData.currency != null ? customerData.currency : '',
      optInLevel: customerData.optInLevel,
      businessName: business.name,
    }));
  }

  const intl = useIntl();

  const columnsDefinition = [
    {
      id: 'optInDate',
      displayName: intl.formatMessage({ id: 'customer_data.date' }),
    },
    {
      id: 'firstName',
      displayName: intl.formatMessage({ id: 'customer_data.firstName' }),
    },
    {
      id: 'lastName',
      displayName: intl.formatMessage({ id: 'customer_data.lastName' }),
    },
    {
      id: 'email',
      displayName: intl.formatMessage({ id: 'customer_data.email' }),
    },
    {
      id: 'phoneNumber',
      displayName: intl.formatMessage({ id: 'customer_data.phoneNumber' }),
    },
    {
      id: 'birthDate',
      displayName: intl.formatMessage({ id: 'customer_data.birthDate' }),
    },
    {
      id: 'waiterName',
      displayName: intl.formatMessage({ id: 'customer_data.staff_name' }),
    },
    {
      id: 'tableNumber',
      displayName: intl.formatMessage({ id: 'customer_data.table_id' }),
    },
    {
      id: 'totalAmount',
      displayName: intl.formatMessage({ id: 'customer_data.total_amount' }),
    },
    {
      id: 'individualSplitAmount',
      displayName: intl.formatMessage({ id: 'customer_data.split_amount' }),
    },
    {
      id: 'currency',
      displayName: intl.formatMessage({ id: 'customer_data.currency' }),
    },
    {
      id: 'optInLevel',
      displayName: intl.formatMessage({ id: 'customer_data.opt_in_level' }),
    },
    {
      id: 'businessName',
      displayName: intl.formatMessage({ id: 'customer_data.business_name', defaultMessage: 'Business Name' }),
    },
  ];

  function onDownloadClicked() {
    ampli.customerDataCsvExportButtonClicked({
      businessId: business.id,
      businessName: business.name,
      businessCountry: business.address.countryCode,
    });
  }

  return (
    <CsvDownloader
      filename={buildFileName()}
      wrapColumnChar='"'
      columns={columnsDefinition}
      datas={formatCustomerData()}
    >
      <Button variant="outlined" color="primary" size="small" startIcon={<GetApp />} onClick={onDownloadClicked}>
        <FormattedMessage id="qr_codes.export_csv" />
      </Button>
    </CsvDownloader>
  );
}

export default GuestConsentCSVExporterButton;
