import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Link from '@mui/material/Link';
import { themeV5 } from 'src/app/theme/ThemeV5';
import React, { FC } from 'react';
import { BlockAction } from 'src/menu/dynamic-menu/pages/ProductsPage/components/blocks/BlocksList';
import { ProductAction } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';
import { AllergenAction } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/AllergensList';

type Props = {
  elementType: 'block' | 'product' | 'modifier' | 'allergen';
  onActionConfirmed: (selectedAction: any) => void;
  action: BlockAction | ProductAction | AllergenAction;
  onClose: () => void;
};

export const ConfirmActionModal: FC<Props> = ({ elementType, action, onActionConfirmed, onClose }) => {
  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle sx={{ p: 3 }}>
        {/* @ts-ignore */}
        <FormattedMessage id={`menus.${elementType}.${action.actionName}.modal.title`} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            // @ts-ignore
            id={`menus.${elementType}.${action.actionName}.modal.text`}
            values={{ name: <strong>{action.targetName}</strong> }}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Link
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            color: themeV5.palette.text.primary,
            textDecorationColor: themeV5.palette.text.primary,
          }}
        >
          <Typography variant="body1">
            {/* @ts-ignore */}
            <FormattedMessage id={`menus.${elementType}.modal.cancel`} defaultMessage="cancel" />
          </Typography>
        </Link>
        <Button onClick={() => onActionConfirmed(action)} autoFocus variant="contained">
          {/* @ts-ignore */}
          <FormattedMessage id={`menus.${elementType}.modal.confirm`} defaultMessage="continue" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
