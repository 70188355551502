import React, {
  ChangeEvent, Dispatch, InputHTMLAttributes, ReactNode, SetStateAction, useCallback,
} from 'react';
import { InputContainer, InputField } from './Input.style';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  onValueChange: Dispatch<SetStateAction<string>> | ((value: string) => void);
  endIcon?: ReactNode;
  hasError?: boolean;
  leftAligned?: boolean;
}

const Input: React.FC<InputProps> = ({
  onValueChange,
  endIcon,
  className,
  hasError = false,
  leftAligned = true,
  ...props
}) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onValueChange(event.currentTarget.value);
    },
    [onValueChange],
  );
  return (
    <InputContainer className={className} hasError={hasError}>
      <InputField {...props} onChange={onChange} leftAligned={leftAligned} />
      {endIcon}
    </InputContainer>
  );
};

export default Input;
