import { CircularProgress, styled, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { ReactNode } from 'react';
import { NoEreputationData } from './NoEreputationData';
import { LocalisationKey } from 'src/lang/en';

export type ViewState = 'error' | 'loading' | 'loaded';

const LoadingOrErrorStyle = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

export const LoadingOrError = ({
  state,
  children,
  intlKeys,
}: {
  state: ViewState, children: ReactNode, intlKeys: {
    loading: LocalisationKey,
  }
}) => {
  const theme = useTheme();
  if (state === 'loaded') return <>{children}</>;
  return (
    <LoadingOrErrorStyle>
      {state === 'loading' && (
        <>
          <CircularProgress />
          <Typography>
            <FormattedMessage id={intlKeys.loading} />
          </Typography>
        </>
      )}
      {state === 'error' && (
        <NoEreputationData theme={theme} />
      )}
    </LoadingOrErrorStyle>
  );
};
