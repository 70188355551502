import React, { useContext, useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { datadogLogs } from '@datadog/browser-logs';
import { VposVenueRepositoryContext } from 'src/app/contexts/vposvenue/VposVenueRepositoryContextProvider';
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  darken,
  FormControlLabel,
  useTheme,
} from '@mui/material';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { RevenueCenter } from 'src/domain/venue/RevenueCenter';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-router';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MaterialReactTable, MRT_ColumnDef, useMaterialReactTable } from 'material-react-table';
import _ from 'lodash';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { getMrtLocale } from 'src/components/table/MaterialReactTable';
import { useGetFirstActivePosConnectionForBusiness } from 'src/pos/queries/getPosConnections';
import { PosConnection, PosConnectionId } from 'src/pos/domain/PosConnection';
import MenuItem from '@mui/material/MenuItem';

type FormValues = {
  revenueCenters: Record<string, RevenueCenter>;
};

const revenueCentersQueryKey = 'revenueCenters';

export type LoadedRevenueCentersSettingsProps = {
  posConnection: PosConnection;
  revenueCenters: RevenueCenter[];
  postRevenueCenters: (posConnectionId: PosConnectionId, revenueCenters: RevenueCenter[]) => Promise<void>;
};

const LoadedRevenueCentersSettings = ({
  posConnection,
  postRevenueCenters,
  revenueCenters,
}: LoadedRevenueCentersSettingsProps) => {
  const { formatMessage } = useIntl();
  const queryClient = useQueryClient();
  const snackbar = useSnackbar();


  const saveRevenueCentersMutation = useMutation({
    mutationFn: (updatedRevenueCenters: RevenueCenter[]) => postRevenueCenters(posConnection.id, updatedRevenueCenters),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: [revenueCentersQueryKey] });
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
    setValue,
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      revenueCenters: _.keyBy(revenueCenters, (r) => r.id),
    },
  });

  async function onSubmit(formValues: FormValues) {
    return saveRevenueCentersMutation
      .mutateAsync(Object.values(formValues.revenueCenters))
      .then(() => {
        reset(formValues);

        snackbar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'saved', defaultMessage: 'saved' }),
        });
      })
      .catch(() => {
        snackbar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'error', defaultMessage: 'error' }),
        });
      });
  }

  const columns = useMemo<MRT_ColumnDef<RevenueCenter>[]>(
    () => [
      {
        id: 'id',
        header: `${formatMessage({ id: 'settings.revenue_centers.id', defaultMessage: 'Id' })}`,
        accessorFn: (row) => row.id,
      },
      {
        id: 'label',
        header: `${formatMessage({ id: 'settings.revenue_centers.label', defaultMessage: 'Label' })}`,
        accessorFn: (row) => row.label,
      },
      {
        id: 'active',
        header: formatMessage({ id: 'settings.revenue_centers.active', defaultMessage: 'Active' }),
        accessorFn: (row) => row.isActive,
        renderColumnActionsMenuItems: ({ closeMenu }) => [
          <MenuItem
            key={1}
            onClick={() => {
              revenueCenters.forEach((revenueCenter) => {
                setValue(`revenueCenters.${revenueCenter.id}.isActive`, true, { shouldDirty: true });
              });
              closeMenu();
            }}
          >
            Select all
          </MenuItem>,
          <MenuItem
            key={2}
            onClick={() => {
              revenueCenters.forEach((revenueCenter) => {
                setValue(`revenueCenters.${revenueCenter.id}.isActive`, false, { shouldDirty: true });
              });
              closeMenu();
            }}
          >
            Unselect all
          </MenuItem>,

        ],
        Cell: ({ row }) => (
          <Controller
            control={control}
            name={`revenueCenters.${row.original.id}.isActive`}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={<Checkbox />}
                label={`${formatMessage({ id: 'settings.revenue_centers.active', defaultMessage: 'Active' })}:`}
                labelPlacement="start"
                checked={value}
                onChange={onChange}
              />
            )}
          />
        ),
      },
    ],
    [],
  );

  const theme = useTheme();
  const baseBackgroundColor = theme.palette.background.paper;

  const table = useMaterialReactTable({
    columns,
    data: revenueCenters ?? [],
    enablePagination: false,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    enableHiding: false,

    initialState: {
      sorting: [
        {
          id: 'label',
          desc: false,
        },
      ],
    },

    mrtTheme: {
      baseBackgroundColor: baseBackgroundColor,
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: darken(baseBackgroundColor, 0.02),
        },
      },
    },

    localization: getMrtLocale(),
  });

  return (
    <Card>
      <Form onSubmit={handleSubmit((formValues) => onSubmit(formValues))}>
        <CardHeader
          title={<></>}
          subheader={formatMessage({
            id: 'settings.revenue_centers.description',
            defaultMessage: 'Select active revenue centers for your venue',
          })}
          action={
            <Button variant="contained" color="primary" type="submit" disabled={!isDirty || !isValid || isSubmitting}>
              {formatMessage({ id: 'settings.save_changes', defaultMessage: 'Save' })}
            </Button>
          }
        />
        <CardContent>
          <Alert variant="outlined" severity="warning">
            {formatMessage({ id: 'settings.revenue_centers.warning' })}
          </Alert>
          <FormattedMessage id={'settings.revenue_centers.details'} />
          <MaterialReactTable table={table} />
        </CardContent>
      </Form>
    </Card>
  );
};

export const RevenueCentersSettings = () => {
  const { getRevenueCenters, postRevenueCenters } = useContext(VposVenueRepositoryContext);
  const business = useCurrentBusinessOrThrow();
  const {
    data: posConnection,
    isLoading: posConnectionLoading,
  } = useGetFirstActivePosConnectionForBusiness(business.id);
  const { data: revenueCenters, isLoading: revenueCenterLoading } = useQuery({
    queryKey: [revenueCentersQueryKey, business.id],
    queryFn: async (): Promise<RevenueCenter[]> => {
      try {
        return await getRevenueCenters(posConnection!.id);
      } catch (e) {
        datadogLogs.logger.warn(`impossible to retrieve revenue centers ${e}`);
        throw e;
      }
    },
    enabled: !!posConnection,
  });

  if (posConnectionLoading || revenueCenterLoading) return <CircularProgress />;

  return <LoadedRevenueCentersSettings posConnection={posConnection!} revenueCenters={revenueCenters ?? []}
                                       postRevenueCenters={postRevenueCenters} />;
};

