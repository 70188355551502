/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const AwardSvg: React.FC<Props> = ({ size = 16, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.94596 9.32411L2.77533 3.65039C2.41076 2.998 2.22847 2.6718 2.25919 2.40473C2.286 2.17172 2.40975 1.96079 2.60008 1.82373C2.81824 1.66663 3.19191 1.66663 3.93926 1.66663H5.80161C6.07935 1.66663 6.21823 1.66663 6.34279 1.70674C6.45302 1.74223 6.55463 1.80031 6.64116 1.87726C6.73895 1.96423 6.80944 2.08388 6.95041 2.32319L10.0001 7.49996L13.0498 2.32319C13.1907 2.08388 13.2612 1.96423 13.359 1.87726C13.4455 1.80031 13.5472 1.74223 13.6574 1.70674C13.7819 1.66663 13.9208 1.66663 14.1986 1.66663H16.0609C16.8083 1.66663 17.1819 1.66663 17.4001 1.82373C17.5904 1.96079 17.7142 2.17172 17.741 2.40473C17.7717 2.6718 17.5894 2.998 17.2248 3.65039L14.0542 9.32411M8.75009 11.6666L10.0001 10.8333V15M8.95842 15H11.0418M13.8303 9.08646C15.9456 11.2018 15.9456 14.6314 13.8303 16.7468C11.7149 18.8621 8.28527 18.8621 6.16992 16.7468C4.05459 14.6315 4.05459 11.2018 6.16992 9.08646C8.28526 6.97113 11.7149 6.97113 13.8303 9.08646Z" stroke={color} strokeWidth="1.67" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default AwardSvg;
