import axios from 'axios';
import { EReputationRepository } from '../domain/EReputationRepository';
import { EReputationKpis, EReputationKpisDto } from '../domain/EReputationKpis';
import { mapEReputationKpis } from './eReputationFromBack';
import { FOUR_WEEKS, KpiTimeframe, SEVEN_DAYS, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

export const mapJsonStringToDateWithoutTimezone = (date: string): Date => {
  const strings = date.split('-');
  return new Date(parseInt(strings[0], 10), parseInt(strings[1], 10) - 1, parseInt(strings[2], 10));
};

export const mapTimeFrame = (timeFrame: string): KpiTimeframe => {
  switch (timeFrame) {
    case 'SEVEN_DAYS':
      return SEVEN_DAYS;
    case 'FOUR_WEEKS':
      return FOUR_WEEKS;
    case 'TWELVE_MONTHS':
      return TWELVE_MONTHS;
  }

  throw new Error(`unknown time frame : ${timeFrame}`);
};

export const mapKpis = mapEReputationKpis(mapJsonStringToDateWithoutTimezone, mapTimeFrame);

export class HttpEReputationRepository implements EReputationRepository {
  constructor(private readonly baseUrl: string) {
  }

  async getKpis(businessId: string, timezone: string): Promise<EReputationKpis> {
    return axios.get<EReputationKpisDto>(`${this.baseUrl}/businesses/${businessId}/e-reputation-kpis`,
      {
        headers: {
          'x-timezone': timezone,
        },
      })
      .then(
        (response) => mapKpis(response.data),
      );
  }
}
