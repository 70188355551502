import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { useQuery } from '@tanstack/react-query';
import { useDependencies } from 'src/tips/dispatch/useDependencies';
import { SundayPoolingRepository } from 'src/tips/dispatch/model/SundayPoolingRepository';
import { PendingTipsDispatchArray } from 'src/tips/dispatch/model/PendingTipsDispatch';

const getAllPendingTipsDispatchQuery = (businessId: BusinessId, sundayPoolingRepository: SundayPoolingRepository) => ({
  queryKey: [queryKeys.sundayTipsPooling.GET_PENDING_TIPS_DISPATCH, { businessId }],
  queryFn: (): Promise<PendingTipsDispatchArray> => sundayPoolingRepository.getAllPendingTipsDispatch(businessId),
});

export const useGetAllPendingTipsDispatch = (businessId: BusinessId) => {
  const dependencies = useDependencies();
  return useQuery(getAllPendingTipsDispatchQuery(businessId, dependencies.sundayPoolingRepository));
};
