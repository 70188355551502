import { TimePeriod } from '../../common/domain/TimePeriod';
import { PromoCode } from '../types';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const RATE_TO_PERCENT = 100 / 10000;
export const PERCENT_TO_RATE = 10000 / 100;

export const isSameDay = (day: Date, day2: Date): boolean => (
  day.getFullYear() === day2.getFullYear() && day.getMonth() === day2.getMonth() && day.getDate() === day2.getDate()
);

export const isSameTimePeriod = (t1: TimePeriod, t2: TimePeriod) => t1.weekDay === t2.weekDay && t1.startTime === t2.startTime && t1.endTime === t2.endTime;

export const areSameTimePeriods = (a1: TimePeriod[], a2: TimePeriod[]) => {
  if (a1.length !== a2.length) return false;
  const uniqueValues = new Set([...a1, ...a2]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const a1Count = a1.filter((t) => isSameTimePeriod(t, v)).length;
    const a2Count = a2.filter((t) => isSameTimePeriod(t, v)).length;
    if (a1Count !== a2Count) return false;
  }
  return true;
};

export const excludedBoxIdsChanged = (excludedBoxIds: string[], promoCodeExcludedBoxIds: string[]) => {
  if (excludedBoxIds.length! === promoCodeExcludedBoxIds.length) return false;
  const uniqueValues = new Set([...excludedBoxIds, ...promoCodeExcludedBoxIds]);
  // eslint-disable-next-line no-restricted-syntax
  for (const v of uniqueValues) {
    const excludedBoxIdsCount = excludedBoxIds.filter((e) => e === v).length;
    const promoCodeExcludedBoxIdsCount = promoCodeExcludedBoxIds.filter((e) => e === v).length;
    if (excludedBoxIdsCount !== promoCodeExcludedBoxIdsCount) return true;
  }
  return false;
};

export const areSamePromoCodes = (currentPromoCode: PromoCode, promoCode: PromoCode) => (
  currentPromoCode.code !== promoCode.code
    || currentPromoCode.rate !== promoCode.rate
    || excludedBoxIdsChanged(currentPromoCode.excludedBoxIds, promoCode.excludedBoxIds)
    || !isSameDay(new Date(currentPromoCode.availableFromEpochMilli), new Date(promoCode.availableFromEpochMilli))
    || !isSameDay(new Date(currentPromoCode.availableUntilEpochMilli), new Date(promoCode.availableUntilEpochMilli))
    || !areSameTimePeriods(currentPromoCode.availabilities, promoCode.availabilities)
);

export const newEmptyPromoCode = (enrollmentId: EnrollmentId) => {
  const promoCode: PromoCode = {
    voucherId: '',
    enrollmentId,
    code: '',
    type: 'RATE',
    amount: undefined,
    rate: 0,
    availableFromEpochMilli: Date.now(),
    availableUntilEpochMilli: Date.now(),
    availabilities: [],
    excludedBoxIds: [],
  };
  return promoCode;
};
