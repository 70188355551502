import { z } from 'zod';

export const RevenueCenterDtoSchema = z.object({
  externalId: z.string(),
  name: z.string(),
  supported: z.boolean(),
});

export type RevenueCenterDto = z.infer<typeof RevenueCenterDtoSchema>;

export const RevenueCenterListDtoSchema = z.object({
  revenueCenters: z.array(RevenueCenterDtoSchema),
});

export type RevenueCenterListDto = z.infer<typeof RevenueCenterListDtoSchema>;
