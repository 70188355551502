import { Money, MoneyView } from '@sundayapp/web-money';
import React from 'react';

type OrphanPosAmountCellProps = {
  amount: Money
};

export const OrphanPosAmountCell = ({ amount }: OrphanPosAmountCellProps) => {
  return (
    <>
      <MoneyView value={amount} />
    </>
  );
};
