import styled from 'styled-components';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { VenueDetails } from 'src/ordering/venues/types';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { OrderLineBox, OrderNotificationMessageLine } from './OrderNotificationMessageLine';
import React from 'react';

const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colorPalette.defaultTextColor};
  margin-bottom: ${getSpacing(1)};
`;
const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
`;
type OrderMessagesVenue = Pick<VenueDetails, 'name' | 'isFoodCourt' | 'countryCode' >;
type Props = { disabled: boolean, venue: OrderMessagesVenue, box: OrderLineBox, setMessage: (updatedMessage: string, locale: string) => Promise<void> };
export const OrderNotificationMessages = ({
  disabled,
  venue,
  box,
  setMessage,
}: Props) => {
  return <>
    <Title>
      <FormattedMessage
        id="venue.settings.orderNotification.message"
        defaultMessage="choose the custom notification message to send to the customer."
        description="Settings to update the message sent to customers"
      />
    </Title>
    <Description>
      <FormattedMessage
        id="venue.settings.orderNotification.message.description"
        defaultMessage="if no message is defined, the default message is used. Use #NUM# as a placeholder for the order number."
        description="Settings to describe how to update the message sent to customers"
      />
    </Description>
    {venue.countryCode && venue.countryCode === 'FR' &&
      <OrderNotificationMessageLine locale="fr" disabled={disabled} venue={venue} box={box} setMessage={setMessage} />
    }
    <OrderNotificationMessageLine locale="en" disabled={disabled} venue={venue} box={box} setMessage={setMessage} />
  </>;
};
