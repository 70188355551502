import { useLocalStorage } from 'react-use';
import { Dispatch, SetStateAction } from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useFixedLocalStorage<T>(key: string, defaultValue: T) {
  const [ value, setValue ] = useLocalStorage(key, defaultValue);

  const setFixedValue: SetValue<T> = (newValueOrUpdater) => {
    if (typeof newValueOrUpdater === 'function') {
      setValue((prevValue) => (newValueOrUpdater as (prevValue: T) => T)(prevValue as T));
    } else {
      setValue(newValueOrUpdater);
    }
  };

  return [ value ?? defaultValue, setFixedValue ] as const;
}
