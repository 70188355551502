import React from 'react';
import { PayoutHttpPort } from '../infrastructure/PayoutHttpPort';
import { PayoutPdqHttpPort } from 'src/accounting/payout/infrastructure/PayoutPdqHttpPort';
import { Payouts } from './Payouts';
import { ConfigurationContainerContext } from './ConfigurationContainerContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';

interface Props {
  businessId: BusinessId;
  timezone : string;
}

export const PayoutsWithContext: React.FC<Props> = ({ businessId, timezone }: Props) => {
  const appConfiguration = ConfigurationLoader.load();
  return (
    <ConfigurationContainerContext.Provider
      value={{
        payoutPort: PayoutHttpPort(appConfiguration.accountingApiBaseUrl),
        payoutPdqPort: PayoutPdqHttpPort(appConfiguration.paymentTerminalBaseUrl),
      }}
    >
      <Payouts businessId={businessId} timezone={timezone} />
    </ConfigurationContainerContext.Provider>
  );
};
