import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateBlockDto, BlockDto, BlocksSummaryDto, UpdateBlockDto,
} from './BlockDto';
import { BlockDtoConverter } from './BlockDtoConverter';
import { RepositoryHttp } from 'src/ordering/common/repository/RepositoryHttp';
import { Block } from '../../domain/Block';
import { MenuImageUrlDto } from '../MenuDto';
import { BusinessId } from 'src/business/domain/Business';

export class BlockRepositoryHttp extends RepositoryHttp {
  constructor(private menuBackendUrl: string) {
    super();
  }

  async updateBlock(businessId: BusinessId, blockId: string, block: UpdateBlockDto): Promise<void> {
    await axios.patch<BlockDto>(`${this.menuBackendUrl}/businesses/${businessId}/blocks/${blockId}`, block,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      });
  }

  async updateBlockTranslation(businessId: BusinessId, blockId: string, language: string, blockUpdate: UpdateBlockDto): Promise<void> {
    await axios.patch<BlockDto>(`${this.menuBackendUrl}/businesses/${businessId}/blocks/${blockId}/translations/${language}`, blockUpdate,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      });
  }

  async deleteBlock(businessId: BusinessId, blockId: string): Promise<void> {
    await axios.delete<BlockDto>(`${this.menuBackendUrl}/businesses/${businessId}/blocks/${blockId}`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      });
  }

  async createBlock(businessId: BusinessId, createBlockRequest: CreateBlockDto): Promise<string> {
    const response = await axios.post<BlockDto>(`${this.menuBackendUrl}/businesses/${businessId}/blocks`,
      createBlockRequest,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      });
    return response.data.id;
  }

  async findAllByBusinessId(businessId: BusinessId): Promise<Block[]> {
    const response = await axios.get<BlocksSummaryDto>(`${this.menuBackendUrl}/businesses/${businessId}/blocks`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });

    return response.data.blocks
      .map((block) => BlockDtoConverter.toBlock(block))
      .sort((m1, m2) => m1.title.localeCompare(m2.title, 'en', { numeric: true }));
  }

  getBlockImageUri(businessId: BusinessId, blockId: string): string {
    return `${this.menuBackendUrl}/blocks/${blockId}/image`;
  }

  async findByBlockId(businessId: BusinessId, blockId: string, language?: string): Promise<Block> {
    const queryParams = language ? `?${new URLSearchParams({ language })}` : '';
    const response = await axios.get<BlockDto>(`${this.menuBackendUrl}/businesses/${businessId}/blocks/${blockId}${queryParams}`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });

    return BlockDtoConverter.toBlock(response.data);
  }

  async updateBlockPicture(businessId: BusinessId, blockId: string, pictureBytes: any): Promise<string> {
    return new Promise((success, failure) => {
      axios.put<MenuImageUrlDto>(
        `${this.menuBackendUrl}/businesses/${businessId}/blocks/${blockId}/image`, pictureBytes,
        {
          ...this.withDefaultHeader(),
          headers: {
            ...this.withDefaultHeader().headers,
            'Content-Type': 'application/octet-stream',
          },
        },
      ).then((response) => {
        success(response.data.imageUrl);
      })
        .catch(failure);
    });
  }

  async copyBlock(businessId: BusinessId, blockId: string): Promise<Block> {
    const response = await axios.post<BlockDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/blocks/${blockId}/copy`,
      {},
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
    return BlockDtoConverter.toBlock(response.data);
  }
}
