import { colorUsage, Panel, spaceUsages, Stats, StatsGroup } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { sumMoneys } from '@sundayapp/web-money';
import { ReportingDigest } from '../../domain/ReportingDigest';
import { CustomMoneyView } from '../CustomMoneyView';
import { RevenueAndTips, RevenueAndTipsProps, RevenuesAndTipsSummary } from '../RevenuesAndTipsSummary';
import { PaymentTerminalSummary } from '../PaymentTerminalSummary';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { OrderingReportingPanel } from '../OrderingReporting';
import { DateRange } from '../../domain/DateRange';
import { Business } from 'src/business/domain/Business';

const RevenueWrapper = styled(Panel)`
  box-shadow: none;
  border: 1px solid ${colorUsage.quaternaryBorder};
  margin-top: ${spaceUsages.mediumLarge};
  background-color: ${colorUsage.fogBackground};
`;

type NonUSReportingViewProps = {
  reportingDigest: ReportingDigest;
  selectedBusiness: Business | undefined;
  reportingDateRange: DateRange;
};

export const NonUSReportingView = ({
  reportingDigest,
  selectedBusiness,
  reportingDateRange,
}: NonUSReportingViewProps) => {
  const { formatMessage } = useIntl();

  const mapProvider = (provider: string): string =>
    (provider === 'Nepting' ? formatMessage({ id: 'accounting.reporting.nepting.details' }) : provider);

  if (!selectedBusiness) {
    return <div />;
  }
  const venueCurrency = selectedBusiness.currency;

  const otherRevenues: RevenueAndTipsProps[] = reportingDigest
    .getOtherProviders('sunday')
    .map(([provider, revenue]) => ({
      name: mapProvider(provider),
      revenue: revenue.grossRevenue,
      tips: revenue.tipRevenue,
      tipsDescription: formatMessage({ id: 'accounting.provider_tips_description' }),
      revenueDescription: formatMessage({ id: 'accounting.total_revenue_description' }),
      inconsistency: revenue.inconsistency,
    }));

  const sundayRevenueAndTips: RevenueAndTipsProps = {
    name: 'sunday',
    revenue: reportingDigest.getProviderRevenue(venueCurrency, 'sunday'),
    tips: reportingDigest.getProviderTips(venueCurrency, 'sunday'),
    tipsDescription: formatMessage({ id: 'accounting.provider_tips_description' }),
    revenueDescription: formatMessage({ id: 'accounting.total_revenue_description' }),
    inconsistency: reportingDigest.getProviderInconsistency('sunday'),
  };

  // Should be re-design after nepting pilot
  const paymentTerminalSchemeDetails = reportingDigest.getPaymentTerminalSchemeDetails();

  const paymentTerminalTotal = safeSumMoney(paymentTerminalSchemeDetails
    .map((financeProp) => sumMoneys(financeProp.grossRevenue, financeProp.tipRevenue)), venueCurrency);

  return (
    <>
      <StatsGroup
        showDetailsLabel={(
          <span role="link" tabIndex={0}>
            {formatMessage({ id: 'accounting.show_details' })}
          </span>
        )}
        hideDetailsLabel={formatMessage({ id: 'accounting.hide_details' })}
      >
        <RevenueAndTips
          name={formatMessage({ id: 'accounting.total' })}
          revenue={reportingDigest.getTotalRevenueWithoutTips(venueCurrency)}
          tips={reportingDigest.getTotalTips(venueCurrency)}
          tipsDescription={formatMessage({ id: 'accounting.total_tips_description' })}
          revenueDescription={formatMessage({ id: 'accounting.total_description' })}
        />

        {sundayRevenueAndTips.revenue && (
          <RevenuesAndTipsSummary revenuesAndTips={[sundayRevenueAndTips]} title="sunday" currency={venueCurrency} />
        )}

        {selectedBusiness.oapEnrollment !== undefined
          && <OrderingReportingPanel businessId={selectedBusiness.id}
                                     enrollmentId={selectedBusiness.oapEnrollment.id}
                                     countryCode={selectedBusiness.address.countryCode}
                                     reportingDateRange={reportingDateRange}/>
        }

        {otherRevenues && (
          <RevenuesAndTipsSummary
            title={formatMessage({ id: 'accounting.other_revenues' })}
            revenuesAndTips={otherRevenues}
            currency={venueCurrency}
          />
        )}
      </StatsGroup>

      {paymentTerminalSchemeDetails.length > 0 && (
        <RevenueWrapper>
          <PaymentTerminalSummary
            schemeSummaries={paymentTerminalSchemeDetails}
            total={paymentTerminalTotal}
          />
        </RevenueWrapper>
      )}

      <RevenueWrapper>
        <Stats
          dataLabel={formatMessage({ id: 'accounting.additional_revenue' })}
          dataProvider={formatMessage({ id: 'accounting.additional_revenue_description' })}
          amount={<CustomMoneyView value={reportingDigest.additionalRevenue} />}
          data-testid="additional-revenue"
        />
      </RevenueWrapper>
    </>
  );
};
