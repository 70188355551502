import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { PaymentTerminalConfigurationSummaryDto } from '../domain/PaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const QUERY_KEY_PAYMENT_TERMINAL_CONFIGURATION_LIST = 'payment_terminal_configuration_list';
const buildQueryKey = (enrollmentId: EnrollmentId) => [QUERY_KEY_PAYMENT_TERMINAL_CONFIGURATION_LIST, enrollmentId] as const;
const listPaymentTerminalConfigurationsQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<PaymentTerminalConfigurationSummaryDto[]> => {
  const [, enrollmentId] = queryKey;
  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return repository.configurations(enrollmentId);
};

export const useListPaymentTerminalConfigurations = (enrollmentId: EnrollmentId) =>
  useQuery({ queryKey: buildQueryKey(enrollmentId), queryFn: listPaymentTerminalConfigurationsQuery });
