import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';

type PayWithCashDisclaimerModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const AlternativePaymentMethodDisclaimer = styled.div`
  color: ${colorPalette.placeholderColor};
  margin: 0 0 ${getSpacing(3)};
`;

export const PayWithCashDisclaimerModal = ({ isOpen, onCancel, onConfirm }: PayWithCashDisclaimerModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      maxWidth="407px"
      isOpened={isOpen}
      onClose={onCancel}
      title={intl.formatMessage({
        id: 'box.alternativePaymentDisclaimerTitle',
        defaultMessage: 'important',
        description: 'modal title to allow payment outside of sunday and relative settings',
      })}
      cancelButtonMessage={intl.formatMessage({ id: 'menus.modal.edition.cancel' })}
    >
      <AlternativePaymentMethodDisclaimer>
        <FormattedMessage
          id="box.alternativePaymentDisclaimer"
          /* eslint-disable-next-line max-len */
          defaultMessage="allowing customers to use this payment method to send orders to the POS without requiring a digital payment. the restaurant staff is responsible for charging the client with cash or other methods in person."
          description="text displayed in modal when editing alternative payment button name"
        />
      </AlternativePaymentMethodDisclaimer>
      <Button variant="primary" size="medium" fullWidth onClick={onConfirm}>
        <FormattedMessage id="box.alternativePaymentDisclaimerConfirm" defaultMessage="confirm" />
      </Button>
    </Modal>
  );
};
