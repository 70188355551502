import { Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { isGoogle, isSunday, Review } from '../browse/domain/Review';

export const Author = (props: { review: Review }) => (
  <Typography variant="h6">
    {isSunday(props.review) && <FormattedMessage id="review.sunday_customer" defaultMessage="sunday customer" />}
    {isGoogle(props.review) && <>{props.review.author.name}</>}
  </Typography>
);
