import { Configuration } from '../configuration/Configuration';
import { StaffPerformance } from './domain/StaffPerformance';
import { StaffPerformanceRepository } from './infrastructure/StaffPerformanceRepository';

export interface StaffPerformanceEffects {
  staffPerformance: StaffPerformance,
}

export const initEffects = (configuration: Configuration): StaffPerformanceEffects => ({
  staffPerformance: new StaffPerformanceRepository(configuration.merchantAnalyticsUrl),
});
