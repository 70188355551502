import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { RepositoryHttp } from 'src/ordering/common/repository/RepositoryHttp';
import { AllergenRepository } from './AllergenRepository';
import { AllergenDtoConverter } from './AllergenDtoConverter';
import { Allergen } from '../../domain/Allergen';
import { AllergenDto, AllergenTranslationDto, BusinessAllergensDto, CreateAllergenDto } from './AllergenDto';
import { AllergenTranslation } from '../../domain/AllergenTranslations';
import { BlockDto } from '../block/BlockDto';
import { BusinessId } from 'src/business/domain/Business';

export class AllergenRepositoryHttp extends RepositoryHttp implements AllergenRepository {
  constructor(private menuBackendUrl: string) {
    super();
  }

  async findAllByBusinessId(businessId: BusinessId): Promise<Allergen[]> {
    const response = await axios.get<BusinessAllergensDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/allergens`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );

    return response.data.allergens.map((allergen) => AllergenDtoConverter.toAllergen(allergen));
  }

  async findByAllergenId(businessId: BusinessId, allergenId: string, language?: string): Promise<Allergen> {
    const queryParams = language ? `?${new URLSearchParams({ language })}` : '';
    const response = await axios.get<AllergenDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/allergens/${allergenId}${queryParams}`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );

    return AllergenDtoConverter.toAllergen(response.data);
  }

  async findTranslationsByAllergenId(businessId: BusinessId): Promise<AllergenTranslation[]> {
    const response = await axios.get<AllergenTranslationDto[]>(
      `${this.menuBackendUrl}/businesses/${businessId}/allergens/translations`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );

    return response.data.map((translation) => ({
      allergenId: translation.allergenId,
      name: translation.name,
      language: translation.language,
    }));
  }

  async createAllergen(businessId: BusinessId, createAllergenRequest: CreateAllergenDto): Promise<string> {
    const response = await axios.post<AllergenDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/allergens`,
      createAllergenRequest,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
    return response.data.id;
  }

  async updateAllergen(businessId: BusinessId, allergenId: string, name: string): Promise<void> {
    await axios.patch(
      `${this.menuBackendUrl}/businesses/${businessId}/allergens/${allergenId}`,
      { name },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async updateTranslation(businessId: BusinessId, allergenId: string, language: string, name: string): Promise<void> {
    await axios.patch<BlockDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/allergens/${allergenId}/translations/${language}`,
      {
        name,
      },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async deleteAllergen(businessId: BusinessId, allergenId: string): Promise<string> {
    const response = await axios.delete(`${this.menuBackendUrl}/businesses/${businessId}/allergens/${allergenId}`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });
    return response.data.id;
  }
}
