import {
  Stack, Table, TableCell, TableHead, TableRow, Typography,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { dimensionsPx } from '../design/dimensions';
import { palette } from '../design/palette';
import { WarningMessage } from './WarningMessage';

export const EmailCell = ({
  align = 'top',
  children,
}: {
  align?: 'baseline' | 'top' | 'middle' | 'bottom' | 'sub' | 'text-top';
  children: ReactNode;
}) => (
  <>
    <TableCell sx={{ verticalAlign: align }}>
      <Stack display="flex" flexDirection="row" gap={2}>
        {children}
      </Stack>
    </TableCell>
  </>
);

export const HeadCell = ({ title, width }: { title: string; width?: string }) => (
  <>
    <TableCell width={width}>
      <Typography color={palette.neutral.grey400}>{title}</Typography>
    </TableCell>
  </>
);

export const EmailTable = ({
  headCellTitle,
  children,
  legend,
  warningMessage,
}: {
  headCellTitle: { title: string; width?: string }[];
  legend?: { title: string; legends: { imagePath: string; title: string }[]; additional?: string[] };
  warningMessage?: { message: string; imagePath: string };
  children: ReactNode;
}) => (
  <>
    {legend && (
    <Stack mt={dimensionsPx.section_separator.s} gap={1}>
      <Typography fontSize={dimensionsPx.fonts.l}>{legend.title}</Typography>
      <WarningMessage warningMessage={warningMessage} />
      {legend.additional?.map((additional) => (
        <Typography color={palette.neutral.grey500} fontSize={dimensionsPx.fonts.s} key={additional}>
          {additional}
        </Typography>
      ))}
      <Stack display="flex" flexDirection="row" gap={2}>
        {legend.legends.map((item) => (
          <Stack display="flex" flexDirection="row" key={item.title} alignItems="center">
            <img height={dimensionsPx.line_height.s} src={item.imagePath} alt="" />
            <Typography color={palette.neutral.grey500} ml={dimensionsPx.section_separator.xxs}>
              {item.title}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
    )}
    <Table>
      <TableHead>
        <TableRow>
          {headCellTitle.map((headCell) => (
            <HeadCell title={headCell.title} width={headCell.width} key={headCell.title} />
          ))}
        </TableRow>
      </TableHead>
      {children}
    </Table>
  </>
);
