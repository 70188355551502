import React, { FC, FormEvent, useState } from 'react';
import { Box, Button, FormControl, Modal, TextField, Typography } from '@mui/material';
import { SendOutlined } from '@mui/icons-material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import CloseIcon from '@mui/icons-material/Close';
import { PaymentLinkRepository } from '../../infrastructure/PaymentLinkRepository';
import axios from 'axios';
import { PaymentLink } from '../../domain/PaymentLink';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';


type Props = {
  isOpen: boolean;
  setOpen: (value: boolean) => void
  value: string | undefined;
  onChange: (email: string) => void
};
const SendLinkToPay: FC<Props> = ({ isOpen, setOpen, value, onChange }) => {
  const close = () => setOpen(false);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    if (email && typeof email === 'string') {
      onChange(email);
      close();
    }
  };

  return <Modal open={isOpen} onClose={close}>
    <form onSubmit={onSubmit}>

      <Box sx={{
        background: 'white',
        top: '50%',
        position: 'absolute',
        left: '50%',
        minWidth: '400px',
        transform: 'translate(-50%, 0)',
      }} display="flex" flexDirection="column" p={3} borderRadius={3} gap={3}>
        <Box sx={{ cursor: 'pointer' }} onClick={close}>
          <CloseIcon />
        </Box>

        <Typography variant="h6" color={themeV5.palette.text.secondary}>
          <FormattedMessage id="payment_link.panel.cta.send_by_email" />
        </Typography>


        <FormControl size="small">
          <TextField type="email" size="small" label="Email" name="email" required defaultValue={value} />
        </FormControl>

        <Button variant="contained" type="submit" size="small">
          <SendOutlined />
        </Button>

      </Box>
    </form>
  </Modal>;
};

export const useSendLinkToPay = (paymentLink: PaymentLink | undefined, onSuccess?: () => void) => {
  const [open, setOpen] = useState(false);
  const configuration = ConfigurationLoader.load();
  const paymentLinkRepository = new PaymentLinkRepository(axios, configuration);
  const sendPaymentLink = async (email: string) => paymentLink && paymentLinkRepository.sendLinkToPayEmail(paymentLink.sessionId, email).then(() => onSuccess && onSuccess());


  return {
    SendLinkToPay: () => <SendLinkToPay isOpen={open}
                                        setOpen={setOpen}
                                        value={paymentLink?.customerEmail}
                                        onChange={sendPaymentLink} />,
    open: () => setOpen(true),
  };
};
