import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import React from 'react';
import { Business, BusinessId } from 'src/business/domain/Business';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import _ from 'lodash';
import { useFixedLocalStorage } from 'src/store/localStorage/useFixedLocalStorage';
import { LocalisationKey } from 'src/lang/en';

interface Props {
  businesses: Business[];
  setSelectedLocations: (businesses: BusinessId[]) => void;
}

const MenuProps = {
  autoFocus: false, // to prevent the scroll to end of list when all items are selected
  PaperProps: {
    style: {
      maxHeight: '25em',
    },
  },
};

export const EnterpriseSelector = ({ businesses, setSelectedLocations }: Props) => {
  const intl = useIntl();

  const [businessIds, setBusinessIds] = useFixedLocalStorage<BusinessId[]>(
    'enterprise-businesses-ids',
    businesses.map((b) => b.id),
  );
  const allBusinesses = _.chain(businesses)
    .map(({ id, name }) => ({ id, name }))
    .orderBy((b) => b.name.toLowerCase())
    .value();

  const getLabel = (id: string) => {
    const business = businesses.find((b) => b.id === id);
    return business ? business.name : '';
  };

  const onChangeValues = (e: SelectChangeEvent<string[]>) => {
    const selected = e.target.value;
    let b = (typeof selected === 'string' ? selected.split(',') : [...selected]) as BusinessId[];
    setBusinessIds(b);
    setSelectedLocations(b);
  };

  function selectAll() {
    setBusinessIds(businesses.map(({ id }) => id));
    setSelectedLocations(businesses.map(({ id }) => id));
  }

  function clearSelection() {
    setBusinessIds([]);
    setSelectedLocations([]);
  }

  return (
    <FormControl>
      <InputLabel id="select-businesses-label" variant={'outlined'} sx={{ marginRight: 3 }}>
        Locations
      </InputLabel>
      <Select
        id="select-businesses"
        labelId="select-businesses-label"
        color="secondary"
        multiple
        input={
          <OutlinedInput
            id="select-multiple-businesses"
            label={intl.formatMessage({ id: 'analytics.venue_filter.label' })}
          />
        }
        renderValue={(ids) => {
          if (ids.length === businesses.length) {
            return (
              <FormattedMessage id={'enterprise.all_locations' as LocalisationKey} defaultMessage={'All locations'} />
            );
          }
          return _.chain(ids)
            .map((id) => getLabel(id))
            .join(', ')
            .value();
        }}
        onChange={(e: SelectChangeEvent<string[]>) => onChangeValues(e)}
        value={businessIds}
        defaultValue={businessIds}
        sx={{ width: '30em' }}
        MenuProps={MenuProps}
        endAdornment={
          businessIds.length > 0 ? (
            <InputAdornment position="end" sx={{ pointerEvents: 'none', position: 'absolute', right: 20 }}>
              <Chip label={<Typography variant="body2">{businessIds.length}</Typography>} sx={{ mr: 1 }} />
            </InputAdornment>
          ) : null
        }
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'flex-start'}
          gap={1}
          marginLeft={3}
          marginTop={1}
        >
          <Button
            onClick={() => selectAll()}
            variant="contained"
            size="small"
            disabled={businessIds.length === businesses.length}
          >
            <Typography variant="body2">
              <FormattedMessage id={'analytics.venue_filter.select_all'} defaultMessage="select all" />
            </Typography>
          </Button>
          <Button onClick={() => clearSelection()} variant="contained" size="small" disabled={businessIds.length < 2}>
            <Typography variant="body2">
              <FormattedMessage id={'analytics.venue_filter.clear'} defaultMessage="clear" />
            </Typography>
          </Button>
        </Box>
        <MenuItem disabled value="">
          <em>
            <FormattedMessage id={'enterprise.all_locations' as LocalisationKey} defaultMessage={'All locations'} />
          </em>
        </MenuItem>
        {allBusinesses.map((allowed) => (
          <MenuItem key={`businesses.${allowed.id}`} value={allowed.id}>
            <Checkbox checked={businessIds.indexOf(allowed.id) > -1} />
            <ListItemText primary={`${allowed.name}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
