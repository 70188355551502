import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { useUpdateBoxDetails } from '../../../../hooks';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type ProductsAvailabilitiesSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const ProductsAvailabilitiesSetting = ({ box, setBox }: ProductsAvailabilitiesSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateAutomaticallyResetProductsAvailabilities = async (automaticallyResetProductsAvailabilities: boolean) => {
    makeUpdating(true);
    await updateBoxDetails({
      automaticallyResetProductsAvailabilities,
    });
    setBox({
      ...box,
      automaticallyResetProductsAvailabilities,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.resetProductsAvailabilities',
        defaultMessage: 'reset products availabilities during night',
        description: 'on venue settings page: field to allow customer to display the order queue for pickup',
      })}
      description={intl.formatMessage({
        id: 'venue.resetProductsAvailabilitiesInfoMessage',
        defaultMessage: 'if this is active, the products availabilities (stocks) will be set automatically at zero during night',
        description:
          "Description of the behaviour of the application when the toggle 'reset product availabilities automatically' is active.",
      })}
      toggleValue={box.automaticallyResetProductsAvailabilities}
      toggleOnChange={updateAutomaticallyResetProductsAvailabilities}
      disabled={isUpdating}
    />
  );
};
