import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { PaymentPort } from '../domain/PaymentPort';
import {
  computePaymentStatus,
  isPayAtTable,
  isTabRelated,
  Payment,
  PaymentForOrdering,
  SpecificPayment,
} from '../domain/Payment';
import { usePaymentAdapter } from './usePaymentAdapter';
import { Business } from 'src/business/domain/Business';
import { PaymentProductOrigin, paymentProductOriginFromEnrollment } from '../domain/PaymentProductOrigin';
import { DatePresets, extractTimeFrameFilterFromURLParams, Period } from '../components/TimeFrameFilter';
import {
  PaymentsListDatePreset,
  paymentsListDatePresets,
  paymentsListDatePresetsTypes,
} from '../components/PaymentsListDatePresets';
import { HttpPaymentTerminalRepository } from 'src/payment-terminal/infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export interface Filters {
  billId: string;
  tabId: string;
  tableNumber: string;
  orderIdDisplay: string;
  cardNumber: string;
  serialNumber: string;
  amount: string;
  status: string;
  waiter: string | null;
}

interface PaymentsHook {
  displayablePayments: SpecificPayment[][];
  currentPage: number;
  lastPage: number;
  previousPage: () => void;
  nextPage: () => void;
  changeFilter: (filter: Partial<Filters>) => void;
  filters: Filters;
  refreshPayments: () => Promise<void>;
  allPayments: SpecificPayment[];
  cancelPayment: (payment: Payment) => Promise<void>;
  hasTabPaymentsToDisplay: boolean;
  isLoading: boolean;
  datePresets: DatePresets<typeof paymentsListDatePresetsTypes>;
  period: Period<PaymentsListDatePreset>;
  waiters: string[];
}

type Props = {
  business: Business;
  paymentAdapter: PaymentPort;
  pageSize?: number;
};

const getBillIdFromParams = (queryParams: string): string => {
  const billIdParam = new URLSearchParams(queryParams).get('billId');
  if (billIdParam != null) return billIdParam;
  return '';
};

const getTabIdFromParams = (queryParams: string): string => {
  const tabIdParam = new URLSearchParams(queryParams).get('tabId');
  if (tabIdParam != null) return tabIdParam;
  return '';
};

export const getOrderDisplayIdFromParams = (queryParams: string): string => {
  const orderDisplayId = new URLSearchParams(queryParams).get('orderDisplayId');
  if (orderDisplayId != null) return orderDisplayId;
  return '';
};

const paymentTerminalRepository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);
export const usePaymentsHook = ({ business, paymentAdapter, pageSize = 10 }: Props): PaymentsHook => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [allPayments, setAllPayments] = useState<SpecificPayment[]>([]);
  const [displayedPaymentsList, setDisplayedPaymentsList] = useState<SpecificPayment[][]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [waiters, setWaiters] = useState<string[]>([]);

  const location = useLocation();
  const datePresets = paymentsListDatePresets();
  const period = useMemo(() => extractTimeFrameFilterFromURLParams<typeof paymentsListDatePresetsTypes>(
    location.search,
    datePresets,
    paymentsListDatePresetsTypes.TODAY,
  ), [business.timezone, location.search]);

  const [filters, setFilters] = useState<Filters>({
    billId: getBillIdFromParams(search),
    tabId: getTabIdFromParams(search),
    tableNumber: '',
    cardNumber: '',
    amount: '',
    orderIdDisplay: getOrderDisplayIdFromParams(search),
    serialNumber: '',
    status: 'all',
    waiter: null,
  });


  const groupPaymentList = (paymentList: SpecificPayment[]): SpecificPayment[][] => {
    const groupedPayment: Map<string, SpecificPayment[]> = paymentList.reduce((group, payment) => {
      // eslint-disable-next-line max-len
      const orderIdOrTabId =
        isPayAtTable(payment) && isTabRelated(payment) ? payment.payment.tabId : payment.payment.orderId;
      const groupingKey = `${orderIdOrTabId}-${payment.payment.totalPaidByCustomer.amount}-${
        payment.payment.paymentProvider
      }-${payment.payment.paymentMethodRef ?? 'none'}-${payment.payment.cardBrand ?? 'none'}`;
      if (!group.has(groupingKey)) {
        group.set(groupingKey, []);
      }
      group.get(groupingKey)?.push(payment);
      return group;
    }, new Map<string, SpecificPayment[]>());
    return Array.from(groupedPayment.values());
  };

  const setPaymentsToDisplay = (paymentsToDisplay: SpecificPayment[]) => {
    const groupedPaymentList = groupPaymentList(paymentsToDisplay);
    groupedPaymentList.forEach((paymentList) =>
      paymentList.sort((p1, p2) => p2.payment.timestamp - p1.payment.timestamp),
    );
    const splitPaymentList = groupedPaymentList.flatMap((group) => {
      const result: SpecificPayment[][] = [];
      let current: SpecificPayment[] = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const p of group) {
        if (p.payment.status === 'succeeded' && current.length > 0) {
          result.push(current);
          current = [];
        }
        current.push(p);
      }
      if (current.length > 0) {
        result.push(current);
      }
      return result;
    });
    splitPaymentList.sort((l1, l2) => l2[0].payment.timestamp - l1[0].payment.timestamp);
    setDisplayedPaymentsList(splitPaymentList);

    const lastPageAfterFilter = Math.ceil(splitPaymentList.length / pageSize);

    if (lastPageAfterFilter !== 0 && lastPageAfterFilter < currentPage) {
      setCurrentPage(lastPageAfterFilter);
    }
    setLastPage(lastPageAfterFilter);
  };

  const matchesTabOrTable = (specificPayment: SpecificPayment, tabOrTable: string) => {
    if (!tabOrTable) {
      return true;
    }

    switch (specificPayment.type) {
      case 'unknown':
        return false;
      case 'ordering':
        return false;
      case 'pay-at-table':
        return specificPayment.tableNumber?.startsWith(tabOrTable) ||
          (isTabRelated(specificPayment) &&
            (specificPayment.tabDisplayId.includes(tabOrTable) || specificPayment.userName?.includes(tabOrTable)));
      case 'pdq':
        return specificPayment.tableNumber?.startsWith(tabOrTable);
    }
  };

  const applyFilter = (paymentsToFilter: SpecificPayment[], newFilters: Partial<Filters>) => {
    const updatedFilters = { ...filters, ...newFilters };
    const { billId, tabId, tableNumber, cardNumber, amount, orderIdDisplay, serialNumber, status, waiter } = updatedFilters;
    const filteredPayments = paymentsToFilter.filter((payment) => {
      const startWithTableNumberFilter = matchesTabOrTable(payment, tableNumber);
      const startWithBillIdFilter = billId ? payment.payment.orderId?.startsWith(billId) ?? false : true;
      const startWithTabIdFilter = tabId ? payment.payment.tabId?.startsWith(tabId) ?? false : true;
      const startWithOrderFilter = (payment.type === 'pay-at-table' || !orderIdDisplay) ? true : (payment as PaymentForOrdering).orderDisplayId?.includes(orderIdDisplay) ?? false;
      const startWithCardNumberFilter = cardNumber ? payment.payment.paymentMethodRef?.startsWith(cardNumber) : true;
      const startWithAmountFilter = amount
        ? payment.payment.computeTotal()[0].amount.toString().startsWith(amount.replace(/[.,]/, ''))
        : true;
      const startWithSerialNumberFilter = serialNumber ? payment.payment.serialNumber?.startsWith(serialNumber) : true;
      const startWithStatus =
        status && status !== 'all' ? computePaymentStatus(payment.payment, status) === status : true;
      const matchWaiter = waiter ? payment.payment.waiterName === waiter : true;

      return (
        startWithCardNumberFilter &&
        startWithTableNumberFilter &&
        startWithAmountFilter &&
        startWithBillIdFilter &&
        (startWithBillIdFilter || startWithTabIdFilter) &&
        startWithOrderFilter &&
        startWithSerialNumberFilter &&
        startWithStatus &&
        matchWaiter
      );
    });

    setFilters(updatedFilters);
    setPaymentsToDisplay(filteredPayments);
  };

  const changeFilter = (newFilters: Partial<Filters>) => applyFilter(allPayments, newFilters);

  const fetchAllPaymentsAndApplyFilter = async () => {
    setLoading(true);
    const productOrigins: PaymentProductOrigin[] = business.enrollments.map(paymentProductOriginFromEnrollment);

    const payments = await paymentAdapter.getPayments(business, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate(), productOrigins);
    payments.sort((p1, p2) => p2.payment.timestamp - p1.payment.timestamp);

    setAllPayments(payments);
    applyFilter(payments, { ...filters, amount: '' });
    setLoading(false);

    const w = new Set<string>(waiters);
    payments.forEach((payment) => payment.payment.waiterName && w.add(payment.payment.waiterName));
    setWaiters([...w].sort());
  };

  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => {
    fetchAllPaymentsAndApplyFilter();
  }, [period]);

  const paginateDisplayedPaymentsList = (): SpecificPayment[][] =>
    displayedPaymentsList.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);

  const hasTabPaymentsToDisplay = (): boolean => {
    return !!allPayments.find((p) => isPayAtTable(p) && isTabRelated(p));
  };

  const cancelPayment = async (payment: Payment): Promise<void> => {
    if (business) {
      if (business.pdqEnrollment && payment.paymentProvider === 'PAYROC') {
        return paymentTerminalRepository.cancelAuthorization(business.pdqEnrollment.id, payment.id).then(() => fetchAllPaymentsAndApplyFilter());
      } else {
        return paymentAdapter.cancelPayment(payment.id).then(() => fetchAllPaymentsAndApplyFilter());
      }
    }
    return Promise.resolve();
  };

  return {
    displayablePayments: paginateDisplayedPaymentsList(),
    currentPage,
    lastPage,
    previousPage,
    nextPage,
    changeFilter,
    filters,
    cancelPayment,
    refreshPayments: fetchAllPaymentsAndApplyFilter,
    allPayments,
    hasTabPaymentsToDisplay: hasTabPaymentsToDisplay(),
    isLoading: loading,
    period,
    datePresets,
    waiters,
  };
};

export const usePayments = (business: Business) => {
  const paymentAdapter = usePaymentAdapter();

  const pageSize = 10;
  return usePaymentsHook({
    business,
    paymentAdapter,
    pageSize,
  });
};
