import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { AllergenRepositoryHttp } from '../../repositories/allergen/AllergenRepositoryHttp';
import { CreateAllergenDto } from '../../repositories/allergen/AllergenDto';
import { QUERYKEY_MENU_ALLERGENS } from '../../queries/allergen/getAllAllergensByBusinessIdQuery';
import { BusinessId } from 'src/business/domain/Business';
import { QUERYKEY_MENU_ALLERGEN } from '../../queries/allergen/getAllergenByIdQuery';

export const useCreateAllergenMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (allergen: CreateAllergenDto) => allergenRepository.createAllergen(businessId, allergen),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGENS] });
    },
  });
};

export const useUpdateAllergenMutation = (businessId: BusinessId, allergenId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (name: string) => allergenRepository.updateAllergen(businessId, allergenId, name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGENS] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGEN] });
    },
  });
};

export const useEditAllergenMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: ({ allergenId, name }: {
      allergenId: string;
      name: string
    }) => allergenRepository.updateAllergen(businessId, allergenId, name),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGENS] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGEN] });
    },
  });
};

export const useDeleteAllergenMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (allergenId: string) => allergenRepository.deleteAllergen(businessId, allergenId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGENS] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGEN] });
    },
  });
};
