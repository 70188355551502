import { Button, InputText, Modal, spaceUsages } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { venueMenuBlockPath } from 'src/app/navigation/pathHelpers';
import { BusinessId } from 'src/business/domain/Business';
import { useCreateBlockMutation } from 'src/menu/dynamic-menu/mutations/block/useBlockMutation';

type BlockCreationModalProps = {
  businessId: BusinessId;
  isOpened: boolean;
  onClose: () => void;
};

type BlockCreationModalInputs = {
  name: string;
};

export const BlockCreationModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const BlockCreationModal = ({ businessId, isOpened, onClose }: BlockCreationModalProps) => {
  const createBlock = useCreateBlockMutation(businessId);
  const navigateTo = useNavigate();
  const snackBar = useSnackbar();
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<BlockCreationModalInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  useEffect(() => {
    reset({
      name: '',
    });
  }, [reset, isSubmitSuccessful]);

  const onClickCreateBlock = async (title: string) => {
    try {
      const blockRequest = {
        title,
      };
      const productCreatedId = await createBlock.mutateAsync(blockRequest);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.blocks.creation.success' }),
      });
      return productCreatedId;
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'menus.blocks.creation.error' }),
      });
    }
  };

  const onSubmit: SubmitHandler<BlockCreationModalInputs> = async ({ name }) => {
    const productCreatedId = await onClickCreateBlock(name);
    onClose();
    navigateTo(venueMenuBlockPath(businessId, productCreatedId!));
  };

  const onCloseThenResetName = async () => {
    onClose();
    reset({
      name: '',
    });
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'menus.blocks.creation.modal.name' })}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onCloseThenResetName}
    >
      <BlockCreationModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={intl.formatMessage({ id: 'menus.blocks.creation.form.title' })}
          placeholder={intl.formatMessage({ id: 'menus.blocks.creation.form.placeholder' })}
          id="menuName"
          {...register('name', {
            required: intl.formatMessage({ id: 'menus.blocks.creation.form.required' }),
            pattern: { value: /.*\S.*/, message: intl.formatMessage({ id: 'menus.blocks.creation.form.required' }) },
          })}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          disabled={!isValid || isSubmitting}
          marginTop={spaceUsages.large}
        >
          <FormattedMessage id="menus.blocks.creation.form.submit" />
        </Button>
      </BlockCreationModalForm>
    </Modal>
  );
};

export default BlockCreationModal;
