/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const RefreshIcon: React.FC<IconProps> = ({ size = 12, color = 'black', ...props }) => (
  <svg
    version="1.1"
    width={size}
    height={size}
    viewBox="0 0 547.842 547.842"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        d="m 494.367,293.097 h -19.836 c -11.092,0 -20.043,8.874 -20.426,19.813 -9.104,84.609 -94.707,162.476 -181.84,162.476 -93.253,0 -178.759,-85.429 -178.759,-178.529 0,-92.258 88.041,-185.229 180.071,-186.529 v 43.387 c 0,12.47 8.874,18.054 19.737,12.47 L 433.998,94.199 c 10.863,-5.585 10.863,-14.612 0,-20.196 L 293.314,2.168 c -10.863,-5.584 -19.737,0 -19.737,12.393 v 43.616 c -138.695,1.301 -240.45,98.062 -240.45,236.833 0,139.612 101.296,240.832 241.139,240.832 133.723,0 231.27,-91.504 240.449,-222.778 0.076,-11.016 -9.18,-19.967 -20.348,-19.967 z"
        fill={color}
      />
    </g>
  </svg>
);
