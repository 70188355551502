import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {
  Box,
  Button,
  IconButton,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { GroupAddOutlined } from '@mui/icons-material';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { GrantRoleModal } from './modals/GrantRoleModal';
import { RemoveUserRoleModal } from './modals/RemoveUserRoleModal';
import { UpdateUserRoleModal } from './modals/UpdateUserRoleModal';
import { ActionContainer, LeftContainer, TitleActionsContainer } from '../UserPermissions.styles';
import { userRoles } from 'src/domain/user/UserRole';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useGrantUserRole } from '../mutation/useGrantUserRole';
import { useRemoveUserRole } from '../mutation/useRemoveUserRole';
import { useUpdateUserRole } from '../mutation/useUpdateUserRole';
import { useListBusinessUsersQuery } from '../query/useListBusinessUsersQuery';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import {
  useInviteUserToDashboardAndLinkAuth0Accounts,
  useReSendSignupLinkToUser,
} from '../mutation/useSendSignupLinkToUser';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { SettingsPageContainer } from '../../components/SettingsPageContainer';
import { userRoleLocalisations } from 'src/pages/Settings/UserPermissions/i18n/UserRoleLocalisation';
import { AuthenticatedUser } from 'src/auth/domain/user';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

const ResendEmailOrInviteUser = ({
  currentUser,
  resendEmail,
  inviteUserToDashboard,
  user,
}: {
  resendEmail: () => Promise<void>;
  inviteUserToDashboard: () => Promise<void>;
  currentUser: AuthenticatedUser;
  user: BusinessUser;
}) => {
  const intl = useIntl();
  const userIsLinkable =
    user.authenticationProviderIds.length === 1 &&
    user.email.trim().length > 0 &&
    user.phoneNumber !== undefined &&
    user.authenticationProviderIds[0].startsWith('sms|');

  return (
    <Tooltip title={intl.formatMessage({ id: 'settings.user_permissions.send_signup_link.tooltip' })} placement="top">
      <IconButton
        onClick={userIsLinkable ? inviteUserToDashboard : resendEmail}
        disabled={currentUser.userId === user.userId}
      >
        {userIsLinkable ? (
          <SpaceDashboardIcon
            sx={{
              maxHeight: '16px',
              maxWidth: '16px',
            }}
          />
        ) : (
          <ForwardToInboxIcon
            sx={{
              maxHeight: '16px',
              maxWidth: '16px',
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
};

export const FaqLink = () => {
  const theme = useTheme();
  return (
    <Link href="/settings/users/faq" target="_blank">
      <Stack direction={'row'} alignItems={'center'} spacing={1}>
        <InfoIcon color={'action'} />
        <Typography fontSize={'x-small'} color={theme.palette.text.secondary}>
          <FormattedMessage id="settings.user_permissions.hint" />
        </Typography>
      </Stack>
    </Link>
  );
};

const UserPermissionsComponent = () => {
  const intl = useIntl();
  const businessId = useBusinessIdOrThrow();
  const currentUser = useAuthenticatedUserOrThrow();
  const useSnackBar = useSnackbar();

  const { data: users, isLoading } = useListBusinessUsersQuery(businessId);
  const { grantUserRole } = useGrantUserRole(businessId);
  const { removeUserRole } = useRemoveUserRole(businessId);
  const { updateUserRole } = useUpdateUserRole(businessId);
  const { reSendSignupLinkToUser } = useReSendSignupLinkToUser(businessId);
  const { mutateAsync: invite } = useInviteUserToDashboardAndLinkAuth0Accounts();
  const [removeRoleModalIsOpen, setRemoveRoleModalIsOpen] = useState<boolean>(false);
  const [updateRoleModalIsOpen, setUpdateRoleModalIsOpen] = useState<boolean>(false);
  const [grantRoleModalIsOpen, setGrantRoleModalIsOpen] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<BusinessUser | undefined>();

  const onDelete = (user: BusinessUser) => {
    setSelectedUser(user);
    setRemoveRoleModalIsOpen(true);
  };

  const onUpdateRole = (user: BusinessUser) => {
    setSelectedUser(user);
    setUpdateRoleModalIsOpen(true);
  };

  const sendSignupEmail = async (email: string) => {
    reSendSignupLinkToUser({ email })
      .then(() =>
        useSnackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.user_permissions.send_signup_link.success' }),
        }),
      )
      .catch(() =>
        useSnackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.user_permissions.send_signup_link.error' }),
        }),
      );
  };

  const inviteUserToDashboard = (user: BusinessUser) =>
    invite({
      userId: user.userId,
      auth0SmsId: user.authenticationProviderIds[0],
      businessId: businessId,
    })
      .then(() =>
        useSnackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.user_permissions.send_signup_link.success' }),
        }),
      )
      .catch(() =>
        useSnackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.user_permissions.send_signup_link.error' }),
        }),
      );

  if (isLoading || !users) {
    return;
  }

  return (
    <SettingsPageContainer minWidth={'1000px'}>
      <Box>
        <TitleActionsContainer>
          <LeftContainer>
            <FaqLink />
          </LeftContainer>

          <Tooltip placement="left" title={intl.formatMessage({ id: 'settings.user_permissions.add_modal.title' })}>
            <Button
              style={{
                borderRadius: '48px',
                backgroundColor: 'black',
                color: 'white',
              }}
              variant="outlined"
              color="primary"
              onClick={() => setGrantRoleModalIsOpen(true)}
            >
              <GroupAddOutlined
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
            </Button>
          </Tooltip>
        </TitleActionsContainer>
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              <TableCell>
                {intl.formatMessage({
                  id: 'settings.user_permissions.email',
                  defaultMessage: 'Email',
                })}
              </TableCell>
              <TableCell>{intl.formatMessage({ id: 'settings.user_permissions.name' })}</TableCell>
              <TableCell>{intl.formatMessage({ id: 'settings.user_permissions.phone_number' })}</TableCell>
              <TableCell>
                {intl.formatMessage({
                  id: 'settings.user_permissions.role',
                  defaultMessage: 'Role',
                })}
              </TableCell>
              <TableCell>
                {intl.formatMessage({
                  id: 'common.actions',
                  defaultMessage: 'Actions',
                })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.userId}>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {user.lastName} {user.firstName}
                </TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{intl.formatMessage({ id: userRoleLocalisations[user.role] })}</TableCell>
                <TableCell>
                  <ActionContainer>
                    <Tooltip
                      title={intl.formatMessage({ id: 'settings.user_permissions.roles.tooltip' })}
                      placement="top"
                    >
                      <IconButton disabled={currentUser.userId === user.userId} onClick={() => onUpdateRole(user)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={intl.formatMessage({ id: 'settings.user_permissions.remove.tooltip' })}
                      placement="top"
                    >
                      <IconButton
                        disabled={currentUser.userId === user.userId}
                        color="error"
                        onClick={() => onDelete(user)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <ResendEmailOrInviteUser
                      resendEmail={() => sendSignupEmail(user.email)}
                      inviteUserToDashboard={() => inviteUserToDashboard(user)}
                      currentUser={currentUser}
                      user={user}
                    />
                  </ActionContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      {grantRoleModalIsOpen && (
        <GrantRoleModal
          onGrantUserRole={(email, role) =>
            grantUserRole({
              email,
              role,
            })
          }
          isOpen={grantRoleModalIsOpen}
          handleClose={() => setGrantRoleModalIsOpen(false)}
          handleConfirm={() => setGrantRoleModalIsOpen(false)}
        />
      )}
      {removeRoleModalIsOpen && (
        <RemoveUserRoleModal
          onRemoveUserRole={(userId) => removeUserRole({ userId })}
          userToRemove={selectedUser!}
          isOpen={removeRoleModalIsOpen}
          handleClose={() => setRemoveRoleModalIsOpen(false)}
          handleConfirm={() => setRemoveRoleModalIsOpen(false)}
        />
      )}
      {updateRoleModalIsOpen && (
        <UpdateUserRoleModal
          onUpdateUserRole={(userId, role) =>
            updateUserRole({
              userId,
              role,
            })
          }
          userToUpdate={selectedUser!}
          isOpen={updateRoleModalIsOpen}
          handleClose={() => setUpdateRoleModalIsOpen(false)}
          handleConfirm={() => setUpdateRoleModalIsOpen(false)}
        />
      )}
    </SettingsPageContainer>
  );
};
export default withRestrictedAccess([userRoles.ADMIN], UserPermissionsComponent);
