import { Time } from '../../domain/Time';

const SUGGESTED_TIMES: Time[] = [
  {
    hours: 0,
    minutes: 0,
  },
  {
    hours: 1,
    minutes: 0,
  },
  {
    hours: 2,
    minutes: 0,
  },
  {
    hours: 3,
    minutes: 0,
  },
  {
    hours: 4,
    minutes: 0,
  },
  {
    hours: 5,
    minutes: 0,
  },
  {
    hours: 6,
    minutes: 0,
  },
  {
    hours: 7,
    minutes: 0,
  },
  {
    hours: 8,
    minutes: 0,
  },
  {
    hours: 9,
    minutes: 0,
  },
  {
    hours: 10,
    minutes: 0,
  },
  {
    hours: 11,
    minutes: 0,
  },
  {
    hours: 12,
    minutes: 0,
  },
  {
    hours: 13,
    minutes: 0,
  },
  {
    hours: 14,
    minutes: 0,
  },
  {
    hours: 15,
    minutes: 0,
  },
  {
    hours: 16,
    minutes: 0,
  },
  {
    hours: 17,
    minutes: 0,
  },
  {
    hours: 18,
    minutes: 0,
  },
  {
    hours: 19,
    minutes: 0,
  },
  {
    hours: 20,
    minutes: 0,
  },
  {
    hours: 21,
    minutes: 0,
  },
  {
    hours: 22,
    minutes: 0,
  },
  {
    hours: 23,
    minutes: 0,
  },
];

export const suggestedTimesAfterThresold = (thresold: Time | undefined): Time[] => {
  if (!thresold) return SUGGESTED_TIMES;
  return SUGGESTED_TIMES.filter((suggestedTime) => (
    suggestedTime.hours > thresold.hours ||
    (suggestedTime.hours === thresold.hours && suggestedTime.minutes > thresold.minutes)
  ));
};
