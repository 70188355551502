import { z } from 'zod';

export const PosConnectionIdSchema = z.string().brand<'PosConnectionId'>();
export type PosConnectionId = z.infer<typeof PosConnectionIdSchema>;

export function invalidPosConnectionIdToRefactor(id: string) {
  return id as PosConnectionId;
}

export type PosConnection = {
  id: PosConnectionId;
  name: string;
  status: PosOnboardingStatus;
  posReference: string;
};

export enum PosOnboardingStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED',
  NOT_ONBOARDED = 'NOT_ONBOARDED',
}

export type RevenueCenter = {
  externalId: string;
  name: string;
  supported: boolean;
};
