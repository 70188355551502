import { Box, Skeleton } from '@mui/material';
import React from 'react';

export const RevenueTableDataLoading = () => {
  const fakeFirstBlock = [1, 2, 3];
  const fakeSecondBlock = [4, 5];
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '200px',
    }}
    >
      {fakeFirstBlock.map((i) => (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="74px"
          key={i}
          sx={{ borderRadius: i === 3 ? '0 0 16px 16px' : 'unset' }}
        />
      ))}
      <div style={{ margin: '8px' }} />
      {fakeSecondBlock.map((i) => (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="74px"
          key={i}
          sx={{ borderRadius: i === 5 ? '0 0 16px 16px' : 'unset' }}
        />
      ))}
    </Box>
  );
};
