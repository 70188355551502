import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Modal, Notification as SundayNotification, spaceUsages } from '@sundayapp/b2b-react-component-library';
import { useForm } from 'react-hook-form';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import {
  UnlinkPosUsernameForm,
  UserNameColumn,
  UserNamesContainer,
  UserNameTitle,
} from './UnlinkPosUsernameModal.styles';

type Props = {
  onUnlinkClicked: (userId: string) => Promise<void>;
  user: BusinessUser;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const UnlinkPosUsernameModal: React.FC<Props> = ({
  onUnlinkClicked,
  handleClose,
  handleConfirm,
  isOpen,
  user,
}: Props) => {
  const intl = useIntl();
  const [hasSubmitError, setHasSubmitError] = useState(false);

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit = async () => {
    setHasSubmitError(false);
    try {
      await onUnlinkClicked(user.userId);
      handleConfirm();
    } catch (e) {
      setHasSubmitError(true);
    }
  };

  return (
    <Modal
      maxWidth={'488px'}
      title={intl.formatMessage(
        { id: 'settings.user_permissions.unlink_pos_username.modal.title' },
        { name: user.firstName },
      )}
      isOpened={isOpen}
      onClose={handleClose}
      cancelButtonMessage={intl.formatMessage({ id: 'settings.user_permissions.unlink_pos_username.modal.cancel' })}
    >
      <UnlinkPosUsernameForm onSubmit={handleSubmit(onSubmit)}>
        <img style={{ maxWidth: '100%' }} src="/unlink-pos-username.png" alt={'unlink-cover'}/>
        <UserNamesContainer>
          <UserNameColumn>
            <UserNameTitle>
              <FormattedMessage id="settings.user_permissions.unlink_pos_username.modal.name_on_sunday" />
            </UserNameTitle>
            {user.lastName} {user.firstName}
          </UserNameColumn>
          <UserNameColumn>
            <UserNameTitle>
              <FormattedMessage id="settings.user_permissions.unlink_pos_username.modal.name_on_pos" />
            </UserNameTitle>
            {user.posUserName}
          </UserNameColumn>
        </UserNamesContainer>

        {hasSubmitError && (
          <SundayNotification variant="error" marginTop={spaceUsages.mediumXLarge}>
            <FormattedMessage id="error.generic_error" />
          </SundayNotification>
        )}

        <Button
          type="submit"
          variant="primary"
          size="medium"
          marginTop={spaceUsages.mediumXLarge}
          disabled={!isValid || isSubmitting}
          fullWidth
        >
          <FormattedMessage id="settings.user_permissions.unlink_pos_username.modal.confirm" />
        </Button>
      </UnlinkPosUsernameForm>
    </Modal>
  );
};
