import styled from 'styled-components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { Typography, useTheme } from '@mui/material';
import { CopyPaste } from 'src/utils/CopyPaste';

const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  text-decoration: underline;
  flex-wrap: nowrap;
  margin-right: 16px;
  font-size: 16px;
  justify-content: center;
`;

const LinkIcon = styled(ContentCopyIcon)`
  margin-right: 5px;
`;

export const CopyLink = ({ shortLink }: { shortLink: string }) => {
  const theme = useTheme();
  return (<CopyPaste value={shortLink} content={
    <LinkButton>
      <LinkIcon />
      <Typography variant="subtitle1" color={theme.palette.text.primary}>
        <FormattedMessage id="payment_link.table.copy_link" />
      </Typography>
    </LinkButton>
  } />);
};
