import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import TodayIcon from '@mui/icons-material/Today';
import { Stack, Typography, useTheme } from '@mui/material';
import { formatCreationDate } from '../browse/allReviews/components/ReviewDates';
import { DateRange } from '../domain/DateRange';

export const InsightPeriod = ({ dateRange }: { dateRange: DateRange }) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <TodayIcon style={{ color: theme.palette.text.secondary }} />
      <Typography color={theme.palette.text.secondary}>
        {intl.formatMessage({ id: 'review.stat.google.title.period' }, {
          startDate: formatCreationDate(dateRange.startDate()
            .getTime(), intl.locale),
          endDate: formatCreationDate(dateRange.endDate()
            .getTime(), intl.locale),
        })}
      </Typography>
    </Stack>
  );
};
