import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';
import { EndOfServiceClient } from '../infrastructure/EndOfServiceClient';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import * as FileSaver from 'file-saver';


export const reconcilePOSOperationsWithSundayPayments = async (businessId: BusinessId, startDate: Date, endDate: Date ) => {
  const repository = new EndOfServiceClient(axios);
  const result = await repository.reconcilePOSOperationsWithSundayPayments(businessId, startDate, endDate);
  if (result) {
    const blob = new Blob([`${result}`], {
      type: 'text/csv;charset=utf-8',
    });
    FileSaver.saveAs(blob, 'reconciliation.csv');
  } else {
    throw new Error('Reconciliation not available');
  }
};
