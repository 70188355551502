import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import { Stack, Typography, useTheme } from '@mui/material';
import { DimensionIcon } from '../components/DimensionIcon';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { formatNumber } from '../../../components/formatNumbers';
import React from 'react';

export const DimensionTrend = ({ dimension }: { dimension: DimensionAnalysis }) => {
  const theme = useTheme();
  return (
    <Stack direction="column" justifyContent="space-between">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" marginBottom="1rem" alignItems="center" gap={1}>
          <DimensionIcon dimension={dimension.name} />
          <Typography variant="subtitle1" color={theme.palette.text.secondary}>
            <FormattedMessage id={`review.dimensions.${dimension.name}`} />
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="column" gap="0.5rem">
        <Typography variant="h5">
          {formatNumber(dimension.averageRating ?? 0, 2)}
          /5
        </Typography>
        <Typography variant="body2" color={theme.palette.text.secondary}>
          {dimension.numberOfReviews} {<FormattedMessage id={'review.dimensions.avis'} />}
        </Typography>
      </Stack>
    </Stack>
  );
};
