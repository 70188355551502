import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { userRoles } from 'src/domain/user/UserRole';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Consent } from 'src/payments/components/Checkout/Consent';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { getCheckoutOnboardingInformation } from 'src/payments/infrastructure/getCheckoutOnboardingInformation';
import { BankAccount } from 'src/payments/components/Checkout/CheckoutOnboarding/BankAccount/BankAccount';
import {
  BusinessPhoneNumber,
  prefillPhone,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessPhoneNumber';
import { BusinessEmail } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessEmail';
import { Header } from 'src/payments/components/Checkout/Header';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useSubmitCheckoutOnboarding } from 'src/payments/mutations/useSubmitCheckoutOnboarding';

const FinalizeCheckoutOnboarding = () => {

  const business = useCurrentBusinessOrThrow();
  const user = useAuthenticatedUserOrThrow();
  const intl = useIntl();

  const form = useForm<CheckoutOnboardingInformationDraft>({
    mode: 'all', criteriaMode: 'all', defaultValues: async () => {
      const checkoutOnboardingInformation = await getCheckoutOnboardingInformation(intl)(business.id);
      return {
        ...checkoutOnboardingInformation,
        contact: {
          email: !!checkoutOnboardingInformation?.contact?.email ? checkoutOnboardingInformation?.contact?.email : user.email ?? '',
          phone: prefillPhone({
            phoneFromCheckoutForm: checkoutOnboardingInformation?.contact?.phone,
            phoneFromMerchantInfo: user.phoneNumber,
          }),
        },
        bankAccount: {
          bankStatement: checkoutOnboardingInformation?.bankAccount?.bankStatement,
          bic: checkoutOnboardingInformation?.bankAccount?.bic,
          iban: checkoutOnboardingInformation?.bankAccount?.iban,
        },
      };
    },
  });

  const snackbar = useSnackbar();
  const businessCountryCode = business.address.countryCode;
  const {
    mutateAsync: submitCheckoutOnboarding,
    isPending,
  } = useSubmitCheckoutOnboarding({ businessId: business.id });

  const onSubmit = async (data: CheckoutOnboardingInformationDraft) => {
    try {
      await submitCheckoutOnboarding({ checkoutOnboardingInfo: data, isDraft: true });
      snackbar.addNotification({
        text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.finalize.submit.success' }),
        variant: 'success',
      });
    } catch (e: string | unknown) {
      snackbar.addNotification({
        text: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.finalize.submit.error' }),
        variant: 'error',
      });
    }
  };


  return (
    <Stack height={'100%'} style={{
      background: 'white',
      height: '100%',
      overflow: 'auto',
    }}>
      <FormProvider {...form}>
        <Header businessId={business.id} businessName={business.name} title={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.finalize.title' })} />
        {
          ['FR', 'GB'].includes(businessCountryCode) ? <Stack
              spacing={3}
              padding={'24px 24px 24px 24px'}
              bgcolor={'white'}
              direction={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              noValidate
              component={'form'}

              onSubmit={form.handleSubmit(onSubmit)}>
              <Stack style={{ width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }} spacing={3}>
                <Stack direction="column" spacing={3}>
                  <Typography variant={'h5'}><FormattedMessage
                    id="settings.payments.checkout.onboarding.finalize.information"
                    defaultMessage={'Contact information'} /></Typography>
                  <BusinessPhoneNumber businessCountryCode={businessCountryCode} />
                  <BusinessEmail />
                </Stack>
                <BankAccount countryCode={businessCountryCode} />
                <Consent />
                <Button disabled={isPending} variant={'contained'} type={'submit'}><FormattedMessage
                  id="settings.payments.checkout.onboarding.finalize.submit"
                  defaultMessage={'Submit'} /></Button>
              </Stack>
            </Stack>
            :
            <Box height={'100%'}>
              <Typography variant={'h5'}><FormattedMessage
                id="settings.payments.checkout.onboarding.finalize.country_not_available"
                defaultMessage={'This operation is not available in your country'} /></Typography>
            </Box>
        }
      </FormProvider>
    </Stack>
  )
  ;
};

export default withRestrictedAccess([userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER], FinalizeCheckoutOnboarding);
