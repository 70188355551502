import axios from 'axios';

export class CommunicationPreferencesRepositoryHttp {
  constructor(private url: string) {
  }

  async updateReportPreference(userId: string, active: boolean, target: string): Promise<void> {
    await axios.post(`${this.url}/v2/users/${userId}/communication-preferences`, {
      communication_preferences: [{
        target,
        active,
      }],
    });
  }

  async getReportPreference(userId: string, target: string): Promise<boolean> {
    const promise = await axios.get<{ communication_preferences: [{
      target: string,
      active: boolean
    }] }>(`${this.url}/v2/users/${userId}/communication-preferences`);
    const find = promise.data.communication_preferences.find((p) => p.target === target);
    if (!find) return Promise.reject();
    return find.active;
  }
}
