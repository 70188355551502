import { useMutation } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ProductRepositoryHttp } from '../../repositories/ProductRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

export const useRefreshProductsMutation = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource) => {
  const configuration = ConfigurationLoader.load();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => productRepositoryHttp.refreshProducts(businessId, menuAssetsSource),
  });
};
