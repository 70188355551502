// eslint-disable-next-line @typescript-eslint/no-redeclare
import { IconWrapper, PlusSvg, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import MenuTimeRange from './MenuTimeRange';
import { TimeRange } from '../../domain/TimeRange';

const Container = styled.div`
  margin-top: ${spaceUsages.largeMedium};
  padding-left: ${spaceUsages.mediumSmall};
  padding-right: ${spaceUsages.mediumSmall};
  width: 100%;
`;

const Header = styled.div`
  margin-bottom: ${spaceUsages.largeSmall};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type MenuTimeRangesProps = {
  times: TimeRange[];
  setTimes: (times: TimeRange[]) => void;
};

const MenuTimeRanges = ({ times, setTimes }: MenuTimeRangesProps) => {
  const MAX_TIME_RANGE = 5;

  const onNewTimeRange = () => {
    setTimes(times.concat({ startTime: '00:00', endTime: '24:00' }));
  };

  const removeTimeRange = (index: number) => {
    setTimes(times.filter((item, idx) => index !== idx));
  };

  const onTimeRangeUpdated = (index: number, updatedTime: TimeRange) => {
    const updatedTimes = times.map((time, idx) => {
      if (index === idx) {
        return updatedTime;
      }
      return time;
    });

    setTimes(updatedTimes);
  };

  return (
    <Container>
      <Header>
        <Text size="large">
          <FormattedMessage id="menus.schedule_selector.modal.time_range.title" />
        </Text>
        {times.length < MAX_TIME_RANGE && (
          <IconWrapper size="small" cursor="pointer" onClick={onNewTimeRange}>
            <PlusSvg />
          </IconWrapper>
        )}
      </Header>
      {times.map((time, index) => (
        <MenuTimeRange
          // eslint-disable-next-line react/no-array-index-key
          key={String(index)}
          startTime={time.startTime}
          endTime={time.endTime}
          onUpdate={(timeParam) => onTimeRangeUpdated(index, timeParam)}
          onDelete={() => removeTimeRange(index)}
        />
      ))}
    </Container>
  );
};

export default MenuTimeRanges;
