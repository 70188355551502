import { Panel } from '@sundayapp/b2b-react-component-library';
import * as React from 'react';
import styled from 'styled-components';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useListReconciliationReportsByStatus } from '../queries/listReports';
import { CashupStatus } from '../domain/ReconciliationReport';
import { useReportSettings } from '../queries/ReportSettings';
import ReconciliationCreation from '../component/ReconciliationCreation';
import ReconciliationTable from '../component/ReconciliationTable';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const OpenReportsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const MainPanel = styled(Panel)`
  padding: 0;
`;

const OpenReportsTab = () => {
  const business = useCurrentBusinessOrThrow();
  const { data: reports, isLoading } = useListReconciliationReportsByStatus(business.id, CashupStatus.Open);
  const { data: reportSettings } = useReportSettings();

  if (isLoading || !reports || !reportSettings) {
    return <CircularProgress />;
  }

  return (
    <OpenReportsBox>
      <ReconciliationCreation />

      <MainPanel>
        {reports.length > 0 && (
          <ReconciliationTable reports={reports} reportSettings={reportSettings} />
        )}
        {reports.length === 0 && (
          <Typography variant="body1">
            <FormattedMessage id="reconciliation.report.open.empty" />
          </Typography>
        )}
      </MainPanel>
    </OpenReportsBox>
  );
};

export default OpenReportsTab;
