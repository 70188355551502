import axios from 'axios';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { ReportingDigest } from '../domain/ReportingDigest';
import { ReportingDigestUseCase } from '../usecase/ReportingDigestUseCase';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { defaultRange, GetAccountingShiftUseCase } from '../usecase/GetAccountingShiftUseCase';
import { SaveAccountingShiftUseCase } from '../usecase/SaveAccountingShiftUseCase';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { BusinessId } from 'src/business/domain/Business';

export const useReporting = () => {
  const business = useCurrentBusinessOrThrow();
  const [reportingDateRange, setReportingDateRange] = useState(defaultRange.today);

  const accountingRepository = new AccountingRepository(axios);

  const getAccountingShiftUseCase = new GetAccountingShiftUseCase(accountingRepository);
  const saveAccountingShiftUseCase = new SaveAccountingShiftUseCase(accountingRepository);

  const [reportingDigest, setReportingDigest] = useState<ReportingDigest | undefined>(undefined);
  const [selectedRange, setSelectedRange] = useState<'today' | 'lunch' | 'dinner' | 'yesterday' | 'custom_range'>(
    'today',
  );
  const [accountingRanges, setAccountingRanges] = useState(defaultRange);
  const [hasLoadedAccountingShift, setHasLoadingAccountingShifts] = useState(false);
  const [hasLoadedReportingDigest, setHasLoadedReportingDigest] = useState(false);

  let reportingUseCase = new ReportingDigestUseCase(business.currency, accountingRepository);

  const fetchReportingDigest = async () => {
    setHasLoadedReportingDigest(false);
    const fetchedReportingDigest: ReportingDigest = await reportingUseCase.handle(
      business.id,
      reportingDateRange.startDate,
      reportingDateRange.endDate,
    );
    setReportingDigest(fetchedReportingDigest);
    setHasLoadedReportingDigest(true);
  };

  const fetchAccountingShift = async (businessId: BusinessId | undefined) => {
    if (businessId) {
      setHasLoadingAccountingShifts(false);
      const rangesFromShift = await getAccountingShiftUseCase.getAccountingDateRange(businessId);
      setAccountingRanges(rangesFromShift);
      setHasLoadingAccountingShifts(true);
    }
  };

  const saveAccountingShifts = async (lunchStart: string, lunchEnd: string, dinnerStart: string, dinnerEnd: string) => {
    await saveAccountingShiftUseCase.saveAccountingDateRange(business.id, lunchStart, lunchEnd, dinnerStart, dinnerEnd);
    await fetchAccountingShift(business.id);
  };

  useEffect(() => {
    fetchReportingDigest();
  }, [reportingDateRange]);

  useEffect(() => {
    const dateNow = new Date(Date.now());

    if (selectedRange === 'today') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.today.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.today);
      }
    }

    if (selectedRange === 'lunch') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.lunch.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.lunch);
      }
    }

    if (selectedRange === 'dinner') {
      if (moment(dateNow).isAfter(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.dinner.tomorrow());
      }
      if (moment(dateNow).isBefore(accountingRanges.dinner.endDate)) {
        setReportingDateRange(accountingRanges.dinner);
      }
    }

    if (selectedRange === 'yesterday') {
      setReportingDateRange(accountingRanges.today.yesterday());
    }
  }, [selectedRange, accountingRanges]);

  useEffect(() => {
    fetchAccountingShift(business.id);
  }, [business.id]);

  return {
    reportingDigest,
    reportingDateRange,
    setSelectedRange,
    accountingRanges,
    setReportingDateRange,
    saveAccountingShifts,
    hasLoadedAccountingShift,
    hasLoadedReportingDigest,
  };
};
