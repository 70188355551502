/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const DoubleChevronSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.41074 2.74408C9.73618 2.41864 10.2638 2.41864 10.5893 2.74408L14.7559 6.91074C15.0814 7.23618 15.0814 7.76382 14.7559 8.08926C14.4305 8.41469 13.9028 8.41469 13.5774 8.08926L10 4.51184L6.42259 8.08926C6.09715 8.41469 5.56951 8.41469 5.24408 8.08926C4.91864 7.76382 4.91864 7.23618 5.24408 6.91074L9.41074 2.74408ZM5.24408 11.9107C5.56951 11.5853 6.09715 11.5853 6.42259 11.9107L10 15.4882L13.5774 11.9107C13.9028 11.5853 14.4305 11.5853 14.7559 11.9107C15.0814 12.2362 15.0814 12.7638 14.7559 13.0893L10.5893 17.2559C10.2638 17.5814 9.73618 17.5814 9.41074 17.2559L5.24408 13.0893C4.91864 12.7638 4.91864 12.2362 5.24408 11.9107Z" fill={color} />
  </svg>

);

export default DoubleChevronSvg;
