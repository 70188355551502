import styled from 'styled-components';
import { BoxOrderStat } from '../../../orders/domain/BoxOrderStat';

type BoxOrderStatComponentProps = {
  boxOrderStat: BoxOrderStat;
};

type ContainerInfoProps = {
  bgColor: string;
};

const Container = styled.div<ContainerInfoProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => props.bgColor};
`;

const Item = styled.div`
  display: flex;
  width: 100%;
`;

const OrderItem = styled(Item)`
`;

const PlacedItem = styled(Item)`
  justify-content: center;
`;

const DurationItem = styled(Item)`
  justify-content: right;
`;

export const BoxOrderStatComponent = ({ boxOrderStat }: BoxOrderStatComponentProps) => {
  const since = new Date().getTime() - boxOrderStat.placedAt.getTime();
  const sinceInMin = Math.floor(since / 60000);

  const computeColor = (min: number): string => {
    if (min > 30) {
      return '#ff4040';
    }
    if (min > 20) {
      return '#ff9318';
    }
    if (min > 15) {
      return '#ffe124';
    }

    return '';
  };

  const color = computeColor(sinceInMin);

  return (
    <Container bgColor={color}>
      <OrderItem>{boxOrderStat.displayId}</OrderItem>
      <PlacedItem>{boxOrderStat.placedAt.toLocaleTimeString()}</PlacedItem>
      <DurationItem>
        {sinceInMin}
        {' '}
        min
      </DurationItem>
    </Container>
  );
};
