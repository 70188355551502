import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { BoxOrderStat } from '../../../orders/domain/BoxOrderStat';
import { BoxOrderStatComponent } from './BoxOrderStatComponent';

type BoxOrderInPreparationsProps = {
  boxOrderStats: BoxOrderStat[];
};

const Container = styled.div`
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoxOrderInPreparations = ({ boxOrderStats }: BoxOrderInPreparationsProps) => {
  if (!boxOrderStats) {
    return <></>;
  }

  const orderedBoxOrderStats = boxOrderStats
    .filter((stats) => {
      const now = new Date().getTime();
      const placedAt = stats.placedAt.getTime();
      return now - placedAt < 14400000;
    })
    .sort((a, b) => {
      const ta = a.placedAt.getTime();
      const tb = b.placedAt.getTime();

      return ta - tb;
    });

  return (
    <Container>
      <Header>
        <div>
          <FormattedMessage id="boxesStats.order" defaultMessage="Order" />
        </div>
        <div>
          {' '}
          <FormattedMessage id="boxesStats.placed_at" defaultMessage="Placed at" />
        </div>
        <div>
          <FormattedMessage id="boxesStats.duration" defaultMessage="Duration" />
        </div>
      </Header>
      {orderedBoxOrderStats.map((boxOrderStat) => (
        <BoxOrderStatComponent boxOrderStat={boxOrderStat} key={boxOrderStat.boxOrderId} />
      ))}
    </Container>
  );
};
