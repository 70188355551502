import styled from 'styled-components';
import React, { ReactNode } from 'react';
import { colorPalette, getSpacing, zIndex } from '../../stylesheet';

type FixedPanelProps = {
  isOpened: boolean;
  onClosed: () => void;
  children: ReactNode;
};

export const FIXED_PANEL = 420;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${colorPalette.overlayShadow};
  z-index: ${zIndex.SIDE_PANEL};
`;

const LeftPart = styled.div`
  width: 100%;
  height: 100%;
`;

const Panel = styled.div`
  min-width: ${FIXED_PANEL}px;
  width: ${FIXED_PANEL}px;
  max-width: ${FIXED_PANEL}px;
  height: 100vh;
  position: fixed;
  background-color: ${colorPalette.defaultBackgroundColor};
  top: 0;
  right: 0;
  border-left: 1px solid ${colorPalette.grey300};
  display: flex;
  flex-direction: column;
`;

export const FixedPanelContainer = styled.div`
  padding-top: ${getSpacing(6)};
  padding-left: ${getSpacing(3)};
  padding-right: ${getSpacing(3)};
  padding-bottom: ${getSpacing(6)};
`;

export const FixedPanel = ({ isOpened, onClosed, children }: FixedPanelProps) => {
  const onClosedRequired = () => {
    setTimeout(onClosed, 0);
  };

  if (isOpened) {
    return (
      <Container>
        <LeftPart onClick={onClosedRequired} />
        <Panel>{children}</Panel>
      </Container>
    );
  }

  return <></>;
};
