/* eslint-disable max-len */
import { IconProps, SvgIcon } from '@mui/material';
import React from 'react';

export const BimpliIcon = ({ sx }: IconProps) => (
  <SvgIcon viewBox="0 0 22 22" fill="none" sx={sx}>
    <path d="M11.4727 4.76953L1.7832 2.0625V20.7539L13.9648 20.5605L11.4727 4.76953Z" fill="white" />
    <path d="M7.86328 0H22V14.1582L16.0703 8.22852L7.86328 0Z" fill="#FFD800" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2332 2.22471C16.935 2.28715 16.5 2.52605 16.3586 2.70506C16.1812 2.92962 16.1526 3.23607 16.2939 3.39785C16.4165 3.53818 16.6066 3.59662 16.7855 3.54897C16.9924 3.49384 17.0822 3.36433 17.0858 3.11571C17.0886 2.9227 17.1023 2.89253 17.2148 2.83122C17.303 2.78314 17.4562 2.7623 17.7273 2.76157C18.0652 2.76058 18.145 2.77566 18.3589 2.88068C18.7207 3.05835 18.7959 3.20376 18.8167 3.76587C18.8319 4.17726 18.8273 4.21073 18.7583 4.18426C18.5902 4.11977 17.9323 4.02803 17.6379 4.02803C17.0125 4.02803 16.5399 4.2008 16.2336 4.54142C15.9267 4.88272 15.8572 5.3219 16.0458 5.72705C16.4216 6.53401 17.7975 6.66859 18.6941 5.98611L18.8445 5.8716L18.8574 6.10999L18.8703 6.34834H19.5342H20.198V6.06904V5.78975H19.8543H19.5105V4.61021C19.5105 3.93169 19.4909 3.33928 19.4644 3.21544C19.3775 2.81025 19.0671 2.47784 18.6151 2.30605C18.3375 2.20052 17.5656 2.15511 17.2332 2.22471ZM18.556 4.59191L18.8262 4.64532L18.8138 5.01201L18.8016 5.37866L18.5697 5.54048C17.7887 6.08559 16.7848 5.97632 16.6616 5.33286C16.5985 5.00359 16.8162 4.68377 17.1841 4.56518C17.415 4.49076 18.1134 4.50438 18.556 4.59191Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2332 2.22471C16.935 2.28715 16.5 2.52605 16.3586 2.70506C16.1812 2.92962 16.1526 3.23607 16.2939 3.39785C16.4165 3.53818 16.6066 3.59662 16.7855 3.54897C16.9924 3.49384 17.0822 3.36433 17.0858 3.11571C17.0886 2.9227 17.1023 2.89253 17.2148 2.83122C17.303 2.78314 17.4562 2.7623 17.7273 2.76157C18.0652 2.76058 18.145 2.77566 18.3589 2.88068C18.7207 3.05835 18.7959 3.20376 18.8167 3.76587C18.8319 4.17726 18.8273 4.21073 18.7583 4.18426C18.5902 4.11977 17.9323 4.02803 17.6379 4.02803C17.0125 4.02803 16.5399 4.2008 16.2336 4.54142C15.9267 4.88272 15.8572 5.3219 16.0458 5.72705C16.4216 6.53401 17.7975 6.66859 18.6941 5.98611L18.8445 5.8716L18.8574 6.10999L18.8703 6.34834H19.5342H20.198V6.06904V5.78975H19.8543H19.5105V4.61021C19.5105 3.93169 19.4909 3.33928 19.4644 3.21544C19.3775 2.81025 19.0671 2.47784 18.6151 2.30605C18.3375 2.20052 17.5656 2.15511 17.2332 2.22471ZM18.556 4.59191L18.8262 4.64532L18.8138 5.01201L18.8016 5.37866L18.5697 5.54048C17.7887 6.08559 16.7848 5.97632 16.6616 5.33286C16.5985 5.00359 16.8162 4.68377 17.1841 4.56518C17.415 4.49076 18.1134 4.50438 18.556 4.59191Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11V22H11H22V18.0576V14.1151L14.9423 7.05757L7.88468 0H3.94234H0V11ZM5.13605 3.71895C5.74565 3.87771 6.17929 4.34118 6.3898 5.05884C6.45416 5.2782 6.46521 5.67162 6.48613 8.48633C6.50977 11.6632 6.50985 11.6662 6.60477 11.8436C6.82494 12.2553 7.23018 12.5051 7.66992 12.5002C8.15285 12.4949 8.50691 12.2775 8.71681 11.8575C8.85135 11.5883 8.85156 11.5867 8.85156 10.8857V10.1836H9.93004H11.0086L10.9903 11.0967C10.9734 11.9422 10.9636 12.0352 10.8582 12.3535C10.5306 13.3427 9.73169 14.1673 8.77839 14.5004C7.94484 14.7916 7.0742 14.7464 6.27726 14.3705C5.34574 13.9312 4.76433 13.2411 4.433 12.1816C4.37564 11.9982 4.36236 11.5089 4.34208 8.83734C4.31909 5.80353 4.31582 5.70599 4.23496 5.64691C4.17248 5.60123 4.00267 5.58594 3.5582 5.58594H2.96484V4.61914V3.65234H3.92262C4.64905 3.65234 4.94214 3.66841 5.13605 3.71895ZM10.3773 5.30024C10.6116 5.40938 10.8479 5.6488 10.9409 5.87147C11.0255 6.07398 11.0145 6.51673 10.9196 6.72555C10.6228 7.37894 9.77831 7.55434 9.21589 7.07949C8.9491 6.8542 8.79875 6.37128 8.88998 6.0326C9.06851 5.3695 9.77689 5.02068 10.3773 5.30024ZM5.96282 16.1582C6.22527 17.5695 7.83836 18.1193 8.86574 17.1477C9.14156 16.8868 9.31386 16.5626 9.37913 16.1815L9.4276 15.8984H10.4995H11.5714L11.5458 16.2529C11.5317 16.4479 11.4698 16.7805 11.4082 16.992C11.0381 18.2626 10.0781 19.2326 8.76562 19.6622C8.42802 19.7726 8.34681 19.7819 7.69141 19.7843C7.02694 19.7867 6.95819 19.7792 6.59643 19.6644C5.89995 19.4434 5.39481 19.1372 4.91017 18.6423C4.59246 18.3179 4.47008 18.1574 4.27165 17.805C4.00477 17.3309 3.78125 16.5483 3.78125 16.088V15.8984H4.84786H5.91452L5.96282 16.1582Z"
      fill="#0086F7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 11V22H11H22V18.0576V14.1151L14.9423 7.05757L7.88468 0H3.94234H0V11ZM5.13605 3.71895C5.74565 3.87771 6.17929 4.34118 6.3898 5.05884C6.45416 5.2782 6.46521 5.67162 6.48613 8.48633C6.50977 11.6632 6.50985 11.6662 6.60477 11.8436C6.82494 12.2553 7.23018 12.5051 7.66992 12.5002C8.15285 12.4949 8.50691 12.2775 8.71681 11.8575C8.85135 11.5883 8.85156 11.5867 8.85156 10.8857V10.1836H9.93004H11.0086L10.9903 11.0967C10.9734 11.9422 10.9636 12.0352 10.8582 12.3535C10.5306 13.3427 9.73169 14.1673 8.77839 14.5004C7.94484 14.7916 7.0742 14.7464 6.27726 14.3705C5.34574 13.9312 4.76433 13.2411 4.433 12.1816C4.37564 11.9982 4.36236 11.5089 4.34208 8.83734C4.31909 5.80353 4.31582 5.70599 4.23496 5.64691C4.17248 5.60123 4.00267 5.58594 3.5582 5.58594H2.96484V4.61914V3.65234H3.92262C4.64905 3.65234 4.94214 3.66841 5.13605 3.71895ZM10.3773 5.30024C10.6116 5.40938 10.8479 5.6488 10.9409 5.87147C11.0255 6.07398 11.0145 6.51673 10.9196 6.72555C10.6228 7.37894 9.77831 7.55434 9.21589 7.07949C8.9491 6.8542 8.79875 6.37128 8.88998 6.0326C9.06851 5.3695 9.77689 5.02068 10.3773 5.30024ZM5.96282 16.1582C6.22527 17.5695 7.83836 18.1193 8.86574 17.1477C9.14156 16.8868 9.31386 16.5626 9.37913 16.1815L9.4276 15.8984H10.4995H11.5714L11.5458 16.2529C11.5317 16.4479 11.4698 16.7805 11.4082 16.992C11.0381 18.2626 10.0781 19.2326 8.76562 19.6622C8.42802 19.7726 8.34681 19.7819 7.69141 19.7843C7.02694 19.7867 6.95819 19.7792 6.59643 19.6644C5.89995 19.4434 5.39481 19.1372 4.91017 18.6423C4.59246 18.3179 4.47008 18.1574 4.27165 17.805C4.00477 17.3309 3.78125 16.5483 3.78125 16.088V15.8984H4.84786H5.91452L5.96282 16.1582Z"
      fill="#0086F7"
    />
  </SvgIcon>
);
