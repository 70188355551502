import { TypographyOptions } from '@mui/material/styles/createTypography';

export const TypographyTheme : TypographyOptions = {
  h1: {
    fontSize: 96,
    lineHeight: 0.85,
    letterSpacing: '-0.05em',
    fontFamily: ' "Helvetica Neue", "Arial", sans-serif',
    fontWeight: 400,
  },
  fontSize: 10,
  h2: {
    fontSize: 60,
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 0.9,
    letterSpacing: '-0.05em',
  },
  h3: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 48,
    lineHeight: 0.94,
    letterSpacing: '-0.04em',
  },
  h4: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    lineHeight: 1,
    fontSize: 34,
    letterSpacing: '-0.03em',
  },
  h5: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 24,
    lineHeight: 1,
    letterSpacing: '-0.03em',
  },
  h6: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 20,
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '-0.02em',
  },

  subtitle1: {
    fontSize: 16,
    lineHeight: 1.25,
    letterSpacing: '-0.02em',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
  },
  subtitle2: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.14,
    letterSpacing: '-0.01em',
  },
  body1: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 16,
    letterSpacing: '-0.02em',
  },
  body2: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 14,
    lineHeight: 1.42,
    letterSpacing: '-0.01em',
  },
  button: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 17,
    fontWeight: 400,
    lineHeight: 1.2,
    textTransform: 'initial',
    letterSpacing: '-0.02em',
  },
  caption: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: 12,
    lineHeight: 1.33,
    letterSpacing: '0em',
  },
  overline: {
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    letterSpacing: '0em',
  },
};
