import { Button } from '@mui/material';
import React from 'react';
import { Upload } from '@mui/icons-material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ReplyTemplateDraft } from '../domain/ReplyTemplate';
import { CsvReader } from 'src/utils/csv/CsvReader';
import { CreateReplyTemplateBuilder } from './CreateReplyTemplateBuilder';
import { ampli } from 'src/ampli';

type Props = {
  onFileSelectionSuccess: (drafts: ReplyTemplateDraft[]) => void;
  onFileSelectionError: (error: any) => void
};

export function ImportReplyTemplateCsvButton({ onFileSelectionSuccess, onFileSelectionError }: Props) {
  return (
    <Button size="medium" component="label" variant="outlined" startIcon={<Upload />}>
      <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.import" />
      <input
        type="file"
        hidden
        accept="application/csv, .csv"
        onClick={(e) => {
          e.currentTarget.value = '';
          ampli.importReplyTemplatesClicked();
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          CsvReader.parseFromCsv(event.target?.files![0], new CreateReplyTemplateBuilder(), ['name', 'content', 'applicableRatings'])
            .then(onFileSelectionSuccess)
            .catch(onFileSelectionError);
        }}
      />
    </Button>
  );
}
