/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { PaletteTheme } from '../../theme/PaletteTheme';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const LogoutSvg: React.FC<Props> = ({ size = 20, color = PaletteTheme.menu.disabled, ...props }: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.46567 1.66669L7.50008 1.66669C7.96032 1.66669 8.33342 2.03978 8.33342 2.50002C8.33342 2.96026 7.96032 3.33335 7.50008 3.33335H6.50008C5.78627 3.33335 5.30102 3.334 4.92595 3.36465C4.5606 3.3945 4.37377 3.4486 4.24343 3.51501C3.92983 3.6748 3.67486 3.92977 3.51507 4.24337C3.44866 4.3737 3.39456 4.56054 3.36471 4.92589C3.33406 5.30096 3.33342 5.7862 3.33342 6.50002V13.5C3.33342 14.2138 3.33406 14.6991 3.36471 15.0742C3.39456 15.4395 3.44866 15.6263 3.51507 15.7567C3.67486 16.0703 3.92983 16.3252 4.24343 16.485C4.37376 16.5514 4.5606 16.6055 4.92595 16.6354C5.30102 16.666 5.78627 16.6667 6.50008 16.6667H7.50008C7.96032 16.6667 8.33342 17.0398 8.33342 17.5C8.33342 17.9603 7.96032 18.3334 7.50008 18.3334H6.46565C5.79485 18.3334 5.24122 18.3334 4.79023 18.2965C4.32181 18.2583 3.89123 18.1761 3.48678 17.97C2.85957 17.6505 2.34964 17.1405 2.03006 16.5133C1.82398 16.1089 1.74185 15.6783 1.70358 15.2099C1.66673 14.7589 1.66674 14.2052 1.66675 13.5344V6.4656C1.66674 5.7948 1.66673 5.24116 1.70358 4.79017C1.74185 4.32175 1.82399 3.89116 2.03006 3.48672C2.34964 2.85951 2.85957 2.34958 3.48678 2.03C3.89123 1.82392 4.32181 1.74179 4.79023 1.70352C5.24122 1.66667 5.79486 1.66668 6.46567 1.66669ZM12.7442 5.2441C13.0696 4.91866 13.5972 4.91866 13.9227 5.2441L18.0893 9.41077C18.4148 9.7362 18.4148 10.2638 18.0893 10.5893L13.9227 14.7559C13.5972 15.0814 13.0696 15.0814 12.7442 14.7559C12.4187 14.4305 12.4187 13.9029 12.7442 13.5774L15.4882 10.8334H7.50008C7.03985 10.8334 6.66675 10.4603 6.66675 10C6.66675 9.53978 7.03985 9.16669 7.50008 9.16669H15.4882L12.7442 6.42261C12.4187 6.09717 12.4187 5.56954 12.7442 5.2441Z" fill={color} />
  </svg>

);

export default LogoutSvg;
