import axios from 'axios';
import { GuestExperienceRepository } from '../domain/GuestExperienceRepository';
import { DistributionByRating } from '../domain/DistributionByRating';
import { DateRange } from '../../domain/DateRange';
import { convertReviewResponseToReview, ReviewResponse } from '../../browse/infrastructure/ReviewResponse';
import { badReviewsForDimension, DimensionAnalysis, DimensionsAnalysis } from '../domain/DimensionsAnalysis';

import { parseDimension } from '../../browse/domain/Dimension';

type DimensionAnalysisResponse = {
  name: string;
  averageRating: number;
  numberOfReviews: number;
};

export type DimensionsAnalysisResponse = {
  reviews: ReviewResponse[];
  dimensions: DimensionAnalysisResponse[];
};

export type DistributionByRatingResponse = { ratings: { rating: number; count: number }[] };

export class HttpGuestExperienceRepository implements GuestExperienceRepository {
  constructor(private readonly baseUrl: string) {
  }

  distributionByRatingInTimeRange = async (businessId: string, dateRange: DateRange): Promise<DistributionByRating> =>
    axios.get<DistributionByRatingResponse>(
      `${this.baseUrl}/businesses/${businessId}/rating-distribution?startingAt=${dateRange.startDate()
        .toISOString()}&endingAt=${dateRange.endDate()
        .toISOString()}`)
      .then(
        (response) => this.mapDistributionByRating(response.data),
      );

  private mapDistributionByRating = (data: DistributionByRatingResponse): DistributionByRating => data.ratings.map((rating) => rating);

  ratingDimensionsAnalysis(businessId: string, dateRange: DateRange): Promise<DimensionsAnalysis> {
    return axios.get<DimensionsAnalysisResponse>(
      `${this.baseUrl}/businesses/${businessId}/dimensions-analysis?startingAt=${dateRange.startDate()
        .toISOString()}&endingAt=${dateRange.endDate()
        .toISOString()}`,
    )
      .then(
        (response) => this.mapDimensionsAnalysis(response.data),
      );
  }

  private mapDimensionsAnalysis = (data: DimensionsAnalysisResponse): DimensionsAnalysis => {
    const reviews = data.reviews.map(convertReviewResponseToReview);
    const dimensions: DimensionAnalysis[] = data.dimensions
      .map((d: DimensionAnalysisResponse) => ({
        name: parseDimension(d.name),
        numberOfReviews: d.numberOfReviews,
        averageRating: d.averageRating === 0 ? undefined : d.averageRating,
        badReviews: badReviewsForDimension(reviews, d.name),
      }));

    return ({
      reviews,
      dimensions,
    });
  };
}
