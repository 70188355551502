/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const CheckoutSvg: React.FC<Props> = ({ size = 20, ...props }: Props) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="#186AFF"/>
      <path d="M14.0334 4.59959L14.0336 4.59951L14.0304 4.59516C13.9493 4.48523 13.8275 4.41639 13.6927 4.40268L13.6927 4.40217H13.6828H8.16667V4.40167L8.15676 4.40268C8.02198 4.41639 7.90012 4.48523 7.8191 4.59516L7.81856 4.59476L7.81285 4.60477L4.96478 9.60317C4.88132 9.73294 4.8813 9.90334 4.96474 10.0331L7.80123 15.03C7.80137 15.0303 7.80151 15.0305 7.80165 15.0308C7.87486 15.1642 8.01766 15.2342 8.15591 15.2342H13.672C13.8204 15.2342 13.9433 15.1448 14.0196 15.0412L14.0197 15.0413L14.0227 15.0368C14.1078 14.9068 14.1085 14.7352 14.0248 14.6046L11.386 9.82911L14.0356 5.03177C14.1193 4.90113 14.1186 4.72953 14.0334 4.59959ZM12.9897 5.24345L10.9198 8.97034L8.86976 5.24345H12.9897ZM5.79524 9.81818L8.14403 5.67429L10.4313 9.81818L8.14403 13.9621L5.79524 9.81818ZM12.969 14.382H8.86976L10.9194 10.6558L12.969 14.382Z" fill="white" stroke="white" strokeWidth="0.195652"/>
    </svg>
);

export default CheckoutSvg;