import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, CircularProgress, Input, Typography } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type MenuDescriptionSettingsProps = {
  menu: Menu;
};

export const MenuDescriptionSettings = ({ menu }: MenuDescriptionSettingsProps) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const business = useCurrentBusinessOrThrow();

  const updateMenu = useUpdateMenuMutation(business.id, menu.id);
  const [menuDescription, setMenuDescription] = useState<string>(menu.description ?? '');
  const [menuDescriptionError, setMenuDescriptionError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const hasChanged = useMemo(
    () => (menu.description ?? '').localeCompare(menuDescription) !== 0,
    [menu.description, menuDescription],
  );

  const onClickUpdateMenuDescription = async () => {
    const cleanedMenuDescription = menuDescription.trim();
    setMenuDescriptionError('');
    setLoading(true);

    updateMenu
      .mutateAsync({ description: cleanedMenuDescription })
      .then(() => {
        setMenuDescription(cleanedMenuDescription);

        useSnackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({
            id: 'menus.settings.description.notification',
            defaultMessage: 'description updated',
          }),
        });
      })
      .catch(() => {
        setMenuDescriptionError(
          intl.formatMessage({
            id: 'menus.settings.description.update-error',
            defaultMessage: 'unable to update description',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const onMenuDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenuDescription(event.target.value);
  };

  if (!business.patEnrollment) {
    return <></>;
  }

  return (
    <Box>
      <FormattedMessage id="menus.settings.description.label" defaultMessage="description of your menu" />

      <Box flexDirection="row" display="flex">
        <Input
          multiline
          maxRows={4}
          onChange={onMenuDescriptionChange}
          error={!!menuDescriptionError}
          sx={{ width: '100%' }}
          value={menuDescription}
          placeholder={intl.formatMessage({
            id: 'menus.settings.description.placeholder',
            defaultMessage: 'input the description of your menu',
          })}
        />

        {hasChanged && (
          <Button size="small" onClick={onClickUpdateMenuDescription} disabled={loading} variant="contained">
            <FormattedMessage id="menus.settings.save-btn" defaultMessage="save" />
          </Button>
        )}
        {loading && <CircularProgress />}
      </Box>
      {menuDescriptionError && (
        <Typography variant="body1" color="error" component="div">
          {menuDescriptionError}
        </Typography>
      )}
    </Box>
  );
};
