import React, { createContext, ReactNode, useMemo } from 'react';
import { OrderingMenuConfig } from './OrderingMenuConfig';
import { OrderingMenuRepositoryHttp } from '../repositories/OrderingMenuRepositoryHttp';

type OrderingMenuContextProviderProps = {
  orderingBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = OrderingMenuConfig & {
  children: ReactNode;
};

export const OrderingMenuConfigContext = createContext<Partial<ProviderConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => <OrderingMenuConfigContext.Provider value={config}>{children}</OrderingMenuConfigContext.Provider>;

export const OrderingMenuContextProvider = ({
  orderingBackendUrl,
  children,
}: OrderingMenuContextProviderProps) => {
  // Repo
  const orderingMenuRepository = useMemo(() => new OrderingMenuRepositoryHttp(orderingBackendUrl), [orderingBackendUrl]);

  return <Provider orderingMenuRepository={orderingMenuRepository}>{children}</Provider>;
};
