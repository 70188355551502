import React, { useCallback } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { colorPalette } from '../../../stylesheet';
import { StyledTimePicker, TimePeriodAndIcon, TimePeriodContainer } from './OpeningHours.style';
import { TimePeriod } from '../../domain/TimePeriod';
import { Time } from '../../domain/Time';
import { getTimePeriodFromString } from '../../utils/getTimePeriodFromString';
import IconButton from '../IconButton';
import { TrashIcon } from '../Icons';

export enum ChangeTimePeriodType {
  START_TIME,
  END_TIME,
}

interface Props {
  period: TimePeriod;
  index: number;
  onChangeTimePeriod: (index: number, periodType: ChangeTimePeriodType, value: Time) => void;
  removeOpeningHour: (index: number) => void;
}

const TimePeriodEditor: React.FC<Props> = ({
  period, index, onChangeTimePeriod, removeOpeningHour,
}) => {
  const intl = useIntl();

  const startTime = getTimePeriodFromString(period.startTime);
  const endTime = getTimePeriodFromString(period.endTime);
  const onStartChange = useCallback(
    (time: Time) => {
      onChangeTimePeriod(index, ChangeTimePeriodType.START_TIME, time);
    },
    [onChangeTimePeriod, index],
  );
  const onEndChange = useCallback(
    (time: Time) => {
      onChangeTimePeriod(index, ChangeTimePeriodType.END_TIME, time);
    },
    [onChangeTimePeriod, index],
  );
  const onRemove = useCallback(() => removeOpeningHour(index), [index, removeOpeningHour]);
  return (
    <TimePeriodAndIcon>
      <TimePeriodContainer>
        <StyledTimePicker
          placeholder={intl.formatMessage({
            id: 'venue.openingHoursStartPlaceholder',
            defaultMessage: 'opening at...',
          })}
          value={startTime!}
          onChange={onStartChange}
        />
        <StyledTimePicker
          placeholder={intl.formatMessage({
            id: 'venue.openingHoursEndPlaceholder',
            defaultMessage: 'closing at...',
          })}
          thresold={startTime}
          value={endTime!}
          onChange={onEndChange}
        />
      </TimePeriodContainer>
      <IconButton Icon={TrashIcon} color={colorPalette.red} onClick={onRemove} />
    </TimePeriodAndIcon>
  );
};

export default TimePeriodEditor;
