import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { AreaDetailsDeletionWarningModal } from './AreaDetailsDeletionWarningModal';
import { Area } from '../../../domain/Area';
import { useDeleteArea } from '../../../hooks';
import { useRouting } from '../../../../common/hooks';
import { AreaDetailsGroup } from './AreaDetailsGroup';
import { BusinessId } from 'src/business/domain/Business';

type AreaDetailsDeleteButtonProps = {
  businessId: BusinessId;
  area: Area;
};

export const AreaDetailsDeleteButton = ({ businessId, area }: AreaDetailsDeleteButtonProps) => {
  const intl = useIntl();
  const navigation = useRouting();
  const [deleteState, deleteArea] = useDeleteArea();
  const [isModalOpened, openModal] = useState<boolean>(false);

  const onDeleteArea = async () => {
    openModal(false);
    await deleteArea(area.id);
    navigation.goToAreas(businessId);
  };

  return (
    <AreaDetailsGroup
      title={intl.formatMessage({ id: 'settings.area.details.manage-group.title', defaultMessage: 'manage area' })}
    >
      <Button variant="warning" size="large" onClick={() => openModal(true)} disabled={deleteState.loading}>
        <FormattedMessage id="settings.area.details.delete-btn" defaultMessage="delete this area" />
      </Button>

      <AreaDetailsDeletionWarningModal
        areaName={area.name}
        isOpen={isModalOpened}
        onCancel={() => openModal(false)}
        onConfirm={onDeleteArea}
      />
    </AreaDetailsGroup>
  );
};
