import { Card, CircularProgress, styled } from '@mui/material';
import React from 'react';

const EmptyContainerCard = styled(Card)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 269px;
  min-width: 50%;
`;

export const EmptyCard = () => (
  <EmptyContainerCard>
    <CircularProgress size="large" />
  </EmptyContainerCard>
);
