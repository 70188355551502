import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-router';
import { SelectLanguage } from 'src/components/select/SelectLanguage';
import { allSupportedLanguages, Language } from 'src/app/i18n/AllSupportedLanguage';
import { useAiSettings } from './useAiSettings';
import { AiReplySettings } from './AiReplySettings';
import { useAiSettingsMutation } from './useAiSettingsMutation';

type FormValues = {
  language: Language,
  guessLanguage: boolean,
};

export const AiReplySettingsPanelLoaded = ({ aiReplySettings }: { aiReplySettings: AiReplySettings }) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();

  const mutation = useAiSettingsMutation();

  const supportedLanguages = allSupportedLanguages();
  const settingsLanguage = useMemo(() => {
    return supportedLanguages.find(l => intl.formatMessage({ id: l.label }) === aiReplySettings.replyLanguage) ?? supportedLanguages[0];
  }, [aiReplySettings]);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, isValid },
    reset,
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      guessLanguage: aiReplySettings.guessLanguage,
      language: settingsLanguage,
    },
  });

  function onSubmit(formValues: FormValues) {
    const newValues = {
      guessLanguage: formValues.guessLanguage,
      replyLanguage: intl.formatMessage({ id: formValues.language.label }),
    };

    mutation.mutateAsync(newValues).then(() => {
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'saved', defaultMessage: 'saved' }),
      });

      reset(formValues);
    });
  }

  return (
    <Form onSubmit={handleSubmit((formValues) => onSubmit(formValues))}>
        <Card>
          <CardHeader
            title={intl.formatMessage({ id: 'settings.reviews.ai_reply_suggestions', defaultMessage: 'AI Reply suggestions' })}
            action={
              <Button variant="contained" color="primary" type="submit" disabled={!isDirty || !isValid || isSubmitting}>
                {intl.formatMessage({ id: 'settings.save_changes', defaultMessage: 'Save' })}
              </Button>
            }
          />
          <CardContent>
            <Controller
              control={control}
              name={'language'}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  sx={{ gap: '1em' }}
                  control={
                    <SelectLanguage
                      supportedLanguages={supportedLanguages}
                      currentLanguage={value}
                      setCurrentLanguage={(language) => {onChange(language);}}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'settings.reviews.ai_reply_suggestions.default_reply_language',
                    defaultMessage: 'Default reply language',
                  })}
                />
              )}
            />
            <Controller
              control={control}
              name={'guessLanguage'}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label={intl.formatMessage({
                    id: 'settings.reviews.ai_reply_suggestions.guess_language',
                    defaultMessage: 'Reply using same language as reviewer',
                  })}
                  checked={value}
                  onChange={onChange}
                />
              )}
            />
          </CardContent>
        </Card>
    </Form>
  );
};

export const AiReplySettingsPanel = () => {
  const { data: aiSettings } = useAiSettings();

  if (!aiSettings) return <CircularProgress />;

  return <AiReplySettingsPanelLoaded aiReplySettings={aiSettings}/>;
};
