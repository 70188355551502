import React from 'react';
import { Grid } from '@mui/material';
import { EReputationPanel } from './panels/EReputationPanel';
import { AVERAGE_RATING, EReputationKpisViewModel, TOTAL_FIVE_STARS, TOTAL_REVIEWS } from './EReputationViewModel';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

type Props = {
  onChangeKpiType: (newKpiType: KpiName) => void;
  initialKpiType: KpiName;
  eReputationKpisViewModel: EReputationKpisViewModel[]
};

export const EReputationPanels = ({
  onChangeKpiType,
  initialKpiType,
  eReputationKpisViewModel,
}: Props) => {
  const kpiNames: KpiName[] = [TOTAL_REVIEWS.type, TOTAL_FIVE_STARS.type, AVERAGE_RATING.type];

  return (
    <Grid container direction="column" spacing={1.5}>
      {kpiNames.map((kpiName) => {
        const globalKpis = eReputationKpisViewModel.find((d) => d.kpiType === kpiName)?.globalKpis;
        return (
          <Grid
            key={kpiName}
            item
            onClick={() => onChangeKpiType(kpiName)}
          >
            {globalKpis &&
              <EReputationPanel
                initialKpiType={kpiName}
                selectedKpiType={initialKpiType}
                globalKpis={globalKpis}
              />
            }
            {!globalKpis &&
              <>No {kpiName} Data For that Time Frame</>
            }
          </Grid>
        );
      })}
    </Grid>
  );
};
