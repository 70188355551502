import { Box, ToggleButton, ToggleButtonGroup, styled } from '@mui/material';

export const ToggleButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
}));
  
export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(1),
  borderRadius: `0px ${theme.spacing(1)} ${theme.spacing(1)} 0px`,
  border: 'none',
}));

export const StyledToggleButtonItem = styled(ToggleButton)(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)} ${theme.spacing(1)} ${theme.spacing(2)}`,
  alignItems: 'center',
  gap: theme.spacing(1),
  borderRadius: `${theme.spacing(1)} 0px 0px ${theme.spacing(1)}`,
  border: `1px solid ${theme.palette.grey[300]}`,

  '&:not(.Mui-selected)': {
    backgroundColor: `${theme.palette.grey[100]}`,
    color: theme.palette.text.secondary,
  },
}));