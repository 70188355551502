import axios from 'axios';
import { GoogleSetup } from '../domain/GoogleSetup';
import { GoogleBusinessLocation } from '../domain/GoogleBusinessLocation';
import { BusinessId } from 'src/business/domain/Business';
import { GoogleSetupStatus } from '../domain/GoogleSetupStatus';

export class HttpGoogleSetup implements GoogleSetup {
  constructor(private readonly baseUrl: string) {
  }

  async linkGoogleAccountToSunday(businessId: string, code: string): Promise<void> {
    await axios.post(`${this.baseUrl}/platforms/google/setup/account`, {
      code: code,
      businessId: businessId,
    });
  }

  async listAvailableLocations(businessId: string): Promise<GoogleBusinessLocation[]> {
    const response = await axios.get<GoogleBusinessLocation[]>(
      `${this.baseUrl}/platforms/google/setup/locationsWithStats`,
      {
        params: {
          businessId: businessId,
        },
      },
    );
    return response.data;
  }


  async selectLocation(businessId: string, location: GoogleBusinessLocation): Promise<void> {
    await axios.put(`${this.baseUrl}/platforms/google/setup/locations`, {
      businessId: businessId,
      accountId: location.accountId,
      locationId: location.id,
      placeId: location.placeId,
    });
  }

  async createDemoAccount(businessId: string, placeId: string): Promise<void> {
    await axios.post(`${this.baseUrl}/platforms/google/setup/demo-account`, {
      businessId: businessId,
      placeId: placeId,
    });
  }

  async getBusinessLocation(businessId: string): Promise<GoogleBusinessLocation | null> {
    const response = await axios.get<GoogleBusinessLocation | null>(
      `${this.baseUrl}/platforms/google/business-location?businessId=${businessId}`,
      {
        validateStatus: (status) => status === 200 || status === 404,
      },
    );
    return response.data ?? null;
  }

  async getSetupStatus(businessId: BusinessId): Promise<GoogleSetupStatus> {
    const response = await axios.get<GoogleSetupStatus>(`${this.baseUrl}/platforms/google/setup/status`, {
      params: {
        businessId: businessId,
      },
    });
    return response.data;
  }

  async unlink(businessId: string): Promise<void> {
    await axios.delete(`${this.baseUrl}/platforms/google/setup/account`, {
      params: {
        businessId: businessId,
      },
    });
  }
}
