/* eslint-disable max-lines */
import { Box, Divider, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { WeeklyReportOrganization } from '../domain/WeeklyReportOrganization';
import {
  renderGtvLine,
  renderNumber,
  safeRenderMoney,
  safeRenderMoneyOrNull,
  safeSubMoneyOrNull,
} from '../domain/TemplatesHelper';
import { KpiMoney } from './KpiMoney';
import { KpiString } from './KpiString';
import { palette } from '../design/palette';
import { dimensionsPx } from '../design/dimensions';
import { WarningMessage } from './WarningMessage';
import warning from '../assets/venueKpi/organization/warning.png';

export const reportPeriod = (intl: IntlShape, startDate: Date, endDate: Date) => {
  const start = intl.formatDate(startDate, { day: 'numeric', month: 'short' });
  const end = intl.formatDate(endDate, { day: 'numeric', month: 'short' });
  return `${start} - ${end}`;
};

export const OrganizationHeader = ({
  weeklyReport,
  startDate,
  endDate,
}: {
  weeklyReport: WeeklyReportOrganization;
  startDate: Date;
  endDate: Date;
}) => {
  const intl = useIntl();
  const otherThanSunday = safeSubMoneyOrNull(weeklyReport.totalGTV.lastWeek, weeklyReport.totalGTVSunday.lastWeek);
  return (
    <Box style={{ backgroundColor: palette.primary.white }} paddingBottom={dimensionsPx.section_separator.l}>
      <Stack display="flex" flexDirection="row" justifyContent="space-between" p={dimensionsPx.section_separator.l}>
        <Typography
          fontSize={dimensionsPx.fonts.m}
          fontWeight={dimensionsPx.font_weight.medium}
          lineHeight={dimensionsPx.line_height.m}
          color={palette.primary.black}
        >
          {weeklyReport.organizationName}
          {' '}
          -
          <FormattedMessage id="venuekpi.weeklyreport.multi.email.title" />
        </Typography>
        <Typography
          fontSize={dimensionsPx.fonts.m}
          fontWeight={dimensionsPx.font_weight.medium}
          lineHeight={dimensionsPx.line_height.m}
          color={palette.primary.black}
          align="right"
          bgcolor={palette.primary.electricPink}
          p={dimensionsPx.section_separator.xs}
          borderRadius={dimensionsPx.section_separator.l}
        >
          {reportPeriod(intl, startDate, endDate)}
        </Typography>
      </Stack>
      <Divider />
      <Stack
        pl={dimensionsPx.section_separator.l}
        pt={dimensionsPx.section_separator.xxxl}
        pr={dimensionsPx.section_separator.xxxl}
        pb={0}
        gap={1}
      >
        <Typography
          fontSize="48px"
          fontWeight={dimensionsPx.font_weight.medium}
          lineHeight={dimensionsPx.line_height.xxl}
          color={palette.primary.black}
          pb={dimensionsPx.section_separator.s}
        >
          <FormattedMessage
            id="venuekpi.weeklyreport.multi.email.title.compare"
            values={{
              organizationName: (
                <span style={{ color: palette.neutral.grey400, whiteSpace: 'nowrap' }}>
                  {weeklyReport.organizationName}
                </span>
              ),
            }}
          />
        </Typography>

        {intl
          .formatMessage({ id: 'venuekpi.weeklyreport.multi.email.title.legend' })
          .split('{linebreak}')
          .map((legend) => (
            <Typography
              fontSize={dimensionsPx.fonts.m}
              fontWeight={dimensionsPx.font_weight.medium}
              lineHeight={dimensionsPx.line_height.m}
              color={palette.primary.black}
              key={legend}
            >
              {legend}
            </Typography>
          ))}
        {weeklyReport.filteredVenueNames && (
          <>
            <WarningMessage
              warningMessage={{
                message: intl.formatMessage(
                  { id: 'venuekpi.weeklyreport.multi.email.filtered_venues' },
                  { venues: weeklyReport.filteredVenueNames.join(', ') },
                ),
                imagePath: warning,
              }}
            />
          </>
        )}
      </Stack>
      <Stack padding={dimensionsPx.section_separator.l} pt={dimensionsPx.section_separator.xl} pb={0} gap={1}>
        <Typography
          fontSize={dimensionsPx.fonts.xl}
          fontWeight={dimensionsPx.font_weight.medium}
          lineHeight={dimensionsPx.line_height.xl}
          letterSpacing={dimensionsPx.letter_spacing.l}
          color={palette.primary.black}
        >
          <KpiMoney
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.GTV' })}
            amount={weeklyReport.totalGTV.lastWeek}
            countryCode={weeklyReport.countryCode}
            fluctuation={weeklyReport.totalGTV.fluctuation}
            amountFontSize={dimensionsPx.fonts.xxl}
            additionalInformation={[
              `${intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.QR' })} : ${safeRenderMoney(
                weeklyReport.totalQR.lastWeek,
                weeklyReport.countryCode,
              )}`,
              renderGtvLine(
                intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.PDQ' }),
                safeRenderMoneyOrNull(weeklyReport.totalPDQ.lastWeek, weeklyReport.countryCode),
              ),
              renderGtvLine(
                intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.non_sunday' }),
                safeRenderMoneyOrNull(otherThanSunday, weeklyReport.countryCode),
              ),
            ]}
            color={palette.primary.black}
          />
        </Typography>
      </Stack>
      <Stack
        m={dimensionsPx.section_separator.s}
        mt={0}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap={2}
      >
        <Stack
          bgcolor={palette.neutral.grey100}
          borderRadius={dimensionsPx.section_separator.l}
          p={dimensionsPx.section_separator.l}
          width="50%"
        >
          {weeklyReport.countryCode === 'US' && (
            <KpiString
              title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.tips' })}
              value={renderNumber(weeklyReport.sundayTipsRateWhenTips.lastWeek, weeklyReport.countryCode)}
              valueType="%"
              valueFontSize={dimensionsPx.fonts.l}
              countryCode={weeklyReport.countryCode}
              fluctuation={weeklyReport.sundayTipsRate.fluctuation}
              additionalInformation={[safeRenderMoney(weeklyReport.totalTips.lastWeek, weeklyReport.countryCode)]}
              color={palette.primary.black}
            />
          )}
          {weeklyReport.countryCode !== 'US' && (
            <KpiMoney
              title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.tips' })}
              amount={weeklyReport.totalTips.lastWeek}
              countryCode={weeklyReport.countryCode}
              fluctuation={weeklyReport.totalTips.fluctuation}
              additionalInformation={[
                renderNumber(weeklyReport.sundayTipsRate.lastWeek, weeklyReport.countryCode)
                  ? `${renderNumber(
                    weeklyReport.sundayTipsRate.lastWeek,
                    weeklyReport.countryCode,
                  )}% ${intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.tip_rate' })}`
                  : '',
              ]}
              color={palette.primary.black}
            />
          )}
        </Stack>
        <Stack
          bgcolor={palette.neutral.grey100}
          borderRadius={dimensionsPx.section_separator.l}
          p={dimensionsPx.section_separator.l}
          width="50%"
        >
          <KpiMoney
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.avg_basket_size' })}
            amount={weeklyReport.averageBasketSize.lastWeek}
            countryCode={weeklyReport.countryCode}
            fluctuation={weeklyReport.averageBasketSize.fluctuation}
            color={palette.primary.black}
          />
        </Stack>
      </Stack>
      <Stack m={dimensionsPx.section_separator.s} borderRadius={dimensionsPx.section_separator.l}>
        <Stack
          bgcolor={palette.neutral.grey100}
          sx={{
            borderTopLeftRadius: dimensionsPx.section_separator.l,
            borderTopRightRadius: dimensionsPx.section_separator.l,
          }}
          p={dimensionsPx.section_separator.l}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingRight="100px"
        >
          <KpiString
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.avg_google_rate' })}
            value={`${renderNumber(weeklyReport.averageGoogleRating.lastWeek, weeklyReport.countryCode)} (${
              weeklyReport.googleRatingCount.lastWeek
            })`}
            fluctuation={weeklyReport.averageGoogleRating.fluctuation}
            countryCode={weeklyReport.countryCode}
            fluctuationType="%"
            color={palette.primary.black}
          />
          <KpiString
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.avg_sunday_rate' })}
            value={`${renderNumber(weeklyReport.averageSundayRating.lastWeek, weeklyReport.countryCode)} (${
              weeklyReport.sundayRatingCount.lastWeek
            })`}
            fluctuation={weeklyReport.averageSundayRating.fluctuation}
            countryCode={weeklyReport.countryCode}
            fluctuationType="%"
            color={palette.primary.black}
          />
        </Stack>
        <Stack
          bgcolor={palette.neutral.grey100}
          sx={{
            borderBottomLeftRadius: dimensionsPx.section_separator.l,
            borderBottomRightRadius: dimensionsPx.section_separator.l,
          }}
          p={dimensionsPx.section_separator.l}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingRight="100px"
        >
          <KpiString
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.food_n_drinks' })}
            titleImageName="fork"
            value={`${renderNumber(weeklyReport.foodAndDrinkRating.lastWeek, weeklyReport.countryCode)} (${
              weeklyReport.foodAndDrinkRatingCount.lastWeek
            })`}
            fluctuation={weeklyReport.foodAndDrinkRating.fluctuation}
            countryCode={weeklyReport.countryCode}
            color={palette.primary.black}
          />
          <KpiString
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.ambiance' })}
            titleImageName="ring"
            value={`${renderNumber(weeklyReport.ambianceRating.lastWeek, weeklyReport.countryCode)} (${
              weeklyReport.ambianceRatingCount.lastWeek
            })`}
            fluctuation={weeklyReport.ambianceRating.fluctuation}
            countryCode={weeklyReport.countryCode}
            color={palette.primary.black}
          />
          <KpiString
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.service' })}
            titleImageName="service"
            value={`${renderNumber(weeklyReport.serviceRating.lastWeek, weeklyReport.countryCode)} (${
              weeklyReport.serviceRatingCount.lastWeek
            })`}
            fluctuation={weeklyReport.serviceRating.fluctuation}
            countryCode={weeklyReport.countryCode}
            color={palette.primary.black}
          />
          <KpiString
            title={intl.formatMessage({ id: 'venuekpi.weeklyreport.multi.email.value_for_money' })}
            titleImageName="piggy_bank"
            value={`${renderNumber(weeklyReport.valueForMoneyRating.lastWeek, weeklyReport.countryCode)} (${
              weeklyReport.valueForMoneyRatingCount.lastWeek
            })`}
            fluctuation={weeklyReport.valueForMoneyRating.fluctuation}
            countryCode={weeklyReport.countryCode}
            color={palette.primary.black}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
