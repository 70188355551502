import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { useUpdateBoxDetails } from '../../../../hooks';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type AlwaysSendTableNameToPosSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const AlwaysSendTableNameToPosSetting = ({ box, setBox }: AlwaysSendTableNameToPosSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateAlwaysSendTableNameToPos = async (alwaysSendTableName: boolean) => {
    makeUpdating(true);
    await updateBoxDetails({
      alwaysSendTableName,
    });
    setBox({
      ...box,
      alwaysSendTableName,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.alwaysSendTableNameToPos',
        defaultMessage: 'Always send table name to POS',
      })}
      description={intl.formatMessage({
        id: 'venue.alwaysSendTableNameToPosMessage',
        defaultMessage:
          'When active, table name will be added to the notes even if the order is not at table (if data is available i.e. ordered from a QR code associated to a table)',
      })}
      toggleValue={box.alwaysSendTableName}
      toggleOnChange={updateAlwaysSendTableNameToPos}
      disabled={isUpdating}
    />
  );
};
