import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { Role } from 'src/domain/user/Role';
import { Relation } from 'src/domain/user/Relation';

export type User = AuthenticatedUser | UnauthenticatedUser;

export type AuthenticatedUser = {
  userId: string;
  email: string;
  userType: UserType;
  lastName: string;
  firstName: string;
  phoneNumber: string;
  claims: Role[];
  authenticated: true;
  impersonated: boolean;
  relations: Relation[];
};

interface UnauthenticatedUser {
  authenticated: false;
  claims: Role[];
}

export const relationTypes = {
  can_access_financials: 'can_access_financials',
} as const;
export type RelationType = ValuesOf<typeof relationTypes>;

export const userType = {
  OPS: 'ops',
  MERCHANT: 'merchant',
} as const;
export type UserType = ValuesOf<typeof userType>;

export const isOpsEmail = (email: string) => email.endsWith('@sundayapp.com');
