import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { AreaSettingContainer } from '../../../components/Settings.style';
import { useRouting } from '../../../../common/hooks';
import Input from '../../../../common/components/Input';
import { usePushNotification } from '../../../../common/components/Notifications.hook';
import { useCreateArea } from '../../../hooks';
import { BusinessId } from 'src/business/domain/Business';
import { CircularProgress } from '@mui/material';

type AreaBoxCreationNameSettingsProps = {
  businessId: BusinessId;
  boxId: string;
  onAreaCreated: (areaId: string) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(3)};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: ${getSpacing(3)};
`;

const InputLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
  margin-bottom: ${getSpacing(1)};
`;

const InputErrorLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.red};
`;

const ActionLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const LoaderWrapper = styled.div`
  margin-left: ${getSpacing(2)};
`;

export const AreaBoxCreationNameSettings = ({ businessId, boxId, onAreaCreated }: AreaBoxCreationNameSettingsProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [createAreaState, createArea] = useCreateArea();
  const [areaName, setAreaName] = useState<string>('');
  const [areaNameError, setAreaNameError] = useState<string>();
  const navigation = useRouting();

  const onCancel = () => {
    navigation.goToAreas(businessId);
  };

  const onCreateArea = async () => {
    const cleanedAreaName = areaName.trim();
    if (cleanedAreaName.length === 0) {
      setAreaNameError(
        intl.formatMessage({ id: 'settings.area.create.name-required', defaultMessage: 'area name required' }),
      );
      return;
    }
    setAreaNameError('');

    const areaId = await createArea(boxId, cleanedAreaName);
    onAreaCreated(areaId);
    pushNotification(
      intl.formatMessage(
        { id: 'venue.settings.create.notification', defaultMessage: 'area {areaName} created' },
        { areaName: `'${cleanedAreaName}'` },
      ),
    );
  };

  return (
    <AreaSettingContainer>
      <Container>
        <Title>
          <FormattedMessage id="settings.area.create.title" defaultMessage="input the name of your area" />
        </Title>
        <InputLabel>
          <FormattedMessage id="settings.area.create.label" defaultMessage="name of your area" />
        </InputLabel>
        <Input onValueChange={setAreaName} hasError={!!areaNameError} />
        {areaNameError && <InputErrorLabel>{areaNameError}</InputErrorLabel>}
      </Container>
      <ActionLine>
        <Button variant="tertiary" size="large" marginTop="8px" onClick={onCancel} disabled={createAreaState.loading}>
          <FormattedMessage id="settings.area.create.cancel-btn" defaultMessage="cancel" />
        </Button>
        <Button
          variant="primary"
          size="large"
          marginTop="8px"
          onClick={onCreateArea}
          disabled={createAreaState.loading}
        >
          <FormattedMessage id="settings.area.create.create-btn" defaultMessage="create area" />
          {createAreaState.loading && (
            <LoaderWrapper>
              <CircularProgress size="medium" />
            </LoaderWrapper>
          )}
        </Button>
      </ActionLine>
    </AreaSettingContainer>
  );
};
