import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';

type SettingLineProps = {
  title: string;
  description?: string;
  error?: string;
  fullDescriptionSize?: boolean;
  direction?: 'column' | 'row';
  children: ReactNode;
};

type ContainerProps = {
  direction: 'column' | 'row';
};

const Container = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => props.direction};
  margin-bottom: ${getSpacing(2)};
  align-items: center;
`;

type TitleContainerProps = {
  fullDescriptionSize: boolean;
};

const TitleContainer = styled.div<TitleContainerProps>`
  display: flex;
  width: ${(props) => (props.fullDescriptionSize ? '100%' : '')};
  flex-direction: column;
  padding-right: ${getSpacing(2)};
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colorPalette.defaultTextColor};
  margin-bottom: ${getSpacing(1)};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Error = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.red};
  margin-top: ${getSpacing(1)};
`;

export const SettingLine = ({
  title,
  description,
  fullDescriptionSize = true,
  error,
  direction = 'row',
  children,
}: SettingLineProps) => (
  <Container direction={direction}>
    <TitleContainer fullDescriptionSize={fullDescriptionSize}>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
      {error && <Error>{error}</Error>}
    </TitleContainer>
    {children}
  </Container>
);
