// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAsync } from 'react-use';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { ButtonContainer, CustomizationForm, NotificationContainer } from '../Customization.styles';
import { useMenuAdapter } from 'src/menu/static-menu/infrastructure/useMenuAdapter';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Alert, Stack, TextField, Typography, useTheme } from '@mui/material';

type AppSettingsMixedMenusInputs = {
  pdfMenuLabel: string;
  websiteMenuLabel: string;
};

interface MixedMenusHandlerProps {
  enrollmentId: EnrollmentId;
}

const MixedMenusHandler = ({ enrollmentId }: MixedMenusHandlerProps) => {
  const useSnackBar = useSnackbar();
  const intl = useIntl();
  const menuAdapter = useMenuAdapter();
  const theme = useTheme();

  const [appSettingsMixedMenusUpdateError, setAppSettingsMixedMenusUpdateError] = useState('');

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<AppSettingsMixedMenusInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const labelsResponse = useAsync(() => menuAdapter.getMenuLabels(enrollmentId));

  useEffect(() => {
    reset({
      pdfMenuLabel: labelsResponse.value?.pdfMenuLabel,
      websiteMenuLabel: labelsResponse.value?.websiteMenuLabel,
    });
  }, [labelsResponse]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));

  const onSubmit = async (inputs: AppSettingsMixedMenusInputs) => {
    setAppSettingsMixedMenusUpdateError('');
    try {
      await menuAdapter.updateMenuLabels(enrollmentId, inputs.pdfMenuLabel ?? '', inputs.websiteMenuLabel ?? '');
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setAppSettingsMixedMenusUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <CustomizationForm onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} padding={3} borderRadius={4} bgcolor={theme.palette.background.paper}>
        <Typography variant={'h6'}>
          <FormattedMessage id="settings.app_settings.mixed_menus.title" />
        </Typography>
        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.mixed_menus.pdf_menu_label' })}
          id="pdfMenuLabel"
          InputLabelProps={{ shrink: true }}
          placeholder={intl.formatMessage({ id: 'settings.app_settings.mixed_menus.pdf_menu_label.placeholder' })}
          error={!!errors.pdfMenuLabel}
          helperText={!!errors.pdfMenuLabel ? errors.pdfMenuLabel?.message : ''}
          {...register('pdfMenuLabel', { deps: [], required: false })}
        />
        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.mixed_menus.website_menu_label' })}
          id="websiteMenuLabel"
          InputLabelProps={{ shrink: true }}
          placeholder={intl.formatMessage({ id: 'settings.app_settings.mixed_menus.website_menu_label.placeholder' })}
          error={!!errors.websiteMenuLabel}
          helperText={!!errors.websiteMenuLabel ? errors.websiteMenuLabel?.message : ''}
          {...register('websiteMenuLabel', {
            deps: [],
            required: false,
            validate: {},
          })}
        />
        {appSettingsMixedMenusUpdateError && (
          <NotificationContainer>
            <Alert severity="error">{appSettingsMixedMenusUpdateError}</Alert>
          </NotificationContainer>
        )}
        {isValid && isDirty && (
          <ButtonContainer>
            <Button type="submit" variant="primary" size="xSmall" disabled={!isValid || isSubmitting || !isDirty}>
              <FormattedMessage id="settings.save_changes" />
            </Button>
          </ButtonContainer>
        )}
      </Stack>
    </CustomizationForm>
  );
};
export default MixedMenusHandler;
