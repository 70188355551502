import { Drawer, styled } from '@mui/material';
import React, { useState } from 'react';
import { themeV5 } from 'src/app/theme/ThemeV5';
import BurgerMenuSvg from '../../../component/icons/BurgerMenuSvg';
import { MobileMenuItems } from './MobileMenuItems';
import { NavigationItem } from '../NavigationItems';
import CrossSvg from '../../../component/icons/CrossSvg';
import { SubLevelDrawer } from '../subLevel/SubLevelDrawer';

type MobileMenuDrawerProps = {
  menuItems: NavigationItem[];
};

const Container = styled('div')`
  padding: 8px 16px 16px;
  height: 100%;

`;

const Header = styled('div')`
  display: flex;
  padding-bottom: 16px;
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
`;

export const MobileMenuDrawer = ({ menuItems }: MobileMenuDrawerProps) => {
  const [isOpen, updateMenuVisibility] = useState<boolean>(false);
  const [openedGroupKey, updateGroupKeyOpened] = useState<string>();
  const [selectedGroupKey, updateGroupKeySelected] = useState<string>();
  const [subLevelDrawerOpened, setSubLevelDrawerOpened] = useState<NavigationItem | undefined>(undefined);

  const onClick = () => {
    updateMenuVisibility(!isOpen);
  };

  const closeSubLevelDrawer = () => {
    setSubLevelDrawerOpened(undefined);
    return;
  };
  const onDrawerClosed = () => {
    updateMenuVisibility(false);
  };

  const onItemSelected = (item: NavigationItem) => {

    if (item.subLevelItems && item.subLevelItems.length > 0) {
      setSubLevelDrawerOpened(item);
      window.scrollTo(0, 0);

    } else {
      updateGroupKeyOpened(undefined);
      onDrawerClosed();
    }
  };

  const onSubLevelItemSelected = () => {
    onDrawerClosed();
  };
  return (
    <>
      <BurgerMenuSvg size={48} color={themeV5.palette.text.secondary} onClick={onClick} />
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={onDrawerClosed}
        PaperProps={{
          sx: { width: '320px' },
        }}
      >
        <Container>

          {!subLevelDrawerOpened ?
            <>
              <Header>
                <IconWrapper onClick={onDrawerClosed}>
                  <CrossSvg size={24} color={themeV5.palette.text.secondary} />
                </IconWrapper>
              </Header>
              <MobileMenuItems
                menuItems={menuItems}
                onClose={onDrawerClosed}
                onItemSelected={onItemSelected}
                openedGroupKey={openedGroupKey}
                updateGroupKeyOpened={updateGroupKeyOpened}
                selectedGroupKey={selectedGroupKey}
                updateGroupKeySelected={updateGroupKeySelected}
              />
            </>
            :
            <SubLevelDrawer onItemSelected={onSubLevelItemSelected}
                            closeSubLevelDrawer={closeSubLevelDrawer}
                            item={subLevelDrawerOpened!} />
          }
        </Container>
      </Drawer>
    </>
  );
};
