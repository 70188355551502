import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Tag } from '../../domain/Tag';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_RELATED_MENU = 'menu_related_menu';
const buildQueryKey = (businessId: BusinessId, productId: string) =>
  [QUERYKEY_MENU_RELATED_MENU, businessId, productId] as const;
export const getRelatedMenusForProductQuery = async ({
  queryKey: [, businessId, productId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Tag[]> => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return menuRepository.findRelatedMenusForProduct(businessId, productId);
};

export const useGetRelatedMenusForProduct = (businessId: BusinessId, productId: string) =>
  useQuery({
    queryKey: buildQueryKey(businessId, productId),
    queryFn: getRelatedMenusForProductQuery,
  });
