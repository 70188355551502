import { Box, Card, IconButton, styled } from '@mui/material';

export const ButtonWrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
  cursor: 'pointer',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  justifyContent: 'space-between',
}));
  
export const IconButtonTitle = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}));
  
export const IconButtonWrapper = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  marginRight: theme.spacing(1),
  
  '.MuiBadge-badge': {
    right: 1,
    top: 4,
  },
}));

export const IconWrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.grey[500],
}));