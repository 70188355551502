import { BoxOrderDetails, BoxOrderSummary, Order, OrderHistory, OrderId, OrderSummary } from '../types';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export interface GetOrdersQuery {
  startDate: Date;
  endDate: Date;
  boxId?: string;
  areaId?: string;
  orderPaymentStatus?: string;
  orderStatus?: string;
  orderDisplayId?: string;
}

export enum AcceptationMode {
  CASH = 'CASH',
  CARD = 'CARD',
  FREE = 'FREE',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export interface OrdersResults {
  orders: OrderSummary[];
}

export interface BoxOrdersResults {
  orders: BoxOrderSummary[];
}

export interface OrderingOrderRepository {
  acceptOrder: (orderId: string, acceptationMode: AcceptationMode) => Promise<boolean>;
  cancelOrder: (orderId: string, reason: string) => Promise<boolean>;
  getOrder: (orderId: string) => Promise<Order>;
  getOrders: (enrollmentId: EnrollmentId, query: GetOrdersQuery, limit?: number) => Promise<OrderSummary[]>;
  getBoxOrder: (orderId: string) => Promise<BoxOrderDetails>;
  getBoxOrders: (boxId: string, query: GetOrdersQuery) => Promise<BoxOrderSummary[]>;
  getOrderHistory: (orderId: OrderId) => Promise<OrderHistory>;
}
