import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ReportId } from '../domain/ReconciliationReport';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';
import { CurrencyCode } from '@sundayapp/web-money';
import { ReconciliationReportRestaurantShiftRequest } from '../domain/ReconciliationReportRestaurantShiftRequest';

interface MutationArgs {
  reportRestaurantShift: ReconciliationReportRestaurantShiftRequest;
  reporter: string;
}

export const useCreateReconciliationReportMutation = (businessId: BusinessId, currency: CurrencyCode) => {
  const queryClient = useQueryClient();
  const reconciliationRepository = new ReconciliationRepositoryHttp();

  return useMutation({
    mutationFn: async ({ reportRestaurantShift, reporter }: MutationArgs): Promise<ReportId> =>
      reconciliationRepository.createReport(businessId, currency, reportRestaurantShift, reporter),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ queryKeys.BUSINESSES, businessId, currency, queryKeys.RECONCILIATION_REPORT ] });
    },
  });
};
