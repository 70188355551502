import React from 'react';
import { TableRow, Typography } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { EmailCell, EmailTable } from './EmailTable';
import { SectionComponent } from './SectionComponent';
import { KpiString } from './KpiString';
import { palette } from '../design/palette';
import { SocialData } from '../domain/TemplatesData';

export const SocialMediaPerformance = ({
  socialData,
  countryCode,
}: {
  socialData: SocialData;
  countryCode: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SectionComponent
        title={`${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.social_media_performance' })}`}
        legend={formatMessage({ id: 'venuekpi.weeklyreport.multi.email.legend' })}
        imagePath={socialData.starImgPath}
        breakBefore
      >
        <EmailTable
          headCellTitle={[
            { title: `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.venue' })}`, width: '40%' },
            { title: `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.google_rating' })}` },
            { title: `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.sunday_rating' })}` },
          ]}
        >
          {socialData.venuesKpis.map((data, index) => (
            <TableRow key={data.name} style={{ breakInside: 'avoid' }}>
              <EmailCell>
                <Typography color={palette.primary.electricPink}>{`${index + 1}`.padStart(2, '0')}</Typography>
                <Typography color={palette.primary.black}>{data.name}</Typography>
              </EmailCell>
              <EmailCell>
                <KpiString
                  value={
                    data.averageRatingGoogleReviews && `${data.averageRatingGoogleReviews} (${data.googleRatingCount})`
                  }
                  fluctuation={data.googleReviewsFluctuation}
                  countryCode={countryCode}
                  color={palette.primary.black}
                />
              </EmailCell>
              <EmailCell>
                <KpiString
                  value={data.averageRatingSunday && `${data.averageRatingSunday} (${data.sundayRatingCount})`}
                  fluctuation={data.ratingSundayFluctuation}
                  countryCode={countryCode}
                  color={palette.primary.black}
                />
              </EmailCell>
            </TableRow>
          ))}
        </EmailTable>
      </SectionComponent>
    </>
  );
};
