import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../stylesheet';
import Line, { AlignItems } from '../../../common/components/Line';
import { useInterval } from '../../../common/utils/useInterval';
import { BoxOrderPacingData } from '../../types';
import { useBoxRepository } from '../../../box/hooks';
import { BoxDetails } from '../../../box/domain/BoxDetails';
import { useGetPacingData } from '../../../venues/hook';

interface Props {
  boxId: string;
}

const PacingDataContainer = styled(Line)`
  margin-top: -1px;
  height: ${getSpacing(7)};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.03em;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${colorPalette.cement};
  border-radius: ${getSpacing(3)};
  padding: 6px ${getSpacing(3)};
  justify-content: space-evenly;
  margin-bottom: ${getSpacing(2)};
`;

const AUTO_REFRESH_TIME = 2000;

const SelectedBoxPacingData: React.FC<Props> = ({ boxId }) => {
  const [pacingData, setPacingData] = useState<BoxOrderPacingData | undefined>(undefined);
  const [boxDetails, setBoxDetails] = useState<BoxDetails | undefined>(undefined);
  const [, getPacingData] = useGetPacingData(boxId);
  const refreshPacingData = useCallback(
    () => getPacingData(boxId).then((data) => {
      setPacingData(data ?? undefined);
    }),
    [boxId, getPacingData, setPacingData],
  );

  const boxRepository = useBoxRepository();

  useEffect(() => {
    refreshPacingData();
  }, [refreshPacingData]);

  useInterval(refreshPacingData, AUTO_REFRESH_TIME);

  useEffect(() => {
    boxRepository.getBoxDetails(boxId).then(setBoxDetails);
  }, [boxId, boxRepository]);

  if (!pacingData || !boxDetails || !boxDetails.pacingEnabled) {
    return null;
  }
  return (
    <PacingDataContainer align={AlignItems.CENTER}>
      <div>
        <FormattedMessage
          id="foodcourt.box.pacing.ordersWaitingData"
          defaultMessage="orders currently waiting: {ordersWaiting}"
          values={{ ordersWaiting: pacingData.nbWaitingOrders }}
        />
      </div>
      <div>
        <FormattedMessage
          id="foodcourt.box.pacing.ordersInPreparationData"
          defaultMessage="orders currently in preparation: {ordersInPreparation} ({pacingInPreparationSlot} slots)"
          values={{
            ordersInPreparation: pacingData.nbInPreparationOrders,
            pacingInPreparationSlot: boxDetails.pacingInPreparationSlots,
          }}
        />
      </div>
    </PacingDataContainer>
  );
};

export default SelectedBoxPacingData;
