import React from 'react';
import RestaurantIcon from '../../assets/restaurant_icon.svg?react';
import ServiceIcon from '../../assets/service_icon.svg?react';
import AmbianceIcon from '../../assets/ambiance_icon.svg?react';
import ValueIcon from '../../assets/value_icon.svg?react';

export const DimensionIcon = ({ dimension }: { dimension: string }) => {
  switch (dimension.toUpperCase()) {
    case 'FOOD_AND_DRINKS':
      return <RestaurantIcon aria-label={dimension} />;
    case 'AMBIANCE':
      return <AmbianceIcon aria-label={dimension} />;
    case 'SERVICE':
      return <ServiceIcon aria-label={dimension} />;
    case 'VALUE_FOR_MONEY':
      return <ValueIcon aria-label={dimension} />;
    default:
      return <RestaurantIcon aria-label={dimension} />;
  }
};
