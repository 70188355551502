/* eslint-disable no-restricted-imports */
import {
  createIntl,
  FormattedDate as ClassicFormattedDate,
  FormattedMessage as ClassicFormattedMessage,
  FormattedTime as ClassicFormattedTime,
  IntlShape as ClassicIntlShape,
  MessageDescriptor as ClassicMessageDescriptor,
  useIntl as classicUseIntl,
} from 'react-intl';
import { LocalisationKey } from 'src/lang/en';
import { FormatXMLElementFn, PrimitiveType } from 'intl-messageformat';
import { Options as IntlMessageFormatOptions } from 'intl-messageformat/src/core';
import { Props } from 'react-intl/src/components/message';
import React from 'react';

type TypedMessageDescriptor = Omit<ClassicMessageDescriptor, 'id'> & {
  id: LocalisationKey,
};

export type IntlShape = Omit<ClassicIntlShape, 'formatMessage'> & {
  formatMessage(
    message: TypedMessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions
  ): string,
};

export function useIntl(): IntlShape {
  const intl = classicUseIntl();

  function formatMessage(
    message: TypedMessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions,
  ): string {
    return intl.formatMessage(message, values, opts);
  }

  return {
    ...intl,
    formatMessage,
  };
}

export type StaticProps = Omit<Props, 'id'> & {
  id: LocalisationKey,
};

export const FormattedMessage: React.ComponentType<StaticProps> = (props: StaticProps) => <ClassicFormattedMessage {...props}  />;

// re-exports of react-intl
export {
  createIntl,
  ClassicFormattedTime as FormattedTime,
  ClassicFormattedDate as FormattedDate,
};

export const unknownKey = '?' as LocalisationKey;
