import axios from 'axios';
import { WifiCredentials } from 'src/domain/venue/WifiCredentials';
import { RestaurantShiftTimes } from 'src/domain/venue/RestaurantShiftTimes';
import { BusinessId } from 'src/business/domain/Business';

export class VenueReferentialRepositoryHttp {
  constructor(private venueReferentialUrl: string) {
  }

  async getWifiCredentials(businessId: BusinessId): Promise<WifiCredentials> {
    const response = await axios.get<WifiCredentials>(
      `${this.venueReferentialUrl}/v2/venues/wifi-credentials/${businessId}`,
    );

    return response.data;
  }

  async updateWifiCredentials(businessId: BusinessId, wifiCredentials: WifiCredentials): Promise<void> {
    await axios.post(
      `${this.venueReferentialUrl}/v2/venues/wifi-credentials/${businessId}`,
      {
        ssid: wifiCredentials.ssid,
        password: wifiCredentials.password,
      },
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );
  }

  async getRestaurantShiftTimes(businessId: BusinessId): Promise<RestaurantShiftTimes> {
    const response = await axios.get<RestaurantShiftTimes>(`${this.venueReferentialUrl}/v2/venues/${businessId}/shifts`);

    return response.data;
  }

  async putShifts(businessId: BusinessId, venueShift: RestaurantShiftTimes): Promise<void> {
    await axios.put(`${this.venueReferentialUrl}/v2/venues/${businessId}/shifts`, venueShift, {
      headers: {
        contentType: 'application/json',
      },
    });
  }
}
