import axios from 'axios';
import { PaymentMethods, PosPaymentMethod, PostPaymentMethods } from 'src/domain/venue/PosPaymentMethods';
import { VposVenueRepository } from 'src/domain/venue/VposVenueRepository';
import { RevenueCenter } from 'src/domain/venue/RevenueCenter';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import { RevenueCenterListDto, RevenueCenterListDtoSchema } from './dto/RevenueCenterDto';

type VposVenuePaymentMethods = {
  sundayCode: string,
  readOnly: boolean,
  preferredValue: string,
  mappedValues: string[],
  allowedValues: AllowedValue[]
};

type AllowedValue = {
  label: string,
  value: string
};

type VposVenuePosPaymentMethods = {
  posPaymentMethods: VposVenuePosPaymentMethod[],
};

type VposVenuePosPaymentMethod = {
  id: string,
  label: string,
};

export class VposVenueRepositoryHttp implements VposVenueRepository {
  constructor(private vposUrl: string) {
  }

  async getPaymentMethods(venueId: string): Promise<PaymentMethods[]> {
    const response = await axios.get<VposVenuePaymentMethods[]>(
      `${this.vposUrl}/payment-methods/${venueId}/mapping`,
    );

    return response.data.map((vposVenueItem: VposVenuePaymentMethods) => ({
      sundayCode: vposVenueItem.sundayCode,
      readOnly: vposVenueItem.readOnly,
      preferredValue: vposVenueItem.preferredValue,
      mappedValues: vposVenueItem.mappedValues,
      allowedValues: vposVenueItem.allowedValues,
    }));
  }

  async postPaymentMethods(posConnectionId: PosConnectionId, posPayments: PostPaymentMethods[]): Promise<void> {
    await axios.post(
      `${this.vposUrl}/payment-methods/${posConnectionId}/mapping`,
      posPayments,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  async getPosPaymentMethods(posConnectionId: PosConnectionId): Promise<PosPaymentMethod[]> {
    const response = await axios.get<VposVenuePosPaymentMethods>(`${this.vposUrl}/payment-methods/${posConnectionId}/pos`);

    return response.data.posPaymentMethods.map((posPaymentMethod) => (
      {
        id: posPaymentMethod.id,
        label: posPaymentMethod.label,
      }
    ));
  }

  async getRevenueCenters(posConnectionId: PosConnectionId): Promise<RevenueCenter[]> {
    const response = await axios.get<unknown>(`${this.vposUrl}/revenue-centers/${posConnectionId}`);
    const list = RevenueCenterListDtoSchema.parse(response.data);
    return list.revenueCenters.map((dto) => ({
      id: dto.externalId,
      label: dto.name,
      isActive: dto.supported,
    }));
  }

  async postRevenueCenters(posConnectionId: PosConnectionId, revenueCenters: RevenueCenter[]): Promise<void> {
    const list: RevenueCenterListDto = {
      revenueCenters: revenueCenters.map((center: RevenueCenter) => ({
        externalId: center.id,
        name: center.label,
        supported: center.isActive,
      })),
    };

    await axios.post(
      `${this.vposUrl}/revenue-centers/${posConnectionId}`,
      list,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
