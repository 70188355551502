import { Stack } from '@mui/material';
import React from 'react';
import Divider from '@mui/material/Divider';
import { badRatingBackground, colorForRating, RatingColor, ratingColors } from '../../../../RatingColor';
import { DimensionIcon } from '../../../../guest-experience/components/DimensionIcon';
import FaceFrown from '../../../../assets/face_frown.svg?react';
import FaceNeutral from '../../../../assets/face_neutral.svg?react';
import FaceSmile from '../../../../assets/face_smile.svg?react';
import FaceSmileWhite from '../../../../assets/face_smile_white.svg?react';
import { comparatorByDimension } from '../../../domain/Dimension';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { DimensionRating } from '../../../domain/DimensionRating';

interface FaceIconProps {
  rating: number;
  ratingColor: RatingColor;
  color?: string;
}

interface DimensionFaceRatingProps {
  rating: number;
  isSelected: boolean;
}

const FaceIcon = ({
  rating,
  ratingColor,
  color,
}: FaceIconProps) => {
  if (rating >= ratingColor.rating) {
    if (rating === 1 || rating === 2) {
      return (
        <FaceFrown key={rating} fill={color} />
      );
    }

    if (rating === 3) {
      return (
        <FaceNeutral key={rating} fill={color} />
      );
    }
    return (
      <FaceSmile key={rating} fill={color} />
    );
  }
  return <FaceSmileWhite key={rating} />;
};

const faceColor = (rating: number, ratingColor: RatingColor, isSelected: boolean) => {
  if (rating < ratingColor.rating) return '#DDDDE4';
  return isSelected ? colorForRating(rating) : '#B4B4BB';
};

const DimensionFaceRating = ({ rating, isSelected }: DimensionFaceRatingProps) => (
  <Stack direction="row">
    {ratingColors.map((ratingColor) => (
      <FaceIcon rating={rating} key={ratingColor.rating} ratingColor={ratingColor}
                color={faceColor(rating, ratingColor, isSelected)} />
    ))}
  </Stack>
);

const DimensionRatingCard = ({ dimensionRating, isSelected }: {
  dimensionRating: DimensionRating,
  isSelected: boolean
}) => (
  <Stack
    direction="row"
    alignItems="center"
    width={'fit-content'}
    gap="0.5rem"
    padding="0.5rem"
    style={{
      background: isSelected ? badRatingBackground : 'none',
      borderRadius: '0.5rem',
    }}
  >
    <DimensionIcon dimension={dimensionRating.dimension} />
    <DimensionFaceRating rating={dimensionRating.rating} isSelected={isSelected} />
  </Stack>
);

export const DimensionRatingCards = ({
  dimensionRatings, highlightedDimension,
}
: {
  dimensionRatings: DimensionRating[],
  highlightedDimension?: string,
}) => {
  const onlySelected = (dimensionRating: DimensionRating) => !isMobileScreen() || dimensionRating.dimension === highlightedDimension;

  return (
    <Stack direction="row" gap="0.5rem" flexWrap="wrap" >
      {comparatorByDimension((d: DimensionRating) => d.dimension)
        .sort(dimensionRatings)
        .filter(onlySelected)
        .map<React.ReactNode>((dimension, index) => (
          <>
            {index !== 0 && <Divider orientation="vertical" flexItem />}
            <DimensionRatingCard
              key={dimension.dimension}
              dimensionRating={dimension}
              isSelected={highlightedDimension === dimension.dimension}
            />
          </>
      ))}
    </Stack>
  );
};
