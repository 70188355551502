import { useContext } from 'react';
import { DependenciesContext } from 'src/tips/dispatch/Dependencies';

export const useDependencies = () => {
  const container = useContext(DependenciesContext);
  if (!container) {
    throw new Error('Please add valid dependencies for the sunday pooling page');
  }
  return container;
};
