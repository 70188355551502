import {
  Button,
  colorUsage,
  InputText,
  Notification as NotificationComponent,
  spaceUsages,
  Text as TextComponent,
} from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AppSettingsForm, ButtonContainer, NotificationContainer } from '../AppSettings.styles';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import {
  useGetPromotionMessage,
  useSavePromotionMessage,
} from 'src/infrastructure/venue/PromotionMessageRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';
import { Card, CardContent, Typography } from '@mui/material';

export type PromotionMessage = {
  message: string;
};

type AppSettingsPromotionInputs = {
  promoMessage: string;
};

interface PromotionHandlerProps {
  businessId: BusinessId;
}

const PromotionHandler = ({ businessId }: PromotionHandlerProps) => {
  const useSnackBar = useSnackbar();
  const intl = useIntl();
  const [appSettingsPromotionUpdateError, setAppSettingsPromotionUpdateError] = useState('');
  const updatePromotionMessage = useSavePromotionMessage();
  const getPromotionMessage = useGetPromotionMessage();

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<AppSettingsPromotionInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const watchPromoMessage = watch('promoMessage');

  useEffect(() => {
    getPromotionMessage(businessId).then((promotionMessage) => {
      reset({
        promoMessage: promotionMessage.message,
      });
    });
  }, []);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));

  const onSubmit = async (inputs: AppSettingsPromotionInputs) => {
    setAppSettingsPromotionUpdateError('');
    try {
      updatePromotionMessage(businessId, inputs.promoMessage).finally();

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setAppSettingsPromotionUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <AppSettingsForm onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            <FormattedMessage id="settings.app_settings.promo.title" />
          </Typography>
          <TextComponent size="small" color={colorUsage.tertiaryText} marginBottom={spaceUsages.largeMedium}>
            <FormattedMessage id="settings.app_settings.promo.description" />
          </TextComponent>
          <InputText
            label={intl.formatMessage({ id: 'settings.app_settings.promo.message' })}
            id="promoMessage"
            placeholder={intl.formatMessage({ id: 'settings.app_settings.promo.message.placeholder' })}
            inputIcon={!watchPromoMessage ? '' : 'check'}
            {...register('promoMessage')}
            marginBottom={spaceUsages.mediumXLarge}
          />
          {appSettingsPromotionUpdateError && (
            <NotificationContainer>
              <NotificationComponent variant="error">{appSettingsPromotionUpdateError}</NotificationComponent>
            </NotificationContainer>
          )}
          {isValid && isDirty && (
            <ButtonContainer>
              <Button type="submit" variant="primary" size="xSmall" disabled={!isValid || isSubmitting || !isDirty}>
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </ButtonContainer>
          )}
        </CardContent>
        </Card>
    </AppSettingsForm>
  );
};

export default PromotionHandler;
