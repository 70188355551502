import { styled } from '@mui/material';

export const AuthenticationPage = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const IllustrationPart = styled('div')`
  display: flex;
  flex-direction: column;
  min-width: 750px;
  width:750px;
  height: 100vh;
  background-color: #FFF5FE;
  background-image: url("/signup-illustration.jpeg");
  background-repeat: no-repeat;
  @media (max-width: 1440px) {
    display: none;
  }
`;

export const AuthenticationPart = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  margin: 1rem;
`;

export const AuthenticationContent = styled('div')`
  display: flex;
  flex-direction: column;
`;


export const SundayLogo = styled('div')`
  width: 218px;
  height: 53px;
  margin: 0;

  @media(min-width:480px) {
    margin-left: 16px;
  }
`;
