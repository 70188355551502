import { useQuery } from '@tanstack/react-query';
import { PosConnectionRepositoryHttp } from '../infrastructure/PosConnectionRepositoryHttp';
import { PosConnection, PosOnboardingStatus } from '../domain/PosConnection';
import { first, sortBy } from 'lodash';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';

const configuration = ConfigurationLoader.load();
const posConnectionRepository = new PosConnectionRepositoryHttp(configuration.vposVenueUrl);

const activeFirstComparison = (p: PosConnection) => {
  switch (p.status) {
    case PosOnboardingStatus.ACTIVE:
      return 1;
    case PosOnboardingStatus.NOT_ONBOARDED:
      return 2;
    case PosOnboardingStatus.SUSPENDED:
      return 3;
    default:
      return 4;
  }
};

export function usePosConnectionsWithSelect<T>(businessId: BusinessId, select: ((connections: PosConnection[]) => T)) {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKeys.pos_connections.all(businessId),
    queryFn: async () => {
      const response = await posConnectionRepository.getPosConnections(businessId);
      return response[businessId] ?? [];
    },
    select: select,
  });
}

export function usePosConnections(businessId: BusinessId) {
  return usePosConnectionsWithSelect(businessId, (data) => data);
}

export const useGetFirstActivePosConnectionForBusiness = (businessId: BusinessId) =>
  usePosConnectionsWithSelect(businessId, (data) => first(sortBy(data, activeFirstComparison)));
