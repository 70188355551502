import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { Shift } from '../domain/Shift';
import { ShiftHour } from '../domain/ShiftHour';
import { BusinessId } from 'src/business/domain/Business';

export class SaveAccountingShiftUseCase {
  constructor(private accountingRepository: AccountingRepository) {
  }

  async saveAccountingDateRange(businessId: BusinessId, lunchStart: string, lunchEnd: string, dinnerStart: string, dinnerEnd: string): Promise<void> {
    const existingShifts: Shift[] = await this.accountingRepository.getShiftsByVenue(businessId);
    const shifts: Shift[] = [
      new Shift(businessId, ShiftHour.fromHourAndMinute(lunchStart), ShiftHour.fromHourAndMinute(lunchEnd), 'LUNCH',
        existingShifts.find((shift) => shift.shiftType === 'LUNCH')?.id),
      new Shift(businessId, ShiftHour.fromHourAndMinute(dinnerStart), ShiftHour.fromHourAndMinute(dinnerEnd), 'DINNER',
        existingShifts.find((shift) => shift.shiftType === 'DINNER')?.id),
    ];
    await this.accountingRepository.saveShiftsByVenue(businessId, shifts);
  }
}
