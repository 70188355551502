import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { getCheckoutOnboardingInformation } from 'src/payments/infrastructure/getCheckoutOnboardingInformation';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const useGetCheckoutOnboardingInformation = ({ businessId }: {
  businessId: string,
}) => {
  const intl = useIntl();
  return useQuery({
    queryKey: [queryKeys.enrollmentSettings.payments.GET_CHECKOUT_ONBOARDING_INFORMATION, businessId, intl],
    //TODO: inject the function
    queryFn: () => getCheckoutOnboardingInformation(intl)(businessId),
    staleTime: 0,
    gcTime: 0,
    refetchOnMount: true,
  });
};
