import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { Box } from '../../../box/domain/Box';
import { BoxCreationSidePanel } from './BoxCreationSidePanel';
import { usePushNotification } from '../../../common/components/Notifications.hook';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type BoxCreationProps = {
  enrollmentId: EnrollmentId;
  onBoxCreated: (box: Box) => void;
};

export const BoxCreation = ({ enrollmentId, onBoxCreated }: BoxCreationProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [isPanelOpen, updatePanelOpening] = useState<boolean>(false);

  const onBoxCreation = () => {
    updatePanelOpening(true);
  };

  const onNewBoxCreation = (box: Box) => {
    updatePanelOpening(false);
    onBoxCreated(box);
    pushNotification(intl.formatMessage({ id: 'foodcourt.box.created', defaultMessage: 'new box created' }));
  };

  return (
    <>
      <Button variant="primary" size="medium" onClick={onBoxCreation}>
        <FormattedMessage id="foodcourt.box.create-btn" defaultMessage="create a new box" />
      </Button>

      <BoxCreationSidePanel
        enrollmentId={enrollmentId}
        isOpened={isPanelOpen}
        onClosed={() => updatePanelOpening(false)}
        onBoxCreated={onNewBoxCreation}
      />
    </>
  );
};
