import axios from 'axios';
import { Configuration } from '../configuration/Configuration';
import { TableDeviceRepositoryHttp } from '../floorplan/infrastructure/TableDeviceRepositoryHttp';
import { Till } from './domain/Till';
import { BillRefresher } from './domain/BillRefresher';
import { VPOSBillRefresher } from './infrastructure/VPOSBillRefresher';
import { init } from './infrastructure/PATOrchestratorTill';
import { TableDeviceRepository } from '../floorplan/domain/TableDeviceRepository';

export interface BillEffects {
  till: Till;
  tableDevice: TableDeviceRepository;
  billRefresher: BillRefresher;
}

export const initEffects = (configuration: Configuration): BillEffects => ({
  till: init(configuration.patOrchestratorUrl),
  tableDevice: new TableDeviceRepositoryHttp(configuration.sundayDeviceManagerUrl),
  billRefresher: new VPOSBillRefresher(axios, configuration.vposRefreshBillApiBaseUrl),
});
