import { z } from 'zod';
import { PaymentData } from './PaymentData';
import { PaymentOrigin } from './PaymentOrigin';

export enum MethodType {
  CreditCard = 'CARD',
  Cash = 'CASH',
  Sunday = 'SUNDAY',
  Other = 'OTHER',
}

export enum FilterType {
  AmericanExpress = 'AMERICAN_EXPRESS',
  MealVoucher = 'MEAL_VOUCHER',
  Other = 'OTHER',
}

export const Method = z.object({
  name: z.string().optional(),
  type: z.nativeEnum(MethodType).catch(MethodType.Other),
  isBreakdown: z.boolean(),
});

export const Reconciliation = z.object({
  method: Method,
  onPos: PaymentData.optional(),
  reported: PaymentData.optional(),
  isHeader: z.boolean().optional(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Method = z.infer<typeof Method>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Reconciliation = z.infer<typeof Reconciliation>;

export const selectAmountByOrigin = (reconciliation?: Reconciliation, origin?: PaymentOrigin) =>
  ((origin === PaymentOrigin.OnPos) ? reconciliation?.onPos : reconciliation?.reported);
