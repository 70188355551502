export type PlatformFilter = 'GOOGLE' | 'SUNDAY' | 'ALL';

export const toPlatformFilter = (queryParams: string) => {
  const givenPlatform = new URLSearchParams(queryParams).get('platform');
  if (givenPlatform === 'ALL') return 'ALL';
  if (givenPlatform === 'SUNDAY') return 'SUNDAY';
  if (givenPlatform === 'GOOGLE') return 'GOOGLE';
  return 'ALL';
};
export const toTimeRangeFilter = (queryParams: string): DateFilter => {
  const givenTimeRange = new URLSearchParams(queryParams).get('timerange');
  console.log(givenTimeRange);
  if (givenTimeRange === 'LAST_7_DAYS') return 'LAST_7_DAYS';
  if (givenTimeRange === 'PREVIOUS_7_DAYS') return 'PREVIOUS_7_DAYS';
  if (givenTimeRange === 'LAST_30_DAYS') return 'LAST_30_DAYS';
  if (givenTimeRange === 'TODAY') return 'TODAY';
  if (givenTimeRange === 'YESTERDAY') return 'YESTERDAY';
  return 'LAST_7_DAYS';
};

export const toServerFilter = (queryParams: string, allWaiters: string): string => {
  const server = new URLSearchParams(queryParams).get('server');
  if (server != null) return server;
  return allWaiters;
};
export const toRatingFilter = (queryParams: string): string => {
  const rating = new URLSearchParams(queryParams).get('rating');
  if (rating === 'BAD') return 'BAD';
  if (rating === 'TOP') return 'TOP';
  return 'ALL';
};

export const toDimensionFilter = (queryParams: string) => {
  const givenDimension = new URLSearchParams(queryParams).get('dimension');
  if (givenDimension !== null) return givenDimension;
  return 'ALL_DIMENSION';
};

export type DateFilter = 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'PREVIOUS_7_DAYS' | 'TODAY' | 'YESTERDAY' | string;
