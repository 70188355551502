import { BusinessId } from 'src/business/domain/Business';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { GuestDataCollection } from '../domain/GuestDataCollection';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ConsentRepositoryHttp } from '../infrastructure/ConsentRepositoryHttp';
import { queryKeys } from 'src/app/queries/utils';

const guestDataCollectionQueryFunction = (businessId: BusinessId) => async (): Promise<GuestDataCollection> => {
  const configuration = ConfigurationLoader.load();
  const repository = new ConsentRepositoryHttp(configuration.consentManagerBaseUrl);
  return repository.getGuestDataCollectionStatus(businessId);
};

export const guestDataCollectionQuery = (businessId: BusinessId) => ({
  queryKey: [queryKeys.GUEST_DATA_COLLECTION, businessId],
  queryFn: guestDataCollectionQueryFunction(businessId),
});

export const useGuestDataCollectionQuery = (
  businessId: BusinessId,
  options?: Omit<UseQueryOptions<GuestDataCollection>, 'queryKey' | 'queryFn' | 'initialData'>,
) => {
  const query = guestDataCollectionQuery(businessId);
  return useQuery<GuestDataCollection>({ queryKey: query.queryKey, queryFn: query.queryFn, ...options });
};
