import React from 'react';
import styled from 'styled-components';
import { DownArrowIcon, UpArrowIcon } from '../Icons';

type ExpandablePanelButtonProps = {
  expanded: boolean;
  expand: (expanded: boolean) => void;
};

export const ExpandDownArrowIcon = styled(DownArrowIcon)`
  cursor: pointer;
`;

export const ExpandUpArrowIcon = styled(UpArrowIcon)`
  cursor: pointer;
`;

export const ExpandablePanelButton = ({ expanded, expand }: ExpandablePanelButtonProps) => {
  if (expanded) {
    return <ExpandUpArrowIcon onClick={() => expand(false)} />;
  }
  return <ExpandDownArrowIcon onClick={() => expand(true)} />;
};
