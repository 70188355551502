import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Controller, useFormContext } from 'react-hook-form';
import { TipsSuggestion } from 'src/pages/Settings/AppSettings/tips/domain/TipsSuggestion';
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Select,
  Stack,
  Switch,
  Typography,
  useTheme,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import MenuItem from '@mui/material/MenuItem';
import React, { ReactNode } from 'react';
import PaymentTerminalSvg from 'src/app/component/icons/PaymentTerminal';
import QrCodesSvg from 'src/app/component/icons/QrCodesSvg';
import { LocalisationKey } from 'src/lang/en';

export const SettingLine = ({
  labelsKeys,
  children: setting,
}: {
  labelsKeys: { mainLabel: LocalisationKey | ReactNode; subLabel?: LocalisationKey };
  children: ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Grid item xs={8} xl={5}>
        <Stack spacing={0.5}>
          {typeof labelsKeys.mainLabel === 'string' ? (
            <Typography>
              <FormattedMessage id={labelsKeys.mainLabel as LocalisationKey} />
            </Typography>
          ) : (
            labelsKeys.mainLabel
          )}
          {labelsKeys.subLabel && (
            <Typography variant={'subtitle2'} color={theme.palette.text.secondary}>
              <FormattedMessage id={labelsKeys.subLabel} />
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid item xs={3} xl={7} alignItems={'flex-start'}>
        <Stack alignItems={'flex-end'}>{setting}</Stack>
      </Grid>
    </Grid>
  );
};
export const TipsGeneralConfiguration = () => {
  const business = useCurrentBusinessOrThrow();
  const { control, watch } = useFormContext<TipsSuggestion>();

  const canChooseTipsCalculationBeforeOrAfterTax = ['US', 'CA'].includes(business.address.countryCode);
  return (
    <Stack spacing={3}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.app_settings.tips.general_configuration.title'} />
      </Typography>
      <Divider />
      <SettingLine
        labelsKeys={{
          mainLabel: 'settings.app_settings.tips.allow_tips.main_label',
          subLabel: 'settings.app_settings.tips.allow_tips.sub_label',
        }}
      >
        <Controller
          name={'tippingEnabled'}
          render={({ field }) => <Switch checked={field.value} {...field} />}
          control={control}
        />
      </SettingLine>
      {watch('tippingEnabled') && (
        <>
          {canChooseTipsCalculationBeforeOrAfterTax && (
            <>
              <Divider />
              <SettingLine
                labelsKeys={{
                  mainLabel: 'settings.app_settings.tips.tips_calculation.main_label',
                  subLabel: 'settings.app_settings.tips.tips_calculation.sub_label',
                }}
              >
                <Stack alignItems={'flex-end'}>
                  <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Tips calculation</InputLabel>
                    <Controller
                      name="tipsCalculationStrategy"
                      control={control}
                      render={({ field }) => (
                        <Select {...field} label="Tips calculation">
                          <MenuItem value={'PRE_TAX'}>
                            <FormattedMessage id={'settings.app_settings.tips.calculation_strategy.pre_tax'} />
                          </MenuItem>
                          <MenuItem value={'POST_TAX'}>
                            <FormattedMessage id={'settings.app_settings.tips.calculation_strategy.post_tax'} />
                          </MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Stack>
              </SettingLine>
            </>
          )}

          <Divider />
          <SettingLine
            labelsKeys={{
              mainLabel: 'settings.app_settings.tips.enforce_account.main_label',
              subLabel: 'settings.app_settings.tips.enforce_account.sub_label',
            }}
          >
            <Stack alignItems={'flex-end'}>
              <Controller
                name={'enforceAccountForTipsForPat'}
                render={({ field }) => (
                  <FormControlLabel
                    labelPlacement={'start'}
                    control={<Checkbox checked={field.value} {...field} />}
                    label={
                      <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <QrCodesSvg />
                        <Typography>
                          <FormattedMessage id={'settings.app_settings.tips.enforce_account.pat_product_name'} />
                        </Typography>
                      </Stack>
                    }
                  />
                )}
                control={control}
              />
              <Controller
                name={'enforceAccountForTipsForPdq'}
                render={({ field }) => (
                  <FormControlLabel
                    labelPlacement={'start'}
                    control={<Checkbox checked={field.value} {...field} />}
                    label={
                      <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <PaymentTerminalSvg />
                        <Typography>
                          <FormattedMessage id={'settings.app_settings.tips.enforce_account.pdq_product_name'} />
                        </Typography>
                      </Stack>
                    }
                  />
                )}
                control={control}
              />
            </Stack>
          </SettingLine>

          {(watch('enforceAccountForTipsForPdq') || watch('enforceAccountForTipsForPat')) && (
            <Alert severity="warning">
              <Stack justifyContent={'space-between'} direction={'row'}>
                <Stack>
                  <Typography fontWeight={'bold'}>
                    <FormattedMessage id={'settings.app_settings.tips.enforce_account.main_text'} />
                  </Typography>
                  <Typography>
                    <FormattedMessage id={'settings.app_settings.tips.enforce_account.sub_text'} />
                  </Typography>
                </Stack>
              </Stack>
            </Alert>
          )}
        </>
      )}
    </Stack>
  );
};
