import { useIntl } from 'src/app/i18n/TypedIntl';
import { sumMoneys, useRenderMoney } from '@sundayapp/web-money';
import React from 'react';
import { VenueKpi } from '../../domain/VenueKpi';
import { HomeCard } from '../HomeCard';
import { computeEvolutionPercentage } from '../HomeTools';
import { RevenueCta } from './RevenueCta';
import { PdqAds } from './PdqAds';
import { Business, isPaymentTerminalAvailable } from 'src/business/domain/Business';
import { shouldHidePdqAdsForMocks } from 'src/mocks/shouldMockDataForBusiness';

type HomeRevenueCardProps = {
  business: Business;
  venueKpi: VenueKpi;
};

export const HomeRevenueCard = ({ business, venueKpi }: HomeRevenueCardProps) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, true);

  const hasPaymentTerminal = isPaymentTerminalAvailable(business);

  const {
    totalRevenue, totalRevenueLastWeek, totalAmountPaymentTerminal, totalAmountPaymentTerminalLastWeek, adoptionRate,
  } = venueKpi;

  const totalSundayRevenue = sumMoneys(totalRevenue, totalAmountPaymentTerminal);
  const totalSundayRevenueLastWeek = sumMoneys(totalRevenueLastWeek, totalAmountPaymentTerminalLastWeek);

  const revenue = renderMoney(totalSundayRevenue);
  const revenueTrend = computeEvolutionPercentage(totalSundayRevenue.amount, totalSundayRevenueLastWeek.amount);

  const qrCodeRevenue = renderMoney(totalRevenue);
  const pdqRevenue = renderMoney(totalAmountPaymentTerminal);

  const subItems = [];
  if (hasPaymentTerminal) {
    subItems.push({
      label: intl.formatMessage({ id: 'venue.home.revenue.qrcode' }),
      value: qrCodeRevenue,
    });
    subItems.push({
      label: intl.formatMessage({ id: 'venue.home.revenue.terminal' }),
      value: pdqRevenue,
    });
  } else if (adoptionRate !== undefined) {
    subItems.push({
      label: intl.formatMessage({ id: 'venue.home.revenue.adoption' }),
      value: `${adoptionRate.toFixed(2)}%`,
    });
  }

  return (
    <HomeCard
      title={intl.formatMessage({ id: 'venue.home.revenue.title' })}
      value={revenue}
      trend={revenueTrend}
      isPercentageTrend
      subItems={subItems}
      cta={(
        <>
          {(!hasPaymentTerminal && !shouldHidePdqAdsForMocks(business.id)) && <PdqAds />}
          <RevenueCta business={business} />
        </>
      )}
    />
  );
};
