import { Button, Stack } from '@mui/material';
import { useState } from 'react';
import { Business } from 'src/business/domain/Business';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { displayMenuResume, MenuReplicationState, SelectMenuMigration } from 'src/enterprise/configuration-replication/domain/menu/MenuReplicationState';
import { SelectMenuSourceStep } from './SelectMenuSourceStep';
import { MenuSummary } from 'src/menu/dynamic-menu/domain/MenuSummary';
import { SelectMenuSelectionStep } from './SelectMenuSelectionStep';
import { SelectMenuTargetStep } from './SelectMenuTargetStep';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { MenuMigrationHeader } from 'src/enterprise/configuration-replication/components/menu/MenuMigrationHeader';

export const SelectMenuMigrationStep = ({
  currentState,
  onNext,
}: {
  currentState: SelectMenuMigration;
  onNext: (state: MenuReplicationState) => void;
}) => {
  const currentBusiness = useCurrentBusinessOrThrow();
  const intl = useIntl();

  const [origin, setOrigin] = useState<Business>(currentState.origin ? currentState.origin : currentBusiness);
  const [menus, setMenus] = useState<MenuSummary[]>(currentState.menus ? currentState.menus : []);
  const [targets, setTargets] = useState<Business[]>(currentState.targets ? currentState.targets : []);

  const setSelectedOrigin = (selectedOrigin: Business) => {
    setOrigin(selectedOrigin);
    setMenus([]);
    setTargets([]);
  };
  const updateSelectedMenus = (menu: MenuSummary, checked: boolean) => {
    if (checked) {
      setMenus([...menus, menu]);
    } else {
      setMenus(menus.filter((selectedMenu) => selectedMenu !== menu));
    }
  };

  const next = () => {
    onNext(displayMenuResume(origin, menus, targets));
  };

  return (
    <Stack padding="1.5rem" gap="1rem">
      <MenuMigrationHeader
        details={intl.formatMessage({ id: 'configuration_replication.menu.selection_description' })}
        infoMessage={intl.formatMessage({ id: 'configuration_replication.menu.info' })}
      />

      <SelectMenuSourceStep selectedBusiness={origin} onSelectBusiness={setSelectedOrigin} />

      <SelectMenuSelectionStep selectedBusiness={origin} selectedMenus={menus} onSelectMenu={updateSelectedMenus} />

      {menus.length !== 0 &&
        <SelectMenuTargetStep selectedBusiness={origin} selectedBusinesses={targets} onSelectBusinesses={setTargets} />
      }

      <Stack direction="row-reverse">
        <Button variant="contained" onClick={next} disabled={menus.length === 0 || targets.length === 0}>
          <FormattedMessage id="configuration_replication.select_source.next" />
        </Button>
      </Stack>
    </Stack>
  );
};
