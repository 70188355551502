import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { MoneyView } from '@sundayapp/web-money';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { RevenueTableCellHead } from './RevenueTableCellHead';
import { revenueSources, revenueSourceTranslations, revenueSourceTypes } from '../../../domain/RevenueSource';
import { translation } from '../../../../common/translation';
import { RevenueRow } from './RevenueRow';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';
import { Revenue } from '../../../domain/RevenueSummary';

export const buildColGroups = (isBusinessWithServiceCharge: boolean) => {
  const columnsNb = isBusinessWithServiceCharge ? 5 : 4;
  return <colgroup>
    {[...Array(columnsNb).keys()].map((_, i) => (<col width={`${100 / columnsNb}%`} key={i} />))};
  </colgroup>;
};

export const RevenueRowGroup = ({
  revenue,
  period,
  isBusinessWithServiceCharge,
  intl,
  theme,
}: {
  revenue: Revenue, period: RevenuePeriod, isBusinessWithServiceCharge: boolean; intl: ReturnType<typeof useIntl>; theme: Theme
}) => {

  const doNotBreakText = { whiteSpace: 'nowrap' };

  const salesWithoutServiceCharge = {
    amount: revenue.totalSalesWithSundayFees.amount - revenue.totalCharges.serviceCharge.amount,
    currency: revenue.totalSalesWithSundayFees.currency,
  };

  const isPaymentTerminal = revenue.source === revenueSources.PAYMENT_TERMINAL;

  return (
    <TableContainer id={revenue.source} component={Paper} sx={{
      borderRadius: '16px',
      '&:first-child': {
        borderRadius: '0 0 16px 16px',
      },
    }}>
      <Table
        aria-label="collapsible table"
        size="medium"
      >
        {buildColGroups(isBusinessWithServiceCharge)}
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography
                variant="h6"
                color={theme.palette.text.primary}
                sx={doNotBreakText}
              >
                <FormattedMessage id={revenueSourceTranslations[revenue.source].id} />
              </Typography>
            </TableCell>
            <RevenueTableCellHead
              name={intl.formatMessage(translation('accounting.revenue_tab.table.header.total'))}
            >
              <Typography variant="h6" color={theme.palette.text.primary}>
                <MoneyView
                  value={revenue.totalSalesAndTips}
                />
              </Typography>
            </RevenueTableCellHead>
            <RevenueTableCellHead
              name={intl.formatMessage(translation('accounting.revenue_tab.table.header.sales'))}
            >
              <MoneyView
                value={isBusinessWithServiceCharge ? salesWithoutServiceCharge : revenue.totalSalesWithSundayFees}
              />
            </RevenueTableCellHead>
            <RevenueTableCellHead
              name={intl.formatMessage(translation('accounting.revenue_tab.table.header.tips'))}
            >
              <MoneyView
                value={revenue.totalTips}
              />
            </RevenueTableCellHead>
            {
              isBusinessWithServiceCharge && (
                <RevenueTableCellHead
                  name={intl.formatMessage(translation('accounting.revenue_tab.table.header.autogratuity'))}
                >
                  {isPaymentTerminal ? '-' : <MoneyView value={revenue.totalCharges.serviceCharge} />}
                </RevenueTableCellHead>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {revenue.revenuesDetails
            .filter(revenueDetails => !isBusinessWithServiceCharge || revenueDetails.source != revenueSourceTypes.MEAL_VOUCHERS)
            .map((revenueDetails) => (
              <RevenueRow
                key={`${revenueDetails.source}_${revenue.source}`}
                row={revenueDetails}
                source={revenue.source}
                period={period}
                isBusinessWithServiceCharge={isBusinessWithServiceCharge}
                theme={theme}
              />
            ))}
        </TableBody>

      </Table>
    </TableContainer>
  );
};
