import { RowProps } from './RowProps';
import { MoneyView } from '@sundayapp/web-money';
import React from 'react';
import { products } from '../model/products';

export const ServiceCharge = ({
  revenue,
}: { revenue: RowProps; }) => {

  if (revenue.product === products.PDQ) return '-';
  return <MoneyView value={revenue.serviceCharges} />;
};
