import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { Menu } from '../../domain/Menu';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_MENU_BY_ID = 'menu_menu_by_id';
const buildQueryKey = (businessId: BusinessId, menuId: string) =>
  [QUERYKEY_MENU_MENU_BY_ID, businessId, menuId] as const;
export const getMenuByIdQuery = async ({
  queryKey: [, , menuId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Menu> => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return menuRepository.findByMenuId(menuId);
};

export const useGetMenuById = (businessId: BusinessId, menuId: string) =>
  useQuery({
    queryKey: buildQueryKey(businessId, menuId),
    queryFn: getMenuByIdQuery,
  });
