import { Box, Paper } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const NoInvoices: React.FC = () => (
  <Paper sx={{ borderRadius: '0.8rem' }}>
    <Box p={3}>
      <FormattedMessage id="accounting.invoice_tab.no_invoice" />
    </Box>
  </Paper>
);
