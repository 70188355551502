import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const TradingName = ({ representativeIndex }: { representativeIndex: number }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.controllingCompanies?.[representativeIndex]?.tradingName;
  const intl = useIntl();
  return (
    <TextField
      fullWidth
      required
      error={!!error}
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.tradingName' })}
      InputLabelProps={{ shrink: true }}
      {...register(`controllingCompanies.${representativeIndex}.tradingName`, {
        required: intl.formatMessage({
          id: 'settings.payments.checkout.onboarding.controllingCompanies.tradingName.error',
        }),
      })}
      helperText={!!error ? error.message : ''}
    />
  );
};
