import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { MenuScheduleTranslationUtils } from './MenuScheduleTranslationUtils';
import { GroupedTimeRanges } from '../../components/SchedulePanel/GroupedTimeRanges';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { IntlShape } from 'src/app/i18n/TypedIntl';

const DateLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DayItem = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.defaultTextColor};
`;

export const HourItem = styled.div`
  font-size: 14px;
  line-height: 16px;
  padding-left: ${getSpacing(1)};
  color: ${colorPalette.grey500};
`;

export const groupedConstraintToReactNode = (
  groupedConstraints: GroupedTimeRanges,
  translation: IntlShape,
): ReactNode[] => {
  const sortedHours = MenuScheduleTranslationUtils.orderRanges(groupedConstraints.ranges);
  const hours = MenuScheduleTranslationUtils.translateHours(sortedHours, translation);
  return MenuScheduleTranslationUtils.translateDays(groupedConstraints, translation).map((day) => (
    <DateLine key={day}>
      <DayItem>
        $
        {day}
      </DayItem>
      <HourItem>
        $
        {hours}
      </HourItem>
    </DateLine>
  ));
};
