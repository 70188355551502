import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material';
import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Review } from '../browse/domain/Review';
import { ellipsis } from './ellipsis';
import { Author } from './Author';
import { ReviewStarRating } from '../browse/allReviews/components/reviewCard/ReviewStarRating';
import { PlatformLogo } from '../browse/allReviews/components/reviewCard/PlatformLogo';

dayjs.extend(localeData);
dayjs.extend(relativeTime);

const LogoAndStars = (props: { review: Review, rating: number }) => (
  <Stack spacing={1} direction="row">
    <ReviewStarRating rating={props.rating} />
    <PlatformLogo review={props.review} />
  </Stack>
);

const FeedbackPreview = (props: { feedback: string }) => (
  <Typography color="textSecondary">
    {ellipsis(props.feedback, 70)}
  </Typography>
);

const baseStyle = {
  cursor: 'pointer',
  boxShadow: 'none',
};

const selectedStyle = () => ({
  ...baseStyle,
  backgroundColor: '#EDEDF2',
  border: '0px',
});

const notSelectedStyle = () => ({
  ...baseStyle,
  '&:hover': {
    backgroundColor: '#EDEDF2',
  },
});

const RelativeDate = ({ date } : { date: number }) => {
  const intl = useIntl();
  return <Typography>{dayjs(date).locale(intl.locale).fromNow()}</Typography>;
};

export const ReviewPreviewCard = ({ review, onSelect, selected }: { review: Review, onSelect: (review: Review) => void, selected: boolean }) => (
  <Card sx={selected ? selectedStyle : notSelectedStyle} onClick={() => onSelect(review)}>
    <CardHeader
      avatar={<LogoAndStars review={review} rating={review.rating} />}
      action={<RelativeDate date={review.creationDate} />}
    />
    <CardContent>
      <Author review={review} />
      <FeedbackPreview feedback={review.feedback} />
    </CardContent>
  </Card>
);
