import * as React from 'react';
import { useEffect } from 'react';
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import { Tag } from '../../../domain/Tag';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { Product } from '../../../domain/Product';
import { BusinessId } from 'src/business/domain/Business';
import { useGetTagsByBusiness } from '../../../queries/product/getTagsByBusinessQuery';

type Props = {
  businessId: BusinessId,
  product: Product,
  productUpdateDto: UpdateProductDto,
  setProductUpdateDto: (productUpdate: UpdateProductDto) => void,
};

const buildNewTagList = (availableTags: Tag[], values: readonly string[]) => values.map((tagName) => {
  const existingTag = availableTags.find((t) => t.name.toLowerCase().trim() === tagName.toLowerCase().trim());
  if (existingTag) {
    return existingTag;
  }
  return {
    id: uuidv4().toString(),
    name: tagName,
  };
});

const checkChangesAndMarkAsChanged = (newSelectedTags: Tag[], previouslySelectedTags: Tag[]) => {
  if (newSelectedTags.length !== previouslySelectedTags.length) {
    return true;
  }

  newSelectedTags.forEach((newTag) => {
    if (!previouslySelectedTags.find((t) => t.id === newTag.id)) {
      return true;
    }
  });

  return false;
};

export const ProductTagsInput = ({
  product, businessId, productUpdateDto, setProductUpdateDto,
}: Props) => {
  const [tags, setTags] = React.useState<Tag[]>(product.tags);

  useEffect(() => {
    setTags(product.tags);
  }, [product, product.tags]);

  const {
    data: existingTags,
    isLoading: tagsLoading,
  } = useGetTagsByBusiness(businessId);

  function updateSelectedTags(values: readonly string[]) {
    const newTags = buildNewTagList(existingTags ?? [], values);
    setTags(newTags);

    const hasChanged = checkChangesAndMarkAsChanged(newTags, tags);
    if (hasChanged) {
      setProductUpdateDto({
        ...productUpdateDto,
        tags: newTags,
      });
    } else {
      setProductUpdateDto({
        ...productUpdateDto,
        tags: undefined,
      });
    }
  }

  if (tagsLoading) return <CircularProgress />;
  return (
    <>
      <Autocomplete
        sx={{ backgroundColor: red }}
        multiple
        freeSolo
        options={existingTags ? existingTags.map((t) => t.name) : []}
        value={tags.map((t) => t.name)}
        defaultValue={tags.map((t) => t.name)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip variant="outlined" color="default" label={option} {...getTagProps({ index })} />
          ))}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Tags"
            placeholder=""
          />
        )}
        onChange={(event, value) => {
          updateSelectedTags(value);
        }}
      />
    </>
  );
};
