import { Stack } from '@mui/material';
import { AccountNumber } from 'src/payments/components/Checkout/CheckoutOnboarding/BankAccount/AccountNumber';
import React from 'react';
import { RoutingNumber } from 'src/payments/components/Checkout/CheckoutOnboarding/BankAccount/RoutingNumber';

export const UsBankInfo = () => (
  <Stack direction="column" spacing={3}>
    <AccountNumber country="US" />
    <RoutingNumber />
  </Stack>
);
