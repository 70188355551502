// eslint-disable-next-line @typescript-eslint/no-redeclare
// eslint-disable-next-line @typescript-eslint/no-redeclare
import * as React from 'react';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Product, productTypes } from '../../../domain/Product';
import { useGetProductsById } from '../../../queries/product/getProductByIdQuery';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { useProductMutation } from '../../../mutations/product/useProductMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BusinessId } from 'src/business/domain/Business';
import { ProductLinksBox } from 'src/menu/dynamic-menu/components/products/ProductLinksBox';
import { EditProductsSelectionButton } from 'src/menu/dynamic-menu/components/products/EditProductsSelectionButton';
import { ProductSummary } from 'src/menu/dynamic-menu/domain/ProductSummary';

const HeaderActions = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

type Props = {
  businessId: BusinessId;
  product: Product;
};

export const RelatedProductPanel = ({ businessId, product }: Props) => {
  const intl = useIntl();
  const snackBar = useSnackbar();

  const [chooseProductModalVisible, setChooseProductModalVisible] = useState(false);
  const updateProduct = useProductMutation(businessId, product.id);

  const relatedProductQueries = useGetProductsById(businessId, product.relatedProductIds ?? []);
  const areRelatedProductsLoading = relatedProductQueries.map(({ isLoading }) => isLoading)
    .reduce((loading, current) => loading || current, false);
  const relatedProducts = relatedProductQueries
    .map(({ data }) => data)
    .filter((p): p is Product => !!p);


  async function updateRelatedProducts(newProductIds: string[]) {
    const productUpdate: UpdateProductDto = {
      relatedProductIds: newProductIds,
    };
    await updateProduct.mutateAsync(productUpdate);
  }

  const onProductSelectionFinished = async (newProductIds: string[]) => {
    try {
      await updateRelatedProducts(newProductIds);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    } finally {
      setChooseProductModalVisible(false);
    }
  };


  const filterOnlySimpleProductSelectable = (p: ProductSummary) => p.type === productTypes.PRODUCT;

  return <ProductLinksBox
    slots={{
      header: <>
        <Typography fontSize="large">
          <FormattedMessage id="menus.product.related_products.header" defaultMessage="Related products" />
        </Typography>
        <HeaderActions>
          <HeaderActions>
            <EditProductsSelectionButton onClick={() => setChooseProductModalVisible(true)} />
          </HeaderActions>
        </HeaderActions>
      </>,
      emptyListState: <FormattedMessage id="menus.product.related_products.no_related_products" />,
    }}
    filterSelectableProducts={filterOnlySimpleProductSelectable}
    products={relatedProducts}
    isLoading={areRelatedProductsLoading}
    businessId={businessId}
    onProductsSorted={onProductSelectionFinished}
    onProductSelected={onProductSelectionFinished}
    isSelectionVisible={chooseProductModalVisible}
    setIsSelectionVisible={setChooseProductModalVisible}
  />;
};
