import * as React from 'react';
import styled from 'styled-components';
import { Stack } from '@mui/material';

type ImageContainerProps = {
  backgroundColor: string;
};
export const ImageContainer = styled.div<ImageContainerProps>`
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: flex-start;
`;

export const DownloadStep = () => (
  <ImageContainer backgroundColor="#EBEDFB">
    <img src="/img/sunday-for-restaurants/download.png" alt="Download image" height={150} />
    <Stack justifyContent={'center'}>
      <img src="/img/sunday-for-restaurants/app-store.svg" alt="App store image" />
      <img src="/img/sunday-for-restaurants/google-play.svg" alt="Google-play image" />
    </Stack>
  </ImageContainer>
);
