import { Typography } from '@mui/material';
import React from 'react';

export const ReviewDate = (props: { creationDate: number }) => {
  const date = new Date(props.creationDate);
  return (
      <Typography>{date.toLocaleString(navigator.language)}</Typography>
  );

};
