import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Table } from '../domain/Table';

export type FloorPlanState = Readonly<{
  floorPlan?: Table[]
}>;

const initialState: FloorPlanState = { floorPlan: [] };

const slice = createSlice({
  name: 'floorPlan',
  initialState,
  reducers: {
    setFloorPlan: (state, action: PayloadAction<Table[] | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.floorPlan = action.payload;
    },
  },
});

export const { setFloorPlan } = slice.actions;
export default slice.reducer;
