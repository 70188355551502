import React from 'react';
import { styled } from '@mui/material/styles';
import { HomeCtaButton } from 'src/home/components/HomeCtaButton';
import MessageAlertSvg from '../../../../app/component/icons/MessageAlertSvg';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Typography, useTheme } from '@mui/material';

type DimensionCtaButtonProps = {
  label: string;
  value: number;
  onClick: () => void;
  disabled: boolean;
};

const Container = styled('div')(
  ({ theme }) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding: 8px 16px 8px 16px;
  justify-content: space-between;
  background-color: ${theme.palette.background.default};
  border-radius: 8px;
`,
);

const LeftPart = styled('div')`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const DimensionCtaButton = ({ label, value, onClick, disabled }: DimensionCtaButtonProps) => {
  const intl = useIntl();
  const theme = useTheme();
  if (disabled) {
    return (
      <Container>
        <LeftPart>
          {<MessageAlertSvg size={24} color={theme.palette.text.secondary} />}
          <Typography color={theme.palette.text.disabled}>
            {intl.formatMessage({ id: 'review.guest_experience.dimension.no_issue' })}
          </Typography>
        </LeftPart>
      </Container>
    );
  }
  return (
    <HomeCtaButton
      icon={<MessageAlertSvg size={24} color={theme.palette.text.secondary} />}
      label={label}
      value={value.toString()}
      onClick={onClick}
    />
  );
};
