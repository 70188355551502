import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const ProofOfPrincipalAddressDocument = () => {
  const { formState: { errors }, register, watch, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors.proofOfPrincipalAddressDocument;
  const proofOfPrincipalAddressDocument = watch('proofOfPrincipalAddressDocument');
  const intl = useIntl();

  const onFileUploaded = (documentId: string) => {
    setValue('proofOfPrincipalAddressDocument', documentId);
  };
  const onDelete = () => {
    setValue('proofOfPrincipalAddressDocument', '');
  };

  return <UploadDocument
    onRegistered={register('proofOfPrincipalAddressDocument', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.proofOfPrincipalAddressDocument.error' }) })}
    onFileUploaded={onFileUploaded}
    onDelete={onDelete}
    documentId={proofOfPrincipalAddressDocument}
    error={error}
    documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.proofOfPrincipalAddressDocument' })}
    purpose={'proof_of_principal_address'}
    helperText={''} />;
};
