import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const ConfirmResetToDefaultsDialog = ({ onClose, onConfirm }: {
  onClose: () => void,
  onConfirm: () => void,
}) => {
  const intl = useIntl();

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth >
      <DialogTitle>
        {intl.formatMessage({ id: 'confirm.areYouSure' })}
      </DialogTitle>
      <DialogActions>
        <Button variant="contained" onClick={() => {
          onConfirm();
          onClose();
        }}>{intl.formatMessage({ id: 'confirmation.yes' })}</Button>
        <Button variant="outlined" onClick={onClose}>{intl.formatMessage({ id: 'confirmation.no' })}</Button>
      </DialogActions>
    </Dialog>
  );
};
