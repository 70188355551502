import { useFormContext } from 'react-hook-form';
import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  AddRepresentative,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/AddRepresentative';
import {
  CheckoutOnboardingInformationDraft,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

import {
  EditUBO,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/UBO/EditUBO';
import { extractSiren } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/extractSiren';
import {
  HasNoUbosOptIn,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/UBO/HasNoUbosOptIn';

export const UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH = 777;

const getNumberOfUBOs = (representatives: RepresentativeDraft[] | undefined) => {
  if (!representatives) return 0;
  return representatives?.filter(representative => representative.roles.ubo).length;
};

export const UBOs = ({ businessCountryCode }: { businessCountryCode: string }) => {
  const intl = useIntl();
  const theme = useTheme();
  const {
    watch,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const representatives = watch('representatives');
  const ubosWithIndexes = representatives?.map((rep, index) => ({ rep, index })).filter(({
    rep,
  }) => rep.roles.ubo);
  const siret = watch('businessRegistrationNumber');
  const siren = extractSiren(siret);
  const urlForUboInformation = `https://data.inpi.fr/entreprises/${siren}`;
  const hasNoUbos = watch('hasNoUbos');

  return <Stack spacing={3}>
    <Stack spacing={1}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.individualRepresentatives.ubo'} />
      </Typography>
      <Typography variant={'subtitle2'} color={theme.palette.text.secondary}>
        <FormattedMessage
          id={'settings.payments.checkout.onboarding.representatives.roles.ubo.hint'}
          values={{
            link: <a href={urlForUboInformation} target="_blank" rel="noreferrer">{urlForUboInformation}</a>,
          }} />
      </Typography>
    </Stack>

    {ubosWithIndexes?.map(({ rep, index }) => {
      return <EditUBO
        key={index + 'ubo'}
        representative={rep}
        index={index}
        businessCountryCode={businessCountryCode}
        onDelete={() => {
          setValue('representatives', representatives?.filter((_, i) => i !== index));
          clearErrors(`representatives.${UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
        }}
      />;
    })}
    <AddRepresentative
      isDisabled={hasNoUbos}
      onAddRepresentative={() => {
        if (getNumberOfUBOs(representatives) >= 4) {
          setError(`representatives.${UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`, {
            type: 'manual',
            message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.ubos.tooManyUbos' }),
          });
          return;
        }
        clearErrors(`representatives.${UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
        const emptyLegalRepresentative: RepresentativeDraft = {
          type: 'individual',
          roles: {
            ubo: true,
            authorisedSignatory: false,
            legalRepresentative: false,
          },
        };
        setValue('representatives', [...(representatives ?? []), emptyLegalRepresentative]);
      }}
      buttonTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.ubo.add' })}
    />
    <HasNoUbosOptIn hasAlreadyEnteredUbos={(ubosWithIndexes ?? []).length > 0} />
    <Typography marginLeft={'14px'} marginTop={'3px'} variant={'caption'} color={theme.palette.error.main}>
      {errors.representatives?.[UBO_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH]?.message}
    </Typography>
  </Stack>;
};
