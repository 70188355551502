import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button, Stack } from '@mui/material';
import { GoogleSetupStage } from '../domain/GoogleSetupStage';
import { GoogleSetupWizard } from './GoogleSetupWizard';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ampli } from 'src/ampli';
import { useLinkGoogleAccountToSunday } from '../infrastruscture/useLinkGoogleAccountToSunday';

type GoogleConnectButtonProps = {
  currentStage: GoogleSetupStage;
};

export const GoogleConnectButton = ({ currentStage }: GoogleConnectButtonProps) => {
  const snackBar = useSnackbar();
  const [locationModelIsOpened, setLocationModelIsOpened ] = useState(false);

  const { loginAndLink, unlink } = useLinkGoogleAccountToSunday();
  const getClose = () => () => {
    setLocationModelIsOpened(false);
  };

  const onConnectButtonClicked = () => {
    loginAndLink()
      .then(() => setLocationModelIsOpened(true))
      .catch(() => snackBar.addNotification({
        variant: 'error',
        text: 'Impossible to connect your Google account. Please try again or contact sunday',
      }));

    ampli.connectGoogleAccountClicked();
  };

  return (
    <Stack direction="row" spacing={2}>
      <Button
        sx={{
          ':hover': {
            bgcolor: 'primary.main',
            color: 'white',
          },
        }}
        size="medium"
        variant="outlined"
        onClick={() => onConnectButtonClicked()}
      >
        {currentStage === GoogleSetupStage.Unhealthy ? (
          <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.fix" />
        ) : (
          <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.setup" />
        )}
      </Button>
      {currentStage === GoogleSetupStage.Unhealthy && (
        <Button
          sx={{
            ':hover': {
              bgcolor: 'primary.main',
              color: 'white',
            },
          }}
          size="medium"
          variant="outlined"
          onClick={() => unlink()}
        >
          <FormattedMessage id="settings.reviews_and_loyalty.platform_setup.unlink" />
        </Button>
      )}
      {locationModelIsOpened && <GoogleSetupWizard close={getClose()} />}
    </Stack>
  );
};
