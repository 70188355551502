import {
  AmericanExpressCardSvg,
  BanContactCardSvg,
  BimpliCardSvg,
  IconWrapper,
  IdealCardSvg,
  MasterCardCardSvg,
  SodexoCardSvg,
  SwileCardSvg,
  UpCardSvg,
  VisaCardSvg,
} from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { DiscoverIcon } from './DiscoverIcon';
import { CardBrand, PaymentMethodType } from '../domain/Payment';
import { JcbIcon } from 'src/accounting/common/Icons/JcbIcon';
import { UnionPayIcon } from 'src/accounting/common/Icons/UnionPayIcon';
import { ChequeVacancesIcon } from 'src/accounting/common/Icons/ChequeVacancesIcon';

interface Props {
  methodType: PaymentMethodType,
  brand?: string | CardBrand
}

export const CardBrandIcon = ({
  methodType,
  brand,
}: Props) => {
  const displayNameCardBrand = () => {
    switch (brand) {
      case 'visa':
        return (
          <IconWrapper size="medium">
            <VisaCardSvg />
          </IconWrapper>
        );
      case 'mastercard':
        return (
          <IconWrapper size="medium">
            <MasterCardCardSvg />
          </IconWrapper>
        );
      case 'amex':
        return (
          <IconWrapper size="medium">
            <AmericanExpressCardSvg />
          </IconWrapper>
        );
      case 'swile':
        return (
          <IconWrapper size="medium">
            <SwileCardSvg />
          </IconWrapper>
        );
      case 'discover':
        return (
          <IconWrapper size="medium">
            <DiscoverIcon />
          </IconWrapper>
        );
      case 'bimpli':
        return (
          <IconWrapper size="medium">
            <BimpliCardSvg />
          </IconWrapper>
        );
      case 'up':
        return (
          <IconWrapper size="medium">
            <UpCardSvg />
          </IconWrapper>
        );
      case 'pass_restaurant':
        return (
          <IconWrapper size="medium">
            <SodexoCardSvg />
          </IconWrapper>
        );
      case 'jcb':
        return (
          <IconWrapper size="medium">
            <JcbIcon />
          </IconWrapper>
        );
      case 'unionpay':
        return (
          <IconWrapper size="medium">
            <UnionPayIcon />
          </IconWrapper>
        );
      case 'Cheque-Vacances':
        return (
          <IconWrapper size="medium">
            <ChequeVacancesIcon />
          </IconWrapper>
        );
      default:
        return <span>{ brand }</span>;
    }
  };

  switch (methodType) {
    case 'bancontact':
      return (
        <IconWrapper size="medium">
          <BanContactCardSvg />
        </IconWrapper>
      );
    case 'ideal':
      return (
        <IconWrapper g="medium">
          <IdealCardSvg />
        </IconWrapper>
      );
    case 'card': {
      return displayNameCardBrand();
    }
    default:
      return (
        <span>{methodType}</span>
      );
  }
};
