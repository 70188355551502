import { Alert, Box, Link } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

export const InfoAccounting = () => {
  const intl = useIntl();
  const frenchFaqUrl = 'https://intercom.help/sundayapp-help/fr/collections/3849750-comptabilite';
  const englishFaqUrl = 'https://intercom.help/sundayapp-help/en/collections/3849750-accounting';

  return (
    <Box mb={3} ml={-4} mt={-4} mr={-4}>
      <Alert
        severity="info"
        sx={{
          paddingLeft: 4,
          paddingRight: 4,
          borderRadius: 0,
          '& .MuiAlert-message': {
            display: 'flex',
            justifyContent: 'space-between',
          },
        }}
      >
        <FormattedMessage id="accounting.faq_info_accounting" />
        <Link href={intl.locale.startsWith('fr') ? frenchFaqUrl : englishFaqUrl} underline="always" color="inherit">
          <FormattedMessage id="accounting.see_all_faq_accounting" />
        </Link>

      </Alert>
    </Box>
  );
};
