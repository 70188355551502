import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentRepositoryHttp } from '../infrastructure/PaymentRepositoryHttp';
import { mapPaymentResponse } from '../infrastructure/Mapper';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { defaultPaymentAccount } from '../domain/PaymentAccount';

export const QUERYKEY_PAYMENT_ACCOUNT = { scope: 'payment_account' };
const buildQueryKey = (enrollmentId: EnrollmentId | undefined) => [{ ...QUERYKEY_PAYMENT_ACCOUNT, enrollmentId }] as const;

export const paymentAccountRequest = async (enrollmentId: EnrollmentId | undefined) => {
  if (!enrollmentId) {
    return defaultPaymentAccount;
  }

  const configuration = ConfigurationLoader.load();
  const paymentRepositoryHttp = new PaymentRepositoryHttp(axios, configuration.paymentApiBaseUrl);

  return paymentRepositoryHttp.account(enrollmentId).then((response) => mapPaymentResponse(response));
};

const paymentAccountQuery = async ({
  queryKey: [{ enrollmentId }],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>) => paymentAccountRequest(enrollmentId);

export const usePaymentAccount = (enrollmentId: EnrollmentId | undefined) =>
  useQuery({
    queryKey: buildQueryKey(enrollmentId),
    queryFn: paymentAccountQuery,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 15 * 60 * 1000,
    gcTime: 20 * 60 * 1000,
  });
