import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Autocomplete, Box, Button, Chip, CircularProgress, Drawer, TextField, Typography } from '@mui/material';
import { useGetTagsByBusiness } from 'src/menu/dynamic-menu/queries/product/getTagsByBusinessQuery';
import { Tag } from 'src/menu/dynamic-menu/domain/Tag';
import { v4 as uuidv4 } from 'uuid';
import { red } from '@mui/material/colors';
import { BusinessId } from 'src/business/domain/Business';
import { Product } from 'src/menu/dynamic-menu/domain/Product';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { WarningRounded } from '@mui/icons-material';
import Link from '@mui/material/Link';

type TagsDrawerProps = {
  selectedProducts: Product[],
  businessId: BusinessId;
  onClose: () => void;
  onActionConfirmed: (selectedTags: Tag[]) => void;
  open: boolean;
};

export const TagsDrawer = ({ selectedProducts, businessId, onClose, onActionConfirmed, open }: TagsDrawerProps) => {
  const intl = useIntl();
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const {
    data: existingTags,
    isLoading: tagsLoading,
  } = useGetTagsByBusiness(businessId);

  const buildNewTagList = (availableTags: Tag[], values: readonly string[]) => values.map((tagName) => {
    const existingTag = availableTags.find((t) => t.name.toLowerCase().trim() === tagName.toLowerCase().trim());
    if (existingTag) {
      return existingTag;
    }
    return {
      id: uuidv4().toString(),
      name: tagName,
    };
  });

  const updateSelectedTags = (values: readonly string[]) => {
    setSelectedTags(buildNewTagList(existingTags ?? [], values));
  };

  const onConfirm = () => {
    onActionConfirmed(selectedTags);
    onClose();
  };

  return (
    <Drawer
      title={intl.formatMessage({ id: 'menus.products.bulk.modal.tags.title', defaultMessage: 'Tags' })}
      open={open}
      onClose={onClose}
      anchor={'right'}
    >
      <Box sx={{ width: '450px' }} role="presentation" display={'flex'} flexDirection={'column'}
           padding={'24px'} gap={3}>
        <Typography variant="h4">
          <FormattedMessage id="menus.products.bulk.modal.tags.title" values={{
            'nb_products': selectedProducts.length,
            'plural': selectedProducts.length > 1 ? 's' : '',
          }} />
        </Typography>
        <Box display="flex" flexDirection={'column'} justifyContent="space-between" gap={3}>
          <Box display="flex"
               sx={{ p: 2 }}
               borderRadius={'1rem'}
               justifyContent="space-between" alignItems="center"
               bgcolor={themeV5.palette.warning.main}
               color={themeV5.palette.warning.light}
               gap={2}>
            <Box display="flex">
              <WarningRounded fontSize={'large'} />
            </Box>
            <Box display="flex" flexDirection={'column'}>
              <Typography variant={'body2'} color={themeV5.palette.warning.light}>
                <FormattedMessage id="menus.products.bulk.modal.tags.content.warning" />
              </Typography>
            </Box>
          </Box>

          {tagsLoading ? (<CircularProgress />) :
            <>
              <Autocomplete
                sx={{ backgroundColor: red }}
                multiple
                freeSolo
                options={existingTags ? existingTags.map((t) => t.name) : []}
                defaultValue={[]}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip variant="outlined" color="default" label={option} {...getTagProps({ index })} />
                  ))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder=""
                  />
                )}
                onChange={(event, value) => {
                  updateSelectedTags(value);
                }}
              />
            </>
          }
          <Box display="flex" flexDirection={'row'} justifyContent="center" alignItems="center" gap={3}>
            <Link
              onClick={onClose}
              sx={{
                cursor: 'pointer',
                color: themeV5.palette.text.primary,
                textDecorationColor: themeV5.palette.text.primary,
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="menus.products.bulk.modal.tags.cancel" />
              </Typography>
            </Link>
            <Button autoFocus variant="contained" onClick={onConfirm}>
              {intl.formatMessage({ id: 'menus.products.bulk.modal.tags.confirm', defaultMessage: 'Save' })}
            </Button>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
