import React, { FC, FormEvent, useEffect, useState } from 'react';
import { Language } from 'src/app/i18n/AllSupportedLanguage';
import { Product } from 'src/menu/dynamic-menu/domain/Product';
import { BusinessId } from 'src/business/domain/Business';
import { useProductTranslationMutation } from 'src/menu/dynamic-menu/mutations/product/useProductTranslationMutation';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ProductRepositoryHttp } from 'src/menu/dynamic-menu/repositories/ProductRepositoryHttp';
import { Box, Button, Drawer, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { CountryFlag } from 'src/components/CountryFlag';
import Divider from '@mui/material/Divider';
import { Translate } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { themeV5 } from 'src/app/theme/ThemeV5';

type TranslationForm = {
  language: Language['id'];
  name: string | null;
  description: string | null;
  shortDescription: string | null;
};
export const TranslationDrawer: FC<{
  supportedLanguages: Language[],
  productId: Product['id'],
  businessId: BusinessId
}> = ({
  supportedLanguages,
  productId,
  businessId,
}) => {
  const mutateTranslation = useProductTranslationMutation(businessId, productId);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [form, setForm] = useState<TranslationForm>({
    language: supportedLanguages[0].id,
    name: null,
    description: null,
    shortDescription: null,
  });
  const intl = useIntl();
  const snackBar = useSnackbar();

  const configuration = ConfigurationLoader.load();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);

  const fetchProductTranslation = (language: string) => productRepositoryHttp.findByProductId(businessId, productId, language);

  function displayError() {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
    });
  }

  useEffect(() => {
    fetchProductTranslation(form.language)
      .then((product) => {
        if (!product) {
          displayError();
          return;
        }
        setForm({
          language: form.language,
          name: product.name,
          description: product.description,
          shortDescription: product.shortDescription,
        });
      })
      .catch(displayError);
  }, [form.language]);

  const saveProductTranslation = (e: FormEvent) => {
    e.preventDefault();
    mutateTranslation.mutateAsync({
      language: form.language,
      updateProduct: {
        name: form.name!,
        description: form.description!,
        shortDescription: form.shortDescription!,
      },
    })
      .then(() => {
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        });
      })
      .catch(displayError);
  };

  const closeDrawer = () => setIsDrawerOpen(false);

  return (<>
    <Button type="button" size="small" variant="text" onClick={() => setIsDrawerOpen(true)}>
      <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
        <Translate />
        <Typography sx={{ textDecoration: 'underline' }}>
          <FormattedMessage id="menus.product.translate.cta" />
        </Typography>
      </Box>
    </Button>

    <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
      <Box p={4} gap={4} display="flex" flexDirection="column">
        <Box onClick={() => closeDrawer()} sx={{ cursor: 'pointer' }}>
          <CloseIcon />
        </Box>

        <Typography variant="h4" color={themeV5.palette.text.primary}>
          <FormattedMessage id="menus.product.translate.cta" />
        </Typography>

        <form onSubmit={saveProductTranslation}>
          <Box display="flex" flexDirection="column" width={500} justifyContent="center" gap={4}>

            <FormControl size="small">
              <Select
                onChange={(event) => setForm((f) => ({
                  ...f,
                  language: event.target.value,
                }))}
                value={form.language}
              >
                {supportedLanguages.map((supportedLanguage) => (
                  <MenuItem value={supportedLanguage.id} key={supportedLanguage.id}>
                    <CountryFlag language={supportedLanguage} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Divider />

            <Box display="flex" flexDirection="column" justifyContent="center" gap={2}>
              <TextField
                variant="outlined"
                size="medium"
                required
                label={<FormattedMessage id="menus.product.details.name" />}
                sx={{ width: '100%' }}
                value={form.name}
                onChange={(e) => setForm((f) => ({
                  ...f,
                  name: e.target.value,
                }))}
              />

              <TextField
                variant="outlined"
                size="medium"
                label={<FormattedMessage id="menus.product.details.short_description" />}
                sx={{ width: '100%' }}
                value={form.shortDescription}
                onChange={(e) => setForm((f) => ({
                  ...f,
                  shortDescription: e.target.value,
                }))}
              />


              <TextField
                variant="outlined"
                size="medium"
                label={<FormattedMessage id="menus.product.details.description" />}
                sx={{ width: '100%' }}
                value={form.description}
                onChange={(e) => setForm((f) => ({
                  ...f,
                  description: e.target.value,
                }))}
              />
            </Box>

            <Divider />

            <Box display="flex" alignItems="center" gap={2}>
              <Button type="submit" variant="contained">
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </Box>
          </Box>
        </form>
      </Box>

    </Drawer>
  </>);
};
