/* eslint-disable max-lines */
import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FixedPanel, FixedPanelContainer } from '../../../../common/components/FixedPanel';
import { OrderingMenu, OrderingMenuId } from '../../../../orderingMenus/domain/OrderingMenu';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { getFulfillmentMethodDescription, getFulfillmentMethodLabel } from '../../../formatting';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { AreaDetailsServiceMenu } from './AreaDetailsServiceMenu';
import { useUpdateAreaConfigurations } from '../../../hooks';
import { Area } from '../../../domain/Area';
import { DeleteServiceButton } from './DeleteServiceButton';
import { useRouting } from '../../../../common/hooks';
import { BusinessId } from 'src/business/domain/Business';
import { AreaConfiguration } from 'src/ordering/area/domain/AreaConfiguration';

type AreaDetailsServicePanelProps = {
  businessId: BusinessId;
  area: Area;
  setArea: (area: Area) => void;
  service: FulfillmentType;
  menus: OrderingMenu[];
  menuIds: string[];
  isOpened: boolean;
  onClosed: () => void;
};

const ServiceName = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: ${getSpacing(2)};
`;

const ServiceDescription = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
`;

const MenusContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 440px);
  overflow-y: auto;
`;

const Menus = styled.div`
  margin-top: ${getSpacing(4)};
  font-size: 16px;
  line-height: 20px;
  display: flex;
  flex-direction: column;
`;

const MenusHeader = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

const MenuMessageError = styled.div`
  margin-top: ${getSpacing(3)};
  margin-bottom: ${getSpacing(3)};
  color: ${colorPalette.red};
`;

export function selectionEquals(menuIdsForService: OrderingMenuId[], selectedMenuIds: string[]) {
  return menuIdsForService.every((menuId) => selectedMenuIds.includes(menuId)) && menuIdsForService.length === selectedMenuIds.length;
}

export function isAreaConfigurationMenusMigrated(isEnrollmentMigrated: boolean, area: Area, menus: OrderingMenu[]) {
  if (!isEnrollmentMigrated) {
    return false;
  }

  const serviceMenuIds = area.configurations.flatMap((config) => config.menuId);
  return serviceMenuIds.every((menuId) => menus.some((menu) => menu.sharedMenuId === menuId));
}

export const areaConfigurationMenuId = (menu: OrderingMenu) => menu.sharedMenuId;

export const AreaDetailsServicePanel = ({
  businessId,
  area,
  setArea,
  service,
  menus,
  menuIds,
  isOpened,
  onClosed,
}: AreaDetailsServicePanelProps) => {
  const routing = useRouting();

  const serviceMenuIds = menuIds
    .map((menuId) => menus.find((m) => [m.sharedMenuId, m.id].includes(menuId)))
    .filter((menu): menu is OrderingMenu => !!menu)
    .map(areaConfigurationMenuId);

  const [selectedMenuIds, updateSelectedMenus] = useState<string[]>(serviceMenuIds);
  const [updateAreaConfigurationState, updateAreaConfigurations] = useUpdateAreaConfigurations();

  const noChangesInSelection = selectionEquals(serviceMenuIds, selectedMenuIds);

  const onMenuSelection = (menu: OrderingMenu, selected: boolean) => {
    const menuId = areaConfigurationMenuId(menu);
    const updatedMenuIds = selected
      ? selectedMenuIds.concat(menuId)
      : selectedMenuIds.filter((id) => id !== menuId);

    updateSelectedMenus(updatedMenuIds);
  };

  const isMenuSelected = (menu: OrderingMenu): boolean => selectedMenuIds.includes(areaConfigurationMenuId(menu));

  const onSaveMenuSelections = async () => {
    const otherConfigurations = area.configurations.filter((config) => config.fulfillmentMethod !== service);
    const serviceConfigurations = selectedMenuIds.map((menuId): AreaConfiguration => ({
      fulfillmentMethod: service,
      menuId,
    }));

    const configurations = [...otherConfigurations, ...serviceConfigurations];
    await updateAreaConfigurations(area.id, configurations);

    setArea({ ...area, configurations });
  };

  const goToMenus = () => {
    routing.goToMenus(businessId);
  };

  return (
    <FixedPanel isOpened={isOpened} onClosed={onClosed}>
      <FixedPanelContainer>
        <ServiceName>{getFulfillmentMethodLabel(service)}</ServiceName>
        <ServiceDescription>{getFulfillmentMethodDescription(service)}</ServiceDescription>

        {menus.length > 0 ? (
          <>
            <Menus>
              <MenusHeader>
                <FormattedMessage
                  id="settings.area.details.service-menus.panel"
                  defaultMessage="select the menus for your service"
                />
              </MenusHeader>

              <MenusContainer>
                {menus.map((menu) => (
                  <AreaDetailsServiceMenu
                    key={menu.id}
                    menu={menu}
                    onMenuSelection={onMenuSelection}
                    selected={isMenuSelected(menu)}
                  />
                ))}
              </MenusContainer>
            </Menus>

            <Button
              variant="primary"
              size="medium"
              fullWidth
              onClick={onSaveMenuSelections}
              marginTop={getSpacing(2)}
              disabled={noChangesInSelection || updateAreaConfigurationState.loading}
            >
              <FormattedMessage id="settings.area.details.service-menus.save-btn" defaultMessage="save changes" />
            </Button>
          </>
        ) : (
          <>
            <MenuMessageError>
              <FormattedMessage
                id="settings.area.details.service-menus.no-menu"
                defaultMessage="You need at least one menu to create a service"
              />
            </MenuMessageError>
            <Button variant="primary" size="medium" fullWidth onClick={goToMenus} marginTop={getSpacing(2)}>
              <FormattedMessage id="settings.area.details.service-menus.go-to-menus-btn" defaultMessage="Go to menus" />
            </Button>
          </>
        )}

        <Button
          variant="tertiary"
          size="medium"
          fullWidth
          onClick={onClosed}
          marginTop={getSpacing(2)}
          disabled={updateAreaConfigurationState.loading}
        >
          <FormattedMessage id="settings.area.details.service-menus.cancel-btn" defaultMessage="cancel" />
        </Button>
      </FixedPanelContainer>
      <DeleteServiceButton
        area={area}
        setArea={setArea}
        currentService={service}
        onServiceDeleted={onClosed}
        hasMenus={serviceMenuIds.length > 0}
      />
    </FixedPanel>
  );
};
