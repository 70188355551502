import { createContext, useContext } from 'react';

export type NotificationToAddType = {
  variant: 'error' | 'success' | 'info' | 'warning';
  title?: string;
  text: string;
};

export interface SnackBar {
  addNotification: (notification: NotificationToAddType) => void;
}

export const SnackBarContext = createContext<SnackBar>({
  addNotification: () => {},
});

export const useSnackbar = () => useContext(SnackBarContext);
