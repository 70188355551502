import { useState } from 'react';
import {
  ReviewAndApplyChanges,
  displayResume,
  SelectTargetBusinesses,
} from '../domain/ConfigurationReplicationState';
import { Button, Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { Business } from 'src/business/domain/Business';
import { useUserPayAtTableBusinesses } from 'src/data-analytics/hooks/useUserPayAtTableBusinesses';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ampli } from 'src/ampli';

export const SelectTargetBusinessesStep = ({
  currentState,
  onNext,
}: {
  currentState: SelectTargetBusinesses;
  onNext: (state: ReviewAndApplyChanges) => void;
}) => {
  const businesses = useUserPayAtTableBusinesses().filter((business) => business.id !== currentState.sourceBusiness.id);
  const [selectedBusinesses, setSelectedBusinesses] = useState<Array<Business>>([]);

  const next = () => {
    ampli.configurationReplicationTargetSelected({
      numberOfReplicationConfigurationTargets: selectedBusinesses.length,
    });
    onNext(displayResume(currentState, selectedBusinesses));
  };

  const isBusinessSelected = (business: Business) => {
    return selectedBusinesses.includes(business);
  };

  const updateSelectionOfBusiness = (business: Business, selected: boolean) => {
    if (selected) {
      setSelectedBusinesses([...selectedBusinesses, business]);
    } else {
      setSelectedBusinesses(selectedBusinesses.filter((b) => b !== business));
    }
  };

  return (
    <Stack padding="1.5rem" gap="1rem">
      <Typography variant="h5">
        <FormattedMessage id="configuration_replication.select_target.title" />
      </Typography>
      <Typography>
        <FormattedMessage id="configuration_replication.select_target.details" />
      </Typography>
      <FormGroup>
        {businesses.map((business) => (
          <FormControlLabel
            key={business.id}
            control={
              <Checkbox
                checked={isBusinessSelected(business)}
                onChange={(event) => updateSelectionOfBusiness(business, event.target.checked)}
              />
            }
            label={business.name}
          />
        ))}
      </FormGroup>
      <Stack direction="row-reverse">
        <Button variant="contained" onClick={next} disabled={selectedBusinesses.length < 1}>
          <FormattedMessage id="configuration_replication.select_target.next" />
        </Button>
      </Stack>
    </Stack>
  );
};
