import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Container, SettingPanelContent, Title } from 'src/ordering/common/components/Settings/SettingPanel';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { PicturePicker } from 'src/ordering/common/components/PicturePicker/PicturePicker';
import { VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { useFileStorage } from 'src/ordering/common/hooks';
import { VENUE_PICTURE_HEIGHT, VENUE_PICTURE_WIDTH } from 'src/ordering/common/components/PicturePicker/constants';
import { Button } from '@sundayapp/b2b-react-component-library';
import { extractVenueImageName, generateVenueImageName } from './venueImageName';

type VenuePictureSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
`;

const DeleteButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

export const VenuePictureSetting = ({ venue, setVenue }: VenuePictureSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [updating, makeUpdating] = useState<boolean>(false);

  const fileStorage = useFileStorage();

  const setVenueImage = useCallback(
    async (base64Image: string) => {
      makeUpdating(true);
      const imageUrl = await fileStorage.saveImageAsPNG(
        `ordering/${venue.name}`,
        generateVenueImageName(),
        base64Image,
      );
      const updatedVenue = {
        ...venue,
        imageUrl,
      };
      await updateVenueDetails(updatedVenue);
      setVenue(updatedVenue);
      pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));

      makeUpdating(false);
    },
    [fileStorage, intl, pushNotification, venue, setVenue, updateVenueDetails, makeUpdating],
  );

  const deleteVenueImage = useCallback(async () => {
    if (!venue.imageUrl) {
      return;
    }
    makeUpdating(true);
    console.log(`delete: ${venue.imageUrl}`);
    await fileStorage.deleteImage(`ordering/${venue.name}`, extractVenueImageName(venue.imageUrl));
    const updatedVenue = {
      ...venue,
      imageUrl: '',
    };
    await updateVenueDetails(updatedVenue);
    setVenue(updatedVenue);
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  }, []);

  return (
    <Container>
      <Title>
        <FormattedMessage id="venue.settings.app-venue.picture.title" defaultMessage="picture" />
      </Title>
      <Description>
        <FormattedMessage
          id="venue.settings.app-venue.picture.description"
          defaultMessage="The picture to display on the home page"
        />
      </Description>
      <SettingPanelContent>
        <PicturePicker
          imageUrl={venue.imageUrl}
          onImageChanged={setVenueImage}
          updating={updating}
          width={VENUE_PICTURE_WIDTH}
          height={VENUE_PICTURE_HEIGHT}
        />
        <Line>
          <DeleteButton variant="primary" size="xSmall" onClick={deleteVenueImage} disabled={!venue.imageUrl}>
            <FormattedMessage id="venue.settings.delete" defaultMessage="Delete" />
          </DeleteButton>
        </Line>
      </SettingPanelContent>
    </Container>
  );
};
