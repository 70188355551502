import { useNavigate } from 'react-router';
import { RouteDefinitions } from '../app/RouteDefinitions';
import { venuePath } from '../app/navigation/pathHelpers';
import { useSearchParams } from 'react-router';
import { useEffect } from 'react';

export const PongoLoyaltyOAuthRedirect = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const navigate = useNavigate();

  useEffect(() => {
    if (!code || !state) {
      navigate('/');
      return;
    }
    navigate(`${venuePath(state)}${RouteDefinitions.PongoLoyalty.path}?code=${code}`);
  }, [code, state, navigate]);
  return null;
};
