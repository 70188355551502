import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import { Card, CardContent, Input, InputAdornment, InputLabel, Stack, Switch, Typography } from '@mui/material';
import { tipsKind, TipsLevelConfiguration } from '../../domain/PaymentTerminalConfiguration';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export type TipsLevelSettingsProps = {
  name: string | undefined;
  disabled: boolean;
  tipsLevel: TipsLevelConfiguration;
  onTipsLevelUpdated: (tipsLevel: TipsLevelConfiguration) => void;
};

function TipsLevelForm({
  name, tipsLevel, disabled, onTipsLevelUpdated,
}: TipsLevelSettingsProps) {
  const business = useCurrentBusinessOrThrow();
  const fixedAmount = tipsLevel.kind === tipsKind.AMOUNT;
  const adornment = fixedAmount ? business.currency : '%';
  return (
    <Card variant="outlined">
      <CardContent>
        <Stack direction="column" justifyContent="space-evenly" alignItems="start" spacing="16px" width="300px">
          { name &&
            <Stack direction="row" justifyContent="start" alignItems="center" spacing="20px">
              <Switch
                disabled={disabled}
                checked={tipsLevel.enabled}
                onChange={(e) => onTipsLevelUpdated({ ...tipsLevel, enabled: e.target.checked })}
              />
              <Typography>{name}</Typography>
            </Stack>
          }
          <Stack direction="row" justifyContent="space-between" spacing="20px" width="100%">
            <Typography>
              <FormattedMessage id="payment.terminal.settings.tips.fixed_amount" />
            </Typography>
            <Switch
              disabled={!tipsLevel.enabled || disabled}
              checked={fixedAmount}
              onChange={(e) =>
                onTipsLevelUpdated({
                  ...tipsLevel,
                  kind: e.target.checked ? tipsKind.AMOUNT : tipsKind.PERCENTAGE,
                })}
            />
          </Stack>

          <Stack direction="row" justifyContent="start" alignItems="center" spacing="20px" width="100%">
            <InputLabel>1</InputLabel>
            <Input
              fullWidth
              type="number"
              inputProps={{ step: '0.1' }}
              value={tipsLevel.value2}
              onChange={(e) => onTipsLevelUpdated({ ...tipsLevel, value2: parseFloat(e.target.value) })}
              disabled={!tipsLevel.enabled || disabled}
              endAdornment={<InputAdornment position="end">{adornment}</InputAdornment>}
            />
          </Stack>

          <Stack direction="row" justifyContent="start" alignItems="center" spacing="20px" width="100%">
            <InputLabel>2</InputLabel>
            <Input
              fullWidth
              type="number"
              inputProps={{ step: '0.1' }}
              value={tipsLevel.value3}
              onChange={(e) => onTipsLevelUpdated({ ...tipsLevel, value3: parseFloat(e.target.value) })}
              disabled={!tipsLevel.enabled || disabled}
              endAdornment={<InputAdornment position="end">{adornment}</InputAdornment>}
            />
          </Stack>

          <Stack direction="row" justifyContent="start" alignItems="center" spacing="20px" width="100%">
            <InputLabel>3</InputLabel>
            <Input
              fullWidth
              type="number"
              inputProps={{ step: '0.1' }}
              value={tipsLevel.value4}
              onChange={(e) => onTipsLevelUpdated({ ...tipsLevel, value4: parseFloat(e.target.value) })}
              disabled={!tipsLevel.enabled || disabled}
              endAdornment={<InputAdornment position="end">{adornment}</InputAdornment>}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TipsLevelForm;
