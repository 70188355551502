/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const PaymentTerminalPlainSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_305_1409)">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.49998 10.8333V13.3333C2.49998 14.2538 3.24617 15 4.16665 15H5.62498V16.6667H4.16665C2.3257 16.6667 0.833313 15.1743 0.833313 13.3333V10.2407C0.833313 9.87041 0.89502 9.50268 1.0159 9.15265L3.1135 3.07859C3.57753 1.73489 4.84267 0.833328 6.26424 0.833328H13.7357C15.1573 0.833328 16.4224 1.73489 16.8865 3.07859L18.9841 9.15265C19.1049 9.50268 19.1666 9.87041 19.1666 10.2407V13.3333C19.1666 15.1743 17.6743 16.6667 15.8333 16.6667H14.375V15H15.8333C16.7538 15 17.5 14.2538 17.5 13.3333V10.8333H2.49998Z" fill={color}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.16669 12.5C4.16669 12.0398 4.53978 11.6667 5.00002 11.6667L15 11.6667C15.4603 11.6667 15.8334 12.0398 15.8334 12.5C15.8334 12.9602 15.4603 13.3333 15 13.3333L5.00002 13.3333C4.53978 13.3333 4.16669 12.9602 4.16669 12.5Z" fill={color}/>
      <path d="M9.16669 13.3333H15V17.5C15 18.4205 14.2538 19.1667 13.3334 19.1667H9.16669V13.3333Z" fill={color}/>
      <path d="M5 12.5H7.5V19.1667H6.66667C5.74619 19.1667 5 18.4205 5 17.5V12.5Z" fill={color}/>
    </g>
    <defs>
      <clipPath id="clip0_305_1409">
        <rect width={size} height={size} fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

export default PaymentTerminalPlainSvg;
