import React from 'react';
import { GlobalConfiguration } from 'src/venueconfiguration/domain/GlobalConfiguration';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { Stack, Switch, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

interface Props {
  globalConfiguration: GlobalConfiguration | undefined;
  updateGlobalConfiguration: (configurations: Map<VenueConfigurationKey, any>) => Promise<void>;
}

const SplitSettings = ({ globalConfiguration, updateGlobalConfiguration }: Props) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const splitByItemsEnabled = globalConfiguration?.entries.get(VenueConfigurationKey.splitByItemsEnabled) ?? true;
  const splitByCustomAmountEnabled =
    globalConfiguration?.entries.get(VenueConfigurationKey.splitByCustomAmountEnabled) ?? true;
  const splitByShareEnabled = globalConfiguration?.entries.get(VenueConfigurationKey.splitByShareEnabled) ?? true;

  const save = async (key: VenueConfigurationKey, value: boolean) => {
    await updateGlobalConfiguration(new Map<VenueConfigurationKey, any>([[key, String(value)]]));
    useSnackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({
        id: 'settings.internal_settings_pat.split_settings.success_saving',
        defaultMessage: 'Success',
      }),
    });
  };

  return (
      <Stack direction="column" gap={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage
              id="settings.internal_settings_pat.split_settings.split_by_items"
              defaultMessage="Split by items"
            />
          </Typography>
          <Switch
            onChange={(e) => save(VenueConfigurationKey.splitByItemsEnabled, e.target.checked)}
            checked={splitByItemsEnabled}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage
              id="settings.internal_settings_pat.split_settings.split_by_custom_amount"
              defaultMessage="Split by items"
            />
          </Typography>
          <Switch
            onChange={(e) => save(VenueConfigurationKey.splitByCustomAmountEnabled, e.target.checked)}
            checked={splitByCustomAmountEnabled}
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage
              id="settings.internal_settings_pat.split_settings.split_by_share"
              defaultMessage="Split by items"
            />
          </Typography>
          <Switch
            onChange={(e) => save(VenueConfigurationKey.splitByShareEnabled, e.target.checked)}
            checked={splitByShareEnabled}
          />
        </Stack>
      </Stack>
  );
};

export default SplitSettings;
