import { Alert, Box } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line @typescript-eslint/no-redeclare

export const DownloadError = ({ onClose }: { onClose?: () => void }) => (
  <Box sx={{ display: 'flex', margin: '16px 0 24px 0', width: '100%' }}>
    <Alert severity="error" onClose={onClose}>
      <FormattedMessage id="accounting.downloading_export_error" />
    </Alert>
  </Box>
);
