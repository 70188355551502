import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessReferentialRepositoryHttp } from '../infrastructure/BusinessReferentialRepositoryHttp';
import { Business, BusinessId } from '../domain/Business';
import { datadogLogs } from '@datadog/browser-logs';
import { configureDatadogForMultiLocations } from 'src/monitoring/datadog';
import { TrackingContextEventEnrichmentPlugin } from 'src/app/tracking/TrackingContextEventEnrichmentPlugin';

const businessesQueryFunction = (businessIds: BusinessId[]) => async (): Promise<Business[]> => {
  const configuration = ConfigurationLoader.load();
  const businessReferentialRepository = new BusinessReferentialRepositoryHttp(configuration.venueApiBaseUrl);
  const businesses: Business[] | undefined = await businessReferentialRepository.getByIds(businessIds);
  if (!businesses) {
    const message = `businesses '${businessIds}' can't be found in business referential!`;
    datadogLogs.logger.warn(message);
    throw new Error(message);
  }

  configureDatadogForMultiLocations();
  TrackingContextEventEnrichmentPlugin.setVenue({
    venueId: 'all-locations',
    businessId: 'all-locations',
    venueName: 'all-locations',
    businessName: 'all-locations',
    venueCountryCode: 'undefined',
  });

  return businesses;
};

export const businessesQuery = (businessIds: BusinessId[]) => ({
  queryKey: [queryKeys.ENTERPRISE, businessIds],
  queryFn: businessesQueryFunction(businessIds),
});
