import { Box, Typography } from '@mui/material';
import { productTypes, translationByProductType } from 'src/menu/dynamic-menu/domain/Product';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { FC } from 'react';
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import {
  ProductTypeBundleImage,
  ProductTypeProductImage,
  ProductTypeVariantGroupImage,
} from 'src/menu/dynamic-menu/pages/CreateProductPage/components/ProductTypeImage';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { lowercase, ProductTypeAllowed } from 'src/menu/dynamic-menu/pages/CreateProductPage/CreateProductPage';

const ProductTypeOption: FC<{
  type: ProductTypeAllowed;
  isSelected: boolean;
  onClick: (type: ProductTypeAllowed) => void;
}> = ({ type, isSelected, onClick }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      borderRadius={4}
      onClick={() => onClick(type)}
      sx={{
        cursor: 'pointer',
        border: `1px solid ${isSelected ? themeV5.palette.primary.main : themeV5.palette.divider}`,
      }}
    >
      <Box>
        {type === productTypes.PRODUCT && <ProductTypeProductImage />}
        {type === productTypes.BUNDLE && <ProductTypeBundleImage />}
        {type === productTypes.VARIANT_GROUP && <ProductTypeVariantGroupImage />}
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={2} gap={1}>
        <Typography variant="subtitle1">
          <FormattedMessage id={translationByProductType[type].single} />
        </Typography>
        <Typography variant="body2" color={themeV5.palette.text.secondary} pb={1} sx={{ textAlign: 'center' }}>
          <FormattedMessage id={`menus.catalog.products.form.type.new.${lowercase(type)}`} />
        </Typography>

        <Box p={1}>
          {isSelected ? (
            <RadioButtonChecked sx={{ color: themeV5.palette.primary.main, width: '24px', height: '24px' }} />
          ) : (
            <RadioButtonUnchecked sx={{ width: '24px', height: '24px' }} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export const ProductTypeSelector: FC<{ value: ProductTypeAllowed; onChange: (type: ProductTypeAllowed) => void }> = ({
  value,
  onChange,
}) => {
  const options = [productTypes.PRODUCT, productTypes.VARIANT_GROUP, productTypes.BUNDLE];

  return (
    <Box display="flex" gap={2}>
      {options.map((opt) => (
        <ProductTypeOption key={opt} type={opt} isSelected={value === opt} onClick={onChange} />
      ))}
    </Box>
  );
};
