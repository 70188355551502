import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useMemo, useState } from 'react';
import { TimezoneType, VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { TimezoneOption, timezoneOptions } from './timezoneOptions';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type TimezoneVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

const filterByCountry = (tz: TimezoneOption, venue: VenueDetails) => {
  const frVenue = venue.countryCode === 'FR';
  // For now, propose only these 2 timezones, as we have only restaurants in these two
  const frTimezone = tz.id === TimezoneType.France_AST || tz.id === TimezoneType.France_Paris;
  // If FR venue, return FR timezones; else return non FR timezones
  return frVenue === frTimezone || tz.id === TimezoneType.NONE;
};

export const TimezoneVenueSetting = ({ venue, setVenue }: TimezoneVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const options = useMemo(() => timezoneOptions(intl).filter((tz) => filterByCountry(tz, venue)), [intl, venue]);

  const computeDropDownLabel = (value: string | undefined): string => {
    if (value === undefined) {
      return computeDropDownLabel(TimezoneType.NONE);
    }

    const idx = options.findIndex((opt) => opt.id === value);
    if (idx >= 0) {
      return options[idx].label;
    }
    return computeDropDownLabel(TimezoneType.NONE);
  };

  const dropDownValue: string = computeDropDownLabel(venue.timezone);
  const dropDownOptions: string[] = options.map((opt) => opt.label);

  const updateTimezone = async (timezone: string) => {
    const selectedIdx = options.findIndex((opt) => opt.label === timezone);
    const updatedTime = options[selectedIdx].id;

    makeUpdating(true);
    setVenue({
      ...venue,
      timezone: updatedTime,
    });
    await updateVenueDetails({
      timezone: updatedTime,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  if (venue.countryCode !== 'US' && venue.countryCode !== 'CA' && venue.countryCode !== 'FR') {
    return <></>;
  }

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.timezone',
        defaultMessage: 'timezone',
        description: 'select the venue timezone',
      })}
      description={intl.formatMessage({
        id: 'venue.settings.timezone.description',
        defaultMessage: 'select the venue timezone',
        description: 'description of the venue timezone setting purpose',
      })}
      dropDownValue={dropDownValue}
      dropDownOptions={dropDownOptions}
      dropDownOnChange={updateTimezone}
      disabled={isUpdating}
    />
  );
};
