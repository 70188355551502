/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const DotsIcon: React.FC<IconProps> = ({ size = 24, color = 'black' }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.125 13.25C17.4346 13.25 16.875 12.6904 16.875 12C16.875 11.3096 17.4346 10.75 18.125 10.75C18.8154 10.75 19.375 11.3096 19.375 12C19.375 12.6904 18.8154 13.25 18.125 13.25ZM11.875 13.25C11.1846 13.25 10.625 12.6904 10.625 12C10.625 11.3096 11.1846 10.75 11.875 10.75C12.5654 10.75 13.125 11.3096 13.125 12C13.125 12.6904 12.5654 13.25 11.875 13.25ZM4.375 12C4.375 12.6904 4.93464 13.25 5.625 13.25C6.31536 13.25 6.875 12.6904 6.875 12C6.875 11.3096 6.31536 10.75 5.625 10.75C4.93464 10.75 4.375 11.3096 4.375 12Z"
      fill={color}
    />
  </svg>
);
