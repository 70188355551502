import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Business, BusinessId } from '../domain/Business';
import { businessesQuery } from 'src/business/queries/businessesQuery';

export const useBusinessesQuery = (
  businessIds: BusinessId[],
  options?: Omit<UseQueryOptions<Business[]>, 'queryKey' | 'queryFn' | 'initialData'>,
) => {
  const query = businessesQuery(businessIds);
  return useQuery<Business[]>({
    queryKey: query.queryKey,
    queryFn: query.queryFn,
    ...options,
    enabled: businessIds.length > 0,
  });
};
