import axios from 'axios';
import { ClawbackReportFile, ClawbackRepository } from 'src/accounting/clawbacks/infrastructure/ClawbackRepository';
import { BusinessId } from 'src/business/domain/Business';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { DateRange } from 'src/accounting/revenues/domain/RevenuesDatePresets';

export const useClawbackReportDownloader = (businessId: BusinessId) => {
  const accountingRepository = new ClawbackRepository(axios);

  const client = useQueryClient();
  return (dateRange: DateRange): Promise<ClawbackReportFile> =>
    client.fetchQuery({
      queryKey: [queryKeys.accounting.clawbacks.DOWNLOAD_CLAWBACKS_REPORT, { businessId, dateRange }],
      queryFn: async () => accountingRepository.downloadReport(businessId, dateRange),
      staleTime: 0,
    });
};
