import axios from 'axios';
import { PosConnectionOptions } from '../domain/PosConnectionOptions';
import { PosConnection, PosConnectionId, RevenueCenter } from '../domain/PosConnection';
import { BusinessId } from 'src/business/domain/Business';

export type PosConnectionsResponse = {
  [businessId: string]: PosConnection[];
};

export class PosConnectionRepositoryHttp {

  constructor(
    private vposVenueBackendUrl: string,
  ) {
  }

  async getPosConnectionOptions(posConnectionId: PosConnectionId): Promise<PosConnectionOptions | null> {
    const response = await axios.get(`${this.vposVenueBackendUrl}/pos-connections/${posConnectionId}/options`,
      { validateStatus: status => status === 200 || status === 404 },
    );
    if (response.status === 404) {
      return null;
    }
    return response.data;
  }

  async updatePosConnectionOptions(posConnectionId: PosConnectionId, options: PosConnectionOptions): Promise<void> {
    await axios.patch(`${this.vposVenueBackendUrl}/pos-connections/${posConnectionId}/options`, options);
  }

  async getPosConnections(businessId: BusinessId): Promise<PosConnectionsResponse> {
    const response = await axios.post<PosConnectionsResponse>(`${this.vposVenueBackendUrl}/pos-connections`, [businessId]);
    return response.data;
  }

  async getRevenueCenters(posConnectionId: PosConnectionId): Promise<RevenueCenter[]> {
    const response = await axios.get<{ revenueCenters: RevenueCenter[] }>(
      `${this.vposVenueBackendUrl}/revenue-centers/${posConnectionId}`,
    );
    return response.data?.revenueCenters ?? [];
  }

}
