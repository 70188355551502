import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import styled from 'styled-components';

const EmptyReviewsImage = styled.img`
  height: 4.3rem;
  width: 4.3rem;
`;

export const EmptyReviewsRating = () => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Paper style={{ padding: '1rem', borderRadius: '2rem', height: '100%' }} elevation={0}>
      <Stack direction="column" gap={1.5} alignItems="center">
        <EmptyReviewsImage
          alt={intl.formatMessage({ id: 'review.stat.no_rating' })}
          src="/img/reviews/salt-&-pepper.svg"
        />
        <Typography variant="h6">
          <FormattedMessage id="review.stat.no_rating" />
        </Typography>
        <Typography textAlign="center" variant="subtitle1" color={theme.palette.text.secondary}>
          <FormattedMessage id="settings.reviews_and_loyalty.description" />
        </Typography>
      </Stack>
    </Paper>
  );
};
