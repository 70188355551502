// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Badge, colorUsage, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { MenuItemColumn } from './MenuItemColumn';
import { MenuLine, MenuTitle } from './MenuItem.styles';
import { isMobileScreen } from 'src/app/theme/ThemeV5';

export type MenuItemNameProps = {
  name: string,
  visibility: boolean
};

const BadgeContainer = styled.div`
  display: flex;
`;

const MenuItemName = ({ name, visibility }: MenuItemNameProps) => {
  const fontColor = visibility ? colorUsage.primaryText : colorUsage.disabledText;

  return (
    <MenuLine isMobile={isMobileScreen()}>
      <MenuTitle>
        <FormattedMessage id="menus.table.header.name" />
      </MenuTitle>
      <MenuItemColumn>
        <Text keepCase color={fontColor}>{name}</Text>
        {!visibility && (
          <BadgeContainer>
            <Badge size="small" variant="grey" ml={spaceUsages.mediumLarge} mt={spaceUsages.extraSmall}>
              <FormattedMessage id="menus.inactive" />
            </Badge>
          </BadgeContainer>
        )}
      </MenuItemColumn>
    </MenuLine>
  );
};

export default MenuItemName;
