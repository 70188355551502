import { Button } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useRef } from 'react';
import { Box, CircularProgress, Modal, Typography } from '@mui/material';
import { Money, MoneyView } from '@sundayapp/web-money';
import { useTabCapture } from '../infrastructure/tab/useTabCaptureStatus';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { TabCaptureStatus } from '../domain/tab/TabCaptureStatus';
import { datadogLogs } from '@datadog/browser-logs';
import { BusinessId } from 'src/business/domain/Business';

type TabCaptureConfirmationProps = {
  show: boolean;
  onCaptureSuccess: () => void;
  onCancel: () => void;
  billLeftToPay: Money;
  tabId: string;
  businessId: BusinessId;
  digitalFeeRate: number;
};

const style = {
  position: 'fixed',
  top: '50vh',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '24px',
  boxShadow: 24,
  p: 4,
};

function printPercentage(digitalFeeRate: number) { // 200 => 2%, 250 => 2.5%, 255 => 2.55%
  return `${parseFloat((digitalFeeRate / 100).toFixed(2))}%`;
}

export const TabCaptureConfirmation = ({
  show,
  onCancel,
  onCaptureSuccess,
  billLeftToPay,
  businessId,
  tabId,
  digitalFeeRate,
}: TabCaptureConfirmationProps) => {
  const timeoutRef = useRef<number | undefined>(undefined);
  const { isLoading, data, capture, isCapturing, abort } = useTabCapture(businessId, tabId);
  const snackBar = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    return () => {
      if (!!timeoutRef.current) {
        window.clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  const onFailure = () => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({ id: 'tabs.charge.left_to_pay.failure' }),
    });
    abort();
    onCancel();
  };
  const onSuccess = () => {
    onCaptureSuccess();
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'tabs.charge.left_to_pay.success' }),
    });
    abort();
    onCancel();
  };
  const onClick = () => {
    capture();
    timeoutRef.current = window.setTimeout(() => {
      datadogLogs.logger.error('[CAPTURE TAB LEFT TO PAY] timeout on completing capture', { tabId, businessId });
      onFailure();
    }, 8000);
  };

  if (!isLoading) {
    if (data.status === TabCaptureStatus.SUCCESS || data.status === TabCaptureStatus.PARTIAL_SUCCESS) {
      if (data.status === TabCaptureStatus.PARTIAL_SUCCESS) {
        datadogLogs.logger.warn('[CAPTURE TAB LEFT TO PAY] capture could only be performed partially', {
          tabId,
          businessId,
        });
      }
      onSuccess();
    }
    if (data.status === TabCaptureStatus.FAILURE) {
      datadogLogs.logger.error('[CAPTURE TAB LEFT TO PAY] capture failed', { tabId, businessId });
      onFailure();
    }
  }

  const title = isCapturing ? 'Capture in progress...' : 'Confirm charge and close the tab';
  const description = isCapturing ? (
    'please wait until completion.'
  ) : (
    <>
      {'charged amount will be : '}
      <MoneyView value={billLeftToPay} />
      {` (left to pay) +20% (tips) +${(printPercentage(digitalFeeRate))} (fees)`}
    </>
  );
  return (
    <Modal open={show} onClose={onCancel}>
      <Box sx={style}>
        <Typography variant={'h5'}>
          <p>{title}</p>
        </Typography>
        <Typography variant={'button'}>
          <p>{description}</p>
        </Typography>
        {isCapturing && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress size={'large'} />
          </div>
        )}
        {!isCapturing && (
          <>
            <div style={{ paddingBottom: '16px' }}>
              <Button variant="warning" size="medium" fullWidth onClick={onClick} data-testid="confirm-capture">
                {'charge and close'}
              </Button>
            </div>
            <div>
              <Button
                variant="secondary"
                size="medium"
                fullWidth
                onClick={() => {
                  if (!isCapturing) {
                    onCancel();
                  }
                }}
                data-testid="cancel-capture"
              >
                {'cancel'}
              </Button>
            </div>
          </>
        )}
      </Box>
    </Modal>
  );
};
