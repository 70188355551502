import { formatNumber, formatPercentage } from '../../../components/formatNumbers';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';
import { GlobalKpis } from 'src/sundayplus/reviews/insights/domain/EReputationKpis';

const formatAverageKpiFooter = (globalKpis: GlobalKpis) => `${globalKpis.withSunday.number >= 0 ? '+' : ''}${(formatNumber(globalKpis.withSunday.number, 2))} pt`;

export const formatGlobalKpiFooter = (kpiType: KpiName, globalKpis: GlobalKpis) => {
  if (kpiType === 'AVG_RATING') return formatAverageKpiFooter(globalKpis);
  return `${formatPercentage(globalKpis.withSunday.percentage)}% (${formatNumber(globalKpis.withSunday.number, 2)})`;
};
