import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useBoxRepository } from '../../hooks';
import { Box } from '../../domain/Box';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type BoxSelectorProps = {
  enrollmentId: EnrollmentId;
  onBoxUnselected: () => void;
  onBoxSelected: (box: Box) => void;
  initialBoxes?: Box[];
  initialSelection?: string;
  emptyMessage?: string;
  withEmptyBox?: boolean;
};

export const BoxSelector = ({
  enrollmentId,
  onBoxSelected,
  onBoxUnselected,
  initialBoxes,
  initialSelection,
  emptyMessage,
  withEmptyBox = true,
}: BoxSelectorProps) => {
  const intl = useIntl();

  const noBoxLabel = useMemo(() => {
    const message = emptyMessage || intl.formatMessage({ id: 'box.selector.all-boxes', defaultMessage: 'All boxes' });
    return `--- ${message} ---`;
  }, [emptyMessage, intl]);

  const initialBoxId = useMemo(() => {
    if (initialSelection) {
      return initialSelection;
    }

    if (withEmptyBox) {
      return noBoxLabel;
    }

    return '';
  }, [initialSelection, noBoxLabel, withEmptyBox]);

  const boxRepository = useBoxRepository();
  const [boxes, setBoxes] = useState<Box[]>(initialBoxes || []);
  const [currentBoxId, setCurrentBoxId] = useState<string>(initialBoxId);

  const options = useMemo((): string[] => {
    if (boxes === undefined) {
      return [];
    }

    const boxNames = boxes
      .map((box) => box.displayName || box.name)
      .sort((a, b) => a.localeCompare(b));

    if (withEmptyBox) {
      return [noBoxLabel, ...boxNames];
    }

    return [...boxNames];
  }, [boxes, noBoxLabel, withEmptyBox]);

  useEffect(() => {
    if (initialBoxes) {
      return;
    }

    boxRepository.getVenueBoxes(enrollmentId).then(setBoxes);
  }, [boxRepository, initialBoxes, enrollmentId]);

  useEffect(() => {
    if (currentBoxId === noBoxLabel && boxes.length === 1) {
      setCurrentBoxId(boxes[0].id);
      onBoxSelected(boxes[0]);
    }
  }, [boxes, currentBoxId, noBoxLabel, onBoxSelected]);

  const onBoxChanged = async (boxName: string) => {
    setCurrentBoxId(boxName);

    const selection = boxes.find((box) => (box.displayName === boxName || box.name === boxName));
    if (!selection) {
      onBoxUnselected();
      return;
    }

    onBoxSelected(selection);
  };

  if (boxes.length <= 1) {
    return <></>;
  }

  return <Dropdown size="medium" options={options} onChange={onBoxChanged} value={currentBoxId} />;
};
