import React, { useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, IconButton } from '@mui/material';
import { BillFreshness } from './BillsTable';
import { BillStatus } from '../domain/Bill';

interface RefreshBillProps {
  orderId: string,
  refreshBill: (orderId: string, isTab: boolean) => void;
  freshness: BillFreshness;
  isTab: boolean;
  billPaymentStatus: BillStatus;
}

function getColor(freshness: BillFreshness, isTab: boolean, billPaymentStatus: BillStatus) {
  if (freshness.stalled && !freshness.tooOld && [BillStatus.EMPTY, BillStatus.NOT_PAID, BillStatus.PARTIALLY_PAID].includes(billPaymentStatus)) {
    return isTab ? 'primary' : 'default';
  }
  return 'info';
}

export const RefreshBillButtonView = ({ orderId, refreshBill, freshness, isTab, billPaymentStatus }: RefreshBillProps) => {
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const triggerRefreshBill = () => async () => {
    setRefreshing(true);
    refreshBill(orderId, isTab);
    setTimeout(() => setRefreshing(false), 6 * 1000);
  };
  const color = getColor(freshness, isTab, billPaymentStatus);

  return (
    <>
      {!refreshing && (
        <>
          <IconButton
            color={color}
            onClick={triggerRefreshBill()}
          >
            <RefreshIcon />
          </IconButton>
        </>
      )}
      {refreshing && (<CircularProgress />)}
    </>
  );
};
