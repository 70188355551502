import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { useUpdateBoxDetails } from '../../../../hooks';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type QueueingSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const QueueingSetting = ({ box, setBox }: QueueingSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateOrdersQueue = async (displayOrdersQueue: boolean) => {
    makeUpdating(true);
    await updateBoxDetails({
      displayOrdersQueue,
    });
    setBox({
      ...box,
      displayOrdersQueue,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.displayOrderQueue',
        defaultMessage: 'display order queue',
        description: 'on venue settings page: field to allow customer to display the order queue for pickup',
      })}
      description={intl.formatMessage({
        id: 'venue.displayOrderQueueInfoMessage',
        defaultMessage: 'if this is active, the order queue will be displayed for Pickup service',
        description:
          "Description of the behaviour of the application when the toggle 'display order queue' is active. Useful only for Pickup service",
      })}
      toggleValue={box.displayOrdersQueue}
      toggleOnChange={updateOrdersQueue}
      disabled={isUpdating}
    />
  );
};
