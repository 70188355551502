import { IntlShape } from 'src/app/i18n/TypedIntl';
import { TimezoneType } from '../../../../types';

export type TimezoneOption = {
  id: TimezoneType;
  label: string;
};

export const timezoneOptions = (intl: IntlShape): TimezoneOption[] => [
  {
    id: TimezoneType.America_New_York,
    label: intl.formatMessage({
      id: 'venue.timezoneEasternTime',
      defaultMessage: 'Eastern Time',
      description: 'Timezone name for Eastern Time / New York',
    }),
  },
  {
    id: TimezoneType.America_Chicago,
    label: intl.formatMessage({
      id: 'venue.timezoneCentralTime',
      defaultMessage: 'Central Time',
      description: 'Timezone name for Central Time / Chicago',
    }),
  },
  {
    id: TimezoneType.America_Denver,
    label: intl.formatMessage({
      id: 'venue.timezoneMountainTime',
      defaultMessage: 'Mountain Time',
      description: 'Timezone name for Mountain Time / Denver',
    }),
  },
  {
    id: TimezoneType.America_Los_Angeles,
    label: intl.formatMessage({
      id: 'venue.timezonePacificTime',
      defaultMessage: 'Pacific Time',
      description: 'Timezone name for Pacific Time / Los Angeles',
    }),
  },
  {
    id: TimezoneType.America_Anchorage,
    label: intl.formatMessage({
      id: 'venue.timezoneAlaskaTime',
      defaultMessage: 'Alaska Time',
      description: 'Timezone name for Alaska Time / Anchorage',
    }),
  },
  {
    id: TimezoneType.America_Adak,
    label: intl.formatMessage({
      id: 'venue.timezoneHawaiiAleutianTime',
      defaultMessage: 'Hawaii-Aleutian Time',
      description: 'Timezone name for Hawaii-Aleutian Time / Adak',
    }),
  },
  {
    id: TimezoneType.Canada_Atlantic,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaAtlanticTime',
      defaultMessage: 'Canada Atlantic Time',
      description: 'Timezone name for Canada Atlantic Time',
    }),
  },
  {
    id: TimezoneType.Canada_Central,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaCentralTime',
      defaultMessage: 'Canada Central Time',
      description: 'Timezone name for Canada Central Time',
    }),
  },
  {
    id: TimezoneType.Canada_Eastern,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaEasternTime',
      defaultMessage: 'Canada Eastern Time',
      description: 'Timezone name for Canada Eastern Time',
    }),
  },
  {
    id: TimezoneType.Canada_Mountain,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaMountainTime',
      defaultMessage: 'Canada Mountain Time',
      description: 'Timezone name for Canada Mountain Time',
    }),
  },
  {
    id: TimezoneType.Canada_Newfoundland,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaNewfoundlandTime',
      defaultMessage: 'Canada Newfoundland Time',
      description: 'Timezone name for Canada Newfoundland Time',
    }),
  },
  {
    id: TimezoneType.Canada_Pacific,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaPacificTime',
      defaultMessage: 'Canada Pacific Time',
      description: 'Timezone name for Canada Pacific Time',
    }),
  },
  {
    id: TimezoneType.Canada_Saskatchewan,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaSaskatchewanTime',
      defaultMessage: 'Canada Saskatchewan Time',
      description: 'Timezone name for Canada Saskatchewan Time',
    }),
  },
  {
    id: TimezoneType.Canada_Yukon,
    label: intl.formatMessage({
      id: 'venue.timezoneCanadaYukonTime',
      defaultMessage: 'Canada Yukon Time',
      description: 'Timezone name for Canada Yukon Time',
    }),
  },
  {
    id: TimezoneType.France_AST,
    label: intl.formatMessage({
      id: 'venue.timezoneAtlanticTime',
      defaultMessage: 'UTC-4 (Guadeloupe, Martinique)',
      description: 'Timezone name for Atlantic Time',
    }),
  },
  {
    id: TimezoneType.France_Paris,
    label: intl.formatMessage({
      id: 'venue.timezoneParisTime',
      defaultMessage: 'Paris Time',
      description: 'Timezone name for Paris Time',
    }),
  },
  {
    id: TimezoneType.NONE,
    label: intl.formatMessage({
      id: 'venue.timezoneNone',
      defaultMessage: 'none',
      description: 'No timezone selected',
    }),
  },
];
