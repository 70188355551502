import { UserRole, userRoles } from 'src/domain/user/UserRole';
import { LocalisationKey } from 'src/lang/en';

export const userRoleLocalisations: Record<UserRole, LocalisationKey> = {
  [userRoles.ADMIN]: 'settings.user_permissions.roles.admin',
  [userRoles.MANAGER]: 'settings.user_permissions.roles.manager',
  [userRoles.GENERAL_MANAGER]: 'settings.user_permissions.roles.general_manager',
  [userRoles.WAITER]: 'settings.user_permissions.roles.waiter',
  [userRoles.STAFF]: 'settings.user_permissions.roles.staff',
};
