export const dimensions = {
  fonts: {
    xs: '0.6rem',
    s: '0.8rem',
    m: '0.9rem',
    l: '1.2rem',
    xl: '1.4rem',
    xxl: '2rem',
    xxxl: '3rem',
  },
  section_separator: {
    none: '0',
    xxs: '0.2rem',
    xs: '0.6rem',
    s: '0.8rem',
    m: '1rem',
    l: '1.2rem',
    xl: '1.4rem',
    xxl: '2rem',
    xxxl: '3rem',
  },
};

export const dimensionsPx = {
  fonts: {
    s: '16px',
    m: '18px',
    l: '24px',
    xl: '32px',
    xxl: '42px',
    xxxl: '56px',
  },
  line_height: {
    s: '16px',
    m: '20px',
    xl: '32px',
    xxl: '50.4px',
  },
  letter_spacing: {
    xs: '-2px',
    s: '-1.6px',
    m: '-0.54px',
    l: '-0.48px',
    xl: '-5%',
  },
  font_weight: {
    medium: '400',
  },
  section_separator: {
    none: '0',
    xxs: '4px',
    xs: '8px',
    s: '16px',
    l: '24px',
    xl: '32px',
    xxl: '42px',
    xxxl: '56px',
  },
};
