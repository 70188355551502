import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BlockRepositoryHttp } from '../../repositories/block/BlockRepositoryHttp';
import { Block } from '../../domain/Block';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_BLOCKS = 'menu_blocks';
const buildQueryKey = (businessId: BusinessId) =>
  [QUERYKEY_MENU_BLOCKS, businessId] as const;
export const getAllBlocksByBusinessIdQuery = async ({
  queryKey: [, businessId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Block[]> => {
  const configuration = ConfigurationLoader.load();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return blockRepository.findAllByBusinessId(businessId);
};

export const useGetAllBlocksByBusinessId = (businessId: BusinessId) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: getAllBlocksByBusinessIdQuery });
