import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { differenceMoneys, Money, MoneyView } from '@sundayapp/web-money';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { computeTaxes } from '../../../payment/utils/utils';
import { TaxSummary } from '../../../payment/types';

interface Props {
  total: Money;
  taxSummary: TaxSummary;
}

const TotalContainer = styled.div`
  padding: ${getSpacing(3)} 0;
  border-bottom: 1px solid ${colorPalette.placeholderColor};
`;

export const TotalLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colorPalette.grey600};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.03em;
  margin-bottom: ${getSpacing(0.5)};
`;

const TotalWithTaxSummary: React.FC<Props> = ({ total, taxSummary }) => {
  const totalTaxes = computeTaxes(taxSummary);
  const totalWithoutTaxes = differenceMoneys(total, totalTaxes);
  return (
    <TotalContainer>
      <TotalLine>
        <FormattedMessage id="orders.subtotal" defaultMessage="subtotal" />
        <MoneyView value={totalWithoutTaxes} />
      </TotalLine>
      <TotalLine>
        <FormattedMessage id="orders.tax" defaultMessage="tax" />
        <MoneyView value={totalTaxes} />
      </TotalLine>
    </TotalContainer>
  );
};

export default TotalWithTaxSummary;
