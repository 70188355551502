import { useMutation } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import axios from 'axios';

export type UploadPurpose =
  'bank_verification'
  | 'identity_verification'
  | 'company_verification'
  | 'financial_verification'
  | 'tax_verification'
  | 'articles_of_association'
  | 'company ownership'
  | 'proof_of_legality'
  | 'proof_of_principal_address'
  | 'shareholder_structure'
  | 'certified_authorised_signatory';

const uploadFile = async ({ document, businessId, purpose }: {
  document: File,
  businessId: BusinessId,
  purpose: UploadPurpose
}) => {
  const formData = new FormData();
  formData.append('file', document);
  return axios.put(
    `${ConfigurationLoader.load().paymentApiBaseUrl}/checkout/onboarding/${businessId.toString()}/files?purpose=${purpose}`,
    formData,
    { timeout: 30000 },
  );
};

export const downloadCheckoutDocument = (businessId: BusinessId, fileId: string) => {
  return axios.get<{ downloadLink: string }>(
    `${ConfigurationLoader.load().paymentApiBaseUrl}/checkout/onboarding/${businessId.toString()}/files/${fileId}`,
  ).then(response => response.data.downloadLink);
};

export const useUploadCheckoutDocument = (businessId: BusinessId) => {
  return useMutation({
    mutationFn: async ({ document, purpose }: { document: File, purpose: UploadPurpose }): Promise<string> => {
      const FOUR_MEGABYTES = 4 * 1024 * 1024;
      if (document.size > FOUR_MEGABYTES) {
        return Promise.reject('File should be less than 4MB');
      }
      try {
        const response = await uploadFile({ businessId, document, purpose });
        return response.data;
      } catch (error) {
        return Promise.reject('Failed to upload file');
      }
    },
  });
};
