import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CodeResponse } from '@react-oauth/google';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpGoogleSetup } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/HttpGoogleSetup';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { queryKeys } from 'src/app/queries/utils';
import {
  useGoogleOauthFacade,
} from 'src/sundayplus/reviews/settings/setup/google/oauth-facade/GoogleOauthFacadeContext';

const configuration = ConfigurationLoader.load();
const repository = new HttpGoogleSetup(configuration.venueFeedbackBaseUrl);

function useLinkGoogleAccountMutation() {
  const businessId = useBusinessIdOrThrow();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ code }: { code: string }) => {
      await repository.linkGoogleAccountToSunday(businessId, code);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.reviewsSetup.googleStatus(businessId) });
    },
  });
}

export function useUnlinkGoogleAccountMutation() {
  const businessId = useBusinessIdOrThrow();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await repository.unlink(businessId);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.reviewsSetup.all(businessId) });
    },
  });
}

export function useLinkGoogleAccountToSunday() {
  const linkAccountMutation = useLinkGoogleAccountMutation();
  const unlinkAccountMutation = useUnlinkGoogleAccountMutation();
  const googleOauthFacade = useGoogleOauthFacade();

  let resolve: (code: CodeResponse) => void;
  let reject: (error: unknown) => void;

  const promise = new Promise<CodeResponse>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  const googleLogin = googleOauthFacade.useGoogleLogin({
    onSuccess: async (codeResponse: CodeResponse) => {
      try {
        await linkAccountMutation.mutateAsync({ code: codeResponse.code });
        resolve(codeResponse);
      } catch (error) {
        reject(error);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onError: async (_: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>) => {
      reject(new Error('Impossible to connect your Google account. Please try again or contact sunday'));
    },
    scope: 'https://www.googleapis.com/auth/business.manage',
    flow: 'auth-code',
  });

  return {
    loginAndLink: () => {
      googleLogin();
      return promise;
    },
    unlink: unlinkAccountMutation.mutateAsync,
  };
}
