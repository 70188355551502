import React, { ReactNode } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled, { css } from 'styled-components';
import Tag from '../../../common/components/Tag';
import { OrderStatus } from '../../types';
import { colorPalette } from '../../../stylesheet';

interface Props {
  status: OrderStatus | undefined;
}

const orderStatusBackgroundColor: Record<OrderStatus, string> = {
  [OrderStatus.ACCEPTED]: colorPalette.lightGreen,
  [OrderStatus.PARSED]: colorPalette.peach,
  [OrderStatus.RECEIVED]: colorPalette.peach,
  [OrderStatus.NEW]: colorPalette.peach,
  [OrderStatus.PRINTED]: colorPalette.peach,
  [OrderStatus.PREPARING]: colorPalette.peach,
  [OrderStatus.PREPARED]: colorPalette.peach,
  [OrderStatus.PLACED]: colorPalette.peach,
  [OrderStatus.BOX_ORDERS_PLACED]: colorPalette.peach,
  [OrderStatus.READY_FOR_PICKUP]: colorPalette.lightGreen,
  [OrderStatus.IN_DELIVERY]: colorPalette.lightBlue,
  [OrderStatus.FINALIZED]: colorPalette.lightGreen,
  [OrderStatus.AUTO_FINALIZED]: colorPalette.lightGreen,
  [OrderStatus.RESOLVED]: colorPalette.peach,
  [OrderStatus.FULLY_PAID]: colorPalette.peach,
  [OrderStatus.DUPLICATE]: colorPalette.lightRed,
  [OrderStatus.CANCEL]: colorPalette.lightRed,
  [OrderStatus.CANCELED]: colorPalette.lightRed,
  [OrderStatus.FAILED]: colorPalette.lightRed,
  [OrderStatus.POS_RECEIVED_FAILED]: colorPalette.lightRed,
  [OrderStatus.PARSE_FAILED]: colorPalette.lightRed,
  [OrderStatus.DELIVERECT_NOTIFICATION_FAILED]: colorPalette.lightRed,
  [OrderStatus.NOTIFIED_TO_DELIVERECT]: colorPalette.peach,
  [OrderStatus.NOTIFIED_TO_POS]: colorPalette.peach,
  [OrderStatus.WAITING_FOR_GROUPING]: colorPalette.peach,
  [OrderStatus.ORDER_GROUPED]: colorPalette.peach,
  [OrderStatus.SCHEDULED]: colorPalette.peach,
  [OrderStatus.QUEUED]: colorPalette.peach,
};

const orderStatusColor: Record<OrderStatus, string> = {
  [OrderStatus.ACCEPTED]: colorPalette.green,
  [OrderStatus.PARSED]: colorPalette.orange,
  [OrderStatus.RECEIVED]: colorPalette.orange,
  [OrderStatus.NEW]: colorPalette.orange,
  [OrderStatus.PRINTED]: colorPalette.orange,
  [OrderStatus.PREPARING]: colorPalette.orange,
  [OrderStatus.PREPARED]: colorPalette.orange,
  [OrderStatus.PLACED]: colorPalette.orange,
  [OrderStatus.BOX_ORDERS_PLACED]: colorPalette.orange,
  [OrderStatus.READY_FOR_PICKUP]: colorPalette.green,
  [OrderStatus.IN_DELIVERY]: colorPalette.blue,
  [OrderStatus.FINALIZED]: colorPalette.green,
  [OrderStatus.AUTO_FINALIZED]: colorPalette.green,
  [OrderStatus.RESOLVED]: colorPalette.orange,
  [OrderStatus.FULLY_PAID]: colorPalette.orange,
  [OrderStatus.DUPLICATE]: colorPalette.red,
  [OrderStatus.CANCEL]: colorPalette.red,
  [OrderStatus.CANCELED]: colorPalette.red,
  [OrderStatus.FAILED]: colorPalette.red,
  [OrderStatus.POS_RECEIVED_FAILED]: colorPalette.red,
  [OrderStatus.PARSE_FAILED]: colorPalette.red,
  [OrderStatus.DELIVERECT_NOTIFICATION_FAILED]: colorPalette.red,
  [OrderStatus.NOTIFIED_TO_DELIVERECT]: colorPalette.orange,
  [OrderStatus.NOTIFIED_TO_POS]: colorPalette.orange,
  [OrderStatus.WAITING_FOR_GROUPING]: colorPalette.orange,
  [OrderStatus.ORDER_GROUPED]: colorPalette.orange,
  [OrderStatus.SCHEDULED]: colorPalette.orange,
  [OrderStatus.QUEUED]: colorPalette.orange,
};

export const OrderStatusContainer = styled(Tag)<Props>`
  ${({ status }) =>
    status
    && css`
      background-color: ${orderStatusBackgroundColor[status]};
      color: ${orderStatusColor[status]};
    `}
`;

const labelPerStatus: Record<OrderStatus, ReactNode> = {
  [OrderStatus.ACCEPTED]: <FormattedMessage id="order.ACCEPTED" defaultMessage="accepted" />,
  [OrderStatus.PARSED]: <FormattedMessage id="order.PARSED" defaultMessage="parsed" />,
  [OrderStatus.NEW]: <FormattedMessage id="order.NEW" defaultMessage="new" />,
  [OrderStatus.PRINTED]: <FormattedMessage id="order.PRINTED" defaultMessage="printed" />,
  [OrderStatus.PREPARING]: <FormattedMessage id="order.PREPARING" defaultMessage="preparing" />,
  [OrderStatus.PREPARED]: <FormattedMessage id="order.PREPARED" defaultMessage="prepared" />,
  [OrderStatus.PLACED]: <FormattedMessage id="order.PLACED" defaultMessage="placed" />,
  [OrderStatus.BOX_ORDERS_PLACED]: <FormattedMessage id="order.PLACED" defaultMessage="placed" />,
  [OrderStatus.RECEIVED]: <FormattedMessage id="order.RECEIVED" defaultMessage="received" />,
  [OrderStatus.READY_FOR_PICKUP]: <FormattedMessage id="order.READY_FOR_PICKUP" defaultMessage="ready for pickup" />,
  [OrderStatus.IN_DELIVERY]: <FormattedMessage id="order.IN_DELIVERY" defaultMessage="in delivery" />,
  [OrderStatus.FINALIZED]: <FormattedMessage id="order.FINALIZED" defaultMessage="complete" />,
  [OrderStatus.AUTO_FINALIZED]: <FormattedMessage id="order.AUTO_FINALIZED" defaultMessage="complete" />,
  [OrderStatus.RESOLVED]: <FormattedMessage id="order.RESOLVED" defaultMessage="resolved" />,
  [OrderStatus.FULLY_PAID]: <FormattedMessage id="order.FULLY_PAID" defaultMessage="paid" />,
  [OrderStatus.DUPLICATE]: <FormattedMessage id="order.DUPLICATE" defaultMessage="duplicate" />,
  [OrderStatus.CANCEL]: <FormattedMessage id="order.CANCEL" defaultMessage="cancel" />,
  [OrderStatus.CANCELED]: <FormattedMessage id="order.CANCELED" defaultMessage="canceled" />,
  [OrderStatus.FAILED]: <FormattedMessage id="order.FAILED" defaultMessage="failed" />,
  [OrderStatus.SCHEDULED]: <FormattedMessage id="order.SCHEDULED" defaultMessage="scheduled" />,
  [OrderStatus.POS_RECEIVED_FAILED]: (
    <FormattedMessage id="order.POS_RECEIVED_FAILED" defaultMessage="pos received failed" />
  ),
  [OrderStatus.PARSE_FAILED]: <FormattedMessage id="order.PARSE_FAILED" defaultMessage="parse failed" />,
  [OrderStatus.DELIVERECT_NOTIFICATION_FAILED]: (
    <FormattedMessage id="order.DELIVERECT_NOTIFICATION_FAILED" defaultMessage="notification failed" />
  ),
  [OrderStatus.NOTIFIED_TO_DELIVERECT]: (
    <FormattedMessage id="order.NOTIFIED_TO_DELIVERECT" defaultMessage="notified" />
  ),
  [OrderStatus.NOTIFIED_TO_POS]: (
    <FormattedMessage
      id="order.NOTIFIED_TO_DELIVERECT" // same as NOTIFIED_TO_DELIVERECT, so use same key
      defaultMessage="notified"
    />
  ),
  [OrderStatus.WAITING_FOR_GROUPING]: <FormattedMessage id="order.WAITING_FOR_GROUPING" defaultMessage="grouping" />,
  [OrderStatus.ORDER_GROUPED]: <FormattedMessage id="order.ORDER_GROUPED" defaultMessage="groupid" />,
  [OrderStatus.QUEUED]: <FormattedMessage id="order.QUEUED" defaultMessage="queued" />,
};

const getLabelPerStatus = (status: OrderStatus) => labelPerStatus[status];

const OrderStatusLabel: React.FC<Props> = ({ status = OrderStatus.FINALIZED }) => (
  <OrderStatusContainer status={status} data-testid="order-status">
    {getLabelPerStatus(status)}
  </OrderStatusContainer>
);

export default OrderStatusLabel;
