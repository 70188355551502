import React, { useCallback, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { FilterTag } from './FilterTag';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { OrderingMenu } from '../../../orderingMenus/domain/OrderingMenu';

interface Props {
  menus?: OrderingMenu[];
  setCurrentFilter: (filter?: string) => void;
  numberOfOutOfStockProducts: number;
}

const FilterTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: ${getSpacing(0.5)} ${getSpacing(2)};
  margin-top: ${getSpacing(2.5)};
`;

const CountTag = styled.div`
  display: inline-block;
  color: ${colorPalette.dust};
  background-color: ${colorPalette.cement};
  padding: ${getSpacing(0.5)};
  margin: ${getSpacing(0.5)};
  border-radius: ${getSpacing(3)};
  min-width: 20px;
  min-height: 20px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  text-align: center;
`;

export const LIMITED_STOCK_FILTER = 'LIMITED_STOCK_FILTER';
export const OUT_OF_STOCK_FILTER = 'OUT_OF_STOCK_FILTER';

export const AvailabilityFilterTags: React.FC<Props> = ({ menus, setCurrentFilter, numberOfOutOfStockProducts }) => {
  const intl = useIntl();
  const [selectedFilter, setSelectedFilter] = useState<string>();

  const changeFilter = useCallback(
    (filter?: string) => {
      const newFilter = selectedFilter === filter ? undefined : filter; // Toggle filter if already selected
      setSelectedFilter(newFilter);
      setCurrentFilter(newFilter);
    },
    [selectedFilter, setCurrentFilter],
  );

  return (
    <FilterTagsContainer>
      <FilterTag
        name={intl.formatMessage({ id: 'product.availability.allProducts', defaultMessage: 'all products' })}
        setSelected={() => {
          changeFilter(undefined);
        }}
        selected={!selectedFilter}
      />
      {menus
        && menus.map((menu) => (
          <FilterTag
            key={menu.id}
            name={menu.name}
            setSelected={() => {
              changeFilter(menu.id);
            }}
            selected={menu.id === selectedFilter}
          />
        ))}
      <FilterTag
        name={intl.formatMessage({ id: 'product.availability.limitedStock', defaultMessage: 'limited stock' })}
        setSelected={() => {
          changeFilter(LIMITED_STOCK_FILTER);
        }}
        selected={LIMITED_STOCK_FILTER === selectedFilter}
      />
      <FilterTag
        name={intl.formatMessage({ id: 'product.availability.outOfStock', defaultMessage: 'out of stock' })}
        setSelected={() => {
          changeFilter(OUT_OF_STOCK_FILTER);
        }}
        selected={OUT_OF_STOCK_FILTER === selectedFilter}
        prefix={<CountTag>{numberOfOutOfStockProducts}</CountTag>}
      />
    </FilterTagsContainer>
  );
};
