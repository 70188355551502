import { EReputationKpi, EReputationKpisByTimeframe, getEReputationKpiByType, GlobalKpis, GraphKpis, StarDistribution, TemporalAverageKpi, TemporalKpi } from './domain/EReputationKpis';
import { FOUR_WEEKS, KpiTimeframe, SEVEN_DAYS, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { average, count, Kpi, KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

export type ChartValue = number;

export type RatingsCountGraphData = {
  fromSunday: ChartValue[];
  fromGoogle: ChartValue[]
};

export type StarsDistributionChartData = {
  value: number;
  starsDistribution: StarDistribution[];
};

export type RatingsAverageGraphData = {
  fromSunday: StarsDistributionChartData[];
  fromGoogle: ChartValue[]
};

export type GraphData = RatingsAverageGraphData | RatingsCountGraphData;

export type EReputationKpisViewModel = {
  kpiType: KpiName;
  globalKpis: GlobalKpis,
  graphKpis: GraphData;
} | {
  kpiType: KpiName;
  globalKpis: undefined,
  graphKpis: undefined;
};

export type EReputationKpisViewModelByTimeframe = {
  timeframe: KpiTimeframe;
  eReputationKpisViewModels: EReputationKpisViewModel[];
};

export const emptyStarsDistributionChartData: StarsDistributionChartData = {
  value: 0,
  starsDistribution: [],
};

const charValues = (timeframe: KpiTimeframe, temporalKpis: TemporalKpi[]) => timeframe.map((comparator) => (dateInRange) => {
  const temporalKpi = temporalKpis.find((kpi) => comparator(kpi.unit, dateInRange));
  if (temporalKpi) return temporalKpi.value;
  return 0;
});

const starsDistributionChartData = (timeframe: KpiTimeframe, temporalAverageKpis: TemporalAverageKpi[]) => timeframe.map((comparator) => (dateInRange) => {
  const temporalAverageKpi = temporalAverageKpis.find((kpi) => comparator(kpi.unit, dateInRange));
  if (temporalAverageKpi) {
    return {
      value: temporalAverageKpi.value,
      starsDistribution: temporalAverageKpi.starsDistribution,
    };
  }
  return emptyStarsDistributionChartData;
});

export const ratingsCountGraphDataFromGraph = (timeframe: KpiTimeframe, graphKpis: GraphKpis): RatingsCountGraphData => ({
  fromSunday: charValues(timeframe, graphKpis.fromSunday),
  fromGoogle: charValues(timeframe, graphKpis.fromGoogle),
});

export const ratingsCountGraphData = (timeframe: KpiTimeframe, eReputationKpiByType: EReputationKpi): RatingsCountGraphData => {
  const graphKpis: GraphKpis = eReputationKpiByType.graphKpis;
  return ratingsCountGraphDataFromGraph(timeframe, graphKpis);
};

export const ratingsAverageGraphData = (timeframe: KpiTimeframe, rep: EReputationKpi): RatingsAverageGraphData => ({
  fromSunday: starsDistributionChartData(timeframe, rep.graphKpis.fromSunday as TemporalAverageKpi[]),
  fromGoogle: charValues(timeframe, rep.graphKpis.fromGoogle),
});

export const getEReputationKpisByTimeframeType = (
  eReputationKpisByTimeframes: EReputationKpisByTimeframe[],
  timeframeKpiType: KpiTimeframe,
): EReputationKpisByTimeframe | undefined => eReputationKpisByTimeframes
  .filter((v) => v.timeframeKpiType === timeframeKpiType)[0];

const eReputationKpisViewModel = (kpi: Kpi<any>, eReputationKpisByTimeframes: EReputationKpisByTimeframe, timeframe: KpiTimeframe): EReputationKpisViewModel => {
  const eReputationKpiByType = getEReputationKpiByType(eReputationKpisByTimeframes, kpi.type);
  return eReputationKpiByType ? ({
    kpiType: kpi.type,
    globalKpis: eReputationKpiByType ? eReputationKpiByType.globalKpis : undefined,
    graphKpis: eReputationKpiByType ? kpi.computeGraphData(timeframe, eReputationKpiByType) : undefined,
  }) : ({
    kpiType: kpi.type,
    globalKpis: undefined,
    graphKpis: undefined,
  });
};

export const AVERAGE_RATING: Kpi<RatingsAverageGraphData> = average('AVG_RATING');
export const TOTAL_REVIEWS: Kpi<RatingsCountGraphData> = count('TOTAL_REVIEWS');
export const TOTAL_FIVE_STARS: Kpi<RatingsCountGraphData> = count('TOTAL_FIVE_STARS');

const eReputationKpisViewModelByTimeframe = (timeframe: KpiTimeframe, eReputationKpisByTimeframes: EReputationKpisByTimeframe[]): EReputationKpisViewModelByTimeframe => {
  const eReputationKpisByTimeframeType = getEReputationKpisByTimeframeType(eReputationKpisByTimeframes, timeframe);
  return eReputationKpisByTimeframeType ? ({
    timeframe: timeframe,
    eReputationKpisViewModels: [
      eReputationKpisViewModel(AVERAGE_RATING, eReputationKpisByTimeframeType, timeframe),
      eReputationKpisViewModel(TOTAL_REVIEWS, eReputationKpisByTimeframeType, timeframe),
      eReputationKpisViewModel(TOTAL_FIVE_STARS, eReputationKpisByTimeframeType, timeframe),
    ],
  }) : ({
    timeframe: timeframe,
    eReputationKpisViewModels: [],
  });
};

export const fromDomain = (eReputationKpisByTimeframes: EReputationKpisByTimeframe[]): EReputationKpisViewModelByTimeframe[] =>
  [
    eReputationKpisViewModelByTimeframe(SEVEN_DAYS, eReputationKpisByTimeframes),
    eReputationKpisViewModelByTimeframe(FOUR_WEEKS, eReputationKpisByTimeframes),
    eReputationKpisViewModelByTimeframe(TWELVE_MONTHS, eReputationKpisByTimeframes),
  ];
