import { HomeCard } from '../HomeCard';
import { useEReputationRepository } from 'src/sundayplus/reviews/configuration/ReviewContext.hook';
import { useAsync } from 'react-use';
import { fromDomain } from 'src/sundayplus/reviews/insights/EReputationViewModel';
import { EmptyCard } from './EmptyCard';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { HomeReviewAverage } from './HomeReviewAverage';
import GoogleSvg from '../../../app/component/icons/GoogleSvg';
import { Business } from 'src/business/domain/Business';
import { SEVEN_DAYS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { GlobalKpis } from 'src/sundayplus/reviews/insights/domain/EReputationKpis';

type HomeReviewsCardWithSubscriptionAndGoogleSetupFinishedV2Props = {
  business: Business;
};

export const HomeReviewsCardWithSubscriptionAndGoogleSetupFinished = ({
  business,
}: HomeReviewsCardWithSubscriptionAndGoogleSetupFinishedV2Props) => {
  const intl = useIntl();

  const repository = useEReputationRepository();
  const {
    value: eReputationKpisViewModelByTimeframes,
    loading: eReputationKpisViewModelByTimeframesLoading,
    error: eReputationKpisViewModelByTimeframesError,
  } = useAsync(async () => {
    const eReputationKpis = await repository.getKpis(business.id, business.timezone);
    return eReputationKpis.eReputationKpisByTimeframes
      ? fromDomain(eReputationKpis.eReputationKpisByTimeframes)
      : undefined;
  });

  if (eReputationKpisViewModelByTimeframesError || eReputationKpisViewModelByTimeframes === undefined) {
    return null;
  }

  if (eReputationKpisViewModelByTimeframesLoading) {
    return <EmptyCard />;
  }

  const timeFrame = eReputationKpisViewModelByTimeframes?.find((model) => model.timeframe === SEVEN_DAYS);
  if (!timeFrame) {
    return <EmptyCard />;
  }

  const totalFiveStarsReviews = timeFrame.eReputationKpisViewModels.find(
    (model) => model.kpiType === 'TOTAL_FIVE_STARS',
  );

  const averageRating = timeFrame.eReputationKpisViewModels.find((model) => model.kpiType === 'AVG_RATING');
  const fiveStarGlobalKpis: GlobalKpis | undefined = totalFiveStarsReviews?.globalKpis;
  const averageGlobalKpis: GlobalKpis | undefined = averageRating?.globalKpis;

  if (!totalFiveStarsReviews || !fiveStarGlobalKpis || !averageGlobalKpis) {
    return null;
  }

  const trend = fiveStarGlobalKpis?.differenceWithPreviousTimeframe || 0;
  return (
    <HomeCard
      titleIcon={<GoogleSvg size={16} />}
      title={intl.formatMessage({ id: 'venue.home.reviews.title' })}
      value={fiveStarGlobalKpis?.value?.toString() ?? '0'}
      trend={trend}
      subItems={[
        {
          label: intl.formatMessage({ id: 'venue.home.reviews.five-stars-google-reviews' }),
          value: fiveStarGlobalKpis?.withSunday?.number?.toString(),
        },
      ]}
      cta={<HomeReviewAverage business={business} averageRating={averageGlobalKpis?.value} />}
    />
  );
};
