import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, CircularProgress, Input, Typography } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

type MenuNameSettingsProps = {
  menu: Menu;
};

export const MenuNameSettings = ({ menu }: MenuNameSettingsProps) => {
  const intl = useIntl();
  const businessId = useBusinessIdOrThrow();
  const useSnackBar = useSnackbar();

  const updateMenu = useUpdateMenuMutation(businessId, menu.id);
  const [menuName, setMenuName] = useState<string>(menu.name);
  const [menuNameError, setMenuNameError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const isValidName = (): boolean => menuName.trim().length > 0;
  const hasChanged = menu.name.localeCompare(menuName) !== 0 && isValidName();

  const onClickUpdateMenuName = async () => {
    const cleanedMenuName = menuName.trim();
    if (cleanedMenuName.length === 0) {
      setMenuNameError(
        intl.formatMessage({ id: 'menus.settings.name.name-required', defaultMessage: 'menu name required' }),
      );
      return;
    }
    setMenuNameError('');
    setLoading(true);
    updateMenu
      .mutateAsync({ name: cleanedMenuName })
      .then(() => {
        setMenuName(cleanedMenuName);
        useSnackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'menus.settings.name.notification', defaultMessage: 'menu name updated' }),
        });
      })
      .catch(() => {
        setMenuNameError(
          intl.formatMessage({
            id: 'menus.settings.name.update-error',
            defaultMessage: 'unable to update menu name',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const onMenuNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenuName(event.target.value);
  };

  return (
    <Box>
      <FormattedMessage id="menus.settings.name.label" defaultMessage="name of your menu" />

      <Box flexDirection="row" display="flex">
        <Input
          onChange={onMenuNameChange}
          error={!!menuNameError}
          sx={{ width: '300px' }}
          value={menuName}
          placeholder={intl.formatMessage({
            id: 'menus.settings.name.placeholder',
            defaultMessage: 'input the name of your menu',
          })}
        />

        {hasChanged && (
          <Button size="small" onClick={onClickUpdateMenuName} disabled={loading} variant="contained">
            <FormattedMessage id="menus.settings.save-btn" defaultMessage="save" />
          </Button>
        )}
        {loading && <CircularProgress />}
      </Box>
      {menuNameError && (
        <Typography variant="body1" color="error" component="div">
          {menuNameError}
        </Typography>
      )}
    </Box>
  );
};
