/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const SundaySvg: React.FC<Props> = ({ size = 150, ...props }: Props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 400 400"
    {...props}
  >
    <g
      transform="translate(0.000000,423.000000) scale(0.100000,-0.100000)"
      fill="#fc1cec"
      stroke="none"
    >
      <path d="M2280 3634 c-8 -3 -22 -8 -30 -11 -8 -4 -19 -5 -24 -4 -21 5 -227
-74 -287 -110 -36 -21 -70 -39 -76 -39 -6 0 -13 -7 -17 -15 -6 -16 -40 -15
-136 6 -30 6 -125 12 -210 12 -153 1 -234 -10 -290 -38 -14 -7 -44 -18 -67
-24 -24 -7 -43 -16 -43 -22 0 -6 -4 -8 -9 -4 -8 4 -76 -29 -91 -45 -3 -3 -21
-14 -40 -24 -100 -54 -260 -210 -281 -273 -6 -18 -15 -33 -20 -33 -5 0 -9 -6
-9 -13 0 -7 -9 -30 -20 -52 -11 -22 -20 -45 -20 -52 0 -8 -7 -16 -15 -19 -8
-4 -23 -23 -32 -43 -9 -20 -20 -38 -23 -41 -12 -10 -49 -126 -61 -190 -7 -36
-12 -114 -12 -175 1 -135 23 -225 88 -358 l45 -94 -40 -69 c-22 -38 -52 -106
-67 -152 -32 -103 -30 -216 6 -299 57 -129 264 -248 444 -255 36 -1 73 -7 81
-14 9 -6 21 -38 26 -70 10 -57 14 -69 39 -124 30 -67 79 -132 93 -124 4 3 8
-1 8 -8 0 -7 12 -21 28 -30 15 -10 34 -22 42 -27 36 -23 91 -41 155 -51 81
-13 240 -5 300 16 22 8 47 14 55 14 20 0 185 91 217 119 7 6 17 11 22 11 6 0
15 9 21 20 6 11 14 19 18 18 4 -2 32 21 62 50 l55 53 60 -7 c77 -9 156 -7 169
6 6 6 23 10 38 10 15 0 28 5 28 10 0 6 9 10 20 10 10 0 23 6 27 13 4 7 27 21
49 31 52 22 168 139 237 237 52 73 54 74 122 92 38 10 91 30 118 45 65 35 179
131 195 164 7 15 20 29 30 33 23 8 82 89 82 114 0 11 12 34 28 52 27 33 27 34
27 183 l0 151 -42 96 c-22 53 -64 131 -92 174 -28 43 -51 82 -51 88 0 6 -15
24 -33 41 -48 44 -61 71 -42 86 8 7 13 16 10 20 -3 5 4 30 16 57 11 26 25 73
30 103 6 30 15 67 20 82 8 18 7 32 -1 47 -7 14 -7 21 0 21 14 0 2 109 -16 137
-8 12 -14 29 -14 37 0 21 -59 118 -81 134 -11 7 -19 20 -19 28 0 22 -139 154
-205 193 -31 19 -93 48 -138 64 -78 29 -91 30 -262 33 -99 2 -187 1 -195 -2z
m60 -153 c0 -5 62 -11 138 -14 148 -5 207 -20 299 -74 58 -33 78 -49 112 -87
14 -16 33 -37 41 -45 8 -9 30 -39 47 -66 18 -28 36 -54 40 -60 8 -10 9 -18 17
-83 5 -41 14 -51 27 -32 7 11 8 1 4 -34 -4 -27 -13 -55 -21 -63 -8 -8 -14 -25
-14 -38 0 -13 -3 -31 -7 -41 -4 -12 -3 -15 6 -10 8 5 7 -5 -4 -30 -9 -21 -17
-39 -19 -41 -2 -2 -11 18 -21 44 -10 27 -33 72 -52 101 -18 29 -33 55 -33 57
0 3 -39 45 -88 93 -70 70 -99 92 -147 111 -33 12 -68 27 -79 33 -10 5 -30 12
-45 14 -43 8 -61 15 -61 25 0 5 -8 9 -18 9 -10 0 -22 4 -28 10 -5 5 -28 11
-50 13 -23 2 -46 7 -53 11 -6 4 -14 5 -18 3 -14 -8 -78 28 -78 43 0 8 -21 28
-46 45 -38 25 -45 34 -38 50 4 11 8 14 8 7 1 -9 12 -11 39 -7 80 13 84 15 79
35 -3 12 1 21 12 23 29 8 51 7 51 -2z m-328 -330 c50 -2 78 -54 36 -66 -13 -4
-33 -17 -45 -28 -20 -18 -26 -19 -48 -7 -14 7 -25 18 -25 24 -1 15 -69 66 -89
67 -13 0 -12 2 2 8 16 6 57 6 169 2z m-786 -66 c10 -8 14 -15 9 -15 -6 0 -16
-13 -23 -30 -8 -16 -20 -30 -28 -30 -8 0 -14 -5 -14 -11 0 -6 -18 -33 -40 -59
-22 -26 -40 -52 -40 -57 0 -14 -24 -63 -32 -63 -3 0 -9 -13 -13 -30 -4 -16
-10 -30 -13 -30 -11 0 -32 -51 -32 -76 0 -12 -7 -25 -16 -28 -9 -3 -14 -12
-11 -20 5 -13 -10 -69 -26 -93 -5 -7 -6 -17 -2 -22 3 -5 1 -30 -5 -55 -5 -25
-11 -69 -12 -98 -2 -57 -3 -59 -80 -122 l-47 -38 -18 36 c-9 20 -22 36 -29 36
-17 0 -45 88 -37 118 4 18 3 23 -6 18 -8 -6 -10 -1 -6 17 4 14 2 28 -4 31 -23
15 -7 183 20 206 3 3 8 13 12 23 4 12 1 17 -11 17 -16 1 -16 1 1 11 9 5 17 19
17 29 0 11 5 20 10 20 6 0 10 6 10 14 0 14 100 106 185 172 45 34 98 64 115
64 7 0 24 8 38 19 15 10 42 22 60 26 18 3 32 13 32 21 0 18 11 18 36 -1z
m1158 -16 c7 6 35 5 79 -3 37 -7 69 -11 72 -8 3 3 5 0 5 -6 0 -12 80 -55 88
-47 2 2 18 -3 35 -11 28 -15 29 -18 24 -64 -8 -63 -39 -123 -72 -138 -34 -16
-32 -16 -50 17 -8 16 -31 39 -51 52 -20 12 -42 36 -50 53 -7 17 -34 53 -59 81
-25 27 -45 59 -44 70 0 11 3 14 6 7 3 -9 9 -10 17 -3z m-650 -36 c11 -7 15
-13 8 -13 -7 0 -11 -4 -8 -9 3 -4 16 -6 30 -4 34 7 42 -14 8 -22 -16 -3 -33
-5 -38 -4 -5 1 -11 0 -14 -1 -9 -3 -44 -9 -75 -13 -17 -2 -40 -9 -52 -15 -12
-7 -32 -16 -45 -21 -21 -8 -24 -6 -23 13 0 12 17 42 37 67 33 40 42 45 73 43
19 -2 35 1 35 7 0 5 9 4 21 -3 12 -7 31 -19 43 -25z m-96 -252 c-23 -20 -44
-35 -48 -34 -3 1 -8 -7 -12 -18 -4 -10 -16 -19 -27 -19 -10 0 -21 -6 -24 -13
-3 -8 -20 -22 -39 -31 -18 -9 -46 -25 -63 -36 -16 -10 -32 -19 -35 -19 -13 -2
-2 48 13 64 10 9 15 21 12 26 -4 5 1 9 10 9 18 0 45 23 45 38 0 12 49 34 61
26 6 -3 15 -1 22 4 7 6 17 11 22 13 23 5 54 22 50 29 -2 3 10 4 26 2 l30 -4
-43 -37z m442 -131 c15 -16 33 -30 39 -30 5 0 17 -23 26 -52 18 -61 21 -163 4
-152 -6 3 -15 1 -19 -7 -7 -10 -16 -6 -44 14 -19 15 -40 27 -46 27 -6 0 -28 9
-50 20 -22 11 -47 20 -56 20 -10 0 -34 8 -55 17 l-38 16 78 54 c43 29 87 65
97 78 24 32 30 31 64 -5z m729 24 c22 -5 31 -12 31 -26 0 -14 5 -18 15 -14 8
3 15 1 15 -4 0 -6 -6 -10 -14 -10 -14 0 -26 -21 -26 -47 0 -8 -18 -37 -41 -64
-40 -48 -75 -62 -105 -41 -18 12 -67 82 -58 82 5 0 3 4 -5 9 -20 13 62 108 98
114 46 7 58 7 90 1z m259 -170 c15 -15 21 -23 13 -19 -9 5 -12 3 -9 -6 3 -8
10 -13 16 -12 14 3 96 -128 129 -208 20 -49 26 -79 24 -126 -1 -38 2 -63 9
-63 13 0 13 -87 0 -95 -6 -4 -10 -12 -9 -18 3 -25 -2 -37 -46 -96 -25 -34 -49
-59 -54 -57 -5 3 -11 -3 -14 -14 -3 -11 -13 -20 -22 -20 -9 0 -35 -15 -58 -34
-44 -35 -106 -70 -113 -64 -2 3 10 26 26 52 74 115 80 137 81 259 0 103 -2
118 -30 185 -16 40 -49 99 -71 132 l-42 59 33 47 c33 48 98 124 106 124 2 0
16 -12 31 -26z m-1559 -116 c-8 -14 -10 -48 -6 -97 5 -70 8 -78 38 -103 18
-15 45 -30 61 -33 15 -4 28 -10 28 -15 0 -13 120 -18 195 -8 39 6 83 11 98 12
15 2 33 8 39 14 7 7 22 12 34 12 12 0 24 7 28 15 3 8 10 15 16 15 5 0 0 -11
-12 -25 -12 -14 -32 -25 -44 -25 -12 0 -38 -14 -58 -32 -27 -24 -48 -33 -84
-36 -26 -2 -48 -8 -50 -13 -5 -14 -22 -11 -20 3 2 7 -6 13 -17 14 -69 6 -122
6 -138 -1 -14 -6 -16 -4 -10 10 5 15 2 17 -20 11 -16 -4 -27 -2 -27 4 0 5 -9
10 -21 10 -28 0 -61 19 -54 31 4 5 -1 9 -10 9 -11 0 -15 6 -11 18 5 14 -2 22
-30 32 -24 10 -42 27 -55 53 -19 37 -18 77 2 77 5 0 9 5 9 11 0 18 32 38 62
39 15 0 30 5 33 10 3 6 13 10 21 10 12 0 13 -4 3 -22z m389 -13 c17 -4 32 -12
32 -17 0 -6 7 -8 15 -4 20 7 19 -14 -4 -43 -13 -16 -24 -22 -36 -17 -11 4 -23
0 -32 -11 -8 -10 -12 -12 -8 -5 4 7 0 12 -9 12 -9 0 -16 -6 -17 -12 0 -10 -2
-10 -6 0 -5 14 -33 17 -33 3 0 -11 -81 -2 -97 12 -9 6 -13 6 -13 -2 0 -8 -6
-8 -20 1 -24 15 -24 21 -4 63 l15 33 92 -3 c51 -1 107 -6 125 -10z m812 -378
c0 -5 11 -33 24 -61 13 -28 27 -75 30 -104 5 -51 4 -53 -45 -112 -27 -33 -55
-60 -62 -60 -39 0 -103 73 -143 163 l-18 40 24 16 c44 29 141 112 146 126 6
15 44 8 44 -8z m-1973 -191 c44 -49 229 -186 252 -186 10 0 20 -37 26 -97 2
-21 8 -66 15 -101 13 -72 8 -85 -28 -78 -68 13 -142 16 -142 5 0 -31 -154 39
-178 80 -16 29 -24 35 -38 29 -3 -2 -16 15 -30 37 -23 39 -33 95 -15 95 5 0
13 18 19 40 8 31 7 40 -3 40 -7 0 -16 -6 -18 -12 -4 -10 -6 -10 -6 -1 -1 7 8
17 19 23 16 9 18 14 8 29 -9 15 -5 31 20 85 17 36 35 66 41 66 5 0 31 -24 58
-54z m1632 -66 c6 0 11 -11 11 -24 0 -13 7 -29 15 -36 8 -7 15 -17 15 -23 0
-5 27 -37 60 -69 68 -68 80 -95 38 -90 -28 3 -38 -12 -15 -22 6 -3 3 -4 -8 -1
-13 3 -30 -4 -47 -20 -15 -14 -31 -25 -35 -24 -4 0 -9 -4 -11 -10 -2 -6 1 -11
8 -11 6 0 8 -5 5 -10 -4 -6 -15 -8 -26 -5 -15 5 -19 1 -20 -17 -1 -18 -2 -19
-6 -5 -3 10 1 24 7 32 6 8 9 22 7 32 -3 10 -2 38 2 63 4 26 1 71 -6 107 -7 34
-10 69 -7 76 3 8 -1 21 -8 28 -7 8 -14 25 -15 39 -3 23 -2 23 11 8 7 -10 18
-18 25 -18z m-157 -105 c14 -36 21 -68 16 -71 -5 -4 -5 -10 0 -17 10 -13 9
-42 -3 -70 -4 -9 -5 -29 -3 -42 4 -17 1 -25 -8 -25 -10 0 -12 -9 -7 -30 5 -25
3 -29 -11 -24 -12 4 -16 0 -16 -15 0 -12 -5 -21 -11 -21 -6 0 -9 -7 -5 -15 3
-8 1 -15 -4 -15 -6 0 -10 -11 -10 -25 0 -18 -6 -25 -20 -26 -11 -1 -26 -2 -32
-3 -8 -1 -13 11 -13 26 -1 16 -10 34 -20 42 -11 8 -17 18 -13 22 10 9 -12 35
-23 28 -5 -3 -9 4 -9 15 0 11 -12 33 -26 50 -14 17 -21 31 -16 31 6 0 10 16
10 36 0 26 -4 34 -14 30 -15 -6 -18 11 -8 38 3 9 10 13 15 10 5 -3 9 1 9 9 0
21 39 52 115 90 39 20 73 36 76 37 3 0 17 -29 31 -65z m-874 -109 c5 -3 63 -3
128 0 65 3 141 6 167 7 l49 2 -32 -26 c-17 -14 -49 -32 -70 -39 -22 -7 -40
-17 -40 -22 0 -4 -12 -8 -26 -8 -19 0 -25 -4 -21 -14 4 -11 1 -13 -10 -9 -12
5 -15 2 -10 -9 3 -11 -1 -18 -13 -21 -11 -3 -20 -1 -22 5 -3 9 -39 2 -153 -28
-45 -12 -51 -12 -63 4 -16 19 -11 101 8 142 10 22 17 26 55 24 24 -1 47 -4 53
-8z m500 -108 c7 -7 12 -22 12 -33 0 -37 50 -148 81 -180 19 -21 29 -41 28
-60 0 -17 -3 -24 -6 -17 -7 17 -23 15 -23 -3 0 -18 -17 -20 -22 -3 -3 9 -17 7
-54 -7 -36 -14 -87 -20 -187 -24 -76 -3 -141 -3 -145 0 -11 10 -105 39 -110
35 -2 -3 -9 -1 -16 5 -6 5 -28 22 -49 38 -21 16 -34 31 -30 34 4 2 33 10 63
18 30 7 59 16 65 21 5 4 19 8 32 8 13 0 23 5 23 10 0 6 11 10 25 10 14 0 25 5
25 10 0 6 11 10 25 10 14 0 25 3 25 8 0 4 24 18 53 32 28 13 54 27 57 30 3 3
19 12 37 19 17 7 39 21 49 32 20 22 26 23 42 7z"
      />
    </g>
  </svg>
);

export default SundaySvg;
