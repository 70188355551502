import { styled } from '@mui/material/styles';
import { TrendUpIcon } from './icons/TrendUpIcon';
import { TrendDownIcon } from './icons/TrendDownIcon';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { Card, Typography } from '@mui/material';
import { ReactNode } from 'react';

type HomeCardSubItem = {
  label: string;
  value?: string;
};

type HomeCardProps = {
  titleIcon?: ReactNode;
  title: string;
  value: string;
  trend: number;
  isPercentageTrend?: boolean;
  subItems: HomeCardSubItem[];
  cta?: ReactNode;
};

const Container = styled(Card)`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 24px;
  min-height: 400px;

  @media (max-width: ${breakpoints.homeTablet}) {
    margin-right: 0;
    margin-bottom: 0;
    height: fit-content;
  }
`;

const TitleHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const TitleIcon = styled('div')`
  display: flex;
  padding-right: 8px;
  align-items: center;
`;

const Title = styled('div')`
`;

const TrendIconWrapper = styled('div')`
  width: 20px;
  height: 20px;
`;

const Value = styled('div')`
  margin-bottom: 8px;
`;

const TrendContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-style: normal;
`;

const TrendValue = styled('div')`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.42px;
  padding-left: 4px;
`;

const TrendLabel = styled('div')`
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.3px;
  opacity: 0.5;
  padding-left: 4px;
`;

const Splitter = styled('div')`
  margin: 16px 0 16px 0;
  height: 0;
  border-top: 1px solid black;
  opacity: 0.1;
`;

const SubItemsContainer = styled('div')`
`;

const SubItemLine = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

const SubItemLabel = styled('div')`
  opacity: 0.5;
`;

const SubItemValue = styled('div')`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.48px;
`;

const CtaContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;

`;

export const HomeCard = ({ titleIcon = undefined, title, value, trend, isPercentageTrend = false, subItems, cta }: HomeCardProps) => {

  const signSymbol = trend >= 0 ? '+' : '';
  const percentSymbol = isPercentageTrend ? '%' : '';
  const trendValue = trend === Number.POSITIVE_INFINITY ? '' : signSymbol + trend + percentSymbol;

  return (
    <Container>
      <TitleHeader>
        {titleIcon && (<TitleIcon>{titleIcon}</TitleIcon>)}
        <Title><Typography variant="h6">{title}</Typography></Title>
      </TitleHeader>
      <Value><Typography variant="h3">{value}</Typography></Value>
      {
        trend !== Number.POSITIVE_INFINITY &&
        <TrendContainer>
          <TrendIconWrapper>
            {trend >= 0 ? <TrendUpIcon /> : <TrendDownIcon />}
          </TrendIconWrapper>
          <TrendValue>
            {trendValue}
          </TrendValue>
          <TrendLabel><FormattedMessage id="venue.home.compare-last-week" /></TrendLabel>
        </TrendContainer>
      }
      <Splitter />
      <SubItemsContainer>
        {subItems.map((item) => (
          <SubItemLine key={item.label}>
            <SubItemLabel><Typography variant="body2">{item.label}</Typography></SubItemLabel>
            <SubItemValue>{item.value}</SubItemValue>
          </SubItemLine>
        ))}
      </SubItemsContainer>
      {cta && (<CtaContainer>{cta}</CtaContainer>)}
    </Container>
  );
};
