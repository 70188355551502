import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button } from '@mui/material';
import { useAcceptOrder, useGetOrder } from '../../orders/hooks';
import { Order } from '../../orders/types';
import { AcceptOrderDrawer } from '../../orders/components/AcceptOrderButton/AcceptOrderDrawer';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { AcceptationMode } from '../../orders/repositories/OrderingOrderRepository';

interface KdsAcceptOrderButtonProps {
  orderId: string;
  onOrderAccepted: () => void;
}

const getButtonSize = (): 'small' | 'medium' => {
  return isMobileScreen() ? 'small' : 'medium';
};

export const KdsAcceptOrderButton = ({ orderId, onOrderAccepted }: KdsAcceptOrderButtonProps) => {
  const [, acceptOrder] = useAcceptOrder(orderId);
  const [getOrderState, getOrder] = useGetOrder();
  const [order, updateOrder] = useState<Order>();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const clickAccept = (mode: AcceptationMode) => {
    acceptOrder(mode).then(() => {
      setDrawerVisible(false);
      onOrderAccepted();
    });
  };

  const onButtonClick = () => {
    getOrder(orderId).then((loadedOrder) => {
      updateOrder(loadedOrder);
      setDrawerVisible(true);
    });
  };

  return (
    <>
      <Button variant="contained" size={getButtonSize()} onClick={onButtonClick} disabled={getOrderState.loading}>
        <FormattedMessage id="orders.acceptOrder" defaultMessage="accept order" />
      </Button>

      {order && (
        <AcceptOrderDrawer
          order={order}
          onAcceptOrder={clickAccept}
          onClose={() => setDrawerVisible(false)}
          visibility={drawerVisible}
        />
      )}
    </>
  );
};
