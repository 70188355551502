import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpSharedDeviceRepository } from '../infrastructure/HttpSharedDeviceRepository';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';

type MutationArgs = { token: string; deviceName: string };

export const useUpdateSharedDeviceName = (businessId: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const httpBusinessesRepository = new HttpSharedDeviceRepository(configuration.waiterAppBackendUrl);

  const mutation = useMutation({
    mutationFn: ({ token, deviceName }: MutationArgs) =>
      httpBusinessesRepository.updateSharedDeviceName(token, deviceName),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.waiters.LIST_SHARED_DEVICES, { businessId }] }),
  });

  return {
    updateDeviceName: (args: MutationArgs) => mutation.mutateAsync(args),
  };
};
