import { RepresentativeDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

export const isCompleteDirector = ({ representative, containsErrors }: {
  representative: RepresentativeDraft | undefined,
  containsErrors: boolean
}): boolean => {
  if (containsErrors || !representative) {
    return false;
  }
  // TODO Sylvain 02/08/2024: change this check when Checkout will fix the bug
  const isAddressComplete = !!representative.address?.city && !!representative.address?.country && !!representative.address?.addressLine1 && !!representative.address?.zip;
  return !!representative!.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    isAddressComplete;
};