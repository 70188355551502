import { getSpacing, IconWrapper, PencilSvg } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import MenuItemLastEdition from './MenuItemLastEdition';
import MenuItemLine from './MenuItemLine';
import MenuItemLineContainer from './MenuItemLineContainer';
import MenuItemName from './MenuItemName';
import MenuItemSchedule from './MenuItemSchedule';
import { businessStaticMenuPath } from 'src/app/navigation/pathHelpers';
import { StaticMenu } from '../../domain/StaticMenus';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type MenuItemProps = {
  menu: StaticMenu;
};

const MenuItemToolColumn = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  min-width: 88px;
  max-width: 88px;
  padding-right: ${getSpacing(3)};
`;

const MenuItem = ({ menu }: MenuItemProps) => {
  const navigate = useNavigate();
  const business = useCurrentBusinessOrThrow();

  const onMenuRoute = () => navigate(businessStaticMenuPath(business.id, menu.id));

  return (
    <MenuItemLineContainer>
      <MenuItemLine>
        <MenuItemName name={menu.description} visibility={menu.visibility} />
        <MenuItemSchedule constraints={menu.constraints} visibility={menu.visibility} />
        <MenuItemLastEdition lastEdition={menu?.lastEdition?.getTime()} visibility={menu.visibility} />
      </MenuItemLine>
      <MenuItemToolColumn>
        <IconWrapper size="small" cursor="pointer" onClick={onMenuRoute}>
          <PencilSvg />
        </IconWrapper>
      </MenuItemToolColumn>
    </MenuItemLineContainer>
  );
};

export default MenuItem;
