import { Button, DangerSvg, IconWrapper, Modal, Panel } from '@sundayapp/b2b-react-component-library';
import { Box, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ReconciliationReport } from '../domain/ReconciliationReport';

const FloatingPanel = styled(Panel)`
  align-self: center;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: sticky;
  margin-bottom: 20px;
  color: white;
`;

interface Props {
  submitAction: () => void,
  isOpened: boolean,
  onClose: () => void,
  report: ReconciliationReport,
}

export const DeleteReportModal = ({
  submitAction, isOpened, onClose, report,
}: Props) => {
  const intl = useIntl();
  return (
    <Modal
      title={intl.formatMessage({ id: 'reconciliation.report.delete' })}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      onClose={onClose}
      isOpened={isOpened}
    >
      <FloatingPanel background={'#e0831e'}>
        <IconWrapper color="white" size="large">
          <DangerSvg />
        </IconWrapper>
        <Box sx={{ marginLeft: '15px' }}>
          <Typography variant="h5">
            {format(report.shift.dateOfService, 'yyyy-MM-dd')}
            {' '}
            -
            {' '}
            {report.shift.name}
          </Typography>
          <Typography variant="body1">
            <FormattedMessage id="reconciliation.report.delete_warning" />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="reconciliation.report.delete_warning_cannot_revert" />
          </Typography>

        </Box>

      </FloatingPanel>
      <Button type="button" variant="primary" onClick={submitAction} fullWidth size="large">
        <FormattedMessage id="modal.confirm" />
      </Button>
    </Modal>
  );
};
