import { useFormContext } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

const companyPositions = [
  'ceo',
  'president',
  'general_partner',
  'cfo',
  'vice_president',
  'managing_member',
  'treasurer',
  'coo',
  'other_executive_officer',
  'other_senior_management',
  'other_non_executive_non_senior',
] as const;

export const CompanyPosition = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register, watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const companyPosition = watch(`representatives.${representativeIndex}.companyPosition`, 'ceo');
  const error = errors?.representatives?.[representativeIndex]?.companyPosition;
  return <TextField
    select
    required
    InputLabelProps={{ shrink: true }}
    defaultValue={companyPosition}
    error={!!error}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.companyPosition' })}
    inputProps={
      register(`representatives.${representativeIndex}.companyPosition`, { 
        required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.companyPosition.error' }),

      })
    }
    helperText={!!error ? error?.message : ''}
  >
    {
      (companyPositions).map((value) => <MenuItem key={value} value={value}>
        <FormattedMessage id={`settings.payments.checkout.onboarding.representatives.companyPosition.${value}`} />
      </MenuItem>)
    }
  </TextField>;
};
