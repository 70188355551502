import React, { useState } from 'react';
import { Panel, spaceUsages, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@sundayapp/b2b-react-component-library';
import { SUNDAY_FOR_RESTAURANTS_WIDTH } from 'src/app/pages/constants';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useListSharedDevices } from '../query/useListSharedDevices';
import { ActionContainer } from 'src/pages/Settings/UserPermissions/UserPermissions.styles';
import { SharedDevice } from '../domain/SharedDevice';
import { useDeleteSharedDevice } from '../mutation/useDeleteSharedDevice';
import { useUpdateSharedDeviceName } from '../mutation/useUpdateSharedDeviceNameMutation';
import { UpdateSharedDeviceNameModal } from './modal/UpdateSharedDeviceNameModal';
import { DeleteShareDeviceModal } from './modal/DeleteSharedDeviceModal';
import { IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export const DevicePermissionsComponent = () => {
  const intl = useIntl();
  const businessId = useBusinessIdOrThrow();
  const [selectedDevice, setSelectedDevice] = useState<SharedDevice | undefined>();
  const [isUpdateNameModalDisplayed, setIsUpdateNameModalDisplayed] = useState(false);
  const [isDeleteModalDisplayed, setIsDeleteModalDisplayed] = useState(false);

  const { data: sharedDevices, isLoading } = useListSharedDevices(businessId);
  const { deleteDevice } = useDeleteSharedDevice(businessId);
  const { updateDeviceName } = useUpdateSharedDeviceName(businessId);

  const onUpdateDevice = (sharedDevice: SharedDevice) => {
    setSelectedDevice(sharedDevice);
    setIsUpdateNameModalDisplayed(true);
  };

  const onDelete = (sharedDevice: SharedDevice) => {
    setSelectedDevice(sharedDevice);
    setIsDeleteModalDisplayed(true);
  };

  if (isLoading || !sharedDevices) {
    return;
  }

  return (
    <>
      <Panel
        flexDirection="column"
        marginTop={spaceUsages.mediumLarge}
        marginBottom={spaceUsages.medium}
        maxWidth={`${SUNDAY_FOR_RESTAURANTS_WIDTH}px`}
      >
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              <TableHeader>{intl.formatMessage({ id: 'sunday_for_restaurants.device_permissions.name' })}</TableHeader>
              <TableHeader>
                {intl.formatMessage({ id: 'sunday_for_restaurants.device_permissions.version' })}
              </TableHeader>
              <TableHeader>
                {intl.formatMessage({ id: 'sunday_for_restaurants.device_permissions.validity' })}
              </TableHeader>
              <TableHeader>{intl.formatMessage({ id: 'common.actions', defaultMessage: 'Actions' })}</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {sharedDevices?.map((sharedDevice) => (
              <TableRow key={sharedDevice.deviceToken}>
                <TableCell>{sharedDevice.deviceName}</TableCell>
                <TableCell>{sharedDevice.appVersion}</TableCell>
                <TableCell>{sharedDevice.authenticationExpirationDate.toDateString()}</TableCell>
                <TableCell>
                  <ActionContainer>
                    <Tooltip
                      title={intl.formatMessage({ id: 'sunday_for_restaurants.device_permissions.name.tooltip' })}
                      placement="top"
                    >
                      <IconButton onClick={() => onUpdateDevice(sharedDevice)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={intl.formatMessage({ id: 'sunday_for_restaurants.user_permissions.remove.tooltip' })}
                      placement="top"
                    >
                      <IconButton color={'error'} onClick={() => onDelete(sharedDevice)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </ActionContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Panel>
      {isUpdateNameModalDisplayed && selectedDevice && (
        <UpdateSharedDeviceNameModal
          isOpen={isUpdateNameModalDisplayed}
          sharedDevice={selectedDevice}
          handleConfirm={async (token, deviceName) => updateDeviceName({ token, deviceName })}
          handleClose={() => setIsUpdateNameModalDisplayed(false)}
        />
      )}
      {isDeleteModalDisplayed && selectedDevice && (
        <DeleteShareDeviceModal
          isOpen={isDeleteModalDisplayed}
          sharedDevice={selectedDevice}
          handleConfirm={async (token) => deleteDevice({ token })}
          handleClose={() => setIsDeleteModalDisplayed(false)}
        />
      )}
    </>
  );
};
