import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { WhiteLabelCustomizationRepositoryHttp } from './WhiteLabelCustomizationRepositoryHttp';
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const getCustomizationQuery = (
  customizationRepositoryHttp: WhiteLabelCustomizationRepositoryHttp,
  enrollmentId: string,
) => ({
  queryKey: [queryKeys.enrollmentSettings.whiteLabel.GET_CUSTOMIZATION, { enrollmentId }],
  queryFn: (): Promise<WhiteLabelCustomization> => {
    return customizationRepositoryHttp.getCustomization(enrollmentId);
  },
});

export const useWhiteLabelCustomization = () => {
  const business = useCurrentBusinessOrThrow();
  const repository = new WhiteLabelCustomizationRepositoryHttp(ConfigurationLoader.load().patOrchestratorUrl);
  return useQuery(getCustomizationQuery(repository, business.patEnrollment?.id!));
};
