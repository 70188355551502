import Grid from '@mui/material/Grid';
import { QRCodeSVG } from 'qrcode.react';
import { Paper, styled } from '@mui/material';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

const ActionList = styled('ul')`
  font-weight: bold;
  list-style-type: none;

  li {
    padding-left: 2rem;
    background-image: url(/doodle-tick-1.png);
    background-position: 0 0;
    background-size: 1.6rem 1.6rem;
    background-repeat: no-repeat;
  }
`;
type Props = {
  venueId: string;
};

const configuration = ConfigurationLoader.load();

function QrCodePreview({ venueId }: Props) {
  const menuLink = `${configuration.b2cAppBaseUrl}/venues/${venueId}`;

  return (
    <Paper variant="outlined" style={{ padding: '15px' }}>
      <Grid container direction="row" alignItems="flex-start">
        <Grid item style={{ fontSize: '1.5rem' }}>
          <p>
            hi
            <br />
            scan the QR code
          </p>
          <ActionList>
            <li>check the menu</li>
            <li>enjoy your meal</li>
          </ActionList>
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center" justifyContent="end">
            <Grid item>
              <a href={menuLink} target="_blank" rel="noreferrer">
                <QRCodeSVG value={menuLink} size={192} fgColor="#000000" level="L" includeMargin />
              </a>
            </Grid>
            <Grid item>
              <img style={{ maxWidth: '130px' }} src="/sunday-logo-with-name.svg" alt="sunday-logo-with-name" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default QrCodePreview;
