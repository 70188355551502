import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Alert, Stack, Typography } from '@mui/material';

export const UploadBankStatementDocument = () => {
  const { formState: { errors }, register, watch, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors.bankAccount?.bankStatement;
  const bankStatementDocumentId = watch('bankAccount.bankStatement');

  const onFileUploaded = (documentId: string) => {
    setValue('bankAccount.bankStatement', documentId);
  };
  const onDelete = () => {
    setValue('bankAccount.bankStatement', '');
  };

  const intl = useIntl();
  return <Stack>
    <UploadDocument
      documentId={bankStatementDocumentId}
      onRegistered={register('bankAccount.bankStatement', {
        required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bank_statement.error' }),
        setValueAs: (value: string) => value.includes('file') ? value : undefined,
      })}
      onFileUploaded={onFileUploaded}
      onDelete={onDelete}
      error={error}
      documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bank_statement.title' })}
      purpose={'bank_verification'}
    />
    <Alert severity="info">
      <Typography fontWeight={'bold'}> <FormattedMessage
        id={'settings.payments.checkout.onboarding.bank_statement.helper_text.extended'} />
      </Typography>
      <Typography>
        <FormattedMessage id={'settings.payments.checkout.onboarding.bank_statement.helper_text'} />
      </Typography>
    </Alert>
  </Stack>;
};
