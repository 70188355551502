import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Business, BusinessId } from 'src/business/domain/Business';
import { EndOfServiceClient } from '../infrastructure/EndOfServiceClient';
import { CountryCode } from 'src/domain/CountryCode';

export const QUERYKEY_END_OF_SERVICE_CONFIGURATION = 'end_of_service_configuration';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_END_OF_SERVICE_CONFIGURATION, businessId] as const;

export const useGetEndOfServiceConfiguration = (business: Business) => {
  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: buildQueryKey(business.id),
    queryFn: async () => {
      const repository = new EndOfServiceClient(axios);
      const configuration = await repository.getEndOfServiceConfiguration(business.id);

      const countryCode: CountryCode = CountryCode[business.address.countryCode as keyof typeof CountryCode];
      return { ...configuration,
        displayPaymentTerminal: business.pdqEnrollment?.id !== undefined,
        displayTrd: [CountryCode.FR].includes(countryCode),
        displayServiceCharge: [CountryCode.US, CountryCode.GB].includes(countryCode),
        displayServiceChargeTooltip:  [CountryCode.US].includes(countryCode),
      };
    },
  });
};
