import { ReactElement, useContext } from 'react';
import { getApp } from 'firebase/app';
import { deleteObject, getDownloadURL, getStorage, ref, uploadString } from 'firebase/storage';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { Configuration } from 'src/configuration/Configuration';
import { NotificationToAddType, useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { FileStorage } from 'src/ordering/common/FileStorage';
import { OrderingContextProvider } from 'src/ordering/OrderingContextProvider';

type MenuContextProviderProps = {
  children: ReactElement;
};

export const OrderAndPayContextProvider = ({ children }: MenuContextProviderProps) => {
  const configuration = useContext(ConfigurationContext) as Configuration;
  const useSnackBar = useSnackbar();

  const onPushNotification = (message: string, variant: NotificationToAddType['variant'] = 'success') => {
    useSnackBar.addNotification({
      variant: variant,
      text: message,
    });
  };

  const app = getApp();
  const storage = getStorage(app);

  const fileStorage: FileStorage = {
    saveImageAsPNG: async (folderName: string, fileName: string, base64Image: string): Promise<string> => {
      const fileRef = ref(storage, `${folderName}/${fileName}.png`);
      await uploadString(fileRef, base64Image, 'data_url', {
        contentType: 'image/png',
      });
      return getDownloadURL(fileRef);
    },
    deleteImage: async (folderName: string, fileName: string): Promise<void> => {
      console.log(`delete: ${folderName}/${fileName}`);
      await deleteObject(ref(storage, `${folderName}/${fileName}`));
    },
  };

  return (
    <OrderingContextProvider
      menuBackendUrl={configuration.menuApiBaseUrl}
      orderingBackendUrl={configuration.orderingApiBaseUrl}
      paymentBackendUrl={configuration.paymentApiBaseUrl}
      onPushNotification={onPushNotification}
      fileStorage={fileStorage}
    >
      {children}
    </OrderingContextProvider>
  );
};
