import { Box, TableBody, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { KpiString } from '../KpiString';
import { EmailCell, EmailTable } from '../EmailTable';
import { palette } from '../../design/palette';
import { InsightData } from '../../domain/TemplatesData';

export const InsightPerDimension = ({ insights, countryCode }: { insights: InsightData; countryCode: string }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <EmailTable
        legend={{
          title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.insights_per_dimension' }),
          legends: [
            {
              imagePath: insights.forkImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.food_n_drinks' }),
            },
            {
              imagePath: insights.ringImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.ambiance' }),
            },
            {
              imagePath: insights.serviceImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.service' }),
            },
            {
              imagePath: insights.piggyBankImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.value_for_money' }),
            },
          ],
        }}
        headCellTitle={[
          { title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.venue' }), width: '40%' },
          { title: '' },
          { title: '' },
        ]}
      >
        <TableBody>
          {insights.venues.map((data, index) => (
            <TableRow key={data.name} style={{ breakInside: 'avoid' }}>
              <EmailCell>
                <>
                  <Typography color={palette.primary.electricPink}>{`${index + 1}`.padStart(2, '0')}</Typography>
                  <Typography color={palette.primary.black}>{data.name}</Typography>
                </>
              </EmailCell>
              <EmailCell>
                <Box>
                  <KpiString
                    imagePath={insights.forkImgPath}
                    value={data.foodAndDrinkRating && `${data.foodAndDrinkRating} (${data.foodAndDrinkRatingCount})`}
                    fluctuation={data.foodAndDrinkRatingFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                  />
                  <KpiString
                    imagePath={insights.ringImgPath}
                    value={data.ambianceRating && `${data.ambianceRating} (${data.ambianceRatingCount})`}
                    fluctuation={data.ambianceRatingFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                  />
                </Box>
              </EmailCell>
              <EmailCell>
                <Box>
                  <KpiString
                    imagePath={insights.serviceImgPath}
                    value={data.serviceRating && `${data.serviceRating} (${data.serviceRatingCount})`}
                    fluctuation={data.serviceRatingFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                  />
                  <KpiString
                    imagePath={insights.piggyBankImgPath}
                    value={data.valueForMoneyRating && `${data.valueForMoneyRating} (${data.valueForMoneyRatingCount})`}
                    fluctuation={data.valueForMoneyRatingFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                  />
                </Box>
              </EmailCell>
            </TableRow>
          ))}
        </TableBody>
      </EmailTable>
    </>
  );
};
