import { DayOfWeek, TimePeriod } from '../../domain/TimePeriod';
import { getTimePeriodFromString } from '../../utils/getTimePeriodFromString';

export const isOpen24Hours = (openingHours: TimePeriod[]) => {
  if (openingHours.length !== 1) return false;
  const openingTime = getTimePeriodFromString(openingHours[0].startTime);
  const closingTime = getTimePeriodFromString(openingHours[0].endTime);

  return (
    openingTime?.hours === 0 && openingTime?.minutes === 0 && closingTime?.hours === 23 && closingTime?.minutes === 59
  );
};

export const getDefaultTimePeriod = (weekDay: DayOfWeek): TimePeriod => ({
  weekDay,
  startTime: '12:00',
  endTime: '15:00',
});

export const get24HoursTimePeriod = (weekDay: DayOfWeek): TimePeriod => ({
  weekDay,
  startTime: '0:00',
  endTime: '23:59',
});
