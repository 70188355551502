import {
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  ChangeEvent, KeyboardEvent, useCallback, useEffect, useMemo, useState,
} from 'react';

interface AutocompleteProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
type ReturnType<T> = [preselectedOption: T, filteredOptions: T[], autocompleteProps: AutocompleteProps];

type ValueFromTextFunction<T> = (text: string) => T | undefined;

const KEY_ARROW_DOWN = 'ArrowDown';
const KEY_ARROW_UP = 'ArrowUp';
const KEY_ENTER = 'Enter';

export function useAutocomplete<T>(
  options: T[],
  onChange: (value: T) => void,
  close: () => void,
  open: boolean,
  createValueFromText?: ValueFromTextFunction<T>,
): ReturnType<T> {
  const [autocomplete, setAutocomplete] = useState<string>('');
  const [preselectedOption, setPreselectedOption] = useState<T>();

  useEffect(() => {
    if (!open) {
      setAutocomplete('');
    }
  }, [open, setAutocomplete]);

  const filteredOptions = useMemo(
    () =>
      options.filter((option) => {
        const words = autocomplete.toLocaleLowerCase().split(/\W+/);
        const optionValues = Object.entries(option as any)
          .filter(([key, value]) => key !== 'id' && value !== null)
          .map(([, optionValue]) => `${optionValue}`.toLocaleLowerCase())
          .join(' ');
        const res = words.find((word) => optionValues.indexOf(word) < 0);
        return res === undefined;
      }),
    [options, autocomplete],
  );

  const onAutocompleteChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAutocomplete(event.currentTarget.value);
    },
    [setAutocomplete],
  );
  const preselectNextOption = useCallback(() => {
    if (filteredOptions.length === 0) return;
    if (preselectedOption === undefined) {
      setPreselectedOption(filteredOptions[0]);
    } else {
      const index = filteredOptions.findIndex((option) => option === preselectedOption);
      if (index === filteredOptions.length - 1) {
        setPreselectedOption(filteredOptions[0]);
      } else {
        setPreselectedOption(filteredOptions[index + 1]);
      }
    }
  }, [filteredOptions, preselectedOption, setPreselectedOption]);
  const preselectPreviousOption = useCallback(() => {
    if (filteredOptions.length === 0) return;
    if (preselectedOption === undefined) {
      setPreselectedOption(filteredOptions[filteredOptions.length - 1]);
    } else {
      const index = filteredOptions.findIndex((option) => option === preselectedOption);
      if (index === 0) {
        setPreselectedOption(filteredOptions[filteredOptions.length - 1]);
      } else {
        setPreselectedOption(filteredOptions[index - 1]);
      }
    }
  }, [filteredOptions, preselectedOption, setPreselectedOption]);
  const selectPreselectedOption = useCallback(() => {
    const value = preselectedOption || (createValueFromText && createValueFromText(autocomplete));
    if (value) {
      onChange(value);
      setPreselectedOption(undefined);
      close();
    }
  }, [preselectedOption, setPreselectedOption, onChange, close, createValueFromText, autocomplete]);
  const onAutocompletKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === KEY_ARROW_DOWN) {
        preselectNextOption();
      } else if (event.key === KEY_ARROW_UP) {
        preselectPreviousOption();
      } else if (event.key === KEY_ENTER) {
        selectPreselectedOption();
      }
    },
    [preselectNextOption, preselectPreviousOption, selectPreselectedOption],
  );

  const autocompleteProps = useMemo(
    () => ({
      value: autocomplete,
      onChange: onAutocompleteChange,
      onKeyDown: onAutocompletKeyDown,
    }),
    [autocomplete, onAutocompleteChange, onAutocompletKeyDown],
  );
  return [preselectedOption!, filteredOptions, autocompleteProps];
}
