import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { AppDesignUseCase } from 'src/domain/venue/AppDesignUseCase';
import { BlurHashService } from 'src/domain/venue/BlurHashService';
import { CropPictureData } from 'src/domain/venue/CropperService';
import {
  useDocumentRepositoryFirebaseStorage,
} from 'src/infrastructure/document/DocumentRepositoryFirebaseStorage';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomization } from '../../infrastructure/useCustomization';
import { useCustomizationUpdater } from '../../infrastructure/useCustomizationUpdater';

export function useLogoUseCase(enrollmentId: EnrollmentId) {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const documentRepositoryStorage = useDocumentRepositoryFirebaseStorage();
  const { data: customization } = useCustomization(enrollmentId);
  const customizationUpdater = useCustomizationUpdater();
  const designUseCase = useMemo(
    () =>
      new AppDesignUseCase(
        customizationUpdater,
        documentRepositoryStorage,
        enrollmentId,
        customization,
        new BlurHashService(),
      ),
    [customizationUpdater, documentRepositoryStorage, customization, enrollmentId],
  );
  const [tempLogoPicture, setTempLogoPicture] = useState('');
  const deleteLogos = async () => {
    setLoading(true);
    await designUseCase.deleteLogos();
    setLoading(false);
  };

  const uploadLogo = async (file: File) => {
    try {
      const downloadLink = await designUseCase.uploadLogoPicture(enrollmentId, file);
      setTempLogoPicture(downloadLink);
    } catch (e) {
      useSnackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.logo.upload.error' }),
      });
    }
  };

  const removeTempLogoPicture = async () => {
    setTempLogoPicture('');
  };

  const saveCroppedPicture = async (crop: CropPictureData) => {
    setLoading(true);
    setTempLogoPicture('');
    try {
      await designUseCase.saveVenueLogo(crop);

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.logo.upload.success' }),
      });
    } catch (e) {
      useSnackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.logo.upload.error.unknown' }),
      });
    }
    setLoading(false);
  };

  const editLogoPicture = useCallback(() => {
    if (customization?.logo?.originalUrl) {
      setTempLogoPicture(customization?.logo?.originalUrl);
    }
  }, [customization]);

  return {
    logoUrl: customization ? customization.logo?.logoUrls?.noResize ?? '' : '',
    deleteLogos,
    uploadLogo,
    tempLogoPicture,
    removeTempLogoPicture,
    saveCroppedPicture,
    editLogoPicture,
    loading,
  };
}
