import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useAreaRepository } from '../../hooks';
import { Area } from '../../domain/Area';

type AreaSelectorProps = {
  boxId: string;
  onAreaUnselected: () => void;
  onAreaSelected: (area: Area) => void;
};

export const AreaSelector = ({ boxId, onAreaSelected, onAreaUnselected }: AreaSelectorProps) => {
  const intl = useIntl();

  const noAreaLabel = useMemo(() => `--- ${intl.formatMessage({ id: 'area.selector.all-areas', defaultMessage: 'All areas' })} ---`, [intl]);

  const areaRepository = useAreaRepository();
  const [areas, setAreas] = useState<Area[]>([]);
  const [currentAreaName, setCurrentAreaName] = useState<string>(noAreaLabel);

  const options = useMemo((): string[] => {
    if (areas === undefined) {
      return [];
    }

    const areaNames = areas
      .map((area) => area.name)
      .sort((a, b) => a.localeCompare(b));

    return [noAreaLabel, ...areaNames];
  }, [areas, noAreaLabel]);

  useEffect(() => {
    areaRepository.getBoxAreas(boxId).then(setAreas);
  }, [areaRepository, boxId]);

  const onAreaChanged = async (areaName: string) => {
    setCurrentAreaName(areaName);

    const selection = areas.find((area) => area.name === areaName);
    if (!selection) {
      onAreaUnselected();
      return;
    }

    onAreaSelected(selection);
  };

  if (areas.length <= 1) {
    return <></>;
  }

  return <Dropdown size="medium" options={options} onChange={onAreaChanged} value={currentAreaName} />;
};
