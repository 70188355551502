import React from 'react';
import './index.css';
import { Auth0Provider } from '@auth0/auth0-react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './monitoring/reportWebVitals';
import { initDatadog } from './monitoring/datadogConfig';
import { ConfigurationLoader } from './configuration/ConfigurationLoader';
import { App } from './app/App';
import { RouteDefinitions } from './app/RouteDefinitions';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router';

const configuration = ConfigurationLoader.load();
initDatadog(configuration);

const onRedirectCallback = (appState?: { returnTo?: string }) => {
  window.location.assign(appState?.returnTo || window.location.pathname);
};

const AppWithAuthentication = () => (
  <Auth0Provider
    domain={configuration.auth0Domain}
    clientId={configuration.auth0ClientId}
    authorizationParams={{
      redirect_uri: `${configuration.callbackUrl}${RouteDefinitions.SignInConfirmation.path}`,
      audience: configuration.auth0Audience,
    }}
    cacheLocation="localstorage"
    useRefreshTokens
    useRefreshTokensFallback
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>
);

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  const router = () => createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/*" element={<AppWithAuthentication />} />
      </>,
    ),
  );
  root.render(
    <RouterProvider router={router()} />,
  );
};


const enableMocking = async () => {
  // @ts-ignore
  const envName = import.meta.env.VITE_ENV_NAME ?? window.sundayEnv.VITE_ENV_NAME;
  if (envName !== 'alpha' && envName !== 'demo') return;

  console.log('Enabling mocking');
  const { worker } = await import('./mocks/browser');
  return worker.start({
    onUnhandledRequest: 'bypass',
  });
};
const failMocking = (error: unknown) => console.log('Failed to enable mocking', error);


// Start the application
enableMocking().catch(failMocking).finally(renderApp);


reportWebVitals();
