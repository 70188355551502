import React, { ChangeEvent, createRef, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { UploadIcon } from '../Icons';
import { loadFileAsImage, loadImage } from '../ImageUploaderWithDimensions/service';
import { PictureManipulator } from './PictureManipulator';

type PicturePickerProps = {
  imageUrl: string | null;
  onImageChanged: (content: string) => void;
  updating: boolean;
  width: number;
  height: number;
  exportWidth?: number;
  exportHeight?: number;
  disabled?: boolean;
};

type PictureUploaderButtonProps = {
  width: number;
  height: number;
};

const PictureUploaderButton = styled.div<PictureUploaderButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0 8px 8px;
  cursor: pointer;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const HiddenInput = styled.input`
  display: none;
`;

const StyledUploadIcon = styled(UploadIcon)`
  margin-left: 15px;
`;

export const PicturePicker = ({
  imageUrl,
  onImageChanged,
  updating,
  width,
  height,
  exportWidth = width,
  exportHeight = height,
  disabled = false,
}: PicturePickerProps) => {
  const inputRef = createRef<HTMLInputElement>();
  const [image, setImage] = useState<HTMLImageElement | undefined>();
  const [editing, makeEditing] = useState<boolean>(false);
  const [zoom, setZoom] = useState<number>(1);

  const onImageContentChanged = (content: string) => {
    try {
      onImageChanged(content);
    } finally {
      makeEditing(false);
    }
  };

  const startUploadFile = useCallback(() => {
    if (inputRef.current && !updating && !editing) {
      inputRef.current!.value = '';
      inputRef.current.click();
    }
  }, [inputRef, updating, editing]);

  const onFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files.length > 0) {
      const file = event.currentTarget.files[0];
      loadFileAsImage(file).then(setImage);
      makeEditing(true);
    }
  };

  const initImage = useCallback((imageUrlToInit: string | null) => {
    if (!imageUrlToInit) {
      setImage(undefined);
      return;
    }

    loadImage(imageUrlToInit).then((initializedImage) => {
      setImage(initializedImage);
    });
  }, []);

  useEffect(() => {
    initImage(imageUrl);
  }, [imageUrl, initImage]);

  const onCancel = () => {
    initImage(imageUrl);
    makeEditing(false);
  };

  return (
    <>
      {(imageUrl || image) ? (
        <PictureManipulator
          image={image}
          imageUrl={imageUrl}
          zoom={zoom}
          setZoom={setZoom}
          width={width}
          height={height}
          exportWidth={exportWidth}
          exportHeight={exportHeight}
          onUploadNewFile={startUploadFile}
          isEditMode={editing}
          onImageContentChanged={onImageContentChanged}
          onCancel={onCancel}
          disabled={disabled}
        />
      ) : (
        <PictureUploaderButton onClick={startUploadFile} width={width} height={height}>
          <FormattedMessage id="picture-picker.add-image" defaultMessage="add image" />
          <StyledUploadIcon />
        </PictureUploaderButton>
      )}

      <HiddenInput type="file" name="product-image" onChange={onFileChange} ref={inputRef} accept="image/*" />
    </>
  );
};
