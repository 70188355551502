import { BlurHashService } from './BlurHashService';
import { CropperService, CropPictureData } from './CropperService';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Customization } from 'src/pages/Settings/Customization/domain/Customization';
import { CustomizationUpdater } from 'src/pages/Settings/Customization/domain/CustomizationUpdater';
import { DocumentRepository } from '../document/DocumentRepository';

const cropperService = new CropperService();

export class AppDesignUseCase {
  constructor(
    private customizationUpdater: CustomizationUpdater,
    private storageRepository: DocumentRepository,
    private enrollmentId: EnrollmentId,
    private customization: Customization | undefined,
    private blurHashService: BlurHashService,
  ) {}

  async deleteCovers() {
    if (!this.customization) {
      return false;
    }
    await this.storageRepository.deleteRefFromUrl(this.customization.cover?.raw);
    await this.storageRepository.deleteRefFromUrl(this.customization.cover?.original);
    await this.storageRepository.deleteRefFromUrl(this.customization.cover?.s);
    await this.storageRepository.deleteRefFromUrl(this.customization.cover?.m);
    await this.storageRepository.deleteRefFromUrl(this.customization.cover?.l);
    await this.storageRepository.deleteRefFromUrl(this.customization.cover?.xl);
    await this.customizationUpdater.deleteCoverPicture(this.enrollmentId);
    await this.customizationUpdater.deleteCoverBackgroundColor(this.enrollmentId);
    return true;
  }

  async deleteLogos() {
    if (!this.customization) {
      return false;
    }
    await this.storageRepository.deleteRefFromUrl(this.customization.logo?.originalUrl);
    await this.customizationUpdater.deleteLogo(this.enrollmentId);
    return true;
  }

  async uploadCoverPicture(enrollmentId: EnrollmentId, file: File) {
    const coverIsBiggerThan5mo = file.size / (1024 * 1024) > 5;
    if (coverIsBiggerThan5mo) {
      throw new Error();
    }
    const originalCoverPicture = await this.storageRepository.saveFile(enrollmentId, 'cover_original', file);
    await this.customizationUpdater.saveCoverPictureOriginalUrl(enrollmentId, originalCoverPicture);
    return originalCoverPicture;
  }

  async uploadLogoPicture(enrollmentId: EnrollmentId, file: File) {
    const coverIsBiggerThan5mo = file.size / (1024 * 1024) > 5;
    if (coverIsBiggerThan5mo) {
      throw new Error();
    }
    const downloadLink = await this.storageRepository.saveFile(enrollmentId, file.name, file);
    await this.customizationUpdater.saveLogoOriginalUrl(enrollmentId, downloadLink);
    return downloadLink;
  }

  async saveVenueLogo(crop: CropPictureData): Promise<void> {
    const base64Image = cropperService.getCroppedPicture(crop, 'png');
    const backgroundColor = crop.background?.color;
    const logoUrls = await this.storageRepository.saveVenueImageAsPNG(this.enrollmentId, base64Image);
    await this.customizationUpdater.saveLogo(this.enrollmentId, {
      noResize: logoUrls.no_resize,
      size48x48: logoUrls['48x48'],
      size80x80: logoUrls['80x80'],
      size300x300: logoUrls['300x300'],
      backgroundColor: backgroundColor ?? '#FFFFFF',
    });
  }

  async saveVenueCoverBackgroundColor(coverBackgroundColor: string): Promise<void> {
    await this.customizationUpdater.saveCoverBackgroundColor(this.enrollmentId, coverBackgroundColor);
  }

  async saveVenueCoverPicture(crop: CropPictureData): Promise<void> {
    const base64Image = cropperService.getCroppedPicture(crop, 'jpeg');
    const covers = await this.storageRepository.saveVenueCoverImage(this.enrollmentId, base64Image);
    const blurhash = await this.blurHashService.getBlurHash(covers.m);
    await this.customizationUpdater.saveCoverPicture(this.enrollmentId, {
      ...covers,
      blurhash,
    });
  }
}
