import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import DayCheckBox from './DayCheckBox';

type DaysSelectorProps = {
  selectedDays: boolean[];
  onSelectionChanged: (selections: boolean[]) => void;
};

export const SelectorRow = styled.div`
  margin-top: ${spaceUsages.mediumSmall};
  display: flex;
  gap: ${spaceUsages.mediumSmall};
  justify-content: center;
  width: 100%;
`;

const DaysSelector = ({ selectedDays, onSelectionChanged }: DaysSelectorProps) => {
  const { formatMessage } = useIntl();
  const dayOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

  const onSelection = (index: number, value: boolean) => {
    const newSelectedDays = [...selectedDays];
    newSelectedDays[index] = value;
    onSelectionChanged(newSelectedDays);
  };

  return (
    <SelectorRow>
      {selectedDays.map((days, index) => (
        <DayCheckBox
          key={dayOfWeek[index]}
          dayName={formatMessage({ id: `common.${dayOfWeek[index]}` })}
          checked={days}
          isChecked={(value) => onSelection(index, value)}
        />
      ))}
    </SelectorRow>
  );
};

export default DaysSelector;
