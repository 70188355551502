import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { DotsIcon } from '../../../common/components/Icons/DotsIcon';
import { ContextMenu, ContextMenuItem } from '../../../common/components/ContextMenu/ContextMenu';
import { TrashIcon } from '../../../common/components/Icons';
import { CopySvg } from '../../../common/components/Icons/CopyIcon';
import { useDeleteBox } from '../../../box/hooks';
import { Box } from '../../../box/domain/Box';
import { BoxDeletionConfirmationModal } from './BoxDeletionConfirmationModal';
import { usePushNotification } from '../../../common/components/Notifications.hook';

type BoxOptionsProps = {
  box: Box;

  onDelete: (box: Box) => void;
};

const OptionIcon = styled.div`
  cursor: pointer;
`;

export const BoxOptions = ({ box, onDelete }: BoxOptionsProps) => {
  const intl = useIntl();

  const [, pushNotification] = usePushNotification();
  const [, deleteBox] = useDeleteBox();
  const [isContextMenuOpened, updateContextMenuVisibility] = useState<boolean>(false);
  const [isDeletionConfirmationPanelOpened, updateDeletionConfirmationPanelVisibility] = useState<boolean>(false);

  const onBoxDeletionRequest = () => {
    updateDeletionConfirmationPanelVisibility(true);
  };

  const onBoxDeleted = async () => {
    try {
      await deleteBox(box.id);
      onDelete(box);
    } finally {
      updateDeletionConfirmationPanelVisibility(false);
    }
  };

  const onPaste = async () => {
    await navigator.clipboard.writeText(box.id);

    pushNotification(
      intl.formatMessage({
        id: 'foodcourt.box.option.copied-id',
        defaultMessage: 'box id copied in the clipboard',
      }),
    );
  };

  const menuContextItems: ContextMenuItem[] = useMemo(() => [
    {
      type: 'label',
      icon: <CopySvg />,
      label: intl.formatMessage({ id: 'foodcourt.box.option.copy-id', defaultMessage: 'copy box id' }),
      variant: 'default',
      onClick: onPaste,
    },
    {
      type: 'separator',
    },
    {
      type: 'label',
      icon: <TrashIcon />,
      label: intl.formatMessage({ id: 'foodcourt.box.option.delete', defaultMessage: 'delete box' }),
      variant: 'danger',
      onClick: onBoxDeletionRequest,
    },
  ], [intl]);

  return (
    <>
      <OptionIcon onClick={() => updateContextMenuVisibility(true)}>
        <DotsIcon />
      </OptionIcon>
      <ContextMenu
        isOpened={isContextMenuOpened}
        onClosed={() => updateContextMenuVisibility(false)}
        items={menuContextItems}
      />

      <BoxDeletionConfirmationModal
        boxName={box.name}
        isOpen={isDeletionConfirmationPanelOpened}
        onCancel={() => updateDeletionConfirmationPanelVisibility(false)}
        onConfirm={onBoxDeleted}
      />
    </>
  );
};
