export type RatingColor = {
  rating: number;
  color: string;
};
export const ratingColors: RatingColor[] = [
  {
    rating: 1,
    color: '#D11023',
  },
  {
    rating: 2,
    color: '#D11023',
  },
  {
    rating: 3,
    color: '#E0821E',
  },
  {
    rating: 4,
    color: '#3FB05E',
  },
  {
    rating: 5,
    color: '#3FB05E',
  },
];

export const colorForRating = (rating: number): string => ratingColors.find((item) => item.rating === rating)?.color ?? 'white';
export const badRatingBackground = '#FBEAEA';
