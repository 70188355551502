import React, { CSSProperties } from 'react';
import { Button, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StatusType } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const FormActions = ({
  onSave,
  isSubmitting,
  onSubmit,
  style,
  formIsSubmitted,
  status,
}: {
  onSave?: () => void;
  onSubmit?: () => void;
  style?: CSSProperties;
  formIsSubmitted: boolean;
  status: StatusType;
  isSubmitting: boolean;
}) => {
  return (
    <Stack style={{ ...style }} direction="row" spacing={1}>
      {!!onSave && (
        <Button
          color={'secondary'}
          variant={'contained'}
          disabled={formIsSubmitted || status === 'PENDING_REVIEW' || status === 'ACTIVE' || status === 'RESTRICTED'}
          onClick={onSave}
        >
          <FormattedMessage id="settings.payments.checkout.onboarding.button.save" />
        </Button>
      )}
      {!!onSubmit && (
        <LoadingButton
          loading={isSubmitting}
          variant={'contained'}
          type={'submit'}
          disabled={formIsSubmitted || status === 'PENDING_REVIEW' || status === 'ACTIVE' || status === 'RESTRICTED'}
          onClick={onSubmit}
        >
          <FormattedMessage id="settings.payments.checkout.onboarding.button.submit" />
        </LoadingButton>
      )}
    </Stack>
  );
};
