import { BoxOrderSummary } from '../../orders/types';

export const canDoReadyForPickup = (boxOrder: BoxOrderSummary): boolean => {
  return boxOrder.status === 'PLACED' || boxOrder.status === 'BOX_ORDERS_PLACED' || boxOrder.status === 'ACCEPTED';
};

export const canDoFinalize = (boxOrder: BoxOrderSummary): boolean => {
  return boxOrder.status === 'READY_FOR_PICKUP';
};

export const displayableBoxOrder = (boxOrder: BoxOrderSummary): boolean => {
  return canDoReadyForPickup(boxOrder) || canDoFinalize(boxOrder);
};

