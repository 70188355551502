import styled, { css } from 'styled-components';
import {
  colorPalette, getSpacing, MOBILE_WIDTH_THRESHOLD, transitions, zIndex,
} from '../../../stylesheet';
import DataGrid from '../../../common/components/DataGrid';

export const SIDE_PANEL_WIDTH = 420;
export const PromoCodesLayout = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  z-index: ${zIndex.DEFAULT};
`;

export const PromoCodesTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${getSpacing(3)};
`;

export interface PromoCodesContainerProps {
  withSidePanelOpened?: boolean;
}

export const PromoCodesContainer = styled.div<PromoCodesContainerProps>`
  flex: 1;
  transition: padding-right ${transitions.default};
  max-width: 100vw;
  margin-bottom: ${getSpacing(2)};
  ${({ withSidePanelOpened }) =>
    withSidePanelOpened
    && css`
      padding-right: ${SIDE_PANEL_WIDTH}px;
      @media (max-width: ${MOBILE_WIDTH_THRESHOLD}) {
        padding-right: 0;
      }
    `}
`;

export const PromoCodesGridContainer = styled.div`
  display: flex;
  background-color: ${colorPalette.defaultBackgroundColor};
  border-radius: 24px;
  padding: ${getSpacing(2)};
  height: 100%;
  flex-direction: column;
`;

export const PromoCodesStyledCustomScroll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;

export const PromoCodesDetailsContainer = styled.aside<PromoCodesContainerProps>`
  position: fixed;
  z-index: ${zIndex.MAIN_MENU};
  top: 0;
  padding: 0;
  right: 0;
  width: 0;
  max-width: 100%;
  height: 100%;
  background-color: ${colorPalette.defaultBackgroundColor};
  transition: width ${transitions.default};
  max-height: 100vh;
  overflow-y: auto;
  ${({ withSidePanelOpened }) =>
    withSidePanelOpened
    && css`
      width: ${SIDE_PANEL_WIDTH}px;
      max-width: 80%;
    `}
`;

export const PromoCodesHeaderText = styled.div`
  font-size: 21px;
`;

export const StyledPromoCodesDataGrid = styled(DataGrid)``;
