import React, { ReactNode } from 'react';
import { CircularProgress, styled, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { NoEreputationData } from './NoEreputationData';
import { LocalisationKey } from 'src/lang/en';

const LoadingOrErrorStyle = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
`;

type Props<T> = {
  onLoaded: (t: T) => ReactNode,
  intlKeys: {
    loading: LocalisationKey,
  },

  queryResult: {
    isLoading: boolean,
    data: T | undefined,
    isError: boolean, }

};
export const LoadingOrErrorHOC = <T extends unknown>({
  onLoaded,
  intlKeys,
  queryResult: {
    data,
    isError,
    isLoading,
  },
}: Props<T>) => {
  const theme = useTheme();

  if (data) return <>{onLoaded(data)}</>;
  return (
    <LoadingOrErrorStyle>
      {isLoading && (
        <>
          <CircularProgress />
          <Typography>
            <FormattedMessage id={intlKeys.loading} />
          </Typography>
        </>
      )}
      {isError && (
        <NoEreputationData theme={theme} />
      )}
    </LoadingOrErrorStyle>
  );
};
