import { VenueKpiDto, VenueKpiRepository } from '../domain/VenueKpiRepository';
import axios from 'axios';

export class DashboardKpiRepository implements VenueKpiRepository {
  constructor(private merchantAnalyticsUrl: string) {
  }

  async getVenueKpi(businessId: string, timezone: string): Promise<VenueKpiDto> {
    const response = await axios.get<VenueKpiDto>(`${this.merchantAnalyticsUrl}/dashboard/${businessId}`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        timezone: timezone,
      },
    });
    return response.data;
  }
}
