import { Box, Container, styled } from '@mui/material';

export const PageLayout = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'noBanner',
})<{ noBanner?: boolean }>(({ theme, noBanner }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  maxWidth: '100%',
  margin: 0,
  padding: `${theme.spacing(0)}!important`,

  [theme.breakpoints.down('tablet')]: {
    ...(noBanner && {
      marginTop: theme.spacing(8),
    }),
  },
}));

export const PageActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));

export const ActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(4),
}));
  