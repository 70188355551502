import React, { createContext, ReactNode } from 'react';
import { VenueContextProvider } from './venues/configuration/VenueContextProvider';
import { PaymentContextProvider } from './payment/configuration/PaymentContextProvider';
import { BoxContextProvider } from './box/configuration/BoxContextProvider';
import { OrderingMenuContextProvider } from './orderingMenus/configuration/OrderingMenuProvider';
import { AreaContextProvider } from './area/configuration/AreaContextProvider';
import { FileStorage } from './common/FileStorage';
import { MenuContextProvider } from '../menu/dynamic-menu/configuration/MenuContextProvider';
import { OrderingPaymentContextProvider } from './payment/configuration/OrderingPaymentContextProvider';
import { OrderingOrderContextProvider } from './orders/configuration/OrderContextProvider';
import { NotificationToAddType } from 'src/app/contexts/snackbars/SnackBarContext';

export interface OrderingConfig {
  menuBackendUrl: string;
  orderingBackendUrl: string;
  paymentBackendUrl: string;
  fileStorage: FileStorage;

  onPushNotification: (message: string, variant: NotificationToAddType['variant']) => void;
}

type ProviderConfig = OrderingConfig & {
  children: ReactNode;
};

export const OrderingConfigContext = createContext<Partial<ProviderConfig>>({});

const ContextProvider: React.FC<ProviderConfig> = ({ children, ...config }) => (
  <OrderingConfigContext.Provider value={config}>{children}</OrderingConfigContext.Provider>
);

export const OrderingContextProvider: React.FC<ProviderConfig> = ({ children, ...config }) => (
  <ContextProvider
    menuBackendUrl={config.menuBackendUrl}
    orderingBackendUrl={config.orderingBackendUrl}
    paymentBackendUrl={config.paymentBackendUrl}
    fileStorage={config.fileStorage}
    onPushNotification={config.onPushNotification}
  >
    <MenuContextProvider menuBackendUrl={config.menuBackendUrl}>
      <VenueContextProvider orderingBackendUrl={config.orderingBackendUrl}>
        <BoxContextProvider orderingBackendUrl={config.orderingBackendUrl}>
          <AreaContextProvider orderingBackendUrl={config.orderingBackendUrl}>
            <OrderingMenuContextProvider orderingBackendUrl={config.orderingBackendUrl}>
              <PaymentContextProvider paymentBackendUrl={config.paymentBackendUrl}>
                <OrderingPaymentContextProvider orderingBackendUrl={config.orderingBackendUrl}>
                  <OrderingOrderContextProvider orderingBackendUrl={config.orderingBackendUrl}>
                    {children}
                  </OrderingOrderContextProvider>
                </OrderingPaymentContextProvider>
              </PaymentContextProvider>
            </OrderingMenuContextProvider>
          </AreaContextProvider>
        </BoxContextProvider>
      </VenueContextProvider>
    </MenuContextProvider>
  </ContextProvider>
);
