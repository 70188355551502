import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Textarea from '../../../../../common/components/Textarea';
import { FulfillmentType } from 'src/ordering/area/domain/FulfillmentType';
import { getSpacing } from 'src/ordering/stylesheet';
import { fulfillmentMethodMessages } from 'src/ordering/area/components/fulfillmentMethodMessages';

type UpdateMessageLineProps = {
  serviceType: FulfillmentType;
  message: string;
  setMessage: (serviceType: FulfillmentType, message: string) => void;
  disabled: boolean;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(2)};
`;

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const Title = styled.div`
  margin-bottom: ${getSpacing(1)};
`;

export const UpdateMessageLine = ({
  serviceType, message, setMessage, disabled,
}: UpdateMessageLineProps) => {
  const [text, setText] = useState<string>(message);

  const isUpdated = text !== message;

  const label = fulfillmentMethodMessages[serviceType];

  return (
    <Container>
      <Title>{label}</Title>
      <Textarea value={text} onValueChange={setText} disabled={disabled} />
      {isUpdated && (
        <Line>
          <SaveButton variant="primary" size="xSmall" onClick={() => setMessage(serviceType, text)} disabled={disabled}>
            <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
          </SaveButton>
        </Line>
      )}
    </Container>
  );
};
