import React, { useState } from 'react';
import { useVenueKpiQuery } from 'src/home/hooks/useVenueKpiQuery';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { HOME_STATE } from '@constants/HomeConstants';
import { HomeStatus } from './components/Status/HomeStatus';
import { HomePage } from './containers/HomePage';

export const Home = () => {
  const business = useCurrentBusinessOrThrow();

  const {
    data: venueKpi,
    isSuccess,
    isError,
    isLoading,
  } = useVenueKpiQuery(business.id, business.currency, business.timezone);

  const [loadingState] = useState<string>(isLoading ? HOME_STATE.LOADING : '');
  const [errorState] = useState<string>(isError ? HOME_STATE.ERROR : '');

  return (
    <>
      {(isLoading || isError) && (
        <HomeStatus state={loadingState !== '' ? loadingState : errorState} />
      )}
      {isSuccess && venueKpi && (
        <HomePage business={business} venueKpi={venueKpi} />
      )}
    </>
  );
};
