import React from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { AreaSettingContainer } from '../../../components/Settings.style';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { Table } from '../../../../venues/domain/Table';
import { AreaBoxTable } from './AreaBoxTable';
import { useRouting } from '../../../../common/hooks';
import { useAssociateAreaTables } from '../../../hooks';
import { BusinessId } from 'src/business/domain/Business';

type AreaBoxCreationTableSelectionProps = {
  businessId: BusinessId;
  boxId: string;
  areaId: string;
  tables: Table[];
  selectedTables: string[];
  onBackButton: () => void;
  onTablesSelected: (tableIds: string[]) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${getSpacing(3)};
  padding-right: ${getSpacing(3)};
  padding-top: ${getSpacing(3)};
  padding-bottom: ${getSpacing(2)};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: ${getSpacing(4.5)};
`;

const ActionLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 50vh;
`;

export const AreaBoxCreationTableSelection = ({
  businessId,
  boxId,
  areaId,
  tables,
  selectedTables,
  onBackButton,
  onTablesSelected,
}: AreaBoxCreationTableSelectionProps) => {
  const navigation = useRouting();
  const [associationStatus, associateAreaTables] = useAssociateAreaTables();

  const onTableSelectionDone = async () => {
    await associateAreaTables(areaId, selectedTables);

    navigation.goToArea(businessId, boxId, areaId);
  };

  const tableSelected = (tableId: string, selected: boolean) => {
    if (selected) {
      onTablesSelected([...selectedTables, tableId]);
      return;
    }

    onTablesSelected(selectedTables.filter((id) => id !== tableId));
  };

  const isTableSelected = (tableId: string): boolean => selectedTables.find((id) => id === tableId) !== undefined;

  return (
    <AreaSettingContainer>
      <Container>
        <Title>
          <FormattedMessage
            id="settings.area.create.table-selection.title"
            defaultMessage="do you want to add tables to this area?"
          />
        </Title>
        <TableContainer>
          {tables.map((table) => (
            <AreaBoxTable
              key={table.id}
              table={table}
              selected={isTableSelected(table.id)}
              onToggled={tableSelected}
              disabled={associationStatus.loading}
            />
          ))}
        </TableContainer>
      </Container>
      <ActionLine>
        <Button variant="tertiary" size="large" marginTop="8px" onClick={onBackButton}>
          <FormattedMessage id="settings.area.create.back-btn" defaultMessage="back" />
        </Button>
        <Button variant="primary" size="large" marginTop="8px" onClick={onTableSelectionDone}>
          <FormattedMessage id="settings.area.create.associate-table-btn" defaultMessage="associate tables" />
        </Button>
      </ActionLine>
    </AreaSettingContainer>
  );
};
