import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { dimensionsPx } from '../design/dimensions';
import { palette } from '../design/palette';
import sunday from '../assets/venueKpi/organization/sunday.png';

export const OrganizationFooter = () => {
  const { formatMessage } = useIntl();
  return (
    <>
      <Stack
        display="flex"
        textAlign="center"
        justifyContent="center"
        bgcolor={palette.primary.white}
        p={dimensionsPx.section_separator.l}
      >
        <Stack display="flex" flexDirection="row" justifyContent="center" textAlign="center" gap={1}>
          <img
            height={dimensionsPx.line_height.s}
            src={sunday}
            alt="sunday logo"
          />
          <Typography>sunday</Typography>
        </Stack>
        <Typography>{formatMessage({ id: 'venuekpi.weeklyreport.multi.email.sunday_save_time' })}</Typography>
      </Stack>
    </>
  );
};
