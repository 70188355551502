import { CountryCode } from 'src/domain/CountryCode';
import { getCountryCodeAsString } from 'src/utils/CountryCodeAsString';
import { LocalisationKey } from 'src/lang/en';
import { IntlShape } from 'src/app/i18n/TypedIntl';

export function countryHasABusinessNumber(countryCode: CountryCode | undefined) {
  return countryCode === CountryCode.FR;
}

export function countryHasRcsNumber(countryCode: CountryCode | undefined) {
  return countryCode === CountryCode.FR;
}

export function businessNumberLabelForCountry(countryCode: CountryCode | undefined, intl: IntlShape) {
  if (countryCode === CountryCode.FR) {
    return intl.formatMessage({ id: 'settings.payments.FR_business_number' });
  }
  if (countryCode === CountryCode.US) {
    return intl.formatMessage({ id: 'settings.payments.US_business_number' });
  }
  return '';
}

const supportedLocalisationKeys = ['BE', 'CA', 'DE', 'ES', 'FR', 'GB', 'IT', 'NL', 'PT', 'US'] as const;

export function taxIdLabelForCountry(countryCode: CountryCode | undefined): LocalisationKey {
  if (countryCode === CountryCode.UNSPECIFIED || countryCode === undefined) {
    return 'settings.payments.default_tax_id';
  }

  const countryLocalisationKey = supportedLocalisationKeys.find((key) => key === getCountryCodeAsString(countryCode));
  if (countryLocalisationKey) {
    return `settings.payments.${countryLocalisationKey}_tax_id`;
  }
  return 'settings.payments.default_tax_id';
}
export function secondaryTaxIdLabelKeyForCountry(countryCode: CountryCode | undefined): LocalisationKey | undefined {
  if (countryCode === CountryCode.CA) {
    return 'settings.payments.CA_secondary_tax_id';
  }
  return undefined;
}
