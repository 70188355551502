import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { BookkeepingRepositoryHttp, ChargebeePaymentMethodStatusType } from '../infrastructure/BookkeepingRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export const QUERYKEY_CHARGEBEE_PAYMENT_METHOD_STATUS = 'chargebee_payment_method_status';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_CHARGEBEE_PAYMENT_METHOD_STATUS, businessId] as const;
export const getChargebeePaymentMethodStatus = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<ChargebeePaymentMethodStatusType> => {
  const [, businessId] = queryKey;
  const configuration = ConfigurationLoader.load();
  const repository = new BookkeepingRepositoryHttp(configuration.bookkeepingApiBaseUrl);

  return repository.getChargebeePaymentMethodStatus(businessId);
};

export const useGetChargebeePaymentMethodStatus = (businessId: BusinessId) => useQuery({
  queryKey: buildQueryKey(businessId),
  queryFn: getChargebeePaymentMethodStatus,
  staleTime: 5 * 60 * 1000,
  retry: false,
});
