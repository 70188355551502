import { queryKeys } from 'src/app/queries/utils';
import { useContext } from 'react';
import { StaticMenuContext } from '../../context/MenuContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { StaticMenu, StaticMenuIdSchema } from '../../domain/StaticMenus';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdateStaticMenu = () => {
  const queryClient = useQueryClient();
  const { menuRepository } = useContext(StaticMenuContext);
  const mutation = useMutation({
    mutationFn: ({ businessId, menu }: { businessId: BusinessId; menu: StaticMenu }) =>
      menuRepository.updateMenu(
        businessId,
        StaticMenuIdSchema.parse(menu.id),
        menu.description,
        menu.descriptionDetails,
        menu.visibility,
        menu.constraints!!,
      ),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.staticMenus.LIST_MENUS] }),
  });
  return {
    mutation,
    updateStaticMenu: (businessId: BusinessId, menu: StaticMenu) => mutation.mutateAsync({ businessId, menu }),
  };
};
