import { ReactNode, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import ReactDOM from 'react-dom';
import { SnackbarsContainer } from './Snackbars.style';
import { NotificationToAddType, SnackBarContext } from './SnackBarContext';
import { SnackBarNotification } from './SnackBarNotification';

const AUTO_DISMISSAL_TIME = 3000;

export type NotificationType = NotificationToAddType & {
  id: string;
};

export const SnackBarProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<NotificationType[]>([]);
  const notificationsRef = useRef(notifications);
  notificationsRef.current = notifications;

  const addNotification = (notification: NotificationToAddType) => {
    const id = `${Math.random()}`;

    if (notifications.length > 3) {
      notifications.pop();
    }
    setNotifications([{ ...notification, id }, ...notifications]);

    setTimeout(() => {
      const updatedNotifs = notificationsRef.current.filter((notif) => notif.id !== `${id}`);
      setNotifications(updatedNotifs);
    }, AUTO_DISMISSAL_TIME);
  };

  return (
    <SnackBarContext.Provider value={{ addNotification }}>
      <>
        {ReactDOM.createPortal(
          <SnackbarsContainer>
            {notifications.map((notification) => (
              <SnackBarNotification key={notification.id} notification={notification} onClose={() => {}} />
            ))}
          </SnackbarsContainer>,
          document.body,
        )}
        {children}
      </>
    </SnackBarContext.Provider>
  );
};
