import { Checkbox, Divider, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useUserPayAtTableBusinesses } from 'src/data-analytics/hooks/useUserPayAtTableBusinesses';
import { Business } from 'src/business/domain/Business';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

type Props = {
  selectedBusiness: Business,
  selectedBusinesses: Business[],
  onSelectBusinesses: (selectedBusinesses: Business[]) => void
};

export const SelectMenuTargetStep = (
  {
    selectedBusiness,
    selectedBusinesses,
    onSelectBusinesses,
  }: Props,
) => {
  const businesses = useUserPayAtTableBusinesses().filter((business) => business.id !== selectedBusiness.id);

  const handleChange = (event: SelectChangeEvent<Business[]>) => {
    onSelectBusinesses(event.target.value as Business[]);
  };

  return (
    <>
      <Divider>
        <FormattedMessage id="common.to" />
      </Divider>
      <FormControl style={{ minWidth: '150px' }}>
        <InputLabel id="multiple-checkbox-target">
          <FormattedMessage id="configuration_replication.select_target.destination" />
        </InputLabel>
        <Select
          labelId="multiple-checkbox-target"
          id="multiple-checkbox-target-name"
          label={<FormattedMessage id="configuration_replication.select_target.destination" />}
          multiple
          value={selectedBusinesses}
          onChange={handleChange}
          renderValue={(selected) => selected.map((b) => b.name).join(', ')}
        >
          {businesses.map((b) => (
            // @ts-ignore
            <MenuItem key={b.id} value={b}>
              <Checkbox checked={selectedBusinesses.includes(b)} />
              <ListItemText primary={b.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
