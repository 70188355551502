import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { configureDatadogForVenue } from 'src/monitoring/datadog';
import { TrackingContextEventEnrichmentPlugin } from 'src/app/tracking/TrackingContextEventEnrichmentPlugin';
import { BusinessReferentialRepositoryHttp } from '../infrastructure/BusinessReferentialRepositoryHttp';
import { Business, BusinessId } from '../domain/Business';
import { datadogLogs } from '@datadog/browser-logs';

const businessQueryFunction = (businessId: BusinessId) => async (): Promise<Business> => {
  const configuration = ConfigurationLoader.load();
  const businessReferentialRepository = new BusinessReferentialRepositoryHttp(configuration.venueApiBaseUrl);
  const business: Business | undefined = await businessReferentialRepository.getById(businessId);
  if (!business) {
    const message = `business '${businessId}' not found in business referential!`;
    datadogLogs.logger.warn(message);
    throw new Error(message);
  }

  configureDatadogForVenue(business.id, business.name);
  TrackingContextEventEnrichmentPlugin.setVenue({
    venueId: business.id,
    businessId: business.id,
    venueName: business.name,
    businessName: business.name,
    venueCountryCode: business.address.countryCode.toString(),
  });

  return business;
};

export const businessQuery = (businessId: BusinessId) => ({
  queryKey: [ queryKeys.BUSINESSES, businessId ],
  queryFn: businessQueryFunction(businessId),
});
