import { Role } from './Role';
import { Relation } from 'src/domain/user/Relation';

export class UserWithRoles {
  constructor(
    public id: string,
    public email: string,
    public firstName: string,
    public lastName: string,
    public phoneNumber: string,
    public roles: Role[],
    public relations: Relation[],
  ) {}
}
