import { IconProps, SvgIcon } from '@mui/material';
import React from 'react';

export const TrendDownIcon = ({ sx }: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" sx={{ width: '100%', height: '100%', ...sx }}>
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M3.51835 3.51811C-0.0614554 7.09791 -0.0614554 12.9019 3.51835 16.4817C7.09816 20.0615 12.9022 20.0615 16.482 16.4817C20.0618 12.9019 20.0618 7.09791 16.482 3.51811C12.9022 -0.0616996 7.09816 -0.0616996 3.51835 3.51811ZM7.05388 7.05364C7.37932 6.7282 7.90696 6.7282 8.23239 7.05364L11.5239 10.3451V7.6429C11.5239 7.18266 11.8969 6.80956 12.3572 6.80956C12.8174 6.80956 13.1905 7.18266 13.1905 7.6429V12.3569C13.1905 12.8172 12.8174 13.1903 12.3572 13.1903H7.64314C7.1829 13.1903 6.80981 12.8172 6.80981 12.3569C6.80981 11.8967 7.1829 11.5236 7.64314 11.5236H10.3453L7.05388 8.23215C6.72845 7.90671 6.72845 7.37908 7.05388 7.05364Z" fill="#E77171"/>
    </SvgIcon>
  );
};

