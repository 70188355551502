import { useCurrentUser } from 'src/auth/component/UserProvider';
import { businessQuery } from 'src/business/queries/businessQuery';
import { Business, BusinessId } from 'src/business/domain/Business';
import { useEffect, useState } from 'react';

export const useUserPayAtTableBusinesses = () => {
  const user = useCurrentUser();

  const [businesses, setBusinesses] = useState<Business[]>([]);

  async function listUserPayAtTableBusinesses() {
    const isFulfilled = <T>(promise: PromiseSettledResult<T>): promise is PromiseFulfilledResult<T> =>
      promise.status === 'fulfilled';

    const promises = await Promise.allSettled(
      user.claims.map((c) => businessQuery(c.businessId as BusinessId).queryFn()),
    );

    return promises
      .filter(isFulfilled)
      .filter(({ value }) => value.patEnrollment !== undefined)
      .map(({ value }) => value);
  }

  useEffect(() => {
    (async () => {
      const patBusinesses = await listUserPayAtTableBusinesses();
      setBusinesses(patBusinesses);
    })();
  }, [user]);

  return businesses;
};
