import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERYKEY_MENU_BLOCK } from '../../queries/block/getBlockByIdQuery';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { UpdateBlockDto } from '../../repositories/block/BlockDto';
import { BlockRepositoryHttp } from '../../repositories/block/BlockRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const useBlockTranslationMutation = (businessId: BusinessId, blockId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const blockRepository = new BlockRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: ({ language, updateBlock }: {
      language: string,
      updateBlock: UpdateBlockDto
    }) => blockRepository.updateBlockTranslation(businessId, blockId, language, updateBlock),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_BLOCK] });
    },
  });
};
