import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { CardMedia, CircularProgress } from '@mui/material';
import { VenueKpi } from 'src/home/domain/VenueKpi';

import { KpiTimeframeName } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

type HomeStatus = {
  [KEY: string]: {
    MEDIA: React.ReactNode;
    TITLE: string;
    SUBTITLES: string[];
    BUTTON: string;
  }
};

export type HomeCardProps = {
  venueKpi: VenueKpi;
  selectedTimeFrame: KpiTimeframeName;
};

export const HOME_STATE = {
  'ERROR': 'IS_ERROR',
  'LOADING': 'IS_LOADING',
  'NULL': 'IS_EMPTY',
};

export const HOME_STATUS: HomeStatus = {
  'IS_ERROR': {
    'MEDIA': <ErrorOutlineIcon fontSize="large" />,
    'TITLE': 'We couldn\'t fetch your data',
    'SUBTITLES': ['Please, refresh your browser\'s page.'],
    'BUTTON': 'Refresh this page',
  },
  'IS_LOADING': {
    'MEDIA': <CircularProgress color="secondary" />,
    'TITLE': 'Loading your data...',
    'SUBTITLES': [
      'This process may take a few seconds,',
      'please do not refresh the page...',
    ],
    'BUTTON': '',
  },
  'IS_EMPTY': {
    'MEDIA': <CardMedia sx={{
      height: '215px',
      objectFit: 'contain',
      width: '260px',
    }} component="img" src="../../../../public/home_empty_status.svg" />,
    'TITLE': 'You\'ll see plenty of data after your first full day of service',
    'SUBTITLES': [
      'Your first service is scheduled for April 24th 2024. ',
      'Come back to check your performance right after.',
    ],
    'BUTTON': '',
  },
};

export const HomeStatsButtonType = {
  'REVENUE': 'REVENUE',
  'ADOPTION': 'ADOPTION',
  'GOOGLE_RATING': 'GOOGLE_RATING',
  'GOOGLE_REVIEW': 'GOOGLE_REVIEW',
};
