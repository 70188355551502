import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';

export const useSaveCoverBackgroundColor = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();
  const mutation = useMutation({
    mutationFn: ({
      enrollmentId,
      coverBackgroundColor,
    }: {
      enrollmentId: EnrollmentId;
      coverBackgroundColor: string;
    }) => customizationRepository.saveCoverBackgroundColor(enrollmentId, coverBackgroundColor),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION] }),
  });
  return {
    mutation,
    saveCoverBackgroundColor: (enrollmentId: EnrollmentId, coverBackgroundColor: string) =>
      mutation.mutateAsync({ enrollmentId, coverBackgroundColor }),
  };
};
