import { Grid } from '@mui/material';
import React from 'react';
import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import { DimensionsTrends } from './DimensionsTrends';
import { DimensionsTrendsViewModel } from './DimensionsTrendsViewModel';
import { AllDimensionCards } from './AllDimensionCards';

interface Props {
  dimensions: DimensionAnalysis[];
  selectedDimensionState: [DimensionAnalysis | undefined, (dimension: DimensionAnalysis) => void],
  dimensionsTrendsViewModel: DimensionsTrendsViewModel,
}

export const DimensionsAnalysisOverview = ({
  dimensions,
  selectedDimensionState,
  dimensionsTrendsViewModel,
}: Props) => (
  <>
    <Grid container spacing={2}>
      <Grid container item spacing={2} xs={12} md={6}>
        <AllDimensionCards dimensions={dimensions} selectedDimensionState={selectedDimensionState} />
      </Grid>
      <Grid item xs={12} md={6}>
        <DimensionsTrends selectedDimension={selectedDimensionState[0]} viewModel={dimensionsTrendsViewModel} />
      </Grid>
    </Grid>
  </>
);
