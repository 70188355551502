import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { ButtonContainer, CustomizationForm, NotificationContainer } from '../Customization.styles';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { useUpdateGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useUpdateGlobalConfiguration';
import { Alert, Stack, TextField, Typography, useTheme } from '@mui/material';
import { isAValidUrl } from 'src/pages/Settings/Customization/components/IsAValidUrl';

type AppSettingsSocialMediaInputs = {
  webSiteLink: string;
  instagramLink: string;
  facebookLink: string;
  twitterLink: string;
  tiktokLink: string;
  customLink: string;
  customLinkTitle: string;
};

const SocialMediaHandler = () => {
  const useSnackBar = useSnackbar();
  const intl = useIntl();
  const theme = useTheme();

  const [appSettingsSocialMediaUpdateError, setAppSettingsSocialMediaUpdateError] = useState('');
  const { data: venueConfiguration } = useGlobalConfiguration();
  const { updateGlobalConfiguration } = useUpdateGlobalConfiguration();

  const {
    register,
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<AppSettingsSocialMediaInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  useEffect(() => {
    if (!venueConfiguration) {
      return;
    }
    reset({
      webSiteLink: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaWebsitePage),
      instagramLink: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaInstagramPage),
      facebookLink: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaFacebookPage),
      twitterLink: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaTwitterPage),
      tiktokLink: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaTiktokPage),
      customLink: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaCustomLink),
      customLinkTitle: venueConfiguration.entries.get(VenueConfigurationKey.socialMediaCustomLinkTitle),
    });
  }, [venueConfiguration]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));
  const onSubmit = async (inputs: AppSettingsSocialMediaInputs) => {
    setAppSettingsSocialMediaUpdateError('');
    try {
      const venueConfigurationToSave: [VenueConfigurationKey, any][] = [];

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaWebsitePage, String(inputs.webSiteLink)]);

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaInstagramPage, String(inputs.instagramLink)]);

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaFacebookPage, String(inputs.facebookLink)]);

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaTwitterPage, String(inputs.twitterLink)]);

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaTiktokPage, String(inputs.tiktokLink)]);

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaCustomLinkTitle, String(inputs.customLinkTitle)]);

      venueConfigurationToSave.push([VenueConfigurationKey.socialMediaCustomLink, String(inputs.customLink)]);

      await updateGlobalConfiguration(new Map<VenueConfigurationKey, any>(venueConfigurationToSave));

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setAppSettingsSocialMediaUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <CustomizationForm onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={1}>
          <Typography variant={'h6'}>
            <FormattedMessage id="settings.app_settings.hamburger_menu.title" />
          </Typography>
          <Typography variant={'body2'} color={theme.palette.text.secondary}>
            <FormattedMessage id="settings.app_settings.hamburger_menu.description" />
          </Typography>
        </Stack>

        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.web_site_link' })}
          id="webSiteLink"
          placeholder={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.web_site_link.placeholder' })}
          error={!!errors.webSiteLink}
          helperText={!!errors.webSiteLink ? errors.webSiteLink?.message : ''}
          InputLabelProps={{ shrink: true }}
          {...register('webSiteLink', {
            deps: [],
            required: false,
            validate: {
              isValidUrl: (value) => {
                if (value) {
                  return isAValidUrl(value) ? true : 'Invalid url';
                }
                return true;
              },
            },
          })}
        />
        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.instagram_link' })}
          id="instagramLink"
          placeholder={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.instagram_link.placeholder' })}
          error={!!errors.instagramLink}
          helperText={!!errors.instagramLink ? errors.instagramLink?.message : ''}
          InputLabelProps={{ shrink: true }}
          {...register('instagramLink', {
            deps: [],
            required: false,
            validate: {
              isValidUrl: (value) => {
                if (value) {
                  return isAValidUrl(value) ? true : 'Invalid url';
                }
                return true;
              },
            },
          })}
        />
        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.facebook_link' })}
          id="facebookLink"
          placeholder={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.facebook_link.placeholder' })}
          error={!!errors.facebookLink}
          helperText={!!errors.facebookLink ? errors.facebookLink?.message : ''}
          InputLabelProps={{ shrink: true }}
          {...register('facebookLink', {
            deps: [],
            required: false,
            validate: {
              isValidUrl: (value) => {
                if (value) {
                  return isAValidUrl(value) ? true : 'Invalid url';
                }
                return true;
              },
            },
          })}
        />
        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.twitter_link' })}
          id="twitterLink"
          placeholder={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.twitter_link.placeholder' })}
          error={!!errors.twitterLink}
          helperText={!!errors.twitterLink ? errors.twitterLink?.message : ''}
          InputLabelProps={{ shrink: true }}
          {...register('twitterLink', {
            deps: [],
            required: false,
            validate: {
              isValidUrl: (value) => {
                if (value) {
                  return isAValidUrl(value) ? true : 'Invalid url';
                }
                return true;
              },
            },
          })}
        />
        <TextField
          label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.tiktok_link' })}
          id="tiktokLink"
          placeholder={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.tiktok_link.placeholder' })}
          error={!!errors.tiktokLink}
          helperText={!!errors.tiktokLink ? errors.tiktokLink?.message : ''}
          InputLabelProps={{ shrink: true }}
          {...register('tiktokLink', {
            deps: [],
            required: false,
            validate: {
              isValidUrl: (value) => {
                if (value) {
                  return isAValidUrl(value) ? true : 'Invalid url';
                }
                return true;
              },
            },
          })}
        />

        <Stack padding={2} borderRadius={4} spacing={2} border={`1px solid ${theme.palette.grey['300']}`}>
          <Typography>
            <FormattedMessage id="settings.app_settings.hamburger_menu.custom_link.title" />
          </Typography>
          <TextField
            label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.custom_link.link_title' })}
            id="customLinkTitle"
            placeholder={intl.formatMessage({
              id: 'settings.app_settings.hamburger_menu.custom_link.link_title.placeholder',
            })}
            error={!!errors.customLinkTitle}
            InputLabelProps={{ shrink: true }}
            helperText={!!errors.customLinkTitle ? errors.customLinkTitle?.message : ''}
            {...register('customLinkTitle', {
              deps: [],
              required: false,
              validate: {},
            })}
          />

          <TextField
            label={intl.formatMessage({ id: 'settings.app_settings.hamburger_menu.custom_link.link' })}
            id="customLink"
            placeholder={intl.formatMessage({
              id: 'settings.app_settings.hamburger_menu.custom_link.link.placeholder',
            })}
            type='url'
            error={!!errors.customLink}
            InputLabelProps={{ shrink: true }}
            helperText={!!errors.customLink ? errors.customLink?.message : ''}
            {...register('customLink', {
              deps: [],
              required: false,
              validate: {
                isValidUrl: (value) => {
                  if (value) {
                    return isAValidUrl(value) ? true : 'Invalid url';
                  }
                  return true;
                },
              },
            })}
          />
        </Stack>

        {appSettingsSocialMediaUpdateError && (
          <NotificationContainer>
            <Alert severity="error">{appSettingsSocialMediaUpdateError}</Alert>
          </NotificationContainer>
        )}
        {isValid && isDirty && (
          <ButtonContainer>
            <Button type="submit" variant="primary" size="xSmall" disabled={!isValid || isSubmitting || !isDirty}>
              <FormattedMessage id="settings.save_changes" />
            </Button>
          </ButtonContainer>
        )}
      </Stack>
    </CustomizationForm>
  );
};

export default SocialMediaHandler;
