import React from 'react';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Stack, Typography } from '@mui/material';
import { formatDate } from 'src/tips/dispatch/components/formatDate';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Variant } from '@mui/material/styles/createTypography';

export const PeriodDisplay = ({ start, end, variant }: { start: Date; end: Date; variant: Variant }) => {
  const business = useCurrentBusinessOrThrow();

  return (
    <Stack direction={'row'} spacing={0.5} justifyContent={'flex-start'}>
      <Typography variant={variant}>
        <FormattedMessage id={'tips.sunday_pooling.dispatch.period.from'} />
      </Typography>
      <Typography variant={variant}>
        {formatDate(start, business.timezone).date.trim()}
      </Typography>
      <Typography variant={variant}>
        <FormattedMessage id={'tips.sunday_pooling.dispatch.period.to'} />
      </Typography>
      <Typography variant={variant}>
        {formatDate(end, business.timezone).date.trim()}
      </Typography>
    </Stack>
  );
};
