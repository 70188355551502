import React, { useEffect, useState } from 'react';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Area } from '../../../domain/Area';
import { useGetBoxAreas } from '../../../hooks';
import { AreaSummary } from './AreaSummary';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { useGetMenus } from '../../../../orderingMenus/hooks';
import { NoAreaPanel } from './NoAreaPanel';
import { useRouting } from '../../../../common/hooks';
import { AreaSettingContainer } from '../../../components/Settings.style';
import { BusinessId } from 'src/business/domain/Business';
import { CircularProgress } from '@mui/material';

type AreaBoxSettingsProps = {
  businessId: BusinessId;
  boxId: string;
};

export const AreaBoxSettings = ({ businessId, boxId }: AreaBoxSettingsProps) => {
  const navigation = useRouting();

  const [, getBoxAreas] = useGetBoxAreas();
  const [areas, setAreas] = useState<Area[]>([]);

  const [menus, setMenus] = useState<OrderingMenu[]>([]);
  const [menuLoadingState, getMenus] = useGetMenus();

  useEffect(() => {
    getBoxAreas(boxId).then((fetchedAreas) => setAreas(fetchedAreas.sort((a, b) => a.name.localeCompare(b.name))));
  }, [getBoxAreas, boxId]);

  useEffect(() => {
    getMenus(boxId).then(setMenus);
  }, [getMenus, boxId]);

  if (menuLoadingState.loading) {
    return <CircularProgress size="large" />;
  }

  const onNewArea = () => {
    navigation.goToAreaCreation(businessId, boxId);
  };

  if (areas.length === 0) {
    return <NoAreaPanel onNewArea={onNewArea} />;
  }

  return (
    <AreaSettingContainer>
      {areas.map((area) => (
        <AreaSummary businessId={businessId} boxId={boxId} area={area} menus={menus} key={area.id} />
      ))}
      <Button variant="primary" size="large" marginTop="8px" onClick={onNewArea}>
        <FormattedMessage id="settings.area.create-btn" defaultMessage="Create a new area" />
      </Button>
    </AreaSettingContainer>
  );
};
