import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDependencies } from 'src/tips/dispatch/useDependencies';
import { BusinessId } from 'src/business/domain/Business';
import { TipsAllocations } from 'src/tips/dispatch/model/TipsAllocation';
import { queryKeys } from 'src/app/queries/utils';

export const useDispatchTips = () => {
  const dependencies = useDependencies();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (params: { businessId: BusinessId; tipsDispatchId: string; allocations: TipsAllocations }) =>
      dependencies.sundayPoolingRepository.dispatchTips(params.businessId, params.tipsDispatchId, params.allocations),
    onSuccess: () =>
      queryClient.invalidateQueries({
        predicate: (query) =>
          [
            queryKeys.sundayTipsPooling.GET_DONE_TIPS_DISPATCH,
            queryKeys.sundayTipsPooling.GET_PENDING_TIPS_DISPATCH,
          ].filter((value) => value === query.queryKey[0]).length > 0,
      }),
  });
  return {
    mutation,
    dispatchTips: (businessId: BusinessId, tipsDispatchId: string, allocations: TipsAllocations) =>
      mutation.mutateAsync({ businessId, tipsDispatchId, allocations }),
  };
};
