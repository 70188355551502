import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { queryKeys } from 'src/app/queries/utils';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { TimePeriod } from '../common/domain/TimePeriod';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import { BoxConfigContext } from './configuration/BoxContextProvider';
import { Box } from './domain/Box';
import { BoxDetailsFields } from './domain/BoxDetails';

export const useBoxRepository = () => {
  const { boxRepository } = useContext(BoxConfigContext);
  return boxRepository!;
};

export const useGetVenueBoxes = (venueId?: EnrollmentId) => {
  const boxRepository = useBoxRepository();

  return useQuery({
    queryKey: [queryKeys.ordering.LIST_BOXES, { venueId }],
    queryFn: async () => {
      if (venueId) {
        return boxRepository.getVenueBoxes(venueId);
      }
      return [];
    },
  });
};

export const useGetPrioritizedVenueBoxes = (venueId: EnrollmentId, boxesPriorities?: string[]) => {
  const query = useGetVenueBoxes(venueId);

  if (!query.data) {
    return query;
  }

  const boxMap = new Map<string, Box>();
  query.data.forEach((box) => boxMap.set(box.id, box));

  const prioritizedBoxes = (boxesPriorities ?? [])
    .map((id) => boxMap.get(id)!)
    .filter((box) => box !== undefined);

  const remainingBoxes = query.data.filter((box) => !(boxesPriorities ?? []).includes(box.id));

  return { ...query, data: [...prioritizedBoxes, ...remainingBoxes] };
};

export const useUpdateBoxDetails = (boxId: string) => {
  const boxRepository = useBoxRepository();
  return useAsyncFn(
    async (update: Partial<BoxDetailsFields>) => boxRepository.updateBoxDetails(boxId, update),
    [boxRepository, boxId],
  );
};

export const useUpdateBoxOpenHours = (boxId: string) => {
  const boxRepository = useBoxRepository();
  return useAsyncFn(
    async (openingHours: TimePeriod[]) => boxRepository.updateBoxOpeningHours(boxId, openingHours),
    [boxRepository, boxId],
  );
};

export const useCreateBox = () => {
  const boxRepository = useBoxRepository();
  return useAsyncFn<Box, [enrollmentId: EnrollmentId, boxName: string]>(
    async (enrollmentId: EnrollmentId, boxName: string) => boxRepository.createBox(enrollmentId, boxName),
    [boxRepository],
  );
};

export const useDeleteBox = () => {
  const boxRepository = useBoxRepository();
  return useAsyncFn<void, [boxId: string]>(
    async (boxId: string) => boxRepository.deleteBox(boxId),
    [boxRepository],
  );
};
