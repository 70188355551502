import { TagDto } from './TagDto';
import { ProductSource, ProductType, productTypes, SnoozePeriod } from '../domain/Product';
import { AllergenDto } from './allergen/AllergenDto';
import { z } from 'zod';
import { Days } from 'src/menu/dynamic-menu/domain/Days';

const productTypeSchema = z.nativeEnum(productTypes);

const tagDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const allergenDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
});

const overridableValueDtoSchema = z.object({
  value: z.array(z.string()).nullish(),
  originalValue: z.array(z.string()).nullish(),
  isOverridden: z.boolean().optional(),
  canUpdateValue: z.boolean().optional(),
});

const moneyDtoSchema = z.object({
  amount: z.number(),
  currency: z.string(),
});

export type MoneyDto = z.infer<typeof moneyDtoSchema>;

const menuBasedPricingStrategyDtoSchema = z.object({
  _type: z.literal('MenuBasedPricingStrategy'),
  price: moneyDtoSchema,
});

const productPriceTimeOverrideDtoSchema = z.object({
  _type: z.literal('ProductPriceTimeOverride'),
  value: moneyDtoSchema,
  days: z.array(z.nativeEnum(Days)),
  timeRanges: z.array(z.object({
    start: z.string().time(),
    end: z.string().time(),
  })),
});

const pricingStrategyDtoSchema = z.union([
  menuBasedPricingStrategyDtoSchema,
  productPriceTimeOverrideDtoSchema,
]);

export type ProductPriceTimeOverrideDto = z.infer<typeof productPriceTimeOverrideDtoSchema>;

export type MenuBasedPricingStrategyDto = z.infer<typeof menuBasedPricingStrategyDtoSchema>;

export type PricingStrategyDto = z.infer<typeof pricingStrategyDtoSchema>;

const ProductDtoSchema = z.object({
  id: z.string(),
  name: z.string(),
  partnerName: z.string(),
  description: z.string(),
  partnerDescription: z.string(),
  shortDescription: z.string(),
  type: productTypeSchema,
  source: z.union([z.literal('SUNDAY'), z.literal('PARTNER')]),
  sku: z.string(),
  price: moneyDtoSchema,
  pictureUrl: z.string(),
  partnerPictureUrl: z.string(),
  tags: tagDtoSchema.array(),
  allergens: allergenDtoSchema.array(),
  relatedProductIds: z.array(z.string()),
  subProductIds: z.array(z.string()),
  subProductIdsInfos: overridableValueDtoSchema.optional(),
  partnerSubProductIds: z.array(z.string()),
  calories: z.number(),
  eatInTax: z.number().optional(),
  takeAwayTax: z.number().optional(),
  deliveryTax: z.number().optional(),
  isAvailableForOrder: z.boolean(),
  snoozePeriod: z.object({
    start: z.string(),
    end: z.string(),
  }).optional(),
  isEligibleForOrder: z.boolean().optional(),
  posConnection: z.object({
    posConnectionId: z.string(),
    externalRevenueCenterId: z.string().optional(),
  }).optional(),
  pricingStrategies: z.array(pricingStrategyDtoSchema).optional(),
});

export type ProductDto = z.infer<typeof ProductDtoSchema>;

export interface UpdateProductDto {
  name?: string;
  description?: string;
  shortDescription?: string;
  price?: MoneyDto;
  tags?: TagDto[];
  allergens?: AllergenDto[];
  relatedProductIds?: string[];
  subProductIds?: string[];
  calories?: number;
  eatInTax?: number;
  takeAwayTax?: number;
  deliveryTax?: number;
  isAvailableForOrder?: boolean;
}

export interface CreateProductDto {
  name: string;
  description: string;
  price: MoneyDto;
  type: ProductType;
}

export interface ProductSummaryDto {
  id: string;
  name: string;
  description: string;
  shortDescription: string;
  sku?: string;
  price: MoneyDto;
  basePrice: MoneyDto;
  pictureUrl: string;
  type: ProductType;
  hasDiscrepancies: boolean;
  snoozePeriod?: SnoozePeriod;
  posConnectionId?: string;
  externalRevenueCenterId?: string;
  source: (typeof ProductSource)[keyof typeof ProductSource];
}

export function isProduct(type: string): type is ProductType {
  return type in productTypes;
}

export interface ProductsSummaryDto {
  products: ProductSummaryDto[];
}

export type ElementDto = {
  id: string;
  type: string;
};

export type PaginatedProductsDto = {
  products: ProductDto[];
  totalCount: number;
};
