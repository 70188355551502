import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { ReconciliationConfiguration } from '../domain/ReconciliationConfiguration';

export const QUERYKEY_RECONCILIATION_ACTIVATION_STATUS = 'reconciliation_activation_status';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_RECONCILIATION_ACTIVATION_STATUS, businessId] as const;
export const getReconciliationConfiguration = async ({
  queryKey: [, businessId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<ReconciliationConfiguration> => {
  const repository = new ReconciliationRepositoryHttp();
  return repository.getReconciliationConfiguration(businessId);
};

export const useGetReconciliationConfiguration = (businessId: BusinessId) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: getReconciliationConfiguration });
