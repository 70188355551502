import { TextField } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const AccountNumber = ({ country }: { country: 'US' | 'GB' }) => {
  const {
    formState: { errors },
    register,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  const pattern = country === 'US' ? /^[0-9]{10,12}$/ : /^[0-9]{8,10}$/;

  return (
    <TextField
      required
      error={!!errors.bankAccount?.accountNumber}
      type={'text'}
      InputLabelProps={{ shrink: true }}
      {...register('bankAccount.accountNumber', {
        required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.accountNumber.required' }),
        pattern: { value: pattern, message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.accountNumber.invalid' }) },
      })}
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.accountNumber' })}
      helperText={errors.bankAccount?.accountNumber?.message || ''}
    />
  );
};
