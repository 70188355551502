// eslint-disable-next-line @typescript-eslint/no-redeclare
import React from 'react';
import PromotionHandler from './components/PromotionHandler';
import TipHandler from './components/TipHandler';
import { EMPTY_GLOBAL_CONFIGURATION } from 'src/venueconfiguration/domain/GlobalConfiguration';
import WifiSettingsHandler from './components/WifiSettingsHandler';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsPageContainer } from '../components/SettingsPageContainer';

const AppSettings = () => {
  const { data: globalConfiguration } = useGlobalConfiguration();
  const business = useCurrentBusinessOrThrow();
  const configuration = globalConfiguration ?? EMPTY_GLOBAL_CONFIGURATION;

  return (
    <SettingsPageContainer>
      <TipHandler business={business} />
      <WifiSettingsHandler businessId={business.id} configuration={configuration} />
      <PromotionHandler businessId={business.id} />
    </SettingsPageContainer>
  );
};

export default AppSettings;
