import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
import { Level } from 'src/utils/logs/plugins';
import { downloadBlob } from '../../../../payout/pages/utils';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { useConfiguration } from '../ConfigurationContainerContext';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { CircularProgress } from '@mui/material';

interface Props {
  period: RevenuePeriod;
  onError: () => void;
}

const DownloadWrapper = styled(DownloadIcon)`
  margin-right: 5px;
`;

export const DownloadPayouts: React.FC<Props> = ({ period, onError }: Props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);

  const businessId = useBusinessIdOrThrow();
  const configuration = useConfiguration();
  const log = useLog();
  const intl = useIntl();

  const downloadExport = () => {
    setInProgress(true);
    configuration.payoutPort
      .getPayoutsExport(businessId, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((blobWithMetadata) => {
        downloadBlob(blobWithMetadata, `${intl.formatMessage({ id: 'accounting.payout_tab.export_file' })}.csv`);
      })
      .catch((error) => {
        log(Level.ERROR, `Could not retrieve payouts summary export for venue id ${businessId} (${period.filter})`, error);
        onError();
      })
      .finally(() => setInProgress(false));
  };

  useEffect(() => () => {
    setInProgress(false);
  }, []);

  return (
    <>
      <Button variant="primary" size="medium" onClick={() => !inProgress && downloadExport()}
              data-testid="download-export-button">
        {inProgress ? (
          <CircularProgress color="inherit" size="large" />
        ) : (
          <>
            <DownloadWrapper />
            <FormattedMessage id="accounting.revenue_tab.panel.downloadPayouts" defaultMessage="download payouts" />
          </>
        )}
      </Button>
    </>
  );
};
