import React, { createContext, ReactNode, useContext } from 'react';
import styled from 'styled-components';
import { getSpacing } from '../../../stylesheet';

export interface DrawerInterface {
  refreshDrawerHeight: () => void;
}

type ProviderConfig = DrawerInterface & {
  children: ReactNode;
};

export const DrawerContent = styled.div`
  padding: ${getSpacing(3)};
`;

export const DrawerContext = createContext<DrawerInterface>({
  refreshDrawerHeight: () => true,
});

export const ConfigProvider: React.FC<ProviderConfig> = ({ children, ...config }) => <DrawerContext.Provider value={config}>{children}</DrawerContext.Provider>;

export const useRefreshDrawerHeight = () => {
  const { refreshDrawerHeight } = useContext(DrawerContext);
  return refreshDrawerHeight;
};
