import { createGlobalStyle } from 'styled-components';
import { themeV5 } from 'src/app/theme/ThemeV5';

export const CalendarStyle = createGlobalStyle`.react-calendar {
  &__viewContainer {
    width: 342px;
    height: 242px;
    margin: 10px;
  }

  &__navigation {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 20px 32px;

    &__label {
      order: 0;
      text-align: left;
    }

    &__arrow {
      order: 1;
    }
  }

  &__month-view__weekdays {
    text-align: center;
    padding-bottom: 8px;

    &__weekday {
      abbr {
        text-decoration: none;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.03em;
        text-transform: lowercase;
        color: ${themeV5.palette.text.secondary};
      }
    }
  }

  button {
    background-color: ${themeV5.palette.background.paper};
    border: 0;
    padding: 0;
    cursor: pointer;
    margin: 0;
  }

  &__tile {
    abbr {
      position: relative;
      z-index: 1;
      border-radius: 100%;
      border: 1px solid transparent;
      padding: 4px;
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      font-size: 14px;
      line-height: 16px;
      color: ${themeV5.palette.text.primary};

      &:hover {
        border-color: ${themeV5.palette.secondary.main};
      }
    }

    &[disabled] {
      abbr {
        color: ${themeV5.palette.text.disabled};
      }
    }

    &.react-calendar__tile--active {
      abbr {
        background-color: ${themeV5.palette.secondary.light};
        color: ${themeV5.palette.text.primary};
      }

      &.react-calendar__tile--rangeStart, &.react-calendar__tile--rangeEnd {
        abbr {
          color: ${themeV5.palette.primary.light};
          background-color: ${themeV5.palette.primary.contrastText};
        }
      }
    }

    &.react-calendar__tile--range {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 40px;
        margin-top: -20px;
        background-color: ${themeV5.palette.background.paper};
      }

      &.react-calendar__tile--rangeStart {
        &:before {
          left: 50%;
          width: 50%;
        }
      }

      &.react-calendar__tile--rangeEnd {
        &:before {
          width: 50%;
        }

        &.react-calendar__tile--rangeStart {
          &:before {
            display: none;
          }
        }
      }
    }

    &.react-calendar__tile--now abbr {
      border-color: ${themeV5.palette.grey[300]};
    }
  }`;
