import styled, { css } from 'styled-components';
import { baseStackStyle, StackProps } from './Stack';
import { spacingInCss } from './Spacing';
import { sizeInCss } from './Size';

type Props = {
  fullHeight?: boolean;
  overflow?: string;
  fullWidth?: boolean;
};

export const Column = styled.div<StackProps & Props>`
  ${baseStackStyle};
  flex-direction: column;
  ${({ fullHeight }) => (fullHeight
    ? css`
      height: 100%;
    `
    : '')};

  ${({ fullWidth }) => (
    fullWidth ? css`
        width: 100%;
      `
      : '')};

  overflow: ${({ overflow }) => (overflow || 'visible')};
  padding: ${({ padding }) => (padding ? spacingInCss(padding) : '0')};

  > :not(:last-child) {
    margin-bottom: ${({ gap }) => (gap ? sizeInCss(gap) : '0')};
  }
`;
