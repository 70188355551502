export type Rating = {
  star: number;
  nbRatings: number;
};

export type RatingByDays = {
  nbRatings: number;
  date: Date;
};

export type RatingPercentage = {
  star: number;
  percentage: number;
  count: number;
};

export class RatingStatistics {
  constructor(
    public nbRatings: number,
    public averageRating: number,
    public ratingsByStars: Rating[],
    public ratingsByDays: RatingByDays[],
  ) {
  }

  hasRatings() {
    return this.nbRatings > 0;
  }

  ratingsPercentageOrderedByStar(): RatingPercentage[] {
    return this.ratingsByStars
      .sort((rating1, rating2) => rating2.star - rating1.star)
      .map((rating) => ({ star: rating.star, percentage: this.ratingAsPercent(rating), count: rating.nbRatings }));
  }

  private ratingAsPercent(rating: Rating) {
    return this.nbRatings === 0 ? 0 : Math.round((100 * rating.nbRatings) / this.nbRatings);
  }

  hasDataOnSeveralDays() {
    return this.ratingsByDays.length > 1;
  }

  static getMonday = (date:Date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  };

  groupRatingsByWeek(): Map<string, number> {
    return this.ratingsByDays.reduce((group, current) => {
      const groupingKey = RatingStatistics.getMonday(current.date).toString();

      if (!group.has(groupingKey)) {
        group.set(groupingKey, 0);
      }
      const ratingsByWeek = group.get(groupingKey)!.valueOf() + current.nbRatings;
      group.set(groupingKey, ratingsByWeek);
      return group;
    }, new Map<string, number>());
  }

  groupRatingsByMonth(): Map<string, number> {
    return this.ratingsByDays.reduce((group, current) => {
      const date = new Date(current.date);
      // We pad with 0 to be compliant for safari using intl date formatting
      const groupingKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;

      if (!group.has(groupingKey)) {
        group.set(groupingKey, 0);
      }
      const ratingsByMonth = group.get(groupingKey)!.valueOf() + current.nbRatings;
      group.set(groupingKey, ratingsByMonth);
      return group;
    }, new Map<string, number>());
  }

  removeLastWeek(lastSunday: Date): RatingByDays[] {
    const temp = new Date(lastSunday);
    return this.ratingsByDays.filter((ratingByDay) => new Date(ratingByDay.date).getTime() < temp.getTime());
  }
}
