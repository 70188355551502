import { Card, styled } from '@mui/material';
import { HomeActionReporting } from './HomeActionReporting';
import { HomeActionPayment } from './HomeActionPayment';
import { HomeActionOrderQrCode } from './HomeActionOrderQrCode';
import { breakpoints, themeV5 } from 'src/app/theme/ThemeV5';
import { HomeActionInvoice } from './HomeActionInvoice';
import { Business } from 'src/business/domain/Business';

type HomeActionsProps = {
  business: Business;
};

const ActionCard = styled(Card)`
  width: 200%;
  margin-top: 24px;
  @media (max-width: ${breakpoints.homeTablet}) {
    width: 100%;
    margin-top: 0;
  }
`;

const ActionLine = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  gap: 24px;
  @media (max-width: ${breakpoints.homeTablet}) {
    gap: 16px;
  }
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    gap: 0;
  }
`;

const BottomActionWrapper = styled('div')`
  display: flex;
  width: 50%;
  @media (max-width: ${breakpoints.mobile}) {
    border-bottom: 1px solid ${themeV5.palette.secondary.dark}23;
    width: 100%;
  }
`;

const ActionWrapper = styled(BottomActionWrapper)`
  border-bottom: 1px solid ${themeV5.palette.secondary.dark}23;
`;

export const HomeActions = ({ business }: HomeActionsProps) => {
  const isUSVenue = business.address.countryCode === 'US';

  return (
    <ActionCard>
      <ActionLine>
        <ActionWrapper>
          <HomeActionReporting business={business} />
        </ActionWrapper>
        <ActionWrapper>
          <HomeActionPayment business={business} />
        </ActionWrapper>
      </ActionLine>
      <ActionLine>
        <BottomActionWrapper>
          <HomeActionOrderQrCode business={business} />
        </BottomActionWrapper>
          <BottomActionWrapper>
            {!isUSVenue && (  <HomeActionInvoice business={business} />   )}
          </BottomActionWrapper>
      </ActionLine>
    </ActionCard>
  );
};
