import React from 'react';
import { InvoicePort } from '../domain/InvoicePort';
import { InvoiceInMemoryPort } from '../infrastructure/InvoiceInMemoryPort';

interface ConfigurationContainer {
  invoicePort: InvoicePort;
}

export const ConfigurationContainerContext = React.createContext<ConfigurationContainer>({
  invoicePort: InvoiceInMemoryPort(),
});
