// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { Box } from '@mui/material';

export type ConfigurationItemNameProps = {
  name: string;
  active: boolean;
};

export const ConfigurationItemName = ({ name, active }: ConfigurationItemNameProps) => {
  const fontColor = active ? colorUsage.primaryText : colorUsage.disabledText;

  return (
    <Box flexDirection="row" display="flex" textOverflow="ellipsis">
      <Text size="xlarge" color={fontColor} style={{ textOverflow: 'ellipsis' }}>
        {name}
      </Text>
    </Box>
  );
};

