import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { countries } from 'src/domain/countries';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const CountryOfBirth = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register, watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[legalRepresentativeIndex]?.countryOfBirth;
  const countryOfBirth = watch(`representatives.${legalRepresentativeIndex}.countryOfBirth`);
  return <TextField
    select
    fullWidth
    required
    defaultValue={countryOfBirth}
    error={!!error}
    InputLabelProps={{ shrink: true }}
    id="country-of-birth-select"
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.countryOfBirth' })}
    inputProps={register(`representatives.${legalRepresentativeIndex}.countryOfBirth`, { required: 'Pays de naissance est requis' })}
    helperText={error?.message || ''}
  >
    {
      countries.map(({ name, code }) => <MenuItem key={code} value={code}>{name}</MenuItem>)
    }
  </TextField>;
};
