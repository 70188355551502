import { EnrichmentPlugin } from '@amplitude/analytics-types';
import { ampli } from 'src/ampli';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { TrackingContextEventEnrichmentPlugin } from './TrackingContextEventEnrichmentPlugin';

export const getPageName = (pathname: string): string => {
  const venuesRegex = /^\/venues\/([a-fA-F0-9-]+)(\/.*)?$/;
  const venuesMatch = pathname.match(venuesRegex);

  const pageName = venuesMatch ? venuesMatch[2] : pathname;
  if (pageName === undefined) {
    return '/';
  }
  const queryParamIndex = pageName.indexOf('?');
  const page = queryParamIndex !== -1 ? pageName.substring(0, queryParamIndex) : pageName;

  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
  const seRegex = /se_[a-zA-z0-9]{27}/g;

  return page.replace(uuidRegex, '').replace(seRegex, '');
};

export const extractEnrollmentIdFromPath = (url: URL): string | undefined => {
  if (!url.pathname.startsWith('/venues/')) {
    return;
  }

  return url.pathname.split('/')[2];
};

export const pageViewTrackingEnrichment = (): EnrichmentPlugin => ({
  name: 'page-view-tracking-enrichment',
  type: 'enrichment',
  setup: async () => undefined,
  execute: async (event) => {
    if (event.event_type !== '[Amplitude] Page Viewed') {
      return event;
    }
    const pageId = getPageName(window.location.pathname);
    event.event_properties = {
      // eslint-disable-line no-param-reassign
      ...event.event_properties,
      '[Amplitude] Page Id': pageId,
    };
    return event;
  },
});

export const initializeAmplitude = () => {
  const configuration = ConfigurationLoader.load();
  ampli.load({
    environment: configuration.envName !== 'production' ? 'development' : 'production',
    client: { configuration: { defaultTracking: true } },
  });
  ampli.client.add(pageViewTrackingEnrichment());
  ampli.client.add(new TrackingContextEventEnrichmentPlugin());
};
