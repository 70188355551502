import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { IconButton, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeviceGroup } from 'src/initial-setup/domain/DeviceGroup';
import { doesDeviceExist } from 'src/initial-setup/domain/doesDeviceExist';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { fromSingleTableToRange } from 'src/initial-setup/domain/fromDeviceNamesToRanges';

interface SingleDeviceFormProps {
  addSingleDevice: (tableNumber: string) => void;
  deviceGroups: DeviceGroup[];
}

const SingleDeviceForm = ({ addSingleDevice, deviceGroups }: SingleDeviceFormProps) => {
  const intl = useIntl();
  const snackBar = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
    },
    setValue,
  } = useForm<{ tableNumber: string }>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<{ tableNumber: string }> = async ({ tableNumber }) => {
    try {
      const deviceGroup = fromSingleTableToRange(tableNumber!);
      if (doesDeviceExist(deviceGroups, deviceGroup)) {
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.alert.table_already_exist' }),
        });
      } else {
        addSingleDevice(tableNumber);
        setValue('tableNumber', '');
      }
    } catch (error: any) {
      datadogLogs.logger.error('An error occurred while trying to add a single table', { error });
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.alert.error' }),
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" spacing={2} marginTop={2}>
        <TextField
          variant={'standard'}
          fullWidth
          label={intl.formatMessage({ id: 'initial_setup.enter_tables.single_table.table_number.label' })}
          placeholder={intl.formatMessage({ id: 'initial_setup.enter_tables.single_table.table_number.placeholder' })}
          id="tableNumber"
          error={!!errors.tableNumber}
          helperText={errors.tableNumber?.message}
          InputLabelProps={{ shrink: true }}
          {...register('tableNumber', {
            required: intl.formatMessage({ id: 'initial_setup.enter_tables.single_table.table_number.required' }),
            pattern: {
              value: /.*\S.*/,
              message: intl.formatMessage({ id: 'initial_setup.enter_tables.single_table.table_number.pattern' }),
            },
          })}
        />
        <IconButton aria-label="add" type={'submit'} disabled={!isValid}>
          <AddIcon />
        </IconButton>
      </Stack>
    </form>
  );
};

export default SingleDeviceForm;
