import { CurrencyCode, money } from '@sundayapp/web-money';
import { WeeklyReportOrganization } from './WeeklyReportOrganization';

// eslint-disable max-len
export const weeklyReportOrganizationFixture: WeeklyReportOrganization = {
  organizationName: 'Big Mamma',
  countryCode: 'FR',
  currency: CurrencyCode.EUR,
  week: { lastWeek: new Date().toISOString(), twoWeeksAgo: '2023-05-27T17:03:36.789Z' },
  totalGTV: {
    lastWeek: money(1_292_302_70000, CurrencyCode.EUR),
    twoWeeksAgo: money(13352000, CurrencyCode.EUR),
    fluctuation: 6,
  },
  totalGTVSunday: {
    lastWeek: money(448_73939000 + 746_480_84000, CurrencyCode.EUR),
    twoWeeksAgo: money(26704000, CurrencyCode.EUR),
    fluctuation: 6,
  },
  totalQR: {
    lastWeek: money(746_480_84000, CurrencyCode.EUR),
    twoWeeksAgo: money(13352000, CurrencyCode.EUR),
    fluctuation: 6,
  },
  totalPDQ: {
    lastWeek: money(448_73939000, CurrencyCode.EUR),
    twoWeeksAgo: money(73352000, CurrencyCode.EUR),
    fluctuation: 6,
  },
  totalTips: {
    lastWeek: money(12500000, CurrencyCode.EUR),
    twoWeeksAgo: money(13352000, CurrencyCode.EUR),
    fluctuation: 6,
  },
  averageBasketSize: {
    lastWeek: money(890000000, CurrencyCode.EUR),
    twoWeeksAgo: money(13352000, CurrencyCode.EUR),
    fluctuation: -6,
  },
  averageGoogleRating: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
  googleRatingCount: { lastWeek: 45, twoWeeksAgo: 48, fluctuation: -3 },
  averageSundayRating: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
  sundayRatingCount: { lastWeek: 76, twoWeeksAgo: 66, fluctuation: 10 },
  foodAndDrinkRating: { lastWeek: 8.7, twoWeeksAgo: 7.7, fluctuation: 1 },
  foodAndDrinkRatingCount: { lastWeek: 112, twoWeeksAgo: 183, fluctuation: -71 },
  ambianceRating: { lastWeek: 9.2, twoWeeksAgo: 9.2, fluctuation: 0 },
  ambianceRatingCount: { lastWeek: 183, twoWeeksAgo: 112, fluctuation: 71 },
  serviceRating: { lastWeek: 9.6, twoWeeksAgo: 8.2, fluctuation: 1.4 },
  serviceRatingCount: { lastWeek: 183, twoWeeksAgo: 112, fluctuation: 71 },
  valueForMoneyRating: { lastWeek: 8.9, twoWeeksAgo: 9.4, fluctuation: -0.5 },
  valueForMoneyRatingCount: { lastWeek: 183, twoWeeksAgo: 112, fluctuation: 71 },
  sundayTipsAmount: {
    lastWeek: null,
    twoWeeksAgo: money(6676000, CurrencyCode.EUR),
    fluctuation: 6,
  },
  sundayTipsRate: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 8 },
  sundayTipsRateWhenTips: { lastWeek: 1.5, twoWeeksAgo: 3.8, fluctuation: 6 },
  shareOrdersAvailable: { lastWeek: 50, twoWeeksAgo: 45, fluctuation: 5 },
  weeklyOrganizationVenueKpis: [
    {
      week: { lastWeek: '2023-05-27T17:03:36.789Z', twoWeeksAgo: '2023-05-27T17:03:36.789Z' },
      venueId: 'a92292fc-965c-4276-bd79-ecaacfe7e546',
      venueName: { value: 'a venue name' },
      organizationName: 'organizationName',
      countryCode: 'US',
      currency: CurrencyCode.EUR,
      pollingMode: 'standard',
      productType: 'PAT',
      adoption: { lastWeek: 44.72, twoWeeksAgo: 66.76, fluctuation: 6 },
      gtvQrCodesSunday: {
        lastWeek: money(1312000, CurrencyCode.EUR),
        twoWeeksAgo: money(6687000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      gtvPaymentTerminalSunday: {
        lastWeek: money(1312000, CurrencyCode.EUR),
        twoWeeksAgo: money(6676000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      totalGtvSunday: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(6676000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      turnoverAmount: {
        lastWeek: money(44500000000, CurrencyCode.EUR),
        twoWeeksAgo: money(6676000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      sundayTipsAmount: {
        lastWeek: null,
        twoWeeksAgo: money(6676000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      sundayTipsRate: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      sundayTipsRateWhenTips: { lastWeek: 1.5, twoWeeksAgo: 3.8, fluctuation: 6 },
      basketSize: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: 6,
      },
      basketSizeWeek: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: 6,
      },
      basketSizeWeekend: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: 6,
      },
      basketSizeLunch: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: 6,
      },
      basketSizeDinner: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: 6,
      },
      averageRatingGoogleReviews: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      googleRatingCount: { lastWeek: 12, twoWeeksAgo: 10, fluctuation: 2 },
      shareGoogleReviewsComingFromSunday: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      averageRatingSunday: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      sundayRatingCount: { lastWeek: 23, twoWeeksAgo: 19, fluctuation: 4 },
      p10AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      p50AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      p90AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: 6 },
      p10BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(505000000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      p50BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(505000000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      p90BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(505000000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      p10RatingWaiter: { lastWeek: 2.1, twoWeeksAgo: 1.6, fluctuation: 0.5 },
      p50RatingWaiter: { lastWeek: 3.4, twoWeeksAgo: 3.6, fluctuation: -0.2 },
      p90RatingWaiter: { lastWeek: 4.7, twoWeeksAgo: 4.6, fluctuation: 0.1 },
      waiterCount: { lastWeek: 2, twoWeeksAgo: 3, fluctuation: -1 },
      foodAndDrinkRating: { lastWeek: 4.2, twoWeeksAgo: 4.1, fluctuation: 0.1 },
      foodAndDrinkRatingCount: { lastWeek: 90, twoWeeksAgo: 67, fluctuation: 23 },
      ambianceRating: { lastWeek: 4.0, twoWeeksAgo: 4.2, fluctuation: -0.2 },
      ambianceRatingCount: { lastWeek: 90, twoWeeksAgo: 67, fluctuation: 23 },
      serviceRating: { lastWeek: 4.2, twoWeeksAgo: 3.6, fluctuation: 0.6 },
      serviceRatingCount: { lastWeek: 90, twoWeeksAgo: 67, fluctuation: 23 },
      valueForMoneyRating: { lastWeek: 4.0, twoWeeksAgo: 4.0, fluctuation: 0 },
      valueForMoneyRatingCount: { lastWeek: 90, twoWeeksAgo: 67, fluctuation: 23 },
      shareOrdersAvailable: { lastWeek: 50, twoWeeksAgo: 3.6, fluctuation: 8 },
    },
    {
      week: { lastWeek: '2023-05-27T17:03:36.789Z', twoWeeksAgo: '2023-05-27T17:03:36.789Z' },
      venueId: '0e24b263-1b32-410b-bddc-809090d073e2',
      venueName: { value: 'A - another venue name' },
      organizationName: 'organizationName',
      countryCode: 'US',
      currency: CurrencyCode.EUR,
      pollingMode: 'standard',
      productType: 'PAT',
      adoption: { lastWeek: 44.72, twoWeeksAgo: 66.76, fluctuation: -6 },
      gtvQrCodesSunday: {
        lastWeek: money(6687000, CurrencyCode.EUR),
        twoWeeksAgo: money(7668700, CurrencyCode.EUR),
        fluctuation: -6,
      },
      gtvPaymentTerminalSunday: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(76676000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      totalGtvSunday: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(76676000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      turnoverAmount: {
        lastWeek: money(7750000, CurrencyCode.EUR),
        twoWeeksAgo: money(76676000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      sundayTipsAmount: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(76676000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      sundayTipsRate: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      sundayTipsRateWhenTips: { lastWeek: 3.5, twoWeeksAgo: 1.5, fluctuation: -2 },
      basketSize: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(4450000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      basketSizeWeek: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(4450000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      basketSizeWeekend: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(4450000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      basketSizeLunch: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(4450000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      basketSizeDinner: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: money(4450000, CurrencyCode.EUR),
        fluctuation: 6,
      },
      p10RatingWaiter: { lastWeek: 2.1, twoWeeksAgo: 1.6, fluctuation: 0.5 },
      p50RatingWaiter: { lastWeek: 3.4, twoWeeksAgo: 3.6, fluctuation: -0.2 },
      p90RatingWaiter: { lastWeek: 4.7, twoWeeksAgo: 4.6, fluctuation: 0.1 },
      averageRatingGoogleReviews: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      googleRatingCount: { lastWeek: 18, twoWeeksAgo: 19, fluctuation: -1 },
      shareGoogleReviewsComingFromSunday: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      averageRatingSunday: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      sundayRatingCount: { lastWeek: 28, twoWeeksAgo: 19, fluctuation: 9 },
      p10AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      p50AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      p90AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: 3.6, fluctuation: -6 },
      p10BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(385000000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      p50BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(385000000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      p90BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: money(385000000, CurrencyCode.EUR),
        fluctuation: -6,
      },
      waiterCount: { lastWeek: 4, twoWeeksAgo: 3, fluctuation: 1 },
      foodAndDrinkRating: { lastWeek: 3.4, twoWeeksAgo: 3.6, fluctuation: -0.2 },
      foodAndDrinkRatingCount: { lastWeek: 56, twoWeeksAgo: 45, fluctuation: 11 },
      ambianceRating: { lastWeek: 4.1, twoWeeksAgo: 3.8, fluctuation: 0.3 },
      ambianceRatingCount: { lastWeek: 56, twoWeeksAgo: 45, fluctuation: 11 },
      serviceRating: { lastWeek: 4.3, twoWeeksAgo: 4.6, fluctuation: 0.3 },
      serviceRatingCount: { lastWeek: 56, twoWeeksAgo: 45, fluctuation: 11 },
      valueForMoneyRating: { lastWeek: 3.8, twoWeeksAgo: 3.7, fluctuation: 0.1 },
      valueForMoneyRatingCount: { lastWeek: 56, twoWeeksAgo: 45, fluctuation: 11 },
      shareOrdersAvailable: { lastWeek: 50, twoWeeksAgo: 3.6, fluctuation: 8 },
    },
    {
      week: { lastWeek: '2023-05-27T17:03:36.789Z', twoWeeksAgo: '2023-05-27T17:03:36.789Z' },
      venueId: '224f81eb-c2c2-4e98-a9fe-60aed2456c25',
      venueName: { value: 'Venue with not so so data and a long name' },
      organizationName: 'organizationName',
      countryCode: 'US',
      currency: CurrencyCode.EUR,
      pollingMode: 'standard',
      productType: 'PAT',
      adoption: { lastWeek: 0.0, twoWeeksAgo: 0.0, fluctuation: 0.0 },
      gtvQrCodesSunday: {
        lastWeek: money(6687000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      gtvPaymentTerminalSunday: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      totalGtvSunday: {
        lastWeek: money(123000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      turnoverAmount: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      sundayTipsAmount: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      sundayTipsRate: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      sundayTipsRateWhenTips: { lastWeek: 1.5, twoWeeksAgo: null, fluctuation: null },
      basketSize: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeWeek: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeWeekend: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeLunch: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeDinner: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      averageRatingGoogleReviews: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      googleRatingCount: { lastWeek: 15, twoWeeksAgo: 19, fluctuation: -4 },
      shareGoogleReviewsComingFromSunday: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      averageRatingSunday: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      sundayRatingCount: { lastWeek: 25, twoWeeksAgo: 28, fluctuation: -3 },
      p10AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      p50AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      p90AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      p10BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p50BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p90BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p10RatingWaiter: { lastWeek: 2.1, twoWeeksAgo: 1.6, fluctuation: 0.5 },
      p50RatingWaiter: { lastWeek: 3.4, twoWeeksAgo: 3.6, fluctuation: -0.2 },
      p90RatingWaiter: { lastWeek: 4.7, twoWeeksAgo: 4.6, fluctuation: 0.1 },
      waiterCount: { lastWeek: 5, twoWeeksAgo: null, fluctuation: null },
      foodAndDrinkRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      foodAndDrinkRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      ambianceRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      ambianceRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      serviceRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      serviceRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      valueForMoneyRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      valueForMoneyRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      shareOrdersAvailable: { lastWeek: 5, twoWeeksAgo: null, fluctuation: null },
    },
    {
      week: { lastWeek: '2023-05-27T17:03:36.789Z', twoWeeksAgo: '2023-05-27T17:03:36.789Z' },
      venueId: '224f81eb-c2c2-4e98-a9fe-60aed2456c25',
      venueName: { value: 'Venue with not so so data and a long name' },
      organizationName: 'organizationName',
      countryCode: 'US',
      currency: CurrencyCode.EUR,
      pollingMode: 'standard',
      productType: 'PAT',
      adoption: { lastWeek: 0.0, twoWeeksAgo: 0.0, fluctuation: 0.0 },
      gtvQrCodesSunday: {
        lastWeek: money(6687000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      gtvPaymentTerminalSunday: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      totalGtvSunday: {
        lastWeek: money(123000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      turnoverAmount: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      sundayTipsAmount: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      sundayTipsRate: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      sundayTipsRateWhenTips: { lastWeek: 1.5, twoWeeksAgo: null, fluctuation: null },
      basketSize: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeWeek: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeWeekend: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeLunch: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeDinner: {
        lastWeek: money(4450000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      averageRatingGoogleReviews: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      googleRatingCount: { lastWeek: 15, twoWeeksAgo: 19, fluctuation: -4 },
      shareGoogleReviewsComingFromSunday: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      averageRatingSunday: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      sundayRatingCount: { lastWeek: 25, twoWeeksAgo: 28, fluctuation: -3 },
      p10AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      p50AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      p90AdoptionWaiter: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      p10BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p50BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p90BasketWaiter: {
        lastWeek: money(445000000, CurrencyCode.EUR),
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p10RatingWaiter: { lastWeek: 2.1, twoWeeksAgo: 1.6, fluctuation: 0.5 },
      p50RatingWaiter: { lastWeek: 3.4, twoWeeksAgo: 3.6, fluctuation: -0.2 },
      p90RatingWaiter: { lastWeek: 4.7, twoWeeksAgo: 4.6, fluctuation: 0.1 },
      waiterCount: { lastWeek: 5, twoWeeksAgo: null, fluctuation: null },
      foodAndDrinkRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      foodAndDrinkRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      ambianceRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      ambianceRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      serviceRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      serviceRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      valueForMoneyRating: { lastWeek: 1.1, twoWeeksAgo: null, fluctuation: null },
      valueForMoneyRatingCount: { lastWeek: 37, twoWeeksAgo: null, fluctuation: null },
      shareOrdersAvailable: { lastWeek: 5, twoWeeksAgo: null, fluctuation: null },
    },
    {
      week: { lastWeek: '2023-05-27T17:03:36.789Z', twoWeeksAgo: '2023-05-27T17:03:36.789Z' },
      venueId: '5fa32786-a136-457d-bc03-93a673215481',
      venueName: { value: 'Z - venue with no data' },
      organizationName: 'organizationName',
      countryCode: 'US',
      currency: CurrencyCode.EUR,
      pollingMode: 'standard',
      productType: 'PAT',
      adoption: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      gtvQrCodesSunday: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      gtvPaymentTerminalSunday: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      totalGtvSunday: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      turnoverAmount: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      sundayTipsAmount: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      sundayTipsRate: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      sundayTipsRateWhenTips: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      basketSize: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeWeek: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeWeekend: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeLunch: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      basketSizeDinner: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      averageRatingGoogleReviews: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      googleRatingCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      shareGoogleReviewsComingFromSunday: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      averageRatingSunday: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      sundayRatingCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      p10AdoptionWaiter: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      p50AdoptionWaiter: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      p90AdoptionWaiter: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      p10BasketWaiter: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p50BasketWaiter: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p90BasketWaiter: {
        lastWeek: null,
        twoWeeksAgo: null,
        fluctuation: null,
      },
      p10RatingWaiter: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      p50RatingWaiter: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      p90RatingWaiter: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      waiterCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      foodAndDrinkRating: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      foodAndDrinkRatingCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      ambianceRating: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      ambianceRatingCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      serviceRating: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      serviceRatingCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      valueForMoneyRating: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      valueForMoneyRatingCount: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
      shareOrdersAvailable: { lastWeek: null, twoWeeksAgo: null, fluctuation: null },
    },
  ],
};
