import { useState } from 'react';

const WHITE = '#FFFFFF';
const defaultColors = ['#FFFFFF', '#000000'];

function removeDups(colors: string[]) {
  const unique: Record<string, boolean> = {};
  colors.forEach((i) => {
    if (!unique[i]) {
      unique[i] = true;
    }
  });
  return Object.keys(unique);
}

export function useColorPicker() {
  const [backgroundColor, setBackgroundColor] = useState(WHITE);
  const [palette, setPalette] = useState(defaultColors);
  const [backgroundColorAlpha, setBackgroundColorAlpha] = useState(0);
  const [isPickingColor, setIsPickingColor] = useState(false);
  const setColor = (color: string) => {
    setBackgroundColor(color);
    setBackgroundColorAlpha(255);
    setIsPickingColor(false);
  };
  const setColorsPalette = (colors: string[]) => {
    setPalette(removeDups([...colors, ...defaultColors]));
  };
  const togglePickingColor = () => {
    setIsPickingColor((state) => !state);
  }; const stopPickingColor = () => {
    setIsPickingColor(false);
  };
  const setPickingColor = (color: string, alpha: number) => {
    setBackgroundColor(color);
    setBackgroundColorAlpha(alpha);
  };
  return {
    background: {
      color: backgroundColor,
      alpha: backgroundColorAlpha,
    },
    palette,
    setPalette: setColorsPalette,
    setColor,
    togglePickingColor,
    stopPickingColor,
    setPickingColor,
    isPickingColor,
  };
}
