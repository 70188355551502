import { PaymentLink } from '../../domain/PaymentLink';
import React, { FC } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Chip } from '@mui/material';
import {
  displayableStatusToChipColor,
  paymentLinkStatusesToDisplayableStatus,
  statusTranslationKey,
} from '../../domain/PaymentLinkStatus';

export const statusChipColor = (paymentLink: PaymentLink) => {
  const displayableStatus = paymentLinkStatusesToDisplayableStatus[paymentLink.status];
  return displayableStatusToChipColor[displayableStatus];
};

export const ChipPaymentLinkStatus: FC<{ paymentLink: PaymentLink }> = ({ paymentLink }) => <Chip
  color={statusChipColor(paymentLink)}
  size="small" label={
  <FormattedMessage id={statusTranslationKey(paymentLink)} />
} />;
