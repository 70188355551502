import styled, { css } from 'styled-components';
import {
  colorPalette, fontFamily, getSpacing, textStyles, transitions, zIndex,
} from '../../../stylesheet';
import { DownArrowIcon } from '../Icons';
import { IconProps } from '../Icons/interface';
import { SelectDirection } from './types';

export enum SelectVariant {
  NORMAL,
  MEDIUM,
  SMALL,
}

interface SelectProps {
  variant?: SelectVariant;
}

interface ArrowIconProps extends IconProps {
  open?: boolean;
}

export const SelectContainer = styled.label<ArrowIconProps & SelectProps>`
  height: ${getSpacing(8)};
  padding: 0 ${getSpacing(3)};
  min-width: 0;
  border-radius: ${getSpacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  border: 1px solid ${colorPalette.grey300};
  cursor: pointer;
  background-color: ${colorPalette.defaultBackgroundColor};
  ${({ open }) =>
    open
    && css`
      z-index: ${zIndex.ACTIVE_SELECT};
    `}
  ${({ variant }) =>
    variant === SelectVariant.SMALL
    && css`
      height: ${getSpacing(4)};
      border-radius: ${getSpacing(2)};
      padding: 0 ${getSpacing(1)};
      margin: ${getSpacing(0.5)} 0;
    `}
      ${({ variant }) =>
    variant === SelectVariant.MEDIUM
    && css`
      height: 50px;
      border-radius: 25px;
      padding: 0 12px;
      margin: 0;
    `}
`;

export const SelectedValue = styled.span`
  flex: 1;
  position: relative;
  padding-right: ${getSpacing(2)};
`;
export const SelectPlaceholder = styled.span`
  ${textStyles.default}
  flex: 1;
  color: ${colorPalette.placeholderColor};
  position: relative;
`;
export const SelectAutocompleteInput = styled.input`
  font-family: ${fontFamily};
  ${textStyles.default}
  flex: 1;
  min-width: 0;
  color: ${colorPalette.grey600};
  position: relative;
  border: 0;
  padding: ${getSpacing(1)} 0;
  outline: 0;
  z-index: 1;
  &::placeholder {
    color: ${colorPalette.placeholderColor};
  }
`;

export const ArrowIcon = styled(DownArrowIcon)<ArrowIconProps>`
  transition: transform ${transitions.default};
  position: relative;
  z-index: 1;
  ${({ open }) =>
    open
    && css`
      transform: rotate(180deg);
    `}
`;

interface SelectOptionsProps {
  open: boolean;
  direction: SelectDirection;
}
export const SelectOptions = styled.div<SelectOptionsProps>`
  position: absolute;
  left: 0;
  width: 100%;
  background-color: ${colorPalette.defaultBackgroundColor};
  display: none;
  overflow: hidden;
  top: auto;
  bottom: 50%;
  margin: 0 -1px 0;
  box-sizing: content-box;
  ${({ direction }) =>
    direction === SelectDirection.BOTTOM
    && css`
      top: 50%;
      bottom: auto;
      margin: 0 0 0 -1px;
    `}
  ${({ direction }) =>
    direction === SelectDirection.TOP
    && css`
      border-top-left-radius: ${getSpacing(4)};
      border-top-right-radius: ${getSpacing(4)};
      padding-top: ${getSpacing(4)};
    `}
    ${({ direction }) =>
    direction === SelectDirection.BOTTOM
    && css`
      border-bottom-left-radius: ${getSpacing(4)};
      border-bottom-right-radius: ${getSpacing(4)};
      padding-bottom: ${getSpacing(4)};
    `}
  ${({ open, direction }) =>
    open
    && css`
      display: block;
      border: 1px solid ${colorPalette.lightBorderColor};
      border-top-width: ${direction === SelectDirection.TOP ? '1px' : '0'};
      border-bottom-width: ${direction === SelectDirection.BOTTOM ? '1px' : '0'};
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.02);
      padding: ${direction === SelectDirection.TOP
    ? `${getSpacing(2)} 0 ${getSpacing(3)} 0`
    : `${getSpacing(3)} 0 ${getSpacing(2)} 0`};
    `}
`;

interface OptionProps {
  highlight?: boolean;
}

export const OptionValue = styled.div<OptionProps>`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    background-color: ${colorPalette.toggleBackgroundColor};
  }
  ${({ highlight }) =>
    highlight
    && css`
      background-color: ${colorPalette.toggleBackgroundColor};
    `}
`;
