import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { FulfillmentType } from '../domain/FulfillmentType';

export const fulfillmentMethodMessages: Record<FulfillmentType, React.ReactNode> = {
  [FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER]: (
    <FormattedMessage id="venue.customMessageForEatInPickUpAtCounter" defaultMessage="message for pick up at counter" />
  ),
  [FulfillmentType.TAKE_AWAY]: (
    <FormattedMessage id="venue.customMessageForTakeAway" defaultMessage="message for take away" />
  ),
  [FulfillmentType.EAT_IN_SERVICE_AT_TABLE]: (
    <FormattedMessage id="venue.customMessageForEatInServiceAtTable" defaultMessage="message for service at table" />
  ),
  [FulfillmentType.HYBRID_ORDERING]: (
    <FormattedMessage id="venue.customMessageForHybridOrderingAtTable" defaultMessage="message for hybrid ordering" />
  ),
  [FulfillmentType.CLICK_AND_COLLECT]: (
    <FormattedMessage id="venue.customMessageForClickAndCollect" defaultMessage="message for the click and collect" />
  ),
  [FulfillmentType.PRE_ORDERING]: (
    <FormattedMessage id="venue.customMessageForPreOrdering" defaultMessage="message for pre ordering" />
  ),
};
