/* eslint-disable no-plusplus */

import { IntlShape } from 'src/app/i18n/TypedIntl';
import { GroupedTimeRanges } from '../../components/SchedulePanel/GroupedTimeRanges';
import { ScheduleRange } from '../../domain/ScheduleRange';
import { Days } from '../../domain/Days';
import { MenuScheduleTranslationUtils } from './MenuScheduleTranslationUtils';

export class MenuSchedulesOptimizationUtils {
  public static groupedTimeToString(groupedConstraints: GroupedTimeRanges, translation: IntlShape): string[] {
    return MenuScheduleTranslationUtils.groupedConstraintToString(groupedConstraints, translation);
  }

  public static optimizeGroupedTimes(groupedTimes: GroupedTimeRanges[]): ScheduleRange[] {
    return MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.MONDAY)
      .concat(MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.TUESDAY))
      .concat(MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.WEDNESDAY))
      .concat(MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.THURSDAY))
      .concat(MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.FRIDAY))
      .concat(MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.SATURDAY))
      .concat(MenuSchedulesOptimizationUtils.optimizeDailyRanges(groupedTimes, Days.SUNDAY));
  }

  private static optimizeDailyRanges(groupedTimes: GroupedTimeRanges[], day: Days): ScheduleRange[] {
    const dailyRanges = groupedTimes
      .filter((groupedTime) => (groupedTime as any)[Days[day].toLowerCase()])
      .flatMap((groupedTime) => groupedTime.ranges)
      .map(
        (range) =>
          ({
            day,
            startTime: range.startTime,
            endTime: range.endTime,
          } as ScheduleRange),
      );

    const firstOptimizationRanges = MenuSchedulesOptimizationUtils.optimizeIncludedRanges(dailyRanges);
    return MenuSchedulesOptimizationUtils.optimizeOverlappingRanges(firstOptimizationRanges);
  }

  private static optimizeIncludedRanges(dailyRanges: ScheduleRange[]): ScheduleRange[] {
    for (let i = 0, sz = dailyRanges.length; i < sz; i++) {
      const rangeRef = dailyRanges[i];

      for (let j = i + 1; j < sz; j++) {
        const range = dailyRanges[j];
        if (MenuSchedulesOptimizationUtils.isRangeIncluded(rangeRef, range)) {
          dailyRanges.splice(i, 1);
          return this.optimizeIncludedRanges(dailyRanges);
        }

        if (MenuSchedulesOptimizationUtils.isRangeIncluded(range, rangeRef)) {
          dailyRanges.splice(j, 1);
          return this.optimizeIncludedRanges(dailyRanges);
        }
      }
    }

    return dailyRanges;
  }

  private static isRangeIncluded(range1: ScheduleRange, range2: ScheduleRange): boolean {
    return range1.startTime >= range2.startTime && range1.endTime <= range2.endTime;
  }

  private static optimizeOverlappingRanges(dailyRanges: ScheduleRange[]): ScheduleRange[] {
    for (let i = 0, sz = dailyRanges.length; i < sz; i++) {
      const rangeRef = dailyRanges[i];

      for (let j = i + 1; j < sz; j++) {
        const range = dailyRanges[j];
        if (MenuSchedulesOptimizationUtils.isRangeCollision(rangeRef, range)) {
          dailyRanges.splice(i, 1);
          dailyRanges.splice(j - 1, 1);
          const additionalRanges = dailyRanges.concat(MenuSchedulesOptimizationUtils.mergeRanges(rangeRef, range));

          return this.optimizeOverlappingRanges(additionalRanges);
        }
      }
    }

    return dailyRanges;
  }

  private static mergeRanges(range1: ScheduleRange, range2: ScheduleRange): ScheduleRange {
    return {
      day: range1.day,
      startTime: range1.startTime <= range2.startTime ? range1.startTime : range2.startTime,
      endTime: range1.endTime >= range2.endTime ? range1.endTime : range2.endTime,
    };
  }

  private static isRangeCollision(range1: ScheduleRange, range2: ScheduleRange): boolean {
    return (
      (range1.startTime <= range2.startTime && range1.endTime >= range2.startTime)
      || (range2.startTime <= range1.startTime && range2.endTime >= range1.startTime)
    );
  }
}
