import { Page } from '@templates/PageTemplate';
import React, { useEffect } from 'react';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useNavigate } from 'react-router';
import { menuItems } from 'src/multi-locations/pages/MultiLocationLayout';

export const MultiLocationsHome = () => {
  const user = useAuthenticatedUserOrThrow();
  const navigate = useNavigate();
  const items = menuItems(user);

  useEffect(() => {
    navigate(items[0].subItems[0].route!!);
  }, []);

  return (
    <Page
      hasHeadTitle={{
        preLogoText: [
          {
            id: undefined,
          },
        ],
        postLogoText: [
          {
            id: 'sunday',
          },
        ],
        variant: 'h5',
        hasBadge: false,
        badgeLabel: '',
        showTitle: false,
      }}
      noBanner={true}
    >
      <h1>Home</h1>
    </Page>
  );
};
