import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { timePeriodsToReactNode } from '../../../../orderingMenus/utils/OrderingMenuSchedulesReactUtils';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type ServiceMenuProps = {
  menu: OrderingMenu;
  withSeparator?: boolean;
};

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: ${getSpacing(1)};
  padding-bottom: ${getSpacing(1)};
  padding-left: ${getSpacing(2)};
  padding-right: ${getSpacing(2)};

  background: ${colorPalette.grey100};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  color: ${colorPalette.grey600};

  width: 100%;
`;

type MenuNameProps = {
  withSeparator: boolean;
};

const MenuName = styled.div<MenuNameProps>`
  font-size: 14px;
  line-height: 22px;
  padding-bottom: ${(props) => (props.withSeparator ? getSpacing(1) : '')};
  color: ${colorPalette.defaultTextColor};
  border-bottom: ${(props) => (props.withSeparator ? `1px solid ${colorPalette.grey300}` : '')};
  margin-bottom: ${getSpacing(1)};
`;

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(1)};
`;

export const ServiceMenu = ({ menu, withSeparator = true }: ServiceMenuProps) => {
  const intl = useIntl();

  const onMenuSchedules = (scheduledMenu: OrderingMenu) => timePeriodsToReactNode(scheduledMenu.availabilities, intl);

  return (
    <MenuContainer>
      <MenuName key={menu.id} withSeparator={withSeparator}>
        {menu.name}
      </MenuName>
      <ScheduleContainer>{onMenuSchedules(menu!)}</ScheduleContainer>
    </MenuContainer>
  );
};
