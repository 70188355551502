import React, { createContext, ReactNode, useMemo } from 'react';
import { VenueConfig } from './VenueConfig';
import { OrderingVenueRepositoryHttp } from '../repositories/OrderingVenueRepositoryHttp';

type VenueContextProviderProps = {
  orderingBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = VenueConfig & {
  children: ReactNode;
};

export const VenueConfigContext = createContext<Partial<ProviderConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => (
  <VenueConfigContext.Provider value={config}>{children}</VenueConfigContext.Provider>
);

export const VenueContextProvider = ({ orderingBackendUrl, children }: VenueContextProviderProps) => {
  // Repo
  const venueRepository = useMemo(() => new OrderingVenueRepositoryHttp(orderingBackendUrl), [orderingBackendUrl]);

  return <Provider venueRepository={venueRepository}>{children}</Provider>;
};
