import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../../stylesheet';

export const AreaServiceContainer = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 14px;
  line-height: 16px;

  margin-top: ${getSpacing(2)};

  padding-top: ${getSpacing(1)};
  padding-bottom: ${getSpacing(1)};
  padding-left: ${getSpacing(2)};
  padding-right: ${getSpacing(2)};

  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};

  width: fit-content;
`;
