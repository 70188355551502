import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Modal, Notification as SundayNotification, spaceUsages } from '@sundayapp/b2b-react-component-library';
import { useForm } from 'react-hook-form';

import { SharedDevice } from '../../domain/SharedDevice';
import styled from 'styled-components';
import { TextField } from '@mui/material';

type Props = {
  handleConfirm: (deviceToken: string, deviceName: string) => Promise<void>;
  sharedDevice: SharedDevice;
  isOpen: boolean;
  handleClose: () => void;
};

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 356px;
`;

export const UpdateSharedDeviceNameModal: React.FC<Props> = ({
  handleConfirm,
  handleClose,
  isOpen,
  sharedDevice,
}: Props) => {
  const intl = useIntl();
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [deviceName, setDeviceName] = useState(sharedDevice.deviceName);
  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit = async () => {
    setHasSubmitError(false);
    try {
      await handleConfirm(sharedDevice.deviceToken, deviceName!!);
      handleClose();
    } catch (e) {
      setHasSubmitError(true);
    }
  };

  return (
    <Modal
      maxWidth={'488px'}
      title={intl.formatMessage({ id: 'sunday_for_restaurants.device_name.modal.title' })}
      isOpened={isOpen}
      onClose={handleClose}
      cancelButtonMessage={intl.formatMessage({ id: 'sunday_for_restaurants.device_name.modal.cancel' })}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            fullWidth
            placeholder={'Bar 2'}
            onChange={(e) => {
              setDeviceName(e.target.value);
            }}
            value={deviceName}
          />

        {hasSubmitError && (
          <SundayNotification variant="error" marginTop={spaceUsages.mediumXLarge}>
            <FormattedMessage id="error.generic_error" />
          </SundayNotification>
        )}

        <Button
          variant={'primary'}
          type="submit"
          size="medium"
          marginTop={spaceUsages.mediumXLarge}
          disabled={!isValid || isSubmitting}
          fullWidth
        >
          <FormattedMessage id="sunday_for_restaurants.device_name.modal.confirm" />
        </Button>
      </Form>
    </Modal>
  );
};
