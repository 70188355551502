import React from 'react';
import { useSelector } from 'react-redux';
import { EReputationKpisViewModel } from '../EReputationViewModel';
import { RatingsAverageGraph } from './RatingsAverageGraph';
import { RatingsCountGraph } from './RatingsCountGraph';
import { averageRatingKpis, totalFiveStarsKpis, totalReviewsKpis } from './RatingsGraphViewModel';
import { getEReputationPageStatus } from 'src/store/e-reputation';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

type Props = {
  kpiType: KpiName,
  eReputationKpisViewModels: EReputationKpisViewModel[],
  subscriptionDate: number;
};

export const RatingsGraph = ({
  kpiType,
  eReputationKpisViewModels,
  subscriptionDate,
}: Props) => {
  const pageStatus = useSelector(getEReputationPageStatus);
  return (
    <>
      {kpiType === 'AVG_RATING' && (
        <RatingsAverageGraph
          graphKpis={averageRatingKpis(eReputationKpisViewModels, new Date(subscriptionDate), pageStatus.timeFrame)}
        />
      )}
      {kpiType === 'TOTAL_REVIEWS' && (
        <RatingsCountGraph
          graphKpis={totalReviewsKpis(eReputationKpisViewModels)}
        />
      )}
      {kpiType === 'TOTAL_FIVE_STARS' && (
        <RatingsCountGraph
          graphKpis={totalFiveStarsKpis(eReputationKpisViewModels)}
        />
      )}
    </>
  );
};
