import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { CurrencyCode } from '@sundayapp/web-money';
import { emptyOapReporting, emptyPatReporting, emptyPdqReporting, Reporting } from './ReportingDigest';

export const products = {
  PAT: 'PAT',
  OAP: 'OAP',
  PDQ: 'PDQ',
} as const;

export const kindOfPayment = {
  CNP: 'CNP',
  CP: 'CP',
} as const;

export type Products = ValuesOf<typeof products>;
export type KindOfPayment = ValuesOf<typeof kindOfPayment>;
export type ReportingByProduct = {
  [key in Products]: Reporting;
};
export type ReportingWaiterByProduct = {
  [key in KindOfPayment]: Reporting;
};
export const emptyProductsReporting = (currency: CurrencyCode): ReportingByProduct => {
  return ({
    PAT: emptyPatReporting('sales_summary.pat_total', currency),
    OAP: emptyOapReporting('sales_summary.oap_total', currency),
    PDQ: emptyPdqReporting('sales_summary.tpe_total', currency),
  });
};

export const emptyWaitersReporting = (currency: CurrencyCode): ReportingWaiterByProduct => {
  return ({
    CNP: emptyPatReporting('sales_summary.cnp_total', currency),
    CP: emptyPdqReporting('sales_summary.tpe_total', currency),
  });
};

export const isCnp = (product: Products) => product === products.PAT || product === products.OAP;
export const isCp = (product: Products) => product === products.PDQ;
export const mapProductToKindOfPayment = (product: Products) => {
  if (product === 'PDQ') {
    return kindOfPayment.CP;
  } else {
    return kindOfPayment.CNP;
  }
};
