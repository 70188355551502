import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';

export const useDeleteCoverPicture = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();
  const mutation = useMutation({
    mutationFn: (enrollmentId: EnrollmentId) => customizationRepository.deleteCoverPicture(enrollmentId),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION] }),
  });
  return {
    mutation,
    deleteCoverPicture: (enrollmentId: EnrollmentId) => mutation.mutateAsync(enrollmentId),
  };
};
