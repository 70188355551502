import { ValuesOf } from 'src/utils/typescript/valuesOf';


export const contratMonetique = {
  CONECS: 'CONECS',
  CONECS_CLESS: 'CONECS_CLESS',
  AMEX: 'AMEX',
  CLESS: 'CLESS',
  CBEMV: 'CBEMV',
  ANCV: 'ANCV',
} as const;

export type ContratMonetique = ValuesOf<typeof contratMonetique>;

export const sortedContratMonetiques = (): ContratMonetique[] => Object.values(contratMonetique).sort((a, b) => a.localeCompare(b));

