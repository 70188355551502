import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';
import { BusinessId } from 'src/business/domain/Business';

const QUERY_KEY_BUSINESS_TIPPING_CONFIGURATION = 'sunday_tipping_enabled';

export const useIsSundayTippingEnabled = (businessId: BusinessId) => {
  return useQuery({
    queryKey: [QUERY_KEY_BUSINESS_TIPPING_CONFIGURATION, businessId],
    queryFn: () => {
      const configuration = ConfigurationLoader.load();
      const repository = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
      return repository.isSundayTippingEnabled(businessId);
    },
  });
};
