import { DeviceGroup } from 'src/initial-setup/domain/DeviceGroup';

const range = (start: number, end: number): ReadonlyArray<number> =>
  [ ...Array(end - start + 1).keys() ].map((i) => i + start);

export const fromRangesToDeviceNames = (deviceRanges: DeviceGroup[]) =>
  deviceRanges.flatMap((deviceRange) => {
    if (!deviceRange.from && !deviceRange.to) {
      return [ deviceRange.prefix ];
    }
    return range(deviceRange.from!, deviceRange.to!).map((index) => `${deviceRange.prefix}${index}`);
  });
