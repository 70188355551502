import { LocalisationKey } from 'src/lang/en';

export interface Language {
  id: string,
  label: LocalisationKey,
  countryFlag: string,
}

const supportedLanguages = {
  FR: {
    id: 'FR',
    label: 'language.FR',
    countryFlag: 'FR',
  },
  EN: {
    id: 'EN',
    label: 'language.EN',
    countryFlag: 'GB',
  },
  US: {
    id: 'US',
    label: 'language.EN',
    countryFlag: 'US',
  },
  ES: {
    id: 'ES',
    label: 'language.ES',
    countryFlag: 'ES',
  },
  JA: {
    id: 'JA',
    label: 'language.JA',
    countryFlag: 'JP',
  },
  ZH: {
    id: 'ZH',
    label: 'language.ZH',
    countryFlag: 'CN',
  },
  DE: {
    id: 'DE',
    label: 'language.DE',
    countryFlag: 'DE',
  },
  IT: {
    id: 'IT',
    label: 'language.IT',
    countryFlag: 'IT',
  },
  NL: {
    id: 'NL',
    label: 'language.NL',
    countryFlag: 'NL',
  },
  PT: {
    id: 'PT',
    label: 'language.PT',
    countryFlag: 'PT',
  },
  PL: {
    id: 'PL',
    label: 'language.PL',
    countryFlag: 'PL',
  },
  CS: {
    id: 'CS',
    label: 'language.CS',
    countryFlag: 'CZ',
  },
  SK: {
    id: 'SK',
    label: 'language.SK',
    countryFlag: 'SK',
  },
  SV: {
    id: 'SV',
    label: 'language.SV',
    countryFlag: 'SE',
  },
  DA: {
    id: 'DA',
    label: 'language.DA',
    countryFlag: 'DK',
  },
  NO: {
    id: 'NO',
    label: 'language.NO',
    countryFlag: 'NO',
  },
  FI: {
    id: 'FI',
    label: 'language.FI',
    countryFlag: 'FI',
  },
  HU: {
    id: 'HU',
    label: 'language.HU',
    countryFlag: 'HU',
  },
  EL: {
    id: 'EL',
    label: 'language.EL',
    countryFlag: 'GR',
  },
  UK: {
    id: 'UK',
    label: 'language.UK',
    countryFlag: 'UA',
  },
  SL: {
    id: 'SL',
    label: 'language.SL',
    countryFlag: 'SI',
  },
  HR: {
    id: 'HR',
    label: 'language.HR',
    countryFlag: 'HR',
  },
  BS: {
    id: 'BS',
    label: 'language.BS',
    countryFlag: 'BA',
  },
  SR: {
    id: 'SR',
    label: 'language.SR',
    countryFlag: 'RS',
  },
  MK: {
    id: 'MK',
    label: 'language.MK',
    countryFlag: 'MK',
  },
  BG: {
    id: 'BG',
    label: 'language.BG',
    countryFlag: 'BG',
  },
  RO: {
    id: 'RO',
    label: 'language.RO',
    countryFlag: 'RO',
  },
  RU: {
    id: 'RU',
    label: 'language.RU',
    countryFlag: 'RU',
  },
  TR: {
    id: 'TR',
    label: 'language.TR',
    countryFlag: 'TR',
  },
  KO: {
    id: 'KO',
    label: 'language.KO',
    countryFlag: 'KR',
  },
} as const;

const AllSupportedLanguage: Language[] = Object.values(supportedLanguages);

export const findLanguage = (code: string): Language => AllSupportedLanguage.find((l) => l.id === code)
  ?? { id: code, label: code as LocalisationKey, countryFlag: code };

const notInto = (languages: Language[]) => (language: Language): boolean =>
  !languages.find((existingLanguage) => existingLanguage.label === language.label);

export const allSupportedLanguages = (): Language[] => AllSupportedLanguage;

export const supportedFilteredLanguages = (filteredOutLanguages: Language[]): Language[] =>
  AllSupportedLanguage.filter(notInto(filteredOutLanguages));
