import styled from 'styled-components';
import DownloadIcon from '@mui/icons-material/Download';
import { LocalisationKey } from 'src/lang/en';
import { Button, CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

const DownloadWrapper = styled(DownloadIcon)`
  margin-right: 5px;
`;

export const DownloadButton = ({
  download,
  inProgress,
  testDataId,
  labelMessageId,
  defaultMessage,
}: {
  download: () => void;
  inProgress: boolean;
  testDataId: string;
  labelMessageId: LocalisationKey;
  defaultMessage?: string;
}) => {
  return (
    <Button size="medium" variant="contained" disabled={inProgress} onClick={() => !inProgress && download()} data-testid={testDataId}>
      {' '}
      {inProgress ? (
        <CircularProgress color="inherit" size="large" style={{ width: '32px', height: '32px' }} />
      ) : (
        <>
          <DownloadWrapper />
          <Typography style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} variant="button">
            <FormattedMessage id={labelMessageId} defaultMessage={defaultMessage} />
          </Typography>
        </>
      )}
    </Button>
  );
};
