import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { IconProps } from '../Icons/interface';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { IconButtonVariants } from './variants';

interface Props extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  Icon?: React.FC<IconProps>;
  variant?: IconButtonVariants;
  active?: boolean;
  color?: string;
  size?: number;
}

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: IconButtonVariants;
  size?: number;
  active?: boolean;
}

const IconButtonContainer: React.FC<ButtonProps> = styled.button<ButtonProps>`
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
  outline: none;
  background-color: ${colorPalette.defaultBackgroundColor};
  border: 1px solid ${colorPalette.lightBorderColor};
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ size }) =>
    size
    && css`
      width: ${size}px;
      min-width: ${size}px;
      height: ${size}px;
    `}
  ${({ active }) =>
    active
    && css`
      background-color: ${colorPalette.primaryBackgroundColor};
      border-color: ${colorPalette.primaryBackgroundColor};
    `}
  ${({ variant }) =>
    variant === IconButtonVariants.BORDERLESS
    && css`
      border-color: transparent;
      background-color: transparent;
    `}
`;

const IconButton: React.FC<Props> = ({
  Icon,
  active,
  color,
  size,
  variant = IconButtonVariants.REGULAR,
  children,
  ...props
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const onMouseEnter = useCallback(() => setIsHover(true), [setIsHover]);
  const onMouseLeave = useCallback(() => setIsHover(false), [setIsHover]);

  const getIconColor = useCallback(() => {
    if (active) return colorPalette.primaryForegroundColor;
    return isHover ? colorPalette.defaultTextColor : colorPalette.grey600;
  }, [isHover, active]);

  return (
    <IconButtonContainer
      {...props}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variant={variant}
      active={active}
      size={size}
    >
      {Icon && <Icon color={color || getIconColor()} />}
    </IconButtonContainer>
  );
};

export default IconButton;
