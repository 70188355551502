import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PageSectionProps } from 'src/home2/types/layout_types';

export const PageSection = (sectionProps: PageSectionProps) => {
  return (
    <Box>
      <Typography variant='h5' sx={{ marginBottom: 2, letterSpacing: -1 }}>
        <FormattedMessage id={sectionProps.title}/>
      </Typography>

      {sectionProps.children}
    </Box>
  );
};