import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { AreaSettingContainer } from '../../../components/Settings.style';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { FulfillmentPanel } from './FulfillmentPanel';
import { AreaWithoutServiceDisclaimerModal } from './AreaWithoutServiceDisclaimerModal';
import { useRouting } from '../../../../common/hooks';
import { BusinessId } from 'src/business/domain/Business';
import { useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import { CircularProgress } from '@mui/material';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

type AreaBoxCreationServiceSelectionProps = {
  businessId: BusinessId;
  initialServices: FulfillmentType[];
  onServiceSelected: (services: FulfillmentType[]) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${getSpacing(3)};
  padding-right: ${getSpacing(3)};
  padding-top: ${getSpacing(3)};
  padding-bottom: ${getSpacing(2)};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: ${getSpacing(3)};
`;

const ActionLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
`;

const configuration = ConfigurationLoader.load();

export const AreaBoxCreationServiceSelection = ({
  businessId,
  initialServices,
  onServiceSelected,
}: AreaBoxCreationServiceSelectionProps) => {
  const navigation = useRouting();
  const [services, updateServices] = useState<FulfillmentType[]>(initialServices);
  const [disclaimerOpened, openDisclaimer] = useState<boolean>(false);
  const { data: menuBusiness, isLoading } = useGetMenuBusiness(businessId);

  if (isLoading) {
    return <CircularProgress />;
  }

  const onSelectionService = () => {
    if (services.length === 0) {
      openDisclaimer(true);
      return;
    }

    onServiceSelected(services);
  };

  const onNoServiceSelectionConfirmed = () => {
    navigation.goToAreas(businessId);
  };

  const onServiceToggled = (service: FulfillmentType, activated: boolean) => {
    if (activated) {
      updateServices([...services, service]);
      return;
    }

    updateServices([...services].filter((item) => item !== service));
  };

  const isServiceSelected = (service: FulfillmentType): boolean => services.find((item) => item === service) !== undefined;

  const isClickAndCollectServiceSelected = services.some((service) => service === FulfillmentType.CLICK_AND_COLLECT);
  const canSelectClickAndCollect = services.some((service) => service !== FulfillmentType.CLICK_AND_COLLECT);
  const canSelectHybridOrdering = !isClickAndCollectServiceSelected && (menuBusiness?.orderingAllowed ?? false);

  return (
    <AreaSettingContainer>
      <Container>
        <Title>
          <FormattedMessage
            id="settings.area.create.service-selection.title"
            defaultMessage="which services do yo want to offer"
          />
        </Title>
        <FulfillmentPanel
          service={FulfillmentType.EAT_IN_SERVICE_AT_TABLE}
          selected={isServiceSelected(FulfillmentType.EAT_IN_SERVICE_AT_TABLE)}
          disabled={isClickAndCollectServiceSelected}
          onToggle={onServiceToggled}
        />
        {configuration.enableHybridOrderingAreas && (
          <FulfillmentPanel
            service={FulfillmentType.HYBRID_ORDERING}
            selected={isServiceSelected(FulfillmentType.HYBRID_ORDERING)}
            disabled={!canSelectHybridOrdering}
            onToggle={onServiceToggled}
          />
        )}

        <FulfillmentPanel
          service={FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER}
          selected={isServiceSelected(FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER)}
          disabled={isClickAndCollectServiceSelected}
          onToggle={onServiceToggled}
        />

        <FulfillmentPanel
          service={FulfillmentType.TAKE_AWAY}
          selected={isServiceSelected(FulfillmentType.TAKE_AWAY)}
          disabled={isClickAndCollectServiceSelected}
          onToggle={onServiceToggled}
        />

        <FulfillmentPanel
          service={FulfillmentType.CLICK_AND_COLLECT}
          selected={isServiceSelected(FulfillmentType.CLICK_AND_COLLECT)}
          disabled={canSelectClickAndCollect}
          onToggle={onServiceToggled}
        />

        <FulfillmentPanel
          service={FulfillmentType.PRE_ORDERING}
          selected={isServiceSelected(FulfillmentType.PRE_ORDERING)}
          disabled={isClickAndCollectServiceSelected}
          onToggle={onServiceToggled}
        />
      </Container>
      <ActionLine>
        <Button variant="primary" size="large" marginTop="8px" onClick={onSelectionService}>
          <FormattedMessage id="settings.area.create.next-step-btn" defaultMessage="next step" />
        </Button>
      </ActionLine>
      <AreaWithoutServiceDisclaimerModal
        isOpen={disclaimerOpened}
        onCancel={() => openDisclaimer(false)}
        onConfirm={onNoServiceSelectionConfirmed}
      />
    </AreaSettingContainer>
  );
};
