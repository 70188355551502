import { TableCell, TableRow } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { differenceMoney } from '@sundayapp/web-money';
import { Reconciliation } from '../../domain/Reconciliation';
import { PaymentOrigin } from '../../domain/PaymentOrigin';
import DeltaDetails from '../DeltaDetails';
import { PaymentDataCell } from '../amountcell/PaymentDataCell';
import { paymentDataAmount } from '../../domain/PaymentData';
import { PosAmountCell } from '../amountcell/PosAmountCell';
import { deltaIsAcceptable } from '../DeltaAcceptable';
import { ReportSettings } from '../../domain/ReportSettings';
import { useCurrentReportOrThrow } from '../../hooks/useCurrentReportOrThrow';
import styled from 'styled-components';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type PosAmountProps = {
  tender: Reconciliation;
};

const PosAmount = ({ tender }: PosAmountProps) => {

  const report = useCurrentReportOrThrow();
  if (tender.method.isBreakdown)
    return <div>-</div>;

  if (tender.onPos?.amount && tender.onPos?.amount?.amount !== 0)
    return <PosAmountCell method={tender.method} amount={tender.onPos.amount} />;

  if (!report.totalOnPos.editable)
    return <PaymentDataCell tender={tender} origin={PaymentOrigin.OnPos} />;

  return <>-</>;
};

type Props = {
  tender: Reconciliation;
  reportSettings: ReportSettings;
};

type RowProps = {
  isBreakdown: boolean;
};

const ColorTableRow = styled(TableRow)<RowProps>`
  td {
    background-color: ${(props) => props.isBreakdown ? '#F6F6F9' : 'white'};
  }
`;


export const OtherTenderRow = ({ tender, reportSettings }: Props) => {
  const { currency } = useCurrentBusinessOrThrow();
  const delta = differenceMoney(
    paymentDataAmount(currency, tender.reported),
    paymentDataAmount(currency, tender.onPos),
  );

  return (
    <ColorTableRow isBreakdown={tender.method.isBreakdown}>
      <TableCell />
      <TableCell style={{ paddingLeft: tender.method.isBreakdown ? '40px' : '20px' }}>{tender.method.name}</TableCell>
      <TableCell>
        <PosAmount tender={tender} />
      </TableCell>
      <TableCell>
        <PaymentDataCell tender={tender} origin={PaymentOrigin.Reported} />
      </TableCell>
      <TableCell>
        <DeltaDetails delta={delta} isBreakdown={tender.method.isBreakdown}
                      deltaIsAcceptable={deltaIsAcceptable(delta, reportSettings)} />
      </TableCell>
    </ColorTableRow>
  );
};
