import { createContext, ReactNode, useMemo } from 'react';
import { StaticMenuRepository } from '../infrastructure/StaticMenuRepository';
import { StaticMenuRepositoryHttp } from '../infrastructure/StaticMenuRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

type StaticMenuContextInterface = {
  menuRepository: StaticMenuRepository;
};

const configuration = ConfigurationLoader.load();
export const StaticMenuContext = createContext<StaticMenuContextInterface>({
  menuRepository: new StaticMenuRepositoryHttp(configuration.menuApiBaseUrl),
});

export const StaticMenuProvider = ({ children }: { children: ReactNode }) => {
  const staticMenuRepository = useMemo(() => new StaticMenuRepositoryHttp(configuration.menuApiBaseUrl), []);

  return (
    <StaticMenuContext.Provider
      value={{
        menuRepository: staticMenuRepository,
      }}
    >
      {children}
    </StaticMenuContext.Provider>
  );
};
