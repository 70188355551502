/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const StarIconSvg: React.FC<Props> = ({
  size = 20,
  ...props
}: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3584 1.77566C11.7667 1.58096 12.2411 1.58096 12.6495 1.77566C13.0025 1.94398 13.1971 2.23622 13.2953 2.39654C13.3968 2.56228 13.5009 2.77342 13.6025 2.97921C13.6077 2.98972 13.6128 3.00022 13.618 3.01069L15.7582 7.34648L20.5802 8.05129C20.8072 8.08442 21.0401 8.11842 21.2291 8.16384C21.4118 8.20779 21.7497 8.30284 22.0186 8.58668C22.3297 8.91504 22.476 9.36626 22.4168 9.8147C22.3656 10.2023 22.1478 10.4776 22.0256 10.6204C21.8993 10.768 21.7306 10.9322 21.5663 11.0922L18.0785 14.4894L18.9014 19.2876C18.9403 19.5139 18.9801 19.746 18.9955 19.9398C19.0103 20.1272 19.0247 20.4781 18.8379 20.8218C18.622 21.2194 18.2381 21.4983 17.7932 21.5807C17.4086 21.652 17.0794 21.5299 16.9057 21.4578C16.7262 21.3834 16.5177 21.2737 16.3146 21.1668L12.0039 18.8999L7.69326 21.1668C7.49011 21.2737 7.28169 21.3834 7.10214 21.4579C6.9285 21.5299 6.59922 21.652 6.21461 21.5807C5.76974 21.4983 5.38587 21.2194 5.16991 20.8218C4.98321 20.4781 4.99756 20.1272 5.0124 19.9398C5.02774 19.746 5.0676 19.5139 5.10645 19.2876L5.92941 14.4894L2.46674 11.1167C2.45838 11.1086 2.45001 11.1004 2.44162 11.0923C2.27725 10.9323 2.1086 10.768 1.98228 10.6204C1.86008 10.4776 1.64224 10.2023 1.59105 9.8147C1.53183 9.36626 1.67813 8.91505 1.98922 8.58668C2.25813 8.30284 2.59604 8.20779 2.7788 8.16384C2.96772 8.11842 3.20064 8.08442 3.42763 8.05129C3.43921 8.0496 3.45078 8.04791 3.46233 8.04622L8.24967 7.34648L10.3899 3.01069C10.395 3.00022 10.4002 2.98972 10.4054 2.97921C10.5069 2.77342 10.6111 2.56228 10.7126 2.39654C10.8107 2.23622 11.0054 1.94398 11.3584 1.77566Z"
      fill="current"
    />
  </svg>
);

export default StarIconSvg;
