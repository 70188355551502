import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

interface TotalHeaderProps {
  title: string,
  children: ReactNode,
}

export const TotalHeader = ({ title, children }: TotalHeaderProps) => (
  <Box sx={{ display: 'block' }}>
    <Typography variant="subtitle2">
      {title}
    </Typography>
    <Typography variant="h4">
      {children}
    </Typography>
  </Box>
);
