import { Box, styled } from '@mui/material';
import { IconWrapper, PlusSvg } from '@sundayapp/b2b-react-component-library';
import { Money, MoneyView } from '@sundayapp/web-money';
import React, { useState } from 'react';
import { CashModal } from './CashModal';
import { CashPayment, totalCashPayments } from '../../domain/CashPayment';
import { CashPayments } from '../../domain/PaymentData';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
styled('div')`
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  row-gap: 5px;
`;

type Props = {
  amount: Money,
  payments: CashPayment[],
  onSave: (newAmount: Money, metadata: CashPayments) => void
};

export const CashPaymentSummaryCell = ({ amount, payments, onSave }: Props) => {
  const { currency } = useCurrentBusinessOrThrow();

  const addCashPayment = (payment: CashPayment) => {
    const newPayments = [...payments, payment];
    const newAmount = totalCashPayments(newPayments, currency);

    onSave(newAmount, { payments: newPayments });
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 2,
    }}
    >
      <MoneyView value={amount} />
      <IconWrapper size="small" cursor="pointer" onClick={() => setShowModal(true)}>
        <PlusSvg />
      </IconWrapper>

      {showModal && (
        <CashModal
          isOpened
          onClose={() => setShowModal(false)}
          save={addCashPayment}
          currencyCode={currency}
        />
      )}
    </Box>
  );
};
