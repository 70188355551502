import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';

interface LoaderProps {
  maxSize: number;
}
export const LoaderContainer = styled.div<LoaderProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ maxSize }) => maxSize}px;
  width: ${({ maxSize }) => maxSize}px;
  position: relative;
  background-color: #F0F0F0;
  border-radius: 8px;
  margin-bottom: ${spaceUsages.mediumLarge};
`;
