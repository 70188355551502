import { parsePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js';

export const formatPhoneNumber = (phoneNumber: string) => {
  try {
    return parsePhoneNumberWithError(phoneNumber).formatInternational();
  } catch (error) {
    return phoneNumber;
  }
};

export const formatPhoneNumberAsE164 = (phoneNumber: string) => {
  return parsePhoneNumber(phoneNumber).number;
};

