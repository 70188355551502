import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from '@sundayapp/b2b-react-component-library';
import { colorPalette, getSpacing } from '../../../stylesheet';
import Input from '../Input';
import { ExpandablePanelButton } from './ExpandablePanelButton';
import { Switch } from '@mui/material';

type SettingHeaderProps = {
  title: string;
  description: string;
  disabled?: boolean;
  expandable?: boolean;

  extraPadding?: boolean;
  children?: ReactNode;

  toggleValue?: boolean;
  toggleOnChange?: (newValue: boolean) => void;

  inputValue?: string;
  inputType?: string;
  inputOnChange?: (newValue: string) => void;

  dropDownValue?: string;
  dropDownOptions?: string[];
  dropDownOnChange?: (value: string) => void;
};

type ContainerProps = {
  extraPadding?: boolean;
};

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.extraPadding ? getSpacing(3) : getSpacing(2))};
  padding-bottom: 0;
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(3)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

type HeaderProps = {
  expandable?: boolean;
};

export const Header = styled.div<HeaderProps>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${getSpacing(2)};
  cursor: ${(props) => (props.expandable ? 'pointer' : 'default')};
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: ${getSpacing(2)};
`;

export const Title = styled.div`
  font-size: 18px;
  line-height: 20px;
  color: ${colorPalette.defaultTextColor};
  margin-bottom: ${getSpacing(1)};
`;

export const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
`;

const SettingInput = styled(Input)`
  width: ${getSpacing(10)};
`;

export const SettingPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${getSpacing(2)};
`;

// eslint-disable-next-line complexity
export const SettingPanel = ({
  title,
  description,
  disabled,
  expandable,
  extraPadding = false,
  toggleOnChange,
  toggleValue,
  inputValue,
  inputOnChange,
  inputType,
  dropDownValue,
  dropDownOptions,
  dropDownOnChange,
  children,
}: SettingHeaderProps) => {
  const [expanded, expand] = useState<boolean>(!expandable);

  const hasToggle = toggleOnChange !== undefined && toggleValue !== undefined;
  const hasInput = inputValue !== undefined && inputOnChange !== undefined;
  const hasDropDown = dropDownValue !== undefined && dropDownOptions !== undefined && dropDownOnChange !== undefined;

  const onHeaderClick = () => {
    if (expandable) {
      expand(!expanded);
    }
  };

  return (
    <Container extraPadding={extraPadding}>
      <Header expandable={expandable} onClick={onHeaderClick}>
        <TitleContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TitleContainer>
        {hasToggle && <Switch checked={toggleValue} onChange={(_, val) => toggleOnChange(val)} disabled={disabled} />}
        {hasInput && (
          <SettingInput
            value={inputValue}
            type={inputType ? inputValue : 'text'}
            onValueChange={inputOnChange}
            leftAligned={false}
            disabled={disabled}
          />
        )}
        {hasDropDown && (
          <Dropdown
            size="medium"
            options={dropDownOptions}
            onChange={dropDownOnChange}
            value={dropDownValue}
            disabled={disabled}
          />
        )}
        {expandable && <ExpandablePanelButton expanded={expanded} expand={expand} />}
      </Header>
      {expanded && <>{children}</>}
    </Container>
  );
};
