import { Button, Modal, spaceUsages } from '@sundayapp/b2b-react-component-library';

import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import DaysSelector from './DaysSelector';
import { TimeRange } from '../../domain/TimeRange';
import MenuTimeRanges from './MenuTimeRanges';
import { GroupedTimeRanges } from './GroupedTimeRanges';

type MenuScheduleModalProps = {
  groupedTimeRanges: GroupedTimeRanges;
  setGroupedTimeRanges: (groupedTimes: GroupedTimeRanges) => void;
  isOpened: boolean;
  onClose: () => void;
};

const MenuScheduleModal = ({
  groupedTimeRanges, setGroupedTimeRanges, isOpened, onClose,
}: MenuScheduleModalProps) => {
  const OPEN_24H = { startTime: '00:00', endTime: '24:00' } as TimeRange;
  const OPEN_7 = [true, true, true, true, true, true, true];
  const { formatMessage } = useIntl();
  const [selectedDays, setSelectedDays] = useState<boolean[]>(OPEN_7);
  const [times, setTimes] = useState<TimeRange[]>([]);
  const [canConfirm, setCanConfirm] = useState<boolean>(true);

  const updateCanConfirm = (updatedSelectedDays: boolean[], updatedTimes: TimeRange[]) => {
    const validDays = updatedSelectedDays.findIndex((value) => value) >= 0;
    const validHours = updatedTimes.findIndex((hour) => hour.startTime <= hour.endTime) >= 0;
    setCanConfirm(validDays && updatedTimes.length > 0 && validHours);
  };

  const onSelectedDayChanged = (selection: boolean[]) => {
    setSelectedDays(selection);
    updateCanConfirm(selection, times);
  };

  const onTimesChanged = (updatedTimes: TimeRange[]) => {
    setTimes(updatedTimes);
    updateCanConfirm(selectedDays, updatedTimes);
  };

  const onConfirmation = () => {
    setGroupedTimeRanges({
      monday: selectedDays[0],
      tuesday: selectedDays[1],
      wednesday: selectedDays[2],
      thursday: selectedDays[3],
      friday: selectedDays[4],
      saturday: selectedDays[5],
      sunday: selectedDays[6],
      ranges: times,
    });
    onClose();
  };

  useEffect(() => {
    if (!groupedTimeRanges) {
      setTimes([OPEN_24H]);
      return;
    }

    setSelectedDays([
      groupedTimeRanges.monday,
      groupedTimeRanges.tuesday,
      groupedTimeRanges.wednesday,
      groupedTimeRanges.thursday,
      groupedTimeRanges.friday,
      groupedTimeRanges.saturday,
      groupedTimeRanges.sunday,
    ]);

    if (groupedTimeRanges.ranges.length === 0) {
      setTimes([OPEN_24H]);
    } else {
      setTimes(groupedTimeRanges.ranges);
    }
  }, [groupedTimeRanges]);

  return (
    <Modal
      title={formatMessage({ id: 'menus.schedule_selector.modal.name' })}
      cancelButtonMessage={formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onClose}
    >
      <DaysSelector selectedDays={selectedDays} onSelectionChanged={onSelectedDayChanged} />
      <MenuTimeRanges times={times} setTimes={onTimesChanged} />
      <Button
        variant="primary"
        fullWidth
        size="large"
        marginTop={spaceUsages.largeSmall}
        onClick={onConfirmation}
        disabled={!canConfirm}
      >
        <FormattedMessage id="menus.schedule_selector.modal.confirm" />
      </Button>
    </Modal>
  );
};

export default MenuScheduleModal;
