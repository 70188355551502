import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps,
  styled,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import RightArrowSvg from '../../../component/icons/RightArrowSvg';
import { NavigationMenuItem } from './NavigationMenuItem';
import { getMenuItemIcon, NavigationItem } from '../NavigationItems';
import { NavigationMenuItemIcon } from './NavigationMenuItemIcon';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { Badge } from 'src/app/component/Badge/Badge';

type NavigationMenuGroupProps = {
  menuItem: NavigationItem;
  compacted: boolean;
  isOpened: boolean;
  isSelected: boolean;
  isMobile: boolean;
  onGroupExpanded: (selected: NavigationItem | undefined) => void;
  onItemSelected: (item: NavigationItem) => void;
};

const NavigationMenuAccordionHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  width: 100%;
`;

const MenuIcon = styled('div')`
  padding: 0 8px 0 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BadgeContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

type NavigationMenuGroupAccordionSummaryProps = AccordionSummaryProps & {
  selected: boolean;
  isMobile: boolean;
};

const AccordionSummary = styled(({ selected, isMobile, ...otherProps }: NavigationMenuGroupAccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<RightArrowSvg color={PaletteTheme.text!.disabled} size={16} />} {...otherProps} />
))(({ selected, isMobile }) => ({
  flexDirection: 'row-reverse',
  borderRadius: '8px',
  color: PaletteTheme.text?.primary,
  height: isMobile ? '48px' : '40px',
  minHeight: isMobile ? '48px' : '40px',
  marginBottom: '4px',
  padding: '4px',
  width: '100%',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '&:hover': {
    borderRadius: '8px',
    backgroundColor: selected ? '' : PaletteTheme.menu.hover,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 0;
`;
const Accordion = styled(MuiAccordion)(() => ({
  '&::before': {
    height: 0,
  },
}));

const AccordionHeaderContainer = styled('div')`
  width: 100%;
`;

export function NavigationMenuGroup({
  menuItem,
  compacted,
  isOpened,
  isSelected,
  onGroupExpanded,
  onItemSelected,
  isMobile,
}: NavigationMenuGroupProps) {
  const intl = useIntl();
  const currentIcon = useMemo(() => getMenuItemIcon(menuItem, isSelected), [isSelected, menuItem]);

  const accordionSummaryHeader = useMemo(
    () => (
      <NavigationMenuAccordionHeader data-intercom-target={menuItem.text}>
        {currentIcon && <MenuIcon>{currentIcon}</MenuIcon>}
        <FormattedMessage id={menuItem.text} />
        {menuItem.badge && (
          <BadgeContainer>
            <Badge label={intl.formatMessage({ id: menuItem.badge })} color={'info'} size="small" />
          </BadgeContainer>
        )}
      </NavigationMenuAccordionHeader>
    ),
    [currentIcon, menuItem],
  );

  const tooltipLabel = menuItem.tooltip ? intl.formatMessage({ id: menuItem.tooltip }) : '';
  const accordionSummary = useCallback(
    (showTooltip: boolean) => (
      <AccordionSummary selected={isSelected} isMobile={isMobile}>
        {showTooltip ? (
          <ThemeTooltip title={tooltipLabel}>
            <AccordionHeaderContainer>{accordionSummaryHeader}</AccordionHeaderContainer>
          </ThemeTooltip>
        ) : (
          <>{accordionSummaryHeader}</>
        )}
      </AccordionSummary>
    ),
    [isSelected, menuItem, accordionSummaryHeader],
  );

  if (!menuItem.subItems || menuItem.subItems.length === 0) {
    return <></>;
  }

  if (compacted) {
    return <NavigationMenuItemIcon icon={currentIcon} />;
  }

  const onAccordionChanged = (event: React.SyntheticEvent, expanded: boolean) => {
    if (expanded) {
      onGroupExpanded(menuItem);
      return;
    }

    onGroupExpanded(undefined);
  };

  const showTooltip = (!isMobileScreen() && menuItem.tooltip) as boolean;
  return (
    <Accordion elevation={0} disableGutters expanded={isOpened} onChange={onAccordionChanged}>
      {accordionSummary(showTooltip)}
      <AccordionDetails>
        {menuItem.subItems.map((subItem) => (
          <NavigationMenuItem
            key={subItem.route + subItem.text}
            menuItem={subItem}
            compacted={compacted}
            onItemSelected={onItemSelected}
            isMobile={isMobile}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
