import { createMoneyWithCurrency, Money, moneyToUnit } from '@sundayapp/web-money';
import * as React from 'react';
import { FC } from 'react';
import { InputAdornment, TextField } from '@mui/material';

type MoneyFormProps = {
  value: Money;
  onChange: (value: Money) => void;
  disabled: boolean;
  label: string;
};

export const MoneyForm: FC<MoneyFormProps> = ({ value, onChange, disabled, label }) => {
  return (
    <TextField
      variant="outlined"
      size="medium"
      disabled={disabled}
      label={label}
      sx={{ width: 250 }}
      type="number"
      value={value.amount > 0 ? moneyToUnit(value) : 0}
      onChange={(e) => onChange(createMoneyWithCurrency(e.target.value, value.currency))}
      InputProps={{
        inputProps: {
          min: 0,
        },
        endAdornment: <InputAdornment position="end">{value.currency}</InputAdornment>,
      }}
    />
  );
};
