import { Money } from '@sundayapp/web-money';
import { ValuesOf } from 'src/utils/typescript/valuesOf';

export const reconciliationOperationKind = {
  NOT_NOTIFIED_PAYMENT: 'NOT_NOTIFIED_PAYMENT',
  PDQ_FAST_PAYMENT: 'PDQ_FAST_PAYMENT',
  WALKOUT: 'WALKOUT',

} as const;
export type ReconciliationOperationKind = ValuesOf<typeof reconciliationOperationKind>;

export const reconciliationOperationOrigin = {
  PDQ: 'PDQ',
  QR_CODE: 'QR_CODE',
  SUPPORT: 'SUPPORT',
} as const;
export type ReconciliationOperationOrigin = ValuesOf<typeof reconciliationOperationOrigin>;

export interface ReconciliationOperation {
  id: string,
  sales: Money,
  tips: Money,
  at: Date,
  kind: ReconciliationOperationKind,
  origin: ReconciliationOperationOrigin,
}

export interface ReconciliationReporting {
  operations: ReconciliationOperation[]
  sales: Money;
  tips: Money;

}

export type ReconciliationReportingDigest = {
  walkouts: ReconciliationReporting;
  notNotifiedPayments: ReconciliationReporting;
  pdqFastPayments: ReconciliationReporting;
};
