/* eslint-disable max-len */
import { IconProps, SvgIcon } from '@mui/material';
import React from 'react';

export const CbIcon = ({ sx }: IconProps) => (
  <SvgIcon
    width="40"
    height="24"
    viewBox="0 0 40 24"
    fill="none"
    sx={sx}
  >
    <rect x="0.5" y="0.5" width="39" height="24" rx="2.5" fill="white" />
    <rect x="9" y="4" width="23" height="16" rx="2" fill="url(#paint0_linear_1290_7324)" />
    <path
      d="M16.7757 12.1457C16.824 12.1457 16.8606 12.1457 16.8966 12.1457C18.1805 12.1457 19.6009 12.1457 20.8848 12.1457C20.8848 12.1457 20.8939 12.1457 20.8848 12.2836C20.8377 13.0099 20.6305 13.6832 20.2049 14.2794C19.7179 14.9605 19.0518 15.3893 18.2602 15.6345C17.7941 15.7789 17.3156 15.8443 16.8273 15.8587C16.1292 15.8789 15.4454 15.8012 14.7891 15.5554C13.5451 15.0886 12.7626 14.2094 12.4724 12.9072C12.3619 12.4117 12.3456 11.9097 12.4011 11.4037C12.4842 10.654 12.7358 9.97346 13.2215 9.38906C13.7046 8.80858 14.325 8.43794 15.0414 8.22156C15.6938 8.02415 16.3612 7.9712 17.0398 8.01369C17.615 8.04964 18.1733 8.16077 18.7008 8.39479C19.8774 8.91709 20.5808 9.81919 20.8194 11.0821C20.8541 11.2658 20.8652 11.4547 20.8894 11.6404C20.8952 11.6855 20.8894 11.7025 20.8894 11.7025C20.8632 11.7018 20.7828 11.7025 20.7567 11.7025C19.4676 11.7025 18.1785 11.7025 16.8894 11.7025C16.8127 11.7025 16.7757 11.7025 16.7757 11.7025C16.7757 11.8064 16.7744 12.0261 16.7744 12.1437L16.7757 12.1457Z"
      fill="white"
    />
    <path
      d="M21.3452 15.6376C21.3452 15.601 21.3452 15.5723 21.3452 15.5442C21.3452 14.4433 21.3465 13.3425 21.3452 12.2417C21.3452 12.2417 21.355 12.1443 21.4335 12.1443C23.154 12.1469 24.8745 12.1456 26.595 12.1495C26.9029 12.1502 27.2147 12.1606 27.5063 12.2731C28.0933 12.4986 28.4496 12.9307 28.5829 13.5412C28.745 14.2838 28.3907 15.0545 27.7312 15.4213C27.5122 15.5428 27.2749 15.6036 27.0284 15.618C26.7055 15.6363 26.3813 15.6435 26.057 15.6435C24.5326 15.6461 23.0082 15.6448 21.4838 15.6442C21.44 15.6442 21.3969 15.6396 21.3452 15.6376Z"
      fill="white"
    />
    <path
      d="M21.3467 11.7036C21.3467 11.6611 21.3467 11.6349 21.3467 11.6095C21.3467 10.5112 21.348 9.4137 21.3467 8.31549C21.3467 8.24816 21.3584 8.2207 21.4356 8.2207C23.1692 8.22397 24.9021 8.22201 26.6357 8.22593C26.9521 8.22593 27.2718 8.24358 27.5672 8.3704C28.1144 8.60508 28.4491 9.02213 28.5792 9.60261C28.7472 10.3504 28.3739 11.1499 27.6947 11.5009C27.4849 11.6095 27.2587 11.6618 27.026 11.6781C26.8096 11.6938 26.5926 11.699 26.3762 11.699C24.7498 11.7016 23.1241 11.7023 21.4977 11.7036C21.4513 11.7036 21.4049 11.7036 21.3473 11.7036H21.3467Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1290_7324"
        x1="32"
        y1="4"
        x2="3.15272"
        y2="19.2414"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#002B61" />
        <stop offset="0.30038" stopColor="#0094B2" />
        <stop offset="0.463208" stopColor="#0094B2" />
        <stop offset="1" stopColor="#099745" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
