import { useRenderMoney } from '@sundayapp/web-money';
import { styled } from '@mui/material';
import { VenueKpi } from '../domain/VenueKpi';
import { HomeHeader } from './header/HomeHeader';
import { breakpoints } from 'src/app/theme/ThemeV5';
import React from 'react';
import { HomeRevenueCard } from './revenue/HomeRevenueCard';
import { HomeTipsCard } from './tips/HomeTipsCard';
import { HomeReviewsCard } from './reviews/HomeReviewsCard';
import { Business } from 'src/business/domain/Business';
import { ReferralBlock } from './referral/ReferralBlock';
import { featureFlag } from 'src/feature-flipping/infrastructure/featureFlag';
import { TableStatus } from './status/TableStatus';
import { HomeActions } from './actions/HomeActions';

type HomeContentProps = {
  business: Business;
  venueKpi: VenueKpi;
};

const Cards = styled('div')`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  gap: 24px;

  @media (max-width: ${breakpoints.homeTablet}) {
    flex-direction: column;
    margin-bottom: 32px;
    gap: 32px;
  }
`;

const ActionsAndStatus = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;

  @media (max-width: ${breakpoints.homeTablet}) {
    flex-direction: column;
  }
`;

export const HomeContent = ({
  business,
  venueKpi,
}: HomeContentProps) => {
  const renderMoney = useRenderMoney(true, true);
  const isPAT = business.patEnrollment !== undefined;

  const { adoptionRate } = venueKpi;
  const referralCampaignActive = featureFlag('referral-campaign');

  return (
    <>
      <HomeHeader
        adoptionRate={adoptionRate}
        totalRevenue={renderMoney(venueKpi.totalRevenue)}
      />
      <Cards>
        <HomeRevenueCard business={business} venueKpi={venueKpi} />
        <HomeTipsCard business={business} venueKpi={venueKpi} />
        <HomeReviewsCard business={business} />
      </Cards>
      <ActionsAndStatus>
        <HomeActions business={business} />
        {referralCampaignActive ? <ReferralBlock business={business} /> : isPAT && <TableStatus business={business} />}
      </ActionsAndStatus>
    </>
  );
};
