import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Divider from '@mui/material/Divider';
import { ReviewsRating } from './ReviewsRating';
import {
  averageRating,
  DistributionByRating,
  mapToRatingStatistics,
  numberOfBadRatings,
  numberOfRatings,
} from '../domain/DistributionByRating';
import { StatCard } from './StatCard';
import { DateRange } from '../../domain/DateRange';
import { GlobalTrends } from './GlobalTrends';
import { averageRatingsViewModel } from '../AverageRatingsViewModel';
import { featureFlagOverallSatisfaction } from './featureFlagOverallSatisfaction';
import { LoadingOrErrorHOC } from '../../../components/LoadingOrErrorHOC';
import { useGuestExperience } from '../infrastructure/useGuestExperience';
import { RedirectToBadReviews } from '../dimension/RedirectToBadReviews';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

const Container = styled(Box)`
  border-radius: 1.5rem;
  background: white;
`;

const Header = styled('div')`
  padding: 1.5rem;
`;

const Content = styled(Stack)`
  padding: 0 1.5rem 1.5rem 1.5rem;
`;

const ReviewsStatisticsContent = ({ distributionByRating }: { distributionByRating: DistributionByRating }) => (
  <Grid container>
    <Grid item sm={4} xs={12}>
      <Stack direction="row" justifyContent="space-between" sx={{ height: '100%' }}>
        <Grid container paddingTop="1.5rem" paddingBottom="1.5rem" gap="1.5rem">
          <Grid item xs={5} sm={12}>
            <StatCard
              stat={`${averageRating(distributionByRating)} / 5`}
              description={<FormattedMessage id="review.guest_experience.stats.average" />}
            />
          </Grid>
          <Grid item xs={5} sm={12}>
            <StatCard
              stat={`${numberOfRatings(distributionByRating)}`}
              description={<FormattedMessage id="review.guest_experience.stats.count" />}
            />
          </Grid>
        </Grid>
        <Divider
          sx={{
            display: {
              xs: 'none',
              sm: 'block',
            },
          }}
          orientation="vertical"
          flexItem
        />
      </Stack>
    </Grid>
    <Grid item sm={8} xs={12}>
      <Divider
        sx={{
          display: {
            xs: 'block',
            sm: 'none',
          },
        }}
        orientation="horizontal"
        flexItem
      />
      <ReviewsRating ratingStatistics={mapToRatingStatistics(distributionByRating!)} />
    </Grid>
  </Grid>
);

export const OverallSatisfaction = ({ dateRange }: { dateRange: DateRange }) => {
  const shouldDisplayOverallSatisfaction = featureFlagOverallSatisfaction();

  const businessId = useBusinessIdOrThrow();

  const queryResult = useGuestExperience(businessId, dateRange.startDate(), dateRange.endDate());
  return (
    <Container>
      <Header>
        <Typography>
          <FormattedMessage id="review.guest_experience.stats.title" defaultMessage="Overall satisfaction" />
        </Typography>
      </Header>
      <Divider />
      <Content direction="column">
        <LoadingOrErrorHOC
          queryResult={queryResult}
          intlKeys={{ loading: 'reviews.guest-experience.loading.loading' }}
          onLoaded={(data) => (
            <>
              <ReviewsStatisticsContent distributionByRating={data.distributionByRating!} />
              {shouldDisplayOverallSatisfaction && (
                <Stack gap={2}>
                  <GlobalTrends viewModel={averageRatingsViewModel(data.dimensionAnalysis!, dateRange)} />
                  <RedirectToBadReviews
                    businessId={businessId}
                    period={dateRange}
                    numberOfRatings={numberOfRatings(data.distributionByRating!)}
                    numberOfBadRatings={numberOfBadRatings(data.distributionByRating!)}
                  />
                </Stack>
              )}
            </>
          )}
        />
      </Content>
    </Container>
  );
};
