import styled from 'styled-components';
import React from 'react';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { getSpacing } from '../../../../stylesheet';
import IconButton from '../../../../common/components/IconButton';
import { CheckIcon, PlusIcon } from '../../../../common/components/Icons';

type AreaBoxMenuProps = {
  menu: OrderingMenu;
  selected: boolean;
  onToggled: (menu: OrderingMenu, selected: boolean) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${getSpacing(3)};
  border: 1px solid #dddde4;
  border-radius: 16px;
  align-items: center;
  margin-bottom: ${getSpacing(1)};
  justify-content: space-between;
`;

const MenuName = styled.div``;

export const AreaBoxMenu = ({ menu, selected, onToggled }: AreaBoxMenuProps) => (
  <Container>
    <MenuName>{menu.name}</MenuName>
    {selected ? (
      <IconButton
        Icon={CheckIcon}
        active
        onClick={() => {
          onToggled(menu, false);
        }}
      />
    ) : (
      <IconButton
        Icon={PlusIcon}
        onClick={() => {
          onToggled(menu, true);
        }}
      />
    )}
  </Container>
);
