// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

export class DateRange {
  constructor(
    public startDate: Date,
    public endDate: Date,
  ) {
  }

  tomorrow() {
    return new DateRange(
      moment(this.startDate).add(1, 'days').toDate(),
      moment(this.endDate).add(1, 'days').toDate(),
    );
  }

  yesterday() {
    return new DateRange(
      moment(this.startDate).subtract(1, 'days').toDate(),
      moment(this.endDate).subtract(1, 'days').toDate(),
    );
  }
}
