// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { MouseEvent, useCallback, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { SelectDirection } from '../Select';
import {
  ArrowIcon,
  OptionValue,
  SelectAutocompleteInput,
  SelectContainer,
  SelectedValue,
  SelectOptions,
  SelectPlaceholder,
} from '../Select/Select.style';
import { useAutocomplete } from '../Select/useAutocomplete';
import { suggestedTimesAfterThresold } from './constants';
import { renderHours } from '../../utils/renderHours';
import { renderMinutes } from '../../utils/renderMinutes';
import { Time } from '../../domain/Time';
import { getTimePeriodFromString } from '../../utils/getTimePeriodFromString';

interface Props {
  thresold?: Time;
  value: Time;
  onChange: (value: Time) => void;
  direction?: SelectDirection;
  placeholder?: string;
}

const stopPropagation = (event: MouseEvent) => event.stopPropagation();

const renderTime = (time: Time) =>
  (time.hours >= 12 ? (
    <FormattedMessage
      id="common.afternoonTime"
      defaultMessage="{hours12}:{minutes} PM"
      values={{
        hours: renderHours(time.hours),
        hours12: renderHours(time.hours - 12 || 12),
        minutes: renderMinutes(time.minutes),
      }}
    />
  ) : (
    <FormattedMessage
      id="common.morningTime"
      defaultMessage="{hours12}:{minutes} AM"
      values={{
        hours: renderHours(time.hours),
        hours12: renderHours(time.hours || 12),
        minutes: renderMinutes(time.minutes),
      }}
    />
  ));

const TimePicker: React.FC<Props> = ({
  value,
  onChange,
  direction = SelectDirection.BOTTOM,
  placeholder,
  thresold,
  ...props
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const close = useCallback(() => setOpen(false), [setOpen]);

  const [preselectedOption, filteredOptions, autocompleteInputProps] = useAutocomplete(
    suggestedTimesAfterThresold(thresold),
    onChange,
    close,
    open,
    getTimePeriodFromString,
  );

  const toggleOpenStatus = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const renderOptions = useCallback(
    (optionValue: Time, index: number) => (
      <OptionValue
        key={index}
        highlight={optionValue === preselectedOption}
        onClick={() => {
          onChange(optionValue);
          close();
        }}
      >
        {renderTime(optionValue)}
      </OptionValue>
    ),
    [onChange, close, preselectedOption],
  );

  const SelectValue = () => {
    if (value && !open) {
      return <SelectedValue>{renderTime(value)}</SelectedValue>;
    }

    if (open) {
      return (
        <SelectAutocompleteInput
          type="text"
          onClick={stopPropagation}
          placeholder={placeholder}
          autoFocus
          {...autocompleteInputProps}
        />
      );
    }
    return <SelectPlaceholder>{placeholder}</SelectPlaceholder>;
  };
  return (
    <SelectContainer onClick={toggleOpenStatus} open={open} {...props}>
      <SelectValue />
      <ArrowIcon open={open} />
      <SelectOptions open={open} onClick={stopPropagation} direction={direction}>
        {filteredOptions.map(renderOptions)}
      </SelectOptions>
    </SelectContainer>
  );
};

export default TimePicker;
