import { Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { colorPalette } from 'src/ordering/stylesheet';

type Props = {
  children: ReactNode
};

export const TrendsNotAvailable = ({ children }: Props) => (
  <Stack
    sx={{
      padding: '1rem',
      borderRadius: '1rem',
      height: '100%',
      backgroundColor: colorPalette.grey100,
    }}
    alignItems="center"
    direction={'column'}
  >
      {children}
  </Stack>
);
