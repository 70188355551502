import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled, { css } from 'styled-components';
import { OrderPaymentStatus, OrderSummary } from '../../types';
import { getPathToOrderPayments } from '../../services';
import Tag from '../../../common/components/Tag';
import { colorPalette } from '../../../stylesheet';
import { useRouting } from '../../../common/hooks';
import { BusinessId } from 'src/business/domain/Business';

interface Props {
  businessId: BusinessId;
  order: OrderSummary;
}

const orderPaymentStatusToBackgroundColor: Record<OrderPaymentStatus, string> = {
  [OrderPaymentStatus.TO_BE_PAID]: colorPalette.peach,
  [OrderPaymentStatus.PARTIALLY_PAID]: colorPalette.peach,
  [OrderPaymentStatus.PAID]: colorPalette.lightGreen,
  [OrderPaymentStatus.PARTIALLY_REFUNDED]: colorPalette.lightGrey,
  [OrderPaymentStatus.REFUNDED]: colorPalette.lightGrey,
  [OrderPaymentStatus.FAILED]: colorPalette.lightRed,
  [OrderPaymentStatus.AUTO_REFUNDED]: colorPalette.lightGrey,
};

const orderPaymentStatusToColor: Record<OrderPaymentStatus, string> = {
  [OrderPaymentStatus.TO_BE_PAID]: colorPalette.orange,
  [OrderPaymentStatus.PARTIALLY_PAID]: colorPalette.orange,
  [OrderPaymentStatus.PAID]: colorPalette.green,
  [OrderPaymentStatus.PARTIALLY_REFUNDED]: colorPalette.defaultTextColor,
  [OrderPaymentStatus.REFUNDED]: colorPalette.defaultTextColor,
  [OrderPaymentStatus.FAILED]: colorPalette.red,
  [OrderPaymentStatus.AUTO_REFUNDED]: colorPalette.defaultTextColor,
};

interface OrderPaymentStatusContainerProps {
  paymentStatus: OrderPaymentStatus;
}

export const OrderPaymentStatusContainer = styled(Tag)<OrderPaymentStatusContainerProps>`
  cursor: pointer;
  ${({ paymentStatus }) =>
    paymentStatus
    && css`
      background-color: ${orderPaymentStatusToBackgroundColor[paymentStatus]};
      color: ${orderPaymentStatusToColor[paymentStatus]};
    `}
`;

const labelPerPaymentStatus: Record<OrderPaymentStatus, ReactNode> = {
  [OrderPaymentStatus.TO_BE_PAID]: <FormattedMessage id="orderPayment.TO_BE_PAID" defaultMessage="to be paid" />,
  [OrderPaymentStatus.PARTIALLY_PAID]: (
    <FormattedMessage id="orderPayment.PARTIALLY_PAID" defaultMessage="partially paid" />
  ),
  [OrderPaymentStatus.PAID]: <FormattedMessage id="orderPayment.PAID" defaultMessage="paid" />,
  [OrderPaymentStatus.PARTIALLY_REFUNDED]: (
    <FormattedMessage id="orderPayment.PARTIALLY_REFUNDED" defaultMessage="partially refunded" />
  ),
  [OrderPaymentStatus.REFUNDED]: <FormattedMessage id="orderPayment.REFUNDED" defaultMessage="refunded" />,
  [OrderPaymentStatus.FAILED]: <FormattedMessage id="orderPayment.FAILED" defaultMessage="failed" />,
  [OrderPaymentStatus.AUTO_REFUNDED]: (
    <FormattedMessage
      id="orderPayment.AUTO_REFUNDED"
      defaultMessage="auto refunded"
      description="payment automatically refunded when the box order failed"
    />
  ),
};

const getLabelPerPaymentStatus = (status: OrderPaymentStatus) => labelPerPaymentStatus[status];

const OrderPaymentStatusLabel: React.FC<Props> = ({ businessId,  order }) => {
  const orderingRouting = useRouting();
  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      getPathToOrderPayments(businessId, order, orderingRouting);
    },
    [orderingRouting, order],
  );
  return (
    <OrderPaymentStatusContainer
      paymentStatus={order.paymentStatus}
      onClick={onClick}
      data-testid="order-payment-status"
    >
      {getLabelPerPaymentStatus(order.paymentStatus)}
    </OrderPaymentStatusContainer>
  );
};

export default OrderPaymentStatusLabel;
