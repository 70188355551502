import { ChevronRight } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { CardContent, CardWrapper, ContentFooterCta, ContentFooterWrapper, ContentSubItemBox, ContentSubItemSection, InfoCardContentWrapper, InfoCardSubtitleWrapper } from '@styles/Card/StyledCard';
import { StatTrend } from '@atoms/StatTrend/StatTrend';

type InfoCardProps = {
  title?: never;
  trend: number;
  trendValue?: string | number | null;
  subTitle: string;
  content?: never;
  hasTitle?: false;
  hasContent?: false | boolean;
  buttonText?: string;
  hasTrend?: boolean;
} | {
  hasTitle: true;
  title: string;
  trend: number;
  trendValue?: string | number | null;
  subTitle: string;
  hasContent: true | boolean;
  content?: {
    headLine: {
      left: string;
      right: string;
    }
    subHeadLine: {
      left: string;
      right: string;
    }
  }[];
  buttonText?: string;
  hasTrend?: boolean;
};

export const InfoCard = ({
  title,
  trend,
  subTitle,
  content = [{
    headLine: {
      left: '',
      right: '',
    },
    subHeadLine: {
      left: '',
      right: '',
    },
  }],
  hasTitle,
  hasContent = false,
  buttonText = '',
  hasTrend = false,
  trendValue = '',
}: InfoCardProps) => {
  return (
    <CardWrapper>
      <CardContent>
        {hasTitle && (
          <Typography variant="body1">
            {title}
          </Typography>
        )}
        <InfoCardSubtitleWrapper hasContent={hasContent}>
          <Typography variant="h5">{subTitle}</Typography>
          {hasTrend && <StatTrend trend={trend >= 0 ? 'UP' : 'DOWN'} value={trendValue !== null ? trendValue : ''} label="venue.home.compare-last-week" />}
        </InfoCardSubtitleWrapper>
        {hasContent && (
          <>
            <Divider />
            <InfoCardContentWrapper>
              {content && content.map((item, index) => (
                <ContentSubItemBox key={index}>
                  <ContentSubItemSection isHeadLine>
                    <Typography variant="body1">{item.headLine.left}</Typography>
                    <Typography variant="body1">{item.headLine.right}</Typography>
                  </ContentSubItemSection>
                  <ContentSubItemSection>
                    <Typography variant="body2">{item.subHeadLine.left}</Typography>
                    <Typography variant="body2">{item.subHeadLine.right}</Typography>
                  </ContentSubItemSection>
                </ContentSubItemBox>
              ))}
            </InfoCardContentWrapper>
          </>
        )}
        {buttonText !== '' && (
          <ContentFooterWrapper>
            <ContentFooterCta variant="outlined" endIcon={<ChevronRight />}>
              {buttonText}
            </ContentFooterCta>
          </ContentFooterWrapper>
        )}
      </CardContent>
    </CardWrapper>
  );
};
