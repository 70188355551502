import React, { FC } from 'react';
import { Box, Modal, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { CheckCircleSvg } from 'src/components/icons/CheckCircleSvg';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const FormSentForValidationModal: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const business = useCurrentBusinessOrThrow();
  const close = () => navigate(`${venuePath(business.id)}${RouteDefinitions.Home.path}`, { replace: true });

  return <Modal
    open={true}
    aria-labelledby="create-success-payment-link-modal"
    disableAutoFocus
  >
    <Box sx={{
      background: 'white', maxWidth: '500px', borderRadius: '18px',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="right" alignItems="center" padding="20px 24px"
           sx={{ cursor: 'pointer' }}
           onClick={close}>
        <CrossIcon />
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="24px"
           padding="0 24px 40px">

        <CheckCircleSvg size={64} color={theme.palette.success.main} />

        <Box gap="16px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <Typography variant="h4">
            <FormattedMessage id="settings.payments.checkout.onboarding.formSent.title" />
          </Typography>

          <Typography variant="subtitle1" color={theme.palette.text.secondary} align="center">
            <FormattedMessage id="settings.payments.checkout.onboarding.formSent.subtitle" />
          </Typography>
        </Box>
      </Box>
    </Box>
  </Modal>;
};
