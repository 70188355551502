import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { IconButton, Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { ReplyTemplate } from '../domain/ReplyTemplate';
import ThreeDotsWithoutStyle from './assets/ThreeDots.svg?react';

export type Action = 'add' | 'update' | 'duplicate';

const ThreeDots = styled(ThreeDotsWithoutStyle)`
  min-width: 1.75px;
  min-height: 14px;
`;

const ITEM_HEIGHT = 48;

type InteractiveAction = {
  id: string;
  content: string;
};

type Props = {
  replyTemplate: ReplyTemplate;
  onDelete: (replyTemplate: ReplyTemplate) => void;
  onDuplicate: (replyTemplate: ReplyTemplate) => void;
  onUpdate: (replyTemplate: ReplyTemplate) => void;
};

export const ReplyTemplateInteractiveMenu = ({
  replyTemplate, onDelete, onDuplicate, onUpdate,
}: Props) => {
  const intl = useIntl();

  const updateTemplateInteractiveMenu: InteractiveAction = {
    id: 'update',
    content: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.list.interactive_menu.edit_template' }),
  };
  const duplicateTemplateInteractiveMenu: InteractiveAction = {
    id: 'duplicate',
    content: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.list.interactive_menu.duplicate_template' }),
  };
  const deleteTemplateInteractiveMenu: InteractiveAction = {
    id: 'delete',
    content: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.list.interactive_menu.delete_template' }),
  };

  const templateMenus: InteractiveAction[] = [
    updateTemplateInteractiveMenu,
    duplicateTemplateInteractiveMenu,
    deleteTemplateInteractiveMenu,
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenContextualMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAction = (action: InteractiveAction) => {
    switch (action.id) {
      case updateTemplateInteractiveMenu.id:
        onUpdate(replyTemplate);
        break;
      case duplicateTemplateInteractiveMenu.id:
        onDuplicate(replyTemplate);
        break;
      case deleteTemplateInteractiveMenu.id:
        onDelete(replyTemplate);
        break;
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        size="large"
        onClick={handleOpenContextualMenu}
      >
        <ThreeDots />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleAction}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        {templateMenus.map((action) => (
          <MenuItem key={action.id} onClick={() => handleAction(action)}>
            {action.content}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
