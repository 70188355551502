import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuBusinessRepositoryHttp } from '../../common/repositories/MenuBusinessRepositoryHttp';
import { Task } from '../domain/Task';

export const QUERYKEY_MENU_GET_ALL_TASKS = 'menu_get_all_tasks';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_MENU_GET_ALL_TASKS, businessId] as const;
const getAllTasks = async ({ queryKey: [, businessId] }: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Task[]> => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return menuRepository.getAllTasks(businessId, 10);
};

export const usePollAllTasks = (businessId: BusinessId, enabled: boolean) =>
  useQuery({
    queryKey: buildQueryKey(businessId),
    queryFn: getAllTasks,
    refetchInterval: 5000,
    enabled,
  });
