import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { userRoles } from 'src/domain/user/UserRole';
import { ReadonlyFieldWithLabel } from '../components/ReadonlyFieldWithLabel';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsPageContainer } from '../components/SettingsPageContainer';
import { Card, CardContent, Typography } from '@mui/material';
import styled from 'styled-components';


export const BusinessDetailsForm = styled(CardContent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

function BusinessInformation() {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();

  return (
    <SettingsPageContainer>
      <Card>
        <BusinessDetailsForm>
          <Typography variant={'h6'}>
            <FormattedMessage id="settings.venue_information" />
          </Typography>
          <ReadonlyFieldWithLabel
            label={intl.formatMessage({ id: 'settings.venue_information.legal_name' })}
            value={business.legalName}
          />
          <ReadonlyFieldWithLabel
            label={intl.formatMessage({ id: 'settings.venue_information.street_address' })}
            value={business.address?.streetAddress}
          />
          <ReadonlyFieldWithLabel
            label={intl.formatMessage({ id: 'settings.venue_information.postal_code' })}
            value={business.address?.zipCode}
          />
          <ReadonlyFieldWithLabel
            label={intl.formatMessage({ id: 'settings.venue_information.city' })}
            value={business.address?.city}
          />
          <ReadonlyFieldWithLabel
            label={intl.formatMessage({ id: 'settings.venue_information.state' })}
            value={business.address?.state}
          />
        </BusinessDetailsForm>
      </Card>
    </SettingsPageContainer>
  );
}

export default withRestrictedAccess(
  [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
  BusinessInformation,
);
