import React, { useCallback } from 'react';
import styled from 'styled-components';
import { lineHeight } from '@sundayapp/b2b-react-component-library';
import { Box } from '../../../box/domain/Box';
import IconButton from '../../../common/components/IconButton';
import { CheckIcon, PlusIcon } from '../../../common/components/Icons';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  boxes: Box[];
  excludedBoxIds: string[];
  setExcludedBoxIds: (excludedBoxIds: string[]) => void;
}

const BoxesContainer = styled.div`
  max-height: 300px;
  overflow: auto;
  border: 1px solid ${colorPalette.grey300};
  border-radius: 16px;
`;

const BoxContainer = styled.div`
  padding: 0 ${getSpacing(1)};
`;

const BoxLine = styled.div`
  padding: ${getSpacing(2)} 0;
  line-height: ${lineHeight.title3};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colorPalette.cement};
  cursor: pointer;
`;

const BoxName = styled.div`
  padding: 0 ${getSpacing(1)};
`;

export const PromoCodeBox: React.FC<Props> = ({ boxes, excludedBoxIds, setExcludedBoxIds }) => {
  const renderBox = useCallback(
    (box: Box) => {
      const isBoxWithPromoCode = !excludedBoxIds.includes(box.id);
      const onClick = () => {
        if (isBoxWithPromoCode) {
          setExcludedBoxIds([...excludedBoxIds, box.id]);
        } else {
          setExcludedBoxIds(excludedBoxIds.filter((id) => box.id !== id));
        }
      };

      return (
        <BoxContainer>
          <BoxLine onClick={onClick}>
            {isBoxWithPromoCode ? <IconButton active Icon={CheckIcon} /> : <IconButton Icon={PlusIcon} />}
            <BoxName>{box.displayName ?? box.name}</BoxName>
          </BoxLine>
        </BoxContainer>
      );
    },
    [excludedBoxIds, setExcludedBoxIds],
  );

  if (!boxes) return null;
  return (
    <BoxesContainer>
      <div>{boxes.map((box) => renderBox(box))}</div>
    </BoxesContainer>
  );
};
