import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { MenuBusinessRepositoryHttp } from '../repositories/MenuBusinessRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_DYNAMIC_MENU_ALLOWED = 'menu_dynamic_menu_allowed';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_MENU_DYNAMIC_MENU_ALLOWED, businessId] as const;
export const getDynamicMenuAllowedQuery = async ({
  queryKey: [, businessId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<boolean> => {
  const configuration = ConfigurationLoader.load();
  const venueRepository = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return venueRepository
    .getBusiness(businessId)
    .then((venue) => Promise.resolve(venue?.dynamicMenuAllowed ?? false))
    .catch(() => Promise.resolve(false));
};

export const useGetDynamicMenuAllowed = (businessId: BusinessId) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: getDynamicMenuAllowedQuery });
