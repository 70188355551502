import React, { useEffect } from 'react';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { RevenuesWithContext } from './components/RevenuesWithContext';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const RevenuePanel = () => {
  const business = useCurrentBusinessOrThrow();

  useEffect(() => {
    ampli.revenuesViewed();
  }, []);

  const venueIsNoPos = useBusinessIsNoPos();

  if (venueIsNoPos) {
    return <NoPos />;
  }
  return (
    <>
      <RevenuesWithContext business={business} />
    </>
  );
};
