import styled from 'styled-components';
import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HexAlphaColorPicker } from 'react-colorful';
import { Button, Dialog, Typography } from '@mui/material';

type ColorPickerModalProps = {
  title: string;
  isOpened: boolean;
  close:()=>void;
  defaultColor: string;
  onColorChanged:(color:string)=> void
};

export const PickerDialog = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  gap: 16px;
`;

export const ColorPickerModal = ({
  title, isOpened, close, defaultColor, onColorChanged,
}: ColorPickerModalProps) => {
  const [color, setColor] = useState(defaultColor ?? '#000000ff');

  const handleColorChanged = () => {
    onColorChanged(color);
    close();
  };

  return (
    <Dialog open={isOpened} onClose={close}>
      <PickerDialog>
        <Typography fontSize="large">{title}</Typography>
        <HexAlphaColorPicker color={color} onChange={(newColor) => setColor(newColor.toUpperCase())} />
        <Typography>{color}</Typography>
        <Button variant="contained" onClick={handleColorChanged}>
          <FormattedMessage
            id="menus.block.details.save_changes"
          />
        </Button>
      </PickerDialog>
    </Dialog>
  );
};
