import { Box, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const NoTips = () => {
  const theme = useTheme();
  return (<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" gap="30px">

    <img src="/tips_empty_state.png" alt="no tips to show" style={{ maxWidth: '250px' }} />

    <Box gap="16px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">

      <Typography variant="h5">
        <FormattedMessage id="tips.summary.empty.title" />
      </Typography>
      <Typography variant="body1" color={theme.palette.text.secondary}>
        <FormattedMessage id="tips.summary.empty.subtitle" />
      </Typography>
    </Box>

  </Box>);
};
