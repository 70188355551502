import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { sumMoneys, useRenderMoney } from '@sundayapp/web-money';
import { VenueKpi } from '../../domain/VenueKpi';
import { computeEvolutionPercentage } from '../HomeTools';
import { HomeCard } from '../HomeCard';
import { HomeTipsAward } from './HomeTipsAward';
import { Business, isPaymentTerminalAvailable } from 'src/business/domain/Business';

type HomeTipsCardV2Props = {
  business: Business;
  venueKpi: VenueKpi;
};

export const HomeTipsCard = ({ business, venueKpi }: HomeTipsCardV2Props) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, true);
  const {
    totalTips,
    totalTipsLastWeek,
    totalTipsPaymentTerminal,
    totalTipsPaymentTerminalLastWeek,
    tipsPercentage,
    tipsPercentagePaymentTerminal,
  } =
    venueKpi;
  const tipsTitle =
    business.address.countryCode === 'US'
      ? intl.formatMessage({ id: 'venue.home.tips.title.with_servicecharge' })
      : intl.formatMessage({ id: 'venue.home.tips.title' });

  const totalSundayTips = sumMoneys(totalTips, totalTipsPaymentTerminal);
  const totalSundayTipsLastWeek = sumMoneys(totalTipsLastWeek, totalTipsPaymentTerminalLastWeek);

  const tips = renderMoney(totalSundayTips);
  const tipsTrend = computeEvolutionPercentage(totalSundayTips.amount, totalSundayTipsLastWeek.amount);

  const qrCodeTips = renderMoney(totalTips);
  const pdqTips = renderMoney(totalTipsPaymentTerminal);

  const subItems = [];

  if (isPaymentTerminalAvailable(business)) {
    subItems.push({
      label: intl.formatMessage({ id: 'venue.home.tips.qrcode' }),
      value: `${qrCodeTips} (${tipsPercentage.toFixed(2)}%)`,
    });
    subItems.push({
      label: intl.formatMessage({ id: 'venue.home.tips.terminal' }),
      value: `${pdqTips} (${tipsPercentagePaymentTerminal.toFixed(2)}%)`,
    });
  } else {
    subItems.push({
      label: intl.formatMessage({ id: 'venue.home.tips.percentage' }),
      value: `${tipsPercentage.toFixed(2)}%`,
    });
  }

  return (
    <HomeCard
      title={tipsTitle}
      value={tips}
      trend={tipsTrend}
      isPercentageTrend
      subItems={subItems}
      cta={<HomeTipsAward business={business} />}
    />
  );
};
