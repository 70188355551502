/* eslint-disable max-len */
import { IconProps, SvgIcon } from '@mui/material';
import React from 'react';

export const VisaIcon = ({ sx }: IconProps) => (
  <SvgIcon width="40" height="24" viewBox="0 0 28 7.40" fill="none" sx={sx}>
    <path
      d="M13.027 0c-1.42 0-2.69.85-2.69 2.42 0 1.802 2.251 1.926 2.251 2.831 0 .381-.378.722-1.023.722a3.073 3.073 0 0 1-1.602-.476L9.67 7.082s.79.402 1.837.402c1.552 0 2.774-.891 2.774-2.489 0-1.903-2.26-2.024-2.26-2.864 0-.298.31-.625.954-.625.727 0 1.32.346 1.32.346l.286-1.53S13.936 0 13.027 0ZM.034.116 0 .346s.597.127 1.136.379c.692.289.742.457.858.98l1.272 5.66H4.97L7.596.115h-1.7l-1.688 4.93L3.52.866c-.063-.478-.383-.75-.775-.75H.035Zm8.246 0-1.334 7.25h1.622L9.897.115H8.28Zm9.044 0c-.39 0-.598.241-.75.664l-2.376 6.585h1.7l.33-1.097h2.071l.2 1.097H20L18.691.115h-1.367Zm.221 1.958.504 2.72H16.7l.846-2.72Z"
      fill="#102A94"
    />
  </SvgIcon>
);
