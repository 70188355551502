import {
  Card,
  CardContent,
  CardHeader,
  css,
  InputAdornment,
  InputLabel,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { GeneralConfiguration } from '../../domain/PaymentTerminalConfiguration';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { NeptingConfiguration, NeptingIlot } from '../../domain/PaymentTerminalRepository';

export type GeneralSettingsProps = {
  generalConfig: GeneralConfiguration;
  neptingConfiguration: NeptingConfiguration;
  onGeneralConfigurationUpdated: (configuration: GeneralConfiguration) => void;
};


const SelectIlot = styled(Select)`
  ${(props) => {
    const intl = useIntl();
    const undefinedIlot = intl.formatMessage({ id: 'payment.terminal.settings.general.ilot.undefined' });
    if (props.value === undefinedIlot)
      return css`
        color: red;
        font-weight: bold;
    `;
    return css``;
  }}
`;

function GeneralForm({ generalConfig, neptingConfiguration, onGeneralConfigurationUpdated }: GeneralSettingsProps) {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const [merchantId] = useState<string | undefined>(generalConfig.externalCardAcquirerId || neptingConfiguration.merchantId);
  const [webServiceUrl] = useState<string | undefined>(generalConfig.neptingWsdlUrl || neptingConfiguration.webServiceUrl);
  const [neptingIlots] = useState<NeptingIlot[] | undefined>(neptingConfiguration.neptingIlots);

  const undefinedIlot = intl.formatMessage({ id: 'payment.terminal.settings.general.ilot.undefined' });

  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader title={intl.formatMessage({ id: 'payment.terminal.settings.general.title' })} />
      <CardContent>
        <Stack direction="column" justifyContent="space-between" spacing="16px " width="100%">
          <Stack direction="row" justifyContent="space-between" spacing="20px" alignItems="center">
            <TextField
              size="small"
              type="text"
              variant="outlined"
              value={generalConfig.name}
              label={intl.formatMessage({ id: 'payment.terminal.settings.general.name' })}
              onChange={(e) =>
                onGeneralConfigurationUpdated({
                  ...generalConfig,
                  name: e.target.value,
                })}
            />


          {business.address.countryCode === 'FR' &&
            <>
              <TextField
                size="small"
                type="text"
                disabled={true}
                variant="outlined"
                label={'N° de contrat monétique (Merchant ID)'}
                value={merchantId}
                onChange={(e) =>
                  onGeneralConfigurationUpdated({
                    ...generalConfig,
                    externalCardAcquirerId: e.target.value,
                  })}
              />
              <TextField
                size="small"
                type="text"
                disabled={true}
                variant="outlined"
                label={'Nepting server code'}
                value={webServiceUrl}
                onChange={(e) =>
                  onGeneralConfigurationUpdated({
                    ...generalConfig,
                    neptingWsdlUrl: e.target.value,
                  })}
              />
              {neptingIlots && neptingIlots.length > 0 &&
                <SelectIlot
                  size="medium"
                  label='Ilot'
                  value={generalConfig.ilotId || undefinedIlot }
                  onChange={event => onGeneralConfigurationUpdated({
                    ...generalConfig,
                    ilotId: event.target.value as string,
                  })}
                >
                  <MenuItem key={undefined} value={undefinedIlot} >
                    {undefinedIlot}
                  </MenuItem>
                  {neptingIlots.map((ilot) => (
                    <MenuItem key={ilot.id} value={ilot.id}>
                      {ilot.name}
                    </MenuItem>
                  ))}
                </SelectIlot>
              }
            </>
          }

          </Stack>

          <Divider />

          <Stack direction="row" justifyContent="space-between" spacing="20px" alignItems="center">
            <Typography>
              <FormattedMessage id="payment.terminal.settings.general.print_tickets" />
            </Typography>
            <Switch
              checked={generalConfig.printMerchantTickets}
              onChange={(e) =>
                onGeneralConfigurationUpdated({
                  ...generalConfig,
                  printMerchantTickets: e.target.checked,
                })}
            />
          </Stack>

          <Divider />

          <Stack direction="row" justifyContent="space-between" spacing="20px" alignItems="center">
            <Typography>
              <FormattedMessage id="payment.terminal.settings.general.high_alert" />
            </Typography>
            <Switch
              checked={generalConfig.alertOnHighAmountEnabled}
              onChange={(e) =>
                onGeneralConfigurationUpdated({
                  ...generalConfig,
                  alertOnHighAmountEnabled: e.target.checked,
                })}
            />
          </Stack>
          <Stack direction="row" justifyContent="start" spacing="20px" alignItems="center">
            <InputLabel>
              <FormattedMessage id="payment.terminal.settings.general.amount" />
            </InputLabel>
            <TextField
              type="number"
              inputProps={{ step: '0.1' }}
              disabled={!generalConfig.alertOnHighAmountEnabled}
              value={generalConfig.highAmountThreshold.amount}
              onChange={(event) =>
                onGeneralConfigurationUpdated({
                  ...generalConfig,
                  highAmountThreshold: { amount: parseFloat(event.target.value), currency: business.currency },
                })}
              InputProps={{ endAdornment: <InputAdornment position="end">{business.currency}</InputAdornment> }}
            />
          </Stack>

          <Divider />

          <Stack direction="row" justifyContent="space-between" spacing="20px" alignItems="center">
            <Typography>
              <FormattedMessage id="payment.terminal.settings.general.low_alert" />
            </Typography>
            <Switch
              checked={generalConfig.alertOnLowAmountEnabled}
              onChange={(e) =>
                onGeneralConfigurationUpdated({
                  ...generalConfig,
                  alertOnLowAmountEnabled: e.target.checked,
                })}
            />
          </Stack>
          <Stack direction="row" justifyContent="start" spacing="20px" alignItems="center">
            <InputLabel>
              <FormattedMessage id="payment.terminal.settings.general.amount" />
            </InputLabel>
            <TextField
              type="number"
              inputProps={{ step: '0.1' }}
              value={generalConfig.lowAmountThreshold.amount}
              disabled={!generalConfig.alertOnLowAmountEnabled}
              onChange={(e) =>
                onGeneralConfigurationUpdated({
                  ...generalConfig,
                  lowAmountThreshold: { amount: parseFloat(e.target.value), currency: business.currency },
                })}
              InputProps={{ endAdornment: <InputAdornment position="end">{business.currency}</InputAdornment> }}
            />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default GeneralForm;
