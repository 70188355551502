import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { QUERYKEY_CHARGEBEE_PAYMENT_METHOD_STATUS } from '../queries/getChargebeePaymentMethodStatus';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BookkeepingRepositoryHttp, chargebeePaymentMethodStatus } from '../infrastructure/BookkeepingRepositoryHttp';


export const useCreateChargebeePortalSessionMutation = (businessId: BusinessId, redirectUrl: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const repository = new BookkeepingRepositoryHttp(configuration.bookkeepingApiBaseUrl);

  return useMutation({
    mutationFn: () => repository.createPortalSession(businessId, redirectUrl),
    onSuccess: async () => {
      queryClient.setQueryData([QUERYKEY_CHARGEBEE_PAYMENT_METHOD_STATUS, businessId], chargebeePaymentMethodStatus.PENDING_VERIFICATION);
    },
  });

};
