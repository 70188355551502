import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from './domain/MetabaseDashboard';
import { language } from '../app/i18n/I18nProvider';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useEffect } from 'react';
import { ampli } from 'src/ampli';
import { shouldMockDataForBusiness } from 'src/mocks/shouldMockDataForBusiness';

export const DataAnalyticsOverview = () => {
  const business = useCurrentBusinessOrThrow();

  useEffect(() => {
    ampli.dataAnalyticsOverviewClicked({
      businessId: business.id,
      businessName: business.name,
      clickDate: new Date().toISOString(),
    });
  }, []);

  if (shouldMockDataForBusiness(business.id)) {
    return (
      <img width="100%" height="auto" src="/img/mocks/analytics/KYC-Overview-Tab-Mocked.png" alt="overview mocked" />
    );
  }
  return (
    <DataAnalyticsIframeComponent
      dashboard={language() === 'fr' ? MetabaseDashboard.OVERVIEW_FRENCH : MetabaseDashboard.OVERVIEW_ENGLISH}
    />
  );
};
