import { z } from 'zod';
import { MoneyZ } from 'src/operations/pos-reconciliation/domain/MoneyZ';

export const PendingTipsDispatchZ = z.object({
  id: z.string(),
  amount: MoneyZ,
  startInclusive: z.coerce.date(),
  endExclusive: z.coerce.date(),
});

export const PendingTipsDispatchZArray = z.array(PendingTipsDispatchZ);

export type PendingTipsDispatch = z.infer<typeof PendingTipsDispatchZ>;

export type PendingTipsDispatchArray = PendingTipsDispatch[];
