import React, { FC, ReactNode } from 'react';
import { Product } from 'src/menu/dynamic-menu/domain/Product';
import { BusinessId } from 'src/business/domain/Business';
import { ProductSummary } from 'src/menu/dynamic-menu/domain/ProductSummary';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Card, CircularProgress } from '@mui/material';
import { ProductSimpleList } from 'src/menu/dynamic-menu/components/products/ProductSimpleList';
import { ProductsSelectionModal } from 'src/menu/dynamic-menu/pages/ProductSelectionModal/ProductSelectionModal';

type ProductLinksBoxProps = {
  slots: {
    header: ReactNode;
    emptyListState: ReactNode;
  },
  products: Product[];
  isLoading: boolean;
  businessId: BusinessId;
  filterSelectableProducts?: (product: ProductSummary) => boolean;
  onProductsSorted: (productIds: Product['id'][]) => void;
  onProductSelected: (productIds: Product['id'][]) => void;
  isSelectionVisible: boolean;
  setIsSelectionVisible: (value: boolean) => void
};

export const ProductLinksBox: FC<ProductLinksBoxProps> = ({
  slots: { header, emptyListState },
  filterSelectableProducts,
  products,
  isLoading,
  businessId,
  onProductsSorted,
  onProductSelected,
  isSelectionVisible,
  setIsSelectionVisible,
}) => {
  const intl = useIntl();

  return (
    <Card>
      <Box display="flex" flexDirection="column" p={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between" gap={1}>
          {header}
        </Box>

        {isLoading
          ? <CircularProgress color="inherit" />
          : <ProductSimpleList products={products}
                               enableNavigate={{ businessId }}
                               onSortChanged={onProductsSorted}
                               emptyState={emptyListState} />}


        <ProductsSelectionModal
          title={intl.formatMessage({ id: 'menus.product.sub_products.manage_button' })}
          productIds={products.map((sbp) => sbp.id)}
          onChange={onProductSelected}
          customFilter={filterSelectableProducts}
          visible={isSelectionVisible}
          onClose={() => setIsSelectionVisible(false)}
        />
      </Box>
    </Card>
  );
};
