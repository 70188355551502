import { Button } from '@sundayapp/b2b-react-component-library';
import { Money, useRenderMoney } from '@sundayapp/web-money';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Modal } from '@mui/material';
import { RefundType } from './useRefund';

type RefundConfirmationProps = {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  refundAmount: Money;
  refundType: RefundType;
};

// Sylvain: confirmation should be centered
// But it seems like there is an issue with the payment page with tablet screen
// That's why has a workaround I use '50vh' but x position is still wrong
const style = {
  position: 'fixed',
  top: '50vh',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '24px',
  boxShadow: 24,
  p: 4,
};

export const RefundConfirmation = ({
  show, onConfirm, onCancel, refundAmount, refundType,
}: RefundConfirmationProps) => {
  const { formatMessage } = useIntl();
  const renderMoney = useRenderMoney();

  return (
    <Modal
      open={show}
      onClose={onCancel}
    >
      <Box sx={style}>
        <div style={{ marginBottom: '16px' }}>
          {formatMessage(
            { id: `payments.refund_modal.body.${refundType}`, defaultMessage: 'Confirm partial refund of {amount}' },
            { amount: renderMoney(refundAmount) },
          )}
        </div>
        <div style={{ paddingBottom: '16px' }}>
          <Button
            variant="warning"
            size="medium"
            fullWidth
            onClick={onConfirm}
            data-testid="confirm-refund"
          >
            {formatMessage({ id: 'payments.refund_dialog.refund', defaultMessage: 'refund' })}
          &nbsp;
            {renderMoney(refundAmount)}
          </Button>
        </div>
        <div>
          <Button
            variant="secondary"
            size="medium"
            fullWidth
            onClick={onCancel}
            data-testid="cancel-refund"
          >
            {formatMessage({ id: 'payments.refund_dialog.cancel', defaultMessage: 'cancel refund' })}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
