import { CustomizationRepositoryHttp } from 'src/pages/Settings/Customization/infrastructure/CustomizationRepositoryHttp';
import { DocumentRepository } from 'src/domain/document/DocumentRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Business } from 'src/business/domain/Business';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export class ApplyCoverPictureChanges {
  private customizationRepository: CustomizationRepositoryHttp;

  constructor(private documentRepositoryStorage: DocumentRepository) {
    // move outside
    this.customizationRepository = new CustomizationRepositoryHttp(ConfigurationLoader.load().patOrchestratorUrl);
  }

  async apply(sourceBusiness: Business, targetBusinesses: Array<Business>) {
    const sourceCustomization = await this.customizationRepository.getCustomization(sourceBusiness.patEnrollment!.id);

    if (
      !sourceCustomization.cover?.original ||
      !sourceCustomization.cover?.raw ||
      !sourceCustomization.cover?.s ||
      !sourceCustomization.cover?.m ||
      !sourceCustomization.cover?.l ||
      !sourceCustomization.cover?.xl ||
      !sourceCustomization.cover?.blurhash
    ) {
      // Do nothing if source has no cover picture
      return;
    }

    // Note: we decide not to delete existing cover picture, it will be overwritten automatically
    for (const targetBusiness of targetBusinesses) {
      const savedOriginalCoverPictureUrl = await this.copyFile(
        sourceCustomization.cover.original,
        targetBusiness.patEnrollment!.id,
        'cover_original',
      );
      await this.customizationRepository.saveCoverPictureOriginalUrl(
        targetBusiness.patEnrollment!.id,
        savedOriginalCoverPictureUrl,
      );

      const raw = await this.copyFile(
        sourceCustomization.cover.raw,
        targetBusiness.patEnrollment!.id,
        'cover.jpeg',
        'cover',
      );
      const { s, m, l, xl } = await this.documentRepositoryStorage.getDownloadUrlsOfResizedCoverImages(
        targetBusiness.patEnrollment!.id,
        Date.now(),
      );
      await this.customizationRepository.saveCoverPicture(targetBusiness.patEnrollment!.id, {
        raw,
        s,
        m,
        l,
        xl,
        blurhash: sourceCustomization.cover.blurhash,
      });
    }
  }

  async copyFile(
    sourceUrl: string,
    targetEnrollmentId: EnrollmentId,
    targetFileName: string,
    targetDirectory?: string,
  ): Promise<string> {
    const response = await fetch(sourceUrl);
    const blob = await response.blob();
    const file = new File([blob], targetFileName, { type: blob.type });
    return this.documentRepositoryStorage.saveFile(
      targetEnrollmentId,
      `${targetDirectory ? `${targetDirectory}/` : ''}${targetFileName}`,
      file,
    );
  }
}
