import axios from 'axios';
import { PayoutPdqPort } from '../domain/PayoutPdqPort';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const PayoutPdqHttpPort = (paymentTerminalBaseUrl: string): PayoutPdqPort => ({
  getPayoutsPdqExport(enrollmentId: EnrollmentId, startDate: Date, endDate: Date) {
    return axios
      .get<Blob>(`${paymentTerminalBaseUrl}/enrollments/${enrollmentId}/acquirer/settlements`, {
      headers: {
        accept: 'application/octet-stream',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    })
      .then((response) => ({
        blob: new Blob([response.data]),
        contentDisposition: response.headers['content-disposition'],
      }));
  },
  getPayoutsPdqDetailsExport(enrollmentId: EnrollmentId, startDate: Date, endDate: Date) {
    return axios
      .get<Blob>(`${paymentTerminalBaseUrl}/enrollments/${enrollmentId}/acquirer/transactions`, {
      headers: {
        accept: 'application/octet-stream',
      },
      params: {
        from: startDate,
        to: endDate,
        language: navigator.language,
      },
    })
      .then((response) => ({
        blob: new Blob([response.data]),
        contentDisposition: response.headers['content-disposition'],
      }));
  },
});
