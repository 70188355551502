import React from 'react';
import { Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTheme } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { getEReputationPageStatus } from 'src/store/e-reputation';
import { barChartData, barChartOptions, BarGraphDefinition } from 'src/components/graph/bar/BarChartUtils';
import { RatingsCountGraphData } from '../EReputationViewModel';
import { sumDataLabels } from 'src/components/graph/bar/sumDataLabels';
import { NoEreputationData } from 'src/sundayplus/components/NoEreputationData';

type Props = {
  graphKpis: RatingsCountGraphData | undefined;
};
export const RatingsCountGraph = ({ graphKpis }: Props) => {
  const theme = useTheme();
  const intl = useIntl();
  const pageStatus = useSelector(getEReputationPageStatus);

  const keys = ['fromGoogle', 'fromSunday'] as const;
  type DataKey = typeof keys[number];

  if (!graphKpis) {
    return <NoEreputationData theme={theme} />;
  }

  const data: BarGraphDefinition<DataKey> = {
    keys: keys,
    dataTypes: {
      fromSunday: {
        color: theme.palette.primary.main,
        label: pageStatus.panel === 'TOTAL_FIVE_STARS'
          ? intl.formatMessage({ id: 'reviews.ereputation.graph.five_stars.caption.from_sunday' })
          : intl.formatMessage({ id: 'reviews.ereputation.graph.total_reviews.caption.from_sunday' }),
      },
      fromGoogle: {
        color: '#4285F4',
        label: pageStatus.panel === 'TOTAL_FIVE_STARS'
          ? intl.formatMessage({ id: 'reviews.ereputation.graph.five_stars.caption.from_google' })
          : intl.formatMessage({ id: 'reviews.ereputation.graph.total_reviews.caption.from_google' }),
      },
    },
    data: {
      fromSunday: graphKpis.fromSunday,
      fromGoogle: graphKpis.fromGoogle,
    },
  };

  return (
    <Chart
      type="bar"
      options={barChartOptions()}
      data={barChartData(pageStatus, data, intl)}
      plugins={[ChartDataLabels, sumDataLabels()]}
    />
  );
};
