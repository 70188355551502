import { Money } from '@sundayapp/web-money';
import { DimensionRating } from './DimensionRating';

export type Platform = 'sunday' | 'google';

export type Empty = '';
export const EMPTY: Empty = '';

// Implementation note:
//    id:
//      implicitly used by the Grid component =>
//      when the new interface will replace the old one, please delete it from the Review type
//    businessId: we do not need this information here =>
//      when the new interface will replace the old one, please delete it from the Review type
export type Review = {
  businessId?: string,
  platform: Platform,
  creationDate: number,
  feedback: string | Empty;
  orderId: string | Empty;
  tableId: string | Empty;
  paymentId: string | Empty;
  rating: number;
  totalAmount?: Money,
  paidAmount?: Money,
  waiterName?: string | Empty,
  tableName?: string | Empty,
  id?: string;
  reply: string | Empty;
  replyIsPossible: boolean;
  comingFromSunday: boolean;
  dimensionRatings: DimensionRating[];
};

export function rating() {
  return (review: Review) => review.rating;
}

export const isSunday = (review: Review): boolean => review.platform === 'sunday';
export const canReply = (review: Review): boolean => review.replyIsPossible;
export const replied = (review: Review): boolean => review.reply !== EMPTY;
export const haveComment = (review: Review): boolean => review.feedback !== EMPTY;
export const hasOrderInfo = (review: Review): boolean =>
  !(review.waiterName === EMPTY && review.tableName === EMPTY && review.totalAmount === undefined && review.paidAmount === undefined);
export const isNotEmptyOrUndefined = (s: string | Empty | undefined): s is string => s !== undefined && s !== EMPTY;

export type Author = {
  name: string;
};

export type GoogleReview = Review & {
  platform: 'google';
  author: Author;
  feedbackId?: string;
};

export const isGoogle = (review: Review): review is GoogleReview => review.platform === 'google';

export const reply = (content: string) => (review: Review): Review => ({ ...review, reply: content });
