import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Product, ProductSource } from '../../../domain/Product';
import { UpdateProductDto } from '../../../repositories/ProductDto';

type Props = {
  product: Product
  productUpdate: UpdateProductDto
  setProductUpdate: (productDto: UpdateProductDto) => void
};
export const ProductName = ({
  product, productUpdate, setProductUpdate,
}: Props) => {
  const intl = useIntl();

  const [productName, setProductName] = useState(product.name);

  useLayoutEffect(() => {
    setProductName(product.name);
  }, [product.name]);

  const updateProductName = (newName: string) => {
    let newProductDto = productUpdate;
    if (product.name !== newName) {
      newProductDto = {
        ...newProductDto,
        name: newName,
      };
    } else {
      newProductDto = {
        ...newProductDto,
        name: undefined,
      };
    }
    setProductUpdate(newProductDto);
    setProductName(newName);
  };

  const canRestoreValue = () => product.source === ProductSource.PARTNER && productName !== product.partnerName;
  const restorePosName = () => {
    updateProductName(product.partnerName);
  };
  return (
    <Box component="div" display="flex" flexDirection="row" alignItems="center" width="100%">
      <TextField
        variant="outlined"
        size="medium"
        label={intl.formatMessage({ id: 'menus.product.details.name' })}
        sx={{ width: '100%' }}
        value={productName}
        onChange={(e) => updateProductName(e.target.value)}
      />
      {canRestoreValue() && (
        <Tooltip
          title={intl.formatMessage({ id: 'menus.product.details.restore_pos_value' })}
          placement="top"
          sx={{ fontSize: '30px' }}
        >
          <IconButton size="large" sx={{ height: 'fit-content' }} onClick={restorePosName}>
            <SettingsBackupRestoreIcon
              fontSize="inherit"
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
