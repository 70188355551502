import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Area } from '../../../domain/Area';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { RightArrowIcon } from '../../../../common/components/Icons';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { AreaSummaryService } from './AreaSummaryService';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { EmptyAreaSummaryService } from './EmptyAreaSummaryService';
import { useRouting } from '../../../../common/hooks';
import { BusinessId } from 'src/business/domain/Business';

type AreaSummaryProps = {
  businessId: BusinessId;
  boxId: string;
  area: Area;
  menus: OrderingMenu[];
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(3)};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

const AreaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: ${getSpacing(3)};
  cursor: pointer;
`;

const AreaTitle = styled.div`
  font-size: 24px;
  color: ${colorPalette.defaultTextColor};
`;

const Separator = styled.div`
  border-bottom: 1px solid ${colorPalette.grey300};
  margin-bottom: ${getSpacing(1)};
`;

export const AreaSummary = ({
  businessId, boxId, area, menus,
}: AreaSummaryProps) => {
  const navigation = useRouting();

  const services = useMemo((): FulfillmentType[] => {
    const serviceSet = new Set<FulfillmentType>();
    area.configurations.forEach((config) => serviceSet.add(config.fulfillmentMethod));
    return Array.from(serviceSet);
  }, [area]);

  const onArea = () => {
    navigation.goToArea(businessId, boxId, area.id);
  };

  return (
    <Container>
      <AreaHeader onClick={onArea}>
        <AreaTitle>{area.name}</AreaTitle>
        <RightArrowIcon size={20} />
      </AreaHeader>
      <Separator />
      {services.length > 0 ? (
        services.map((service) => (
          <AreaSummaryService area={area} menus={menus} service={service} key={area.id + FulfillmentType[service]} />
        ))
      ) : (
        <EmptyAreaSummaryService />
      )}
    </Container>
  );
};
