import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { ReportSettingsInputs } from './ReportSettingsInputs';
import {
  GenericPaymentMethodSettingsPanel,
  Line,
  PaymentMethodSettingsPanelProps,
} from './GenericPaymentMethodSettingsPanel';

export const SundayPaymentMethodSettingsPanel = (props: PaymentMethodSettingsPanelProps) => {
  const { form, id } = props;

  return (
    <GenericPaymentMethodSettingsPanel
      {...props}
      deleteEnabled={false}
    >
      <>
        <Line>
          <Typography variant="body1" marginTop="5px">
            <FormattedMessage id="reconciliation.settings.payment_method.split_by_amex" />
          </Typography>
          <input
            type="checkbox"
            style={{ width: '20px', height: '20px' }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...form.register(`paymentMethodsById.${id}.splitByAmericanExpress` as keyof ReportSettingsInputs)}
          />
        </Line>
        <Line>
          <Typography variant="body1" marginTop="5px">
            <FormattedMessage id="reconciliation.settings.payment_method.split_by_digital_meal_voucher" />
          </Typography>
          <input
            type="checkbox"
            style={{ width: '20px', height: '20px' }}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...form.register(`paymentMethodsById.${id}.splitByDigitalMealVoucher` as keyof ReportSettingsInputs)}
          />
        </Line>
      </>
    </GenericPaymentMethodSettingsPanel>
  );
};
