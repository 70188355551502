import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Input,
  InputLabel,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import { v4 as uuidv4 } from 'uuid';
import { Waiter, WaiterConfiguration } from '../../domain/PaymentTerminalConfiguration';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';

const AddWaiterStack = styled('div')`
  flex-direction: row;
  justify-content: start;
  align-items: start;
  display: flex;
  gap: 20px;
  max-width: 400px;
  margin-left: 16px;
  @media (max-width: ${breakpoints.mobile}) {
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }
`;
const buildColumns = (deleteEnable: boolean, onDelete: (waiter: Waiter) => void): GridColDef[] => [
  {
    field: 'name',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="payment.terminal.settings.waiters.header.name" />,
    renderCell: ({ row: waiter }: GridCellParams<Waiter>) => {
      return (
        <CardHeader
          sx={{ padding: '6px' }}
          avatar={<Avatar> <PersonIcon /> </Avatar>}
          title={waiter.name}
        />
      );
    },
  },
  {
    field: 'delete',
    disableColumnMenu: true,
    flex: 1,
    renderHeader: () => <FormattedMessage id="payment.terminal.settings.waiters.header.delete" />,
    renderCell: ({ row: waiter }: GridCellParams<Waiter>) => {
      return (
        <IconButton edge="end" disabled={deleteEnable}>
          <DeleteIcon
            onClick={() => onDelete(waiter)}
          />
        </IconButton>
      );
    },
  },
];

export type WaiterSettingsProps = {
  waiterConfig: WaiterConfiguration;
  onWaiterConfigUpdated: (configuration: WaiterConfiguration) => void;
};

function WaitersForm({ waiterConfig, onWaiterConfigUpdated }: WaiterSettingsProps) {
  const intl = useIntl();
  const [waiter, setWaiter] = useState<Waiter | undefined>(undefined);
  const [waiterName, setWaiterName] = useState('');

  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader
        title={intl.formatMessage({ id: 'payment.terminal.settings.waiters.title' })}
        subheader={
          <Typography paddingTop="8px">
            {intl.formatMessage({ id: 'payment.terminal.settings.waiters.subtitle' })}
          </Typography>
        }
        action={
          <Switch
            checked={waiterConfig.enabled}
            onChange={(e) => onWaiterConfigUpdated({
              ...waiterConfig,
              enabled: e.target.checked,
            })}
          />
        }
      />
      <CardContent>
        <DataGrid
          autoHeight
          rows={waiterConfig.waiters}
          columns={buildColumns(!waiterConfig.enabled, (waiterToDelete: Waiter) => {
            onWaiterConfigUpdated({
              ...waiterConfig,
              waiters: waiterConfig.waiters.filter((item) => item.id !== waiterToDelete.id),
            });
          })}
          disableRowSelectionOnClick
          hideFooter
        />

        <AddWaiterStack>
          <InputLabel>{intl.formatMessage({ id: 'payment.terminal.settings.waiters.name' })}</InputLabel>
          <Input
            type="text"
            disabled={!waiterConfig.enabled}
            value={waiterName}
            onChange={(event) => {
              setWaiterName(event.target.value);
              setWaiter({
                id: uuidv4(),
                name: event.target.value,
              });
            }}
          />
          <Button
            size="small"
            disabled={!waiterConfig.enabled || !waiterName.length}
            onClick={() => {
              const { waiters } = waiterConfig;
              onWaiterConfigUpdated({
                ...waiterConfig,
                waiters: [...waiters, waiter!],
              });
              setWaiter(undefined);
              setWaiterName('');
            }}
          >
            <FormattedMessage id="payment.terminal.settings.waiters.add" />
          </Button>
        </AddWaiterStack>
      </CardContent>
    </Card>
  );
}

export default WaitersForm;
