import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { datadogLogs } from '@datadog/browser-logs';
import { Payment, PaymentMethodRef, PaymentProvider } from '../domain/Payment';
import { Refund as RefundResult, SuccessfulRequest } from '../../domain/payment/Refund';
import { RefundRequestFactory } from 'src/infrastructure/payment/RefundRequestFactory';
import { v4 as uuidv4, v5 as uuidv5 } from 'uuid';
import { EnrollmentForPayment } from '../domain/EnrollmentForPayment';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { BusinessId } from 'src/business/domain/Business';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentResponse = {
  paymentId: string,
  totalAmount: MoneyResponse,
  tipsAmount: MoneyResponse,
  orderAmount: MoneyResponse,
  status: string,
  createdAt: number,
  refunds: RefundResponse[],
  paymentProvider: PaymentProvider,
  failDetails: any,
  paymentMethodType: string,
  paymentMethodReference?: PaymentMethodRef,
  payoutSource: string,
  cardBrand?: string,
  voucher?: VoucherResponse,
  metadata: Record<string, string>,
  digitalFeeAmount: MoneyResponse,
  processedAt: number,
};

export const paymentLinkFeeRuleTypes = {
  PAYMENT_LINK: 'PAYMENT_LINK',
  PAYMENT_LINK_PREMIUM: 'PAYMENT_LINK_PREMIUM',
} as const;

export type PaymentLinkFeeRuleType = typeof paymentLinkFeeRuleTypes[keyof typeof paymentLinkFeeRuleTypes];

type SundayPaymentLinkFeeRule = {
  type: 'PAYMENT_LINK',
  value: PaymentLinkFeeRuleType,
  feeRate: number
};

export type PaymentAccountResponse = {
  pspAccounts: Record<string, PspAccount>;
  sundayFees: {
    digitalFeeCapping: number | null;
    digitalFeeRate: number;
    paymentLinkRules: SundayPaymentLinkFeeRule[]
  },
  stripeOnboardingAllowed: boolean;
  paymentsEnabled: boolean;
  canAcceptPayments: boolean;
};

export type PspAccount = {
  active?: boolean;
  onboardingStatus?: string;
  paymentMethods: PspPaymentMethod[];
  type?: string;
  platformOnboardingStatus?: string;
};

export type PspPaymentMethod = {
  name: string;
  enabled: boolean;
  status?: string;
};

export type MoneyResponse = {
  amount: number;
  currency: string;
};

export type VoucherResponse = {
  code: string;
  amount: MoneyResponse;
};

export type RefundResponse = {
  refundId: string,
  amount: MoneyResponse,
  status: string,
  createdAt: number,
  description: string,
};

type GenerateConfigurationURLRequest = {
  idempotencyKey: string;
  businessId: string;
  refreshUrl: string;
  returnUrl: string;
};

export class PaymentRepositoryHttp {
  constructor(
    private httpClient: AxiosInstance,
    private paymentApiBaseUrl: string,
  ) {
  }

  async generateConfigurationURL(businessId: BusinessId, refreshUrl: string, returnUrl: string): Promise<string> {
    const generateUuidStableForNSec = (n: number): string => {
      const seed = (Math.trunc(new Date().getTime() / (n * 1000))).toString();
      return uuidv5(seed, uuidv4());
    };
    const request: GenerateConfigurationURLRequest = {
      idempotencyKey: generateUuidStableForNSec(2),
      businessId,
      refreshUrl,
      returnUrl,
    };

    return this.httpClient
      .post(`${this.paymentApiBaseUrl}/stripe/configuration-url`, request, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response: AxiosResponse) => response.data.url);
  }

  refund(payment: Payment, amount: number, description?: string): Promise<RefundResult> {
    const request = RefundRequestFactory.build(payment, amount, description);

    return this.httpClient
      .put(`${this.paymentApiBaseUrl}/admin/payments/${payment.id}/refunds`, request, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(
        (response: AxiosResponse) =>
          ({
            success: true,
            refundId: response.data.refundId,
          }) as SuccessfulRequest,
      )
      .catch((error: Error | AxiosError<{ businessCode: string }>) => {
        if (axios.isAxiosError(error)) {
          return {
            success: false,
            error: (error.response?.data as { businessCode?: string })?.businessCode ?? '',
          };
        }
        return {
          success: false,
          error: 'UNKNOWN',
        };
      });
  }

  cancel(paymentId: string): Promise<void> {
    return this.httpClient
      .put(`${this.paymentApiBaseUrl}/payments/${paymentId}/cancel`, undefined, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => undefined)
      .catch((error: Error | AxiosError<{ businessCode: string }>) => {
        datadogLogs.logger.error(`unable to cancel payment with id: ${paymentId}`, { paymentId }, error);
      });
  }

  list(businessId: BusinessId, start: Date, end: Date): Promise<PaymentResponse[]> {
    const params = {
      params: {
        businessId,
        startDate: start.getTime(),
        endDate: end.getTime(),
      },
    };
    return this.httpClient
      .get(`${this.paymentApiBaseUrl}/payments`, params)
      .then((response: AxiosResponse) => response.data.payments);
  }

  account = (enrollmentId: EnrollmentId): Promise<PaymentAccountResponse> => {
    return this.httpClient.get(`${this.paymentApiBaseUrl}/v2/enrollments/${enrollmentId}`).then((response: AxiosResponse) => response.data);
  };

  async getEnrollment(enrollmentId: EnrollmentId): Promise<EnrollmentForPayment> {
    const response = await this.httpClient.get<EnrollmentForPayment>(`${this.paymentApiBaseUrl}/enrollments/${enrollmentId}`);
    return {
      paymentsEnabled: response.data.paymentsEnabled,
      stripeOnboardingAllowed: response.data.stripeOnboardingAllowed,
      canAcceptPayments: response.data.canAcceptPayments,
    };
  }
}
