/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const RefreshSvg: React.FC<Props> = ({ size = 16, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.0003 17.0835C13.9123 17.0835 17.0837 13.9122 17.0837 10.0002C17.0837 7.64555 15.9348 5.55927 14.167 4.27133M10.8337 18.6668L9.16699 17.0002L10.8337 15.3335M10.0003 2.91683C6.08831 2.91683 2.91699 6.08815 2.91699 10.0002C2.91699 12.3548 4.06588 14.4411 5.83366 15.729M9.16699 4.66683L10.8337 3.00016L9.16699 1.3335" stroke={color} strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default RefreshSvg;
