/* eslint-disable max-len */
export const ZapFastSvg = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     fill="none">
  <path fillRule="evenodd" clipRule="evenodd"
        d="M2.5 17.5001C2.5 16.9478 2.94772 16.5001 3.5 16.5001H9C9.55228 16.5001 10 16.9478 10 17.5001C10 18.0524 9.55228 18.5001 9 18.5001H3.5C2.94772 18.5001 2.5 18.0524 2.5 17.5001Z"
        fill="#70707B" />
  <path fillRule="evenodd" clipRule="evenodd"
        d="M1 12.0001C1 11.4478 1.44772 11.0001 2 11.0001H6.5C7.05228 11.0001 7.5 11.4478 7.5 12.0001C7.5 12.5524 7.05228 13.0001 6.5 13.0001H2C1.44772 13.0001 1 12.5524 1 12.0001Z"
        fill="#70707B" />
  <path fillRule="evenodd" clipRule="evenodd"
        d="M3 6.50008C3 5.9478 3.44772 5.50008 4 5.50008H9C9.55228 5.50008 10 5.9478 10 6.50008C10 7.05237 9.55228 7.50008 9 7.50008H4C3.44772 7.50008 3 7.05237 3 6.50008Z"
        fill="#70707B" />
  <path fillRule="evenodd" clipRule="evenodd"
        d="M17.3676 2.07009C17.7947 2.23891 18.0519 2.67699 17.9912 3.13225L17.1422 9.50008H20.9454C20.9569 9.50008 20.9684 9.50008 20.9799 9.50008C21.2007 9.50003 21.4295 9.49998 21.6185 9.51726C21.8044 9.53425 22.1426 9.5793 22.4449 9.81263C22.7975 10.0847 23.0109 10.4994 23.0274 10.9445C23.0415 11.3261 22.8816 11.6275 22.7874 11.7887C22.6916 11.9525 22.5586 12.1386 22.4302 12.3182C22.4235 12.3276 22.4168 12.337 22.4102 12.3463L15.8137 21.5813C15.5468 21.9551 15.0596 22.0989 14.6324 21.9301C14.2053 21.7613 13.9481 21.3232 14.0088 20.8679L14.8578 14.5001H11.0546C11.0431 14.5001 11.0316 14.5001 11.0201 14.5001C10.7993 14.5001 10.5705 14.5002 10.3815 14.4829C10.1956 14.4659 9.85743 14.4209 9.55511 14.1875C9.20254 13.9154 8.98913 13.5007 8.97263 13.0557C8.95847 12.674 9.1184 12.3726 9.21263 12.2115C9.30841 12.0477 9.44143 11.8615 9.56977 11.6819C9.57647 11.6726 9.58316 11.6632 9.58983 11.6539L16.1863 2.41885C16.4532 2.04511 16.9404 1.90127 17.3676 2.07009Z"
        fill="#70707B" />
</svg>;
