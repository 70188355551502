import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Product } from '../../../domain/Product';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { IntlShape } from 'src/app/i18n/TypedIntl';

type Props = {
  product: Product;
  productUpdate: UpdateProductDto;
  setProductUpdate: (productDto: UpdateProductDto) => void;
  taxKind: TaxKind;
  disabled: boolean;
};

export enum TaxKind {
  EAT_IN,
  TAKE_AWAY,
  DELIVERY,
}

const getVat = (kind: TaxKind, product: Product) => {
  switch (kind) {
    case TaxKind.EAT_IN: return product.eatInTax / 100.0;
    case TaxKind.TAKE_AWAY: return product.takeAwayTax / 100.0;
    case TaxKind.DELIVERY: return product.deliveryTax / 100.0;
  }
};

const getLabel = (taxKind: TaxKind, intl: IntlShape) => {
  switch (taxKind) {
    case TaxKind.EAT_IN: return intl.formatMessage({ id: 'menus.product.details.vat.eatIn' });
    case TaxKind.TAKE_AWAY: return intl.formatMessage({ id: 'menus.product.details.vat.takeAway' });
    case TaxKind.DELIVERY: return intl.formatMessage({ id: 'menus.product.details.vat.delivery' });
  }
};

export const ProductVat = ({ product, productUpdate, setProductUpdate, taxKind, disabled = false }: Props) => {
  const intl = useIntl();

  const [value, setValue] = useState(getVat(taxKind, product));

  useLayoutEffect(() => {
    setValue(getVat(taxKind, product));
  }, [product]);

  const onUpdateValue = (newValue: number) => {
    let newProductDto = productUpdate;
    const newTax = (newValue !== getVat(taxKind, product)) ? newValue * 100.0 : undefined;
    switch (taxKind) {
      case TaxKind.EAT_IN: newProductDto = { ...newProductDto, eatInTax: newTax }; break;
      case TaxKind.TAKE_AWAY: newProductDto = { ...newProductDto, takeAwayTax: newTax }; break;
      case TaxKind.DELIVERY: newProductDto = { ...newProductDto, deliveryTax: newTax }; break;
    }
    setProductUpdate(newProductDto);
    setValue(newValue);
  };

  return (
    <TextField
      variant="outlined"
      size="medium"
      disabled={disabled}
      label={getLabel(taxKind, intl)}
      sx={{ width: 250 }}
      type="number"
      value={value > 0 ? value : 0}
      onChange={(e) => onUpdateValue(parseFloat(e.target.value))}
      InputProps={{
        inputProps: {
          min: 0,
          step: '0.01',
        },
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  );
};
