import { Button, Stack } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { displayMenuMigration, MenuReplicationState } from '../../domain/menu/MenuReplicationState';
import { MenuMigrationHeader } from 'src/enterprise/configuration-replication/components/menu/MenuMigrationHeader';

export const IntroduceMenuStep = ({ onNext }: { onNext: (state: MenuReplicationState) => void }) => {

  const intl = useIntl();

  const onStart = () => {
    onNext(displayMenuMigration());
  };

  return (
    <Stack padding="1rem 1rem">

      <MenuMigrationHeader details={intl.formatMessage({ id: 'configuration_replication.menu.details' })} />

      <Stack direction="row-reverse" padding="1rem">
        <Button variant="contained" onClick={onStart}>
          <FormattedMessage id="configuration_replication.introduction.start" />
        </Button>
      </Stack>
    </Stack>
  );
};
