import styled, { css } from 'styled-components';
import { getSpacing } from '../../../stylesheet';

export enum AlignItems {
  FLEX_START = 'flex-start',
  CENTER = 'center',
  FLEX_END = 'flex-end',
  STRETCH = 'stretch',
}

interface Props {
  align?: AlignItems;
}

export const Line = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ align }) =>
    align
    && css`
      align-items: ${align};
    `}
`;

export const Col = styled.div`
  flex: 1 1 50%;
  box-sizing: border-box;
  padding: 0 ${getSpacing(1.5)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
