import axios from 'axios';
import { AreaRepository } from './AreaRepository';
import { Area } from '../domain/Area';
import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import { AreaConfiguration } from '../domain/AreaConfiguration';
import { SundayDevice } from '../domain/SundayDevice';

interface AreasResult {
  areas: Area[];
}

export class AreaRepositoryHttp extends RepositoryHttp implements AreaRepository {
  constructor(private venueBackendUrl: string) {
    super();
  }

  async getBoxAreas(boxId: string): Promise<Area[]> {
    const response = await axios.get<AreasResult>(
      `${this.venueBackendUrl}/boxes/${boxId}/areas`,
      this.withDefaultHeader(),
    );

    return response.data.areas;
  }

  async getArea(boxId: string, areaId: string): Promise<Area | undefined> {
    const response = await axios.get<AreasResult>(
      `${this.venueBackendUrl}/boxes/${boxId}/areas`,
      this.withDefaultHeader(),
    );

    return response.data.areas.find((area) => area.id === areaId);
  }

  async deleteArea(areaId: string): Promise<void> {
    return axios.delete(
      `${this.venueBackendUrl}/areas/${areaId}`,
      this.withDefaultHeader(),
    );
  }

  async createArea(boxId: string, areaName: string): Promise<string> {
    const response = await axios.post<string>(
      `${this.venueBackendUrl}/boxes/${boxId}/areas`,
      { configurations: [], name: areaName, visible: true },
      this.withDefaultHeader(),
    );

    return response.data;
  }

  updateAreaName(area: Area, updatedName: string): Promise<void> {
    return axios.put(
      `${this.venueBackendUrl}/areas/${area.id}`,
      { name: updatedName, visible: area.visible, isDefaultArea: area.defaultArea },
      this.withDefaultHeader(),
    );
  }

  updateAreaConfigurations(areaId: string, configurations: AreaConfiguration[]): Promise<void> {
    return axios.put(
      `${this.venueBackendUrl}/areas/${areaId}/configurations`,
      { configurations },
      this.withDefaultHeader(),
    );
  }

  associateTable(areaId: string, tableId: string): Promise<void> {
    return axios.post(
      `${this.venueBackendUrl}/areas/${areaId}/tables`,
      { tableId },
      this.withDefaultHeader(),
    );
  }

  async getAreaSundayDevices(areaId: string): Promise<SundayDevice[]> {
    const response = await axios.get<SundayDevice[]>(
      `${this.venueBackendUrl}/areas/${areaId}/linked-sunday-devices`,
      this.withDefaultHeader(),
    );

    return response.data;
  }

  async geTableSundayDevices(areaId: string): Promise<SundayDevice[]> {
    const response = await axios.get<SundayDevice[]>(
      `${this.venueBackendUrl}/areas/${areaId}/tables/linked-sunday-devices`,
      this.withDefaultHeader(),
    );

    return response.data;
  }
}
