import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  padding: 0;
  width: 100%;
  box-sizing: border-box;
`;
