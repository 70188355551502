import {
  companyPositionNeeded,
  powerOfAttorneyNeeded,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  needsNationalIdNumber,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/NationalIdNumberIfUS';

const checkIfDocumentIdComplete = (representative: RepresentativeDraft) => {
  if (!representative.identificationType) return false;
  if (representative.identificationType == 'passport') {
    return !!representative.identificationFrontDocument;
  }
  return !!representative.identificationFrontDocument && !!representative.identificationBackDocument;
};

export const isAuthorisedSignatoryComplete = ({
  representative,
  containsErrors,
  businessCountryCode,
}: {
  representative: RepresentativeDraft | undefined;
  containsErrors: boolean;
  businessCountryCode: string;
}): boolean => {
  const isUBO = !!representative?.roles?.ubo;
  const needsCompanyPosition = companyPositionNeeded(representative);
  const needsPowerOfAttorney = powerOfAttorneyNeeded(representative, businessCountryCode);
  const isAddressComplete =
    !!representative?.address?.city &&
    !!representative.address?.country &&
    !!representative.address?.addressLine1 &&
    !!representative.address?.zip &&
    (representative?.address.country !== 'US' || representative?.address.country === 'US' && !!representative.address.state);
  const isUboExtraFieldsComplete = !isUBO || !!representative.ownershipPercentage;
  const isCompositionPositionFilled = !needsCompanyPosition || !!representative?.companyPosition;
  const hasPowerOfAttorneyDocumentIfNotRequired =
    !needsPowerOfAttorney || !!representative?.certifiedAuthorisedSignatoryDocument;
  const isNationalIdNumberComplete = !!representative?.nationalIdNumber || !needsNationalIdNumber(businessCountryCode, representative?.address?.country ?? '');
  if (containsErrors || !representative) return false;
  if (!checkIfDocumentIdComplete(representative)) return false;
  return (
    !!representative!.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    !!representative?.email &&
    isAddressComplete &&
    isNationalIdNumberComplete &&
    hasPowerOfAttorneyDocumentIfNotRequired &&
    isUboExtraFieldsComplete &&
    isCompositionPositionFilled
  );
};
