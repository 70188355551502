/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const CardRefreshSvg: React.FC<Props> = ({ size = 24, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.2929 8.29289C16.6834 7.90237 17.3166 7.90237 17.7071 8.29289L19.7071 10.2929C20.0976 10.6834 20.0976 11.3166 19.7071 11.7071L17.7071 13.7071C17.3166 14.0976 16.6834 14.0976 16.2929 13.7071C15.9024 13.3166 15.9024 12.6834 16.2929 12.2929L16.5527 12.0331C15.108 12.2491 14 13.4952 14 15C14 15.5523 13.5523 16 13 16C12.4477 16 12 15.5523 12 15C12 12.3727 14.0264 10.2187 16.6014 10.0157L16.2929 9.70711C15.9024 9.31658 15.9024 8.68342 16.2929 8.29289ZM23 14C23.5523 14 24 14.4477 24 15C24 17.6273 21.9736 19.7813 19.3986 19.9843L19.7071 20.2929C20.0976 20.6834 20.0976 21.3166 19.7071 21.7071C19.3166 22.0976 18.6834 22.0976 18.2929 21.7071L16.2929 19.7071C15.9024 19.3166 15.9024 18.6834 16.2929 18.2929L18.2929 16.2929C18.6834 15.9024 19.3166 15.9024 19.7071 16.2929C20.0976 16.6834 20.0976 17.3166 19.7071 17.7071L19.4473 17.9669C20.892 17.7509 22 16.5048 22 15C22 14.4477 22.4477 14 23 14Z" fill={color} />
    <path d="M5.16146 3H18.8385C19.3657 2.99998 19.8205 2.99997 20.195 3.03057C20.5904 3.06287 20.9836 3.13419 21.362 3.32699C21.9265 3.61461 22.3854 4.07355 22.673 4.63804C22.8658 5.01641 22.9371 5.40963 22.9694 5.80498C23 6.17954 23 6.6343 23 7.16144V11C23 11.5523 22.5523 12 22 12C21.4477 12 21 11.5523 21 11V7.2C21 6.62345 20.9992 6.25118 20.9761 5.96784C20.9539 5.69618 20.9162 5.59546 20.891 5.54601C20.7951 5.35785 20.6422 5.20487 20.454 5.109C20.4045 5.08381 20.3038 5.04613 20.0322 5.02393C19.7488 5.00078 19.3766 5 18.8 5H5.2C4.62345 5 4.25118 5.00078 3.96784 5.02393C3.69618 5.04613 3.59546 5.0838 3.54601 5.109C3.35785 5.20487 3.20487 5.35785 3.109 5.54601C3.0838 5.59546 3.04613 5.69618 3.02393 5.96784C3.00078 6.25118 3 6.62345 3 7.2V8H14.171C14.0946 8.21637 14.0439 8.43969 14.0187 8.66521C13.3053 9.00144 12.6579 9.45459 12.1012 10H3V14.8C3 15.3766 3.00078 15.7488 3.02393 16.0322C3.04613 16.3038 3.0838 16.4045 3.109 16.454C3.20487 16.6422 3.35785 16.7951 3.54601 16.891C3.59546 16.9162 3.69618 16.9539 3.96784 16.9761C4.25118 16.9992 4.62345 17 5.2 17H12C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19H5.16148C4.63432 19 4.17955 19 3.80498 18.9694C3.40963 18.9371 3.01641 18.8658 2.63803 18.673C2.07355 18.3854 1.6146 17.9265 1.32698 17.362C1.13419 16.9836 1.06287 16.5904 1.03057 16.195C0.999971 15.8205 0.999985 15.3657 1 14.8386V7.16146C0.999985 6.63434 0.999971 6.17953 1.03057 5.80498C1.06287 5.40963 1.13419 5.01641 1.32698 4.63803C1.6146 4.07355 2.07355 3.6146 2.63803 3.32698C3.01641 3.13419 3.40963 3.06287 3.80498 3.03057C4.17953 2.99997 4.63434 2.99998 5.16146 3Z" fill={color} />
  </svg>
);

export default CardRefreshSvg;
