import { Button, styled } from '@mui/material';

export const WhiteButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  '&:active': {
    backgroundColor: theme.palette.common.white,
  },
  '&:focus': {
    boxShadow: `0 0 0 0 ${theme.palette.grey[100]}`,
  },
}));
