import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Stack, Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import NoReviewsImage from '../../assets/no_reviews_on_dimension.svg?react';
import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import InformationSvg from '../../../../app/component/icons/InformationSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { Comparator } from '../../reply/comparator/Comparator';
import { Review } from '../../browse/domain/Review';
import { ReviewCard } from '../../browse/allReviews/components/reviewCard/ReviewCard';

interface Props {
  dimension: DimensionAnalysis;
}

const DetectedIssuesTooltip = () => (
  <ThemeTooltip
    title={
      <FormattedMessage
        id="review.guest_experience.dimensions_analysis.selected_dimension.info"
        defaultMessage="Highlights reviews with a comment with a rating of 3 or less on the selected dimension"
      />
    }
    placement="top"
    arrow
  >
    <Stack>
      <InformationSvg color={themeV5.palette.text.secondary} size={16} />
    </Stack>
  </ThemeTooltip>
);

const sortByDate: Comparator<Review> = Comparator.sortingBy((r: Review) => r.creationDate).reverse();

const sorting = sortByDate;

export const DetectedIssues = ({ dimension }: Props) => {
  const theme = useTheme();
  return (
    <Stack direction="column" justifyContent="space-between" gap="1rem">
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          <FormattedMessage id="review.guest_experience.dimensions_analysis.selected_dimension.verbatim" />
        </Typography>
        <DetectedIssuesTooltip />
      </Stack>
      <Divider />
      {dimension.badReviews.length === 0 ? (
        <Stack direction="column" gap={1} alignItems="center">
          <NoReviewsImage />
          <Typography>
            <FormattedMessage
              id="review.guest_experience.dimensions_analysis.no_reviews_for_selected_dimension"
              defaultMessage="No reviews with issues on selected dimension"
            />
          </Typography>
        </Stack>
      ) : (
        sorting
          .sort(dimension.badReviews)
          .map<React.ReactNode>((review) => (
            <ReviewCard key={review.id} review={review} highlightedDimension={dimension.name} />
        ))
          // eslint-disable-next-line react/jsx-key
          .reduce((prev, curr) => [prev, <Divider />, curr])
      )}
    </Stack>
  );
};
