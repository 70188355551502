import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { venueKpiQuery } from '../queries/venueKpiQuery';
import { VenueKpi } from '../domain/VenueKpi';

export const useVenueKpiQuery = (
  businessId: string,
  currency: string,
  timezone: string,
  options?: Omit<UseQueryOptions<VenueKpi>, 'queryKey' | 'queryFn' | 'initialData'>,
) => {
  const query = venueKpiQuery(businessId, currency, timezone);
  return useQuery<VenueKpi>({ queryKey: query.queryKey, queryFn: query.queryFn, ...options });
};
