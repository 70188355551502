import React from 'react';
import styled from 'styled-components';
import Line, { AlignItems } from '../../../common/components/Line';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  notes: string | undefined;
}

const OrderNotesContainer = styled(Line)`
  margin-top: -1px;
  border-top: 1px solid ${colorPalette.grey300};
  border-bottom: 1px solid ${colorPalette.placeholderColor};
  height: ${getSpacing(10)};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.03em;
`;

const OrderNotes: React.FC<Props> = ({ notes }) => {
  if (!notes) return null;
  return <OrderNotesContainer align={AlignItems.CENTER}>{notes}</OrderNotesContainer>;
};

export default OrderNotes;
