import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';
import { MobileNavigationMenu } from './MobileNavigationMenu';
import { SideBarMenu } from '@organisms/Navigation/SideBar';
import { NavigationItem } from 'src/app/navigation/navigationMenu/NavigationItems';

export const NavigationMenu = ({ menuItems }: { menuItems: NavigationItem[] }) => {
  const { isMobileScreen, isTabletScreen } = useMediaBreakpoints();

  if (isMobileScreen || isTabletScreen) {
    return <MobileNavigationMenu menuItems={menuItems} />;
  }

  return <SideBarMenu menuItems={menuItems} />;
};
