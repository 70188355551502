import { PaletteOptions } from '@mui/material';

declare module '@mui/material' {
  interface PaletteOptions {
    menu: {
      color: string;
      hover: string;
      icon: string;
      disabled: string;
      subitems: {
        color: string;
        hover: string;
        selected: string;
        selectedHover: string;
        disabled: string;
      };
    };
  }
}

export const PaletteTheme: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#ff17e9',
    light: '#ffe5fd',
    dark: '#be10ae',
    contrastText: '#000000',
  },
  secondary: {
    main: '#000000',
    light: '#ededf2',
    dark: '#424248',
    contrastText: '#424248',
  },
  background: {
    default: '#f6f6f9',
    paper: '#ffffff',
  },
  text: {
    primary: '#000000',
    secondary: '#70707b',
    disabled: '#b4b4bb',
  },
  menu: {
    color: '#000000',
    hover: 'rgba(221,221,228,0.25)',
    icon: '#70707B',
    disabled: '#b4b4bb',
    subitems: {
      color: '#70707B',
      hover: 'rgba(221,221,228,0.25)',
      selected: '#FFF2FE',
      selectedHover: '#FFE5FD',
      disabled: '#b4b4bb',
    },
  },
  error: {
    main: '#d11023',
    dark: '#a50f1e',
    light: '#FFD1DC',
    contrastText: '#D11023',
  },
  warning: {
    main: '#e0821e',
    dark: '#ad5900',
    light: '#fff4d1',
    contrastText: '#E0821E',
  },
  info: {
    main: '#E6F3FA',
    dark: '#01579b',
    light: '#cdeffe',
    contrastText: '#01579B',
  },
  success: {
    main: '#3FB05E',
    dark: '#336B42',
    light: '#D1FFDD',
    contrastText: '#336B42',
  },
  divider: 'rgba(66,66,72,0.14)',
  grey: {
    100: '#F6F6F9',
    300: '#DDDDE4',
  },
};
