import styled from 'styled-components';

export const ProductNameInfo = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  gap: 5px;
  cursor: pointer;
`;
