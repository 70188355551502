import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { QUERYKEY_PAYMENT_TERMINAL_LIST } from '../queries/listPaymentTerminalsQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const usePaymentTerminalDeletionMutation = (enrollmentId: EnrollmentId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return useMutation({
    mutationFn: (id: string) => repository.delete(enrollmentId, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_PAYMENT_TERMINAL_LIST] });
    },
  });
};
