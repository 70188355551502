import { useGetUnpaidOrders } from '../../orders/hooks';
import React, { useEffect, useState } from 'react';
import { OrderSummary } from '../../orders/types';
import { BoxTitle, Container, NoOrderYetPanel } from './KdsContainer';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { KdsBoxOrderToPayTable } from './KdsBoxOrderToPayTable';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type KdsOrderToBePaidProps = {
  enrollmentId: EnrollmentId;
};

const REFRESH_TIME_IN_MILLI = 10000;   // 10 seconds

const FourHoursAgo = () => {
  return new Date(Date.now() - 4 * 60 * 60 * 1000);
};

export const KdsOrderToBePaid = ({ enrollmentId }: KdsOrderToBePaidProps) => {

  const [orders, updateOrders] = useState<OrderSummary[]>([]);
  const [, getUnpaidOrders] = useGetUnpaidOrders(enrollmentId);

  useEffect(() => {
    getUnpaidOrders(FourHoursAgo(), new Date())
      .then(updateOrders);

    const intervalId = setInterval(() => {
      getUnpaidOrders(FourHoursAgo(), new Date())
        .then(updateOrders);
    }, REFRESH_TIME_IN_MILLI);

    return () => {
      clearInterval(intervalId);
    };

  }, [enrollmentId, getUnpaidOrders]);

  const onOrderPaid = (orderId: string) => {
    updateOrders(orders.filter((order) => order.id !== orderId));
  };

  return (
    <Container>
      <BoxTitle>
        <FormattedMessage id="kds.orders.to-pay" defaultMessage="Orders to pay"/>
      </BoxTitle>

      {orders.length === 0
        ? (<NoOrderYetPanel>
          <FormattedMessage id="kds.no-orders-yet" defaultMessage="No orders yet"/>
        </NoOrderYetPanel>)
        : <KdsBoxOrderToPayTable orders={orders} onOrderPaid={onOrderPaid}/>
      }
    </Container>
  );
};
