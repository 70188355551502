import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { DetectedIssues } from './DetectedIssues';
import selectDimensionImg from '../../assets/select_dimension.png';
import { DateRange } from '../../domain/DateRange';
import { DimensionAnalysis, DimensionsAnalysis as DimensionsAnalysisType } from '../domain/DimensionsAnalysis';
import { DimensionsAnalysisOverview } from './DimensionsAnalysisOverview';
import { dimensionsTrendsViewModel, dimensionsTrendsViewModelForNewLayout } from './DimensionsTrendsViewModel';
import { LoadingOrErrorHOC } from '../../../components/LoadingOrErrorHOC';
import { UseQueryResult } from '@tanstack/react-query';
import { Dimension } from './Dimension';
import { featureFlagOverallSatisfaction } from '../overallSatisfaction/featureFlagOverallSatisfaction';

const Container = styled(Box)`
  border-radius: 1.5rem;
  background: white;
`;

const Header = styled('div')`
  padding: 1.5rem;
`;

const NoDimensionSelected = () => (
  <Stack direction="column" gap={2} alignItems="center" textAlign="center" marginTop="4rem" marginBottom="5rem">
    <img src={selectDimensionImg} alt="select dimension" width="120px" height="120px" />
    <Typography variant="h6">
      <FormattedMessage
        id="review.guest_experience.dimensions_analysis.no_dimension_selected"
        defaultMessage="Select a dimension to see the detected issues"
      />
    </Typography>
  </Stack>
);

export const DimensionsAnalysis = ({
  dateRange,
  dimensionAnalysisQueryResult,
}: {
  dateRange: DateRange, dimensionAnalysisQueryResult: UseQueryResult<DimensionsAnalysisType, unknown>
}) => {
  const [selectedDimension, setSelectedDimension] = React.useState<DimensionAnalysis | undefined>(undefined);
  const shouldDisplayOverallSatisfaction = featureFlagOverallSatisfaction();
  const period = DateRange.fromInstants(
    dateRange.startDateI(),
    dateRange.endDateI(),
  );

  return (
    <LoadingOrErrorHOC
      queryResult={dimensionAnalysisQueryResult}
      intlKeys={{ loading: 'reviews.guest-experience.loading.loading' }}
      onLoaded={(dimensionsAnalysis: DimensionsAnalysisType) => (
        <>
          {shouldDisplayOverallSatisfaction ?
            <Grid container spacing={3}>
              {dimensionsAnalysis.dimensions.map(dimension => {
                return <Grid key={dimension.name} item xs={12} sm={6} md={6}>
                    <Dimension
                      dimension={dimension}
                      viewModel={dimensionsTrendsViewModelForNewLayout(
                        dimensionsAnalysis,
                        period,
                        dimension,
                      )}
                      period={dateRange} />
                  </Grid>;
              },
              )}
            </Grid>
            :
            <Container>
              <Stack direction="column">
                <Header>
                  <Typography>
                    <FormattedMessage
                      id="review.guest_experience.dimensions_analysis.title"
                      defaultMessage="Detected relevant issues"
                    />
                  </Typography>
                </Header>
                <Divider />
                <Stack direction="column" padding="1.5rem" gap={3}>
                  <DimensionsAnalysisOverview
                    dimensions={dimensionsAnalysis.dimensions}
                    selectedDimensionState={[selectedDimension, setSelectedDimension]}
                    dimensionsTrendsViewModel={dimensionsTrendsViewModel(dimensionsAnalysis, DateRange.fromInstants(
                      dateRange.startDateI(),
                      dateRange.endDateI(),
                    ))}
                  />
                  <>
                    {selectedDimension && <DetectedIssues dimension={selectedDimension} />}
                    {!selectedDimension && <NoDimensionSelected />}
                  </>
                </Stack>

              </Stack>
            </Container>
          }
        </>
      )}
    />
  );
};
