import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const CustomNoRowsOverlay = () => (
  <div style={{
    position: 'absolute', width: '100%', textAlign: 'center', top: '100px',
  }}
  >
    <FormattedMessage id="material.grid.no_rows" />
  </div>
);
