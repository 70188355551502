import { Panel } from '@sundayapp/b2b-react-component-library';
import { Box, Input, Switch, Typography } from '@mui/material';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Line } from './GenericPaymentMethodSettingsPanel';
import { ReportSettingsInputs } from './ReportSettingsInputs';

interface Props {
  register: UseFormRegister<ReportSettingsInputs>,
}

export const DocumentationPanel = ({
  register,
}: Props) => {
  const intl = useIntl();

  return (
    <Panel flexDirection="column">
      <Line sx={{ width: '100%', display: 'flex', marginBottom: '15px' }}>
        <Typography variant="h5" marginTop="5px">
          <FormattedMessage id="reconciliation.settings.documentation" />
        </Typography>
        <Switch
          id="documentationVisible"
          {...register('documentationVisible')}
        />
      </Line>

      <Box width="100%">
        <Input
          multiline
          minRows={4}
          fullWidth
          placeholder={intl.formatMessage({ id: 'reconciliation.settings.documentation.placeholder' })}
          {...register('documentationText')}
        />
      </Box>

    </Panel>
  );
};
