import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpSharedDeviceRepository } from '../infrastructure/HttpSharedDeviceRepository';
import { queryKeys } from 'src/app/queries/utils';

type MutationArgs = { token: string };

export const useDeleteSharedDevice = (businessId: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const httpBusinessesRepository = new HttpSharedDeviceRepository(configuration.waiterAppBackendUrl);

  const mutation = useMutation({
    mutationFn: ({ token }: MutationArgs) => httpBusinessesRepository.deleteSharedDevice(token),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.waiters.LIST_SHARED_DEVICES, { businessId }] }),
  });

  return {
    deleteDevice: (args: MutationArgs) => mutation.mutateAsync(args),
  };
};
