import * as React from 'react';
import { useEffect, useState } from 'react';
import { useListPaymentTerminalConfigurations } from '../queries/listPaymentTerminalConfigurationsQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { usePaymentTerminalProvisioningMutation } from '../mutations/usePaymentTerminalProvisioningMutation';
import { PaymentTerminalModal } from './PaymentTerminalModal';

interface Props {
  enrollmentId: EnrollmentId
  isOpen: boolean
  onClose: () => void
}

function AddPaymentTerminalModal({ enrollmentId, isOpen, onClose }: Props) {
  const paymentTerminalConfigurations = useListPaymentTerminalConfigurations(enrollmentId).data;
  const [paymentTerminalName, setPaymentTerminalName] = useState<string>('');
  const [paymentTerminalConfiguration, setPaymentTerminalConfiguration] = useState<string>('');
  const provision = usePaymentTerminalProvisioningMutation(enrollmentId);

  useEffect(() => {
    if (paymentTerminalConfigurations !== undefined) {
      setPaymentTerminalConfiguration(paymentTerminalConfigurations[0].id!!);
    }
  }, [isOpen]);

  const doProvision = () => {
    provision.mutateAsync({
      name: paymentTerminalName,
      configurationId: paymentTerminalConfiguration,
    }).then(() => onClose());
  };

  return (
    <PaymentTerminalModal terminalName={paymentTerminalName}
                          setPaymentTerminalName={setPaymentTerminalName}
                          paymentTerminalConfigurations={paymentTerminalConfigurations!!}
                          setPaymentTerminalConfiguration={setPaymentTerminalConfiguration}
                          paymentTerminalConfiguration={paymentTerminalConfiguration}
                          onClose={onClose}
                          isOpen={isOpen}
                          action={doProvision} />
  );
}

export default AddPaymentTerminalModal;
