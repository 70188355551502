import { styled } from '@mui/material';

type UserIconProps = {
  userName: string;
};

type UserImageProps = {
  hue: number;
};

const UserImg = styled('div')<UserImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  width: 32px;
  min-height: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: hsl(${(props) => props.hue} 80% 50%);
  border: 1px solid rgb(0,0,0,0.1) ;
`;

const computeHueFromUserName = (userName: string): number => {
  let value = 0;
  for (let i = 0, sz = userName.length; i < sz; i += 1) {
    value += userName.charCodeAt(i);
  }
  return value % 360;
};

export const UserIcon = ({ userName }: UserIconProps) => <UserImg hue={computeHueFromUserName(userName)}>{userName[0]}</UserImg>;
