import { WhiteLabelCustomizationRepository } from 'src/domain/customization/WhiteLabelCustomizationRepository';
import { Business } from 'src/business/domain/Business';
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';

export class ApplyColorCustomizationChanges {
  constructor(private whiteLabelCustomizationRepository: WhiteLabelCustomizationRepository) {}

  async apply(sourceBusiness: Business, targetBusinesses: Array<Business>) {
    const sourceCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      sourceBusiness.patEnrollment!.id,
    );
    for (const business of targetBusinesses) {
      await this.copyColorCustomization(sourceCustomization, business);
    }
  }

  private async copyColorCustomization(sourceCustomization: WhiteLabelCustomization, business: Business) {
    const currentCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      business.patEnrollment!.id,
    );
    const updatedCustomization: WhiteLabelCustomization = {
      ...currentCustomization,
      color: sourceCustomization.color,
    };
    await this.whiteLabelCustomizationRepository.saveCustomization(business.patEnrollment!.id, updatedCustomization);
  }
}
