import styled from 'styled-components';
import React, { useCallback, useMemo, useState } from 'react';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { getFulfillmentMethodLabel } from '../../../formatting';
import IconButton from '../../../../common/components/IconButton';
import { EditIcon, PlusIcon } from '../../../../common/components/Icons';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { areaConfigurationMenuId, AreaDetailsServicePanel } from './AreaDetailsServicePanel';
import { Area } from '../../../domain/Area';
import { ServiceMenu } from '../../AreaListSettings/components/ServiceMenu';
import { BusinessId } from 'src/business/domain/Business';

type AreaDetailsServiceProps = {
  businessId: BusinessId;
  area: Area;
  setArea: (area: Area) => void;
  service: FulfillmentType;
  menus: OrderingMenu[];
  menuIds: string[];
  disabledMessage: string | undefined;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  padding: ${getSpacing(3)};
  padding-bottom: ${getSpacing(1)};
  margin-bottom ${getSpacing(2)};
`;

type HeaderProps = {
  disabled: boolean;
};

const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${(props) => (props.disabled ? '' : 'pointer')};
  margin-bottom: ${getSpacing(2)};
`;

type ServiceNameProps = {
  disabled: boolean;
};

const ServiceName = styled.div<ServiceNameProps>`
  font-size: 21px;
  line-height: 22px;
  color: ${(props) => (props.disabled ? colorPalette.grey400 : '')};
`;

const ServiceMenuWrapper = styled.div`
  margin-bottom: ${getSpacing(2)};
`;

const DisabledSpan = styled.span`
  font-size: 12px;
  line-height: 14px;
  color: ${colorPalette.grey400};
`;

export const AreaDetailsService = ({
  businessId,
  area,
  setArea,
  service,
  menus,
  menuIds,
  disabledMessage,
}: AreaDetailsServiceProps) => {
  const [isPanelOpened, openPanel] = useState<boolean>(false);

  const onClosePanel = useCallback(() => {
    setTimeout(() => {
      openPanel(false);
    }, 0);
  }, [openPanel]);

  const onActive = useCallback(() => {
    if (disabledMessage) {
      return;
    }
    openPanel(true);
  }, [disabledMessage]);

  const actionButton = useMemo(() => {
    if (disabledMessage) {
      return <></>;
    }

    if (menuIds.length > 0) {
      return <IconButton Icon={EditIcon} onClick={onActive} disabled={!!disabledMessage} />;
    }

    return <IconButton Icon={PlusIcon} onClick={onActive} disabled={!!disabledMessage} />;
  }, [disabledMessage, menuIds, onActive]);

  const serviceMenus = useMemo(
    () => menuIds
      .map((menuId) => menus.find((menu) => areaConfigurationMenuId(menu) === menuId))
      .filter((menu) => menu !== undefined),
    [menuIds, menus],
  );

  return (
    <Container onClick={onActive}>
      <Header disabled={!!disabledMessage}>
        <ServiceName disabled={!!disabledMessage}>{getFulfillmentMethodLabel(service)}</ServiceName>
        <DisabledSpan>{disabledMessage}</DisabledSpan>
        {actionButton}
      </Header>
      {serviceMenus.length > 0
        && serviceMenus.map((menu) => (
          <ServiceMenuWrapper key={menu!.id}>
            <ServiceMenu menu={menu!} />
          </ServiceMenuWrapper>
        ))}

      {isPanelOpened && (
        <AreaDetailsServicePanel
          businessId={businessId}
          area={area}
          setArea={setArea}
          service={service}
          menus={menus}
          menuIds={menuIds}
          isOpened
          onClosed={onClosePanel}
        />
      )}
    </Container>
  );
};
