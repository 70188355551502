import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Card, CardContent, CircularProgress, Switch, Typography } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { LineWrapper } from 'src/pages/Settings/AppSettings/AppSettings.styles';

interface ConsentCollectionToggleProps {
  toggleActivated: boolean;
  toggleConsentCollection: (active: boolean) => void;
  processing: boolean;
}

export const ConsentCollectionToggle = ({
  toggleActivated,
  toggleConsentCollection,
  processing,
}: ConsentCollectionToggleProps) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();

  const onSwitchChange = async (activeGuestDataCollection: boolean) => {
    try {
      toggleConsentCollection(activeGuestDataCollection);
      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.consent.success_saving' }),
      });
    } catch (e) {
      useSnackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage id="settings.consent.title" />
        </Typography>
        {processing
          ? (<CircularProgress color="secondary" />)
          : (<LineWrapper>
              <Typography variant={'body1'}>
                <FormattedMessage id="settings.consent.toggle" />
              </Typography>
              <Switch onChange={(e) => onSwitchChange(e.target.checked)} checked={toggleActivated} />
            </LineWrapper>
          )}
      </CardContent>
    </Card>
  );
};
