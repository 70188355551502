import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuBusinessRepositoryHttp } from '../repositories/MenuBusinessRepositoryHttp';
import { QUERYKEY_MENU_VENUE } from '../queries/getMenuBusinessQuery';
import { RemoveLanguageDto } from '../repositories/RemoveLanguageDto';
import { BusinessId } from 'src/business/domain/Business';

export const useRemoveLanguageMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuBusinessRepositoryHttp = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (request: RemoveLanguageDto) => menuBusinessRepositoryHttp.removeLanguage(businessId, request.language),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_VENUE] });
    },
  });
};
