import { CardContent, CardWrapper, FigureItemLabel, StatsCardChartBox, StatsCardFooterBox, StatsLabelsBox, StatsLabelsItem } from '@styles/Card/StyledCard';
import { PageStatsCard, StatsChartLabelProps, StatsContent } from 'src/home2/types/layout_types';
import { StyledToggleButton } from '@atoms/ToggleButton/ToggleButton';
import { Box, Typography } from '@mui/material';
import { useGetTrendValue } from '@hooks/useGetTrendValue';
import { StatTrend } from '@atoms/StatTrend/StatTrend';
import { CircleSvg } from 'src/app/component/icons/CircleSvg';
import { CircleLineSvg } from 'src/app/component/icons/CircleLineSvg';
import { LineStroke } from 'src/app/component/icons/LineStrokeSvg';

const textToIcon = (text: string, color: string) => {
  switch (text) {
    case 'circle':
      return <CircleSvg color={color} />;
    case 'circleLine':
      return <CircleLineSvg color={color} />;
    case 'lineStroke':
      return <LineStroke color={color} />;
    default:
      return <></>;
  }
};

export const StatsLabels = (props: StatsChartLabelProps & StatsContent) => {
  const {
    trend,
    isPercentageTrend,
  } = props;

  const { trendValue } = useGetTrendValue({
    trend,
    isPercentageTrend,
    hasTrend: trend !== 0,
  });

  if (!props.subItems) return null;
  
  return (
    <StatsLabelsBox>
      {props.subItems && props.subItems.map((subItem) => (
        <StatsLabelsItem key={subItem.label} isCentered={subItem.isCentered} order={subItem.order}>
          <FigureItemLabel>
            {subItem.icon && subItem.color && (
              textToIcon(subItem.icon, subItem.color)
            )}
            <Typography variant="body1">{subItem.label}</Typography>
          </FigureItemLabel>
          <Box>
            <Typography variant="h5">{subItem.value}</Typography>
          </Box>
          {trendValue && (
            <Box>
              <StatTrend
                trend={trend >= 0 ? 'UP' : 'DOWN'}
                value={trendValue}
                label="venue.home.compare-last-week"
              />
            </Box>
          )}
        </StatsLabelsItem>
      ))}
    </StatsLabelsBox>
  );
};

export const StatsCard = (statsCardProps: PageStatsCard<any>) => {
  return (
    <CardWrapper>
      <CardContent>
        {statsCardProps.actions && statsCardProps.actions.toggleButtons && statsCardProps.actions.toggleSettings &&
          <StyledToggleButton
            listButtons={statsCardProps.actions.toggleButtons}
            {...statsCardProps.actions.toggleSettings}
          />
        }
        {statsCardProps.header}
        {statsCardProps.chart && statsCardProps.content && <StatsLabels {...statsCardProps.chart} {...statsCardProps.content} />}
        <StatsCardChartBox>
          {statsCardProps.chart.element}
        </StatsCardChartBox>
        <StatsCardFooterBox>{statsCardProps.footer}</StatsCardFooterBox>
      </CardContent>
    </CardWrapper>
  );
};
