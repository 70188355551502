import { useBusinessId } from './useBusinessId';
import { datadogLogs } from '@datadog/browser-logs';
import { useBusinessQuery } from './useBusinessQuery';
import { Business, BusinessId } from '../domain/Business';

export const useCurrentBusinessOrThrow = (): Business => {
  const businessId = useBusinessId();
  const { data: business, isError, error } = useBusinessQuery(businessId as BusinessId);

  if (!business || isError) {
    datadogLogs.logger.error('Business has failed to be fetched', { venue: business, error });
    throw new Error('Business has failed to be fetched');
  }

  return business;
};
