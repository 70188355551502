import { useReviewRepository } from '../../browse/infrastructure/configuration/ReviewContext.hook';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { reply, Review } from '../../browse/domain/Review';
import { Reviews } from '../../browse/domain/Reviews';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useReplyToReview = () => {
  const reviewRepository = useReviewRepository();
  const business = useCurrentBusinessOrThrow();
  const user = useAuthenticatedUserOrThrow();
  return (review: Review, replyContent: string): Promise<Review> =>
    reviewRepository
      .replyToReview(business.id, review.id!, replyContent, review.platform, user.email)
      .then(() => reply(replyContent)(review));
};

export const useReplyToReviewFromReviews = () => {
  const singleReplyToReview = useReplyToReview();
  return (review: Review, replyContent: string, reviews: Reviews): Promise<Reviews> =>
    singleReplyToReview(review, replyContent).then((repliedReview) =>
      reviews.replyToReview(repliedReview.id!, repliedReview.reply),
    );
};
