const download = (blob: Blob, invoiceName: string, extension: string) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${invoiceName}.${extension}`;
  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );

  document.body.removeChild(link);
};

export const downloadInvoiceDocument = (blob: Blob, invoiceName: string) => {
  return download(blob, invoiceName, 'pdf');
};

export const downloadInvoiceDocuments = (blob: Blob, invoiceName: string) => {
  return download(blob, invoiceName, 'zip');
};
