import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuBusinessRepositoryHttp } from '../repositories/MenuBusinessRepositoryHttp';
import { QUERYKEY_MENU_VENUE } from '../queries/getMenuBusinessQuery';
import { QUERYKEY_MENU_DYNAMIC_MENU_ALLOWED } from '../queries/getDynamicMenuAllowedQuery';
import { CreateOrUpdateMenuBusinessDto } from '../repositories/CreateOrUpdateMenuBusinessDto';
import { BusinessId } from 'src/business/domain/Business';
import { UpdateMenuBusinessDto } from '../repositories/UpdateMenuBusinessDto';

export const useBusinessMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const businessMenuRepository = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (createOrUpdateMenuBusinessDto: CreateOrUpdateMenuBusinessDto) => businessMenuRepository.createOrUpdateBusiness(businessId, createOrUpdateMenuBusinessDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_VENUE] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_DYNAMIC_MENU_ALLOWED] });
    },
  });
};

export const useUpdateBusinessMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const businessMenuRepository = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (updateMenuBusinessDto: UpdateMenuBusinessDto) => businessMenuRepository.updateBusiness(businessId, updateMenuBusinessDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_VENUE, businessId] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_DYNAMIC_MENU_ALLOWED, businessId] });
    },
  });
};
