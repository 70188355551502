/* eslint-disable max-len */
import { SvgIcon } from '@mui/material';
import React from 'react';

export const DateIcon = () => (
  <SvgIcon
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: 25,
      height: 25,
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11ZM11 4C11.5523 4 12 4.44772 12 5V10.382L15.4472 12.1056C15.9412 12.3526 16.1414 12.9532 15.8944 13.4472C15.6474 13.9412 15.0468 14.1414 14.5528 13.8944L10.5528 11.8944C10.214 11.725 10 11.3788 10 11V5C10 4.44772 10.4477 4 11 4Z"
      fill="#70707B"
    />

  </SvgIcon>
);
