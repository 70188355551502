// @ts-ignore

export const TabsTheme = {
  styleOverrides: {
    root: {
      '& .MuiTab-root': {
        border: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: '32px',
        minWidth: 'unset',
        fontSize: '22px',
        color: '#000',
        opacity: 0.2,
        '@media (max-width: 480px)': {
          fontSize: '16px',
          minHeight: '48px',

        },
      },
      '& .Mui-selected': {
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: '32px',
        color: '#000 !important',
        opacity: 1.0,
      },
      '& .MuiTabs-indicator': {
        backgroundColor: '#000',
      },
    },
  },
};
