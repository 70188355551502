import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const mapDayjsDate = ({ date, format }: {
  date: string | undefined, format: string
}) => {
  if (!date) {
    return undefined;
  }
  dayjs.extend(customParseFormat);
  return dayjs(date, format);
};
