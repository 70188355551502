import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { ChipProps } from '@mui/material';
import { PaymentLink, PaymentLinkStatus, paymentLinkStatuses } from './PaymentLink';
import { LocalisationKey } from 'src/lang/en';

export const paymentLinkDisplayableStatus = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
  DROPPED: 'DROPPED',
} as const;

type PaymentLinkDisplayableStatus = ValuesOf<typeof paymentLinkDisplayableStatus>;

export const displayableStatusToChipColor: { [key in PaymentLinkDisplayableStatus]: ChipProps['color'] } = {
  [paymentLinkDisplayableStatus.PAID]: 'success',
  [paymentLinkDisplayableStatus.UNPAID]: 'warning',
  [paymentLinkDisplayableStatus.DROPPED]: 'secondary',
};

export const paymentLinkStatusesToDisplayableStatus: { [key in PaymentLinkStatus]: PaymentLinkDisplayableStatus } = {
  [paymentLinkStatuses.CREATED]: paymentLinkDisplayableStatus.UNPAID,
  [paymentLinkStatuses.IN_PROGRESS]: paymentLinkDisplayableStatus.UNPAID,
  [paymentLinkStatuses.READY]: paymentLinkDisplayableStatus.UNPAID,
  [paymentLinkStatuses.DONE]: paymentLinkDisplayableStatus.PAID,
  [paymentLinkStatuses.DROPPED]: paymentLinkDisplayableStatus.DROPPED,
} as const;


export const displayableStatusTranslationKey = (displayableStatus: PaymentLinkDisplayableStatus): LocalisationKey => `payment_link.table.status.${displayableStatus}`;

export const statusTranslationKey = (paymentLink: PaymentLink) => {
  const displayableStatus = paymentLinkStatusesToDisplayableStatus[paymentLink.status];
  return displayableStatusTranslationKey(displayableStatus);
};
