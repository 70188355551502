import { z } from 'zod';


export const SharedDevice = z.object({
  businessId: z.string(),
  deviceToken: z.string(),
  appVersion: z.string(),
  authenticationToken: z.string(),
  isSharedDevice: z.boolean(),
  authenticationExpirationDate: z.string().transform( arg => new Date( arg ) ),
  deviceName: z.string(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SharedDevice = z.infer<typeof SharedDevice>;
