import { useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { HomeAction } from './HomeAction';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { venueAccountingInvoicesPath } from 'src/app/navigation/pathHelpers';
import ValidatedDocumentSvg from '../../../app/component/icons/ValidatedDocumentSvg';
import { Business } from 'src/business/domain/Business';

type HomeActionReportingProps = {
  business: Business;
};

export const HomeActionInvoice = ({ business }: HomeActionReportingProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <HomeAction
      icon={<ValidatedDocumentSvg size={20} color={themeV5.palette.text.secondary} />}
      title={intl.formatMessage({ id: 'venue.home.actions.download.invoices' })}
      onClick={() => {
        navigate(venueAccountingInvoicesPath(business.id));
      }}
    />
  );
};
