import React from 'react';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { userRoles } from 'src/domain/user/UserRole';
import { VenueAndBoxSettings } from 'src/ordering/venues/pages';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsPageContainer } from '../components/SettingsPageContainer';


const OrderingInformation = () => {
  const business = useCurrentBusinessOrThrow();
  return (
    <SettingsPageContainer>
      <VenueAndBoxSettings enrollment={business.oapEnrollment!} />
    </SettingsPageContainer>
  );
};

export default withRestrictedAccess([userRoles.ADMIN], OrderingInformation);
