import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Product } from '../../../domain/Product';
import { UpdateProductDto } from '../../../repositories/ProductDto';

type Props = {
  product: Product,
  productUpdate: UpdateProductDto,
  setProductUpdate: (productDto: UpdateProductDto) => void
};
export const ProductCalories = ({
  product, productUpdate, setProductUpdate,
}: Props) => {
  const intl = useIntl();

  const [value, setValue] = useState(product.calories);

  useLayoutEffect(() => {
    setValue(product.calories);
  }, [product.calories]);

  const onUpdateValue = (newValue: number) => {
    let newProductDto = productUpdate;
    if (newValue !== product.calories) {
      newProductDto = {
        ...newProductDto,
        calories: newValue,
      };
    } else {
      newProductDto = {
        ...newProductDto,
        calories: undefined,
      };
    }
    setProductUpdate(newProductDto);
    setValue(newValue);
  };

  return (
    <TextField
      variant="outlined"
      size="medium"
      label={intl.formatMessage({ id: 'menus.product.details.calories' })}
      sx={{ width: 250 }}
      type="number"
      value={value > 0 ? value : 0}
      onChange={(e) => onUpdateValue(parseInt(e.target.value, 10))}
      InputProps={{
        endAdornment: <InputAdornment position="end"><FormattedMessage
          id="menus.product.details.calories.unit" /></InputAdornment>,
      }}
    />

  );
};
