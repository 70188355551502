import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import OpeningHourForDay from './OpeningHourForDay';
import { ALL_DAYS_OF_WEEK, DayOfWeek, TimePeriod } from '../../domain/TimePeriod';
import { RoundWarningIcon, WarningContainer, WarningMessage } from './OpeningHours.style';

interface Props {
  openingHours: TimePeriod[];
  updateOpeningHours: (update: TimePeriod[]) => void;
  customWarningMessage?: ReactNode;
}

const sortTimePeriodByStartDate = (tp1: TimePeriod, tp2: TimePeriod) => {
  if (tp1.weekDay !== tp2.weekDay) return tp1.weekDay - tp2.weekDay;
  if (tp1.startTime < tp2.startTime) {
    return -1;
  }
  if (tp1.startTime > tp2.startTime) {
    return 1;
  }
  return 0;
};

export const OpeningHoursPanel: React.FC<Props> = ({ openingHours, updateOpeningHours, customWarningMessage }) => {
  const updateOpeningHoursForDay = useCallback(
    (day: DayOfWeek, openingHoursForDay: TimePeriod[]) => {
      const newOpeningHours = [
        ...openingHours.filter((timePeriod) => timePeriod.weekDay !== day),
        ...openingHoursForDay,
      ].sort(sortTimePeriodByStartDate);
      updateOpeningHours(newOpeningHours);
    },
    [updateOpeningHours, openingHours],
  );
  const renderOpeningHoursForDay = useCallback(
    (day: DayOfWeek) => (
      <OpeningHourForDay
        key={day}
        day={day}
        openingHours={openingHours}
        updateOpeningHoursForDay={updateOpeningHoursForDay}
      />
    ),
    [updateOpeningHoursForDay, openingHours],
  );
  return (
    <div>
      {openingHours.length === 0 && (
        <WarningContainer>
          <RoundWarningIcon>!</RoundWarningIcon>
          <WarningMessage>
            {customWarningMessage || (
              <FormattedMessage
                id="venue.noOpeningDaysSoAlwaysOpen"
                defaultMessage="no opening days have been selected, the restaurant is considered as always open"
              />
            )}
          </WarningMessage>
        </WarningContainer>
      )}
      <div>{ALL_DAYS_OF_WEEK.map(renderOpeningHoursForDay)}</div>
    </div>
  );
};
