import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Line, { AlignItems } from '../../../common/components/Line';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  cutleryNumber: number | undefined;
}

const CutleryLine = styled(Line)`
  border-top: 1px solid ${colorPalette.placeholderColor};
  border-bottom: 1px solid ${colorPalette.grey300};
  height: ${getSpacing(10)};
  font-size: 18px;
  line-height: 22px;
  letter-spacing: -0.03em;
`;

const OrderCutlery: React.FC<Props> = ({ cutleryNumber }) => {
  if (!cutleryNumber) return null;
  return (
    <CutleryLine align={AlignItems.CENTER}>
      <FormattedMessage id="order.utensilsRequested" defaultMessage="utensils requested" />
      <span>{cutleryNumber || 0}</span>
    </CutleryLine>
  );
};

export default OrderCutlery;
