import { HttpGoogleSetup } from './HttpGoogleSetup';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GoogleBusinessLocation } from 'src/sundayplus/reviews/settings/setup/google/domain/GoogleBusinessLocation';

const configuration = ConfigurationLoader.load();
const googleSetupRepository = new HttpGoogleSetup(configuration.venueFeedbackBaseUrl);

export const useGoogleSetupLocation = (businessId: BusinessId) => {
  return useQuery(({
    queryKey: queryKeys.reviewsSetup.googleLocation(businessId),
    queryFn: (): Promise<GoogleBusinessLocation | null> => {
      return googleSetupRepository.getBusinessLocation(businessId);
    },
    staleTime: 60 * 1000,
  }));
};

export const useSelectGoogleLocationMutation = (businessId: BusinessId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (location: GoogleBusinessLocation) => googleSetupRepository.selectLocation(businessId, location),
    onSuccess: () => {
      return queryClient.invalidateQueries({ queryKey: queryKeys.reviewsSetup.all(businessId) });
    },
  });
};
