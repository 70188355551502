import { useState } from 'react';
import { ConfigurationReplicationState } from './domain/ConfigurationReplicationState';
import { SelectConfigurationsStep } from './components/SelectConfigurationsStep';
import { SelectSourceBusinessStep } from './components/SelectSourceBusinessStep';
import { SelectTargetBusinessesStep } from './components/SelectTargetBusinessesStep';
import { ReviewAndApplyChangesStep } from './components/ReviewAndApplyChangesStep';
import { IntroduceFlowStep } from './components/IntroduceFlowStep';
import { Card, CardHeader, Stack } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { IntroduceMenuStep } from './components/menu/IntroduceMenuStep';
import { SelectMenuMigrationStep } from './components/menu/SelectMenuMigrationStep';
import { MenuReplicationState } from './domain/menu/MenuReplicationState';
import { ReviewMenuMigrationStep } from './components/menu/ReviewMenuMigrationStep';

export const ConfigurationReplication = () => {

  const [state, setState] = useState<ConfigurationReplicationState>({
    step: 'introduce flow',
  });
  const [menuState, setMenuState] = useState<MenuReplicationState>({
    step: 'introduce flow',
  });

  const setNextState = (nextState: ConfigurationReplicationState) => setState(nextState);
  const setNextMenuState = (nextState: MenuReplicationState) => setMenuState(nextState);

  const intl = useIntl();

  return (
    <Stack gap="1rem">
      {menuState.step === 'introduce flow' &&
        <Card>
          <CardHeader title={intl.formatMessage({ id: 'configuration_replication.title' })} />
          {state.step === 'introduce flow' && <IntroduceFlowStep onNext={setNextState} />}
          {state.step === 'select source business' && <SelectSourceBusinessStep onNext={setNextState} />}
          {state.step === 'select configurations' && <SelectConfigurationsStep currentState={state} onNext={setNextState} />}
          {state.step === 'select target businesses' && <SelectTargetBusinessesStep currentState={state} onNext={setNextState} />}
          {state.step === 'review and apply changes' && <ReviewAndApplyChangesStep currentState={state} />}
        </Card>
      }
      {state.step === 'introduce flow' &&
        <Card>
          {menuState.step === 'introduce flow' && <IntroduceMenuStep onNext={setNextMenuState} />}
          {menuState.step === 'select menu migration' && <SelectMenuMigrationStep currentState={menuState} onNext={setNextMenuState} />}
          {menuState.step === 'menu review' && <ReviewMenuMigrationStep currentState={menuState} onEdit={setNextMenuState} />}
        </Card>
      }
    </Stack>
  );
};
