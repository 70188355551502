import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { language } from 'src/app/i18n/I18nProvider';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsEnterpriseDashboard = () => {
  // useEffect(() => {
  //   ampli.dataAnalyticsCompareYourBusinessClicked({
  //     businessId: business.id,
  //     businessName: business.name,
  //     clickDate: new Date().toISOString(),
  //   });
  // }, []);

  return (
    <DataAnalyticsIframeComponent
      dashboard={
        language() === 'fr'
          ? MetabaseDashboard.ENTERPRISE_DASHBOARD_FRENCH
          : MetabaseDashboard.ENTERPRISE_DASHBOARD_ENGLISH
      }
    />
  );
};
