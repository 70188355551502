import { BusinessUser } from 'src/domain/user/BusinessUser';

export const capitalizeFirstLetter = (string: string | undefined) => {
  if (!string) return string;
  return string.charAt(0)
    .toUpperCase() + string.slice(1);
};
export const chooseUserIdentifier = (user: BusinessUser) => {
  if (user.firstName && user.lastName) return `${capitalizeFirstLetter(user.firstName)} ${user.lastName.toUpperCase()}`;
  if (user.phoneNumber) return user.phoneNumber;
  return user.email;
};
