import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { AppDesignUseCase } from 'src/domain/venue/AppDesignUseCase';
import { BlurHashService } from 'src/domain/venue/BlurHashService';
import {
  useDocumentRepositoryFirebaseStorage,
} from 'src/infrastructure/document/DocumentRepositoryFirebaseStorage';
import { CropPictureData } from '../Cropping/CropTool';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomization } from '../../infrastructure/useCustomization';
import { useCustomizationUpdater } from '../../infrastructure/useCustomizationUpdater';

export function useCoverUseCase(enrollmentId: EnrollmentId) {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const documentRepositoryStorage = useDocumentRepositoryFirebaseStorage();
  const { data: customization } = useCustomization(enrollmentId);
  const customizationUpdater = useCustomizationUpdater();
  const designUseCase = useMemo(
    () =>
      new AppDesignUseCase(
        customizationUpdater,
        documentRepositoryStorage,
        enrollmentId,
        customization,
        new BlurHashService(),
      ),
    [customizationUpdater, documentRepositoryStorage, enrollmentId, customization],
  );
  const [tempCoverPicture, setTempCoverPicture] = useState('');

  const saveCoverBackgroundColor = async (color: string) => {
    await designUseCase.saveVenueCoverBackgroundColor(color);
    useSnackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'settings.app_settings.cover.background.success' }),
    });
  };

  const deleteCoverBackgroundColor = async () => {
    await designUseCase.saveVenueCoverBackgroundColor('');
  };

  const deleteCovers = async () => {
    setLoading(true);
    await designUseCase.deleteCovers();
    setLoading(false);
  };

  const editCoverPicture = useCallback(() => {
    if (customization?.cover?.original) {
      setTempCoverPicture(customization.cover.original);
    }
  }, [customization]);

  const uploadCover = async (file: File) => {
    try {
      const downloadLink = await designUseCase.uploadCoverPicture(enrollmentId, file);
      setTempCoverPicture(downloadLink);
    } catch (e) {
      useSnackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.cover.upload.error' }),
      });
    }
  };

  const removeTempCoverPicture = () => {
    setTempCoverPicture('');
  };

  const saveCroppedPicture = async (crop: CropPictureData) => {
    setLoading(true);
    setTempCoverPicture('');

    try {
      await designUseCase.saveVenueCoverPicture(crop);

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.cover.picture.success' }),
      });
    } catch (e) {
      useSnackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.cover.picture.error.unknown' }),
      });
    }

    setLoading(false);
  };
  return {
    coverBackgroundColor: customization ? customization?.cover?.background ?? '' : '',
    coverUrl: customization ? customization?.cover?.raw ?? '' : '',
    deleteCovers,
    uploadCover,
    saveCoverBackgroundColor,
    tempCoverPicture,
    removeTempCoverPicture,
    saveCroppedPicture,
    deleteCoverBackgroundColor,
    editCoverPicture,
    loading,
  };
}
