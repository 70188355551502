import React from 'react';
import { AreaListSettings } from 'src/ordering/area/pages';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsPageContainer } from '../components/SettingsPageContainer';


export const AreaSettings = () => {
  const business = useCurrentBusinessOrThrow();
  return (
    <SettingsPageContainer>
      <AreaListSettings businessId={business.id} enrollmentId={business.oapEnrollment!.id} />
    </SettingsPageContainer>
  );
};
