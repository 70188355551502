import { euros, Money } from '@sundayapp/web-money';
import { v4 } from 'uuid';
import { Bill, Tab } from './Bill';
import { BusinessId } from 'src/business/domain/Business';

export const billId = v4();
export const orderId = v4();
export const venueId = v4();
export const businessId = v4() as BusinessId;
export const tableId = v4();
export const subOrderId = v4();
export const splitId = v4();
export const paymentId = v4();
export const guestId = v4();
export const sundayUserId = v4();
export const a42euros = euros(42_00_000);
export const billSplitAmount = euros(12_00_000);
export const issuedAt = new Date('2022-09-22T16:28:22.318Z');
export const engagedAt = new Date('2022-09-22T16:29:47.632Z');
export const paidAt = new Date('2022-09-22T16:29:51.093Z');
export const staffName = 'George Abitbol';

type PropertySetter = (bill: Bill) => Bill;

export function a42EurosBill(...properties: PropertySetter[]): Bill {
  const bill: Bill = {
    billId,
    issuedAt,
    totalBillAmount: a42euros,
    order: {
      orderId,
      businessId : businessId,
      tableId,
      staffName,
    },
    leftToPay: a42euros,
    sundayBillSplits: [],
  };

  const applyProperties = properties.reduce(
    (previous, current) => (b) => current(previous(b)),
    (a) => a,
  );
  return applyProperties(bill);
}
export function withId(id: string): PropertySetter {
  return (bill) => ({
    ...bill,
    billId: id,
  });
}
export function withLeftToPay(amount: Money): PropertySetter {
  return (bill) => ({
    ...bill,
    leftToPay: amount,
  });
}

export function withTab(tab: Tab): PropertySetter {
  return (bill) => ({
    ...bill,
    tab: tab,
  });
}
