import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { SIDE_PANEL_WIDTH } from '../../pages/PromoCodesList/PromoCodesList.style';
import { colorPalette, getSpacing } from '../../../stylesheet';

export const PromoCodeButtonContainer = styled.div`
  width: ${SIDE_PANEL_WIDTH}px;
  position: fixed;
  bottom: 0;
  background-color: ${colorPalette.defaultBackgroundColor};
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 24px 24px 0 0;
`;

export const PromoCodeButtonLine = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

export const PromoCodeActionButton = styled(Button)`
  width: auto;
  margin: ${getSpacing(1)} ${getSpacing(2)};
  opacity: 1;
`;
