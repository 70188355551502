import { Typography, useTheme } from '@mui/material';
import React from 'react';


type ReadonlyFieldWithLabelProps = { label: React.ReactNode, value: string | undefined };
export const ReadonlyFieldWithLabel = ({ label, value }: ReadonlyFieldWithLabelProps) => {
  const theme = useTheme();
  return <div>
    <Typography variant={'caption'} color={theme.palette.text.secondary}>
      {label}
    </Typography>
    <Typography>
      {value}
    </Typography>
  </div>;
};
