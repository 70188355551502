import { Product, productTypes } from 'src/menu/dynamic-menu/domain/Product';
import { Box, Button, Typography } from '@mui/material';
import { CloseOutlined, DeleteOutline, LocalMallOutlined, Snooze } from '@mui/icons-material';
import React, { FC } from 'react';

import {
  useBulkAllergensProductsMutation,
  useBulkDisableProductsForOrderingMutation,
  useBulkEnableProductsForOrderingMutation,
  useBulkLinkRelatedProducts,
  useBulkSnoozeProductsMutation,
  useBulkTagsProductsMutation,
  useBulkUnsnoozeProductsMutation,
} from 'src/menu/dynamic-menu/mutations/product/useBulkProductsMutation';
import { BusinessId } from 'src/business/domain/Business';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useBulkDeleteProducts } from 'src/menu/dynamic-menu/mutations/product/useBulkDeleteProducts';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MenuBusiness } from 'src/menu/common/domain/MenuBusiness';
import {
  AppOrderingProductsModal,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/AppOrderingProductsModal';
import {
  SnoozeProductsModal,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/SnoozeProductsModal';
import {
  DeleteProductsModal,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/DeleteProductsModal';
import { ActionsDots } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/MultiSelectActionsDots';
import { TagsDrawer } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/TagsDrawer';
import { AllergensDrawer } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/AllergensDrawer';
import {
  LinkRelatedProductsDrawer,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/LinkRelatedProductsDrawer';

type Props = {
  multiSelectedProducts: Product[],
  onSelectedProductsChanged: (selectedProducts: Product[]) => void,
  businessId: BusinessId,
  menuProductElementType: 'MODIFIER' | 'PRODUCT',
  menuBusiness: MenuBusiness | null | undefined
};

export type AppOrderingAction = 'enableAppOrdering' | 'disableAppOrdering';
export type SnoozeAction = 'snooze' | 'unsnooze';
export type DotsAction = 'editTags' | 'editAllergens' | 'editRelatedProducts';
export type ProductsBulkAction = 'delete' | SnoozeAction | AppOrderingAction | DotsAction;

export const MultiSelectProductsToolbar: FC<Props> = ({
  multiSelectedProducts,
  onSelectedProductsChanged,
  businessId,
  menuProductElementType,
  menuBusiness,
}) => {

  const intl = useIntl();
  const snackbar = useSnackbar();
  const isModifierMenu = menuProductElementType === productTypes.MODIFIER;

  const [selectedAction, setSelectedAction] = React.useState<ProductsBulkAction | undefined>(undefined);
  const { deleteBulkProducts, currentTaskStatus } = useBulkDeleteProducts(businessId);
  const snoozeProductsMutation = useBulkSnoozeProductsMutation(businessId);
  const unsnoozeProductsMutation = useBulkUnsnoozeProductsMutation(businessId);
  const enableProductsForOrderingMutation = useBulkEnableProductsForOrderingMutation(businessId);
  const disableProductsForOrderingMutation = useBulkDisableProductsForOrderingMutation(businessId);
  const editTags = useBulkTagsProductsMutation(businessId);
  const editAllergens = useBulkAllergensProductsMutation(businessId);
  const linkRelatedProducts = useBulkLinkRelatedProducts(businessId);

  type BulkActionByType = {
    success: string,
    error: string,
    action: (input?: any) => Promise<void>
  };

  const generateBulkActions = (selectedProducts: Product[]): Record<ProductsBulkAction, BulkActionByType> => ({
    delete: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.delete.task_started',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.delete.failure',
      }),
      action: async () => {
        deleteBulkProducts(selectedProducts.map((product) => product.id));
      },
    },
    snooze: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.snooze.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.snooze.failure',
      }),
      action: () => snoozeProductsMutation.mutateAsync(selectedProducts.map((product) => product.sku)),
    },
    unsnooze: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.unsnooze.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.unsnooze.failure',
      }),
      action: () => unsnoozeProductsMutation.mutateAsync(selectedProducts.map((product) => product.sku)),
    },
    enableAppOrdering: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.enable_app_ordering.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.enable_app_ordering.failure',
      }),
      action: () => enableProductsForOrderingMutation.mutateAsync(
        selectedProducts
          .filter((product) => menuBusiness?.orderingAllowed && product.isEligibleForOrder)
          .map((product) => product.id),
      ),
    },
    disableAppOrdering: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.disable_app_ordering.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.disable_app_ordering.failure',
      }),
      action: () => disableProductsForOrderingMutation.mutateAsync(
        selectedProducts
          .filter((product) => menuBusiness?.orderingAllowed && product.isEligibleForOrder)
          .map((product) => product.id),
      ),
    },
    editTags: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.tags.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.tags.failure',
      }),
      action: (selectedTags) => editTags.mutateAsync({
        productIds: selectedProducts.map((product) => product.id),
        tags: selectedTags,
      }),
    },
    editAllergens: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.allergens.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.allergens.failure',
      }),
      action: (selectedAllergens) => editAllergens.mutateAsync({
        productIds: selectedProducts.map((product) => product.id),
        allergens: selectedAllergens,
      }),
    },
    editRelatedProducts: {
      success: intl.formatMessage({
        id: 'menus.products.bulk.link_related_products.success',
      }, { nb_products: selectedProducts.length, plural: selectedProducts.length > 1 ? 's' : '' }),
      error: intl.formatMessage({
        id: 'menus.products.bulk.link_related_products.failure',
      }),
      action: (selectedRelatedProductIds: string[]) => linkRelatedProducts.mutateAsync({
        productIds: selectedProducts.map((product) => product.id),
        relatedProductIds: selectedRelatedProductIds,
      },
      ),
    },
  });

  const runBulkAction = async (selectedProducts: Product[], input?: any) => {
    if (!selectedAction) return;
    const selectedBulkAction = generateBulkActions(selectedProducts)[selectedAction];

    selectedBulkAction.action(input)
      .then(() => {
        snackbar.addNotification({
          text: selectedBulkAction.success,
          variant: 'success',
        });
      })
      .catch(() => snackbar.addNotification({ text: selectedBulkAction.error, variant: 'error' }));
  };

  const runBulkActionAndResetSelection = (selectedProducts: Product[], input?: any) => {
    runBulkAction(selectedProducts, input)
      .then(() => onSelectedProductsChanged([]));
  };

  const onCloseBulkModal = () => setSelectedAction(undefined);

  return (
    <>
      <Box display="flex" justifyContent="space-between"
           alignItems="center"
           bgcolor="black"
           color={'white'}
           borderRadius={'8px'}
           padding={'4px 8px 4px 8px'}>
        <Box display="flex" gap={2}>
          <Button startIcon={<CloseOutlined style={{ color: 'white', opacity: '56%', fontSize: 16 }} />}
                  onClick={() => onSelectedProductsChanged([])}>
            <Typography alignContent={'center'} color={'white'} variant="body1">
              <FormattedMessage id="menus.products.bulk.selected"
                                values={{
                                  'nb_products': multiSelectedProducts.length,
                                  'plural': multiSelectedProducts.length > 1 ? 's' : '',
                                }} />
            </Typography>
          </Button>
        </Box>
        <Box display="flex" gap={2} alignItems="center" justifyContent={'center'}>
          {(!isModifierMenu && menuBusiness?.orderingAllowed) && (
            <Button startIcon={<LocalMallOutlined style={{ color: 'white', opacity: '56%', fontSize: 16 }} />}
                    onClick={() => setSelectedAction('enableAppOrdering')}>
              <Typography color={'white'} variant="body1">
                <FormattedMessage id="menus.products.bulk.app_ordering_availability" />
              </Typography>
            </Button>)}
          <Button startIcon={<Snooze style={{ color: 'white', opacity: '56%', fontSize: 16 }} />}
                  onClick={() => setSelectedAction('snooze')}>
            <Typography color={'white'} variant="body1"><FormattedMessage
              id="menus.products.bulk.snooze" /></Typography>
          </Button>
          {!isModifierMenu && (<Button
            startIcon={<DeleteOutline style={{ color: 'white', opacity: '56%', fontSize: 16 }} />}
            onClick={() => setSelectedAction('delete')}>
            <Typography color={'white'} variant="body1"><FormattedMessage
              id="menus.products.bulk.delete" /></Typography>
          </Button>)}
          <ActionsDots setSelectedAction={setSelectedAction} />
        </Box>
      </Box>

      {selectedAction === 'delete' && (
        <DeleteProductsModal
          selectedProducts={multiSelectedProducts}
          onActionConfirmed={(selectedProducts) => runBulkAction(selectedProducts)}
          onActionDone={() => onSelectedProductsChanged([])}
          onClose={onCloseBulkModal}
          currentTaskStatus={currentTaskStatus}
        />
      )}
      {(selectedAction === 'snooze' || selectedAction === 'unsnooze') && (
        <SnoozeProductsModal
          switchAction={(action) => setSelectedAction(action)}
          selectedProducts={multiSelectedProducts}
          onActionConfirmed={(selectedProducts) => runBulkActionAndResetSelection(selectedProducts)}
          onClose={onCloseBulkModal} />
      )}
      {(selectedAction === 'enableAppOrdering' || selectedAction === 'disableAppOrdering') && (
        <AppOrderingProductsModal
          switchAction={(action) => setSelectedAction(action)}
          selectedProducts={multiSelectedProducts}
          onActionConfirmed={(selectedProducts) => runBulkActionAndResetSelection(selectedProducts)}
          onClose={onCloseBulkModal} />
      )}
      {(selectedAction === 'editTags') && (
        <TagsDrawer
          selectedProducts={multiSelectedProducts}
          businessId={businessId}
          onClose={onCloseBulkModal}
          onActionConfirmed={(selectedTags) => runBulkActionAndResetSelection(multiSelectedProducts, selectedTags)}
          open={selectedAction === 'editTags'} />
      )}
      {(selectedAction === 'editAllergens') && (
        <AllergensDrawer
          selectedProducts={multiSelectedProducts}
          businessId={businessId}
          onClose={onCloseBulkModal}
          onActionConfirmed={(selectedAllergens) => runBulkActionAndResetSelection(multiSelectedProducts, selectedAllergens)}
          open={selectedAction === 'editAllergens'} />
      )}
      {(selectedAction === 'editRelatedProducts') && (
        <LinkRelatedProductsDrawer
          selectedProducts={multiSelectedProducts}
          businessId={businessId}
          onClose={onCloseBulkModal}
          onActionConfirmed={(selectedRelatedProductIds: string[]) => runBulkActionAndResetSelection(multiSelectedProducts, selectedRelatedProductIds)}
          open={selectedAction === 'editRelatedProducts'}
        />
      )}
    </>
  );
};
