import React, { ReactNode } from 'react';
import { Accordion, AccordionDetails, Box, useTheme } from '@mui/material';
import {
  RepresentativeSummary,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/RepresentativeSummary';

export const Representative = ({ children, isComplete, title, onDelete, isReadOnly = false }: {
  children: ReactNode,
  isComplete: boolean,
  isReadOnly?: boolean,
  title: string,
  onDelete: () => void
}) => {
  const theme = useTheme();
  return <Box>
    <Accordion
      disabled={isReadOnly}
      defaultExpanded={!isComplete}
      style={{
        border: '1px solid',
        borderColor: theme.palette.grey[300],
        borderRadius: '24px',
        padding: '12px 0px',
      }}>
      <RepresentativeSummary title={title} complete={isComplete} onDelete={onDelete} />
      {
        !isReadOnly && <AccordionDetails>
          {children}
        </AccordionDetails>
      }

    </Accordion>
  </Box>;
};
