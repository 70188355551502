import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ProductRepository } from './ProductRepository';
import { CreateProductDto, PaginatedProductsDto, ProductDto, ProductsSummaryDto, UpdateProductDto } from './ProductDto';
import { ProductDtoConverter } from './ProductDtoConverter';
import { RepositoryHttp } from 'src/ordering/common/repository/RepositoryHttp';
import { ProductSummary } from '../domain/ProductSummary';
import { Product } from '../domain/Product';
import { MenuImageUrlDto } from './MenuDto';
import { BusinessId } from 'src/business/domain/Business';
import { PaginatedProducts } from 'src/menu/dynamic-menu/domain/PaginatedProducts';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { Task } from 'src/menu/tasks/domain/Task';
import { TaskDto, toTask } from 'src/menu/common/repositories/TaskDto';
import { Tag } from 'src/menu/dynamic-menu/domain/Tag';
import { Allergen } from 'src/menu/dynamic-menu/domain/Allergen';

export class ProductRepositoryHttp extends RepositoryHttp implements ProductRepository {
  constructor(private menuBackendUrl: string) {
    super();
  }

  async updateProduct(businessId: BusinessId, productId: string, product: UpdateProductDto): Promise<void> {
    await axios.patch<ProductDto>(`${this.menuBackendUrl}/businesses/${businessId}/products/${productId}`, product, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });
  }

  async updateProductTranslation(
    businessId: BusinessId,
    productId: string,
    language: string,
    productUpdate: UpdateProductDto,
  ): Promise<void> {
    await axios.patch<ProductDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/products/${productId}/translations/${language}`,
      productUpdate,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async deleteProduct(businessId: BusinessId, productId: string): Promise<void> {
    await axios.delete<ProductDto>(`${this.menuBackendUrl}/businesses/${businessId}/products/${productId}`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });
  }

  async deleteProducts(businessId: BusinessId, productIds: string[]): Promise<Task> {
    const task = await axios.post<TaskDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/delete-products`,
      { productIds },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
    return toTask(task.data);
  }

  async snoozeProducts(businessId: BusinessId, productSkus: string[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/snooze-products`,
      { productSkus },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async unSnoozeProducts(businessId: BusinessId, productSkus: string[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/unsnooze-products`,
      { productSkus },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async enableProductsForOrdering(businessId: BusinessId, productIds: string[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/enable-products`,
      { productIds },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async disableProductsForOrdering(businessId: BusinessId, productIds: string[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/disable-products`,
      { productIds },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async createProduct(businessId: BusinessId, createProductRequest: CreateProductDto): Promise<string> {
    const response = await axios.post<ProductDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/products`,
      createProductRequest,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
    return response.data.id;
  }

  async findAllByBusinessId(businessId: BusinessId, filterOnlyInsideMenu?: boolean): Promise<ProductSummary[]> {
    const queryParams = filterOnlyInsideMenu ? `?${new URLSearchParams({ onlyInMenu: 'true' })}` : '';

    const response = await axios.get<ProductsSummaryDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/products${queryParams}`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );

    return response.data.products
      .map((product) => ProductDtoConverter.toProductSummary(product))
      .sort((m1, m2) => m1.name.localeCompare(m2.name, 'en', { numeric: true }));
  }

  async findByProductId(businessId: BusinessId, productId: string, language?: string): Promise<Product | null> {
    try {
      const queryParams = language ? `?${new URLSearchParams({ language })}` : '';
      const response = await axios.get<ProductDto>(
        `${this.menuBackendUrl}/businesses/${businessId}/products/${productId}${queryParams}`,
        {
          withCredentials: true,
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );

      return ProductDtoConverter.toProduct(response.data);
    } catch (e) {
      return null;
    }
  }

  async refreshProducts(businessId: BusinessId, menuAssetsSource: MenuAssetsSource): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/products-refresh`,
      {
        posConnectionId: menuAssetsSource.posConnectionId,
        externalRevenueCenterId: menuAssetsSource.externalRevenueCenterId,
      },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async updateProductPicture(businessId: BusinessId, productId: string, pictureBytes: any): Promise<string> {
    return new Promise((success, failure) => {
      axios
        .post<MenuImageUrlDto>(
        `${this.menuBackendUrl}/businesses/${businessId}/products/${productId}/image`,
        pictureBytes,
        {
          ...this.withDefaultHeader(),
          headers: {
            ...this.withDefaultHeader().headers,
            'Content-Type': 'application/octet-stream',
          },
        },
      )
        .then((response) => {
          success(response.data.imageUrl);
        })
        .catch(failure);
    });
  }

  async deleteProductPicture(businessId: BusinessId, productId: string): Promise<void> {
    await axios.delete<MenuImageUrlDto>(`${this.menuBackendUrl}/businesses/${businessId}/products/${productId}/image`, {
      ...this.withDefaultHeader(),
    });
  }

  async copyProduct(businessId: BusinessId, productId: string): Promise<Product> {
    const response = await axios.post<ProductDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/products/${productId}/copy`,
      {},
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
    return ProductDtoConverter.toProduct(response.data);
  }

  async snoozeProduct(businessId: BusinessId, productSku: string): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/products/${productSku}/snooze`,
      {},
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async unSnoozeProduct(businessId: BusinessId, productSku: string): Promise<void> {
    await axios.delete(`${this.menuBackendUrl}/businesses/${businessId}/products/${productSku}/snooze`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });
  }

  async searchProducts(
    businessId: BusinessId,
    page: number,
    pageSize: number,
    productTypes: string[],
    filter: string | undefined,
    advanced: string | undefined,
    menuAssetsSource: MenuAssetsSource | undefined,
  ): Promise<PaginatedProducts> {
    const queryParams = new URLSearchParams({ page: page.toString(), pageSize: pageSize.toString() });
    if (filter) {
      queryParams.append('filter', filter);
    }

    if (menuAssetsSource) {
      queryParams.append('posConnectionId', menuAssetsSource.posConnectionId);
      if (menuAssetsSource.externalRevenueCenterId) {
        queryParams.append('externalRevenueCenterId', menuAssetsSource.externalRevenueCenterId);
      }
    }

    productTypes.forEach((productType) => {
      queryParams.append('productTypes', productType);
    });

    if (advanced) {
      queryParams.append('advanced', advanced);
    }

    const response = await axios.get<PaginatedProductsDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/products-search?${queryParams}`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );

    return {
      products: response.data.products.map((dto) => ProductDtoConverter.toProduct(dto)),
      totalCount: response.data.totalCount,
    };
  }

  async findBySource(businessId: BusinessId, menuAssetsSource: MenuAssetsSource): Promise<ProductSummary[]> {
    const queryParams = new URLSearchParams({
      posConnectionId: menuAssetsSource.posConnectionId,
    });

    if (menuAssetsSource.externalRevenueCenterId) {
      queryParams.set('externalRevenueCenterId', menuAssetsSource.externalRevenueCenterId);
    }

    const response = await axios.get<ProductsSummaryDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/products?${queryParams}`,
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );

    return response.data.products
      .map((product) => ProductDtoConverter.toProductSummary(product))
      .sort((m1, m2) => m1.name.localeCompare(m2.name, 'en', { numeric: true }));
  }

  async revertToPartnerPicture(businessId: BusinessId, productId: string): Promise<void> {
    await axios.patch<void>(
      `${this.menuBackendUrl}/businesses/${businessId}/products/${productId}/revert-to-partner-image`,
      {},
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async revertToPartnerSubProducts(businessId: BusinessId, productId: string): Promise<void> {
    await axios.patch<void>(
      `${this.menuBackendUrl}/businesses/${businessId}/products/${productId}/revert-to-partner-subproducts`,
      {},
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async setTagsToProducts(businessId: BusinessId, productIds: string[], tags: Tag[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/set-products-tags`,
      {
        productIds,
        tags,
      },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async setAllergensToProducts(businessId: BusinessId, productIds: string[], allergens: Allergen[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/set-products-allergens`,
      {
        productIds,
        allergens,
      },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }

  async linkRelatedProducts(businessId: BusinessId, productIds: string[], relatedProductIds: string[]): Promise<void> {
    await axios.post(
      `${this.menuBackendUrl}/businesses/${businessId}/set-related-products`,
      {
        productIds,
        relatedProductIds,
      },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
  }
}
