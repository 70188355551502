import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { TitleContent, TitleEmphasis } from './HomeTitle';

type HomeTitleWithAdoptionProps = {
  adoptionRate: number | undefined;
};

export const HomeTitleWithAdoption = ({ adoptionRate }: HomeTitleWithAdoptionProps) => (
  <TitleContent>
    <FormattedMessage
      id="venue.home.adoption.title.rate"
      defaultMessage="{adoptionRate}% of your payments are on sunday."
      values={{ adoptionRate }}
    />
    {' '}
    <TitleEmphasis>
      <FormattedMessage
        id="venue.home.adoption.title.emphasis"
        defaultMessage="get more tips and reviews!"
      />
    </TitleEmphasis>
  </TitleContent>
);