import axios from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentAdapter } from '../infrastructure/PaymentAdapter';
import { PaymentRepositoryHttp } from '../infrastructure/PaymentRepositoryHttp';
import { OrderingRepositoryHttp } from '../infrastructure/OrderingRepositoryHttp';
import { PatOrchestratorRepositoryHttp } from '../infrastructure/PatOrchestratorRepositoryHttp';
import { useListTables } from 'src/floorplan/queries/listTables';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const usePaymentAdapter = (): PaymentAdapter => {
  const configuration = ConfigurationLoader.load();
  const paymentRepositoryHttp = new PaymentRepositoryHttp(axios, configuration.paymentApiBaseUrl);
  const orderingRepositoryHttp = new OrderingRepositoryHttp(axios, configuration);
  const patOrchestratorPaymentRepositoryHttp = new PatOrchestratorRepositoryHttp(
    axios,
    configuration.patOrchestratorUrl,
  );
  const business = useCurrentBusinessOrThrow();
  const listTables = useListTables(business.id);


  return new PaymentAdapter(
    paymentRepositoryHttp,
    listTables,
    orderingRepositoryHttp,
    patOrchestratorPaymentRepositoryHttp,
  );
};
