import { Box, Grid, styled } from '@mui/material';
import { GridItemLayoutProps, GridItemRowsProps } from 'src/home2/types/grid_layout_types';

export const StyledGridBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  flexDirection: 'row',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down('tablet')]: {
    flexDirection: 'column',
  },
}));

export const getGridAttributes = (size: GridItemLayoutProps['layout']['size']) => {
  switch (size) {
    case 'small':
      return {
        xs: 12,
        lg: 4,
      };
    case 'large':
      return {
        xs: 12,
        lg: 8,
      };
    case 'even':
    default:
      return {
        xs: 12,
      }; 
  }
};

export const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => 
    prop !== 'order' &&
    prop !== 'layout' &&
    prop !== 'rows',
})<GridItemLayoutProps & GridItemRowsProps>(({ 
  theme, 
  order,
  layout,
  rows,
  ...nativeProps 
}) => ({
  display: 'flex',
  order: order ?? 'inherit',
  gap: theme.spacing(3),

  // Handle Grid item sizing
  ...(nativeProps.item && {
    display: 'flex',
    // Case when a column as only one row
    ...(rows ===  1 && {
      flex: 1,
    }),
    // Case when two column as only one row
    ...(rows ===  2 && {
      display: 'flex',
      '&:first-of-type': {
        flex: 2,
      },
      flex: 1,
    }),
  }),

  // Handle other styling depending on direction
  ...(nativeProps.container && layout.direction === 'column' && {
    alignItems: 'stretch',
    '[nativeProps.spacing]': 3,
  }),
}));
