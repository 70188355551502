import React, { useMemo } from 'react';
import { SundayDevice } from '../../../domain/SundayDevice';
import { QrCodes } from '../../../../common/components/qrCodes/QrCodes';

type AreaQrCodesProps = {
  areaId: string;
  sundayDevices: SundayDevice[];
};

export const AreaQrCodes = ({ areaId, sundayDevices }: AreaQrCodesProps) => {
  const areaDevices = useMemo(
    () => sundayDevices.filter((devices) => devices.areaId === areaId),
    [sundayDevices, areaId],
  );

  return <QrCodes devices={areaDevices} title="qr code" />;
};
