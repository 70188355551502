import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { StyledToggleButtonGroup, StyledToggleButtonItem, ToggleButtonWrapper } from '@styles/Button/StyledToggleButton';
import { ToggleButtonItemProps, ToggleButtonProps } from 'src/home2/types/component_types';
import { Typography } from '@mui/material';

export const StyledToggleButton = (toggleButtonProps: ToggleButtonProps<any>) => {
  const handleOnToggleButton = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
    toggleButtonProps.setSelectedOption(nextView);
  };

  return (
    <ToggleButtonWrapper>
      <StyledToggleButtonGroup
        value={toggleButtonProps.selectedOption ?? toggleButtonProps.listButtons[0]}
        onChange={handleOnToggleButton}
        exclusive
      >
        {toggleButtonProps.listButtons && toggleButtonProps.listButtons.map((button: ToggleButtonItemProps<any>) => (
          <StyledToggleButtonItem key={button.id} value={button.value} disabled={button.isDisabled}>
            <Typography variant="body1">
              <FormattedMessage id={button.id} />
            </Typography>
          </StyledToggleButtonItem>
        ))}
      </StyledToggleButtonGroup>
    </ToggleButtonWrapper>
  );
};
