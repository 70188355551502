import { ArrowIcon, IconButton } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';

type Props = {
  currentPage: number,
  lastPage: number,
  previousPage: () => void,
  nextPage: () => void,
};

const Navigation = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  background: white;
  width: 100%;
  margin: -15px 0 0 0;
  border-top: 1px solid #dddde4;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px;
`;

export const PaymentNavigation = ({
  lastPage, currentPage, previousPage, nextPage,
}: Props) => (
  <>
    {(lastPage > 1)
      && (
        <Navigation>
          <IconButton
            data-testid="previousButton"
            size="small"
            onClick={previousPage}
            disabled={currentPage === 1}
            variant="default"
          >
            <ArrowIcon direction="left" />
          </IconButton>

          <Typography color={themeV5.palette.text.primary} variant="body2">
            {currentPage}
            {' - '}
            {lastPage}
          </Typography>

          <IconButton
            data-testid="nextButton"
            size="small"
            onClick={nextPage}
            disabled={currentPage === lastPage}
            variant="default"
          >
            <ArrowIcon direction="right" />
          </IconButton>
        </Navigation>
      )}
  </>
);
