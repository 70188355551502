import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { useQuery } from '@tanstack/react-query';
import { useDependencies } from 'src/tips/dispatch/useDependencies';
import { SundayPoolingRepository } from 'src/tips/dispatch/model/SundayPoolingRepository';
import { Staff } from 'src/tips/dispatch/model/Staff';

const getAllStaffQuery = (businessId: BusinessId, sundayPoolingRepository: SundayPoolingRepository) => ({
  queryKey: [queryKeys.sundayTipsPooling.GET_ALL_STAFF, { businessId }],
  queryFn: (): Promise<Staff[]> => sundayPoolingRepository.getAllStaff(businessId),
});

export const useGetAllStaff = (businessId: BusinessId) => {
  const dependencies = useDependencies();
  return useQuery(getAllStaffQuery(businessId, dependencies.sundayPoolingRepository));
};
