import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Button, { ButtonVariant } from '../../../common/components/Button';
import { useVenueRepository } from '../../../venues/hook';
import { Order, OrderStatus } from '../../types';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';

type SendBoxOrdersToPosProps = {
  order: Order;
};

export const SendBoxOrdersToPos = ({ order }: SendBoxOrdersToPosProps) => {
  const venueRepository = useVenueRepository();
  const [isProcessing, updateProcessing] = useState<boolean>(false);
  const user = useAuthenticatedUserOrThrow();

  if (!user.impersonated) {
    return <></>;
  }

  if (order.status !== OrderStatus.ACCEPTED && order.status !== OrderStatus.BOX_ORDERS_PLACED) {
    return <></>;
  }

  const sendToPosWebhook = () => {
    updateProcessing(true);
    const boxOrderIds = (order as any).boxOrderIds as string[];
    if (boxOrderIds) {
      Promise.all(boxOrderIds.map(
        (boxOrderId) => venueRepository.sendBoxOrderToPos(boxOrderId),
      ))
        .finally(() => {
          updateProcessing(false);
        });
    }
  };

  return (
    <Button variant={ButtonVariant.PRIMARY} onClick={sendToPosWebhook} data-testid="send-to-pos" disabled={isProcessing}>
      <FormattedMessage
        id="order.sentToPos"
        defaultMessage="send to POS now"
      />
    </Button>
  );
};
