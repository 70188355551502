import { CurrencyCode, Money } from '@sundayapp/web-money';
import { schemaForType } from 'src/utils/ZodUtils';
import { z } from 'zod';

export const transferStatuses = {
  PENDING: 'PENDING',
  SUSPENDED: 'SUSPENDED',
  SUCCEEDED: 'SUCCEEDED',
  CANCELED: 'CANCELED',
} as const;

const MoneyZ = schemaForType<Money>()(
  z.object({
    amount: z.number(),
    currency: z.nativeEnum(CurrencyCode).catch(CurrencyCode.EUR),
  }),
);

const TransferStatusSchema = z.nativeEnum(transferStatuses);

export type Transfer = z.infer<typeof TransferSchema>;

export const TransferSchema = z.object({
  id: z.string(),
  amount: MoneyZ,
  status: TransferStatusSchema,
  createdAt: z.coerce.date(),
});

export const StaffTippingSummarySchema = z.object({
  balance: MoneyZ,
  transfers: z.array(TransferSchema),
});

export type StaffTippingSummary = z.infer<typeof StaffTippingSummarySchema>;
