import React from 'react';
import { styled, Typography } from '@mui/material';
import { FormattedMessage, IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment';
import { themeV5 } from 'src/app/theme/ThemeV5';
import CalendarSvg from '../../../app/component/icons/CalendarSvg';
import InformationSvg from '../../../app/component/icons/InformationSvg';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${themeV5.palette.text.secondary};
  height: fit-content;
`;

const InfoWrapper = styled('div')`
  display: flex;
`;

const toFormattedDate = (intl: IntlShape, date: Moment) => intl.formatDate(date.toDate(), {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
});

export const HomeHeaderTimeRangeInfo = () => {
  const intl = useIntl();

  const date = moment();
  const endCurrentPeriod = toFormattedDate(intl, date.subtract(1, 'days'));
  const beginCurrentPeriod = toFormattedDate(intl, date.subtract(6, 'days'));
  const endPrevPeriod = toFormattedDate(intl, date.subtract(1, 'days'));
  const beginPrevPeriod = toFormattedDate(intl, date.subtract(6, 'days'));

  const currentRange = intl.formatMessage({
    id: 'venue.home.date-range.hint',
    defaultMessage: '{beginPrevPeriod} - {endPrevPeriod} vs {beginCurrentPeriod} - {endCurrentPeriod}',
  },
  {
    beginPrevPeriod,
    endPrevPeriod,
    beginCurrentPeriod,
    endCurrentPeriod,
  });

  return (
    <Container>
      <CalendarSvg color={themeV5.palette.text.secondary} size={16} />
      <Typography variant="body2" marginLeft="4px" marginRight="4px">
        <FormattedMessage id="venue.home.date-range.info" defaultMessage="Last 7 days vs 7 days before" />
      </Typography>
      <ThemeTooltip title={currentRange} placement="top" arrow>
        <InfoWrapper>
          <InformationSvg color={themeV5.palette.text.secondary} size={16} />
        </InfoWrapper>
      </ThemeTooltip>
    </Container>
  );
};
