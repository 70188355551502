import { BusinessId } from 'src/business/domain/Business';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridColDef,
  GridRowModel,
  gridStringOrNumberComparator,
} from '@mui/x-data-grid';
import { Product, translationByProductType } from 'src/menu/dynamic-menu/domain/Product';
import React, { FC } from 'react';
import { useProductMutation } from 'src/menu/dynamic-menu/mutations/product/useProductMutation';
import { Box, Checkbox, Chip, Switch, Tooltip, Typography } from '@mui/material';
import { EmptyTableCellValue } from 'src/payments/components/EmptyTableCellValue';
import {
  ProductInformationIcon,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductInformationIcon';
import { ChevronRight, Snooze } from '@mui/icons-material';
import { gridMoneyComparator } from 'src/app/theme/DatagridTheme';
import { renderMoney } from '@sundayapp/web-money';
import { Action, ActionsButton } from 'src/components/actions-button/ActionsButton';
import AlarmOffIcon from '@mui/icons-material/AlarmOff';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { FormattedMessage, IntlShape } from 'src/app/i18n/TypedIntl';
import { ProductAction } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';
import { ProductName } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductName';
import { ImgContainer } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductImageContainer';
import { ProductImage } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/MenuImage';
import { ProductNameInfo } from './ProductNameInfo';
import { ContentCopy } from 'src/app/component/icons/ContentCopySvg';
import { Delete } from 'src/app/component/icons/DeleteSvg';
import { MenuAssetSourceIcon } from 'src/menu/dynamic-menu/components/MenuAssetSourceIcon';
import { MenuAssetsSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

type BulkUpdateSelection = {
  areAllSelected: boolean,
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSelectProduct: (row: GridRowModel<Product>, checked: boolean) => void,
  isProductSelected: (row: GridRowModel<Product>) => boolean,
};

export const buildProductsColumns = (
  businessId: BusinessId,
  intl: IntlShape,
  onProductClicked: (product: ProductAction) => void,
  menuAssetsSources: MenuAssetsSources,
  { areAllSelected, onSelectProduct, isProductSelected, onSelectAllClick }: BulkUpdateSelection,
): GridColDef<Product>[] => {
  const ProductAvailable: FC<{ product: Product }> = ({ product }) => {
    const productUpdate = useProductMutation(businessId, product.id);
    return product.isEligibleForOrder ? (
      <Switch
        checked={product.isAvailableForOrder}
        onChange={(e, checked) => {
          productUpdate.mutate({ isAvailableForOrder: checked });
        }}
      />
    ) : (
      <EmptyTableCellValue />
    );
  };

  return [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderHeader: () =>
        (<Checkbox
          color="primary"
          size="large"
          checked={areAllSelected}
          onChange={onSelectAllClick}
          inputProps={{
            'aria-label': 'select all',
          }}
        />),
      display: 'flex',
      flex: 0.3,
      renderCell: ({ row }) => (
        <Checkbox
          size="large"
          color="primary"
          checked={isProductSelected(row)}
          onChange={(event) => onSelectProduct(row, event.currentTarget.checked)}
        />
      ),
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'menus.products.header.product' }),
      disableColumnMenu: true,
      display: 'flex',
      flex: 6,
      sortComparator: gridStringOrNumberComparator,
      renderCell: ({ row }: GridCellParams) => (
        <ProductName>
          <ImgContainer>{row.pictureUrl && <ProductImage $imageUrl={row.pictureUrl} />}</ImgContainer>
          <ProductNameInfo>
            <Typography>{row.name}</Typography>
            <ProductInformationIcon product={row} />
          </ProductNameInfo>
          <ChevronRight fontSize="large" style={{ marginLeft: 1 }} />
        </ProductName>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      display: 'flex',
      flex: 3,
      disableColumnMenu: true,
      renderCell: ({ row }: GridCellParams<Product>) => {
        return (
          <Box display={'flex'} gap={1} alignItems={'center'}>
            <Chip color={'secondary'} label={<FormattedMessage id={translationByProductType[row.type].single} />} />
            {row.isSnoozed && (
              <Tooltip title={intl.formatMessage({ id: 'menus.product.datagrid.snoozed' })}>
                <Snooze />
              </Tooltip>
            )}
            <MenuAssetSourceIcon isSunday={row.source === 'SUNDAY'} posConnection={row.posConnection || null} menuAssetsSources={menuAssetsSources} />
          </Box>
        );
      },
    },
    {
      field: 'price',
      headerAlign: 'center',
      align: 'center',
      display: 'flex',
      flex: 2,
      headerName: intl.formatMessage({ id: 'menus.products.header.price' }),
      disableColumnMenu: true,
      sortComparator: gridMoneyComparator,
      renderCell: ({ row }: GridCellParams) => renderMoney(row.price, intl.locale),
    },
    {
      field: 'isEligibleForOrder',
      sortable: false,
      align: 'center',
      display: 'flex',
      flex: 1,
      headerAlign: 'center',
      headerName: intl.formatMessage({ id: 'menus.products.header.isEligibleForOrder' }),
      disableColumnMenu: true,
      renderCell: ({ row }) => <ProductAvailable product={row} />,
    },
    {
      field: 'actions',
      sortable: false,
      align: 'center',
      display: 'flex',
      flex: 1,
      headerAlign: 'center',
      headerName: intl.formatMessage({ id: 'menus.products.header.actions' }),
      renderCell: ({ row }) => {
        const actions: Action[] = [
          {
            icon: <ContentCopy />,
            visible: true,
            intlKey: 'menus.product.copy.cta',
            action: () => onProductClicked({ product: row, actionName: 'copy', targetName: row.name }),
          },
          {
            icon: row.isSnoozed ? <AlarmOffIcon /> : <AlarmOnIcon />,
            visible: !!row.sku,
            intlKey: row.isSnoozed ? 'menus.product.unsnooze.cta' : 'menus.product.snooze.cta',
            action: () =>
              onProductClicked({
                product: row,
                actionName: row.isSnoozed ? 'unsnooze' : 'snooze',
                targetName: row.name,
              }),
          },
          {
            icon: <Delete />,
            visible: true,
            intlKey: 'menus.product.delete.cta',
            action: () => onProductClicked({ product: row, actionName: 'delete', targetName: row.name }),
          },
        ];
        return <ActionsButton actions={actions} popperPlacement="left-end" sx={{ paddingRight: '10px' }} />;
      },
    },
  ];
};
