import { Button, CheckSvg, DangerSvg, IconWrapper, Panel } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { differenceMoney, money } from '@sundayapp/web-money';
import { FormattedMessage, FormattedTime } from 'src/app/i18n/TypedIntl';
import { useNavigate, useParams } from 'react-router';
import { PaymentOrigin } from '../domain/PaymentOrigin';
import { useFinalizeReconciliationReportMutation } from '../mutations/useFinishReconciliationMutation';
import { totalCashUp } from '../domain/ReconciliationReport';
import { venueReconciliationHistoryTabPath, venueReconciliationTabPath } from 'src/app/navigation/pathHelpers';
import { FinishReportModal } from './FinishReportModal';
import { useReport } from '../queries/getReport';
import { DeleteReportModal } from './DeleteReportModal';
import { useDeleteReportMutation } from '../mutations/useDeleteReportMutation';
import { useReportSettings } from '../queries/ReportSettings';
import { deltaIsAcceptable } from './DeltaAcceptable';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const FloatingPanel = styled(Panel)`
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 80%;
  position: sticky;
  margin: 20px;
  bottom: 20px;
`;
export const ReportSavePanel = () => {
  const [showModalFinish, setShowModalFinish] = useState<boolean>(false);
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const business = useCurrentBusinessOrThrow();
  const navigate = useNavigate();
  const { reportId } = useParams();
  const { data: report, isLoading } = useReport(reportId!);
  const finalizeMutation = useFinalizeReconciliationReportMutation(business.id, report!);
  const deleteReportMutation = useDeleteReportMutation();
  const { data: reportSettings } = useReportSettings();

  if (isLoading || !report || !reportSettings) {
    return <CircularProgress />;
  }

  const totalOnPos = (report.totalOnPos.editable ? report.totalOnPos.amount : totalCashUp(business.currency, report, PaymentOrigin.OnPos))
    ?? money(0, business.currency);
  const totalReported = totalCashUp(business.currency, report, PaymentOrigin.Reported);
  const delta = differenceMoney(totalReported, totalOnPos);

  const balanced = delta.amount === 0;

  const color = deltaIsAcceptable(delta, reportSettings) ? '#3fb05e' : '#e0831e';

  const finalize = () => {
    finalizeMutation.mutateAsync()
      .then(() => navigate(venueReconciliationHistoryTabPath(business.id)));
    setShowModalFinish(false);
  };

  const deleteReport = () => {
    deleteReportMutation.mutate(reportId!);
    setShowModalDelete(false);
    navigate(venueReconciliationTabPath(business.id, 'open'));
  };

  return (
    <FloatingPanel background={color}>
      <Box
        flexDirection="row"
        sx={{
          display: 'flex', alignItems: 'center', justifyContent: 'spaced', gap: '15px',
        }}
      >
        <IconWrapper color="white">
          {balanced ? <CheckSvg /> : <DangerSvg />}
        </IconWrapper>
        <Typography sx={{ color: 'white' }}>
          <FormattedMessage id="reconciliation.report.last_update" />
          <FormattedTime
            value={report.lastModification}
            day="2-digit"
            month="2-digit"
            year="numeric"
            hour="2-digit"
            minute="2-digit"
            second="2-digit"
          />
        </Typography>
      </Box>
      <Box sx={{
        display: 'flex', alignItems: 'center', justifyContent: 'end', gap: 2,
      }}
      >
        <Button
          size="small"
          variant="tertiary"
          style={{ border: 0 }}
        >
          <Typography
            style={{
              color: 'white', borderColor: 'white', textDecoration: 'underline',
            }}
            onClick={() => setShowModalDelete(true)}
          >
            <FormattedMessage id="reconciliation.report.delete" />
          </Typography>
        </Button>

        {showModalDelete && (
          <DeleteReportModal
            submitAction={() => deleteReport()}
            report={report}
            isOpened={showModalDelete}
            onClose={() => setShowModalDelete(false)}
          />
        )}
        <Button
          type="button"
          variant="primary"
          size="small"
          style={{
            backgroundColor: 'white', color: 'black', borderColor: 'white',
          }}
          onClick={() => setShowModalFinish(true)}

        >
          <FormattedMessage id="reconciliation.report.finish" />
        </Button>
        {showModalFinish && (
          <FinishReportModal
            submitAction={() => finalize()}
            report={report}
            isOpened={showModalFinish}
            color={color}
            delta={delta}
            onClose={() => setShowModalFinish(false)}
          />
        )}
      </Box>
    </FloatingPanel>
  );
};
