/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const PaymentLinkSvg: React.FC<Props> = ({
  size = 20,
  color = themeV5.palette.primary.main,
  ...props
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} {...props} viewBox="0 0 20 20" fill="none">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M10.6461 2.29223C11.5891 1.38144 12.8521 0.877469 14.1631 0.888861C15.4741 0.900253 16.7281 1.4261 17.6552 2.35314C18.5822 3.28018 19.1081 4.53424 19.1194 5.84522C19.1308 7.15621 18.6269 8.41922 17.7161 9.36223L17.7059 9.37256L15.206 11.8725C14.6991 12.3795 14.0891 12.7716 13.4174 13.0222C12.7456 13.2728 12.0278 13.376 11.3127 13.3248C10.5975 13.2736 9.90178 13.0691 9.27258 12.7254C8.64339 12.3816 8.09548 11.9066 7.66603 11.3325C7.39036 10.9639 7.46565 10.4417 7.83419 10.166C8.20273 9.89033 8.72497 9.96561 9.00064 10.3342C9.28694 10.7169 9.65221 11.0336 10.0717 11.2628C10.4911 11.492 10.955 11.6282 11.4317 11.6624C11.9085 11.6965 12.387 11.6277 12.8349 11.4607C13.2827 11.2936 13.6894 11.0322 14.0273 10.6942L16.522 8.19945C17.1262 7.57131 17.4604 6.73142 17.4528 5.85971C17.4452 4.98572 17.0947 4.14968 16.4767 3.53165C15.8586 2.91362 15.0226 2.56306 14.1486 2.55546C13.2765 2.54789 12.4363 2.88239 11.808 3.48709L10.3792 4.90761C10.0528 5.2321 9.52519 5.23056 9.2007 4.90417C8.87621 4.57779 8.87775 4.05015 9.20413 3.72567L10.6375 2.30067L10.6461 2.29223Z"
          fill={color} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.58263 6.97771C7.25439 6.72712 7.97218 6.62394 8.68732 6.67516C9.40247 6.72637 10.0982 6.93079 10.7274 7.27455C11.3566 7.61831 11.9045 8.09336 12.334 8.66749C12.6096 9.03603 12.5344 9.55827 12.1658 9.83394C11.7973 10.1096 11.275 10.0343 10.9994 9.66579C10.7131 9.28303 10.3478 8.96633 9.92833 8.73716C9.50887 8.50799 9.04503 8.37171 8.56827 8.33756C8.0915 8.30342 7.61298 8.37221 7.16514 8.53927C6.7173 8.70632 6.31062 8.96774 5.9727 9.30579L3.478 11.8005C2.8738 12.4286 2.53959 13.2685 2.54716 14.1402C2.55476 15.0142 2.90532 15.8503 3.52335 16.4683C4.14138 17.0863 4.97742 17.4369 5.85141 17.4445C6.72311 17.4521 7.563 17.1178 8.19114 16.5137L9.61075 15.094C9.93618 14.7686 10.4638 14.7686 10.7893 15.094C11.1147 15.4195 11.1147 15.9471 10.7893 16.2726L9.36426 17.6976L9.35393 17.7077C8.41092 18.6185 7.14791 19.1225 5.83692 19.1111C4.52594 19.0997 3.27188 18.5738 2.34484 17.6468C1.4178 16.7198 0.891952 15.4657 0.88056 14.1547C0.869168 12.8437 1.37314 11.5807 2.28393 10.6377L2.29408 10.6274L4.79397 8.12749C4.79394 8.12752 4.79401 8.12745 4.79397 8.12749C5.30084 7.62047 5.91092 7.22828 6.58263 6.97771Z"
          fill={color} />
  </svg>

);

export default PaymentLinkSvg;
