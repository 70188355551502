import { VenueConfigurationKey } from '@sundayapp/global-configuration';

export class GlobalConfiguration {
  constructor(
    public entries: Map<VenueConfigurationKey, any>,
  ) {
  }
}

export const EMPTY_GLOBAL_CONFIGURATION = new GlobalConfiguration(new Map());
