import React, { useCallback } from 'react';
import { RightArrowIcon } from '../../../common/components/Icons';
import { useSelectOrderForDetails } from '../../hooks';
import { OrderSummary } from '../../types';

interface Props {
  order: OrderSummary;
}

const DetailsButton: React.FC<Props> = ({ order }) => {
  const [, selectOrder] = useSelectOrderForDetails();
  const onClick = useCallback(() => selectOrder(order.id), [selectOrder, order.id]);
  return <RightArrowIcon onClick={onClick} />;
};

export default DetailsButton;
