import { MoneyView } from '@sundayapp/web-money';
import {
  Card,
  Typography,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText, DialogActions, Stack, IconButton,
} from '@mui/material';
import * as React from 'react';
import { ReconciliationReporting } from 'src/operations/end-of-service/model/ReconciliationReportingDigest';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { venueSpecificPaymentPath } from 'src/app/navigation/pathHelpers';
import { useNavigate } from 'react-router';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { copyPaste } from 'src/utils/CopyPaste';
import ZoomInIcon from '@mui/icons-material/ZoomIn';

export const ReconciliationCard = (
  {
    title,
    subtitle,
    reporting,
    displayDetails,
  }: {
    title: string,
    subtitle: string | undefined,
    reporting: ReconciliationReporting,
    displayDetails: boolean,
  },
) => {
  const [detailsOpen, setDetailsOpen] = React.useState(false);

  const navigate = useNavigate();
  const businessId = useBusinessIdOrThrow();

  return (
    <>
      <Card sx={{ minWidth: 275, maxWidth: 275 }}>
        <CardContent>
          {subtitle && <Typography variant="caption" gutterBottom color="textSecondary"> {subtitle} </Typography>}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Number of operations: {reporting.operations.length}
          </Typography>
          <Typography variant="body1">Total sales: <MoneyView value={reporting.sales} /></Typography>
          <Typography variant="body1">Total tips: <MoneyView value={reporting.tips} /></Typography>
        </CardContent>
        {displayDetails &&
          <CardActions>
            <Button onClick={() => setDetailsOpen(true)} size="small">Details</Button>
          </CardActions>
        }
      </Card>
      <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)}>
        <DialogTitle>
          <Typography>Details of included operations</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Stack direction="column" spacing={1}>
              {reporting.operations.map((operation) => (
                <Stack direction="row" key={operation.id} spacing={2} justifyContent={'space-between'}>
                  <Stack direction="row" spacing={0.5} alignItems={'center'}>
                    <IconButton>
                      <ZoomInIcon onClick={() => navigate(venueSpecificPaymentPath(businessId, operation.at))} />
                    </IconButton>
                    <IconButton>
                      <ContentCopyIcon onClick={() => copyPaste(operation.id)} />
                    </IconButton>
                    <Typography variant="body2">ID: {operation.id}</Typography>
                  </Stack>
                  <Typography variant="body2">Sales: <MoneyView value={operation.sales} /></Typography>
                  <Typography variant="body2">Tips: <MoneyView value={operation.tips} /></Typography>
                </Stack>
              ))}
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsOpen(false)} autoFocus variant="contained">
            <FormattedMessage id="datepicker.ok" defaultMessage="OK" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
