import { useMutation } from '@tanstack/react-query';
import {
  reconcilePOSOperationsWithSundayPayments,
} from 'src/operations/end-of-service/queries/reconcilePOSOperationsWithSundayPayments';
import { BusinessId } from 'src/business/domain/Business';

export const useReconcile = ({ businessId, startDate, endDate }: {
  businessId: BusinessId,
  startDate: Date,
  endDate: Date,
}) => {
  return useMutation({
    mutationFn: () => reconcilePOSOperationsWithSundayPayments(
      businessId, startDate, endDate,
    ),
  });
};
