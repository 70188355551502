import styled from 'styled-components';
import { colorUsage } from '@sundayapp/b2b-react-component-library';

type DayCheckBoxProps = {
  dayName: string;
  checked: boolean;
  isChecked: (value: boolean) => void;
};

type CheckedBoxProps = {
  checked: boolean;
};

export const CheckedBox = styled.div<CheckedBoxProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.checked ? colorUsage.primaryBackground : colorUsage.secondaryBackground)};
  color: ${(props) => (props.checked ? colorUsage.clearText : colorUsage.secondaryText)};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
`;

const DayCheckBox = ({ dayName, checked, isChecked }: DayCheckBoxProps) => {
  const displayedDayName = dayName.substring(0, 1);
  return (
    <CheckedBox checked={checked} onClick={() => isChecked(!checked)}>{displayedDayName}</CheckedBox>
  );
};

export default DayCheckBox;
