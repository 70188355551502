import React from 'react';
import { Line } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';
import { useTheme } from '@mui/material';
import ChartDataLabels, { Context } from 'chartjs-plugin-datalabels';
import { useSelector } from 'react-redux';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { getEReputationPageStatus } from 'src/store/e-reputation';
import { getChartXAxisLabels } from './XAxisLabels';
import { RatingsAverageGraphData, StarsDistributionChartData } from '../EReputationViewModel';
import { tooltipLabel } from './tooltipLabel';
import { NoEreputationData } from 'src/sundayplus/components/NoEreputationData';

type Props = {
  graphKpis: RatingsAverageGraphData | undefined;
};
export const RatingsAverageGraph = ({ graphKpis }: Props) => {
  const theme = useTheme();
  const intl = useIntl();
  const pageStatus = useSelector(getEReputationPageStatus);
  const withSundayCaption = intl.formatMessage({ id: 'reviews.ereputation.graph.average.caption.with_sunday' });
  const withoutSundayCaption = intl.formatMessage({ id: 'reviews.ereputation.graph.average.caption.without_sunday' });

  if (!graphKpis) {
    return <NoEreputationData theme={theme} />;
  }

  const data: ChartData<'line'> = {
    labels: getChartXAxisLabels(pageStatus.timeFrame, Date.now(), intl),
    datasets: [
      {
        label: withSundayCaption,
        data: graphKpis.fromSunday.map((kpi: StarsDistributionChartData) => (kpi.value === 0 ? null : kpi.value)),
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
      },
      {
        datalabels: {
          color: 'white',
        },
        label: withoutSundayCaption,
        data: graphKpis.fromGoogle.map((n) => (n === 0 ? null : n)),
        borderColor: '#4285F4',
        backgroundColor: '#4285F4',
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 30,
        right: 30,
        top: 30,
      },
    },
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          boxWidth: 40,
        },
        position: 'bottom',
      },
      datalabels: {
        // @ts-ignore
        backgroundColor: (context: Context) => context.dataset.backgroundColor,
        color: 'black',
        borderRadius: 24,
        borderWidth: 15,
      },
      tooltip: {
        mode: 'nearest',
        padding: 12,
        bodySpacing: 4,
        position: 'average',
        intersect: false,
        displayColors: false,
        filter: ({ dataset }) => dataset.label === withSundayCaption,
        callbacks: {
          label: (ctx) => tooltipLabel(graphKpis.fromSunday, ctx.dataIndex),
        },
      },
    },
    scales: {
      yAxes: {
        position: 'left',
        display: true,
        offset: true,
        max: 5,
        border: {
          color: 'white',
        },
        grid: {
          tickColor: 'white',
        },
      },
      xAxes: {
        display: true,
        border: {
          color: 'white',
        },
      },
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return <Line options={options} data={data} plugins={[ChartDataLabels]} />;
};
