import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import Textarea from '../../../../../common/components/Textarea';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { BoxDetails, CustomMessagesJson } from '../../../../domain/BoxDetails';
import { Venue } from 'src/ordering/venues/types';
import { SegmentedMessage } from 'sms-segments-calculator';

export type OrderLineBox = Pick<BoxDetails, 'customMessagesJson' | 'name'>;
export type OrderLineVenue = Pick<Venue, 'name' | 'isFoodCourt'>;
type OrderNotificationMessageLineProps = {
  venue: OrderLineVenue;
  box: OrderLineBox;
  disabled: boolean;
  setMessage: (updatedMessage: string, locale: string) => void;
  locale: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(2)};
`;

const Label = styled.div`
  display: inline;
  background-color: white;
  font-size: 12px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  position: relative;
  top: ${getSpacing(1)};
  left: ${getSpacing(2.5)};
  width: min-content;
  padding-inline: 0.5rem;

`;

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const Description = styled.div<{ segmentsCount: number }>`
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.segmentsCount > 1 ? colorPalette.orange : colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
  margin-top: ${getSpacing(1)};
`;

const getCustomOrderNotificationMessage = (messages: CustomMessagesJson | undefined, locale: string): string => {
  if (messages === undefined || messages.SmsForBoxOrderReady === undefined) {
    return '';
  }

  if (messages.SmsForBoxOrderReady[locale]) {
    return messages.SmsForBoxOrderReady[locale];
  }

  return messages.SmsForBoxOrderReady?.und ?? '';
};
const defaultMessage = (venueName: string, isFoodCourt: boolean, boxName: string, locale: string) => {
  return locale === 'fr'
    ? `allez à ${venueName} ${isFoodCourt ? boxName : ''} pour récupérer votre commande #NUM#`
    : `hey! quickly go to ${venueName}${isFoodCourt ? ` ${boxName}` : ''} to pick up your order #NUM#`;
};
const translatedMessage = (box: OrderLineBox, venue: OrderLineVenue, locale: string) => {
  return getCustomOrderNotificationMessage(box.customMessagesJson, locale)
    || defaultMessage(venue.name, venue.isFoodCourt, box.name, locale);
};

const getSegmentsCountFromText = (t: string) : number => {
  const segment = new SegmentedMessage(t);
  return segment.segmentsCount;
};

export const OrderNotificationMessageLine = ({
  venue,
  box,
  disabled,
  setMessage,
  locale,
}: OrderNotificationMessageLineProps) => {

  const intl = useIntl();
  // If no custom message is defined, display the default one
  const message = translatedMessage(box, venue, locale);

  const [text, setText] = useState<string>(message);
  const [segmentsCount, setSegmentsCount] = useState<number>(getSegmentsCountFromText(message));

  const isUpdated = text !== message;

  const handleSave = () => {
    const messageToSave = text || defaultMessage(venue.name, venue.isFoodCourt, box.name, locale);
    setMessage(messageToSave, locale);
    setText(messageToSave);
  };
  const french = intl.formatMessage({
    id: 'venue.setting.orderNotificationMessageLine.language.french',
    defaultMessage: 'French',
  });
  const english = intl.formatMessage({
    id: 'venue.setting.orderNotificationMessageLine.language.english',
    defaultMessage: 'English',
  });
  const localeLabel = locale === 'fr' ? french : english;

  const updateTextValue = (t: string) : void => {
    setSegmentsCount(getSegmentsCountFromText(t));
    setText(t);
  };

  return (
    <Container>
      <Label>{localeLabel}</Label>
      <Textarea value={text} onValueChange={updateTextValue} disabled={disabled} />
      <Description segmentsCount={segmentsCount}>
        <FormattedMessage
          id="venue.setting.orderNotificationMessageLine.cost"
          defaultMessage="This message will cost {segmentsCount} SMS to send"
          values={{ segmentsCount }}
        />
      </Description>
      {isUpdated && (
        <Line>
          <SaveButton
            variant="primary"
            size="xSmall"
            onClick={() => handleSave()}
            disabled={disabled}
          >
            <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
          </SaveButton>
        </Line>
      )}
    </Container>
  );
};
