import { Card, Stack, Step, StepContent, StepIconProps, StepLabel, Stepper, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Check } from '@mui/icons-material';
import { palette } from 'src/organization-report/design/palette';
import OnboardingStepComponent from 'src/onboarding/components/OnboardingStepComponent';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';
import { OnboardingHeader } from 'src/onboarding/components/OnboardingHeader';
import { computeSteps } from 'src/onboarding/domain/computeSteps';
import { HttpWatchTowerRepository } from 'src/intercom/infrastructure/HttpWatchTowerRepository';
import axios from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { OnboardingStep } from 'src/onboarding/domain/OnboardingStep';


const StepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ ownerState }) => ({
    color: ownerState.active ? palette.primary.white : palette.primary.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: '50%',
    backgroundColor: ownerState.active ? palette.primary.electricPink : palette.neutral.grey300,
    '& .StepIcon-completedIcon': {
      zIndex: 1,
      fontSize: 18,
    },
    '& .StepIcon-circle': {
      textAlign: 'center',
    },
  }),
);

//TODO
// eslint-disable-next-line react/display-name
const computeStepIcon = (position: number, stepIsCompleted: boolean) => ({
  active,
  className,
}: StepIconProps) => {

  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {stepIsCompleted ? (
        <Check className="StepIcon-completedIcon" />
      ) : (
        <div className="StepIcon-circle">{position}</div>
      )}
    </StepIconRoot>
  );
};

const Onboarding = () => {
  const [activeStepPosition, setActiveStepPosition] = useState(0);
  const business = useCurrentBusinessOrThrow();
  const { isMobileScreen } = useMediaBreakpoints();
  const [steps, setSteps] = useState<OnboardingStep[]>([]);

  const theme = useTheme();

  const configuration = ConfigurationLoader.load();
  const repository = new HttpWatchTowerRepository(axios, configuration);

  const findFirstIncompleteStep = (onboardingSteps: OnboardingStep[]) => {
    for (let i = 0; i < onboardingSteps.length; i++) {
      if (!onboardingSteps[i].isCompleted) {
        return i;
      }
    }
    return 0;
  };

  useEffect(() => {
    repository.getOnboardingBusinessInfo(business.id).then((onboardingBusinessInfo) => {
      const onboardingSteps = computeSteps(business, onboardingBusinessInfo);
      setSteps(onboardingSteps);
      setActiveStepPosition(findFirstIncompleteStep(onboardingSteps));
    });
  }, [business.id]);


  return <>
    <OnboardingHeader />
    <Typography variant={isMobileScreen ? 'body2' : 'body1'} sx={{ m: 2 }} color={theme.palette.text.disabled}>
      <FormattedMessage id={'onboarding.stepper.description'} />
    </Typography>
    <Card sx={{ p: 2 }}>
      <Stepper orientation="vertical" activeStep={activeStepPosition}>
        {
          steps.map((step, position) => (
            <Step key={step.labelKey} onClick={() => setActiveStepPosition(position)}>
              <StepLabel StepIconComponent={computeStepIcon(position + 1, step.isCompleted)}>
                <Stack>
                  <Typography><FormattedMessage id={step.labelKey} /></Typography>
                </Stack>
              </StepLabel>
              <StepContent>
                <OnboardingStepComponent step={step} />
              </StepContent>
            </Step>
          ))
        }
      </Stepper>
    </Card>
  </>;
};

export default Onboarding;
