/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const InformationSvg: React.FC<Props> = ({
  size = 20, color = themeV5.palette.text.primary, ...props
}: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1826_106229)">
      <path d="M7.99967 10.6666V7.99998M7.99967 5.33331H8.00634M14.6663 7.99998C14.6663 11.6819 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6819 1.33301 7.99998C1.33301 4.31808 4.31778 1.33331 7.99967 1.33331C11.6816 1.33331 14.6663 4.31808 14.6663 7.99998Z" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1826_106229">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InformationSvg;
