import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { CashupStatus, ReconciliationReport } from '../domain/ReconciliationReport';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

const listReportsQuery = (businessId: BusinessId) => ({
  queryKey: [queryKeys.BUSINESSES, businessId, queryKeys.RECONCILIATION_REPORT, 'reports'],
  queryFn: async (): Promise<ReconciliationReport[]> => new ReconciliationRepositoryHttp().listAllReports(businessId),
});

export const useListReconciliationReportsByStatus = (
  businessId: BusinessId,
  status: CashupStatus | undefined,
  optionalRange?: [Date, Date],
) => {
  const query = listReportsQuery(businessId);
  return useQuery<ReconciliationReport[]>({
    queryKey: query.queryKey,
    queryFn: query.queryFn,
    enabled: !!businessId,
    select: (list) =>
      list
        .filter((report) => !status || report.status === status)
        .filter(
          (report) =>
            !optionalRange ||
            (report.shift.dateOfService >= optionalRange[0] && report.shift.dateOfService <= optionalRange[1]),
        ),
  });
};
