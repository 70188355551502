/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function ElectronIcon(props: PaymentMethodIconProps) {
  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="39" height="23" rx="2.5" fill="white" />
      <path
        d="M22.1173 17.131H21.2926V17.8986H22.2152V18.1294H21.0146V15.9997H22.1679V16.2305H21.2926V16.9034H22.1173V17.131Z"
        fill="#102A94"
      />
      <path d="M22.6392 15.8862H22.9171V18.1294H22.6392V15.8862Z" fill="#102A94" />
      <path
        d="M24.4339 17.2166C24.4371 17.0429 24.3612 16.768 24.0485 16.768C23.7641 16.768 23.644 17.024 23.6218 17.2166H24.4339ZM23.6188 17.4157C23.6251 17.7918 23.8621 17.9466 24.1431 17.9466C24.3423 17.9466 24.4655 17.9118 24.5667 17.8677L24.6172 18.0667C24.5192 18.1108 24.3487 18.1646 24.1053 18.1646C23.6347 18.1646 23.3535 17.8518 23.3535 17.3905C23.3535 16.9292 23.6251 16.569 24.0706 16.569C24.573 16.569 24.7026 17.0051 24.7026 17.2863C24.7026 17.343 24.6993 17.3841 24.6929 17.4157H23.6188Z"
        fill="#102A94"
      />
      <path
        d="M26.2166 18.076C26.144 18.1108 25.9828 18.1646 25.7775 18.1646C25.3162 18.1646 25.0161 17.8518 25.0161 17.3841C25.0161 16.9134 25.3382 16.569 25.8375 16.569C26.0017 16.569 26.147 16.6099 26.2229 16.6511L26.1599 16.8628C26.0933 16.828 25.989 16.79 25.8375 16.79C25.4867 16.79 25.2973 17.0522 25.2973 17.3684C25.2973 17.7222 25.5247 17.9402 25.828 17.9402C25.986 17.9402 26.0902 17.9023 26.1692 17.8675L26.2166 18.076Z"
        fill="#102A94"
      />
      <path
        d="M26.9961 16.2341V16.6006H27.394V16.8121H26.9961V17.6369C26.9961 17.8266 27.0498 17.9339 27.2046 17.9339C27.2803 17.9339 27.3247 17.9277 27.3658 17.9148L27.3784 18.1267C27.3247 18.1456 27.2394 18.1646 27.1319 18.1646C27.0023 18.1646 26.8981 18.1203 26.8318 18.0478C26.756 17.9623 26.7244 17.8266 26.7244 17.6465V16.8121H26.4873V16.6006H26.7244V16.3162L26.9961 16.2341Z"
        fill="#102A94"
      />
      <path
        d="M27.7822 17.0772C27.7822 16.8971 27.7788 16.7423 27.7695 16.6001H28.0128L28.0255 16.9034H28.0348C28.1044 16.6981 28.2749 16.5685 28.4613 16.5685C28.4898 16.5685 28.512 16.5717 28.5373 16.5747V16.8371C28.5088 16.8307 28.4805 16.8307 28.4424 16.8307C28.2467 16.8307 28.1075 16.976 28.0696 17.1847C28.0634 17.2226 28.0602 17.2699 28.0602 17.3143V18.1294H27.7822V17.0772Z"
        fill="#102A94"
      />
      <path
        d="M29.0362 17.3684C29.0362 17.7033 29.2257 17.956 29.4974 17.956C29.7629 17.956 29.9618 17.7063 29.9618 17.362C29.9618 17.1029 29.8323 16.7773 29.5037 16.7773C29.1784 16.7773 29.0362 17.0808 29.0362 17.3684ZM30.2463 17.3525C30.2463 17.918 29.8513 18.1646 29.4848 18.1646C29.074 18.1646 28.752 17.8611 28.752 17.3778C28.752 16.8691 29.0899 16.569 29.5101 16.569C29.9493 16.569 30.2463 16.888 30.2463 17.3525Z"
        fill="#102A94"
      />
      <path
        d="M30.6723 17.0136C30.6723 16.8525 30.669 16.7261 30.6597 16.5998H30.906L30.9219 16.8525H30.9282C31.004 16.7103 31.1809 16.5682 31.4337 16.5682C31.6453 16.5682 31.9739 16.6945 31.9739 17.2189V18.1289H31.6959V17.2474C31.6959 17.001 31.6044 16.7956 31.3421 16.7956C31.162 16.7956 31.0197 16.9252 30.9694 17.08C30.9565 17.1148 30.9503 17.1621 30.9503 17.2096V18.1289H30.6723V17.0136Z"
        fill="#102A94"
      />
      <path
        d="M19.6111 7.14261L17.9716 14.9608H15.9886L17.6281 7.14261H19.6111ZM27.9531 12.1908L28.9973 9.25485L29.5971 12.1908H27.9531ZM30.1666 14.9608H32L30.3982 7.14261H28.7066C28.3251 7.14261 28.0045 7.36844 27.8622 7.71574L24.8868 14.9608H26.9691L27.3821 13.7929H29.9262L30.1666 14.9608ZM24.9902 12.4082C24.9985 10.3452 22.1936 10.2307 22.212 9.30887C22.2187 9.0287 22.4804 8.7303 23.0532 8.65407C23.337 8.61667 24.1209 8.58678 25.0088 9.00408L25.3562 7.34526C24.8791 7.1693 24.2652 6.99988 23.5015 6.99988C21.5412 6.99988 20.1622 8.06192 20.1512 9.58393C20.1384 10.7097 21.1362 11.3372 21.8865 11.7122C22.6595 12.0951 22.9186 12.3412 22.915 12.6834C22.9095 13.208 22.2978 13.4402 21.7291 13.4488C20.7318 13.465 20.1536 13.1739 19.6925 12.9548L19.3325 14.6686C19.7965 14.8855 20.6515 15.0739 21.5371 15.0835C23.621 15.0835 24.9841 14.0336 24.9902 12.4082ZM16.7775 7.14261L13.5648 14.9608H11.4686L9.88752 8.72119C9.79178 8.33778 9.70842 8.19665 9.41667 8.03459C8.93988 7.7704 8.15253 7.52331 7.46045 7.36956L7.50714 7.14261H10.8817C11.3112 7.14261 11.6979 7.43429 11.7961 7.93933L12.6316 12.4638L14.694 7.14261H16.7775Z"
        fill="#102A94"
      />
      <mask id="mask0_1288_7515" maskUnits="userSpaceOnUse" x="7" y="7" width="25" height="9">
        <path
          d="M19.6111 7.14261L17.9716 14.9608H15.9886L17.6281 7.14261H19.6111ZM27.9531 12.1908L28.9973 9.25485L29.5971 12.1908H27.9531ZM30.1666 14.9608H32L30.3982 7.14261H28.7066C28.3251 7.14261 28.0045 7.36844 27.8622 7.71574L24.8868 14.9608H26.9691L27.3821 13.7929H29.9262L30.1666 14.9608ZM24.9902 12.4082C24.9985 10.3452 22.1936 10.2307 22.212 9.30887C22.2187 9.0287 22.4804 8.7303 23.0532 8.65407C23.337 8.61667 24.1209 8.58678 25.0088 9.00408L25.3562 7.34526C24.8791 7.1693 24.2652 6.99988 23.5015 6.99988C21.5412 6.99988 20.1622 8.06192 20.1512 9.58393C20.1384 10.7097 21.1362 11.3372 21.8865 11.7122C22.6595 12.0951 22.9186 12.3412 22.915 12.6834C22.9095 13.208 22.2978 13.4402 21.7291 13.4488C20.7318 13.465 20.1536 13.1739 19.6925 12.9548L19.3325 14.6686C19.7965 14.8855 20.6515 15.0739 21.5371 15.0835C23.621 15.0835 24.9841 14.0336 24.9902 12.4082ZM16.7775 7.14261L13.5648 14.9608H11.4686L9.88752 8.72119C9.79178 8.33778 9.70842 8.19665 9.41667 8.03459C8.93988 7.7704 8.15253 7.52331 7.46045 7.36956L7.50714 7.14261H10.8817C11.3112 7.14261 11.6979 7.43429 11.7961 7.93933L12.6316 12.4638L14.694 7.14261H16.7775Z"
          fill="#102A94"
        />
      </mask>
      <g mask="url(#mask0_1288_7515)">
        <path
          d="M12.2116 10.3678C11.9421 9.4247 10.4152 8.57143 9.68546 8.26268L6.48569 8.34688L5.98047 6.41018L6.99092 5.65234H14.4851L14.6535 6.99961L13.1378 10.1152C12.9414 10.5923 12.481 11.3109 12.2116 10.3678Z"
          fill="#F7B600"
        />
      </g>
    </svg>
  );
}
