import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Review } from '../browse/domain/Review';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpReviewRepository } from '../browse/infrastructure/HttpReviewRepository';
import OpenAiSvg from '../browse/allReviews/components/OpenAiSvg';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { featureReviewsAiSuggestions } from './featureReviewsAiSuggestions';
import { AiReplySettings } from './ai/AiReplySettings';
import { useAiSettings } from './ai/useAiSettings';
import { CircularProgress } from '@mui/material';

type Props = {
  review: Review;
  onSuggest: (suggestion: string) => void;
};

export const SuggestReplyBasedOnOpenAILoaded = ({ review, onSuggest, settings }: Props & { settings: AiReplySettings }) => {
  const { name: businessName } = useCurrentBusinessOrThrow();
  const showSuggestReply = featureReviewsAiSuggestions();
  const configuration = ConfigurationLoader.load();
  const reviewRepositoryHttp = new HttpReviewRepository(configuration.venueFeedbackBaseUrl);

  const [loadingOnSuggest, setLoadingOnSuggest] = useState(false);

  const suggestReply = async () => {
    setLoadingOnSuggest(true);
    const suggestedReply = await reviewRepositoryHttp.suggestReply(businessName, settings.replyLanguage, settings.guessLanguage,  review.feedback, review.rating);
    setLoadingOnSuggest(false);
    if (!suggestedReply) return;
    ampli.openAiReplySuggested({ suggestedReply });
    onSuggest(suggestedReply);
  };

  return (
    <>
      {showSuggestReply && (
        <LoadingButton
          loading={loadingOnSuggest}
          fullWidth
          variant="contained"
          onClick={suggestReply}
          startIcon={<OpenAiSvg size={32} color="white" />}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
          }}
        >
          <FormattedMessage id="venue.reviews.reply.suggest" defaultMessage="suggest" />
        </LoadingButton>
      )}
    </>
  );
};

export const SuggestReplyBasedOnOpenAI = ({ review, onSuggest }: Props) => {
  const { data: settings, isLoading } = useAiSettings();

  if (isLoading || !settings) return <CircularProgress />;
  return <SuggestReplyBasedOnOpenAILoaded review={review} onSuggest={onSuggest} settings={settings} />;
};
