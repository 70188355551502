import {
  styled, Tooltip, tooltipClasses, TooltipProps,
} from '@mui/material';

export const ThemeTooltip = styled(({
  className, placement = 'right', arrow = false, ...props
}: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} placement={placement} arrow={arrow} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
    fontSize: '12px',
    lineHeight: '14px',
    letter: '-0,3px',
    padding: '8px',
  },
});
