import { CsvReaderRowBuilder } from 'src/utils/csv/CsvReaderRowBuilder';
import { ReplyTemplateDraft } from '../domain/ReplyTemplate';

export class CreateReplyTemplateBuilder implements CsvReaderRowBuilder<ReplyTemplateDraft> {
  private replyTemplateDrafts: ReplyTemplateDraft[] = [];

  add(fields: string[]): this {
    if (fields.length >= 2) {
      this.addReplyTemplate(fields[0], fields[1], fields[2]);
    }

    return this;
  }

  parseRatings = (ratingCsv: string) => ratingCsv.split('-').map((s) => Number(s));

  addReplyTemplate(name: string, content: string, applicableRatings: string): this {
    this.replyTemplateDrafts.push({
      name, content, applicableRatings: this.parseRatings(applicableRatings), prefilled: false,
    });
    return this;
  }

  build(): ReplyTemplateDraft[] {
    return this.replyTemplateDrafts;
  }
}
