import { Form } from 'react-router';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, Divider, FormControl, Modal, Stack, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import InfoIcon from '@mui/icons-material/Info';
import { FormValues, PaymentMethodsProps, PaymentsMethodsFields } from './types';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { SelectPreferredValue } from './SelectPreferredValue';
import { SelectMappedValues } from './SelectMappedValues';
import { ReadOnlyPaymentMethods } from './ReadOnlyPaymentMethods';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { usePaymentMethods } from 'src/pages/Settings/PaymentMethodMapping/usePaymentMethods';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import { UseMutationResult } from '@tanstack/react-query';
import { LocalisationKey } from 'src/lang/en';

export const paymentMethodNameLocalisations: Record<string, LocalisationKey> = {

  ['SUNDAY']: 'settings.posPaymentMethod.method.sunday',
  ['CARD']: 'settings.posPaymentMethod.method.card',
  ['CASH']: 'settings.posPaymentMethod.method.cash',
  ['CARD_AMEX']: 'settings.posPaymentMethod.method.amex',
  ['CARD_AMEX_KAPN']: 'settings.posPaymentMethod.method.amex',
  ['CARD_DISCOVER']: 'settings.posPaymentMethod.method.discover',
  ['CARD_BIMPLI']: 'settings.posPaymentMethod.method.bimpli',
  ['CARD_CB']: 'settings.posPaymentMethod.method.cb',
  ['CARD_EDENRED']: 'settings.posPaymentMethod.method.edenred',
  ['CARD_ELECTRON']: 'settings.posPaymentMethod.method.electron',
  ['CARD_KADEOS']: 'settings.posPaymentMethod.method.kadeos',
  ['CARD_MAESTRO']: 'settings.posPaymentMethod.method.maestro',
  ['CARD_MASTERCARD']: 'settings.posPaymentMethod.method.mastercard',
  ['CARD_MASTERCARD_DEBIT']: 'settings.posPaymentMethod.method.mastercard',
  ['CARD_NATIXIS_INTERTITRES']: 'settings.posPaymentMethod.method.natixis',
  ['CARD_SODEXO']: 'settings.posPaymentMethod.method.pluxee',
  ['CARD_PLUXEE']: 'settings.posPaymentMethod.method.pluxee',
  ['CARD_SWILE']: 'settings.posPaymentMethod.method.swile',
  ['CARD_UP_DEJ']: 'settings.posPaymentMethod.method.updej',
  ['CARD_VISA']: 'settings.posPaymentMethod.method.visa',
  ['CARD_VISA_DEBIT']: 'settings.posPaymentMethod.method.visa',
  ['CARD_CHEQUE_VACANCES']: 'settings.posPaymentMethod.method.chequevacances',
  ['CARD_CHEQUE_DEJEUNER']: 'settings.posPaymentMethod.method.chequedejeuner',
  ['CARD_JCB']: 'settings.posPaymentMethod.method.jcb',
  ['CARD_CONECS']: 'settings.posPaymentMethod.method.conecs',
  ['CARD_DCC']: 'settings.posPaymentMethod.method.dcc',
  ['CARD_CB_CONTACTLESS']: 'settings.posPaymentMethod.method.cb_contactless',
  ['LOYALTY_REWARD_APPLIED_VIA_SUNDAY']: 'settings.posPaymentMethod.method.loyalty_reward_applied_via_sunday',
};


const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9fa;
  padding: 24px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormPanel = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PaymentMethodsFormAlreadyLoaded = ({
  paymentMethods,
  readOnlyPaymentMethods,
  submitPaymentsMethods,
}: {
  paymentMethods: PaymentMethodsProps[];
  readOnlyPaymentMethods: PaymentMethodsProps[];
  submitPaymentsMethods: UseMutationResult<void, unknown, PaymentsMethodsFields[], unknown>;
}) => {
  const { formatMessage } = useIntl();
  const useSnackBar = useSnackbar();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: {
      isValid,
      isSubmitting,
    },
  } = useForm<FormValues>({
    mode: 'all',
    defaultValues: {
      paymentMethods: paymentMethods.map((posPaymentMethod) => ({
        sundayCode: posPaymentMethod.sundayCode,
        preferredValue: posPaymentMethod.preferredValue,
        mappedValues: posPaymentMethod.mappedValues,
      })),
    },
  });

  const onSubmit = (data: FormValues) => {
    handleClose();
    submitPaymentsMethods
      .mutateAsync(data.paymentMethods)
      .then(() =>
        useSnackBar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'settings.app_settings.success_saving' }),
        }),
      )
      .catch(() =>
        useSnackBar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'settings.app_settings.error_saving' }),
        }),
      );
    reset(data, { keepDirty: false });
  };

  if (paymentMethods.length < 1 && readOnlyPaymentMethods.length < 1) {
    return (
      <Typography>
        <FormattedMessage id="settings.posPaymentMethod.not_supported_by_pos" />
      </Typography>
    );
  }

  return (
    <>
      <Stack display="flex" direction="row" gap={4} padding={2} key="stack-container-readonly">
        <Box width="100%">
          <Typography color="text.secondary">
            <FormattedMessage id="settings.posPaymentMethod.sundayCode" />
            <ThemeTooltip title={formatMessage({ id: 'settings.posPaymentMethod.sundayCode.toolpit' })}>
              <InfoIcon />
            </ThemeTooltip>
          </Typography>
          <Typography color="text.secondary">{'(sunday -> POS)'}</Typography>
        </Box>
        <Box width="100%">
          <Typography color="text.secondary">
            <FormattedMessage id="settings.posPaymentMethod.primaryValue" />
            <ThemeTooltip title={formatMessage({ id: 'settings.posPaymentMethod.primaryValue.toolpit' })}>
              <InfoIcon />
            </ThemeTooltip>
          </Typography>
          <Typography color="text.secondary">{'(POS -> sunday)'}</Typography>
        </Box>
        <Typography width="100%" color="text.secondary">
          <FormattedMessage id="settings.posPaymentMethod.allowedValues" />
          <ThemeTooltip title={formatMessage({ id: 'settings.posPaymentMethod.allowedValues.toolpit' })}>
            <InfoIcon />
          </ThemeTooltip>
        </Typography>
      </Stack>
      <Divider />
      <ReadOnlyPaymentMethods paymentMethods={readOnlyPaymentMethods} />
      <Divider />
      <FormPanel>
        {paymentMethods.map((paymentMethod: PaymentMethodsProps, index: number) => (
          <>
            <Stack display="flex" direction="row" gap={4} alignItems={'center'} padding={2}
                   key={`stack-container-${index}`}>
              <FormControl fullWidth>
                <Typography aria-labelledby="sunday-code">
                  <FormattedMessage
                    id={paymentMethodNameLocalisations[paymentMethod.sundayCode] ?? 'settings.posPaymentMethod.method.undefined'}
                    defaultMessage={paymentMethod.sundayCode} />
                </Typography>

              </FormControl>

              <Controller
                render={({ field: { value } }) => (
                  <SelectPreferredValue
                    allowedValues={paymentMethod.allowedValues}
                    setValue={setValue}
                    getValues={getValues}
                    value={value}
                    index={index}
                  />
                )}
                control={control}
                name={`paymentMethods.${index}.preferredValue`}
              />

              <Controller
                render={({ field: { value } }) => (
                  <SelectMappedValues
                    allowedValues={paymentMethod.allowedValues}
                    getValues={getValues}
                    setValue={setValue}
                    value={value}
                    index={index}
                  />
                )}
                control={control}
                name={`paymentMethods.${index}.mappedValues`}
              />
            </Stack>
            <Divider />
          </>
        ))}

        <Button
          onClick={handleOpen}
          variant="contained"
          disabled={!isValid || isSubmitting}
        >
          <FormattedMessage id="settings.save_changes" />
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ModalBox>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <FormattedMessage id="settings.posPaymentMethod.modal_title" />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <FormattedMessage id="settings.posPaymentMethod.modal_description" />
            </Typography>
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isSubmitting}
            >
              <FormattedMessage id="settings.save_changes" />
            </Button>
          </ModalBox>
        </Modal>
      </FormPanel>
    </>
  );
};


export const PaymentMethodsForm = ({ posConnectionId }: { posConnectionId: PosConnectionId; }) => {
  const {
    isLoading,
    paymentMethods,
    submitPaymentsMethods,
  } = usePaymentMethods(posConnectionId);


  const editablePaymentMethods = paymentMethods?.filter(({ readOnly }) => !readOnly) ?? [];
  const readOnlyPaymentMethods = paymentMethods?.filter(({ readOnly }) => readOnly) ?? [];

  if (isLoading || !paymentMethods) {
    return <CircularProgress />;
  }

  return <PaymentMethodsFormAlreadyLoaded paymentMethods={editablePaymentMethods}
                                          submitPaymentsMethods={submitPaymentsMethods}
                                          readOnlyPaymentMethods={readOnlyPaymentMethods} />;
};
