import axios from 'axios';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { TipsSuggestion } from '../domain/TipsSuggestion';

export class TipsSuggestionRepositoryHttp {
  constructor(private baseUrl: string) {}

  async getTipsSuggestion(enrollmentId: EnrollmentId): Promise<TipsSuggestion> {
    const response = await axios.get(`${this.baseUrl}/enrollments/${enrollmentId}/tips-suggestion`);
    return response.data;
  }

  async setTipsSuggestion(enrollmentId: EnrollmentId, tipsSuggestion: TipsSuggestion): Promise<void> {
    await axios.put(`${this.baseUrl}/enrollments/${enrollmentId}/tips-suggestion`, tipsSuggestion);
  }
}
