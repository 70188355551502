import React from 'react';
import { FormControl, InputLabel, Select, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { CalendarTodayOutlined } from '@mui/icons-material';

export const PeriodFilter = ({
  selectedYear,
  years,
  onYearChanged,
}: {
  selectedYear: string;
  years: string[];
  onYearChanged: (year: string) => void;
}) => {
  return (
    <>
      <FormControl>
        <InputLabel id="invoice-period-select-label">
          <FormattedMessage id={'accounting.invoice_tab.period'} defaultMessage={'Period'} />
        </InputLabel>
        <Select
          labelId="invoice-period-select-label"
          id="invoice-period-select"
          value={selectedYear}
          label={<FormattedMessage id={'accounting.invoice_tab.period'} defaultMessage={'Period'} />}
          onChange={(event) => onYearChanged(event.target.value)}
          sx={{ width: '8rem' }}
          renderValue={(year: string) => (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{year}</Typography>
              <CalendarTodayOutlined />
            </Stack>
          )}
        >
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              <Typography>{year}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
