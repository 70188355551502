import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../../stylesheet';

const getDateAndTimeStrings = (timestamp: number, locale: string) => {
  const d = new Date(timestamp);
  return {
    time: d.toLocaleTimeString(locale, {
      hour: '2-digit',
      minute: '2-digit',
    }),
    date: d.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }),
  };
};
const SnoozeDetail = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-transform: lowercase;
  font-style: italic;
  padding: 0;
  color: ${colorPalette.rhino};
`;
export const SnoozeDetailContainer = ({
  snoozeStart,
  snoozeEnd,
}: { snoozeStart?: number, snoozeEnd?: number }) => {
  const { locale } = useIntl();
  return (
    <>
      {snoozeStart && snoozeEnd && (
        <SnoozeDetail>
          <FormattedMessage
            id="product.availability.details.snoozeuntil"
            defaultMessage="snooze until {date}"
            values={getDateAndTimeStrings(snoozeEnd, locale)}
          />
        </SnoozeDetail>
      )}
    </>
  );
};
