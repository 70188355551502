/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const RightArrowSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M13.0886 4.41009C13.414 4.73553 13.414 5.26317 13.0886 5.58861L8.67786 9.99935L13.0886 14.4101C13.414 14.7355 13.414 15.2632 13.0886 15.5886C12.7632 15.914 12.2355 15.914 11.9101 15.5886L6.91009 10.5886C6.58466 10.2632 6.58466 9.73553 6.91009 9.41009L11.9101 4.41009C12.2355 4.08466 12.7632 4.08466 13.0886 4.41009Z"
          fill={color} />
  </svg>

);

export default RightArrowSvg;
