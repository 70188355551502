import { useVenueRepository } from '../venues/hook';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { PromoCodeAmount } from './types';

export const useGetPromoCodes = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn(
    async (enrollmentId: EnrollmentId) => venueRepository.getPromoCodes(enrollmentId),
    [venueRepository],
  );
};

export const useSelectPromoCodeDetails = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn(
    async (voucherId: string) => venueRepository.getPromoCode(voucherId),
    [venueRepository],
  );
};

export const useUpdatePromoCode = (enrollmentId: EnrollmentId, voucherId: string) => {
  const venueRepository = useVenueRepository();
  return useAsyncFn(
    async (promoCode) => {
      if (!promoCode.voucherId) {
        const newId = await venueRepository.createPromoCode(enrollmentId, promoCode);
        return { ...promoCode, voucherId: newId };
      }
      await venueRepository.updatePromoCode(voucherId, promoCode);
      return promoCode;
    },
    [venueRepository, enrollmentId, voucherId],
  );
};
export const useCreatePromoCodeAmount = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn(
    async (promoCode: Partial<PromoCodeAmount>, enrollmentId: EnrollmentId) => {
      const newId = await venueRepository.createPromoCodeAmount(enrollmentId, promoCode);
      return { ...promoCode, voucherId: newId };
    },
    [venueRepository],
  );
};

export const useDeletePromoCode = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn(
    async (voucherId: string) => venueRepository.deletePromoCode(voucherId),
    [venueRepository],
  );
};
