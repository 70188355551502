import { AxiosInstance } from 'axios';
import { Configuration } from 'src/configuration/Configuration';
import { WatchTowerRepository } from '../domain/WatchTowerRepository';
import { OnboardingBusinessInfo } from '../domain/OnboardingBusinessInfo';

export class HttpWatchTowerRepository implements WatchTowerRepository {
  constructor(
    private axios: AxiosInstance,
    private configuration: Configuration,
  ) {
  }

  async getOnboardingBusinessInfo(
    businessId: string,
  ): Promise<OnboardingBusinessInfo> {
    const url = `${this.configuration.auditTrailApiBaseUrl}/businesses/${businessId}/onboarding`;
    const response = await this.axios.get<OnboardingBusinessInfo>(url);
    return response.data;
  }


}
