import { AxiosInstance, AxiosResponse } from 'axios';
import { OrderSummary } from '../domain/OrderSummary';
import { Configuration } from 'src/configuration/Configuration';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export class OrderingRepositoryHttp {
  private readonly orderingApiBaseUrl: string;

  constructor(
    private httpClient: AxiosInstance,
    configuration: Pick<Configuration, 'orderingApiBaseUrl'>,
  ) {
    this.orderingApiBaseUrl = configuration.orderingApiBaseUrl;
  }

  getOrders(enrollmentId: EnrollmentId, startDate: Date, endDate: Date): Promise<OrderSummary[]> {
    const params = {
      params: { venueId: enrollmentId, afterTime: startDate.getTime(), beforeTime: endDate.getTime() },
    };
    return this.httpClient.get(`${this.orderingApiBaseUrl}/enrollments/${enrollmentId}/orders`, params)
      .then((response: AxiosResponse) => response.data.orders);
  }

  async getReceipt(orderId: string): Promise<Blob> {
    return this.httpClient.get(
      `${this.orderingApiBaseUrl}/orders/${orderId}/receipt`,
      { responseType: 'blob' },
    ).then((response: AxiosResponse) =>
      new Blob([response?.data], { type: 'application/pdf' }),
    );
  }
}
