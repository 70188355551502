// eslint-disable-next-line @typescript-eslint/no-redeclare
import { InputText } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAsync } from 'react-use';
import { AppSettingsForm, ButtonContainer, NotificationContainer } from '../AppSettings.styles';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { GlobalConfiguration } from 'src/venueconfiguration/domain/GlobalConfiguration';
import { VenueReferentialRepositoryHttp } from 'src/infrastructure/venue/VenueReferentialRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';
import { Alert, Button, Card, CardContent, Typography } from '@mui/material';

type AppSettingsWifiSettingsInputs = {
  wifiName: string;
  wifiPassword: string;
};

interface WifiSettingsHandlerProps {
  businessId: BusinessId;
  configuration: GlobalConfiguration;
}

const venueReferentialRepository = new VenueReferentialRepositoryHttp(ConfigurationLoader.load().venueReferentialUrl);
const WifiSettingsHandler = ({ businessId }: WifiSettingsHandlerProps) => {
  const useSnackBar = useSnackbar();

  const wifiCredentials = useAsync(() => venueReferentialRepository.getWifiCredentials(businessId));
  const intl = useIntl();
  const [appSettingsWifiSettingsUpdateError, setAppSettingsWifiSettingsUpdateError] = useState('');

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: {
      isValid,
      isSubmitting,
      isDirty,
    },
  } = useForm<AppSettingsWifiSettingsInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const watchWifiName = watch('wifiName');
  const watchWifiPassword = watch('wifiPassword');

  useEffect(() => {
    reset({
      wifiName: wifiCredentials.value?.ssid,
      wifiPassword: wifiCredentials.value?.password,
    });
  }, [wifiCredentials]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));

  const onSubmit = async (inputs: AppSettingsWifiSettingsInputs) => {
    setAppSettingsWifiSettingsUpdateError('');
    try {
      await venueReferentialRepository.updateWifiCredentials(businessId, {
        ssid: inputs.wifiName,
        password: inputs.wifiPassword,
      });

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setAppSettingsWifiSettingsUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  return (
    <AppSettingsForm onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            <FormattedMessage id="settings.app_settings.wifi.title" />
          </Typography>
          <InputText
            label={intl.formatMessage({ id: 'settings.app_settings.wifi.name' })}
            id="wifiName"
            placeholder={intl.formatMessage({ id: 'settings.app_settings.wifi.name.placeholder' })}
            inputIcon={!watchWifiName ? '' : 'check'}
            {...register('wifiName')}
          />
          <InputText
            label={intl.formatMessage({ id: 'settings.app_settings.wifi.password' })}
            id="wifiPassword"
            placeholder={intl.formatMessage({ id: 'settings.app_settings.wifi.password.placeholder' })}
            inputIcon={!watchWifiPassword ? '' : 'check'}
            {...register('wifiPassword')}
          />
          {appSettingsWifiSettingsUpdateError && (
            <NotificationContainer>
              <Alert severity="error">{appSettingsWifiSettingsUpdateError}</Alert>
            </NotificationContainer>
          )}
          {isValid && isDirty && (
            <ButtonContainer>
              <Button type="submit" variant="contained" size="small" disabled={!isValid || isSubmitting || !isDirty}>
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </ButtonContainer>
          )}
        </CardContent>
      </Card>
    </AppSettingsForm>
  );
};

export default WifiSettingsHandler;
