import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const UploadFrontDocument = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const { formState: { errors }, register, watch, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[legalRepresentativeIndex]?.identificationFrontDocument;
  const intl = useIntl();
  const frontDocumentId = watch(`representatives.${legalRepresentativeIndex}.identificationFrontDocument`);

  const onFileUploaded = (documentId: string) => {
    setValue(`representatives.${legalRepresentativeIndex}.identificationFrontDocument`, documentId);
  };
  const onDelete = () => {
    setValue(`representatives.${legalRepresentativeIndex}.identificationFrontDocument`, '');
  };
  return <UploadDocument
    documentId={frontDocumentId}
    onRegistered={register(`representatives.${legalRepresentativeIndex}.identificationFrontDocument`,
      { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationFrontDocument.error' }) })}
    onFileUploaded={onFileUploaded}
    onDelete={onDelete}
    error={error}
    purpose={'identity_verification'}
    documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationFrontDocument' })}
    helperText={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationDocument.helperText' })}
  />;
};
