import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { AllergenRepositoryHttp } from '../../repositories/allergen/AllergenRepositoryHttp';
import { AllergenTranslation } from '../../domain/AllergenTranslations';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_ALLERGEN_TRANSLATIONS = 'menu_allergen_translations';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_MENU_ALLERGEN_TRANSLATIONS, businessId] as const;
export const getAllAllergenTranslationsByBusinessIdQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<AllergenTranslation[]> => {
  const [, businessId] = queryKey;

  const configuration = ConfigurationLoader.load();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return allergenRepository.findTranslationsByAllergenId(businessId);
};

export const useGetAllAllergenTranslationsByBusinessId = (businessId: BusinessId) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: getAllAllergenTranslationsByBusinessIdQuery });
