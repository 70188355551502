import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsTips = () => {
  //
  // useEffect(() => {
  //   ampli.dataAnalyticsTipsClicked({
  //     businessId: business.id,
  //     businessName: business.name,
  //   });
  // }, []);

  return <DataAnalyticsIframeComponent dashboard={MetabaseDashboard.TIPS_ENGLISH} />;
};
