/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const CrossIcon: React.FC<IconProps> = ({ size = 22, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.69682 16.3032C5.45275 16.0591 5.45275 15.6634 5.69682 15.4193L15.4195 5.69661C15.6636 5.45253 16.0593 5.45253 16.3034 5.69661C16.5475 5.94068 16.5475 6.33641 16.3034 6.58049L6.58071 16.3032C6.33663 16.5473 5.9409 16.5473 5.69682 16.3032Z"
      fill={color}
    />
    <rect
      x="6.13867"
      y="5.25476"
      width="15"
      height="1.25"
      rx="0.625"
      transform="rotate(45 6.13867 5.25476)"
      fill={color}
    />
  </svg>
);
