import { queryKeys } from 'src/app/queries/utils';
import { useContext } from 'react';
import { StaticMenuContext } from '../../context/MenuContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StaticMenuId } from '../../domain/StaticMenus';

export const useCreateStaticMenu = () => {
  const queryClient = useQueryClient();
  const { menuRepository } = useContext(StaticMenuContext);
  const mutation = useMutation({
    mutationFn: ({ businessId, description }: { businessId: BusinessId; description: string }): Promise<StaticMenuId> =>
      menuRepository.createMenu(businessId, description),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.staticMenus.LIST_MENUS] }),
  });
  return {
    mutation,
    createStaticMenu: (businessId: BusinessId, description: string): Promise<StaticMenuId> =>
      mutation.mutateAsync({ businessId, description }),
  };
};
