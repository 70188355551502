import React, { useState } from 'react';
import {
  distributeByAmountsForEligibleStaffs,
  keepOnlyStaffWithTips,
  StaffAllocation,
} from 'src/tips/dispatch/model/StaffAllocation';
import { Box, Divider, Input, LinearProgress, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { themeV5 } from 'src/app/theme/ThemeV5';
import {
  createMoneyWithCurrency,
  CurrencyCode,
  differenceMoney,
  getCurrencySymbol,
  Money,
  money,
  moneyToUnit,
  MoneyView,
  sumMoneys,
} from '@sundayapp/web-money';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Staff } from 'src/tips/dispatch/model/Staff';
import { AmountToDispatch } from 'src/tips/dispatch/components/AmountToDispatch';
import { NumericFormat } from 'react-number-format';
import { Warning } from '@mui/icons-material';

function calcToBeAssignedAmount(tipsDispatchAmount: Money, assignedAmount: Money) {
  const toBeAssignedAmount = differenceMoney(tipsDispatchAmount, assignedAmount);
  return toBeAssignedAmount.amount > 0 ? toBeAssignedAmount : money(0, tipsDispatchAmount.currency);
}

function parseAmountForCurrency(currency: CurrencyCode) {
  return (staffAmount: string): Money => {
    const parsedAmount = parseFloat(staffAmount);
    return createMoneyWithCurrency(Number.isNaN(parsedAmount) ? 0 : parsedAmount, currency);
  };
}

const calcAssignedAmount = (staffAllocations: StaffAllocation[], currency: CurrencyCode) =>
  staffAllocations.reduce((acc, { allocation }) => sumMoneys(acc, allocation), money(0, currency));

const distributionProgress = (staffAllocations: StaffAllocation[], amount: Money) =>
  (calcAssignedAmount(staffAllocations, amount.currency).amount * 100) / amount.amount;

export const StaffDispatchByAmounts = ({
  staffs,
  amount,
  onStaffAllocationUpdated,
}: {
  staffs: Staff[];
  amount: Money;
  onStaffAllocationUpdated: (staffAllocations: StaffAllocation[]) => void;
}) => {
  const [staffAllocations, setStaffAllocations] = useState<StaffAllocation[]>(
    distributeByAmountsForEligibleStaffs(amount, staffs),
  );

  const assignedAmount = calcAssignedAmount(staffAllocations, amount.currency);
  const toBeAssignedAmount = calcToBeAssignedAmount(amount, assignedAmount);
  const parseAmount = parseAmountForCurrency(amount.currency);
  const tipsDistributionProgress = distributionProgress(staffAllocations, amount);
  const overAssigned = tipsDistributionProgress > 100;

  const onAllocationsChanged = (redistributed: StaffAllocation[]) => {
    onStaffAllocationUpdated(keepOnlyStaffWithTips(redistributed));
    setStaffAllocations(redistributed);
  };

  const onUpdateStaffAllocatedAmount = (staffId: string, staffAmount: string) => {
    const allocation = parseAmount(staffAmount);
    const updated = staffAllocations.map((staffAlloc) =>
      staffAlloc.staffId === staffId ? { ...staffAlloc, allocation } : staffAlloc,
    );

    onAllocationsChanged(updated);
  };

  const staffColor = (staffAmount: Money) =>
    staffAmount.amount > 0 ? themeV5.palette.text.primary : themeV5.palette.text.secondary;

  return (
    <Stack spacing={2.4} direction={'column'}>
      <Stack direction={'column'} spacing={4}>
        <AmountToDispatch amount={amount} />

        <LinearProgress
          variant="determinate"
          value={Math.min(100, tipsDistributionProgress)}
          color={overAssigned ? 'warning' : 'primary'}
        />

        <Stack direction={'row'}>
          <Stack direction="column" gap={1}>
            <Typography variant={'body1'}>
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                <FormattedMessage id={'tips.sunday_pooling.dispatch.amount.assigned'} />
                {overAssigned && <Warning color={'warning'} />}
              </Stack>
            </Typography>
            <Typography variant={'h6'}>
              <MoneyView value={assignedAmount} />
            </Typography>
          </Stack>

          <Stack direction="column" gap={1}>
            <Typography variant={'body1'}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.amount.to_be_assigned'} />
            </Typography>
            <Typography variant={'h6'}>
              <MoneyView value={toBeAssignedAmount} />
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Box paddingY={4}>
        <Divider />
      </Box>

      <Stack spacing={0.75} direction={'column'}>
        <Typography variant={'h6'}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.configure.title'} />
        </Typography>
        <Typography variant={'body2'} color={themeV5.palette.text.secondary}>
          <FormattedMessage id={'tips.sunday_pooling.dispatch_by_amount.configure.description'} />
        </Typography>
      </Stack>

      <Stack direction={'column'} spacing={1.2}>
        <Grid container color={themeV5.palette.text.secondary}>
          <Grid size={{ xs: 8 }} alignContent={'center'}>
            <Typography variant={'body1'}>
              <FormattedMessage id={'tips.sunday_pooling.dispatch.staff_name'} />
            </Typography>
          </Grid>
          <Grid size={{ xs: 4 }} alignContent={'center'}>
            <Typography variant={'body1'} align={'right'}>
              <FormattedMessage id={'tips.sunday_pooling.report.staff_receive'} />
            </Typography>
          </Grid>
        </Grid>
        {staffAllocations.map((staffAllocation) => (
          <Grid container key={staffAllocation.staffId} padding={'0.5rem 0 0 0'}>
            <Grid size={{ xs: 8 }} alignContent={'center'}>
              <label htmlFor={staffAllocation.staffId}>
                <Typography variant={'body1'} color={staffColor(staffAllocation.allocation)}>
                  {staffAllocation.staffName}
                </Typography>
              </label>
            </Grid>
            <Grid size={{ xs: 4 }}>
              <Stack direction="row" gap={1} justifyContent="end">
                <NumericFormat
                  customInput={Input}
                  required
                  allowedDecimalSeparators={[',', '.']}
                  decimalScale={2}
                  value={moneyToUnit(staffAllocation.allocation)}
                  onChange={(event) => onUpdateStaffAllocatedAmount(staffAllocation.staffId, event.target.value)}
                  autoComplete="off"
                  sx={{
                    textAlignLast: 'end',
                    alignItems: 'center',
                    width: '6rem',
                    '& .MuiInput-input': {
                      padding: 0,
                    },
                  }}
                  slotProps={{
                    input: {
                      maxLength: 8,
                      step: 0.1,
                      min: 0,
                    },
                  }}
                />
                <Typography>{getCurrencySymbol(amount.currency)}</Typography>
              </Stack>
            </Grid>
          </Grid>
        ))}
      </Stack>
    </Stack>
  );
};
