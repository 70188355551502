import { UseFormRegister } from 'react-hook-form';
import { Stack, TextField } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export type LandingTextCustomizationFormData = {
  shortWelcome: string;
  description: string;
};

export function LandingTextCustomization({ register }: {
  register: UseFormRegister<LandingTextCustomizationFormData>
}) {
  return (
    <Stack gap={'1em'}>
      <TextField {...register('shortWelcome')} label={<FormattedMessage id={'settings.customization.landing_page.short_welcome'} />} />
      <TextField {...register('description')} label={<FormattedMessage id={'settings.customization.landing_page.description'} />} />
    </Stack>
  );
}
