/* eslint-disable @typescript-eslint/no-redeclare */
import { CurrencyCode, money, Money } from '@sundayapp/web-money';
import { z } from 'zod';
import { CashPayment } from './CashPayment';
import { MoneyZ } from './MoneyZ';

export const CashPayments = z.object({
  payments: z.array(CashPayment),
});
export type CashPayments = z.infer<typeof CashPayments>;

export const PaymentData = z.object({
  amount: MoneyZ.optional(),
  editable: z.boolean(),
  metadata: CashPayments.optional().catch(undefined),
});

export type PaymentData = z.infer<typeof PaymentData>;

export const cashPayments = (paymentData?: PaymentData) => (paymentData?.metadata?.payments as (CashPayment[] | undefined)) ?? [];

export const paymentDataAmount = (currencyCode: CurrencyCode, paymentData?: PaymentData): Money => {
  if (!paymentData) {
    return money(0, currencyCode);
  }
  return paymentData.amount!;
};
