import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { GlobalConfigurationRepositoryHttp } from './GlobalConfigurationRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { queryKeys } from 'src/app/queries/utils';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type UpdateGlobalConfigurationArgs = {
  configurations: Map<VenueConfigurationKey, any>;
};

export const useUpdateGlobalConfiguration = () => {
  const business = useCurrentBusinessOrThrow();
  const queryClient = useQueryClient();
  const globalConfigurationRepository = new GlobalConfigurationRepositoryHttp(
    ConfigurationLoader.load().globalConfigurationUrl,
  );
  const mutation = useMutation({
    mutationFn: ({ configurations }: UpdateGlobalConfigurationArgs) => {
      return globalConfigurationRepository.updateGlobalConfiguration(
        business.id,
        configurations,
        business.address.countryCode,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.GET_GLOBAL_CONFIGURATION] });
    },
  });
  return {
    mutation,
    updateGlobalConfiguration: (configurations: Map<VenueConfigurationKey, any>) =>
      mutation.mutateAsync({ configurations }),
  };
};
