import { Button } from '@sundayapp/b2b-react-component-library';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { venueVouchersVivatechPath } from 'src/app/navigation/pathHelpers';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { shouldDisplayVoucherVivaTech } from 'src/menu/static-menu/navigation.hooks';
import { DataGridAlign } from '../../../common/components/DataGrid/DataGrid';
import ErrorContainer from '../../../common/components/ErrorContainer';
import { RightArrowIcon } from '../../../common/components/Icons';
import Spinner from '../../../common/components/Spinner';
import { PromoCodeDetails } from '../../components/PromoCodeDetails';
import { useGetPromoCodes, useSelectPromoCodeDetails } from '../../hooks';
import { newEmptyPromoCode, RATE_TO_PERCENT } from '../../services/PromoCodeService';
import { PromoCode } from '../../types';
import {
  PromoCodesContainer,
  PromoCodesDetailsContainer,
  PromoCodesGridContainer,
  PromoCodesHeaderText,
  PromoCodesLayout,
  PromoCodesStyledCustomScroll,
  PromoCodesTitleContainer,
  StyledPromoCodesDataGrid,
} from './PromoCodesList.style';
import PromoCodeStatusLabel, { PromoCodeStatus } from './PromoCodeStatusLabel';

export type PromoCodesListProps = {
  enrollmentId: EnrollmentId;
};

export const formatDatePromoCode = (dateAtEpochMilli: number) => {
  const date = new Date(dateAtEpochMilli);
  return (
    <span>
      {date.toLocaleString(undefined, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
      })}
    </span>
  );
};

const getPromoCodeStatus = (promoCode: PromoCode) => {
  const now = Date.now();
  if (promoCode.availableUntilEpochMilli < now) {
    return PromoCodeStatus.EXPIRED;
  }
  if (promoCode.availableFromEpochMilli > now) {
    return PromoCodeStatus.SCHEDULED;
  }
  return PromoCodeStatus.ACTIVE;
};

const VOUCHERS_COLUMNS = [
  {
    id: 'promoCodeName',
    label: 'name',
    renderCell: (promoCode: PromoCode) => <span data-testid="promo-code-name">{promoCode.code}</span>,
  },
  {
    id: 'promoCodeRate',
    label: 'rate',
    renderCell: (promoCode: PromoCode) => (
      <span data-testid="promo-code-amount">{`${promoCode.rate * RATE_TO_PERCENT} %`}</span>
    ),
  },
  {
    id: 'promoCodeStatus',
    label: 'status',
    renderCell: (promoCode: PromoCode) => <PromoCodeStatusLabel status={getPromoCodeStatus(promoCode)} />,
  },
  {
    id: 'promoCodeStartDate',
    label: 'start date',
    renderCell: (promoCode: PromoCode) => (
      <span data-testid="promo-code-start-date">{formatDatePromoCode(promoCode.availableFromEpochMilli)}</span>
    ),
  },
  {
    id: 'promoCodeEndDate',
    label: 'end date',
    renderCell: (promoCode: PromoCode) => (
      <span data-testid="promo-code-end-date">{formatDatePromoCode(promoCode.availableUntilEpochMilli)}</span>
    ),
  },
  {
    id: 'details',
    label: ' ',
    align: DataGridAlign.RIGHT,
    renderCell: () => <RightArrowIcon />,
  },
];

export const PromoCodesList = ({ enrollmentId }: PromoCodesListProps) => {
  const navigate = useNavigate();

  const { formatMessage } = useIntl();
  const [promoCodesState, getPromoCodes] = useGetPromoCodes();
  const [promoCodes, setPromoCodes] = useState<PromoCode[]>([]);
  useEffect(() => {
    getPromoCodes(enrollmentId).then(setPromoCodes);
  }, [getPromoCodes, enrollmentId]);

  const [, getPromoCode] = useSelectPromoCodeDetails();
  const [selectedPromoCode, setSelectedPromoCode] = useState<PromoCode>();
  const togglePromoCodeSelection = useCallback(
    (promoCode: PromoCode) => {
      if (selectedPromoCode && selectedPromoCode.voucherId === promoCode.voucherId) {
        setSelectedPromoCode(undefined);
      } else {
        setSelectedPromoCode(undefined);
        getPromoCode(promoCode.voucherId).then((result) => {
          setSelectedPromoCode(result);
        });
      }
    },
    [getPromoCode, setSelectedPromoCode, selectedPromoCode],
  );

  const createPromoCode = useCallback(
    (selectedVenueId: EnrollmentId) => {
      setSelectedPromoCode(newEmptyPromoCode(selectedVenueId));
    },
    [setSelectedPromoCode],
  );
  const createVivatechPromoCode = useCallback(
    () => {
      navigate(venueVouchersVivatechPath(enrollmentId));
    },
    [setSelectedPromoCode],
  );
  const closePanel = useCallback(() => {
    setSelectedPromoCode(undefined);
  }, [setSelectedPromoCode]);

  const onPromoCodeUpdated = (promoCodeUpdated: PromoCode) => {
    setSelectedPromoCode(promoCodeUpdated);
    if (!promoCodes.find((p) => p.voucherId === promoCodeUpdated.voucherId)) {
      // New promo code, add it
      setPromoCodes([...promoCodes, promoCodeUpdated]);
    } else {
      // Updated promo code, update it also in array
      setPromoCodes(
        promoCodes.map((promoCode) => {
          if (promoCode.voucherId === promoCodeUpdated.voucherId) {
            return promoCodeUpdated;
          }
          return promoCode;
        }),
      );
    }
  };
  const onPromoCodeDeleted = (voucherId: string) => {
    setSelectedPromoCode(undefined);
    setPromoCodes(promoCodes.filter((p) => p.voucherId !== voucherId));
  };
  const promoCodesFiltered = useMemo(() => (promoCodes ? promoCodes.filter((promoCode) => promoCode.type === 'RATE') : []), [promoCodes]);
  const withSidePanelOpened = selectedPromoCode !== undefined;

  return (
    <PromoCodesLayout>
      <PromoCodesContainer withSidePanelOpened={withSidePanelOpened}>
        <ErrorContainer error={promoCodesState.error} />
        {promoCodesState.loading ? (
          <Spinner />
        ) : (
          <PromoCodesGridContainer>
            <PromoCodesTitleContainer>
              <PromoCodesHeaderText>
                {formatMessage(
                  { id: 'promocode.list.number', defaultMessage: '{number} voucher codes' },
                  { number: promoCodesFiltered.length },
                )}
              </PromoCodesHeaderText>

              {shouldDisplayVoucherVivaTech(enrollmentId) && (
                <Button variant="primary" size="small" onClick={() => createVivatechPromoCode()}>
                  <FormattedMessage id="promocode.create.vivatech"
                                    defaultMessage="create new vivatech voucher code" />
                </Button>
              )}
              <Button variant="primary" size="small" onClick={() => createPromoCode(enrollmentId)}>
                <FormattedMessage id="promocode.create" defaultMessage="create new voucher code" />
              </Button>
            </PromoCodesTitleContainer>
            <PromoCodesStyledCustomScroll>
              {promoCodesFiltered && (
                <StyledPromoCodesDataGrid<any>
                  columns={VOUCHERS_COLUMNS}
                  rows={promoCodesFiltered}
                  onLineClick={togglePromoCodeSelection}
                />
              )}
            </PromoCodesStyledCustomScroll>
          </PromoCodesGridContainer>
        )}
      </PromoCodesContainer>
      <PromoCodesDetailsContainer withSidePanelOpened={withSidePanelOpened}>
        {selectedPromoCode && (
          <PromoCodeDetails
            promoCode={selectedPromoCode}
            onPromoCodeUpdated={onPromoCodeUpdated}
            onPromoCodeDeleted={onPromoCodeDeleted}
            closePanel={closePanel}
            enrollmentId={enrollmentId}
            allPromoCodes={promoCodes}
          />
        )}
      </PromoCodesDetailsContainer>
    </PromoCodesLayout>
  );
};
