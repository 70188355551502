// eslint-disable-next-line max-classes-per-file
import axios from 'axios';
import { ContactUsRepository } from '../domain/ContactUsRepository';
import { ContactInformation } from '../domain/ContactInformation';

class NonAuthenticatedAxios {
  constructor(private webhookUrl: string) {
  }

  async post<T>(value : T): Promise<void> {
    await axios.create().post(`${this.webhookUrl}`,
      JSON.stringify(value),
      {
        headers: {
          'Content-Type': 'text/plain',
        },
      });
  }
}

export class ContactUsRepositoryHttp implements ContactUsRepository {
  private nonAuthenticatedAxios: NonAuthenticatedAxios;

  constructor(private webhookUrl: string) {
    this.nonAuthenticatedAxios = new NonAuthenticatedAxios(webhookUrl);
  }

  async sendContactInformation(contactInformation: ContactInformation): Promise<void> {
    await this.nonAuthenticatedAxios.post(contactInformation);
  }
}
