import { colorUsage } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { Input } from '@mui/material';

type TimeSelectorProps = {
  time: string;
  isInvalidDate: boolean;
  onUpdate: (time: string) => void;
};

const TimeSelector = ({ time, isInvalidDate, onUpdate }: TimeSelectorProps) => {
  return (
    <Input
      type="time"
      value={time}
      onChange={(e) => onUpdate(e.target.value)}
      error={isInvalidDate}
      sx={{ color: isInvalidDate ? colorUsage.error : '' }}
    />
  );
};

export default TimeSelector;
