import { styled, Typography } from '@mui/material';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

const Wrapper = styled('div')`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
`;

type Props = React.ComponentProps<typeof Wrapper>;

export const BackButton = ({ ...props }: Props) => (
  <Wrapper {...props}>
    <ChevronLeftIcon />
    <Typography variant="subtitle1">
      <FormattedMessage id="back-btn" />
    </Typography>
  </Wrapper>
);
