import * as React from 'react';
import { Autocomplete, Box, Button, Card, Chip, CircularProgress, Switch, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Product } from '../../domain/Product';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BusinessId } from 'src/business/domain/Business';
import { isEligibleForOrder } from '../../domain/AvailableForOrder';
import { useGetAllProductsByBusinessId } from '../../queries/product/getAllProductByBusinessIdQuery';
import { VariantGroupUpdateRequest } from 'src/menu/dynamic-menu/repositories/VariantGroupDto';
import { useUpdateVariantGroupMutation } from 'src/menu/dynamic-menu/mutations/variantGroup/useVariantGroupMutation';
import { Controller, useForm } from 'react-hook-form';
import { red } from '@mui/material/colors';
import { v4 } from 'uuid';
import { useGetTagsByBusiness } from 'src/menu/dynamic-menu/queries/product/getTagsByBusinessQuery';
import { useGetAllergensByBusiness } from 'src/menu/dynamic-menu/queries/product/getAllergensByBusinessQuery';
import Divider from '@mui/material/Divider';
import { themeV5 } from 'src/app/theme/ThemeV5';

type Props = {
  businessId: BusinessId;
  product: Product;
  orderingAllowed: boolean;
};

const buildVariantGroupFormInitialState = (product: Product): VariantGroupUpdateRequest => ({
  name: product.name,
  description: product.description,
  shortDescription: product.shortDescription,
  variants: product.subProductIds.map(id => ({ id })),
  tags: product.tags,
  allergens: product.allergens,
  isAvailableForOrder: product.isAvailableForOrder,
});

export const VariantGroupInfoForm = ({
  businessId,
  product,
  orderingAllowed,
}: Props) => {
  const intl = useIntl();

  const { mutate, isPending } = useUpdateVariantGroupMutation(businessId, product.id);
  const { data: products, isLoading } = useGetAllProductsByBusinessId(businessId);
  const snackBar = useSnackbar();

  const {
    data: allTags,
  } = useGetTagsByBusiness(businessId);

  const allergensList = [
    intl.formatMessage({ id: 'menus.allergens.gluten' }),
    intl.formatMessage({ id: 'menus.allergens.celery' }),
    intl.formatMessage({ id: 'menus.allergens.crustaceans' }),
    intl.formatMessage({ id: 'menus.allergens.eggs' }),
    intl.formatMessage({ id: 'menus.allergens.lupin' }),
    intl.formatMessage({ id: 'menus.allergens.milk' }),
    intl.formatMessage({ id: 'menus.allergens.molluscs' }),
    intl.formatMessage({ id: 'menus.allergens.mustard' }),
    intl.formatMessage({ id: 'menus.allergens.peanuts' }),
    intl.formatMessage({ id: 'menus.allergens.sesame' }),
    intl.formatMessage({ id: 'menus.allergens.sulphites' }),
    intl.formatMessage({ id: 'menus.allergens.treenuts' }),
  ];
  const {
    data: existingAllergens,
  } = useGetAllergensByBusiness(businessId);

  const allAllergenNames = Array.from(new Set([...allergensList, ...(existingAllergens?.map((t) => t.name) ?? [])]));

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ defaultValues: buildVariantGroupFormInitialState(product) });


  const sendProductUpdate = (request: VariantGroupUpdateRequest) => {
    mutate(request, {
      onSuccess: () => snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({
          id: 'menus.product.update.notification.success',
          defaultMessage: 'product updated',
        }),
      }),
      onError: () => {
        reset();
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({
            id: 'menus.product.update.notification.error',
            defaultMessage: 'unable to update product',
          }),
        });
      },
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <form onSubmit={handleSubmit(sendProductUpdate)}>
        <Box display="flex" flexDirection="column" gap={2} p={3}>
          <Typography variant="h6" pb={2}>
            <FormattedMessage id="menus.product.details.header" defaultMessage="about the item" />
          </Typography>

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                size="medium"
                value={field.value ?? product.name}
                label={intl.formatMessage({ id: 'menus.product.details.name' })}
                sx={{ width: '100%' }}
              />
            )}
          />

          <Controller
            name="shortDescription"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                size="medium"
                label={intl.formatMessage({ id: 'menus.product.details.short_description' })}
                sx={{ width: '100%' }}
                value={field.value ?? product.shortDescription}
              />
            )}
          />

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                size="medium"
                label={intl.formatMessage({ id: 'menus.product.details.description' })}
                sx={{ width: '100%' }}
                value={field.value ?? product.description}
              />
            )}
          />


          <Controller
            name="tags"
            control={control}
            render={({ field }) => (
              <Autocomplete
                sx={{ backgroundColor: red }}
                multiple
                freeSolo
                options={(allTags || []).map((t) => t.name)}
                defaultValue={(field.value ?? product.tags).map((t) => t.name)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip variant="outlined" color="default" label={option} {...getTagProps({ index })} />
                  ))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    placeholder=""
                  />
                )}
                onChange={(_, value) => {
                  field.onChange(value.map((name) => {
                    const existingTag = product.tags.find((t) => t.name === name);
                    return existingTag ?? { id: v4(), name };
                  }));
                }}
              />
            )}
          />

          <Controller
            name="allergens"
            control={control}
            render={({ field }) => (
              <Autocomplete
                sx={{ backgroundColor: red }}
                multiple
                freeSolo
                options={allAllergenNames || []}
                defaultValue={(field.value ?? product.allergens).map((t) => t.name)}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    // eslint-disable-next-line react/jsx-key
                    <Chip variant="outlined" color="default" label={option} {...getTagProps({ index })} />
                  ))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={intl.formatMessage({ id: 'menus.product.allergens.label' })}
                    placeholder=""
                  />
                )}
                onChange={(_, value) => {
                  field.onChange(value.map((name) => {
                    const existingAllergen = product.allergens.find((t) => t.name === name);
                    return existingAllergen ?? { id: v4(), name };
                  }));
                }}
              />
            )}
          />

          {orderingAllowed &&
            isEligibleForOrder(product, products ?? []) && (
              <Controller
                name="isAvailableForOrder"
                control={control}
                render={({ field }) => (<>
                  <Divider />
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body1">
                        <FormattedMessage id="menus.product.details.quickOrder" defaultMessage="Quick order" />
                      </Typography>
                      <Typography color={themeV5.palette.text.secondary} variant="subtitle2">
                        <FormattedMessage id="menus.product.details.quickOrder" defaultMessage="Quick order" />
                      </Typography>
                    </Box>

                    <Switch
                      onChange={(event) => field.onChange(event.target.checked)}
                      checked={field.value ?? product.isAvailableForOrder}
                    />

                  </Box>
                </>)}
              />
          )}

          <Button variant="contained" disabled={isPending || !isDirty} type="submit" style={{ marginTop: '8px' }}>
            <FormattedMessage id="menus.product.details.save_changes" />
          </Button>
        </Box>
      </form>
    </Card>
  );
};
