import styled from 'styled-components';
import { Button, Card, CardContent, CardHeader, Input, InputLabel, Stack } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { NeptingIlot } from '../../domain/PaymentTerminalRepository';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  width: 500px;
  background: #f4f4f7;
`;

interface Props {
  ilot: NeptingIlot;
  onEditIlot: (ilot: NeptingIlot) => void;
  onDeleteIlot: (ilotId: string) => void;
  onCompleted: () => void,
}

function IlotForm({ ilot, onEditIlot, onDeleteIlot }: Props) {
  const intl = useIntl();
  const [contractId, setContractId] = useState<string>(ilot.id);
  const [contractName, setContractName] = useState<string>(ilot.name);

  useEffect(() => {
    setContractId(ilot.id);
    setContractName(ilot.name);
  }, [ilot]);

  const updateContract = async () => {
    onEditIlot({ id: contractId, name: contractName });
  };

  return (
    <Container>

      <Card sx={{ padding: '16px' }}>
        <CardContent>
          <Stack direction="column" justifyContent="space-between" spacing="16px " width="100%">
            <InputLabel>{intl.formatMessage({ id: 'payment.terminal.nepting.ilot.id' })}</InputLabel>
            <Input
              type="text"
              value={contractId}
              onChange={(event) => {
                setContractId(event.target.value);
              }}
            />

            <InputLabel>{intl.formatMessage({ id: 'payment.terminal.nepting.ilot.name' })}</InputLabel>
            <Input
              type="text"
              value={contractName}
              onChange={(event) => {
                setContractName(event.target.value);
              }}
            />
          </Stack>
        </CardContent>
      </Card>

      <Card sx={{ padding: '16px' }}>
        <CardHeader
          title={intl.formatMessage({ id: 'payment.terminal.nepting.ilot.delete' })}
          subheader={intl.formatMessage({ id: 'payment.terminal.nepting.ilot.delete.subtitle' })}
          action={
            <Button variant="contained" color="error" onClick={() => onDeleteIlot(contractId)}>
              <FormattedMessage id="payment.terminal.manage.menu.delete" />
            </Button>
          }
        />
      </Card>

      <Button variant="contained" onClick={updateContract}>
        {
          (!!ilot)
            ? <FormattedMessage id="payment.terminal.nepting.ilot.save_changes" />
            : <FormattedMessage id="payment.terminal.nepting.ilot.create" />
        }
      </Button>
    </Container>
  );
}

export default IlotForm;
