import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, FormControl, FormHelperText, Modal, Stack, TextField, Typography, useTheme } from '@mui/material';
import { isEmailValid } from 'src/domain/user/Email';
import { styleButton, styleModal } from '../../../../modal.style';
import { AdditionalInformationField } from './AdditionalInformationField';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

interface Props {
  title: string;
  description: string;
  onContactUs: (name: string, email: string, phoneNumber: string, additionalInformation?: string) => void;
  handleConfirm: () => void;
  handleClose: () => void;
  isOpen: boolean;
  additionalInformationField?: AdditionalInformationField;
}

type ContactUsInputs = {
  name: string;
  email: string;
  phoneNumber: string;
  additionalInformation?: string;
};

export const ContactUsModal: React.FC<Props> = ({
  title,
  description,
  onContactUs,
  handleConfirm,
  handleClose,
  isOpen,
  additionalInformationField,
}: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const currentUser = useAuthenticatedUserOrThrow();
  const snackbar = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<ContactUsInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit = async ({ name, email, phoneNumber, additionalInformation }: ContactUsInputs) => {
    try {
      onContactUs(name, email, phoneNumber, additionalInformation);
      handleConfirm();
    } catch (e) {
      snackbar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'error.generic_error' }),
      });
    }
  };

  return (
    <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-title">
      <Box component="form" onSubmit={handleSubmit(onSubmit)} marginTop={2} sx={{ ...styleModal, width: 500 }}>
        <Stack direction="column" justifyContent="start" gap={4}>
          <Stack direction="column" justifyContent="start" gap={1.5}>
            <Typography variant="h5" id="modal-title">
              {title}
            </Typography>
            <Typography variant="subtitle2" color={theme.palette.text.secondary}>
              {description}
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="start" gap={3}>
            <FormControl>
              <TextField
                id="name"
                defaultValue={`${currentUser.firstName} ${currentUser.lastName}`}
                label={intl.formatMessage({ id: 'sunday_plus.contact_us.form.name' })}
                InputLabelProps={{ shrink: true }}
                placeholder={intl.formatMessage({ id: 'sunday_plus.contact_us.form.name.placeholder' })}
                error={!!errors.name}
                {...register('name', {
                  required: intl.formatMessage({ id: 'sunday_plus.contact_us.form.name_required' }),
                })}
                aria-describedby="name-error-text"
              />
              <FormHelperText id="name-error-text" error>
                {errors.name?.message}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                id="email"
                defaultValue={currentUser.email}
                label={intl.formatMessage({ id: 'sunday_plus.contact_us.form.email' })}
                InputLabelProps={{ shrink: true }}
                placeholder={intl.formatMessage({ id: 'sunday_plus.contact_us.form.email.placeholder' })}
                error={!!errors.email}
                {...register('email', {
                  required: intl.formatMessage({ id: 'sunday_plus.contact_us.form.email_required' }),
                  validate: {
                    isEmail: (value) =>
                      isEmailValid(value)
                        ? true
                        : intl.formatMessage({ id: 'sunday_plus.contact_us.form.email_invalid' }),
                  },
                })}
                aria-describedby="email-error-text"
              />
              <FormHelperText id="email-error-text" error>
                {errors.email?.message}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                id="phoneNumber"
                label={intl.formatMessage({ id: 'sunday_plus.contact_us.form.phone_number' })}
                InputLabelProps={{ shrink: true }}
                placeholder={intl.formatMessage({ id: 'sunday_plus.contact_us.form.phone_number.placeholder' })}
                error={!!errors.phoneNumber}
                {...register('phoneNumber', {
                  required: intl.formatMessage({ id: 'sunday_plus.contact_us.form.phone_number_required' }),
                })}
                aria-describedby="phoneNumber-error-text"
              />
              <FormHelperText id="phoneNumber-error-text" error>
                {errors.phoneNumber?.message}
              </FormHelperText>
            </FormControl>
            {additionalInformationField && (
              <FormControl>
                <TextField
                  id="additionalInformation"
                  label={additionalInformationField.label}
                  InputLabelProps={{ shrink: true }}
                  placeholder={additionalInformationField.placeholder}
                  error={!!errors.phoneNumber}
                  {...register('additionalInformation', {
                    required: additionalInformationField.requiredErrorMessage,
                  })}
                  aria-describedby="additional-information-error-text"
                />
                <FormHelperText id="additional-information-error-text" error>
                  {errors.additionalInformation?.message}
                </FormHelperText>
              </FormControl>
            )}
          </Stack>

          <Stack direction="column" gap={1.5}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              style={{ ...styleButton }}
              disabled={!isValid || isSubmitting}
            >
              <FormattedMessage id="sunday_plus.contact_us.form.confirm" />
            </Button>
            <Button size="large" variant="outlined" style={{ ...styleButton }} onClick={handleClose}>
              <FormattedMessage id="sunday_plus.contact_us.form.cancel" />
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
