import React, { FC, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Link from '@mui/material/Link';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { AllergenTranslations } from 'src/menu/dynamic-menu/domain/AllergenTranslations';
import { Language } from 'src/app/i18n/AllSupportedLanguage';

export type AllergenTranslationsMap = Record<string, string>;

type UpdatedAllergen = {
  allergenId: string;
  allergenName: string;
  allergenTranslations: AllergenTranslationsMap;
};

export const EditAllergenModal: FC<{
  allergen: AllergenTranslations;
  supportedLanguages: Array<Language>;
  onActionConfirmed: (updatedAllergen: UpdatedAllergen) => void;
  onClose: () => void;
}> = ({ allergen, supportedLanguages, onActionConfirmed, onClose }) => {
  const currentAllergenTranslations = allergen.translations.reduce<AllergenTranslationsMap>(
    (acc, { language, name }) => {
      acc[language] = name;
      return acc;
    },
    {},
  );
  const [allergenName, setAllergenName] = useState(allergen.name);
  const [allergenTranslations, setAllergenTranslations] = useState(currentAllergenTranslations);

  return (
    <Dialog fullWidth maxWidth="xs" open={true} onClose={onClose}>
      <DialogTitle paddingBottom={'16px'}>
        <FormattedMessage id={'menus.allergen.edit.modal.title'} defaultMessage={'Edit allergen'} />
      </DialogTitle>
      <DialogContent>
        <Stack direction={'column'} gap={'8px'} padding={'8px'}>
          <TextField
            variant={'outlined'}
            label={'Allergen name'}
            type="text"
            value={allergenName}
            onChange={(event) => {
              setAllergenName(event.target.value);
            }}
          />
          <Typography sx={{ opacity: 0.6 }}>Translations</Typography>
          <Stack direction={'column'} gap={'16px'}>
            {supportedLanguages.map((language) => (
              <TextField
                key={language.id}
                variant={'outlined'}
                label={language.id}
                type="text"
                value={allergenTranslations[language.id]}
                onChange={(event) => {
                  setAllergenTranslations((prevState) => ({
                    ...prevState,
                    [language.id]: event.target.value,
                  }));
                }}
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Link
          onClick={onClose}
          sx={{
            cursor: 'pointer',
            color: themeV5.palette.text.primary,
            textDecorationColor: themeV5.palette.text.primary,
          }}
        >
          <Typography variant="body1">
            <FormattedMessage id={'menus.allergen.edit.modal.cancel'} defaultMessage="cancel" />
          </Typography>
        </Link>
        <Button
          onClick={() =>
            onActionConfirmed({
              allergenId: allergen.id,
              allergenName: allergenName,
              allergenTranslations,
            })
          }
          autoFocus
          variant="contained"
        >
          <FormattedMessage id={'menus.allergen.edit.modal.save'} defaultMessage="save" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
