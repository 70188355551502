import React, { useState } from 'react';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { BusinessId } from 'src/business/domain/Business';
import { Box, Switch } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

type MenuEnablerProps = {
  menu: Menu;
  businessId: BusinessId;
};

const MenuEnabler = ({ menu, businessId }: MenuEnablerProps) => {
  const [isProcessing, setProgressing] = useState<boolean>();
  const updateMenu = useUpdateMenuMutation(businessId, menu.id);

  const updateMenuEnabledStatus = async () => {
    if (menu.enabled) {
      await updateMenu.mutateAsync({ enable: false });
    } else {
      await updateMenu.mutateAsync({ enable: true });
    }
  };

  const onChange = async () => {
    if (isProcessing) {
      return;
    }

    setProgressing(true);
    try {
      await updateMenuEnabledStatus();
    } finally {
      setProgressing(false);
    }
  };

  if (menu.enabled === undefined) return;

  return (
    <Box flexDirection="row" display="flex" rowGap={1} columnGap={1}>
      <Switch onChange={onChange} checked={menu.enabled} />
      <FormattedMessage id="menus.settings.enabled" defaultMessage="Menu enabled" />
    </Box>
  );
};

export default MenuEnabler;
