import React, { FC, Fragment } from 'react';
import { Box, Grid, Tooltip, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { MoneyView } from '@sundayapp/web-money';
import { SundayPayoutSummary, SundayPayoutSummaryBreakdownItem } from '../../../domain/SundayPayoutSummary';
import { InfoOutlined } from '@mui/icons-material';

const BreakdownItem: FC<{ item: SundayPayoutSummaryBreakdownItem }> = ({ item }) =>
  <Grid
    item
    mb={0.5}
    xs={12}
    sx={{
      display: 'flex',
      gap: '8px',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <Box display="flex" alignItems="center">
      <Typography variant="body1" color={item.color}>
        <FormattedMessage id={item.label.id} />
      </Typography>
      {item.tooltip && <Tooltip
        sx={{ display: 'flex' }}
        title={<FormattedMessage id={item.tooltip.id} />}
      >
        <InfoOutlined fontSize={'small'} sx={{ color: item.color, ml: 1 }} />
      </Tooltip>}
    </Box>
    <Typography variant="body1" color={item.color}>
      <MoneyView value={item.amount} />
    </Typography>
  </Grid>;

export const SundayPayoutSummaryBreakdown = ({ sundayPayoutSummary }: { sundayPayoutSummary: SundayPayoutSummary }) => {
  const theme = useTheme();

  const breadowns = [
    sundayPayoutSummary.cards,
    sundayPayoutSummary.paymentLinks,
    sundayPayoutSummary.otherRevenues,
  ];
  return (
    <>
      <Grid p={2} container>
        <Grid
          item
          mb={2}
          pb={2}
          mt={0.5}
          xs={12}
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #dddde0',
          }}
        >
          <Typography variant="h5" color={theme.palette.text.primary}>
            <FormattedMessage id="accounting.revenue_tab.panel.totalPaidOutBySunday"
                              defaultMessage="Total paid out from sunday" />
          </Typography>
          <Typography variant="h5" color={theme.palette.text.primary}>
            <MoneyView value={sundayPayoutSummary.totalPaidOut} />
          </Typography>
        </Grid>

        {breadowns.map((breakdown, index) => (<Fragment key={index}>
            {breakdown.length > 0 && <Box display="flex" flexDirection="column" width="100%" sx={{
              ...(index > 0 ? { mt: 4 } : {}),
            }}>
              {breakdown.map((item) => (
                <BreakdownItem key={item.label.id} item={item} />),
              )}
            </Box>}
          </Fragment>),
        )}
      </Grid>
    </>
  );
};
