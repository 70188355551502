import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { VenueDetails } from '../../../../types';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type FoldableCategoriesVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const FoldableCategoriesVenueSetting = ({ venue, setVenue }: FoldableCategoriesVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateFoldableCategories = async (foldableCategories: boolean) => {
    makeUpdating(true);
    await updateVenueDetails({
      foldableCategories,
    });
    setVenue({
      ...venue,
      foldableCategories,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.foldableCategories',
        defaultMessage: 'fold menu categories by default',
        description: 'fold menu categories by default',
      })}
      description={intl.formatMessage({
        id: 'venue.foldableCategoriesDescription',
        defaultMessage: 'If active, will allow the customer to fold / unfold the menu categories',
        description: 'description of the foldable menu categories in the setting',
      })}
      toggleValue={venue.foldableCategories}
      toggleOnChange={updateFoldableCategories}
      disabled={isUpdating}
    />
  );
};
