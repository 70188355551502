import React, {
  ChangeEvent, createRef, ReactNode, useCallback,
} from 'react';
import styled from 'styled-components';
import { readFile } from './service';

interface PictureUploaderProps {
  onChange: (base64Picture: string) => void;
  children: ReactNode;
}

export const PictureUploaderContainer = styled.div`
  cursor: pointer;
`;

export const HiddenInput = styled.input`
  display: none;
`;

const PictureUploader: React.FC<PictureUploaderProps> = ({ onChange, children }) => {
  const inputRef = createRef<HTMLInputElement>();

  const startUploadFile = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const onFileChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.files && event.currentTarget.files.length > 0) {
        const file = event.currentTarget.files[0];
        const image = await readFile(file);
        onChange(image);
      }
    },
    [onChange],
  );

  return (
    <PictureUploaderContainer onClick={startUploadFile}>
      <HiddenInput type="file" onChange={onFileChange} ref={inputRef} accept="image/*" />
      {children}
    </PictureUploaderContainer>
  );
};

export default PictureUploader;
