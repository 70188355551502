import { RatingStatistics } from '../../browse/domain/RatingStatistics';

export type RatingCount = {
  rating: number;
  count: number;
};

export type DistributionByRating = RatingCount[];

const toPrecisionOf2Digits = (average: number): number => Math.round(average * 100 + Number.EPSILON) / 100;

export const numberOfRatings = (distributionByRating: DistributionByRating): number => distributionByRating.map((r) => r.count)
  .reduce((acc, c) => acc + c, 0);

const badRating = 3;
export const numberOfBadRatings = (distributionByRating: DistributionByRating): number => distributionByRating.map((r) => r.count)
  .filter((value, index)=> index < badRating)
  .reduce((acc, c) => acc + c, 0);

const totalRating = (distributionByRating: DistributionByRating): number => distributionByRating.map((r) => r.rating * r.count)
  .reduce((acc, c) => acc + c, 0);

export const averageRating = (distributionByRating: DistributionByRating): number => {
  const nbRatings = numberOfRatings(distributionByRating);
  const ttlRating = totalRating(distributionByRating);
  return nbRatings === 0 ? 0 : toPrecisionOf2Digits(ttlRating / nbRatings);
};

const ratingByStars = (distributionByRating: DistributionByRating): { nbRatings: number; star: number }[] => {
  const accumulatedRatingByStars = distributionByRating
    .map((ratingCount) => [ratingCount.rating, ratingCount.count]);
  return Array
    .from(accumulatedRatingByStars)
    .map((entry) => ({
      star: entry[0],
      nbRatings: entry[1],
    }));
};

export const mapToRatingStatistics = (distributionByRating: DistributionByRating): RatingStatistics => new RatingStatistics(
  numberOfRatings(distributionByRating),
  averageRating(distributionByRating),
  ratingByStars(distributionByRating),
  [],
);
