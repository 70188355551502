import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { MenuItemColumn } from './MenuItemColumn';
import MenuScheduleDisplay from './MenuScheduleDisplay';
import { MenuLine, MenuTitle } from './MenuItem.styles';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { MenuConstraints } from '../../domain/StaticMenus';

export type MenuItemScheduleProps = {
  constraints?: MenuConstraints;
  visibility: boolean;
};

const MenuItemSchedule = ({ constraints, visibility }: MenuItemScheduleProps) => (
  <MenuLine isMobile={isMobileScreen()}>
    <MenuTitle>
      <FormattedMessage id="menus.table.header.schedule" />
    </MenuTitle>
    <MenuItemColumn>
      <MenuScheduleDisplay isActive={visibility} constraints={constraints} />
    </MenuItemColumn>
  </MenuLine>
);

export default MenuItemSchedule;
