import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { PosConnectionRepositoryHttp } from '../infrastructure/PosConnectionRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { PosConnection, RevenueCenter } from 'src/pos/domain/PosConnection';

export const QUERYKEY_POS_CONNECTIONS_WITH_REVENUE_CENTERS = 'pos-connections-revenue-centers';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_POS_CONNECTIONS_WITH_REVENUE_CENTERS, businessId] as const;

export type PosConnectionWithRevenueCenters = PosConnection & {
  revenueCenters: RevenueCenter[]
};
export const getPosConnectionsWithRevenueCentersQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<PosConnectionWithRevenueCenters[]> => {
  const businessId = queryKey[1];
  const configuration = ConfigurationLoader.load();
  const posConnectionRepository = new PosConnectionRepositoryHttp(configuration.vposVenueUrl);
  const response = await posConnectionRepository.getPosConnections(businessId);
  const posConnections = Object.values(response).flat();
  return Promise.all(
    posConnections.map((posConnection) => posConnectionRepository.getRevenueCenters(posConnection.id)
      .then((revenueCenters) => ({
        ...posConnection,
        revenueCenters,
      }))),
  );
};

export const useGetPosConnectionsWithRevenueCenters = (businessId: BusinessId) => useQuery({
  queryKey: buildQueryKey(businessId),
  queryFn: getPosConnectionsWithRevenueCentersQuery,
});
