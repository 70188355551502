import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { QUERYKEY_MENU_MENU_BY_ID } from '../../queries/menu/getMenuByIdQuery';
import { CreateSundayMenuDto, UpdateMenuDto } from '../../repositories/MenuDto';
import { BusinessId } from 'src/business/domain/Business';
import { QUERYKEY_MENUS } from 'src/menu/dynamic-menu/queries/menu/findMenusBySourceQuery';

export const useUpdateMenuMutation = (businessId: BusinessId, menuId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (updateDto: UpdateMenuDto) => menuRepository.updateMenu(businessId, menuId, updateDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENUS] });
    },
  });
};

export const useDeleteMenuMutation = (businessId: BusinessId, menuId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => menuRepository.deleteMenu(menuId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID, QUERYKEY_MENUS] });
    },
  });
};


export const useCreateMenuMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (dto: CreateSundayMenuDto) => menuRepository.createMenu(businessId, dto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENUS] });
    },
  });
};

export const useCopyMenuMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (menuId: string) => menuRepository.copyMenu(businessId, menuId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENUS] });
    },
  });
};
export const useUpdateMenuPictureMutation = (businessId: BusinessId, menuId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (base64Picture: string): Promise<string> => new Promise((success, failure) => {
      fetch(base64Picture)
        .then((response) => response.arrayBuffer())
        .then((buffer) => {
          menuRepository.updateMenuPicture(businessId, menuId, buffer)
            .then(success)
            .catch(failure);
        }).catch(failure);
    }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};
