import { CurrencyCode } from '@sundayapp/web-money';
import dayjs from 'dayjs';
import { Payout } from './Payout';

export const aPayout: Payout = {
  id: 'po_1234',
  createdAt: dayjs().unix() * 1000,
  amount: { amount: 1000_00000, currency: CurrencyCode.EUR },
  arrivalDate: '2023-01-01',
  status: 'in transit',
  operationDates: ['2023-01-01'],
};
