import { AxiosStatic } from 'axios';
import { BillRefresher } from '../domain/BillRefresher';
import { CorrelationIdGenerator } from 'src/utils/correlation-id/CorrelationIdGenerator';

export class VPOSBillRefresher implements BillRefresher {
  constructor(
    private httpClient: AxiosStatic,
    private vposRefreshBillApiBaseUrl: string,
  ) {}

  async refreshBill(orderId: string, isTab: boolean): Promise<void> {
    const queryString = isTab ? '?isTab=true' : '';
    await this.httpClient.put(`${this.vposRefreshBillApiBaseUrl}/bills/${orderId}/refresh${queryString}`, {
      headers: {
        contentType: 'application/json',
        correlation_id: CorrelationIdGenerator.generate(),
      },
    });
  }

  async refreshBillForTable(tableId: string): Promise<void> {
    await this.httpClient.put(`${this.vposRefreshBillApiBaseUrl}/tables/${tableId}/refresh`, {
      headers: {
        contentType: 'application/json',
        correlation_id: CorrelationIdGenerator.generate(),
      },
    });
  }
}
