import { IconButton, Stack, Tooltip } from '@mui/material';
import { Money, MoneyView } from '@sundayapp/web-money';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CashPayments } from '../../domain/PaymentData';
import { PaymentOrigin } from '../../domain/PaymentOrigin';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Method, MethodType } from '../../domain/Reconciliation';
import { useUpdateReconciliationLineAmount } from '../../mutations/updateReconciliationLine';
import { useCurrentReportOrThrow } from '../../hooks/useCurrentReportOrThrow';
import { isOpened } from '../../domain/ReconciliationReport';

type PosAmountCellProps = {
  method: Method,
  amount: Money,
};

export const PosAmountCell = ({ method, amount }: PosAmountCellProps) => {
  const { formatMessage } = useIntl();
  const mutation = useUpdateReconciliationLineAmount(method);

  const report = useCurrentReportOrThrow();

  const save = (origin: PaymentOrigin, newAmount: Money, metadata?: CashPayments) => {
    mutation.mutate({
      origin, newAmount, metadata,
    });
  };

  return (
    <>
      {method.type === MethodType.Sunday ? (
        <MoneyView value={amount} />
      ) : (
        <Stack display="flex" flexDirection="row" gap={1} alignItems="center">
          <MoneyView value={amount} />
          {isOpened(report) && (
            <Tooltip title={formatMessage({ id: 'reconciliation.report.autofill' })} placement="top" arrow>
              <IconButton
                onClick={() => save(PaymentOrigin.Reported, amount)}
                sx={{ backgroundColor: themeV5.palette.primary.light }}
              >
                <KeyboardDoubleArrowRightIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
    </>
  );
};
