import { MoneyView } from '@sundayapp/web-money';
import React, { useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { CashPayment, totalCashPayment } from '../../domain/CashPayment';
import { CashModal } from './CashModal';
import { useCurrentReportOrThrow } from '../../hooks/useCurrentReportOrThrow';
import { isOpened } from '../../domain/ReconciliationReport';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

interface CashPaymentsDetailsProps {
  cashPayment: CashPayment,
  onSave: (cashPayment: CashPayment) => void,
  onDelete: (cashPayment: CashPayment) => void,
}

export const CashPaymentDetails = ({
  cashPayment, onSave, onDelete,
}: CashPaymentsDetailsProps) => {
  const theme = useTheme();
  const report = useCurrentReportOrThrow();
  const [showModal, setShowModal] = useState(false);

  const { currency } = useCurrentBusinessOrThrow();

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'auto min-content',
        gridTemplateRows: 'auto',
        gridTemplateAreas: `
             "amount icon"
             "waiter icon"`,
        backgroundColor: 'white',
        borderRadius: '20px',
        padding: '10px',
        margin: '10px',
      }}
    >
      <Box sx={{ gridArea: 'amount' }}>
        <MoneyView value={totalCashPayment(cashPayment, currency)} />
      </Box>
      <Typography sx={{ gridArea: 'waiter' }} variant="body2" color={theme.palette.text.secondary}>
        {cashPayment.reporter.name}
      </Typography>
      {isOpened(report) && (
        <Box
          sx={{
            gridArea: 'icon', display: 'flex', alignItems: 'center', gap: 1,
          }}
          onClick={() => setShowModal(true)}
        >
          <img src="/edit-icon.svg" alt="edit" width="16" height="16" />
          <ClearIcon onClick={() => onDelete(cashPayment)} />
        </Box>

      )}
      {showModal && (
        <CashModal isOpened payment={cashPayment} onClose={() => setShowModal(false)} save={onSave}
                   currencyCode={currency} />
      )}
    </Box>
  );
};
