import React from 'react';
import { IconProps } from './interface';

export const UploadIcon: React.FC<IconProps> = ({ size = 12, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6L11.5001 6.49993L6.35372 1.35359L6.35372 12H5.64662L5.64662 1.3531L0.499859 6.49986L0 6L6 9.53674e-07L12 6Z"
      fill={color}
    />
  </svg>
);
