import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, styled } from '@mui/material';
import { TableDevice } from 'src/floorplan/domain/TableDevice';
import React from 'react';
import { QRCodeSVG } from 'qrcode.react';

const QrCodeActionsList = styled('ul')({
  fontWeight: 'bold',
  listStyleType: 'none',
  '& li': {
    paddingLeft: '2rem',
    backgroundImage: 'url(/doodle-tick-1.png)',
    backgroundPosition: '0 0',
    backgroundSize: '1.6rem 1.6rem',
    backgroundRepeat: 'no-repeat',
  },
});

interface QRCodePreviewerDialogProps {
  previewedTable: TableDevice;
  isOpen: boolean;
  handleClose: () => void;
}

function QRCodePreviewerDialog({ previewedTable, isOpen, handleClose }: QRCodePreviewerDialogProps) {
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>
        Preview
        <br />
        <span style={{ color: 'gray' }}>{`table ${previewedTable?.number}`}</span>
      </DialogTitle>
      <DialogContent style={{ padding: '16px', background: '#F9F9FA' }}>
        <Paper variant="outlined" style={{ padding: '16px' }}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
            <Grid item style={{ fontSize: '1.5rem' }}>
              <p>
                hi, table <span style={{ color: '#FF17E9' }}>{previewedTable?.number}</span>
                <br />
                scan the QR code
              </p>
              <QrCodeActionsList>
                <li>check the menu</li>
                <li>pay the bill</li>
              </QrCodeActionsList>
            </Grid>

            <Grid item>
              <Grid container direction="column" justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <QRCodeSVG value={previewedTable.shortLink} size={192} fgColor="#000000" level="L" includeMargin />
                </Grid>
                <Grid item>
                  <img style={{ maxWidth: '130px' }} src="/sunday-logo-with-name.svg" alt="sunday-logo-with-name" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Button variant="outlined" onClick={handleClose}>
              close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default QRCodePreviewerDialog;
