import React from 'react';
import { useNavigate } from 'react-router';
import { venueSundayPlusReviewsPath } from 'src/app/navigation/pathHelpers';
import { HomeCtaButton } from '../HomeCtaButton';
import { colorPalette } from '@sundayapp/b2b-react-component-library';
import ReviewsPlainSvg from '../../../app/component/icons/ReviewsPlainSvg';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { formatNumber } from 'src/sundayplus/components/formatNumbers';
import { Business } from 'src/business/domain/Business';

type HomeReviewAverageProps = {
  business: Business;
  averageRating: number | undefined;
};

export const HomeReviewAverage = ({ business, averageRating }: HomeReviewAverageProps) => {
  const navigate = useNavigate();
  const intl = useIntl();

  if (!averageRating) {
    return <></>;
  }

  const onClick = () => {
    navigate(`${venueSundayPlusReviewsPath(business.id)}?timerange=LAST_7_DAYS&platform=GOOGLE`);
  };

  return (
    <HomeCtaButton
      icon={<ReviewsPlainSvg size={24} color={colorPalette.grey500} />}
      label={intl.formatMessage({ id: 'venue.home.reviews.average.label' })}
      value={formatNumber(averageRating ?? 0, 2)}
      onClick={onClick}
    />
  );
};
