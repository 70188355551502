import * as React from 'react';
import { useState } from 'react';
import { ContentCopyOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useCopyMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { MenuSummary } from '../../../domain/MenuSummary';
import { venueDynamicMenuPath } from 'src/app/navigation/pathHelpers';
import { BusinessId } from 'src/business/domain/Business';

type Props = {
  businessId: BusinessId;
  menu: MenuSummary;
};

const DuplicateIcon = styled(ContentCopyOutlined)`
  font-size: large;
  cursor: pointer;
`;

export const CopyMenu = ({ businessId, menu }: Props) => {
  const intl = useIntl();
  const navigateTo = useNavigate();
  const snackBar = useSnackbar();
  const copyMenu = useCopyMenuMutation(businessId);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const showCopyError = () => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({ id: 'menus.menu.copy.notification.error', defaultMessage: 'unable to copy menu' }),
    });
  };
  const showCopySuccess = () => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'menus.menu.copy.notification.success', defaultMessage: 'menu copied !' }),
    });
  };

  const onClickDuplicate = (e: React.MouseEvent<SVGViewElement, MouseEvent>) => {
    e.stopPropagation();
    setConfirmationOpen(true);
  };

  const handleClose = () => {
    setConfirmationOpen(false);
  };
  const confirmCopy = () => {
    copyMenu
      .mutateAsync(menu.id)
      .then((newMenuId) => {
        showCopySuccess();
        navigateTo(venueDynamicMenuPath(businessId, newMenuId));
      })
      .catch(() => showCopyError())
      .finally(() => handleClose());
  };
  return (
    <>
      <DuplicateIcon onClick={onClickDuplicate} />
      <Dialog open={confirmationOpen} onClose={handleClose}>
        <DialogTitle>
          <FormattedMessage id="menus.menu.copy.modal.title" defaultMessage="copy menu?" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="menus.menu.copy.modal.text"
              defaultMessage="Are you sure you want to create a new menu based on this one?"
              values={{ name: menu.name }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <FormattedMessage id="menus.menu.modal.cancel" defaultMessage="cancel" />
          </Button>
          <Button onClick={confirmCopy} autoFocus variant="contained">
            <FormattedMessage id="menus.menu.modal.confirm" defaultMessage="continue" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
