import React from 'react';
import { AverageRatingData, AverageRatingsViewModel } from '../AverageRatingsViewModel';
import { AverageRatingsChart } from '../AverageRatingsChart';
import { WithChartIntegrityCheck } from '../dimension/WithChartIntegrityCheck';

interface Props {
  viewModel: AverageRatingsViewModel;
}

export const GlobalTrends = ({
  viewModel,
}: Props) => {
  return <>
    <WithChartIntegrityCheck viewModel={viewModel}>
      <AverageRatingsChart viewModel={viewModel as AverageRatingData} />
    </WithChartIntegrityCheck>
  </>;
};
