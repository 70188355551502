import { CurrencyCode, differenceMoney, money, Money, sumMoneys } from '@sundayapp/web-money';
import { z } from 'zod';
import { Method, Reconciliation, selectAmountByOrigin } from './Reconciliation';
import { Settings } from './Settings';
import { PaymentOrigin } from './PaymentOrigin';
import { PaymentData, paymentDataAmount } from './PaymentData';
import { MoneyZ } from './MoneyZ';
import { OrphanPosLine } from './OrphanPosLine';

export enum CashupStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export const Shift = z.object({
  name: z.string(),
  dateOfService: z.string().transform((arg) => new Date(arg)),
  start: z.string().transform((arg) => new Date(arg)),
  end: z.string().transform((arg) => new Date(arg)),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type Shift = z.infer<typeof Shift>;

export const ReportId = z.object({
  id:z.string(),
});
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReportId = z.infer<typeof ReportId>;
export const ReconciliationReport = z.object({
  id: z.string(),
  shift: Shift,
  settings: Settings.optional(),
  status: z.nativeEnum(CashupStatus).catch(CashupStatus.Closed),
  lastModification: z.string().transform((arg) => new Date(arg)),
  reconciliations: z.array(Reconciliation),
  totalOnPos: PaymentData,
  totalReported: MoneyZ.optional(),
  comment: z.string().optional(),
  reporter: z.string().optional(),
  orphanPosLines: z.array(OrphanPosLine).optional(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReconciliationReport = z.infer<typeof ReconciliationReport>;

export const findReconciliationByMethod = (report: ReconciliationReport, method: Method) => report.reconciliations.find((l) => l.method === method)!;
export const findAmountByOrigin = (report: ReconciliationReport, method: Method, origin: PaymentOrigin): PaymentData => {
  const line = findReconciliationByMethod(report, method);
  return selectAmountByOrigin(line, origin)!;
};
export const totalCashUp = (currency: CurrencyCode, cashUp: ReconciliationReport, origin: PaymentOrigin): Money =>
  cashUp.reconciliations.map(
    (line) =>
      (origin === PaymentOrigin.OnPos)
        ? paymentDataAmount(currency, line.onPos)
        : line.isHeader ? paymentDataAmount(currency) : paymentDataAmount(currency, line.reported),
  )
    .reduce((sum, cur) => sumMoneys(sum, cur), money(0, currency));

export const totalOnPos = (currency: CurrencyCode, report: ReconciliationReport): Money =>
  (report.totalOnPos.editable
    ? report.totalOnPos.amount
    : totalCashUp(currency, report, PaymentOrigin.OnPos)
  )
  ?? money(0, currency);

export const totalReported = (currency: CurrencyCode, report: ReconciliationReport): Money =>
  totalCashUp(currency, report, PaymentOrigin.Reported);

export const totalDelta = (currency: CurrencyCode, report: ReconciliationReport): Money =>
  differenceMoney(totalReported(currency, report), totalOnPos(currency, report));

export const isOpened = (report: ReconciliationReport) => report.status === CashupStatus.Open;

export const isClosed = (report: ReconciliationReport) => report.status === CashupStatus.Closed;
