import {
  colorUsage,
  IconWrapper,
  PencilSvg,
  spaceUsages,
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  Text,
  TrashSvg,
} from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { GroupedHourConstraints } from './GroupedHourConstraints';
import { MenuScheduleUtils } from './MenuScheduleUtils';
import MenuScheduleModal from './MenuScheduleModal';

type GroupedConstraintLineProps = {
  constraints : GroupedHourConstraints;
  onUpdated: (constraints: GroupedHourConstraints) => void;
  onDelete: () => void;
  deleteAvailable: boolean;
};

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupedConstraintsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const GroupedConstraintsIcons = styled.div`
  display: flex;
  gap: ${spaceUsages.medium};
`;

const GroupedConstraintLine = ({
  constraints, onUpdated, onDelete, deleteAvailable,
}: GroupedConstraintLineProps) => {
  const intl = useIntl();
  const labels = MenuScheduleUtils.groupedConstraintToString(constraints, intl);
  const [scheduleSelectorDialogOpened, setScheduleSelectorDialogOpened] = useState<boolean>(false);

  return (
    <GroupedConstraintsRow>
      <LabelsContainer>
        {labels.map((label) => (<Text size="small" key={label}>{label}</Text>))}
      </LabelsContainer>
      <GroupedConstraintsIcons>
        <IconWrapper size="small" cursor="pointer" onClick={() => setScheduleSelectorDialogOpened(true)}>
          <PencilSvg />
        </IconWrapper>
        {deleteAvailable && (
        <IconWrapper color={colorUsage.error} size="small" cursor="pointer" onClick={() => onDelete()}>
          <TrashSvg />
        </IconWrapper>
        )}
      </GroupedConstraintsIcons>

      <MenuScheduleModal
        groupedHourConstraints={constraints}
        setGroupedHourConstraints={onUpdated}
        isOpened={scheduleSelectorDialogOpened}
        onClose={() => setScheduleSelectorDialogOpened(false)}
      />
    </GroupedConstraintsRow>
  );
};

export default GroupedConstraintLine;
