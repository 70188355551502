import { useFormContext } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';
import { FirstName } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/FirstName';
import { LastName } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/LastName';
import {
  DateOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/DateOfBirth';
import {
  CountryOfBirth,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/CountryOfBirth';
import React, { useEffect } from 'react';
import {
  OwnershipPercentage,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/OwnershipPercentage';
import {
  RepresentativeAddress,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/RepresentativeAddress';
import Divider from '@mui/material/Divider';
import { Email } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/Email';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  CompanyPosition,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/CompanyPosition';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { AuthorisedSignatoryExtraRoles } from './AuthorisedSignatoryExtraRoles';
import {
  NationalIdNumberIfUS,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/NationalIdNumberIfUS';

const useRemoveFormFieldsOnChangeOfRepresentativeType = (isUBO: boolean, representativeIndex: number, needsCompanyPosition: boolean) => {
  const { watch, unregister } = useFormContext<CheckoutOnboardingInformationDraft>();
  const country = watch(`representatives.${representativeIndex}.address.country`);
  useEffect(() => {
    if (!isUBO) {
      unregister(`representatives.${representativeIndex}.ownershipPercentage`);
    }
    if (!needsCompanyPosition) {
      unregister(`representatives.${representativeIndex}.companyPosition`);
    }
    if (country !== 'US') {
      unregister(`representatives.${representativeIndex}.address.state`);
    }
  }, [isUBO, country, representativeIndex, needsCompanyPosition]);
};


export const AuthorisedSignatoryPersonalInfo = ({
  isUBO,
  needsCompanyPosition,
  representativeIndex,
  businessCountryCode,
}: {
  isUBO: boolean,
  needsCompanyPosition: boolean,
  representativeIndex: number,
  businessCountryCode: string
}) => {
  useRemoveFormFieldsOnChangeOfRepresentativeType(isUBO, representativeIndex, needsCompanyPosition);
  return <Stack spacing={3}>
    <Typography variant={'h6'}><FormattedMessage
      id={'settings.payments.checkout.onboarding.representatives.personalInformation'} /></Typography>
    <Stack spacing={3} direction={'row'}>
      <FirstName legalRepresentativeIndex={representativeIndex} />
      <LastName legalRepresentativeIndex={representativeIndex} />
    </Stack>
    <AuthorisedSignatoryExtraRoles representativeIndex={representativeIndex} />
    <DateOfBirth legalRepresentativeIndex={representativeIndex} />
    <CountryOfBirth legalRepresentativeIndex={representativeIndex} />
    <NationalIdNumberIfUS businessCountryCode={businessCountryCode} representativeIndex={representativeIndex} />
    <Email representativeIndex={representativeIndex} />
    <RepresentativeAddress representativeIndex={representativeIndex} />
    {isUBO && <Stack spacing={3}>
      <Divider />
      <OwnershipPercentage representativeIndex={representativeIndex} />
    </Stack>
    }
    {needsCompanyPosition && <Stack spacing={3}>
      <Divider />
      <CompanyPosition representativeIndex={representativeIndex} />
    </Stack>
    }
  </Stack>
  ;
};
