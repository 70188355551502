import React from 'react';
import { Box, Theme } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { RevenueRowGroup } from './RevenueRowGroup';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';
import { RevenuesSummary } from '../../../domain/RevenueSummary';

export const RevenuesTable = ({
  revenuesSummary,
  period,
  isBusinessWithServiceCharge,
  intl,
  theme,
}: { revenuesSummary: RevenuesSummary; period: RevenuePeriod; isBusinessWithServiceCharge: boolean; intl: ReturnType<typeof useIntl>; theme: Theme }) => {

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    }}>
      {revenuesSummary.revenues.map((summary) => (
        <RevenueRowGroup
          revenue={summary}
          period={period}
          isBusinessWithServiceCharge={isBusinessWithServiceCharge}
          intl={intl}
          theme={theme}
          key={summary.source}
        />
      ))}
    </Box>
  );
};
