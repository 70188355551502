import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const useReopenReportMutation = (businessId: BusinessId) => {
  const queryClient = useQueryClient();
  const reconciliationRepository = new ReconciliationRepositoryHttp();

  return useMutation({
    mutationFn: async (reportId: string): Promise<void> => {
      await reconciliationRepository.reopen(businessId, reportId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.BUSINESSES, businessId, queryKeys.RECONCILIATION_REPORT] });
    },
  });
};
