import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { BoxDetails } from '../../../../box/domain/BoxDetails';
import { SettingsCategory } from './SettingsCategory';
import { BoxOpeningHourSetting } from '../../../../box/pages/BoxSettings/components/OpeningHour/BoxOpeningHourSetting';
import { DisplayBoxInfoSetting } from '../../../../box/components/BoxInfo/DisplayBoxInfoSetting';
import { BoxPictureSetting } from '../../../../box/components/boxPictureSetting';

type BoxSettingsProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BoxSettings = ({ box, setBox }: BoxSettingsProps) => {
  const intl = useIntl();
  return (
    <Container>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'settings.app_settings.app_design.title',
          defaultMessage: 'app design',
        })}
      >
        <DisplayBoxInfoSetting box={box} setBox={setBox} />
        <BoxPictureSetting box={box} setBox={setBox} />
      </SettingsCategory>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.setOpeningHoursTitle',
          defaultMessage: 'opening hours',
        })}
        description={intl.formatMessage({
          id: 'venue.setOpeningHoursDescription',
          defaultMessage: 'Customize the opening hours here',
        })}
      >
        <BoxOpeningHourSetting box={box} setBox={setBox} />
      </SettingsCategory>
    </Container>
  );
};
