import { RequirementDue, Status, StatusType } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Alert, Box, List, ListItem, Typography, useTheme } from '@mui/material';
import React from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { styled } from '@mui/material/styles';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const TitleContainer = styled(Box)(() => ({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const Banner = styled(Alert)`
  justify-content: center;
  border-radius: 0;
`;

export const InformationBanner = ({ isSubmitSuccessful, requirementsDue, status }: {
  status: StatusType,
  isSubmitSuccessful: boolean,
  requirementsDue: RequirementDue[],
}) => {
  const theme = useTheme();
  return <Box>
    {!isSubmitSuccessful && status === 'REQUIREMENT_DUE' &&
      <Banner icon={false} severity="error">
        <TitleContainer>
          <ErrorOutlineOutlinedIcon />
          <Typography><FormattedMessage id='settings.payments.checkout.onboarding.banner.requirement_due' /></Typography>
        </TitleContainer>
        <List dense={true}>
          {requirementsDue.map(requirement => {
            return <ListItem style={{ color: theme.palette.grey.A700 }}
                             key={requirement.field + requirement.reason}>{`- ${requirement.fieldLabel} : ${requirement.message || requirement.reason}`}</ListItem>;
          })}
        </List>
      </Banner>}
      {
      status === Status.RESTRICTED &&
      <Banner icon={false} severity="error">
        <TitleContainer>
          <ErrorOutlineOutlinedIcon />
          <Typography><FormattedMessage id='settings.payments.checkout.onboarding.banner.restricted' /></Typography>
        </TitleContainer>
      </Banner>
    }
    {
      status === Status.PENDING_REVIEW &&
      <Banner icon={false} severity="info">
        <TitleContainer>
          <ErrorOutlineOutlinedIcon />
          <Typography><FormattedMessage id='settings.payments.checkout.onboarding.banner.pending_review' /></Typography>
        </TitleContainer>
      </Banner>
    }
    {
      status === Status.ACTIVE &&
      <Banner icon={false} severity="success">
        <TitleContainer>
          <CheckCircleOutlineOutlinedIcon />
          <Typography><FormattedMessage id='settings.payments.checkout.onboarding.banner.active' /></Typography>
        </TitleContainer>
      </Banner>
    }

  </Box>;
};
