import { ChipProps, Theme } from '@mui/material';

export const ChipTheme = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: ChipProps, theme: Theme }) => {
      type PaletteColor = keyof typeof theme.palette;
      const paletteColor: PaletteColor = ownerState.color === 'default' ? 'primary' : ownerState.color ?? 'primary';
      const color = ownerState.color === 'primary' || ownerState.color === 'secondary' ? theme.palette[paletteColor].main : theme.palette[paletteColor].contrastText;

      const backgroundColor = theme.palette[paletteColor].light;

      return {
        backgroundColor,
        color: color,
        fontSize: '13px',
        lineHeight: '15px',
        '&:hover': {
          backgroundColor,
          color: color,
        },
      };
    },
  },
};
