import { CurrencyCode, Money } from '@sundayapp/web-money';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Text } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useState } from 'react';

export type MoneyDisplayProps = {
  money: Money;
};

export const MoneyDisplay = ({ money }: MoneyDisplayProps) => {
  const [value, setValue] = useState<string>('');

  const toValue = (moneyToMap: Money): string => {
    const amount = moneyToMap.amount / 100000;

    if (moneyToMap.currency === CurrencyCode.EUR) {
      return `${amount}€`;
    }

    if (moneyToMap.currency === CurrencyCode.GBP) {
      return `£${amount}`;
    }

    if (moneyToMap.currency === CurrencyCode.USD) {
      return `$${amount}`;
    }

    return amount.toString();
  };

  useEffect(() => {
    setValue(toValue(money));
  }, [money]);

  return <Text size="medium">{value}</Text>;
};
