import { Grid, Theme, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

export const NoEreputationData = (props: { theme: Theme }) => (
  <Grid container direction="column" alignItems="center" gap={2}>
    <Grid item>
      <img src="/img/reviews/no_data.svg" alt="no data yet" />
    </Grid>
    <Grid item>
      <Typography fontSize="1.25rem"><FormattedMessage id="reviews.ereputation.error.title" /></Typography>
    </Grid>
    <Grid item>
      <div style={{
        textAlign: 'center',
        fontSize: '1rem',
        color: props.theme.palette.text.disabled,
      }}
      >
        <Typography>
          <FormattedMessage id="reviews.ereputation.error.wait_for_it.1" />
        </Typography>
        <Typography>
          <FormattedMessage id="reviews.ereputation.error.wait_for_it.2" />
        </Typography>
      </div>
    </Grid>
  </Grid>
);
