import React, { useState } from 'react';
import { Box, Button, Modal } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Reviews } from '../../browse/domain/Reviews';
import { Review } from '../../browse/domain/Review';
import { BulkReply } from './BulkReply';
import { isFailure, isSuccess, noFailure, Result } from '../../../lib/Result';
import { TimeframeSelection } from '../TimeframePicker';
import { ampli } from 'src/ampli';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
};
export const ReplyToFiveStarReviews = ({
  reviews,
  onBulkReply,
  selectedTimeFrame,
}: {
  reviews: Reviews;
  onBulkReply: (repliedReviews: Review[]) => void;
  selectedTimeFrame: TimeframeSelection;
}) => {
  const intl = useIntl();
  const [opened, setOpened] = useState<boolean>(false);
  const fiveStarReviews = new Reviews(reviews.withRating(5).asArray());
  const snackbar = useSnackbar();

  const openModal = () => {
    setOpened(true);
    ampli.bulkReplyOpened({ numberOf5starsReviews: fiveStarReviews.length() });
  };

  const onCancel = () => {
    setOpened(false);
    ampli.bulkReplyCancelled();
  };

  const getOnBulkCompleted = (results: Result<Review, Error>[]) => {
    const repliesSent = results.filter(isSuccess).map((s) => s.value);
    const errors = results.filter(isFailure).map((s) => s.value);
    if (noFailure(results)) {
      snackbar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'venue.reviews.reply.bulk.success' }, { number_of_replies: repliesSent.length }),
      });
    } else {
      snackbar.addNotification({
        variant: 'warning',
        text: intl.formatMessage(
          { id: 'venue.reviews.reply.bulk.error' },
          {
            number_of_replies: repliesSent.length,
            number_of_errors: errors.length,
          },
        ),
      });
    }
    onBulkReply(repliesSent);
    setOpened(false);
  };

  return fiveStarReviews.length() === 0 ? (
    <></>
  ) : (
    <>
      <Button variant="contained" onClick={openModal}>
        <FormattedMessage id="venue.reviews.reply.bulk.action.button" />
      </Button>
      <Modal open={opened} onClose={onCancel}>
        <Box sx={style}>
          <BulkReply
            reviews={fiveStarReviews.asArray()}
            onBulkCompleted={getOnBulkCompleted}
            onCancel={onCancel}
            selectedTimeFrame={selectedTimeFrame}
          />
        </Box>
      </Modal>
    </>
  );
};
