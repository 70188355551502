import { useIntl } from 'src/app/i18n/TypedIntl';
import { AverageRatingsContentProps } from '../types/average_ratings_types';

export const useGetAverageRatingsLabels = () => {
  const intl = useIntl();
  const withSundayCaption = intl.formatMessage({ id: 'venue.home.reviews.google_rating.average' });
  const withoutSundayCaption = intl.formatMessage({ id: 'venue.home.reviews.google_rating.no_sunday' });
    
  return { 
    withSunday: {
      label: withSundayCaption,
      color: '#FF17E9',
      borderColor: '#FF17E9',
    },
    withoutSunday: {
      label: withoutSundayCaption,
      color: '#4285F4',
      borderColor: '#4285F4',
    }, 
  };
};

export const useGetAverageRatingsContent = (props: AverageRatingsContentProps) => {
  const intl = useIntl();
  const { averageRating, averageRatingLastWeek } = props;
  const withSundayCaption = intl.formatMessage({ id: 'venue.home.reviews.google_rating.average' });
  const withoutSundayCaption = intl.formatMessage({ id: 'venue.home.reviews.google_rating.no_sunday' });
  
  const averageRatingsDataTypes = {
    withSunday: {
      label: withSundayCaption,
      color: '#FF17E9',
      borderColor: '#FF17E9',
    },
    withoutSunday: {
      label: withoutSundayCaption,
      color: '#4285F4',
      borderColor: '#4285F4',
    },
  };

  const subItems = [
    {
      value: averageRating.toString(),
      label: intl.formatMessage({ id: 'venue.home.reviews.google_rating.current' }),
      icon: 'lineStroke',
      color: 'black',
      isCentered: false,
    },
    {
      value: averageRatingLastWeek.toString(),
      label: intl.formatMessage({ id: 'venue.home.reviews.google_rating.average' }),
      icon: 'circleLine',
      color: '#FF17E9',
      isCentered: false,
    },
    {
      value: '100',
      label: intl.formatMessage({ id: 'venue.home.reviews.google_rating.no_sunday' }),
      icon: 'circleLine',
      color: '#4285F4',
      isCentered: false,
    },
  ];
      
  return {
    labels: averageRatingsDataTypes,
    subItems,
    trend: 0,
  };
};