import React from 'react';
import { PayoutInMemoryPort } from '../infrastructure/PayoutInMemoryPort';
import { PayoutPdqInMemoryPort } from '../infrastructure/PayoutPdqInMemoryPort';
import { PayoutPort } from '../domain/PayoutPort';
import { PayoutPdqPort } from '../domain/PayoutPdqPort';

interface ConfigurationContainer {
  payoutPort: PayoutPort;
  payoutPdqPort: PayoutPdqPort
}

export const ConfigurationContainerContext = React.createContext<ConfigurationContainer>({
  payoutPort: PayoutInMemoryPort(),
  payoutPdqPort: PayoutPdqInMemoryPort(),
});
