import { useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import EditSvg from 'src/app/component/icons/EditSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { venueRevenuesPath, venueEndOfServicePath } from 'src/app/navigation/pathHelpers';
import { Business } from 'src/business/domain/Business';
import { LinkButton } from '@atoms/LinkButton.tsx/LinkButton';

type ReportingButtonrops = {
  business: Business;
};

export const ReportingButton = ({ business }: ReportingButtonrops) => {
  const intl = useIntl();
  const navigate = useNavigate();

  return (
    <LinkButton
      icon={<EditSvg size={20} color={themeV5.palette.text.secondary} />}
      title={intl.formatMessage({ id: 'venue.home.actions.reporting' })}
      onClick={() => {
        const accountingRevenuePath = venueRevenuesPath(business.id, 'TODAY');
        const endOfServicePath = venueEndOfServicePath(business.id);
        navigate(business.address.countryCode === 'FR' ? endOfServicePath : accountingRevenuePath, {});
      }}
    />
  );
};
