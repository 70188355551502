import React, { FC, HTMLAttributeAnchorTarget, PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { ButtonGroup, ClickAwayListener, Grow, Paper, Popper, PopperPlacementType } from '@mui/material';
import { IconButton } from '@sundayapp/b2b-react-component-library';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { LocalisationKey } from 'src/lang/en';

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
`;
type Props = {
  href: string | undefined;
  target: HTMLAttributeAnchorTarget | undefined;
} & PropsWithChildren;

const LinkHrefWrapper: FC<Props> = ({ href, target, children }) =>
  href ? (
    <a href={href} target={target} style={{ textDecoration: 'inherit', color: 'inherit' }}>
      {children}
    </a>
  ) : (
    <>{children}</>
  );

export type Action = {
  icon: ReactNode;
  action?: (event: MouseEvent) => void;
  href?: string;
  intlKey: LocalisationKey;
  visible: boolean;
};

export const ActionsButton: FC<{ actions: Action[], popperPlacement?: PopperPlacementType, sx?: any }> = ({ actions, popperPlacement, sx }) => {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const [open, setOpen] = React.useState(false);
  const handleMenuItemClick = (event: MouseEvent, action: Action) => {
    if (action.action) {
      action.action(event);
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const getPopperTransition = (placement: PopperPlacementType) => {
    switch (placement) {
      case 'left-start':
      case 'left-end':
      case 'left':
        return 'top bottom';
      case 'bottom':
        return 'left top';
      default:
        return 'left bottom';
    }
  };

  return (
    <ActionsWrapper>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button">
        <IconButton
          size="medium"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <MoreVertIcon fontSize={'large'} />
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
          ...sx,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={popperPlacement || 'right-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: getPopperTransition(placement),
            }}
          >
            <Paper sx={{ 
              borderRadius: '8px',
              boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
              minWidth: '175px' }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem sx={{ borderRadius: '8px', padding: 0, '& .MuiButtonBase-root': { padding: '8px 16px' } }}>
                  {actions
                    .filter((a) => a.visible)
                    .map((action, index) => (
                      <LinkHrefWrapper href={action.href} target="_blank" key={index}>
                        <MenuItem
                          key={action.intlKey}
                          onClick={(event) => handleMenuItemClick(event as unknown as MouseEvent, action)}
                        >
                          <div style={{ width: '20px', height: '20px', marginRight: '16px' }}>{action.icon}</div>
                          <FormattedMessage id={action.intlKey} />
                        </MenuItem>
                      </LinkHrefWrapper>
                    ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ActionsWrapper>
  );
};
