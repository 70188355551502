import { IconProps, SvgIcon } from '@mui/material';
import React from 'react';

export const TrendUpIcon = ({ sx }: IconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" fill="none" sx={{ width: '100%', height: '100%', ...sx }}>
      {/* eslint-disable-next-line max-len */}
        <path fillRule="evenodd" clipRule="evenodd" d="M3.51835 16.4817C7.09816 20.0615 12.9022 20.0615 16.482 16.4817C20.0618 12.9019 20.0618 7.09791 16.482 3.51811C12.9022 -0.0616998 7.09816 -0.0616994 3.51835 3.51811C-0.0614557 7.09791 -0.0614552 12.9019 3.51835 16.4817ZM7.05388 12.9462C6.72845 12.6208 6.72845 12.0931 7.05388 11.7677L10.3453 8.47623H7.64314C7.1829 8.47623 6.80981 8.10313 6.80981 7.6429C6.80981 7.18266 7.1829 6.80956 7.64314 6.80956L12.3572 6.80956C12.8174 6.80956 13.1905 7.18266 13.1905 7.6429L13.1905 12.3569C13.1905 12.8172 12.8174 13.1903 12.3572 13.1903C11.8969 13.1903 11.5239 12.8172 11.5239 12.3569V9.65474L8.2324 12.9462C7.90696 13.2716 7.37932 13.2716 7.05388 12.9462Z" fill="#8DD8A5"/>
    </SvgIcon>
  );
};

