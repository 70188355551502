import { DateTimePicker } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useLocation, useNavigate } from 'react-router';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Column } from '../../Column';
import { ReviewCards } from './allReviews/components/ReviewCards';
import ReviewCSVExporterButton from './allReviews/components/ReviewCSVExporterButton';
import { DatePickerContainer, ReviewListHeader, ReviewsHeader } from './BrowseReviews.style';
import { emptyReviews, Reviews } from './domain/Reviews';
import { DateRange } from '../domain/DateRange';
import { HttpReviewRepository } from './infrastructure/HttpReviewRepository';
import {
  DateFilter,
  PlatformFilter,
  toDimensionFilter,
  toPlatformFilter,
  toRatingFilter,
  toServerFilter,
  toTimeRangeFilter,
} from './BrowseReviewsViewModel';
import { useReviews } from './queries/useReviews';
import {
  RequireConnectedGoogleAccount,
} from '../settings/setup/google/components/RequireConnectedGoogleAccount';
import { sizeInCss, tShirt } from '../../Size';
import { ReviewHint } from './allReviews/components/ReviewHint';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const configuration = ConfigurationLoader.load();
const repository = new HttpReviewRepository(configuration.venueFeedbackBaseUrl);

export const BrowseReviews = () => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const navigate = useNavigate();

  const platformFilterLabel = intl.formatMessage({
    id: 'venue.reviews.all.platforms.filter.short-label',
    defaultMessage: 'Platforms',
  });
  const feedbackFilterLabel = intl.formatMessage({
    id: 'venue.reviews.all.feedbacks.filter.short-label',
    defaultMessage: 'Comments',
  });
  const reviewReplyFilterLabel = intl.formatMessage({
    id: 'venue.reviews.all.review_reply.filter.short-label',
    defaultMessage: 'Replies',
  });
  const ratingFilterLabel = intl.formatMessage({
    id: 'venue.reviews.all.ratings.filter.short-label',
    defaultMessage: 'Ratings',
  });
  const waiterFilterLabel = intl.formatMessage({
    id: 'venue.reviews.all.waiters.filter.short-label',
    defaultMessage: 'Waiters',
  });
  const dimensionFilterLabel = intl.formatMessage({
    id: 'venue.reviews.all.dimensions.filter.short-label',
    defaultMessage: 'Issues',
  });
  const selectedDateRangeShortLabel = intl.formatMessage({
    id: 'venue.reviews.all.date.filter.short-label',
    defaultMessage: 'Date',
  });
  const allPlatforms = intl.formatMessage({ id: 'venue.reviews.all.platforms.filter.label' });
  const allFeedbacks = intl.formatMessage({ id: 'venue.reviews.all.feedbacks.filter.label' });
  const allDimensions = intl.formatMessage({ id: 'venue.reviews.all.dimensions.filter.label' });
  const foodDimension = intl.formatMessage({ id: 'venue.reviews.food.dimensions.filter.label' });
  const ambianceDimension = intl.formatMessage({ id: 'venue.reviews.ambiance.dimensions.filter.label' });
  const serviceDimension = intl.formatMessage({ id: 'venue.reviews.service.dimensions.filter.label' });
  const valueDimension = intl.formatMessage({ id: 'venue.reviews.value.dimensions.filter.label' });
  const withFeedback = intl.formatMessage({ id: 'venue.reviews.with.feedbacks.filter.label' });
  const withoutFeedback = intl.formatMessage({ id: 'venue.reviews.without.feedbacks.filter.label' });
  const allReviewReply = intl.formatMessage({ id: 'venue.reviews.all.review_reply.filter.label' });
  const withReviewReply = intl.formatMessage({ id: 'venue.reviews.with.review_reply.filter.label' });
  const withoutReviewReply = intl.formatMessage({ id: 'venue.reviews.without.review_reply.filter.label' });
  const withoutSundayReviewReply = intl.formatMessage({ id: 'venue.reviews.without.sunday_review_reply.filter.label' });
  const withoutGoogleReviewReply = intl.formatMessage({ id: 'venue.reviews.without.google_review_reply.filter.label' });
  const allRatings = intl.formatMessage({ id: 'venue.reviews.all.ratings.filter.label' });
  const badRating = intl.formatMessage({ id: 'venue.reviews.bad.ratings.filter.label' });
  const topRating = intl.formatMessage({ id: 'venue.reviews.top.ratings.filter.label' });
  const allWaiters = intl.formatMessage({ id: 'venue.reviews.all.waiters.filter.label' });
  const last7days = intl.formatMessage({
    id: 'venue.reviews.last7days.filter.label',
    defaultMessage: 'last 7 days',
  });
  const previous7days = intl.formatMessage({
    id: 'venue.reviews.previous7days.filter.label',
    defaultMessage: 'previous 7 days',
  });
  const last30days = intl.formatMessage({
    id: 'venue.reviews.last30days.filter.label',
    defaultMessage: 'Last 30 days',
  });
  const today = intl.formatMessage({ id: 'venue.reviews.today.filter.label' });
  const yesterday = intl.formatMessage({ id: 'venue.reviews.yesterday.filter.label' });
  const customRange = intl.formatMessage({ id: 'venue.reviews.custom.filter.label' });

  const {
    search,
    pathname,
  } = useLocation();
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(DateRange.lastSevenDays());
  const [refreshedDateRange, setRefreshedDateRange] = useState<boolean>(false);
  const [selectedDateRangeLabel, setSelectedDateRangeLabel] = useState<DateFilter>(toTimeRangeFilter(search));
  const [dateRangeHasBeenChosen, setDateRangeHasBeenChosen] = useState<string>();
  const [filteredReviews, setFilteredReviews] = useState<Reviews>(emptyReviews());
  const [platformFilterValue, setPlatformFilterValue] = useState<PlatformFilter>(toPlatformFilter(search));
  const [feedbackFilterValue, setFeedbackFilterValue] = useState<string>(allFeedbacks);
  const [dimensionFilterValue, setDimensionFilterValue] = useState<string>(toDimensionFilter(search));
  const [reviewReplyFilterValue, setReviewReplyFilterValue] = useState<string>(allReviewReply);
  const [ratingFilterValue, setRatingFilterValue] = useState<string>(toRatingFilter(search));
  const [waiterFilterValue, setWaiterFilterValue] = useState<string>(toServerFilter(search, allWaiters));
  const [datePickerIsOpened, setDatePickerIsOpened] = useState<boolean>(false);
  const [allWaitersNames, setAllWaitersNames] = useState<string[]>([]);
  const [initialized, setInitialized] = useState<boolean>(false);

  const {
    value: reviewsResponse,
    loading: reviewsLoading,
  } = useReviews(business, toTimeRangeFilter(search));

  const filterReviewsByFeedback = (reviews: Reviews, value: string): Reviews => {
    if (value === allFeedbacks) return reviews.allFeedbacks();
    if (value === withFeedback) return reviews.withFeedback();
    if (value === withoutFeedback) return reviews.withoutFeedback();
    return new Reviews([]);
  };

  const filterReviewsByDimension = (reviews: Reviews, value: string): Reviews => {
    if (value === 'ALL_DIMENSION') return reviews.allDimensions();
    if (value === 'FOOD_AND_DRINKS') return reviews.withFoodDimension();
    if (value === 'AMBIANCE') return reviews.withAmbianceDimension();
    if (value === 'SERVICE') return reviews.withServiceDimension();
    if (value === 'VALUE_FOR_MONEY') return reviews.withValueDimension();
    return new Reviews([]);
  };

  const changeUrlQueryParams = (key: 'timerange' | 'platform' | 'server' | 'rating' | 'dimension', value: string) => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.set(key, value);
    navigate(`${pathname}?${urlSearchParams}`, { replace: true });
  };
  const removeUrlQueryParam = (key: string) => {
    const urlSearchParams = new URLSearchParams(search);
    urlSearchParams.delete(key);
    navigate(`${pathname}?${urlSearchParams}`, { replace: true });
  };

  const onInitialization = (platform: string, reviews: Reviews, waiterName: string, rating: string, dimension: string, uniqueWaiterNames: string[]) => {
    ampli.reviewsInitializationStarted({
      platform,
      waiterName,
    });
    let reviewsTmp = reviews;
    setPlatformFilterValue(platform as PlatformFilter);
    if (platform === 'ALL') reviewsTmp = reviews.allPlatforms();
    if (platform === 'SUNDAY') reviewsTmp = reviews.platform('sunday');
    if (platform === 'GOOGLE') reviewsTmp = reviews.platform('google');
    changeUrlQueryParams('platform', platform);

    if (uniqueWaiterNames.includes(waiterName)) {
      setWaiterFilterValue(waiterName);
      reviewsTmp = waiterName === allWaiters ? reviewsTmp.allWaiters() : reviewsTmp.withWaiter(waiterName);
    }

    reviewsTmp = reviewsTmp.withRatingsCategory(rating);
    changeUrlQueryParams('rating', rating);

    if (dimension === 'ALL_DIMENSION') reviewsTmp = reviewsTmp.allDimensions();
    if (dimension === 'FOOD_AND_DRINKS') reviewsTmp = reviewsTmp.withFoodDimension();
    if (dimension === 'AMBIANCE') reviewsTmp = reviewsTmp.withAmbianceDimension();
    if (dimension === 'SERVICE') reviewsTmp = reviewsTmp.withServiceDimension();
    if (dimension === 'VALUE_FOR_MONEY') reviewsTmp = reviewsTmp.withValueDimension();
    changeUrlQueryParams('dimension', dimension);

    setFilteredReviews(reviewsTmp);
  };

  const updateReviews = (range: DateRange) => {
    setRefreshedDateRange(true);
    repository
      .listReviews(business.id, range?.toTimeRange())
      .then((response) => {
        const reviews = filteredReviews.updateWith(response.unfilteredReview());
        setAllWaitersNames(reviews.collectUniqueWaiterNames());
        setFilteredReviews(reviews);
      })
      .finally(() => setRefreshedDateRange(false));
  };

  const updateAndTrackSelectedDateRange = (buttonType: string, range: DateRange) => {
    const shortcut = buttonType || 'disabled';
    const startDate = range.startDate();
    const endDate = range.endDate();
    ampli.reviewsDateShortcutFilterClicked({
      shortcut,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    });
    setSelectedDateRange(range);
    updateReviews(range);
  };

  const onDateChange = (value: string) => {
    switch (value) {
      case 'LAST_7_DAYS': {
        updateAndTrackSelectedDateRange(value, DateRange.lastSevenDays());
        setSelectedDateRangeLabel('LAST_7_DAYS');
        setDateRangeHasBeenChosen(undefined);
        changeUrlQueryParams('timerange', 'LAST_7_DAYS');
        break;
      }
      case 'LAST_30_DAYS': {
        updateAndTrackSelectedDateRange(value, DateRange.lastThirtyDays());
        setDateRangeHasBeenChosen(undefined);
        setSelectedDateRangeLabel('LAST_30_DAYS');
        changeUrlQueryParams('timerange', 'LAST_30_DAYS');
        break;
      }
      case 'TODAY': {
        updateAndTrackSelectedDateRange(value, DateRange.today());
        setSelectedDateRangeLabel('TODAY');
        setDateRangeHasBeenChosen(undefined);
        changeUrlQueryParams('timerange', 'TODAY');
        break;
      }
      case 'YESTERDAY': {
        updateAndTrackSelectedDateRange(value, DateRange.yesterday());
        setDateRangeHasBeenChosen(undefined);
        setSelectedDateRangeLabel('YESTERDAY');
        changeUrlQueryParams('timerange', 'YESTERDAY');
        break;
      }
      case customRange: {
        setDatePickerIsOpened(!datePickerIsOpened);
        break;
      }
      default: {
        break;
      }
    }
  };
  const onSelectedCustomRange = (ranges: Date[]) => {
    console.log('change');

    if (ranges.length === 2) {
      const reviewsDateRange = DateRange.fromDates(ranges[0], ranges[1]);
      updateAndTrackSelectedDateRange(customRange, reviewsDateRange);
      setDateRangeHasBeenChosen(reviewsDateRange.shortLabel(intl.locale));
      setSelectedDateRangeLabel(customRange);
      removeUrlQueryParam('timerange');
    }
  };

  useEffect(() => {
    ampli.newReviewsTabViewed();
  }, []);

  const closeDatePicker = () => setDatePickerIsOpened(false);
  const changeSelectedRange = (range: Date[]) => {
    onSelectedCustomRange(range);
    closeDatePicker();
  };

  const onWithFeedbackChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    ampli.reviewsWithWithoutFeedbackShortcutFilterClicked({ feedback: value });
    setFeedbackFilterValue(value);
    setFilteredReviews(filterReviewsByFeedback(filteredReviews, value));
  };

  const onWithDimensionChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    setDimensionFilterValue(value);
    setFilteredReviews(filterReviewsByDimension(filteredReviews, value));
    changeUrlQueryParams('dimension', value);
  };

  const onPlatformChange = (value: string, reviews: Reviews) => {
    ampli.reviewsPlatformShortcutFilterClicked({ platform: value });
    setPlatformFilterValue(value as PlatformFilter);
    if (value === 'ALL') setFilteredReviews(reviews.allPlatforms());
    if (value === 'SUNDAY') setFilteredReviews(reviews.platform('sunday'));
    if (value === 'GOOGLE') setFilteredReviews(reviews.platform('google'));
    changeUrlQueryParams('platform', value);
  };

  const onWithWithReviewReplyChange = (event: SelectChangeEvent) => {
    const filterReviewsByReviewReply = (reviews: Reviews, filter: string): Reviews => {
      if (filter === allReviewReply) return reviews.withAndWithoutReviewReply();
      if (filter === withoutReviewReply) return reviews.withoutReviewReply();
      if (filter === withoutSundayReviewReply) return reviews.withoutReviewReply();
      if (filter === withoutGoogleReviewReply) return reviews.withoutReviewReply();
      if (filter === withReviewReply) return reviews.withReviewReply();
      return new Reviews([]);
    };
    const applyFilterByPlatform = (reviews: Reviews, filter: string) => {
      if (filter === withoutSundayReviewReply) {
        onPlatformChange('SUNDAY', reviews);
      } else if (filter === withoutGoogleReviewReply) {
        onPlatformChange('GOOGLE', reviews);
      } else {
        setFilteredReviews(reviews);
      }
    };

    const { value: filter } = event.target;
    ampli.reviewsWithWithoutReviewReplyShortcutFilterClicked({ reply: filter });
    setReviewReplyFilterValue(filter);

    const reviewsFilteredByReply = filterReviewsByReviewReply(filteredReviews, filter);

    applyFilterByPlatform(reviewsFilteredByReply, filter);
  };

  const onWithRatingChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    ampli.reviewsWithRatingShortcutFilterClicked({ rating: value });
    setRatingFilterValue(value);
    changeUrlQueryParams('rating', value);

    const ratingInNumber = (rating: string) => {
      switch (rating) {
        case 'BAD':
          return [1, 2, 3];
        case 'TOP':
          return [4, 5];
        default:
          return [1, 2, 3, 4, 5];
      }
    };

    const reviews =
      value === allRatings ? filteredReviews.allRatings() : filteredReviews.withRatings(ratingInNumber(value));
    setFilteredReviews(reviews);
  };

  const onWithWaiterChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    ampli.reviewsWithWaiterShortcutFilterClicked({ waiter: value });
    setWaiterFilterValue(value);

    const reviews = value === allWaiters ? filteredReviews.allWaiters() : filteredReviews.withWaiter(value);
    setFilteredReviews(reviews);
    changeUrlQueryParams('server', value);
  };

  const getComponentLabelForCustomDate = () => dateRangeHasBeenChosen ?? customRange;
  const getComponentValueForCustomDate = () => dateRangeHasBeenChosen ?? selectedDateRangeLabel;

  useEffect(() => {
    if (!reviewsLoading && reviewsResponse && !initialized) {
      const reviews = new Reviews(reviewsResponse.unfilteredReview());
      setInitialized(true);
      const uniqueWaiterNames = reviews.collectUniqueWaiterNames();
      setAllWaitersNames(uniqueWaiterNames);
      onInitialization(platformFilterValue, reviews, waiterFilterValue, ratingFilterValue, dimensionFilterValue, uniqueWaiterNames);
    }
  }, [reviewsResponse, reviewsLoading]);
  return (
    <Column>
      <ReviewsHeader>
        <ReviewHint
          englishReviewHintUrl={configuration.englishReviewHintUrl}
          frenchReviewHintUrl={configuration.frenchReviewHintUrl}
        />
      </ReviewsHeader>

      <RequireConnectedGoogleAccount />

      <Box marginTop={sizeInCss(tShirt('m'))}>
        <ReviewListHeader>
          <Typography variant="h6">
            <FormattedMessage id="reviews.all_reviews" defaultMessage="all reviews" /> :{' '}
            {filteredReviews.asArray().length}
          </Typography>
          <ReviewCSVExporterButton
            venueId={business.id}
            venueName={business.name}
            reviews={filteredReviews.asArray()}
          />
        </ReviewListHeader>
        <div style={{ marginBottom: '1rem' }}>
          <Grid container direction="row" gap={1} spacing={1}>
            {datePickerIsOpened && (
              <Grid item>
                <DatePickerContainer>
                  <DateTimePicker
                    onApply={changeSelectedRange}
                    onClose={closeDatePicker}
                    value={[selectedDateRange.startDate(), selectedDateRange.endDate()]}
                    applyButtonLabel={intl.formatMessage({ id: 'datepicker.apply' })}
                    cancelButtonLabel={intl.formatMessage({ id: 'datepicker.cancel' })}
                    startTimeInputLabel={intl.formatMessage({ id: 'datepicker.start-time' })}
                    endTimeInputLabel={intl.formatMessage({ id: 'datepicker.end-time' })}
                  />
                </DatePickerContainer>
              </Grid>
            )}
            <Grid item>
              <FormControl>
                <InputLabel id="date-range-filter-select-label">{selectedDateRangeShortLabel}</InputLabel>
                <Select
                  labelId="date-range-filter-select-label"
                  id="date-range-filter-select"
                  value={getComponentValueForCustomDate()}
                  label={selectedDateRangeShortLabel}
                  onChange={(event) => onDateChange(event.target.value)}
                  disabled={datePickerIsOpened}
                >
                  <MenuItem value="TODAY" key="TODAY">
                    {today}
                  </MenuItem>
                  <MenuItem value="YESTERDAY" key="YESTERDAY">
                    {yesterday}
                  </MenuItem>
                  <MenuItem value="LAST_7_DAYS" key="LAST_7_DAYS">
                    {last7days}
                  </MenuItem>
                  {selectedDateRangeLabel === 'PREVIOUS_7_DAYS' &&
                    <MenuItem value="PREVIOUS_7_DAYS"
                              key="PREVIOUS_7_DAYS">
                      {previous7days}
                    </MenuItem>
                  }
                  <MenuItem value="LAST_30_DAYS" key="LAST_30_DAYS">
                    {last30days}
                  </MenuItem>
                  <MenuItem
                    onClick={() => onDateChange(customRange)}
                    value={getComponentLabelForCustomDate()}
                    key={getComponentLabelForCustomDate()}
                  >
                    {getComponentLabelForCustomDate()}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel id="platform-filter-select-label">{platformFilterLabel}</InputLabel>
                <Select
                  labelId="platform-filter-select-label"
                  id="platform-filter-select"
                  value={platformFilterValue}
                  label={platformFilterLabel}
                  onChange={(event) => onPlatformChange(event.target.value, filteredReviews)}
                >
                  <MenuItem value="ALL">{allPlatforms}</MenuItem>
                  <MenuItem value="SUNDAY">sunday</MenuItem>
                  <MenuItem value="GOOGLE">google</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel id="feedback-filter-select-label">{feedbackFilterLabel}</InputLabel>
                <Select
                  labelId="feedback-filter-select-label"
                  id="feedback-filter-select"
                  value={feedbackFilterValue}
                  label={feedbackFilterLabel}
                  onChange={onWithFeedbackChange}
                >
                  <MenuItem value={allFeedbacks}>{allFeedbacks}</MenuItem>
                  <MenuItem value={withFeedback}>{withFeedback}</MenuItem>
                  <MenuItem value={withoutFeedback}>{withoutFeedback}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel id="review-reply-filter-select-label">{reviewReplyFilterLabel}</InputLabel>
                <Select
                  labelId="review-reply-filter-select-label"
                  id="review-reply-filter-select"
                  value={reviewReplyFilterValue}
                  label={reviewReplyFilterLabel}
                  onChange={onWithWithReviewReplyChange}
                >
                  <MenuItem value={allReviewReply}>{allReviewReply}</MenuItem>
                  <MenuItem value={withReviewReply}>{withReviewReply}</MenuItem>
                  <MenuItem value={withoutReviewReply}>{withoutReviewReply}</MenuItem>
                  <MenuItem value={withoutGoogleReviewReply}>{withoutGoogleReviewReply}</MenuItem>
                  <MenuItem value={withoutSundayReviewReply}>{withoutSundayReviewReply}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel id="rating-filter-select-label">{ratingFilterLabel}</InputLabel>
                <Select
                  labelId="rating-filter-select-label"
                  id="rating-filter-select"
                  value={ratingFilterValue}
                  label={ratingFilterLabel}
                  onChange={onWithRatingChange}
                >
                  <MenuItem value={'ALL'}>{allRatings}</MenuItem>
                  <MenuItem value={'BAD'}>{badRating}</MenuItem>
                  <MenuItem value={'TOP'}>{topRating}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel id="waiter-filter-select-label">{waiterFilterLabel}</InputLabel>
                <Select
                  labelId="waiter-filter-select-label"
                  id="waiter-filter-select"
                  value={allWaitersNames.includes(waiterFilterValue) ? waiterFilterValue : allWaiters}
                  label={waiterFilterLabel}
                  onChange={onWithWaiterChange}
                >
                  {[allWaiters].concat(allWaitersNames)
                    .map((waiterOtion) => (
                      <MenuItem key={waiterOtion} value={waiterOtion}>
                        {waiterOtion}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl>
                <InputLabel id="feedback-filter-select-label">{dimensionFilterLabel}</InputLabel>
                <Select
                  labelId="dimension-filter-select-label"
                  id="dimension-filter-select"
                  value={dimensionFilterValue}
                  label={dimensionFilterLabel}
                  onChange={onWithDimensionChange}
                >
                  <MenuItem value={'ALL_DIMENSION'}>{allDimensions}</MenuItem>
                  <MenuItem value={'FOOD_AND_DRINKS'}>{foodDimension}</MenuItem>
                  <MenuItem value={'AMBIANCE'}>{ambianceDimension}</MenuItem>
                  <MenuItem value={'SERVICE'}>{serviceDimension}</MenuItem>
                  <MenuItem value={'VALUE_FOR_MONEY'}>{valueDimension}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        {(reviewsLoading || refreshedDateRange) && <CircularProgress />}
        {!(reviewsLoading || refreshedDateRange) && <ReviewCards reviews={filteredReviews} />}
      </Box>
    </Column>
  )
  ;
};
