import { useQuery } from '@tanstack/react-query';
import { AllWaiters } from '../domain/AllWaiters';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { WaiterHttpPort } from '../infrastructure/WaiterHttpPort';

export const QUERYKEY_WAITER_REFERENTIAL = { scope: 'waiter_referential' };

const buildQueryKey = (businessId: string) => [QUERYKEY_WAITER_REFERENTIAL, businessId] as const;

export const getWaiterReferentialQuery = (businessId: string): Promise<AllWaiters> => {
  const configuration = ConfigurationLoader.load();
  const waiterPort = new WaiterHttpPort(configuration.bookkeepingApiBaseUrl);
  return waiterPort.getWaiters(businessId);
};

export const useGetWaiterReferential = (businessId: string) =>
  useQuery({ queryKey: buildQueryKey(businessId), queryFn: () => getWaiterReferentialQuery(businessId) });
