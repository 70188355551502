import styled from 'styled-components';
import { colorPalette, fontFamily } from '../../../stylesheet';

export const StyledTextarea = styled.textarea`
  font-family: ${fontFamily};
  font-size: 16px;
  line-height: 18px;
  background-color: transparent;
  color: ${colorPalette.shadow};
  letter-spacing: -0.03em;
  display: block;
  height: 100%;
  width: 100%;
  outline: 0;
  resize: none;
  border: 0;

  &::placeholder {
    color: ${colorPalette.placeholderColor};
  }
  &:hover {
    outline: none;
  }
  &:focus-within {
    outline: none;
  }
`;
