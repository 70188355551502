import { LoadingButton } from '@mui/lab';
import { ButtonProps } from '@mui/material';
import { useState } from 'react';

/**
 * Button component which use a function `handle` returning a Promise to handle the loading state of a button.
 * 
 * @param props button props plus a handle function returning a Promise
 * @returns a button with a loading state
 */
export function LoadingButtonPromise(props: ButtonProps & { handle: () => Promise<any> }) {
  const [loading, setLoading] = useState(false);

  const onClickWrapper = () => {
    setLoading(true);
    props.handle()
      .finally(() => setLoading(false));
  };

  return (
    <LoadingButton {...props} onClick={onClickWrapper} loading={loading}>
        {props.children}
    </LoadingButton>
  );
}
