import { styled } from '@mui/material/styles';
import { MOBILE_WIDTH_THRESHOLD } from 'src/ordering/stylesheet';

export const MenuBlocks = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 40%;
  margin-bottom: 2rem;
  @media (max-width: ${MOBILE_WIDTH_THRESHOLD}) {
    max-width: 100%;
  }
`;

export const CategoriesWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const MenuDeletionBtnWrapper = styled('div')`
  display: flex;
  justify-content: center;
`;
