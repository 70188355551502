import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SearchOutlined } from '@mui/icons-material';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import { NoProductOverlay } from '../ProductPage/components/NoProductOverlay';
import { buildBlockSelectionColumns } from './buildBlockSelectionColumns';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useGetAllBlocksByBusinessId } from '../../queries/block/getAllBlocksByBusinessIdQuery';
import { blockMatch } from './blockMatch';
import { Block } from '../../domain/Block';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export type Props = {
  title: string;
  defaultSelectedBlocks: string[];
  maxSelectionCount?: number;
  onBlockSelectionFinished: (blockIds: string[]) => void;
  customFilter?: (block: Block) => boolean;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
};
export const BlocksSelectionModal = ({
  title,
  defaultSelectedBlocks,
  maxSelectionCount = 0,
  onBlockSelectionFinished,
  customFilter = () => true,
  isOpen,
  setIsOpen,
}: Props) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const snackBar = useSnackbar();

  const [selectedBlocks, setSelectedBlocks] = useState<string[]>(defaultSelectedBlocks ?? []);

  const { data: blocks, isLoading } = useGetAllBlocksByBusinessId(business.id);

  const [filteredBlocks, setFilteredBlocks] = useState<Block[]>([]);
  const [filter, setFilter] = useState<string | undefined>(undefined);

  useEffect(() => {
    setFilteredBlocks(blocks?.filter(customFilter).filter((b) => blockMatch(filter, b)) ?? []);
  }, [filter, blocks]);

  if (isLoading) return <CircularProgress />;

  const onClickAddRemove = (blockId: string) => {
    if (selectedBlocks.includes(blockId)) {
      setSelectedBlocks([...selectedBlocks.filter((id) => id !== blockId)]);
    } else if (maxSelectionCount > 0 && selectedBlocks.length >= maxSelectionCount) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage(
          {
            id: 'menus.block.selection.max_count',
          },
          { count: maxSelectionCount },
        ),
      });
    } else {
      setSelectedBlocks([...selectedBlocks, blockId]);
    }
  };

  const isSelected = (blockId: string) => selectedBlocks.includes(blockId);
  const onClose = () => {
    setSelectedBlocks(defaultSelectedBlocks);
    setIsOpen(false);
  };
  return (
    <Dialog fullWidth maxWidth="lg" open={isOpen} onClose={onClose}>
      <DialogContent>
        <Header>
          <Typography fontSize="large" sx={{ padding: '16px' }}>
            {title}
          </Typography>
          <IconButton aria-label="close" onClick={onClose} sx={{ height: 'fit-content' }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Header>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: '100%', margin: '8px 0' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          placeholder={intl.formatMessage({ id: 'menus.blocks.search.placeholder' })}
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        />
        <DataGrid
          rows={filteredBlocks}
          columns={buildBlockSelectionColumns(intl, business.id, isSelected)}
          rowHeight={50}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          columnHeaderHeight={0}
          disableRowSelectionOnClick
          getRowId={(row) => row.id}
          onRowClick={(param) => onClickAddRemove(param.row.id)}
          pageSizeOptions={[10]}
          slots={{
            noRowsOverlay: NoProductOverlay,
          }}
        />
        <Divider />
        <Footer>
          <Typography fontSize="large">
            <FormattedMessage id="menus.block.selection.summary" values={{ count: selectedBlocks.length.toString() }} />
          </Typography>
          <Button variant="contained" onClick={() => onBlockSelectionFinished(selectedBlocks)}>
            <FormattedMessage id="menus.block.selection.confirm" />
          </Button>
        </Footer>
      </DialogContent>
    </Dialog>
  );
};
