import axios from 'axios';
import { BoxOrderPacingData, OrderStatus } from '../../orders/types';
import { OrderingVenueRepository } from './OrderingVenueRepository';
import { CrmAuthenticationDetails, VenueDetails, VenueDetailsFields } from '../types';
import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import { PromoCode, PromoCodeAmount, PromoCodes } from '../../promoCodes/types';
import { SundayDevice } from '../../area/domain/SundayDevice';
import { Table } from '../domain/Table';
import { BoxStats } from '../../box/domain/BoxStat';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { z } from 'zod';

interface VenueTablesResult {
  tables: Table[];
}

const statsResponseSchema = z.object({
  boxId: z.string(),
  inPreparation: z.array(z.object({
    boxOrderId: z.string(),
    orderId: z.string(),
    displayId: z.string(),
    status: z.string(),
    placedAt: z.number().default(0),
  })).default([]),
});

export class OrderingVenueRepositoryHttp extends RepositoryHttp implements OrderingVenueRepository {
  constructor(private orderingBackendUrl: string) {
    super();
  }

  updateBoxOrderStatus(boxOrderId: string, boxOrderStatus: OrderStatus, reason: string): Promise<void> {
    return axios.post(
      `${this.orderingBackendUrl}/box-orders/${boxOrderId}/box-order-status`,
      { status: OrderStatus[boxOrderStatus], reason: reason },
      this.withDefaultHeader(),
    );
  }

  sendBoxOrderToPos(boxOrderId: string): Promise<void> {
    return axios.post(`${this.orderingBackendUrl}/box-orders/${boxOrderId}/sendToPos`, {}, this.withDefaultHeader());
  }

  async getVenue(enrollmentId: EnrollmentId): Promise<VenueDetails> {
    const response = await axios.get<VenueDetails>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}`,
      this.withDefaultHeader(),
    );

    return response.data;
  }

  async updateVenue(enrollmentId: EnrollmentId, update: Partial<VenueDetailsFields>): Promise<void> {
    await axios.put<void>(`${this.orderingBackendUrl}/enrollments/${enrollmentId}`, update, this.withDefaultHeader());
  }

  async storeCrmAuthentication(enrollmentId: EnrollmentId, update: Partial<CrmAuthenticationDetails>): Promise<void> {
    await axios.put<void>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/crm-config`,
      update,
      this.withDefaultHeader(),
    );
  }

  async getVenueTables(enrollmentId: EnrollmentId): Promise<Table[]> {
    const response = await axios.get<VenueTablesResult>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/tables`,
      this.withDefaultHeader(),
    );

    return response.data.tables;
  }

  async getPromoCodes(enrollmentId: EnrollmentId): Promise<PromoCode[]> {
    const response = await axios.get<PromoCodes>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/vouchers`,
      this.withDefaultHeader(),
    );
    return response.data.vouchers.sort((a, b) => a.code.localeCompare(b.code));
  }

  async getPromoCode(voucherId: string): Promise<PromoCode> {
    const response = await axios.get<PromoCode>(
      `${this.orderingBackendUrl}/vouchers/${voucherId}`,
      this.withDefaultHeader(),
    );
    return response.data;
  }

  async updatePromoCode(voucherId: string, updatedPromoCode: Partial<PromoCode>): Promise<void> {
    await axios.put<void>(
      `${this.orderingBackendUrl}/vouchers/${voucherId}`,
      updatedPromoCode,
      this.withDefaultHeader(),
    );
    return Promise.resolve(undefined);
  }

  async createPromoCode(enrollmentId: EnrollmentId, createPromoCode: Partial<PromoCode>): Promise<string> {
    const response = await axios.post<string>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/vouchers`,
      createPromoCode,
      this.withDefaultHeader(),
    );
    return response.data;
  }

  async createPromoCodeAmount(enrollmentId: EnrollmentId, createPromoCode: Partial<PromoCodeAmount>): Promise<string> {
    const response = await axios.post<string>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/vouchers-amount`,
      createPromoCode,
      this.withDefaultHeader(),
    );
    return response.data;
  }

  async deletePromoCode(voucherId: string): Promise<boolean> {
    const response = await axios.delete<string>(
      `${this.orderingBackendUrl}/vouchers/${voucherId}`,
      this.withDefaultHeader(),
    );
    return response.data === 'OK';
  }

  async getSundayDevices(enrollmentId: EnrollmentId): Promise<SundayDevice[]> {
    const response = await axios.get<SundayDevice[]>(
      `${this.orderingBackendUrl}/enrollments/${enrollmentId}/linked-sunday-devices`,
      this.withDefaultHeader(),
    );

    return response.data;
  }

  async getPacingData(boxId: string): Promise<BoxOrderPacingData | null> {
    const response = await axios.get<BoxOrderPacingData>(
      `${this.orderingBackendUrl}/boxes/${boxId}/pacing-data`,
      {
        ...this.withDefaultHeader(),
        validateStatus: (status) => (status >= 200 && status < 300) || status === 404,
      },
    );

    if (response.status === 404) {
      return null;
    }
    return response.data;
  }

  async getBoxStats(boxId: string): Promise<BoxStats> {
    const response = await axios.get<unknown>(
      `${this.orderingBackendUrl}/boxes/${boxId}/stats`,
      this.withDefaultHeader(),
    );

    const data = statsResponseSchema.parse(response.data);

    return {
      ...data,
      inPreparation: data.inPreparation.map((inPrep) => ({
        ...inPrep,
        placedAt: new Date(inPrep.placedAt * 1000),
      })),
    };
  }
}
