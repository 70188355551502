import React, { FC } from 'react';
import { CheckCircleSvg } from 'src/components/icons/CheckCircleSvg';
import { ShareSvg } from '../assets/ShareSvg';
import { ZapFastSvg } from '../assets/ZapFastSvg';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import PlusSvg from '../../app/component/icons/PlusSvg';
import landingImg from '../assets/no_payment_link_landing.png';
import { LocalisationKey } from 'src/lang/en';

export const NoPaymentLinkLanding: FC<{ openCreateLinkModal: () => void }> = ({ openCreateLinkModal }) => {
  const steps: { translationKey: LocalisationKey; icon: React.JSX.Element }[] = [
    { icon: <CheckCircleSvg />, translationKey: 'payment_link.landing.explanations.setup' },
    { icon: <ShareSvg />, translationKey: 'payment_link.landing.explanations.share_link' },
    { icon: <ZapFastSvg />, translationKey: 'payment_link.landing.explanations.take_payment' },
  ];

  const theme = useTheme();

  theme.typography = {
    ...theme.typography,
    h3: {
      fontFamily: '"Helvetica Neue", "Arial", sans-serif',
      fontSize: '40px',
      fontWeight: 400,
      lineHeight: '100%',
      letterSpacing: '-2px',
    },
  };

  const flex = isMobileScreen() ? '0 0 100%' : '0 0 50%';

  return <Grid container sx={{ background: 'white', borderRadius: '24px', width: '100%' }}>
    <Grid item direction="column" sx={{ padding: '40px 24px', display: 'flex' }} gap={'24px'}
          flex={flex}>
      <Box>
        <Typography variant="h3" color={theme.palette.text.primary}>
          <FormattedMessage id="payment_link.landing.title" />
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        {steps.map((step, index) => <Box key={index} padding="8px 16px" display="flex" gap="16px">
          <Box display="flex" alignItems="center">{step.icon}</Box>
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle1" color={theme.palette.text.secondary}>
              <FormattedMessage id={step.translationKey} />
            </Typography>
          </Box>
        </Box>)}
      </Box>
      <Box>
        <Button size="large" variant="contained" onClick={openCreateLinkModal}>
          <Box display="flex" justifyContent="center" alignItems="center" gap="8px" padding="8px 22px">
            <PlusSvg color="white" />
            <Typography variant="subtitle1">
              <FormattedMessage id="payment_link.landing.cta.create_link" />
            </Typography>
          </Box>
        </Button>
      </Box>
    </Grid>
    <Grid item direction="column" flex={flex}>
      <img src={landingImg} alt="landing image"
           style={{ maxHeight: '480px', objectFit: 'contain', maxWidth: '100%' }} />
    </Grid>
  </Grid>;
};
