/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const GoogleSvg: React.FC<Props> = ({ size = 20, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M14.4 8.14823C14.4 7.60009 14.3555 7.20009 14.2593 6.78528H8V9.25932H11.674C11.6 9.87416 11.2 10.8001 10.3111 11.4223L10.2986 11.5051L12.2777 13.0383L12.4148 13.0519C13.674 11.889 14.4 10.1778 14.4 8.14823Z" fill="#4285F4" />
    <path d="M7.99952 14.6667C9.79949 14.6667 11.3106 14.0741 12.4143 13.0519L10.3106 11.4223C9.74766 11.8149 8.99209 12.0889 7.99952 12.0889C6.23657 12.0889 4.74028 10.926 4.2069 9.3186L4.12871 9.32524L2.07086 10.9178L2.04395 10.9926C3.14023 13.1704 5.39209 14.6667 7.99952 14.6667Z" fill="#34A853" />
    <path d="M4.20705 9.31855C4.06632 8.90374 3.98487 8.45926 3.98487 8.00002C3.98487 7.54074 4.06632 7.09631 4.19965 6.6815L4.19592 6.59316L2.11228 4.97498L2.0441 5.0074C1.59227 5.91112 1.33301 6.92595 1.33301 8.00002C1.33301 9.07409 1.59227 10.0889 2.0441 10.9926L4.20705 9.31855Z" fill="#FBBC05" />
    <path d="M7.99952 3.91113C9.25135 3.91113 10.0958 4.45187 10.5773 4.90375L12.4588 3.06671C11.3032 1.99264 9.79949 1.33337 7.99952 1.33337C5.39209 1.33337 3.14023 2.82966 2.04395 5.00742L4.19949 6.68152C4.74028 5.07411 6.23657 3.91113 7.99952 3.91113Z" fill="#EB4335" />
  </svg>
);

export default GoogleSvg;
