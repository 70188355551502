import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { EditIcon } from 'src/app/component/icons/EditIcon';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const EditProductsSelectionButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return <Box display="flex" alignItems="center" gap={1} onClick={onClick} sx={{ cursor: 'pointer' }}>
    <EditIcon />
    <Typography variant="subtitle1">
      <FormattedMessage id="menus.catalog.products.form.variant_group.edit_variants.button" />
    </Typography>
  </Box>;
};
