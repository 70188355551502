export type Success<S> = {
  type: 'success';
  value: S
};
export const success = <S extends any>(value: S) => ({
  type: 'success',
  value,
});
export type Failure<F> = {
  type: 'failure';
  value: F
};
export const failure = <F extends any>(value: F) => ({
  type: 'failure',
  value,
});
export type Result<S, F> = Success<S> | Failure<F>;

export const isSuccess = <S, F>(result: Result<S, F>): result is Success<S> => result.type === 'success';
export const isFailure = <S, F>(result: Result<S, F>): result is Failure<F> => result.type === 'failure';

export const noFailure = <S, F>(results: Result<S, F>[]): boolean => !results.find(isFailure);
