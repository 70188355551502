import React, { useState } from 'react';
import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { MoneyView } from '@sundayapp/web-money';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { RowProps, salesSummaryRevenueNames } from './RowProps';
import { RowWithoutDetails } from './RowWithoutDetails';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { ServiceCharge } from './ServiceCharge';


export const RowWithDetails = ({
  revenueAndTips,
  hideTips,
  showDirectTips,
  showPooledTips,
  eosConfiguration,
}: {
  revenueAndTips: RowProps;
  hideTips: boolean;
  showDirectTips: boolean;
  showPooledTips: boolean;
  eosConfiguration: EndOfServiceConfiguration;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const intl = useIntl();

  const localisationKey = salesSummaryRevenueNames.find(v => v === revenueAndTips.name);
  const label = localisationKey ? intl.formatMessage({ id: localisationKey }) : revenueAndTips.name;

  return (
    <>
      <TableRow>
        <TableCell component="th">
          <Box display="flex" gap="10px">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>

            <Typography>
              {label}
            </Typography>
          </Box>
        </TableCell>
        <TableCell component="th" align="right">
          <Typography>
            {revenueAndTips.numberOfPayment}
          </Typography>
        </TableCell>
        <TableCell component="th" align="right">
          <MoneyView value={revenueAndTips.total} />
        </TableCell>
        {!hideTips &&
          <TableCell component="th" sx={{ color: '#70707B' }} align="right">
            <MoneyView value={revenueAndTips.revenue} />
          </TableCell>
        }
        {!hideTips && showPooledTips &&
          <TableCell component="th" sx={{ color: '#70707B' }} align="right">
            <MoneyView value={revenueAndTips.tips} />
          </TableCell>
        }
        {!hideTips && showDirectTips &&
          <TableCell component="th" sx={{ color: '#70707B' }} align="right">
            <MoneyView value={revenueAndTips.sundayTips} />
          </TableCell>
        }
        {eosConfiguration.displayServiceCharge &&
          <TableCell component="th" sx={{ color: '#70707B' }} align="right">
            <ServiceCharge revenue={revenueAndTips} />
          </TableCell>
        }
      </TableRow>
      {open && revenueAndTips.details.map((revenueAndTip) => (
        <RowWithoutDetails showPooledTips={showPooledTips}
                           revenue={revenueAndTip}
                           key={revenueAndTip.name}
                           showDirectTips={showDirectTips}
                           isRoot={false}
                           hideTips={hideTips}
                           eosConfiguration={eosConfiguration} />
      ))}

    </>
  );
};
