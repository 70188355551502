import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { ErrorInfo } from 'react';

export const dataDogErrorHandler = (error: unknown, info?: ErrorInfo) => {
  if (datadogRum) {
    datadogRum.addError(error, {
      stack: info?.componentStack || '',
    });
    if (error instanceof Error) {
      datadogLogs.logger.error('APP CRASHED', {}, error);
    }
  }
};
