import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box } from '../../domain/Box';
import { BoxStatsDetails } from './BoxStatsDetails';

type BoxStatsContainerProps = {
  boxes: Box[];
};

const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 32px;
`;

const Item = styled.div`
  display: flex;
`;

type ItemColorProps = {
  color: string;
};

const ItemColor = styled.span<ItemColorProps>`
  color: ${(props) => props.color}
`;

export const BoxStatsContainer = ({ boxes }: BoxStatsContainerProps) => {
  const orderedBoxes = boxes.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <ExplanationContainer>
        <Item>
          <FormattedMessage id="boxesStats.instructions" defaultMessage="Click a box title to expand / collapse" />
        </Item>
        <Item>
          <ItemColor color="#ffe124"><FormattedMessage id="boxesStats.yellow" defaultMessage="Yellow" /></ItemColor>
          <FormattedMessage id="boxesStats.placed_since" defaultMessage="= placed since {since} min" values={{ since: '15' }} />
        </Item>
        <Item>
          <ItemColor color="#ff9318"><FormattedMessage id="boxesStats.orange" defaultMessage="Orange" /></ItemColor>
          <FormattedMessage id="boxesStats.placed_since" defaultMessage="= placed since {since} min" values={{ since: '20' }} />
        </Item>
        <Item>
          <ItemColor color="#ff4040"><FormattedMessage id="boxesStats.red" defaultMessage="Red" /></ItemColor>
          <FormattedMessage id="boxesStats.placed_since" defaultMessage="= placed since {since} min" values={{ since: '30' }} />
        </Item>
      </ExplanationContainer>

      {orderedBoxes.map((box) => (<BoxStatsDetails box={box} key={box.id} />))}

    </>
  );
};
