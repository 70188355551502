import { HttpGoogleSetup } from './HttpGoogleSetup';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { useQuery } from '@tanstack/react-query';
import { GoogleSetupStatus } from '../domain/GoogleSetupStatus';

const googleSetupStatusQuery = (googleSetupRepository: HttpGoogleSetup, businessId: BusinessId) => ({
  queryKey: queryKeys.reviewsSetup.googleStatus(businessId),
  queryFn: (): Promise<GoogleSetupStatus> => {
    return googleSetupRepository.getSetupStatus(businessId);
  },
  staleTime: 60 * 1000,
});

export const useGoogleSetupStage = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const googleSetupRepository = new HttpGoogleSetup(configuration.venueFeedbackBaseUrl);
  return useQuery(googleSetupStatusQuery(googleSetupRepository, businessId));
};
