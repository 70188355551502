import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { PlatformLogo } from './PlatformLogo';
import { Review } from '../../../domain/Review';
import { DimensionRatingCards } from './DimensionRatingCards';
import { ReviewStarRating } from './ReviewStarRating';
import { ReviewFeedback } from './ReviewFeedback';
import { ReviewOrderInfo } from './ReviewOrderInfo';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { BusinessDate } from 'src/components/date/BusinessDate';

interface Props {
  review: Review;
  highlightedDimension?: string;
}

export const ReviewCard = ({ review, highlightedDimension }: Props) => {
  const CreationDate = () => {
    if (isMobileScreen()) {
      return <></>;
    }

    return (
        <Typography variant="caption" color="textSecondary">
          <BusinessDate timestampMillis={review.creationDate} />
        </Typography>
    );
  };

  return (
    <Grid
      container
      direction="column"
      gap={1}
      spacing={1}
    >
      <Grid item>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack id='foobar' direction="row" gap="0.5rem" alignItems="center" width={'fit-content'}>
            <ReviewStarRating rating={review.rating} />
            <PlatformLogo review={review} />
          </Stack>
          <CreationDate />
        </Stack>
      </Grid>
      {review.dimensionRatings.length > 0 && (
        <Grid
          item
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        >
          <DimensionRatingCards
            dimensionRatings={review.dimensionRatings}
            highlightedDimension={highlightedDimension}
          />
        </Grid>
      )}
      <Grid item sx={{ display: { xs: 'none', sm: 'flex' } }}>
        <ReviewOrderInfo review={review} />
      </Grid>
      <Grid item>
        <ReviewFeedback review={review} />
      </Grid>
    </Grid>
  );
};
