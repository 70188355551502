import React from 'react';
import styled from 'styled-components';
import { GoToSvg, IconWrapper } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { usePushNotification } from '../Notifications.hook';
import { OrderingQrCode } from './OrderingQrCode';
import IconButton from '../IconButton';
import { CopySvg } from '../Icons/CopyIcon';

type QrCodeInfoProps = {
  url: string;
  title: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${getSpacing(2)};
  width: 100%;
`;

const QRLinks = styled.div`
  padding-left: ${getSpacing(3)};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
  margin-bottom: ${getSpacing(1)};
`;

const UrlRedirectionPanel = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colorPalette.grey200};
  padding: ${getSpacing(2)};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(3)};
  color: ${colorPalette.defaultTextColor};
  width: 100%;
  cursor: pointer;
  text-decoration: none;
`;

const UrlRedirectionText = styled.div`
  font-size: 18px;
  line-height: 20px;
  padding-right: ${getSpacing(2)};
`;

const CopyLink = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: fit-content;
`;

const CopyLinkText = styled.div`
  font-size: 16px;
  line-height: 20px;
  padding-left: ${getSpacing(1)};
  color: ${colorPalette.grey600};
`;

export const QrCodeInfo = ({ url, title }: QrCodeInfoProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();

  const canUseClipboard = !!navigator.clipboard;

  const onPaste = async () => {
    await navigator.clipboard.writeText(url);

    pushNotification(
      intl.formatMessage({
        id: 'settings.qr-code.details.url-copied',
        defaultMessage: 'url copied in the clipboard',
        description: 'message when the QRCode link is copied to the clipboard',
      }),
    );
  };

  return (
    <Container>
      <OrderingQrCode url={url} />
      <QRLinks>
        <Title>{title}</Title>
        <UrlRedirectionPanel href={url} target="_blank">
          <UrlRedirectionText>{url}</UrlRedirectionText>
          <IconWrapper size="small" color={colorPalette.grey500}>
            <GoToSvg />
          </IconWrapper>
        </UrlRedirectionPanel>
        {canUseClipboard && (
          <CopyLink onClick={onPaste}>
            <IconButton Icon={CopySvg} onClick={onPaste} />
            <CopyLinkText>
              <FormattedMessage id="settings.qr-code.copy-link" defaultMessage="copy link" />
            </CopyLinkText>
          </CopyLink>
        )}
      </QRLinks>
    </Container>
  );
};
