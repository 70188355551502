import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  Button,
  ClickableTooltip,
  colorUsage,
  Dropdown,
  IconWrapper,
  InfoSvg,
  InputText,
  Modal,
  spaceUsages,
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  Text,
} from '@sundayapp/b2b-react-component-library';
import { useForm } from 'react-hook-form';
import { UpdateUserRoleForm } from './UpdateUserRoleModal.styles';
import { RoleLabelContainer } from './GrantRoleModal.styles';
import { goToFaqTooltip } from '../../GoToFaqTooltip';
import { UserRole, userRoles } from 'src/domain/user/UserRole';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { chooseUserIdentifier } from '../stringUtils';
import { Alert } from '@mui/material';
import { userRoleLocalisations } from 'src/pages/Settings/UserPermissions/i18n/UserRoleLocalisation';

type Option = {
  id: UserRole;
  label: string;
};

interface Props {
  onUpdateUserRole: (userId: string, role: UserRole) => Promise<void>;
  userToUpdate: BusinessUser;
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
}

type UpdateUserRoleInputs = {};

export const UpdateUserRoleModal: React.FC<Props> = ({
  onUpdateUserRole,
  handleClose,
  handleConfirm,
  isOpen,
  userToUpdate,
}: Props) => {
  const intl = useIntl();
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const options: Option[] = Object.entries(userRoles)
    .filter(([key]) => key !== userToUpdate.role)
    .map(([, value]) => ({
      id: value,
      label: intl.formatMessage({ id: userRoleLocalisations[value] }),
    }));
  const [selectedUserRole, setSelectedUserRole] = useState<Option | undefined>(undefined);

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<UpdateUserRoleInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit = async () => {
    setHasSubmitError(false);
    try {
      await onUpdateUserRole(userToUpdate.userId, selectedUserRole!.id);
      handleConfirm();
    } catch (e) {
      setHasSubmitError(true);
    }
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'settings.user_permissions.update_modal.title' })}
      isOpened={isOpen}
      onClose={handleClose}
      cancelButtonMessage={intl.formatMessage({ id: 'settings.user_permissions.update_modal.cancel' })}
    >
      <UpdateUserRoleForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          id="email"
          label={intl.formatMessage({ id: 'settings.user_permissions.email', defaultMessage: 'Email' })}
          value={chooseUserIdentifier(userToUpdate)}
          disabled="true"
          marginBottom={spaceUsages.mediumLarge}
          marginTop={spaceUsages.mediumLarge}
        />
        <RoleLabelContainer>
          <Text size="small" color={colorUsage.tertiaryText}>
            <FormattedMessage id="settings.user_permissions.update_modal.user_permission" />
          </Text>
          <ClickableTooltip
            direction="top"
            title={intl.formatMessage({ id: 'settings.user_permissions.update_modal.user_permission' })}
            subtitle={goToFaqTooltip()}
          >
            <IconWrapper
              color={colorUsage.tertiaryBackground}
              size="small"
              marginLeft={spaceUsages.mediumSmall}
              cursor="pointer"
            >
              <InfoSvg />
            </IconWrapper>
          </ClickableTooltip>
        </RoleLabelContainer>
        <Dropdown
          size="small"
          options={options}
          value={selectedUserRole}
          labelField="label"
          onChange={setSelectedUserRole}
          placeholder={intl.formatMessage({ id: 'settings.user_permissions.form.role.placeholder' })}
        />
        {hasSubmitError && (
          <Alert severity="error" >
            <FormattedMessage id="error.generic_error" />
          </Alert>
        )}
        <Button
          type="submit"
          variant="primary"
          size="medium"
          disabled={!isValid || !selectedUserRole || isSubmitting}
          fullWidth
        >
          <FormattedMessage id="settings.user_permissions.update_modal.confirm" />
        </Button>
      </UpdateUserRoleForm>
    </Modal>
  );
};
