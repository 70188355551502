import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
  thick?: number;
}

export const LineStroke: React.FC<Props> = ({ size = 16, thick = 2, color = 'black', ...props }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={thick} viewBox="0 0 16 2" fill="none" {...props}>
        <path d="M0 1H16" stroke={color} strokeDasharray="6 4" />
    </svg>
);