import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpGuestExperienceRepository } from './HttpGuestExperienceRepository';
import { queryKeys } from 'src/app/queries/utils';
import { DistributionByRating } from '../domain/DistributionByRating';
import { DateRange } from '../../domain/DateRange';

export const distributionByRatingInTimeRangeQuery = (venueId: string, startingAt: Date, endingAt: Date) => ({
  queryKey: [queryKeys.REVIEWS_DISTRIBUTION_BY_RATING_IN_TIMERANGE, {
    venueId,
    startingAt,
    endingAt,
  }],
  queryFn: (): Promise<DistributionByRating> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpGuestExperienceRepository(configuration.venueFeedbackBaseUrl);
    return repository.distributionByRatingInTimeRange(venueId, DateRange.fromDates(startingAt, endingAt));
  },
});

export const useDistributionByRatingInTimeRange = (venueId: string, startingAt: Date, endingAt: Date) => useQuery(distributionByRatingInTimeRangeQuery(venueId, startingAt, endingAt));
