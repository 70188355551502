import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { debounce } from 'lodash';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import { SettingExpandablePanel } from 'src/ordering/common/components/Settings/SettingExpandablePanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { useUpdateBoxDetails } from '../../../../hooks';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import DebouncedInputText from '../../../../../common/components/DebouncedInputText';
import { UpdatePacingInPreparationSlots } from './UpdatePacingInPreparationSlots';
import { Switch } from '@mui/material';

type PacingBoxSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

type PacingSettingValues = {
  pacingEnabled?: boolean;
  pacingInPreparationSlots?: number;
  pacingStalledDelayMinutes?: number;
  sendReminderNotification?: boolean;
};

export const PacingBoxSetting = ({ box, setBox }: PacingBoxSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [expanded, setExpansion] = useState<boolean>(false);
  const [pacingInPreparationSlots, setPacingInPreparationSlots] = useState<number | undefined>(0);

  const currentSettings: PacingSettingValues = useMemo(
    () => ({
      pacingEnabled: box.pacingEnabled,
      pacingInPreparationSlots: box.pacingInPreparationSlots ?? 0,
    }),
    [box.pacingEnabled, box.pacingInPreparationSlots],
  );

  const pacingStalledEnabled: boolean = useMemo(
    () => !!(box.pacingStalledDelayMinutes && box.pacingStalledDelayMinutes > 0),
    [box.pacingStalledDelayMinutes],
  );

  const boxUpdated = useCallback(
    async (values: PacingSettingValues) => {
      makeUpdating(true);
      await updateBoxDetails({
        ...values,
      });
      setBox({
        ...box,
        ...values,
      });
      pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
      makeUpdating(false);
    },
    [box, intl, pushNotification, setBox, updateBoxDetails],
  );

  const pacingToggled = async (value: boolean) => {
    await boxUpdated({ ...currentSettings, pacingEnabled: value });

    if (value) {
      setExpansion(true);
    }
  };

  const debouncedPacingUpdater = useMemo(() => {
    const updatePacingInPreparationSlots = async (value: string) => {
      await boxUpdated({ ...currentSettings, pacingInPreparationSlots: !value ? undefined : parseInt(value, 10) });
    };
    return debounce((val) => updatePacingInPreparationSlots(val), 1000);
  }, [boxUpdated, currentSettings]);

  const updatePacingStalledDelayMinutes = async (value: number) => {
    boxUpdated({ pacingStalledDelayMinutes: value });
  };

  const togglePacingStalledEnabled = async (value: boolean) => {
    boxUpdated({ pacingStalledDelayMinutes: value ? 10 : -1 });
  };

  const toggleSendReminderNotification = async (value: boolean) => {
    boxUpdated({ sendReminderNotification: value });
  };

  useEffect(() => {
    setPacingInPreparationSlots(box.pacingInPreparationSlots ?? 0);
  }, [box.pacingInPreparationSlots]);

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'box.pacingEnabled',
        defaultMessage: 'enable order pacing',
      })}
      description={intl.formatMessage({
        id: 'box.pacingEnabledDescription',
        defaultMessage: 'Pacing limits the amount of orders send to POS to avoid overwhelming the kitchen.',
        description: 'Pacing settings description',
      })}
      toggleValue={box.pacingEnabled}
      toggleOnChange={pacingToggled}
      disabled={isUpdating}
    >
      <SettingExpandablePanel visible={box.pacingEnabled} expanded={expanded} expandChange={setExpansion}>
        <SettingLine
          title={intl.formatMessage({
            id: 'box.pacingInPreparationSlots',
            defaultMessage: 'number of slots for in preparation orders',
          })}
          description={intl.formatMessage({
            id: 'box.pacingInPreparationSlotsInfoMessage',
            defaultMessage:
              'when pacing is enabled, indicates the number of orders that can be in preparation at the same time',
          })}
        >
          <UpdatePacingInPreparationSlots
            pacingSlots={pacingInPreparationSlots !== undefined ? pacingInPreparationSlots : 0}
            setPacingSlots={(value: string) => {
              debouncedPacingUpdater(value);
              setPacingInPreparationSlots(!value ? undefined : parseInt(value, 10));
            }}
          />
        </SettingLine>
        <SettingPanel
          title={intl.formatMessage({
            id: 'box.pacingStalledEnabled',
            defaultMessage: 'enable removal of stalled order',
          })}
          description={intl.formatMessage({
            id: 'box.pacingStalledEnabledDescription',
            defaultMessage:
              'Order stalled in preparation for a certain amount of time can be automatically removed from the preparation queue to free up slots, and a reminder is sent to the customer.',
          })}
          toggleValue={pacingStalledEnabled}
          toggleOnChange={togglePacingStalledEnabled}
          disabled={isUpdating}
        >
          {pacingStalledEnabled && (
            <>
              <SettingLine
                title={intl.formatMessage({
                  id: 'box.pacingSendReminderNotification',
                  defaultMessage: 'Send the reminder notification',
                })}
                description={intl.formatMessage({
                  id: 'box.pacingSendReminderNotificationDescription',
                  defaultMessage:
                    'Should a reminder be sent to the customer after the stalled delay',
                })}
              >
                <Switch
                  checked={box.sendReminderNotification}
                  onChange={(_, v) => toggleSendReminderNotification(v)}
                  disabled={isUpdating}
                />
              </SettingLine>
              <SettingLine
                title={intl.formatMessage({
                  id: 'box.pacingStalledDelayMinutes',
                  defaultMessage: 'Time before considering a preparing order as stalled',
                })}
                description={intl.formatMessage({
                  id: 'box.pacingStalledDelayMinutesInfoMessage',
                  defaultMessage:
                    'when pacing is enabled, indicates the time before an order in preparation should be automatically removed from pacing and the user should receive a reminder to take his order',
                })}
              >
                <div>
                  <DebouncedInputText
                    type="number"
                    value={box.pacingStalledDelayMinutes ?? 0}
                    commitChange={(value: string) => {
                      updatePacingStalledDelayMinutes(!value ? -1 : parseInt(value, 10));
                    }}
                  />
                </div>
              </SettingLine>
            </>
          )}
        </SettingPanel>
      </SettingExpandablePanel>
    </SettingPanel>
  );
};
