/* eslint-disable max-len */
import React, { ReactNode } from 'react';
import { ServiceType } from 'src/domain/venue/ServiceType';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import {
  boxesStatPath,
  businessAccountingClawbacksPath,
  businessStaticMenusPath,
  communicationPreferencesPath,
  onboardingPath,
  opsToolingEnterpriseSettingsPath,
  opsToolingLoyaltyOnboardingSettingsPath,
  opsToolingPath,
  opsToolingPatSettingsPath,
  opsToolingPosPaymentsSettingsPath,
  opsToolingTipsSettingsPath,
  settingsAppSettingsPath,
  settingsCustomizationPath,
  settingsMenusPath,
  settingsOrderingAppPath,
  settingsOrderingPath,
  settingsPermissionsPath,
  settingsReviewsPath,
  sundayForRestaurantsPath,
  tipsCollectionPath,
  tipsDistributionPath,
  tipsStaffRulesPath,
  venueAccountingInvoicesPath,
  venueAccountingPayoutsPath,
  venueAccountingReportingPath,
  venueAccountingRevenuesPath,
  venueBillsPath,
  venueDataAnalyticsBusinessPerformancePath,
  venueDataAnalyticsCustomerInsightsPath,
  venueDataAnalyticsEnterpriseDashboardPath,
  venueDataAnalyticsOverviewPath,
  venueDataAnalyticsRepeatKpisPath,
  venueDataAnalyticsReviewsPath,
  venueDataAnalyticsTipsPath,
  venueDynamicMenusPath,
  venueEndOfServicePath,
  venueFoodCourtPath,
  venueHomePath,
  venueKdsPath,
  venueOPBillsPath,
  venueOPProductAvailabilityPath,
  venueOPVoucherCodesPath,
  venuePaymentLinksPath,
  venuePaymentsPath,
  venueProductsPath,
  venueQRCodesPath,
  venueReconciliationOpenTabPath,
  venueSettingsAreasPath,
  venueSettingsInformationPath,
  venueSettingsPaymentsPath,
  venueSettingsReconciliationPath,
  venueSettingsRevenueCentersPath,
  venueSettingsShiftPath,
  venueStaffPerformancePath,
  venueSundayPlusCustomerDataPath,
  venueSundayPlusGuestExperiencePath,
  venueSundayPlusInsightsPath,
  venueSundayPlusReplyToReviewsPath,
  venueSundayPlusReviewsPath,
  venueTpePath,
} from '../pathHelpers';
import { UserRole, userRoles } from 'src/domain/user/UserRole';
import HomeSvg from '../../component/icons/HomeSvg';
import OperationsSvg from '../../component/icons/OperationsSvg';
import PerformanceSvg from '../../component/icons/PerformanceSvg';
import ReviewsSvg from '../../component/icons/ReviewsSvg';
import LoyaltySvg from '../../component/icons/LoyaltySvg';
import AccountingSvg from '../../component/icons/AccountingSvg';
import SettingsSvg from '../../component/icons/SettingsSvg';
import HomePlainSvg from '../../component/icons/HomePlainSvg';
import OperationsPlainSvg from '../../component/icons/OperationsPlainSvg';
import PerformancePlainSvg from '../../component/icons/PerformancePlainSvg';
import ReviewsPlainSvg from '../../component/icons/ReviewsPlainSvg';
import LoyaltyPlainSvg from '../../component/icons/LoyaltyPlainSvg';
import AccountingPlainSvg from '../../component/icons/AccountingPlainSvg';
import SettingsPlainSvg from '../../component/icons/SettingsPlainSvg';
import { PaletteTheme } from '../../theme/PaletteTheme';
import MenusSvg from '../../component/icons/MenusSvg';
import MenusPlainSvg from '../../component/icons/MenusPlainSvg';
import PhoneSvg from '../../component/icons/PhoneSvg';
import PhonePlainSvg from '../../component/icons/PhonePlainSvg';
import { InfoAccounting } from 'src/accounting/InfoAccounting';
import TipsSelectedSvg from '../../component/icons/TipsSelectedSvg';
import TipsSvg from '../../component/icons/TipsSvg';
import DataAnalyticsSvg from '../../component/icons/DataAnalyticsSvg';
import DataAnalyticsPlainSvg from '../../component/icons/DataAnalyticsPlainSvg';
import PaymentLinkSvg from '../../component/icons/PaymentLinkSvg';
import { BusinessId } from 'src/business/domain/Business';
import PaymentTerminalSvg from '../../component/icons/PaymentTerminal';
import PaymentTerminalPlainSvg from '../../component/icons/PaymentTerminalPlain';
import { OpsToolingSvg } from '../../component/icons/OpsToolingSvg';
import { CountryCode } from 'src/domain/CountryCode';
import { themeV5 } from '../../theme/ThemeV5';
import { LocalisationKey } from 'src/lang/en';
import FlagSvg from 'src/app/component/icons/FlagSvg';

export type NavigationItem = {
  icon?: ReactNode;
  selectedIcon?: ReactNode;
  banner?: ReactNode;
  text: LocalisationKey;
  route?: string;
  badge?: LocalisationKey;
  availableTo: {
    products: ServiceType[];
    stillShowWhenChurned?: boolean;
    hideForShadowEnrollment?: boolean;
  };
  onlyForOps?: boolean;
  availableIn?: (keyof typeof CountryCode)[];
  featureFlag?: string;
  authorizedRoles?: UserRole[];
  popperText?: string;
  subItems: NavigationItem[];
  subLevelItems?: NavigationItem[];
  availableForNoPos: boolean;
  hiddenInDrawer?: boolean;
  tooltip?: LocalisationKey;
  hideTitle?: boolean;
  hideForPos?: string[];
  onlyOnSpecificBusinessIds?: BusinessId[];
};

export type NavigationItems = {
  items: NavigationItem[];
  findTitle: (currentRoute: string) => string | undefined;
};

export const emptyDrawer = {
  items: [],
  findTitle: () => undefined,
};

export const navigationDrawerItemKey = (menuItem: NavigationItem): string =>
  `${menuItem.route ? menuItem.route : ''}_${menuItem.text}`;

const findRouteMatches = (items: NavigationItem[], currentRoutePath: string[], rank: number): NavigationItem[] => {
  if (currentRoutePath.length <= rank) {
    return [];
  }

  return items
    .flatMap((i) => i)
    .filter((item) => item.route)
    .filter((item) => {
      const routePath = item.route!.split('/');
      if (routePath.length <= rank) {
        return false;
      }
      return routePath[rank].localeCompare(currentRoutePath[rank]) === 0;
    });
};

const flattenDrawerItem = (item: NavigationItem): NavigationItem[] => {
  let result = [];

  result.push(item);
  if (item.subItems) {
    result = [...result, ...item.subItems.flatMap((subItem) => flattenDrawerItem(subItem))];
  }
  if (item.subLevelItems) {
    result = [...result, ...item.subLevelItems.flatMap((subItem) => flattenDrawerItem(subItem))];
  }

  return result;
};

const flattenDrawerItems = (items: NavigationItem[]): NavigationItem[] =>
  items.flatMap((item) => flattenDrawerItem(item));

const findRouteMatch = (items: NavigationItem[], currentRoute: string): NavigationItem | undefined => {
  const currentRoutePath = currentRoute.split('/');

  let currentMatches = flattenDrawerItems(items);
  for (let rank = 0, sz = currentRoutePath.length; rank < sz; rank += 1) {
    const routes = findRouteMatches(currentMatches, currentRoutePath, rank);
    if (routes.length === 0) {
      return undefined;
    }
    if (routes.length === 1) {
      return routes[0];
    }
    currentMatches = routes;
  }

  for (const item of currentMatches) {
    // test exact match
    if (item.route === currentRoute) {
      return item;
    }
  }
  return undefined;
};

export const findItem = (drawerItems: NavigationItem[], currentRoute: string): NavigationItem | undefined =>
  findRouteMatch(drawerItems, currentRoute);

export const findMenuItemByText = (drawerItems: NavigationItem[], text: string): NavigationItem | undefined =>
  drawerItems.find((value) => value.text === text);

const findParent = (
  items: NavigationItem[],
  search: NavigationItem,
  parent?: NavigationItem,
): NavigationItem | undefined => {
  for (const item of items) {
    if (item.route === search.route) {
      return parent;
    }
    const foundSubItem = findParent(item.subItems ?? [], search, item);
    if (foundSubItem) {
      return foundSubItem;
    }
    const foundSubLevelItem = findParent(item.subLevelItems ?? [], search, item);
    if (foundSubLevelItem) {
      return foundSubLevelItem;
    }
  }
};

export const findParentMenuItem = (drawerItems: NavigationItem[], search: NavigationItem): NavigationItem | undefined =>
  findParent(drawerItems, search);

export const getMenuItemIcon = (menuItem: NavigationItem, isSelected: boolean) => {
  if (isSelected && menuItem.selectedIcon) {
    return menuItem.selectedIcon;
  }

  if (!isSelected && menuItem.icon) {
    return menuItem.icon;
  }

  return undefined;
};

export const findBottomItems = (drawerItems: NavigationItem[]): NavigationItem[] => {
  const settings = findMenuItemByText(drawerItems, 'menu.settings');

  if (settings) {
    return [settings];
  }
  return [];
};

const settingsItems = (businessId: BusinessId): NavigationItem => ({
  icon: <SettingsSvg color={PaletteTheme.menu.disabled} />,
  selectedIcon: <SettingsPlainSvg />,
  text: 'menu.settings',
  authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
  availableTo: {
    products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.SMB_PAY, ServiceType.PAYMENT_TERMINAL],
    stillShowWhenChurned: true,
  },
  availableForNoPos: true,
  subItems: [],
  subLevelItems: [
    {
      text: 'menu.settings.general',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: {
        products: [
          ServiceType.PAY_AT_TABLE,
          ServiceType.ORDER_AND_PAY,
          ServiceType.SMB_PAY,
          ServiceType.PAYMENT_TERMINAL,
        ],
      },
      availableForNoPos: true,
      subItems: [
        {
          text: 'menu.settings.general.general_information',
          hideTitle: true,
          route: venueSettingsInformationPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.general.shifts',
          hideTitle: true,
          route: venueSettingsShiftPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.general.payments_information',
          hideTitle: true,
          route: venueSettingsPaymentsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
          },
          availableForNoPos: false,
          subItems: [],
        },
        // {
        //   text: 'menu.settings.general.pos_payments',
        //   hideTitle: true,
        //   route: venueSettingsPosPaymentsPath(businessId),
        //   authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
        //  availableTo: { products: [
        //     ServiceType.PAY_AT_TABLE,
        //     ServiceType.ORDER_AND_PAY,
        //     ServiceType.SMB_PAY,
        //     ServiceType.PAYMENT_TERMINAL,
        //   ] },
        //   availableForNoPos: false,
        //   subItems: [],
        // },
        {
          text: 'menu.settings.general.reconciliation',
          hideTitle: true,
          route: venueSettingsReconciliationPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.general.reviews',
          hideTitle: true,
          route: settingsReviewsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.general.menus',
          hideTitle: true,
          route: settingsMenusPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'settings.user_permissions',
          hideTitle: true,
          route: settingsPermissionsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      text: 'menu.settings.pay_at_table',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE] },
      availableForNoPos: true,
      subItems: [
        {
          text: 'settings.app_settings',
          hideTitle: true,
          route: settingsAppSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'settings.customization',
          hideTitle: true,
          route: settingsCustomizationPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.pat.mobile_app',
          hideTitle: true,
          route: sundayForRestaurantsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
          hiddenInDrawer: true,
        },
        {
          text: 'menu.settings.pat.tips',
          hideTitle: true,
          route: tipsStaffRulesPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
          hiddenInDrawer: true,
        },
        {
          text: 'menu.settings.pat.wifi',
          hideTitle: true,
          route: undefined, //settingsAppSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hiddenInDrawer: true,
          subItems: [],
        },
      ],
    },
    {
      text: 'menu.settings.order_and_pay',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.ORDER_AND_PAY] },
      availableForNoPos: true,
      subItems: [
        {
          text: 'settings.app_settings_oap',
          hideTitle: true,
          route: settingsOrderingAppPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.ORDER_AND_PAY], hideForShadowEnrollment: true },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'settings.orderings',
          hideTitle: true,
          route: settingsOrderingPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.ORDER_AND_PAY] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.oap.areas',
          hideTitle: true,
          route: venueSettingsAreasPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.ORDER_AND_PAY], hideForShadowEnrollment: true },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      text: 'menu.settings.payment_terminal',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAYMENT_TERMINAL] },
      availableForNoPos: true,
      hiddenInDrawer: true,
      subItems: [
        {
          text: 'menu.settings.pdq.devices',
          hideTitle: false,
          route: venueTpePath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAYMENT_TERMINAL] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.pdq.configuration',
          hideTitle: false,
          route: venueTpePath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: { products: [ServiceType.PAYMENT_TERMINAL] },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
  ],
});
const navigationItems = (businessId: BusinessId): NavigationItems => ({
  items: [
    {
      icon: <HomeSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <HomePlainSvg />,
      text: 'menu.home',
      hideTitle: true,
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      route: venueHomePath(businessId),
      availableTo: {
        products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL, ServiceType.ORDER_AND_PAY],
        stillShowWhenChurned: true,
      },
      availableForNoPos: false,
      subItems: [],
      tooltip: 'menu.tooltip.home',
    },
    {
      icon: <PhoneSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <PhonePlainSvg />,
      text: 'menu.sunday_for_restaurants',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      route: sundayForRestaurantsPath(businessId),
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL] },
      availableForNoPos: false,
      subItems: [],
      tooltip: 'menu.tooltip.app_sunday',
    },
    {
      text: 'menu.communication_preferences',
      route: communicationPreferencesPath(businessId),
      availableTo: { products: [ServiceType.PAY_AT_TABLE] },
      availableForNoPos: false,
      subItems: [],
      hiddenInDrawer: true,
    },
    {
      icon: <OperationsSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <OperationsPlainSvg />,
      text: 'menu.operations',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: {
        products: [
          ServiceType.PAY_AT_TABLE,
          ServiceType.ORDER_AND_PAY,
          ServiceType.SMB_PAY,
          ServiceType.PAYMENT_TERMINAL,
        ],
      },
      availableForNoPos: true,
      tooltip: 'menu.tooltip.operations',
      subItems: [
        {
          text: 'menu.foodcourt',
          route: venueFoodCourtPath(businessId),
          availableTo: { products: [ServiceType.ORDER_AND_PAY] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.orders',
          route: venueOPBillsPath(businessId),
          availableTo: { products: [ServiceType.ORDER_AND_PAY] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.boxesStats',
          route: boxesStatPath(businessId),
          availableTo: { products: [ServiceType.ORDER_AND_PAY] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.bills',
          route: venueBillsPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.payments',
          route: venuePaymentsPath(businessId),
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.op_product_availability',
          route: venueOPProductAvailabilityPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: { products: [ServiceType.ORDER_AND_PAY], hideForShadowEnrollment: true },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.qr_codes',
          route: venueQRCodesPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: true,
          onlyForOps: true,
          subItems: [],
        },
        {
          text: 'menu.sales.summary',
          route: venueEndOfServicePath(businessId),
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.reconciliation',
          route: venueReconciliationOpenTabPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.kds',
          route: venueKdsPath(businessId),
          availableTo: { products: [ServiceType.ORDER_AND_PAY] },
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER, userRoles.WAITER],
          availableForNoPos: false,
          featureFlag: 'kds-enabled',
          subItems: [],
        },
      ],
    },
    {
      icon: <PaymentTerminalSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <PaymentTerminalPlainSvg />,
      authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER, userRoles.WAITER],
      text: 'payment.terminal',
      tooltip: 'menu.tooltip.payment.terminal',
      route: venueTpePath(businessId),
      availableIn: ['FR', 'US', 'GB', 'DE', 'MC'],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL] },
      availableForNoPos: false,
      subItems: [],
    },
    {
      icon: <PerformanceSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <PerformancePlainSvg />,
      text: 'menu.staff_performance',
      tooltip: 'menu.tooltip.performance',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      route: venueStaffPerformancePath(businessId),
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL] },
      availableForNoPos: false,
      subItems: [],
    },
    {
      icon: <DataAnalyticsSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <DataAnalyticsPlainSvg />,
      text: 'menu.analytics',
      tooltip: 'menu.tooltip.data-analytics',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE] },
      availableForNoPos: false,
      subItems: [
        {
          text: 'menu.analytics.overview',
          route: venueDataAnalyticsOverviewPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [],
        },
        {
          text: 'menu.analytics.business_performance',
          route: venueDataAnalyticsBusinessPerformancePath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [],
        },
        {
          text: 'menu.analytics.customer_insights',
          route: venueDataAnalyticsCustomerInsightsPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.analytics.tips',
          route: venueDataAnalyticsTipsPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          availableIn: ['US'],
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [
            {
              text: 'menu.analytics.tips',
              route: tipsStaffRulesPath(businessId),
              availableTo: { products: [ServiceType.PAY_AT_TABLE] },
              availableForNoPos: false,
              subItems: [],
              hiddenInDrawer: true,
            },
          ],
        },
        {
          text: 'menu.analytics.repeat_kpis',
          route: venueDataAnalyticsRepeatKpisPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          featureFlag: 'kyc-repeat-kpis',
          subItems: [],
        },
        {
          text: 'menu.analytics.business_comparison',
          route: venueDataAnalyticsEnterpriseDashboardPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [],
        },
        {
          text: 'menu.analytics.reviews',
          route: venueDataAnalyticsReviewsPath(businessId),
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      icon: <ReviewsSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <ReviewsPlainSvg />,
      text: 'menu.reviews',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL] },
      availableForNoPos: true,
      tooltip: 'menu.tooltip.reviews',
      subItems: [
        {
          text: 'menu.reviews.insights',
          route: venueSundayPlusInsightsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.reviews.guest_experience',
          route: venueSundayPlusGuestExperiencePath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.reviews.reviews',
          route: venueSundayPlusReviewsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.reviews.reply',
          route: venueSundayPlusReplyToReviewsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL],
          },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      icon: <LoyaltySvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <LoyaltyPlainSvg />,
      text: 'menu.loyalty',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
      availableForNoPos: true,
      tooltip: 'menu.tooltip.loyalty',
      subItems: [
        {
          text: 'menu.op_voucher_codes',
          route: venueOPVoucherCodesPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: { products: [ServiceType.ORDER_AND_PAY] },
          availableForNoPos: true,
          subItems: [],
        },
      ],
    },
    {
      text: 'menu.customer_data',
      icon: <ContactsOutlinedIcon htmlColor={PaletteTheme.menu.icon} />,
      selectedIcon: <ContactsOutlinedIcon htmlColor={themeV5.palette.primary.main} />,
      route: venueSundayPlusCustomerDataPath(businessId),
      authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
      availableForNoPos: false,
      subItems: [],
    },
    {
      icon: <PaymentLinkSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <PaymentLinkSvg />,
      text: 'payment_link.title',
      route: venuePaymentLinksPath(businessId),
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
      availableForNoPos: false,
      tooltip: 'menu.tooltip.payment_link',
      subItems: [],
    },
    {
      icon: <TipsSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <TipsSelectedSvg />,
      text: 'sunday_tipping.title',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL, ServiceType.ORDER_AND_PAY] },
      availableForNoPos: false,
      tooltip: 'menu.tooltip.direct_tipping',
      subItems: [
        {
          text: 'sunday_tipping.collection.title',
          route: tipsCollectionPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            stillShowWhenChurned: true,
            products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL, ServiceType.ORDER_AND_PAY],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'sunday_tipping.distribution.title',
          route: tipsDistributionPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            stillShowWhenChurned: true,
            products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL, ServiceType.ORDER_AND_PAY],
          },
          availableIn: ['FR'],
          availableForNoPos: false,
          subItems: [],
          badge: 'common.new',
        },
        {
          text: 'sunday_tipping.staff_rules.title',
          route: tipsStaffRulesPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL, ServiceType.ORDER_AND_PAY],
          },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      icon: <AccountingSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <AccountingPlainSvg />,
      text: 'accounting.title',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: {
        products: [
          ServiceType.PAY_AT_TABLE,
          ServiceType.ORDER_AND_PAY,
          ServiceType.SMB_PAY,
          ServiceType.PAYMENT_TERMINAL,
        ],
      },
      availableForNoPos: false,
      tooltip: 'menu.tooltip.accounting',
      subItems: [
        {
          text: 'accounting.revenue_tab',
          route: venueAccountingRevenuesPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'accounting.reporting_tab',
          route: venueAccountingReportingPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'accounting.payout_tab',
          route: venueAccountingPayoutsPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'accounting.invoice_tab',
          route: venueAccountingInvoicesPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'accounting.clawbacks_tab',
          route: businessAccountingClawbacksPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [ServiceType.PAYMENT_TERMINAL],
            stillShowWhenChurned: true,
          },
          availableForNoPos: true,
          subItems: [],
        },
      ],
      banner: <InfoAccounting />,
    },
    {
      icon: <MenusSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <MenusPlainSvg />,
      text: 'menu.management',
      authorizedRoles: [userRoles.ADMIN],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
      availableForNoPos: true,
      tooltip: 'menu.tooltip.menu-management',
      subItems: [
        {
          text: 'menu.dynamic-menus',
          route: venueDynamicMenusPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.menu-assets',
          route: venueProductsPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.menus',
          route: businessStaticMenusPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: true,
          subItems: [],
        },
      ],
    },
    {
      icon: <OpsToolingSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <OpsToolingSvg />,
      text: 'menu.ops_tooling',
      route: opsToolingPath(businessId),
      authorizedRoles: [],
      availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY, ServiceType.PAYMENT_TERMINAL] },
      availableForNoPos: true,
      onlyForOps: true,
      subItems: [
        {
          text: 'menu.ops_tooling.enterprise_settings',
          route: opsToolingEnterpriseSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.ops_tooling.pat_settings',
          route: opsToolingPatSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.ops_tooling.tips_settings',
          route: opsToolingTipsSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE, ServiceType.ORDER_AND_PAY] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.settings.general.pos_payments',
          route: opsToolingPosPaymentsSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.settings.general.loyalty',
          route: opsToolingLoyaltyOnboardingSettingsPath(businessId),
          authorizedRoles: [userRoles.ADMIN],
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: true,
          subItems: [],
        },
        {
          text: 'menu.settings.general.revenue_centers',
          route: venueSettingsRevenueCentersPath(businessId),
          authorizedRoles: [userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
          },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      icon: <FlagSvg color={PaletteTheme.menu.icon} />,
      hideTitle: true,
      selectedIcon: <FlagSvg />,
      text: 'menu.onboarding',
      tooltip: 'menu.tooltip.onboarding',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      route: onboardingPath(businessId),
      availableTo: {
        products: [ServiceType.PAY_AT_TABLE, ServiceType.PAYMENT_TERMINAL],
      },
      availableForNoPos: false,
      subItems: [],
      onlyOnSpecificBusinessIds: [
        '7c08ea49-f06e-4d10-ac39-b4aa1f034951' as BusinessId, // [Boite] Chez Floris on Alpha
      ],
    },
    settingsItems(businessId),
  ],
  findTitle: (currentRoute: string) => {
    const { items } = navigationItems(businessId);
    return findRouteMatch(items, currentRoute)?.text;
  },
});
export default navigationItems;
