import React from 'react';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { MenusPage } from './components/MenusPage';
import { useMenuAssetsSources } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';

export const DynamicMenusPage = () => {
  const business = useCurrentBusinessOrThrow();
  const menuAssetsSources = useMenuAssetsSources();

  return <MenusPage businessId={business.id} menuAssetsSources={menuAssetsSources} />;
};
