import { useMutation, useQueryClient } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { httpBillReceiptRepository } from 'src/infrastructure/payment/HttpBillReceiptRepository';
import { ReceiptDisplaySettings } from '../domain/ReceiptDisplaySettings';
import { QUERYKEY_RECEIPT_DISPLAY_SETTINGS } from '../queries/getReceiptDisplaySettings';


export const useReceiptDisplaySettingsMutation = (businessId: BusinessId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (settings: Partial<ReceiptDisplaySettings>) => httpBillReceiptRepository.updateReceiptDisplaySettings(businessId, settings),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_RECEIPT_DISPLAY_SETTINGS, businessId] });
    },
  });
};
