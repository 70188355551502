import type { Identifier, XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import { colorUsage, IconWrapper, spaceUsages, UpDownArrowSvg } from '@sundayapp/b2b-react-component-library';
import MenuItemLineContainer from './MenuItemLineContainer';
import MenuItemLine from './MenuItemLine';
import MenuItemName from './MenuItemName';
import MenuItemSchedule from './MenuItemSchedule';
import { StaticMenu } from '../../domain/StaticMenus';

export interface DragMenuItemProps {
  id: any;
  menu: StaticMenu;
  index: number;
  moveMenu: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const IconContainer = styled.div`
  background-color: ${colorUsage.primaryBackground};
  padding: 12px;
  border-radius: 50%;
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const DraggableMenuItemToolColumn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 88px;
  max-width: 88px;
  border-top-right-radius: ${spaceUsages.largeSmall};
  border-bottom-right-radius: ${spaceUsages.largeSmall};
  cursor: pointer;
`;

export const DraggableMenuItem = ({ id, menu, index, moveMenu }: DragMenuItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'MENU_ITEM',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      // Only perform the move when the mouse has crossed half of the item's height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveMenu(dragIndex, hoverIndex);

      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: 'MENU_ITEM',
    item: () => ({ id, index }),
  });

  drag(drop(ref));
  return (
    <MenuItemLineContainer ref={ref} data-handler-id={handlerId}>
      <MenuContent>
        <MenuItemLine>
          <MenuItemName name={menu.description} visibility={menu.visibility} />
          <MenuItemSchedule constraints={menu.constraints} visibility={menu.visibility} />
        </MenuItemLine>
        <DraggableMenuItemToolColumn>
          <IconContainer>
            <IconWrapper size="small" color={colorUsage.clearText} cursor="pointer">
              <UpDownArrowSvg />
            </IconWrapper>
          </IconContainer>
        </DraggableMenuItemToolColumn>
      </MenuContent>
    </MenuItemLineContainer>
  );
};
