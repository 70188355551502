import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpReviewRepository } from '../../browse/infrastructure/HttpReviewRepository';
import { Reviews } from '../../browse/domain/Reviews';
import { TimeRange } from '../../browse/domain/TimeRange';
import { Business } from 'src/business/domain/Business';

const listReviewsQuery = (venueId: string, timeRange: TimeRange) => ({
  queryKey: [
    queryKeys.LIST_OF_REVIEWS_TO_REPLY,
    {
      venueId,
      timeRange,
    },
  ],
  queryFn: (): Promise<Reviews> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpReviewRepository(configuration.venueFeedbackBaseUrl);
    return repository.listReviews(venueId, timeRange);
  },
});

export const useListReviews = (business: Business, timeRange: TimeRange) =>
  useQuery(listReviewsQuery(business.id, timeRange));
