import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const LegalName = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.controllingCompanies?.[legalRepresentativeIndex]?.legalName;
  const intl = useIntl();
  return <TextField
    fullWidth
    required
    error={!!error}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.legalName' })}
    InputLabelProps={{ shrink: true }}
    {...register(`controllingCompanies.${legalRepresentativeIndex}.legalName`, { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.legalName.error' }) })}
    helperText={!!error ? error.message : ''}
  />;
};
