import React from 'react';
import NumberSelector from './NumberSelector';

type TimeSelectorProps = {
  time: string;
  invalidDate: boolean;
  onUpdate: (time: string) => void;
};

const TimeSelector = ({ time, invalidDate, onUpdate }: TimeSelectorProps) => {
  const timeParts = time.split(':');
  const hours = timeParts[0];
  let minutes = timeParts[1];

  const onHourSelected = (hour: string) => {
    if (hour === '24') {
      minutes = '00';
    }

    onUpdate(`${hour}:${minutes}`);
  };

  const onMinuteSelected = (minute: string) => {
    onUpdate(`${hours}:${minute}`);
  };

  const hourValues = Array.from(Array(25).keys()).map(
    (num) => `${num.toLocaleString('en-US', { minimumIntegerDigits: 2 })}`,
  );

  const minuteValues = hours === '24'
    ? ['00']
    : Array.from(Array(12).keys()).map((num) => `${(num * 5).toLocaleString('en-US', { minimumIntegerDigits: 2 })}`);

  return (
    <>

      <NumberSelector values={hourValues} onSelection={onHourSelected} invalidDate={invalidDate} time={hours} />
      :
      <NumberSelector values={minuteValues} onSelection={onMinuteSelected} invalidDate={invalidDate} time={minutes} />
    </>
  );
};

export default TimeSelector;
