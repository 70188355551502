import { IntlShape } from 'src/app/i18n/TypedIntl';
import { isEqual } from 'lodash';
import { GroupedTimeRanges } from '../../components/SchedulePanel/GroupedTimeRanges';
import { Schedules } from '../../domain/Schedules';
import { TimeRange } from '../../domain/TimeRange';
import { ScheduleRange } from '../../domain/ScheduleRange';
import { Days } from '../../domain/Days';
import { MenuScheduleTranslationUtils } from './MenuScheduleTranslationUtils';
import { MenuSchedulesOptimizationUtils } from './MenuSchedulesOptimizationUtils';

type MatchingResult = {
  matching: GroupedTimeRanges[];
  unMatching: GroupedTimeRanges[];
};

export class MenuSchedulesUtils {
  public static groupedTimeToString(groupedConstraints: GroupedTimeRanges, translation: IntlShape): string[] {
    return MenuScheduleTranslationUtils.groupedConstraintToString(groupedConstraints, translation);
  }

  public static schedulesToGroupedTimes(schedules: Schedules): GroupedTimeRanges[] {
    const dailyRanges = MenuSchedulesUtils.groupRangesPerDays(schedules.ranges);
    const weekdays: GroupedTimeRanges[] = [];
    const weekend: GroupedTimeRanges[] = [];
    MenuSchedulesUtils.dispatchDays(dailyRanges, weekdays, weekend);

    return MenuSchedulesUtils.findMatchingDays(weekdays).concat(MenuSchedulesUtils.findMatchingDays(weekend));
  }

  public static groupedTimesToSchedules(groupedTimes: GroupedTimeRanges[]): Schedules {
    const scheduleRanges = groupedTimes.flatMap((times) => MenuSchedulesUtils.groupedTimeRangesToScheduleRanges(times));
    return new Schedules(scheduleRanges);
  }

  public static optimizeGroupedTimes(groupedTimes: GroupedTimeRanges[]): GroupedTimeRanges[] {
    const scheduleRanges = MenuSchedulesOptimizationUtils.optimizeGroupedTimes(groupedTimes);
    const schedules = {
      ranges: scheduleRanges,
    } as Schedules;
    return MenuSchedulesUtils.schedulesToGroupedTimes(schedules);
  }

  // eslint-disable-next-line max-params
  public static buildGroupedTimeRanges(
    monday: boolean,
    tuesday: boolean,
    wednesday: boolean,
    thursday: boolean,
    friday: boolean,
    saturday: boolean,
    sunday: boolean,
    ranges: TimeRange[],
  ): GroupedTimeRanges {
    return {
      monday,
      tuesday,
      wednesday,
      thursday,
      friday,
      saturday,
      sunday,
      ranges,
    };
  }

  private static hasSameTimeRangesOnAllDays(groupedTimeRanges: GroupedTimeRanges[], expectedLength: number): boolean {
    if (groupedTimeRanges.length !== expectedLength) {
      return false;
    }

    return groupedTimeRanges.every((item) => isEqual(item.ranges, groupedTimeRanges[0].ranges));
  }

  private static groupedTimeRangesToScheduleRanges(groupedTimeRanges: GroupedTimeRanges): ScheduleRange[] {
    let result: ScheduleRange[] = [];

    if (groupedTimeRanges.monday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.MONDAY, ...range } as ScheduleRange)),
      );
    }

    if (groupedTimeRanges.thursday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.THURSDAY, ...range } as ScheduleRange)),
      );
    }

    if (groupedTimeRanges.wednesday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.WEDNESDAY, ...range } as ScheduleRange)),
      );
    }

    if (groupedTimeRanges.tuesday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.TUESDAY, ...range } as ScheduleRange)),
      );
    }

    if (groupedTimeRanges.friday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.FRIDAY, ...range } as ScheduleRange)),
      );
    }

    if (groupedTimeRanges.saturday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.SATURDAY, ...range } as ScheduleRange)),
      );
    }

    if (groupedTimeRanges.sunday) {
      result = result.concat(
        groupedTimeRanges.ranges.map((range) => ({ day: Days.SUNDAY, ...range } as ScheduleRange)),
      );
    }

    return result;
  }

  private static groupRangesPerDays(ranges: ScheduleRange[]): GroupedTimeRanges[] {
    const timeRanges = new Map<Days, GroupedTimeRanges>();
    const result: GroupedTimeRanges[] = [];

    ranges.forEach((range) => {
      let groupedTimeRanges = timeRanges.get(range.day);
      if (groupedTimeRanges === undefined) {
        groupedTimeRanges = MenuSchedulesUtils.buildGroupedTimeRanges(
          range.day === Days.MONDAY,
          range.day === Days.TUESDAY,
          range.day === Days.WEDNESDAY,
          range.day === Days.THURSDAY,
          range.day === Days.FRIDAY,
          range.day === Days.SATURDAY,
          range.day === Days.SUNDAY,
          [],
        );
        timeRanges.set(range.day, groupedTimeRanges);
        result.push(groupedTimeRanges);
      }

      groupedTimeRanges.ranges.push({ startTime: range.startTime, endTime: range.endTime });
    });

    return result;
  }

  private static dispatchDays(input: GroupedTimeRanges[], weekday: GroupedTimeRanges[], weekend: GroupedTimeRanges[]) {
    const computedWeekday: GroupedTimeRanges[] = [];
    const computedWeekend: GroupedTimeRanges[] = [];
    input.forEach((inp) => {
      if (inp.saturday || inp.sunday) {
        computedWeekend.push(inp);
      } else {
        computedWeekday.push(inp);
      }
    });

    if (MenuSchedulesUtils.hasSameTimeRangesOnAllDays(computedWeekday, 5)) {
      weekday.push(
        MenuSchedulesUtils.buildGroupedTimeRanges(
          true,
          true,
          true,
          true,
          true,
          false,
          false,
          computedWeekday[0].ranges,
        ),
      );
    } else {
      computedWeekday.forEach((item) => weekday.push(item));
    }

    if (MenuSchedulesUtils.hasSameTimeRangesOnAllDays(computedWeekend, 2)) {
      weekend.push(
        MenuSchedulesUtils.buildGroupedTimeRanges(
          false,
          false,
          false,
          false,
          false,
          true,
          true,
          computedWeekend[0].ranges,
        ),
      );
    } else {
      computedWeekend.forEach((item) => weekend.push(item));
    }
  }

  private static findMatchingDays(days: GroupedTimeRanges[]): GroupedTimeRanges[] {
    if (days.length === 0) {
      return [];
    }

    const matchingResult = this.findMatchingDaysWithFirstDays(days);
    const groupedTimeRanges = MenuSchedulesUtils.buildGroupedRangeTimes(matchingResult.matching);
    if (matchingResult.unMatching.length === 0) {
      return [groupedTimeRanges];
    }
    return [groupedTimeRanges].concat(MenuSchedulesUtils.findMatchingDays(matchingResult.unMatching));
  }

  private static buildGroupedRangeTimes(days: GroupedTimeRanges[]): GroupedTimeRanges {
    const result = days[0];
    return days.reduce(
      (acc, item) => ({
        ranges: acc.ranges,
        monday: acc.monday || item.monday,
        tuesday: acc.tuesday || item.tuesday,
        wednesday: acc.wednesday || item.wednesday,
        thursday: acc.thursday || item.thursday,
        friday: acc.friday || item.friday,
        saturday: acc.saturday || item.saturday,
        sunday: acc.sunday || item.sunday,
      }),
      result,
    );
  }

  private static findMatchingDaysWithFirstDays(days: GroupedTimeRanges[]): MatchingResult {
    const matching: GroupedTimeRanges[] = [];
    const unMatching: GroupedTimeRanges[] = [];

    days.forEach((comparingDay) => {
      if (isEqual(days[0].ranges, comparingDay.ranges)) {
        matching.push(comparingDay);
      } else {
        unMatching.push(comparingDay);
      }
    });

    return {
      matching,
      unMatching,
    };
  }
}
