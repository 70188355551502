import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import { LocalisationKey } from 'src/lang/en';
import { Stack, Typography } from '@mui/material';

interface Props {
  stepNumber:number;
  title: LocalisationKey;
  details:string;
  moreDetails?:string;
}

export const StepDetails = ({
  title, stepNumber, details, moreDetails,
}:Props) => (
  <Stack gap={2} padding={2}>
      <Typography variant={'h5'}>
        {stepNumber}
        .{' '}
      <FormattedMessage id={title} />
      </Typography>
    <Stack gap={1}>
    <Typography variant={'subtitle1'}>{details}</Typography>
      {moreDetails && <Typography color={'textSecondary'} variant={'subtitle2'}>{moreDetails}</Typography>}
    </Stack>
  </Stack>
);
