import { FC, FormEvent, useState } from 'react';
import { productTypes } from 'src/menu/dynamic-menu/domain/Product';
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ProductTypeSelector } from 'src/menu/dynamic-menu/pages/CreateProductPage/components/ProductTypeSelector';
import { Check } from '@mui/icons-material';
import { ProductTypeAllowed } from 'src/menu/dynamic-menu/pages/CreateProductPage/CreateProductPage';

export const ProductTypeSelectionStep: FC<{ onSubmit: (productType: ProductTypeAllowed) => void }> = ({ onSubmit }) => {
  const [type, setType] = useState<ProductTypeAllowed>(productTypes.PRODUCT);

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(type);
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Box display="flex" flexDirection="column" gap="24px" margin="auto">
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography variant="h5">
            <FormattedMessage id={'menus.catalog.products.form.product_type.label'} />
          </Typography>
          <Typography variant="subtitle2" color={themeV5.palette.text.secondary}>
            <FormattedMessage id={'menus.catalog.products.form.product_type.label.subtitle'} />
          </Typography>
        </Box>

        <ProductTypeSelector value={type} onChange={setType} />

        <Box>
          <Button type="submit" size="large" variant="contained" disabled={false}>
            <Box display="flex" justifyContent="center" alignItems="center" gap="8px" padding="8px 22px">
              <Check sx={{ width: '24px', height: '24px' }} />
              <Typography variant="subtitle1">
                <FormattedMessage id="menus.catalog.products.create.product" />
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </form>
  );
};
