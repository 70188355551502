import { z } from 'zod';

export const StaticMenuIdSchema = z.string().brand('StaticMenuId');
export type StaticMenuId = z.infer<typeof StaticMenuIdSchema>;

const HoursSchema = z.object({ start: z.string(), end: z.string() });
export type Hours = z.infer<typeof HoursSchema>;

const DayConstraintSchema = z.object({ hours: z.array(HoursSchema).optional(), available: z.boolean() });
export type DayConstraint = z.infer<typeof DayConstraintSchema>;

const MenuConstraintsSchema = z.object({
  sunday: DayConstraintSchema,
  saturday: DayConstraintSchema,
  tuesday: DayConstraintSchema,
  wednesday: DayConstraintSchema,
  thursday: DayConstraintSchema,
  friday: DayConstraintSchema,
  monday: DayConstraintSchema,
});

export type MenuConstraints = z.infer<typeof MenuConstraintsSchema>;

const StaticMenuSchema = z.object({
  id: StaticMenuIdSchema,
  description: z.string(),
  visibility: z.boolean(),
  descriptionDetails: z.string().optional().default(''),
  fileName: z.string().optional(),
  link: z.string().optional(),
  images: z.array(z.string()).optional().default([]),
  constraints: MenuConstraintsSchema,
  lastEdition: z.coerce.date(),
});
export type StaticMenu = z.infer<typeof StaticMenuSchema>;

const StaticMenusSchema = z.object({ menus: z.array(StaticMenuSchema) });
export type StaticMenus = z.infer<typeof StaticMenusSchema>;

export const parseStaticMenus = (data: unknown) => StaticMenusSchema.parse(data);
