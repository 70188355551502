import styled, { css } from 'styled-components';
import { borderRadius, colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  hasError: boolean;
}

export const InputContainer = styled.label<Props>`
  height: ${getSpacing(5)};
  line-height: 40px;
  border: 1px solid ${colorPalette.lightBorderColor};
  border-radius: ${borderRadius.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  ${({ hasError }) =>
    hasError
    && css`
      border-color: ${colorPalette.red};
      color: ${colorPalette.red};
      background-color: ${colorPalette.lightRed};
    `}
`;

export type InputFieldProps = {
  leftAligned: boolean;
};

export const InputField = styled.input<InputFieldProps>`
  align-self: stretch;
  flex: 1;
  padding: 0 ${getSpacing(2)};
  background: none;
  border: 0;
  outline: 0;
  min-width: 0;
  color: ${colorPalette.shadow};
  text-align: ${(props) => (props.leftAligned ? 'left' : 'right')};
  &:focus {
    outline: 0;
  }
  font-size: 16px;
  line-height: 18px;
`;

export default InputField;
