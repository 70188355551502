import styled from 'styled-components';
import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { VenueDetails } from '../../../venues/types';
import { Box } from '../../../box/domain/Box';
import { getSpacing } from '../../../stylesheet';
import { useUpdateVenueDetails } from '../../../venues/hook';
import { usePushNotification } from '../../../common/components/Notifications.hook';
import { BoxPanel } from './BoxPanel';

type BoxRankingPanelProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
  boxes: Box[];
  setBoxes: (boxes: Box[]) => void;
  onBoxDeleted: (box: Box) => void;
};

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${getSpacing(2)};
`;

export const BoxesPanel = ({
  venue, setVenue, boxes, setBoxes, onBoxDeleted,
}: BoxRankingPanelProps) => {
  const intl = useIntl();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [, pushNotification] = usePushNotification();

  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateBoxPriorities = async (updatedBoxes: Box[]) => {
    const boxesPriorities = updatedBoxes.map((box) => box.id);

    makeUpdating(true);

    await updateVenueDetails({ boxesPriorities });

    setBoxes(updatedBoxes);
    setVenue({ ...venue, boxesPriorities });

    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  const moveUp = async (moveIndex: number) => {
    const prevValue = boxes[moveIndex - 1];
    const currValue = boxes[moveIndex];

    const updatedBoxes = boxes.map((b, idx) => {
      if (moveIndex === idx) {
        return prevValue;
      }
      if (moveIndex - 1 === idx) {
        return currValue;
      }
      return b;
    });

    await updateBoxPriorities(updatedBoxes);
  };

  const moveDown = async (moveIndex: number) => {
    const nextValue = boxes[moveIndex + 1];
    const currValue = boxes[moveIndex];

    const updatedBoxes = boxes.map((b, idx) => {
      if (moveIndex === idx) {
        return nextValue;
      } if (moveIndex + 1 === idx) {
        return currValue;
      }
      return b;
    });
    await updateBoxPriorities(updatedBoxes);
  };

  const updateBox = (updatedBox: Box) => {
    const updatedBoxes = boxes.map((box) => {
      if (box.id === updatedBox.id) {
        return updatedBox;
      }
      return box;
    });
    setBoxes(updatedBoxes);
  };

  return (
    <PanelContainer>
      {boxes.map((box, index) => (
        <BoxPanel
          key={box.id}
          box={box}
          setBox={updateBox}
          boxRank={index}
          boxCount={boxes.length}
          isUpdating={isUpdating}
          moveUp={moveUp}
          moveDown={moveDown}
          onDelete={onBoxDeleted}
        />
      ))}
    </PanelContainer>
  );
};
