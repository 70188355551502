import { Review } from '../../browse/domain/Review';

import { Dimension } from '../../browse/domain/Dimension';

export const aBadReviewRatingThreshold: number = 3;

export type DimensionAnalysis = {
  name: Dimension;
  averageRating: number | undefined;
  numberOfReviews: number;
  badReviews: Review[];
};

export type DimensionsAnalysis = { reviews: Review[], dimensions: DimensionAnalysis[] };

export const badReviewsForDimension = (reviews: Review[], dimension: string): Review[] => {
  if (reviews.length === 0) return [];

  return reviews.filter((review) => review.dimensionRatings.some((reviewDimension) => reviewDimension.dimension === dimension && reviewDimension.rating <= aBadReviewRatingThreshold));
};
