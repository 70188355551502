import { IntlShape } from 'src/app/i18n/TypedIntl';
import { RepresentativeDto } from 'src/payments/infrastructure/CheckoutOnboardingInformationDto';
import { LocalisationKey } from 'src/lang/en';

const validLocalizationKeys: Record<string, LocalisationKey> = {
  'businessType': 'settings.payments.checkout.onboarding.businessType',
  'businessRegistrationNumber': 'settings.payments.checkout.onboarding.businessRegistrationNumber',
  'dateOfIncorporation': 'settings.payments.checkout.onboarding.dateOfIncorporation',
  'legalName': 'settings.payments.checkout.onboarding.legalName',
  'tradingName': 'settings.payments.checkout.onboarding.tradingName',
  'bankAccount.accountNumber': 'settings.payments.checkout.onboarding.bankAccount.accountNumber',
  'bankAccount.bankCode': 'settings.payments.checkout.onboarding.bankAccount.bankCode',
  'bankAccount.bankStatement': 'settings.payments.checkout.onboarding.bank_statement.title',
  'bankAccount.iban': 'settings.payments.checkout.onboarding.bankAccount.iban',
  'bankAccount.bic': 'settings.payments.checkout.onboarding.bankAccount.bic',
  'annualProcessingVolume': 'settings.payments.checkout.onboarding.annualProcessingVolume',
  'averageTransactionValue': 'settings.payments.checkout.onboarding.averageTransactionValue',
  'highestTransactionValue': 'settings.payments.checkout.onboarding.highestTransactionValue',
  'primaryAddress.addressLine1': 'settings.payments.checkout.onboarding.address.addressLine1',
  'primaryAddress.city': 'settings.payments.checkout.onboarding.address.city',
  'primaryAddress.zip': 'settings.payments.checkout.onboarding.address.zip',
  'primaryAddress.country': 'settings.payments.checkout.onboarding.address.country',
  'registeredAddress': 'settings.payments.checkout.onboarding.registeredAddress',
  'registeredAddress.addressLine1': 'settings.payments.checkout.onboarding.address.addressLine1',
  'registeredAddress.city': 'settings.payments.checkout.onboarding.address.city',
  'registeredAddress.zip': 'settings.payments.checkout.onboarding.address.zip',
  'registeredAddress.country': 'settings.payments.checkout.onboarding.address.country',
  'contact.email': 'settings.payments.checkout.onboarding.contact.email',
  'contact.phone': 'settings.payments.checkout.onboarding.contact.phone',
  'representatives': 'settings.payments.checkout.onboarding.representatives',
  'controllingCompanies': 'settings.payments.checkout.onboarding.controllingCompanies',
  'controllingCompanies.address.country': 'settings.payments.checkout.onboarding.controllingCompanies.registrationCountry',
  'controllingCompanies.ownershipPercentage': 'settings.payments.checkout.onboarding.representatives.ownershipPercentage',
  'representatives.address': 'settings.payments.checkout.onboarding.representatives.address',
  'representatives.legalName': 'settings.payments.checkout.onboarding.controllingCompanies.legalName',
  'representatives.address.addressLine1': 'settings.payments.checkout.onboarding.address.addressLine1',
  'representatives.address.city': 'settings.payments.checkout.onboarding.address.city',
  'representatives.address.country': 'settings.payments.checkout.onboarding.address.country',
  'representatives.address.zip': 'settings.payments.checkout.onboarding.address.zip',
  'representatives.countryOfBirth': 'settings.payments.checkout.onboarding.representatives.countryOfBirth',
  'representatives.dateOfBirth': 'settings.payments.checkout.onboarding.representatives.dateOfBirth',
  'representatives.email': 'settings.payments.checkout.onboarding.representatives.email',
  'representatives.firstName': 'settings.payments.checkout.onboarding.representatives.firstName',
  'representatives.lastName': 'settings.payments.checkout.onboarding.representatives.lastName',
  'representatives.ownershipPercentage': 'settings.payments.checkout.onboarding.representatives.ownershipPercentage',
  'representatives.companyPosition': 'settings.payments.checkout.onboarding.representatives.companyPosition',
  'representatives.nationalIdNumber': 'settings.payments.checkout.onboarding.representatives.nationalIdNumber',
  'representatives.identificationFrontDocument': 'settings.payments.checkout.onboarding.representatives.identificationFrontDocument',
  'representatives.identificationBackDocument': 'settings.payments.checkout.onboarding.representatives.identificationBackDocument',
  'representatives.identificationType': 'settings.payments.checkout.onboarding.representatives.identificationType',
  'representatives.certifiedAuthorisedSignatoryDocument': 'settings.payments.checkout.onboarding.representatives.certifiedAuthorisedSignatoryDocument',
  'articlesOfAssociationDocument': 'settings.payments.checkout.onboarding.articlesOfAssociationDocument',
  'proofOfPrincipalAddressDocument': 'settings.payments.checkout.onboarding.proofOfPrincipalAddressDocument',
  'shareholderStructureDocument': 'settings.payments.checkout.onboarding.shareholderStructureDocument',
  'incorporationDocument': 'settings.payments.checkout.onboarding.incorporationDocument',

};
const getValidLocalizationKey = (field: string) => {
  return validLocalizationKeys[field];
};
const getRequirementDueFieldLabelForRepresentative = (intl: IntlShape) => (field: string, representatives: RepresentativeDto[]) => {
  const extractIndex = () => {
    const representativeIndexMatch = field.match(/\[(\d+)]/g);
    if (!!representativeIndexMatch && representativeIndexMatch?.length > 0) {
      const index = parseInt(representativeIndexMatch[0].replace('[', '').replace(']', ''));
      if (representatives.length > index) {
        return index;
      }
    }
    return undefined;
  };
  const representativeIndex = extractIndex();
  const validKey = getValidLocalizationKey(field.replace(/\[(\d+)]/g, ''));
  const fieldLabel = validKey ? intl.formatMessage({ id: validKey }) : field;
  if (representativeIndex === undefined) return fieldLabel;
  const representativeName = representatives[representativeIndex].type === 'individual' ?
    `Representative  ${representatives[representativeIndex].firstName} ${representatives[representativeIndex].lastName}` :
    `Controlling company ${representatives[representativeIndex].legalName}`;
  return `${representativeName} - ${fieldLabel.toLowerCase()}`;
};

export const getRequirementDueFieldLabel = (intl: IntlShape) => (field: string, representatives: RepresentativeDto[]) => {
  const validKey = getValidLocalizationKey(field.replace(/\[(\d+)]/g, ''));
  if (!validKey) return field;
  const requirementDueConcernsARepresentative = field.includes('representatives[');
  if (requirementDueConcernsARepresentative) {
    return getRequirementDueFieldLabelForRepresentative(intl)(field, representatives);
  }
  return validKey ? intl.formatMessage({ id: validKey }) : field;
};


