import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { Step } from '../component/step/Step';
import { ReadyStep } from '../component/step/ReadyStep';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { SUNDAY_FOR_RESTAURANTS_WIDTH } from 'src/app/pages/constants';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DevicePermissionsComponent } from '../component/DevicePermissionsComponent';
import { DownloadStep } from '../component/step/DownloadStep';
import UserPermissionsWithPosConnections from '../../pages/Settings/UserPermissions/components/UserPermissionsWithPosConnections';
import { UserInvitationComponent } from 'src/app-for-restaurants/component/UserInvitationComponent';
import { LinkStepWithQrCode } from 'src/app-for-restaurants/component/step/LinkStep';

const HowToAccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeV5.palette.grey['300']};
  border-radius: ${spaceUsages.largeSmall};
  width: ${SUNDAY_FOR_RESTAURANTS_WIDTH}px;
`;

const HowToAccessTitle = styled.div`
  padding-left: ${spaceUsages.largeSmall};
  padding-top: ${spaceUsages.mediumXLarge};
  color: ${themeV5.palette.grey['800']};
  font-size: 20px;
  font-weight: 400;
`;

const ManageAccessTitle = styled.div`
  font-weight: 400;
  font-size: 24px;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: ${spaceUsages.largeSmall};
  gap: ${spaceUsages.mediumSmall};
`;

const ManageAccessContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${SUNDAY_FOR_RESTAURANTS_WIDTH}px;
`;

export const HowtoAccess = () => {
  const intl = useIntl();
  const [toggleToShow, setToggleToShow] = useState('USER');
  return (
    <>
      <HowToAccessContainer>
        <HowToAccessTitle>
          <FormattedMessage id="sunday_for_restaurants.access.title" />
        </HowToAccessTitle>
        <StepContainer>
          <Step
            title="sunday_for_restaurants.access.step.one.title"
            details={intl.formatMessage({ id: 'sunday_for_restaurants.access.step.one.details' })}
            stepNumber={1}
            image={<DownloadStep />}
          />
          <LinkStepWithQrCode />
          <Step
            title="sunday_for_restaurants.access.step.third.title"
            details={intl.formatMessage({ id: 'sunday_for_restaurants.access.step.third.details' })}
            stepNumber={3}
            image={<ReadyStep />}
          />
        </StepContainer>
      </HowToAccessContainer>
      <ManageAccessContainer>
        <ManageAccessTitle>
          <FormattedMessage id="sunday_for_restaurants.manage_access.title" />
        </ManageAccessTitle>
        <ToggleButtonGroup
          value={toggleToShow}
          exclusive={true}
          onChange={(event: React.MouseEvent<HTMLElement>) => {
            // @ts-ignore
            let value = event.target.value;
            setToggleToShow(value);
          }}
        >
          <ToggleButton value="USER">
            <FormattedMessage id="sunday_for_restaurants.access.menu.users" />
          </ToggleButton>
          <ToggleButton value="DEVICE">
            <FormattedMessage id="sunday_for_restaurants.access.menu.devices" />
          </ToggleButton>
          <ToggleButton value="INVITE">
            <FormattedMessage id="sunday_for_restaurants.access.menu.invitation" />
          </ToggleButton>
        </ToggleButtonGroup>
      </ManageAccessContainer>

      {toggleToShow == 'USER' && <UserPermissionsWithPosConnections />}
      {toggleToShow == 'DEVICE' && <DevicePermissionsComponent />}
      {toggleToShow == 'INVITE' && <UserInvitationComponent />}
    </>
  );
};
