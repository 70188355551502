import React from 'react';
import { EmptyCard } from './EmptyCard';
import { HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished } from './HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished';
import { HomeReviewsCardWithSubscriptionAndGoogleSetupFinished } from './HomeReviewsCardWithSubscriptionAndGoogleSetupFinished';
import { Business } from 'src/business/domain/Business';
import {
  useGoogleSetupStageTermination,
} from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStageTermination';

type HomeReviewsCardV2Props = {
  business: Business;
};

export const HomeReviewsCard = ({ business }: HomeReviewsCardV2Props) => {
  const { isFinished: googleSetupFinished, loading } = useGoogleSetupStageTermination(business.id);

  if (loading) {
    return <EmptyCard />;
  }

  if (googleSetupFinished) {
    return <HomeReviewsCardWithSubscriptionAndGoogleSetupFinished business={business} />;
  }
  return <HomeReviewsCardWithSubscriptionAndGoogleSetupNotFinished businessId={business.id} />;
};
