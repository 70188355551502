import React, { ReactNode } from 'react';
import styled from 'styled-components';
import IconButton, { IconButtonVariants } from '../IconButton';
import { CrossIcon } from '../Icons';
import { getSpacing } from '../../../stylesheet';

interface Props {
  close?: () => void;
  children: ReactNode;
}

const DrawerHeaderContainer = styled.header`
  padding: ${getSpacing(3)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DrawerHeader: React.FC<Props> = ({ close, children, ...props }) => (
  <DrawerHeaderContainer {...props}>
    {children}
    {close && <IconButton onClick={close} variant={IconButtonVariants.BORDERLESS} Icon={CrossIcon} />}
  </DrawerHeaderContainer>
);

export default DrawerHeader;
