import { BlockDto } from './BlockDto';
import { Block } from '../../domain/Block';

export class BlockDtoConverter {
  static toBlock(dto: BlockDto): Block {
    return {
      id: dto.id,
      title: dto.title,
      description: dto.description,
      imageUrl: dto.imageUrl,
      textColor: dto.textColor,
      backgroundColor: dto.backgroundColor,
      linkUrl: dto.linkUrl,
      linkLabel: dto.linkLabel,
      type: dto.type,
    };
  }
}
