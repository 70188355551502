import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { TableDeviceRepositoryHttp } from '../infrastructure/TableDeviceRepositoryHttp';
import { Table } from '../domain/Table';

export const QUERYKEY_FLOOR_PLAN_LIST_TABLE = { scope: 'floor_plan_list_table' };
const buildQueryKey = (businessId: BusinessId) => [{
  ...QUERYKEY_FLOOR_PLAN_LIST_TABLE,
  businessId,
}] as const;

export const listTablesRequest = async (businessId: BusinessId): Promise<Table[]> => {
  const configuration = ConfigurationLoader.load();
  const tableDeviceRepository = new TableDeviceRepositoryHttp(configuration.sundayDeviceManagerUrl);

  try {
    return await tableDeviceRepository.listTables(businessId);
  } catch (e) {
    console.error('Ignoring floor plan manager error', e);
    return [];
  }
};

const listTablesQuery = async ({
  queryKey: [{ businessId }],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>) => listTablesRequest(businessId);

export const useListTables = (businessId: BusinessId) =>
  useQuery({
    queryKey: buildQueryKey(businessId),
    queryFn: listTablesQuery,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 15 * 60 * 1000,
    gcTime: 20 * 60 * 1000,
  });
