/* eslint-disable max-lines */
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetVenueBoxes } from '../../../box';
import { useGetVenueDetails } from '../../../venues/hook';
import { VenueDetails } from '../../../venues/types';
import { BoxOrdersList } from './BoxOrdersList';

export type OPOrdersListProps = {
  enrollmentId: EnrollmentId;
  boxId?: string;
};

export const OPOrdersList = ({ enrollmentId, boxId }: OPOrdersListProps) => {
  const { isLoading: boxLoading, data: boxes } = useGetVenueBoxes(enrollmentId);
  const [, getVenueDetails] = useGetVenueDetails();
  const business = useCurrentBusinessOrThrow();

  const [venue, setVenue] = useState<VenueDetails>();

  useEffect(() => {
    getVenueDetails(enrollmentId).then((venueDetails) => {
      setVenue(venueDetails);
    });
  }, [enrollmentId, getVenueDetails]);

  if (boxLoading) {
    return <CircularProgress />;
  }

  const currentBox = boxes?.find((box) => box.id === boxId);

  if (venue) {
    return <BoxOrdersList businessId={business.id} venue={venue} fixedBox={currentBox} />;
  }

  return <></>;
};
