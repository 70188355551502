import { useQueryClient } from '@tanstack/react-query';
import { listReplyTemplates } from '../infrastructure/useListReplyTemplates';
import { ReplyTemplate } from '../../reply-template/domain/ReplyTemplate';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useListReplyTemplatesForBusiness: () => Promise<ReplyTemplate[]> = () => {
  const business = useCurrentBusinessOrThrow();
  const queryClient = useQueryClient();
  return queryClient.fetchQuery(listReplyTemplates(business.id));
};
