import { CircularProgress } from '@mui/material';
import { Product, productTypesByElementType } from 'src/menu/dynamic-menu/domain/Product';
import React, { useEffect, useState } from 'react';
import { useSearchProducts } from 'src/menu/dynamic-menu/queries/product/useSearchProducts';
import {
  Pagination,
  useSearchProductsFilters,
} from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useSearchProductsFilters';
import { Business } from 'src/business/domain/Business';
import { useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import {
  MenuProductElementType,
  ProductsList,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { ProductsCatalogToolbar } from './ProductsCatalogToolbar';
import {
  MultiSelectProductsToolbar,
} from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/MultiSelectProductsToolbar';

export const ProductCatalog = ({
  business,
  menuAssetsSource: { syncSource, resourcesFilter },
}: {
  business: Business;
  menuAssetsSource: { syncSource: MenuAssetsSource | undefined; resourcesFilter: MenuAssetsSource | undefined };
}) => {
  const { pagination, filter, setSearchParams, productType, advanced, family } = useSearchProductsFilters();

  const menuProductElementType = family as MenuProductElementType;
  const productTypesByCurrentElementType = productTypesByElementType[menuProductElementType];

  const { data: paginatedProducts, isLoading: productsLoading } = useSearchProducts(
    business.id,
    pagination,
    productType === 'All' ? productTypesByCurrentElementType : [productType],
    filter,
    advanced === 'All' ? undefined : advanced,
    resourcesFilter,
  );

  const { data: menuBusiness } = useGetMenuBusiness(business.id);

  const [multiSelectedItems, setMultiSelectedItems] = useState<Product[]>([]);

  useEffect(() => {
    setMultiSelectedItems([]);
  }, [family]);

  const onSelectedProductsChanged = (selectedItems: Product[]) => {
    setMultiSelectedItems(selectedItems);
  };

  const onPaginationChanged = (newPagination: { filter: string } & Pagination) => {
    setSearchParams((prev) => {
      prev.set('filter', newPagination.filter);
      prev.set('page', newPagination.page.toString());
      prev.set('pageSize', newPagination.pageSize.toString());
      return prev;
    });
    setMultiSelectedItems([]);
  };

  const onSelectAllClick = () => {
    if (!paginatedProducts) {
      return;
    }
    if (multiSelectedItems.length === paginatedProducts.products.length) {
      onSelectedProductsChanged([]);
      return;
    }
    onSelectedProductsChanged(paginatedProducts.products);
  };

  const onSelectProduct = (row: Product, checked: boolean) => {
    const selectedProducts = checked ? [...multiSelectedItems, row] : multiSelectedItems.filter((p) => p.id !== row.id);
    onSelectedProductsChanged(selectedProducts);
  };

  const isProductSelected = (row: Product): boolean => {
    return multiSelectedItems.some((p) => p.id === row.id);
  };

  return (
    <>
      {multiSelectedItems.length == 0 && (
        <ProductsCatalogToolbar
          business={business}
          syncSource={syncSource}
          productType={productType}
          productTypesByCurrentElementType={productTypesByCurrentElementType}
          setSearchParams={setSearchParams}
          menuProductElementType={menuProductElementType}
          filter={filter}
          advanced={advanced}
        />
      )}
      {multiSelectedItems.length > 0 && (
        <MultiSelectProductsToolbar
          multiSelectedProducts={multiSelectedItems}
          onSelectedProductsChanged={onSelectedProductsChanged}
          businessId={business.id}
          menuProductElementType={menuProductElementType}
          menuBusiness={menuBusiness}
        />
      )}
      {(productsLoading || !paginatedProducts) ?
        (<CircularProgress />) : (
          <ProductsList
            business={business}
            productFamily={menuProductElementType}
            menuBusiness={menuBusiness}
            paginatedProducts={paginatedProducts || { products: [], totalCount: 0 }}
            pagination={pagination}
            filter={filter}
            onPaginationChanged={onPaginationChanged}
            multiSelectedItems={multiSelectedItems}
            onSelectAllClick={onSelectAllClick}
            onSelectProduct={onSelectProduct}
            isProductSelected={isProductSelected}
          />
        )}
    </>
  );
};
