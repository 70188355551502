import React from 'react';
import { spaceUsages, Title } from '@sundayapp/b2b-react-component-library';
import { ErrorPageWrapper, RobotImg } from './ErrorPage.style';
import robotImg from '../../../app/assets/robot.png';

const ErrorPage = () => (
  <ErrorPageWrapper>
    <RobotImg src={robotImg} alt="error page" />
    <Title size="title1" marginBottom={spaceUsages.mediumXLarge}>
      An error occurred, please retry.
    </Title>
  </ErrorPageWrapper>
);

export default ErrorPage;
