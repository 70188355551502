import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { DetailedProductAvailability, UpdateProductAvailability } from '../../types';
import { AvailabilityLineDetails } from './AvailabilityLineDetails';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  productDetails: DetailedProductAvailability[];
  updateAvailability: (updateProductAvailability: UpdateProductAvailability) => void;
}

const AvailabilityTable = styled.div`
  display: table;
  width: 100%;
  margin-top: ${getSpacing(2)};
`;

const Header = styled.div`
  display: table-row;
`;

const ColumnTitle = styled.div`
  display: table-cell;
  color: ${colorPalette.rhino};
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-transform: lowercase;
  padding: 0 ${getSpacing(1)} 0 ${getSpacing(2)};
  height: 53px;
  vertical-align: middle;
`;

const NumberColumnTitle = styled(ColumnTitle)`
  text-align: center  ;
`;

export const AvailabilityDataGrid: React.FC<Props> = ({ productDetails, updateAvailability }) => (
  <AvailabilityTable>
    <Header>
      <ColumnTitle>
        <FormattedMessage id="product.availability.list.product" defaultMessage="product" />
      </ColumnTitle>
      <ColumnTitle>
        <FormattedMessage id="product.availability.list.category" defaultMessage="category" />
      </ColumnTitle>
      <ColumnTitle>
        <FormattedMessage id="product.availability.list.sku" defaultMessage="sku" />
      </ColumnTitle>
      <ColumnTitle>
        <FormattedMessage id="product.availability.list.price" defaultMessage="price" />
      </ColumnTitle>
      <NumberColumnTitle>
        <FormattedMessage id="product.availability.list.stock" defaultMessage="stock" />
      </NumberColumnTitle>
    </Header>

    {productDetails.map((p) => (
      <AvailabilityLineDetails key={p.sku} productDetails={p} updateAvailability={updateAvailability} />
    ))}
  </AvailabilityTable>
);
