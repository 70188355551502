import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type SortBoxesByWaitingTimeSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const SortBoxesByWaitingTimeSetting = ({ venue, setVenue }: SortBoxesByWaitingTimeSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateVenue = async (sortBoxesByWaitingTime: boolean) => {
    makeUpdating(true);
    await updateVenueDetails({
      sortBoxesByWaitingTime,
    });
    setVenue({
      ...venue,
      sortBoxesByWaitingTime,
    });
    await pushNotification(
      intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }),
    );
    makeUpdating(false);
  };

  if (!venue.isFoodCourt) {
    return null;
  }

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.sortBoxesByWaitingTime',
        defaultMessage: 'Sort boxes by waiting time',
        description: 'sort boxes by waiting title',
      })}
      description={intl.formatMessage({
        id: 'venue.sortBoxesByWaitingTimeDescription',
        defaultMessage:
          'When activated, the boxes are sorted. Available boxes will be prioritized.',
      })}
      toggleValue={venue.sortBoxesByWaitingTime}
      toggleOnChange={updateVenue}
      disabled={isUpdating || !venue.enableBoxWaitingTime}
    />
  );
};
