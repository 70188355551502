import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { FixedPanel } from '../../../../common/components/FixedPanel';
import { Table } from '../../../../venues/domain/Table';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { SundayDevice } from '../../../domain/SundayDevice';
import { AreaDetailsQRTableDisplay } from './AreaDetailsQRTableDisplay';

type AreaDetailsTableDisplayPanelProps = {
  areaId: string;
  tables: Table[];
  sundayDevices: Map<string, SundayDevice>;
  isOpened: boolean;
  onClosed: () => void;
};

const Container = styled.div`
  padding: ${getSpacing(3)};
  padding-right: 0;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(100vh - 172px);
  margin-bottom: ${getSpacing(5)};
`;

const PanelTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-top: ${getSpacing(3)};
  margin-bottom: ${getSpacing(2)};
`;

const PanelDescription = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
  margin-bottom: ${getSpacing(4)};
`;

export const AreaDetailsTableDisplayPanel = ({
  areaId,
  tables,
  sundayDevices,
  isOpened,
  onClosed,
}: AreaDetailsTableDisplayPanelProps) => {
  const areaTables = useMemo(() => tables.filter((table) => table.areaId === areaId), [areaId, tables]);

  return (
    <FixedPanel isOpened={isOpened} onClosed={onClosed}>
      <Container>
        <PanelTitle>
          <FormattedMessage
            id="settings.area.details.service-at-table.see-all-table.title"
            defaultMessage="tables for service at table"
          />
        </PanelTitle>
        <PanelDescription>
          <FormattedMessage
            id="settings.area.details.service-at-table.see-all-table.description"
            defaultMessage="Your area has different tables with one QR code per table. With service at table orders will include the table number"
          />
        </PanelDescription>
        <TableContainer>
          {areaTables.map((table) => (
            <AreaDetailsQRTableDisplay key={table.id} table={table} device={sundayDevices.get(table.id)} />
          ))}
        </TableContainer>
      </Container>
    </FixedPanel>
  );
};
