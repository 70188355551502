import React from 'react';
import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { SortElement } from './SortElement';

const ElementItemLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: ${spaceUsages.largeSmall};
  padding: 8px;
  background-color: white;
`;

type MenuItemProps = {
  element: SortElement;
};

export const ElementItem = ({ element }: MenuItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: element.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ElementItemLineContainer ref={setNodeRef} {...attributes} style={style}>
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        width={350}
        style={{ cursor: 'pointer' }}
      >
        <DragIndicator fontSize="large" {...listeners} style={{ cursor: 'grab', opacity: '0.5', marginRight: 16 }} />
        <Typography>{element.label}</Typography>
      </Box>
    </ElementItemLineContainer>
  );
};
