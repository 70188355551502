import axios, { AxiosInstance } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export class HttpOrderingReceiptRepository {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios;
  }

  async sendBillReceipt(orderId: string, email: string): Promise<void> {
    return this.httpClient.post(
      `${ConfigurationLoader.load().orderingApiBaseUrl}/orders/${orderId}/receipt/resend`,
      { email },
    );
  }
}
