import { AreaConfiguration } from '../domain/AreaConfiguration';
import { FulfillmentType } from '../domain/FulfillmentType';

export const areaConfigurationToMenuAssociations = (
  areaConfiguration: AreaConfiguration[],
): Map<FulfillmentType, string[]> => {
  const services = new Map<FulfillmentType, string[]>();
  areaConfiguration.forEach((config) => {
    const association = services.get(config.fulfillmentMethod) || [];
    association.push(config.menuId);
    services.set(config.fulfillmentMethod, association);
  });

  return services;
};
