import { useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Review } from '../browse/domain/Review';
import { ReplyTemplate } from '../reply-template/domain/ReplyTemplate';
import { useListReplyTemplates } from './infrastructure/useListReplyTemplates';
import { generateReplyTextFromTemplate } from '../reply-template/domain/ReplyTemplateUtils';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type Props = {
  review: Review;
  selectTemplate: (template: ReplyTemplate) => void;
  selectedTemplate: ReplyTemplate | undefined;
  onSuggest: (suggestion: string, template: ReplyTemplate) => void;
  reset: () => void;
};

export const SuggestReplyBasedOnTemplate = ({ review, onSuggest, selectTemplate, selectedTemplate, reset }: Props) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const { data: replyTemplates } = useListReplyTemplates(business.id);
  const replyTemplatesConfigured = replyTemplates && replyTemplates.length > 0;
  const NONE: string = '';

  const filterByRating = (templates: ReplyTemplate[], rating: number): ReplyTemplate[] =>
    templates.filter((template) => template.applicableRatings.includes(rating) || !template.applicableRatings);

  const onTemplateSelected = (template: ReplyTemplate) => {
    selectTemplate(template);
    const templateFilled = generateReplyTextFromTemplate(template, {
      review,
      business,
    });
    onSuggest(templateFilled, template);
    const templateId = template.id;
    ampli.reviewReplyTemplateChosen({ templateId });
  };

  const onChangeTemplate = (templateId: String) => {
    const template = filterByRating(replyTemplates!, review.rating).find((t) => t.id === templateId);
    if (template) {
      onTemplateSelected(template);
    } else {
      reset();
    }
  };

  if (!replyTemplatesConfigured) return <></>;
  return (
    <FormControl fullWidth>
      <InputLabel>{intl.formatMessage({ id: 'reviews.reply.template.choose' })}</InputLabel>
      <Select
        value={selectedTemplate ? selectedTemplate.id : NONE}
        label={intl.formatMessage({ id: 'reviews.reply.template.choose' })}
        onChange={(e) => onChangeTemplate(e.target.value)}
      >
        <MenuItem value={NONE}>
          <em>{intl.formatMessage({ id: 'reviews.reply.template.choose.none' })}</em>
        </MenuItem>
        {filterByRating(replyTemplates, review.rating).map((template) => (
          <MenuItem value={template.id} key={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
