import { IntlShape } from 'src/app/i18n/TypedIntl';
import { GroupedTimeRanges } from '../../components/SchedulePanel/GroupedTimeRanges';
import { TimeRange } from '../../domain/TimeRange';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
type Day = typeof days[number];

export class MenuScheduleTranslationUtils {
  static groupedConstraintToString(groupedConstraints: GroupedTimeRanges, translation: IntlShape): string[] {
    const sortedHours = MenuScheduleTranslationUtils.orderRanges(groupedConstraints.ranges);
    const hours = MenuScheduleTranslationUtils.translateHours(sortedHours, translation);
    return MenuScheduleTranslationUtils.translateDays(groupedConstraints, translation).map((day) => `${day} ${hours}`);
  }

  public static orderRanges(hours: TimeRange[]): TimeRange[] {
    return hours.sort((t1, t2) => t1.startTime.localeCompare(t2.startTime));
  }

  public static translateHours(hours: TimeRange[], translation: IntlShape): string {
    if (hours.length === 0) {
      return translation.formatMessage({ id: 'menus.hours.range.24h' });
    }
    const hour = MenuScheduleTranslationUtils.translateHour(hours[0], translation);
    if (hours.length > 1) {
      const additionalHours = MenuScheduleTranslationUtils.translateHours(hours.slice(1), translation);
      return `${hour} · ${additionalHours}`;
    }
    return hour;
  }

  public static translateDays(groupedConstraints: GroupedTimeRanges, translation: IntlShape): string[] {
    if (MenuScheduleTranslationUtils.hasWeekDays(groupedConstraints)) {
      return MenuScheduleTranslationUtils.translateWithWeekdays(groupedConstraints, translation);
    }

    if (MenuScheduleTranslationUtils.hasWeekend(groupedConstraints)) {
      return MenuScheduleTranslationUtils.translateWithWeekend(groupedConstraints, translation);
    }

    const dayNames = MenuScheduleTranslationUtils.getDays(groupedConstraints);
    if (dayNames.length === 0) {
      return [];
    }

    if (dayNames.length === 1) {
      return [translation.formatMessage({ id: `common.${dayNames[0]}` })];
    }

    const shortenDays = dayNames.map((dayName) => translation.formatMessage({ id: `common.short.${dayName}` }));

    return [shortenDays.join(', ')];
  }

  private static translateWithWeekdays(groupedConstraints: GroupedTimeRanges, translation: IntlShape) {
    const additionalTranslation = MenuScheduleTranslationUtils.translateDays(
      {
        ...groupedConstraints,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
      },
      translation,
    );
    return [translation.formatMessage({ id: 'common.weekdays' })].concat(additionalTranslation);
  }

  private static translateWithWeekend(groupedConstraints: GroupedTimeRanges, translation: IntlShape) {
    const additionalTranslation = MenuScheduleTranslationUtils.translateDays(
      {
        ...groupedConstraints,
        saturday: false,
        sunday: false,
      },
      translation,
    );
    return additionalTranslation.concat([translation.formatMessage({ id: 'common.weekend' })]);
  }

  private static hasWeekDays(groupedConstraints: GroupedTimeRanges): boolean {
    return (
      groupedConstraints.monday
      && groupedConstraints.tuesday
      && groupedConstraints.wednesday
      && groupedConstraints.thursday
      && groupedConstraints.friday
    );
  }

  private static hasWeekend(groupedConstraints: GroupedTimeRanges): boolean {
    return groupedConstraints.saturday && groupedConstraints.sunday;
  }

  private static translateHour(hour: TimeRange, translation: IntlShape): string {
    if (MenuScheduleTranslationUtils.isFullDay(hour)) {
      return translation.formatMessage({ id: 'menus.hours.range.24h' });
    }

    return `${hour.startTime} - ${hour.endTime}`;
  }

  private static isFullDay(hour: TimeRange): boolean {
    return hour.startTime === '00:00' && hour.endTime === '24:00';
  }

  private static getDays(groupedConstraints: GroupedTimeRanges) {
    const dayConstraints: Record<Day, boolean> = {
      monday: groupedConstraints.monday,
      tuesday: groupedConstraints.tuesday,
      wednesday: groupedConstraints.wednesday,
      thursday: groupedConstraints.thursday,
      friday: groupedConstraints.friday,
      saturday: groupedConstraints.saturday,
      sunday: groupedConstraints.sunday,
    };

    return days.filter((day) => dayConstraints[day]);
  }
}
