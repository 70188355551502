import { Business } from 'src/business/domain/Business';
import { LocalisationKey } from 'src/lang/en';

export type IntroduceFlow = {
  step: 'introduce flow';
};

export type SelectSourceBusiness = {
  step: 'select source business';
};

export type SelectConfigurations = {
  step: 'select configurations';
  sourceBusiness: Business;
};

export type SelectTargetBusinesses = {
  step: 'select target businesses';
  sourceBusiness: Business;
  configurations: Array<SelectableConfigurations>;
};

export type ReviewAndApplyChanges = {
  step: 'review and apply changes';
  sourceBusiness: Business;
  configurations: Array<SelectableConfigurations>;
  targetBusinesses: Array<Business>;
};

export type SelectableConfigurations =
  | 'customization colors'
  | 'customization post payment banner'
  | 'customization collapsible menu banner'
  | 'cover picture';

export const selectableConfigurationsLabels: Record<SelectableConfigurations, LocalisationKey> = {
  'customization colors': 'configuration_replication.configuration.customization_color',
  'customization post payment banner': 'configuration_replication.configuration.customization_post_payment_banner',
  'customization collapsible menu banner': 'configuration_replication.configuration.customization_burger_banner',
  'cover picture': 'configuration_replication.configuration.customization_cover_picture',
};

export const allSelectableConfigurations = Object.keys(selectableConfigurationsLabels) as SelectableConfigurations[];

export type ConfigurationReplicationState =
  | IntroduceFlow
  | SelectSourceBusiness
  | SelectConfigurations
  | SelectTargetBusinesses
  | ReviewAndApplyChanges;

export const displaySourceBusiness = (): SelectSourceBusiness => {
  return { step: 'select source business' };
};

export const displayConfigurations = (sourceBusiness: Business): SelectConfigurations => {
  return { step: 'select configurations', sourceBusiness };
};

export const displayTargetBusinesses = (state: SelectConfigurations, configurations: Array<SelectableConfigurations>): SelectTargetBusinesses => {
  return {
    step: 'select target businesses',
    configurations: configurations,
    sourceBusiness: state.sourceBusiness,
  };
};

export const displayResume = (
  state: SelectTargetBusinesses,
  targetBusinesses: Array<Business>,
): ReviewAndApplyChanges => {
  return {
    step: 'review and apply changes',
    configurations: state.configurations,
    sourceBusiness: state.sourceBusiness,
    targetBusinesses: targetBusinesses,
  };
};
