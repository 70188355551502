import React from 'react';
import styled, { css } from 'styled-components';
import { colorPalette, fontFamily } from '../../../stylesheet';

export interface Props
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size?: number;
  active?: boolean;
  primary?: boolean;
  label?: string;
  color?: string;
}

interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  size: number;
  primary?: boolean;
  color?: string;
}

const StyledButton: React.FC<ButtonProps> = styled.button<ButtonProps>`
  font-family: ${fontFamily};
  border: 1px solid ${colorPalette.lightBorderColor};
  background-color: ${colorPalette.defaultBackgroundColor};
  padding: 0;
  text-align: center;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${({ size }) => css`
    width: ${size}px;
    height: ${size}px;
    border-radius: ${size}px;
    border-width: ${size >= 64 ? '2px' : '1px'};
    font-size: ${size > 32 ? '24px' : '18px'};
  `}
  &:disabled {
    color: ${colorPalette.grey600};
  }
  ${({ primary }) =>
    primary
    && css`
      border-color: ${colorPalette.primaryBackgroundColor};
      background-color: ${colorPalette.primaryBackgroundColor};
      color: ${colorPalette.primaryForegroundColor};
    `}
  ${({ color }) =>
    color
    && css`
      background-color: ${color};
      border-color: ${color};
    `}
`;

const RoundButton: React.FC<Props> = ({
  children, label, size = 16, primary = false, ...props
}) => (
  <StyledButton {...props} size={size} primary={primary}>
    {label || children}
  </StyledButton>
);

export default RoundButton;
