import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../common/components/Button';
import { Order } from '../../types';
import { getSpacing } from '../../../stylesheet';
import Drawer from '../../../common/components/Drawer';
import { CashChangeCalculator } from './CashChangeCalculator';
import { AcceptationMode } from '../../repositories/OrderingOrderRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

interface Props {
  order: Order;
  visibility: boolean;
  onAcceptOrder: (mode: AcceptationMode) => void;
  onClose: () => void;
}

const AcceptOrderMessage = styled.div`
  margin: ${getSpacing(2)} 0;
`;

const AcceptOrderDrawerContainer = styled.div`
  margin: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
  gap: ${getSpacing(1)};
`;

export const AcceptOrderDrawer: React.FC<Props> = ({ order, visibility, onAcceptOrder, onClose }) => {
  const [drawerCashVisible, setDrawerCashVisible] = useState(false);
  const { ogEnrollmentId } = ConfigurationLoader.load();
  const isOgEnrollment = ogEnrollmentId === order.enrollmentId;

  return (
    <>
      <Drawer visible={visibility} maxWidth="600px">
        <AcceptOrderDrawerContainer>
          <AcceptOrderMessage>
            <FormattedMessage id="orders.accept.choosePaymentMethod" defaultMessage="Paid by:" />
          </AcceptOrderMessage>
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={() => {
              setDrawerCashVisible(true);
              onClose();
            }}
          >
            <FormattedMessage id="orders.acceptOrder.cash" defaultMessage="Cash" />
          </Button>
          {!isOgEnrollment &&
            <Button variant={ButtonVariant.PRIMARY} onClick={() => onAcceptOrder(AcceptationMode.CARD)}>
              <FormattedMessage id="orders.acceptOrder.card" defaultMessage="Card" />
            </Button>}
          {!isOgEnrollment &&
            <Button variant={ButtonVariant.PRIMARY} onClick={() => onAcceptOrder(AcceptationMode.FREE)}>
              <FormattedMessage id="orders.acceptOrder.free" defaultMessage="Free" />
            </Button>}
          <Button variant={ButtonVariant.LIGHT} onClick={onClose}>
            <FormattedMessage id="orders.acceptOrder.cancel" defaultMessage="Cancel" />
          </Button>
        </AcceptOrderDrawerContainer>
      </Drawer>
      {drawerCashVisible && (
        <CashChangeCalculator order={order} accept={onAcceptOrder} close={() => setDrawerCashVisible(false)} />
      )}
    </>
  );
};
