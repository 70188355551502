import React from 'react';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { AreaBoxMenu } from './AreaBoxMenu';

type AreaBoxMenuSelectionProps = {
  service: FulfillmentType;
  menus: OrderingMenu[];
  selectedMenus: OrderingMenu[];
  onMenuToggled: (service: FulfillmentType, menu: OrderingMenu, activated: boolean) => void;
};

export const AreaBoxMenuSelection = ({
  service, menus, selectedMenus, onMenuToggled,
}: AreaBoxMenuSelectionProps) => {
  const isMenuSelected = (menuId: string): boolean => selectedMenus.find((selectedMenu) => selectedMenu.id === menuId) !== undefined;

  const onToggled = (menu: OrderingMenu, selected: boolean) => {
    onMenuToggled(service, menu, selected);
  };

  return (
    <>
      {menus.map((menu) => (
        <AreaBoxMenu key={menu.id} menu={menu} selected={isMenuSelected(menu.id)} onToggled={onToggled} />
      ))}
    </>
  );
};
