import { Firestore } from 'firebase/firestore';
import { MenuPort } from '../domain/MenuPort';
import { MenuRepositoryFirestore } from './MenuRepositoryFirestore';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export class MenuAdapter implements MenuPort {
  constructor(private menuRepositoryFirestore: MenuRepositoryFirestore) {}

  static fromFirebase(firestore: Firestore): MenuAdapter {
    const menuRepository = new MenuRepositoryFirestore(firestore);
    return new MenuAdapter(menuRepository);
  }

  getMenuLabels(
    enrollmentId: EnrollmentId,
  ): Promise<{ pdfMenuLabel: string | undefined; websiteMenuLabel: string | undefined }> {
    return this.menuRepositoryFirestore.getMenuLabels(enrollmentId);
  }

  async updateMenuLabels(enrollmentId: EnrollmentId, pdfMenuLabel: string, websiteMenuLabel: string) {
    await this.menuRepositoryFirestore.updateMenuLabels(enrollmentId, pdfMenuLabel, websiteMenuLabel);
  }
}
