import React from 'react';
import { Box, Button } from '@mui/material';
import { Money, MoneyView } from '@sundayapp/web-money';

type Props = {
  delta: Money,
  isBreakdown?: boolean,
  deltaIsAcceptable: boolean,
};

const DeltaDetails = ({ delta, isBreakdown = false, deltaIsAcceptable }: Props) => {
  const color = deltaIsAcceptable ? '#3fb05e' : '#e0831e';
  const backgroundButton = delta.amount === 0 ? '#fff4d1' : color;
  const textColor = delta.amount === 0 ? 'black' : 'white';
  const background = delta.amount === 0 ? 'white' : '#fff4d1';

  return (
    <Box alignItems="center" display="flex" color={textColor} flexDirection="row">
      {isBreakdown && (<div>-</div>)}
      {!isBreakdown && delta.amount === 0 && (<img src="/check.svg" alt="check" height="32px" width="32px" />)}
      {!isBreakdown && delta.amount !== 0 && (
        <Button
          variant="outlined"
          size="small"
          style={{
            borderColor: background, backgroundColor: backgroundButton, color: textColor, fontSize: '15px',
          }}
          disabled
        >
          <MoneyView value={delta} />
        </Button>
      )}
    </Box>
  );
};

export default DeltaDetails;
