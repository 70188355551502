import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useAssociateAreaTables } from '../../../hooks';
import { Table } from '../../../../venues/domain/Table';
import { FixedPanel } from '../../../../common/components/FixedPanel';
import { AreaDetailsTable } from './AreaDetailsTable';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type AreaDetailsTableAssociationPanelProps = {
  areaId: string;
  tables: Table[];
  setTables: (tables: Table[]) => void;
  onTableUpdated: () => void;
  isOpened: boolean;
  onClosed: () => void;
};

const Container = styled.div`
  padding: ${getSpacing(3)};
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 50vh;
  margin-bottom: ${getSpacing(5)};
`;

const ActionLine = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const PanelTitle = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-top: ${getSpacing(3)};
  margin-bottom: ${getSpacing(2)};
`;

const PanelDescription = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
  margin-bottom: ${getSpacing(4)};
`;

export const AreaDetailsTableAssociationPanel = ({
  areaId,
  tables,
  setTables,
  onTableUpdated,
  isOpened,
  onClosed,
}: AreaDetailsTableAssociationPanelProps) => {
  const availableTables = useMemo(() => tables.filter((table) => table.areaId !== areaId), [tables, areaId]);

  const [associationStatus, associateAreaTables] = useAssociateAreaTables();
  const [selectedTables, updateSelectedTables] = useState<string[]>([]);

  const computeUpdatedTableSelection = (tableId: string, selected: boolean): string[] => {
    if (selected) {
      return [...selectedTables, tableId];
    }

    return selectedTables.filter((id) => id !== tableId);
  };

  const tableSelected = (tableId: string, selected: boolean) => {
    const updatedTableSelection = computeUpdatedTableSelection(tableId, selected);
    updateSelectedTables(updatedTableSelection);
  };

  const isTableSelected = (tableId: string): boolean => selectedTables.find((id) => id === tableId) !== undefined;

  const onTableSelectionDone = async () => {
    await associateAreaTables(areaId, selectedTables);

    const updatedTables = tables.map((table) => {
      const selectedTable = selectedTables.find((id) => id === table.id) !== undefined;
      if (selectedTable) {
        return { ...table, areaId };
      }
      return { ...table };
    });

    setTables(updatedTables);
    updateSelectedTables([]);
    onTableUpdated();
    onClosed();
  };

  return (
    <FixedPanel isOpened={isOpened} onClosed={onClosed}>
      <Container>
        <PanelTitle>
          <FormattedMessage
            id="settings.area.details.service-at-table.associate-tables.title"
            defaultMessage="link new table to an area"
          />
        </PanelTitle>
        <PanelDescription>
          <FormattedMessage
            id="settings.area.details.service-at-table.associate-tables.description"
            defaultMessage="select the table that you want to add to your area"
          />
        </PanelDescription>
        <TableContainer>
          {availableTables.map((table) => (
            <AreaDetailsTable
              key={table.id}
              table={table}
              selected={isTableSelected(table.id)}
              onToggled={tableSelected}
              disabled={associationStatus.loading}
            />
          ))}
        </TableContainer>
        <ActionLine>
          <Button
            variant="primary"
            size="medium"
            fullWidth
            onClick={onTableSelectionDone}
            disabled={selectedTables.length === 0}
          >
            <FormattedMessage
              id="settings.area.details.service-at-table.associate-tables.save"
              defaultMessage="add tables to my area"
            />
          </Button>
          <Button variant="tertiary" size="medium" fullWidth marginTop="16px" onClick={onClosed}>
            <FormattedMessage
              id="settings.area.details.service-at-table.associate-tables.cancel"
              defaultMessage="cancel"
            />
          </Button>
        </ActionLine>
      </Container>
    </FixedPanel>
  );
};
