import { InfoCard } from '@molecules/Card/InfoCard';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const CustomerData = () => {
  const intl = useIntl();

  return (
      <InfoCard 
        hasTitle
        title={intl.formatMessage({ id: 'venue.home.customer_data.title' })}
        subTitle='15,098' //TODO::Replace with actual data
        buttonText={intl.formatMessage({ id: 'venue.home.customer_data.button' })}
        trend={0}
        hasContent={false}
      />
  );
};