import React, { useState } from 'react';
import styled from 'styled-components';
import { getSpacing } from 'src/ordering/stylesheet';
import DebouncedInputText from '../../../../../common/components/DebouncedInputText';
import { Checkbox } from '@mui/material';

type ServiceFeePanelProps = {
  type: string;
  title: string;
  placeholder: string;
  value: number;
  convertedValue: string;
  selected: boolean;
  onSelection: () => void;
  commitChange: (newValue: number) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${getSpacing(1)};
`;

export const ServiceFeePanel = ({
  type,
  title,
  placeholder,
  value,
  convertedValue,
  selected,
  onSelection,
  commitChange,
}: ServiceFeePanelProps) => {
  const [ focus, setFocus ] = useState<boolean>(false);

  const commitChangeHandler = (v: string) => {
    if (!v) {
      commitChange(0);
      return;
    }

    commitChange(parseFloat(v));
  };

  return (
    <Container>
      <Checkbox id={type} checked={selected} onChange={onSelection} />
      <DebouncedInputText
        label={title}
        type={focus ? 'number' : 'text'}
        placeholder={placeholder}
        value={focus ? value : convertedValue}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        commitChange={commitChangeHandler}
        data-testid="venue-service-charge-amount-input"
      />
    </Container>
  );
};
