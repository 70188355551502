/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const SearchIcon: React.FC<IconProps> = ({ size = 16, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.9789 11.1095C13.0947 9.77001 13.651 8.05193 13.5323 6.31266C13.4136 4.5734 12.6289 2.94687 11.3415 1.77143C10.054 0.595991 8.36299 -0.0378545 6.62013 0.00175019C4.87727 0.0413549 3.21677 0.751361 1.98407 1.98407C0.751361 3.21677 0.0413549 4.87727 0.00175019 6.62013C-0.0378545 8.36299 0.595991 10.054 1.77143 11.3415C2.94687 12.6289 4.5734 13.4136 6.31266 13.5323C8.05193 13.651 9.77001 13.0947 11.1095 11.9789L14.958 15.8274C15.0739 15.9394 15.2293 16.0014 15.3905 16C15.5517 15.9986 15.7059 15.9339 15.8199 15.8199C15.9339 15.7059 15.9986 15.5517 16 15.3905C16.0014 15.2293 15.9394 15.0739 15.8274 14.958L11.9789 11.1095ZM1.25068 6.78451C1.25068 5.69002 1.57523 4.62011 2.1833 3.71007C2.79136 2.80004 3.65563 2.09076 4.6668 1.67191C5.67798 1.25307 6.79064 1.14348 7.8641 1.35701C8.93756 1.57053 9.92359 2.09758 10.6975 2.8715C11.4714 3.64542 11.9985 4.63145 12.212 5.70491C12.4255 6.77837 12.3159 7.89104 11.8971 8.90221C11.4783 9.91339 10.769 10.7777 9.85894 11.3857C8.9489 11.9938 7.87899 12.3183 6.78451 12.3183C5.31734 12.3167 3.91074 11.7332 2.87329 10.6957C1.83585 9.65828 1.2523 8.25167 1.25068 6.78451Z"
      fill={color}
    />
  </svg>
);
