import axios from 'axios';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentLinkRepository } from '../infrastructure/PaymentLinkRepository';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_PAYMENT_LINKS = { scope: 'payment_links' };
export const buildQueryKey = (businessId: BusinessId | undefined) => [{
  ...QUERYKEY_PAYMENT_LINKS,
  businessId,
}] as const;

export const paymentLinksRequest = async (businessId: BusinessId | undefined) => {
  if (!businessId) {
    return [];
  }

  const paymentLinkRepository = new PaymentLinkRepository(axios, ConfigurationLoader.load());
  return paymentLinkRepository.findAll(businessId);
};

const paymentLinksQuery = async ({
  queryKey: [{ businessId }],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>) => paymentLinksRequest(businessId);

export const usePaymentLinks = (businessId: BusinessId | undefined) =>
  useQuery({
    queryKey: buildQueryKey(businessId),
    queryFn: paymentLinksQuery,
  });
