import { ControllingCompanyDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

export const isControllingCompanyComplete = ({ controllingCompany, containsErrors }: {
  controllingCompany: ControllingCompanyDraft | undefined,
  containsErrors: boolean
}): boolean => {
  if (containsErrors || !controllingCompany) return false;
  return !!controllingCompany.legalName &&
    (!!controllingCompany.ownershipPercentage
      && !!controllingCompany.address?.country);
};
