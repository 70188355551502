import { useFormContext } from 'react-hook-form';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { BusinessType } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessType';
import { LegalName } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/LegalName';
import {
  IsTradingThemSameAsLegalName,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/IsTradingThemSameAsLegalName';
import {
  TradingBusinessName,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/TradingBusinessName';
import {
  BusinessRegistrationNumber,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessRegistrationNumber';
import { BusinessEmail } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessEmail';
import {
  RegisteredBusinessAddress,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/RegisteredBusinessAddress';
import {
  IsPrimaryAddressTheSameAsRegistered,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/IsPrimaryAddressTheSameAsRegistered';
import { PrimaryAddress } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/PrimaryAddress';
import {
  BusinessPhoneNumber,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/BusinessPhoneNumber';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  DateOfIncorporation,
} from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/DateOfIncorporation';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';


export const BusinessDetails = ({ businessCountryCode }: { businessCountryCode: string }) => {
  const { watch } = useFormContext<CheckoutOnboardingInformationDraft>();

  const isPrimaryAddressTheSameAsRegistered = watch('isPrimaryAddressTheSameAsRegistered');
  const isTradingNameTheSameAsLegalName = watch('isTradingNameTheSameAsLegalName');

  return <Stack direction="column" spacing={3}>
    <Typography variant={'h5'}>
      1 · <FormattedMessage id={'settings.payments.checkout.onboarding.businessDetails'} />
    </Typography>
    <BusinessType businessCountryCode={businessCountryCode}/>
    <LegalName />
    <IsTradingThemSameAsLegalName />
    {!isTradingNameTheSameAsLegalName && <TradingBusinessName />}
    <BusinessRegistrationNumber />
    <DateOfIncorporation />
    <BusinessPhoneNumber businessCountryCode={businessCountryCode} />
    <BusinessEmail />
    <RegisteredBusinessAddress />
    <IsPrimaryAddressTheSameAsRegistered />
    {!isPrimaryAddressTheSameAsRegistered && <PrimaryAddress />}
  </Stack>
  ;
};
