import axios from 'axios';
import { Money } from '@sundayapp/web-money';
import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import { OrderingPaymentRepository } from './OrderingPaymentRepository';

export class OrderingPaymentRepositoryHttp extends RepositoryHttp implements OrderingPaymentRepository {
  constructor(private venueBackendUrl: string) {
    super();
  }

  async refundPayWithCash(paymentId: string, refundAmount: Money): Promise<boolean> {
    const response = await axios.post<string>(
      `${this.venueBackendUrl}/payments/${paymentId}/refund-pay-with-cash/`,
      { refundAmount },
      this.withDefaultHeader(),
    );

    return response.data === 'OK';
  }
}
