import React, { useEffect, useState } from 'react';
import { colorPalette } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Money, useRenderMoney } from '@sundayapp/web-money';
import { useNavigate } from 'react-router';
import SunSvg from '../../../app/component/icons/SunSvg';
import { HomeCtaButton } from '../HomeCtaButton';
import { useGetTodayAccountingSummary } from 'src/accounting/revenues/hooks/useGetAccountingSummary';
import { venueRevenuesPath } from 'src/app/navigation/pathHelpers';
import { Business } from 'src/business/domain/Business';
import { isRevenuesSummary } from 'src/accounting/revenues/domain/RevenueSummary';

type RevenueCtaProps = {
  business: Business;
};

export const RevenueCta = ({ business }: RevenueCtaProps) => {
  const intl = useIntl();
  const getTodayAccountingSummary = useGetTodayAccountingSummary();
  const renderMoney = useRenderMoney(true, true);
  const navigate = useNavigate();

  const [todayRevenue, setTodayRevenue] = useState<string>();
  const [revenueComputing, updateRevenueComputing] = useState<boolean>(false);

  useEffect(() => {
    updateRevenueComputing(true);
    getTodayAccountingSummary(business.id, business.timezone)
      .then((summary) => {
        if (!isRevenuesSummary(summary) || summary.revenues.length !== 1) {
          return;
        }
        const revenue = summary.revenues[0];

        const grossRevenue: Money = {
          amount: revenue.totalSalesAndTips.amount,
          currency: revenue.totalSalesAndTips.currency,
        };
        const grossRevenueLabel = renderMoney(grossRevenue);

        setTodayRevenue(grossRevenueLabel);
      })
      .finally(() => {
        updateRevenueComputing(false);
      });
  }, [business]);

  const onClick = () => {
    navigate(venueRevenuesPath(business.id, 'TODAY'));
  };

  if (revenueComputing) {
    return <>Computing !!!</>;
  }

  if (!todayRevenue) {
    return <></>;
  }

  return (
    <HomeCtaButton
      icon={<SunSvg size={24} color={colorPalette.grey500} />}
      label={intl.formatMessage({ id: 'venue.home.revenue.cta' })}
      value={todayRevenue}
      onClick={onClick}
    />
  );
};
