/* eslint-disable class-methods-use-this */
type Shape = 'round' | 'rect';

interface CropArea {
  x: number,
  y: number,
  width: number,
  height: number,
}

export interface CropPictureData {
  shape: Shape;
  image: HTMLImageElement;
  background?: {
    color: string;
    alpha?: number
  },
  area: CropArea;
}

function decimalToHexa(color: number) {
  const res = color.toString(16);
  if (res.length === 1) {
    return `0${res}`;
  }
  return res;
}

function toHexaStringColor(color: Uint8ClampedArray) {
  return `#${decimalToHexa(color[0])}${decimalToHexa(color[1])}${decimalToHexa(color[2])}`;
}

const roundAndFill = (crop: CropPictureData, cropArea: CropArea, finalContext: CanvasRenderingContext2D, finalCanvas: HTMLCanvasElement) => {
  if (crop.shape === 'round') {
    const centerX = cropArea.width / 2;
    const centerY = cropArea.height / 2;
    const radius = cropArea.width / 2;
    const startAngle = 0;
    const endAngle = 2 * Math.PI;

    finalContext!.beginPath();
    finalContext!.arc(centerX, centerY, radius, startAngle, endAngle);
    finalContext!.closePath();
    finalContext!.clip();
    if (crop.background && crop.background.color) {
      // eslint-disable-next-line no-param-reassign
      finalContext!.fillStyle = crop.background.color;
      finalContext!.fillRect(0, 0, finalCanvas.width, finalCanvas.height);
    }
  }
};

function createCanvasAndContext(area: CropArea) {
  const finalCanvas = document.createElement('canvas');
  const finalContext = finalCanvas.getContext('2d');
  finalCanvas.width = area.width;
  finalCanvas.height = area.height;
  return {
    finalCanvas,
    finalContext,
  };
}

export class CropperService {
  public getPickedColor(crop: CropPictureData, x: number, y: number) {
    const { ctx } = this.getCroppedCanvasFromImage(crop);
    if (!ctx) {
      return {
        color: '',
        alpha: 0,
      };
    }
    const pickedColor = ctx.getImageData(
      x,
      y,
      1,
      1,
    ).data;
    return {
      color: toHexaStringColor(pickedColor),
      alpha: pickedColor[3],
    };
  }

  public getCroppedPicture(crop: CropPictureData, format: 'png' | 'jpeg') {
    const { canvas } = this.getCroppedCanvasFromImage(crop);
    if (!canvas) {
      return '';
    }
    return canvas.toDataURL(format === 'png' ? 'image/png' : 'image/jpeg', format === 'png' ? 1 : 0.75);
  }

  public static downloadImage(url: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = url;
    });
  }

  private getCroppedCanvasFromImage(crop: CropPictureData) {
    const { area, image } = crop;
    const { finalCanvas, finalContext } = createCanvasAndContext(area);
    if (!finalContext) {
      return {
        ctx: null,
        canvas: null,
      };
    }

    roundAndFill(crop, area, finalContext, finalCanvas);
    finalContext.drawImage(image, area.x * -1, area.y * -1);

    return { ctx: finalContext, canvas: finalCanvas };
  }
}
