import styled from 'styled-components';
import { colorPalette, zIndex } from '../../../stylesheet';

export const AvailabilityLayout = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  z-index: ${zIndex.DEFAULT};
  flex: 1;
  background-color: ${colorPalette.defaultBackgroundColor};
  border-radius: 24px;
`;

export const AvailabilityListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvailabilityGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const AvailabilityStyledCustomScroll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
`;
