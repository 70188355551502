import { FormattedMessage } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, GoToSvg, IconWrapper, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import { GoToContainer } from './GoToFaqTooltip.styles';
import { Link } from '@mui/material';

export const goToFaqTooltip = () => (
  <>
    <FormattedMessage id="settings.user_permissions.roles_description" />
    <Link href="/settings/users/faq" target="_blank">
      <GoToContainer>
        <Text size="small" color={colorUsage.clearText} marginRight={spaceUsages.medium}>
          <FormattedMessage id="settings.user_permissions.learn_more" />
        </Text>
        <IconWrapper color={colorUsage.clearText} size="small" cursor="pointer">
          <GoToSvg />
        </IconWrapper>
      </GoToContainer>
    </Link>
  </>
);
