import { PspAccount } from '../infrastructure/PaymentRepositoryHttp';

type PaymentLinkFeeRules = {
  premium: number;
  standard: number;
};

export const cardOptionsMapping = {
  'International Card': 'international_card',
  'American Express': 'amex',
};

export const mealVoucherMapping = {
  'Edenred': 'edenred',
  'Swile': 'swile',
  'Pluxee (Sodexo)': 'conecs',
  'Up déjeuner': 'conecs',
  'Bimpli': 'conecs',
};

export class PaymentAccount {

  constructor(
    public pspAccounts: Record<string, PspAccount>,
    public digitalFeeRate: number,
    public stripeOnboardingAllowed: boolean,
    public canAcceptPayments: boolean,
    public paymentLinkFeeRules: PaymentLinkFeeRules,
    public digitalFeeCapping?: number,
  ) {}

  hasCheckoutEnabled = (): boolean => this.pspAccounts?.checkout?.active ?? false;

  hasToastEnabled = (): boolean => this.pspAccounts?.toast?.active ?? false;

  mainPSP = (): string | undefined => ['checkout', 'toast', 'stripe'].find((psp) => this.pspAccounts[psp]?.active);

  // this is too complex: Payment API should return a simplier information
  isPaymentMethodEnabled(paymentMethod: string): boolean {
    for (const psp of Object.values(this.pspAccounts)) {
      const active = (psp.active ?? true) && ((psp.onboardingStatus ?? 'ONBOARDED') === 'ONBOARDED');
      if (active && psp.paymentMethods.find((pm) => pm.name === paymentMethod && pm.enabled && ((pm.status ?? 'ONBOARDED') === 'ONBOARDED'))) {
        return true;
      }
    }
    return false;
  }

  isCheckoutMigrationToPlatformDone(): boolean {
    const checkout = this.pspAccounts?.checkout;
    return checkout && checkout.platformOnboardingStatus == 'ONBOARDED';
  }
}

export const defaultPaymentAccount = new PaymentAccount({}, 0, false, false, { premium: 0, standard: 0 });
