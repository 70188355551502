import React from 'react';
import { MenuItemColumn } from './MenuItemColumn';
import { Schedules } from '../../../domain/Schedules';
import MenuScheduleDisplay from './MenuScheduleDisplay';

export type MenuItemScheduleProps = {
  active: boolean;
  schedules: Schedules;
};

const MenuItemSchedule = ({ schedules, active }: MenuItemScheduleProps) => (
  <MenuItemColumn>
    <MenuScheduleDisplay active={active} schedules={schedules} />
  </MenuItemColumn>
);

export default MenuItemSchedule;
