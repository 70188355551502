import React, { useState } from 'react';
import { LoggedUser } from './LoggedUser';
import { UserMenuContent } from './UserMenuContent';
import { MenuDrawer } from './MenuDrawer';

export const MobileUserMenu = () => {
  const [isOpen, updateMenuVisibility] = useState<boolean>(false);

  const onUserClick = () => {
    updateMenuVisibility(!isOpen);
  };

  const onUserMenuClosed = () => {
    updateMenuVisibility(false);
  };

  return (
    <>
      <LoggedUser compact onClick={onUserClick} />
      <MenuDrawer
        open={isOpen}
        onClose={onUserMenuClosed}
      >
        <UserMenuContent onClose={onUserMenuClosed} />
      </MenuDrawer>
    </>
  );
};
