import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import { BoxDetails, CustomMessagesJson, StatusToSendNotificationDataType } from '../../../../domain/BoxDetails';
import { useUpdateBoxDetails } from '../../../../hooks';
import { OrderSentStatusDropdown } from './OrderSentStatusDropdown';
import { CustomerRequiredDataType, VenueDetails } from 'src/ordering/venues/types';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { OrderNotificationMessages } from './OrderNotificationMessages';
import { Switch } from '@mui/material';

type OrderNotificationVenueSettingsProps = {
  venue: VenueDetails;
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

type SettingValues = {
  smsNotifications: boolean;
  emailNotifications: boolean;
  statusToSendNotification: StatusToSendNotificationDataType;
  notifyServiceAtTable: boolean;
};

export const buildCustomMessageJson = (box: Pick<BoxDetails, 'customMessagesJson'>, locale: string, updatedMessage: string): CustomMessagesJson => {
  const customMessagesJson = {
    ...box.customMessagesJson,
    SmsForBoxOrderReady: {
      ...box.customMessagesJson?.SmsForBoxOrderReady,
      [locale]: updatedMessage,
    },
  };
  const und = Object.entries(customMessagesJson.SmsForBoxOrderReady)
    .filter(([l]) => l !== 'und')
    .map((translation) => translation[1])
    .join(' ');

  return {
    ...customMessagesJson,
    SmsForBoxOrderReady: {
      ...customMessagesJson.SmsForBoxOrderReady,
      und: und,
    },
  };
};

// eslint-disable-next-line complexity
export const OrderNotificationBoxSettings = ({
  venue,
  box,
  setBox,
}: OrderNotificationVenueSettingsProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();

  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);

  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const hasCustomerPhoneNumber = venue.customerRequiredData === CustomerRequiredDataType.PHONE_ONLY
    || venue.customerRequiredData === CustomerRequiredDataType.EMAIL_AND_PHONE
    || venue.customerRequiredData === CustomerRequiredDataType.AT_LEAST_EMAIL_OR_PHONE;

  const hasCustomerEmail = venue.customerRequiredData === CustomerRequiredDataType.EMAIL_ONLY
    || venue.customerRequiredData === CustomerRequiredDataType.EMAIL_AND_PHONE
    || venue.customerRequiredData === CustomerRequiredDataType.AT_LEAST_EMAIL_OR_PHONE;

  const currentSettings: SettingValues = {
    smsNotifications: box.smsNotifications,
    emailNotifications: box.emailNotifications,
    statusToSendNotification: box.statusToSendNotification ?? StatusToSendNotificationDataType.SENT_TO_POS,
    notifyServiceAtTable: box.notifyServiceAtTable ?? false,
  };

  const setMessage = async (updatedMessage: string, locale: string) => {
    const customMessagesJson = buildCustomMessageJson(box, locale, updatedMessage);

    makeUpdating(true);
    await updateBoxDetails({
      customMessagesJson,
    });
    setBox({
      ...box,
      customMessagesJson,
    });
    pushNotification(intl.formatMessage({
      id: 'venue.settings.updated',
      defaultMessage: 'venue settings updated',
    }));
    makeUpdating(false);
  };

  const boxUpdated = async (values: SettingValues) => {
    makeUpdating(true);
    await updateBoxDetails({
      ...values,
    });
    setBox({
      ...box,
      ...values,
    });
    pushNotification(intl.formatMessage({
      id: 'venue.settings.updated',
      defaultMessage: 'venue settings updated',
    }));
    makeUpdating(false);
  };

  const updateStatusToSend = async (statusToSendNotification: StatusToSendNotificationDataType) => {
    await boxUpdated({
      ...currentSettings,
      statusToSendNotification,
    });
  };

  const updateSmsNotificationActivation = async (smsNotificationActivation: boolean) => {
    await boxUpdated({
      ...currentSettings,
      smsNotifications: smsNotificationActivation,
    });
  };

  const updateEmailNotificationActivation = async (emailNotificationActivation: boolean) => {
    await boxUpdated({
      ...currentSettings,
      emailNotifications: emailNotificationActivation,
    });
  };

  const updateNotifyServiceAtTable = async (notifyServiceAtTable: boolean) => {
    await boxUpdated({
      ...currentSettings,
      notifyServiceAtTable,
    });
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.settings.orderNotification',
        defaultMessage: 'order notifications',
        description: 'Settings panel to configure the notification related to orders',
      })}
      description={intl.formatMessage({
        id: 'venue.settings.orderNotification.description',
        defaultMessage: 'Send a notification to customers when their order is ready for pickup',
        description: 'Description of the order notification settings',
      })}
      expandable
      disabled={isUpdating}
    >
      <SettingLine
        title={intl.formatMessage({
          id: 'venue.settings.orderNotification.smsNotification',
          defaultMessage: 'sms notification',
        })}
        description={intl.formatMessage({
          id: 'venue.settings.orderNotification.smsNotification.description',
          defaultMessage: 'Notify customers by SMS',
          description: 'explain the role of the sms notification toggle',
        })}
        error={
          !hasCustomerPhoneNumber && currentSettings.smsNotifications
            ? intl.formatMessage({
              id: 'venue.phoneNotAsked',
              defaultMessage:
                "warning: the customer phone number is not asked for this venue, meaning sms won't be sent",
              description: 'error when sms notification are activated but phone number not asked to the customer',
            })
            : undefined
        }
      >
        <Switch
          checked={currentSettings.smsNotifications}
          onChange={(_, v) => updateSmsNotificationActivation(v)}
          disabled={isUpdating}
        />
      </SettingLine>
      <SettingLine
        title={intl.formatMessage({
          id: 'venue.settings.orderNotification.emailNotification',
          defaultMessage: 'email notification',
        })}
        description={intl.formatMessage({
          id: 'venue.settings.orderNotification.emailNotification.description',
          defaultMessage: 'Notify customers by email',
          description: 'explain the role of the email notification toggle',
        })}
        error={
          !hasCustomerEmail && currentSettings.emailNotifications
            ? intl.formatMessage({
              id: 'venue.emailNotAsked',
              defaultMessage: "warning: the customer email is not asked for this venue, meaning email won't be sent",
              description: 'error when email notification are activated but email not asked to the customer',
            })
            : undefined
        }
      >
        <Switch
          checked={currentSettings.emailNotifications}
          onChange={(_, val) => updateEmailNotificationActivation(val)}
          disabled={isUpdating}
        />
      </SettingLine>
      <SettingLine
        title={intl.formatMessage({
          id: 'venue.customSmsSetWhen',
          defaultMessage: 'select when to send the sms',
        })}
        description={intl.formatMessage({
          id: 'venue.customSmsSetWhen.description',
          defaultMessage: 'send the notification when the point of sell receive the order or the order is ready.',
          description: 'describe the different choice proposed to the user',
        })}
      >
        <OrderSentStatusDropdown
          statusToSend={currentSettings.statusToSendNotification}
          disabled={isUpdating}
          onStatusToSendChange={updateStatusToSend}
        />
      </SettingLine>
      <OrderNotificationMessages disabled={isUpdating} venue={venue} box={box} setMessage={setMessage} />
      <SettingLine
        title={intl.formatMessage({
          id: 'venue.settings.orderNotification.notifyServiceAtTable',
          defaultMessage: 'Notify also for at table service',
        })}
        description={intl.formatMessage({
          id: 'venue.settings.orderNotification.notifyServiceAtTable.description',
          defaultMessage:
            'By default, notifications are not sent for at table service. Check this option to send notifications also for at table service',
        })}
      >
        <Switch
          checked={currentSettings.notifyServiceAtTable}
          onChange={(_, v) => updateNotifyServiceAtTable(v)}
          disabled={isUpdating}
        />
      </SettingLine>
    </SettingPanel>
  );
};
