import React from 'react';
import { PayoutsWithContext } from './PayoutsWithContext';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const PayoutPanel = () => {
  const business = useCurrentBusinessOrThrow();
  const venueIsNoPos = useBusinessIsNoPos();
  if (venueIsNoPos) return <NoPos />;

  return (
    <>
      <PayoutsWithContext businessId={business.id} timezone={business.timezone} />
    </>
  );
};
