// eslint-disable-next-line @typescript-eslint/no-redeclare
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { getStringAsCountryCode } from 'src/utils/GetStringAsCountryCode';
import { businessNumberLabelForCountry, countryHasABusinessNumber, countryHasRcsNumber, taxIdLabelForCountry } from './utils';
import { ReadonlyFieldWithLabel } from '../components/ReadonlyFieldWithLabel';
import { Business } from 'src/business/domain/Business';
import { Card, CardContent, Typography } from '@mui/material';

interface TaxInformationPanelProps {
  business: Business;
}

export function TaxInformationPanel({ business }: TaxInformationPanelProps) {
  const intl = useIntl();
  const venueCountryCode = getStringAsCountryCode(business.address?.countryCode);

  const labelForCountry = intl.formatMessage({ id: taxIdLabelForCountry(venueCountryCode) });


  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage id="settings.payments.tax_information" />
        </Typography>
        {/* VAT registration number for FR; EIN for US */}
        <ReadonlyFieldWithLabel
          label={labelForCountry}
          value={business.metadata.TAX_ID}
        />

        {/* SIRET for FR; DUNS for US */}
        {countryHasABusinessNumber(venueCountryCode) && (
          <ReadonlyFieldWithLabel
            label={businessNumberLabelForCountry(venueCountryCode, intl)}
            value={business.metadata.SIRET}
          />
        )}
        {/* RCS */}
        {countryHasRcsNumber(venueCountryCode) && (
          <ReadonlyFieldWithLabel
            label={intl.formatMessage({ id: 'settings.payments.FR_secondary_business_number' })}
            value={business.metadata.RCS}
          />
        )}
      </CardContent>
    </Card>
  );
}

export default TaxInformationPanel;
