import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { Alert, Paper } from '@mui/material';

export const SundayPoolingError = () => (
  <Paper>
    <Alert severity="error">
      <FormattedMessage id="tips.sunday_pooling.data.error" />
    </Alert>
  </Paper>
);
