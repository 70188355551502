import React, { useState } from 'react';
import { AllWaiters, Waiter } from '../domain/AllWaiters';
import { Alert, Box, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import styled from 'styled-components';
import { WaiterActions } from './WaiterActions';
import { formatPhoneNumber } from './formatPhoneNumber';
import { Outlet } from 'react-router';
import { Business } from 'src/business/domain/Business';
import { EnrollmentId } from 'src/business/domain/Enrollment';

interface Props {
  business: Business;
  allWaiters: AllWaiters;
  directTippingConfiguration: Record<EnrollmentId, boolean>;
}

const CellWithIcon = styled(TableCell)`
  padding: 10px;
  width: 500px;
`;

const DisabledLabel = styled(Typography)`
  color: #70707b;
`;

export const WaitersTable: React.FC<Props> = ({ allWaiters, business, directTippingConfiguration }: Props) => {
  const [selectedRow, setSelectedRow] = useState<string | undefined>(undefined);
  const [showDisabled, setShowDisabled] = useState<boolean>(false);

  const displayRate = (rate: number | undefined) => {
    if (rate === undefined || rate === 0) {
      return (
        <DisabledLabel>
          <FormattedMessage id="direct_tipping.disabled" />
        </DisabledLabel>
      );
    } else {
      return `${rate / 100}%`;
    }
  };

  const enabledOnPat = () => business.patEnrollment && directTippingConfiguration[business.patEnrollment.id];
  const enabledOnPdq = () => business.pdqEnrollment && directTippingConfiguration[business.pdqEnrollment.id];

  const isEnabled = (waiter: Waiter) => {
    if (showDisabled) {
      return true;
    }
    return (
      (enabledOnPat() && waiter.rates[business.patEnrollment!!.id] !== 0) ||
      (enabledOnPdq() && waiter.rates[business.pdqEnrollment!!.id] !== 0)
    );
  };

  return (
    <>
      <Box pb={1} mb={2}>
        <Typography variant="h4">
          <FormattedMessage id="direct_tipping.my_staff" />
        </Typography>
        <Typography variant="body2" sx={{ color: 'gray' }}>
          <FormattedMessage id="direct_tipping.my_staff_description" />
        </Typography>
        <Stack direction="row" spacing={2} mt={2}>
          <Switch checked={showDisabled} value={showDisabled} onChange={() => setShowDisabled(!showDisabled)} />
          <Typography>
            <FormattedMessage id="direct_tipping.show_disabled" />
          </Typography>
        </Stack>
      </Box>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                border: 'none',
                pb: 1,
              }}
            >
              <Typography variant="body1" sx={{ color: 'gray' }}>
                <FormattedMessage id="direct_tipping.name_in_pos" defaultMessage="Name in the POS" />
              </Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: 'gray',
                border: 'none',
                pb: 1,
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="direct_tipping.phone_number" defaultMessage="Phone number" />
              </Typography>
            </TableCell>
            <TableCell
              align="left"
              colSpan={enabledOnPat() && enabledOnPdq() ? 2 : 1}
              sx={{
                color: 'gray',
                border: 'none',
                pb: 1,
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="direct_tipping.rate" defaultMessage="Instant tipping" />
              </Typography>
            </TableCell>
            <TableCell
              align="left"
              sx={{
                color: 'gray',
                border: 'none',
                pb: 1,
              }}
            >
              <Typography variant="body1">
                <FormattedMessage id="direct_tipping.actions" defaultMessage="Actions" />
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} />
            {enabledOnPat() && <TableCell sx={{ color: 'gray' }}>QR</TableCell>}
            {enabledOnPdq() && <TableCell sx={{ color: 'gray' }}>PDQ</TableCell>}
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {allWaiters.waitersWithServerAccountsRequired
            .filter((name) => name !== '')
            .map((pendingWaiterName) => (
              <TableRow key={pendingWaiterName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{pendingWaiterName}</TableCell>
                <CellWithIcon align="left">
                  <Box display={'flex'}>
                    <Alert severity="warning">
                      <FormattedMessage
                        id="direct_tipping.server_account_required"
                        defaultMessage="Server account required"
                      />
                    </Alert>
                  </Box>
                </CellWithIcon>
                {enabledOnPat() && <TableCell>-</TableCell>}
                {enabledOnPdq() && <TableCell>-</TableCell>}
                <TableCell></TableCell>
              </TableRow>
            ))}

          {allWaiters.waiters
            .filter((waiter) => isEnabled(waiter))
            .map((waiter) => (
              <TableRow
                key={waiter.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                onMouseEnter={() => setSelectedRow(waiter.waiterId)}
                onMouseLeave={() => setSelectedRow(undefined)}
              >
                <TableCell>{waiter.name}</TableCell>
                <TableCell align="left">
                  <Box display={'flex'} mt={'5px'}>
                    <Box mr={'5px'}>
                      <PhoneOutlinedIcon />
                    </Box>
                    {formatPhoneNumber(waiter.phoneNumber)}
                  </Box>
                </TableCell>
                {enabledOnPat() && <TableCell>{displayRate(waiter.rates[business.patEnrollment!!.id])}</TableCell>}
                {enabledOnPdq() && <TableCell>{displayRate(waiter.rates[business.pdqEnrollment!!.id])}</TableCell>}
                <CellWithIcon sx={{ color: 'gray' }}>
                  <WaiterActions
                    waiterId={waiter.waiterId}
                    businessId={business.id}
                    isHover={waiter.waiterId === selectedRow}
                  />
                </CellWithIcon>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Outlet />
    </>
  );
};
