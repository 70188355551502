import { Button, ButtonsGroup, colorPalette, Panel, spaceUsages } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment/moment';
import { ConfigureShiftHoursModal } from './ConfigureShiftHoursModal';
import { NonUSReportingView } from './ReportingView/NonUSReportingView';
import { useReporting } from './useReporting';
import { DateRange } from '../domain/DateRange';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { CircularProgress, Stack } from '@mui/material';

const FiltersWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  white-space: nowrap;
  padding: ${spaceUsages.mediumLarge} ${spaceUsages.mediumLarge};
`;

const LoaderWrapper = styled(Panel)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  margin-top: ${spaceUsages.mediumLarge};
  ${(props) =>
    !props.forWaitersPanel &&
    css`
      background-color: ${colorPalette.grey200};
      border: 1px solid ${colorPalette.grey300};
    `}
`;

export const ReportingPanel = () => {
  const { formatMessage } = useIntl();
  const business = useCurrentBusinessOrThrow();
  const {
    reportingDigest,
    reportingDateRange,
    setSelectedRange,
    accountingRanges,
    setReportingDateRange,
    saveAccountingShifts,
    hasLoadedReportingDigest,
  } = useReporting();
  const [isShiftHoursModalOpened, setShiftHoursModalOpened] = useState(false);
  const [customRangeLabel, setCustomRangeLabel] = useState<string>(formatMessage({ id: 'accounting.custom_range' }));

  const displayDateRange = (range: DateRange) =>
    `${moment(range.startDate).format('LT')} - ${moment(range.endDate).format('LT')}`;

  const formatDate = (date: Moment): String => date.format('L').substring(0, 5);

  const onSelectedCustomRange = (ranges: Date[]) => {
    if (ranges.length === 2) {
      setReportingDateRange(new DateRange(ranges[0], ranges[1]));
      const startMoment = moment(ranges[0]);
      const endMoment = moment(ranges[1]);
      setCustomRangeLabel(`
            ${formatDate(startMoment)} - ${formatDate(endMoment)}
            ${startMoment.format('LT')}-${endMoment.format('LT')}
      `);
    }
  };
  const dateFilterButtons: any = [
    { id: 'today', label: `${formatMessage({ id: 'accounting.today' })} ${displayDateRange(accountingRanges.today)}` },
    { id: 'lunch', label: `${formatMessage({ id: 'accounting.lunch' })} ${displayDateRange(accountingRanges.lunch)}` },
    {
      id: 'dinner',
      label: `${formatMessage({ id: 'accounting.dinner' })} ${displayDateRange(accountingRanges.dinner)}`,
    },
    {
      id: 'yesterday',
      label: `${formatMessage({ id: 'accounting.yesterday' })} ${displayDateRange(accountingRanges.today.yesterday())}`,
    },
    {
      id: 'custom_range',
      label: customRangeLabel,
      type: 'datetime',
      onDateRangeSelection: onSelectedCustomRange,
    },
  ];

  const venueIsNoPos = useBusinessIsNoPos();
  if (venueIsNoPos) return <NoPos />;

  const onChangeDateRange = (buttonKey: string) => {
    if (
      buttonKey === 'today' ||
      buttonKey === 'yesterday' ||
      buttonKey === 'lunch' ||
      buttonKey === 'dinner' ||
      buttonKey === 'custom_range'
    ) {
      setSelectedRange(buttonKey);
    }
  };

  const onConfigureHoursShiftChange = async (
    lunchStart: string,
    lunchEnd: string,
    dinnerStart: string,
    dinnerEnd: string,
  ) => {
    await saveAccountingShifts(lunchStart, lunchEnd, dinnerStart, dinnerEnd);
    setShiftHoursModalOpened(false);
  };

  const onConfigureShiftHoursOpen = () => {
    setShiftHoursModalOpened(true);
  };

  const isPayAtTableVenue = business.patEnrollment !== undefined;

  return (
    <>
      <Stack direction={'column'} gap={5}>
        <FiltersWrapper>
          <ButtonsGroup
            buttons={dateFilterButtons}
            initialSelectedButton={dateFilterButtons[0].id}
            onChange={onChangeDateRange}
          />
          <Button size="small" variant="secondary" onClick={onConfigureShiftHoursOpen}>
            <FormattedMessage id="accounting.configure_service_hours" defaultMessage="Configure service hours" />
          </Button>
        </FiltersWrapper>
        {isPayAtTableVenue && (
          <ConfigureShiftHoursModal
            isOpened={isShiftHoursModalOpened}
            onClose={() => setShiftHoursModalOpened(false)}
            onSave={onConfigureHoursShiftChange}
            accountingRanges={accountingRanges}
          />
        )}
        {!hasLoadedReportingDigest && (
          <LoaderWrapper>
            <CircularProgress size="large" />
          </LoaderWrapper>
        )}

        {hasLoadedReportingDigest && reportingDigest && (
          <NonUSReportingView
            reportingDigest={reportingDigest}
            selectedBusiness={business}
            reportingDateRange={reportingDateRange}
          />
        )}
      </Stack>
    </>
  );
};
