import React, { ReactNode } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Drawer from '../Drawer';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';
import Button, { ButtonVariant } from '../Button';

interface Props {
  visible: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  children: ReactNode;
}

const ConfirmationContent = styled.div`
  padding: 0 ${getSpacing(3)};
`;

const ConfirmHeader = styled.div`
  border-bottom: 1px solid ${colorPalette.grey300};
`;

const ConfirmLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${getSpacing(3)} 0 ${getSpacing(4)};
  ${textStyles.big}
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${getSpacing(2)};
`;

const ConfirmationDrawer: React.FC<Props> = ({
  visible, onCancel, onConfirm, children,
}) => (
  <Drawer visible={visible}>
    {visible && (
    <ConfirmationContent>
      <ConfirmHeader>{children}</ConfirmHeader>
      <ConfirmLine>
        <FormattedMessage id="confirm.areYouSure" defaultMessage="are you sure?" />
        <StyledButton
          variant={ButtonVariant.PRIMARY}
          onClick={onConfirm}
          data-testid="confirmation-drawer-confirm-button"
        >
          <FormattedMessage id="confirmation.yes" defaultMessage="yes" />
        </StyledButton>
        <StyledButton
          variant={ButtonVariant.LIGHT}
          onClick={onCancel}
          data-testid="confirmation-drawer-cancel-button"
        >
          <FormattedMessage id="confirmation.no" defaultMessage="no" />
        </StyledButton>
      </ConfirmLine>
    </ConfirmationContent>
    )}
  </Drawer>
);

export default ConfirmationDrawer;
