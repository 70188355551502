import React from 'react';
import { Box, useTheme } from '@mui/material';

export const PreviewContainer = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Box width={'100%'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-around'}
         bgcolor={theme.palette.background.default}
         paddingTop={5} paddingBottom={5} borderRadius={4} gap={2}>
      {children}
    </Box>
  );
};
