import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductRepositoryHttp } from '../../repositories/ProductRepositoryHttp';
import { CreateProductDto, UpdateProductDto } from '../../repositories/ProductDto';
import { QUERYKEY_MENU_PRODUCT } from '../../queries/product/getProductByIdQuery';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { QUERYKEY_MENU_PRODUCTS } from '../../queries/product/getAllProductByBusinessIdQuery';
import { BusinessId } from 'src/business/domain/Business';

export const useProductMutation = (businessId: BusinessId, productId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (updateProduct: UpdateProductDto) =>
      productRepository.updateProduct(businessId, productId, updateProduct),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useProductPictureMutation = (businessId: BusinessId, productId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (base64Picture: string) =>
      fetch(base64Picture)
        .then((response) => response.arrayBuffer())
        .then((buffer) => productRepository.updateProductPicture(businessId, productId, buffer)),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
    },
  });
};

export const useDeleteProductPictureMutation = (businessId: BusinessId, productId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => productRepository.deleteProductPicture(businessId, productId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
    },
  });
};

export const useCopyProductMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productId: string) => productRepository.copyProduct(businessId, productId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useSnoozeProductMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productSku: string) => productRepository.snoozeProduct(businessId, productSku),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useUnSnoozeProductMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productSku: string) => productRepository.unSnoozeProduct(businessId, productSku),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useDeleteProductMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productId: string) => productRepository.deleteProduct(businessId, productId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useCreateProductMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (createDto: CreateProductDto) => productRepository.createProduct(businessId, createDto),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
    },
  });
};

export const useRevertToPartnerPictureProductMutation = (businessId: BusinessId, productId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => productRepository.revertToPartnerPicture(businessId, productId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
    },
  });
};

export const useRevertToPartnerSubProductsMutation = (businessId: BusinessId, productId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepository = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => productRepository.revertToPartnerSubProducts(businessId, productId),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] }),
  });
};
