import { Chip } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const IsCompleteBadge = ({ isComplete }: { isComplete: boolean }) => {
  const intl = useIntl();
  const messageId = isComplete
    ? 'settings.payments.checkout.onboarding.representatives.status.complete'
    : 'settings.payments.checkout.onboarding.representatives.status.incomplete';
  return <Chip color={isComplete ? 'success' : 'warning'} label={intl.formatMessage({ id: messageId })} />;
};
