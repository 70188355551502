import { Select, Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@sundayapp/b2b-react-component-library';

import { InsightPeriod } from './InsightPeriod';
import { DateRange } from '../domain/DateRange';
import { AvailableRange, toReviewsDateRange } from './domain/AvailableRange';
import { ampli } from 'src/ampli';
import { Instant } from '../../Instant';

const trackDateRangeSelection = (customDateRange: DateRange) => {
  ampli.guestExperienceDateRangeFilterChanged({
    numberOfDaysInRange: customDateRange.numberOfDays(),
    daysSinceToday: Instant.fromEpoch(customDateRange.startDate()
      .getTime())
      .deltaInDays(Instant.fromEpoch(Date.now())),
  });
};

export const GuestExperienceHeader = ({
  dateRange,
  onDateRangeChange,
}: { dateRange: DateRange, onDateRangeChange: (dateRange: DateRange) => void }) => {
  const intl = useIntl();
  const [datePickerOpened, setDatePickerOpened] = useState<boolean>(false);

  const [selectedRange, selectRange] = useState<AvailableRange>('last 7 days');

  const onRangeChange = (rangeType: AvailableRange) => {
    if (rangeType === 'custom') {
      return;
    }
    selectRange(rangeType);
    const fixedDateRange = toReviewsDateRange(rangeType);
    onDateRangeChange(fixedDateRange);
    trackDateRangeSelection(fixedDateRange);
  };

  const onCustomDateRangeSelected = (range: Date[]) => {
    selectRange('custom');
    const customDateRange = DateRange.fromDates(range[0], range[1]);
    onDateRangeChange(customDateRange);
    setDatePickerOpened(false);
    trackDateRangeSelection(customDateRange);
  };

  return (

    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
      <Select
        value={selectedRange}
        onChange={(event) => onRangeChange(event.target.value as AvailableRange)}
      >
        <MenuItem value="yesterday">
          <Typography>
            <FormattedMessage
              id="review.guest_experience.filter.yesterday"
            />
          </Typography>
        </MenuItem>
        <MenuItem value="last 7 days">
          <Typography>
            <FormattedMessage
              id="review.guest_experience.filter.last7days"
            />
          </Typography>
        </MenuItem>
        <MenuItem value="last 30 days">
          <Typography>
            <FormattedMessage
              id="review.guest_experience.filter.last30days"
            />
          </Typography>
        </MenuItem>
        <MenuItem
          value="custom"
          onClick={() => {
            setDatePickerOpened(true);
          }}
        >
          <Typography>
            <FormattedMessage
              id="review.guest_experience.filter.custom"
            />
          </Typography>
        </MenuItem>
      </Select>
      <InsightPeriod dateRange={dateRange} />
      {datePickerOpened && (
        <DateTimePicker
          onApply={onCustomDateRangeSelected}
          onClose={() => {
            setDatePickerOpened(false);
          }}
          value={[dateRange.startDate(), dateRange.endDate()]}
          applyButtonLabel={intl.formatMessage({ id: 'datepicker.apply' })}
          cancelButtonLabel={intl.formatMessage({ id: 'datepicker.cancel' })}
          startTimeInputLabel={intl.formatMessage({ id: 'datepicker.start-time' })}
          endTimeInputLabel={intl.formatMessage({ id: 'datepicker.end-time' })}
        />
      )}
    </Stack>
  );
};
