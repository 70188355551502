// eslint-disable-next-line @typescript-eslint/no-redeclare
import { isNaN } from 'lodash';

export const computeEvolutionPercentage = (currentValue: number, previousValue: number): number => {
  const value = Math.trunc(((currentValue - previousValue) / previousValue) * 100);
  if (isNaN(value)) {
    return 0;
  }
  return value;
};
