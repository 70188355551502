import { Box, Switch, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import { FC, useLayoutEffect, useState } from 'react';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { Product } from '../../../domain/Product';
import Divider from '@mui/material/Divider';
import { themeV5 } from 'src/app/theme/ThemeV5';

type Props = {
  product: Product;
  productUpdate: UpdateProductDto;
  setProductUpdate: (productDto: UpdateProductDto) => void;
};

export const IsAvailableForOrderSwitch: FC<Props> = ({ product, productUpdate, setProductUpdate }) => {
  const [isAvailableForOrder, setIsAvailableForOrder] = useState(product.isAvailableForOrder);

  useLayoutEffect(() => {
    setIsAvailableForOrder(product.isAvailableForOrder);
  }, [product.isAvailableForOrder]);

  return (<>
    <Divider />
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Typography variant="body1">
          <FormattedMessage id="menus.product.details.quickOrder" />
        </Typography>
        <Typography color={themeV5.palette.text.secondary} variant="subtitle2">
          <FormattedMessage id="menus.product.details.is_available_for_order.subtitle" />
        </Typography>
      </Box>
      {!product.isEligibleForOrder ? (
        <Typography color={themeV5.palette.text.secondary} variant="subtitle2">
          <FormattedMessage id="menus.product.details.eligible_for_order.not_eligible" />
        </Typography>
      ) : (
        <Switch
          checked={isAvailableForOrder}
          onChange={(event) => {
            setProductUpdate({ ...productUpdate, isAvailableForOrder: event.target.checked });
            setIsAvailableForOrder(event.target.checked);
          }}
        />
      )}
    </Box>
  </>);
};
