import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';

import { revenuesDatePresets, revenueTabDatePresets } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Money, money, MoneyView, sumMoneys } from '@sundayapp/web-money';
import { palette } from 'src/organization-report/design/palette';
import { isAllowed, isSourceFilter, sourceFilters, SourceFilterValue, TipsView } from '../domain/Tips';
import { CoinsIconSvg } from '../components/CoinsIconSvg';
import { HttpTipsRepository } from '../infrastructure/HttpTipsRepository';
import axios from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { NoTips } from './NoTips';
import { extractTimeFrameFilterFromURLParams, TimeFrameFilter } from 'src/payments/components/TimeFrameFilter';
import { ServiceType } from 'src/domain/venue/ServiceType';
import QrCodesSvg from 'src/app/component/icons/QrCodesSvg';
import PdqSvg from 'src/app/component/icons/PdqSvg';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import InfoIcon from '@mui/icons-material/Info';

function extractFilters(search: string): SourceFilterValue | undefined {
  const params = new URLSearchParams(search);
  const value = params.get('payment-method') || undefined;
  return isSourceFilter(value) ? value : undefined;
}

const TipsCollectedCard = (props: { amount: Money; title: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Card sx={{ width: '100%' }}>
      <CardHeader
        avatar={<CoinsIconSvg />}
        title={
          <Box display={'flex'} alignItems={'center'} gap={1}>
            <Typography variant="subtitle2" color={theme.palette.text.secondary}>
              {props.title}
            </Typography>
            <Tooltip title={<FormattedMessage id={'tips.summary.card.tooltip'} />}>
              <InfoIcon />
            </Tooltip>
          </Box>
        }
      />
      <CardContent>
        <Typography variant="h4">
          <MoneyView value={props.amount} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export const TipsCollection = () => {
  const navigate = useNavigate();
  const business = useCurrentBusinessOrThrow();
  const location = useLocation();
  const datePresets = revenuesDatePresets();
  const period = useMemo(
    () => extractTimeFrameFilterFromURLParams(location.search, datePresets, revenueTabDatePresets.TODAY),
    [location],
  );
  const defaultSourceFilter: SourceFilterValue = (
    business.patEnrollment || business.oapEnrollment ? sourceFilters.QR_CODE.value : sourceFilters.PDQ.value
  ) as SourceFilterValue;
  const sourceFilter: SourceFilterValue = useMemo<SourceFilterValue>(
    () => extractFilters(location.search) ?? defaultSourceFilter,
    [location],
  );

  const onSourceFilterChanged = (event: React.MouseEvent<HTMLElement>, newPaymentMethodFilter: string) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('payment-method', newPaymentMethodFilter);
    location.search = urlParams.toString();
    return navigate(location);
  };

  const [tipsView, setTipsView] = useState<TipsView | null>(null);
  const configuration = ConfigurationLoader.load();
  const repository = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);

  useEffect(() => {
    repository
      .findAllBy(business.id, sourceFilter, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((summaries) =>
        setTipsView({
          totalTips: summaries.reduce(
            (acc, summary) =>
              sumMoneys(
                acc,
                sumMoneys(summary.posPooledAmount, summary.sundayPooledTipsAmount, summary.directTipsAmount),
              ),
            money(0, business.currency),
          ),
          totalDirectTips: summaries.reduce(
            (acc, summary) => sumMoneys(acc, summary.directTipsAmount),
            money(0, business.currency),
          ),
          totalPooledTips: summaries.reduce(
            (acc, summary) => sumMoneys(acc, summary.posPooledAmount, summary.sundayPooledTipsAmount),
            money(0, business.currency),
          ),
          tips: summaries,
        }),
      );
  }, [business.id, sourceFilter, period]);

  if (!tipsView) {
    return 'loading';
  }

  const hasOnlyPosPooledTips = tipsView.tips.every(
    (tips) => tips.posPooledAmount.amount > 0 && tips.directTipsAmount.amount === 0,
  );

  const availableSourceFilters = Object.values(sourceFilters).filter(isAllowed(business));

  const getIcon = (serviceType: ServiceType, selected: boolean) => {
    const color = selected ? 'black' : 'grey';
    return serviceType === ServiceType.PAY_AT_TABLE || ServiceType.ORDER_AND_PAY ? (
      <QrCodesSvg color={color} />
    ) : (
      <PdqSvg color={color} />
    );
  };

  return (
    <Box display="flex" gap="24px" flexDirection="column">
      <Grid2 container rowGap={2} alignItems="center" justifyContent="space-between" display="flex">
        <Grid2
          size={{
            xs: 12,
            md: 6,
          }}
        >
          <TimeFrameFilter datePresets={datePresets} period={period} />
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <ToggleButtonGroup
            fullWidth
            value={sourceFilter}
            exclusive
            onChange={onSourceFilterChanged}
            disabled={availableSourceFilters.length <= 1}
          >
            {availableSourceFilters.map((source) => (
              <ToggleButton key={source.value} value={source.value}>
                <Box mt={'4px'} mr={1}>
                  {getIcon(source.displayFor, sourceFilter === source.value)}
                </Box>
                <FormattedMessage id={source.translations} />
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Grid2>
      </Grid2>
      <Grid2 container alignItems="center" rowSpacing={3} columnSpacing={3} display="flex">
        <Grid2
          display="flex"
          size={{
            xs: 12,
            md: 4,
          }}
          minHeight="175px"
        >
          <TipsCollectedCard
            title={<FormattedMessage id="tips.summary.card.total_collected" />}
            amount={tipsView.totalTips}
          />
        </Grid2>

        {!hasOnlyPosPooledTips && (
          <>
            <Grid2
              display="flex"
              size={{
                xs: 12,
                md: 4,
              }}
              minHeight="175px"
            >
              <TipsCollectedCard
                title={<FormattedMessage id="tips.summary.card.total_direct" />}
                amount={tipsView.totalDirectTips}
              />
            </Grid2>
            <Grid2
              display="flex"
              size={{
                xs: 12,
                md: 4,
              }}
              minHeight="175px"
            >
              <TipsCollectedCard
                amount={tipsView.totalPooledTips}
                title={<FormattedMessage id="tips.summary.card.total_pooled" />}
              />
            </Grid2>
          </>
        )}
      </Grid2>

      {tipsView.tips.length === 0 && (
        <TableContainer sx={{ borderRadius: '10px', padding: '20px' }} component={Paper}>
          <NoTips />
        </TableContainer>
      )}

      {tipsView.tips.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={1} sx={{ border: 'none' }}>
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  <FormattedMessage id="tips.summary.table.waiter.title" />
                </Typography>
              </TableCell>

              <TableCell colSpan={3} sx={{ border: 'none' }}>
                <Typography variant="subtitle2" color={palette.neutral.grey500}>
                  <FormattedMessage id="tips.summary.table.tips.title" />
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="caption" color={palette.neutral.grey400}>
                  <FormattedMessage id="tips.summary.table.column.waiter.header" />
                </Typography>
              </TableCell>

              <TableCell align="left">
                <Typography variant="caption" color={palette.neutral.grey400}>
                  <FormattedMessage id="tips.summary.table.column.tips.total.header" />
                </Typography>
              </TableCell>
              {!hasOnlyPosPooledTips && (
                <>
                  <TableCell align="left">
                    <Typography variant="caption" color={palette.neutral.grey400}>
                      <FormattedMessage id="tips.summary.table.column.tips.pooled_tip.header" />
                    </Typography>
                  </TableCell>

                  <TableCell align="left">
                    <Typography variant="caption" color={palette.neutral.grey400}>
                      <FormattedMessage id="tips.summary.table.column.tips.direct_tip.header" />
                    </Typography>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {tipsView.tips.map((tips) => (
              <TableRow
                key={tips.waiterName}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  color: 'inherit',
                }}
              >
                <TableCell>
                  <Typography variant="subtitle2">
                    {tips.waiterName || <FormattedMessage id="tips.summary.table.waiter.no_waiter_associated" />}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="subtitle2">
                    <MoneyView
                      value={sumMoneys(tips.directTipsAmount, tips.posPooledAmount, tips.sundayPooledTipsAmount)}
                    />
                  </Typography>
                </TableCell>
                {!hasOnlyPosPooledTips && (
                  <>
                    <TableCell align="left">
                      <Typography variant="subtitle2">
                        <MoneyView value={sumMoneys(tips.posPooledAmount, tips.sundayPooledTipsAmount)} />
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="subtitle2">
                        <MoneyView value={tips.directTipsAmount} />
                      </Typography>
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
