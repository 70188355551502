import { useIntl } from 'src/app/i18n/TypedIntl';
import { Link, Typography } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const LoyaltyDocumentationLink = ({ link, pos, loyaltyProvider }: {
  link: string,
  pos: string,
  loyaltyProvider: string
}) => {
  const { formatMessage } = useIntl();

  return <Typography>
    <FormattedMessage id="loyalty.onboarding.ops_documentation" values={{
      link: (<Link
        href={link}
        target="_blank"
      >
        {formatMessage({ id: 'loyalty.onboarding.ops_documentation.link', defaultMessage: 'link' })}
      </Link>),
      pos: pos,
      loyalty_provider: loyaltyProvider,
    }} />
  </Typography>;
};
