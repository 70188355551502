import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';

export const useSaveDescriptionMutation = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();
  return useMutation({
    mutationFn: ({
      enrollmentId,
      description,
    }: {
      enrollmentId: EnrollmentId;
      description: string;
    }) => customizationRepository.saveDescription(enrollmentId, description),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION] }),
  });
};
