import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { AllergenRepositoryHttp } from '../../repositories/allergen/AllergenRepositoryHttp';
import { QUERYKEY_MENU_ALLERGEN_TRANSLATIONS } from '../../queries/allergen/getAllAllergenTranslationsByBusinessIdQuery';
import { BusinessId } from 'src/business/domain/Business';
import { AllergenTranslationsMap } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/EditAllergenModal';

export const useAllergenTranslationsMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);

  return useMutation({
    mutationFn: (a: { allergenId: string; translations: AllergenTranslationsMap }) => {
      const translations = Object.keys(a.translations).map((key) => ({ language: key, name: a.translations[key] }));
      return Promise.all(
        translations.map(({ language, name }) =>
          allergenRepository.updateTranslation(businessId, a.allergenId, language, name),
        ),
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_ALLERGEN_TRANSLATIONS] });
    },
  });
};
