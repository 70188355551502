/* eslint-disable max-lines */
import { centsToMoney, Money, MoneyView, multiplyMoney, sumMoneys } from '@sundayapp/web-money';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../common/components/Button';
import ConfirmationDrawer from '../../../common/components/ConfirmationDrawer';
import { useRefreshDrawerHeight } from '../../../common/components/Drawer';
import ErrorContainer from '../../../common/components/ErrorContainer';
import { DeleteIcon, PlusIcon } from '../../../common/components/Icons';
import RoundButton from '../../../common/components/RoundButton';
import Spinner from '../../../common/components/Spinner';
import { Order, OrderItem, OrderPaymentStatus } from '../../types';
import { PaymentSummary as PaymentSummaryType } from '../../../payment/types';
import OrderItemForRefund from '../OrderItemForRefund';
import PaymentSummary from '../PaymentSummary';
import TotalWithTaxSummary from '../TotalWithTaxSummary/TotalWithTaxSummary';

import { useRefundPayment, useUnselectOrderForRefund } from '../../hooks';
import { computeItemTotalPrice } from '../../services';
import Input from '../../../common/components/Input';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';

interface Props {
  order: Order;
  payment: PaymentSummaryType;
  orderRefunded: (order: Order, status: OrderPaymentStatus) => void;
}

const RefundDrawerContainer = styled.div`
  padding: 0 ${getSpacing(3)};
`;

const RefundDrawerHeader = styled.div`
  height: ${getSpacing(10)};
  box-sizing: border-box;
  border-bottom: 1px solid ${colorPalette.placeholderColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 21px;
  line-height: 24px;
  letter-spacing: -0.03em;
`;

const OrderItemsContainer = styled.div`
  border-bottom: 1px solid ${colorPalette.placeholderColor};
`;

const TotalToRefundContainer = styled.div`
  padding: ${getSpacing(3)} 0 ${getSpacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${textStyles.big}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${getSpacing(3)};
`;

const CustomAmountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${colorPalette.grey600};
  height: ${getSpacing(10)};
  border-bottom: 1px solid ${colorPalette.placeholderColor};
  ${textStyles.default}
`;

const StyledInput = styled(Input)`
  max-width: ${getSpacing(10)};
  border: 0;

  input {
    padding-left: 0;
    padding-right: 0;
    text-align: right;
    ${textStyles.default}
    color: ${colorPalette.grey600};
  }
`;

const CustomAmountValue = styled.div`
  white-space: nowrap;
  ${textStyles.default}
  color: ${colorPalette.grey600};
`;

export const TotalPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${getSpacing(3)} 0 ${getSpacing(4)};
  ${textStyles.big}
`;

const RefundDrawerContent: React.FC<Props> = ({ order, payment, orderRefunded }) => {
  const [displayConfirmDrawer, setDisplayConfirmDrawer] = useState<boolean>(false);
  const unselectOrderForRefund = useUnselectOrderForRefund();
  const [refundState, refundPayment] = useRefundPayment();
  const refreshDrawerHeight = useRefreshDrawerHeight();
  const [customAmount, setCustomAmount] = useState<string>('');
  const [customAmountValue, setCustomAmountValue] = useState<Money>();
  const [editingCustom, setEditingCustom] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<Record<number, number>>({});

  const setSelectedItemForOrder = useCallback(
    (orderItemIndex: number, quantity: number) => {
      setSelectedItems((state) => ({
        ...state,
        [orderItemIndex]: quantity,
      }));
    },
    [setSelectedItems],
  );
  useLayoutEffect(refreshDrawerHeight, [order, refreshDrawerHeight]);
  const renderOrderItems = useCallback(
    (orderItem: OrderItem, index: number) => (
      <OrderItemForRefund
        key={index}
        item={orderItem}
        selectedQuantity={selectedItems[index] || 0}
        setSelectedQuantity={(quantity: number) => setSelectedItemForOrder(index, quantity)}
      />
    ),
    [selectedItems, setSelectedItemForOrder],
  );

  const totalRefundByItems: Money = useMemo(() => {
    const zero: Money = { amount: 0, currency: order.totalPrice.currency };
    return order.items.reduce((total: Money, item, index) => {
      const quantity = selectedItems[index];
      if (quantity) {
        return sumMoneys(total, multiplyMoney(computeItemTotalPrice(item), quantity));
      }
      return total;
    }, zero);
  }, [order, selectedItems]);

  useEffect(() => {
    if (customAmount === '') {
      setCustomAmountValue(undefined);
      return;
    }
    const value = parseFloat(customAmount);
    if (!Number.isNaN(value)) {
      setCustomAmountValue({
        amount: centsToMoney(Math.round(value * 100)),
        currency: order.totalPrice.currency,
      });
    }
  }, [customAmount, order.totalPrice.currency]);

  const totalToRefund = useMemo(() => {
    if (customAmountValue && customAmountValue.amount > 0) return customAmountValue;
    if (totalRefundByItems.amount > 0) return totalRefundByItems;
    return order.totalPaid;
  }, [totalRefundByItems, order, customAmountValue]);

  const toggleConfirmRefundDrawer = useCallback(
    () => setDisplayConfirmDrawer(!displayConfirmDrawer),
    [setDisplayConfirmDrawer, displayConfirmDrawer],
  );

  const processRefund = useCallback(async () => {
    setDisplayConfirmDrawer(false);
    await refundPayment(payment.id, totalToRefund, order.paidBySunday);
    orderRefunded(
      order,
      totalToRefund === order.totalPaid ? OrderPaymentStatus.REFUNDED : OrderPaymentStatus.PARTIALLY_REFUNDED,
    );
  }, [refundPayment, payment.id, totalToRefund, orderRefunded, order]);

  const EditAmountComponent = () => {
    if (editingCustom) {
      return (
        <StyledInput
          type="number"
          value={customAmount}
          onValueChange={setCustomAmount}
          onBlur={() => setEditingCustom(false)}
          autoFocus
          data-testid="order-refund-custom-amount-input"
        />
      );
    } if (customAmountValue) {
      return (
        <CustomAmountValue onClick={() => setEditingCustom(true)}>
          <MoneyView value={customAmountValue} />
        </CustomAmountValue>
      );
    }
    return (
      <RoundButton size={32} onClick={() => setEditingCustom(true)}>
        <PlusIcon />
      </RoundButton>
    );
  };

  return (
    <RefundDrawerContainer>
      <RefundDrawerHeader>
        <FormattedMessage id="refund.issueARefund" defaultMessage="issue a refund" />
        <DeleteIcon onClick={unselectOrderForRefund} />
      </RefundDrawerHeader>
      <OrderItemsContainer>{order.items.map(renderOrderItems)}</OrderItemsContainer>
      <TotalWithTaxSummary total={order.totalPrice} taxSummary={order.taxSummary} />
      <TotalPrice>
        <FormattedMessage id="orders.total" defaultMessage="total" />
        <MoneyView value={order.totalPrice} />
      </TotalPrice>
      <PaymentSummary payments={order.payments} currency={order.totalPrice.currency} />
      <CustomAmountContainer>
        <FormattedMessage id="orders.refundCustomAmount" defaultMessage="enter a custom amount" />
        <EditAmountComponent />
      </CustomAmountContainer>
      <TotalToRefundContainer>
        <FormattedMessage id="orders.totalRefund" defaultMessage="total refund" />
        <MoneyView value={totalToRefund} data-testid="order-refund-amount" />
      </TotalToRefundContainer>
      <ErrorContainer error={refundState.error} />
      <ButtonContainer>
        {refundState.loading ? (
          <Spinner />
        ) : (
          <Button
            variant={ButtonVariant.PRIMARY}
            onClick={toggleConfirmRefundDrawer}
            data-testid="order-process-refund-button"
          >
            <FormattedMessage id="orders.processRefund" defaultMessage="process refund" />
          </Button>
        )}
      </ButtonContainer>
      <ConfirmationDrawer visible={displayConfirmDrawer} onCancel={toggleConfirmRefundDrawer} onConfirm={processRefund}>
        <TotalToRefundContainer>
          <FormattedMessage id="orders.confirmRefund" defaultMessage="confirm refund" />
          <MoneyView value={totalToRefund} />
        </TotalToRefundContainer>
      </ConfirmationDrawer>
    </RefundDrawerContainer>
  );
};

export default RefundDrawerContent;
