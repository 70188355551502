import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { styled } from '@mui/material';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import SundaySvg from 'src/app/component/icons/SundaySvg';

const TitleContent = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 34px;
  letter-spacing: -2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const SundayLogoWrapper = styled('div')`
  margin: 0 0 0 10px;

  @media (max-width: 480px) {
    margin: 0 0 0 5px;
  }
`;

export const OnboardingHeader = () => (
  <TitleContent>
    <FormattedMessage
      id="onboarding.welcome.part1"
      defaultMessage="Welcome to your"
    />
    <span style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', marginRight: '10px' }}>
      <SundayLogoWrapper>
        <SundaySvg color={PaletteTheme.menu.icon} size={50} />
      </SundayLogoWrapper>
      <FormattedMessage
        id="sunday"
        defaultMessage="sunday"
      />
    </span>

    <FormattedMessage
      id="onboarding.welcome.part2"
      defaultMessage="sunday dashboard"
    />
  </TitleContent>
);
