import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
// eslint-disable-next-line import/no-extraneous-dependencies
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { CountryCode } from 'libphonenumber-js';

export const prefillPhone = ({ phoneFromCheckoutForm, phoneFromMerchantInfo }: {
  phoneFromCheckoutForm?: string,
  phoneFromMerchantInfo?: string
}) => {
  if (!!phoneFromCheckoutForm) return phoneFromCheckoutForm;
  // prefill only if starts with +. National only value is not properly handled by the lib component
  if (!!phoneFromMerchantInfo && phoneFromMerchantInfo.startsWith('+'))
    return phoneFromMerchantInfo;
  return '';
};

export const BusinessPhoneNumber = ({ businessCountryCode }: { businessCountryCode: string }) => {
  const { control } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  return <Controller
    control={control}
    rules={{
      validate: (value) => {
        if (!value) return false;
        return matchIsValidTel(value);
      },
    }}
    render={({ field, fieldState }) => (
      <MuiTelInput
        {...field}
        defaultCountry={businessCountryCode as CountryCode}
        required
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.contact.phone' })}
        helperText={fieldState.invalid ? intl.formatMessage({ id: 'settings.payments.checkout.onboarding.phone.error' }) : ''}
        error={fieldState.invalid}
      />
    )}
    name="contact.phone"
  />;
};
