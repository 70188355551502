import { IconWrapper, InputText, TrashSvg } from '@sundayapp/b2b-react-component-library';
import { Box, FormControl, SelectChangeEvent, Stack, styled, Switch } from '@mui/material';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { DragHandle } from '@mui/icons-material';
import { useSortable } from '@dnd-kit/sortable';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ReportSettingsInputs } from './ReportSettingsInputs';
import { Settings } from './styles';
import { MultipleChipSelect } from 'src/components/select/MultipleChipSelect';
import { ReconciliationConfigurationContext } from '../../providers/ReconciliationConfigurationContext';

export interface PaymentMethodSettingsPanelProps {
  id: string,
  form: UseFormReturn<ReportSettingsInputs>,
  deleteEnabled: boolean,
  onDelete?: () => void,
  defaultValues: string[],
}

export const Line = styled('div')`
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const CustomSelect = styled('select')`
  width: 170px;
  margin-top: 10px;
  height: 42px;
  border-radius: 8px;
  background: white;

`;

const Panel = styled(Box)`
  border-radius: 24px;
  display: inline-flex;
  gap: 2em;
  background: white;
  padding: 24px;
  padding-left: 1em;
  align-items: center;
  width: 100%;
`;

export const GenericPaymentMethodSettingsPanel = ({
  id,
  form,
  deleteEnabled,
  onDelete,
  children,
  defaultValues,
}: PropsWithChildren<PaymentMethodSettingsPanelProps>) => {
  const intl = useIntl();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const { formatMessage } = useIntl();

  const [selectedValues, setSelectedValues] = useState<string[]>(defaultValues);
  const { posPaymentMethods } = useContext(ReconciliationConfigurationContext);

  function onChangeMappedValues(e: SelectChangeEvent<string[]>): void {
    const selected = e.target.value;
    const strings = typeof selected === 'string' ? selected.split(',') : selected;
    setSelectedValues(strings);
    form.setValue(`paymentMethodsById.${id}.posPaymentMethodIds`, strings);
  }

  console.log(form.register(`paymentMethodsById.${id}.activated`));
  return (
    <Panel ref={setNodeRef} {...attributes} style={style}>
      <DragHandle fontSize="large" {...listeners} />
      <Stack flexDirection="column" width="100%" gap={3}>
        <Line sx={{ width: '100%', display: 'flex' }}>
          <FormControl fullWidth>
            <InputText
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              {...form.register(`paymentMethodsById.${id}.name`)}
              placeholder={intl.formatMessage({ id: 'reconciliation.settings.new_payment_method_placeholder' })}
            />
          </FormControl>
          <Controller
            name={`paymentMethodsById.${id}.activated`}
            control={form.control}
            render={({ field: { value:valueProp } }) => {
              return (
                <Switch
                        id={`paymentMethodsById.${id}.activated`}
                        checked={valueProp}
                        // onChange={onChangeController}
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...form.register(`paymentMethodsById.${id}.activated`)}
                      />
              );
            }}
          />
        </Line>
        <FormControl fullWidth>
          <MultipleChipSelect
            label={formatMessage({ id: 'reconciliation.settings.mappedValues' })}
            options={posPaymentMethods}
            onChangeMappedValues={onChangeMappedValues}
            selectedValues={selectedValues}
          />
        </FormControl>
        <Settings>
          {children}
        </Settings>
        {deleteEnabled && (
          <Box
            width="100%"
            display="flex"
            flexDirection="row-reverse"
          >
            <IconWrapper
              size="large"
              cursor="pointer"
              onClick={onDelete}
            >
              <TrashSvg />
            </IconWrapper>
          </Box>
        )}
      </Stack>
    </Panel>
  );
};
