import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumEvent } from '@datadog/browser-rum';
import { Configuration } from '../configuration/Configuration';

const initLogs = (configuration: Configuration) => {
  if (configuration.canPushToDatadog) {
    datadogLogs.init({
      clientToken: configuration.datadogToken!,
      site: 'datadoghq.eu',
      service: 'merchant-front-end',
      forwardErrorsToLogs: false,
      env: configuration.envName,
      version: configuration.version,
      sessionSampleRate: 100,
    });
  }
};

const filterLoadErrors = (event: RumEvent) => {
  return !(
    event.type === 'error' &&
    event.error.source === 'source' &&
    event.error.type === 'TypeError' &&
    event.error.message.includes('Load failed')
  );
};
const initRum = (configuration: Configuration) => {
  const { datadogRumApplicationId } = configuration;
  const { datadogRumToken } = configuration;

  if (datadogRumApplicationId !== undefined && datadogRumToken !== undefined) {
    datadogRum.init({
      applicationId: datadogRumApplicationId,
      clientToken: datadogRumToken,
      site: 'datadoghq.eu',
      service: 'merchant-front-end',
      env: configuration.envName,
      version: configuration.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      enableExperimentalFeatures: ['clickmap', 'feature_flags'],
      actionNameAttribute: 'data-testid',
      allowedTracingUrls: [
        /https:\/\/(?!.*auth).*\.sundayapp\.dev/,
        /https:\/\/(?!.*auth).*\.sunday\.cloud/,
        /https:\/\/(?!.*auth).*\.sundayapp\.xyz/,
        /https:\/\/(?!.*auth).*\.sundayapp\.io/,
      ],
      defaultPrivacyLevel: 'mask-user-input',
      beforeSend: filterLoadErrors,
    });

    datadogRum.startSessionReplayRecording();
  }
};

export const initDatadog = (configuration: Configuration) => {
  initLogs(configuration);
  initRum(configuration);
};
