import React from 'react';
import { AppVenueSettings } from 'src/ordering/venues/pages';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsPageContainer } from '../components/SettingsPageContainer';


export const OrderingAppSettings = () => {
  const business = useCurrentBusinessOrThrow();
  return (
    <SettingsPageContainer>
      <AppVenueSettings enrollmentId={business.oapEnrollment!.id} />
    </SettingsPageContainer>
  );
};
