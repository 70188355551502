import { Stack } from '@mui/material';
import React from 'react';
import { isGoogle, isSunday, Review } from '../../../domain/Review';
import BlueGoogle from './BlueGoogle';
import BlueSunday from './BlueSunday';

export const PlatformLogo = ({ review }: { review: Review }) => (
  <>
    {isGoogle(review) && review.comingFromSunday && (
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        style={{
          borderRadius: '40px',
          padding: '0px',
          backgroundColor: '#EBEDFB',
        }}
      >
        <BlueGoogle height={20} />
      </Stack>
    )}
    {isGoogle(review) && !review.comingFromSunday && (
      <Stack
        direction="row"
        alignItems="center"
        style={{
          borderRadius: '40px',
          padding: '0px',
          backgroundColor: '#EBEDFB',
        }}
      >
        <BlueGoogle size={20} />
      </Stack>
    )}
    {isSunday(review) && <BlueSunday size={20} />}
  </>
);
