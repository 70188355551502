import { BusinessId } from 'src/business/domain/Business';
import {
  useGoogleSetupStage,
} from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStage';
import { GoogleSetupStage } from 'src/sundayplus/reviews/settings/setup/google/domain/GoogleSetupStage';

export function useGoogleSetupStageTermination(businessId: BusinessId): {
  isFinished: boolean | undefined;
  loading: boolean;
} {
  const { data: stage, isLoading, isError } = useGoogleSetupStage(businessId);

  return {
    isFinished: stage && stage.status === GoogleSetupStage.Finished,
    loading: isLoading || isError,
  };
}
