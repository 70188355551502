import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductRepositoryHttp } from '../../repositories/ProductRepositoryHttp';
import { UpdateProductDto } from '../../repositories/ProductDto';
import { QUERYKEY_MENU_PRODUCT } from '../../queries/product/getProductByIdQuery';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';

export const useProductTranslationMutation = (businessId: BusinessId, productId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: ({ language, updateProduct }: {
      language: string,
      updateProduct: UpdateProductDto
    }) => productRepositoryHttp.updateProductTranslation(businessId, productId, language, updateProduct),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT] });
    },
  });
};
