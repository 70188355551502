import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpUserRepository } from 'src/infrastructure/user/HttpUserRepository';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

type MutationArgs = { email: string };

export const useReSendSignupLinkToUser = (businessId: string) => {
  const configuration = ConfigurationLoader.load();

  const userRepository = new HttpUserRepository(configuration.merchantAccountsBaseUrl);

  const mutation = useMutation({
    mutationFn: ({ email }: MutationArgs) => userRepository.reSendSignUpLink(email, businessId),
  });

  return {
    reSendSignupLinkToUser: (args: MutationArgs) => mutation.mutateAsync(args),
  };
};
export const useInviteUserToDashboardAndLinkAuth0Accounts = () => {
  const configuration = ConfigurationLoader.load();


  return useMutation({
    mutationFn: async ({
      userId,
      businessId,
      auth0SmsId,
    }: { userId: string, businessId: string, auth0SmsId: string }) => {
      await axios.put(
        `${configuration.waiterAppBackendUrl}/users/${userId}/account/email/link`,
        {
          businessId,
          auth0SmsId,
        },
      );
    },
  });
};
