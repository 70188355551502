import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  BoxOrderDetails, BoxOrderSummary, Order, OrderSummary,
} from '../types';
import { RootState } from '../../common/redux/types';

export const getVenueOrders = (state: RootState) => state.ordering.orders.orders;
export const getBoxOrders = (state: RootState) => state.ordering.orders.boxOrders;

const sortOrdersByDate = (o1: BoxOrderSummary | OrderSummary, o2: BoxOrderSummary | OrderSummary) =>
  (o2.placedAtEpochMilli ?? 0) - (o1.placedAtEpochMilli ?? 0);
export const useVenueOrders = () => {
  const orders = useSelector(getVenueOrders);
  return useMemo(() => [...orders].sort(sortOrdersByDate), [orders]);
};
export const useBoxOrders = () => {
  const orders = useSelector(getBoxOrders);
  return useMemo(() => [...orders].sort(sortOrdersByDate), [orders]);
};

export const getOrderForDetails = (state: RootState): Order | undefined => {
  const orderId = state.ordering.orders.selectedForDetailsOrderId;
  if (!orderId) return undefined;
  if (state.ordering.orders.selectedOrder && state.ordering.orders.selectedOrder.id === orderId) return state.ordering.orders.selectedOrder;
  return undefined;
};

export const getOrderForRefund = (state: RootState): Order | undefined => {
  const orderId = state.ordering.orders.selectedForRefundOrderId;
  if (!orderId) return undefined;
  if (state.ordering.orders.selectedOrder && state.ordering.orders.selectedOrder.id === orderId) return state.ordering.orders.selectedOrder;
  return undefined;
};

export const getSelectedOrder = (state: RootState): Order | undefined => state.ordering.orders.selectedOrder;
export const getSelectedBoxOrder = (state: RootState): BoxOrderDetails | undefined =>
  state.ordering.orders.selectedBoxOrder;
