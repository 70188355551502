import { MoneyView } from '@sundayapp/web-money';
import React from 'react';
import styled from 'styled-components';
import { OrderItem } from '../../types';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  modifier: OrderItem;
}

const OrderItemModifierContainer = styled.div`
  margin: ${getSpacing(0.5)} 0 0;
  display: inline-flex;
  align-items: center;
  color: ${colorPalette.grey600};
`;

const OrderItemModifierPrice = styled.div`
  margin-left: ${getSpacing(0.5)};
  white-space: nowrap;
`;

const OrderItemModifier: React.FC<Props> = ({ modifier }) => (
  <OrderItemModifierContainer>
    <span>{modifier.name}</span>
    {modifier.price && modifier.price.amount > 0 && (
      <OrderItemModifierPrice>
        <MoneyView value={modifier.price} />
      </OrderItemModifierPrice>
    )}
  </OrderItemModifierContainer>
);

export default OrderItemModifier;
