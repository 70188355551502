import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { queryKeys } from 'src/app/queries/utils';
import { HttpEReputationRepository } from './HttpEReputationRepository';
import { FetchQueryOptions, useQuery } from '@tanstack/react-query';
import { EReputationKpisViewModelByTimeframe, fromDomain } from '../EReputationViewModel';

const SixHours = 60 * 60 * 6000;

export const eReputationKpisQueryBuilder = (
  businessId: string,
  timezone: string,
): FetchQueryOptions<EReputationKpisViewModelByTimeframe[] | null> => ({
  queryKey: [
    queryKeys.REVIEWS_E_REPUTATION,
    {
      businessId,
      timezone,
    },
  ],
  queryFn: async (): Promise<EReputationKpisViewModelByTimeframe[] | null> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpEReputationRepository(configuration.venueFeedbackBaseUrl);
    const kpis = await repository.getKpis(businessId, timezone);
    const timeframes = kpis.eReputationKpisByTimeframes;
    return timeframes ? fromDomain(timeframes) : null;
  },
  staleTime: SixHours,
  gcTime: SixHours,
});

export const useEReputationKpis = (businessId: string, timezone: string) => {
  return useQuery(eReputationKpisQueryBuilder(businessId, timezone));
};
