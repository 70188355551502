import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { IconButton, Typography } from '@mui/material';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import * as React from 'react';
import { useState } from 'react';
import { defaultInvoiceFilters } from 'src/accounting/invoices/domain/InvoiceFilters';
import { themeV5 } from 'src/app/theme/ThemeV5';

const Show = () => (
  <>
    <VisibilityOutlined fontSize={'medium'}/>
    <Typography>
      <FormattedMessage id="accounting.invoice_tab.show_catering"/>
    </Typography>
  </>
);

const Hide = () => (
  <>
    <VisibilityOffOutlined fontSize={'medium'}/>
    <Typography>
      <FormattedMessage id="accounting.invoice_tab.hide_catering"/>
    </Typography>
  </>
);

export const CateringFilter = ({ onShowCatering }: { onShowCatering: (show: boolean) => void }) => {
  const [show, setShow] = useState(defaultInvoiceFilters.showCatering);

  const onChange = (value: boolean) => {
    setShow(value);
    onShowCatering(value);
  };

  return (
    <IconButton
      disableRipple
      onClick={() => onChange(!show)}
      sx={{ gap: '0.2rem', alignContent: 'center', color: themeV5.palette.text.primary }}
    >
      {!show && <Show />}
      {show && <Hide />}
    </IconButton>
  );
};
