import { OrderSummary } from '../../orders/types';
import { styled } from '@mui/material';
import React from 'react';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { colorPalette } from '../../stylesheet';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { KdsAcceptOrderButton } from './KdsAcceptOrderButton';
import { useRenderMoney } from '@sundayapp/web-money';


type KdsBoxOrderToPayTableProps = {
  orders: OrderSummary[];
  onOrderPaid: (orderId: string) => void;
};


const OrderTable = styled('table')`
  border-collapse: collapse;
  width: 100%;
`;

const TableRow = styled('tr')`
  width: 100%;
`;

const TableHeader = styled('th')`
  color: ${colorPalette.rhino};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 0 12px 0;
`;

const TableCell = styled('td')`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  text-align: center;
  padding: 12px;
  border-top: solid 1px ${colorPalette.rhino};

  @media (max-width: ${breakpoints.mobile}) {
    padding-right: 0;
  }
`;

const CellContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const KdsBoxOrderToPayTable = ({ orders, onOrderPaid }: KdsBoxOrderToPayTableProps) => {
  const renderMoney = useRenderMoney();

  return (
      <OrderTable>
        <TableRow>
          <TableHeader>
            <FormattedMessage id="kds.orders.number"/>
          </TableHeader>
          <TableHeader>
            <FormattedMessage id="kds.orders.price"/>
          </TableHeader>
          <TableHeader>
            <FormattedMessage id="kds.orders.actions"/>
          </TableHeader>
        </TableRow>
        {orders.map((order) => (
          <TableRow key={order.id}>
            <TableCell>
              {order.displayId}
            </TableCell>
            <TableCell>
              {renderMoney(order.totalPrice)}
            </TableCell>
            <TableCell>
                <CellContainer>
                  <KdsAcceptOrderButton orderId={order.id} onOrderAccepted={() => onOrderPaid(order.id)}/>
                </CellContainer>
            </TableCell>
          </TableRow>
        ))}
      </OrderTable>
  );
};
