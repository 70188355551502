import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useFormContext } from 'react-hook-form';
import { TipsSuggestion } from 'src/pages/Settings/AppSettings/tips/domain/TipsSuggestion';
import { Button, Chip, Stack, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import AutoFixOffIcon from '@mui/icons-material/AutoFixOff';
import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { SettingLine } from 'src/pages/Settings/AppSettings/components/TipsGeneralConfiguration';
import { LocalisationKey } from 'src/lang/en';

export const SundayCustomerExperience = () => {
  const intl = useIntl();
  const {
    setValue,
    watch,
  } = useFormContext<TipsSuggestion>();

  return <Stack spacing={3}>
    <Typography variant={'h6'}>
      <FormattedMessage id={'settings.app_settings.tips.customer_experience.title'} />
    </Typography>
    <Divider />
    <SettingLine labelsKeys={{
      mainLabel: (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography>
            <FormattedMessage id={'settings.app_settings.tips.smart_configuration.main_label'} />
          </Typography>
          <Chip color={'info'} label={intl.formatMessage({ id: 'settings.app_settings.tips.smart_chip' })} />
        </Stack>
      )
      ,
      subLabel: 'settings.app_settings.tips.smart_configuration.sub_label',
    }}>
      <Tooltip title={intl.formatMessage({ id: 'settings.app_settings.tips.tooltip.switch_to_custom' })} arrow placement={'top'}>
        <Button
          size={'small'}
          startIcon={<AutoFixOffIcon />}
          sx={{
            minWidth: 'auto',
            whiteSpace: 'nowrap',
          }}
          color={'secondary'} onClick={() => setValue('tipsConfigurationMode', 'BUSINESS', {
            shouldDirty: watch('tipsConfigurationMode') !== 'BUSINESS',
          })}>
          <FormattedMessage id={'settings.app_settings.tips.button.switch_to_custom'} />
        </Button>
      </Tooltip>
    </SettingLine>

    <Divider />

    <Stack spacing={2}>
      {['settings.app_settings.tips.smart_configuration.incentive.1',
        'settings.app_settings.tips.smart_configuration.incentive.2',
        'settings.app_settings.tips.smart_configuration.incentive.3',
        'settings.app_settings.tips.smart_configuration.incentive.4',
        'settings.app_settings.tips.smart_configuration.incentive.5'].map((key) => (
        <Stack direction={'row'} spacing={1} alignItems={'center'} key={key}>
          <CheckIcon fontSize={'large'} />
          <Typography>
            <FormattedMessage id={key as LocalisationKey} />
          </Typography>
        </Stack>),
      )}
    </Stack>
  </Stack>;
};
