import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PosConnectionRepositoryHttp } from '../infrastructure/PosConnectionRepositoryHttp';
import { PosConnectionOptions } from '../domain/PosConnectionOptions';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PosConnectionId } from 'src/pos/domain/PosConnection';
import { queryKeys } from 'src/app/queries/utils';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';


export const useUpdatePosConnectionOptionsMutation = () => {
  const businessId = useBusinessIdOrThrow();
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();

  const posConnectionRepository = new PosConnectionRepositoryHttp(configuration.vposVenueUrl);
  return useMutation({
    mutationFn: ({
      posConnectionId,
      options,
    }: {
      posConnectionId: PosConnectionId;
      options: PosConnectionOptions;
    }) => posConnectionRepository.updatePosConnectionOptions(posConnectionId, options),
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.pos_connections.options(businessId, variables.posConnectionId) });
    },
  });
};
