import React from 'react';
import { IconProps } from './interface';

export const DeleteIcon: React.FC<IconProps> = ({ size = 30, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M9.52918 19.6127L19.3672 9.77466L19.9821 10.3895L10.1441 20.2275L9.52918 19.6127Z" fill={color} />
    <rect
      x="10.1445"
      y="9.77478"
      width="13.913"
      height="0.869565"
      transform="rotate(45 10.1445 9.77478)"
      fill={color}
    />
  </svg>
);
