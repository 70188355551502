import { CurrencyCode, money, Money } from '@sundayapp/web-money';
import { CountryCode } from 'src/domain/CountryCode';
import { CardBrand } from 'src/payments/domain/Payment';
import { ValuesOf } from 'src/utils/typescript/valuesOf';

export interface CardInfos {
  countryCode?: CountryCode;
  brand: CardBrand;
  last4?: string;
  network?: string;
  isAmex: boolean;
}

export const productOrigin = {
  PDQ: 'PDQ',
  PAT: 'PAT',
  OAP: 'PAT',
  PAYMENT_LINK: 'PAYMENT_LINK',
  CHECKIN: 'CHECKIN',
  SMB: 'SMB',
  NOT_INTEGRATED: 'NOT_INTEGRATED',
  UNKNOWN: 'NOT_INTEGRATED',
} as const;
export type PaymentProductOrigin = ValuesOf<typeof productOrigin>;

export interface NonReconciledPayment {
  createdAt: Date;
  paymentId: string;
  sales: Money;
  posPooledTips: Money;
  productOrigin: PaymentProductOrigin;
  tableNumber?: string;
  waiterName?: string;
  billCode?: string;
  cardInfos?: CardInfos;
}

export interface ReconciliationReport {
  nonNotifiedPayments: NonReconciledPayment[];
  fastPaymentPdq: NonReconciledPayment[];
}

export const amountOfSales = (discrepancies: NonReconciledPayment[], currency: CurrencyCode) =>
  discrepancies
    .map((discrepancy) => discrepancy.sales)
    .reduce(
      (sum, sales) => ({
        amount: sum.amount + sales.amount,
        currency: sum.currency,
      }),
      money(0, currency),
    );
