// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Product, productTypes, ProductWithSubProductsType } from '../../../domain/Product';
import { useGetProductsById } from '../../../queries/product/getProductByIdQuery';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import {
  useProductMutation,
  useRevertToPartnerSubProductsMutation,
} from '../../../mutations/product/useProductMutation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ProductSummary } from '../../../domain/ProductSummary';
import { BusinessId } from 'src/business/domain/Business';
import { EditProductsSelectionButton } from 'src/menu/dynamic-menu/components/products/EditProductsSelectionButton';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ProductLinksBox } from 'src/menu/dynamic-menu/components/products/ProductLinksBox';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

const HeaderActions = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

type Props = {
  businessId: BusinessId;
  product: Product;
};

export const ProductSubProducts = ({ businessId, product }: Props) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const configuration = ConfigurationLoader.load();
  const [isProductSelectionVisible, setIsProductSelectionVisible] = useState(false);

  const updateProduct = useProductMutation(businessId, product.id);
  const revertToPartnerSubProductsMutation = useRevertToPartnerSubProductsMutation(businessId, product.id);

  const subProductQueries = useGetProductsById(businessId, product.subProductIds ?? []);

  const canHaveSubProducts = (
    currentProduct: Product,
  ): currentProduct is Product & {
    type: ProductWithSubProductsType;
  } => {
    switch (currentProduct.type) {
      case productTypes.VARIANT_GROUP:
      case productTypes.MODIFIER_GROUP:
      case productTypes.PRODUCT:
      case productTypes.BUNDLE:
      case productTypes.VARIANT:
        return true;
      case productTypes.MODIFIER:
        return false;
    }
  };

  if (!canHaveSubProducts(product)) {
    return <></>;
  }

  const subProducts = subProductQueries.map(({ data }) => data).filter((sbp): sbp is Product => !!sbp);
  const areSubProductsLoading = subProductQueries
    .map(({ isLoading }) => isLoading)
    .reduce((loading, current) => loading || current, false);

  const openProductSelection = () => {
    setIsProductSelectionVisible(true);
  };

  async function updateSubProducts(newProductIds: string[]) {
    const productUpdate: UpdateProductDto = {
      subProductIds: newProductIds,
    };
    await updateProduct.mutateAsync(productUpdate);
  }

  const restorePosSubProducts = async () => revertToPartnerSubProductsMutation.mutateAsync();

  const onProductSelectionFinished = async (newProductIds: string[]) => {
    try {
      await updateSubProducts(newProductIds);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    } finally {
      setIsProductSelectionVisible(false);
    }
  };

  const customFilterForSelection = (p: ProductSummary): boolean => {
    if (product.source === 'PARTNER' && p.source === 'PARTNER') {
      // only sunday sub-products can be added to a partner product
      return false;
    }

    switch (product.type) {
      case productTypes.BUNDLE:
        return (
          p.type === productTypes.PRODUCT ||
          p.type === productTypes.MODIFIER_GROUP ||
          p.type === productTypes.VARIANT_GROUP
        );
      case productTypes.VARIANT_GROUP:
        return p.type === productTypes.PRODUCT;
      case productTypes.PRODUCT:
        return p.type === productTypes.MODIFIER_GROUP || p.type === productTypes.VARIANT_GROUP;
      case productTypes.MODIFIER_GROUP:
        return p.type === productTypes.MODIFIER;
    }
  };

  const Header = () => {
    switch (product.type) {
      case productTypes.VARIANT_GROUP:
        return (
          <>
            <Typography fontSize="large">
              <FormattedMessage id="menus.product.variant.header" />
            </Typography>
            <Typography fontSize="small" color={themeV5.palette.text.secondary}>
              <FormattedMessage id="menu.catalog.product.selection.variant_group.rule" />
            </Typography>
          </>
        );
      case productTypes.MODIFIER_GROUP:
        return (
          <>
            <Typography fontSize="large">
              <FormattedMessage id="menus.product.sub_products.header" />
            </Typography>
            <Typography fontSize="small" color={themeV5.palette.text.secondary}>
              <FormattedMessage id="menu.catalog.product.selection.modifier_group.rule" />
            </Typography>
          </>
        );
      case productTypes.PRODUCT:
      case productTypes.BUNDLE:
        return (
          <Typography fontSize="large">
            <FormattedMessage id="menus.product.sub_products.header" />
          </Typography>
        );
    }
  };

  const emptyListState = () => {
    const unhandledCases = <></>;
    switch (product.type) {
      case productTypes.VARIANT_GROUP:
        return <FormattedMessage id="menu.catalog.product.selection.variants" />;
      case productTypes.PRODUCT:
        return <FormattedMessage id="menu.catalog.product.selection.modifier_groups" />;
      case productTypes.MODIFIER_GROUP:
        return <FormattedMessage id="menu.catalog.product.selection.modifiers" />;
      case productTypes.BUNDLE:
        return unhandledCases;
    }
  };

  return (
    <ProductLinksBox
      products={subProducts || []}
      isLoading={areSubProductsLoading}
      businessId={businessId}
      filterSelectableProducts={customFilterForSelection}
      onProductsSorted={onProductSelectionFinished}
      onProductSelected={onProductSelectionFinished}
      isSelectionVisible={isProductSelectionVisible}
      setIsSelectionVisible={setIsProductSelectionVisible}
      slots={{
        header: (
          <>
            <Box display="flex" flexDirection="column">
              <Header />
            </Box>

            {!configuration.enablePosProductModifications || !product.isSubProductsEditable ? (
              <Typography fontSize="small">
                <FormattedMessage id="menus.product.sub_products.tip_partner" />
              </Typography>
            ) : (
              <HeaderActions>
                <EditProductsSelectionButton onClick={openProductSelection} />
                {product.isSubProductsOverridden && (
                  <Tooltip
                    title={intl.formatMessage({ id: 'menus.product.details.restore_pos_value' })}
                    placement="top"
                    sx={{ fontSize: '30px' }}
                  >
                    <IconButton size="large" sx={{ height: 'fit-content' }} onClick={restorePosSubProducts}>
                      <SettingsBackupRestoreIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                )}
              </HeaderActions>
            )}
          </>
        ),
        emptyListState: emptyListState(),
      }}
    />
  );
};
