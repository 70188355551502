import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { currencyLabel } from './ProcessingDetails';

export const HighestTransactionValue = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors.highestTransactionValue;
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const currency = currencyLabel(business);

  return <TextField
    required
    InputLabelProps={{
      shrink: true,
    }}
    InputProps={{
      startAdornment: <InputAdornment position="start">{currency}</InputAdornment>,
    }}
    error={!!error}
    type={'number'}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.highestTransactionValue' })}
    {...register('highestTransactionValue', {
      setValueAs: (value) => value ? parseInt(value) : undefined,
      required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.highestTransactionValue.error.required' }),
      min: { value: 1, message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.highestTransactionValue.error.min' }) },
    })}
    helperText={!!error ? error.message : ''}
  />

  ;
};
