import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { RepositoryHttp } from 'src/ordering/common/repository/RepositoryHttp';
import { TagRepository } from './TagRepository';
import { Tag } from '../domain/Tag';
import { TagDtoConverter } from './TagDtoConverter';
import { BusinessTagsDto } from './TagDto';
import { BusinessId } from 'src/business/domain/Business';

export class TagRepositoryHttp extends RepositoryHttp implements TagRepository {
  constructor(private menuBackendUrl: string) {
    super();
  }

  async findAllByBusinessId(businessId: BusinessId): Promise<Tag[]> {
    const response = await axios.get<BusinessTagsDto>(`${this.menuBackendUrl}/businesses/${businessId}/tags`, {
      withCredentials: true,
      headers: {
        correlation_id: uuidv4(),
      },
    });

    return response.data.tags
      .map((tag) => TagDtoConverter.toTag(tag));
  }
}
