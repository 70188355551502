import { negate } from 'lodash';
import { Empty, EMPTY, isGoogle, isNotEmptyOrUndefined, isSunday, Platform, Review } from './Review';
import { noFilter, Predicate } from './Predicate';
import { DateRange } from '../../domain/DateRange';
import { Instant } from '../../../Instant';
import { Comparator } from '../../reply/comparator/Comparator';

interface Filter {
  byPlatform: Predicate<Review>;
  byFeedbackPresence: Predicate<Review>;
  byReviewReplyPresence: Predicate<Review>;
  byRating: Predicate<Review>;
  byWaiter: Predicate<Review>;
  byDateRange: Predicate<Review>;
  byDimension: Predicate<Review>;
  splice: (reviews: Review[]) => Review[];
}

const noFilters: Filter = {
  byPlatform: noFilter,
  byFeedbackPresence: noFilter,
  byReviewReplyPresence: noFilter,
  byRating: noFilter,
  byWaiter: noFilter,
  byDateRange: noFilter,
  byDimension: noFilter,
  splice: (reviews) => reviews,
};

export const withFeedback = (review: Review) => review.feedback !== EMPTY;

export const withBadFoodDimension = (review: Review) => {
  return review.dimensionRatings.filter(dimensionRating =>
    dimensionRating.dimension === 'FOOD_AND_DRINKS' && dimensionRating.rating <= 3).length == 1;
};
export const withBadAmbianceDimension = (review: Review) => {
  return review.dimensionRatings.filter(dimensionRating =>
    dimensionRating.dimension === 'AMBIANCE' && dimensionRating.rating <= 3).length == 1;
};
export const withBadServiceDimension = (review: Review) => {
  return review.dimensionRatings.filter(dimensionRating =>
    dimensionRating.dimension === 'SERVICE' && dimensionRating.rating <= 3).length == 1;
};
export const withBadValueDimension = (review: Review) => {
  return review.dimensionRatings.filter(dimensionRating =>
    dimensionRating.dimension === 'VALUE_FOR_MONEY' && dimensionRating.rating <= 3).length == 1;
};

export const withoutFeedback = negate(withFeedback);

export const withReviewReply = (review: Review) => review.reply !== EMPTY;

export const withoutReviewReply = (review: Review) => review.replyIsPossible && review.reply === EMPTY;
const enhancedGoogleReviewsWithOrderInfo = (reviews: Review[]) => {
  const sundayFeedbacksFound: string[] = [];
  return reviews
    .map((review) => {
      if (isGoogle(review) && review.feedbackId) {
        const sundayFeedbackCreatedForThisGoogleReview = reviews.find(
          (f) => isSunday(f) && f.id === review.feedbackId,
        );
        if (sundayFeedbackCreatedForThisGoogleReview) {
          sundayFeedbacksFound.push(review.feedbackId);
          return {
            ...review,
            waiterName: sundayFeedbackCreatedForThisGoogleReview.waiterName,
            tableName: sundayFeedbackCreatedForThisGoogleReview.tableName,
            totalAmount: sundayFeedbackCreatedForThisGoogleReview.totalAmount,
          };
        }
        return { ...review };
      }
      return { ...review };
    })
    .filter((r) => !(isSunday(r) && sundayFeedbacksFound.includes(r.id!)));
};

export class Reviews {
  public static sortByMostRecent = (a: Review, b: Review) => b.creationDate - a.creationDate;

  private readonly allReviews: Review[];

  private readonly filter: Filter;

  private readonly filteredReviews: Review[];

  constructor(allReviews: Review[], filter: Filter = noFilters) {
    this.filter = filter;
    this.allReviews = [...enhancedGoogleReviewsWithOrderInfo(allReviews)];
    const reviews = this.allReviews;
    this.filteredReviews = this.filter.splice(
      reviews
        .filter(this.filter.byPlatform)
        .filter(this.filter.byFeedbackPresence)
        .filter(this.filter.byReviewReplyPresence)
        .filter(this.filter.byRating)
        .filter(this.filter.byWaiter)
        .filter(this.filter.byDateRange)
        .filter(this.filter.byDimension),
    );
  }

  static fromSingleReview(review: Review): Reviews {
    return new Reviews([review], noFilters);
  }

  platform(platform: Platform): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byPlatform: (review: Review) => review.platform === platform,
    });
  }

  allPlatforms(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byPlatform: noFilter,
    });
  }

  withFeedback(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byFeedbackPresence: withFeedback,
    });
  }

  withoutFeedback(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byFeedbackPresence: withoutFeedback,
    });
  }

  allFeedbacks(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byFeedbackPresence: noFilter,
    });
  }

  allDimensions(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byDimension: noFilter,
    });
  }

  withFoodDimension(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byDimension: withBadFoodDimension,
    });
  }

  withAmbianceDimension(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byDimension: withBadAmbianceDimension,
    });
  }

  withServiceDimension(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byDimension: withBadServiceDimension,
    });
  }

  withValueDimension(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byDimension: withBadValueDimension,
    });
  }

  asArray(): Review[] {
    return this.filteredReviews;
  }

  length(): number {
    return this.filteredReviews.length;
  }

  first(): Review | undefined {
    return this.filteredReviews[0];
  }

  unfilteredReview(): Review[] {
    return this.allReviews;
  }

  splice(start: number, deleteCount?: number): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      splice: (reviews: Review[]) => reviews.splice(start, deleteCount),
    });
  }

  withAndWithoutReviewReply(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byReviewReplyPresence: noFilter,
    });
  }

  remove(predicate: (review: Review) => boolean) {
    return this.keep(negate(predicate));
  }

  keep(predicate: (review: Review) => boolean) {
    return new Reviews(this.allReviews.filter(predicate), this.filter);
  }

  withReviewReply(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byReviewReplyPresence: withReviewReply,
    });
  }

  withoutReviewReply(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byReviewReplyPresence: withoutReviewReply,
    });
  }

  updateWith(allReviews: Review[]): Reviews {
    return new Reviews(allReviews, this.filter);
  }

  updateWithReviews(allReviews: Reviews): Reviews {
    return new Reviews(allReviews.allReviews, this.filter);
  }

  replyToReview(reviewId: string, reply: string): Reviews {
    const clonedReviews = this.allReviews.map((r: Review) => ({ ...r }));
    const review = clonedReviews.find((r: Review) => r.id === reviewId);
    if (!review) {
      return this;
    }
    review.reply = reply;
    return new Reviews(clonedReviews, this.filter);
  }

  allRatings(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byRating: noFilter,
    });
  }

  withRating(ratingValue: number): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byRating: (review: Review) => ratingValue === review.rating,
    });
  }

  withRatings(ratingValues: number[]): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byRating: (review: Review) => ratingValues.includes(review.rating),
    });
  }

  withRatingsCategory(category: string) {
    if (category === 'BAD') return this.withRatings([1, 2]);
    if (category === 'TOP') return this.withRatings([4, 5]);
    return this;
  }

  withWaiter(waiterName: string): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byWaiter: (review: Review) => review.waiterName === waiterName,
    });
  }

  allWaiters(): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byWaiter: noFilter,
    });
  }

  public averageRating(): number {
    return this.filteredReviews.length === 0
      ? 0
      : this.filteredReviews.reduce((acc, review) => acc + review.rating, 0) / this.filteredReviews.length;
  }

  keepingUniqueValues = () => (uniqueValues: string[], currentValue: string) =>
    (uniqueValues.includes(currentValue) ? uniqueValues : [...uniqueValues, currentValue]);

  removeEmptyOrUndefined = (s: string | Empty | undefined): string[] => (isNotEmptyOrUndefined(s) ? [s] : []);

  collectUniqueWaiterNames(): string[] {
    return this.allReviews
      .map((review) => review.waiterName)
      .flatMap(this.removeEmptyOrUndefined)
      .reduce(this.keepingUniqueValues(), [])
      .sort();
  }

  withoutSundayReviewReply(): Reviews {
    return new Reviews(this.allReviews, {
      ...noFilters,
      byPlatform: (review: Review) => review.platform === 'sunday',
      byReviewReplyPresence: withoutReviewReply,
    });
  }

  forDateRange(range: DateRange): Reviews {
    return new Reviews(this.allReviews, {
      ...this.filter,
      byDateRange: (review: Review) => range.contains(Instant.fromEpoch(review.creationDate)),
    });
  }

  contains(review: Review) {
    return this.filteredReviews.map((r) => r.id)
      .includes(review.id);
  }

  map(mapper: (r: Review) => Review) {
    return new Reviews(this.allReviews.map(mapper), this.filter);
  }

  private updateReviews(allReviews: Review[]) {
    const map = allReviews.reduce((acc, next) => {
      acc.set(next.id!, next);
      return acc;
    }, new Map<string, Review>());

    return this.allReviews.map((r) => (map.has(r.id!) ? map.get(r.id!)! : r));
  }

  upsertWith(reviews: Reviews): Reviews {
    return new Reviews(this.updateReviews(reviews.allReviews), this.filter);
  }

  sort(comparator: Comparator<Review>): Reviews {
    return new Reviews(this.allReviews.sort((r1, r2) => comparator.compare(r1, r2)), this.filter);
  }
}

export const createReviews = (initialReviews: Review[]): Reviews => new Reviews(initialReviews, noFilters);

export const emptyReviews = (): Reviews => createReviews([]);
