import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';

type QRCodeProps = {
  url: string;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(2)};
  border-radius: ${getSpacing(2)};
  background-color: ${colorPalette.grey600};
  width: fit-content;
`;

const Layer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(1)};
  border-radius: ${getSpacing(1)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

export const OrderingQrCode = ({ url }: QRCodeProps) => (
  <Container>
    <Layer>
      <QRCodeSVG value={url} size={80} fgColor="#000000" level="L" />
    </Layer>
  </Container>
);
