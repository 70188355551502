import { money, Money, sumMoneys } from '@sundayapp/web-money';
import { Payment, PaymentRefund, RefundStatus } from '../payment/types';

const sumMoney = (acc: Money, value: Money): Money => sumMoneys(acc, value);
const getRefundedAmount = (refund: PaymentRefund): Money => refund.amount;
const isRefundSuccessful = (refund: PaymentRefund) =>
  refund.status === RefundStatus.SUCCEEDED || refund.status === RefundStatus.CREATED;
export const computeRefunds = (payment: Payment) =>
  payment.refunds
    .filter(isRefundSuccessful)
    .map(getRefundedAmount)
    .reduce(sumMoney, money(0, payment.totalAmount.currency));
