import * as React from 'react';
import { Card, styled, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { BlockItem } from '../../MenuDetailsPage/components/BlockItem';
import { Block } from '../../../domain/Block';
import { BusinessId } from 'src/business/domain/Business';

type Props = {
  businessId: BusinessId,
  block: Block,
};

const BlockPreviewCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  gap: 16px;
`;
const BlockPreviewContainer = styled(Card)`
  margin: 0 32px;
`;

export const BlockPreview = ({ businessId, block }: Props) => (
  <BlockPreviewCard>
    <Typography fontSize="large"><FormattedMessage id="menus.block.preview.header" /></Typography>
    <BlockPreviewContainer>
      <BlockItem businessId={businessId} block={block} />
    </BlockPreviewContainer>
  </BlockPreviewCard>
);
