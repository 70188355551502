import { Button } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line import/no-cycle
import MenuDeletionModal from './MenuDeletionModal';
import { BusinessId } from 'src/business/domain/Business';

type MenuDeletionBtnProps = {
  businessId: BusinessId;
  menuId: string;
  menuName: string;
};

const MenuDeletionBtn = ({ businessId, menuId, menuName }: MenuDeletionBtnProps) => {
  const [isDeleteMenuModalOpen, setIsDeleteMenuModalOpen] = useState<boolean>(false);

  return (
    <>
      <Button variant="warning" size="large" onClick={() => setIsDeleteMenuModalOpen(true)} data-tesid="delete-menu">
        <FormattedMessage id="menus.edition.delete_button" />
      </Button>

      <MenuDeletionModal
        businessId={businessId}
        isOpen={isDeleteMenuModalOpen}
        onClose={() => {
          setIsDeleteMenuModalOpen(false);
        }}
        menuId={menuId}
        menuName={menuName}
      />
    </>
  );
};
export default MenuDeletionBtn;
