import { match } from 'ts-pattern';
import { CurrencyCode, Money } from '@sundayapp/web-money';
import { MenuBasedPricingStrategyDto, MoneyDto, PricingStrategyDto, ProductDto, ProductPriceTimeOverrideDto, ProductSummaryDto } from './ProductDto';
import { ProductSummary } from '../domain/ProductSummary';
import { MenuBasedPricingStrategy, PricingStrategy, Product, ProductPriceTimeOverrideStrategy, TimeString } from '../domain/Product';

const toMoney = (money: MoneyDto): Money => ({
  amount: money.amount,
  currency: (<any>CurrencyCode)[money.currency],
});

// Cast is safe because we know that the time string from zod is always in the format HH:mm
const toTimeString = (zodTime: string): TimeString => zodTime.substring(0, 5) as TimeString;

const toProductPriceTimeOverride = (productPriceTimeOverride: ProductPriceTimeOverrideDto): ProductPriceTimeOverrideStrategy => ({
  _type: 'ProductPriceTimeOverride',
  price: toMoney(productPriceTimeOverride.value),
  configuration: {
    days: productPriceTimeOverride.days,
    timeRanges: productPriceTimeOverride.timeRanges.map((timeRange) => ({
      start: toTimeString(timeRange.start),
      end: toTimeString(timeRange.end),
    })),
  },
});

const toMenuBasedPricingStrategy = (menuBasedPricingStrategy: MenuBasedPricingStrategyDto): MenuBasedPricingStrategy => ({
  _type: 'MenuBasedPricingStrategy',
  price: toMoney(menuBasedPricingStrategy.price),
});

const toPricingStrategy = (pricingStrategy: PricingStrategyDto): PricingStrategy => match(pricingStrategy)
  .with({ _type: 'ProductPriceTimeOverride' }, toProductPriceTimeOverride)
  .with({ _type: 'MenuBasedPricingStrategy' }, toMenuBasedPricingStrategy)
  .exhaustive();


export class ProductDtoConverter {
  static toProductSummary(dto: ProductSummaryDto): ProductSummary {
    return {
      id: dto.id,
      name: dto.name,
      description: dto.description,
      shortDescription: dto.shortDescription,
      sku: dto.sku,
      price: this.fromMoney(dto.price),
      basePrice: this.fromMoney(dto.basePrice),
      pictureUrl: dto.pictureUrl,
      type: dto.type,
      hasDiscrepancies: dto.hasDiscrepancies,
      isSnoozed: !!dto?.snoozePeriod,
      posConnection: dto.posConnectionId
        ? {
          posConnectionId: dto.posConnectionId,
          externalRevenueCenterId: dto.externalRevenueCenterId,
        }
        : undefined,
      source: dto.source,
    };
  }

  static toProduct(dto: ProductDto): Product {
    return {
      id: dto.id,
      name: dto.name,
      partnerName: dto.partnerName,
      description: dto.description,
      partnerDescription: dto.partnerDescription,
      shortDescription: dto.shortDescription,
      type: dto.type,
      source: dto.source,
      sku: dto.sku,
      price: this.fromMoney(dto.price),
      pictureUrl: dto.pictureUrl,
      partnerPictureUrl: dto.partnerPictureUrl,
      tags: dto.tags ?? [],
      allergens: dto.allergens ?? [],
      relatedProductIds: dto.relatedProductIds ?? [],
      subProductIds: dto.subProductIdsInfos?.value ?? dto.subProductIds ?? [],
      isSubProductsOverridden: dto.subProductIdsInfos?.isOverridden ??
        (dto.source === 'PARTNER' && !!dto.partnerSubProductIds && !!dto.subProductIds), // TODO remove this when menu backend has been released
      isSubProductsEditable: dto.subProductIdsInfos?.canUpdateValue ??
        (dto.source === 'SUNDAY' || !dto.partnerSubProductIds || dto.partnerSubProductIds.length === 0), // TODO remove this when menu backend has been released
      calories: dto.calories,
      eatInTax: dto.eatInTax ?? 0, // TODO taxes won't be optional when menu backend has been released
      takeAwayTax: dto.takeAwayTax ?? 0,
      deliveryTax: dto.deliveryTax ?? 0,
      isAvailableForOrder: dto.isAvailableForOrder,
      isSnoozed: !!dto?.snoozePeriod,
      isEligibleForOrder: dto.isEligibleForOrder,
      posConnection: dto.posConnection,
      pricingStrategies: dto.pricingStrategies?.map(toPricingStrategy),
    };
  }

  static fromMoney(price: MoneyDto): Money {
    return {
      amount: price.amount,
      currency: (<any>CurrencyCode)[price.currency],
    };
  }
}
