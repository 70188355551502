import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorPalette } from '../../../stylesheet';
import Tag from '../../../common/components/Tag';

interface Props {
  status: PromoCodeStatus;
}

export enum PromoCodeStatus {
  EXPIRED = 'expired',
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
}

const promoCodeStatusBackgroundColor: Record<PromoCodeStatus, string> = {
  [PromoCodeStatus.EXPIRED]: colorPalette.lightRed,
  [PromoCodeStatus.ACTIVE]: colorPalette.lightGreen,
  [PromoCodeStatus.SCHEDULED]: colorPalette.peach,
};

const promoCodeStatusColor: Record<PromoCodeStatus, string> = {
  [PromoCodeStatus.EXPIRED]: colorPalette.red,
  [PromoCodeStatus.ACTIVE]: colorPalette.green,
  [PromoCodeStatus.SCHEDULED]: colorPalette.orange,
};

export const PromoCodeStatusContainer = styled(Tag)<Props>`
  ${({ status }) =>
    status
    && css`
      background-color: ${promoCodeStatusBackgroundColor[status]};
      color: ${promoCodeStatusColor[status]};
    `}
`;

const labelPerStatus: Record<PromoCodeStatus, ReactNode> = {
  [PromoCodeStatus.EXPIRED]: <FormattedMessage id="promocode.EXPIRED" defaultMessage="expired" />,
  [PromoCodeStatus.ACTIVE]: <FormattedMessage id="promocode.ACTIVE" defaultMessage="active" />,
  [PromoCodeStatus.SCHEDULED]: <FormattedMessage id="promocode.SCHEDULED" defaultMessage="scheduled" />,
};

const getLabelPerStatus = (status: PromoCodeStatus) => labelPerStatus[status];

const PromoCodeStatusLabel: React.FC<Props> = ({ status }) => (
  <PromoCodeStatusContainer status={status} data-testid="promo-code-status">
    {getLabelPerStatus(status)}
  </PromoCodeStatusContainer>
);

export default PromoCodeStatusLabel;
