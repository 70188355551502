import { SubTitleBox } from '@styles/Page/PageHeaderStyle';
import { HeadSubTitleProps } from 'src/home2/types/layout_types';

export const PageSubTitle = (headSubTitleProps: HeadSubTitleProps) => {
  if (!headSubTitleProps.hasHeadSubTitle) {
    return null;
  }
  
  return (
    <SubTitleBox>
      {headSubTitleProps.children}
    </SubTitleBox>
  );
};