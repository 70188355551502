import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { getExposedProductName, Product } from '../../domain/Product';
import { Language } from 'src/app/i18n/AllSupportedLanguage';
import { venueDynamicMenuPath, venueMenuProductPath, venueProductsPath } from 'src/app/navigation/pathHelpers';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { TranslationDrawer } from 'src/menu/dynamic-menu/pages/ProductPage/TranslationDrawer';

type ProductTitleProps = {
  menuId?: string;
  product: Product;
  supportedLanguages: Language[];
  contextProductList: string[];
};

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: -16px;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NavigationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
`;

const NavigationButton = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  min-width: 150px;
`;

const EmptyNavigationButton = styled('div')`
  width: 150px;
`;

export const ProductHeader = ({ product, supportedLanguages, contextProductList, menuId }: ProductTitleProps) => {
  const navigate = useNavigate();
  const businessId = useBusinessIdOrThrow();

  const findPreviousAndNextProduct = () => {
    const i = contextProductList.indexOf(product.id);
    const previous = i > 0 ? contextProductList[i - 1] : undefined;
    const next = i < contextProductList.length ? contextProductList[i + 1] : undefined;
    return { previous, next };
  };

  const onBack = async () => {
    return menuId ? navigate(venueDynamicMenuPath(businessId, menuId)) : navigate(venueProductsPath(businessId));
  };

  const nextAndPreviousIds = findPreviousAndNextProduct();
  const navigateToProduct = (productId: string) => {
    navigate(venueMenuProductPath(businessId, productId), { state: { contextProductList, menuId } });
  };

  return (
    <Container>
      <TitleContainer>
        <IconButton size="large" onClick={onBack} sx={{ height: 'fit-content', margin: '8px' }}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <Typography fontSize="xx-large">{getExposedProductName(product)}</Typography>
      </TitleContainer>
      <NavigationContainer>
        {nextAndPreviousIds.previous ? (
          <NavigationButton onClick={() => navigateToProduct(nextAndPreviousIds.previous!)}>
            <NavigateBeforeIcon />
            <FormattedMessage id="menus.product.details.navigation.previous" defaultMessage="Previous product" />
          </NavigationButton>
        ) : (
          <EmptyNavigationButton />
        )}

        {nextAndPreviousIds.next ? (
          <NavigationButton onClick={() => navigateToProduct(nextAndPreviousIds.next!)}>
            <FormattedMessage id="menus.product.details.navigation.next" defaultMessage=" Next product" />
            <NavigateNextIcon />
          </NavigationButton>
        ) : (
          <EmptyNavigationButton />
        )}
      </NavigationContainer>

      {supportedLanguages.length && (
        <TranslationDrawer supportedLanguages={supportedLanguages} businessId={businessId} productId={product.id} />
      )}
    </Container>
  );
};
