/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function EdenredIcon(props: PaymentMethodIconProps) {
  return (
    <svg viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.58 5.217c-.356 0-.581.227-.652.528h1.266c-.043-.332-.28-.528-.614-.528Zm-3.382.132c-.453 0-.739.306-.739.734 0 .422.291.738.739.738.447 0 .748-.3.748-.738s-.302-.734-.748-.734Z"
        fill="#F72717"
      />
      <path
        d="M13.331 4.647c-.679 0-1.208.266-1.294.957h-.003C11.821 2.474 9.302 0 6.055 0 3.49 0 1.293 1.546.38 3.739h2.176l-.113.718H.734v.76H2.28l-.118.692H.734v.443c0 .27.119.396.345.396h1.46l-.119.718H.804c-.078 0-.153-.007-.225-.02 0 0-.28-.029-.453-.25-.004-.005-.075-.087-.11-.222L0 6.909a4.986 4.986 0 0 0 .157.731c.726 2.524 3.09 4.375 5.898 4.375 2.872 0 5.298-1.937 5.963-4.549h-.824V6c0-.396-.157-.65-.555-.65-.399 0-.555.254-.555.65v1.467H9.26V5.798c0-.797.593-1.15 1.379-1.15s1.379.353 1.379 1.15v1.668h.828V6c0-.396.108-.65.501-.65.124 0 .275.037.329.064l.156-.708c-.09-.01-.323-.058-.5-.058ZM5.775 5.919c0 1.009-.608 1.6-1.616 1.6-.932 0-1.562-.613-1.562-1.436 0-.86.598-1.437 1.492-1.437.41 0 .711.159.862.317V3.74h.824v2.18Zm2.08.993c.242 0 .466-.07.677-.164l-.069.639a2.215 2.215 0 0 1-.807.132c-.949 0-1.617-.544-1.617-1.468 0-.802.598-1.404 1.514-1.404 1.034 0 1.551.644 1.53 1.636H6.95c.08.455.522.629.905.629ZM15.2 4.647c-.915 0-1.513.602-1.513 1.404 0 .924.668 1.468 1.616 1.468.286 0 .571-.037.808-.132l.069-.639c-.21.095-.435.164-.677.164-.383 0-.824-.174-.905-.629h2.133c.02-.992-.496-1.636-1.53-1.636Zm-.624 1.098c.07-.3.296-.528.651-.528.334 0 .571.196.614.528h-1.265Zm4.599-2.006v1.224c-.15-.158-.452-.316-.861-.316-.895 0-1.492.575-1.492 1.436 0 .823.63 1.436 1.562 1.436 1.007 0 1.616-.592 1.616-1.6V3.74h-.825Zm-.754 3.083c-.447 0-.738-.317-.738-.739 0-.428.285-.734.738-.734.447 0 .749.296.749.734s-.302.739-.749.739Z"
        fill="#F72717"
      />
    </svg>
  );
}
