import { Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { styled } from '@mui/material/styles';
import { Language } from '../app/i18n/AllSupportedLanguage';

type CountryFlagProps = {
  language: Language,
  isDefault?: boolean,
};

const FlagItem = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px
`;

const FlagImg = styled('img')`
  height: 16px
`;
export const CountryFlag = ({ language, isDefault = false }: CountryFlagProps) => (language ? (
  <FlagItem>
    <FlagImg
      loading="lazy"
      src={`https://flagcdn.com/w20/${language.countryFlag.toLowerCase()}.png`}
      srcSet={`https://flagcdn.com/w40/${language.countryFlag.toLowerCase()}.png 2x`}
      alt=""
    />
    <Typography>
      <FormattedMessage id={language.label} />
    </Typography>

    {isDefault && (
    <Typography>
      {' '}
      <FormattedMessage id="menus.details.default_language" />
    </Typography>
    )}
  </FlagItem>
)
  : <Typography>{language}</Typography>);
