/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const PerformancePlainSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.47812 2H13.5219C14.2034 2 14.7454 1.99999 15.1827 2.03572C15.6305 2.07231 16.0123 2.14884 16.362 2.32698C16.9265 2.6146 17.3854 3.07354 17.673 3.63803C17.8512 3.98765 17.9277 4.36949 17.9643 4.81729C18 5.25456 18 5.79659 18 6.47806V13.5219C18 14.2034 18 14.7454 17.9643 15.1827C17.9277 15.6305 17.8512 16.0123 17.673 16.362C17.3854 16.9265 16.9265 17.3854 16.362 17.673C16.0123 17.8512 15.6305 17.9277 15.1827 17.9643C14.7454 18 14.2034 18 13.5219 18H6.47806C5.79659 18 5.25456 18 4.81729 17.9643C4.36949 17.9277 3.98765 17.8512 3.63803 17.673C3.07354 17.3854 2.6146 16.9265 2.32698 16.362C2.14884 16.0123 2.07231 15.6305 2.03572 15.1827C1.99999 14.7454 2 14.2034 2 13.5219V6.47811C2 5.7966 1.99999 5.25458 2.03572 4.81729C2.07231 4.36949 2.14884 3.98765 2.32698 3.63803C2.6146 3.07354 3.07354 2.6146 3.63803 2.32698C3.98765 2.14884 4.36949 2.07231 4.81729 2.03572C5.25458 1.99999 5.79661 2 6.47812 2ZM14.1615 5.83333C14.1615 5.3731 13.7884 5 13.3281 5C12.8679 5 12.4948 5.3731 12.4948 5.83333V14.1667C12.4948 14.6269 12.8679 15 13.3281 15C13.7884 15 14.1615 14.6269 14.1615 14.1667V5.83333ZM10.8281 9.16667C10.8281 8.70643 10.455 8.33333 9.99479 8.33333C9.53455 8.33333 9.16146 8.70643 9.16146 9.16667V14.1667C9.16146 14.6269 9.53455 15 9.99479 15C10.455 15 10.8281 14.6269 10.8281 14.1667V9.16667ZM7.49479 12.5C7.49479 12.0398 7.1217 11.6667 6.66146 11.6667C6.20122 11.6667 5.82812 12.0398 5.82812 12.5V14.1667C5.82812 14.6269 6.20122 15 6.66146 15C7.1217 15 7.49479 14.6269 7.49479 14.1667V12.5Z" fill={color} />
  </svg>
);

export default PerformancePlainSvg;
