import { BusinessId } from 'src/business/domain/Business';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { QUERYKEY_MENU_PRODUCTS } from './getAllProductByBusinessIdQuery';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ProductRepositoryHttp } from '../../repositories/ProductRepositoryHttp';
import { PaginatedProducts } from 'src/menu/dynamic-menu/domain/PaginatedProducts';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

type Pagination = {
  page: number;
  pageSize: number;
};

const searchProductsQueryKey = (
  businessId: BusinessId,
  { page, pageSize }: Pagination,
  productTypes: string[],
  filter?: string,
  advanced?: string,
  menuAssetsSource?: MenuAssetsSource,
) => [QUERYKEY_MENU_PRODUCTS, businessId, page, pageSize, filter, productTypes, advanced, menuAssetsSource] as const;

function searchProductsQuery({
  queryKey: [, businessId, page, pageSize, filter, productTypes, advancedFilter, menuAssetsSource],
}: QueryFunctionContext<ReturnType<typeof searchProductsQueryKey>>): Promise<PaginatedProducts> {
  const configuration = ConfigurationLoader.load();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);

  return productRepositoryHttp.searchProducts(businessId, page, pageSize, productTypes, filter, advancedFilter, menuAssetsSource);
}

export const useSearchProducts = (
  businessId: BusinessId,
  pagination: Pagination,
  productTypes: string[],
  filter?: string,
  advanced?: string,
  menuAssetsSource?: MenuAssetsSource,
) =>
  useQuery({
    queryKey: searchProductsQueryKey(businessId, pagination, productTypes, filter, advanced, menuAssetsSource),
    queryFn: searchProductsQuery,
  });
