import { useFormContext } from 'react-hook-form';
import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  EditControllingCompany,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/EditControllingCompany';
import {
  AddRepresentative,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/AddRepresentative';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  HasNoControllingCompaniesOptIn,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/ControllingCompanies/HasNoControllingCompaniesOptIn';

export const CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH = 666;


export const ControllingCompanies = () => {
  const intl = useIntl();
  const { watch, setValue, formState: { errors }, clearErrors } = useFormContext<CheckoutOnboardingInformationDraft>();
  const controllingCompanies = watch('controllingCompanies');
  const theme = useTheme();
  const hasNoControllingCompanies = watch('hasNoControllingCompanies');
  return <Stack spacing={3}>
    <Stack spacing={1}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.controllingCompanies'} />
      </Typography>
      <Typography variant={'subtitle2'} color={theme.palette.text.secondary}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.controllingCompanies.hint'} />
      </Typography>
    </Stack>
    {controllingCompanies?.map((representative, index) => {
      return <EditControllingCompany
        key={index + 'controllingCompanies'}
        index={index}
        onDelete={() => {
          setValue('controllingCompanies', controllingCompanies.filter((_, i) => i !== index));
          clearErrors(`controllingCompanies.${CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH}`);
        }}
      />;
    })}
    <AddRepresentative
      isDisabled={hasNoControllingCompanies}
      onAddRepresentative={() => {
        clearErrors(`controllingCompanies.${CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH}`);
        setValue('controllingCompanies', [...(controllingCompanies ?? []), {}]);
      }}
      buttonTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.add' })}
    />
    <HasNoControllingCompaniesOptIn hasAlreadyControllingCompanies={(controllingCompanies ?? []).length > 0} />
    <Typography marginLeft={'14px'} marginTop={'3px'} variant={'caption'} color={theme.palette.error.main}>
      {errors.controllingCompanies?.[CONTROLLING_COMPANIES_GLOBAL_ERROR_INDEX_PATH]?.message}
    </Typography>
  </Stack>;
};
