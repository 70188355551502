import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { queryKeys } from 'src/app/queries/utils';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useAddCommentsOnReport = () => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();
  const { reportId } = useParams();
  const reconciliationRepository = new ReconciliationRepositoryHttp();

  return useMutation({
    mutationFn: (comment: string) => reconciliationRepository.addComment(business.id, reportId!, comment),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.BUSINESSES, business, queryKeys.RECONCILIATION_REPORT] }),
  });
};
