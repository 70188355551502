// eslint-disable-next-line @typescript-eslint/no-redeclare
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { Alert, Box } from '@mui/material';

const ErrorWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
`;

const Center = styled.div`
  display: flex;
  margin: auto;
`;

export const InvoicesError = ({ onClose }: { onClose?: () => void }) => (
    <ErrorWrapper>
      <Center>
        <Box pb={2}>
          <Alert severity="error" onClose={onClose}>
            <FormattedMessage id="accounting.invoice_tab.invoice_loading_error" />
          </Alert>
        </Box>
      </Center>
    </ErrorWrapper>
);
