import { Button, styled } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { useNavigate } from 'react-router';
import { venueOPBillsPath } from 'src/app/navigation/pathHelpers';
import { EnrollmentId } from 'src/business/domain/Enrollment';

const Container = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
`;

type Props = {
  enrollmentId: EnrollmentId;
};

export const RedirectToAllOrderButton = ({ enrollmentId }: Props) => {
  const navigate = useNavigate();

  const onRedirect = () => {
    navigate(venueOPBillsPath(enrollmentId));
  };

  return (
    <Container>
      <Button variant="outlined" size='medium' onClick={onRedirect}>
        <FormattedMessage id="kds.redirection.orders"/>
      </Button>
    </Container>
  );
};
