import React, { FC, useEffect, useState } from 'react';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Box, Button, IconButton, TextareaAutosize, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useGetChargesDisplay } from 'src/ops-tooling/infrastructure/queries/useGetChargesDisplay';
import { useSaveChargesDisplay } from 'src/ops-tooling/infrastructure/mutations/useSaveChargesDisplay';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import ClearIcon from '@mui/icons-material/Clear';

export const ChargeDisplayCustomization: FC<{ enrollmentId: EnrollmentId }> = ({ enrollmentId }) => {
  const intl = useIntl();
  const snackbar = useSnackbar();
  const mutation = useSaveChargesDisplay();
  const { isLoading, data } = useGetChargesDisplay(enrollmentId);
  const [benefitSurchargeExplanationLabel, setBenefitSurchargeExplanationLabel] = useState<string | undefined>();

  useEffect(() => {
    setBenefitSurchargeExplanationLabel(data?.benefitSurchargeExplanationLabel);
  }, [data]);

  if (isLoading) {
    return <></>;
  }

  return (
    <Box display={'flex'} flexDirection="column">
      <Box display={'flex'} flexDirection="row" alignItems={'center'} gap={'16px'}>
        <Typography>
          <FormattedMessage id={'settings.internal_settings_pat.charges_display.benefit_surcharge'} />
        </Typography>
        <TextareaAutosize
          style={{
            borderRadius: '12px 12px 0 12px',
            width: '440px',
            maxWidth: '440px',
            padding: '6px',
            fontWeight: '400',
            lineHeight: '1.5',
            fontSize: '1rem',
          }}
          maxRows={4}
          value={benefitSurchargeExplanationLabel}
          onChange={(event) => {
            setBenefitSurchargeExplanationLabel(event.target.value);
          }}
        />
        <IconButton onClick={() => setBenefitSurchargeExplanationLabel('')}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <Button
          variant="contained"
          type="submit"
          onClick={() => {
            mutation
              .mutateAsync({
                enrollmentId,
                chargesDisplay: { benefitSurchargeExplanationLabel: benefitSurchargeExplanationLabel },
              })
              .then(() => {
                snackbar.addNotification({
                  variant: 'success',
                  text: intl.formatMessage({
                    id: 'settings.internal_settings_pat.charges_display.success_saving',
                    defaultMessage: 'Success',
                  }),
                });
              });
          }}
        >
          save
        </Button>
      </Box>
    </Box>
  );
};
