import { ReactNode } from 'react';
import { Box, styled, SwipeableDrawer } from '@mui/material';

type MenuDrawerProps = {
  children: ReactNode;

  open: boolean;
  onClose: () => void;
};

const CustomDrawer = styled(SwipeableDrawer)`
  .MuiPaper-root {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    padding-top: 12px;
  }
`;

const Puller = styled(Box)`
  width: 32px;
  height: 4px;
  background-color: #DDDDE4;
  border-radius: 2px;
  position: absolute;
  top: 12px;
  left: calc(50% - 16px);
`;

export const MenuDrawer = ({ open, onClose, children }: MenuDrawerProps) => (
  <CustomDrawer
    anchor="bottom"
    open={open}
    onOpen={() => {}}
    onClose={onClose}
  >
    <Puller />
    {children}
  </CustomDrawer>
);
