export const palette = {
  primary: {
    electricPink: '#FF17E9',
    black: '#000000',
    white: '#FFFFFF',
    black2: '#1E262B',
    black3: '#293136',
  },
  neutral: {
    grey100: '#F6F6F9',
    grey200: '#EDEDF2',
    grey300: '#DDDDE4',
    grey400: '#B4B4BB',
    grey500: '#70707B',
    grey600: '#424248',
    brandBlue: '#EBEDFB',
  },
  status: {
    success: '#336B42',
    danger: '#D11023',
  },
  progress: {
    positive: '#3FB05E',
    negative: '#D11023',
  },
};
