import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { useNavigate } from 'react-router';
import Authentication from '../../component/Authentication';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { Button, Stack, Typography } from '@mui/material';

export const SignOut = () => {
  const navigate = useNavigate();
  const onLogin = () => navigate(RouteDefinitions.SignIn.path);

  return (
    <Authentication titleKey="signout.title">
      <Stack spacing={4}>
        <Typography fontSize={'x-large'}>
          <FormattedMessage
            id="signout.see-you"
            defaultMessage="See you soon"
          />
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={onLogin}
        >
          <FormattedMessage id="signout.login" defaultMessage="Login again" />
        </Button>
      </Stack>
    </Authentication>
  );
};
