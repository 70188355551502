import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { Page } from '@templates/PageTemplate';
import { TitleFormat } from 'src/home2/types/layout_types';
import { StatusCard } from '@organisms/Body/StatusCard/StatusCard';
import { HOME_STATE, HOME_STATUS } from '@constants/HomeConstants';
import { sundayTitle } from '@pages/home-page/containers/HomePage';

export const HomeStatus = ({ state }: { state: string }) => {
  const user = useAuthenticatedUserOrThrow();
  const welcomeTitle: TitleFormat[] = [
    {
      id: 'venue.home.welcome.title',
      values: {
        user_name: user.firstName,
      },
    },
  ];

  const statusData = {
    button: HOME_STATUS[state].BUTTON,
    media: HOME_STATUS[state].MEDIA,
    subtitles: HOME_STATUS[state].SUBTITLES,
    title: HOME_STATUS[state].TITLE,
  };

  return (
    <Page
      hasHeadTitle={{
        preLogoText: welcomeTitle,
        hasLogo: true,
        postLogoText: [sundayTitle],
        variant: 'h4',
      }}
      hasHeadSubTitle={state === HOME_STATE.NULL}
    >
      <StatusCard {...statusData} />
    </Page>
  );
};
