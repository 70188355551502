import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { useCoverUseCase } from 'src/pages/Settings/Customization/components/Pictures/useCoverUseCase';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { ButtonContainer, CustomizationForm } from 'src/pages/Settings/Customization/Customization.styles';
import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material';
import { InputColor } from 'src/pages/Settings/Customization/components/Pictures/CoverBackgroundColorHandler.styles';
import { useForm } from 'react-hook-form';


export const CoverColorCustomizer = ({ saveCoverBackgroundColor, coverBackgroundColor }: {
  coverBackgroundColor: string;
  saveCoverBackgroundColor: (color: string) => Promise<void>;
}) => {
  const [isDirtyFromColorInputs, setIsDirtyFromColorInputs] = React.useState(false);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isValid, isSubmitting, isDirty, errors },
  } = useForm<{
    coverBackgroundColor: string;
  }>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      coverBackgroundColor: coverBackgroundColor ?? '#FFFFFF',
    },
  });

  const watchCoverBackgroundColor = watch('coverBackgroundColor');

  const onSubmit = async (inputs: { coverBackgroundColor: string }) => {
    await saveCoverBackgroundColor(inputs.coverBackgroundColor);
    reset(inputs, { keepDirty: false });
    setIsDirtyFromColorInputs(false);
  };

  const intl = useIntl();

  return (
    <CustomizationForm onSubmit={handleSubmit(onSubmit)}>

      <Stack spacing={2}>
        <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <TextField
            fullWidth
            label={intl.formatMessage({ id: 'settings.app_settings.logo.crop.background' })}
            placeholder={'#FFFFFF'} InputLabelProps={{ shrink: true }}
            error={!!errors.coverBackgroundColor}
            {...register('coverBackgroundColor', {
              deps: [],
              required: false,
            })} />
          <Box display={'flex'} alignItems={'center'} gap={1} flexDirection={'row'}>
            <InputColor
              type="color"
              value={watchCoverBackgroundColor}
              onChange={(e) => {
                setValue('coverBackgroundColor', e.target.value);
                setIsDirtyFromColorInputs(true);
              }}
            />
          </Box>
        </Stack>
        {isValid && (isDirty || isDirtyFromColorInputs) && (
          <ButtonContainer>
            <Button type="submit" variant="contained" size={'small'} disabled={!isValid || isSubmitting || (!isDirty && !isDirtyFromColorInputs)}>
              <FormattedMessage id="settings.save_changes" />
            </Button>
          </ButtonContainer>
        )}
      </Stack>
    </CustomizationForm>
  );
};

export const CoverColorCustomizerWrapper = ({ enrollmentId }: { enrollmentId: EnrollmentId }) => {
  const {
    saveCoverBackgroundColor,
    coverBackgroundColor,
    loading,
  } = useCoverUseCase(enrollmentId);

  if (loading) return <CircularProgress />;

  return (
    <CoverColorCustomizer saveCoverBackgroundColor={saveCoverBackgroundColor}
                          coverBackgroundColor={coverBackgroundColor} />
  );
};

