import { QueryClient, QueryFunction, QueryKey } from '@tanstack/react-query';
import { BusinessId } from 'src/business/domain/Business';
import { PosConnectionId } from 'src/pos/domain/PosConnection';

export type GetOrFetchQuery<Data> = { queryKey: QueryKey; queryFn: QueryFunction<Data> };

export const getOrFetch = async <Data>(queryClient: QueryClient, query: GetOrFetchQuery<Data>): Promise<Data> =>
  queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));

export const queryKeys = {
  GUEST_DATA_COLLECTION: 'guest_data_collection',
  BUSINESSES: 'businesses',
  ENTERPRISE: 'enterprise',
  VENUE_KPI: 'venue_kpi',
  REWARD_PROGRAM: 'reward_program',
  USERS: 'users',
  FEATURE_FLAGS: 'feature_flags',
  RECONCILIATION_REPORT: 'reconciliation_report',
  RECONCILIATION_REPORT_SETTINGS: 'reconciliation_report_settings',
  RECONCILIATION_POS_PAYMENT_METHODS: 'reconciliation_posPaymentMethods',
  SHIFTS: 'shifts',
  REVIEWS_SEVEN_DAYS_RATING_DISTRIBUTIONS: 'reviews_seven_days_rating_distributions',
  REVIEWS_DISTRIBUTION_BY_RATING_IN_TIMERANGE: 'reviews_distribution_by_rating_in_timerange',
  REVIEWS_DIMENSIONS_ANALYSIS_IN_TIMERANGE: 'reviews_dimensions_analysis_in_timerange',
  LIST_OF_REVIEWS_TO_REPLY: 'list_of_reviews_to_reply',
  REVIEWS_E_REPUTATION: 'reviews_e_reputation',
  REVIEWS_ANALYSIS: 'reviews_analysis',
  LIST_OF_REPLY_TEMPLATES: 'list_of_reply_templates',
  GET_GLOBAL_CONFIGURATION: 'get global configuration',
  GET_ENROLLMENT_FOR_PAYMENT: 'get enrollment for payment',
  LIST_BUSINESS_USERS: 'list business users',
  ordering: {
    LIST_BOXES: 'list boxes',
  },
  booking: {
    IS_ENROLLMENT_CONFIGURED: 'is enrollment configured',
    LIST: 'list bookings',
    GET_BY_ID: 'get booking by id',
    CONFIGURATION: 'get configuration',
    FLOW: 'get booking flow',
    FLOW_BY_RULES: 'get booking flow by rules',
  },
  loyalty: {
    LIST_PONGO_STORES: 'list pongo stores',
    GET_BUSINESS_LOYALTY_REFERENCE: 'get business loyalty reference',
  },
  waiters: {
    LIST: 'list waiters',
    LIST_SHARED_DEVICES: 'list shared devices',
    CODE_FOR_REGISTRATION_ON_SFS_APP: 'code_for_registration_on_sfs_app',
    LIST_INVITATIONS: 'invitations list',
  },
  FLOOR_PLAN: 'floor-plan',
  BILLS: 'bills',
  STAFF_PERFORMANCE: 'staff-performance',
  enrollmentSettings: {
    customization: {
      GET_CUSTOMIZATION: 'get customization',
    },
    whiteLabel: {
      GET_CUSTOMIZATION: 'get white label customization',
    },
    payments: {
      UPLOAD_FILE: 'upload file',
      GET_CHECKOUT_ONBOARDING_INFORMATION: 'get checkout onboarding information',
      BANK_ACCOUNT_INFORMATION: 'get checkout onboarding information',
    },
  },
  reviewsSetup: {
    all: (businessId: BusinessId) => ['reviews_setup', businessId] as const,
    googleStatus: (businessId: BusinessId) => [...queryKeys.reviewsSetup.all(businessId), 'google_status'] as const,
    googleLocation: (businessId: BusinessId) => [...queryKeys.reviewsSetup.all(businessId), 'google_location'] as const,
  },

  reviews: {
    GOOGLE_SETUP_STATUS: 'google setup status',
    GOOGLE_SETUP_LOCATION: 'google setup location',
  },
  tabs: {
    CAPTURE_STATUS: 'tab capture status',
  },
  staticMenus: {
    LIST_MENUS: 'list static menus',
  },
  tips: {
    GET_TIPS_SUGGESTIONS: 'get tips suggestions',
    GET_WAITERS_NOT_LINKED: 'get waiters not linked',
  },
  REVIEWS_AI_SETTINGS: 'reviews_ai_settings',
  HOME: {
    GET_REVIEWS_GRAPH_DATA: 'get reviews graph data',
  },
  sundayTipsPooling: {
    GET_PENDING_TIPS_DISPATCH: 'get pending tips dispatch',
    GET_DONE_TIPS_DISPATCH: 'get done tips dispatch',
    GET_ALL_STAFF: 'get all staff',
  },
  accounting: {
    clawbacks: {
      LIST_CLAWBACKS: 'list clawbacks',
      DOWNLOAD_CLAWBACKS_REPORT: 'download clawbacks report',
    },
  },
  endOfService: {
    reconciliationReport: 'reconciliation_report',
  },
  pos_connections: {
    all: (businessId: BusinessId) => ['pos_connections', businessId] as const,
    options: (businessId: BusinessId, posConnectionId: PosConnectionId) =>
      [...queryKeys.pos_connections.all(businessId), 'options', posConnectionId] as const,
  },
} as const;
