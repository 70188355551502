import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { queryKeys } from 'src/app/queries/utils';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useDeleteReportMutation = () => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();
  const reconciliationRepository = new ReconciliationRepositoryHttp();

  return useMutation({
    mutationFn: (reportId: string) => reconciliationRepository.delete(business.id, reportId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.BUSINESSES, business.id, queryKeys.RECONCILIATION_REPORT] });
    },
  });
};
