import { Drawer, styled } from '@mui/material';
import { BusinessSelection } from 'src/business/components/BusinessSelection';
import { NavigationItem } from './NavigationItems';
import { MobileMenuDrawer } from './component/MobileMenuDrawer';
import { MobileUserMenu } from './component/MobileUserMenu';

type MobileNavigationMenuProps = {
  menuItems: NavigationItem[];
};

const Container = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 8px 16px;
  justify-content: space-between;
`;

const MenuAndVenues = styled('div')`
  display: flex;
  align-items: center;
`;

export const MobileNavigationMenu = ({ menuItems }: MobileNavigationMenuProps) => (
  <Drawer PaperProps={{ elevation: 3 }} variant="permanent" elevation={3} anchor="top">
    <Container>
      <MenuAndVenues>
        <MobileMenuDrawer menuItems={menuItems} />
        <BusinessSelection compact={false} />
      </MenuAndVenues>
      <MobileUserMenu />
    </Container>
  </Drawer>
);
