import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const Email = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.email;

  return <TextField
    required
    type={'email'}
    error={!!error}
    InputLabelProps={{ shrink: true }}
    {...register(`representatives.${representativeIndex}.email`, {
      required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.email.error.required' }),
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.email.error.pattern' }),
      },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.email' })}
    helperText={error?.message || ''}
  />;
};
