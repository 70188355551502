import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { DispatchDetails } from 'src/tips/dispatch/model/DispatchDetails';
import { SundayPoolingRepositoryHttp } from 'src/tips/dispatch/infrastructure/SundayPoolingRepositoryHttp';

export const QUERY_KEY_GET_DISPATCH_DETAILS = 'get_dispatch_details';

const buildQueryKey = (businessId: BusinessId, dispatchId: string) =>
  [QUERY_KEY_GET_DISPATCH_DETAILS, businessId, dispatchId] as const;

const getDispatchDetailsQuery = (businessId: BusinessId, dispatchId: string): Promise<DispatchDetails> => {
  const configuration = ConfigurationLoader.load();
  const repository = new SundayPoolingRepositoryHttp(configuration.bookkeepingApiBaseUrl);
  return repository.getDispatchDetails(businessId, dispatchId);
};

export const useGetDispatchDetails = (businessId: BusinessId, dispatchId: string) =>
  useQuery({
    queryKey: buildQueryKey(businessId, dispatchId),
    queryFn: () => getDispatchDetailsQuery(businessId, dispatchId),
  });
