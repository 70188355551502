import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { LoyaltyDocumentationLink } from 'src/loyalty/LoyaltyDocumentationLink';

export const PongoValidationBurnModal = ({ visible, disabled, onClick, onClose }: {
  visible: boolean;
  disabled: boolean;
  onClick: () => void;
  onClose: () => void
}) => {
  const [isOnRightPos, setIsOnRightPos] = useState(false);
  const [isTenderType, setIsTenderType] = useState(false);
  const [isBurnByAmount, setIsBurnByAmount] = useState(false);
  const { formatMessage } = useIntl();

  const invalidBurnCondition = !isOnRightPos || !isTenderType || !isBurnByAmount;

  return (
    <Dialog
      open={visible}
      onClose={onClose}
    >
      <DialogTitle color="error">
        <Typography variant="h5">
          {formatMessage({
            id: 'loyalty.onboarding.burn_validation.title',
          })}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <DialogContentText>
          <Typography variant="body1">
            {formatMessage({
              id: 'loyalty.onboarding.burn_validation.content',
            }, { pos: 'IKENTOO' })}
          </Typography>
        </DialogContentText>
        <FormControlLabel
          label={formatMessage({
            id: 'loyalty.onboarding.burn_validation.certify_pos',
          }, { pos: 'LIGHTSPEED (ikentoo)' })}
          control={
            <Checkbox
              id="assertIsOnRightPos"
              defaultChecked={isOnRightPos}
              onChange={() => setIsOnRightPos(!isOnRightPos)} />}
        />
        <FormControlLabel
          label={formatMessage({
            id: 'loyalty.onboarding.burn_validation.burn_tender_type',
          })}
          control={
            <Checkbox
              id="isTenderReward"
              defaultChecked={isTenderType}
              onChange={() => setIsTenderType(!isTenderType)} />}
        />

        <FormControlLabel
          label={formatMessage({
            id: 'loyalty.onboarding.burn_validation.burn_by_amount',
          })}
          control={
            <Checkbox
              id="isBurnByAmount"
              defaultChecked={isBurnByAmount}
              onChange={() => setIsBurnByAmount(!isBurnByAmount)} />}
        />
        <LoyaltyDocumentationLink
          link="https://www.notion.so/sundayapp/Ikentoo-Pongo-Burn-Onboarding-process-dc3579d9fc4549f0a57ab8922993e593"
          pos="Lightspeed"
          loyaltyProvider="Pongo"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="error"
          onClick={onClick}
          disabled={disabled || invalidBurnCondition}
        >
          {formatMessage({ id: 'loyalty.onboarding.continue' })}
        </Button>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          {formatMessage({ id: 'loyalty.onboarding.cancel' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
