import { Outlet, useNavigate } from 'react-router';
import React from 'react';
import { PaymentLinksTable } from './PaymentLinksTable';
import { usePaymentLinks } from '../queries/paymentLinks';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { PaymentLink } from '../domain/PaymentLink';
import { NoPaymentLinkLanding } from './NoPaymentLinkLandingPage';
import { TableLoadingState } from 'src/components/table/TableLoadingState';
import { translation } from 'src/accounting/common/translation';
import { RouteDefinitions, subPath } from 'src/app/RouteDefinitions';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Business, BusinessId } from 'src/business/domain/Business';

export type PaymentLinkPageOutletContext = {
  paymentLinks: PaymentLink[];
  enrollmentId: EnrollmentId
  businessId: BusinessId
};

export const paymentLinkEnrollmentId = (business: Business) => {
  return (business.patEnrollment || business.oapEnrollment)?.id;
};

export const PaymentLinkPage = () => {
  const business = useCurrentBusinessOrThrow();
  const navigate = useNavigate();
  const {
    isLoading,
    data: allPaymentLinks,
    error,
  } = usePaymentLinks(business.id);
  const openCreateLinkModal = () => navigate(subPath(RouteDefinitions.PaymentLinksNew));
  const enrollmentId = paymentLinkEnrollmentId(business);

  if (!enrollmentId) {
    return <>Payment links are not available for this business</>;
  }

  if (isLoading) {
    return <TableLoadingState title={translation('payment_link.table.loading_state.title')}
                              subtitle={translation('payment_link.table.loading_state.subtitle')} />;
  }

  if (error) {
    return <>Error while loading payment links. Please try again.</>;
  }

  const context: PaymentLinkPageOutletContext = {
    paymentLinks: allPaymentLinks || [],
    enrollmentId: enrollmentId,
    businessId: business.id,
  };

  return (
    <>

      {(!allPaymentLinks || allPaymentLinks.length === 0)
        ? <NoPaymentLinkLanding openCreateLinkModal={openCreateLinkModal} />
        : <PaymentLinksTable openCreateLinkModal={openCreateLinkModal}
                             allPaymentLinks={allPaymentLinks} />
      }

      <Outlet context={context} />
    </>
  );
};
