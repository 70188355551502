// @ts-ignore
import { AlertProps, Theme } from '@mui/material';

export const AlertTheme = {
  styleOverrides: {
    root: ({ ownerState, theme }: { ownerState: AlertProps; theme: Theme }) => ({
      borderRadius: '8px',
      width: '100%',
      alignItems: 'center',
      ...(ownerState.severity === 'error' && {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.primary,
        '& .MuiAlert-icon': {
          color: theme.palette.error.main,
        },
      }),
      ...(ownerState.severity === 'success' && {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
        '& .MuiAlert-icon': {
          color: theme.palette.success.contrastText,
        },
      }),
      ...(ownerState.severity === 'info' && {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.info.dark,
        '& .MuiAlert-icon': {
          color: theme.palette.info.dark,
        },
        '& .MuiAlert-message': {
          width: '100%',
        },
      }),
    }),
  },
};
