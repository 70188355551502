import { BusinessId } from 'src/business/domain/Business';
import {
  VariantGroupCreationRequest,
  VariantGroupUpdateRequest,
} from 'src/menu/dynamic-menu/repositories/VariantGroupDto';

export class VariantGroupVariantError extends Error {

  constructor(public readonly type = 'variant_group_variants') {
    super(type);
  }
}

export const isVariantGroupVariantError = (error: unknown): error is VariantGroupVariantError => {
  return error !== null && typeof error === 'object' && 'type' in error && error.type === 'variant_group_variants';
};

export interface VariantGroupRepository {

  createVariantGroup(businessId: BusinessId, request: VariantGroupCreationRequest): Promise<string>;

  updateVariantGroup(businessId: BusinessId, variantGroupId: string, request: VariantGroupUpdateRequest): Promise<void>;
}
