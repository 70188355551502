import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsRepeatKpis = () => {
  // useEffect(() => {
  //   ampli.dataAnalyticsRepeatKpis({
  //     businessId: business.id,
  //     businessName: business.name,
  //   });
  // }, []);

  return <DataAnalyticsIframeComponent dashboard={MetabaseDashboard.REPEAT_KPIS_ENGLISH} />;
};
