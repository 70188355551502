import { useContext } from 'react';
import { PaymentConfigContext } from './configuration/PaymentContextProvider';
import { OrderingPaymentConfigContext } from './configuration/OrderingPaymentContextProvider';

export const usePaymentRepository = () => {
  const { paymentRepository } = useContext(PaymentConfigContext);
  return paymentRepository!;
};

export const useOrderingPaymentRepository = () => {
  const { orderingPaymentRepository } = useContext(OrderingPaymentConfigContext);
  return orderingPaymentRepository!;
};
