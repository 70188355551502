import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpSharedDeviceRepository } from 'src/app-for-restaurants/infrastructure/HttpSharedDeviceRepository';
import { UserInvitation } from 'src/app-for-restaurants/domain/UserInvitation';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { queryKeys } from 'src/app/queries/utils';

type MutationArgs = { invitations:UserInvitation[], business:string };

export const useUsersInvite = (businessId: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const httpBusinessesRepository = new HttpSharedDeviceRepository(configuration.waiterAppBackendUrl);
  const snackBar = useSnackbar();
  const intl = useIntl();
  const mutation = useMutation({
    mutationFn: ({ invitations, business }: MutationArgs) =>
      httpBusinessesRepository.inviteUserWithRole(invitations, business),
    onSuccess: (_, variables) => {
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: `sunday_for_restaurants.access.menu.invitation.success${variables.invitations.length > 1 ? '_plural' : ''}` }),
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.waiters.LIST_INVITATIONS, { businessId }] });
    },
    onError: () => {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'sunday_for_restaurants.access.menu.invitation.error' }),
      });
    },
  });

  return {
    createInvite: (args: MutationArgs) => mutation.mutateAsync(args),
  };
};
