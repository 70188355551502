import React from 'react';
import { Money, money, MoneyView } from '@sundayapp/web-money';
import { MethodType, Reconciliation } from '../../domain/Reconciliation';
import { PaymentOrigin } from '../../domain/PaymentOrigin';
import { cashPayments, CashPayments } from '../../domain/PaymentData';
import { EditableAmountCell } from './EditableAmountCell';
import { useUpdateReconciliationLineAmount } from '../../mutations/updateReconciliationLine';
import { isClosed } from '../../domain/ReconciliationReport';
import { CashPaymentSummaryCell } from '../cash/CashPaymentSummaryCell';
import { useCurrentReportOrThrow } from '../../hooks/useCurrentReportOrThrow';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type Props = {
  tender: Reconciliation,
  origin: PaymentOrigin
};

export const PaymentDataCell = ({ tender, origin }: Props) => {
  const paymentData = origin === PaymentOrigin.OnPos ? tender.onPos : tender.reported;
  const { currency } = useCurrentBusinessOrThrow();
  const report = useCurrentReportOrThrow();

  const mutation = useUpdateReconciliationLineAmount(tender.method);

  const save = (newAmount: Money, metadata?: CashPayments) => {
    mutation.mutate({ origin, newAmount, metadata });
  };

  if (!paymentData) {
    return <></>;
  }

  const amountOrZero = paymentData.amount ?? money(0, currency);

  if (isClosed(report) || !paymentData.editable) {
    return (<MoneyView value={amountOrZero} />);
  }

  if (tender.method.type === MethodType.Cash) {
    return (
      <CashPaymentSummaryCell
        amount={amountOrZero}
        payments={cashPayments(paymentData)}
        onSave={save}
      />
    );
  }

  return <EditableAmountCell editable amount={paymentData.amount} onSave={save} />;
};
