import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button } from '@sundayapp/b2b-react-component-library';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import Input from '../../../../../common/components/Input';
import { PickupAddressType } from '../../../../domain/BoxDetails';

type ClickAndCollectAddressProps = {
  pickupAddress: PickupAddressType;
  onPickupAddressChanged: (updatedAddress: PickupAddressType) => void;
  disabled: boolean;
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${getSpacing(1)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: ${getSpacing(1)};
`;

export const Label = styled.div`
  display: flex;
  margin-right: ${getSpacing(2)};
  min-width: ${getSpacing(12)};
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-right: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
`;

const Title = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colorPalette.defaultTextColor};
  margin-bottom: ${getSpacing(1)};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
`;

const AddressInput = styled(Input)`
  width: 100%;
`;

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
  margin-bottom: ${getSpacing(1)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const isDifferentPickupAddressFields = (value1: string | undefined, value2: string | undefined): boolean => value1 !== value2;

const isDifferentPickupAddresses = (pickupAddress: PickupAddressType, pickupAddress2: PickupAddressType): boolean => (
  isDifferentPickupAddressFields(pickupAddress.line1, pickupAddress2.line1)
    || isDifferentPickupAddressFields(pickupAddress.line2, pickupAddress2.line2)
    || isDifferentPickupAddressFields(pickupAddress.zipCode, pickupAddress2.zipCode)
    || isDifferentPickupAddressFields(pickupAddress.city, pickupAddress2.city)
);

export const ClickAndCollectAddress = ({
  pickupAddress,
  onPickupAddressChanged,
  disabled,
}: ClickAndCollectAddressProps) => {
  const [currentAddress, setCurrentAddress] = useState<PickupAddressType>(pickupAddress);

  const isAddressUpdated = isDifferentPickupAddresses(currentAddress, pickupAddress);

  return (
    <Container>
      <TitleContainer>
        <Title>
          <FormattedMessage id="venue.click-and-collect.modal.pickup-address.label" defaultMessage="pickup address" />
        </Title>
        <Description>
          <FormattedMessage
            id="venue.click-and-collect.modal.pickup-address.description"
            defaultMessage="where customer will pickup their food"
          />
        </Description>
      </TitleContainer>
      <Row>
        <Label>
          <FormattedMessage
            id="venue.click-and-collect.modal.pickup-address.line1.placeholder"
            defaultMessage="Line 1"
            description="first line of the pickup address usually for the street but can be a box name too"
          />
        </Label>
        <AddressInput
          value={currentAddress.line1}
          onValueChange={(line1: string) => setCurrentAddress({ ...currentAddress, line1 })}
          disabled={disabled}
        />
      </Row>
      <Row>
        <Label>
          {' '}
          <FormattedMessage
            id="venue.click-and-collect.modal.pickup-address.line2.placeholder"
            defaultMessage="Line 2"
            description="second line of the pickup address, optional but can be the street when the box name is used in the first line"
          />
        </Label>
        <AddressInput
          value={currentAddress.line2}
          onValueChange={(line2: string) => setCurrentAddress({ ...currentAddress, line2 })}
          disabled={disabled}
        />
      </Row>
      <Row>
        <Label>
          {' '}
          <FormattedMessage
            id="venue.click-and-collect.modal.pickup-address.zipcode.placeholder"
            defaultMessage="Zip code"
            description="zip code of the pickup location"
          />
        </Label>
        <Input
          value={currentAddress.zipCode}
          onValueChange={(zipCode: string) => setCurrentAddress({ ...currentAddress, zipCode })}
          disabled={disabled}
        />
      </Row>
      <Row>
        <Label>
          {' '}
          <FormattedMessage
            id="venue.click-and-collect.modal.pickup-address.city.placeholder"
            defaultMessage="City"
            description="city of the pickup location"
          />
        </Label>
        <AddressInput
          value={currentAddress.city}
          onValueChange={(city: string) => setCurrentAddress({ ...currentAddress, city })}
          disabled={disabled}
        />
      </Row>
      {isAddressUpdated && (
        <Line>
          <SaveButton
            variant="primary"
            size="xSmall"
            onClick={() => onPickupAddressChanged(currentAddress)}
            disabled={disabled}
          >
            <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
          </SaveButton>
        </Line>
      )}
    </Container>
  );
};
