import React, { createContext, ReactNode, useMemo } from 'react';
import { AreaConfig } from './AreaConfig';
import { AreaRepositoryHttp } from '../repositories/AreaRepositoryHttp';

type AreaContextProviderProps = {
  orderingBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = AreaConfig & {
  children: ReactNode;
};

export const AreaConfigContext = createContext<Partial<AreaConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => <AreaConfigContext.Provider value={config}>{children}</AreaConfigContext.Provider>;

export const AreaContextProvider = ({ orderingBackendUrl, children }: AreaContextProviderProps) => {
  // Repo
  const areaRepository = useMemo(() => new AreaRepositoryHttp(orderingBackendUrl), [orderingBackendUrl]);

  return <Provider areaRepository={areaRepository}>{children}</Provider>;
};
