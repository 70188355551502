import { Button, InputText, Panel, spaceUsages } from '@sundayapp/b2b-react-component-library';
import { Box, CircularProgress, Typography } from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useBoolean } from 'react-use';
import { useCreateReconciliationReportMutation } from '../mutations/useCreateReconciliationReportMutation';
import { venueReconciliationReportPath } from 'src/app/navigation/pathHelpers';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useListReconciliationReportsByStatus } from '../queries/listReports';
import { CashupStatus } from '../domain/ReconciliationReport';
import { ConfirmSkipReportDaysModal } from './ConfirmSkipReportDaysModal';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { DatePicker } from 'src/components/date-picker/DatePicker';
import { RestaurantShiftPicker } from '../../restaurant-shifts/RestaurantShiftPicker';
import {
  RestaurantShiftTimesOptionType,
  restaurantShiftTimesOptionTypes,
} from '../../restaurant-shifts/models/RestaurantShiftTimesOption';
import {
  closestClosableRestaurantShift,
  reconciliationReportRestaurantShiftRequest,
} from '../domain/ReconciliationReportRestaurantShiftRequest';
import { useRestaurantShiftTimesOptions } from '../../restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { createReportNeedsConfirmation, getAllowedCreateReportDate } from '../domain/AllowedCreateReportDate';

const CreationReportForm = styled.form`
  width: 100%;

  input[type='text'] {
    border-radius: 5px;
  }
`;

type CreateReportForm = {
  shiftType: RestaurantShiftTimesOptionType;
  dateOfShift: Date;
  reporter: string;
};

const ReconciliationCreation = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const useSnackBar = useSnackbar();
  const business = useCurrentBusinessOrThrow();
  const createReportMutation = useCreateReconciliationReportMutation(business.id, business.currency);
  const [isCreating, setCreating] = useState(false);
  const { data: reports, isLoading: isLoadingReports } = useListReconciliationReportsByStatus(
    business.id,
    CashupStatus.Closed,
  );
  const user = useAuthenticatedUserOrThrow();
  const [showConfirmSkipModal, setShowConfirmSkipModal] = useBoolean(false);

  const { options: restaurantShiftsDefinitionOptions, loaded } = useRestaurantShiftTimesOptions();

  const [form, setForm] = useState<CreateReportForm>({
    shiftType: restaurantShiftTimesOptionTypes.ALL_SHIFTS,
    dateOfShift: new Date(),
    reporter: user.email,
  });

  const allowedCreateReportDate = getAllowedCreateReportDate(reports || [], restaurantShiftsDefinitionOptions);

  useEffect(() => {
    if (!loaded) {
      return;
    }

    const { reconciliationReportRequest, restaurantShiftTimesOptionType } = closestClosableRestaurantShift(
      restaurantShiftsDefinitionOptions,
    );
    setForm({
      shiftType: restaurantShiftTimesOptionType,
      dateOfShift: reconciliationReportRequest.startAt,
      reporter: user.email,
    });
  }, [loaded]);

  if ((!loaded || isLoadingReports) && user) {
    return <CircularProgress />;
  }

  function createReport(formValues: CreateReportForm) {
    setCreating(true);

    const shiftOption = restaurantShiftsDefinitionOptions[formValues.shiftType];
    const reportRestaurantShift = reconciliationReportRestaurantShiftRequest(formValues.dateOfShift, shiftOption);

    createReportMutation
      .mutateAsync({ reportRestaurantShift, reporter: formValues.reporter })
      .then((report) => {
        navigate(venueReconciliationReportPath(business.id, report.id));
      })
      .catch((error: TypeError) => {
        console.log(`creation ${error}`);
        if (error.message === 'Request failed with status code 409') {
          useSnackBar.addNotification({
            variant: 'error',
            text: intl.formatMessage({ id: 'reconciliation.error_report_with_same_parameters_already_exists' }),
          });
        }
      })
      .finally(() => setCreating(false));
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (createReportNeedsConfirmation(form.dateOfShift, allowedCreateReportDate)) {
      setShowConfirmSkipModal(true);
    } else {
      createReport(form);
    }
  };

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 30);

  return (
    <CreationReportForm onSubmit={onSubmit}>
      {showConfirmSkipModal && (
        <ConfirmSkipReportDaysModal
          createReportDate={form.dateOfShift}
          allowedCreateReportDate={allowedCreateReportDate}
          onConfirm={() => createReport(form)}
          onClose={() => setShowConfirmSkipModal(false)}
        />
      )}

      <Panel flexDirection="column">
        <Box pb={2}>
          {' '}
          <Typography variant="h5">
            <FormattedMessage id="reconciliation.new_report_title" />
          </Typography>
        </Box>

        <Box gap={2} width="100%" flexDirection="row" alignItems="center" display="flex" mt="10px">
          <Box gap={2} display="flex">
            <DatePicker
              date={form.dateOfShift}
              minDate={minDate}
              maxDate={new Date()}
              onClickDay={(setStateDate) =>
                setForm((actual) => ({
                  ...actual,
                  dateOfShift: setStateDate instanceof Date ? setStateDate : setStateDate(actual.dateOfShift),
                }))
              }
            />
            <RestaurantShiftPicker
              value={restaurantShiftsDefinitionOptions[form.shiftType]}
              onChange={(shiftOption) =>
                setForm((actual) => ({
                  ...actual,
                  shiftType: shiftOption.type,
                }))
              }
              options={restaurantShiftsDefinitionOptions}
            />
          </Box>
          <Box display="flex" width="100%">
            <InputText
              label={intl.formatMessage({ id: 'reconciliation.new_report_creator' })}
              id="reporter"
              value={form.reporter}
              required
              type="text"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setForm((actual) => ({
                  ...actual,
                  reporter: e.target.value,
                }))
              }
              marginBottom={spaceUsages.small}
            />
          </Box>

          <Box>
            <Button
              type="submit"
              variant="primary"
              size="medium"
              marginTop={spaceUsages.small}
              disabled={isCreating}
              sx={{ alignItems: 'center' }}
            >
              <FormattedMessage id="reconciliation.create_new_report" />
              {isCreating && (
                <>
                  &nbsp;
                  <CircularProgress />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </Panel>
    </CreationReportForm>
  );
};

export default ReconciliationCreation;
