import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useCoverUseCase } from './useCoverUseCase';
import PictureContainer from './PictureContainer';
import { CropPictureData } from 'src/domain/venue/CropperService';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import {
  CoverColorCustomizerWrapper,
} from 'src/pages/Settings/Customization/components/ColorCustomizers/CoverColorCustomizer';
import { Stack } from '@mui/material';

interface CoverHandlerProps {
  venueId: string;
}

export const CoverHandler: React.FC<CoverHandlerProps> = ({ venueId }: CoverHandlerProps) => {
  const {
    deleteCovers,
    uploadCover,
    tempCoverPicture,
    removeTempCoverPicture,
    saveCroppedPicture,
    editCoverPicture,
    coverUrl,
    loading,
  } = useCoverUseCase(venueId as EnrollmentId);
  return (
    <Stack spacing={2}>
      <PictureContainer
        inputId="cover"
        title={<FormattedMessage id="settings.app_settings.cover.cover" />}
        url={coverUrl}
        onCloseCropTool={removeTempCoverPicture}
        tempPicture={tempCoverPicture}
        uploadFile={(file: File) => uploadCover(file)}
        deletePicture={() => deleteCovers()}
        saveCroppedPicture={(crop: CropPictureData) => saveCroppedPicture(crop)}
        editPicture={editCoverPicture}
        aspect={16 / 9}
        colorSelector={false}
        cropShape="rect"
        loading={loading}
      />
      <CoverColorCustomizerWrapper enrollmentId={venueId as EnrollmentId} />
    </Stack>
  );
};
