export enum MetabaseDashboard {
  //ENGLISH & US
  OVERVIEW_ENGLISH = 24,
  BUSINESS_PERFORMANCE_ENGLISH = 26,
  CUSTOMER_INSIGHTS_ENGLISH = 25,
  ENTERPRISE_DASHBOARD_ENGLISH = 28,
  TIPS_ENGLISH = 9,
  REPEAT_KPIS_ENGLISH = 13,
  REVIEWS_ENGLISH = 49,

  //FRENCH
  OVERVIEW_FRENCH = 21,
  BUSINESS_PERFORMANCE_FRENCH = 22,
  CUSTOMER_INSIGHTS_FRENCH = 23,
  ENTERPRISE_DASHBOARD_FRENCH = 27,
  REVIEWS_FRENCH = 49,
}
