import { IconWrapper, Panel, PlusSvg } from '@sundayapp/b2b-react-component-library';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

interface AddPaymentMethodProps {
  addPaymentMethod: () => void
}

export const AddPaymentMethodSettingPanel = ({ addPaymentMethod }: AddPaymentMethodProps) => (
  <Panel
    flexDirection="row"
  >
    <Box
      sx={{
        display: 'flex', alignItems: 'left', gap: 2,
      }}
      width="100%"
    >
      <Typography variant="h5">
        <FormattedMessage id="reconciliation.settings.add_payment_method" />
      </Typography>
      <Box sx={{ marginTop: '5px', float: 'right' }}>
        <IconWrapper size="small" cursor="pointer" onClick={addPaymentMethod}>
          <PlusSvg />
        </IconWrapper>
      </Box>

    </Box>
    <Box />
  </Panel>

);
