import { Money, sumMoneys } from '@sundayapp/web-money';
import {
  PaymentSummary, PaymentSummaryStatus, Refund, TaxSummary,
} from '../types';

export const computeTaxes = (taxSummary: TaxSummary): Money => sumMoneys(...Object.values(taxSummary.taxes));

export const isPaymentSuccessful = (payment: PaymentSummary) => payment.status === PaymentSummaryStatus.SUCCESS;

export const isRefundSuccessful = (refund: Refund) =>
  refund.status === PaymentSummaryStatus.SUCCESS || refund.status === PaymentSummaryStatus.CREATED;
