import { WhiteLabelCustomizationRepository } from 'src/domain/customization/WhiteLabelCustomizationRepository';
import { Business } from 'src/business/domain/Business';
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { DocumentRepository } from 'src/domain/document/DocumentRepository';

export class ApplyPostPaymentBannerChanges {
  constructor(
    private documentRepositoryStorage: DocumentRepository,
    private whiteLabelCustomizationRepository: WhiteLabelCustomizationRepository,
  ) {}

  async apply(sourceBusiness: Business, targetBusinesses: Array<Business>) {
    const sourceCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      sourceBusiness.patEnrollment!.id,
    );
    for (const business of targetBusinesses) {
      await this.deletePostPaymentBanner(business);
      const savedPicture = await this.savePostPaymentBanner(sourceCustomization, business);
      await this.copyPostPaymentBanner(sourceCustomization, savedPicture, business);
    }
  }

  private async copyPostPaymentBanner(
    sourceCustomization: WhiteLabelCustomization,
    savedPicture: string | undefined,
    business: Business,
  ) {
    const currentCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      business.patEnrollment!.id,
    );
    const updatedCustomization = {
      ...currentCustomization,
      postPaymentBanner: { ...sourceCustomization.postPaymentBanner, imageUrl: savedPicture },
    };
    await this.whiteLabelCustomizationRepository.saveCustomization(business.patEnrollment!.id, updatedCustomization);
  }

  private async deletePostPaymentBanner(business: Business) {
    const existingCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      business.patEnrollment!.id,
    );
    if (existingCustomization.postPaymentBanner?.imageUrl) {
      await this.documentRepositoryStorage.deleteRefFromUrl(existingCustomization.postPaymentBanner.imageUrl);
    }
  }

  private async savePostPaymentBanner(
    sourceCustomization: WhiteLabelCustomization,
    business: Business,
  ): Promise<string | undefined> {
    if (!sourceCustomization.postPaymentBanner?.imageUrl) {
      return undefined;
    }
    const response = await fetch(sourceCustomization.postPaymentBanner?.imageUrl);
    const blob = await response.blob();
    const file = new File([blob], 'postPaymentBanner.jpg', { type: blob.type });
    return this.documentRepositoryStorage.saveFile(business.patEnrollment!.id, `customization/${file.name}`, file);
  }
}
