import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { AverageDimensionsRatingsChart } from './AverageDimensionsRatingsChart';
import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import { DimensionsTrendsViewModel, isLackOfData, isTimeFrameTooShort } from './DimensionsTrendsViewModel';
import { TrendsNotAvailable } from './TrendsNotAvailable';

interface Props {
  selectedDimension: DimensionAnalysis | undefined;
  viewModel: DimensionsTrendsViewModel;
}

export const DimensionsTrends = ({
  selectedDimension,
  viewModel,
}: Props) => {
  if (isLackOfData(viewModel)) {
    return (
      <TrendsNotAvailable>
        <FormattedMessage id="reviews.guest-experience.dimensions.graph_with_lack_of_data" defaultMessage="Not enough data for trends" />
      </TrendsNotAvailable>
    );
  }

  if (isTimeFrameTooShort(viewModel)) {
    return (
      <TrendsNotAvailable>
        <FormattedMessage id="reviews.guest-experience.dimensions.graph_with_short_time_frame" defaultMessage="Time frame too short for trends" />
      </TrendsNotAvailable>
    );
  }

  return (
    <AverageDimensionsRatingsChart
      averageDimensionsRatingsChartData={viewModel}
      selectedDimension={selectedDimension ? selectedDimension.name : undefined}
    />
  );
};
