import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { QUERYKEY_MENU_MENU_BY_ID } from '../../queries/menu/getMenuByIdQuery';
import { CategoryToCreate } from '../../domain/CategoryToCreate';
import { CategoryToUpdate } from '../../domain/CategoryToUpdate';
import { ElementDto } from '../../repositories/ProductDto';
import { BusinessId } from 'src/business/domain/Business';

export const useCreateCategoryMutation = (businessId: BusinessId, menuId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (categoryToCreate: CategoryToCreate) => menuRepository.createCategory(menuId, categoryToCreate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};
export const useUpdateCategoryMutation = (businessId: BusinessId, menuId: string, categoryId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (categoryToUpdate: CategoryToUpdate) => menuRepository.updateCategory(menuId, categoryId, categoryToUpdate),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};
export const useDeleteCategoryMutation = (businessId: BusinessId, menuId: string, categoryId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: () => menuRepository.deleteCategory(categoryId, menuId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};

export const useUpdateProductsInCategoryMutation = (businessId: BusinessId, menuId: string, categoryId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (productIds: string[]) => menuRepository.updateProductsInCategory(businessId, menuId, categoryId, productIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};

export const useUpdateBlocksInCategoryMutation = (businessId: BusinessId, menuId: string, categoryId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (blockIds: string[]) => menuRepository.updateBlocksInCategory(businessId, menuId, categoryId, blockIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};

export const useUpdateElementsInCategoryMutation = (businessId: BusinessId, menuId: string, categoryId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (elements: ElementDto[]) => menuRepository.updateElementsInCategory(businessId, menuId, categoryId, elements),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_MENU_BY_ID] });
    },
  });
};
