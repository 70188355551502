import { Alert, Box } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const ExportDownloadError = () => (
  <Box sx={{  margin: '16px 0 24px 0' }}>
    <Alert severity="error" >
      <FormattedMessage id="accounting.downloading_export_error" />
    </Alert>
  </Box>
);
