import React, { PropsWithChildren } from 'react';
import { Chip, CircularProgress, styled, Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { GoogleConnectButton } from './GoogleConnectButton';
import { GoogleSetupStage } from '../domain/GoogleSetupStage';
import { useGoogleSetupStage } from '../infrastruscture/useGoogleSetupStage';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';
import { GoogleOAuthFacadeProvider } from 'src/sundayplus/reviews/settings/setup/google/oauth-facade/GoogleOAuthFacadeProvider';

const ActivationFrame = styled('div')`
  background: black;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
`;

const ActivationText = styled('div')`
  > * + * {
    margin-top: 1.5rem;
  }

  > :nth-child(3) {
    margin-block-end: auto;
  }

  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`;
const ActivationContentText = styled('div')`
  > * + * {
    margin-top: 0.2rem;
  }
`;

type ConnectGoogleAccountProps = {
  isInitialSetup?: boolean;
};

const ConnectGoogleAccount = ({ isInitialSetup }: ConnectGoogleAccountProps) => {
  const intl = useIntl();
  const { isDesktopScreen } = useMediaBreakpoints();
  const businessId = useBusinessIdOrThrow();
  const { data: stage, isLoading } = useGoogleSetupStage(businessId);
  const theme = useTheme();

  if (!stage || isLoading) {
    return <CircularProgress />;
  }

  const status = stage.status;

  const chipKey = () => {
    if (status === GoogleSetupStage.Unhealthy) {
      return 'settings.reviews_and_loyalty.google_setup.content.to_fix';
    }
    return 'settings.reviews_and_loyalty.google_setup.content.to_complete';
  };

  return (
    <ActivationFrame>
      <ActivationText>
        {!isInitialSetup && (
          <Chip
            color="warning"
            label={intl.formatMessage({ id: chipKey() })}
            variant="filled"
            size="small"
            style={{
              width: '7rem',
              marginBottom: '0.5rem',
            }}
          />
        )}
        {!isInitialSetup && (
          <Typography variant="h6" color={theme.palette.secondary.light}>
            <FormattedMessage id="settings.reviews_and_loyalty.google_setup.title" />
          </Typography>
        )}
        <ActivationContentText>
          {status !== GoogleSetupStage.Unhealthy && (
            <>
              <Typography variant="body1" color={theme.palette.text.disabled}>
                <FormattedMessage id="settings.reviews_and_loyalty.google_setup.content.first_text" />
              </Typography>
              <Typography variant="body1" color={theme.palette.text.disabled}>
                <FormattedMessage id="settings.reviews_and_loyalty.google_setup.content.second_text" />
              </Typography>
              <Typography variant="body1" color={theme.palette.text.disabled}>
                <FormattedMessage id="settings.reviews_and_loyalty.google_setup.content.third_text" />
              </Typography>
            </>
          )}
        </ActivationContentText>
        <GoogleConnectButton currentStage={status} />
      </ActivationText>
      {isDesktopScreen && <img alt="Google with sunday" src="/img/reviews/google_not_connected.png" />}
    </ActivationFrame>
  );
};

export function RequireConnectedGoogleAccount(props: PropsWithChildren<ConnectGoogleAccountProps>) {
  const businessId = useBusinessIdOrThrow();
  const { data: googleSetupStatus } = useGoogleSetupStage(businessId);

  const status = googleSetupStatus?.status;

  if (!googleSetupStatus) {
    return <CircularProgress />;
  }

  return (
    <GoogleOAuthFacadeProvider>
      {status === GoogleSetupStage.Finished ? (
        props.children
      ) : (
        <ConnectGoogleAccount isInitialSetup={props.isInitialSetup} />
      )}
    </GoogleOAuthFacadeProvider>
  );
}
