import {
  CurrencyCode, money, Money, sumMoneys,
} from '@sundayapp/web-money';
import { z } from 'zod';
import { map } from 'lodash';

export const Reporter = z.object({
  name: z.string(),
  id: z.string(),
});

export const Denominations = z.record(z.number().optional());

export const CashPayment = z.object({
  reporter: Reporter,
  cashDenominations: Denominations,
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CashPayment = z.infer<typeof CashPayment>;

function valueOrZeroIfNullOrNaN(value?: number): number {
  return (!value || Number.isNaN(value)) ? 0 : value;
}

export const totalCashPayment = (cashPayment: CashPayment, currencyCode: CurrencyCode): Money => {
  const { cashDenominations } = cashPayment;

  const totalInCents = map(cashDenominations, (value, key) => +key * valueOrZeroIfNullOrNaN(value))
    .reduce((prev, curr) => prev + curr * 100000, 0);

  return money(totalInCents, currencyCode);
};

export const totalCashPayments = (cashPayment: CashPayment[], currencyCode: CurrencyCode): Money => cashPayment.map((payment) => totalCashPayment(payment, currencyCode))
  .reduce((prev, curr) => sumMoneys(prev, curr), money(0, currencyCode));
