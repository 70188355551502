import { BusinessId } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERYKEY_MENU_PRODUCTS } from 'src/menu/dynamic-menu/queries/product/getAllProductByBusinessIdQuery';
import { VariantGroupRepositoryHttp } from 'src/menu/dynamic-menu/repositories/VariantGroupRepositoryHttp';
import {
  VariantGroupCreationRequest,
  VariantGroupUpdateRequest,
} from 'src/menu/dynamic-menu/repositories/VariantGroupDto';
import { QUERYKEY_MENU_PRODUCT } from 'src/menu/dynamic-menu/queries/product/getProductByIdQuery';

export const useCreateVariantGroupMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const repository = new VariantGroupRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (createDto: VariantGroupCreationRequest) => repository.createVariantGroup(businessId, createDto),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] }),
  });
};

export const useUpdateVariantGroupMutation = (businessId: BusinessId, variantGroupId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const repository = new VariantGroupRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (request: VariantGroupUpdateRequest) => repository.updateVariantGroup(businessId, variantGroupId, request),
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] }),
        queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCT, businessId, variantGroupId] }),
      ]);
    },
  });
};
