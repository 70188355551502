import React, { createContext, ReactNode, useMemo } from 'react';
import { OrderConfig } from './OrderConfig';
import { OrderingOrderRepositoryHttp } from '../repositories/OrderingOrderRepositoryHttp';

type OrderContextProviderProps = {
  orderingBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = OrderConfig & {
  children: ReactNode;
};

export const OrderConfigContext = createContext<Partial<ProviderConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => (
  <OrderConfigContext.Provider value={config}>{children}</OrderConfigContext.Provider>
);

export const OrderingOrderContextProvider = ({ orderingBackendUrl, children }: OrderContextProviderProps) => {
  const repository = useMemo(() => new OrderingOrderRepositoryHttp(orderingBackendUrl), [orderingBackendUrl]);
  return <Provider orderRepository={repository}>{children}</Provider>;
};
