import { NavigateFunction } from 'react-router';
import { UserRepository } from 'src/domain/user/UserRepository';

export class CompleteSignUpUseCase {
  constructor(private userRepository: UserRepository, private navigate: NavigateFunction) {}

  async handle(email: string, lastName: string, firstName: string, phoneNumber: string) {
    await this.userRepository.completeUserAccount(email, lastName, firstName, phoneNumber);
    this.navigate('/');
  }
}
