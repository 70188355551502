/* eslint-disable */
import axios from 'axios';
import {CategoryCreatedDto, CreateSundayMenuDto, MenuDto, MenuImageUrlDto, MenusSummaryDto, MenuSummaryDto, UpdateMenuDto} from './MenuDto';
import {MenuDtoConverter} from './MenuDtoConverter';
import {CategoryCreated} from '../domain/CategoryCreated';
import {CategoryToCreate} from '../domain/CategoryToCreate';
import {CategoryToUpdate} from '../domain/CategoryToUpdate';
import {MenuRepository} from './MenuRepository';
import {MenuSummary} from '../domain/MenuSummary';
import {Menu} from '../domain/Menu';
import {RepositoryHttp} from 'src/ordering/common/repository/RepositoryHttp';
import {ElementDto} from './ProductDto';
import {BusinessId} from 'src/business/domain/Business';
import {MenuAssetsSource} from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import {MenuFeatureConfiguration, SundayFeature} from 'src/menu/dynamic-menu/domain/MenuFeatureConfiguration';
import { Box } from 'src/ordering/box/domain/Box';

export class MenuRepositoryHttp extends RepositoryHttp implements MenuRepository {
  constructor(private menuBackendUrl: string) {
    super();
  }

  async findAllByBusinessId(businessId: BusinessId): Promise<MenuSummary[]> {
    const response = await axios.get<MenusSummaryDto>(this.menuBackendUrl + `/businesses/${businessId}/menus`,
      this.withDefaultHeader());
    return this.convertMenuDtoToMenus(response.data.menus);
  }

  async findRelatedMenusForProduct(businessId: BusinessId, productId: string): Promise<MenuSummary[]> {
    const response = await axios.get<MenusSummaryDto>(this.menuBackendUrl + `/businesses/${businessId}/products/${productId}/menus`,
      this.withDefaultHeader());
    return this.convertMenuDtoToMenus(response.data.menus);
  }

  async findRelatedMenusForBlock(businessId: BusinessId, blockId: string): Promise<MenuSummary[]> {
    const response = await axios.get<MenusSummaryDto>(this.menuBackendUrl + `/businesses/${businessId}/blocks/${blockId}/menus`,
      this.withDefaultHeader());
    return this.convertMenuDtoToMenus(response.data.menus);
  }

  async findByMenuAssetsSource(businessId: BusinessId, menuAssetsSource: MenuAssetsSource | undefined): Promise<MenuSummary[]> {
    const params = menuAssetsSource ? this.buildGetMenusParams(menuAssetsSource) : undefined;
    const response = await axios.get<MenusSummaryDto>(this.menuBackendUrl + `/businesses/${businessId}/menus`,
      {
        ...this.withDefaultHeader(),
        params,
      });
    return this.convertMenuDtoToMenus(response.data.menus);
  }

  private buildGetMenusParams(menuAssetsSource: MenuAssetsSource): URLSearchParams {
    const params = new URLSearchParams();

    params.append('posConnectionId', menuAssetsSource.posConnectionId);
    if (menuAssetsSource.externalRevenueCenterId) {
      params.append('externalRevenueCenterId', menuAssetsSource.externalRevenueCenterId);
    }

    return params;
  }

  async findByMenuId(menuId: string): Promise<Menu> {
    const response = await axios.get<MenuDto>(this.menuBackendUrl + `/menus/${menuId}`,
      this.withDefaultHeader());
    return MenuDtoConverter.toMenu(response.data);
  }

  async refreshMenus(businessId: BusinessId, menuAssetsSource: MenuAssetsSource): Promise<void> {
    await axios.post(
      this.menuBackendUrl + `/businesses/${businessId}/menus-refresh`,
      {
        posConnectionId: menuAssetsSource.posConnectionId,
        externalRevenueCenterId: menuAssetsSource.externalRevenueCenterId,
      },
      this.withDefaultHeader(),
    );
  }

  async updateMenusOrder(businessId: BusinessId, menuIds: string[]): Promise<void> {
    await axios.put(this.menuBackendUrl + `/businesses/${businessId}/menus-order`, {
        menuIds: menuIds,
      },
      this.withDefaultHeader());
  }

  async createMenu(businessId: BusinessId, dto: CreateSundayMenuDto): Promise<Menu['id']> {
    try {
      const response = await axios.post(
        this.menuBackendUrl + `/businesses/${businessId}/menus`,
        {
          name: dto.name,
          posConnection: dto.menuAssetsSource ? {
            posConnectionId: dto.menuAssetsSource.posConnectionId,
            externalRevenueCenterId: dto.menuAssetsSource.externalRevenueCenterId,
          } : undefined,
        },
        this.withDefaultHeader(),
      );

      return response.data.id;
    } catch (e) {
      throw new Error('error creating the menu');
    }
  }

  async deleteMenu(menuId: string): Promise<void> {
    try {
      await axios.delete(this.menuBackendUrl + `/menus/${menuId}`,
        this.withDefaultHeader());
    } catch (e) {
      throw new Error('error deleting the menu');
    }
  }

  async migrateMenu(businessId: BusinessId, menuIds: string[], targetBusinessId: BusinessId, posConnectionId?: string, revenueCenterId?: string): Promise<void> {
    await axios.post(
      this.menuBackendUrl + `/businesses/${businessId}/share_menus`,
      {
        menuIds: menuIds,
        destinationBusinessId: targetBusinessId,
        destinationPosConnectionId: posConnectionId,
        destinationExternalRevenueCenterId: revenueCenterId,
      },
      this.withDefaultHeader(),
    );
  }

  async copyMenu(businessId: BusinessId, menuId: string): Promise<string> {
    try {
      const response = await axios.post(this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/copy`,
        this.withDefaultHeader());
      return response.data.id
    } catch (e) {
      throw new Error('error copying the menu');
    }
  }

  async createCategory(menuId: string, categoryToCreate: CategoryToCreate): Promise<CategoryCreated> {
    const response = await axios.post<CategoryCreatedDto>(
      this.menuBackendUrl + `/menus/${menuId}/categories`,
      {
        name: categoryToCreate.name,
        description: categoryToCreate.description,
      },
      this.withDefaultHeader(),
    );
    return {
      id: response.data.id,
    };
  }

  async deleteCategory(categoryId: string, menuId: string): Promise<void> {
    try {
      await axios.delete(this.menuBackendUrl + `/menus/${menuId}/categories/${categoryId}`,
        this.withDefaultHeader());
    } catch (e) {
      throw new Error('error deleting the category');
    }
  }

  async updateProductsInCategory(businessId: BusinessId, menuId: string, categoryId: string, productIds: string[]): Promise<void> {
    await axios.put(
      this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/categories/${categoryId}/products`,
      { productIds },
      this.withDefaultHeader(),
    );
  }

  async updateBlocksInCategory(businessId: BusinessId, menuId: string, categoryId: string, blockIds: string[]): Promise<void> {
    await axios.put(
      this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/categories/${categoryId}/blocks`,
      { blockIds },
      this.withDefaultHeader(),
    );
  }

  async updateElementsInCategory(businessId: BusinessId, menuId: string, categoryId: string, elementIds: ElementDto[]): Promise<void> {
    await axios.put(
      this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/categories/${categoryId}/elements`,
      { elementIds },
      this.withDefaultHeader(),
    );
  }

  async updateCategory(menuId: string, categoryId: string, categoryToUpdate: CategoryToUpdate): Promise<void> {
    await axios.put<CategoryCreatedDto>(
      this.menuBackendUrl + `/menus/${menuId}/categories/${categoryId}`,
      {
        name: categoryToUpdate.name,
        description: categoryToUpdate.description,
      },
      this.withDefaultHeader(),
    );
  }

  async updateMenu(businessId: BusinessId, menuId: string, menuDto: UpdateMenuDto): Promise<void> {
    await axios.patch(
      this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}`,
      menuDto,
      this.withDefaultHeader(),
    );
  }

  async updateMenuPicture(businessId: BusinessId, menuId: string, pictureBytes: any): Promise<string> {
    return new Promise((success, failure) => {
      axios.post<MenuImageUrlDto>(
        this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/image`, pictureBytes,
        {
          ...this.withDefaultHeader(),
          headers: {
            ...this.withDefaultHeader().headers,
            "Content-Type": "application/octet-stream",
          },
        },
      )
        .then((response) => {
          success(response.data.imageUrl);
        })
        .catch(failure);
    });
  }

  private convertMenuDtoToMenus(menuDtos: MenuSummaryDto[]): MenuSummary[] {
    return menuDtos.map((menu) => MenuDtoConverter.toMenuSummary(menu));
  }

  async findMenuFeatureConfiguration(businessId: BusinessId, menuId: string): Promise<MenuFeatureConfiguration | undefined> {

    const menuConfigurations = await axios.get<MenuFeatureConfiguration[]>(
      this.menuBackendUrl + `/businesses/${businessId}/menu-configurations`, this.withDefaultHeader()
    );

    return menuConfigurations.data.find((conf) => conf.menuId === menuId);
  }

  async enablePayAtTable(businessId: BusinessId, menuId: string): Promise<SundayFeature[]> {
    const response = await axios.patch<MenuFeatureConfiguration>(
      this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/add-feature`,
      { feature: 'PAY_AT_TABLE' },
      this.withDefaultHeader());

    return response.data.sundayFeatures;
  }

  async disablePayAtTable(businessId: BusinessId, menuId: string): Promise<SundayFeature[]> {
    const response = await axios.patch<MenuFeatureConfiguration>(
      this.menuBackendUrl + `/businesses/${businessId}/menus/${menuId}/remove-feature`,
      { feature: 'PAY_AT_TABLE' },
      this.withDefaultHeader());

    return response.data.sundayFeatures;

  }

}
