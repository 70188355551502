import React, { Context, createContext, ReactNode, useMemo } from 'react';
import { VposVenueRepositoryHttp } from 'src/infrastructure/venue/VposVenueRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentMethods, PosPaymentMethod, PostPaymentMethods } from 'src/domain/venue/PosPaymentMethods';
import { VposVenueRepository } from 'src/domain/venue/VposVenueRepository';
import { RevenueCenter } from 'src/domain/venue/RevenueCenter';
import { PosConnectionId } from 'src/pos/domain/PosConnection';

export const VposVenueRepositoryContext: Context<VposVenueRepository> = createContext({} as VposVenueRepository);

export const VposVenueRepositoryProvider = ({ children }: { children: ReactNode }) => {
  const { vposVenueUrl } = ConfigurationLoader.load();
  const vposVenueRepository: VposVenueRepository = useMemo(() => new VposVenueRepositoryHttp(vposVenueUrl), []);

  return (
    <VposVenueRepositoryContext.Provider
      value={{
        getPaymentMethods: (venueId: string): Promise<PaymentMethods[]> => vposVenueRepository.getPaymentMethods(venueId),
        postPaymentMethods: (posConnectionId: PosConnectionId, posPayments: PostPaymentMethods[]): Promise<void> => vposVenueRepository.postPaymentMethods(posConnectionId, posPayments),
        getPosPaymentMethods: (posConnectionId: PosConnectionId): Promise<PosPaymentMethod[]> => vposVenueRepository.getPosPaymentMethods(posConnectionId),
        getRevenueCenters: (posConnectionId: PosConnectionId): Promise<RevenueCenter[]> => vposVenueRepository.getRevenueCenters(posConnectionId),
        postRevenueCenters: (posConnectionId: PosConnectionId, revenueCenters: RevenueCenter[]): Promise<void> => vposVenueRepository.postRevenueCenters(posConnectionId, revenueCenters),
      }}
    >
      {children}
    </VposVenueRepositoryContext.Provider>
  );
};
