import { TableHead, TableHeader, TableRow } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

type Props = {
  tpeVenue: boolean;
  isDigitalFeeDisplayed: boolean;
};

export const PaymentsHeader = ({ tpeVenue, isDigitalFeeDisplayed }: Props) => (
  <TableHead>
    <TableRow>
      <TableHeader textAlign="start" />
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.date_time" />
      </TableHeader>
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.source" />
      </TableHeader>
      {tpeVenue && (
        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.waiter_name" />
        </TableHeader>
      )}

      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.method" />
      </TableHeader>
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.tips" />
      </TableHeader>
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.sub_total" />
      </TableHeader>
      {isDigitalFeeDisplayed && (
        <TableHeader textAlign="start">
          <FormattedMessage id="payments.table.customer_fee" />
        </TableHeader>
      )}
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.total" />
      </TableHeader>
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.status" />
      </TableHeader>
      <TableHeader textAlign="start">
        <FormattedMessage id="payments.table.pos_status" />
      </TableHeader>
      <TableHeader textAlign="start" style={{ paddingRight: '21px' }}>
        <FormattedMessage id="payments.table.actions" />
      </TableHeader>
    </TableRow>
  </TableHead>
);
