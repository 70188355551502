export function isValidUrl(string: string): boolean {
  try {
    const url = new URL(string);
    return !!url.protocol && !!url.host;
  } catch (_) {
    return false;
  }
}

export function prependSchemeIfNotAlreadyPresent(scheme: string, url: string): string {
  if (isValidUrl(url)) {
    return url;
  }
  return `${scheme}://${url}`;
}
