import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { QUERYKEY_MENUS } from '../../queries/menu/findMenusBySourceQuery';
import { BusinessId } from 'src/business/domain/Business';

export const useUpdateMenusOrderMutation = (businessId: BusinessId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return useMutation({
    mutationFn: (menuIds: string[]) => menuRepository.updateMenusOrder(businessId, menuIds),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENUS] });
    },
  });
};
