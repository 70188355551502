import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { Menu } from '../../../domain/Menu';
import { MenuNameSettings } from './MenuNameSettings';
import { MenuShortDescSettings } from './MenuShortDescSettings';
import { MenuDescriptionSettings } from './MenuDescriptionSettings';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type MenuNamePanelPros = {
  menu: Menu;
};

const MenuDetailsPanel = ({ menu }: MenuNamePanelPros) => {
  const businessOrThrow = useCurrentBusinessOrThrow();

  if (!businessOrThrow.patEnrollment && !menu.sundayMenu) {
    return <></>;
  }

  return (
    <Card>

      <CardContent>

        <Typography variant="h6" component="div">
          <FormattedMessage id="menus.settings.details.title" defaultMessage="menu details" />
        </Typography>

        <Box sx={{
          mt: 3, gap: 3, display: 'flex', flexDirection: 'column',
        }}
        >

          {menu.sundayMenu && (
            <MenuNameSettings menu={menu} />
          )}
          <MenuShortDescSettings menu={menu} />
          <MenuDescriptionSettings menu={menu} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default MenuDetailsPanel;
