import { ProductSummary } from '../../../../domain/ProductSummary';

const normalizeAndRemoveDiacritics = (text: string) =>
  text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

export const productMatch = (filterString: string | undefined, product: ProductSummary) => {
  const filterClean = normalizeAndRemoveDiacritics(filterString ?? '');
  const nameClean = normalizeAndRemoveDiacritics(product.name);
  return nameClean.indexOf(filterClean) >= 0 || product.id === filterClean || product.sku === filterClean;
};
