import { breakpoints, colorUsage } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';

export const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  padding: 16px;
  background-color: ${colorUsage.secondaryBackground};
  overflow: scroll;

  @media (min-width: ${breakpoints.large}) {
    padding: 32px 128px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 50px;
  padding-top: 30px;
`;

export const RolesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const RoleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 153px;
  padding-bottom: 30px;
`;
