import axios from 'axios';
import { ConsentRepository } from '../domain/ConsentRepository';
import { CustomerData } from '../domain/CustomerData';
import { BusinessId } from 'src/business/domain/Business';
import { GuestDataCollection } from '../domain/GuestDataCollection';

export class ConsentRepositoryHttp implements ConsentRepository {
  constructor(private readonly baseUrl: string) {
  }


  async getGuestDataCollectionStatus(businessId: BusinessId): Promise<GuestDataCollection> {
    try {
      const response = await axios.get(
        `${this.baseUrl}/v1/guest-data-collections/${businessId}`,
      );
      return response.data;
    } catch (e) {
      throw new Error('error retrieving business guest data collection status');
    }
  }

  async activateGuestDataCollection(businessId: BusinessId): Promise<void> {
    try {
      await axios.post<void>(
        `${this.baseUrl}/v1/businesses/${businessId}/guest-data-collections`,
      );
    } catch (e) {
      throw new Error('error activating business guest data collection');
    }
  }

  async deactivateGuestDataCollection(businessId: BusinessId): Promise<void> {
    try {
      await axios.delete<void>(
        `${this.baseUrl}/v1/businesses/${businessId}/guest-data-collections`,
      );
    } catch (e) {
      throw new Error('error deactivating business guest data collection');
    }
  }

  async listVenueConsent(businessId: BusinessId): Promise<CustomerData[]> {
    try {
      const axiosResponse = await axios.get<{ customerDatas: CustomerData[] }>(
        `${this.baseUrl}/v1/business/${businessId}`,
      );
      return axiosResponse.data.customerDatas;
    } catch (e) {
      throw new Error('error getting customer data');
    }
  }
}
