import { Button, Modal, Select, Stack, styled, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { IconWrapper } from '@sundayapp/b2b-react-component-library';
import CrossSvg from '../../app/component/icons/CrossSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { PaymentTerminalConfigurationSummaryDto } from '../domain/PaymentTerminalRepository';

const AddTpeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

const Center = styled('div')`
  display: flex;
  margin: auto;
`;
export const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
  padding: '1.5rem',
  width: '500px',
};

interface Props {
  terminalName: string
  isOpen: boolean
  onClose: () => void
  paymentTerminalConfiguration: string
  setPaymentTerminalName: (name: string) => void
  setPaymentTerminalConfiguration: (configuration: string) => void
  paymentTerminalConfigurations: PaymentTerminalConfigurationSummaryDto[]
  action: () => void
  edit?: boolean
}


export const PaymentTerminalModal = ({
  terminalName,
  isOpen,
  onClose,
  paymentTerminalConfiguration,
  setPaymentTerminalName,
  setPaymentTerminalConfiguration,
  paymentTerminalConfigurations,
  action,
  edit,
}: Props) => {
  const intl = useIntl();
  return (
    <Modal open={isOpen}>
      <Stack direction="column" sx={styleModal} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4" sx={{ marginBottom: '40px' }}>
            <FormattedMessage id="payment.terminal.manage.new.tpe" />
          </Typography>
          <IconWrapper onClick={() => onClose()}>
            <CrossSvg size={24} color={themeV5.palette.text.secondary} />
          </IconWrapper>
        </Stack>
        <AddTpeContainer>
          <TextField
            label={intl.formatMessage({ id: 'payment.terminal.manage.new.name' })}
            value={terminalName}
            onChange={(event) => setPaymentTerminalName(event.target.value)}
          />
          <Select
            value={paymentTerminalConfiguration}
            label={
              <Typography style={{ opacity: 1 }}>
                <FormattedMessage id="payment.terminal.manage.new.configuration" />
              </Typography>
            }
            variant="outlined"
            onChange={(event) => setPaymentTerminalConfiguration(event.target.value)}
          >
            {paymentTerminalConfigurations !== undefined ? paymentTerminalConfigurations.map((configuration) => (
              <MenuItem value={configuration.id} key={configuration.id}>
                {configuration.name}
              </MenuItem>
            )) : null
            }
          </Select>
          <Button
            variant="contained"
            disabled={terminalName.length === 0}
            onClick={() => action()}
            style={{ width: '100%' }}
          >
            <Center>
              {edit &&
                <FormattedMessage id="payment.terminal.manage.edit.button" />
              }
              {!edit &&
                <FormattedMessage id="payment.terminal.manage.new.button" />
              }
            </Center>
          </Button>
        </AddTpeContainer>
      </Stack>
    </Modal>
  );
};
