import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Button } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ContactUsRepositoryHttp } from '../infrastructure/ContactUsRepositoryHttp';
import { ContactUsModal } from './ContactUsModal';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { AdditionalInformationField } from './AdditionalInformationField';

interface ContactUsButtonProps {
  title: string;
  source: string;
  reason: 'loyalty' | 'booking' | 'payment terminal' | 'checkin';
  additionalField?: AdditionalInformationField;
}

export const ContactUsButton = ({ title, source, reason, additionalField }: ContactUsButtonProps) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const [contactUsModalIsOpen, setContactUsModalIsOpen] = useState<boolean>(false);
  const business = useCurrentBusinessOrThrow();
  const contactUsRepository = new ContactUsRepositoryHttp(ConfigurationLoader.load().contactUsUrl);

  async function onContactUs(name: string, email: string, phoneNumber: string, additionalInformation?: string) {
    setContactUsModalIsOpen(true);
    await contactUsRepository.sendContactInformation({
      name,
      email,
      phoneNumber,
      venueId: business.id,
      countryCode: business.address.countryCode,
      reason: `I am interested in sunday ${reason}${additionalInformation ? ` (${additionalInformation})` : ''}.`,
    });
    useSnackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'sunday_plus.banner.after_trial.contact_us.success_request' }),
    });

    ampli.sundayPlusContactUsFormSubmitted({ source });
  }

  const handleContactUsClosed = () => {
    setContactUsModalIsOpen(false);
  };

  const handleContactUsConfirmed = () => {
    setContactUsModalIsOpen(false);
  };

  const onOpenContactUsModal = () => {
    setContactUsModalIsOpen(true);
    ampli.sundayPlusContactFormDisplayed({ source });
  };

  return (
    <>
      <Button variant="contained" onClick={onOpenContactUsModal}>
        {title}
      </Button>
      <ContactUsModal
        title={intl.formatMessage({ id: 'sunday_plus.banner.after_trial.contact_us.title' })}
        description={intl.formatMessage({ id: 'sunday_plus.banner.after_trial.contact_us.description' })}
        onContactUs={onContactUs}
        handleConfirm={handleContactUsConfirmed}
        handleClose={handleContactUsClosed}
        isOpen={contactUsModalIsOpen}
        additionalInformationField={additionalField}
      />
    </>
  );
};
