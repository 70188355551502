export interface BlockDto {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  textColor: string;
  backgroundColor: string;
  linkUrl: string;
  linkLabel: string;
  type: 'BLOCK'
}

export interface UpdateBlockDto {
  title?: string;
  description?: string;
  imageUrl?: string;
  textColor?: string;
  backgroundColor?: string;
  linkUrl?: string;
  linkLabel?: string;
}

export interface CreateBlockDto {
  title: string;
}

export function isBlock(type: string): boolean {
  return type === 'BLOCK';
}
export interface BlocksSummaryDto {
  blocks: BlockDto[];
}
