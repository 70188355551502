import axios from 'axios';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { UserRepository } from 'src/domain/user/UserRepository';
import { UserWithRoles } from 'src/domain/user/UserWithRoles';
import { FindUserRoleResponse, findUserRoleResponseSchema } from './FindUserRoleResponse';
import { UserRole } from 'src/domain/user/UserRole';
import { ListBusinessUsersResponse } from './ListBusinessUsersResponse';
import { datadogLogs } from '@datadog/browser-logs';
import { RelationType } from 'src/auth/domain/user';

export class HttpUserRepository implements UserRepository {
  constructor(private baseUrl: string) {}

  async getMyProfile(): Promise<UserWithRoles> {
    const { data } = await axios.get<FindUserRoleResponse>(`${this.baseUrl}/v2/users/me`, {
      headers: {
        contentType: 'application/json',
      },
    });

    const parsedResponse = findUserRoleResponseSchema.safeParse(data);
    if (parsedResponse.success) {
      return new UserWithRoles(
        parsedResponse.data.user.id,
        parsedResponse.data.user.email,
        parsedResponse.data.user.first_name,
        parsedResponse.data.user.last_name,
        parsedResponse.data.user.phone_number,
        parsedResponse.data.user.roles.map(({ business_id, business_name, role }) => ({
          businessId: business_id,
          businessName: business_name,
          userRole: role as UserRole,
        })),
        parsedResponse.data.user.relations.map(({ relation, object_type, object_id }) => ({
          relation: relation as RelationType,
          objectType: object_type,
          objectId: object_id,
        })),
      );
    } else {
      datadogLogs.logger.error('User cannot be parsed', { data, error: parsedResponse.error });
      return new UserWithRoles(
        data.user.id,
        data.user.email,
        data.user.first_name,
        data.user.last_name,
        data.user.phone_number,
        data.user.roles.map(({ business_id, business_name, role }) => ({
          businessId: business_id,
          businessName: business_name,
          userRole: role as UserRole,
        })),
        (data.user.relations ?? []).map(({ relation, object_type, object_id }) => ({
          relation: relation as RelationType,
          objectType: object_type,
          objectId: object_id,
        })),
      );
    }
  }

  async retrieveFirebaseToken(): Promise<string> {
    const response = await axios.post<{
      token: string;
    }>(`${this.baseUrl}/v2/users/me/firebase-custom-token/`);
    return response.data.token;
  }

  async listBusinessUsers(businessId: string): Promise<BusinessUser[]> {
    const response = await axios.get<ListBusinessUsersResponse>(`${this.baseUrl}/businesses/${businessId}/users`, {
      headers: {
        contentType: 'application/json',
      },
    });
    return response.data.users
      .map((user) => ({
        userId: user.user_id,
        email: user.email,
        posUserName: user.pos_user_name,
        role: user.role as UserRole,
        firstName: user.first_name,
        lastName: user.last_name,
        phoneNumber: user.phone_number,
        authenticationProviderIds: user.authenticationProviderIds,
      }))
      .sort((a, b) => a.email.localeCompare(b.email));
  }

  async grantUserRole(businessId: string, email: string, role: UserRole): Promise<void> {
    await axios.put(
      `${this.baseUrl}/businesses/${businessId}/users`,
      {
        email,
        role,
      },
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );
  }

  async removeUserRole(businessId: string, userId: string): Promise<void> {
    await axios.delete(`${this.baseUrl}/businesses/${businessId}/users/${userId}`);
  }

  async updateUserRole(businessId: string, userId: string, role: UserRole): Promise<void> {
    await axios.post(
      `${this.baseUrl}/businesses/${businessId}/users/${userId}/role`,
      {
        role,
      },
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );
  }

  async reSendSignUpLink(email: string, businessId: string): Promise<void> {
    await axios.post(
      `${this.baseUrl}/businesses/${businessId}/users/reSendSignUpLink`,
      {
        email,
      },
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );
  }

  async completeUserAccount(email: string, lastName: string, firstName: string, phoneNumber: string): Promise<void> {
    await axios.post(
      `${this.baseUrl}/v2/users/me`,
      {
        lastName,
        firstName,
        phoneNumber,
      },
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );
  }

  async updateUser(
    businessId: string,
    userId: string,
    updateUserRequest: {
      firstName: string;
      lastName: string;
      posUserName: string;
      phoneNumber: string;
    },
  ): Promise<void> {
    await axios.put(
      `${this.baseUrl}/businesses/${businessId}/users/${userId}`,
      {
        ...updateUserRequest,
      },
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );
  }

  async unlinkPosUserName(businessId: string, userId: string): Promise<void> {
    await axios.delete(`${this.baseUrl}/businesses/${businessId}/users/${userId}/posUserName`, {
      headers: {
        contentType: 'application/json',
      },
    });
  }
}
