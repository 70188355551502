import { useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import MenuDailySchedule from './MenuDailySchedule';
import { MenuScheduleUtils } from '../../menuDetails/components/MenuScheduleUtils';
import { MenuConstraints } from '../../domain/StaticMenus';

type MenuScheduleDisplayProps = {
  isActive: boolean;
  constraints?: MenuConstraints;
};

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;

const MenuScheduleDisplay = ({ isActive, constraints }: MenuScheduleDisplayProps) => {
  const intl = useIntl();
  const groupedConstraints = MenuScheduleUtils.menuToGroupedConstraints(constraints!);
  const labels = groupedConstraints.map((constraint) => MenuScheduleUtils.groupedConstraintToString(constraint, intl));

  return (
    <LabelContainer>
      {labels.map((label, index) => (
        <MenuDailySchedule key={String(index)} messages={label} isActive={isActive} />
      ))}
    </LabelContainer>
  );
};

export default MenuScheduleDisplay;
