import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { PaymentTerminal } from '../domain/PaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const QUERYKEY_PAYMENT_TERMINAL_LIST = 'payment_terminals_list' ;
const buildQueryKey = (enrollmentId: EnrollmentId | undefined, withVersion: Boolean) => [QUERYKEY_PAYMENT_TERMINAL_LIST, enrollmentId, withVersion] as const;
export const listPaymentTerminalsQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<PaymentTerminal[]> => {
  const [, enrollmentId, withVersion] = queryKey;
  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return repository.listPaymentTerminals(enrollmentId, withVersion);
};

export const useListPaymentTerminals = (enrollmentId: EnrollmentId | undefined, withVersion: Boolean = true) =>
  useQuery({ queryKey: buildQueryKey(enrollmentId, withVersion), queryFn: listPaymentTerminalsQuery });
