import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { Card, CardContent, Stack, Switch, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useUpdateGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useUpdateGlobalConfiguration';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';

export const EnableReviewsSettings = () => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();
  const { updateGlobalConfiguration } = useUpdateGlobalConfiguration();
  const { data: globalConfiguration } = useGlobalConfiguration();
  const enableReviews = globalConfiguration?.entries.get(VenueConfigurationKey.reviewsEnabled) ?? false;
  const theme = useTheme();

  const save = async (value: boolean) => {
    await updateGlobalConfiguration(
      new Map<VenueConfigurationKey, any>([[VenueConfigurationKey.reviewsEnabled, String(value)]]),
    );
    useSnackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'settings.reviews_and_loyalty.success_saving' }),
    });
  };

  return (
    <Card>
      <CardContent>
        <Stack direction="column" gap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h6">
              <FormattedMessage id="settings.reviews_and_loyalty.title" />
            </Typography>
            <Switch onChange={(e) => save(e.target.checked)} checked={enableReviews} />
          </Stack>
          <Typography variant="caption" color={theme.palette.text.disabled}>
            <FormattedMessage id="settings.reviews_and_loyalty.description" />
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
