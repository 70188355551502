import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const UploadBackDocument = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number,
}) => {
  const { formState: { errors }, register, watch, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();

  const error = errors?.representatives?.[legalRepresentativeIndex]?.identificationBackDocument;

  const backDocumentId = watch(`representatives.${legalRepresentativeIndex}.identificationBackDocument`);
  const intl = useIntl();

  const onFileUploaded = (documentId: string) => {
    setValue(`representatives.${legalRepresentativeIndex}.identificationBackDocument`, documentId);
  };
  const onDelete = () => {
    setValue(`representatives.${legalRepresentativeIndex}.identificationBackDocument`, '');
  };
  return <UploadDocument
    documentId={backDocumentId}
    onRegistered={register(`representatives.${legalRepresentativeIndex}.identificationBackDocument`,
      { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationBackDocument.button' }) })}
    onFileUploaded={onFileUploaded}
    onDelete={onDelete}
    error={error}
    purpose={'identity_verification'}
    documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationBackDocument' })}
    helperText={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.identificationDocument.helperText' })}
  />;
};
