import { useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { NavigationExternalLink } from './NavigationExternalLink';
import { QuestionMarkSvg } from 'src/app/component/icons/QuestionMarkSvg';
import { ampli } from 'src/ampli';
import { useBusinessId } from 'src/business/hooks/useBusinessId';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { BusinessId } from 'src/business/domain/Business';

type NavigationGuideExternalLinkProps = {
  isMobile: boolean;
  isCompacted: boolean;
};

const getExternalLink = (language: string): string => {
  if (language.startsWith('fr')) {
    return 'https://intercom.help/sundayapp-help/fr/';
  }

  return 'https://intercom.help/sundayapp-help/en/';
};

export const NavigationGuideExternalLink = ({ isMobile, isCompacted }: NavigationGuideExternalLinkProps) => {
  const intl = useIntl();
  const businessId = useBusinessId();
  const { data: business } = useBusinessQuery(businessId as BusinessId);

  const externalLink = getExternalLink(intl.locale);

  const trackClick = () => {
    ampli.guideAndTrainingClicked({ venueId: business?.id });
  };

  return (
    <NavigationExternalLink
      isMobile={isMobile}
      isCompacted={isCompacted}
      currentIcon={<QuestionMarkSvg color="#b4b4bb" />}
      translatedMessageKey="menu.learn_and_guide"
      externalLink={externalLink}
      beforeSubmit={trackClick}
    />
  );
};
