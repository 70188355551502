import { styled } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const Overlay = styled('div')`
  margin: 16px;
  font-size: 14px;
  display: flex;
  width: 100%;
  justify-content: center;
`;

export function NoBlockOverlay() {
  return (
    <Overlay>
      <FormattedMessage id="menus.blocks.no_data" />
    </Overlay>
  );
}
