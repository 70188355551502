import { Box, Button, Card, CardContent as MUICardContent, styled } from '@mui/material';

export const CardWrapper = styled(Card)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
  
export const CardContent = styled(MUICardContent)(() => ({
  display: 'flex',
  flex: 1, 
}));

export const StatsCardChartBox = styled(Box)(() => ({
  flex: 1, 
  minHeight: '351px', 
  maxWidth: '100%', 
}));

export const StatsCardFooterBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  gap: theme.spacing(2),
  alignSelf: 'stretch',
}));

export const StatsLabelsBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
}));

export const StatsLabelsItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'order' && prop !== 'isCentered',
})<{ order?: number, isCentered?: boolean }>(({ theme, order, isCentered }) => ({
  alignItems: !isCentered ? 'start' : 'center',
  display: 'flex', 
  flexDirection: 'column',
  flex: '1 0 0',
  gap: theme.spacing(1),
  order: order ?? 'inherit',
}));

export const FigureItemLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const InfoCardSubtitleWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasContent',
})<{ hasContent?: boolean }>(({ theme, hasContent }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),

  ...(!hasContent && {
    flex: 1,
  }),
}));

export const InfoCardContentWrapper = styled(Box)(() => ({
  flex: 1,
}));

export const ContentSubItemBox = styled(Box)(({ theme }) => ({
  display: 'flex', 
  flexDirection: 'column', 
  marginBottom: theme.spacing(1),
}));

export const ContentSubItemSection = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHeadLine',
})<{ isHeadLine?: boolean }>(({ theme, isHeadLine }) => ({
  display: 'flex', 
  justifyContent: 'space-between',

  '.MuiTypography-root': {
    color: theme.palette.text.secondary,
    ...(isHeadLine && {
      '&:not(first-of-type)': {
        color: theme.palette.text.primary,
      },
    }),
  },
}));

export const ContentFooterWrapper = styled(Box)(() => ({
  display: 'flex', 
  justifyContent: 'flex-end', 
  alignSelf: 'flex-end',
}));

export const ContentFooterCta = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`, 
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));
