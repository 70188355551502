import { Money } from '@sundayapp/web-money';

export interface BillResponse {
  billId: { id: string };
  totalBillAmount: Money;
  issuedAt?: string;
  order: Order;
  leftToPay: Money;
  tab?: Tab;
  sundayBillSplits: SundayBillSplit[];
  nonSundayBillSplits: NonSundayBillSplit[];
  updatedAt?: string;
}

export interface Order {
  orderId: { uuid: string };
  venueId?: { value: string };
  businessId?: { value: string };
  tableId: { uuid: string };
  staffName?: string;
  orderCode?: { value: string };
}

export interface Tab {
  id: string;
  displayId: string;
  customerFullName: string;
  chargeLeftToPayAmountRequestedAt?: string;
  nominalDigitalFeeRateForCapture?: number;
}
export const BillSplitStatus = {
  ISSUED: 'ISSUED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  REGISTERED: 'REGISTERED',
} as const;

export interface SundayBillSplit {
  status: (typeof BillSplitStatus)[keyof typeof BillSplitStatus];
  payment?: Payment; // It's not used in the merchant.
  unfulfilledPayments: UnfulfilledPayment[]; // It's not used in the merchant.
  refunds: Refund[]; // It's not used in the merchant.
}

export interface Payment {  // It's not used in the merchant.
  id: { value: string };
  paidAt?: string;
}

export interface Refund {} // It's not used in the merchant.
export interface UnfulfilledPayment {}  // It's not used in the merchant.

export interface NonSundayBillSplit {}  // It's not used in the merchant.
