import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { FoodCourtImagePlaceHolder, FoodCourtMapContainer, FoodCourtMapImage } from './FoodCourtMapSettings.style';
import { VenueDetails } from '../../../../types';
import { useFileStorage } from 'src/ordering/common/hooks';
import { useUpdateVenueDetails } from '../../../../hook';
import PictureUploader from '../../../../../common/components/PictureUploader';
import { Container, Title } from 'src/ordering/common/components/Settings/SettingPanel';
import { getSpacing } from 'src/ordering/stylesheet';

interface FoodCourtMapSettingsProps {
  venue: VenueDetails;
}

const SettingPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${getSpacing(2)};
  max-width: 552px;
`;

const FoodCourtMapSettings: React.FC<FoodCourtMapSettingsProps> = ({ venue }) => {
  const [newFoodCourtMapImage, setNewFoodCourtMapImage] = useState<string | null>(null);
  const fileStorage = useFileStorage();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  useEffect(() => {
    setNewFoodCourtMapImage(venue.foodCourtMapUrl);
  }, [setNewFoodCourtMapImage, venue.foodCourtMapUrl]);
  const setFoodCourtMapImage = useCallback(
    async (base64Image: string) => {
      const foodCourtMapUrl = await fileStorage.saveImageAsPNG(`ordering/maps/${venue.id}`, uuidv4(), base64Image);
      setNewFoodCourtMapImage(foodCourtMapUrl);
      updateVenueDetails({ foodCourtMapUrl });
    },
    [venue, setNewFoodCourtMapImage, updateVenueDetails, fileStorage],
  );

  return (
    <Container>
      <Title>
        <FormattedMessage
          id="venue.foodcourtMap"
          defaultMessage="foodcourt map"
          description="section title of foodcourt map image field in foodcourt profile page"
        />
      </Title>
      <SettingPanelContent>
        <PictureUploader onChange={setFoodCourtMapImage}>
          <FoodCourtMapContainer>
            {newFoodCourtMapImage ? (
              <FoodCourtMapImage src={newFoodCourtMapImage} />
            ) : (
              <FoodCourtImagePlaceHolder>
                <FormattedMessage
                  id="venue.foodCourtImagePlaceholder"
                  defaultMessage="upload an image as your foodcourt map"
                  description="placeholder in foodcourt map section when no foodcourt map image exists"
                />
              </FoodCourtImagePlaceHolder>
            )}
          </FoodCourtMapContainer>
        </PictureUploader>
      </SettingPanelContent>
    </Container>
  );
};

export default FoodCourtMapSettings;
