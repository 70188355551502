import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpUserRepository } from 'src/infrastructure/user/HttpUserRepository';
import { UserRole } from 'src/domain/user/UserRole';
import { queryKeys } from 'src/app/queries/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type MutationArgs = { userId: string; role: UserRole };

export const useUpdateUserRole = (businessId: string) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();

  const userRepository = new HttpUserRepository(configuration.merchantAccountsBaseUrl);

  const mutation = useMutation({
    mutationFn: ({ userId, role }: MutationArgs) => userRepository.updateUserRole(businessId, userId, role),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.LIST_BUSINESS_USERS, { businessId }] }),
  });

  return {
    updateUserRole: (args: MutationArgs) => mutation.mutateAsync(args),
  };
};
