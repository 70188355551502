import React, { useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type TipsRoundingVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const TipsRoundingVenueSetting = ({ venue, setVenue }: TipsRoundingVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const tipsRoundingEnabled: boolean = useMemo(() => !!(venue.tipsRoundingMode && venue.tipsRoundingMode === 'TO_TENTH'), [venue.tipsRoundingMode]);

  const updateVenue = async (enableTipsRounding: boolean) => {
    const tipsRoundingMode = enableTipsRounding ? 'TO_TENTH' : 'NONE';
    makeUpdating(true);
    await updateVenueDetails({
      tipsRoundingMode,
    });
    setVenue({
      ...venue,
      tipsRoundingMode,
    });
    await pushNotification(
      intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }),
    );
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.enableTipsRounding',
        defaultMessage: 'enable tips rounding',
        description: 'enable tips rounding title',
      })}
      description={intl.formatMessage({
        id: 'venue.enableTipsRoundingDescription',
        defaultMessage:
          'When activated, the tips proposed will be rounded to the tenth of unit (3.47 will be rounded to 3.50)',
      })}
      toggleValue={tipsRoundingEnabled}
      toggleOnChange={updateVenue}
      disabled={isUpdating}
    />
  );
};
