import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { getFulfillmentMethodLabel } from '../../../formatting';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { Area } from '../../../domain/Area';
import { ServiceMenu } from './ServiceMenu';
import { areaConfigurationMenuId } from 'src/ordering/area/pages/AreaDetailsSettings/components/AreaDetailsServicePanel';

type AreaSummaryServiceProps = {
  area: Area;
  service: FulfillmentType;
  menus: OrderingMenu[];
};

const Container = styled.div``;

const ServiceContainer = styled.div`
  border: 1px solid ${colorPalette.grey400};
  border-radius: ${getSpacing(3)};
  padding-top: ${getSpacing(1)};
  padding-bottom: ${getSpacing(1)};
  padding-left: ${getSpacing(2)};
  padding-right: ${getSpacing(2)};
  width: fit-content;
  margin-top: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
`;

const ServiceName = styled.span`
  color: ${colorPalette.grey600};
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2px;
  color: ${colorPalette.grey500};
  gap: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
`;

const NoMenuText = styled.div`
  color: ${colorPalette.red};
`;

export const AreaSummaryService = ({ area, service, menus }: AreaSummaryServiceProps) => {

  const serviceMenus = useMemo(
    () =>
      area.configurations
        .filter((config) => config.fulfillmentMethod === service)
        .map((config) => menus.find((menu) => areaConfigurationMenuId(menu) === config.menuId))
        .filter((menu) => menu !== undefined),
    [area, service, menus],
  );

  return (
    <Container>
      <ServiceContainer>
        <ServiceName>{getFulfillmentMethodLabel(service)}</ServiceName>
      </ServiceContainer>
      <MenuContainer>
        {serviceMenus.length > 0 ? (
          serviceMenus.map((menu) => <ServiceMenu key={menu!.id} menu={menu!} />)
        ) : (
          <NoMenuText>
            <FormattedMessage
              id="settings.area.service.no-menu"
              defaultMessage="menu required"
              description="message display when no menu are associated to a service"
            />
          </NoMenuText>
        )}
      </MenuContainer>
    </Container>
  );
};
