import { useVenueRepository } from '../../venues/hook';
import { useAsyncFn } from '../../common/utils/useAsyncFn';
import { Enrollment } from 'src/business/domain/Enrollment';

export type OrderingMenuConfiguration = {
  isFoodCourt: boolean;
  isOapWithDirectIntegration: boolean;
};

export const useOrderingMenuConfiguration = () => {
  const venueRepository = useVenueRepository();

  return useAsyncFn<OrderingMenuConfiguration, [oapEnrollment: Enrollment, patEnrollment: Enrollment | undefined]>(
    async (oapEnrollment: Enrollment) => {
      const venue = await venueRepository.getVenue(oapEnrollment.id);
      const hasDirectIntegration = venue.boxes.boxes.some(box => box.posIntegration.type === 'DIRECT_INTEGRATION');
      return {
        isFoodCourt: venue.isFoodCourt,
        isOapWithDirectIntegration: oapEnrollment.status === 'ACTIVE' && hasDirectIntegration,
      };
    },
    [venueRepository],
  );
};
