import { Box, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, FormattedTime } from 'src/app/i18n/TypedIntl';
import { useCurrentReportOrThrow } from '../hooks/useCurrentReportOrThrow';

export const ReportHistoryPanel = () => {
  const theme = useTheme();
  const report = useCurrentReportOrThrow();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
      }}
    >
      <Typography
        color={theme.palette.text.secondary}
      >
        <FormattedMessage id="reconciliation.report.last_update" />
        <FormattedTime
          value={report.lastModification}
          day="2-digit"
          month="2-digit"
          year="numeric"
          hour="2-digit"
          minute="2-digit"
          second="2-digit"
        />
      </Typography>
    </Box>
  );
};
