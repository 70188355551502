import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { queryKeys } from 'src/app/queries/utils';
import { WhiteLabelCustomizationRepositoryHttp } from './WhiteLabelCustomizationRepositoryHttp';
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';

type UpdateCustomizationArgs = {
  enrollmentId: string;
  customization: WhiteLabelCustomization;
};

export const useWhiteLabelSaveCustomization = () => {
  const queryClient = useQueryClient();
  const whiteLabelCustomizationRepositoryHttp = new WhiteLabelCustomizationRepositoryHttp(
    ConfigurationLoader.load().patOrchestratorUrl,
  );
  const mutation = useMutation({
    mutationFn: ({ customization, enrollmentId }: UpdateCustomizationArgs) => {
      return whiteLabelCustomizationRepositoryHttp.saveCustomization(enrollmentId, customization);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.whiteLabel.GET_CUSTOMIZATION] });
    },
  });
  return {
    mutation,
    saveWhiteLabelCustomization: (enrollmentId: string, customization: WhiteLabelCustomization) =>
      mutation.mutateAsync({ customization, enrollmentId }),
  };
};
