import { NoPos } from 'src/pages/NoPos/NoPos';
import { PromoCodesList } from 'src/ordering/promoCodes';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';

export const OPVoucherCodesPage = () => {
  const business = useCurrentBusinessOrThrow();

  const isNoPos = useBusinessIsNoPos();
  if (isNoPos) return <NoPos />;

  return <PromoCodesList enrollmentId={business.oapEnrollment!.id} />;
};
