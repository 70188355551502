import React from 'react';
import { TableBody, TableRow, Typography } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { palette } from '../design/palette';
import { EmailCell, EmailTable } from './EmailTable';
import { KpiString } from './KpiString';
import { SectionComponent } from './SectionComponent';
import { renderGtvLine } from '../domain/TemplatesHelper';
import { FinancialData } from '../domain/TemplatesData';

export const FinancialPerformance = ({
  financialData,
  countryCode,
}: {
  financialData: FinancialData;
  countryCode: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <SectionComponent
        title={`${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.financial_performance' })}`}
        legend={formatMessage({ id: 'venuekpi.weeklyreport.multi.email.legend' })}
        imagePath={financialData.lightingImgPath}
        breakBefore
      >
        <EmailTable
          headCellTitle={[
            {
              title: `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.sunday_share' })}`,
              width: '40%',
            },
            { title: `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.sunday_gtv' })}` },
            { title: `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.tips' })}` },
          ]}
        >
          <TableBody>
            {financialData.venuesKpis.map((data, index) => (
              <TableRow key={data.name} style={{ breakInside: 'avoid' }}>
                <EmailCell>
                  <>
                    <Typography color={palette.primary.electricPink}>{`${index + 1}`.padStart(2, '0')}</Typography>
                    <KpiString
                      title={data.name}
                      value={data.adoption}
                      valueType="%"
                      fluctuation={data.adoptionFluctuation}
                      countryCode={countryCode}
                      color={palette.primary.black}
                      fluctuationType=" pts"
                    />
                  </>
                </EmailCell>
                <EmailCell>
                  <KpiString
                    value={data.gtv}
                    fluctuation={data.gtvFluctuation}
                    color={palette.primary.black}
                    additionalInformation={[
                      `${formatMessage({ id: 'venuekpi.weeklyreport.multi.email.QR' })} : ${
                        data.qr !== null ? data.qr : 'N/A'
                      }`,
                      renderGtvLine(formatMessage({ id: 'venuekpi.weeklyreport.multi.email.PDQ' }), data.pdqr),
                      renderGtvLine(
                        formatMessage({ id: 'venuekpi.weeklyreport.multi.email.non_sunday' }),
                        data.otherGtv,
                      ),
                    ]}
                    countryCode={countryCode}
                    fluctuationType="%"
                  />
                </EmailCell>
                <EmailCell>
                  <KpiString
                    value={data.tips}
                    fluctuation={data.tipsFluctuation}
                    color={palette.primary.black}
                    countryCode={countryCode}
                    fluctuationType=" pts"
                    valueType="%"
                  />
                </EmailCell>
              </TableRow>
            ))}
          </TableBody>
        </EmailTable>
      </SectionComponent>
    </>
  );
};
