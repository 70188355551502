import { Shift } from '../domain/Shift';

export class ShiftPayloadMapper {
  public static toShiftPayload(newShifts: Shift[]): any {
    return {
      shifts: newShifts.map((newShift) => ShiftPayloadMapper.shiftToJSON(newShift)),
    };
  }

  static shiftToJSON(shift: Shift) {
    return {
      id: shift.id,
      venue_id: shift.businessId,
      start_shift_hour: {
        hour: shift.startShiftHour.hour,
        minute: shift.startShiftHour.minute,
      },
      end_shift_hour: {
        hour: shift.endShiftHour.hour,
        minute: shift.endShiftHour.minute,
      },
      shift_type: shift.shiftType,
    };
  }
}
