import React, { FC } from 'react';
import styled from 'styled-components';
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, MenuList, Paper, Popper } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuItem from '@mui/material/MenuItem';
import { DotsAction } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/bulk/MultiSelectProductsToolbar';
import { useIntl } from 'src/app/i18n/TypedIntl';

type Props = {
  setSelectedAction: (selectedAction: DotsAction) => void;
};
export const ActionsDots: FC<Props> = ({ setSelectedAction }) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const options = [
    intl.formatMessage({ id: 'menus.products.bulk.edit_tags' }),
    intl.formatMessage({ id: 'menus.products.bulk.edit_allergens' }),
    intl.formatMessage({ id: 'menus.products.bulk.edit_related_products' }),
  ];

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    switch (index) {
      case 0:
        setSelectedAction('editTags');
        break;
      case 1:
        setSelectedAction('editAllergens');
        break;
      case 2:
        setSelectedAction('editRelatedProducts');
        break;
    }
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const DotsButton = styled(Button)`
    padding: 0;
    align-content: center;
    flex-direction: row;
    display: flex;
    justify-content: center;
  `;

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="button group actions"
      >
        <DotsButton
          onClick={handleToggle}
          aria-controls={open ? 'actions-dots-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select action"
          aria-haspopup="menu">
          <MoreVertIcon style={{ color: 'white', opacity: '56%', fontSize: '16px' }} />
        </DotsButton>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={'bottom-end'}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{
              borderRadius: '8px',
              boxShadow: '0px 5px 5px -3px rgba(0, 0, 0, 0.06), 0px 8px 10px 1px rgba(0, 0, 0, 0.04), 0px 3px 14px 2px rgba(0, 0, 0, 0.04)',
              minWidth: '175px',
            }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="actions-dots-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 3}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>);
};
