import { IconWrapper, TableCell, TableRow } from '@sundayapp/b2b-react-component-library';
import { differenceMoney, money, MoneyView } from '@sundayapp/web-money';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { FormattedDate, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useMemo, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { CashupStatus, ReconciliationReport, totalCashUp } from '../domain/ReconciliationReport';
import { TotalHeader } from './TotalHeader';
import { PaymentOrigin } from '../domain/PaymentOrigin';
import { EditableAmountCell } from './amountcell/EditableAmountCell';
import { useUpdateTotalOnPosMutation } from '../mutations/useUpdateTotalOnPos';
import { useRefreshTotalOnPosUsingPosDataMutation } from '../mutations/useRefreshTotalOnPosUsingPosDataMutation';
import { useCurrentReportOrThrow } from '../hooks/useCurrentReportOrThrow';
import { useReportSettings } from '../queries/ReportSettings';
import { deltaIsAcceptable } from './DeltaAcceptable';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useTotalOnPos = (report: ReconciliationReport) => {
  const business = useCurrentBusinessOrThrow();

  const canRefreshUsingPosData = useMemo(() => report.totalOnPos.editable && ['IKENTOO', 'DUMMY_1_0_SUNDAY', 'ZELTY', 'TRIVEC'].includes(business.posReference ?? ''), [report, business]);
  const [isRefreshing, setRefreshing] = useState(false);

  const refreshUsingPosData = useRefreshTotalOnPosUsingPosDataMutation();

  const refresh = () => {
    setRefreshing(true);
    refreshUsingPosData.mutateAsync().finally(() => setRefreshing(false));
  };

  const totalOnPos = useMemo(() => ((report.totalOnPos.editable)
    ? report.totalOnPos.amount ?? money(0, business.currency)
    : totalCashUp(business.currency, report, PaymentOrigin.OnPos)), [report]);

  return {
    totalOnPos,
    canRefreshUsingPosData,
    refresh,
    isRefreshing,
  };
};

export const ReportHeader = () => {
  const intl = useIntl();
  const { currency } = useCurrentBusinessOrThrow();
  const report = useCurrentReportOrThrow();
  const { data: reportSettings } = useReportSettings();

  const {
    totalOnPos, isRefreshing: isTotalRefreshing, refresh: refreshTotal, canRefreshUsingPosData,
  } = useTotalOnPos(report);

  const totalReported = useMemo(() => totalCashUp(currency, report, PaymentOrigin.Reported), [report]);
  const delta = useMemo(() => differenceMoney(totalReported, totalOnPos), [report]);
  const theme = useTheme();

  const updateTotalOnPosMutation = useUpdateTotalOnPosMutation(report);

  if (!reportSettings) {
    return <CircularProgress />;
  }
  const color = deltaIsAcceptable(delta, reportSettings) ? '#3fb05e' : '#e0831e';

  return (
    <TableRow style={{ height: '100px' }}>
      <TableCell colSpan={2}>
        <Typography variant="subtitle2" color={theme.palette.text.secondary}>
          <FormattedDate value={report.shift.dateOfService} />
        </Typography>
        <Typography variant="h5">
          {report.shift.name}
        </Typography>
      </TableCell>
      <TableCell>
        <TotalHeader title={intl.formatMessage({ id: 'reconciliation.report.total_on_pos' })}>
          <Box sx={{
            display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center',
          }}
          >
            <EditableAmountCell
              amount={totalOnPos}
              editable={report.totalOnPos.editable && report.status === CashupStatus.Open && !isTotalRefreshing}
              onSave={(newAmount) => {
                updateTotalOnPosMutation.mutate(newAmount);
              }}
            />
            {canRefreshUsingPosData && (
              <>
                {!isTotalRefreshing && (
                  <IconWrapper
                    size="small"
                    cursor="pointer"
                    onClick={refreshTotal}
                  >
                    <RefreshIcon />
                  </IconWrapper>
                )}
                {isTotalRefreshing && (
                  <CircularProgress />
                )}
              </>
            )}
          </Box>
        </TotalHeader>

      </TableCell>
      <TableCell>
        <TotalHeader title={intl.formatMessage({ id: 'reconciliation.report.total_reported' })}>
          <MoneyView value={totalReported} />
        </TotalHeader>
      </TableCell>
      <TableCell style={{ backgroundColor: color, color: 'white' }}>
        <TotalHeader title={intl.formatMessage({ id: 'reconciliation.report.delta' })}>
          <MoneyView value={delta} />
        </TotalHeader>
      </TableCell>
    </TableRow>
  );
};
