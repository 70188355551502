import { CircularProgress } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { useVenueKpiQuery } from './hooks/useVenueKpiQuery';
import { HomeContent } from './components/HomeContent';
import { useCurrentBusinessOrThrow } from '../business/hooks/useCurrentBusinessOrThrow';
import { StatusWrapper } from 'src/home2/components/organisms/Body/StatusCard/StatusCard';

export const Home = () => {
  const business = useCurrentBusinessOrThrow();
  const {
    data: venueKpi,
    isSuccess,
    isError,
    isLoading,
  } = useVenueKpiQuery(business.id, business.currency, business.timezone);

  if (isLoading) {
    return (
      <StatusWrapper sx={{
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '.MuiCircularProgress-root': {
          width: '50px',
        },
      }}>
        <CircularProgress size="large" />
      </StatusWrapper>
    );
  }
  if (isError) {
    return <FormattedMessage id="venue.home.error.message" />;
  }

  if (isSuccess && venueKpi) {
    return <HomeContent business={business} venueKpi={venueKpi} />;
  }

  return <></>;
};
