import { BlobWithMetadata } from 'src/accounting/payout/domain/BlobWithMetadata';
import axios from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { DateRange } from 'src/accounting/revenues/domain/RevenuesDatePresets';

export type ExportEnterprisePayoutArgs = {
  business_ids: string[];
  range: DateRange;
};

export const exportEnterprisePayouts = (args: ExportEnterprisePayoutArgs): Promise<BlobWithMetadata> => {
  const appConfiguration = ConfigurationLoader.load();
  return axios
    .post<Blob>(
    `${appConfiguration.sundayApiBaseUrl}/enterprise-gateway/accounting/multi-businesses/payouts`,
    {
      business_ids: args.business_ids, // POST payload
    },
    {
      headers: {
        accept: 'application/octet-stream',
      },
      params: {
        from: args.range.startDate.toDate(),
        to: args.range.endDate.toDate(),
        language: navigator.language,
      },
    },
  )
    .then((response) => ({
      blob: new Blob([response.data]),
      contentDisposition: response.headers['content-disposition'],
    }));
};

export type ExportEnterpriseInvoicesArgs = {
  business_ids: string[];
  year: string;
};

export const exportEnterpriseInvoices = (args: ExportEnterpriseInvoicesArgs): Promise<Blob> => {
  const appConfiguration = ConfigurationLoader.load();

  return axios
    .post<Blob>(
    `${appConfiguration.sundayApiBaseUrl}/enterprise-gateway/accounting/multi-businesses/invoices`,
    {
      business_ids: args.business_ids,
    },
    {
      headers: {
        accept: 'application/octet-stream',
      },
      responseType: 'blob',
      params: {
        year: args.year,
      },
    },
  )
    .then((response) => new Blob([response.data], { type: 'application/zip' }));
};

