/* eslint-disable max-lines */
import { Button } from '@sundayapp/b2b-react-component-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { BusinessId } from 'src/business/domain/Business';
import { usePaymentAdapter } from 'src/payments/page/usePaymentAdapter';
import { useShowReceiptErrorNotification } from 'src/payments/page/useShowReceiptErrorNotification';
import { AreaSelector } from '../../../area/components/AreaSelector/AreaSelector';
import { Area } from '../../../area/domain/Area';
import { BoxSelector } from '../../../box/components';
import { Box } from '../../../box/domain/Box';
import { getEndOfDay } from '../../../common/components/DatePickerAsSelect/DatePickerAsSelect';
import IconButton from '../../../common/components/IconButton';
import { RefreshIcon } from '../../../common/components/Icons';
import Spinner from '../../../common/components/Spinner';
import { getToday } from '../../../common/utils/getToday';
import { useInterval } from '../../../common/utils/useInterval';
import { FilterTag } from '../../../productAvailability/components/AvailabilityFilterTags/FilterTag';
import { VenueDetails } from '../../../venues/types';
import OrderDetails from '../../components/OrderDetails';
import SelectedBoxPacingData from '../../components/PacingData/SelectedBoxPacingData';
import {
  useGetFreshOrders,
  useGetNextPaginatedOrders,
  useGetPaginatedOrders,
  useHasAdditionalOrders,
  useSelectOrderForDetails,
  useUnselectOrderForDetails,
} from '../../hooks';
import { getOrderForDetails, getSelectedOrder, useVenueOrders } from '../../redux/selectors';
import { OrderStatus, OrderSummary } from '../../types';
import { buildColumns, isDateBeforeToday } from './BoxOrderColumns';
import {
  GridContainer,
  Header,
  OrderDetailsContainer,
  OrdersFilters,
  OrdersListContainer,
  OrdersListFooter,
  OrdersListLayout,
  StyledCustomScroll,
  StyledDatePicker,
  StyledOrdersDataGrid,
} from './OrdersList.style';
import { TextField } from '@mui/material';

type BoxOrdersListProps = {
  businessId: BusinessId;
  venue: VenueDetails;
  fixedBox?: Box;
};

const AUTO_REFRESH_TIME = 30000;
const ORDER_FETCH_LIMIT = 100;

const NOT_CONFIRMED_ORDERS_FILTER = 'NOT_CONFIRMED_ORDERS_FILTER';

// eslint-disable-next-line complexity
export const BoxOrdersList = ({ businessId, venue, fixedBox }: BoxOrdersListProps) => {
  const intl = useIntl();
  const user = useAuthenticatedUserOrThrow();
  const paymentAdapter = usePaymentAdapter();

  const orders = useVenueOrders();
  const showReceiptErrorNotification = useShowReceiptErrorNotification();

  const [selectedBox, setSelectedBox] = useState<Box | undefined>(fixedBox);
  const [selectedArea, setSelectedArea] = useState<Area | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date>(getToday());
  const [endDate, setEndDate] = useState<Date>(getEndOfDay(getToday()));
  const [orderDisplayId, setOrderDisplayId] = useState<string>();
  const [toRefreshOrderDisplayId, setToRefreshOrderDisplayId] = useState<string>();

  const [hasMoreOrders, setHasMoreOrders] = useState<boolean>();
  const [selectedFilter, setSelectedFilter] = useState<string>();
  const setDateRange = useCallback(
    (start: Date, end: Date) => {
      setStartDate(start);
      setEndDate(end);
    },
    [setStartDate, setEndDate],
  );

  const [orderFirstState, getFirstOrders] = useGetPaginatedOrders(
    venue.id,
    ORDER_FETCH_LIMIT,
    venue.isFoodCourt ? selectedBox?.id : undefined,
    selectedArea?.id,
    toRefreshOrderDisplayId,
  );

  const [nextOrdersState, getNextOrders] = useGetNextPaginatedOrders(
    venue.id,
    ORDER_FETCH_LIMIT,
    venue.isFoodCourt ? selectedBox?.id : undefined,
    selectedArea?.id,
    toRefreshOrderDisplayId,
  );

  const [computeAdditionalOrdersState, computeAdditionalOrders] = useHasAdditionalOrders(
    venue.id,
    venue.isFoodCourt ? selectedBox?.id : undefined,
    selectedArea?.id,
    toRefreshOrderDisplayId,
  );

  const [, getFreshOrders] = useGetFreshOrders(
    venue.id,
    venue.isFoodCourt ? selectedBox?.id : undefined,
    selectedArea?.id,
    toRefreshOrderDisplayId,
  );

  const selectedOrder = useSelector(getSelectedOrder);
  const orderForDetails = useSelector(getOrderForDetails);

  const fetchAdditionalOrders = () => {
    getNextOrders(startDate, endDate).then(() => {
      computeAdditionalOrders(startDate).then(setHasMoreOrders);
    });
  };

  const refreshOrders = () => {
    getFreshOrders(endDate);
  };

  //wait for 1sec before refreshing the order display id to avoid spamming backend
  useEffect(() => {
    const timeout = setTimeout(() => {
      setToRefreshOrderDisplayId(orderDisplayId);
    }, 1000);
    return () => clearTimeout(timeout);
  }, [orderDisplayId]);

  useEffect(() => {
    getFirstOrders(startDate, endDate).then(() => {
      computeAdditionalOrders(startDate).then(setHasMoreOrders);
    });
  }, [getFirstOrders, startDate, endDate, selectedArea, selectedBox, toRefreshOrderDisplayId]);

  useInterval(refreshOrders, AUTO_REFRESH_TIME, isDateBeforeToday(endDate));
  const withSidePanelOpened = orderForDetails !== undefined;
  const [, selectOrder] = useSelectOrderForDetails();
  const unselectOrder = useUnselectOrderForDetails();

  const toggleOrderSelection = useCallback(
    (order: OrderSummary) => {
      if (selectedOrder && selectedOrder.id === order.id) {
        unselectOrder();
      } else {
        selectOrder(order.id);
      }
    },
    [selectedOrder, selectOrder, unselectOrder],
  );

  useEffect(
    () => () => {
      unselectOrder();
    },
    [unselectOrder],
  );

  const onBoxChanged = (box: Box) => {
    setSelectedBox(box);
    setSelectedArea(undefined);
  };

  const onAreaChanged = (area: Area) => {
    setSelectedArea(area);
  };

  const areaSelector = useMemo(() => {
    if (!selectedBox) {
      return <></>;
    }

    return (
      <AreaSelector
        boxId={selectedBox.id}
        onAreaSelected={onAreaChanged}
        onAreaUnselected={() => setSelectedArea(undefined)}
      />
    );
  }, [selectedBox]);

  const changeFilter = useCallback(
    (filter?: string) => {
      const newFilter = selectedFilter === filter ? undefined : filter; // Toggle filter if already selected
      setSelectedFilter(newFilter);
    },
    [selectedFilter],
  );

  const handleDownloadReceipt = useCallback(async (order: OrderSummary) => {
    paymentAdapter
      .downloadReceiptForOrdering(order.id, order.displayId || 'receipt')
      .catch(showReceiptErrorNotification);
  }, []);

  const ORDERS_COLUMNS = useMemo(
    () => buildColumns(businessId, intl, user, handleDownloadReceipt),
    [intl, user, handleDownloadReceipt],
  );

  const filteredOrders = useMemo(() => {
    if (!orders) return undefined;
    if (!selectedFilter) return orders;
    if (selectedFilter === NOT_CONFIRMED_ORDERS_FILTER) {
      return orders.filter((o) => !o.paidBySunday && o.status === OrderStatus.PLACED);
    }
    return orders;
  }, [selectedFilter, orders]);

  return (
    <OrdersListLayout>
      <OrdersListContainer withSidePanelOpened={withSidePanelOpened}>
        <Header>
          <OrdersFilters>
            <StyledDatePicker value={[startDate, endDate]} onChange={setDateRange} type="range" />
            {!fixedBox && (
              <BoxSelector
                enrollmentId={venue.id}
                onBoxSelected={onBoxChanged}
                onBoxUnselected={() => setSelectedBox(undefined)}
              />
            )}
            {areaSelector}
            <FilterTag
              name={intl.formatMessage({ id: 'orders.filter.notConfirmed', defaultMessage: 'not confirmed' })}
              setSelected={() => {
                changeFilter(NOT_CONFIRMED_ORDERS_FILTER);
              }}
              selected={NOT_CONFIRMED_ORDERS_FILTER === selectedFilter}
            />
            <TextField
              onChange={(e: any) => {
                setOrderDisplayId(e.target.value ? e.target.value : undefined);
              }}
              value={orderDisplayId}
              size="small"
              label={intl.formatMessage({ id: 'orders.column.displayId' })}
            />
          </OrdersFilters>
          <IconButton Icon={RefreshIcon} size={50} onClick={refreshOrders} />
        </Header>
        {selectedBox !== undefined && <SelectedBoxPacingData boxId={selectedBox.id} />}
        {orderFirstState.error && <div>{`${orderFirstState.error.message}`}</div>}
        {orderFirstState.loading ? (
          <Spinner />
        ) : (
          filteredOrders && (
            <GridContainer>
              <StyledCustomScroll>
                <StyledOrdersDataGrid<any>
                  columns={ORDERS_COLUMNS}
                  rows={filteredOrders}
                  getSubItems={(value: OrderSummary) => value.regroupedOrderSummaries}
                  onLineClick={toggleOrderSelection}
                  data-testid="orders-list"
                />
                {!computeAdditionalOrdersState.loading && hasMoreOrders && !nextOrdersState.loading && (
                  <Button variant="primary" size="medium" fullWidth onClick={fetchAdditionalOrders}>
                    <FormattedMessage id="orders.additional-orders" defaultMessage="Load more orders" />
                  </Button>
                )}
              </StyledCustomScroll>
              {nextOrdersState.error && <div>{`${nextOrdersState.error.message}`}</div>}
              {nextOrdersState.loading && <Spinner />}
            </GridContainer>
          )
        )}
        <OrdersListFooter />
      </OrdersListContainer>
      <OrderDetailsContainer withSidePanelOpened={withSidePanelOpened}>
        {orderForDetails && <OrderDetails businessId={businessId} order={orderForDetails} />}
      </OrderDetailsContainer>
    </OrdersListLayout>
  );
};
