import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { TagRepositoryHttp } from '../../repositories/TagRepositoryHttp';
import { Tag } from '../../domain/Tag';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_TAGS = 'menu_business_tags';
const buildQueryKey = (businessId: BusinessId) =>
  [QUERYKEY_MENU_TAGS, businessId] as const;
export const getBusinessTagsQuery = async ({
  queryKey: [, businessId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Tag[]> => {
  const configuration = ConfigurationLoader.load();
  const tagRepositoryHttp = new TagRepositoryHttp(configuration.menuApiBaseUrl);
  return tagRepositoryHttp.findAllByBusinessId(businessId);
};

export const useGetTagsByBusiness = (businessId: BusinessId) =>
  useQuery({
    queryKey: buildQueryKey(businessId),
    queryFn: getBusinessTagsQuery,
  });
