import styled, { css } from 'styled-components';
import { colorPalette } from '../../../stylesheet';
import React from 'react';

export enum ButtonVariant {
  DEFAULT,
  PRIMARY,
  LIGHT,
}
export interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant?: ButtonVariant;
  borderLess?: boolean;
  danger?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 0 16px;
  height: 60px;
  border: 1px solid ${colorPalette.lightBorderColor};
  font-size: 18px;
  line-height: 18px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  ${({ variant }) =>
    variant
    && variant === ButtonVariant.PRIMARY
    && css`
      background-color: ${colorPalette.primaryBackgroundColor};
      color: ${colorPalette.primaryForegroundColor};
      border: 0;
      border-radius: 30px;
    `}

  ${({ variant, danger }) =>
    variant
    && variant === ButtonVariant.LIGHT
    && css`
      background-color: ${colorPalette.primaryForegroundColor};
      color: ${colorPalette.primaryBackgroundColor};
      border: 1px solid ${danger ? colorPalette.grey300 : colorPalette.primaryBackgroundColor};
      border-radius: 30px;
    `}

    ${({ disabled }) =>
    disabled
    && css`
      cursor: default;
      background-color: ${colorPalette.disabledBackgroundColor};
    `}
  &[disabled] {
    cursor: default;
    background-color: ${colorPalette.disabledBackgroundColor};
  }

  ${({ borderLess }) =>
    borderLess
    && css`
      border: 0;
      background: transparent;
    `}

  ${({ danger }) =>
    danger
    && css`
      color: ${colorPalette.red};
    `}
`;

export default Button;
