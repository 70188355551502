import { CurrencyCode, Money, money } from '@sundayapp/web-money';
import { AccountingRepository } from '../infrastructure/AccountingRepository';
import { ReportingDigest } from '../domain/ReportingDigest';
import { BusinessId } from 'src/business/domain/Business';

export class ReportingDigestUseCase {
  private noMoney: Money = money(0, CurrencyCode.UNKNOWN);

  constructor(
    private currency: CurrencyCode,
    private accountingRepository: AccountingRepository,
  ) {}

  async handle(businessId: BusinessId, startDate: Date, endDate: Date): Promise<ReportingDigest> {
    const summary = await this.accountingRepository.summaryOnAPeriod(businessId, startDate, endDate);

    const reportingDigest = new ReportingDigest();
    Object.entries(summary.detailsBySource).forEach(([provider, details]) => {
      reportingDigest.addDetails(
        provider,
        details.grossRevenue,
        details.tipRevenue,
        this.noMoney,
        this.noMoney,
        this.noMoney,
      );
    });

    if (summary.schemeDetailsBySource) {
      Object.entries(summary.schemeDetailsBySource).forEach(([provider, details]) => {
        reportingDigest.addSchemeDetails(provider, details);
      });
    }

    reportingDigest.additionalRevenue = summary.additionalRevenue;
    return reportingDigest;
  }
}
