import { Box, Grid, TableCell, TableRow, Theme, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { PaymentMethodBrand, PaymentMethodIcon } from '../../../../common/Icons/PaymentMethodIcon';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { RevenueAmountsCells } from './RevenueAmountsCells';
import { OtherRevenueIcon, OtherRevenueName } from '../../../../common/Icons/OtherRevenueIcon';
import { CellVariant } from './RevenueRow';
import { RevenueUnit } from '../../../domain/RevenueSummary';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

const paymentMethodIcons: { [key in PaymentMethodKnownLabel]: PaymentMethodBrand } = {
  Edenred: 'edenred',
  METHOD_EDENRED: 'edenred',
  UP: 'up',
  Up: 'up',
  Bimpli: 'bimpli',
  BIMPLI: 'bimpli',
  Swile: 'swile',
  Sodexo: 'sodexo',
  Pluxee: 'pluxee',
  PASS_RESTAURANT: 'sodexo',
  CB: 'carteBancaires',
  MASTERCARD: 'mastercard',
  VISA: 'visa',
  CHEQUE_DEJEUNER: 'up',
  EDENRED: 'edenred',
  SODEXO: 'sodexo',
  PLUXEE: 'pluxee',
  NATIXIS_INTERTITRES: 'bimpli',
  SWILE: 'swile',
  MAESTRO: 'maestro',
  ELECTRON: 'electron',
  JCB: 'jcb',
  UNION_PAY: 'unionpay',
};

const otherRevenueIcons: { [key in OtherRevenueKnownLabel]: OtherRevenueName } = {
  MERCHANT_CREDIT: 'merchantCredit',
  MANUAL: 'adjustment',
};

const paymentMethodLabels: { [key in PaymentMethodKnownLabel]: string } = {
  METHOD_EDENRED: 'Edenred',
  Edenred: 'Edenred',
  UP: 'Up',
  Up: 'Up',
  Bimpli: 'Bimpli',
  BIMPLI: 'Bimpli',
  Swile: 'Swile',
  Sodexo: 'Sodexo',
  Pluxee: 'Pluxee',
  PASS_RESTAURANT: 'Sodexo',
  CB: 'Cartes Bancaires',
  MASTERCARD: 'Mastercard',
  VISA: 'Visa',
  CHEQUE_DEJEUNER: 'up',
  EDENRED: 'Edenred',
  SODEXO: 'Sodexo',
  PLUXEE: 'Pluxee',
  NATIXIS_INTERTITRES: 'Bimpli',
  SWILE: 'Swile',
  ELECTRON: 'Visa Electron',
  MAESTRO: 'Maestro',
  JCB: 'JCB',
  UNION_PAY: 'Union Pay',
} as const;


const paymentMethodsKnownLabels = {
  QR_EDENRED: 'Edenred',
  QR_METHOD_EDENRED: 'METHOD_EDENRED',
  QR_UP: 'Up',
  QR_UP_UPPERCASE: 'UP',
  QR_BIMPLI: 'Bimpli',
  QR_BIMPLI_UPPERCASE: 'BIMPLI',
  QR_SWILE: 'Swile',
  QR_SODEXO: 'Sodexo',
  QR_PLUXEE: 'Pluxee',
  QR_SODEXO_PASS_RESTAURANT: 'PASS_RESTAURANT',
  PDQ_CB: 'CB',
  PDQ_MASTERCARD: 'MASTERCARD',
  PDQ_VISA: 'VISA',
  PDQ_UP: 'CHEQUE_DEJEUNER',
  PDQ_EDENRED: 'EDENRED',
  PDQ_SODEXO: 'SODEXO',
  PDQ_PLUXEE: 'PLUXEE',
  PDQ_BIMPLI: 'NATIXIS_INTERTITRES',
  PDQ_SWILE: 'SWILE',
  PDQ_MAESTRO: 'MAESTRO',
  PDQ_ELECTRON: 'ELECTRON',
  PDQ_JCB: 'JCB',
  PDQ_UNION_PAY: 'UNION_PAY',
} as const;

type PaymentMethodKnownLabel = ValuesOf<typeof paymentMethodsKnownLabels>;

const otherRevenuesKnownLabels = {
  MERCHANT_CREDIT: 'MERCHANT_CREDIT',
  MANUAL: 'MANUAL',
} as const;

type OtherRevenueKnownLabel = ValuesOf<typeof otherRevenuesKnownLabels>;

const isKnownOtherRevenueLabel = (otherRevenueLabel: string): otherRevenueLabel is OtherRevenueKnownLabel =>
  Object.values(otherRevenuesKnownLabels).includes(otherRevenueLabel as OtherRevenueKnownLabel);

const isKnownPaymentMethodLabel = (paymentMethodLabel: string): paymentMethodLabel is PaymentMethodKnownLabel =>
  Object.values(paymentMethodsKnownLabels).includes(paymentMethodLabel as PaymentMethodKnownLabel);

const otherRevenueIcon = (name: string): ReactNode => (
  <OtherRevenueIcon name={isKnownOtherRevenueLabel(name) ? otherRevenueIcons[name] : 'generic'} />
);

const paymentMethodIcon = (paymentMethod: string | undefined): ReactNode => (
  <PaymentMethodIcon
    brand={paymentMethod && isKnownPaymentMethodLabel(paymentMethod) ? paymentMethodIcons[paymentMethod] : 'generic'} />
);

const paymentMethodLabel = (originalLabel: string): string =>
  isKnownPaymentMethodLabel(originalLabel) ? paymentMethodLabels[originalLabel] : originalLabel;

export const RevenueUnitRow = ({
  row,
  isBusinessWithServiceCharge,
  isFromOtherRevenues,
  theme,
  isPaymentTerminal,
}: {
  row: RevenueUnit;
  isBusinessWithServiceCharge: boolean;
  isFromOtherRevenues: boolean;
  theme: Theme;
  isPaymentTerminal: boolean;
}) => {
  const cellStyle: CellVariant = { variant: 'subtitle1', color: theme.palette.text.secondary };
  const sx = { borderBottom: 'unset' };
  return (
    <TableRow sx={{ backgroundColor: '#FAFAFA' }}>
      <TableCell component="th" scope="row" sx={{ height: '56px', borderBottom: 'unset' }}>
        <Grid container>
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Box display="flex" gap="8px" justifyContent="start" alignItems="center">
              {isFromOtherRevenues ? otherRevenueIcon(row.name) : paymentMethodIcon(row.name)}
              <Typography variant="subtitle1" color={theme.palette.text.secondary} sx={{ whiteSpace: 'no-wrap' }}>
                {isFromOtherRevenues
                  ? <FormattedMessage id={`accounting.revenues.table.adjustments.${row.name as OtherRevenueKnownLabel}`} defaultMessage={row.name} />
                  : paymentMethodLabel(row.name)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </TableCell>
      <RevenueAmountsCells
        amounts={row.revenueAmounts}
        isBusinessWithServiceCharge={isBusinessWithServiceCharge}
        sx={sx}
        td={cellStyle}
        th={cellStyle}
        isPaymentTerminal={isPaymentTerminal}
      />
    </TableRow>
  );
};
