import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';

export const QUERYKEY_DIRECT_TIPPING_BUSINESS_CONFIGURATION = { scope: 'direct_tipping_business_configuration' };

const buildQueryKey = (businessId: string) => [QUERYKEY_DIRECT_TIPPING_BUSINESS_CONFIGURATION, businessId] as const;

export const getDirectTippingBusinessConfigurationQuery = (
  businessId: string,
): Promise<Record<EnrollmentId, boolean>> => {
  const configuration = ConfigurationLoader.load();
  const repository = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
  return repository.getDirectTippingConfiguration(businessId);
};

export const useGetDirectTippingBusinessConfiguration = (businessId: string) =>
  useQuery({
    queryKey: buildQueryKey(businessId),
    queryFn: () => getDirectTippingBusinessConfigurationQuery(businessId),
  });
