import { CustomizationBanner, WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { Customization } from './domain/Customization';
import { Business } from 'src/business/domain/Business';
import { IntlShape } from 'src/app/i18n/TypedIntl';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Panel, spaceUsages, Text, Title } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import PostPaymentPreview from './components/PostPaymentPreview';
import BannerSettings from './components/BannerSettings';
import React from 'react';
import { pixels } from 'src/sundayplus/Size';
import { Column } from 'src/sundayplus/Column';

interface Props {
  whiteLabelCustomization: WhiteLabelCustomization;
  customization: Customization | undefined;
  business: Business;
  onSaveBanner: (b: CustomizationBanner) => void;
  intl: IntlShape;
}

export function PostPayment({
  business: { patEnrollment },
  customization,
  intl,
  onSaveBanner,
  whiteLabelCustomization,
}: Props) {
  return (
    <Panel flexDirection="column" marginTop={spaceUsages.mediumLarge} marginBottom={spaceUsages.mediumSmall}>
      <Column gap={pixels(16)}>
        <Title size="title4" marginBottom={spaceUsages.mediumLarge} keepCase>
          <FormattedMessage id="settings.customization.postPayment.title" defaultMessage="Post payment" />
        </Title>
        <Text size="small" color={colorUsage.tertiaryText} keepCase>
          <FormattedMessage id="settings.customization.postPayment.description" />
        </Text>
        <BannerSettings
          PreviewComponent={PostPaymentPreview}
          previewComponentProps={{
            customizationColors: whiteLabelCustomization.color,
            logoUrl: customization?.logo?.logoUrls?.noResize,
          }}
          venueId={patEnrollment?.id!}
          banner={whiteLabelCustomization.postPaymentBanner || {}}
          previewBackgroundColor={whiteLabelCustomization.color.marketingBanners}
          onSaveBanner={onSaveBanner}
          imageRatio={3 / 2}
          title={intl.formatMessage({ id: 'settings.customization.banner.postPayment.pictureTitle' })}
          defaultImageFilename={'postPaymentBanner'}
          titleMaxLength={64}
          descriptionMaxLength={180}
        />
      </Column>
    </Panel>
  );
}
