import { WhiteLabelCustomizationRepository } from 'src/domain/customization/WhiteLabelCustomizationRepository';
import { Business } from 'src/business/domain/Business';
import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
import { DocumentRepository } from 'src/domain/document/DocumentRepository';

export class ApplyCollapsibleMenuBannerChanges {
  constructor(
    private documentRepositoryStorage: DocumentRepository,
    private whiteLabelCustomizationRepository: WhiteLabelCustomizationRepository,
  ) {}

  async apply(sourceBusiness: Business, targetBusinesses: Array<Business>) {
    const sourceCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      sourceBusiness.patEnrollment!.id,
    );
    for (const business of targetBusinesses) {
      await this.deleteCollapsibleMenuBanner(business);
      const savedPicture = await this.saveCollapsibleMenuBanner(sourceCustomization, business);
      await this.copyCollapsibleMenuBanner(sourceCustomization, savedPicture, business);
    }
  }

  private async copyCollapsibleMenuBanner(
    sourceCustomization: WhiteLabelCustomization,
    savedPicture: string | undefined,
    business: Business,
  ) {
    const currentCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      business.patEnrollment!.id,
    );
    const updatedCustomization: WhiteLabelCustomization = {
      ...currentCustomization,
      burgerBanner: { ...sourceCustomization.burgerBanner, imageUrl: savedPicture },
    };
    await this.whiteLabelCustomizationRepository.saveCustomization(business.patEnrollment!.id, updatedCustomization);
  }

  private async deleteCollapsibleMenuBanner(business: Business) {
    const existingCustomization = await this.whiteLabelCustomizationRepository.getCustomization(
      business.patEnrollment!.id,
    );
    if (existingCustomization.burgerBanner?.imageUrl) {
      await this.documentRepositoryStorage.deleteRefFromUrl(existingCustomization.burgerBanner.imageUrl);
    }
  }

  private async saveCollapsibleMenuBanner(
    sourceCustomization: WhiteLabelCustomization,
    business: Business,
  ): Promise<string | undefined> {
    if (!sourceCustomization.burgerBanner?.imageUrl) {
      return undefined;
    }
    const response = await fetch(sourceCustomization.burgerBanner?.imageUrl);
    const blob = await response.blob();
    const file = new File([blob], 'menuBanner.jpg', { type: blob.type });
    return this.documentRepositoryStorage.saveFile(business.patEnrollment!.id, `customization/${file.name}`, file);
  }
}
