import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { VenueDetails } from '../../../types';
import { SettingsCategory } from './SettingsCategory';
import { DisplayVenueInfoSetting } from './VenueInfo/DisplayVenueInfoSetting';
import { VenuePictureSetting } from './VenuePicture/venuePictureSetting';
import { WebsiteUrlVenueSetting } from './WebsiteUrl/WebsiteUrlVenueSetting';
import FoodCourtMapSettings from './FoodCourtMapSettings';

type FoodCourtSettingsProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FoodCourtSettings = ({ venue, setVenue }: FoodCourtSettingsProps) => {
  const intl = useIntl();
  return (
    <Container>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'settings.app_settings.app_design.title',
          defaultMessage: 'app design',
        })}
      >
        <DisplayVenueInfoSetting venue={venue} setVenue={setVenue} />
        <VenuePictureSetting venue={venue} setVenue={setVenue} />
        <FoodCourtMapSettings venue={venue} />
        <WebsiteUrlVenueSetting venue={venue} setVenue={setVenue} />
      </SettingsCategory>
    </Container>
  );
};
