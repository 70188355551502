import axios from 'axios';
import { EnrollmentConfigurationRepository } from '../domain/EnrollmentConfigurationRepository';
import { EnrollmentConfiguration } from '../domain/EnrollmentConfiguration';

export class HttpEnrollmentConfigurationRepository implements EnrollmentConfigurationRepository {
  constructor(private waiterAppBackEndUrl: string) {
  }

  async enrollmentConfiguration(enrollmentId: string): Promise<EnrollmentConfiguration> {
    const response = await axios.get(`${this.waiterAppBackEndUrl}/businesses/${enrollmentId}/configuration`);
    return EnrollmentConfiguration.parse(response.data);
  }

  async save(businessId: string, enrollmentConfiguration: EnrollmentConfiguration): Promise<void> {
    (await axios.post(`${this.waiterAppBackEndUrl}/businesses/${businessId}/configuration`, enrollmentConfiguration));
    return Promise.resolve();
  }
}
