import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Dropdown } from '@sundayapp/b2b-react-component-library';
import { PayWithCashLabel, VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { SettingExpandablePanel } from 'src/ordering/common/components/Settings/SettingExpandablePanel';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import { payWithCashLabelOptions } from './PayWithCashLabelOption';
import { PayWithCashDisclaimerModal } from './PayWithCashDisclaimerModal';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { Switch } from '@mui/material';

type PayWithCashVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

type PayWithCashLabelOption = {
  id: PayWithCashLabel;
  label: string;
};

type SettingValues = {
  sundayPaymentMandatory: boolean;
  requireOrderApproval?: boolean;
  payWithCashLabel?: string;
};

export const PayWithCashVenueSetting = ({ venue, setVenue }: PayWithCashVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isActivationLoading, setActivationLoading] = useState<boolean>(false);
  const [displayDisclaimer, setDisplayDisclaimer] = useState<boolean>(false);
  const [expanded, setExpansion] = useState<boolean>(false);

  const currentSettings: SettingValues = {
    sundayPaymentMandatory: venue.sundayPaymentMandatory,
    requireOrderApproval: venue.requireOrderApproval,
    payWithCashLabel: venue.payWithCashLabel,
  };

  const venueUpdated = async (values: SettingValues) => {
    setActivationLoading(true);
    await updateVenueDetails({
      ...values,
    });
    setVenue({
      ...venue,
      ...values,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    setActivationLoading(false);
  };

  const options: PayWithCashLabelOption[] = payWithCashLabelOptions(intl);

  const selectedOption = options.find((option) => option.id === venue.payWithCashLabel) || options[0];

  const updateCashPaymentActivation = async (activated: boolean) => {
    if (!displayDisclaimer && activated) {
      setDisplayDisclaimer(true);
      return;
    }
    setDisplayDisclaimer(false);

    if (currentSettings.payWithCashLabel === undefined) {
      currentSettings.payWithCashLabel = 'payWithCash';
    }

    await venueUpdated({ ...currentSettings, sundayPaymentMandatory: !activated });

    if (activated) {
      setExpansion(true);
    }
  };

  const updateRequireOrderApproval = async (required: boolean) => {
    await venueUpdated({ ...currentSettings, requireOrderApproval: !required });
  };

  const setPayWithCashLabelFromOption = async (payWithCashOption: PayWithCashLabelOption) => {
    await venueUpdated({ ...currentSettings, payWithCashLabel: payWithCashOption.id });
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.allowPaymentOutsideSunday',
        defaultMessage: 'allow payment outside of sunday',
        description: 'Settings to allow payment outside sunday (pay with cash, pay at counter, etc.)',
      })}
      description={intl.formatMessage({
        id: 'venue.allowPaymentOutsideSundayDescription',
        defaultMessage:
          'this functionality allows orders to be sent to pos without payment system. choose the display text for the button.',
        description: 'Description of the cash payment settings',
      })}
      toggleValue={!venue.sundayPaymentMandatory}
      toggleOnChange={updateCashPaymentActivation}
      disabled={isActivationLoading}
    >
      <PayWithCashDisclaimerModal
        isOpen={displayDisclaimer}
        onCancel={() => setDisplayDisclaimer(false)}
        onConfirm={() => updateCashPaymentActivation(true)}
      />
      <SettingExpandablePanel visible={!venue.sundayPaymentMandatory} expanded={expanded} expandChange={setExpansion}>
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.autoAcceptCashOrderDisplayText',
            defaultMessage: 'choose the display text for the button',
          })}
        >
          <Dropdown
            size="small"
            options={options}
            value={selectedOption}
            labelField="label"
            onChange={setPayWithCashLabelFromOption}
          />
        </SettingLine>

        <SettingLine
          title={intl.formatMessage({
            id: 'venue.autoAcceptCashOrder',
            defaultMessage: 'auto-accept cash order',
          })}
          description={intl.formatMessage({
            id: 'venue.autoAcceptCashOrderInfoMessage',
            defaultMessage:
              'when this is active, orders will be sent directly to the kitchen without needing staff confirmation of payment',
          })}
        >
          <Switch
            checked={venue.requireOrderApproval === undefined ? true : !venue.requireOrderApproval}
            onChange={(_, v) => updateRequireOrderApproval(v)}
            disabled={isActivationLoading}
          />
        </SettingLine>
      </SettingExpandablePanel>
    </SettingPanel>
  );
};
