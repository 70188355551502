/* eslint-disable */

export const EditIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon Left">
      <path id="Icon (Stroke)" fillRule="evenodd" clipRule="evenodd"
            d="M13.1608 2.3274C14.1766 1.3116 15.8235 1.3116 16.8393 2.3274C17.8551 3.34319 17.8551 4.99012 16.8393 6.00591L6.37037 16.4749C6.35387 16.4914 6.33752 16.5077 6.3213 16.524C6.08177 16.764 5.87057 16.9756 5.61596 17.1316C5.39228 17.2687 5.14842 17.3697 4.89332 17.4309C4.60296 17.5006 4.304 17.5003 3.96493 17.5C3.94197 17.5 3.91883 17.5 3.8955 17.5H2.50004C2.03981 17.5 1.66671 17.1269 1.66671 16.6667V15.2712C1.66671 15.2479 1.66669 15.2248 1.66667 15.2018C1.66635 14.8627 1.66607 14.5638 1.73579 14.2734C1.79703 14.0183 1.89804 13.7744 2.03511 13.5508C2.19114 13.2961 2.40273 13.0849 2.64272 12.8454C2.65896 12.8292 2.67534 12.8128 2.69184 12.7963L13.1608 2.3274ZM15.6608 3.50591C15.2959 3.14099 14.7042 3.14099 14.3393 3.50591L3.87035 13.9749C3.55405 14.2912 3.49573 14.3571 3.45618 14.4216C3.41049 14.4962 3.37682 14.5774 3.3564 14.6625C3.33873 14.7361 3.33338 14.8239 3.33338 15.2712V15.8333H3.8955C4.34281 15.8333 4.43065 15.828 4.50425 15.8103C4.58928 15.7899 4.67056 15.7562 4.74513 15.7105C4.80966 15.671 4.87556 15.6127 5.19186 15.2963L15.6608 4.8274C16.0257 4.46248 16.0257 3.87083 15.6608 3.50591ZM9.16669 16.6667C9.16669 16.2064 9.53979 15.8333 10 15.8333H17.5C17.9603 15.8333 18.3334 16.2064 18.3334 16.6667C18.3334 17.1269 17.9603 17.5 17.5 17.5H10C9.53979 17.5 9.16669 17.1269 9.16669 16.6667Z"
            fill="black" />
    </g>
  </svg>
);
/* eslint-enable */
