import * as React from 'react';
import { useMemo, useState } from 'react';
import {
  CellContext,
  ColumnHelper,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Money, MoneyView } from '@sundayapp/web-money';
import { FormattedDate, FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Table, TableBody, TableHead, TableHeader, TableRow } from '@sundayapp/b2b-react-component-library';
import EditIcon from '@mui/icons-material/Edit';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, TableFooter, Tooltip, Typography } from '@mui/material';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DeltaDetails from './DeltaDetails';
import {
  CashupStatus,
  ReconciliationReport,
  totalDelta,
  totalOnPos,
  totalReported,
} from '../domain/ReconciliationReport';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { downloadBlob, toYyyyMmDdFormat } from 'src/accounting/payout/pages/utils';
import { venueReconciliationReportPath } from 'src/app/navigation/pathHelpers';
import { deltaIsAcceptable } from './DeltaAcceptable';
import { ReportSettings } from '../domain/ReportSettings';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { LocalisationKey } from 'src/lang/en';

const TableCell = styled('td')`
  padding: 9px 0 9px 16px;
  font-size: 16px;
`;

const ReportsTable = styled(Table)`
  width: 100%;
  border-radius: 40px;
  border-collapse: collapse;

  & > tr,
  thead {
    line-height: 50px;
    border-bottom: 1px solid lightgrey;
  }

  & > tr:last-child {
    border: none;
  }
`;

const SortableHeader = styled('div')`
  display: flex;
  align-items: center;

  & > svg {
    width: 32px;
    height: 32px;
  }
`;

const ActionBox = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  ${Button} {
    width: 50px;
  }
`;

const columnHelper: ColumnHelper<ReconciliationReport> = createColumnHelper();
const moneySorter = (rowA: Row<ReconciliationReport>, rowB: Row<ReconciliationReport>, columnId: any) =>
  (rowA.renderValue(columnId) as Money).amount - (rowB.renderValue(columnId) as Money).amount;

type Props = {
  reports: ReconciliationReport[];
  reportSettings: ReportSettings;
};

const statusLocalisationKey: Record<CashupStatus, LocalisationKey> = {
  [CashupStatus.Open]: 'reconciliation.report.status.open',
  [CashupStatus.Closed]: 'reconciliation.report.status.closed',
};

const ReconciliationTable = ({ reports, reportSettings }: Props) => {
  const business = useCurrentBusinessOrThrow();
  const [sorting, setSorting] = useState<SortingState>([]);
  const reconciliationRepository = new ReconciliationRepositoryHttp();
  const navigate = useNavigate();
  const intl = useIntl();

  const downloadExport = (dateOfService: Date) => {
    reconciliationRepository.exportByShift(business.id, dateOfService, dateOfService).then((blobWithMetadata) => {
      const date = toYyyyMmDdFormat(dateOfService);
      downloadBlob(blobWithMetadata, `reconciliation-${business.name}-${date}.csv`);
    });
  };

  const defaultColumns = useMemo(
    () => [
      columnHelper.accessor((r) => r.shift.dateOfService, {
        id: 'date',
        header: intl.formatMessage({ id: 'reconciliation.report_table.date' }),
        cell: (info) => <FormattedDate value={info.getValue()} />,
      }),
      columnHelper.accessor((r) => r.shift.name, {
        id: 'service_name',
        header: intl.formatMessage({ id: 'reconciliation.report_table.service_name' }),
      }),
      columnHelper.accessor((r) => totalOnPos(business.currency, r), {
        id: 'on_pos',
        header: intl.formatMessage({ id: 'reconciliation.report_table.on_pos' }),
        cell: (info) => <MoneyView value={info.getValue()} />,
        sortingFn: moneySorter,
      }),
      columnHelper.accessor((r) => totalReported(business.currency, r), {
        id: 'reported',
        header: intl.formatMessage({ id: 'reconciliation.report_table.reported' }),
        cell: (info) => <MoneyView value={info.getValue()} />,
        sortingFn: moneySorter,
      }),
      columnHelper.accessor((r) => totalDelta(business.currency, r), {
        id: 'delta',
        header: intl.formatMessage({ id: 'reconciliation.report_table.delta' }),
        cell: (info) => (
          <DeltaDetails
            delta={info.getValue()}
            deltaIsAcceptable={deltaIsAcceptable(info.getValue(), reportSettings)}
          />
        ),
        sortingFn: moneySorter,
      }),
      columnHelper.accessor((r) => r.status, {
        id: 'status',
        header: intl.formatMessage({ id: 'reconciliation.report_table.status' }),
        cell: (status) => <FormattedMessage id={statusLocalisationKey[status.getValue()]} />,
      }),
      columnHelper.display({
        id: 'actions',
        header: intl.formatMessage({ id: 'reconciliation.report_table.actions' }),
        cell: (info: CellContext<ReconciliationReport, unknown>) => {
          const { row } = info;
          const report = row.original;
          return (
            <ActionBox>
              <Button
                variant="secondary"
                size="xSmall"
                onClick={() => {
                  navigate(venueReconciliationReportPath(business.id, report.id));
                }}
              >
                {report.status === CashupStatus.Open && <EditIcon />}
                {report.status === CashupStatus.Closed && <ZoomInIcon />}
              </Button>
              <Button
                variant="secondary"
                size="xSmall"
                onClick={() => {
                  downloadExport(report.shift.dateOfService);
                }}
              >
                <DownloadIcon />
              </Button>
            </ActionBox>
          );
        },
      }),
      columnHelper.accessor((r) => r.reporter, {
        id: 'reporter',
        header: intl.formatMessage({ id: 'reconciliation.report_table.reporter' }),
        cell: (reporter) => (
          <>
            <Typography variant="body2">{reporter.getValue()}</Typography>
          </>
        ),
      }),
      columnHelper.accessor((r) => r.comment, {
        id: 'comment',
        header: intl.formatMessage({ id: 'reconciliation.report_table.comment' }),
        cell: (reporter) => (
          <>
            {reporter.getValue() && (
              <Tooltip title={reporter.getValue()} placement="top">
                <AnnouncementIcon />
              </Tooltip>
            )}
          </>
        ),
      }),
    ],
    [business, intl],
  );

  const table = useReactTable({
    data: reports,
    columns: defaultColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <ReportsTable size="large">
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHeader key={header.id}>
                {header.isPlaceholder ? null : (
                  <SortableHeader
                    {...{
                      className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(
                      <div>{header.column.columnDef.header?.toString() ?? ''}</div>,
                      header.getContext(),
                    )}
                    {{
                      asc: <ArrowDropUpIcon />,
                      desc: <ArrowDropDownIcon />,
                    }[header.column.getIsSorted() as string] ?? null}
                  </SortableHeader>
                )}
              </TableHeader>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => (
          <TableRow key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </TableFooter>
    </ReportsTable>
  );
};

export default ReconciliationTable;
