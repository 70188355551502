import { UseGoogleLoginOptionsAuthCodeFlow } from '@react-oauth/google';
import { createContext, useContext } from 'react';

export interface GoogleOauthFacade {
  useGoogleLogin: (options: UseGoogleLoginOptionsAuthCodeFlow) => () => void;
}

export const GoogleOauthFacadeContext = createContext<GoogleOauthFacade>({
  useGoogleLogin: () => () => {},
});

export const useGoogleOauthFacade = () => useContext(GoogleOauthFacadeContext);
