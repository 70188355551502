import { useVenueRepository } from '../venues/hook';
import { SundayDevice } from '../area/domain/SundayDevice';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const useGetSundayDevices = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn<SundayDevice[], [enrollmentId: EnrollmentId]>(
    async (enrollmentId: EnrollmentId) => venueRepository.getSundayDevices(enrollmentId),
    [venueRepository],
  );
};
