import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { GrantRoleModal } from './modals/GrantRoleModal';
import { RemoveUserRoleModal } from './modals/RemoveUserRoleModal';
import { UpdateUserRoleModal } from './modals/UpdateUserRoleModal';
import { ActionContainer } from '../UserPermissions.styles';
import { userRoles } from 'src/domain/user/UserRole';
import { SUNDAY_FOR_RESTAURANTS_WIDTH } from 'src/app/pages/constants';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useGrantUserRole } from '../mutation/useGrantUserRole';
import { useRemoveUserRole } from '../mutation/useRemoveUserRole';
import { useUpdateUserRole } from '../mutation/useUpdateUserRole';
import { useListBusinessUsersQuery } from '../query/useListBusinessUsersQuery';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { UnlinkPosUsernameModal } from './modals/UnlinkPosUsernameModal';
import { BusinessUser } from 'src/domain/user/BusinessUser';
import { useUnlinkPosUserName } from '../mutation/useUnlinkPosUserName';
import { capitalizeFirstLetter } from './stringUtils';
import { userRoleLocalisations } from 'src/pages/Settings/UserPermissions/i18n/UserRoleLocalisation';
import { FaqLink } from 'src/pages/Settings/UserPermissions/components/UserPermissionsComponent';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetAllStaff } from 'src/tips/dispatch/infrastructure/useGetAllStaff';
import InfoIcon from '@mui/icons-material/Info';
import { useGetBusinessTippingConfiguration } from 'src/tips/queries/useGetBusinessTippingConfiguration';
import { isOpsEmail } from 'src/auth/domain/user';
import { useNavigate } from 'react-router';
import { AttachMoney } from '@mui/icons-material';
import { staffTippingSummaryPath } from 'src/app/navigation/pathHelpers';

const UserPermissionsWithPosConnections = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const businessId = useBusinessIdOrThrow();
  const { data: businessStaffs, isLoading: isBusinessStaffsLoading } = useGetAllStaff(businessId);
  const currentUser = useAuthenticatedUserOrThrow();
  const { data: users, isLoading } = useListBusinessUsersQuery(businessId);
  const { data: businessTippingConfiguration, isLoading: isBusinessTippingConfigurationLoading } =
    useGetBusinessTippingConfiguration(businessId);
  const { grantUserRole } = useGrantUserRole(businessId);
  const { removeUserRole } = useRemoveUserRole(businessId);
  const { updateUserRole } = useUpdateUserRole(businessId);
  const { unlinkPosUserName } = useUnlinkPosUserName(businessId);
  const [removeRoleModalIsOpen, setRemoveRoleModalIsOpen] = useState<boolean>(false);
  const [updateRoleModalIsOpen, setUpdateRoleModalIsOpen] = useState<boolean>(false);
  const [grantRoleModalIsOpen, setGrantRoleModalIsOpen] = useState<boolean>(false);
  const [unlinkFromPosUsernameModalIsOpen, setUnlinkFromPosUsernameModalIsOpen] = useState<boolean>(false);

  const [selectedUser, setSelectedUser] = useState<BusinessUser | undefined>();

  const isSundayUser = isOpsEmail(currentUser.email) || currentUser.impersonated;

  const onDelete = (user: BusinessUser) => {
    setSelectedUser(user);
    setRemoveRoleModalIsOpen(true);
  };

  const onUpdateRole = (user: BusinessUser) => {
    setSelectedUser(user);
    setUpdateRoleModalIsOpen(true);
  };

  const onUnlinkPosUserName = (user: BusinessUser) => {
    setSelectedUser(user);
    setUnlinkFromPosUsernameModalIsOpen(true);
  };

  if (
    isLoading ||
    isBusinessStaffsLoading ||
    isBusinessTippingConfigurationLoading ||
    !users ||
    !businessStaffs ||
    !businessTippingConfiguration
  ) {
    return;
  }

  const isBusinessUsingSundayTipping = businessTippingConfiguration.type === 'SundayTipping';

  const userIsEligibleToReceiveTips = ({ userId }: BusinessUser) =>
    businessStaffs.findIndex(({ externalId }) => externalId === userId) > -1;

  return (
    <Card sx={{ maxWidth: `${SUNDAY_FOR_RESTAURANTS_WIDTH}px` }}>
      <CardHeader title={intl.formatMessage({ id: 'settings.user_permissions' })} subheader={<FaqLink />} />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{intl.formatMessage({ id: 'settings.user_permissions.name' })}</TableCell>
              <TableCell>
                {intl.formatMessage({ id: 'settings.user_permissions.role', defaultMessage: 'Role' })}
              </TableCell>
              <TableCell>{intl.formatMessage({ id: 'settings.user_permissions.phone_number' })}</TableCell>
              <TableCell>
                {intl.formatMessage({ id: 'settings.user_permissions.email', defaultMessage: 'Email' })}
              </TableCell>
              <TableCell>{intl.formatMessage({ id: 'settings.user_permissions.pos_username' })}</TableCell>
              {isBusinessUsingSundayTipping && (
                <TableCell>{intl.formatMessage({ id: 'settings.user_permission.eligible_to_tips' })}</TableCell>
              )}
              <TableCell>{intl.formatMessage({ id: 'common.actions', defaultMessage: 'Actions' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.userId}>
                <TableCell>
                  {capitalizeFirstLetter(user.firstName)} {user.lastName?.toUpperCase()}
                </TableCell>
                <TableCell>{intl.formatMessage({ id: userRoleLocalisations[user.role] })}</TableCell>
                <TableCell>{user.phoneNumber}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell align={'center'}>
                  <Stack direction={'row'} alignItems={'center'}>
                    {user.posUserName && <Chip label={user.posUserName} color="success" />}
                    {!user.posUserName && (
                      <Chip
                        label={intl.formatMessage({
                          id: 'settings.user_permissions.pos_username_not_linked',
                          defaultMessage: 'Not linked',
                        })}
                        color="warning"
                      />
                    )}
                  </Stack>
                </TableCell>

                {isBusinessUsingSundayTipping && (
                  <TableCell align={'center'}>
                    {userIsEligibleToReceiveTips(user) ? (
                      <Chip
                        label={intl.formatMessage({
                          id: 'settings.user_permission.eligible_to_tips.eligible',
                        })}
                        color="success"
                      />
                    ) : (
                      <Stack>
                        <Tooltip
                          title={intl.formatMessage({
                            id: 'settings.user_permission.eligible_to_tips.not_eligible.explanation',
                          })}
                        >
                          <Chip
                            icon={<InfoIcon />}
                            label={intl.formatMessage({
                              id: 'settings.user_permission.eligible_to_tips.not_eligible',
                            })}
                            color="warning"
                          />
                        </Tooltip>
                      </Stack>
                    )}
                  </TableCell>
                )}

                <TableCell>
                  <ActionContainer>
                    <Tooltip
                      title={intl.formatMessage({ id: 'settings.user_permissions.roles.tooltip' })}
                      placement="top"
                    >
                      <Tooltip
                        title={intl.formatMessage({ id: 'settings.user_permissions.remove.tooltip' })}
                        placement="top"
                      >
                        <IconButton disabled={currentUser.userId === user.userId} onClick={() => onUpdateRole(user)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </Tooltip>
                    <Tooltip
                      title={intl.formatMessage({ id: 'settings.user_permissions.remove.tooltip' })}
                      placement="top"
                    >
                      <IconButton
                        disabled={currentUser.userId === user.userId}
                        color="error"
                        onClick={() => onDelete(user)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    {user.posUserName && (
                      <Tooltip
                        title={intl.formatMessage({ id: 'settings.user_permissions.unlink_pos_username.tooltip' })}
                        placement="top"
                      >
                        <IconButton onClick={() => onUnlinkPosUserName(user)}>
                          <LinkOffIcon sx={{ maxHeight: '16px', maxWidth: '16px' }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {isSundayUser && userIsEligibleToReceiveTips(user) && (
                      <Tooltip title={'Tips report'} placement="top">
                        <IconButton onClick={() => navigate(staffTippingSummaryPath(businessId, user.userId))}>
                          <AttachMoney
                            sx={{
                              maxHeight: '16px',
                              maxWidth: '16px',
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ActionContainer>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {grantRoleModalIsOpen && (
          <GrantRoleModal
            onGrantUserRole={(email, role) => grantUserRole({ email, role })}
            isOpen={grantRoleModalIsOpen}
            handleClose={() => setGrantRoleModalIsOpen(false)}
            handleConfirm={() => setGrantRoleModalIsOpen(false)}
          />
        )}
        {removeRoleModalIsOpen && (
          <RemoveUserRoleModal
            onRemoveUserRole={(userId) => removeUserRole({ userId })}
            userToRemove={selectedUser!}
            isOpen={removeRoleModalIsOpen}
            handleClose={() => setRemoveRoleModalIsOpen(false)}
            handleConfirm={() => setRemoveRoleModalIsOpen(false)}
          />
        )}
        {updateRoleModalIsOpen && (
          <UpdateUserRoleModal
            onUpdateUserRole={(userId, role) => updateUserRole({ userId, role })}
            userToUpdate={selectedUser!}
            isOpen={updateRoleModalIsOpen}
            handleClose={() => setUpdateRoleModalIsOpen(false)}
            handleConfirm={() => setUpdateRoleModalIsOpen(false)}
          />
        )}
        {unlinkFromPosUsernameModalIsOpen && (
          <UnlinkPosUsernameModal
            onUnlinkClicked={(userId) => unlinkPosUserName({ userId })}
            user={selectedUser!}
            isOpen={unlinkFromPosUsernameModalIsOpen}
            handleClose={() => setUnlinkFromPosUsernameModalIsOpen(false)}
            handleConfirm={() => setUnlinkFromPosUsernameModalIsOpen(false)}
          />
        )}
      </CardContent>
    </Card>
  );
};
export default withRestrictedAccess([userRoles.ADMIN], UserPermissionsWithPosConnections);
