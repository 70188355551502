import styled from 'styled-components';
import { InputDatePicker } from '@sundayapp/b2b-react-component-library';
import { colorPalette, getSpacing } from '../../stylesheet';

export const PromoCodeDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(6)} ${getSpacing(3)} ${getSpacing(4)} ${getSpacing(3)};
  margin-bottom: 200px;
`;

export const PromoCodeTitle = styled.div`
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.05em;
  margin: 0 0 ${getSpacing(3)};
`;

export const PromoCodeDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${getSpacing(1)} 0;
`;

export const PromoCodeBoxes = styled.div`
  display: block;
  align-items: center;
  justify-content: space-between;
  margin: ${getSpacing(1)} 0;
`;

export const PromoCodeDateRange = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  align-content: space-between;
  margin: ${getSpacing(1)} 0;
`;

export const PromoCodeStyledDatePicker = styled(InputDatePicker)`
  width: 100%;
  height: ${getSpacing(5)};
  margin: 0 ${getSpacing(1.5)} ${getSpacing(0.5)} 0;
  background-color: ${colorPalette.defaultBackgroundColor};
`;

export const PromoCodeDetailsLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PromoCodeDetailsLineTitle = styled.div`
  color: ${colorPalette.grey500};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.03em;
  text-transform: lowercase;
  margin-bottom: ${getSpacing(1)};
`;

export const PromoCodeDetailsLineContent = styled.div`
  padding-bottom: 0;
`;
