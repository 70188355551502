import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CreateArea } from '../domain/CreateArea';
import { Area } from '../domain/Area';

export interface AreasState {
  areas?: Area[];
  selectedAreaId?: string;
}

interface UpdateArea {
  areaId: string;
  update: Partial<CreateArea>;
}

const initialState: AreasState = {};

export const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    setAreas: (state, action: PayloadAction<Area[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.areas = action.payload;
    },
    addArea: (state, action: PayloadAction<Area>) => {
      // eslint-disable-next-line no-param-reassign
      state.areas = [...(state.areas || []), action.payload];
    },
    deleteArea: (state, action: PayloadAction<string>) => {
      if (!state.areas) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      state.areas = state.areas.filter((area) => area.id !== action.payload);
    },
    updateArea: (state, action: PayloadAction<UpdateArea>) => {
      if (!state.areas) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      state.areas = state.areas.map((area) =>
        (area.id === action.payload.areaId
          ? {
            ...area,
            ...action.payload.update,
          }
          : area));
    },
    selectAreaId: (state, action: PayloadAction<string | undefined>) => {
      // eslint-disable-next-line no-param-reassign
      state.selectedAreaId = action.payload;
    },
    setDefaultArea: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line no-param-reassign
      state.areas = state.areas!.map((area) => ({
        ...area,
        defaultArea: area.id === action.payload,
      }));
    },
  },
});

export const {
  setAreas, addArea, updateArea, selectAreaId, setDefaultArea, deleteArea,
} = areasSlice.actions;
export default areasSlice.reducer;
