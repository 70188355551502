import { Box, styled } from '@mui/material';
import sunday from '/sunday-logo.png';

export const LogoWrapper = styled(Box)(({ theme }) => ({
  background: `url(${sunday}) no-repeat center`,
  backgroundSize: 'contain',
  padding: `0px ${theme.spacing(2)}`,
  marginLeft: 4,
})) as typeof Box;

export const TitleEmphasisBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  color: theme.palette.primary.main,
})) as typeof Box;

export const SubTitleBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: `${theme.spacing(1)} 0px`,
  padding: `${theme.spacing(2)} 0px`,

  [theme.breakpoints.down('tablet')]: {
    alignItems: 'start',
    flexDirection: 'column',
  },

  '>:last-child': {
    marginTop: theme.spacing(1),
  },
}));
