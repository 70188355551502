import { z } from 'zod';
import { v4 as uuidv4 } from 'uuid';
import { SundayFetchMode } from './SundayFetchMode';

export const PaymentMethodSettingEdition = z.object({
  editable: z.boolean().default(true),
  paymentMethodSettingType: z.string(),
});

export const PaymentMethodSettings = z.object({
  id: z.string().default(() => uuidv4()),
  name: z.string(),
  type: z.string(),
  activated: z.boolean().default(true),
  onPos: PaymentMethodSettingEdition,
  reported: PaymentMethodSettingEdition,
  splitByDigitalMealVoucher: z.boolean().default(false),
  splitByAmericanExpress: z.boolean().default(false),
  rootFilter: z.string().optional(),
  removable: z.boolean().default(true),
  posPaymentMethodIds: z.array(z.string()).default([]),
});

export const ActivableSettingZ = z.object({
  active: z.boolean().default(false),
});

export const ReportSettings = z.object({
  paymentMethodSettings: z.array(PaymentMethodSettings).default([]),
  totalOnPosEditable: z.boolean().default(false),
  documentationActivated: z.boolean().default(false),
  documentation: z.string().optional(),
  sundayFetchMode: z.nativeEnum(SundayFetchMode).catch(SundayFetchMode.WITHOUT_TIPS),
  defaultFloatAmount: z.number().optional(),
  forceCommentIfDelta: ActivableSettingZ.extend({
    allowedAmountBeforeForcing: z.number().default(0),
  }).default({ active: true, allowedAmountBeforeForcing: 0 }),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentMethodSettingEdition = z.infer<typeof PaymentMethodSettingEdition>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PaymentMethodSettings = z.infer<typeof PaymentMethodSettings>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ReportSettings = z.infer<typeof ReportSettings>;
