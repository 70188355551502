import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Money } from '@sundayapp/web-money';
import { Product } from '../../../domain/Product';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { MoneyForm } from './MoneyForm';

type Props = {
  product: Product;
  productUpdate: UpdateProductDto;
  setProductUpdate: (productDto: UpdateProductDto) => void;
  disabled: boolean;
};

export const ProductPrice = ({ product, productUpdate, setProductUpdate, disabled = false }: Props) => {
  const intl = useIntl();

  const [value, setValue] = useState(product.price);

  useLayoutEffect(() => {
    setValue(product.price);
  }, [product.price]);

  const onUpdateValue = (newValue: Money) => {
    let newProductDto = productUpdate;
    if (newValue.amount !== product.price.amount) {
      newProductDto = {
        ...newProductDto,
        price: newValue,
      };
    } else {
      newProductDto = {
        ...newProductDto,
        price: undefined,
      };
    }
    setProductUpdate(newProductDto);
    setValue(newValue);
  };

  return (
    <MoneyForm value={value} onChange={onUpdateValue} disabled={disabled}
               label={intl.formatMessage({ id: 'menus.product.details.price' })} />
  );
};
