import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { List, ListItemText, styled } from '@mui/material';
import { differenceInCalendarDays, intlFormat } from 'date-fns';
import { AllowedCreateReportDate } from '../domain/AllowedCreateReportDate';

type Props = {
  createReportDate: Date,
  allowedCreateReportDate: AllowedCreateReportDate,
  onConfirm: () => void
  onClose: () => void,
};

const formatDate = (date: Date) => intlFormat(date, {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

const ListItem = styled(ListItemText)`
  display: list-item;
  margin: 1em;
`;

const ConfirmButton = styled(Button)`
  margin-top: 1em;
`;

export const ConfirmSkipReportDaysModal = ({
  createReportDate, allowedCreateReportDate, onConfirm, onClose,
}: Props) => {
  const intl = useIntl();
  const skippedDays = differenceInCalendarDays(createReportDate, allowedCreateReportDate.maxAllowedReportDate);

  return (
    <Modal
      title={intl.formatMessage({ id: 'reconciliation.new_report.confirm_skip_days.title' })}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      onClose={onClose}
      isOpened
    >
      <List sx={{
        listStyleType: 'disc', pl: 4, maxWidth: '50em',
      }}
      >
        {skippedDays > 1 && (
          <ListItem>
            {intl.formatMessage({ id: 'reconciliation.new_report.confirm_skip_days.skip_days_warning' },
              {
                lastClosedReportDate: formatDate(allowedCreateReportDate.lastClosedReport?.shift.dateOfService!),
                createDate: formatDate(createReportDate),
                skippedDays,
              })}
          </ListItem>
        )}

        {!allowedCreateReportDate.lastReportClosedTheBusinessDay && (
          <ListItem>
            {intl.formatMessage({ id: 'reconciliation.new_report.confirm_skip_days.last_report_did_not_close_business_day_warning' },
              {
                lastReportShift: allowedCreateReportDate.lastClosedReport?.shift.name,
              })}
          </ListItem>
        )}
      </List>

      <FormattedMessage id="reconciliation.new_report.confirm_skip_days.please_confirm" />
      <ConfirmButton
        type="button"
        variant="primary"
        onClick={() => {
          onClose();
          onConfirm();
        }}
        fullWidth
        size="large"
      >
        <FormattedMessage id="modal.confirm" />
      </ConfirmButton>
    </Modal>
  );
};
