import axios from 'axios';
import {
  ClawbackRepository,
  ListMerchantClawbacksQueryParams,
} from 'src/accounting/clawbacks/infrastructure/ClawbackRepository';
import { BusinessId } from 'src/business/domain/Business';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';

export const useMerchantClawbacks = (businessId: BusinessId, params: ListMerchantClawbacksQueryParams) => {
  const accountingRepository = new ClawbackRepository(axios);

  return useQuery({
    queryKey: [queryKeys.accounting.clawbacks.LIST_CLAWBACKS, { businessId, ...params }],
    queryFn: async () => accountingRepository.list(businessId, params),
  });
};
