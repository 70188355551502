import React from 'react';
import styled from 'styled-components';
import { OrderItem } from '../../types';
import OrderItemModifier from './OrderItemModifier';
import { colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  subItems: OrderItem[];
}

export const OrderItemModifiersListContainer = styled.ul`
  margin: 0;
  padding: 0 0 0 ${getSpacing(2)};
`;

export const OrderSubItemContainer = styled.li`
  display: block;
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 4px;
    height: 4px;
    background-color: ${colorPalette.grey600};
    border-radius: 2px;
    margin-right: ${getSpacing(1)};
  }
`;

const renderOrderItemModifier = (modifier: OrderItem, index: number) => (
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <OrderItemModifierWithSubItems key={index} modifier={modifier} />
);

const OrderItemModifiersList: React.FC<Props> = ({ subItems }) => (
  <OrderItemModifiersListContainer>{subItems.map(renderOrderItemModifier)}</OrderItemModifiersListContainer>
);

const OrderItemModifierWithSubItems: React.FC<{ modifier: OrderItem }> = ({ modifier }) => (
  <OrderSubItemContainer>
    <OrderItemModifier modifier={modifier} />
    {modifier.subItems && modifier.subItems.length > 0 && <OrderItemModifiersList subItems={modifier.subItems} />}
  </OrderSubItemContainer>
);

export default OrderItemModifiersList;
