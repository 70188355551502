/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const OpenAiSvg: React.FC<Props> = ({ size = 150, color = 'white', ...props }: Props) => (
  <svg
    id="openai"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 150"
    width={size}
    height={size}
    {...props}
  >
    <g id="Layer_3">
      <path id="Layer_3-2" style={{ fill: 'none' }} d="M0,0H150V150H0V0Z" />
    </g>
    <path
      style={{ fill: color }}
      d="M132.17,62.55c3.02-9.09,1.97-19.04-2.87-27.3-7.28-12.67-21.91-19.19-36.19-16.12-12.45-13.85-33.77-14.98-47.62-2.53-4.4,3.95-7.67,8.99-9.51,14.61-9.38,1.92-17.48,7.8-22.23,16.12-7.36,12.65-5.69,28.61,4.13,39.46-3.03,9.09-1.99,19.04,2.84,27.3,7.29,12.67,21.93,19.19,36.22,16.12,6.36,7.16,15.49,11.23,25.07,11.18,14.64,.01,27.62-9.44,32.09-23.38,9.38-1.93,17.48-7.8,22.23-16.12,7.27-12.63,5.59-28.5-4.16-39.33Zm-50.16,70.1c-5.85,0-11.51-2.04-15.99-5.79l.79-.45,26.57-15.34c1.35-.79,2.17-2.23,2.18-3.79v-37.46l11.23,6.5c.11,.06,.19,.16,.21,.29v31.04c-.03,13.79-11.2,24.96-24.99,24.99Zm-53.71-22.94c-2.93-5.06-3.98-10.99-2.97-16.76l.79,.47,26.59,15.34c1.34,.79,3,.79,4.34,0l32.49-18.73v12.97c0,.14-.07,.26-.18,.34l-26.91,15.52c-11.96,6.89-27.24,2.79-34.14-9.15Zm-7-57.87c2.95-5.09,7.61-8.98,13.15-10.97v31.57c-.02,1.55,.81,2.99,2.16,3.76l32.33,18.65-11.23,6.5c-.12,.07-.27,.07-.39,0l-26.86-15.49c-11.93-6.92-16.03-22.18-9.15-34.14v.13Zm92.28,21.44l-32.43-18.83,11.21-6.47c.12-.07,.27-.07,.39,0l26.86,15.52c11.95,6.9,16.05,22.18,9.15,34.13-2.9,5.03-7.47,8.9-12.92,10.93v-31.57c-.05-1.55-.91-2.96-2.26-3.71Zm11.18-16.81l-.79-.47-26.54-15.47c-1.35-.79-3.02-.79-4.37,0l-32.46,18.73v-12.97c-.01-.13,.05-.27,.16-.34l26.86-15.49c11.97-6.9,27.27-2.78,34.16,9.19,2.91,5.06,3.97,10.97,2.98,16.72v.11Zm-70.29,22.99l-11.23-6.47c-.11-.07-.19-.18-.21-.32v-30.96c.02-13.82,11.23-25,25.05-24.98,5.83,0,11.48,2.05,15.96,5.78l-.79,.45-26.57,15.34c-1.35,.79-2.17,2.23-2.18,3.79l-.03,37.38Zm6.1-13.15l14.47-8.34,14.49,8.34v16.68l-14.44,8.34-14.49-8.34-.03-16.68Z"
    />
  </svg>
);

export default OpenAiSvg;
