import { MenuBusinessDto } from './MenuBusinessDto';
import { MenuBusiness } from '../domain/MenuBusiness';
import { findLanguage } from 'src/app/i18n/AllSupportedLanguage';
import { ExposedMenuType } from '../domain/ExposedMenuType';
import { BusinessId } from 'src/business/domain/Business';

export class BusinessDtoConverter {
  static toBusiness(dto: MenuBusinessDto): MenuBusiness {
    return {
      id: dto.id as BusinessId,
      dynamicMenuAllowed: dto.dynamicMenuAllowed,
      defaultLanguage: findLanguage(dto.defaultLanguage),
      translatedLanguages: dto.translatedLanguages.map((code) => findLanguage(code)),
      exposedMenuType:
        ExposedMenuType.DYNAMIC_MENU === dto.exposedMenuType
          ? ExposedMenuType.DYNAMIC_MENU
          : ExposedMenuType.STATIC_MENU,
      externalMenuUrl: dto.externalMenuUrl,
      orderingAllowed: dto.orderingAllowed,
      orderingEnabled: dto.orderingEnabled,
      filterConfiguration: dto.filterConfiguration,
      openBillEnabled: dto.openBillEnabled,
    };
  }
}
