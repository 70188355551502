import React from 'react';
import { ConfigurationContainerContext } from './ConfigurationContainerContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PayoutHttpPort } from '../../../payout/infrastructure/PayoutHttpPort';
import { Revenues } from './Revenues';
import { Business } from 'src/business/domain/Business';

interface Props {
  business: Business;
}

export const RevenuesWithContext: React.FC<Props> = ({ business }: Props) => {
  const appConfiguration = ConfigurationLoader.load();
  return (
    <ConfigurationContainerContext.Provider
      value={{
        payoutPort: PayoutHttpPort(appConfiguration.accountingApiBaseUrl),
      }}
    >
      <Revenues business={business} />
    </ConfigurationContainerContext.Provider>
  );
};
