import { ReviewAndApplyChanges } from './ConfigurationReplicationState';
import { WhiteLabelCustomizationRepository } from 'src/domain/customization/WhiteLabelCustomizationRepository';
import { ApplyPostPaymentBannerChanges } from './ApplyPostPaymentBannerChanges';
import { ApplyCollapsibleMenuBannerChanges } from './ApplyCollapsibleMenuBannerChanges';
import { DocumentRepository } from 'src/domain/document/DocumentRepository';
import { ApplyCoverPictureChanges } from './ApplyCoverPictureChanges';
import { ApplyColorCustomizationChanges } from './ApplyColorCustomizationChanges';

export class ApplyConfigurationChanges {
  constructor(
    private documentRepositoryStorage: DocumentRepository,
    private whiteLabelCustomizationRepository: WhiteLabelCustomizationRepository,
  ) {}

  async applyConfigurationChanges(currentState: ReviewAndApplyChanges) {
    if (currentState.configurations.includes('customization post payment banner')) {
      await new ApplyPostPaymentBannerChanges(
        this.documentRepositoryStorage,
        this.whiteLabelCustomizationRepository,
      ).apply(currentState.sourceBusiness, currentState.targetBusinesses);
    }
    if (currentState.configurations.includes('customization collapsible menu banner')) {
      await new ApplyCollapsibleMenuBannerChanges(
        this.documentRepositoryStorage,
        this.whiteLabelCustomizationRepository,
      ).apply(currentState.sourceBusiness, currentState.targetBusinesses);
    }
    if (currentState.configurations.includes('cover picture')) {
      await new ApplyCoverPictureChanges(this.documentRepositoryStorage).apply(
        currentState.sourceBusiness,
        currentState.targetBusinesses,
      );
    }
    if (currentState.configurations.includes('customization colors')) {
      await new ApplyColorCustomizationChanges(this.whiteLabelCustomizationRepository).apply(
        currentState.sourceBusiness,
        currentState.targetBusinesses,
      );
    }
  }
}
