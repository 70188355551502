import * as React from 'react';
import { styled, Typography } from '@mui/material';
import { Money, renderMoney } from '@sundayapp/web-money';
import { IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { isProductPriceTimeOverride, PricingStrategy, ProductPriceTimeOverrideStrategy, TimeRange } from 'src/menu/dynamic-menu/domain/Product';
import { Days } from 'src/menu/dynamic-menu/domain/Days';
import { LocalisationKey } from 'src/lang/en';

type StrategyKey = {
  timeRange: TimeRange
  price: Money
};

type GroupedStrategies = Record<string, ProductPriceTimeOverrideStrategy>;

type LinearizedPriceTimeOverrideStrategy = StrategyKey & {
  days: Days[];
};

const renderStrategy = (intl: IntlShape, strategy: ProductPriceTimeOverrideStrategy) => {
  const translateDay = (day: Days) => intl.formatMessage({ id: `common.short.${day.toString().toLowerCase()}` as LocalisationKey });
  const days = strategy.configuration.days.map(day => translateDay(day)).join(', ');

  const translateTime = (time: string) => time.substring(0, 5);
  const hours = intl.formatList(strategy.configuration.timeRanges.map(time => `${translateTime(time.start)} - ${translateTime(time.end)}`));

  return `${days} ${hours}`;
};

const groupByTimeRanges = (pricingStrategies: ProductPriceTimeOverrideStrategy[]): GroupedStrategies => {
  const strategyKeyToString = ({ timeRange, price }: StrategyKey) =>
    `${timeRange.start}-${timeRange.end} ${price.amount}${price.currency}`;
  const mergeStrategiesWithSameKey = ({ timeRange, price, days }: LinearizedPriceTimeOverrideStrategy, strategy?: ProductPriceTimeOverrideStrategy): ProductPriceTimeOverrideStrategy => {
    return {
      _type: 'ProductPriceTimeOverride',
      price,
      configuration: {
        timeRanges: [timeRange],
        days: [...(strategy?.configuration.days || []), ...days],
      },
    };
  };

  return pricingStrategies
    .flatMap(strategy =>
      strategy.configuration.timeRanges.map(timeRange => ({ timeRange, price: strategy.price, days: strategy.configuration.days })),
    )
    .reduce((acc, linearizedStrategy) => {
      const key = strategyKeyToString(linearizedStrategy);
      const actualStrategy = acc[key];
      acc[key] = mergeStrategiesWithSameKey(linearizedStrategy, actualStrategy);
      return acc;
    }, {} as GroupedStrategies);
};

const ProductPriceTimeStrategies = styled('div')<{ padding: number }>`
  display: flex;
  flex-direction: column;
  justify-items: center;
  margin-top: 10px;
  margin-left: ${(p) => -p.padding}px;
  margin-right: ${(p) => -p.padding}px;
`;

const ProductPriceTimeStrategyItem = styled('div')<{ nested: boolean, padding: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${(p) => p.nested ? '44px' : '56px'};
  align-items: center;
  padding: ${(p) => `0 ${p.padding}px`};
  border-bottom: ${(p) => p.nested ? 'none' : '1px solid rgba(0, 0, 0, 0.1)'};

  &:last-child {
    border-bottom: none;
  };
`;

type Props = {
  pricingStrategies: PricingStrategy[];
  nested?: boolean;
};

const ProductPromotionsList = ({ pricingStrategies, nested = false }: Props) => {
  const intl = useIntl();

  const promotionStrategies = pricingStrategies.filter(isProductPriceTimeOverride);
  if (!promotionStrategies?.length) {
    return null;
  }
  const groupedStrategies = groupByTimeRanges(promotionStrategies);

  const globalContainerPadding = 32;
  return (
    <ProductPriceTimeStrategies padding={globalContainerPadding}>
      {Object.keys(groupedStrategies).map((strategyKey) => {
        const currentStrategy = groupedStrategies[strategyKey];
        return (
          <ProductPriceTimeStrategyItem key={strategyKey} nested={nested} padding={globalContainerPadding}>
            <Typography variant="body2" {...(nested ? { fontSize: 'smaller' } : {})}>{renderStrategy(intl, currentStrategy)}</Typography>
            <Typography variant="body2">{renderMoney(currentStrategy.price, intl.locale)}</Typography>
          </ProductPriceTimeStrategyItem>
        );
      })}
    </ProductPriceTimeStrategies>
  );
};

export { ProductPromotionsList };
