/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function MerchantCreditIcon(props: PaymentMethodIconProps) {
  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="39" height="23" rx="2.5" fill="white" />
      <rect x="0.5" y="0.5" width="39" height="23" rx="2.5" stroke="#EDEDF2" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.3014 5.33325H25.6989C26.1382 5.33324 26.5172 5.33322 26.8294 5.35873C27.1588 5.38565 27.4865 5.44508 27.8018 5.60574C28.2722 5.84542 28.6547 6.22787 28.8943 6.69828C29.055 7.01359 29.1144 7.34128 29.1414 7.67073C29.1669 7.98287 29.1668 8.36183 29.1668 8.80112V11.9999C29.1668 12.4602 28.7937 12.8333 28.3335 12.8333C27.8733 12.8333 27.5002 12.4602 27.5002 11.9999V11.1666H12.5002V15.1666C12.5002 15.647 12.5008 15.9573 12.5201 16.1934C12.5386 16.4198 12.57 16.5037 12.591 16.5449C12.6709 16.7017 12.7984 16.8292 12.9552 16.9091C12.9964 16.9301 13.0803 16.9615 13.3067 16.98C13.5428 16.9993 13.853 16.9999 14.3335 16.9999H20.0002C20.4604 16.9999 20.8335 17.373 20.8335 17.8333C20.8335 18.2935 20.4604 18.6666 20.0002 18.6666H14.3014C13.8621 18.6666 13.4831 18.6666 13.171 18.6411C12.8415 18.6142 12.5138 18.5548 12.1985 18.3941C11.7281 18.1544 11.3457 17.772 11.106 17.3016C10.9453 16.9862 10.8859 16.6586 10.859 16.3291C10.8335 16.017 10.8335 15.638 10.8335 15.1987V8.80113C10.8335 8.36184 10.8335 7.98287 10.859 7.67073C10.8859 7.34128 10.9453 7.01359 11.106 6.69828C11.3457 6.22787 11.7281 5.84542 12.1985 5.60574C12.5138 5.44508 12.8415 5.38565 13.171 5.35873C13.4831 5.33322 13.8621 5.33324 14.3014 5.33325ZM12.5002 9.49992H27.5002V8.83325C27.5002 8.35279 27.4995 8.04257 27.4802 7.80645C27.4617 7.58007 27.4303 7.49613 27.4093 7.45493C27.3294 7.29813 27.202 7.17064 27.0452 7.09075C27.004 7.06976 26.92 7.03836 26.6936 7.01986C26.4575 7.00057 26.1473 6.99992 25.6668 6.99992H14.3335C13.853 6.99992 13.5428 7.00057 13.3067 7.01986C13.0803 7.03836 12.9964 7.06975 12.9552 7.09075C12.7984 7.17064 12.6709 7.29813 12.591 7.45493C12.57 7.49613 12.5386 7.58006 12.5201 7.80645C12.5008 8.04256 12.5002 8.35279 12.5002 8.83325V9.49992Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M22.7327 17.5199C22.4146 17.1872 22.4264 16.6597 22.759 16.3417L25.3142 13.8981C25.6468 13.58 26.1743 13.5918 26.4924 13.9244C26.8105 14.257 26.7987 14.7845 26.4661 15.1026L25.3649 16.1557L28.3523 16.2224C28.8124 16.2327 29.1771 16.6141 29.1668 17.0742C29.1566 17.5343 28.7752 17.899 28.3151 17.8887L25.3277 17.822L26.3808 18.9232C26.6989 19.2558 26.6871 19.7833 26.3545 20.1014C26.0218 20.4195 25.4943 20.4077 25.1762 20.0751L22.7327 17.5199Z" fill="black" />
    </svg>
  );
}
