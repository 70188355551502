import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { MetabaseDashboard } from './domain/MetabaseDashboard';
import { useCurrentBusinessOrThrow } from '../business/hooks/useCurrentBusinessOrThrow';
import { useEffect } from 'react';
import { ampli } from '../ampli';
import { shouldMockDataForBusiness } from '../mocks/shouldMockDataForBusiness';


export const DataAnalyticsRepeatKpis = () => {
  const business = useCurrentBusinessOrThrow();

  useEffect(() => {
    ampli.dataAnalyticsRepeatKpis({
      businessId: business.id,
      businessName: business.name,
    });
  }, []);

  if (shouldMockDataForBusiness(business.id)) {
    return <img width="100%" height="auto" src="/img/mocks/analytics/KYC-Repeat-Kpis-Mocked.png"
                alt="overview mocked" />;
  }

  return (
    <DataAnalyticsIframeComponent dashboard={MetabaseDashboard.REPEAT_KPIS_ENGLISH} />
  );
};
