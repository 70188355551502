import { TextField } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const RoutingNumber = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  return <TextField
    required
    error={!!errors.bankAccount?.bankCode}
    type={'text'}
    InputLabelProps={{ shrink: true }}
    {...register('bankAccount.bankCode', {
      required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.routingNumber.required' }),
      pattern: { value: /^[0-9]{9}$/, message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.routingNumber.invalid' }) },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.routingNumber' })}
    helperText={errors.bankAccount?.bankCode?.message || 'ex: 123456789'}
  />;
};
