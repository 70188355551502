import styled from 'styled-components';
import { Tooltip } from '@mui/material';

const NumberOfReviewsSkeletonBox = styled.div`
  height: 12px;
  width: 32px;
  background-color: #ededf2;
  border-radius: 4px;
`;

export const NumberOfReviewsSkeleton = () => (
  <Tooltip placement="top" arrow title="">
    <NumberOfReviewsSkeletonBox />
  </Tooltip>
);
