import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { AuthenticationPage, AuthenticationPart, IllustrationPart } from 'src/auth/component/Authentication.styles';
import { useLogout } from 'src/auth/hooks/useLogout';
import { Button, Stack, Typography } from '@mui/material';

const NoVenue = () => {
  const logout = useLogout();

  return (
    <AuthenticationPage>
      <IllustrationPart />
      <AuthenticationPart>
        <Stack spacing={2}>
          <div>
            <Typography fontSize={'xx-large'}><FormattedMessage id="no_venue.title" /></Typography>
            <Typography fontSize="xlarge">
            <FormattedMessage
              id="no_venue.message"
              defaultMessage="Please ask to the venue owner"
            />
            </Typography>
          </div>
          <Button onClick={logout} size="large" variant="contained">
            <FormattedMessage id="menu.logout" />
          </Button>
        </Stack>
      </AuthenticationPart>
    </AuthenticationPage>
  );
};

export default NoVenue;
