import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { ProductRepositoryHttp } from '../../repositories/ProductRepositoryHttp';
import { ProductSummary } from '../../domain/ProductSummary';
import { BusinessId } from 'src/business/domain/Business';
import { MenuAssetsSource } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

export const QUERYKEY_MENU_PRODUCTS = 'menu_products';

const buildQueryKey = (businessId: BusinessId, filterOnlyInsideMenu?: boolean) =>
  [QUERYKEY_MENU_PRODUCTS, businessId, filterOnlyInsideMenu] as const;

export const getAllProductByBusinessIdQuery = async ({
  queryKey: [, businessId, filterOnlyInsideMenu],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<ProductSummary[]> => {
  const configuration = ConfigurationLoader.load();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);

  return productRepositoryHttp.findAllByBusinessId(businessId, filterOnlyInsideMenu);
};

export const useGetAllProductsByBusinessId = (businessId: BusinessId, filterOnlyInsideMenu?: boolean) =>
  useQuery({ queryKey: buildQueryKey(businessId, filterOnlyInsideMenu), queryFn: getAllProductByBusinessIdQuery });

const findBySourceQueryKey = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource) =>
  [QUERYKEY_MENU_PRODUCTS, businessId, menuAssetsSource] as const;

export const findProductsBySourceQuery = async ({
  queryKey: [, businessId, menuAssetsSource],
}: QueryFunctionContext<ReturnType<typeof findBySourceQueryKey>>): Promise<ProductSummary[]> => {
  const configuration = ConfigurationLoader.load();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);

  return productRepositoryHttp.findBySource(businessId, menuAssetsSource);
};

export const useFindProductsBySource = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource) =>
  useQuery({ queryKey: findBySourceQueryKey(businessId, menuAssetsSource), queryFn: findProductsBySourceQuery });


export const usePollProductsBySource = (businessId: BusinessId, menuAssetsSource: MenuAssetsSource, enabled: boolean) =>
  useQuery({
    queryKey: findBySourceQueryKey(businessId, menuAssetsSource),
    queryFn: findProductsBySourceQuery,
    refetchInterval: 5000,
    enabled,
  });
