import React, { useCallback } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import {
  DayLabel,
  IconButtonWrapper,
  OpeningHourLabelLine,
  OpeningHourLine,
  OpeningHourLineHeader,
  OpeningHourPeriods,
  OpeningHourStatusContainer,
  StyledCheckIcon,
} from './OpeningHours.style';
import { get24HoursTimePeriod, getDefaultTimePeriod, isOpen24Hours } from './services';
import TimePeriodEditor, { ChangeTimePeriodType } from './TimePeriodEditor';
import { DayOfWeek, TimePeriod } from '../../domain/TimePeriod';
import { Time } from '../../domain/Time';
import { renderHours } from '../../utils/renderHours';
import { renderMinutes } from '../../utils/renderMinutes';
import IconButton from '../IconButton';
import { renderDay } from '../../utils/renderDay';
import { PlusIcon } from '../Icons';

interface Props {
  day: DayOfWeek;
  openingHours: TimePeriod[];
  updateOpeningHoursForDay: (day: DayOfWeek, openingHoursForDay: TimePeriod[]) => void;
}

const getDayOpeningStatusLabel = (isOpen: boolean, open24Hours: boolean) => {
  if (!isOpen) {
    return <FormattedMessage id="venue.closed" defaultMessage="closed" />;
  } if (open24Hours) {
    return <FormattedMessage id="venue.open24Hours" defaultMessage="open 24 hrs" />;
  }
  return <FormattedMessage id="venue.open" defaultMessage="open" />;
};

const OpeningHourForDay: React.FC<Props> = ({ day, openingHours, updateOpeningHoursForDay }) => {
  const timePeriodForDay = openingHours.filter((period: TimePeriod) => period.weekDay === day);

  const updateTimePeriodForDay = useCallback(
    (updateFunction: (state: TimePeriod[]) => TimePeriod[]) => {
      const newState = updateFunction(timePeriodForDay);
      updateOpeningHoursForDay(day, newState);
      return newState;
    },
    [day, updateOpeningHoursForDay],
  );

  const onChangeTimePeriod = useCallback(
    (index: number, type: ChangeTimePeriodType, value: Time) => {
      updateTimePeriodForDay((currentTimePeriod) => [
        ...currentTimePeriod.slice(0, index),
        {
          ...currentTimePeriod[index],
          [type === ChangeTimePeriodType.START_TIME ? 'startTime' : 'endTime']: `${renderHours(
            value.hours,
          )}:${renderMinutes(value.minutes)}`,
        },
        ...currentTimePeriod.slice(index + 1),
      ]);
    },
    [updateTimePeriodForDay],
  );
  const removeOpeningHour = useCallback(
    (index: number) => {
      updateTimePeriodForDay((currentTimePeriod) => {
        const updatedTimePeriods = [...currentTimePeriod.slice(0, index), ...currentTimePeriod.slice(index + 1)];

        if (updatedTimePeriods.length === 0) {
          updatedTimePeriods.push({ weekDay: day, startTime: '00:00', endTime: '23:59' });
        }

        return updatedTimePeriods;
      });
    },
    [day, updateTimePeriodForDay],
  );
  const toggleOpenThisDay = useCallback(() => {
    updateTimePeriodForDay((currentTimePeriod) => {
      if (currentTimePeriod.length > 0) return [];
      return [get24HoursTimePeriod(day)];
    });
  }, [updateTimePeriodForDay, day]);

  const addOpeningHours = useCallback(() => {
    updateTimePeriodForDay((currentTimePeriod) => {
      if (isOpen24Hours(currentTimePeriod)) {
        return [getDefaultTimePeriod(day)];
      }
      return [...currentTimePeriod, getDefaultTimePeriod(day)];
    });
  }, [updateTimePeriodForDay, day]);

  const isOpen = timePeriodForDay.length > 0;
  const open24Hours = isOpen && isOpen24Hours(timePeriodForDay);
  const isLabelOpen = isOpen || openingHours.length === 0;

  const renderTimePeriod = useCallback(
    (period: TimePeriod, index: number) => (
      <TimePeriodEditor
        key={`${period.weekDay}-${index}`}
        period={period}
        index={index}
        onChangeTimePeriod={onChangeTimePeriod}
        removeOpeningHour={removeOpeningHour}
      />
    ),
    [onChangeTimePeriod, removeOpeningHour],
  );

  return (
    <OpeningHourLine>
      <OpeningHourLineHeader>
        <IconButtonWrapper>
          {isOpen ? (
            <IconButton Icon={StyledCheckIcon} active onClick={toggleOpenThisDay} />
          ) : (
            <IconButton onClick={toggleOpenThisDay} />
          )}
        </IconButtonWrapper>
        <DayLabel>{renderDay(day)}</DayLabel>
        {isOpen ? (
          <OpeningHourLabelLine>
            <OpeningHourStatusContainer>
              {getDayOpeningStatusLabel(isLabelOpen, open24Hours)}
            </OpeningHourStatusContainer>
            <IconButton Icon={PlusIcon} onClick={addOpeningHours} />
          </OpeningHourLabelLine>
        ) : (
          <OpeningHourStatusContainer>{getDayOpeningStatusLabel(isLabelOpen, open24Hours)}</OpeningHourStatusContainer>
        )}
      </OpeningHourLineHeader>
      {isOpen && !open24Hours && <OpeningHourPeriods>{timePeriodForDay.map(renderTimePeriod)}</OpeningHourPeriods>}
    </OpeningHourLine>
  );
};

export default OpeningHourForDay;
