import { Engineering, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box, css, Stack, styled } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { BusinessId } from 'src/business/domain/Business';
import { isTaskOngoing, isTaskTerminated, Task, TaskStatus, TaskType } from 'src/menu/tasks/domain/Task';
import { usePollAllTasks } from 'src/menu/tasks/queries/pollAllTasks';

const ModalContainer = styled(Box)`
    padding: 0;
    width: 300px;
    max-height: 400px;
    box-sizing: border-box;
    background-color: white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border: 1px solid lightgrey;
    overflow: hidden;
`;

const ModalHeader = styled('div')`
    background-color: black;
    padding: 0.8em;
    color: white;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
        margin: 0;
        display: inline;
    }

    div {
        display: flex;
        gap: 1em;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
    }
`;

type OpeningProps = {
  opened: boolean;
};

const ModalContent = styled(Stack)<OpeningProps>`
    max-height: 350px;
    transition: max-height 1s;
    overflow: auto;

    ${({ opened }) =>
    !opened &&
            css`
                max-height: 0;
            `};
`;

const TaskLine = styled(Box)`
    padding: 0.8em;
    border-bottom: 1px solid lightgrey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-shrink: 0;

    &:last-child {
        border-bottom: none;
    }

    span {
        color: grey;
    }
`;

const TaskTitle = styled(Box)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h3 {
        margin: 0;
        color: dimgrey;
    }
`;

const TaskName = ({ task: { type, status, startedAt, finishedAt } } : { task: Task }) =>
  <h3 title={isTaskTerminated(status) ? dayjs(finishedAt).from(dayjs(startedAt), true) : ''}>
    <FormattedMessage id={`tasks.${(type.toLowerCase() as Lowercase<TaskType>)}`}/>
  </h3>;

const TaskStartedAt = ({ startedAt }: { startedAt: Date }) => {
  const formatedDate = dayjs(startedAt).format('L LT');
  return <span title={formatedDate}>{dayjs(startedAt).fromNow()}</span>;
};

function statusIcon(status: TaskStatus) {
  switch (status) {
    case TaskStatus.STARTED:
    case TaskStatus.TERMINATING:
      return '⏳';
    case TaskStatus.SUCCEEDED:
      return '✅';
    case TaskStatus.FAILED:
      return '❌';
  }
}

const TaskStatusIcon = ({ status }: { status: TaskStatus }) => {
  const icon = statusIcon(status);
  return <span>{icon}</span>;
};

type Props = {
  businessId: BusinessId;
};

export const TasksModal = ({ businessId }: Props) => {
  const [opened, openModal] = React.useState(false);
  const { data: tasks, isLoading } = usePollAllTasks(businessId, true);

  if (isLoading || !tasks || tasks?.length === 0) {
    return null;
  }

  const sortedTasks = tasks.sort((a, b) => b.startedAt.getTime() - a.startedAt.getTime());
  const ongoingTasks = sortedTasks.map(t => t.status).filter(isTaskOngoing);

  return (
    <ModalContainer>
      <ModalHeader onClick={() => openModal(!opened)}>
        <div>
          <Engineering/>
          <h3><FormattedMessage id="tasks.panel.title" defaultMessage="Activities" /> {ongoingTasks.length > 0 ? `(${ongoingTasks.length})` : ''}</h3>
        </div>
        {opened ? (<KeyboardArrowDown/>) : (<KeyboardArrowUp/>)}
      </ModalHeader>
      <ModalContent opened={opened} direction="column" alignItems="stretch" justifyContent="flex-start">
        {sortedTasks.map((task) => (
          <TaskLine key={task.id}>
            <TaskTitle>
              <TaskName task={task} />
              <TaskStatusIcon status={task.status}/>
            </TaskTitle>
            <TaskStartedAt startedAt={task.startedAt}/>
          </TaskLine>
        ))}
      </ModalContent>
    </ModalContainer>
  );
};
