import React from 'react';
import { SvgIcon } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import { SvgIconPropsSizeOverrides } from '@mui/material/SvgIcon/SvgIcon';

export const FastPaymentIcon = ({ fontSize }: {
  fontSize?: OverridableStringUnion<
  'inherit' | 'large' | 'medium' | 'small',
  SvgIconPropsSizeOverrides
  >
}) => (
  <SvgIcon fontSize={fontSize}>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="zap-fast">
        {/* eslint-disable-next-line max-len */}
        <path id="Icon (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M18.1884 2.56997C18.6155 2.73879 18.8727 3.17687 18.812 3.63213L17.963 9.99996L21.8007 9.99996C22.0215 9.99991 22.2503 9.99986 22.4393 10.0171C22.6252 10.0341 22.9634 10.0792 23.2657 10.3125C23.6183 10.5846 23.8317 10.9993 23.8482 11.4444C23.8623 11.826 23.7024 12.1274 23.6082 12.2886C23.5124 12.4524 23.3794 12.6385 23.251 12.8181L16.6345 22.0812C16.3676 22.4549 15.8804 22.5988 15.4532 22.43C15.0261 22.2611 14.7689 21.8231 14.8296 21.3678L15.6786 15H11.8754C11.8639 15 11.8524 15 11.8409 15C11.6201 15 11.3913 15.0001 11.2023 14.9828C11.0164 14.9658 10.6782 14.9207 10.3759 14.6874C10.0233 14.4153 9.80993 14.0006 9.79343 13.5555C9.77927 13.1739 9.9392 12.8725 10.0334 12.7114C10.1292 12.5475 10.2622 12.3614 10.3906 12.1818C10.3973 12.1724 10.404 12.1631 10.4106 12.1537L17.0071 2.91872C17.274 2.54498 17.7612 2.40114 18.1884 2.56997ZM12.264 13H16.8208C17.1091 13 17.3833 13.1244 17.5732 13.3412C17.7631 13.5581 17.8501 13.8464 17.812 14.1321L17.3432 17.6481L21.3776 12H16.8208C16.5325 12 16.2583 11.8756 16.0684 11.6587C15.8785 11.4418 15.7915 11.1535 15.8296 10.8678L16.2984 7.35183L12.264 13ZM3.8208 6.99996C3.8208 6.44768 4.26852 5.99996 4.8208 5.99996H9.8208C10.3731 5.99996 10.8208 6.44768 10.8208 6.99996C10.8208 7.55225 10.3731 7.99996 9.8208 7.99996H4.8208C4.26852 7.99996 3.8208 7.55225 3.8208 6.99996ZM1.8208 12.5C1.8208 11.9477 2.26852 11.5 2.8208 11.5H7.3208C7.87309 11.5 8.3208 11.9477 8.3208 12.5C8.3208 13.0522 7.87309 13.5 7.3208 13.5H2.8208C2.26852 13.5 1.8208 13.0522 1.8208 12.5ZM3.3208 18C3.3208 17.4477 3.76852 17 4.3208 17H9.8208C10.3731 17 10.8208 17.4477 10.8208 18C10.8208 18.5522 10.3731 19 9.8208 19H4.3208C3.76852 19 3.3208 18.5522 3.3208 18Z"
              fill="black" />
      </g>
    </svg>

  </SvgIcon>
);
