import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { VenueDetails } from '../../../../types';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { useUpdateVenueDetails } from '../../../../hook';
import Input from '../../../../../common/components/Input';
import { colorPalette, getSpacing } from 'src/ordering/stylesheet';
import { Container, SettingPanelContent, Title } from 'src/ordering/common/components/Settings/SettingPanel';

type WebsiteUrlVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
`;

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  width: 100%;
  margin-top: ${getSpacing(1)};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-bottom: ${getSpacing(1)};
`;

const ErrorMessage = styled.div`
  display: flex;
  color: ${colorPalette.red};
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
`;

export const WebsiteUrlVenueSetting = ({ venue, setVenue }: WebsiteUrlVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [websiteUrl, setWebsiteUrl] = useState<string>(venue.websiteUrl ?? '');

  const trimmedWebsiteUrl = websiteUrl.trim();

  const websiteUrlChanged = trimmedWebsiteUrl !== venue.websiteUrl;

  const hasWebsiteUrlError = trimmedWebsiteUrl.length > 0
    && !trimmedWebsiteUrl.startsWith('http://')
    && !trimmedWebsiteUrl.startsWith('https://');

  const updateVenueSettings = async () => {
    const updatedWebsiteUrl = websiteUrl.trim();

    makeUpdating(true);
    await updateVenueDetails({
      websiteUrl: updatedWebsiteUrl,
    });
    setVenue({
      ...venue,
      websiteUrl: updatedWebsiteUrl,
    });

    setWebsiteUrl(updatedWebsiteUrl);

    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <Container>
      <Title>
        <FormattedMessage id="venue.settings.app-venue.website.title" defaultMessage="website URL" />
      </Title>
      <Description>
        <FormattedMessage
          id="venue.settings.app-venue.website.description"
          defaultMessage="website to display in the home page"
        />
      </Description>
      <SettingPanelContent>
        <Input value={websiteUrl} onValueChange={setWebsiteUrl} disabled={isUpdating} placeholder="https://..." />
        {hasWebsiteUrlError && (
          <ErrorMessage>
            <FormattedMessage
              id="venue.settings.app-venue.website.error"
              defaultMessage="The website must start with 'https://'"
            />
          </ErrorMessage>
        )}
        {websiteUrlChanged && (
          <Line>
            <SaveButton
              variant="primary"
              size="xSmall"
              onClick={updateVenueSettings}
              disabled={isUpdating || hasWebsiteUrlError}
            >
              <FormattedMessage id="venue.settings.save" defaultMessage="Save" />
            </SaveButton>
          </Line>
        )}
      </SettingPanelContent>
    </Container>
  );
};
