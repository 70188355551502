// eslint-disable-next-line @typescript-eslint/no-redeclare
import { ArrowIcon, colorUsage, IconWrapper, spaceUsages, Text, Title } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import UploadMenuPdfButton from './components/UploadMenuPdfButton';
import MenuDetailInputPanel from './components/MenuDetailInputPanel';
import EditDescriptionModal from './components/EditDescriptionModal';
import MenuSchedulePanel from './components/MenuSchedulePanel';
import MenuPdfImageHandler from './components/MenuPdfImageHandler';
import MenuDeletionButton from './components/MenuDeletionButton';
import EditNameModal from './components/EditNameModal';
import { businessStaticMenusPath } from 'src/app/navigation/pathHelpers';
import { useListStaticMenus } from '../infrastructure/queries/useListStaticMenus';
import { useUpdateStaticMenu } from '../infrastructure/mutations/useUpdateStaticMenu';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { Switch } from '@mui/material';

const MenuDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: ${spaceUsages.largeMedium};
  justify-content: center;
`;

const MenuDetailsLeft = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 359px;
  max-width: 359px;
  gap: ${spaceUsages.mediumSmall};
`;

const MenuDetailsRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const MenuTitleContainer = styled.div`
  position: relative;
  padding-left: ${spaceUsages.mediumLarge};
  padding-right: ${spaceUsages.mediumLarge};
  padding-bottom: ${spaceUsages.largeMedium};
`;

const MenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  padding-left: ${spaceUsages.mediumLarge};
  padding-right: ${spaceUsages.mediumLarge};
`;
const MenuLastEdition = styled.div`
  padding-left: ${spaceUsages.mediumLarge};
  padding-right: ${spaceUsages.mediumLarge};
`;

const MenuToggleWrapper = styled.div`
  margin-top: ${spaceUsages.mediumSmall};
`;
const MenuGoBackWrapper = styled.div`
  left: -16px;
  position: absolute;
  margin-top: ${spaceUsages.medium};
`;

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
} as Intl.DateTimeFormatOptions;

export const StaticMenuDetailsPage = () => {
  const intl = useIntl();
  const { menuId } = useParams();
  const businessId = useBusinessIdOrThrow();
  const navigate = useNavigate();

  const [editNameDialogOpened, setEditNameDialogOpened] = useState<boolean>(false);
  const [editDescriptionDialogOpened, setEditDescriptionDialogOpened] = useState<boolean>(false);

  const { data: menus } = useListStaticMenus(businessId);
  const menu = menus?.find((m) => m.id === menuId);
  const { updateStaticMenu } = useUpdateStaticMenu();

  const onMenuVisibilityChanged = async () => {
    if (menu) {
      const menuUpdated = {
        ...menu,
        visibility: !menu.visibility,
      };
      await updateStaticMenu(businessId, menuUpdated);
    }
  };

  const onGoToMenu = async () => navigate(businessStaticMenusPath(businessId));

  return (
    <>
      <MenuDetailsContainer>
        {menu && (
          <>
            <MenuDetailsLeft>
              <MenuTitleContainer>
                <MenuTitle>
                  <MenuGoBackWrapper>
                    <IconWrapper size="small" cursor="pointer" color={colorUsage.secondaryText} onClick={onGoToMenu}>
                      <ArrowIcon direction="left" />
                    </IconWrapper>
                  </MenuGoBackWrapper>
                  <Title keepCase size="title2">
                    {menu.description}
                  </Title>
                  <MenuToggleWrapper>
                    <Switch
                      onChange={onMenuVisibilityChanged}
                      checked={menu.visibility}
                      disabled={!menu.fileName}
                    />
                  </MenuToggleWrapper>
                </MenuTitle>
                <MenuLastEdition>
                  {menu.lastEdition && (
                    <Text size="small" color={colorUsage.secondaryText}>
                      {`${intl.formatMessage({ id: 'menus.edition.last_edition' })} ${new Date(
                        menu?.lastEdition.getTime(),
                      ).toLocaleDateString(intl.locale, options)}`}
                    </Text>
                  )}
                </MenuLastEdition>
              </MenuTitleContainer>

              <MenuDetailInputPanel
                label={intl.formatMessage({ id: 'menus.edition.name' })}
                inputValue={menu.description}
                onAction={() => setEditNameDialogOpened(true)}
              />
              <MenuDetailInputPanel
                label={
                  menu.descriptionDetails
                    ? intl.formatMessage({ id: 'menus.edition.description' })
                    : intl.formatMessage({ id: 'menus.edition.add_description' })
                }
                inputValue={menu.descriptionDetails}
                onAction={() => setEditDescriptionDialogOpened(true)}
              />
              <MenuSchedulePanel menu={menu} />
              {!menu.fileName && (
                <>
                  <UploadMenuPdfButton variant="primary" text={intl.formatMessage({ id: 'menus.upload_pdf.button' })} />
                  <MenuDeletionButton menu={menu} />
                </>
              )}
            </MenuDetailsLeft>
            {menu.fileName && (
              <MenuDetailsRight>
                <MenuPdfImageHandler menu={menu} />
                <MenuDeletionButton menu={menu} />
              </MenuDetailsRight>
            )}
            <EditNameModal
              isOpened={editNameDialogOpened}
              onClose={() => setEditNameDialogOpened(false)}
              selectedMenu={menu}
            />
            <EditDescriptionModal
              createMode={!menu.descriptionDetails}
              isOpened={editDescriptionDialogOpened}
              onClose={() => setEditDescriptionDialogOpened(false)}
              selectedMenu={menu}
            />
          </>
        )}
      </MenuDetailsContainer>
    </>
  );
};
