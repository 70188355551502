import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { centsToMoney, CurrencyCode, money, Money, moneyToUnit, useRenderMoney } from '@sundayapp/web-money';
import styled from 'styled-components';
import { ServiceChargeType, VenueDetails } from '../../../../types';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { SettingExpandablePanel } from 'src/ordering/common/components/Settings/SettingExpandablePanel';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import { useUpdateVenueDetails } from '../../../../hook';
import { ServiceFeePanel } from './ServiceFeePanel';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';
import { Switch } from '@mui/material';

type ServiceFeeRateVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const computeInitialServiceChargeType = (venue: VenueDetails) => {
  if (venue.serviceChargeType === ServiceChargeType.NONE) {
    return ServiceChargeType.NONE;
  }

  if (venue.serviceChargeType === ServiceChargeType.AMOUNT) {
    return ServiceChargeType.AMOUNT;
  }

  return ServiceChargeType.RATE;
};

const computeInitialPreviousServiceChargeType = (venue: VenueDetails) => {
  if (venue.serviceChargeType === ServiceChargeType.AMOUNT) {
    return ServiceChargeType.AMOUNT;
  }

  return ServiceChargeType.RATE;
};

type SettingValues = {
  serviceChargeType: ServiceChargeType;
  serviceChargeMandatory: boolean;
  serviceChargeRate: number;
  serviceChargeAmount: Money;
};

export const ServiceFeeRateVenueSetting = ({ venue, setVenue }: ServiceFeeRateVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [isUpdating, makeUpdate] = useState<boolean>(false);
  const [expanded, setExpansion] = useState<boolean>(false);
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const renderMoney = useRenderMoney();

  const [previousServiceChargeType, setPreviousServiceChargeType] = useState<ServiceChargeType>(
    computeInitialPreviousServiceChargeType(venue),
  );

  const currentSettings: SettingValues = {
    serviceChargeType: computeInitialServiceChargeType(venue),
    serviceChargeMandatory: venue.serviceChargeMandatory,
    serviceChargeRate: venue.serviceChargeRate,
    serviceChargeAmount: venue.serviceChargeAmount,
  };

  const venueUpdated = async (values: SettingValues) => {
    makeUpdate(true);
    await updateVenueDetails({
      ...values,
    });
    setVenue({
      ...venue,
      ...values,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdate(false);
  };

  const toggleServiceCharge = async (value: boolean) => {
    setPreviousServiceChargeType(currentSettings.serviceChargeType);

    if (value) {
      await venueUpdated({ ...currentSettings, serviceChargeType: previousServiceChargeType });
      return;
    }

    await venueUpdated({ ...currentSettings, serviceChargeType: ServiceChargeType.NONE });
  };

  const toggleMandatoryCharge = async (value: boolean) => {
    await venueUpdated({ ...currentSettings, serviceChargeMandatory: value });
  };

  const updateServiceChargeType = async (value: ServiceChargeType) => {
    await venueUpdated({ ...currentSettings, serviceChargeType: value });
  };

  const updateServiceChargeRate = async (value: number) => {
    await venueUpdated({ ...currentSettings, serviceChargeRate: value * 10000 });
  };

  const updateServiceChargeAmount = async (value: number) => {
    const moneyValue = money(centsToMoney(100 * value), venue.currency as unknown as CurrencyCode);

    await venueUpdated({ ...currentSettings, serviceChargeAmount: moneyValue });
  };

  const hasServiceChargeActivated = currentSettings.serviceChargeType !== ServiceChargeType.NONE;

  if (venue.isFoodCourt) {
    return <></>;
  }

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.serviceFee',
        defaultMessage: 'service fee',
        description: 'Settings to configure the service fee charge',
      })}
      description={intl.formatMessage({
        id: 'venue.serviceFeeDescription',
        defaultMessage: 'when active add to the customer the service fee',
        description: 'Description of the service fee charge settings',
      })}
      toggleValue={hasServiceChargeActivated}
      toggleOnChange={toggleServiceCharge}
      disabled={isUpdating}
    >
      <SettingExpandablePanel visible={hasServiceChargeActivated} expanded={expanded} expandChange={setExpansion}>
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.serviceFeeMakeMandatory',
            defaultMessage: 'make mandatory',
          })}
          description={intl.formatMessage({
            id: 'venue.serviceFeeHelper',
            defaultMessage: 'when this is active, the service fee is mandatory',
          })}
        >
          <Switch
            checked={currentSettings.serviceChargeMandatory}
            onChange={(_, val) => toggleMandatoryCharge(val)}
            disabled={isUpdating}
          />
        </SettingLine>

        <PanelContainer>
          <ServiceFeePanel
            type="rate"
            title={intl.formatMessage({ id: 'venue.serviceFeeRate', defaultMessage: 'service fee rate' })}
            placeholder={intl.formatMessage({
              id: 'venue.serviceFeeRatePlaceholder',
              defaultMessage: 'enter a service fee percent',
            })}
            selected={currentSettings.serviceChargeType === ServiceChargeType.RATE}
            onSelection={() => {
              updateServiceChargeType(ServiceChargeType.RATE);
            }}
            value={currentSettings.serviceChargeRate / 10000}
            convertedValue={`${currentSettings.serviceChargeRate / 10000} %`}
            commitChange={updateServiceChargeRate}
          />

          <ServiceFeePanel
            type="amount"
            title={intl.formatMessage({ id: 'venue.serviceFeeAmount', defaultMessage: 'service fee amount' })}
            placeholder={intl.formatMessage({
              id: 'venue.serviceFeeAmountPlaceholder',
              defaultMessage: 'enter a service fee amount',
            })}
            selected={currentSettings.serviceChargeType === ServiceChargeType.AMOUNT}
            onSelection={() => {
              updateServiceChargeType(ServiceChargeType.AMOUNT);
            }}
            value={moneyToUnit(currentSettings.serviceChargeAmount)}
            convertedValue={renderMoney(currentSettings.serviceChargeAmount)}
            commitChange={updateServiceChargeAmount}
          />
        </PanelContainer>
      </SettingExpandablePanel>
    </SettingPanel>
  );
};
