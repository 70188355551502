import axios from 'axios';
import { PayoutPort } from '../domain/PayoutPort';
import { PayoutZ } from '../domain/Payout';
import { BusinessId } from 'src/business/domain/Business';

export const PayoutHttpPort = (payoutUrl: string): PayoutPort => ({
  getPayouts(businessId: BusinessId, startDate: Date, endDate: Date) {
    return axios
      .get(`${payoutUrl}/accounting/venues/${businessId}/payouts`, {
        headers: {
          accept: 'application/json',
        },
        params: {
          from: startDate,
          to: endDate,
        },
      })
      .then((response) => {
        const safeParse = PayoutZ.array().safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  },
  getPayoutsExport(businessId: BusinessId, startDate: Date, endDate: Date) {
    return axios
      .get<Blob>(`${payoutUrl}/accounting/venues/${businessId}/payouts`, {
      headers: {
        accept: 'application/octet-stream',
      },
      params: {
        from: startDate,
        to: endDate,
        language: navigator.language,
      },
    })
      .then((response) => ({
        blob: new Blob([response.data]),
        contentDisposition: response.headers['content-disposition'],
      }));
  },
  getPayoutsDetailsExport(businessId: BusinessId, startDate: Date, endDate: Date) {
    return axios
      .get<Blob>(`${payoutUrl}/accounting/venues/${businessId}/detailed-payouts`, {
      headers: {
        accept: 'application/octet-stream',
      },
      params: {
        from: startDate,
        to: endDate,
        language: navigator.language,
      },
    })
      .then((response) => ({
        blob: new Blob([response.data]),
        contentDisposition: response.headers['content-disposition'],
      }));
  },
});
