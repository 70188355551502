import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { breakpoints, spaceUsages, Stats } from '@sundayapp/b2b-react-component-library';
import { differenceMoneys, sumMoneys } from '@sundayapp/web-money';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { BusinessId } from 'src/business/domain/Business';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useGetVenueBoxes } from 'src/ordering/box';
import { useReportingDigest } from 'src/ordering/reporting';
import { ReportingDigest, ReportingDigestPerBox } from 'src/ordering/reporting/types';
import { DateRange } from '../domain/DateRange';
import { CustomMoneyView } from './CustomMoneyView';
import { RevenueAndTips } from './RevenuesAndTipsSummary';

type Box = {
  id: string;
  name: string;
  displayName: string;
};

type OrderingReportingBoxDetailProps = {
  box: Box;
  report: ReportingDigest;
};

const ReportContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  min-width: 0;
  max-width: 100%;
  background: white;
  border-radius: 24px;
  box-shadow: 0 1px 8px rgba(0,0,0,0.03);
  padding: 16px;
  gap: 1rem;
`;

const ReportTitle = styled('div')`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  color: #424248;
  margin: 0 4px 16px 0;
  text-transform: lowercase;
`;

const RevenueBreakdownContainer = styled('div')`
  display: flex;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

function getRevenueDetailsBreakdown(report: ReportingDigest, intl: IntlShape) {
  if (report.reporting.cashOutsideSundayB2CAppRevenue?.amount > 0
  || report.reporting.cardOutsideSundayB2CAppRevenue?.amount > 0) {
    return [
      {
        label: intl.formatMessage(
          {
            id: 'finance.OrderAndPay',
            defaultMessage: 'Order&Pay',
          },
        ),
        revenue: report.reporting.stripeRevenue,
      },
      {
        label: intl.formatMessage(
          {
            id: 'finance.cashCentralTill',
            defaultMessage: 'Central till cash',
          },
        ),
        revenue: report.reporting.cashOutsideSundayB2CAppRevenue,
      },
      {
        label: intl.formatMessage(
          {
            id: 'finance.cardCentralTill',
            defaultMessage: 'Central till card',
          },
        ),
        revenue: report.reporting.cardOutsideSundayB2CAppRevenue,
      },
    ];
  }
  return [];
}

const OrderingReportingBoxDetail = ({ box, report }: OrderingReportingBoxDetailProps) => {
  const intl = useIntl();

  if (!report?.reporting) {
    return <></>;
  }

  return (
    <RevenueAndTips
      name={intl.formatMessage(
        {
          id: 'finance.boxGrossRevenue',
          defaultMessage: '{boxName} gross revenue',
        },
        {
          boxName: box.displayName || box.name,
        },
      )}
      revenue={report.reporting.totalGrossRevenue}
      tips={report.reporting.totalTips}
      revenueDescription={intl.formatMessage({
        id: 'finance.excludingTips',
        defaultMessage: 'excluding tips',
      })}
      revenueDetailsBreakdown={getRevenueDetailsBreakdown(report, intl)}
    />
  );
};

// Remove unaccounted refunds (aka refunds not linked to a box order (case of multibox orders))
// The monobox refunds have already been removed from stripe revenue hence the difference between totalAccountedRefund and grandTotalRefunded
const getInAppTotalIncludingUnaccountedRefunds = (reportingDigest: ReportingDigestPerBox) =>
  sumMoneys(reportingDigest.stripeRevenue, differenceMoneys(reportingDigest.totalAccountedRefund, reportingDigest.grandTotalRefunded));

type OrderingReportingPanelContentProps = {
  businessId: BusinessId;
  countryCode: string;
  boxes: Box[];

  reportingDateRange: DateRange;
};

const OrderingReportingPanelContent = ({
  businessId, countryCode, boxes, reportingDateRange,
}: OrderingReportingPanelContentProps) => {
  const { formatMessage } = useIntl();
  const dateQuery = useMemo(() => ({ startDate: reportingDateRange.startDate, endDate: reportingDateRange.endDate }), [reportingDateRange.startDate, reportingDateRange.endDate]);
  const boxIds = useMemo(() => boxes.map((box) => box.id), [boxes]);

  const reportingDigest = useReportingDigest(countryCode, businessId, boxIds, dateQuery);

  const renderBoxDetail = useCallback((box: Box) => {
    if (!reportingDigest) {
      return <></>;
    }

    return <OrderingReportingBoxDetail key={box.id} box={box} report={reportingDigest.detailPerBox[box.id]} />;
  }, [reportingDigest]);

  const renderSummaryWithPayWithCash = useCallback(() => {
    if (!reportingDigest) {
      return <></>;
    }

    if (reportingDigest.cashOutsideSundayB2CAppRevenue?.amount > 0
      || reportingDigest.cardOutsideSundayB2CAppRevenue?.amount > 0) {
      return (
        <ReportContainer>
          <ReportTitle>
            <FormattedMessage id="finance.totalBreakdownIncludingCash" defaultMessage="Breakdown per payment type" />
          </ReportTitle>
          <RevenueBreakdownContainer>
            <Stats
              dataLabel={formatMessage({ id: 'accounting.orderAndPayInApp.revenue' })}
              amount={<CustomMoneyView value={getInAppTotalIncludingUnaccountedRefunds(reportingDigest)} />}
              padding={spaceUsages.mediumLarge}
              data-testid="orderAndPay-in-app-revenue"
            />
            <Stats
              dataLabel={formatMessage({ id: 'accounting.orderAndPayCash.revenue' })}
              amount={<CustomMoneyView value={reportingDigest.cashOutsideSundayB2CAppRevenue} />}
              padding={spaceUsages.mediumLarge}
              data-testid="orderAndPay-cash-revenue"
            />
            <Stats
              dataLabel={formatMessage({ id: 'accounting.orderAndPayCard.revenue' })}
              amount={<CustomMoneyView value={reportingDigest.cardOutsideSundayB2CAppRevenue} />}
              padding={spaceUsages.mediumLarge}
              data-testid="orderAndPay-card-revenue"
            />
          </RevenueBreakdownContainer>
        </ReportContainer>
      );
    }
  }, [reportingDigest]);

  if (!reportingDigest) {
    return <></>;
  }

  return (
    <>
      {renderSummaryWithPayWithCash()}
      <ReportContainer>
        <ReportTitle>
          <FormattedMessage id="finance.boxDetails" defaultMessage="per boxes" />
        </ReportTitle>
        <>
          {reportingDigest.grandTotalRefunded.amount > 0 && (
          <RevenueBreakdownContainer>
            <Stats
              dataLabel={formatMessage({ id: 'accounting.orderAndPayInApp.refunds' })}
              dataProvider={formatMessage({ id: 'accounting.orderAndPayInApp.forPaymentFromApp' })}
              amount={<CustomMoneyView value={reportingDigest.grandTotalRefunded} />}
              padding={spaceUsages.mediumLarge}
              data-testid="orderAndPay-in-app-refunds"
            />
            <Stats
              dataLabel={formatMessage({ id: 'accounting.orderAndPayInApp.refunds.accounted' })}
              dataProvider={formatMessage({ id: 'accounting.orderAndPayInApp.forPaymentFromApp' })}
              amount={<CustomMoneyView value={reportingDigest.totalAccountedRefund} />}
              padding={spaceUsages.mediumLarge}
              data-testid="orderAndPay-in-app-refunds-accounted"
            />
            <Stats
              dataLabel={formatMessage({ id: 'accounting.orderAndPayInApp.refunds.unaccounted' })}
              dataProvider={formatMessage({ id: 'accounting.orderAndPayInApp.forPaymentFromApp' })}
              amount={<CustomMoneyView value={differenceMoneys(reportingDigest.grandTotalRefunded, reportingDigest.totalAccountedRefund)} />}
              padding={spaceUsages.mediumLarge}
              data-testid="orderAndPay-in-app-refunds-unaccounted"
            />
          </RevenueBreakdownContainer>
          )}
          {boxes.map((box) => renderBoxDetail(box))}
        </>
      </ReportContainer>
    </>
  );
};

type ReportingPanelProps = {
  businessId: BusinessId;
  enrollmentId: EnrollmentId;
  countryCode: string;

  reportingDateRange: DateRange;
};

export const OrderingReportingPanel = ({
  businessId, enrollmentId, countryCode, reportingDateRange,
}: ReportingPanelProps) => {
  const { isLoading, isError, data: loadedBoxes } = useGetVenueBoxes(enrollmentId);

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return <></>;
  }

  const boxes = (loadedBoxes ?? [])
    .map(({ id, name, displayName }) => ({ id, name, displayName } as Box));

  if (boxes.length <= 1) {
    return <></>;
  }

  return <OrderingReportingPanelContent businessId={businessId} countryCode={countryCode} boxes={boxes} reportingDateRange={reportingDateRange} />;
};
