import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessReferentialRepositoryHttp } from 'src/business/infrastructure/BusinessReferentialRepositoryHttp';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { venuePath } from 'src/app/navigation/pathHelpers';


const BusinessInitialSetupCompleted = () => {

  const navigate = useNavigate();
  const intl = useIntl();
  const snackBar = useSnackbar();
  const business = useCurrentBusinessOrThrow();
  const configuration = ConfigurationLoader.load();
  const businessReferentialRepository = new BusinessReferentialRepositoryHttp(configuration.venueApiBaseUrl);

  useEffect(() => {
    businessReferentialRepository.completeInitialSetup(business.id).catch(() => {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'initial_setup.completed.error' }),
      });
    });
    const timer = setTimeout(() => {
      navigate(`${venuePath(business.id)}${RouteDefinitions.Onboarding.path}`);

      // TODO how to reload business before the navigate
      window.location.reload();
    }, 2000);
    return () => clearTimeout(timer);

  }, [ navigate ]);

  return (
    <Box sx={{
      padding: '16px',
      maxWidth: '600px',
      width: 'calc(100% - 32px)',
      margin: 'auto',
      backgroundColor: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
    }}>
      <CircularProgress color="primary" data-testid="invoices-loader" />
      <Typography variant={'h5'} margin={2}>
        <FormattedMessage id="initial_setup.completed.title" />
      </Typography>
      {/*      <Typography variant={'body1'}>
        <FormattedMessage id="initial_setup.completed.text" />
      </Typography>*/}
    </Box>
  );
};

export default BusinessInitialSetupCompleted;
