import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { QUERYKEY_PAYMENT_TERMINAL_LIST } from '../queries/listPaymentTerminalsQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const usePaymentTerminalProvisioningMutation = (enrollmentId: EnrollmentId) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return useMutation({
    mutationFn: (dto: PaymentTerminalProvisioningMutationDto) => repository.provision(enrollmentId, dto.name, dto.configurationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_PAYMENT_TERMINAL_LIST] });
    },
  });
};

export const usePaymentTerminalProvisioningUpdateMutation = (enrollmentId: EnrollmentId, id: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return useMutation({
    mutationFn: (dto: PaymentTerminalProvisioningMutationDto) => repository.updateProvision(enrollmentId, id, dto.name, dto.configurationId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_PAYMENT_TERMINAL_LIST] });
    },
  });
};

export type PaymentTerminalProvisioningMutationDto = {
  name: string;
  configurationId: string;
};
