import { DateRange } from '../../domain/DateRange';

export type AvailableRange = 'yesterday' | 'last 7 days' | 'last 30 days' | 'custom';
export const toReviewsDateRange = (rangeType: AvailableRange): DateRange => {
  if (rangeType === 'last 7 days') {
    return DateRange.lastSevenDays();
  }
  if (rangeType === 'last 30 days') {
    return DateRange.lastThirtyDays();
  }
  if (rangeType === 'custom') {
    return DateRange.lastThirtyDays();
  }
  return DateRange.yesterday();
};
