/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const LeftArrowIcon: React.FC<IconProps> = ({ size = 14, color = 'black', ...props }) => (
  <svg
    width={size / 2}
    height={size}
    viewBox="0 0 7 14"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m 6.2323084,13.104509 c 0.1544631,0 0.3079297,-0.05793 0.4257812,-0.175781 0.235702,-0.2357 0.235702,-0.617816 0,-0.853516 L 2.117074,7.5322432 c -0.2929,-0.29289 -0.2929,-0.7696 0,-1.0625 l 4.5410156,-4.541016 c 0.235702,-0.2357 0.235702,-0.619668 0,-0.855468 -0.235702,-0.23570002 -0.6178056,-0.23570002 -0.8535156,0 L 0.76355838,6.1162276 c -0.4881496,0.48816 -0.4881596,1.2813712 0,1.7695312 L 5.804574,12.928728 c 0.117855,0.117851 0.2732713,0.175781 0.4277344,0.175781 z"
      fill={color}
    />
  </svg>
);
