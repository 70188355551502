import { Money, MoneyView } from '@sundayapp/web-money';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const CustomMoneyView = ({ value } : { value : Money | undefined }) => {
  const { formatMessage } = useIntl();

  return (value === undefined || value.amount === 0)
    ? <>{formatMessage({ id: 'accounting.none' })}</>
    : <MoneyView value={value} />;
};
