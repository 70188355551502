import { CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { Box } from '../../../box/domain/Box';
import { useGetPrioritizedVenueBoxes } from '../../../box/hooks';
import { Container, Description, Header, TitleContainer } from '../../../common/components/Settings/SettingPanel';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { useUpdateVenueDetails } from '../../../venues/hook';
import { VenueDetails } from '../../../venues/types';
import { BoxCreation } from './BoxCreation';
import { BoxesPanel } from './BoxesPanel';

type BoxRankingVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const Title = styled.div`
  font-size: 21px;
  line-height: 21px;
  color: ${colorPalette.defaultTextColor};
  margin-bottom: ${getSpacing(1)};
`;

const BoxRankingVenueSettingContainer = ({ venue, setVenue, existingBoxes }: BoxRankingVenueSettingProps & { existingBoxes: Box[] }) => {
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);

  const [boxes, setBoxes] = useState<Box[]>(existingBoxes ?? []);

  const onBoxCreated = async (box: Box) => {
    const updatedBoxes = [...boxes, box];

    const boxesPriorities = updatedBoxes.map((updatedBox) => updatedBox.id);
    await updateVenueDetails({ boxesPriorities });

    setBoxes(updatedBoxes);
  };

  const onBoxDeleted = async (deletedBox: Box) => {
    const updatedBoxes = boxes.filter((box) => box.id !== deletedBox.id);

    const boxesPriorities = updatedBoxes.map((updatedBox) => updatedBox.id);
    await updateVenueDetails({ boxesPriorities });

    setBoxes(updatedBoxes);
  };

  return (
    <Container extraPadding>
      <Header>
        <TitleContainer>
          <Title>
            <FormattedMessage
              id="venue.boxesPriorities"
              defaultMessage="boxes"
              description="box ranking setting title"
            />
          </Title>
          <Description>
            <FormattedMessage
              id="venue.boxesPrioritiesDescription"
              defaultMessage="create a new box or change the position of boxes in the main page"
            />
          </Description>
        </TitleContainer>
        <BoxCreation enrollmentId={venue.id} onBoxCreated={onBoxCreated} />
      </Header>
      <BoxesPanel venue={venue} setVenue={setVenue} boxes={boxes} setBoxes={setBoxes} onBoxDeleted={onBoxDeleted} />
    </Container>
  );
};

export const BoxRankingVenueSetting = ({ venue, setVenue }: BoxRankingVenueSettingProps) => {
  const { isLoading, data: boxes } = useGetPrioritizedVenueBoxes(venue.id, venue.boxesPriorities);

  if (isLoading) {
    return <CircularProgress />;
  }

  return <BoxRankingVenueSettingContainer venue={venue} setVenue={setVenue} existingBoxes={boxes ?? []} />;
};
