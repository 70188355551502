import { EReputationKpi, EReputationKpiDto, EReputationKpis, EReputationKpisByTimeframeDto, EReputationKpisDto, GraphKpis, GraphKpisDto, TemporalKpi, TemporalKpiDto } from '../domain/EReputationKpis';
import { KpiTimeframe } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

function mapTemporalKpi(dateMapper: (dateAsString: string) => Date): (initial: TemporalKpiDto) => TemporalKpi {
  return (initial: TemporalKpiDto) => ({
    unit: dateMapper(initial.unit),
    value: initial.value,
  });
}

function mapGraphKpis(dateMapper: (dateAsString: string) => Date): (graphKpis: GraphKpisDto) => GraphKpis {
  return (initial: GraphKpisDto) => ({
    fromGoogle: initial.fromGoogle.map(mapTemporalKpi(dateMapper)),
    fromSunday: initial.fromSunday.map((e) => ({
      ...e,
      unit: dateMapper(e.unit),
    })),
  });
}

function mapEReputationKpi(dateMapper: (t: string) => Date): (initial: EReputationKpiDto) => EReputationKpi {
  return (initial: EReputationKpiDto) => ({
    kpiType: initial.kpiType,
    globalKpis: initial.globalKpis,
    graphKpis: mapGraphKpis(dateMapper)(initial.graphKpis),
  });
}

function mapEReputationKpiByTimeFrame(dateMapper: (t: string) => Date, timeFrameMapper: (s: string) => KpiTimeframe) {
  return (initial: EReputationKpisByTimeframeDto) => ({
    timeframeKpiType: timeFrameMapper(initial.timeframeKpiType),
    eReputationKpis: initial.eReputationKpis.map(mapEReputationKpi(dateMapper)),
  });
}

export function mapEReputationKpis(dateMapper: (t: string) => Date, timeFrameMapper: (s: string) => KpiTimeframe):
(initial: EReputationKpisDto) => EReputationKpis {
  return (initial: EReputationKpisDto) => ({
    eReputationKpisByTimeframes: initial.eReputationKpisByTimeframes?.map(mapEReputationKpiByTimeFrame(dateMapper, timeFrameMapper)),
  });
}
