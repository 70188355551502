import { Box, Card, CardContent, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

export const OptInLevelDefinition = () => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Typography>
          <FormattedMessage id={'customer_data.opt_in_level.definitions'} />
        </Typography>
        <Box paddingTop="16px" display="flex" flexDirection="column">
          <Box display="flex" gap="16px">
            <Typography>
              <FormattedMessage id={'customer_data.opt_in_level.hard'} />:
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              <FormattedMessage id={'customer_data.opt_in_level.hard.description'} />
            </Typography>
          </Box>
          <Box paddingTop="16px" display="flex" gap="16px">
            <Typography>
              <FormattedMessage id={'customer_data.opt_in_level.soft'} />:
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              <FormattedMessage id={'customer_data.opt_in_level.soft.description'} />
            </Typography>
          </Box>
          <Box paddingTop="16px" display="flex" gap="16px">
            <Typography>
              <FormattedMessage id={'customer_data.opt_in_level.none'} />:
            </Typography>
            <Typography color={theme.palette.text.secondary}>
              <FormattedMessage id={'customer_data.opt_in_level.none.description'} />
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
