import { AccountingRepository } from '../../reporting/infrastructure/AccountingRepository';
import axios from 'axios';
import { revenuesDatePresets } from '../domain/RevenuesDatePresets';
import { BusinessId } from 'src/business/domain/Business';

export const useGetTodayAccountingSummary = () => {
  const accountingRepository = new AccountingRepository(axios);

  return (businessId: BusinessId, timezone: string) => {
    const datePresets = revenuesDatePresets(timezone).TODAY;
    return accountingRepository.getRevenuesSummary(businessId, datePresets.range.startDate.clone().utc().format(), datePresets.range.endDate.clone().utc().format());
  };
};
