import { Collapse, Grid, Table, TableBody, TableCell, TableRow, Theme, Typography } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { RevenueUnitRow } from './RevenueUnitRow';
import {
  RevenueSource,
  revenueSources,
  RevenueSourceType,
  revenueSourceTypes,
  revenueSourceTypeTranslations,
} from '../../../domain/RevenueSource';
import { translation, Translation } from '../../../../common/translation';
import { RevenueAmountsCells } from './RevenueAmountsCells';
import { RevenuePeriod } from '../../../domain/RevenuesDatePresets';
import { Variant } from '@mui/material/styles/createTypography';
import { RevenueDetails } from '../../../domain/RevenueSummary';
import { buildColGroups } from './RevenueRowGroup';

export type CellVariant = { variant: Variant; color: string };

const knownPaidBySources = [
  'meal_vouchers',
  'payment_links',
  'payment_terminal',
  'qr_code',
  'sunday',
  'toast',
] as const;

export const RevenueRow = ({
  row,
  source,
  isBusinessWithServiceCharge,
  theme,
}: {
  row: RevenueDetails;
  source: RevenueSource;
  period: RevenuePeriod;
  isBusinessWithServiceCharge: boolean;
  theme: Theme;
}) => {
  const [open, setOpen] = useState(false);
  const sourceTranslationKey = knownPaidBySources.find(v => v === source.toLocaleLowerCase());
  const sourceTranslation = sourceTranslationKey
    ? translation(`accounting.revenue_tab.table.paid_by.${sourceTranslationKey}`)
    : translation('accounting.revenue_tab.table.paid_by', { provider: source.toLocaleLowerCase() });

  const revenueTypeSubtitles: { [key in RevenueSourceType]: Translation } = {
    CARDS: sourceTranslation,
    OTHER_REVENUES: translation('accounting.revenue_tab.table.paid_by.sunday'),
    MEAL_VOUCHERS: translation('accounting.revenue_tab.table.paid_by.meal_vouchers'),
    PAYMENT_LINKS: translation('accounting.revenue_tab.table.paid_by.payment_links'),
    AMEX: sourceTranslation,
  };

  const thCellStyle: CellVariant = { variant: 'h6', color: theme.palette.text.primary };
  const tdCellStyle: CellVariant = { variant: 'subtitle1', color: theme.palette.text.secondary };

  const hasRevenueUnits = row.revenueUnits.length > 0;
  const isFromOtherRevenues = row.source === revenueSourceTypes.OTHER_REVENUES;
  const isPaymentTerminal = source === revenueSources.PAYMENT_TERMINAL;

  const RevenueUnitsOpeningIndicator = open ? (
    <ExpandMoreIcon fontSize="large" sx={{ color: theme.palette.text.secondary }} />
  ) : (
    <ChevronRightIcon fontSize="large" sx={{ color: theme.palette.text.secondary }} />
  );
  const doNotBreakText = { whiteSpace: 'nowrap' };

  return (
    <>
      <TableRow
        sx={{ '& > *': { cursor: hasRevenueUnits ? 'pointer' : 'inherit' } }}
        onClick={() => (hasRevenueUnits ? setOpen(!open) : undefined)}
      >
        <TableCell component="th" scope="row" sx={{ height: '74px' }}>
          <Grid container>
            <Grid item xs={3}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: '48.5px !important',
                  }}>
              {hasRevenueUnits ? RevenueUnitsOpeningIndicator : <></>}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={doNotBreakText} variant="subtitle1" color={theme.palette.text.primary}>
                <FormattedMessage id={revenueSourceTypeTranslations[row.source].id} />
              </Typography>
              <Typography sx={doNotBreakText} variant="subtitle2" color={theme.palette.text.secondary}>
                <FormattedMessage
                  id={revenueTypeSubtitles[row.source].id}
                  values={revenueTypeSubtitles[row.source].values}
                  defaultMessage={revenueTypeSubtitles[row.source].defaultMessage}
                />
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
        <RevenueAmountsCells
          amounts={row.revenueAmounts}
          td={tdCellStyle}
          th={thCellStyle}
          isBusinessWithServiceCharge={isBusinessWithServiceCharge}
          isPaymentTerminal={isPaymentTerminal}
        />

      </TableRow>
      {hasRevenueUnits && (
        <TableRow>
          <TableCell style={{ padding: 'unset', borderBottom: 'unset' }} colSpan={isBusinessWithServiceCharge ? 5 : 4}>
            <Collapse in={open} timeout="auto" sx={{ width: '100%' }}>
              <Table aria-label="table" size="medium">
                {buildColGroups(isBusinessWithServiceCharge)}
                <TableBody>
                  {row.revenueUnits.map((revenueUnit) => (
                    <RevenueUnitRow
                      row={revenueUnit}
                      isBusinessWithServiceCharge={isBusinessWithServiceCharge}
                      isFromOtherRevenues={isFromOtherRevenues}
                      key={revenueUnit.name}
                      theme={theme}
                      isPaymentTerminal={isPaymentTerminal}
                    />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
