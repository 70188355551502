import { useMediaQuery, useTheme } from '@mui/material';
import { HomeContentProps } from '../HomePage';
import { PaymentSection } from './HomeContent/PaymentSection';
import { HomeActions } from './HomeHeader/Actions';
import { PageSubTitle } from '@organisms/Header/PageSubTitle';
import { HomeHeaderTimeRangeInfo } from 'src/home/components/header/HomeHeaderTimeRangeInfo';
import { useState } from 'react';
import { StyledToggleButton } from 'src/home2/components/atoms/ToggleButton/ToggleButton';
import { ReviewSection } from './HomeContent/ReviewSection';
import { FOUR_WEEKS, KpiTimeframeName, SEVEN_DAYS, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { VenueKpi } from 'src/home/domain/VenueKpi';

export type HomeSectionProps = {
  venueKpi: VenueKpi,
  selectedTimeFrame: KpiTimeframeName; 
};

export const HomeContent = ({
  venueKpi,
  adoptionRate,
}: HomeContentProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));

  const [selectedTimeFrame, setSelectedTimeFrame] = useState<KpiTimeframeName>('SEVEN_DAYS');

  return (
    <>
      {!isMobile && <HomeActions />}
      {adoptionRate && (
        <PageSubTitle>
          <StyledToggleButton
            listButtons={[
              {
                id: 'reviews.ereputation.timeframe.last_seven_days',
                value: SEVEN_DAYS,
              },
              {
                id: 'reviews.ereputation.timeframe.last_four_weeks',
                value: FOUR_WEEKS,
              },
              {
                id: 'reviews.ereputation.timeframe.last_twelve_months',
                value: TWELVE_MONTHS,
              },
            ]}
            selectedOption={selectedTimeFrame}
            setSelectedOption={setSelectedTimeFrame}
          />
          <HomeHeaderTimeRangeInfo />
        </PageSubTitle>
      )}
      <PaymentSection
        venueKpi={venueKpi}
        selectedTimeFrame={selectedTimeFrame}
      />
      <ReviewSection 
        venueKpi={venueKpi} 
        selectedTimeFrame={selectedTimeFrame} 
      />
    </>
  );
};
