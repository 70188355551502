import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { LocalisationKey } from 'src/lang/en';

export const businessRegistrationNumberPattern: { [key: string]: RegExp } = {
  FR: /^.{14}$/,
  GB: /^(((AC|CE|CS|FC|FE|GE|GS|IC|LP|NC|NF|NI|NL|NO|NP|OC|OE|PC|R0|RC|SA|SC|SE|SF|SG|SI|SL|SO|SR|SZ|ZC|\d{2})\d{6})|((IP|SP|RS)[A-Z\d]{6})|(SL\d{5}[\dA]))$/,
  US: /^\d{9}$/,
};

const getBusinessRegistrationNumberFieldType = (country: string) => (country == 'FR' ? 'number' : 'text');

const businessRegistrationNumberRequiredLabel = (country: string): LocalisationKey => {
  if (country.toLowerCase() === 'gb') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.required.gb';
  if (country.toLowerCase() === 'us') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.required.us';
  return 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.required.fr';
};

const businessRegistrationNumberPatternLabel = (country: string): LocalisationKey => {
  if (country.toLowerCase() === 'gb') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.pattern.gb';
  if (country.toLowerCase() === 'us') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.pattern.us';
  return 'settings.payments.checkout.onboarding.businessRegistrationNumber.error.pattern.fr';
};

const businessRegistrationNumberLabel = (country: string): LocalisationKey => {
  if (country.toLowerCase() === 'gb') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.gb';
  if (country.toLowerCase() === 'us') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.us';
  return 'settings.payments.checkout.onboarding.businessRegistrationNumber.fr';
};

const businessRegistrationNumberHelpLabel = (country: string): LocalisationKey => {
  if (country.toLowerCase() === 'gb') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.help.gb';
  if (country.toLowerCase() === 'us') return 'settings.payments.checkout.onboarding.businessRegistrationNumber.help.us';
  return 'settings.payments.checkout.onboarding.businessRegistrationNumber.help.fr';
};

export const BusinessRegistrationNumber = () => {
  const {
    formState: { errors },
    register,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const businessRegistrationNumberError = errors.businessRegistrationNumber;
  const business = useCurrentBusinessOrThrow();
  const country = business.address.countryCode;
  const pattern = businessRegistrationNumberPattern[business.address.countryCode] || /.*/;
  const intl = useIntl();

  return (
    <TextField
      required
      type={getBusinessRegistrationNumberFieldType(country)}
      InputLabelProps={{ shrink: true }}
      error={!!businessRegistrationNumberError}
      {...register('businessRegistrationNumber', {
        required: intl.formatMessage({ id: businessRegistrationNumberRequiredLabel(country) }),
        pattern: {
          value: pattern,
          message: intl.formatMessage({
            id: businessRegistrationNumberPatternLabel(country),
          }),
        },
      })}
      label={intl.formatMessage({ id: businessRegistrationNumberLabel(country) })}
      helperText={
        businessRegistrationNumberError?.message ||
        intl.formatMessage({ id: businessRegistrationNumberHelpLabel(country) })
      }
    />
  );
};
