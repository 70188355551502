import * as React from 'react';
import { Tooltip } from '@mui/material';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ProductSummary } from '../../../../domain/ProductSummary';

type Props = {
  product: ProductSummary;
};
export const ProductInformationIcon = ({ product }: Props) => {
  const intl = useIntl();

  if (!product.hasDiscrepancies) return <></>;
  return (
    <Tooltip title={intl.formatMessage({ id: 'menus.products.discrepancies' })}>
      <PermDeviceInformationIcon color="warning" />
    </Tooltip>
  );
};
