import styled from 'styled-components';


export const TextContainer = styled('div')`
  display: flex;
  flex-direction: column;

  > :not(:first-child) {
    margin-top: 1rem;
  }
`;

export const SettingContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
