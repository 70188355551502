import { CardMedia, Grid, useTheme } from '@mui/material';
import React from 'react';
import { EReputationKpisViewModel } from './EReputationViewModel';
import { EReputationPanels } from './EReputationPanels';
import { RatingsGraph } from './graphs/RatingsGraph';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { StatusCard } from '@organisms/Body/StatusCard/StatusCard';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';
import { NoEreputationData } from 'src/sundayplus/components/NoEreputationData';

const removeEmptyKpis = (eReputationKpisViewModel: EReputationKpisViewModel[]): EReputationKpisViewModel[] =>
  eReputationKpisViewModel.filter((eRepKpis) => eRepKpis.globalKpis || eRepKpis.graphKpis);

type Props = {
  onChange: (newKpiType: KpiName) => void;
  kpiType: KpiName,
  eReputationKpisViewModels: EReputationKpisViewModel[];
  subscriptionDate: number;
};
export const PanelsAndGraph = ({
  onChange,
  kpiType,
  eReputationKpisViewModels,
  subscriptionDate,
}: Props) => {
  const theme = useTheme();
  let displayRatingGraph = true;
  if (isMobileScreen()) {
    displayRatingGraph = false;
  }

  const filteredEReputationKpisViewModel = removeEmptyKpis(eReputationKpisViewModels);

  if (filteredEReputationKpisViewModel.length == 0) {
    return <NoEreputationData theme={theme} />;
  }

  if (filteredEReputationKpisViewModel.length == 0) {
    return <StatusCard
      media={<CardMedia sx={{
        height: '215px',
        objectFit: 'contain',
        width: '260px',
      }} component="img" src="../../../../public/home_empty_status.svg" />}
      title={'No data to show for selected time period'}
      subtitles={[]}
      button={''}
    />;
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      marginTop="1.5rem"
    >
      <Grid
        item
        xl={2.8}
        lg={2.8}
        md={12}
        sm={12}
        xs={12}
      >
        <EReputationPanels
          onChangeKpiType={onChange}
          initialKpiType={kpiType}
          eReputationKpisViewModel={eReputationKpisViewModels}
        />
      </Grid>
      {displayRatingGraph && (<Grid
        item
        xs={9}
        sx={{
          display: {
            sm: 'none',
            md: 'none',
            lg: 'flex',
          },
          backgroundColor: 'white',
          borderRadius: '1.2rem',
          padding: '2rem',
          boxShadow: '0.1rem 0.1rem 0.1rem #EDEDF2',
          width: '100%',
        }}
      >
        <RatingsGraph
          kpiType={kpiType}
          eReputationKpisViewModels={eReputationKpisViewModels}
          subscriptionDate={subscriptionDate}
        />
      </Grid>)}
    </Grid>
  );
};
