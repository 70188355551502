// @ts-ignore

import { GridComparatorFn } from '@mui/x-data-grid';
import { Money } from '@sundayapp/web-money';

export const DataGridTheme = {
  styleOverrides: {
    root: {
      borderColor: 'transparent',
      borderSize: '0px',
      backgroundColor: 'white',
      borderRadius: '16px',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)',

      '& .MuiDataGrid-columnSeparator': {
        visibility: 'hidden !important',
      },

      '& .MuiDataGrid-container--top [role=row]': {
        backgroundColor: 'white',

        '&:first-of-type': {
          borderTopRightRadius: '16px',
          borderTopLeftRadius: '16px',
          padding: '24px 1§px 6px 16px',
        },
      },

      '& .MuiDataGrid-columnHeaderTitleContainer': {
        color: '#70707B',
      },

      '& .headerGroupWithoutBorder': {
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          borderBottom: 'none !important',
        },
      },

      '& .MuiDataGrid-row': {
        '&:last-of-type': {
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        },
      },

      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },

    },
  },
};

export const gridMoneyComparator: GridComparatorFn<Money> = (v1: Money, v2: Money) => v1.amount - v2.amount;
