import { CurrencyCode, Money } from '@sundayapp/web-money';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { SchemeSummary } from '../infrastructure/SummaryOnAPeriod';

export class ReportingDigest {
  public detailsBySource: Map<string, { grossRevenue: Money, netRevenue?: Money, tipRevenue: Money, inconsistency?: Money, serviceCharge?: Money }>;

  public schemeDetailsBySource: Map<string, SchemeSummary[]>;

  public additionalRevenue?: Money;

  constructor() {
    this.detailsBySource = new Map();
    this.schemeDetailsBySource = new Map();
  }

  addDetails(provider: string, grossRevenue: Money, tipRevenue: Money, netRevenue?: Money, inconsistency?: Money, serviceCharge?: Money) {
    this.detailsBySource.set(provider, {
      grossRevenue, netRevenue, tipRevenue, inconsistency, serviceCharge,
    });
  }

  addSchemeDetails(provider: string, schemeSummaries: SchemeSummary[]) {
    this.schemeDetailsBySource.set(provider, schemeSummaries);
  }

  getPaymentTerminalSchemeDetails = (): SchemeSummary[] => this.schemeDetailsBySource?.get('Nepting') ?? [];

  getTotalRevenueWithoutTips(currency: CurrencyCode) {
    return safeSumMoney(Array.from(this.detailsBySource)
      .map(([, revenue]) => revenue.grossRevenue), currency);
  }

  getTotalTips(currency: CurrencyCode) {
    return safeSumMoney(Array.from(this.detailsBySource)
      .map(([, revenue]) => revenue.tipRevenue), currency);
  }

  getProviderRevenue(currency: CurrencyCode, provider: string): Money {
    return safeSumMoney(Array.from(this.detailsBySource)
      .filter(([currentProvider]) => currentProvider.toLowerCase() === provider.toLowerCase())
      .map(([, revenue]) => revenue.grossRevenue), currency);
  }

  getProviderTips(currency: CurrencyCode, provider: string): Money {
    return safeSumMoney(Array.from(this.detailsBySource)
      .filter(([currentProvider]) => currentProvider.toLowerCase() === provider.toLowerCase())
      .map(([, revenue]) => revenue.tipRevenue), currency);
  }

  getProviderInconsistency(provider: string): Money | undefined {
    return Array.from(this.detailsBySource)
      .find(([currentProvider]) => currentProvider.toLowerCase() === provider.toLowerCase())
      ?.[1].inconsistency;
  }

  getOtherProviders(excludedProvider: string) {
    return Array.from(this.detailsBySource).filter(([provider]) => provider.toLowerCase() !== excludedProvider);
  }
}
