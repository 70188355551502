import { styled } from '@mui/material';
import { breakpoints } from 'src/app/theme/ThemeV5';

export const Container = styled('div')`
  border-radius: 24px;
  background-color: white;
  padding: 24px 16px;
  margin-bottom: 32px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 1px 3px rgba(50, 50, 93, 0.06);

  @media (max-width: ${breakpoints.mobile}) {
    width: 100vw;
    border-radius: 0;
    margin: 0 -16px 0 -16px;
  }
`;

export const BoxTitle = styled('div')`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const NoOrderYetPanel = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  font-size: 16px;
`;
