/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const RightArrowIcon: React.FC<IconProps> = ({ size = 14, color = 'black', ...props }) => (
  <svg
    width={size / 2}
    height={size}
    viewBox="0 0 7 14"
    fill="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      id="path2"
      d="M 1 0.89648438 C 0.84553688 0.89648438 0.69207025 0.95441463 0.57421875 1.0722656 C 0.33851675 1.3079656 0.33851675 1.6900812 0.57421875 1.9257812 L 5.1152344 6.46875 C 5.4081344 6.76164 5.4081344 7.23835 5.1152344 7.53125 L 0.57421875 12.072266 C 0.33851675 12.307966 0.33851675 12.691934 0.57421875 12.927734 C 0.80992075 13.163434 1.1920244 13.163434 1.4277344 12.927734 L 6.46875 7.8847656 C 6.9569 7.3966056 6.95691 6.6033944 6.46875 6.1152344 L 1.4277344 1.0722656 C 1.3098794 0.95441463 1.1544631 0.89648438 1 0.89648438 z "
      fill={color}
    />
  </svg>
);
