// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { MouseEvent, useCallback, useState } from 'react';
import Calendar from 'react-calendar';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { DoubleLeftArrowIcon, DoubleRightArrowIcon, LeftArrowIcon, RightArrowIcon } from '../Icons';
import { SelectDirection } from '../Select';
import { ArrowIcon, SelectContainer, SelectedValue, SelectOptions } from '../Select/Select.style';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { getToday } from '../../utils/getToday';
import { areDatesEqual } from '../../utils/areDatesEqual';

export enum DatePickerType {
  DATE,
  RANGE,
}

interface Props {
  value: Date | [Date, Date];
  onChange: (startDate: Date, endDate?: Date) => void;
  direction?: SelectDirection;
  type?: DatePickerType;
}

export const CalendarContainer = styled.div`
  padding: ${getSpacing(0.5)} ${getSpacing(1)};
`;

export const DateRangeLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const DateRangeSeparator = styled(RightArrowIcon)`
  margin: 0 ${getSpacing(1)};
`;

const stopPropagation = (event: MouseEvent) => event.stopPropagation();

export const getEndOfDay = (date: Date) => {
  const endOfDay = new Date(date);
  endOfDay.setDate(endOfDay.getDate() + 1);
  return new Date(endOfDay.getTime() - 1);
};

const isSameDay = (d1: Date, d2: Date | undefined) =>
  d2 instanceof Date
  && d1.getDate() === d2.getDate()
  && d1.getMonth() === d2.getMonth()
  && d1.getFullYear() === d2.getFullYear();

const DateLabel: React.VFC<{ startDate: Date; endDate: Date }> = ({ startDate, endDate }) => {
  const today = getToday();
  const yesterday = getToday();
  yesterday.setDate(yesterday.getDate() - 1);
  const isSingleDaySelected = isSameDay(startDate, endDate);
  const isValueToday = isSingleDaySelected && areDatesEqual(startDate, today);
  const isValueYesterday = isSingleDaySelected && areDatesEqual(startDate, yesterday);
  if (isValueToday) return <FormattedMessage id="date.today" defaultMessage="today" />;
  if (isValueYesterday) return <FormattedMessage id="date.yesterday" defaultMessage="yesterday" />;
  if (isSingleDaySelected) return <>{startDate.toLocaleDateString()}</>;
  return (
    <DateRangeLabel>
      <span>{startDate.toLocaleDateString()}</span>
      <DateRangeSeparator />
      <span>{endDate ? endDate.toLocaleDateString() : 'unknown'}</span>
    </DateRangeLabel>
  );
};

const DatePickerAsSelect: React.FC<Props> = ({
  value,
  onChange,
  type = DatePickerType.DATE,
  direction = SelectDirection.BOTTOM,
  ...props
}) => {
  const startDate = value instanceof Date ? value : value[0];
  const endDate = value instanceof Date ? value : value[1];
  const valueButNextYear = new Date(startDate);
  // this fix a bug from Calendar component
  valueButNextYear.setFullYear(valueButNextYear.getFullYear() + 1);
  const [open, setOpen] = useState<boolean>(false);
  const toggleOpenStatus = useCallback(() => {
    setOpen(!open);
  }, [setOpen, open]);

  const onValueChange = useCallback(
    (changedValue: any) => {
      const [startRange, endRange] = changedValue instanceof Date
        ? [changedValue, undefined]
        : [changedValue[0], changedValue.length > 1 ? changedValue[1] : undefined];
      onChange(startRange, endRange || getEndOfDay(startRange));
      if (type === DatePickerType.DATE || (type === DatePickerType.RANGE && endRange !== undefined)) {
        setOpen(false);
      }
    },
    [onChange, setOpen, type],
  );

  return (
    <SelectContainer onClick={toggleOpenStatus} open={open} {...props}>
      <SelectedValue>
        <DateLabel startDate={startDate} endDate={endDate} />
      </SelectedValue>
      <ArrowIcon open={open} />
      <SelectOptions open={open} onClick={stopPropagation} direction={direction}>
        {open && (
          <CalendarContainer>
            <Calendar
              value={value}
              onChange={onValueChange}
              maxDate={getToday()}
              defaultActiveStartDate={valueButNextYear}
              nextLabel={<RightArrowIcon color={colorPalette.grey600} />}
              prevLabel={<LeftArrowIcon color={colorPalette.grey600} />}
              next2Label={<DoubleRightArrowIcon color={colorPalette.grey600} />}
              prev2Label={<DoubleLeftArrowIcon color={colorPalette.grey600} />}
              returnValue={type === DatePickerType.DATE ? 'start' : 'range'}
              allowPartialRange={type === DatePickerType.RANGE}
              selectRange={type === DatePickerType.RANGE}
            />
          </CalendarContainer>
        )}
      </SelectOptions>
    </SelectContainer>
  );
};

export default DatePickerAsSelect;
