import { Checkbox, FormControl, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const IsPrimaryAddressTheSameAsRegistered = () => {
  const {
    register,
    watch,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const theme = useTheme();
  const isPrimaryAddressTheSameAsRegistered = watch('isPrimaryAddressTheSameAsRegistered');
  const registeredAddress = watch('registeredAddress');
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormControlLabel
        control={
          <Checkbox {...register('isPrimaryAddressTheSameAsRegistered')}
                    defaultChecked={!!isPrimaryAddressTheSameAsRegistered}
                    size={'large'} />}
        label={<Stack>
          <Typography><FormattedMessage id={'settings.payments.checkout.onboarding.isPrimaryAddressTheSameAsRegistered'} /></Typography>
          {isPrimaryAddressTheSameAsRegistered &&
            <Typography color={theme.palette.text.secondary}
                        variant={'body2'}>{`${registeredAddress?.addressLine1}, ${registeredAddress?.zip}, ${registeredAddress?.city}, ${registeredAddress?.country}`}</Typography>}
        </Stack>}
        labelPlacement="end"
      />
    </Stack>
  </FormControl>;
};
