import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Alert, Box, Grid, Switch, Typography } from '@mui/material';
import { CommunicationPreferencesRepositoryHttp } from './infrastructure/CommunicationPreferencesRepositoryHttp';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { isRoleAboveOrEqual, userRoles } from 'src/domain/user/UserRole';
import { roleForBusiness } from 'src/domain/user/Role';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

const DAILY_REVIEWS_EMAIL = 'DAILY_REVIEWS_EMAIL';
const WEEKLY_EMAIL = 'WEEKLY_EMAIL';
const CommunicationPreferences = () => {
  const { userId, claims } = useAuthenticatedUserOrThrow();
  const configuration = ConfigurationLoader.load();
  const businessId = useBusinessIdOrThrow();
  const repository = new CommunicationPreferencesRepositoryHttp(configuration.merchantAccountsBaseUrl);
  const snackbar = useSnackbar();
  const [dailyReportPreference, setDailyReportPreference] = useState<boolean>(false);
  const [weeklyReportPreference, setWeeklyReportPreference] = useState<boolean>(false);
  const [errorOnFetchDailyReportPreference, setErrorOnFetchDailyReportPreference] = useState<boolean>(false);
  const [errorOnFetchWeeklyReportPreference, setErrorOnFetchWeeklyReportPreference] = useState<boolean>(false);
  const [hasPermissionToReceiveDailyEmail, setHasPermissionToReceiveDailyEmail] = useState<boolean>(false);
  const [hasPermissionToReceiveWeeklyEmail, setHasPermissionToReceiveWeeklyEmail] = useState<boolean>(false);
  const roleForSelectedVenue = roleForBusiness(claims, businessId);
  const intl = useIntl();

  const onChangeDailyReportPreference = () => {
    repository
      .updateReportPreference(userId, !dailyReportPreference, DAILY_REVIEWS_EMAIL)
      .then(() => setDailyReportPreference(!dailyReportPreference))
      .then(() =>
        snackbar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'communication_preferences.on_update.success' }),
        }),
      )
      .catch(() =>
        snackbar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'communication_preferences.on_update.fail' }),
        }),
      );
  };
  const onChangeWeeklyReportPreference = () => {
    repository
      .updateReportPreference(userId, !weeklyReportPreference, WEEKLY_EMAIL)
      .then(() => setWeeklyReportPreference(!weeklyReportPreference))
      .then(() =>
        snackbar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'communication_preferences.on_update.success' }),
        }),
      )
      .catch(() =>
        snackbar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'communication_preferences.on_update.fail' }),
        }),
      );
  };
  useEffect(() => {
    setHasPermissionToReceiveDailyEmail(roleForSelectedVenue ? isRoleAboveOrEqual(roleForSelectedVenue, userRoles.MANAGER) : false);
    setHasPermissionToReceiveWeeklyEmail(roleForSelectedVenue ? isRoleAboveOrEqual(roleForSelectedVenue, userRoles.MANAGER) : false);

    repository
      .getReportPreference(userId, DAILY_REVIEWS_EMAIL)
      .then(setDailyReportPreference)
      .catch(() => {
        setErrorOnFetchDailyReportPreference(true);
        snackbar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'communication_preferences.on_fetch.fail' }),
        });
      });

    repository
      .getReportPreference(userId, WEEKLY_EMAIL)
      .then(setWeeklyReportPreference)
      .catch(() => {
        setErrorOnFetchWeeklyReportPreference(true);
        snackbar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'communication_preferences.on_fetch.fail' }),
        });
      });
  }, []);

  return (
    <>
      <Box alignItems="stretch" sx={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '1rem' }}>
        <Grid container direction="column" spacing="1rem">
          <Grid item>
            <Grid container justifyContent="space-between" spacing="1rem">
              <Grid item>
                <Typography variant="h4">
                  <FormattedMessage id="communication_preferences.title" />
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  onChange={onChangeDailyReportPreference}
                  checked={dailyReportPreference}
                  disabled={!hasPermissionToReceiveDailyEmail || errorOnFetchDailyReportPreference}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              <FormattedMessage id="communication_preferences.disclaimer" />
            </Typography>
          </Grid>
          {!hasPermissionToReceiveDailyEmail && (
            <Grid item>
              <Alert severity="error">
                <Typography>
                  <FormattedMessage id="communication_preferences.no_permission" />
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </Box>
      <Box
        alignItems="stretch"
        sx={{
          backgroundColor: 'white',
          padding: '1.5rem',
          borderRadius: '1rem',
          marginTop: '1rem',
        }}
      >
        <Grid container direction="column" spacing="1rem">
          <Grid item>
            <Grid container justifyContent="space-between" spacing="1rem">
              <Grid item>
                <Typography variant="h4">
                  <FormattedMessage id="communication_preferences.weekly.title" />
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  onChange={onChangeWeeklyReportPreference}
                  checked={weeklyReportPreference}
                  disabled={!hasPermissionToReceiveWeeklyEmail || errorOnFetchWeeklyReportPreference}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              <FormattedMessage id="communication_preferences.weekly.disclaimer" />
            </Typography>
          </Grid>
          {!hasPermissionToReceiveDailyEmail && (
            <Grid item>
              <Alert severity="error">
                <Typography>
                  <FormattedMessage id="communication_preferences.weekly.no_permission" />
                </Typography>
              </Alert>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default CommunicationPreferences;
