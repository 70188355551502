import React from 'react';
import { IconProps } from './interface';

export const DownArrowIcon: React.FC<IconProps> = ({ size = 16, color = 'black', ...props }) => (
  <svg
    width={(size * 15) / 16}
    height={(size * 19) / 16}
    viewBox="0 0 15 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.615267 0.763639L0.000398704 1.37851L7.37891 8.75702L14.7574 1.37851L14.1425 0.763639L7.37891 7.52728L0.615267 0.763639Z"
      fill={color}
    />
  </svg>
);
