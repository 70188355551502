import { styled } from '@mui/material';
import { CustomizedBusinessSelectionIcon } from 'src/business/components/BusinessSelectionIcon';
import { BusinessId } from 'src/business/domain/Business';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useBusinessesQuery } from 'src/business/hooks/useBusinessesQuery';

type IconStackWrapperProps = {
  size: number;
  offset: number;
};

// Wrapper for stacking icons
export const IconStackWrapper = styled('div')<IconStackWrapperProps>`
  position: relative;
  width: ${({ size, offset }) => size + offset * 2}px; /* Adjust based on the size of the icons */
  height: ${({ size }) => size}px;
`;

type StackedIconProps = {
  zIndex: number;
  offset: number;
};

// Wrapper for each individual icon in the stack
const StackedIcon = styled('div')<StackedIconProps>`
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  left: ${({ offset }) => `${offset}px`};
`;

type IconStackProps = {
  iconSize?: number;
  offset?: number;
};

export const MultiLocationsLogo = ({ iconSize = 24, offset = 6 }: IconStackProps) => {
  const user = useAuthenticatedUserOrThrow();
  const { data: businesses } = useBusinessesQuery(user.relations.map((r) => r.objectId as BusinessId));
  const icons = !!businesses
    ? user.relations
      .sort((a, b) => a.objectId.localeCompare(b.objectId))
      .slice(0, 3)
      .map((r) => r.objectId)
      .map((bId) => businesses.find((b) => b.id === bId))
      .map((b) => {
        return { businessId: b?.id!!, name: b?.name!! };
      })
    : [];
  return (
    <IconStackWrapper size={iconSize} offset={offset}>
      {icons.map((icon, index) => (
        <StackedIcon key={index} zIndex={icons.length - index} offset={index * offset}>
          <CustomizedBusinessSelectionIcon id={icon.businessId} name={icon.name} iconSize={iconSize} />
        </StackedIcon>
      ))}
    </IconStackWrapper>
  );
};
