import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { NavigationItem } from '../NavigationItems';
import { SubLevelMenuItem } from './SubLevelMenuItem';
import { styled } from '@mui/material';

type SubLevelMenuGroupProps = {
  menuItem: NavigationItem;
  onItemSelected: (item: NavigationItem) => void;

};

const GroupHeader = styled('div')`
  display: flex;
  text-transform: uppercase;
  flex-direction: row;
  color: #70707B;
  margin: 0px 12px 12px 12px;
  font-size: 12px;
  align-items: center;
`;

const GroupContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export function SubLevelMenuGroup({
  menuItem, onItemSelected,
}: SubLevelMenuGroupProps) {

  if (!menuItem.subItems || menuItem.subItems.length === 0) {
    return <></>;
  }

  return (
    <div>
      <GroupHeader>
        <FormattedMessage id={menuItem.text} />
      </GroupHeader>
      <GroupContent>
        {menuItem.subItems.map((subItem) => (
          <SubLevelMenuItem
            key={subItem.route + subItem.text}
            menuItem={subItem}
            onItemSelected={onItemSelected}
          />
        ))}
      </GroupContent>
    </div>
  );
}
