import { Box, FormControl, InputLabel, Select, Typography } from '@mui/material';
import Calendar from 'react-calendar';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import { Dispatch, SetStateAction } from 'react';
import { CalendarStyle } from './CalendarStyle';
import { DateIcon } from '../icons/DateIcon';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const DatePicker = ({
  date, minDate, maxDate, onClickDay, error,
}: {
  date: Date
  minDate: Date,
  maxDate: Date,
  onClickDay: Dispatch<SetStateAction<Date>>,
  error?: boolean
}) => {
  const { formatMessage } = useIntl();

  return (
    <FormControl>
      <InputLabel id="select-date"><Typography>Date</Typography></InputLabel>
      <Select
        labelId="select-date"
        label={formatMessage({ id: 'common.date' })}
        value={date}
        defaultValue={date}
        renderValue={() => (
          <Box display="flex" gap="5px">
            <DateIcon />
            <Typography>{date?.toLocaleDateString()}</Typography>
          </Box>
        )}
        sx={{ width: 'max-content' }}
        error={error}
      >
        <Calendar
          value={date}
          maxDate={maxDate}
          minDate={minDate}
          defaultActiveStartDate={date}
          nextLabel={<ArrowRight fontSize="large" />}
          prevLabel={<ArrowLeft fontSize="large" />}
          next2Label={null}
          prev2Label={null}
          returnValue="start"
          allowPartialRange={false}
          selectRange={false}
          onClickDay={onClickDay}
        />
        <CalendarStyle />
      </Select>
    </FormControl>
  );
};
