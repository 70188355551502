import styled from 'styled-components';
import { colorPalette, fontSize, getSpacing } from '@sundayapp/b2b-react-component-library';

export const MenuTitle = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
  max-width: 180px;
  font-size: ${fontSize.large};
  padding-top: ${getSpacing(1.5)};
  color: ${colorPalette.grey500}
`;

type MenuLineProps = {
  isMobile: boolean;
};

export const MenuLine = styled.div<MenuLineProps>`
  display: flex;
  width: 100%;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
`;
