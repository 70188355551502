import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { CoverPicture } from '../domain/CoverPicture';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';

export const useSaveCoverPicture = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();
  const mutation = useMutation({
    mutationFn: ({ enrollmentId, coverPicture }: { enrollmentId: EnrollmentId; coverPicture: CoverPicture }) =>
      customizationRepository.saveCoverPicture(enrollmentId, coverPicture),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION] }),
  });
  return {
    mutation,
    saveCoverPicture: (enrollmentId: EnrollmentId, coverPicture: CoverPicture) =>
      mutation.mutateAsync({ enrollmentId, coverPicture }),
  };
};
