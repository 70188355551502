import {
  RestaurantShiftTimesOption,
  RestaurantShiftTimesOptionType,
  restaurantShiftTimesOptionTypes,
} from '../../restaurant-shifts/models/RestaurantShiftTimesOption';
import { RestaurantShiftTimesOptions } from '../../restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { addDays, isWithinInterval } from 'date-fns';
import { restaurantShiftDateRange } from '../../restaurant-shifts/models/RestaurantShiftDateRange';

export const reportShiftNameFromShiftType: { [key in RestaurantShiftTimesOptionType]: string } = {
  LUNCH: 'Lunch',
  DINNER: 'Dinner',
  ALL_SHIFTS: 'All Shifts',
} as const;

export type ReconciliationReportRestaurantShiftRequest = {
  name: string;
  startAt: Date;
  endAt: Date;
};

export const reconciliationReportRestaurantShiftRequest = (dayOfShift: Date, shift: RestaurantShiftTimesOption): ReconciliationReportRestaurantShiftRequest => {
  const { startAt, endAt } = restaurantShiftDateRange(shift, dayOfShift);
  return {
    name: reportShiftNameFromShiftType[shift.type],
    startAt,
    endAt,
  };
};

export const closestClosableRestaurantShift = (
  restaurantShiftTimesOptions: RestaurantShiftTimesOptions,
  now = new Date(),
): {
  reconciliationReportRequest: ReconciliationReportRestaurantShiftRequest,
  restaurantShiftTimesOptionType: RestaurantShiftTimesOptionType
} => {
  const yesterday = addDays(now, -1);

  const lunch = reconciliationReportRestaurantShiftRequest(now, restaurantShiftTimesOptions.LUNCH);
  const dinner = reconciliationReportRestaurantShiftRequest(now, restaurantShiftTimesOptions.DINNER);
  const yesterdayDinner = reconciliationReportRestaurantShiftRequest(yesterday, restaurantShiftTimesOptions.DINNER);
  const allShifts = reconciliationReportRestaurantShiftRequest(now, restaurantShiftTimesOptions.ALL_SHIFTS);

  const nowWithinLunch = isWithinInterval(now, { start: lunch.startAt, end: lunch.endAt });
  const nowWithinDinner = isWithinInterval(now, { start: dinner.startAt, end: dinner.endAt });
  const nowWithinYesterdayDinner = isWithinInterval(now, {
    start: yesterdayDinner.startAt,
    end: yesterdayDinner.endAt,
  });

  if (nowWithinYesterdayDinner) {
    return {
      reconciliationReportRequest: yesterdayDinner,
      restaurantShiftTimesOptionType: restaurantShiftTimesOptionTypes.DINNER,
    };
  }

  if (nowWithinLunch) {
    return {
      reconciliationReportRequest: lunch,
      restaurantShiftTimesOptionType: restaurantShiftTimesOptionTypes.LUNCH,
    };
  }

  if (nowWithinDinner) {
    return {
      reconciliationReportRequest: dinner,
      restaurantShiftTimesOptionType: restaurantShiftTimesOptionTypes.DINNER,
    };
  }

  return {
    reconciliationReportRequest: allShifts,
    restaurantShiftTimesOptionType: restaurantShiftTimesOptionTypes.ALL_SHIFTS,
  };
};
