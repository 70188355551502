import { queryKeys } from 'src/app/queries/utils';
import { useContext } from 'react';
import { StaticMenuContext } from '../../context/MenuContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { StaticMenuId, StaticMenuIdSchema } from '../../domain/StaticMenus';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useDeleteStaticMenu = () => {
  const queryClient = useQueryClient();
  const { menuRepository } = useContext(StaticMenuContext);
  const mutation = useMutation({
    mutationFn: ({ businessId, menuId }: { businessId: BusinessId; menuId: StaticMenuId }) =>
      menuRepository.deleteMenu(businessId, StaticMenuIdSchema.parse(menuId)),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.staticMenus.LIST_MENUS] }),
  });
  return {
    mutation,
    deleteStaticMenu: (businessId: BusinessId, menuId: StaticMenuId) => mutation.mutateAsync({ businessId, menuId }),
  };
};
