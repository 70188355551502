import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const Bic = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  return <TextField
    required
    error={!!errors.bankAccount?.bic}
    type={'text'}
    InputLabelProps={{ shrink: true }}
    {...register('bankAccount.bic', {
      required: 'Bic is requis',
      pattern: { value: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, message: 'Bic est invalide' },
      minLength: { value: 8, message: 'Bic invalide' },
      maxLength: { value: 11, message: 'Bic invalide' },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.bic' })}
    helperText={errors.bankAccount?.bic?.message || ''}
  />;
};
