// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MenuItemColumn } from './MenuItemColumn';
import { MenuLine, MenuTitle } from './MenuItem.styles';
import { isMobileScreen } from 'src/app/theme/ThemeV5';

export type MenuItemLastEditionProps = {
  lastEdition: number,
  visibility: boolean
};

const MenuItemLastEdition = ({ lastEdition, visibility }: MenuItemLastEditionProps) => {
  const intl = useIntl();

  const options = {
    year: 'numeric', month: 'long', day: 'numeric',
  } as Intl.DateTimeFormatOptions;

  return (
    <MenuLine isMobile={isMobileScreen()}>
      <MenuTitle>
        <FormattedMessage id="menus.table.header.edited" />
      </MenuTitle>
      <MenuItemColumn>
        { lastEdition && (
          <Text
            size="medium"
            color={visibility ? colorUsage.secondaryText : colorUsage.disabledText}
          >
            {new Date(lastEdition).toLocaleDateString(intl.locale, options)}
          </Text>
        )}
      </MenuItemColumn>
    </MenuLine>
  );
};
export default MenuItemLastEdition;
