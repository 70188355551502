import React, {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { InputText } from '@sundayapp/b2b-react-component-library';
import { debounce } from 'lodash';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  commitChange: Dispatch<SetStateAction<string>> | ((value: string) => void);
  /* props from InputText */
  id?: string;
  name?: string | undefined;
  label?: string | undefined;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean | undefined;
  placeholder?: string | undefined;
  subCaption?: string | undefined;
  error?: boolean | undefined;
  inputIcon?: 'search' | 'check' | 'cross' | undefined;
  touched?: boolean | undefined;
  maxLength?: number | undefined;
  type?: React.HTMLInputTypeAttribute | undefined;
  width?: string | undefined;
}

const DebouncedInputText: React.FC<InputProps> = ({
  commitChange, value, onBlur, ...props
}) => {
  const [displayedValue, setDisplayedValue] = useState<string>('');
  const debouncedUpdater = useMemo(() => debounce((val) => commitChange(val), 1000), [commitChange]);

  const onChangeHandler = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      setDisplayedValue(event.currentTarget.value);
      debouncedUpdater(event.currentTarget.value);
    },
    [debouncedUpdater],
  );

  useEffect(() => {
    setDisplayedValue(String(value ?? ''));
  }, [value]);

  return (
    <InputText
      {...props}
      value={displayedValue}
      onChange={onChangeHandler}
      onBlur={(e: ChangeEvent<HTMLInputElement>) => {
        commitChange(displayedValue);
        if (onBlur) onBlur(e);
      }}
    />
  );
};

export default DebouncedInputText;
