import { Time } from '../domain/Time';

const amOrPmRegex = /([ap]m)/i;
const pmRegex = /pm/i;
const removeEmptyString = (str: string) => str !== '';

/* eslint-disable complexity */
export const getTimePeriodFromString = (str: string): Time | undefined => {
  const res = str.split(amOrPmRegex).filter(removeEmptyString);
  if (res.length > 2) return;
  const [timeStr, amPmStr] = amOrPmRegex.test(res[0]) ? [res[1], res[1]] : res;
  const timeParts = timeStr.split(':');
  if (timeParts.length > 2) return;
  const hours = parseInt(timeParts[0], 10) + (pmRegex.test(amPmStr) ? 12 : 0);
  if (Number.isNaN(hours) || hours < 0 || hours > 23) return;
  if (timeParts.length > 1) {
    const minutes = parseInt(timeParts[1], 10);
    if (Number.isNaN(minutes) || minutes < 0 || minutes > 60) return;
    return { hours, minutes };
  }
  return { hours, minutes: 0 };
};
