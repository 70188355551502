import { Position } from './types';

export const readFile = (file: File): Promise<string> => new Promise<string>((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => {
    resolve(reader.result as string);
  };
  reader.onerror = reject;
  reader.readAsDataURL(file);
});

export const loadImage = (url: string): Promise<HTMLImageElement> => new Promise<HTMLImageElement>((resolve, reject) => {
  const img = new Image();
  img.setAttribute('crossorigin', 'anonymous');
  img.onload = () => resolve(img);
  img.onerror = (error) => reject(error);
  img.src = url;
});

export const loadFileAsImage = async (file: File) => {
  const url = await readFile(file);
  return loadImage(url);
};

interface RenderImageParams {
  backgroundColor?: string;
  offset: Position;
  dimensions: Position;
  zoom: number;
}

export const renderImage = (image: HTMLImageElement, imageData: RenderImageParams): Promise<string> => new Promise<string>((resolve, reject) => {
  window.requestAnimationFrame(() => {
    const {
      backgroundColor, offset, zoom, dimensions,
    } = imageData;
    const { x: imageWidth, y: imageHeight } = dimensions;
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    canvas.setAttribute('width', `${imageWidth}px`);
    canvas.setAttribute('height', `${imageHeight}px`);
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Current browser does not handle 2d canvas'));
    }
    const widthRatio = imageWidth / image.width;
    const heightRatio = imageHeight / image.height;
    const fitToImageRatio = Math.min(widthRatio, heightRatio);
    const scale = zoom * fitToImageRatio;

    const mustCenterHorizontally = widthRatio > heightRatio;
    const mustCenterVertically = widthRatio < heightRatio;
    const correctedOffsetX = mustCenterHorizontally
      ? offset.x + (zoom * (imageWidth - image.width * fitToImageRatio)) / 2
      : offset.x;
    const correctedOffsetY = mustCenterVertically
      ? offset.y + (zoom * (imageHeight - image.height * fitToImageRatio)) / 2
      : offset.y;

    ctx!.drawImage(image, correctedOffsetX, correctedOffsetY, scale * image.width, scale * image.height);
    if (backgroundColor) {
      ctx!.globalCompositeOperation = 'destination-over';
      ctx!.rect(0, 0, imageWidth, imageHeight);
      ctx!.fillStyle = backgroundColor;
      ctx!.fill();
    }
    resolve(canvas.toDataURL());
  });
});
