import { CircularProgress } from '@mui/material';
import React from 'react';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetVenueBoxes } from '../../box';
import { Box } from '../../box/domain/Box';
import { KdsBoxComponent } from '../components/KdsBoxComponent';
import { KdsOrderToBePaid } from '../components/KdsOrderToBePaid';
import { RedirectToAllOrderButton } from '../components/RedirectToAllOrderButton';

export const KdsPage = () => {
  const business = useCurrentBusinessOrThrow();

  const { isLoading, data: boxes } = useGetVenueBoxes(business.oapEnrollment?.id);

  if (!business.oapEnrollment) {
    return (<>Order and pay not enabled</>);
  }
  const enrollmentId = business.oapEnrollment.id;

  if (isLoading) {
    return <CircularProgress />;
  }

  if (boxes?.length === 0) {
    return (<>No box found</>);
  }

  const orderedBoxes = (boxes ?? [])
    .map((box) => ({ id: box.id, name: box.name, displayName: box.displayName } as Box))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div>
      <KdsOrderToBePaid enrollmentId={enrollmentId}/>
      {orderedBoxes.map((box) => <KdsBoxComponent key={box.id} enrollmentId={enrollmentId} box={box} displayTitle />)}
      <RedirectToAllOrderButton enrollmentId={enrollmentId} />
    </div>
  );
};
