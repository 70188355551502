import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { countries } from 'src/domain/countries';
import MenuItem from '@mui/material/MenuItem';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const RegistrationCountry = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  const business = useCurrentBusinessOrThrow();
  const { formState: { errors }, register, watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.controllingCompanies?.[representativeIndex]?.address?.country;
  const registrationCountry = watch(`controllingCompanies.${representativeIndex}.address.country`, business.address.countryCode);
  const intl = useIntl();
  return <TextField
    select
    fullWidth
    required
    defaultValue={registrationCountry}
    error={!!error}
    InputLabelProps={{ shrink: true }}
    id="registration-country-select"
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.registrationCountry' })}
    inputProps={register(`controllingCompanies.${representativeIndex}.address.country`,
      { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.controllingCompanies.registrationCountry.error' }) })}
    helperText={error?.message || ''}
  >
    {
      countries.map(({ name, code }) => <MenuItem key={code} value={code}>{name}</MenuItem>)
    }
  </TextField>;
};
