import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type AreaDetailsGroupProps = {
  title: string;
  children: ReactNode;
};

const GroupTitle = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: ${getSpacing(2)};
  color: ${colorPalette.grey500};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${getSpacing(2)};
`;

export const AreaDetailsGroup = ({ title, children }: AreaDetailsGroupProps) => (
  <Container>
    <GroupTitle>{title}</GroupTitle>
    {children}
  </Container>
);
