import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Alert, Stack, styled, TextField } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpUserRepository } from 'src/infrastructure/user/HttpUserRepository';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { parsePhoneNumber } from 'libphonenumber-js';
import { datadogLogs } from '@datadog/browser-logs';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { StepRef } from 'src/initial-setup/components/steps/StepRef';
import { StepProps } from 'src/initial-setup/components/steps/StepProps';

type CompleteSignUpInputs = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const PersonalInformationFormBody = styled('div')`
  padding: 24px;
  width: 100%;
  max-width: 576px;
  background: white;
  margin: 64px 0 24px;
`;

const PersonalInformationStep = forwardRef<StepRef, StepProps>((props, ref) => {
  const configuration = ConfigurationLoader.load();
  const intl = useIntl();
  const userRepository = new HttpUserRepository(configuration.merchantAccountsBaseUrl);
  const [signUpError, setSignUpError] = useState<string>('');

  const {
    control,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
    },
    setValue,
  } = useForm<CompleteSignUpInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  useEffect(() => {
    props.onValidChanged(isValid);
  }, [isValid]);

  useEffect(() => {
    userRepository.getMyProfile().then((u) => {
      setValue('firstName', u.firstName);
      setValue('lastName', u.lastName);
      setValue('phoneNumber', u.phoneNumber);
      props.onValidChanged(true);
    });
  }, []);

  const onSubmit: SubmitHandler<CompleteSignUpInputs> = async ({ firstName, lastName, phoneNumber }) => {
    setSignUpError('');
    try {
      await userRepository.completeUserAccount('', lastName, firstName, parsePhoneNumber(phoneNumber).number);
    } catch (error: any) {
      datadogLogs.logger.error('An error occurred while trying to sign in a venue owner', { error });
      setSignUpError(intl.formatMessage({ id: 'signin_callback.errors.other' }));
    }
  };

  useImperativeHandle(ref, () => ({
    submit: handleSubmit(onSubmit),
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack alignItems={'center'} spacing={2}>
        <PersonalInformationFormBody>
          <Stack spacing={2}>
            <TextField
              variant={'standard'}
              fullWidth
              label={intl.formatMessage({ id: 'signup.form.firstname' })}
              placeholder={intl.formatMessage({ id: 'signup.form.firstname.placeholder' })}
              id="firstName"
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
              InputLabelProps={{ shrink: true }}
              {...register('firstName', {
                required: intl.formatMessage({ id: 'signup.form.firstname.required' }),
                pattern: { value: /.*\S.*/, message: intl.formatMessage({ id: 'signup.form.firstname.required' }) },
              })}
            />
            <TextField
              variant={'standard'}
              fullWidth
              label={intl.formatMessage({ id: 'signup.form.lastname' })}
              placeholder={intl.formatMessage({ id: 'signup.form.lastname.placeholder' })}
              id="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
              InputLabelProps={{ shrink: true }}
              {...register('lastName', {
                required: intl.formatMessage({ id: 'signup.form.lastname.required' }),
                pattern: { value: /.*\S.*/, message: intl.formatMessage({ id: 'signup.form.lastname.required' }) },
              })}
            />
            <Controller
              control={control}
              name="phoneNumber"
              rules={{
                validate: (value) => {
                  if (!value) return false;
                  return matchIsValidTel(value);
                },
              }}
              render={({
                field,
                fieldState,
              }) => (
                <MuiTelInput
                  fullWidth
                  defaultCountry={'FR'}
                  variant={'standard'}
                  {...field}
                  label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.contact.phone' })}
                  helperText={fieldState.invalid ? intl.formatMessage({ id: 'settings.payments.checkout.onboarding.phone.error' }) : ''}
                  error={fieldState.invalid}
                />
              )}
            />
          </Stack>
        </PersonalInformationFormBody>
        {signUpError && (
          <Alert severity="error">
            {signUpError}
          </Alert>
        )}
      </Stack>
    </form>
  );
});

PersonalInformationStep.displayName = 'PersonalInformationStep';

export default PersonalInformationStep;
