import { styled } from '@mui/material';

export const ErrorPageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const RobotImg = styled('img')`
  margin-right: 40px;
`;
