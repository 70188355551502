/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const FlagSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (

  <svg width={size} height={size} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M2.80327 0.250002C2.59296 0.249977 2.38812 0.249953 2.21466 0.264125C2.02417 0.279689 1.79939 0.316412 1.5732 0.43166C1.2596 0.591449 1.00463 0.846417 0.844842 1.16002C0.743572 1.35877 0.702934 1.55644 0.684006 1.73071C0.637862 1.93318 0.663182 2.15433 0.663182 2.36002C0.663182 2.36253 0.663182 2.36504 0.663182 2.36755V16.5C0.663182 16.9602 1.03628 17.3333 1.49652 17.3333C1.95675 17.3333 2.32985 16.9602 2.32985 16.5L2.32985 9H6.91319L6.91318 10.1933C6.91316 10.4036 6.91314 10.6084 6.92731 10.7819C6.94287 10.9724 6.97959 11.1971 7.09484 11.4233C7.25463 11.7369 7.5096 11.9919 7.8232 12.1517C8.04939 12.2669 8.27417 12.3037 8.46466 12.3192C8.63813 12.3334 8.84296 12.3334 9.05329 12.3333L15.4963 12.3333C15.6444 12.3334 15.8067 12.3334 15.9442 12.3209C16.0905 12.3076 16.3294 12.2724 16.5578 12.1173C16.8357 11.9286 17.0259 11.6359 17.0855 11.3053C17.1345 11.0336 17.0696 10.801 17.0223 10.6619C16.978 10.5312 16.912 10.3829 16.8518 10.2476L15.7511 7.77094L16.7713 5.73058C16.8407 5.59181 16.9159 5.44154 16.9676 5.30905C17.0219 5.17013 17.1006 4.93258 17.0589 4.65064C17.0087 4.3105 16.8205 4.00608 16.5388 3.80902C16.3052 3.64568 16.0576 3.60981 15.9091 3.59622C15.7674 3.58325 15.5994 3.5833 15.4442 3.58334L11.0799 3.58334V2.3901C11.0799 2.17978 11.0799 1.97495 11.0657 1.80148C11.0502 1.61099 11.0134 1.38621 10.8982 1.16002C10.7384 0.846417 10.4834 0.591449 10.1698 0.43166C9.94365 0.316412 9.71887 0.279689 9.52838 0.264125C9.35491 0.249953 9.15009 0.249977 8.93978 0.250002L2.80327 0.250002Z"
          fill={color} />
  </svg>
);

export default FlagSvg;
