import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpTipsRepository } from 'src/tips/infrastructure/HttpTipsRepository';
import axios from 'axios';

const buildQueryKey = (userId: string) => [{ scope: 'staff_payout_method_details' }, userId] as const;

export const useStaffPayoutMethodDetails = (userId: string) =>
  useQuery({
    queryKey: buildQueryKey(userId),
    queryFn: () => {
      const configuration = ConfigurationLoader.load();
      const waiterPort = new HttpTipsRepository(axios, configuration.bookkeepingApiBaseUrl);
      return waiterPort.getStaffPayoutMethodDetails(userId);
    },
  });
