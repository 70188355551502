import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useState } from 'react';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { downloadBlob } from '../utils';
import { Level } from 'src/utils/logs/plugins';
import { Period } from '../../../revenues/domain/RevenuesDatePresets';
import { PayoutPort } from '../../domain/PayoutPort';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  period: Period;
  onError: () => void;
  payoutPort: PayoutPort;
}

export const DownloadPayoutDetails: React.FC<Props> = ({ period, onError, payoutPort }: Props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const businessId = useBusinessIdOrThrow();

  const log = useLog();
  const intl = useIntl();

  const downloadExport = () => {
    setInProgress(true);

    payoutPort
      .getPayoutsDetailsExport(businessId, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((blobWithMetadata) => {
        downloadBlob(blobWithMetadata, `${intl.formatMessage({ id: 'accounting.payout_tab.payouts_details_export_file' })}.csv`);
      })
      .catch((error) => {
        log(Level.ERROR, `Could not retrieve payouts details export for venue id ${businessId} (${period.filter})`, error);
        onError();
      })
      .finally(() => setInProgress(false));
  };

  useEffect(() => () => {
    setInProgress(false);
  }, []);

  return (
    <>
      <Button onClick={() => !inProgress && downloadExport()} data-testid="download-payouts-details-button"
              startIcon={<DownloadIcon />}>
        {inProgress ? (
          <CircularProgress color={'secondary'} />
        ) : (
          <FormattedMessage
            id="accounting.payout_tab.download_payouts_details_report"
            defaultMessage="payouts details"
          />
        )}
      </Button>
    </>
  );
};
