import { InputDatePicker } from '@sundayapp/b2b-react-component-library';
import styled, { css } from 'styled-components';
import DataGrid from '../../../common/components/DataGrid';
import {
  colorPalette, getSpacing, MOBILE_WIDTH_THRESHOLD, transitions, zIndex,
} from '../../../stylesheet';

export const OrdersListLayout = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
`;

interface Props {
  withSidePanelOpened?: boolean;
}

const SIDE_PANEL_WIDTH = 340;

export const OrdersListContainer = styled.div<Props>`
  flex: 1;
  transition: padding-right ${transitions.default};
  max-width: 100vw;
  ${({ withSidePanelOpened }) =>
    withSidePanelOpened
    && css`
      padding-right: ${SIDE_PANEL_WIDTH}px;
      @media (max-width: ${MOBILE_WIDTH_THRESHOLD}) {
        padding-right: 0;
      }
    `}
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.defaultBackgroundColor};
  border-radius: 24px;
  padding: ${getSpacing(2)};
  min-height: 400px;
  height: calc(100vh - 280px);
  min-width: 550px;
`;

export const StyledCustomScroll = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const OrdersListFooter = styled.div`
  flex: 1;
  border-right: 1px solid ${colorPalette.grey300};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${getSpacing(1.5)};
  border-right: 1px solid ${colorPalette.grey300};
  background-color: ${colorPalette.defaultBackgroundColor};
  margin-bottom: ${getSpacing(2)};
  border-radius: 24px;
  min-width: 550px;
`;

export const OrdersFilters = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: ${getSpacing(1.5)};
`;

export const StyledDatePicker = styled(InputDatePicker)`
  display: flex;
  align-self: stretch;
  min-width: 240px;
  height: 56px;
  margin: 0 0 ${getSpacing(0.5)} 0;
  background-color: ${colorPalette.defaultBackgroundColor};
`;

export const StyledOrdersDataGrid = styled(DataGrid)``;

export const OrderDetailsContainer = styled.aside<Props>`
  position: fixed;
  z-index: ${zIndex.MAIN_MENU};
  top: 0;
  padding: 0;
  right: 0;
  width: 0;
  max-width: 100%;
  height: 100%;
  background-color: ${colorPalette.defaultBackgroundColor};
  transition: width ${transitions.default};
  max-height: 100vh;
  overflow-y: auto;
  ${({ withSidePanelOpened }) =>
    withSidePanelOpened
    && css`
      width: ${SIDE_PANEL_WIDTH}px;
      max-width: 80%;
    `}
`;

export const SmsSentLabel = styled.div`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-top: ${getSpacing(0.5)};
`;

export const SmallDetailLabel = styled.div`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.03em;
  text-align: center;
  margin-top: ${getSpacing(0.5)};
`;
