import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../common/components/Button';
import Spinner from '../../../common/components/Spinner';
import { useCancelOrder } from '../../hooks';
import { Order, OrderStatus } from '../../types';
import { getSpacing, textStyles } from '../../../stylesheet';
import ConfirmationDrawer from '../../../common/components/ConfirmationDrawer';

interface Props {
  order: Order;
}

const CancelContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${getSpacing(1)} 0;
`;

const ConfirmCancellationContainer = styled.div`
  padding: ${getSpacing(3)} 0 ${getSpacing(4)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${textStyles.big}
`;

export const CancelOrderButton: React.FC<Props> = ({ order }) => {
  const [cancelState, cancelOrder] = useCancelOrder(order.id);
  const [displayConfirmDrawer, setDisplayConfirmDrawer] = useState(false);

  const toggleConfirmRefundDrawer = useCallback(
    () => setDisplayConfirmDrawer(!displayConfirmDrawer),
    [setDisplayConfirmDrawer, displayConfirmDrawer],
  );

  if (order.orderFromCentralTill && order.status !== OrderStatus.CANCELED && order.status !== OrderStatus.CANCEL) {
    if (cancelState.loading) { return <Spinner />; }
    return (
      <CancelContainer>
        <Button
          variant={ButtonVariant.PRIMARY}
          onClick={() => setDisplayConfirmDrawer(true)}
          data-testid="order-cancel-button"
          disabled={cancelState.loading}
        >
          <FormattedMessage id="orders.cancelOrder" defaultMessage="cancel order" />
        </Button>
        <ConfirmationDrawer visible={displayConfirmDrawer} onCancel={toggleConfirmRefundDrawer} onConfirm={cancelOrder}>
          <ConfirmCancellationContainer>
            <FormattedMessage id="orders.confirmOrderCancellation" defaultMessage="confirm order cancellation" />
          </ConfirmCancellationContainer>
        </ConfirmationDrawer>
      </CancelContainer>
    );
  }

  return null;
};

export default CancelOrderButton;
