import { z } from 'zod';
import { MoneySchema } from 'src/domain/money';

export const DispatchDetailsSchema = z.object({
  id: z.string(),
  triggeredBy: z.string(),
  dispatchedAt: z.coerce.date(),
  amount: MoneySchema,
  allocations: z.array(
    z.object({
      name: z.string(),
      phoneNumber: z.string(),
      amount: MoneySchema,
    }),
  ),
  startInclusive: z.coerce.date(),
  endExclusive: z.coerce.date(),
});

export type DispatchDetails = z.infer<typeof DispatchDetailsSchema>;
