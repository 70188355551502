import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { QUERYKEY_WAITER_REFERENTIAL } from '../queries/getWaiterReferentialQuery';
import { WaiterHttpPort } from '../infrastructure/WaiterHttpPort';
import { QUERYKEY_WAITER } from '../queries/getWaiterQuery';

export const useUpdateRateMutation = (businessId: string) => {
  const configuration = ConfigurationLoader.load();
  const queryClient = useQueryClient();
  const waiterPort = new WaiterHttpPort(configuration.bookkeepingApiBaseUrl);
  return useMutation({
    mutationFn: (updateRateDto: UpdateRateDto) =>
      waiterPort.updateRate(
        businessId,
        updateRateDto.waiterId,
        updateRateDto.rate,
        updateRateDto.enrollmentId,
      ),
    onSuccess: (waiterUpdated) => {
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_WAITER_REFERENTIAL, businessId] });
      queryClient.invalidateQueries({ queryKey: [QUERYKEY_WAITER, businessId, waiterUpdated.waiterId] });
    },
  });
};

export interface UpdateRateDto {
  waiterId: string,
  rate: number,
  enrollmentId: string
}
