import { Button, CheckSvg, DangerSvg, IconWrapper, Modal, Panel } from '@sundayapp/b2b-react-component-library';
import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { FormattedMessage, FormattedTime, useIntl } from 'src/app/i18n/TypedIntl';
import { Money } from '@sundayapp/web-money';
import { ReconciliationReport } from '../domain/ReconciliationReport';
import { ReportCommentEdition } from './ReportCommentEdition';
import { useReportSettings } from '../queries/ReportSettings';
import { deltaIsAcceptable } from './DeltaAcceptable';

const FloatingPanel = styled(Panel)`
  align-self: center;
  flex-direction: row;
  width: 100%;
  align-items:center;
  position: sticky;
  margin-bottom: 20px;
  color:white;
`;

interface CommentsPanelModalProps {
  submitAction: () => void,
  isOpened: boolean,
  onClose: () => void,
  report: ReconciliationReport,
  color: string,
  delta: Money,
}

export const FinishReportModal = ({
  submitAction,
  isOpened,
  onClose,
  report,
  color,
  delta,
}: CommentsPanelModalProps) => {
  const intl = useIntl();
  const { data: reportSettings } = useReportSettings();

  if (!reportSettings) {
    return <CircularProgress />;
  }

  const deltaAcceptable = deltaIsAcceptable(delta, reportSettings);
  const validated = deltaAcceptable || (report.comment?.length ?? 0) > 0;

  return (
    <Modal
      title={intl.formatMessage({ id: 'reconciliation.report.finish' })}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      onClose={onClose}
      isOpened={isOpened}
    >
      <FloatingPanel background={color}>
        <IconWrapper color="white">
          { deltaAcceptable ? <CheckSvg /> : <DangerSvg />}
        </IconWrapper>
        <Box sx={{ marginLeft: '15px' }}>
          <Typography variant="body1">
            {format(report.shift.dateOfService, 'yyyy-MM-dd')}
            {' '}
            -
            {' '}
            {report.shift.name}
          </Typography>
          <Typography variant="body2">
            {deltaAcceptable ? '' : (
              <FormattedTime
                value={report.lastModification}
                day="2-digit"
                month="2-digit"
                year="numeric"
                hour="2-digit"
                minute="2-digit"
                second="2-digit"
              />
            )}
          </Typography>

        </Box>
      </FloatingPanel>
      <ReportCommentEdition />
      <Button type="button" variant="primary" onClick={submitAction} fullWidth size="large" disabled={!validated}>
        <FormattedMessage id="modal.confirm" />
      </Button>
    </Modal>
  );
};
