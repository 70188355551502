import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { CashPayment, totalCashPayments } from '../domain/CashPayment';
import { CashPayments, PaymentData } from '../domain/PaymentData';
import { findAmountByOrigin } from '../domain/ReconciliationReport';
import { Method } from '../domain/Reconciliation';
import { PaymentOrigin } from '../domain/PaymentOrigin';
import { invalidateReport, updateReconciliationLineAmount } from './updateReconciliationLine';
import { useCurrentReportOrThrow } from '../hooks/useCurrentReportOrThrow';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useRemoveCashPaymentMutation = (method: Method) => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();
  const report = useCurrentReportOrThrow();

  return useMutation({
    mutationFn({ origin, cashPayment }: {
      origin: PaymentOrigin,
      cashPayment: CashPayment
    }) {
      const amount = findAmountByOrigin(report, method, origin);
      const newAmount = produce(amount, (draft: PaymentData) => {
        const metadata = draft.metadata as CashPayments;
        const updatedPayments = metadata.payments.filter((payment) => payment.reporter.id !== cashPayment.reporter.id);

        metadata.payments = updatedPayments;
        // eslint-disable-next-line no-param-reassign
        draft.amount = totalCashPayments(updatedPayments, business.currency);
      });

      return updateReconciliationLineAmount(business.id, report, method, origin, newAmount);
    },

    onSuccess: () => invalidateReport(queryClient, business.id, report),
  });
};
