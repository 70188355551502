import React from 'react';
import { useRenderMoney } from '@sundayapp/web-money';
import { Box, Grid, Stack, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { EMPTY, isGoogle, Review } from '../../../domain/Review';
import { ReviewBill } from '../ReviewBill';
import { isValidMoney } from 'src/utils/MoneyUtils';

interface Props {
  review: Review;
}

const OrderInfo = ({ content }: { content: string | undefined }) => (
  <Typography color="textSecondary" variant="caption" sx={{ display: 'inline-block' }}>
    {content}
  </Typography>
);

export const ReviewOrderInfo = ({ review }: Props) => {
  const renderMoney = useRenderMoney();
  const intl = useIntl();
  const TableName = () => (
    <>
      {review.tableName !== EMPTY
        && (
          <OrderInfo content={`${intl.formatMessage({
            id: 'venue.reviews.reply.table',
            defaultMessage: 'Table',
          })} ${review.tableName}`} />

        )}
    </>
  );

  const PaidAmount = () => (
    <>
      {isValidMoney(review.paidAmount)
        && (
          <OrderInfo content={`${renderMoney(review.paidAmount)} ${intl.formatMessage({
            id: 'venue.reviews.paid',
            defaultMessage: 'paid',
          })}`} />
        )}
    </>
  );

  const WaiterName = () => (
    <>
      {review.waiterName !== EMPTY
        && (
          <OrderInfo content={`${intl.formatMessage({
            id: 'venue.reviews.reply.waiter_name',
            defaultMessage: 'served by',
          })} ${review.waiterName}`} />
        )}
    </>
  );

  const AuthorName = () => (
    <>
      {isGoogle(review) &&
        (
          <Grid item>
            <Stack direction="row" spacing={1}>
              <Typography color="textSecondary">
                <FormattedMessage id="venue.reviews.posted_by" defaultMessage="Posted by" />
                {' '}
                {review.author.name}
              </Typography>
            </Stack>
          </Grid>
        )}
    </>
  );

  const DotSeparator = styled('div')`
    > * + *:before {
      content: '\\00a0•\\00a0'
    }
  `;
  const BillInfo = () => (
    <Grid item>
      <DotSeparator>
        <TableName />
        <PaidAmount />
        <WaiterName />
      </DotSeparator>
    </Grid>
  );

  return (
    <Box>
      <Grid container direction="row" gap="1rem" alignItems="center">
        <AuthorName />
        <BillInfo />
        <ReviewBill review={review} fontSize="12px" />
      </Grid>
    </Box>
  );
};
