import axios from 'axios';
import { SundayPoolingRepository } from 'src/tips/dispatch/model/SundayPoolingRepository';
import { BusinessId } from 'src/business/domain/Business';
import { DoneTipsDispatchZ, DoneTipsDispatchZArray } from 'src/tips/dispatch/model/DoneTipsDispatch';
import { TipsAllocations } from '../model/TipsAllocation';
import { Staff, StaffListZ } from 'src/tips/dispatch/model/Staff';
import { PendingTipsDispatchZArray } from 'src/tips/dispatch/model/PendingTipsDispatch';
import { DispatchDetails, DispatchDetailsSchema } from 'src/tips/dispatch/model/DispatchDetails';

export class SundayPoolingRepositoryHttp implements SundayPoolingRepository {
  constructor(private sundayPoolingUrl: string) {}

  getAllPendingTipsDispatch = (businessId: BusinessId) =>
    axios
      .get(`${this.sundayPoolingUrl}/sunday-pooling/${businessId}/tips-dispatch/pending`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = PendingTipsDispatchZArray.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      })
      .catch((error) => {
        throw Error(`fail getting pending tips dispatch for businessId=${businessId}, error=${error}`);
      });

  getAllDoneTipsDispatch = (businessId: BusinessId, from: Date, to: Date) =>
    axios
      .get(`${this.sundayPoolingUrl}/sunday-pooling/${businessId}/tips-dispatch/done`, {
        headers: {
          accept: 'application/json',
        },
        params: { from, to },
      })
      .then((response) => {
        const safeParse = DoneTipsDispatchZArray.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      })
      .catch((error) => {
        throw Error(`fail getting done tips dispatch for businessId=${businessId}, error=${error}`);
      });

  dispatchTips = (businessId: BusinessId, tipsDispatchId: string, allocations: TipsAllocations) =>
    axios
      .post(`${this.sundayPoolingUrl}/sunday-pooling/${businessId}/tips-dispatch/${tipsDispatchId}`, {
        allocations,
      })
      .then((response) => {
        const safeParse = DoneTipsDispatchZ.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      })
      .catch((error) => {
        throw Error(`fail dispatching tips for businessId=${businessId}, error=${error}`);
      });

  getAllStaff = (businessId: BusinessId): Promise<Staff[]> =>
    axios
      .get(`${this.sundayPoolingUrl}/sunday-pooling/${businessId}/staff`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = StaffListZ.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data.staff;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      })
      .catch((error) => {
        throw Error(`fail getting staff for businessId=${businessId}, error=${error}`);
      });

  getDispatchDetails = (businessId: BusinessId, dispatchId: string): Promise<DispatchDetails> =>
    axios
      .get(`${this.sundayPoolingUrl}/sunday-pooling/${businessId}/tips-dispatch/${dispatchId}`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        return DispatchDetailsSchema.parse(response.data);
      })
      .catch((error) => {
        throw Error(`fail getting tips dispatch for businessId=${businessId}, error=${error}`);
      });
}
