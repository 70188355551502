import { BusinessId } from 'src/business/domain/Business';
import { AiReplySettings } from './AiReplySettings';
import axios from 'axios';
import { AiReplySettingsDtoSchema } from './AiReplySettingsDto';

export class HttpReviewAiSettingsRepository {
  constructor(private venueFeedbackBaseUrl: string) {
  }

  async get(businessId: BusinessId): Promise<AiReplySettings | undefined> {
    const response = await axios.get(`${this.venueFeedbackBaseUrl}/reply-settings/businesses/${businessId}`);
    if (response.data === '') {
      return undefined;
    }
    return AiReplySettingsDtoSchema.parse(response.data);
  }

  async save(businessId: BusinessId, settings: AiReplySettings): Promise<void> {
    await axios.post(`${this.venueFeedbackBaseUrl}/reply-settings/businesses/${businessId}`, settings);
  }
}
