import React from 'react';
import { Modal } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SundayDevice } from '../../../domain/SundayDevice';
import { QrCodeInfo } from '../../../../common/components/qrCodes/QrCodeInfo';

type AreaDetailsTableModalProps = {
  tableName: string;
  device: SundayDevice;
  onClosed: () => void;
};

export const AreaDetailsTableModal = ({ tableName, device, onClosed }: AreaDetailsTableModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      maxWidth="600px"
      isOpened
      onClose={onClosed}
      title={intl.formatMessage(
        {
          id: 'settings.area.details.service-at-table.see-all-table.table.modal.title',
          defaultMessage: 'Table : {tableName}',
        },
        { tableName },
      )}
      cancelButtonMessage={intl.formatMessage({
        id: 'settings.area.details.service-at-table.see-all-table.table.modal.ok-btn',
        defaultMessage: 'ok',
      })}
    >
      <QrCodeInfo url={device.url} title="qr code" />
    </Modal>
  );
};
