import { localisedRevenueNames, RevenueDetails, RevenuesSummary } from './RevenueSummary';
import { revenueSources, revenueSourceTypes } from './RevenueSource';
import { translation, Translation } from '../../common/translation';
import { CurrencyCode, differenceMoney, money, Money, multiplyMoney, sumMoneys } from '@sundayapp/web-money';
import { Theme } from '@mui/material';


export type SundayPayoutSummaryBreakdownItem = {
  label: Translation,
  amount: Money,
  color: string,
  tooltip?: Translation,
};
const isZero = (m: Money) => m.amount === 0;

type CardsBreakdown = {
  totalGross: SundayPayoutSummaryBreakdownItem;
  salesNet: SundayPayoutSummaryBreakdownItem;
  tips: SundayPayoutSummaryBreakdownItem;
  fee?: SundayPayoutSummaryBreakdownItem;
  tax?: SundayPayoutSummaryBreakdownItem;
  vat?: SundayPayoutSummaryBreakdownItem;
  serviceCharge?: SundayPayoutSummaryBreakdownItem;
  refunds: SundayPayoutSummaryBreakdownItem;
  orphanRefunds?: SundayPayoutSummaryBreakdownItem;
  sundayFeesVatIncluded: SundayPayoutSummaryBreakdownItem;
};

const orUndefinedIfZero = (item: SundayPayoutSummaryBreakdownItem) => isZero(item.amount) ? undefined : item;

function cardsBreakdown(cards: RevenueDetails, theme: Theme): CardsBreakdown {

  const amounts = cards.revenueAmounts;
  const currency = amounts.salesWithSundayFees.currency;
  const salesNet = {
    amount: amounts.salesWithSundayFees.amount
      - amounts.totalAllCharges.fee.amount
      - amounts.totalAllCharges.tax.amount
      - amounts.totalAllCharges.vat.amount
      - amounts.totalAllCharges.serviceCharge.amount,
    currency,
  };

  return {
    totalGross: {
      label: translation('accounting.revenues.sunday_payout_summary.qr_codes.total_sales_with_sunday_fees_and_tips'),
      amount: amounts.totalSalesAndTips,
      color: theme.palette.text.primary,
    },
    salesNet: {
      label: translation('accounting.revenue_tab.panel.salesNet'),
      amount: salesNet,
      color: theme.palette.text.secondary,
    },
    vat: orUndefinedIfZero({
      label: translation('accounting.revenue_tab.panel.vat'),
      amount: amounts.totalAllCharges.vat,
      color: theme.palette.text.secondary,
    }),
    tips: {
      label: translation('accounting.revenue_tab.panel.tips'),
      amount: amounts.tips,
      color: theme.palette.text.secondary,
    },
    tax: orUndefinedIfZero({
      label: translation('accounting.revenue_tab.panel.tax'),
      amount: amounts.totalAllCharges.tax,
      color: theme.palette.text.secondary,
    }),
    fee: orUndefinedIfZero({
      label: translation('accounting.revenue_tab.panel.fee'),
      amount: amounts.totalAllCharges.fee,
      color: theme.palette.text.secondary,
    }),
    serviceCharge: orUndefinedIfZero({
      label: translation('accounting.revenue_tab.panel.autogratuity'),
      amount: amounts.totalAllCharges.serviceCharge,
      color: theme.palette.text.secondary,
    }),
    refunds: {
      label: translation('accounting.revenue_tab.panel.refunds'),
      amount: amounts.refunds.gross,
      color: theme.palette.text.secondary,
      tooltip: translation('accounting.revenue_tab.panel.refunds_tooltip'),
    },
    orphanRefunds: orUndefinedIfZero({
      label: translation('accounting.revenue_tab.panel.orphan_refunds'),
      amount: amounts.orphanRefunds.gross,
      color: theme.palette.text.secondary,
      tooltip: translation('accounting.revenue_tab.panel.orphan_refunds_tooltip'),
    }),
    sundayFeesVatIncluded: {
      label: translation('accounting.revenues.sunday_payout_summary.qr_codes.sundayFeesVatIncluded'),
      amount: multiplyMoney(amounts.sundayFeesVatIncluded, -1),
      color: theme.palette.text.primary,
    },
  };
}

type PaymentLinksBreakdown = {
  totalGross: SundayPayoutSummaryBreakdownItem;
  refunds: SundayPayoutSummaryBreakdownItem;
  orphanRefunds?: SundayPayoutSummaryBreakdownItem;
  sundayFeesVatIncluded: SundayPayoutSummaryBreakdownItem;
};

function paymentLinksBreakdown(paymentLinks: RevenueDetails, theme: Theme): PaymentLinksBreakdown {

  const amounts = paymentLinks.revenueAmounts;

  return {
    totalGross: {
      label: translation('accounting.revenue_tab.table.cell.payment_links'),
      amount: amounts.totalSalesAndTips,
      color: theme.palette.text.primary,
    },
    refunds: {
      label: translation('accounting.revenue_tab.panel.refunds'),
      amount: amounts.refunds.gross,
      color: theme.palette.text.secondary,
      tooltip: translation('accounting.revenue_tab.panel.refunds_tooltip'),
    },
    orphanRefunds: orUndefinedIfZero({
      label: translation('accounting.revenue_tab.panel.orhan_refunds'),
      amount: amounts.orphanRefunds.gross,
      color: theme.palette.text.secondary,
      tooltip: translation('accounting.revenue_tab.panel.orphan_refunds_tooltip'),
    }),
    sundayFeesVatIncluded: {
      label: translation('accounting.revenues.sunday_payout_summary.payment_link.sundayFeesVatIncluded'),
      amount: multiplyMoney(amounts.sundayFeesVatIncluded, -1),
      color: theme.palette.text.primary,
    },
  };
}


type OtherRevenuesBreakdown = {
  [key in string | 'totalGross']: SundayPayoutSummaryBreakdownItem;
};

function revenueUnitTranslation(revenueUnitName: string): Translation {
  const localisedName = localisedRevenueNames.find(v => v === revenueUnitName);
  return translation(`accounting.revenue_tab.panel.${localisedName!}`, {}, revenueUnitName);
}

function otherRevenuesBreakdown(otherRevenues: RevenueDetails, theme: Theme): OtherRevenuesBreakdown {

  const amounts = otherRevenues.revenueAmounts;

  const details = otherRevenues.revenueUnits.reduce((acc, revenueUnit) => ({
    ...acc,
    [revenueUnit.name]: {
      label: revenueUnitTranslation(revenueUnit.name),
      amount: revenueUnit.revenueAmounts.totalSalesAndTips,
      color: theme.palette.text.secondary,
    },
  }), {});

  return {
    totalGross: {
      label: translation('accounting.revenue_tab.panel.otherRevenues'),
      amount: amounts.totalSalesAndTips,
      color: theme.palette.text.primary,
    },
    ...details,
  };
}


export type SundayPayoutSummary = {
  cards: SundayPayoutSummaryBreakdownItem[],
  paymentLinks: SundayPayoutSummaryBreakdownItem[],
  otherRevenues: SundayPayoutSummaryBreakdownItem[],
  totalPaidOut: Money,
};

const values = (some: CardsBreakdown | PaymentLinksBreakdown | OtherRevenuesBreakdown | undefined) => some ? Object.values(some) : [];
const isRevenueDetails = (some: RevenueDetails | undefined): some is RevenueDetails => !!some;

export const sundayPayoutSummary = ({ revenues }: RevenuesSummary, businessCurrency: CurrencyCode, theme: Theme): SundayPayoutSummary => {
  const cardsRevenueDetails = revenues.find(revenue => revenue.source === revenueSources.QR_CODE)
    ?.revenuesDetails.find(revenueDetails => revenueDetails.source === revenueSourceTypes.CARDS);
  const cards = cardsRevenueDetails ? cardsBreakdown(cardsRevenueDetails, theme) : undefined;

  const paymentLinksRevenueDetails = revenues.find(revenue => revenue.source === revenueSources.OTHER)
    ?.revenuesDetails.find(revenueDetails => revenueDetails.source === revenueSourceTypes.PAYMENT_LINKS);
  const paymentLinks = paymentLinksRevenueDetails ? paymentLinksBreakdown(paymentLinksRevenueDetails, theme) : undefined;

  const otherRevenuesDetails = revenues.find(revenue => revenue.source === revenueSources.OTHER)
    ?.revenuesDetails.find(revenueDetails => revenueDetails.source === revenueSourceTypes.OTHER_REVENUES);
  const otherRevenues = otherRevenuesDetails ? otherRevenuesBreakdown(otherRevenuesDetails, theme) : undefined;

  return ({
    cards: values(cards).filter((i) => !!i),
    paymentLinks: values(paymentLinks).filter((i) => !!i),
    otherRevenues: values(otherRevenues).filter((i) => !!i),
    totalPaidOut: [ cardsRevenueDetails, paymentLinksRevenueDetails, otherRevenuesDetails ].filter(isRevenueDetails)
      .map((revenueDetails: RevenueDetails) => differenceMoney(revenueDetails.revenueAmounts.totalSalesAndTips, revenueDetails.revenueAmounts.sundayFeesVatIncluded))
      .reduce((acc, m) => sumMoneys(acc, m), money(0, businessCurrency)),
  });
};

