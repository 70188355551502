import React from 'react';
import { Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { Invoice, invoiceMonth, InvoicesPerYearMonth, invoiceYear } from 'src/accounting/invoices/domain/Invoice';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { BusinessId } from 'src/business/domain/Business';
import { useRenderMoney } from '@sundayapp/web-money';
import { SimpleTreeView, TreeItem2 } from '@mui/x-tree-view';
// eslint-disable-next-line no-restricted-imports
import { FormattedMessage, useIntl } from 'react-intl';
import { downloadInvoiceDocument, downloadInvoiceDocuments } from 'src/accounting/invoices/pages/downloadInvoiceDocument';
import { useConfiguration } from 'src/accounting/invoices/pages/useConfiguration';
import { formatYearMonth } from 'src/accounting/invoices/pages/formatDate';
import { FileAttachmentIcon } from 'src/ordering/common/components/Icons/FileAttachmentIcon';
import { FolderIcon } from 'src/ordering/common/components/Icons/FolderIcon';
import { DownloadInvoice } from 'src/accounting/invoices/pages/DownloadInvoice';

{/* eslint-disable react/prop-types */
}

export const Invoices = ({ businessId, invoicesPerYearMonth }: {
  businessId: BusinessId,
  invoicesPerYearMonth: InvoicesPerYearMonth[]
},
) => {
  const intl = useIntl();
  const configuration = useConfiguration();
  const renderMoney = useRenderMoney(true, false);

  const downloadInvoice = (invoice: Invoice) => {
    configuration.invoicePort.getInvoice(businessId, invoice.id)
      .then((blob) => downloadInvoiceDocument(blob, invoice.name));
  };

  const downloadInvoices = (invoices: InvoicesPerYearMonth) => {
    configuration.invoicePort.downloadAllInvoicesOfMonth(
      businessId,
      Number(invoiceYear(invoices)),
      Number(invoiceMonth(invoices)),
    )
      .then((blob) =>
        downloadInvoiceDocuments(
          blob,
          `${intl.formatMessage({ id: 'accounting.invoice_tab.invoice' })}s_${invoices.yearMonth}`,
        ));
  };

  const InvoicesHeader = () => {
    return (
      <Grid container paddingLeft={'12px'} paddingRight={'12px'}  paddingBottom={3} paddingTop={3} color={themeV5.palette.text.secondary}>
        <Grid item xs={6}>
          <Typography variant="subtitle1"><FormattedMessage
            id={'accounting.invoice_tab.year_month_header'} /></Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="subtitle1"><FormattedMessage
            id={'accounting.invoice_tab.total_amount_header'} /></Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'}>
          <Typography variant="subtitle1">
            <FormattedMessage id={'accounting.invoice_tab.action_header'} />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const SummaryLabel = ({ invoicePerYearMonth }: { invoicePerYearMonth: InvoicesPerYearMonth }) => {
    return (
      <Grid container paddingLeft={'12px'} paddingBottom={1} paddingTop={1}>
        <Grid item xs={6} alignContent={'center'}>
          <Stack direction={'row'} alignItems={'center'} gap={1}>
            <FolderIcon size={20} />
            <Typography variant={'body1'}>{formatYearMonth(invoicePerYearMonth.yearMonth, intl.locale)}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} textAlign={'right'} alignContent={'center'}>
          <Typography variant={'body1'}>{renderMoney(invoicePerYearMonth.summary.totalAmount)}</Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'} alignContent={'center'}>
          <DownloadInvoice onClick={() => downloadInvoices(invoicePerYearMonth)} />
        </Grid>
      </Grid>
    );
  };

  const InvoicesLabel = ({ invoice }: { invoice: Invoice }) => {
    return (
      <Grid container paddingTop={1} paddingBottom={1}>
        <Grid item xs={6} alignContent={'center'}>
          <Stack direction={'row'} alignItems={'center'} gap={0.5} paddingLeft={3}>
            <FileAttachmentIcon size={20} />
            <Typography noWrap variant={'body1'}>{invoice.name}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3} textAlign={'right'} alignContent={'center'}>
          <Typography variant={'body1'}>{renderMoney(invoice.totalAmount)}</Typography>
        </Grid>
        <Grid item xs={3} textAlign={'right'} alignContent={'center'}>
          <DownloadInvoice onClick={() => downloadInvoice(invoice)} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper sx={{ borderRadius: '0.8rem' }}>
      <InvoicesHeader />
      <Divider />
      <SimpleTreeView>
        {invoicesPerYearMonth.map((invoicePerYearMonth) => {
          return (
            <Stack key={`stack-${invoicePerYearMonth.yearMonth}`}>
              <TreeItem2
                key={`tree-item-${invoicePerYearMonth.yearMonth}`}
                itemId={invoicePerYearMonth.yearMonth}
                slots={{ label: SummaryLabel }}
                slotProps={{ label: { invoicePerYearMonth: invoicePerYearMonth } as any }}
              >
                {invoicePerYearMonth.invoices.map((invoice) => {
                  return (
                    <Stack key={`stack-${invoice.id}`}>
                      <Stack paddingLeft={3}><Divider /></Stack>
                      <TreeItem2
                        key={`tree-item-${invoice.id}`}
                        itemId={invoice.id}
                        slots={{ label: InvoicesLabel }}
                        slotProps={{ label: { invoice: invoice } as any }} />
                    </Stack>
                  );
                })}
              </TreeItem2>
              <Divider />
            </Stack>
          );
        })}
      </SimpleTreeView>
    </Paper>
  );
};
