import { emptyStarsDistributionChartData, StarsDistributionChartData } from '../EReputationViewModel';
import { datesAreEqual, last12Months, last4Weeks, lastWeekDays, weeksAreEqual } from '../DatesManipulation';

import { FOUR_WEEKS, KpiTimeframeName, SEVEN_DAYS, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

const filterInInterval = (fromSunday: StarsDistributionChartData[], subscriptionDate: Date, interval: Date[], compareFunction: (a: Date, b: Date) => void): StarsDistributionChartData[] => {
  if (subscriptionDate.getTime() < interval[0].getTime()) {
    return [...fromSunday];
  }

  if (subscriptionDate.getTime() > interval[interval.length - 1].getTime()) {
    return fromSunday.map(() => emptyStarsDistributionChartData);
  }

  const matchedMonth = interval.find((d) => compareFunction(d, subscriptionDate));
  const subscriptionIndex = interval.indexOf(matchedMonth!);

  const before = [...fromSunday].slice(0, subscriptionIndex)
    .map(() => emptyStarsDistributionChartData);
  const after = [...fromSunday].slice(subscriptionIndex);

  return [...before, ...after];
};

export const filterSundayStarsDistributionBySubscriptionDateForLastYear = (
  fromSunday: StarsDistributionChartData[], subscriptionDate: Date,
): StarsDistributionChartData[] => {
  const firstDayOfMonth = new Date(subscriptionDate.getFullYear(), subscriptionDate.getMonth(), 1);

  return filterInInterval(fromSunday, firstDayOfMonth, last12Months()
    .reverse(), datesAreEqual);
};

export const filterSundayStarsDistributionBySubscriptionDateForLastMonth = (
  fromSunday: StarsDistributionChartData[], subscriptionDate: Date,
): StarsDistributionChartData[] => filterInInterval(fromSunday, subscriptionDate, last4Weeks()
  .reverse(), weeksAreEqual);

export const filterSundayStarsDistributionBySubscriptionDateForLastWeek = (
  fromSunday: StarsDistributionChartData[], subscriptionDate: Date,
): StarsDistributionChartData[] => filterInInterval(fromSunday, subscriptionDate, lastWeekDays()
  .reverse(), datesAreEqual);

export const filterSundayStarsDistributionBySubscriptionDate = (
  fromSunday: StarsDistributionChartData[],
  subscriptionDate: Date,
  timeframe: KpiTimeframeName,
): StarsDistributionChartData[] => {
  if (timeframe === SEVEN_DAYS.name()) return filterSundayStarsDistributionBySubscriptionDateForLastWeek(fromSunday, subscriptionDate);
  if (timeframe === FOUR_WEEKS.name()) return filterSundayStarsDistributionBySubscriptionDateForLastMonth(fromSunday, subscriptionDate);
  if (timeframe === TWELVE_MONTHS.name()) return filterSundayStarsDistributionBySubscriptionDateForLastYear(fromSunday, subscriptionDate);

  throw new Error('Please specify a timeframe');
};
