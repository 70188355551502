import { Typography, useTheme } from '@mui/material';
import React from 'react';
import GoogleSvg from '../../../../../app/component/icons/GoogleSvg';

type Props = {
  title: string;
};
export const PanelTitle = ({ title }: Props) => {
  const theme = useTheme();
  return (
    <div style={{
      display: 'flex',
      alignItems: 'end',
      gap: '0.4rem',
      flexDirection: 'row',
      marginTop: '0.5rem',
    }}
    >
      <GoogleSvg size={24} />
      <Typography variant="subtitle2" color={theme.palette.text.secondary}>
        {title}
      </Typography>
    </div>
  );
};
