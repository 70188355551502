import * as React from 'react';
import { CircularProgress } from '@mui/material';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGetEndOfServiceConfiguration } from '../queries/getEndOfServiceConfigurationQuery';
import { EndOfService } from './EndOfService';


export const EndOfServicePage = () => {
  const business = useCurrentBusinessOrThrow();
  const { data: eosConfiguration, isLoading } = useGetEndOfServiceConfiguration(business);

  if (isLoading) return <CircularProgress />;

  return <EndOfService eosConfiguration={eosConfiguration!} business={business} />;
};
