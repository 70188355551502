import * as React from 'react';
import { Box } from '@mui/material';

type TabPanelProps = {
  children?: React.ReactNode;
  index: string;
  value: string;
};

function TabPanel(props: TabPanelProps) {
  const { children = null, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 3,
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

export default TabPanel;
