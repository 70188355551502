import { Money } from '@sundayapp/web-money';
import { v4 } from 'uuid';
import { Payment } from 'src/payments/domain/Payment';

export interface RefundRequest {
  idempotencyKey: string;
  description?: string;
  refundAmount: Money;
}

export class RefundRequestFactory {
  static build(payment: Payment, amount: number, description?: string): RefundRequestFactory {
    return {
      idempotencyKey: v4(),
      description,
      refundAmount: {
        amount,
        currency: payment.totalPaidByCustomer.currency,
      },
    };
  }
}
