/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const InfoIcon: React.FC<IconProps> = ({ size = 16, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16ZM7.2 4.8C7.2 4.35817 7.55817 4 8 4C8.44183 4 8.8 4.35817 8.8 4.8C8.8 5.24183 8.44183 5.6 8 5.6C7.55817 5.6 7.2 5.24183 7.2 4.8ZM7.2 8C7.2 7.55817 7.55817 7.2 8 7.2C8.44183 7.2 8.8 7.55817 8.8 8V11.2C8.8 11.6418 8.44183 12 8 12C7.55817 12 7.2 11.6418 7.2 11.2L7.2 8Z"
      fill={color}
    />
  </svg>
);
