import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BoxDetails } from '../domain/BoxDetails';
import { usePushNotification } from '../../common/components/Notifications.hook';
import { useUpdateBoxDetails } from '../hooks';
import { useFileStorage } from '../../common/hooks';
import { Container, SettingPanelContent, Title } from '../../common/components/Settings/SettingPanel';
import { PicturePicker } from '../../common/components/PicturePicker/PicturePicker';
import { BOX_PICTURE_HEIGHT, BOX_PICTURE_WIDTH } from '../../common/components/PicturePicker/constants';

type BoxPictureSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const BoxPictureSetting = ({ box, setBox }: BoxPictureSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [updating, makeUpdating] = useState<boolean>(false);

  const fileStorage = useFileStorage();

  const setBoxImage = useCallback(
    async (base64Image: string) => {
      makeUpdating(true);
      const imageUrl = await fileStorage.saveImageAsPNG(`ordering/${box.id}`, `${uuidv4()}.png`, base64Image);
      const updatedBox = {
        ...box,
        imageUrl,
      };
      await updateBoxDetails(updatedBox);
      setBox(updatedBox);
      pushNotification(intl.formatMessage({ id: 'box.settings.updated', defaultMessage: 'box settings updated' }));

      makeUpdating(false);
    },
    [fileStorage, intl, pushNotification, box, setBox, updateBoxDetails, makeUpdating],
  );

  return (
    <Container>
      <Title>
        <FormattedMessage id="box.settings.picture.title" defaultMessage="picture" />
      </Title>
      <SettingPanelContent>
        <PicturePicker
          imageUrl={box.imageUrl}
          onImageChanged={setBoxImage}
          updating={updating}
          width={BOX_PICTURE_WIDTH}
          height={BOX_PICTURE_HEIGHT}
        />
      </SettingPanelContent>
    </Container>
  );
};
