import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React, { useCallback, useState } from 'react';
import { BoxDetails } from '../../domain/BoxDetails';
import { borderRadius, colorPalette, getSpacing } from '../../../stylesheet';
import { usePushNotification } from '../../../common/components/Notifications.hook';
import { useUpdateBoxDetails } from '../../hooks';
import { SettingLine } from '../../../common/components/Settings/SettingLine';
import Input from '../../../common/components/Input';
import { BoxTag } from './BoxTag';

type BoxTagsProps = {
  box: BoxDetails;
  setBox: (venue: BoxDetails) => void;
};

const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${getSpacing(2)};
`;

const TagsInput = styled.div`
  line-height: 40px;
  border: 1px solid ${colorPalette.lightBorderColor};
  border-radius: ${borderRadius.default};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

const StyledInput = styled(Input)`
  border: 0;
`;

const TextAreaDescription = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
`;

const tagStringToArray = (tags: string): string[] => tags
  .split('§§§')
  .map((t) => t.trim())
  .filter((t) => t !== '');

const tagArrayToString = (tags: string[]): string => tags.join('§§§');

export const BoxTags = ({ box, setBox }: BoxTagsProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>(tagStringToArray(box.tags ?? ''));
  const [newTag, setNewTag] = useState<string>('');

  const updateBoxSettings = useCallback(
    async (tagsToUpdate: string[]) => {
      makeUpdating(true);
      const tagString = tagArrayToString(tagsToUpdate);
      await updateBoxDetails({
        ...{ tags: tagString },
      });
      setBox({
        ...box,
        ...{ tags: tagString },
      });
      pushNotification(intl.formatMessage({ id: 'box.settings.updated', defaultMessage: 'box settings updated' }));
      makeUpdating(false);
    },
    [box, intl, pushNotification, setBox, updateBoxDetails],
  );

  const removeTag = useCallback(
    (tag: string) => {
      const newTags = tags.filter((t) => t !== tag);
      updateBoxSettings(newTags).then(() => {
        setTags(newTags);
      });
    },
    [tags, updateBoxSettings],
  );

  const addTag = useCallback(
    (tag: string) => {
      const newTags = [...new Set(tags.concat(tag))];
      updateBoxSettings(newTags).then(() => {
        setTags(newTags);
      });
    },
    [tags, updateBoxSettings],
  );

  const updateNewTag = (value: string) => {
    const separator = value.indexOf(',');
    if (separator !== -1) {
      const tag = value.substring(0, separator);
      addTag(tag);
      setNewTag(value.substring(separator + 1)); // +1 to remove also the separator
    } else {
      setNewTag(value);
    }
  };

  return (
    <SettingLine
      title={intl.formatMessage({
        id: 'box.settings.app-venue.tags.title',
        defaultMessage: 'tags',
      })}
      description={intl.formatMessage({
        id: 'box.settings.app-venue.tags.description',
        defaultMessage: 'the tags will be displayed below the box name on the landing page',
      })}
      direction="column"
    >
      <TextAreaWrapper>
        <TagsInput>
          {tags.map((t) => (
            <BoxTag key={t} tag={t} removeTag={(tag) => removeTag(tag)} />
          ))}
          <StyledInput
            value={newTag}
            onValueChange={updateNewTag}
            disabled={isUpdating}
            placeholder={intl.formatMessage({
              id: 'box.settings.tagPlaceholder',
              defaultMessage: 'tag...',
            })}
          />
        </TagsInput>
        <TextAreaDescription>
          <FormattedMessage id="box.settings.app-venue.tags.help" defaultMessage="enter a comma after your tag" />
        </TextAreaDescription>
      </TextAreaWrapper>
    </SettingLine>
  );
};
