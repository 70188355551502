import { FormControl, Stack, Typography } from '@mui/material';
import { PaymentMethodsProps } from './types';
import { getLabel } from './utils';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { paymentMethodNameLocalisations } from 'src/pages/Settings/PaymentMethodMapping/PaymentMethodsForm';

export const ReadOnlyPaymentMethods = ({ paymentMethods }: { paymentMethods: PaymentMethodsProps[] }) => (
  <>
    {paymentMethods.map((paymentMethod: PaymentMethodsProps, index: number) =>
      (
        <Stack display="flex" direction="row" gap={4} padding={2} key={`stack-container-readonly-${index}`}>
          <FormControl fullWidth>
            <Typography aria-labelledby="readonly-sunday-code">
              <FormattedMessage
                id={paymentMethodNameLocalisations[paymentMethod.sundayCode] ?? 'settings.posPaymentMethod.method.undefined'}
                defaultMessage={paymentMethod.sundayCode} />
              </Typography>
          </FormControl>
          <FormControl fullWidth>
            <Typography aria-labelledby="readonly-primaryValue">
              {getLabel(paymentMethod.preferredValue, paymentMethod.allowedValues)}
            </Typography>
          </FormControl>
          <FormControl fullWidth />
        </Stack>
      ))}
  </>
);
