import React from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import { styled, Tooltip } from '@mui/material';
import VoucherTooltipContent from './VoucherTooltipContent';
import PaymentAmount from './PaymentAmount';
import { Payment } from '../domain/Payment';

interface Props {
  payment: Payment;
}
export const PaymentTotal = styled('div')`
  white-space: nowrap;
`;
export const SpanVoucher = styled('span')`
  width: 16px;
  height: 16px;
  padding: 0 0 0 5px;
`;
const PaymentLocalOfferIcon = styled(LocalOfferIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const TotalPaidAmount: React.FC<Props> = ({ payment }: Props) => {
  if (!payment.voucher) return <PaymentAmount amount={payment.computeTotal()} />;

  return (
    <Tooltip
      placement={'left'}
      title={(
        <VoucherTooltipContent
          customerTotalPaid={payment.totalPaidByCustomer}
          voucherAmount={payment.voucher.amount}
        />
      )}
    >
      <PaymentTotal>
        <PaymentAmount amount={payment.computeTotal()} />
        <SpanVoucher>
          <PaymentLocalOfferIcon fontSize="inherit" />
        </SpanVoucher>
      </PaymentTotal>
    </Tooltip>
  );
};

export default TotalPaidAmount;
