import { HOME_STATUS, HomeStatsButtonType } from '@constants/HomeConstants';
import { StatsCard } from '@molecules/Card/StatsCard';
import { ChevronRight } from '@mui/icons-material';
import { Button, useTheme } from '@mui/material';
import { StatusCard } from '@organisms/Body/StatusCard/StatusCard';
import { AverageRatingsChart } from 'src/home2/features/home-page/components/Sections/Reviews/ReviewsCharts/Charts/AverageRatingsChart';
import { useState } from 'react';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useGoogleSetupStage } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStage';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useGetAverageRatingsContent } from '../hooks/useGetAverageRatingsContent';
import { HomeSectionProps } from 'src/home2/features/home-page/containers/HomePage/HomeContent';

export const ReviewsCharts = ({ selectedTimeFrame, venueKpi }: HomeSectionProps) => {
  const business = useCurrentBusinessOrThrow();
  const theme = useTheme();
  const { data: googleSetupStatus, isLoading: IS_LOADING, error: IS_ERROR } = useGoogleSetupStage(business.id);
  const [statToDisplay, setStatToDisplay] = useState<string>(HomeStatsButtonType.GOOGLE_RATING);

  const state = IS_LOADING ? 'IS_LOADING' : (IS_ERROR ? 'IS_ERROR' : '');

  const statusData = {
    button: HOME_STATUS[state]?.BUTTON,
    media: HOME_STATUS[state]?.MEDIA,
    subtitles: HOME_STATUS[state]?.SUBTITLES,
    title: HOME_STATUS[state]?.TITLE,
  };

  const { averageRating, averageRatingLastWeek, adoptionRate } = venueKpi;

  const { labels: averageRatingsDataTypes, subItems, trend } = useGetAverageRatingsContent({ averageRating, averageRatingLastWeek, adoptionRate });

  return (
    <>
      {!googleSetupStatus && (state === 'IS_LOADING' || state === 'IS_ERROR') && <StatusCard {...statusData} />}
      {googleSetupStatus && (
        <StatsCard
          actions={
            {
              toggleButtons: [
                {
                  id: 'venue.home.reviews.google_rating.title',
                  value: HomeStatsButtonType.GOOGLE_RATING,
                },
                {
                  id: 'venue.home.reviews.google_reviews.title',
                  value: HomeStatsButtonType.GOOGLE_REVIEW,
                },
              ],
              toggleSettings: {
                selectedOption: statToDisplay,
                setSelectedOption: setStatToDisplay,
              },
            }
          }
          chart={
            statToDisplay === HomeStatsButtonType.GOOGLE_RATING ?
              {
                element: <AverageRatingsChart selectedTimeFrame={selectedTimeFrame}  />,
                labels: averageRatingsDataTypes,
              }
              : {
                element: <></>,
                labels: {},
              }}
              content={{
                trend,
                subItems,
              }}
          footer={
            <Button
              variant="outlined"
              sx={{
                border: `1px solid ${theme.palette.grey[300]}`,
                padding: '8px 12px',
              }}
              endIcon={<ChevronRight />}
            >
              <FormattedMessage id='venue.home.reviews.button' />
            </Button>
          }
        />
      )}
    </>
  );
};
