import { Money, MoneyView } from '@sundayapp/web-money';
import React from 'react';
import AccountBox from '@mui/icons-material/AccountBox';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { styled } from '@mui/material';
import SundayIcon from '../../app/component/icons/SundayIcon';

interface Props {
  customerTotalPaid: Money;
  voucherAmount: Money;
}

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 2px;
  font-size: 14px;
`;

export const Title = styled('div')`
  text-align: center;
  width: 100%;
  padding: 4px 6px 6px 6px;
  border-bottom: 1px solid white;
`;

export const Detail = styled('div')`

  align-items: center;
  padding: 6px 0 6px 0;
  display: inherit;
  flex-direction: row;
  justify-content: space-between;
`;

export const VoucherSundayIcon = styled(SundayIcon)`
  margin-right: 5px;
`;

export const VoucherAccountBox = styled(AccountBox)`
  margin-right: 5px;
`;

export const VoucherMoneyView = styled(MoneyView)`
  margin-left: 5px;
  flex-grow: 1;
  text-align: right;
`;

const VoucherTooltipContent: React.FC<Props> = ({ customerTotalPaid, voucherAmount }: Props) => (
  <Container>
    <Title>
      <FormattedMessage id="payments.voucher.discount" />
    </Title>
    <Detail>
      <VoucherAccountBox fontSize="small" />
      <FormattedMessage id="payments.voucher.customer_paid" />
      <VoucherMoneyView value={customerTotalPaid} />
    </Detail>

    <Detail>
      <VoucherSundayIcon size={20} />
      <FormattedMessage id="payments.voucher.sunday_paid" />
      <VoucherMoneyView value={voucherAmount} />
    </Detail>
  </Container>
);

export default VoucherTooltipContent;
