import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SearchOutlined } from '@mui/icons-material';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { ProductSummary } from '../../domain/ProductSummary';
import { NoProductOverlay } from '../ProductPage/components/NoProductOverlay';
import { useGetAllProductsByBusinessId } from '../../queries/product/getAllProductByBusinessIdQuery';
import { buildProductSelectionColumns } from './buildProductSelectionColumns';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { productMatch } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/productMatch';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useMenuAssetsSources } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';

const Footer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 100%;
`;

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export type Props = {
  title: string;
  productIds: string[];
  maxSelectionCount?: number;
  onChange: (productIds: string[]) => void;
  onProductsSelected?: (products: ProductSummary[]) => void;
  customFilter?: (product: ProductSummary) => boolean;
  visible: boolean;
  onClose: () => void;
};


type SelectionProps = {
  filter: string;
  products: ProductSummary[];
  values: ProductSummary['id'][];
  onChange: (products: ProductSummary[]) => void;
  maxSelectionCount: number;
};

const ProductTableSelection: FC<SelectionProps> = ({ filter, products, values, onChange, maxSelectionCount }) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const menuAssetsSources = useMenuAssetsSources();

  const initiallySelectedProducts = products
    .filter((p) => values.includes(p.id))
    // keep initial order because menu products are ordered
    .toSorted((a, b) => values.indexOf(a.id) - values.indexOf(b.id));

  const [selectedProducts, setSelectedProducts] = useState<ProductSummary[]>(initiallySelectedProducts);

  const selectedProductIds = selectedProducts.map((p) => p.id);
  const isSelected = (productId: string) => selectedProductIds.includes(productId);

  const filteredProducts = products.filter((p) => productMatch(filter, p));

  const onClickAddRemove = (product: ProductSummary) => {
    if (selectedProductIds.includes(product.id)) {
      setSelectedProducts((actual) => actual.filter((p) => p.id !== product.id));
    } else if (maxSelectionCount > 0 && selectedProductIds.length >= maxSelectionCount) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage(
          {
            id: 'menus.product.selection.max_count',
            defaultMessage: "You can't select more than {count} products",
          },
          { count: maxSelectionCount },
        ),
      });
    } else {
      setSelectedProducts((actual) => actual.concat(product));
    }
  };


  const onConfirm = () => {
    onChange(selectedProducts);
  };

  return (<>
    <DialogContent sx={{ padding: '0 24px' }}>
      <DataGrid
        rows={filteredProducts}
        columns={buildProductSelectionColumns(intl, isSelected, menuAssetsSources)}
        rowHeight={50}
        autoHeight
        sx={{
          boxShadow: 'unset',
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        columnHeaderHeight={0}
        disableRowSelectionOnClick
        getRowId={(row) => row.id}
        onRowClick={(param) => onClickAddRemove(param.row)}
        pageSizeOptions={[10]}
        slots={{
          noRowsOverlay: NoProductOverlay,
        }}
      />
    </DialogContent>
    <DialogActions>
      <Footer>
        <Typography fontSize="large">
          <FormattedMessage
            id="menus.product.selection.summary"
            values={{ count: selectedProductIds.length.toString() }}
            defaultMessage="You have selected 1 product"
          />
        </Typography>
        <Button variant="contained" onClick={onConfirm}>
          <FormattedMessage id="menus.product.selection.confirm" defaultMessage="Confirm selection" />
        </Button>
      </Footer>
    </DialogActions>
  </>);
};


export const ProductsSelectionModal = ({
  title,
  productIds,
  maxSelectionCount = 0,
  onChange,
  onProductsSelected = () => {
  },
  customFilter = () => true,
  visible,
  onClose,
}: Props) => {
  const business = useCurrentBusinessOrThrow();
  const intl = useIntl();

  const [filter, setFilter] = useState<string>('');
  const { data: products, isLoading } = useGetAllProductsByBusinessId(business.id);

  const filteredProducts = (products || []).filter(customFilter);

  const onModalClose = () => {
    onClose();
  };

  if (!visible) {
    return <></>;
  }

  const onProductSelection = (selectedProducts: ProductSummary[]) => {
    onProductsSelected(selectedProducts);
    onChange(selectedProducts.map(p => p.id));
  };

  return (
    <Dialog fullWidth maxWidth="lg" open onClose={onModalClose} scroll="paper">
      <DialogTitle>
        <Header>
          <Typography fontSize="large" pt={2} pb={2}>
            {title}
          </Typography>
          <IconButton aria-label="close" onClick={onModalClose} sx={{ height: 'fit-content' }}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Header>
        <TextField
          variant="outlined"
          size="small"
          style={{ width: '100%', margin: '8px 0' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          placeholder={intl.formatMessage({ id: 'menus.products.search.placeholder', defaultMessage: 'search' })}
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
        />
      </DialogTitle>
      {isLoading
        ? <CircularProgress />
        : <ProductTableSelection
          filter={filter}
          products={filteredProducts}
          values={productIds}
          onChange={onProductSelection}
          maxSelectionCount={maxSelectionCount}
        />}
    </Dialog>
  );
};

