import React from 'react';
import { InvoicesWithContext } from './InvoicesWithContext';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { NoPos } from 'src/pages/NoPos/NoPos';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const InvoicesTab = () => {
  const business = useCurrentBusinessOrThrow();
  const venueIsNoPos = useBusinessIsNoPos();
  if (venueIsNoPos) return <NoPos />;

  return (
    <>
      <InvoicesWithContext businessId={business.id} />
    </>
  );
};
