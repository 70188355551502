import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import { NavigationMenu } from 'src/app/navigation/navigationMenu/NavigationMenu';
import { MainLayoutContainer } from 'src/app/layouts/MainLayout';
import { ServiceType } from 'src/domain/venue/ServiceType';
import AccountingSvg from 'src/app/component/icons/AccountingSvg';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import AccountingPlainSvg from 'src/app/component/icons/AccountingPlainSvg';
import { userRoles } from 'src/domain/user/UserRole';
import { InfoAccounting } from 'src/accounting/InfoAccounting';
import { Outlet } from 'react-router';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useNavigate } from 'react-router';
import { tipsStaffRulesPath, venueHomePath } from 'src/app/navigation/pathHelpers';
import { AuthenticatedUser, relationTypes } from 'src/auth/domain/user';
import { NavigationItem } from 'src/app/navigation/navigationMenu/NavigationItems';
import { ampli } from 'src/ampli';
import { experiment } from 'src/feature-flipping/infrastructure/experiment';
import { hasAccessToMultiLocations } from 'src/multi-locations/domain/User';
import { useSundayIntercomForMultilocation } from 'src/intercom/hooks/useSundayIntercomForMultilocation';
import DataAnalyticsSvg from 'src/app/component/icons/DataAnalyticsSvg';
import DataAnalyticsPlainSvg from 'src/app/component/icons/DataAnalyticsPlainSvg';

const accountingEntries = (user: AuthenticatedUser): NavigationItem[] => {
  if (!user.relations.some((relation) => relation.relation === relationTypes.can_access_financials)) {
    return [];
  }

  return [
    {
      icon: <DataAnalyticsSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <DataAnalyticsPlainSvg />,
      text: 'menu.analytics',
      tooltip: 'menu.tooltip.data-analytics',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: { products: [ServiceType.PAY_AT_TABLE] },
      availableForNoPos: false,
      subItems: [
        {
          text: 'menu.analytics.overview',
          route: '/all-locations/data-analytics/overview',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [],
        },
        {
          text: 'menu.analytics.business_performance',
          route: '/all-locations/data-analytics/business-performance',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [],
        },
        {
          text: 'menu.analytics.customer_insights',
          route: '/all-locations/data-analytics/customer-insights',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'menu.analytics.tips',
          route: '/all-locations/data-analytics/tips',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          availableIn: ['US'],
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [
            {
              text: 'menu.analytics.tips',
              route: tipsStaffRulesPath('businessId'),
              availableTo: { products: [ServiceType.PAY_AT_TABLE] },
              availableForNoPos: false,
              subItems: [],
              hiddenInDrawer: true,
            },
          ],
        },
        {
          text: 'menu.analytics.repeat_kpis',
          route: '/all-locations/data-analytics/repeat-kpis',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          featureFlag: 'kyc-repeat-kpis',
          subItems: [],
        },
        {
          text: 'menu.analytics.business_comparison',
          route: '/all-locations/data-analytics/enterprise-dashboard',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          hideForPos: ['PI_ELECTRONIQUE', 'CARREPOS', 'POSITOUCH_OMNIVORE', 'ZONAL'],
          subItems: [],
        },
        {
          text: 'menu.analytics.reviews',
          route: '/all-locations/data-analytics/reviews',
          availableTo: { products: [ServiceType.PAY_AT_TABLE] },
          availableForNoPos: false,
          subItems: [],
        },
      ],
    },
    {
      icon: <AccountingSvg color={PaletteTheme.menu.icon} />,
      selectedIcon: <AccountingPlainSvg />,
      text: 'accounting.title',
      authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
      availableTo: {
        products: [
          ServiceType.PAY_AT_TABLE,
          ServiceType.ORDER_AND_PAY,
          ServiceType.SMB_PAY,
          ServiceType.PAYMENT_TERMINAL,
        ],
      },
      availableForNoPos: false,
      tooltip: 'menu.tooltip.accounting',
      subItems: [
        {
          text: 'accounting.payout_tab',
          route: '/all-locations/accounting/payouts',
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
        {
          text: 'accounting.invoice_tab',
          route: '/all-locations/accounting/invoices',
          authorizedRoles: [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER],
          availableTo: {
            products: [
              ServiceType.PAY_AT_TABLE,
              ServiceType.ORDER_AND_PAY,
              ServiceType.SMB_PAY,
              ServiceType.PAYMENT_TERMINAL,
            ],
            stillShowWhenChurned: true,
          },
          availableForNoPos: false,
          subItems: [],
        },
      ],
      banner: <InfoAccounting />,
    },
  ];
};

export const menuItems = (user: AuthenticatedUser) => {
  return [...accountingEntries(user)];
};

export const MultiLocationLayout = () => {
  document.title = 'multi-locations dashboard';
  const user = useAuthenticatedUserOrThrow();
  const navigate = useNavigate();
  // TODO: make it a loader or something

  useEffect(() => {
    if (user.relations.length < 2) {
      navigate(venueHomePath(user.claims[0].businessId));
    }
  }, [user, navigate]);

  useSundayIntercomForMultilocation(user.claims[0].businessId);

  useEffect(() => {
    const updateTracking = async () => {
      ampli.identify(user.userId, {
        type: user.userType,
        hasImpersonated: user.impersonated,
        hasAllLocationsAccess: hasAccessToMultiLocations(user),
      });
      const userProperties = {
        venueId: 'all-locations',
        businessId: 'all-locations',
        businessCountryCode: '',
        businessName: 'all-locations',
      };

      await ampli.userLoggedIn(userProperties).promise;
      await experiment.fetch({ user_properties: { ...userProperties, venueId: 'all-locations' } });
    };

    updateTracking().then(() => {
      return undefined;
    });
  }, [user.userId, user.userType]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <NavigationMenu menuItems={menuItems(user)} />
        <MainLayoutContainer component="main">
          <Outlet />
        </MainLayoutContainer>
      </Box>
    </>
  );
};
