/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const SunSvg: React.FC<Props> = ({ size = 16, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 1C12.5523 1 13 1.44772 13 2V4C13 4.55228 12.5523 5 12 5C11.4477 5 11 4.55228 11 4V2C11 1.44772 11.4477 1 12 1ZM4.1928 4.1928C4.58332 3.80227 5.21648 3.80227 5.60701 4.1928L7.02122 5.60701C7.41175 5.99753 7.41175 6.6307 7.02122 7.02122C6.6307 7.41175 5.99753 7.41175 5.60701 7.02122L4.1928 5.60701C3.80227 5.21648 3.80227 4.58332 4.1928 4.1928ZM19.8072 4.1928C20.1977 4.58332 20.1977 5.21648 19.8072 5.60701L18.393 7.02122C18.0025 7.41175 17.3693 7.41175 16.9788 7.02122C16.5883 6.6307 16.5883 5.99753 16.9788 5.60701L18.393 4.1928C18.7835 3.80227 19.4167 3.80227 19.8072 4.1928ZM12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12ZM1 12C1 11.4477 1.44772 11 2 11H4C4.55228 11 5 11.4477 5 12C5 12.5523 4.55228 13 4 13H2C1.44772 13 1 12.5523 1 12ZM19 12C19 11.4477 19.4477 11 20 11H22C22.5523 11 23 11.4477 23 12C23 12.5523 22.5523 13 22 13H20C19.4477 13 19 12.5523 19 12ZM7.02122 16.9829C7.41175 17.3734 7.41175 18.0066 7.02122 18.3971L5.60701 19.8113C5.21648 20.2018 4.58332 20.2018 4.1928 19.8113C3.80227 19.4208 3.80227 18.7876 4.1928 18.3971L5.60701 16.9829C5.99753 16.5924 6.6307 16.5924 7.02122 16.9829ZM16.9788 16.9829C17.3693 16.5924 18.0025 16.5924 18.393 16.9829L19.8072 18.3971C20.1977 18.7876 20.1977 19.4208 19.8072 19.8113C19.4167 20.2018 18.7835 20.2018 18.393 19.8113L16.9788 18.3971C16.5883 18.0066 16.5883 17.3734 16.9788 16.9829ZM12 19C12.5523 19 13 19.4477 13 20V22C13 22.5523 12.5523 23 12 23C11.4477 23 11 22.5523 11 22V20C11 19.4477 11.4477 19 12 19Z" fill={color}/>
  </svg>
);

export default SunSvg;
