import { Configuration } from 'src/configuration/Configuration';

export function initFavicon(configuration: Configuration) {
  const envName = configuration.envName;

  const link: HTMLLinkElement = document.querySelector("link[rel*='shortcut icon']")!;
  if (import.meta.env.DEV) {
    link.href = '/Favicon/local.png';

  } else {
    link.href = `/Favicon/${envName}.png`;
  }
}
