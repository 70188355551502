import { Allergen } from '../../domain/Allergen';
import { AllergenDto } from './AllergenDto';

export class AllergenDtoConverter {
  static toAllergen(dto: AllergenDto): Allergen {
    return {
      id: dto.id,
      name: dto.name,
    };
  }
}
