import styled from 'styled-components';
import React from 'react';
import { getSpacing } from '../../../../stylesheet';
import IconButton from '../../../../common/components/IconButton';
import { CheckIcon, PlusIcon } from '../../../../common/components/Icons';
import { Table } from '../../../../venues/domain/Table';

type AreaDetailsTableProps = {
  table: Table;
  selected: boolean;
  disabled: boolean;
  onToggled: (tableId: string, selected: boolean) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: ${getSpacing(2)};
`;

const TableName = styled.div`
  margin-left: ${getSpacing(2)};
`;

export const AreaDetailsTable = ({
  table, selected, disabled, onToggled,
}: AreaDetailsTableProps) => {
  const onTableToggled = (tableId: string, updatedSelection: boolean) => {
    if (disabled) {
      return;
    }

    onToggled(tableId, updatedSelection);
  };

  const buttons = () => {
    if (selected) {
      return (
        <IconButton
          Icon={CheckIcon}
          active
          onClick={() => {
            onTableToggled(table.id, false);
          }}
        />
      );
    }

    return (
      <IconButton
        Icon={PlusIcon}
        onClick={() => {
          onTableToggled(table.id, true);
        }}
      />
    );
  };

  return (
    <Container>
      {buttons()}
      <TableName>{table.name}</TableName>
    </Container>
  );
};
