import React from 'react';
import styled from 'styled-components';
import { ArrowIcon, colorUsage, IconWrapper, spaceUsages, Title } from '@sundayapp/b2b-react-component-library';
import { useRouting } from 'src/ordering/common/hooks';
import MenuLastEditionDate from './MenuLastEditionDate';
import MenuEnabler from './MenuEnabler';
import { Menu } from '../../../domain/Menu';
import { BusinessId } from 'src/business/domain/Business';
import { SourceIcon } from 'src/menu/common/SourceIcon';
import { Box } from '@mui/material';
import { MenuFeatureConfiguration } from 'src/menu/dynamic-menu/pages/MenusPage/components/MenuFeatureConfiguration';
import { MenuAssetSourceIcon } from 'src/menu/dynamic-menu/components/MenuAssetSourceIcon';
import { useMenuAssetsSources } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';
import { sizeInCss, tShirt } from 'src/sundayplus/Size';

type MenuTitleProps = {
  menu: Menu;
  businessId: BusinessId;
};

const MenuHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding-left: ${spaceUsages.mediumLarge};
  padding-right: ${spaceUsages.mediumLarge};
  justify-content: start;
`;

const GoBackWrapper = styled.div`
  left: -16px;
  position: absolute;
  margin-top: 16px;
`;

const MenuDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizeInCss(tShirt('s'))};
`;

const MenuTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 8px;
`;

const MenuHeader = ({ businessId, menu }: MenuTitleProps) => {
  const menuNavigation = useRouting();

  const onGoToMenu = async () => {
    menuNavigation.goToMenus(businessId);
  };
  const menuAssetsSources = useMenuAssetsSources();

  return (
    <MenuHeaderWrapper>
      <GoBackWrapper>
        <IconWrapper size="small" cursor="pointer" color={colorUsage.secondaryText} onClick={onGoToMenu}>
          <ArrowIcon direction="left" />
        </IconWrapper>
        ·
      </GoBackWrapper>

      <MenuDetails>
        <MenuTitle>
          {!menu.posConnection?.posConnectionId && (
            <SourceIcon isSunday={menu.sundayMenu} />
          )}
          <Title size="title2" pr={spaceUsages.xLarge}>
            {menu?.name}
          </Title>
        </MenuTitle>
        {!!menu.posConnection?.posConnectionId && (
          <Box><MenuAssetSourceIcon isSunday={menu.sundayMenu} posConnection={menu.posConnection} menuAssetsSources={menuAssetsSources} /></Box>
        )}
        <MenuLastEditionDate menu={menu} />
      </MenuDetails>
      <Box display="flex" flexDirection="column" gap={1}>
        <MenuEnabler menu={menu} businessId={businessId} />
        <MenuFeatureConfiguration businessId={businessId} menuId={menu.id} />
      </Box>
    </MenuHeaderWrapper>
  );
};

export default MenuHeader;
