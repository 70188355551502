import React, { FC, FormEvent, useState } from 'react';
import { Box, Button, Modal, TextField, Typography, useTheme } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { useNavigate, useOutletContext } from 'react-router';
import { Check } from '@mui/icons-material';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { PaymentLinkRepository } from '../infrastructure/PaymentLinkRepository';
import axios from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { buildQueryKey } from '../queries/paymentLinks';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { NumericFormat } from 'react-number-format';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { usePaymentAccount } from 'src/payments/queries/paymentAccount';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { Dayjs } from 'dayjs';
import { PaymentLinkPageOutletContext } from './PaymentLinkPage';


type Nullable<T> = { [K in keyof T]: T[K] | null };

const repository = new PaymentLinkRepository(axios, ConfigurationLoader.load());

export type PaymentLinkForm = {
  amount: number;
  description: string;
  title: string;
  eventDate: Dayjs | null;
};

function legalsLinkFor(countryCode: string, isMor: boolean) {
  if (countryCode === 'FR') {
    return 'https://sundayapp.com/fr/cgs-fr/';
  }

  if (countryCode === 'GB') {
    return isMor ? 'https://sundayapp.com/en-gb/terms-and-conditions/' : 'https://sundayapp.com/terms-and-conditions/';
  }

  return 'https://sundayapp.com/terms-and-conditions/';
}

export const CreatePaymentLinkModal: FC = () => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();
  const { enrollmentId } = useOutletContext<PaymentLinkPageOutletContext>();
  const { data: paymentAccount } = usePaymentAccount(enrollmentId);

  const theme = useTheme();
  const intl = useIntl();
  const navigate = useNavigate();
  const close = () => navigate(-1);

  const legalsLinkHref = legalsLinkFor(business.address.countryCode, paymentAccount?.hasCheckoutEnabled() ?? false);
  const [form, setForm] = useState<Nullable<PaymentLinkForm>>({
    amount: null,
    description: null,
    title: null,
    eventDate: null,
  });
  const [error, setError] = useState<String>('');

  const isFormComplete = (value: Nullable<PaymentLinkForm>): value is PaymentLinkForm => !!value.amount
    && value.amount > 0
    && !!value.title
    && !!value.description
    && (!value.eventDate || value.eventDate.isValid());

  async function createLink(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!isFormComplete(form)) {
      return;
    }

    try {
      const response = await repository.create({
        enrollmentId: enrollmentId,
        title: form.title,
        description: form.description,
        amountInCents: form.amount * 100,
        eventDate: form.eventDate?.toISOString(),
      });

      queryClient.invalidateQueries({ queryKey: buildQueryKey(business.id) });

      navigate(`${venuePath(business.id)}/payment-links/ready-to-share`, {
        state: { shortLink: response.shortLink },
        replace: true,
      });
    } catch (e) {
      setError('Unable to create payment link');
      window.setTimeout(() => setError(''), 5000);
    }
  }

  const premiumFeePercentageDisplay = (paymentAccount?.paymentLinkFeeRules?.premium || 0) / 1_00000;
  const standardFeePercentageDisplay = (paymentAccount?.paymentLinkFeeRules?.standard || 0) / 1_00000;

  return <Modal
    open={true}
    aria-labelledby="create-payment-link-modal"
  >
    <Box sx={{ background: 'white', height: '100%', overflow: 'auto' }} display="flex" flexDirection="column">
      <Box display="flex" flexDirection="row" justifyContent="space-between" padding="20px 24px"
           borderBottom={`1px solid ${theme.palette.secondary.light}`}>
        <Box minWidth="50px"></Box>
        <Box flexGrow={1} display="flex" justifyContent={'center'}>
          <Typography>
            <FormattedMessage id={'payment_link.create.title'} />
          </Typography>
        </Box>
        <Box minWidth="50px" display="flex" alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={close}>
          <CrossIcon />
        </Box>
      </Box>

      <Box sx={{ width: '100%', maxWidth: '800px', marginLeft: 'auto', marginRight: 'auto' }} display="flex"
           padding="16px"
           flexDirection="column">

        <form onSubmit={(event) => createLink(event)}>
          <Box padding="60px 0">
            <Box display="flex" flexDirection="column" gap="24px" maxWidth="676px" margin="auto">

              <Box display="flex" flexDirection="column" gap="24px">
                <Typography variant="h5">
                  <FormattedMessage id={'payment_link.create.form.title'} />
                </Typography>
                <Typography variant="subtitle2" sx={{ textDecoration: 'underline' }}>
                  <FormattedMessage id={'payment_link.create.form.subtitle'} />
                </Typography>
              </Box>

              <TextField
                required
                id="outlined-required"
                label={intl.formatMessage({ id: 'payment_link.create.form.title.label' })}
                placeholder={intl.formatMessage({ id: 'payment_link.create.form.title.placeholder' })}
                onChange={(event) => setForm((actual) => ({ ...actual, title: event.target.value }))}
                value={form.title}
              />

              <TextField
                required
                multiline
                rows={4}
                id="outlined-required"
                label={intl.formatMessage({ id: 'payment_link.create.form.description.label' })}
                placeholder={intl.formatMessage({ id: 'payment_link.create.form.description.placeholder' })}
                onChange={(event) => setForm((actual) => ({ ...actual, description: event.target.value }))}
                value={form.description}
              />


              <NumericFormat customInput={TextField}
                             required
                             label={intl.formatMessage({ id: 'payment_link.create.form.amount.label' })}
                             placeholder={intl.formatMessage({ id: 'payment_link.create.form.amount.placeholder' })}
                             allowedDecimalSeparators={[',', '.']}
                             decimalScale={2}
                             value={form.amount}
                             onChange={(event) => setForm((actual) => ({
                               ...actual,
                               amount: +parseFloat(event.target.value).toFixed(2),
                             }))}
                             autoComplete="off" />

              <LocalizationProvider dateAdapter={AdapterDayjs}
                                    adapterLocale={intl.locale}>
                <MobileDateTimePicker label={intl.formatMessage({ id: 'payment_link.create.form.event_date.label' })}
                                      value={form.eventDate}
                                      onChange={(dayjsDate) => {
                                        setForm((actual) => ({
                                          ...actual,
                                          eventDate: dayjsDate,
                                        }));
                                      }}
                />
              </LocalizationProvider>

              <Box>
                <Typography variant="subtitle2" color={theme.palette.text.secondary}>
                  <FormattedMessage id="payment_link.create.legals" values={{
                    link: <a href={legalsLinkHref} target="_blank" rel="noreferrer">
                      <FormattedMessage id="payment_link.create.legals.link_label" />
                    </a>,
                    premiumFee: premiumFeePercentageDisplay,
                    standardFee: standardFeePercentageDisplay,
                  }} />
                </Typography>
              </Box>

              <Box>
                <Button type="submit" size="large" variant="contained" disabled={!isFormComplete(form)}>
                  <Box display="flex" justifyContent="center" alignItems="center" gap="8px" padding="8px 22px">
                    <Check />
                    <Typography variant="subtitle1">
                      <FormattedMessage id="payment_link.landing.cta.create_link" />
                    </Typography>
                  </Box>
                </Button>
              </Box>

              {error && <Box>
                <Typography variant="subtitle1" color={theme.palette.error.main}>
                  <FormattedMessage id="payment_link.create.form.error" /> - {error}
                </Typography>
              </Box>}
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  </Modal>;
};
