import React from 'react';
import { IconProps } from './interface';

export const PlusIcon: React.FC<IconProps> = ({ size = 12, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 6C12 6.27614 11.7761 6.5 11.5 6.5L0.5 6.5C0.223858 6.5 0 6.27614 0 6C0 5.72386 0.223858 5.5 0.5 5.5L11.5 5.5C11.7761 5.5 12 5.72386 12 6Z"
      fill={color}
    />
    <rect x="5.5" y="12" width="12" height="1" rx="0.5" transform="rotate(-90 5.5 12)" fill={color} />
  </svg>
);
