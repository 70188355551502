import axios from 'axios';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { GlobalConfiguration } from '../domain/GlobalConfiguration';

type GlobalConfigurationEntryResponse = { name: string, value: string | boolean };

type GlobalConfigurationResponse = {
  venueId: string,
  configuration: GlobalConfigurationEntryResponse[]
};

export class GlobalConfigurationRepositoryHttp {
  constructor(private globalConfigurationUrl: string) {
  }

  private static buildGlobalConfigurationUrlParameters(countryCode?: string) {
    if (!countryCode) {
      return '';
    }

    return `?countryCode=${countryCode}`;
  }

  async getGlobalConfiguration(venueId: string, countryCode?: string): Promise<GlobalConfiguration> {
    const queryParameter = GlobalConfigurationRepositoryHttp.buildGlobalConfigurationUrlParameters(countryCode);
    const response = await axios.get<GlobalConfigurationResponse>(`${this.globalConfigurationUrl}/venues/${venueId}/configurations${queryParameter}`);
    const configurationEntries = new Map<VenueConfigurationKey, any>();
    response.data.configuration.forEach((entry: GlobalConfigurationEntryResponse) => {
      configurationEntries.set(entry.name as VenueConfigurationKey, entry.value);
    });
    return new GlobalConfiguration(configurationEntries);
  }

  async updateGlobalConfiguration(venueId: string, configurations: Map<VenueConfigurationKey, any>, countryCode: string): Promise<void> {
    await axios.post(`${this.globalConfigurationUrl}/venues/${venueId}/configurations?countryCode=${countryCode}`,
      {
        entries: [...configurations.entries()].map((value) => ({ name: value[0], value: value[1] })),
      });
  }
}
