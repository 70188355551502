import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '../../domain/Box';
import { BoxOrderInPreparations } from './BoxOrderInPreparations';
import { BoxOrderStat } from '../../../orders/domain/BoxOrderStat';
import { useGetBoxStats } from '../../../venues/hook';

type BoxStatsContainerProps = {
  box: Box;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

const BoxTitle = styled.div`
  font-size: 21px;
  margin-bottom: 16px;
`;

const TitleLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const BoxStatsDetails = ({ box }: BoxStatsContainerProps) => {
  const getBoxStats = useGetBoxStats();

  const [boxOrderInPreparation, updateBoxOrderInPreparation] = useState<BoxOrderStat[]>([]);
  const [isExpanded, updateExpansion] = useState<boolean>(true);

  useEffect(() => {
    const itv = setInterval(() => {
      getBoxStats(box.id).then((boxStats) => {
        updateBoxOrderInPreparation(boxStats.inPreparation);
      });
    }, 5000);

    return () => {
      clearInterval(itv);
    };
  });

  const onBoxTitleClick = () => {
    updateExpansion(!isExpanded);
  };

  return (
    <Container>
      <TitleLine onClick={onBoxTitleClick}>
        <BoxTitle>{box.displayName}</BoxTitle>
        {isExpanded ? <ExpandMoreIcon fontSize="large" /> : <ChevronRightIcon fontSize="large" />}
      </TitleLine>
      {isExpanded && <BoxOrderInPreparations boxOrderStats={boxOrderInPreparation} />}
    </Container>
  );
};
