import { ValuesOf } from 'src/utils/typescript/valuesOf';

interface RestaurantShiftTime {
  hour: number;
  minute: number;
}

export const restaurantShiftTimesOptionTypes = {
  LUNCH: 'LUNCH',
  DINNER: 'DINNER',
  ALL_SHIFTS: 'ALL_SHIFTS',
} as const;

export type RestaurantShiftTimesOptionType = ValuesOf<typeof restaurantShiftTimesOptionTypes>;

export interface RestaurantShiftTimesOption {
  startAt: RestaurantShiftTime;
  endAt: RestaurantShiftTime;
  type: RestaurantShiftTimesOptionType;
  label: string;
  timeRangeLabel: string,
}
