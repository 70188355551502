import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { DateTimePicker } from '@sundayapp/b2b-react-component-library';
import { StaffPerformanceRangeType } from '../domain/StaffPerformance';
import { breakpoints, isTabletScreen } from 'src/app/theme/ThemeV5';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';

type HeaderProps = {
  range: StaffPerformanceRangeType;
  startDate: Date;
  endDate: Date;
  setRange: (range: StaffPerformanceRangeType) => void;
  setCustomRangeDate: (startDate: Date, endDate: Date) => void;
};

const Container = styled.div`
  display: flex;
  margin: 32px 16px 16px 16px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${breakpoints.tablet}) {
    align-items: start;
    flex-direction: column;
  }
`;

const RadioTimeRangeContainer = styled.div`
  display: flex;
  margin-left: 16px;
`;

const TimeRangeLegend = styled(Typography)`
  color: ${PaletteTheme.text?.secondary};
`;

export const Header = ({
  range, setRange, startDate, endDate, setCustomRangeDate,
}: HeaderProps) => {
  const intl = useIntl();
  const [datePickerIsOpened, updateDatePickerVisibility] = useState<boolean>(false);
  const [currentStartDate, updateStartDate] = useState<Date>(startDate);
  const [currentEndDate, updateEndDate] = useState<Date>(endDate);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rangeValue = (event.target as HTMLInputElement).value as StaffPerformanceRangeType;
    if (rangeValue === 'CUSTOM' && (startDate === undefined || endDate === undefined)) {
      return;
    }

    setRange(rangeValue);
  };

  const changeSelectedRange = (selectedRange: Date[]) => {
    updateStartDate(selectedRange[0]);
    updateEndDate(selectedRange[1]);
    // @ts-ignore
    setRange('CUSTOM');
    setCustomRangeDate(selectedRange[0], selectedRange[1]);
    updateDatePickerVisibility(false);
  };

  const onCustomDate = () => updateDatePickerVisibility(true);

  const closeDatePicker = () => updateDatePickerVisibility(false);

  const customLabel = useMemo(() => {
    // @ts-ignore
    if (range === 'CUSTOM') {
      return intl.formatMessage(
        { id: 'staff_performance.custom_range_with_value', defaultMessage: 'from {startDate} to {endDate}' },
        { startDate: currentStartDate.toDateString(), endDate: currentEndDate.toDateString() },
      );
    }
    return intl.formatMessage({ id: 'staff_performance.custom_range' });
  }, [range, currentStartDate, currentEndDate]);

  return (
    <Container>
      <RadioTimeRangeContainer>
        <RadioGroup
          row={!isTabletScreen()}
          aria-label="date-filter"
          name="date-filter"
          defaultValue={'7_DAYS'}
          value={range}
          onChange={handleChange}
        >
          <FormControlLabel
            value={'7_DAYS'}
            control={<Radio color="primary" />}
            label={<FormattedMessage id="staff_performance.last_7_days" />}
            labelPlacement="end"
          />
          <FormControlLabel
            value={'30_DAYS'}
            control={<Radio color="primary" />}
            label={<FormattedMessage id="staff_performance.last_30_days" />}
            labelPlacement="end"
          />
          <FormControlLabel
            value={'ALL_TIME'}
            control={<Radio color="primary" />}
            label={<FormattedMessage id="staff_performance.all_time" />}
            labelPlacement="end"
          />
          <FormControlLabel
            value={'CUSTOM'}
            control={<Radio color="primary" />}
            label={customLabel}
            labelPlacement="end"
            onClick={onCustomDate}
          />
        </RadioGroup>

        {datePickerIsOpened && (
          <DateTimePicker
            onApply={changeSelectedRange}
            onClose={closeDatePicker}
            value={[currentStartDate, currentEndDate]}
            applyButtonLabel={intl.formatMessage({ id: 'datepicker.apply' })}
            cancelButtonLabel={intl.formatMessage({ id: 'datepicker.cancel' })}
            startTimeInputLabel={intl.formatMessage({ id: 'datepicker.start-time' })}
            endTimeInputLabel={intl.formatMessage({ id: 'datepicker.end-time' })}
          />
        )}

      </RadioTimeRangeContainer>
      <TimeRangeLegend variant="body2">
        <FormattedMessage id="staff_performance.sunday_payments_message" />
      </TimeRangeLegend>
    </Container>
  );
};
