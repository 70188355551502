import React, { ReactNode } from 'react';
import { styled, Typography } from '@mui/material';
import RightArrowSvg from '../../../app/component/icons/RightArrowSvg';
import { themeV5 } from 'src/app/theme/ThemeV5';

type HomeActionProps = {
  title: string;
  icon: ReactNode;
  onClick: () => void;
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  padding-top: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
`;

const ActionTitle = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  min-height: 32px;
  border-radius: 8px;
`;

const IconWrapperWithBackground = styled(IconWrapper)`
  background-color: ${themeV5.palette.background.default};
`;

export const HomeAction = ({ title, icon, onClick }: HomeActionProps) => (
  <Container onClick={onClick}>
    <ActionTitle>
      <IconWrapperWithBackground>
        {icon}
      </IconWrapperWithBackground>
      <Typography variant="body2" paddingLeft="16px">
        {title}
      </Typography>
    </ActionTitle>
    <IconWrapper>
      <RightArrowSvg size={24} color={themeV5.palette.text.disabled} />
    </IconWrapper>
  </Container>
);
