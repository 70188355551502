import { useQuery } from '@tanstack/react-query';
import { WaiterHistory } from '../domain/AllWaiters';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { WaiterHttpPort } from '../infrastructure/WaiterHttpPort';
import { QUERYKEY_WAITER } from './getWaiterQuery';

export const QUERYKEY_WAITER_HISTORY = 'waiter_history';

const buildQueryKey = (businessId: string, waiterId: string | undefined) =>
  [QUERYKEY_WAITER, businessId, waiterId, QUERYKEY_WAITER_HISTORY] as const;

export const getWaiterHistoryQuery = (businessId: string, waiterId: string | undefined): Promise<WaiterHistory[]> => {
  const configuration = ConfigurationLoader.load();
  const waiterPort = new WaiterHttpPort(configuration.bookkeepingApiBaseUrl);
  if (!waiterId) return Promise.resolve([]);
  return waiterPort.getHistory(businessId, waiterId);
};

export const useGetWaiterHistory = (businessId: string, waiterId: string | undefined) =>
  useQuery({
    queryKey: buildQueryKey(businessId, waiterId),
    queryFn: () => getWaiterHistoryQuery(businessId, waiterId),
  });
