import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Drawer } from '@mui/material';
import { Payment } from '../../domain/Payment';
import { RefundComponent } from './RefundComponent';

type RefundSidePanelProps = {
  onClose: () => void;
  payment: Payment;
  isOpen: boolean;
  handleRefreshPayment: () => Promise<void>;
};

export const RefundSidePanel = ({
  onClose, payment, isOpen, handleRefreshPayment,
}: RefundSidePanelProps) => {
  const { formatMessage } = useIntl();

  if (!payment) {
    return null;
  }

  return (
    <div>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
      >
        <div style={{ padding: '0 16px 0 16px', width: '420px', maxWidth: '100vw' }}>
          <h1>{formatMessage({ id: 'payments.refund_dialog.title', defaultMessage: 'refund' })}</h1>
          <RefundComponent onClose={onClose} payment={payment} handleRefreshPayment={handleRefreshPayment} />
        </div>
      </Drawer>
    </div>
  );
};
