import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import * as React from 'react';

const PropertyFieldContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

type PropertyProps = {
  label: string
  value: string
};

export const ProductPartnerPropertyField = ({ label, value }: PropertyProps) => (
  <PropertyFieldContainer>
    <Typography sx={{ fontWeight: 'bold', fontSize: 'small' }}>
      {label}
      :
    </Typography>
    <Typography sx={{ fontSize: 'small' }}>{value}</Typography>
  </PropertyFieldContainer>
);
