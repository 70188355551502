import { RepresentativeDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import {
  needsNationalIdNumber,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/PersonalInfo/NationalIdNumberIfUS';


const checkIfDocumentIdComplete = (representative: RepresentativeDraft) => {
  if (!representative.identificationType) return false;
  if (representative.identificationType == 'passport') {
    return !!representative.identificationFrontDocument;
  }
  return !!representative.identificationFrontDocument && !!representative.identificationBackDocument;
};

export const isCompleteControl = ({ representative, containsErrors, businessCountryCode }: {
  representative: RepresentativeDraft,
  containsErrors: boolean,
  businessCountryCode: string
}): boolean => {
  if (containsErrors || !representative) {
    return false;
  }
  if (!checkIfDocumentIdComplete(representative)) return false;
  const isAddressComplete = !!representative.address?.city && !!representative.address?.country && !!representative.address?.addressLine1 && !!representative.address?.zip;
  const isEmailComplete = !!representative.email || businessCountryCode !== 'US';
  const isNationalIdNumberComplete = !!representative?.nationalIdNumber || !needsNationalIdNumber(businessCountryCode, representative?.address?.country ?? '');

  return !!representative.firstName &&
    !!representative.lastName &&
    !!representative.dateOfBirth &&
    !!representative.countryOfBirth &&
    !!representative.companyPosition && isEmailComplete &&
    isAddressComplete &&
    isNationalIdNumberComplete;
};
