import { Modal } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import MenuItem from '@mui/material/MenuItem';
import { Box, Button, Select, SelectChangeEvent, TextField, Typography } from '@mui/material';
import { venueMenuProductPath } from 'src/app/navigation/pathHelpers';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useCreateProductMutation } from '../../../../mutations/product/useProductMutation';
import {
  ProductType,
  productTypes,
  productTypesByElementType,
  translationByProductType,
} from '../../../../domain/Product';
import { Business } from 'src/business/domain/Business';
import { MenuProductElementType } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';

type ProductCreationModalProps = {
  business: Business;
  productElementType: MenuProductElementType;
  isOpened: boolean;
  onClose: () => void;
};

export const ProductCreationForm = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 32px;
  width: 100%;
`;

const ProductCreationModal = ({
  business,
  productElementType,
  isOpened,
  onClose,
}: ProductCreationModalProps) => {
  const createProduct = useCreateProductMutation(business.id);
  const navigateTo = useNavigate();
  const availableProductTypes = productTypesByElementType[productElementType];
  const [type, setType] = useState<ProductType>(availableProductTypes[0]);
  const [name, setName] = useState('');
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    setIsValid(name.trim() !== '' && type.trim() !== '');
  }, [name, type]);

  const snackBar = useSnackbar();
  const intl = useIntl();

  const onClickCreateProduct = async () => {
    try {
      const productRequest = {
        name,
        type,
        description: '',
        price: {
          amount: 0,
          currency: business.currency,
        },
      };
      const productId = await createProduct.mutateAsync(productRequest);

      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.products.creation.success' }),
      });
      onClose();
      navigateTo(venueMenuProductPath(business.id, productId!));

    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'menus.products.creation.error' }),
      });
    }
  };

  const onCloseThenResetName = async () => {
    onClose();
    setName('');
    setType(productTypes.PRODUCT);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setType(event.target.value as ProductType);
  };

  return (
    <Modal
      title={<FormattedMessage id={'menus.products.new'}
                               values={{ elementType: intl.formatMessage({ id: translationByProductType[productElementType].single }).toLowerCase() }} />}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onCloseThenResetName}
    >
      <ProductCreationForm>
        <TextField
          variant="outlined"
          size="medium"
          label={intl.formatMessage({ id: 'menus.products.creation.form.name' })}
          sx={{ width: '100%' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Box display="flex" flexDirection="column" gap="4px">
          <Typography>type</Typography>
          <Select
            value={type}
            variant="outlined"
            sx={{ width: '100%' }}
            color="primary"
            onChange={handleChange}
            MenuProps={{
              style: { zIndex: 35001 },
            }}
          >
            {availableProductTypes.map((availableType) => (
              <MenuItem key={availableType} value={availableType}>
                <FormattedMessage id={translationByProductType[availableType].single} />
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          type="submit"
          variant="contained"
          sx={{
            width: '100%',
            justifyContent: 'center',
          }}
          disabled={!isValid}
          onClick={onClickCreateProduct}
        >
          <FormattedMessage id="menus.products.creation.form.submit" />
        </Button>
      </ProductCreationForm>
    </Modal>
  );
};

export default ProductCreationModal;
