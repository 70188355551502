import React from 'react';
import { useNavigate, useSearchParams } from 'react-router';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { useAuthentication } from '../../hooks/useAuthentication';
import { CircularProgress } from '@mui/material';

export const Auth0Impersonation = () => {
  const authentication = useAuthentication();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const organization = searchParams.get('org');
  const actAsUserId = searchParams.get('actAsUserId');
  const venueId = searchParams.get('venueId');

  if (!organization || !actAsUserId || !venueId) {
    navigate(RouteDefinitions.SignIn.path);
    return <></>;
  }

  const redirectPath = venuePath(venueId);
  sessionStorage.setItem('redirectVenueId', venueId);

  authentication.impersonate(redirectPath, organization, actAsUserId, venueId);

  return (<CircularProgress color="inherit" size="large" />);
};
