import { Comparator } from '../../reply/comparator/Comparator';

export type Dimension = 'FOOD_AND_DRINKS' | 'AMBIANCE' | 'SERVICE' | 'VALUE_FOR_MONEY';
const dimensionTable: { [key: string]: Dimension; } = {
  FOOD_AND_DRINKS: 'FOOD_AND_DRINKS',
  AMBIANCE: 'AMBIANCE',
  SERVICE: 'SERVICE',
  VALUE_FOR_MONEY: 'VALUE_FOR_MONEY',
};
export const parseDimension = (s: string): Dimension => {
  const dimension = dimensionTable[s];
  if (!dimension) {
    throw Error(`unknown dimension : ${s}`);
  }
  return dimension;
};
export const dimensionNaturalOrder: Dimension[] = ['FOOD_AND_DRINKS', 'AMBIANCE', 'SERVICE', 'VALUE_FOR_MONEY'];
const dimensionComparator = Comparator.fromArray(dimensionNaturalOrder);
export const comparatorByDimension = <T>(keyMapper: (m: T) => Dimension): Comparator<T> => Comparator.usingKey(keyMapper, dimensionComparator);
