import { Box, styled } from '@mui/material';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { Business } from 'src/business/domain/Business';
import { featureFlag } from 'src/feature-flipping/infrastructure/featureFlag';
import { ReferralBlock } from 'src/home/components/referral/ReferralBlock';
import { TableStatus } from 'src/home/components/status/TableStatus';

const ActionsAndStatus = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  @media (max-width: ${breakpoints.homeTablet}) {
    flex-direction: column;
  } 
`;

export const HomeFooter = ({ business }: { business: Business }) => {
  const isPAT = business.patEnrollment !== undefined;
  const referralCampaignActive = featureFlag('referral-campaign');

  return (
    <ActionsAndStatus>
      {referralCampaignActive ? <ReferralBlock business={business} /> : isPAT && <TableStatus business={business} />}
    </ActionsAndStatus>
  );
};