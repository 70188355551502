import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type EnableBoxWaitingTimeSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const EnableBoxWaitingTimeSetting = ({ venue, setVenue }: EnableBoxWaitingTimeSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateVenue = async (enableBoxWaitingTime: boolean) => {
    makeUpdating(true);
    await updateVenueDetails({
      enableBoxWaitingTime,
    });
    setVenue({
      ...venue,
      enableBoxWaitingTime,
    });
    await pushNotification(
      intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }),
    );
    makeUpdating(false);
  };

  if (!venue.isFoodCourt) {
    return null;
  }

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.enableBoxWaitingTime',
        defaultMessage: 'Show boxes estimated waiting time',
      })}
      description={intl.formatMessage({
        id: 'venue.enableBoxWaitingTimeDescription',
        defaultMessage:
          'When activated, we show estimated waiting time on each box',
      })}
      toggleValue={venue.enableBoxWaitingTime}
      toggleOnChange={updateVenue}
      disabled={isUpdating}
    />
  );
};
