import styled from 'styled-components';
import { Select } from '@mui/material';

export const ApplicableRatingsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  align-self: center;
  align-items: center;
`;

export const MenuProps = {
  style: {
    zIndex: 99999,
  },
  PaperProps: {
    style: {
      maxHeight: 400,
      width: 129,
    },
  },
};

export const ApplicableRatingsSelect = styled(Select)`
  margin-left: 10px;
  min-width: 60px;
`;
