import { parse } from 'csv-parse/browser/esm';
import { CsvReaderRowBuilder } from './CsvReaderRowBuilder';

export class CsvReader {
  static parseFromCsv<T>(csvFile: File, domainBuilder: CsvReaderRowBuilder<T>, headers: string[]): Promise<T[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const content = reader.result?.toString().replace(/\r/g, '\n') ?? '';
        parse(content, {
          trim: true,
          delimiter: [';', ','],
          skip_empty_lines: true,
        }, (err: Error | undefined, data: string[][]) => {
          if (err) {
            reject(new Error(`Bad CSV File format: ${err.message}`));
            return;
          }

          if (data.length === 0) {
            reject(new Error('Empty file'));
            return;
          }

          const headerRow = data.shift()!;
          const existingHeaderFormatError: string | undefined = headerRow.find((headerColumn, index) => headerColumn !== headers[index]);
          if (existingHeaderFormatError !== undefined) {
            reject(new Error(`Bad CSV header format. Expecting '${headers.join(';')}' in first line`));
            return;
          }

          data.map((parts) => domainBuilder.add(parts.map((value: string) => value.trim())));
          resolve(domainBuilder.build());
        });
      };
      reader.readAsText(csvFile);
    });
  }
}
