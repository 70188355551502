import React from 'react';
import { Chip } from '@mui/material';
import { PointOfSale } from '@mui/icons-material';
import SundaySvg from 'src/app/component/icons/SundaySvg';

type SourceIconProps = {
  isSunday: boolean;
  name?: string;
};

const SourceIcon = ({ isSunday, name }: SourceIconProps) => {
  const additionalCss = {
    padding: '3px',
    ...(!name && {
      '& .MuiChip-label': { padding: '3px' },
      '& .MuiChip-icon': { marginLeft: '2px' },
    } ),
  };
  const icon = isSunday ? <SundaySvg size={20} /> : <PointOfSale />;
  const color = isSunday ? 'default' : 'info';
  return name
    ? <Chip sx={additionalCss} color={color} size="small" icon={icon} variant="filled" label={name} />
    : <Chip sx={additionalCss} color={color} size="small" icon={icon} variant="filled" />;

};

export { SourceIcon };
