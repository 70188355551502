import { useNavigate } from 'react-router';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { venuePaymentsPath } from 'src/app/navigation/pathHelpers';
import CreditCardSvg from 'src/app/component/icons/CreditCardSvg';
import { Business } from 'src/business/domain/Business';
import { usePayments } from 'src/payments/page/usePayments';
import { LinkButton } from '@atoms/LinkButton.tsx/LinkButton';

type PaymentButtonProps = {
  business: Business;
};

export const PaymentButton = ({ business }: PaymentButtonProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { hasTabPaymentsToDisplay } = usePayments(business);

  return (
    <LinkButton
      icon={<CreditCardSvg size={20} color={themeV5.palette.text.secondary} />}
      title={intl.formatMessage({ id: 'venue.home.actions.payment.history' })}
      onClick={() => {
        navigate(venuePaymentsPath(business.id));
      }}
      withBadge={hasTabPaymentsToDisplay}
    />
  );
};
