import MenuItem from '@mui/material/MenuItem';
import { useFormContext } from 'react-hook-form';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { TextField } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

const ukAndEuBusinessTypes = [
  'limited_company',
  'public_limited_company',
] as const;

const usBusinessTypes = [
  'individual_or_sole_proprietorship',
  'private_corporation',
  'limited_liability_corporation',
  'publicly_traded_corporation',
  'limited_partnership',
] as const;

const listAllBusinessTypes = (businessCountryCode: string): typeof ukAndEuBusinessTypes | typeof usBusinessTypes => {
  if (businessCountryCode === 'US') {
    return usBusinessTypes;
  }
  return ukAndEuBusinessTypes;
};

export const BusinessType = ({ businessCountryCode }: { businessCountryCode: string }) => {
  const {
    formState: { errors },
    register,
    watch,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const businessType = watch('businessType');
  const businessTypeError = errors.businessType;
  const intl = useIntl();

  return (
    <TextField
      select
      required
      InputLabelProps={{ shrink: true }}
      defaultValue={businessType || ''}
      error={!!businessTypeError}
      id="business-type-select"
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessType' })}
      inputProps={register('businessType', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.businessType.error.required' }) })}
      helperText={businessTypeError?.message || ''}
    >
      {listAllBusinessTypes(businessCountryCode).map(value => (
        <MenuItem key={value} value={value}>
          <FormattedMessage id={`settings.payments.checkout.onboarding.businessType.${value}`} />
        </MenuItem>
      ))}
    </TextField>
  );
};
