import { DeviceGroup } from 'src/initial-setup/domain/DeviceGroup';

const compareStringsByAlphaNumeric = (firstString: string, secondString: string) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return collator.compare(firstString, secondString);
};

const splitDeviceName = (deviceName: string): { prefix: string; value: number | undefined } => {
  const extracted = deviceName.match(/(.*?)(\d*)$/);
  return { prefix: extracted![1], value: extracted![2] ? parseInt(extracted![2], 10) : undefined };
};

export const add = (deviceName: string, increment: number): string => {
  const { prefix, value } = splitDeviceName(deviceName);
  return value !== undefined ? `${prefix}${value + increment}` : prefix;
};

const findRangeIndex = (sortedDeviceNames: string[]): number => {
  const position = sortedDeviceNames.findIndex((value, index) => value !== add(sortedDeviceNames[0], index));
  if (position === 0) {
    return 1;
  }

  return position === -1 ? sortedDeviceNames.length : position;
};

const split = (sortedDeviceNames: string[]): { before: string[]; after: string[] } => {
  const index = findRangeIndex(sortedDeviceNames);
  return { before: sortedDeviceNames.slice(0, index), after: sortedDeviceNames.slice(index) };
};

export const fromSingleTableToRange = (deviceName: string): DeviceGroup => {
  const { prefix, value } = splitDeviceName(deviceName);
  return { prefix, from: value, to: value };
};

export const fromDeviceNamesToRanges = (deviceNames: string[]): DeviceGroup[] => {
  if (deviceNames.length === 0) return [];

  let sortedDeviceNames = deviceNames.sort(compareStringsByAlphaNumeric);
  const response = [];

  do {
    const { before, after } = split(sortedDeviceNames);
    const { value: from, prefix } = splitDeviceName(before[0]);
    const { value: to } = splitDeviceName(before[before.length - 1]);
    response.push({
      prefix,
      from,
      to,
    });
    sortedDeviceNames = after;
  } while (sortedDeviceNames.length !== 0);

  return response;
};
