import { AxiosRequestConfig } from 'axios';
import { v4 as uuidv4 } from 'uuid';

export class RepositoryHttp {
  // eslint-disable-next-line class-methods-use-this
  protected withDefaultHeader(): AxiosRequestConfig {
    const headers: any = {
      correlation_id: uuidv4(),
    };

    return {
      withCredentials: true,
      headers,
    };
  }
}
