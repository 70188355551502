import { useContext } from 'react';
import { ConfigurationContainerContext } from './ConfigurationContainerContext';

export const useConfiguration = () => {
  const container = useContext(ConfigurationContainerContext);
  if (!container) {
    throw new Error('Please add a valid configuration');
  }
  return container;
};
