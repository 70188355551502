import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { MenuBusiness } from '../domain/MenuBusiness';
import { MenuBusinessRepositoryHttp } from '../repositories/MenuBusinessRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_VENUE = 'menu_venue';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_MENU_VENUE, businessId] as const;
export const getMenuBusinessQuery = async ({
  queryKey: [, businessId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<MenuBusiness | null> => {
  const configuration = ConfigurationLoader.load();

  const venueRepository = new MenuBusinessRepositoryHttp(configuration.menuApiBaseUrl);
  return venueRepository.getBusiness(businessId);
};

export const useGetMenuBusiness = (venueId: BusinessId) =>
  useQuery({ queryKey: buildQueryKey(venueId), queryFn: getMenuBusinessQuery });
