import React, { ReactNode } from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import { Routes, useRouteError } from 'react-router';
import FirebaseLegacyAuthenticationInterceptor from '../auth/component/FirebaseLegacyAuthenticationInterceptor';
import { Bills } from '../bills/pages';
import { OrderListPage } from '../orders/pages/OrderListPage';
import ErrorPage from '../auth/pages/ErrorPage/ErrorPage';
import { CustomerDataPage } from '../marketing/customer-data/container/GuestConsent';
import AppSettings from '../pages/Settings/AppSettings/AppSettings';
import OrderingInformation from '../pages/Settings/OrderingInformation/OrderingInformation';
import PaymentsSettings from '../pages/Settings/PaymentsSettings/PaymentsSettings';
import CheckoutOnboarding from 'src/payments/page/CheckoutOnboarding';
import { RevenueCentersSettings } from '../pages/Settings/RevenueCenters/RevenueCentersSettings';
import FAQ from '../pages/Settings/UserPermissions/FAQ';
import UserPermissions from '../pages/Settings/UserPermissions/UserPermissions';
import StripeSetupDone from '../pages/StripeSetupDone';
import { Payments } from '../payments/page';
import { StaffPerformance } from '../staff-performance/page/StaffPerformance';
import { BrowseReviews } from '../sundayplus/reviews/browse/BrowseReviews';
import ReviewsSettings from '../sundayplus/reviews/settings/ReviewsSettings';
import { WithRestrictedAccess } from './component/permission/withRestrictedAccess';
import { RouteDefinitions, subPath, TIPS_BASE_URL } from './RouteDefinitions';
import { OrderingAppSettings } from '../pages/Settings/OrderingAppSettings/OrderingAppSettings';
import Venue from './routes/AuthenticatedRoutes/Venue';
import SignIn from '../auth/pages/SignIn/SignIn';
import { AreaCreationSettings } from '../pages/Settings/AreaSettings/AreaCreationSettings';
import { AreaDetailsSettings } from '../pages/Settings/AreaSettings/AreaDetailsSettings';
import { AreaSettings } from '../pages/Settings/AreaSettings/AreaSettings';
import { userRoles } from '../domain/user/UserRole';
import { Home } from '../home/Home';
import {
  marketingTargets,
  pathFromTarget,
  queryStringFromTarget,
  TrackAndRedirect,
} from './navigation/marketinEmailRedirections';
import { OPVoucherCodesPage } from '../opvoucher-codes/pages/OPVoucherCodesPage';
import { FoodCourt } from '../food-court/pages/FoodCourt';
import QRCodes from '../qrcode/QRCodes';
import PaymentTerminal from '../payment-terminal/pages/PaymentTerminal';
import { RedirectToHomePage } from './navigation/components/RedirectToHomePage';
import { RedirectToRootPath } from './navigation/components/RedirectToRootPath';
import { OPProductAvailabilityPage } from '../ordering/productAvailability/pages/OPProductAvailabilityPage';
import { Insights } from '../sundayplus/reviews/insights/Insights';
import { ReportingPanel } from '../accounting/reporting/pages/ReportingPanel';
import { PayoutPanel } from '../accounting/payout/pages/PayoutPanel';
import { InvoicesTab } from '../accounting/invoices/pages/InvoicesTab';
import CommunicationPreferences from '../pages/CommunicationPreferences/CommunicationPreferences';
import { StaticMenuProvider } from '../menu/static-menu/context/MenuContextProvider';
import { ReconciliationReport } from '../operations/pos-reconciliation/component/ReconciliationReport';
import ReconciliationTabs from '../operations/pos-reconciliation/page/ReconciliationTabs';
import { EndOfServicePage } from '../operations/end-of-service/pages/EndOfServicePage';
import { EReputationLoader } from '../sundayplus/reviews/insights/EReputationLoader';
import { GuestExperience } from '../sundayplus/reviews/guest-experience/GuestExperience';
import { ShiftSettings } from '../pages/Settings/ShiftSettings/ShiftSettings';
import NoVenue from '../business/pages/NoVenue';
import { SignInConfirmation } from '../auth/pages/SignIn/SignInConfirmation';
import { SignOut } from '../auth/pages/SignOut/SignOut';
import { Auth0Impersonation } from '../auth/pages/Impersonate/Auth0Impersonation';
import { ReplyToReviews } from '../sundayplus/reviews/reply/ReplyToReviews';
import { PaymentMethodsMapping } from '../pages/Settings/PaymentMethodMapping/PaymentMethodsMapping';
import CompleteSignUp from '../auth/pages/SignUp/CompleteSignUp';
import { AuthenticationGuard } from '../auth/component/AuthenticationGuard';
import { RedirectToFirstUserVenue } from './navigation/components/RedirectToFirstUserVenue';
import { AppErrorPage } from './component/ErrorPage/AppErrorPage';
import { RevenuePanel } from '../accounting/revenues/pages/RevenuePanel';
import { dataDogErrorHandler } from '../monitoring/DataDogErrorHandler';
import { SundayPayoutSummaryDrawer } from '../accounting/revenues/pages/SundayPayoutSummaryDrawer';
import { ProductPage } from '../menu/dynamic-menu/pages/ProductPage/ProductPage';
import { Vouchers } from '../ordering/promoCodes/pages/AmountVoucherTemp/Vouchers';
import { BoxStatsPage } from '../ordering/box/pages/BoxStats/BoxStatsPage';
import { MenuSettings } from '../pages/Settings/MenuSettings/MenuSettings';
import { StaticMenusPage } from '../menu/static-menu/menuList/StaticMenusPage';
import { DynamicMenusPage } from '../menu/dynamic-menu/pages/MenusPage/DynamicMenusPage';
import { DynamicMenuDetailsPage } from '../menu/dynamic-menu/pages/MenuDetailsPage/DynamicMenuDetailsPage';
import { StaticMenuDetailsPage } from '../menu/static-menu/menuDetails/StaticMenuDetailsPage';
import { BlockPage } from '../menu/dynamic-menu/pages/BlockPage/BlockPage';
import { SundayForRestaurants } from '../app-for-restaurants/pages/SundayForRestaurants';
import { OrganizationReport } from '../organization-report/pages/OrganizationReport';
import { KdsPage } from '../ordering/kds/pages/KdsPage';
import Customization from '../pages/Settings/Customization/Customization';
import { WaiterHistoricalChanges } from '../tips/page/WaiterHistoricalChanges';
import { PaymentLinkPage } from '../payment-link/page/PaymentLinkPage';
import { WaiterReferentialManagement } from '../tips/page/WaiterReferentialManagement';
import { TipsCollection } from '../tips/page/TipsCollection';
import { CreatePaymentLinkModal } from '../payment-link/page/CreatePaymentLinkModal';
import BusinessInformation from '../pages/Settings/BusinessInformation/BusinessInformation';
import { UpdateTippingConfiguration } from '../tips/page/UpdateTippingConfiguration';
import { PaymentLinkReadyToShareModal } from '../payment-link/page/PaymentLinkReadyToShareModal';
import { DataAnalyticsBusinessPerformance } from '../data-analytics/DataAnalyticsBusinessPerformance';
import { DataAnalyticsOverview } from '../data-analytics/DataAnalyticsOverview';
import { DataAnalyticsReviews } from '../data-analytics/DataAnalyticsReviews';
import { DataAnalyticsCustomerInsights } from '../data-analytics/DataAnalyticsCustomerInsights';
import { PongoLoyaltyOAuthRedirect } from '../loyalty/PongoLoyaltyOAuthRedirect';
import { DataAnalyticsEnterpriseDashboard } from '../data-analytics/DataAnalyticsEnterpriseDashboard';
import ReportSettingsPanel from '../operations/pos-reconciliation/page/ReportSettingsPanel';
import ReconciliationContextProvider from '../operations/pos-reconciliation/providers/ReconciliationConfigurationContext';
import { ConfigurationReplication } from '../enterprise/configuration-replication/ConfigurationReplication';
import { PaymentLinkPanel } from '../payment-link/page/PaymentLinkPanel';
import { DataAnalyticsTips } from '../data-analytics/DataAnalyticsTips';
import { DataAnalyticsRepeatKpis } from '../data-analytics/DataAnalyticsRepeatKpis';
import { Home as HomeV2 } from '@pages/home-page/Home';
import { ProductsPage } from 'src/menu/dynamic-menu/pages/ProductsPage/ProductsPage';
import { TipsDispatch } from 'src/tips/dispatch/TipsDispatch';
import FinalizeCheckoutOnboarding from 'src/payments/page/FinalizeCheckoutOnboarding';
import { EnterpriseSettings } from 'src/ops-tooling/enterprise/EnterpriseSettings';
import InternalSettingsPaT from 'src/ops-tooling/PaT/InternalSettingsPaT';
import { TipsConfigurationForProduct } from 'src/pages/Settings/AppSettings/components/TipsConfiguration';
import { CreateProductPage } from 'src/menu/dynamic-menu/pages/CreateProductPage/CreateProductPage';
import { FinalizeCheckoutOnboardingRedirect } from 'src/payments/page/CheckoutOnboardingRedirect';
import { FormSentForValidationModal } from 'src/payments/components/Checkout/CheckoutOnboarding/FormSentForValidationModal';
import { DigitalMenusContext } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';
import { CheckoutLanding } from 'src/auth/pages/CheckoutLanding';
import Onboarding from 'src/onboarding/components/Onboarding';
import { LoyaltyOnboarding } from 'src/loyalty/LoyaltyOnboarding';
import { LoyaltyOnboardingPongo } from 'src/loyalty/LoyaltyOnboardingPongo';
import { ClawbacksPage } from 'src/accounting/clawbacks/pages/ClawbacksPage';
import { MultiLocationLayout } from 'src/multi-locations/pages/MultiLocationLayout';
import { MultiLocationsHome } from 'src/multi-locations/pages/MultiLocationsHome';
import { MultiLocationsInvoices } from 'src/multi-locations/pages/invoices/MultiLocationsInvoices';
import { MultiLocationsPayouts } from 'src/multi-locations/pages/payout/MultiLocationsPayouts';
import { StaffTippingSummaryPage } from 'src/tips/page/StaffTippingSummaryPage';
import { MultiLocationDataAnalyticsOverview } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsOverview';
import { MultiLocationDataAnalyticsBusinessPerformance } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsBusinessPerformance';
import { MultiLocationDataAnalyticsCustomerInsights } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsCustomerInsights';
import { MultiLocationDataAnalyticsTips } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsTips';
import { MultiLocationDataAnalyticsRepeatKpis } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsRepeatKpis';
import { MultiLocationDataAnalyticsReviews } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsReviews';
import { MultiLocationDataAnalyticsEnterpriseDashboard } from 'src/multi-locations/pages/data-analytics/MultiLocationDataAnalyticsEnterpriseDashboard';

type MenuPageWrapperProps = {
  children: ReactNode;
};

const MenuPageWrapper = ({ children }: MenuPageWrapperProps) => (
  <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN]}>
    <StaticMenuProvider>{children}</StaticMenuProvider>
  </WithRestrictedAccess>
);

export const RouterErrorPage = () => {
  const error = useRouteError();
  dataDogErrorHandler(error);
  return <AppErrorPage />;
};
const AuthenticatedRoutes = () => (
  <Routes>
    <Route element={<Outlet context={{}} />} errorElement={<RouterErrorPage />}>
      <Route
        path={RouteDefinitions.FirebaseAuthenticationRedirect.path}
        element={<FirebaseLegacyAuthenticationInterceptor />}
      />
      <Route path={RouteDefinitions.SignIn.path} element={<SignIn />} />
      <Route path={RouteDefinitions.SignInConfirmation.path} element={<SignInConfirmation />} />
      <Route path={RouteDefinitions.CompleteSignUp.path} element={<CompleteSignUp />} />
      <Route path={RouteDefinitions.SignOut.path} element={<SignOut />} />
      <Route path={RouteDefinitions.AuthenticationError.path} element={<ErrorPage />} />
      <Route path={RouteDefinitions.NoVenue.path} element={<NoVenue />} />
      <Route path={RouteDefinitions.Auth0Impersonation.path} element={<Auth0Impersonation />} />
      <Route path={RouteDefinitions.CheckoutLanding.path} element={<CheckoutLanding />} />

      {/* Redirections for marketing email links  */}
      <Route
        path={pathFromTarget(marketingTargets.PAYMENTS)}
        element={<Navigate to={queryStringFromTarget(marketingTargets.PAYMENTS)} />}
      />
      <Route
        path={pathFromTarget(marketingTargets.STAFF_PERFORMANCE)}
        element={<Navigate to={queryStringFromTarget(marketingTargets.STAFF_PERFORMANCE)} />}
      />
      <Route
        path={pathFromTarget(marketingTargets.REVIEWS)}
        element={<Navigate to={queryStringFromTarget(marketingTargets.REVIEWS)} />}
      />
      <Route
        path={pathFromTarget(marketingTargets.INSIGHTS)}
        element={<Navigate to={queryStringFromTarget(marketingTargets.INSIGHTS)} />}
      />
      <Route path={RouteDefinitions.WeeklyEmail.path} element={<TrackAndRedirect />} />
      {/* End of marketing email redirections */}

      <Route path={RouteDefinitions.RootPath.path} element={<RedirectToFirstUserVenue />} />
      <Route path={RouteDefinitions.PongoLoyaltyOauthRedirect.path} element={<PongoLoyaltyOAuthRedirect />} />
      <Route
        path={RouteDefinitions.FinalizeCheckoutOnboardingRedirect.path}
        element={<FinalizeCheckoutOnboardingRedirect />}
      />

      <Route
        path={'/all-locations'}
        element={<AuthenticationGuard component={MultiLocationLayout} errorElement={<AppErrorPage />} />}
      >
        <Route index element={<MultiLocationsHome />} />
        <Route path={'accounting/invoices'} element={<MultiLocationsInvoices />} />
        <Route path={'accounting/payouts'} element={<MultiLocationsPayouts />} />
        <Route path={'data-analytics/overview'} element={<MultiLocationDataAnalyticsOverview />} index />
        <Route
          path={'data-analytics/business-performance'}
          element={<MultiLocationDataAnalyticsBusinessPerformance />}
        />
        <Route path={'data-analytics/customer-insights'} element={<MultiLocationDataAnalyticsCustomerInsights />} />
        <Route path={'data-analytics/tips'} element={<MultiLocationDataAnalyticsTips />} />
        <Route path={'data-analytics/repeat-kpis'} element={<MultiLocationDataAnalyticsRepeatKpis />} />
        <Route path={'data-analytics/reviews'} element={<MultiLocationDataAnalyticsReviews />} />
        <Route
          path={'data-analytics/enterprise-dashboard'}
          element={<MultiLocationDataAnalyticsEnterpriseDashboard />}
          index
        />
      </Route>

      <Route
        path={RouteDefinitions.Venue.path}
        element={<AuthenticationGuard component={Venue} errorElement={<AppErrorPage />} />}
      >
        <Route index element={<RedirectToHomePage />} />
        <Route
          path={subPath(RouteDefinitions.Home)}
          element={
            <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
              <Home />
            </WithRestrictedAccess>
          }
        />

        <Route path={subPath(RouteDefinitions.Home2)} element={<HomeV2 />} />
        <Route path={subPath(RouteDefinitions.Payments)} element={<Payments />} />
        <Route path={subPath(RouteDefinitions.DataAnalytics)}>
          <Route path={subPath(RouteDefinitions.DataAnalyticsOverview)} element={<DataAnalyticsOverview />} index />
          <Route
            path={subPath(RouteDefinitions.DataAnalyticsBusinessPerformance)}
            element={<DataAnalyticsBusinessPerformance />}
          />
          <Route
            path={subPath(RouteDefinitions.DataAnalyticsCustomerInsights)}
            element={<DataAnalyticsCustomerInsights />}
          />
          <Route path={subPath(RouteDefinitions.DataAnalyticsTips)} element={<DataAnalyticsTips />} />
          <Route path={subPath(RouteDefinitions.DataAnalyticsRepeatKpis)} element={<DataAnalyticsRepeatKpis />} />
          <Route path={subPath(RouteDefinitions.DataAnalyticsReviews)} element={<DataAnalyticsReviews />} />
          <Route
            path={subPath(RouteDefinitions.DataAnalyticsEnterpriseDashboard)}
            element={<DataAnalyticsEnterpriseDashboard />}
            index
          />
        </Route>
        <Route path={TIPS_BASE_URL}>
          <Route
            path={subPath(RouteDefinitions.TipsCollection)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER]}>
                <TipsCollection />
              </WithRestrictedAccess>
            }
          />
          <Route
            path={subPath(RouteDefinitions.TipsDistribution)}
            element={
              <WithRestrictedAccess
                availableIn={['FR']}
                authorizedRoles={[userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER]}
              >
                <TipsDispatch />
              </WithRestrictedAccess>
            }
          />
          <Route path={subPath(RouteDefinitions.StaffTippingSummary)}>
            <Route
              path={subPath(RouteDefinitions.StaffTippingSummaryById)}
              element={
                <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER]}>
                  <StaffTippingSummaryPage />
                </WithRestrictedAccess>
              }
            />
          </Route>

          <Route
            path={subPath(RouteDefinitions.TipsStaffRules)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER]}>
                <WaiterReferentialManagement />
              </WithRestrictedAccess>
            }
          >
            <Route path={subPath(RouteDefinitions.WaiterTipping)}>
              <Route
                path={subPath(RouteDefinitions.WaiterTippingById)}
                element={
                  <WithRestrictedAccess
                    authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}
                  >
                    <UpdateTippingConfiguration />
                  </WithRestrictedAccess>
                }
              />
            </Route>
            <Route path={subPath(RouteDefinitions.WaiterHistory)}>
              <Route
                path={subPath(RouteDefinitions.WaiterHistoryById)}
                element={
                  <WithRestrictedAccess
                    authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}
                  >
                    <WaiterHistoricalChanges />
                  </WithRestrictedAccess>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route path={subPath(RouteDefinitions.PaymentTerminal)} element={<PaymentTerminal />} />
        <Route path={subPath(RouteDefinitions.EndOfService)} element={<EndOfServicePage />} />
        <Route path={subPath(RouteDefinitions.PaymentLinks)} element={<PaymentLinkPage />}>
          <Route path={subPath(RouteDefinitions.PaymentLinkPanel)} element={<PaymentLinkPanel />} />
          <Route path={subPath(RouteDefinitions.PaymentLinksReadyToShare)} element={<PaymentLinkReadyToShareModal />} />
          <Route
            path={subPath(RouteDefinitions.PaymentLinksNew)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER]}>
                <CreatePaymentLinkModal />
              </WithRestrictedAccess>
            }
          />
        </Route>
        <Route path={subPath(RouteDefinitions.OrganizationReport)} element={<OrganizationReport />} />
        <Route path={subPath(RouteDefinitions.SundayForRestaurants)} element={<SundayForRestaurants />} />
        <Route path={subPath(RouteDefinitions.FoodCourt)} element={<FoodCourt />} />
        <Route path={subPath(RouteDefinitions.Settings)}>
          <Route path={subPath(RouteDefinitions.UserPermissions)} element={<UserPermissions />} />
          <Route path={subPath(RouteDefinitions.AppSettings)} element={<AppSettings />} />
          <Route path={subPath(RouteDefinitions.Customization)} element={<Customization />} />
          <Route path={subPath(RouteDefinitions.VenueInformation)} element={<BusinessInformation />} />
          <Route path={subPath(RouteDefinitions.ShiftSettings)} element={<ShiftSettings />} />
          <Route path={subPath(RouteDefinitions.RevenueCentersSettings)} element={<RevenueCentersSettings />} />
          <Route path={subPath(RouteDefinitions.OrderingAppSettings)} element={<OrderingAppSettings />} />
          <Route path={subPath(RouteDefinitions.MenuSettings)} element={<MenuSettings />} />
          <Route
            path={subPath(RouteDefinitions.ReconciliationSettings)}
            element={
              <ReconciliationContextProvider>
                <ReportSettingsPanel />{' '}
              </ReconciliationContextProvider>
            }
          />
          <Route path={subPath(RouteDefinitions.areasSettings)}>
            <Route path={subPath(RouteDefinitions.boxAreaSettings)}>
              <Route path={subPath(RouteDefinitions.areaCreateSettings)} element={<AreaCreationSettings />} />
              <Route path={subPath(RouteDefinitions.areaDetailsSettings)} element={<AreaDetailsSettings />} />
            </Route>
            <Route index element={<AreaSettings />} />
          </Route>

          <Route path={subPath(RouteDefinitions.OrderingSettings)} element={<OrderingInformation />} />
          <Route path={subPath(RouteDefinitions.ReviewsSettings)} element={<ReviewsSettings />} />
          <Route path={subPath(RouteDefinitions.PaymentsSettings)} element={<PaymentsSettings />} />
          <Route path={subPath(RouteDefinitions.PaymentsCheckoutOnboarding)} element={<CheckoutOnboarding />} />
          <Route
            path={subPath(RouteDefinitions.PaymentsCheckoutOnboardingFormSent)}
            element={<FormSentForValidationModal />}
          />
          <Route
            path={subPath(RouteDefinitions.PaymentsFinalizeCheckoutOnboarding)}
            element={<FinalizeCheckoutOnboarding />}
          />
          <Route path={subPath(RouteDefinitions.ConfigurationReplication)} element={<ConfigurationReplication />} />
          {/* TODO: why this route ? Remove ? */}
          <Route index element={<BusinessInformation />} />
        </Route>

        <Route path={subPath(RouteDefinitions.MenuManager)} element={<DigitalMenusContext />}>
          <Route path={subPath(RouteDefinitions.StaticMenus)}>
            <Route
              index
              element={
                <MenuPageWrapper>
                  <StaticMenusPage />
                </MenuPageWrapper>
              }
            />
            <Route
              path={subPath(RouteDefinitions.StaticMenuDetails)}
              element={
                <MenuPageWrapper>
                  <StaticMenuDetailsPage />
                </MenuPageWrapper>
              }
            />
          </Route>

          <Route path={subPath(RouteDefinitions.DigitalMenus)}>
            <Route
              index
              element={
                <MenuPageWrapper>
                  <DynamicMenusPage />
                </MenuPageWrapper>
              }
            />
            <Route
              path={subPath(RouteDefinitions.DigitalMenuDetails)}
              element={
                <MenuPageWrapper>
                  <DynamicMenuDetailsPage />
                </MenuPageWrapper>
              }
            />
          </Route>
          <Route path={subPath(RouteDefinitions.Products)}>
            <Route
              index
              element={
                <MenuPageWrapper>
                  <ProductsPage />
                </MenuPageWrapper>
              }
            />
            <Route path={subPath(RouteDefinitions.NewProduct)}>
              <Route
                index
                element={
                  <MenuPageWrapper>
                    <CreateProductPage />
                  </MenuPageWrapper>
                }
              />
            </Route>
            <Route
              path={subPath(RouteDefinitions.ProductDetails)}
              element={
                <MenuPageWrapper>
                  <ProductPage />
                </MenuPageWrapper>
              }
            />
          </Route>
          <Route path={subPath(RouteDefinitions.Blocks)}>
            <Route
              path={subPath(RouteDefinitions.BlocksDetails)}
              element={
                <MenuPageWrapper>
                  <BlockPage />
                </MenuPageWrapper>
              }
            />
          </Route>
        </Route>
        <Route path={subPath(RouteDefinitions.QRCodes)} element={<QRCodes />} />
        <Route path={subPath(RouteDefinitions.SundayPlus)}>
          <Route
            path={subPath(RouteDefinitions.Reviews)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
                <BrowseReviews />
              </WithRestrictedAccess>
            }
          />
          <Route
            path={subPath(RouteDefinitions.ReplyToReviews)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
                <ReplyToReviews />
              </WithRestrictedAccess>
            }
          />
          <Route
            path={subPath(RouteDefinitions.ReviewsInsights)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
                <Insights />
              </WithRestrictedAccess>
            }
          />
          <Route
            path={subPath(RouteDefinitions.ReviewsEReputation)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
                <EReputationLoader />
              </WithRestrictedAccess>
            }
          />
          <Route
            path={subPath(RouteDefinitions.ReviewsGuestExperience)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
                <GuestExperience />
              </WithRestrictedAccess>
            }
          />
          <Route
            path={subPath(RouteDefinitions.CustomerData)}
            element={
              <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN]}>
                <CustomerDataPage />
              </WithRestrictedAccess>
            }
          />
        </Route>

        <Route
          path={subPath(RouteDefinitions.PongoLoyalty)}
          element={
            <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
              <LoyaltyOnboardingPongo />
            </WithRestrictedAccess>
          }
        ></Route>

        <Route
          path={subPath(RouteDefinitions.LoyaltyOnboarding)}
          element={
            <WithRestrictedAccess authorizedRoles={[userRoles.ADMIN, userRoles.MANAGER, userRoles.GENERAL_MANAGER]}>
              <LoyaltyOnboarding />
            </WithRestrictedAccess>
          }
        ></Route>

        <Route path={subPath(RouteDefinitions.StripeSetupDone)} element={<StripeSetupDone />} />

        <Route path={subPath(RouteDefinitions.Accounting)}>
          <Route index element={<Navigate to={subPath(RouteDefinitions.Revenues)} replace />} />
          <Route path={subPath(RouteDefinitions.Reporting)} element={<ReportingPanel />} />
          <Route path={subPath(RouteDefinitions.Payouts)} element={<PayoutPanel />} />
          <Route path={subPath(RouteDefinitions.Invoices)} element={<InvoicesTab />} />
          <Route path={subPath(RouteDefinitions.Clawbacks)} element={<ClawbacksPage />} />
          <Route path={subPath(RouteDefinitions.Revenues)} element={<RevenuePanel />}>
            <Route path={subPath(RouteDefinitions.RevenuesDetails)} element={<SundayPayoutSummaryDrawer />} />
          </Route>
        </Route>
        <Route path={subPath(RouteDefinitions.Reconciliation)}>
          <Route index element={<ReconciliationTabs />} />
          <Route path={subPath(RouteDefinitions.ReconciliationTabs)} element={<ReconciliationTabs />} />
          <Route path={subPath(RouteDefinitions.ReconciliationReports)}>
            <Route path={subPath(RouteDefinitions.ReconciliationReportById)} element={<ReconciliationReport />} />
          </Route>
        </Route>
        <Route path={subPath(RouteDefinitions.Bills)} element={<Bills />} />
        <Route path="orders" element={<Navigate to={RouteDefinitions.Bills.path} replace />} />
        <Route path={subPath(RouteDefinitions.OPOrders)} element={<OrderListPage />} />
        <Route path={subPath(RouteDefinitions.OPVoucherCodes)} element={<OPVoucherCodesPage />} />
        <Route path={subPath(RouteDefinitions.BoxStats)} element={<BoxStatsPage />} />
        <Route path={subPath(RouteDefinitions.VouchersClubHouse24)} element={<Vouchers />} />
        <Route path={subPath(RouteDefinitions.OPProductAvailability)} element={<OPProductAvailabilityPage />} />
        <Route path={subPath(RouteDefinitions.StaffPerformance)} element={<StaffPerformance />} />
        <Route path={subPath(RouteDefinitions.CommunicationPreferences)} element={<CommunicationPreferences />} />
        <Route path={subPath(RouteDefinitions.Kds)} element={<KdsPage />} />
        <Route path={subPath(RouteDefinitions.OpsTooling)}>
          <Route path={subPath(RouteDefinitions.TipsSetting)} element={<TipsConfigurationForProduct />} />
          <Route path={subPath(RouteDefinitions.PatSettings)} element={<InternalSettingsPaT />} />
          <Route path={subPath(RouteDefinitions.EnterpriseSettings)} element={<EnterpriseSettings />} />
          <Route path={subPath(RouteDefinitions.PosPaymentSettings)} element={<PaymentMethodsMapping />} />
        </Route>
        <Route path={subPath(RouteDefinitions.Onboarding)} element={<Onboarding />} />

        {/* Catch all venues route */}
        <Route path="*" element={<RedirectToHomePage />} />
      </Route>
      <Route path={RouteDefinitions.UsersFaq.path} element={<FAQ />} />

      {/* Catch all route */}
      <Route path="/*" element={<RedirectToRootPath />} />
    </Route>
  </Routes>
);
export default AuthenticatedRoutes;
