import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import SocialMediaHandler from './components/SocialMediaHandler';
import MixedMenusHandler from './components/MixedMenusHandler';
import {
  CustomizationBanner,
  EMPTY_WHITE_LABEL_CUSTOMIZATION,
  WhiteLabelCustomization,
} from 'src/domain/customization/WhiteLabelCustomization';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { VenueConfigurationKey } from '@sundayapp/global-configuration';
import { useWhiteLabelCustomization } from 'src/infrastructure/venue/useWhiteLabelCustomization';
import { useWhiteLabelSaveCustomization } from 'src/infrastructure/venue/useWhiteLabelSaveCustomization';
import { useCustomization } from './infrastructure/useCustomization';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ColorCustomization } from './ColorCustomization';
import { isMobileScreen } from 'src/app/theme/ThemeV5';
import { LoadingImage } from './LoadingImage';
import { MenuBanner } from './MenuBanner';
import { PostPayment } from './PostPayment';
import { SettingsPageContainer } from '../components/SettingsPageContainer';
import { Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import TabPanel from 'src/app/component/Tab/TabPanel';
import Spinner from 'src/ordering/common/components/Spinner';
import Divider from '@mui/material/Divider';
import { LandingPageCustomization } from 'src/pages/Settings/Customization/LandingPageCustomization';

const TabLabel = ({ label }: { label: string }) => <Typography variant="body1">{label}</Typography>;

const Customization = () => {
  const business = useCurrentBusinessOrThrow();
  const { data: globalConfiguration } = useGlobalConfiguration();
  const featureMixedMenus = globalConfiguration?.entries.get(VenueConfigurationKey.featureMixedMenus) ?? false;
  const intl = useIntl();
  const theme = useTheme();
  const { data: whiteLabelCustomizationData, isLoading } = useWhiteLabelCustomization();
  const whiteLabelCustomization = whiteLabelCustomizationData ?? EMPTY_WHITE_LABEL_CUSTOMIZATION;
  const { saveWhiteLabelCustomization } = useWhiteLabelSaveCustomization();
  const { data: customization } = useCustomization(business.patEnrollment?.id!);

  const snackBar = useSnackbar();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };


  const onSaveWhiteLabelCustomization = async (c: WhiteLabelCustomization) => {
    await saveWhiteLabelCustomization(business.patEnrollment?.id!, c);
    snackBar.addNotification({
      text: intl.formatMessage({ id: 'settings.customization.changes.saved' }),
      variant: 'success',
    });
  };

  // TODO use this when V1 is done : value of the toggle in Ops Dashboard > Pay at Table > Customization settings
  // const showCustomizationSettings = whiteLabelCustomization.activated && !isMobileScreen();

  const showCustomizationSettings = !isMobileScreen();
  if (isLoading) return <Spinner />;
  return (
    <SettingsPageContainer>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={(
          <TabLabel label={intl.formatMessage({ id: 'settings.app_settings.branding.title' })} />)} />
        <Tab label={(
          <TabLabel label={intl.formatMessage({ id: 'settings.app_settings.marketing.title' })} />)} />
      </Tabs>
      <TabPanel value={value.toString()} index="0">

        {showCustomizationSettings && (
          <ColorCustomization
            whiteLabelCustomization={whiteLabelCustomization}
            customization={customization}
            business={business}
          />
        )}

        <LandingPageCustomization
          customization={customization}
          business={business}
          previewBackgroundColor={whiteLabelCustomization.color.appBackground}
        ></LandingPageCustomization>

        {showCustomizationSettings && (
          <LoadingImage
            whiteLabelCustomization={whiteLabelCustomization}
            secondaryColor={whiteLabelCustomization.color.loadingPage}
            business={business}
            intl={intl}
            onSaveCustomizationImageUrl={(imageUrl) => {
              whiteLabelCustomization.loadingImgUrl = imageUrl;
              onSaveWhiteLabelCustomization(whiteLabelCustomization);
            }}
          />
        )}
      </TabPanel>
      <TabPanel value={value.toString()} index="1">

        <>
          <Stack spacing={4} padding={3} borderRadius={4} bgcolor={theme.palette.background.paper}>
            {showCustomizationSettings && (
              <MenuBanner
                business={business}
                intl={intl}
                whiteLabelCustomization={whiteLabelCustomization}
                customization={customization}
                onSaveBanner={(b: CustomizationBanner) => {
                  whiteLabelCustomization.burgerBanner = b;
                  onSaveWhiteLabelCustomization(whiteLabelCustomization);
                }}
              />)
            }
            <Divider />
            <SocialMediaHandler />

          </Stack>
          {showCustomizationSettings && (
            <PostPayment
              whiteLabelCustomization={whiteLabelCustomization}
              customization={customization}
              business={business}
              onSaveBanner={(b: CustomizationBanner) => {
                whiteLabelCustomization.postPaymentBanner = b;
                onSaveWhiteLabelCustomization(whiteLabelCustomization);
              }}
              intl={intl}
            />)}
        </>

        {featureMixedMenus && <MixedMenusHandler enrollmentId={business.patEnrollment?.id!} />}

      </TabPanel>
    </SettingsPageContainer>
  );
};

export default Customization;
