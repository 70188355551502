import { useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { isUs } from 'src/payments/components/Checkout/CheckoutOnboarding/IsUs';

const useRemoveNationalIdNumberOnCountryOfResidenceChange = ({ representativeIndex }: {
  representativeIndex: number
}) => {
  const { watch, unregister } = useFormContext<CheckoutOnboardingInformationDraft>();
  const country = watch(`representatives.${representativeIndex}.address.country`);
  useEffect(() => {
    if (country !== 'US') {
      unregister(`representatives.${representativeIndex}.nationalIdNumber`);
    }
  }, [country, representativeIndex]);
};


const NationalIdNumber = ({ legalRepresentativeIndex }: {
  legalRepresentativeIndex: number
}) => {
  const intl = useIntl();
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[legalRepresentativeIndex]?.nationalIdNumber;
  return <TextField
    required
    error={!!error}
    InputLabelProps={{ shrink: true }}
    {...register(`representatives.${legalRepresentativeIndex}.nationalIdNumber`, {
      pattern: {
        value: /^\d{9}$/,
        message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.nationalIdNumber.error.pattern' }),
      },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.nationalIdNumber' })}
    helperText={error?.message || intl.formatMessage({ id: 'settings.payments.checkout.onboarding.representatives.nationalIdNumber.helperText' })}
  />;
};

export const needsNationalIdNumber = (businessCountryCode: string, residenceCountryCode: string) => isUs(businessCountryCode) && isUs(residenceCountryCode);

export const NationalIdNumberIfUS = ({ businessCountryCode, representativeIndex }: {
  businessCountryCode: string,
  representativeIndex: number
}) => {
  const { watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  useRemoveNationalIdNumberOnCountryOfResidenceChange({ representativeIndex });
  const residenceCountryCode = watch(`representatives.${representativeIndex}.address.country`);

  return <>
    {needsNationalIdNumber(businessCountryCode, residenceCountryCode ?? '') &&
      <NationalIdNumber legalRepresentativeIndex={representativeIndex} />}
  </>;
};
