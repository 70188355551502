import axios from 'axios';
import { BoxRepository } from './BoxRepository';
import { Box } from '../domain/Box';
import { BoxDetails, BoxDetailsFields } from '../domain/BoxDetails';
import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import { TimePeriod } from '../../common/domain/TimePeriod';
import { EnrollmentId } from 'src/business/domain/Enrollment';

interface BoxesResult {
  boxes: Box[];
}

export class BoxRepositoryHttp extends RepositoryHttp implements BoxRepository {
  constructor(private venueBackendUrl: string) {
    super();
  }

  async updateBoxDetails(boxId: string, update: Partial<BoxDetailsFields>): Promise<void> {
    await axios.put<void>(`${this.venueBackendUrl}/boxes/${boxId}`, update, this.withDefaultHeader());
  }

  async updateBoxOpeningHours(boxId: string, openingHours: TimePeriod[]): Promise<void> {
    await axios.post<void>(
      `${this.venueBackendUrl}/boxes/${boxId}/opening-hours`,
      { boxOpeningHours: openingHours },
      this.withDefaultHeader(),
    );
  }

  async getVenueBoxes(enrollmentId: EnrollmentId): Promise<Box[]> {
    const response = await axios.get<BoxesResult>(
      `${this.venueBackendUrl}/enrollments/${enrollmentId}/boxes`,
      this.withDefaultHeader(),
    );

    return response.data.boxes;
  }

  async getBoxDetails(boxId: string): Promise<BoxDetails> {
    const response = await axios.get<BoxDetails>(`${this.venueBackendUrl}/boxes/${boxId}`, this.withDefaultHeader());

    return response.data;
  }

  async createBox(enrollmentId: EnrollmentId, boxName: string): Promise<Box> {
    const response = await axios.post<string>(
      `${this.venueBackendUrl}/enrollments/${enrollmentId}/boxes`,
      { name: boxName },
      this.withDefaultHeader(),
    );

    return {
      id: response.data,
      name: boxName,
      boxOpeningHours: [],
      active: false,
      linkId: null,
      posIntegration: { type: 'NOT_CONNECTED' },
    };
  }

  async deleteBox(boxId: string): Promise<void> {
    return axios.delete(`${this.venueBackendUrl}/boxes/${boxId}`, this.withDefaultHeader());
  }
}
