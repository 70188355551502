enum SizeUnitType {
  TShirt = 'TShirt',
  Pixels = 'Pixels',
  REM = 'REM',
}

export type Size = TShirt | Pixels | REM;

// -----------------------------
// TShirt
// -----------------------------

export const tShirt = (value: TShirtSize): Size => ({
  type: SizeUnitType.TShirt,
  value,
});

type TShirt = {
  type: SizeUnitType.TShirt;
  value: TShirtSize;
};

type TShirtSize = 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl';

const tShirtInCss = (size: TShirt): string => {
  switch (size.value) {
    case 'xs':
      return `${4}px`;
    case 's':
      return `${8}px`;
    case 'm':
      return `${16}px`;
    case 'l':
      return `${20}px`;
    case 'xl':
      return `${24}px`;
    case '2xl':
      return `${32}px`;
    case '3xl':
      return `${80}px`;
  }
};

// -----------------------------
// Pixels
// -----------------------------

type Pixels = { type: SizeUnitType.Pixels; value: number };

export const pixels = (px: number): Size => ({
  type: SizeUnitType.Pixels,
  value: px,
});

const pixelsInCss = (size: Pixels) => `${size.value}px`;

export const none = () => pixels(0);

// -----------------------------
// EM
// -----------------------------

type REM = { type: SizeUnitType.REM; value: number };

export const rem = (size: number): Size => ({
  type: SizeUnitType.REM,
  value: size,
});

const remInCss = (size: REM) => `${size.value}rem`;

export const sizeInCss = (size: Size): string => {
  switch (size.type) {
    case SizeUnitType.TShirt:
      return tShirtInCss(size);
    case SizeUnitType.Pixels:
      return pixelsInCss(size);
    case SizeUnitType.REM:
      return remInCss(size);
  }
};
