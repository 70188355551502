import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useAuthentication } from './useAuthentication';
import { RouteDefinitions } from 'src/app/RouteDefinitions';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const authentication = useAuthentication();
  const navigate = useNavigate();

  return async () => {
    queryClient.removeQueries();
    navigate(RouteDefinitions.SignOut.path);
    await authentication.logOut();
  };
};
