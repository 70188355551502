import { AuthenticatedUser, RelationType, relationTypes, User } from 'src/auth/domain/user';
import { BusinessId } from 'src/business/domain/Business';

export const hasAccessToMultiLocations = (user: User) => {
  return user.authenticated && user.relations.length >= 2;
};

export const businessIdsForMultiLocations = (
  user: AuthenticatedUser,
  withRelationType?: RelationType,
): BusinessId[] => {
  return user.relations
    .filter((r) => withRelationType === null || r.relation === relationTypes.can_access_financials)
    .map((r) => r.objectId as BusinessId);
};
