import { PaymentAccountResponse, PaymentLinkFeeRuleType, paymentLinkFeeRuleTypes } from './PaymentRepositoryHttp';
import { PaymentAccount } from '../domain/PaymentAccount';

export const mapPaymentResponse = (paymentAccountResponse: PaymentAccountResponse): PaymentAccount => {
  const paymentLinkFeeRule = (ruleType: PaymentLinkFeeRuleType) => paymentAccountResponse.sundayFees.paymentLinkRules
    .find((rule) => rule.value === ruleType);
  
  return new PaymentAccount(
    paymentAccountResponse.pspAccounts,
    paymentAccountResponse.sundayFees.digitalFeeRate,
    paymentAccountResponse.stripeOnboardingAllowed,
    paymentAccountResponse.canAcceptPayments,
    {
      premium: paymentLinkFeeRule(paymentLinkFeeRuleTypes.PAYMENT_LINK_PREMIUM)?.feeRate ?? 0,
      standard: paymentLinkFeeRule(paymentLinkFeeRuleTypes.PAYMENT_LINK)?.feeRate ?? 0,
    },
    paymentAccountResponse.sundayFees.digitalFeeCapping || undefined,
  );
};
