import styled, { css } from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';

export const Table = styled.table`
  //width: 100%;
  border-spacing: 0 2px;
`;

interface LineProps {
  selected?: boolean;
}

export const Line = styled.tr<LineProps>`
  ${({ selected }) =>
    selected
    && css`
      position: relative;
      background-color: ${colorPalette.defaultBackgroundColor};
      &:after {
        content: '';
        position: absolute;
        display: block;
        top: -2px;
        right: -1px;
        width: 1px;
        height: 100%;
        padding-bottom: 1px;
        background-color: ${colorPalette.defaultBackgroundColor};
      }
    `}
`;

export const HeaderCell = styled.th`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 8px 16px;
  border-bottom: 1px solid ${colorPalette.lightBorderColor};
  color: ${colorPalette.placeholderColor};
  ${({ align }) => css`
    text-align: ${align};
  `}
  &:first-child {
    padding-left: ${getSpacing(4)};
  }
  &:last-child {
    padding-right: ${getSpacing(5)};
  }
`;

export const Cell = styled.td`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  border-bottom: 1px solid ${colorPalette.lightBorderColor};
  height: 80px;
  padding: 0 16px;
  ${({ align }) => css`
    text-align: ${align};
  `}
  &:first-child {
    padding-left: ${getSpacing(4)};
  }
  &:last-child {
    padding-right: ${getSpacing(5)};
  }
`;

export const ChildCell = styled(Cell)`
  position: relative;
`;

type ChildLinkProps = {
  isFirst: boolean;
};

export const ChildLink = styled.div<ChildLinkProps>`
  border-left: 1px solid ${colorPalette.grey600};
  width: 1px;
  height: ${({ isFirst }) => (isFirst ? getSpacing(7.5) : getSpacing(12))};
  position: absolute;
  top: ${({ isFirst }) => (isFirst ? getSpacing(-3) : getSpacing(-7))};
  left: ${getSpacing(4)};
`;
