import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import DownloadIcon from '@mui/icons-material/Download';
import { useConfiguration } from '../useConfiguration';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { downloadBlob } from '../utils';
import { Level } from 'src/utils/logs/plugins';
import { RevenuePeriod } from '../../../revenues/domain/RevenuesDatePresets';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { Button, CircularProgress } from '@mui/material';

interface Props {
  period: RevenuePeriod;
  onError: () => void;
}

export const DownloadExport: React.FC<Props> = ({ period, onError }: Props) => {
  const [inProgress, setInProgress] = useState<boolean>(false);

  const businessId = useBusinessIdOrThrow();
  const configuration = useConfiguration();
  const log = useLog();
  const intl = useIntl();

  const downloadExport = () => {
    setInProgress(true);
    configuration.payoutPort
      .getPayoutsExport(businessId, period.dateRange.startDate.toDate(), period.dateRange.endDate.toDate())
      .then((blobWithMetadata) => {
        downloadBlob(blobWithMetadata, `${intl.formatMessage({ id: 'accounting.payout_tab.export_file' })}.csv`);
      })
      .catch((error) => {
        log(Level.ERROR, `Could not retrieve payouts summary export for venue id ${businessId} (${period.filter})`, error);
        onError();
      })
      .finally(() => setInProgress(false));
  };

  useEffect(() => () => {
    setInProgress(false);
  }, []);

  return (
    <>
      <Button variant="contained" size="medium" onClick={() => !inProgress && downloadExport()}
              data-testid="download-export-button" startIcon={<DownloadIcon />}>
        {inProgress ? (
          <CircularProgress size="large" />
        ) : (
          <FormattedMessage id="accounting.payout_tab.download_reports" />
        )}
      </Button>
    </>
  );
};
