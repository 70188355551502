export enum TaskStatus {
  STARTED = 'STARTED',
  TERMINATING = 'TERMINATING',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export function isTaskOngoing(taskStatus: TaskStatus): boolean {
  return taskStatus === TaskStatus.STARTED || taskStatus === TaskStatus.TERMINATING;
}

export function isTaskSucceeded(taskStatus: TaskStatus): boolean {
  return taskStatus === TaskStatus.SUCCEEDED;
}

export function isTaskFailed(taskStatus: TaskStatus): boolean {
  return taskStatus === TaskStatus.FAILED;
}

export function isTaskTerminated(taskStatus: TaskStatus): boolean {
  return taskStatus === TaskStatus.FAILED || taskStatus === TaskStatus.SUCCEEDED;
}

export type TaskType
  = 'SYNC_CATALOG_FROM_EXTERNAL_SOURCE'
  | 'ADD_TRANSLATION_LANGUAGE'
  | 'DELETE_PRODUCTS';

export type Task = {
  id: string;
  type: TaskType;
  status: TaskStatus;
  startedAt: Date;
  finishedAt?: Date;
};
