import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { ExpandablePanelButton } from './ExpandablePanelButton';

type SettingExpandablePanelProps = {
  visible: boolean;
  expanded: boolean;
  expandChange: (value: boolean) => void;
  children: ReactNode;
};

const ExpandSettingHeader = styled.div`
  margin-top: ${getSpacing(1)};
  margin-bottom: ${getSpacing(2)};
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
`;

const ExpandSettingLabel = styled.div`
  margin-right: ${getSpacing(1)};
  color: ${colorPalette.grey600};
`;

const ExpandSettingPanel = styled.div`
  padding-top: ${getSpacing(1)};
`;

export const SettingExpandablePanel = ({
  visible, expanded, expandChange, children,
}: SettingExpandablePanelProps) => {
  if (!visible) {
    return <></>;
  }

  return (
    <>
      <ExpandSettingHeader onClick={() => expandChange(!expanded)}>
        <ExpandSettingLabel>
          <FormattedMessage id="settings.expand-all-setting" defaultMessage="see all settings" />
        </ExpandSettingLabel>
        <ExpandablePanelButton expand={expandChange} expanded={expanded} />
      </ExpandSettingHeader>
      {expanded && <ExpandSettingPanel>{children}</ExpandSettingPanel>}
    </>
  );
};
