import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Button, CircularProgress, Input, Typography } from '@mui/material';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Menu } from '../../../domain/Menu';
import { useUpdateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

type MenuShortDescSettingsProps = {
  menu: Menu;
};

export const MenuShortDescSettings = ({ menu }: MenuShortDescSettingsProps) => {
  const intl = useIntl();
  const useSnackBar = useSnackbar();

  const business = useCurrentBusinessOrThrow();

  const updateMenu = useUpdateMenuMutation(business.id, menu.id);
  const [menuShortDesc, setMenuShortDesc] = useState<string>(menu.shortDescription ?? '');
  const [menuShortDescError, setMenuShortDescError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const hasChanged = useMemo(
    () => (menu.shortDescription ?? '').localeCompare(menuShortDesc) !== 0,
    [menu.shortDescription, menuShortDesc],
  );

  const onClickUpdateMenuShortDesc = async () => {
    const cleanedMenuShortDesc = menuShortDesc.trim();
    setMenuShortDescError('');
    setLoading(true);
    updateMenu
      .mutateAsync({ shortDescription: cleanedMenuShortDesc })
      .then(() => {
        setMenuShortDesc(cleanedMenuShortDesc);
        useSnackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({
            id: 'menus.settings.shortDesc.notification',
            defaultMessage: 'menu short description updated',
          }),
        });
      })
      .catch(() => {
        setMenuShortDescError(
          intl.formatMessage({
            id: 'menus.settings.shortDesc.update-error',
            defaultMessage: 'unable to update short description ',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const onMenuShortDescChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenuShortDesc(event.target.value);
  };

  return (
    <Box>
      <FormattedMessage id="menus.settings.shortDesc.label" defaultMessage="short description of your menu" />

      <Box flexDirection="row" display="flex">
        <Input
          onChange={onMenuShortDescChange}
          error={!!menuShortDescError}
          sx={{ width: '300px' }}
          value={menuShortDesc}
          placeholder={intl.formatMessage({
            id: 'menus.settings.shortDesc.placeholder',
            defaultMessage: 'input the short description of your menu',
          })}
        />

        {hasChanged && (
          <Button size="small" onClick={onClickUpdateMenuShortDesc} disabled={loading} variant="contained">
            <FormattedMessage id="menus.settings.save-btn" defaultMessage="save" />
          </Button>
        )}
        {loading && <CircularProgress />}
      </Box>
      {menuShortDescError && (
        <Typography variant="body1" color="error" component="div">
          {menuShortDescError}
        </Typography>
      )}
    </Box>
  );
};
