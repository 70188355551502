import { Chip, CircularProgress, styled } from '@mui/material';
import React from 'react';
import { BusinessSelectionIcon } from './BusinessSelectionIcon';
import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import { usePatCustomization } from 'src/pages/Settings/Customization/infrastructure/useCustomization';
import { BusinessId } from '../domain/Business';
import { useIntl } from 'src/app/i18n/TypedIntl';

export type BusinessSelectionItemProps = {
  id: BusinessId;
  name: string;
  isCurrent?: boolean;
  isCompact?: boolean;
  iconSize?: number;
  isSuspended: boolean;
};
type ItempProp = {
  isCurrent: boolean;
};
const Item = styled('div')<ItempProp>(({ isCurrent }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  gap: '12px',
  alignItems: 'center',
  maxWidth: isCurrent ? '160px' : '100%',
}));

const Name = styled('span')`
  white-space: break-spaces;
`;

const CurrentName = styled(Name)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export function BusinessSelectionItem({
  id,
  name,
  isCompact = false,
  isCurrent = false,
  iconSize = 24,
  isSuspended,
}: BusinessSelectionItemProps) {
  const { data: customization } = usePatCustomization(id);
  const intl = useIntl();
  if (!customization) {
    return (
      <Item isCurrent={isCurrent}>
        <CircularProgress style={{ alignSelf: 'center' }} />
      </Item>
    );
  }

  const getCurrentName = () => (
    <ThemeTooltip title={name} placement="right">
      <CurrentName>{name}</CurrentName>
    </ThemeTooltip>
  );

  const getName = () => <Name>{name}</Name>;

  return (
    <Item isCurrent={isCurrent}>
      <BusinessSelectionIcon name={name} imageUrl={customization.logo?.logoUrls?.noResize} size={iconSize} />
      {!isCompact && (
        <>
          {isCurrent ? getCurrentName() : getName()}{' '}
          {isSuspended && (
            <Chip color={'error'} label={intl.formatMessage({ id: 'business_selection.archived' })} size={'small'} />
          )}
        </>
      )}
    </Item>
  );
}
