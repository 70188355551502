import { Button } from '@mui/material';
import { GetApp } from '@mui/icons-material';
import CsvDownloader from 'react-csv-downloader';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { TableDevice } from 'src/floorplan/domain/TableDevice';

interface CSVExporterButtonProps {
  venueId: string,
  venueName: string,
  listableTableDevices: TableDevice[]
}

function QRCodesCSVExporterButton({ venueId, venueName, listableTableDevices }: CSVExporterButtonProps) {
  function buildFileName() {
    const isoFormattedDate = new Date(Date.now()).toISOString();
    const fileNameFormattedVenueName = venueName.toLowerCase()
      .replace(' ', '-');

    return `${isoFormattedDate}-${fileNameFormattedVenueName}-floor-plan`;
  }

  function formatTableDevices() {
    return listableTableDevices.map((tableDevice) => ({
      venueId,
      venueName,
      tableNumber: tableDevice.number,
      qrCodeLink: tableDevice.shortLink,
    }));
  }

  const intl = useIntl();

  const columnsDefinition = [{
    id: 'venueId',
    displayName: intl.formatMessage({ id: 'qr_codes.venue_id' }),
  }, {
    id: 'venueName',
    displayName: intl.formatMessage({ id: 'qr_codes.venue_name' }),
  }, {
    id: 'tableNumber',
    displayName: intl.formatMessage({ id: 'qr_codes.table_number' }),
  }, {
    id: 'qrCodeLink',
    displayName: intl.formatMessage({ id: 'qr_codes.qr_code_link' }),
  }];

  return (
    <CsvDownloader
      filename={buildFileName()}
      wrapColumnChar='"'
      columns={columnsDefinition}
      datas={formatTableDevices()}
    >
      <Button
        style={{ borderRadius: '48px', backgroundColor: 'black', color: 'white' }}
        variant="outlined"
        color="primary"
        startIcon={<GetApp />}
      >
        <FormattedMessage id="qr_codes.export_csv" />
      </Button>
    </CsvDownloader>
  );
}

export default QRCodesCSVExporterButton;
