export const formatDate = (date: Date, timezone: string) => ({
  date: date.toLocaleDateString(navigator.language, {
    month: 'long',
    day: 'numeric',
    timeZone: timezone,
  }),
  time: date.toLocaleTimeString(navigator.language, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timezone,
  }),
});
