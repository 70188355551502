import { Product, ProductSource, productTypes } from './Product';
import { ProductSummary } from './ProductSummary';

class OrderingEligibleProductValidator {
  constructor(
    private product: Product,
    private products: ProductSummary[],
  ) {
  }

  hasSku = (): boolean => !!this.product.sku;

  isPartnerProduct = (): boolean => this.product.source === ProductSource.PARTNER;

  isProduct(): boolean {
    return (
      this.product.type === productTypes.PRODUCT &&
      this.product.subProductIds.every((subProductId: string) => {
        return this.isSubProductType(subProductId, [productTypes.VARIANT_GROUP, productTypes.MODIFIER_GROUP]);
      })
    );
  }

  isVariantGroup(): boolean {
    return (
      this.product.type === productTypes.VARIANT_GROUP &&
      !!this.product.subProductIds.length &&
      this.product.subProductIds.every((subProductId: string) => {
        return this.isSubProductType(subProductId, [productTypes.VARIANT, productTypes.PRODUCT]);
      })
    );
  }

  private isSubProductType(subProductId: string, types: string[]) {
    const subProduct = this.products.find(({ id }) => id === subProductId);
    return subProduct ? types.includes(subProduct.type) : false;
  }
}

export function isEligibleForOrder(product: Product, products: ProductSummary[]) {
  const validator = new OrderingEligibleProductValidator(product, products);

  const isPartnerProduct =
    validator.hasSku() && validator.isPartnerProduct() && (validator.isProduct() || validator.isVariantGroup());

  const isSundayVariantGroup = !validator.isPartnerProduct() && validator.isVariantGroup();

  return isPartnerProduct || isSundayVariantGroup;
}
