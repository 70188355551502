import axios from 'axios';
import { StaticMenuRepository } from './StaticMenuRepository';
import { BusinessId } from 'src/business/domain/Business';
import { MenuConstraints, parseStaticMenus, StaticMenu, StaticMenuId, StaticMenuIdSchema } from '../domain/StaticMenus';
import { z } from 'zod';

const CreatedMenuIdSchema = z.object({ menuId: StaticMenuIdSchema });
const parseCreatedMenuId = (data: any) => {
  return CreatedMenuIdSchema.parse(data);
};

export class StaticMenuRepositoryHttp implements StaticMenuRepository {
  constructor(private readonly baseUrl: string) {}

  public async listMenus(businessId: BusinessId): Promise<Array<StaticMenu>> {
    const response = await axios.get(`${this.baseUrl}/businesses/${businessId}/static-menus`);
    return parseStaticMenus(response.data).menus;
  }

  async createMenu(businessId: BusinessId, description: string): Promise<StaticMenuId> {
    const response = await axios.put(`${this.baseUrl}/businesses/${businessId}/static-menus`, { description });
    return parseCreatedMenuId(response.data).menuId;
  }

  async updateMenu(
    businessId: BusinessId,
    menuId: StaticMenuId,
    description: string,
    descriptionDetails: string,
    visibility: boolean,
    constraints: MenuConstraints,
  ) {
    await axios.post(`${this.baseUrl}/businesses/${businessId}/static-menus/${menuId}`, {
      description,
      descriptionDetails,
      visibility,
      constraints,
    });
  }

  async deleteMenu(businessId: BusinessId, menuId: StaticMenuId) {
    await axios.delete(`${this.baseUrl}/businesses/${businessId}/static-menus/${menuId}`);
  }

  async reorderMenus(businessId: BusinessId, orderOfMenus: StaticMenuId[]) {
    await axios.post(`${this.baseUrl}/businesses/${businessId}/static-menus/order`, { orderOfMenus });
  }

  async setMenuContent(businessId: BusinessId, parse: StaticMenuId, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    await axios.post(`${this.baseUrl}/businesses/${businessId}/static-menus/${parse}/content`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
