import { AxiosInstance } from 'axios';
import { Configuration } from 'src/configuration/Configuration';
import { NewPaymentLink, NewPaymentLinkResponse, PaymentLink } from '../domain/PaymentLink';
import { BusinessId } from 'src/business/domain/Business';

export class PaymentLinkRepository {
  constructor(
    private axios: AxiosInstance,
    private configuration: Configuration,
  ) {
  }

  async create(paymentLink: NewPaymentLink): Promise<NewPaymentLinkResponse> {
    const response = await this.axios.post<NewPaymentLinkResponse>(
      `${this.configuration.paymentApiBaseUrl}/v2/payment-links/sessions`,
      paymentLink,
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );

    return response.data;
  }

  async findAll(businessId: BusinessId): Promise<PaymentLink[]> {
    const response = await this.axios.get<PaymentLink[]>(
      `${this.configuration.paymentApiBaseUrl}/v2/payment-links/sessions?businessId=${businessId}`,
      {
        headers: {
          contentType: 'application/json',
        },
      },
    );

    return response.data;
  }

  async drop(paymentLink: PaymentLink) {
    await this.axios.delete(`${this.configuration.paymentApiBaseUrl}/v2/payment-links/sessions/${paymentLink.sessionId}`);
  }

  async sendLinkToPayEmail(sessionId: PaymentLink['sessionId'], email: string) {
    await this.axios.put(`${this.configuration.paymentApiBaseUrl}/v2/payment-links/sessions/${sessionId}/send-email`, {
      email,
    });
  }
}
