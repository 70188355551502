import { styled } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { BlockImagePicker } from './components/BlockImagePicker';
import { BlockDetailsPanel } from './BlockDetailsPanel';
import { BlocksMenuPanel } from './components/BlocksMenuPanel';
import { BlockHeader } from './BlockHeader';
import { findLanguage, Language } from 'src/app/i18n/AllSupportedLanguage';
import { useGetMenuBusiness } from '../../../common/queries/getMenuBusinessQuery';
import { useGetBlockById } from '../../queries/block/getBlockByIdQuery';
import { BlockPreview } from './components/BlockPreview';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const PageContainer = styled('div')`
  display: flex;
  flex-direction: column;
`;
const BlockGrid = styled('div')`
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-template-rows: auto auto auto auto;
  gap: 32px 0;
  column-gap: 32px;
  grid-template-areas:
  "pos_block_details pos_block_details "
  "left_pane right_pane "
`;

const LeftPane = styled('div')`
  grid-area: left_pane;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const RightPane = styled('div')`
  grid-area: right_pane;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

function getCurrentLanguageOrUndefinedIfDefault(currentLanguage: Language | undefined, defaultLanguage: Language | undefined) {
  if (!defaultLanguage || !currentLanguage) return undefined;
  return currentLanguage.id !== defaultLanguage.id ? currentLanguage.id : undefined;
}

export const BlockPage = () => {
  const { blockId } = useParams();
  const business = useCurrentBusinessOrThrow();
  const [currentLanguage, setCurrentLanguage] = useState<Language>();
  const [defaultLanguage, setDefaultLanguage] = useState(findLanguage('FR'));
  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const { data: menuBusiness } = useGetMenuBusiness(business.id);
  const { state } = useLocation();
  const contextBlockList = state?.contextBlockList ?? [];
  const {
    data: block,
    isSuccess: blockSuccess,
    isLoading: blockLoading,
  } = useGetBlockById(business.id, blockId!, getCurrentLanguageOrUndefinedIfDefault(currentLanguage, menuBusiness?.defaultLanguage));

  useEffect(() => {
    setDefaultLanguage(menuBusiness?.defaultLanguage ?? findLanguage('FR'));
    setSupportedLanguages(menuBusiness?.translatedLanguages ?? []);
  }, [menuBusiness]);

  if (blockLoading) return <CircularProgress />;
  if (!blockSuccess || !block) return <Box>Unable to load data...</Box>;

  if (!currentLanguage) setCurrentLanguage(defaultLanguage);
  const isCurrentLanguageATranslatedLanguage = currentLanguage?.id !== defaultLanguage.id;
  return (
    <PageContainer>
      {blockLoading && <CircularProgress />}
      <BlockHeader
        block={block!}
        defaultLanguage={defaultLanguage}
        supportedLanguages={supportedLanguages}
        currentLanguage={currentLanguage}
        setCurrentLanguage={setCurrentLanguage}
        contextBlockList={contextBlockList ?? []}
      />
      <BlockGrid>
        <LeftPane>
          <BlockDetailsPanel
            businessId={business.id}
            block={block}
            currentLanguage={currentLanguage}
            translationMode={isCurrentLanguageATranslatedLanguage}
          />
        </LeftPane>

        <RightPane>
          <BlockPreview businessId={business.id} block={block} />
          <BlockImagePicker
            disabled={isCurrentLanguageATranslatedLanguage}
            businessId={business.id}
            block={block}
          />
          <BlocksMenuPanel businessId={business.id} block={block} />
        </RightPane>
      </BlockGrid>
    </PageContainer>
  );
};
