import styled from 'styled-components';
import React, { useState } from 'react';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Box } from '../../../box/domain/Box';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { DownArrowIcon, UpArrowIcon } from '../../../common/components/Icons';
import { useUpdateBoxDetails } from '../../../box/hooks';
import { BoxOptions } from './BoxOptions';
import Input from '../../../common/components/Input';
import { CircularProgress, Switch } from '@mui/material';

type BoxPanelProps = {
  box: Box;
  setBox: (box: Box) => void;
  boxRank: number;
  boxCount: number;

  isUpdating: boolean;

  moveUp: (index: number) => void;
  moveDown: (index: number) => void;

  onDelete: (box: Box) => void;
};

type BoxContainerProps = {
  isNotConnected: boolean;
};

const BoxContainer = styled.div<BoxContainerProps>`
  display: flex;
  flex-direction: row;
  padding: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  border: 1px solid ${(props) => (props.isNotConnected ? colorPalette.red : colorPalette.lightBorderColor)};
  border-radius: ${getSpacing(2)};
  justify-content: space-between;
  align-items: center;
`;

const BoxInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

type BoxNameProps = {
  disabled: boolean;
};

const BoxDetails = styled.div`
  margin-left: ${getSpacing(2)};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const BoxName = styled.div<BoxNameProps>`
  margin-left: ${getSpacing(1)};
  display: flex;
  flex-direction: row;
  justify-content: start;
  color: ${(props) => (props.disabled ? colorPalette.grey400 : '')};
`;

const BoxErrorLabel = styled.span`
  color: ${colorPalette.red};
  margin-left: ${getSpacing(1)};
`;

const Commands = styled.div`
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
  margin-right: ${getSpacing(2)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getSpacing(2)};
  border: 1px solid ${colorPalette.grey300};
  cursor: pointer;
`;

const CommandSeparator = styled.div`
  border-right: 1px solid ${colorPalette.grey300};
  height: ${getSpacing(4)};
  margin-right: ${getSpacing(2)};
`;

const Block = styled.div`
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
`;

const SaveButton = styled(Button)`
  min-width: ${getSpacing(10)};
  margin-left: ${getSpacing(2)};
`;

export const BoxPanel = ({
  box, setBox, boxRank, boxCount, isUpdating, moveUp, moveDown, onDelete,
}: BoxPanelProps) => {
  const [boxName, setBoxName] = useState<string>(box.displayName || box.name);

  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);

  const onBoxToggle = async (boxId: string, updatedValue: boolean) => {
    await updateBoxDetails({ active: updatedValue });

    setBox({ ...box, active: updatedValue });
  };

  const updateBoxName = async (newBoxName: string) => {
    await updateBoxDetails({
      ...{ displayName: newBoxName },
    });
    setBox({ ...box, displayName: newBoxName });
  };

  const isNotConnected = box.posIntegration.type === 'NOT_CONNECTED';
  const nameChanged = boxName !== box.displayName;

  return (
    <BoxContainer isNotConnected={isNotConnected}>
      <BoxInfo>
        <Switch onChange={(_, updatedValue: boolean) => onBoxToggle(box.id, updatedValue)} checked={box.active} />
        <BoxDetails>
          <BoxName disabled={!box.active || isNotConnected}>
            <Input value={boxName} onValueChange={setBoxName} disabled={isUpdating} />
            {nameChanged && (
              <SaveButton
                variant="primary"
                size="xSmall"
                onClick={() => updateBoxName(boxName)}
                disabled={isUpdating}
              >
                <FormattedMessage id="box.settings.save" defaultMessage="Save" />
              </SaveButton>
            )}
          </BoxName>
          {isNotConnected && (
            <BoxErrorLabel>
              <FormattedMessage id="foodcourt.box.no-link.label" defaultMessage="link with pos location is needed" />
            </BoxErrorLabel>
          )}
        </BoxDetails>
      </BoxInfo>

      {isUpdating ? (
        <CircularProgress size="medium" />
      ) : (
        <Commands>
          {boxRank > 0 && (
            <Circle>
              <UpArrowIcon onClick={() => moveUp(boxRank)} />
            </Circle>
          )}
          {boxRank < boxCount - 1 ? (
            <Circle>
              <DownArrowIcon onClick={() => moveDown(boxRank)} />
            </Circle>
          ) : (
            <Block />
          )}
          <CommandSeparator />
          <BoxOptions box={box} onDelete={onDelete} />
        </Commands>
      )}
    </BoxContainer>
  );
};
