import { useContext, useMemo } from 'react';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import { AreaConfiguration } from './domain/AreaConfiguration';
import { getAreaFulfillmentMethod } from './utils/getAreaFulfillmentMethod';
import { AreaConfigContext } from './configuration/AreaContextProvider';
import { Area } from './domain/Area';
import { SundayDevice } from './domain/SundayDevice';

export const useAreaFulfillmentMethods = (areaConfigurations: AreaConfiguration[]) =>
  useMemo(() => getAreaFulfillmentMethod(areaConfigurations), [areaConfigurations]);

export const useAreaRepository = () => {
  const { areaRepository } = useContext(AreaConfigContext);
  return areaRepository!;
};

export const useGetBoxAreas = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<Area[], [boxId: string]>(
    async (boxId: string) => areaRepository.getBoxAreas(boxId),
    [areaRepository],
  );
};

export const useGetBoxArea = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<Area | undefined, [boxId: string, areaId: string]>(
    async (boxId: string, areaId: string) => areaRepository.getArea(boxId, areaId),
    [areaRepository],
  );
};

export const useDeleteArea = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<void, [areaId: string]>(
    async (areaId: string) => areaRepository.deleteArea(areaId),
    [areaRepository],
  );
};

export const useCreateArea = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<string, [boxId: string, areaName: string]>(
    async (boxId: string, areaName: string) => areaRepository.createArea(boxId, areaName),
    [areaRepository],
  );
};

export const useUpdateAreaConfigurations = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<void, [areaId: string, configurations: AreaConfiguration[]]>(
    async (areaId: string, configurations: AreaConfiguration[]) =>
      areaRepository.updateAreaConfigurations(areaId, configurations),
    [areaRepository],
  );
};

export const useUpdateAreaName = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<void, [area: Area, name: string]>(
    async (area: Area, updateAreaName: string) => areaRepository.updateAreaName(area, updateAreaName),
    [areaRepository],
  );
};

export const useAssociateAreaTables = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<void, [areaId: string, tableIds: string[]]>(
    async (areaId: string, tableIds: string[]) => {
      const promises = tableIds.map((tableId) => areaRepository.associateTable(areaId, tableId));
      await Promise.all(promises);
    },
    [areaRepository],
  );
};

export const useGetAreaSundayDevices = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<SundayDevice[], [areaId: string]>(
    async (areaId: string) => areaRepository.getAreaSundayDevices(areaId),
    [areaRepository],
  );
};

export const useGetTableSundayDevices = () => {
  const areaRepository = useAreaRepository();
  return useAsyncFn<SundayDevice[], [areaId: string]>(
    async (areaId: string) => areaRepository.geTableSundayDevices(areaId),
    [areaRepository],
  );
};
