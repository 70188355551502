/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function AccountingAdjustmentIcon(props: PaymentMethodIconProps) {
  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect x="0.5" y="0.5" width="39" height="23" rx="2.5" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.6945 5.25C14.2046 5.25 14.6181 5.66352 14.6181 6.17362V8.02085H16.4653C16.9754 8.02085 17.3889 8.43437 17.3889 8.94447C17.3889 9.45457 16.9754 9.86808 16.4653 9.86808H14.6181V11.7153C14.6181 12.2254 14.2046 12.6389 13.6945 12.6389C13.1844 12.6389 12.7708 12.2254 12.7708 11.7153V9.86808H10.9236C10.4135 9.86808 10 9.45457 10 8.94447C10 8.43437 10.4135 8.02085 10.9236 8.02085H12.7708V6.17362C12.7708 5.66352 13.1844 5.25 13.6945 5.25Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M21.6113 15.8056C21.6113 15.2226 22.0839 14.75 22.6669 14.75H27.9447C28.5277 14.75 29.0003 15.2226 29.0003 15.8056C29.0003 16.3885 28.5277 16.8611 27.9447 16.8611H22.6669C22.0839 16.8611 21.6113 16.3885 21.6113 15.8056Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.7853 5.86068C25.1976 5.44846 25.8659 5.44846 26.2781 5.86068C26.6904 6.2729 26.6904 6.94125 26.2781 7.35347L15.1193 18.5123C14.7071 18.9245 14.0388 18.9245 13.6265 18.5123C13.2143 18.1 13.2143 17.4317 13.6265 17.0195L24.7853 5.86068Z" fill="black" />
      <rect x="0.5" y="0.5" width="39" height="23" rx="2.5" stroke="#EDEDF2" />
    </svg>
  );
}
