import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router';
import { queryKeys } from 'src/app/queries/utils';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { LoyaltyRepository } from '../infrastructure/LoyaltyRepository';

const configuration = ConfigurationLoader.load();
const loyaltyRepository = new LoyaltyRepository(configuration.loyaltyApiBaseUrl);
export const useAuthenticatePongoBusiness = () => {
  const businessId = useBusinessIdOrThrow();
  const code = useSearchParams()[0].get('code');

  return useQuery({
    queryKey: [queryKeys.loyalty.LIST_PONGO_STORES, { businessId, code }],
    queryFn: async (): Promise<boolean> => {
      if (code) {
        return loyaltyRepository.authenticateWithPongo(businessId, code);
      }
      return false;
    },
  });
};
