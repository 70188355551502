import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useCustomizationRepositoryHttp } from './useCustomizationRepositoryHttp';

export const useSaveLogoOriginalUrl = () => {
  const queryClient = useQueryClient();
  const customizationRepository = useCustomizationRepositoryHttp();
  const mutation = useMutation({
    mutationFn: ({ enrollmentId, logoOriginalUrl }: { enrollmentId: EnrollmentId; logoOriginalUrl: string }) =>
      customizationRepository.saveLogoOriginalUrl(enrollmentId, logoOriginalUrl),
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [queryKeys.enrollmentSettings.customization.GET_CUSTOMIZATION] }),
  });
  return {
    mutation,
    saveLogoOriginalUrl: (enrollmentId: EnrollmentId, logoOriginalUrl: string) =>
      mutation.mutateAsync({ enrollmentId, logoOriginalUrl }),
  };
};
