import { Button, Divider, Stack, TextField } from '@mui/material';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MenuRepositoryHttp } from 'src/menu/dynamic-menu/repositories/MenuRepositoryHttp';
import { useBoxRepository } from 'src/ordering/box/hooks';
import { displayMenuMigration, MenuMigrationReview, MenuReplicationState } from 'src/enterprise/configuration-replication/domain/menu/MenuReplicationState';
import { ApplyMenuChanges } from 'src/enterprise/configuration-replication/domain/menu/ApplyMenuChanges';
import { MenuMigrationHeader } from 'src/enterprise/configuration-replication/components/menu/MenuMigrationHeader';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useNavigate } from 'react-router';
import { settingsConfigurationReplicationPath } from 'src/app/navigation/pathHelpers';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const PreviewField = ({ value }: { value: string }) => {
  return (
    <TextField
      variant={'outlined'}
      disabled
      defaultValue={value}
      sx={{
        backgroundColor: '#f0f0f0',
        '& .MuiInputBase-input.Mui-disabled': {
          '-webkit-text-fill-color': 'black',
        },
      }}
    />
  );
};

type ReviewState = 'reviewing' | 'applying';

export const ReviewMenuMigrationStep = ({
  currentState,
  onEdit,
}: {
  currentState: MenuMigrationReview;
  onEdit: (state: MenuReplicationState) => void;
}) => {

  const intl = useIntl();
  const snackBar = useSnackbar();
  const navigate = useNavigate();
  const business = useCurrentBusinessOrThrow();

  const [state, setState] = useState<ReviewState>('reviewing');

  const boxRepository = useBoxRepository();
  const menuRepository = new MenuRepositoryHttp(ConfigurationLoader.load().menuApiBaseUrl);

  const changes = new ApplyMenuChanges(boxRepository, menuRepository);

  const edit = () => {
    onEdit(displayMenuMigration(currentState.origin, currentState.menus, currentState.targets));
  };

  const apply = async () => {
    setState('applying');
    await changes.apply(currentState)
      .then(report => {
        if (report.length > 0) {
          snackBar.addNotification({
            variant: 'error',
            title: intl.formatMessage({ id: 'configuration_replication.menu.title' }),
            text: report.join(', '),
          });
        } else {
          snackBar.addNotification({
            variant: 'success',
            title: intl.formatMessage({ id: 'configuration_replication.menu.title' }),
            text: intl.formatMessage({ id: 'configuration_replication.review_and_apply.applied' }),
          });
        }
      });
    setState('reviewing');
    navigate(settingsConfigurationReplicationPath(business.id));
  };

  return (
    <Stack padding="1.5rem" gap="1rem">
      <MenuMigrationHeader
        details={intl.formatMessage({ id: 'configuration_replication.review.description' })}
        warningMessage={intl.formatMessage({ id: 'configuration_replication.menu.warning' })}
      />

      <Divider>
        <FormattedMessage id="common.from" />
      </Divider>
      <PreviewField value={currentState.origin.name} />
      <PreviewField value={currentState.menus.map((m) => m.name).join(', ')} />

      <Divider>
        <FormattedMessage id="common.to" />
      </Divider>
      <PreviewField value={currentState.targets.map((t) => t.name).join(', ')} />

      <Stack direction="row-reverse" gap="0.75rem">
        <Button variant="contained" onClick={apply} disabled={state === 'applying'}>
          <FormattedMessage id="common.save" />
        </Button>
        <Button variant="outlined" onClick={edit}>
          <FormattedMessage id="common.edit" />
        </Button>
      </Stack>
    </Stack>
  );
};
