import { KpiTimeframe } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { EReputationKpi } from 'src/sundayplus/reviews/insights/domain/EReputationKpis';
import { ratingsAverageGraphData, RatingsAverageGraphData, RatingsCountGraphData, ratingsCountGraphData } from 'src/sundayplus/reviews/insights/EReputationViewModel';

export type KpiName = 'AVG_RATING' | 'TOTAL_REVIEWS' | 'TOTAL_FIVE_STARS';

export class Kpi<T extends RatingsAverageGraphData | RatingsCountGraphData> {

  public readonly type: KpiName;

  public readonly computeGraphData: (timeframe: KpiTimeframe, eReputationKpi: EReputationKpi) => T;

  constructor(type: KpiName, computeGraphData: (timeframe: KpiTimeframe, graphKpis: EReputationKpi) => T) {
    this.type = type;
    this.computeGraphData = computeGraphData;
  }

}

export const average = (kpiTypeName: KpiName): Kpi<RatingsAverageGraphData> => new Kpi<RatingsAverageGraphData>(kpiTypeName, ratingsAverageGraphData);

export const count = (kpiTypeName: KpiName): Kpi<RatingsCountGraphData> => new Kpi<RatingsCountGraphData>(kpiTypeName, ratingsCountGraphData);

