import { CurrencyCode, Money, money } from '@sundayapp/web-money';
import { FrenchReportingDigest, ReportingDigest, ReportingDigestPerBox } from './types';
import {
  BoxOrderSummary, ConfirmationMethod, OrderPaymentStatus, OrderStatus,
} from '../orders/types';

interface BoxOrdersByBoxId {
  boxId: string;
  boxOrder: BoxOrderSummary[];
}

const isFullyRefunded = (boxOrder: BoxOrderSummary) : boolean =>
  boxOrder.paymentStatus === OrderPaymentStatus.REFUNDED || boxOrder.paymentStatus === OrderPaymentStatus.AUTO_REFUNDED;

const buildFrenchReporting = (boxOrders: BoxOrderSummary[]): FrenchReportingDigest => {
  const currency = boxOrders.length > 0 && boxOrders[0]?.totalPaid ? boxOrders[0].totalPaid.currency : CurrencyCode.EUR;
  const report = {
    totalGrossRevenue: 0,
    totalTips: 0,
    totalAccountedRefund: 0,
    stripeRevenue: 0,
    stripeTips: 0,
    cashOutsideSundayB2CAppRevenue: 0,
    cardOutsideSundayB2CAppRevenue: 0,
    other: {},
  };
  boxOrders.forEach((boxOrder) => {
    const tipsAmount = boxOrder?.tipsAmount?.amount ?? 0;
    if (boxOrder.status !== OrderStatus.CANCELED && boxOrder.status !== OrderStatus.CANCEL) {
      const discountAmount = boxOrder?.discount?.amount ?? 0;
      let refundAmount = boxOrder?.refundAmount?.amount ?? 0;
      if (isFullyRefunded(boxOrder) && refundAmount === 0) {
        refundAmount = boxOrder.totalPrice.amount - discountAmount;
      }
      let orderAmountPaid = boxOrder.totalPrice.amount - discountAmount - refundAmount;
      if (orderAmountPaid < 0) {
        // Can happen with full refund including digital fees
        orderAmountPaid = 0;
        refundAmount = boxOrder.totalPrice.amount - discountAmount;
      }
      report.totalGrossRevenue += orderAmountPaid;
      report.totalTips += tipsAmount;
      report.totalAccountedRefund += refundAmount;
      if (boxOrder.confirmationMethod === ConfirmationMethod.MANUALLY_CASH
        || boxOrder.confirmationMethod === ConfirmationMethod.AUTOMATICALLY) {
        report.cashOutsideSundayB2CAppRevenue += orderAmountPaid;
      } else if (boxOrder.confirmationMethod === ConfirmationMethod.MANUALLY_CARD) {
        report.cardOutsideSundayB2CAppRevenue += orderAmountPaid;
      } else {
        report.stripeRevenue += orderAmountPaid;
        report.stripeTips += tipsAmount;
      }
    }
  });
  return {
    totalGrossRevenue: money(report.totalGrossRevenue, currency),
    totalTips: money(report.totalTips, currency),
    totalAccountedRefund: money(report.totalAccountedRefund, currency),
    stripeRevenue: money(report.stripeRevenue, currency),
    stripeTips: money(report.stripeTips, currency),
    cashOutsideSundayB2CAppRevenue: money(report.cashOutsideSundayB2CAppRevenue, currency),
    cardOutsideSundayB2CAppRevenue: money(report.cardOutsideSundayB2CAppRevenue, currency),
    other: [],
  };
};

export const buildReporting = (countryCode: string, boxOrders: BoxOrdersByBoxId[], grandTotalRefunded: Money | null): ReportingDigestPerBox => {
  let currency = CurrencyCode.UNKNOWN;
  const report = boxOrders.reduce(
    (
      acc: {
        detailPerBox: Record<string, ReportingDigest>;
        totalGrossRevenue: number;
        totalTips: number;
        totalAccountedRefund: number;
        cashOutsideSundayB2CAppRevenue: number;
        cardOutsideSundayB2CAppRevenue: number;
        stripeRevenue: number;
      },
      boxOrdersByBoxId,
    ) => {
      const reporting = buildFrenchReporting(boxOrdersByBoxId.boxOrder);
      acc.detailPerBox[boxOrdersByBoxId.boxId] = {
        countryCode: 'FR',
        reporting,
      };
      acc.totalGrossRevenue += reporting.totalGrossRevenue.amount;
      acc.totalTips += reporting.totalTips.amount;
      acc.totalAccountedRefund += reporting.totalAccountedRefund.amount;
      acc.cashOutsideSundayB2CAppRevenue += reporting.cashOutsideSundayB2CAppRevenue.amount;
      acc.cardOutsideSundayB2CAppRevenue += reporting.cardOutsideSundayB2CAppRevenue.amount;
      acc.stripeRevenue += reporting.stripeRevenue.amount;
      if (currency === CurrencyCode.UNKNOWN && reporting.totalGrossRevenue.currency !== CurrencyCode.UNKNOWN) {
        currency = reporting.totalGrossRevenue.currency;
      }
      return acc;
    },
    {
      detailPerBox: {},
      totalGrossRevenue: 0,
      totalTips: 0,
      totalAccountedRefund: 0,
      cashOutsideSundayB2CAppRevenue: 0,
      cardOutsideSundayB2CAppRevenue: 0,
      stripeRevenue: 0,
    },
  );
  return {
    detailPerBox: report.detailPerBox,
    totalGrossRevenue: money(report.totalGrossRevenue, currency),
    totalTips: money(report.totalTips, currency),
    totalAccountedRefund: money(report.totalAccountedRefund, currency),
    grandTotalRefunded: grandTotalRefunded ?? money(0, currency),
    cashOutsideSundayB2CAppRevenue: money(report.cashOutsideSundayB2CAppRevenue, currency),
    cardOutsideSundayB2CAppRevenue: money(report.cardOutsideSundayB2CAppRevenue, currency),
    stripeRevenue: money(report.stripeRevenue, currency),
  };
};
