import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { getStartAndEndDateForChosenDateAndShiftOptions } from 'src/operations/end-of-service/hooks/getStartAndEndDateForChosenDateAndShiftOptions';
import { Business } from 'src/business/domain/Business';
import { RestaurantShiftTimesOptions } from 'src/operations/restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { RestaurantShiftTimesOption } from 'src/operations/restaurant-shifts/models/RestaurantShiftTimesOption';
import { PaymentsSourceOfDiscrepancies } from 'src/operations/end-of-service/components/reconciliationHelpCenter/PaymentsSourceOfDiscrepancies';
import { useReconciliationReport } from 'src/operations/end-of-service/queries/useFailedToBeNotifiedPayments';
import { ReconciliationReportLineByLine } from 'src/operations/end-of-service/components/reconciliationHelpCenter/ReconciliationReportLineByLine';
import { ReconciliationReport } from 'src/operations/end-of-service/model/NonReconciledPayment';
import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';

const Title = ({ children, color }: { children: ReactNode; color: string }) => {
  return (
    <Typography variant={'h6'} color={color} fontWeight={500}>
      {children}
    </Typography>
  );
};

const ReportContainer = ({ children, withDiscrepancies }: { children: ReactNode; withDiscrepancies: boolean }) => {
  const theme = useTheme();
  const backgroundColor = withDiscrepancies ? theme.palette.warning.light : theme.palette.info.light;
  const textColor = withDiscrepancies ? theme.palette.warning.dark : theme.palette.info.dark;
  return (
    <Stack borderRadius={2} bgcolor={backgroundColor} padding={2} spacing={2}>
      <Stack direction="row" spacing={1} alignItems="center">
        {withDiscrepancies ? (
          <WarningIcon fontSize="large" sx={{ color: textColor }} />
        ) : (
          <InfoIcon fontSize="large" sx={{ color: textColor }} />
        )}
        <Title color={textColor}>
          {withDiscrepancies ? (
            <FormattedMessage id="sales_summary.reconcile.help_center.discrepancyDetected.title" />
          ) : (
            <FormattedMessage id="sales_summary.reconcile.help_center.title" />
          )}
        </Title>
      </Stack>
      {children}
    </Stack>
  );
};

const ReconciliationReportWithoutDiscrepancies = (props: {
  business: Business;
  startDate: Date;
  reconciliationReport: ReconciliationReport;
  endDate: Date;
}) => {
  return (
    <ReportContainer withDiscrepancies={false}>
      <ReconciliationReportLineByLine business={props.business} startDate={props.startDate} endDate={props.endDate} />
    </ReportContainer>
  );
};

const ReconciliationReportWithDiscrepancies = (props: {
  business: Business;
  startDate: Date;
  reconciliationReport: ReconciliationReport;
  endDate: Date;
}) => {
  return (
    <ReportContainer withDiscrepancies={true}>
      <PaymentsSourceOfDiscrepancies
        business={props.business}
        startDate={props.startDate}
        reconciliationReport={props.reconciliationReport}
      />
      <ReconciliationReportLineByLine business={props.business} startDate={props.startDate} endDate={props.endDate} />
    </ReportContainer>
  );
};

export const ReconciliationHelpCenter = ({
  business,
  reportingDate,
  options,
  restaurantShiftTimesOption,
}: {
  business: Business;
  reportingDate: Date;
  options: RestaurantShiftTimesOptions;
  restaurantShiftTimesOption: RestaurantShiftTimesOption;
}) => {
  const { startAtLocalBusiness, endAtLocalBusiness } = getStartAndEndDateForChosenDateAndShiftOptions(
    options,
    restaurantShiftTimesOption,
    reportingDate,
    business,
  );
  const { isLoading, data } = useReconciliationReport({
    business,
    startDate: startAtLocalBusiness,
    endDate: endAtLocalBusiness,
  });
  if (isLoading) return <CircularProgress />;
  if (!data) return <Typography>No data available</Typography>;

  const hasDiscrepancies = data.nonNotifiedPayments.length !== 0 || data.fastPaymentPdq.length !== 0;

  if (hasDiscrepancies) {
    return (
      <ReconciliationReportWithDiscrepancies
        business={business}
        startDate={startAtLocalBusiness}
        reconciliationReport={data}
        endDate={endAtLocalBusiness}
      />
    );
  }

  return (
    <ReconciliationReportWithoutDiscrepancies
      business={business}
      startDate={startAtLocalBusiness}
      reconciliationReport={data}
      endDate={endAtLocalBusiness}
    />
  );
};
