import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { useUpdateBoxDetails } from '../../../../hooks';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type OrderNotesBoxSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const OrderNotesBoxSetting = ({ box, setBox }: OrderNotesBoxSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateOrderNotes = async (enableBoxOrderNotes: boolean) => {
    makeUpdating(true);
    await updateBoxDetails({
      enableBoxOrderNotes,
    });
    setBox({
      ...box,
      enableBoxOrderNotes,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.enableOrderNotes',
        defaultMessage: 'allow order notes',
        description: 'on venue settings page: field to allow customer to add notes on order',
      })}
      description={intl.formatMessage({
        id: 'venue.enableOrderNotesInfoMessage',
        defaultMessage: 'if this is active, customers can add notes to an order',
        description:
          "tooltip helper to describe the behaviour of the application when the toggle 'allow order notes' is active",
      })}
      toggleValue={box.enableBoxOrderNotes}
      toggleOnChange={updateOrderNotes}
      disabled={isUpdating}
    />
  );
};
