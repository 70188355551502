import { styled, Switch, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { EnrollmentConfiguration } from '../domain/EnrollmentConfiguration';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
`;
const Config = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface Props {
  appConfig: EnrollmentConfiguration;
  updateLeaderBoard: (enrollmentConfiguration: EnrollmentConfiguration) => Promise<void>;
}

export const TipsConfiguration = ({ appConfig, updateLeaderBoard }: Props) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const theme = useTheme();

  const enabledWaitersRanking = async () => {
    const updated = {
      ...appConfig,
      configuration: {
        leaderboardEnabled: appConfig.configuration.leaderboardEnabled,
        tipsEnabled: appConfig?.configuration.tipsEnabled,
        tipsAmountOnly: !appConfig?.configuration.tipsAmountOnly,
      },
    };
    updateLeaderBoard(updated)
      .then(() =>
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        }),
      )
      .catch(() =>
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
        }),
      );
  };

  return (
    <Container>
      <Config>
        <Typography>
          <FormattedMessage id="sunday_for_restaurants.settings.performance.message" />
        </Typography>
        <Switch checked={!appConfig?.configuration.tipsAmountOnly} onChange={enabledWaitersRanking} />
      </Config>
      <Typography variant="subtitle2" color={theme.palette.text.secondary}>
        <FormattedMessage id="sunday_for_restaurants.settings.performance.message.details" />
      </Typography>
    </Container>
  );
};
