/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const PhonePlainSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (
  <svg width={size} height={size} {...props} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.1994 0.833375H6.80187C6.36259 0.833361 5.9836 0.833348 5.67146 0.858851C5.34201 0.885768 5.01433 0.945198 4.69901 1.10586C4.22861 1.34554 3.84615 1.72799 3.60647 2.1984C3.44581 2.51372 3.38638 2.8414 3.35946 3.17085C3.33396 3.48299 3.33397 3.86195 3.33399 4.30123V15.6988C3.33397 16.1381 3.33396 16.5171 3.35946 16.8292C3.38638 17.1587 3.44581 17.4864 3.60647 17.8017C3.84615 18.2721 4.22861 18.6545 4.69901 18.8942C5.01433 19.0549 5.34201 19.1143 5.67146 19.1412C5.9836 19.1667 6.36257 19.1667 6.80185 19.1667H13.1995C13.6387 19.1667 14.0177 19.1667 14.3298 19.1412C14.6593 19.1143 14.987 19.0549 15.3023 18.8942C15.7727 18.6545 16.1552 18.2721 16.3948 17.8017C16.5555 17.4864 16.6149 17.1587 16.6418 16.8292C16.6673 16.5171 16.6673 16.1382 16.6673 15.6989V4.30127C16.6673 3.86202 16.6673 3.48298 16.6418 3.17085C16.6149 2.8414 16.5555 2.51372 16.3948 2.1984C16.1552 1.72799 15.7727 1.34554 15.3023 1.10586C14.987 0.945198 14.6593 0.885768 14.3298 0.858851C14.0177 0.833348 13.6387 0.833361 13.1994 0.833375ZM10.0007 13.3334C9.3103 13.3334 8.75065 13.893 8.75065 14.5834C8.75065 15.2737 9.3103 15.8334 10.0007 15.8334C10.691 15.8334 11.2507 15.2737 11.2507 14.5834C11.2507 13.893 10.691 13.3334 10.0007 13.3334Z" fill={color} />
  </svg>
);

export default PhonePlainSvg;
