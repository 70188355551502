import { Button, Card, CardHeader, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { PaymentTerminalConfiguration } from '../../domain/PaymentTerminalConfiguration';
import ConfigurationDeletionConfirmationModal from './ConfigurationDeletionConfirmationModal';

export type DeleteConfigurationProps = {
  configuration: PaymentTerminalConfiguration;
  numberOfTerminals: number;
  onClick: () => void;
};

function DeleteConfigurationForm({ onClick, configuration, numberOfTerminals }: DeleteConfigurationProps) {
  const intl = useIntl();
  const [deleteDialogOpened, setDeleteDialogOpened] = useState<boolean>(false);

  const onConfirmation = async () => {
    setDeleteDialogOpened(false);
    onClick();
  };

  return (
    <Card sx={{ padding: '16px' }}>
      <CardHeader
        title={intl.formatMessage({ id: 'payment.terminal.settings.delete' })}
        subheader={
          <Typography paddingTop="8px">
            {intl.formatMessage({ id: 'payment.terminal.settings.delete.subtitle' })}
          </Typography>
        }
        action={
          <Button variant="contained" color="error" onClick={() => setDeleteDialogOpened(true)}>
            <FormattedMessage id="payment.terminal.manage.menu.delete" />
          </Button>
        }
      />
      <ConfigurationDeletionConfirmationModal
        configurationName={configuration.general.name}
        isOpened={deleteDialogOpened}
        onClose={() => setDeleteDialogOpened(false)}
        onConfirmation={onConfirmation}
        numberOfTerminals={numberOfTerminals}
      />

    </Card>
  );
}

export default DeleteConfigurationForm;
