import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { AllergenRepositoryHttp } from '../../repositories/allergen/AllergenRepositoryHttp';
import { Allergen } from '../../domain/Allergen';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_ALLERGEN = 'menu_allergen';
const buildQueryKey = (businessId: BusinessId, allergenId: string, language?: string) =>
  [
    QUERYKEY_MENU_ALLERGEN,
    businessId,
    allergenId,
    language,
  ] as const;
export const getAllergenByIdQuery = async ({
  queryKey: [, businessId, allergenId, language],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Allergen> => {
  const configuration = ConfigurationLoader.load();
  const allergenRepository = new AllergenRepositoryHttp(configuration.menuApiBaseUrl);
  return allergenRepository.findByAllergenId(businessId, allergenId, language);
};

export const useGetAllergenById = (businessId: BusinessId, allergenId: string, language?: string) =>
  useQuery({ queryKey: buildQueryKey(businessId, allergenId, language), queryFn: getAllergenByIdQuery });
