import axios from 'axios';
import { StaffPerformance } from '../domain/StaffPerformance';
import { StaffDto, StaffDtoSchema } from './StaffDto';
import { Staff } from '../page/StaffPerformanceViewModel';
import { BusinessId } from 'src/business/domain/Business';
import { DateRange } from 'src/sundayplus/reviews/domain/DateRange';

const waiterNameNotEmpty = (staff: StaffDto): boolean => staff.waiterName.length > 0;

export class StaffPerformanceRepository implements StaffPerformance {
  constructor(private merchantAnalyticsUrl: string) {}

  async handleStaffPerformanceForBusinessId(businessId: BusinessId, dateRange: DateRange): Promise<Staff[]> {
    const result = await axios.get<unknown>(`${this.merchantAnalyticsUrl}/staff-performance/${businessId}`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        startDate: dateRange.startDateI().toISOString(),
        endDate: dateRange.endDateI().toISOString(),
      },
    });
    return StaffDtoSchema.array().parse(result.data).filter(waiterNameNotEmpty);
  }
}
