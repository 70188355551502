import { Alert, Button, Divider, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { FC } from 'react';
import { PendingTipsDispatch } from 'src/tips/dispatch/model/PendingTipsDispatch';
import { StaffAllocation, toTipsAllocations } from 'src/tips/dispatch/model/StaffAllocation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { useDispatchTips } from 'src/tips/dispatch/infrastructure/useDispatchTips';
import { ChevronLeftOutlined } from '@mui/icons-material';
import { DispatchPrerequisites } from 'src/tips/dispatch/components/DispatchPrerequisites';
import { AmountToDispatch } from 'src/tips/dispatch/components/AmountToDispatch';
import { StaffDispatchReport } from 'src/tips/dispatch/components/StaffDispatchReport';
import { DispatchedBy } from 'src/tips/dispatch/components/DispatchedBy';
import { DispatchStrategy } from 'src/tips/dispatch/model/DispatchStrategy';
import { ampli } from 'src/ampli';

const SignButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <Button size="small" variant="contained" onClick={onClick} startIcon={<CheckIcon />}>
      <FormattedMessage id={'tips.sunday_pooling.dispatch.sign'} />
    </Button>
  );
};

type Props = {
  onClose: (completed: boolean) => void;
  pendingTipsDispatch: PendingTipsDispatch;
  staffAllocations: StaffAllocation[];
  strategy: DispatchStrategy;
};

export const ValidateDispatch: FC<Props> = ({ onClose, pendingTipsDispatch, staffAllocations, strategy }) => {
  const business = useCurrentBusinessOrThrow();
  const user = useCurrentUser() as AuthenticatedUser;
  const { dispatchTips, mutation } = useDispatchTips();

  const dispatch = () =>
    dispatchTips(business.id, pendingTipsDispatch.id, toTipsAllocations(staffAllocations)).then(() => {
      ampli.tipsDistributionSucceeded({
        tipsStrategy: strategy,
        currency: pendingTipsDispatch.amount.currency,
        amount: pendingTipsDispatch.amount.amount,
      });
      onClose(true);
    });

  return (
    <Stack sx={{ background: 'white', height: '100%', overflow: 'auto', paddingBottom: '1rem' }} flexDirection="column">
      <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
        <Stack sx={{ cursor: 'pointer' }} onClick={() => onClose(false)}>
          <ChevronLeftOutlined />
        </Stack>
        <Stack alignItems={'center'}>
          <Typography>
            <FormattedMessage id={'tips.sunday_pooling.dispatch.confirm'} />
          </Typography>
        </Stack>
        <Stack alignItems={'flex-end'}>
          <SignButton onClick={dispatch} />
        </Stack>
      </Stack>

      <DispatchPrerequisites />

      <Stack
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
        padding="3rem 0 3rem 0"
        flexDirection="column"
        justifyContent={'flex-start'}
        alignItems={'center'}
      >
        <Stack maxWidth={'50rem'} height="100%" justifyContent={'space-between'}>
          <Stack direction={'column'} spacing={4} paddingBottom={8}>
            <AmountToDispatch amount={pendingTipsDispatch.amount} />
            <Divider />
            <StaffDispatchReport staffAllocations={staffAllocations} />
            <Divider />
            <DispatchedBy triggeredBy={`${user.firstName} ${user.lastName}`} dispatchedAt={new Date()} />

            {mutation.error && (
              <Alert severity="error">
                <FormattedMessage id={'tips.sunday_pooling.dispatch.error'} />
              </Alert>
            )}

            <SignButton onClick={dispatch} />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
