import { ReactNode } from 'react';
import { Popover, styled } from '@mui/material';
import { isTabletScreen } from 'src/app/theme/ThemeV5';
import { MenuDrawer } from 'src/app/navigation/navigationMenu/component/MenuDrawer';

type VenueSelectorContainerProps = {
  children: ReactNode;

  open: boolean;
  onCloseVenue: () => void;
};

const VenuesPopover = styled(Popover)({
  '& .MuiPopover-paper': {
    borderRadius: '10px',
    boxShadow: undefined,
    width: '360px',
  },
});

export const BusinessSelectorContainer = ({ open, onCloseVenue, children }: VenueSelectorContainerProps) => {
  if (isTabletScreen()) {
    return (
      <MenuDrawer open={open} onClose={onCloseVenue}>
        {children}
      </MenuDrawer>
    );
  }

  return (
    <VenuesPopover
      open={open}
      anchorReference="anchorPosition"
      onClose={onCloseVenue}
      marginThreshold={8}
      anchorPosition={{ top: 0, left: 0 }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {children}
    </VenuesPopover>
  );
};
