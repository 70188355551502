import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Tag } from '../../domain/Tag';
import { MenuRepositoryHttp } from '../../repositories/MenuRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_BLOCKS_RELATED_MENU = 'menu_block_related_menu';
const buildQueryKey = (businessId: BusinessId, blockId: string) =>
  [QUERYKEY_MENU_BLOCKS_RELATED_MENU, businessId, blockId] as const;
export const getRelatedMenusForBlockQuery = async ({
  queryKey: [, businessId, blockId],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Tag[]> => {
  const configuration = ConfigurationLoader.load();
  const menuRepository = new MenuRepositoryHttp(configuration.menuApiBaseUrl);
  return menuRepository.findRelatedMenusForBlock(businessId, blockId);
};

export const useGetRelatedMenusForBlock = (businessId: BusinessId, blockId: string) =>
  useQuery({ queryKey: buildQueryKey(businessId, blockId), queryFn: getRelatedMenusForBlockQuery });
