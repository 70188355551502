import { isGoogle, Review } from '../../browse/domain/Review';
import { ReplyTemplate } from './ReplyTemplate';
import { Business } from 'src/business/domain/Business';

export const ALLOWED_VARIABLE_CUSTOMER_NAME = '@customer_name@';
export const ALLOWED_VARIABLE_VENUE_NAME = '@venue_name@';

export type TemplateKey = typeof ALLOWED_VARIABLE_VENUE_NAME | typeof ALLOWED_VARIABLE_CUSTOMER_NAME;
export type TemplateParameter = { key: TemplateKey; value: string | undefined };

export const checkVariables = (content: string): boolean => {
  const cleanedContent = content
    .replaceAll(ALLOWED_VARIABLE_CUSTOMER_NAME, '')
    .replaceAll(ALLOWED_VARIABLE_VENUE_NAME, '');
  return !/(\s|^)@/.test(cleanedContent);
};

const fillTemplate = (template: string, parameters: Array<TemplateParameter>): string =>
  parameters.reduce((all, parameter) => {
    const replacedKey = parameter.value ? parameter.key : ` ${parameter.key}`;
    const replacedValue = parameter.value ?? '';
    return all.replaceAll(replacedKey, replacedValue);
  }, template);

const reviewParameters = (review: Review): TemplateParameter[] => [
  {
    key: ALLOWED_VARIABLE_CUSTOMER_NAME,
    value: isGoogle(review) ? review.author.name : undefined,
  },
];

const businessParameters = (business: Business): TemplateParameter[] => [
  {
    key: ALLOWED_VARIABLE_VENUE_NAME,
    value: business.name,
  },
];

export const generateReplyTextFromTemplate = (
  template: ReplyTemplate,
  { review, business }: { review: Review; business: Business },
): string => fillTemplate(template.content, [...reviewParameters(review), ...businessParameters(business)]);
