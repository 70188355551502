import axios from 'axios';
import { RepositoryHttp } from '../../common/repository/RepositoryHttp';
import {
  DetailedProductAvailability,
  DetailedProductsAvailabilities,
  ProductAvailabilities,
  ProductAvailability,
} from '../../productAvailability/types';
import { OrderingMenu } from '../domain/OrderingMenu';
import { OrderingMenuWithProducts } from '../domain/OrderingMenuWithProducts';
import { OrderingMenuRepository } from './OrderingMenuRepository';

interface MenusResults {
  menus: OrderingMenu[];
}

export class OrderingMenuRepositoryHttp extends RepositoryHttp implements OrderingMenuRepository {
  constructor(private orderingBackendUrl: string) {
    super();
  }

  async getMenus(boxId: string): Promise<OrderingMenu[]> {
    const response = await axios.get<MenusResults>(
      `${this.orderingBackendUrl}/boxes/${boxId}/menus`,
      this.withDefaultHeader(),
    );

    return response.data.menus;
  }

  async getMenuWithProducts(menuId: string): Promise<OrderingMenuWithProducts> {
    const response = await axios.get<OrderingMenuWithProducts>(
      `${this.orderingBackendUrl}/menus/${menuId}?withProducts=true`,
      this.withDefaultHeader(),
    );

    return response.data;
  }

  async getProductAvailabilities(boxId: string): Promise<ProductAvailability[]> {
    const response = await axios.get<ProductAvailabilities>(
      `${this.orderingBackendUrl}/boxes/${boxId}/product-availability`,
      this.withDefaultHeader(),
    );

    return response.data.productAvailabilities;
  }

  async getProductDetailsWithAvailabilities(boxId: string): Promise<DetailedProductAvailability[]> {
    const response = await axios.get<DetailedProductsAvailabilities>(
      `${this.orderingBackendUrl}/boxes/${boxId}/all-products-with-availabilities`,
      this.withDefaultHeader(),
    );

    return response.data.productAvailabilities;
  }

  async updateProductAvailabilities(boxId: string, productAvailabilities: ProductAvailability[]): Promise<DetailedProductAvailability[]> {
    const response = await axios.put<DetailedProductsAvailabilities>(
      `${this.orderingBackendUrl}/boxes/${boxId}/product-availability`,
      { productAvailabilities },
      this.withDefaultHeader(),
    );

    return response.data.productAvailabilities;
  }
}
