import React from 'react';
import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { findLanguage, Language } from 'src/app/i18n/AllSupportedLanguage';
import { CountryFlag } from '../CountryFlag';

type SelectLanguageProps = {
  supportedLanguages: Language[];
  defaultLanguage?: Language;
  currentLanguage?: Language;
  setCurrentLanguage: (language: Language) => void;
};

const FlagSelector = styled(Select)`
  height: fit-content;
  min-width: 200px;
`;

export const SelectLanguage = ({
  supportedLanguages,
  defaultLanguage,
  currentLanguage,
  setCurrentLanguage,
}: SelectLanguageProps) => {
  const handleChangeLanguage = (newLang: string) => {
    setCurrentLanguage(findLanguage(newLang));
  };
  const supportedLanguageList = defaultLanguage ? [defaultLanguage, ...supportedLanguages] : supportedLanguages;
  return (
    <FlagSelector
      value={currentLanguage?.id}
      variant="outlined"
      onChange={(e: SelectChangeEvent<unknown>) => handleChangeLanguage(e.target.value as string)}
    >
      {supportedLanguageList.map((lang) => {
        const isDefault = defaultLanguage?.id === lang.id;
        return (
          <MenuItem key={lang.id} value={lang.id}>
            <CountryFlag language={lang} isDefault={isDefault} />
          </MenuItem>
        );
      })}

    </FlagSelector>
  );
};
