import {
  colorUsage,
  IconWrapper,
  Panel,
  PencilSvg,
  PlusSvg,
  spaceUsages,
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  Text,
} from '@sundayapp/b2b-react-component-library';
import React from 'react';
import styled from 'styled-components';

export type MenuDetailProps = {
  label: string,
  inputValue?: string,
  onAction: () => void
};

const MenuDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const MenuDetailTitle = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
`;

const MenuDetailLabel = styled.div`
  display: flex;
  justify-content: start;
  width: 100%;
`;

const MenuDetailTool = styled.div`
  display: flex;
  justify-content: end;
  min-width: 88px;
  max-width: 88px;
`;

const MenuDetailInputPanel = ({ label, inputValue, onAction }: MenuDetailProps) => (
  <Panel paddingLeft={spaceUsages.mediumLarge} paddingRight={spaceUsages.mediumLarge}>
    <MenuDetailContainer>
      <MenuDetailTitle>
        <MenuDetailLabel>
          <Text size="xlarge">
            {label}
          </Text>
        </MenuDetailLabel>
        <MenuDetailTool>
          <IconWrapper size="small" cursor="pointer" onClick={onAction}>
            { inputValue ? (<PencilSvg />) : (<PlusSvg />) }
          </IconWrapper>
        </MenuDetailTool>
      </MenuDetailTitle>
      {inputValue && (<Text keepCase size="small" color={colorUsage.secondaryText}>{inputValue}</Text>)}
    </MenuDetailContainer>
  </Panel>
);
export default MenuDetailInputPanel;
