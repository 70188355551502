import { ReactNode, useMemo } from 'react';
import { ReviewRepositoryContext } from './ReviewRepositoryContext';
import { HttpReviewRepository } from '../HttpReviewRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export const ReviewRepositoryProvider = ({ children }: { children: ReactNode }) => {
  const configuration = useMemo(() => ConfigurationLoader.load(), []);

  const reviewRepository = useMemo(() => new HttpReviewRepository(configuration.venueFeedbackBaseUrl), [configuration]);

  return (
    <ReviewRepositoryContext.Provider value={{
      reviewRepository,
    }}
    >
      {children}
    </ReviewRepositoryContext.Provider>
  );
};
