import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpUserRepository } from 'src/infrastructure/user/HttpUserRepository';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';

export const useListBusinessUsersQuery = (businessId: string) => {
  const configuration = ConfigurationLoader.load();
  const userRepository = new HttpUserRepository(configuration.merchantAccountsBaseUrl);

  return useQuery({
    queryKey: [queryKeys.LIST_BUSINESS_USERS, { businessId }],
    queryFn: () => userRepository.listBusinessUsers(businessId),
  });
};
