export type CustomizationColor = {
  loadingPage?: string;
  marketingBanners?: string;
  appBackground?: string;
};

export type CustomizationBanner = {
  title?: string;
  description?: string;
  linkLabel?: string;
  linkUrl?: string;
  imageUrl?: string;
};

export type WhiteLabelCustomization = {
  activated: boolean;
  loadingImgUrl: string;
  color: CustomizationColor;
  burgerBanner?: CustomizationBanner;
  postPaymentBanner?: CustomizationBanner;
};

export const EMPTY_WHITE_LABEL_CUSTOMIZATION: WhiteLabelCustomization = {
  activated: false,
  loadingImgUrl: '',
  color: {},
  burgerBanner: {},
  postPaymentBanner: {},
};
