import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TimePeriod } from '../../common/domain/TimePeriod';
import { MenuSchedulesUtils } from 'src/menu/dynamic-menu/utils/menuSchedules/MenuSchedulesUtils';
import { Schedules } from 'src/menu/dynamic-menu/domain/Schedules';
import {
  MenuScheduleTranslationUtils,
} from 'src/menu/dynamic-menu/utils/menuSchedules/MenuScheduleTranslationUtils';
import { convertTimePeriodToScheduleRange } from './OrderingMenuSchedulesUtils';
import { DayItem, HourItem } from 'src/menu/dynamic-menu/utils/menuSchedules/MenuScheduleTranslationReactUtils';
import { IntlShape } from 'src/app/i18n/TypedIntl';

const DateLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const timePeriodsToReactNode = (timePeriods: TimePeriod[], translation: IntlShape): ReactNode[] => {
  const schedules = new Schedules(timePeriods.map(convertTimePeriodToScheduleRange));

  const groupedTimeRanges = MenuSchedulesUtils.optimizeGroupedTimes(
    MenuSchedulesUtils.schedulesToGroupedTimes(schedules),
  );

  return groupedTimeRanges.flatMap((groupedConstraints) => {
    const sortedHours = MenuScheduleTranslationUtils.orderRanges(groupedConstraints.ranges);
    const hours = MenuScheduleTranslationUtils.translateHours(sortedHours, translation);

    return MenuScheduleTranslationUtils.translateDays(groupedConstraints, translation).map((day) => (
      <DateLine key={day}>
        <DayItem>{day}</DayItem>
        <HourItem>{hours}</HourItem>
      </DateLine>
    ));
  });
};
