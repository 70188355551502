import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const CircleSvg: React.FC<Props> = ({ size = 12, color = themeV5.palette.text.primary, ...props }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 12 12" fill="none" {...props}>
        <circle cx="6" cy="6" r="6" fill={color} />
    </svg>
);