import { EnrollmentId } from 'src/business/domain/Enrollment';

const vivatechEnrollmentIds = [
  'a25557bf-0294-4453-87e0-e27709556b68', // ALPHA - O&P - Chez Plaisant - Zelty 2
  '1fe9dddd-f61c-41b4-ba18-4b6d970a8b39', // ALPHA - Laurent PATOP
  '9b545730-ac4c-47fd-8ff4-9095035fbb96', // ALPHA - Vivatech
  'dc21b5cd-b658-416d-9c7f-bd1c0cdd0cfc', // PROD - HORETO - VIVATECH
];
export const shouldDisplayVoucherVivaTech = (enrollmentId: EnrollmentId): boolean =>
  vivatechEnrollmentIds.includes(enrollmentId);
