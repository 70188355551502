import React, { useRef, useState } from 'react';
import { useBusinessQuery } from 'src/business/hooks/useBusinessQuery';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { Box, Button, CircularProgress, Step, StepLabel, Stepper, Typography } from '@mui/material';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { LocalisationKey } from 'src/lang/en';
import { BackButton } from 'src/operations/pos-reconciliation/component/BackButton';
import PersonalInformationStep from 'src/initial-setup/components/steps/PersonalInformationStep';
import { StepRef } from 'src/initial-setup/components/steps/StepRef';
import ConnectGoogleAccountStep from 'src/initial-setup/components/steps/ConnectGoogleAccountStep';
import EnterYourTablesStep from 'src/initial-setup/components/steps/EnterYourTablesStep';
import BusinessInitialSetupCompleted from 'src/initial-setup/components/BusinessInitialSetupCompleted';
import { useMediaBreakpoints } from '@hooks/useMediaBreakpoints';

interface StepModel {
  key: string;
  defaultMessage: string;
  component: React.ReactNode;
  action: () => void;
}

const BorderWrapper = styled.div`
  border-bottom: 1px solid lightgrey;
`;

const StepperWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
`;

const ButtonsWrapper = styled(Box)`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px;
`;

const BackButtonWrapper = styled(Box)`
  position: absolute;
  top: 35px;
  left: 16px;
  z-index: 10;
`;


const BusinessInitialSetup = () => {
  const intl = useIntl();

  const stepRef = useRef<StepRef>(null);
  const { isMobileScreen } = useMediaBreakpoints();

  const [ isValid, setIsValid ] = useState(false);

  const handleSubmit = () => {
    if (stepRef.current) {
      stepRef.current.submit();
    }
  };

  const businessId = useBusinessIdOrThrow();
  const { isLoading } = useBusinessQuery(businessId);

  const [ activeStepPosition, setActiveStepPosition ] = useState(0);

  const steps: StepModel[] = [
    {
      key: 'initial_setup.personal_information.title',
      defaultMessage: 'Personal information',
      component: <PersonalInformationStep ref={stepRef} onValidChanged={setIsValid} />,
      action: () => handleSubmit(),
    },
    {
      key: 'initial_setup.connect_google_account.title',
      defaultMessage: 'Connect Google Account',
      component: <ConnectGoogleAccountStep ref={stepRef} onValidChanged={setIsValid} />,
      action: () => handleSubmit(),
    },
    {
      key: 'initial_setup.enter_tables.title',
      defaultMessage: 'Enter your tables',
      component: <EnterYourTablesStep ref={stepRef} onValidChanged={setIsValid} />,
      action: () => handleSubmit(),
    },
  ];

  const activeStep = steps[activeStepPosition];

  const handleContinue = () => {
    setIsValid(false);
    steps[activeStepPosition].action();

    setActiveStepPosition((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStepPosition((prevActiveStep) => prevActiveStep - 1);
  };

  if (isLoading) return <CircularProgress />;

  if (activeStepPosition === steps.length) {
    return <BusinessInitialSetupCompleted />;
  }

  return (
    <Box sx={{
      padding: '16px',
      maxWidth: '600px',
      height: '100vh',
      margin: 'auto',
      backgroundColor: '#fff',
      position: 'relative',
    }}>
      {activeStepPosition !== 0 && (
        <BackButtonWrapper>
          <BackButton onClick={handleBack} />
        </BackButtonWrapper>
      )}

      <BorderWrapper>
        <StepperWrapper>
          <Stepper activeStep={activeStepPosition} alternativeLabel>
            {steps.map(({ key, defaultMessage }) => (
              <Step key={key}>
                <StepLabel>
                  {isMobileScreen ? '' :
                    <FormattedMessage id={key as LocalisationKey} defaultMessage={defaultMessage} />}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </StepperWrapper>
      </BorderWrapper>

      <Typography variant={isMobileScreen ? 'h5' : 'h4'} sx={{ m: 2 }}>
        <FormattedMessage id={activeStep.key as LocalisationKey} defaultMessage={activeStep.defaultMessage} />
      </Typography>

      {activeStep.component}

      <ButtonsWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={handleContinue}
          fullWidth
          disabled={!isValid}
        >
          {activeStepPosition === steps.length - 1 ? intl.formatMessage({ id: 'initial_setup.button.finish' }) : intl.formatMessage({ id: 'initial_setup.button.continue' })}
        </Button>
      </ButtonsWrapper>
    </Box>
  );
};

export default BusinessInitialSetup;
