import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router';
import LogoutSvg from '../../../component/icons/LogoutSvg';
import MailSvg from '../../../component/icons/MailSvg';
import { UserInfo } from 'src/user/components/UserInfo';
import { RouteDefinitions, subPath } from 'src/app/RouteDefinitions';
import { useLogout } from 'src/auth/hooks/useLogout';

type UserMenuContentProps = {
  onClose: () => void;
};

const FooterMenuList = styled(MenuList)`
  &:focus-visible {
    outline: none;
  }
`;

const UserInfoMenuItem = styled('div')`
  padding: 16px;
`;

const ItemsContainer = styled('div')`
  padding: 8px;
`;

const FooterMenuItem = styled(MenuItem)`
  border-radius: 8px;
`;

const capitalizeFirstLetter = (s: string) => s[0].toUpperCase() + s.slice(1);

export const UserMenuContent = ({ onClose }: UserMenuContentProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const logout = useLogout();

  const redirectToCommunicationSettings = () => {
    onClose();
    return navigate(subPath(RouteDefinitions.CommunicationPreferences));
  };

  return (
    <FooterMenuList>
      <UserInfoMenuItem>
        <UserInfo userNameMaxWidth={180} emailAsSubtitle />
      </UserInfoMenuItem>
      <Divider />
      <ItemsContainer>
        <FooterMenuItem onClick={redirectToCommunicationSettings} sx={{ marginBottom: '4px' }}>
          <ListItemIcon>
            <MailSvg />
          </ListItemIcon>
          <Typography variant="body2">
            {capitalizeFirstLetter(intl.formatMessage({ id: 'menu.communication_preferences' }))}
          </Typography>
        </FooterMenuItem>
        <FooterMenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutSvg />
          </ListItemIcon>
          <Typography variant="body2">
            <FormattedMessage id="menu.logout" />
          </Typography>
        </FooterMenuItem>
      </ItemsContainer>
    </FooterMenuList>
  );
};
