import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { styled } from '@mui/material';
import { NavigationMenuItems } from './NavigationMenuItems';
import {
  findBottomItems,
  findItem,
  findParentMenuItem,
  navigationDrawerItemKey,
  NavigationItem,
} from '../NavigationItems';
import { NavigationMenuItem } from './NavigationMenuItem';
import { NavigationGuideExternalLink } from './NavigationGuideExternalLink';

type MobileMenuItemsProps = {
  menuItems: NavigationItem[];
  onClose: () => void;
  openedGroupKey: string | undefined;
  selectedGroupKey: string | undefined;
  updateGroupKeyOpened: (value: string | undefined) => void;
  updateGroupKeySelected: (value: string | undefined) => void;
  onItemSelected: (item: NavigationItem) => void;

};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px;
`;

export const MobileMenuItems = ({
  menuItems, openedGroupKey, selectedGroupKey, updateGroupKeyOpened, updateGroupKeySelected, onItemSelected,
}: MobileMenuItemsProps) => {
  const currentRoute = useLocation().pathname;

  const currentItem = useMemo(() => findItem(menuItems, currentRoute), [menuItems, currentRoute]);
  const bottomItems = useMemo(() => findBottomItems(menuItems), [menuItems]);
  const mainDrawerItems = useMemo(() => menuItems.filter(value => !bottomItems.includes(value)), [menuItems]);


  useMemo(() => {
    if (!currentItem) {
      return;
    }

    const groupItem = findParentMenuItem(mainDrawerItems, currentItem);
    if (!groupItem) {
      updateGroupKeySelected(undefined);
      return;
    }

    const itemKey = navigationDrawerItemKey(groupItem);
    updateGroupKeySelected(itemKey);
  }, [mainDrawerItems, currentItem]);

  const onMenuGroupExpanded = (selection: NavigationItem | undefined) => {
    if (!selection) {
      updateGroupKeyOpened(undefined);
      return;
    }

    const menuItemKey = navigationDrawerItemKey(selection);
    updateGroupKeyOpened(menuItemKey);
  };


  return (
    <Container>
      <div>
        <NavigationMenuItems
          menuItems={mainDrawerItems}
          isCompacted={false}
          openedGroupKey={openedGroupKey}
          selectedGroupKey={selectedGroupKey}
          onItemSelected={onItemSelected}
          onMenuGroupExpanded={onMenuGroupExpanded}
          isMobile
        />
      </div>
      <div>
        <NavigationGuideExternalLink isMobile={false} isCompacted={false} />
        {bottomItems.map((item) => (
          <NavigationMenuItem
            key={navigationDrawerItemKey(item)}
            menuItem={item}
            compacted={false}
            onItemSelected={onItemSelected}
            isGeneralItem
            rootItem
          />
        ))}
      </div>
    </Container>
  );
};
