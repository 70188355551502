import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { BusinessId } from 'src/business/domain/Business';
import { useRemoveLoyaltyConfiguration } from 'src/loyalty/mutations/useRemoveLoyaltyConfiguration';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const OnboardingHeader = ({ businessId, loyaltyReference, theme }: {
  businessId: BusinessId,
  loyaltyReference: string,
  theme: string,
}) => {
  const { formatMessage } = useIntl();
  const snackBar = useSnackbar();
  const [visible, setVisible] = useState(false);
  const removeConfiguration = useRemoveLoyaltyConfiguration(businessId);

  const onClick = async () => {
    try {
      removeConfiguration.mutate();
      snackBar.addNotification({
        variant: 'success',
        text: formatMessage({ id: 'loyalty.remove.success' }),
      });
    } catch (error: any) {
      snackBar.addNotification({
        variant: 'error',
        text: formatMessage({ id: 'loyalty.onboarding.errors.other' }),
      });
    }
  };

  return (
    <Box padding="8px" display="flex" flexDirection="row" justifyContent="space-between">
      <Typography>
        {formatMessage({ id: 'loyalty.onboarding.connected' }, { loyaltyReference, theme })}
      </Typography>
      <Button variant="contained" onClick={() => setVisible(!visible)}>
        {formatMessage({ id: 'loyalty.remove.action' })}
      </Button>
      <Dialog
        open={visible}
        onClose={() => {
          setVisible(!visible);
        }}
      >
        <DialogTitle color="error">
          {formatMessage({ id: 'loyalty.remove.title' })}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {formatMessage({ id: 'loyalty.remove.content' }, { loyaltyReference })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            onClick={onClick}
          >
            {formatMessage({ id: 'loyalty.onboarding.continue' })}
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setVisible(!visible);
            }}
          >
            {formatMessage({ id: 'loyalty.onboarding.cancel' })}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
