import { useFormContext } from 'react-hook-form';
import React from 'react';
import { UploadDocument } from 'src/payments/components/Checkout/UploadDocuments/UploadDocument';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const UploadIncorporationDocument = () => {
  const { formState: { errors }, register, watch, setValue } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors.incorporationDocument;
  const incorporationDocumentId = watch('incorporationDocument');
  const intl = useIntl();

  const onFileUploaded = (documentId: string) => {
    setValue('incorporationDocument', documentId);
  };
  const onDelete = () => {
    setValue('incorporationDocument', '');
  };

  return <UploadDocument
    onRegistered={register('incorporationDocument', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.incorporationDocument.error' }) })}
    onFileUploaded={onFileUploaded}
    onDelete={onDelete}
    documentId={incorporationDocumentId}
    error={error}
    documentTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.incorporationDocument' })}
    purpose={'company_verification'}
    helperText={''} />;
};
