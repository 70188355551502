import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { IconButton, Stack, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DeviceGroup } from 'src/initial-setup/domain/DeviceGroup';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { doesDeviceExist } from 'src/initial-setup/domain/doesDeviceExist';


interface DeviceGroupFormProps {
  addDeviceGroup: (deviceGroup: DeviceGroup) => void;
  deviceGroups: DeviceGroup[];
}

const DeviceGroupForm = ({ addDeviceGroup, deviceGroups }: DeviceGroupFormProps) => {
  const intl = useIntl();

  const snackBar = useSnackbar();

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
    },
    setValue,
  } = useForm<DeviceGroup>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<DeviceGroup> = async ({ from, to }) => {
    try {
      // TODO: remove this when prefix is supported
      const deviceGroup = { prefix: '', from, to };

      if (doesDeviceExist(deviceGroups, deviceGroup)) {
        datadogLogs.logger.error('Device already exists');
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.alert.table_already_exist' }),
        });
      } else {
        addDeviceGroup(deviceGroup);
        setValue('from', undefined);
        setValue('to', undefined);
      }
    } catch (error: any) {
      datadogLogs.logger.error('An error occurred while trying to add group', { error });
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.alert.error' }),
      });
    }
  };

  return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} alignItems="center" marginTop={2}>
          <TextField
            sx={{ width: '45%' }}
            variant={'standard'}
            type={'number'}
            label={intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.from.label' })}
            placeholder={intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.from.placeholder' })}
            id="from"
            error={!!errors.from}
            helperText={errors.from?.message}
            InputLabelProps={{ shrink: true }}
            {...register('from', {
              required: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.from.required' }),
              setValueAs: (value) => parseInt(value, 10),
              pattern: {
                value: /^\d+$/,
                message: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.from.pattern' }),
              },
            })}
          />
          <TextField
            sx={{ width: '45%' }}
            variant={'standard'}
            type={'number'}
            label={intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.to.label' })}
            placeholder={intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.to.placeholder' })}
            id="to"
            error={!!errors.to}
            helperText={errors.to?.message}
            InputLabelProps={{ shrink: true }}
            {...register('to', {
              required: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.to.required' }),
              setValueAs: (value) => parseInt(value, 10),
              pattern: {
                value: /^\d+$/,
                message: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.to.pattern' }),
              },
            })}
          />
          <IconButton aria-label="add"
                      type={'submit'}
                      disabled={!isValid}>
            <AddIcon />
          </IconButton>
        </Stack>
      </form>
  );
}
;

export default DeviceGroupForm;
