import React, { ReactNode, useCallback, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { borderRadius, colorPalette, TRANSITION_DEFAULT_DELAY_IN_MS, transitions, zIndex } from '../../../stylesheet';
import { DrawerContext } from './DrawerContext';

interface Props {
  visible: boolean;
  onClick?: () => void;
  fullSize?: boolean;
  toggleButton?: ReactNode;
  noToggleButton?: boolean;
  noToastContainer?: boolean;
  children: ReactNode;
  maxWidth?: string;
}

interface DrawerOutsideProps {
  visible: boolean;
  fullSize?: boolean;
  maxWidth?: string;
}

const DrawerOutside = styled.div<DrawerOutsideProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  z-index: ${zIndex.HIDDEN};
  transition: z-index linear 0s 400ms, background-color ease-in-out 400ms;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  ${({ visible }) =>
    visible
    && css`
      z-index: 10000;
      transition: background-color ease-in-out 400ms;
      height: 100%;
      background-color: ${colorPalette.darkMask};
    `}
`;

const Container = styled.aside<DrawerOutsideProps>`
  height: 0;
  width: ${(props) => props.maxWidth ? 'calc(min(100%, ' + props.maxWidth + '))' : '100%'};
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  border-radius: ${borderRadius.default} ${borderRadius.default} 0 0;
  max-height: 100%;
  box-sizing: content-box;
  overflow: hidden;
  position: relative;
  padding: 0;
  transition: background-color ${transitions.default}, height ${transitions.default}, overflow ${transitions.delayed};
  ${({ visible }) =>
    visible
    && css`
      background-color: ${colorPalette.defaultBackgroundColor};
      transition: background-color ${transitions.default}, height ${transitions.default},
        overflow-y ${transitions.delayed};
      max-height: 100vh;
      overflow-y: visible;
    `}
`;

const Content = styled.div<Props>`
  opacity: 0;
  transition: opacity ${transitions.default};
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: center;
  position: relative;
  ${({ visible }) =>
    visible
    && css`
      opacity: 1;
    `}
  ${({ fullSize }) =>
    fullSize
    && css`
      height: 100%;
    `}
`;

const Drawer: React.FC<Props> = ({
  visible,
  onClick,
  fullSize,
  toggleButton,
  children,
  noToggleButton,
  ...props
}) => {
  const [height, setHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [canScroll, setCanScroll] = useState<boolean>(visible);

  const refreshDrawerHeight = useCallback(() => {
    if (contentRef.current && visible) {
      setHeight(contentRef.current.offsetHeight);
    } else {
      setHeight(0);
    }
  }, [visible, contentRef, setHeight]);

  useLayoutEffect(refreshDrawerHeight, [refreshDrawerHeight, children]);

  useLayoutEffect(() => {
    window.setTimeout(() => {
      setCanScroll(visible);
    }, TRANSITION_DEFAULT_DELAY_IN_MS);
  }, [visible, setCanScroll]);

  return (
    <DrawerContext.Provider value={{ refreshDrawerHeight }}>
      <DrawerOutside visible={visible} onClick={onClick} {...props}>
        <Container
          visible={visible}
          style={
            height > 0
              ? {
                height: fullSize ? '100vh' : height,
                overflowY: canScroll ? 'visible' : 'hidden',
              }
              : undefined
          }
          {...props}
        >
          <Content visible={visible} fullSize={fullSize}>
            {visible && <div ref={contentRef}>{children}</div>}
          </Content>
        </Container>
      </DrawerOutside>
    </DrawerContext.Provider>
  );
};

export default Drawer;
