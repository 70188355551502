import React from 'react';
import { ConfigurationContainerContext } from './ConfigurationContainerContext';
import { InvoiceHttpPort } from '../infrastructure/InvoiceHttpPort';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { InvoicesLoader } from 'src/accounting/invoices/pages/InvoicesLoader';

interface Props {
  businessId: BusinessId;
}

export const InvoicesWithContext: React.FC<Props> = ({
  businessId,
}: Props) => {
  const appConfiguration = ConfigurationLoader.load();
  return (
    <ConfigurationContainerContext.Provider
      value={{
        invoicePort: InvoiceHttpPort(appConfiguration.accountingApiBaseUrl),
      }}
    ><InvoicesLoader businessId={businessId} />
    </ConfigurationContainerContext.Provider>
  );
};
