import { useEffect, useRef } from 'react';

type CallbackFnType = () => void;

export const useInterval = (callback: CallbackFnType, intervalTime: number, disable = false) => {
  const callbackRef = useRef<CallbackFnType>(callback);
  const intervalTimeRef = useRef<number>(intervalTime);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback, callbackRef]);

  useEffect(() => {
    intervalTimeRef.current = intervalTime;
  }, [intervalTime, intervalTimeRef]);

  useEffect(() => {
    if (!disable && typeof window !== 'undefined') {
      const interval = window.setInterval(callbackRef.current, intervalTimeRef.current);
      return () => window.clearInterval(interval);
    }
  }, [callbackRef, intervalTimeRef, disable]);
};
