import { Task, TaskStatus, TaskType } from 'src/menu/tasks/domain/Task';

export interface TaskDto {
  id: string;
  type: string;
  status: TaskStatus;
  startedAt: string;
  finishedAt?: string;
}

export function toTask(taskDto: TaskDto): Task {
  return {
    id: taskDto.id,
    type: taskDto.type as TaskType,
    status: taskDto.status,
    startedAt: new Date(taskDto.startedAt),
    finishedAt: taskDto.finishedAt ? new Date(taskDto.finishedAt) : undefined,
  };
}
