import { Money } from '@sundayapp/web-money';
import { Tag } from './Tag';
import { Allergen } from './Allergen';
import { menuElementTypes, MenuProductElementType } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';
import { LocalisationKey } from 'src/lang/en';
import { Days } from 'src/menu/dynamic-menu/domain/Days';
import { PosConnection } from 'src/menu/dynamic-menu/domain/PosConnection';

export type SnoozePeriod = { start: string; end: string };
export type ProductWithSubProductsType =
  typeof productTypes.PRODUCT
  | typeof productTypes.BUNDLE
  | typeof productTypes.VARIANT_GROUP
  | typeof productTypes.MODIFIER_GROUP;

type Hours = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23';
// eslint-disable-next-line
type Minutes = '00' | '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12' | '13' | '14' | '15' | '16' | '17' | '18' | '19' | '20' | '21' | '22' | '23' | '24' | '25' | '26' | '27' | '28' | '29' | '30' | '31' | '32' | '33' | '34' | '35' | '36' | '37' | '38' | '39' | '40' | '41' | '42' | '43' | '44' | '45' | '46' | '47' | '48' | '49' | '50' | '51' | '52' | '53' | '54' | '55' | '56' | '57' | '58' | '59';
type Seconds = Minutes;
export type TimeString = `${Hours}:${Minutes}` | `${Hours}:${Minutes}:${Seconds}`;

export type TimeRange = {
  start: TimeString;
  end: TimeString;
};

export type ProductPriceTimeOverrideStrategy = {
  _type: 'ProductPriceTimeOverride';
  price: Money;
  configuration: {
    days: Days[];
    timeRanges: TimeRange[];
  }
};

export const isProductPriceTimeOverride = (strategy: PricingStrategy): strategy is ProductPriceTimeOverrideStrategy =>
  strategy._type ===  'ProductPriceTimeOverride';

export type MenuBasedPricingStrategy = {
  _type: 'MenuBasedPricingStrategy';
  price: Money;
};

export type PricingStrategy = MenuBasedPricingStrategy | ProductPriceTimeOverrideStrategy;

export type ProductType =  (typeof productTypes)[keyof typeof productTypes];

export interface Product {
  id: string;
  name: string;
  partnerName: string;
  shortDescription: string;
  description: string;
  partnerDescription: string;
  type: (typeof productTypes)[keyof typeof productTypes];
  source: (typeof ProductSource)[keyof typeof ProductSource];
  sku: string;
  price: Money;
  pictureUrl: string;
  partnerPictureUrl: string;
  tags: Tag[];
  allergens: Allergen[];
  relatedProductIds: string[];
  subProductIds: string[];
  isSubProductsOverridden: boolean;
  isSubProductsEditable: boolean;
  calories: number;
  eatInTax: number;
  takeAwayTax: number;
  deliveryTax: number;
  isAvailableForOrder: boolean;
  isSnoozed: boolean;
  isEligibleForOrder?: boolean;
  posConnection?: PosConnection;
  pricingStrategies?: PricingStrategy[];
}

export const getExposedProductName = (product: {
  name: Product['name'],
  partnerName?: Product['partnerName']
}) => (product.name ? product.name : product.partnerName);
export const ProductSource = {
  SUNDAY: 'SUNDAY',
  PARTNER: 'PARTNER',
} as const;

export const productTypes = {
  PRODUCT: 'PRODUCT',
  MODIFIER: 'MODIFIER',
  MODIFIER_GROUP: 'MODIFIER_GROUP',
  BUNDLE: 'BUNDLE',
  VARIANT_GROUP: 'VARIANT_GROUP',
  VARIANT: 'VARIANT',
} as const;

export const productTypesByElementType: Record<MenuProductElementType, ProductType[]> = {
  [menuElementTypes.PRODUCT]: [productTypes.PRODUCT, productTypes.VARIANT_GROUP, productTypes.BUNDLE],
  [menuElementTypes.MODIFIER]: [productTypes.MODIFIER, productTypes.MODIFIER_GROUP],
};

export const translationByProductType: Record<ProductType, {
  single: LocalisationKey,
  plural: LocalisationKey
}> = {
  [productTypes.PRODUCT]: {
    single: 'menus.products.type.product',
    plural: 'menus.products.type.product.plural',
  },
  [productTypes.MODIFIER]: {
    single: 'menus.products.type.modifier',
    plural: 'menus.products.type.modifier.plural',
  },
  [productTypes.MODIFIER_GROUP]: {
    single: 'menus.products.type.modifier_group',
    plural: 'menus.products.type.modifier_group.plural',
  },
  [productTypes.VARIANT]: {
    single: 'menus.products.type.variant',
    plural: 'menus.products.type.variant.plural',
  },
  [productTypes.VARIANT_GROUP]: {
    single: 'menus.products.type.variant_group',
    plural: 'menus.products.type.variant_group.plural',
  },
  [productTypes.BUNDLE]: {
    single: 'menus.products.type.bundle',
    plural: 'menus.products.type.bundle.plural',
  },
} as const;
