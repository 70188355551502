import React from 'react';
import { styled } from '@mui/material';
import { HomeTitleWithoutAdoption } from './HomeTitleWithoutAdoption';
import { HomeHeaderTimeRangeInfo } from './HomeHeaderTimeRangeInfo';
import { HomeTitleWithAdoption } from './HomeTitleWithAdoption';

type HomeHeaderProps = {
  adoptionRate: number | undefined;
  totalRevenue: string;
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MobileWrapper = styled('div')`
  margin-bottom: 16px;
`;

export const HomeHeader = ({ adoptionRate }: HomeHeaderProps) => {
  if (adoptionRate) {
    return (
      <Container>
        <HomeTitleWithAdoption adoptionRate={adoptionRate} />
        <MobileWrapper><HomeHeaderTimeRangeInfo /></MobileWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <HomeTitleWithoutAdoption />
    </Container>
  );
};
