import { Checkbox, FormControl, FormControlLabel, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const IsTradingThemSameAsLegalName = () => {
  const {
    register,
    watch,
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const isTradingNameTheSameAsLegalName = watch('isTradingNameTheSameAsLegalName');
  const legalName = watch('legalName');
  const theme = useTheme();
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormControlLabel
        control={
          <Checkbox defaultChecked={!!isTradingNameTheSameAsLegalName} {...register('isTradingNameTheSameAsLegalName')}
                    size={'large'} />}
        label={<Stack>
          <Typography><FormattedMessage id={'settings.payments.checkout.onboarding.isTradingNameTheSameAsLegalName'} /></Typography>
          {isTradingNameTheSameAsLegalName &&
            <Typography color={theme.palette.text.secondary} variant={'body2'}>{legalName}</Typography>}
        </Stack>}
        labelPlacement="end"
      />
    </Stack>
  </FormControl>;
};
