import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { colorPalette, getSpacing } from '../../../stylesheet';
import Button from '../../../common/components/Button';

interface Props {
  name: string;
  selected?: boolean;
  setSelected: (value: string) => void;
  prefix?: ReactNode;
}

interface MenuTagProps {
  selected?: boolean;
}

const MenuTag = styled(Button)<MenuTagProps>`
  margin: ${getSpacing(0.5)};
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-transform: lowercase;
  height: 46px;
  color: ${colorPalette.dust};
  background-color: transparent;
  border: 1px solid ${colorPalette.cement};
  border-radius: 24px;
  ${({ selected }) =>
    selected
    && css`
      color: ${colorPalette.primaryForegroundColor};
      background-color: ${colorPalette.rhino};
      border-color: ${colorPalette.rhino};
    `}
`;

export const FilterTag: React.FC<Props> = ({
  name, selected, setSelected, prefix,
}) => (
  <MenuTag selected={selected} onClick={() => setSelected(name)}>
    {prefix}
    {name}
  </MenuTag>
);
