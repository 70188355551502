import { Modal } from '@sundayapp/b2b-react-component-library';
import React, { useEffect } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import ErrorContainer from '../../../common/components/ErrorContainer';
import Spinner from '../../../common/components/Spinner';
import { useGetOrderHistory } from '../../hooks';
import { OrderId } from '../../types';
import OrderHistoryTimeline from '../OrderHistoryTimeline';

interface Props {
  orderId: OrderId;
  visible: boolean;
  hide: () => void;
}

const OrderHistoryModal: React.FC<Props> = ({ orderId, visible, hide }) => {
  const intl = useIntl();
  const [orderHistoryState, getOrderHistory] = useGetOrderHistory();
  useEffect(() => {
    if (visible) {
      getOrderHistory(orderId);
    }
  }, [getOrderHistory, orderId, visible]);
  return (
    <Modal
      isOpened={visible}
      onClose={hide}
      title={intl.formatMessage({ id: 'orders.orderHistory', defaultMessage: 'order history' })}
      cancelButtonMessage={intl.formatMessage({ id: 'orders.close', defaultMessage: 'close' })}
    >
      <ErrorContainer error={orderHistoryState.error} />
      {orderHistoryState.loading ? <Spinner /> : <OrderHistoryTimeline history={orderHistoryState.result} />}
    </Modal>
  );
};

export default OrderHistoryModal;
