import { styled } from '@mui/material';
import SundaySvg from './SundaySvg';

export interface Props {
  size?: number;
  className?: string;
}

const SundayIconContainer = styled('div')`
  background-color: white
`;

const SundayIcon = ({ size, className, ...props }: Props) => (
  <SundayIconContainer className={`${className ?? ''}`}>
    <SundaySvg size={size} {...props} />
  </SundayIconContainer>
);

export default SundayIcon;
