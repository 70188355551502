import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { colorUsage, IconWrapper, PaintBucketSvg, spaceUsages } from '@sundayapp/b2b-react-component-library';
import {
  BackgroundColorPicker,
  BackgroundColorPickerContainer,
  InputColor,
  InputColorWrapper,
} from './ColorSelector.styles';

interface ColorPickerProps {
  palette: string[],
  background: string,
  setColor: (color: string) => void,
  togglePickingColor: () => void;
}

const ColorButtonStyle = {
  display: 'flex',
  width: '32px',
  height: '32px',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${colorUsage.quaternaryBorder}`,
  borderRadius: '50%',
  cursor: 'pointer',
} as const;

export const ColorSelector = ({
  palette,
  background,
  setColor,
  togglePickingColor,
}: ColorPickerProps) => (
  <BackgroundColorPickerContainer>
    <FormattedMessage id="settings.app_settings.logo.crop.background" />
    <BackgroundColorPicker>
      {palette.map((color) => (
        <div
          key={color}
          role="none"
          style={{ ...ColorButtonStyle, backgroundColor: color }}
          onClick={() => setColor(color)}
        />
      ))}
      <InputColorWrapper>
        <InputColor
          value={background}
          type="color"
          onChange={(event) => setColor(event.currentTarget.value)}
        />
        <div
          role="none"
          style={ColorButtonStyle}
          onClick={togglePickingColor}
        >
          <IconWrapper size="fluid" padding={spaceUsages.small} color={colorUsage.secondaryText} cursor="pointer">
            <PaintBucketSvg />
          </IconWrapper>
        </div>
      </InputColorWrapper>
    </BackgroundColorPicker>
  </BackgroundColorPickerContainer>
);
