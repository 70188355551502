import update from 'immutability-helper';
import { useEffect, useState } from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DraggableMenuItem } from './DraggableMenuItem';
import { StaticMenu } from '../../domain/StaticMenus';

type DragContainerProps = {
  menus: StaticMenu[];
  setMenus: (menus: StaticMenu[]) => void;
};

interface DragMenuItem {
  id: number;
  menu: StaticMenu;
}

export const DraggableMenuContainer = ({ menus, setMenus }: DragContainerProps) => {
  const [currentMenus, setCurrentMenus] = useState<DragMenuItem[]>([]);

  const moveMenu = (dragIndex: number, hoverIndex: number) => {
    const updatedMenus = update(currentMenus, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, currentMenus[dragIndex] as DragMenuItem],
      ],
    });

    setCurrentMenus(updatedMenus);
    setMenus(updatedMenus.map((item) => item.menu));
  };

  useEffect(() => {
    setCurrentMenus(menus.map((menu, idx) => ({ id: idx + 1, menu })));
  }, [menus]);

  return (
    <DndProvider backend={HTML5Backend}>
      {currentMenus.map((menu, index) => (
        <DraggableMenuItem key={menu.id} index={index} id={menu.id} menu={menu.menu} moveMenu={moveMenu} />
      ))}
    </DndProvider>
  );
};
