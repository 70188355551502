import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { colorForRating } from '../../RatingColor';
import { themeV5 } from 'src/app/theme/ThemeV5';

const ProgressBarContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

const ProgressBarBackground = styled('div')(() => ({
  width: '100%',
  height: '8px',
  borderRadius: '4px',
  backgroundColor: `${themeV5.palette.action.disabledBackground}`,
  position: 'absolute',
}));

const ProgressBarProgress = styled('div')<{ star: number, progress: string }>(({ star, progress }) => ({
  height: '8px',
  borderRadius: '4px',
  backgroundColor: colorForRating(star),
  position: 'absolute',
  width: progress,
  transition: 'width 1s ease-in-out',
}));

export const ProgressBar = ({ progress, star }: { progress: number, star: number }) => {
  const [p, setP] = useState<number>(0);
  useEffect(() => {
    setP(progress);
  }, [progress]);
  return (
    <ProgressBarContainer>
      <ProgressBarBackground />
      <ProgressBarProgress star={star} progress={`${p}%`} />
    </ProgressBarContainer>
  );
};
