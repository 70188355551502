import { encode } from 'blurhash';

/* eslint-disable class-methods-use-this */
export class BlurHashService {
  public async getBlurHash(imageUrl: string) {
    const image = await this.loadImage(imageUrl);
    const imageData = this.getImageData(image);
    if (!imageData) {
      return '';
    }
    return encode(imageData.data, imageData.width, imageData.height, 4, 4);
  }

  private async loadImage(imageUrl: string) {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => resolve(img);
      img.onerror = (...args) => reject(args);
      img.src = imageUrl;
    });
  }

  private getImageData(image: HTMLImageElement) {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    if (!context) {
      return null;
    }
    context.drawImage(image, 0, 0);
    return context.getImageData(0, 0, image.width, image.height);
  }
}
