import React from 'react';
import Drawer from '../../../common/components/Drawer';
import { Order, OrderPaymentStatus } from '../../types';
import { PaymentSummary } from '../../../payment/types';
import RefundDrawerContent from './RefundDrawerContent';

interface Props {
  order?: Order;
  payment?: PaymentSummary;
  orderRefunded: (order: Order, status: OrderPaymentStatus) => void;
}

const RefundDrawer: React.FC<Props> = ({ order, payment, orderRefunded }) => (
  <Drawer visible={order !== undefined && payment !== undefined} fullSize>
    {order && payment && <RefundDrawerContent order={order} payment={payment} orderRefunded={orderRefunded} />}
  </Drawer>
);

export default RefundDrawer;
