import { InputAdornment, styled, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

const StyledBusinessSearchBar = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: '100%',

  '& .MuiSvgIcon-root': {
    color: theme.palette.text.disabled,
  },

  '& .MuiInput-root': {
    fontSize: '18px',
  },
}));

export const BusinessSearchBar = ({
  onChange,
  value,
}: {
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  value: string;
}) => {
  return (
    <StyledBusinessSearchBar
      label=""
      variant="standard"
      size="small"
      color="primary"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon fontSize="large" />
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      value={value}
    />
  );
};
