import React, { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  closestCenter,
  defaultDropAnimationSideEffects,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DropAnimation,
} from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DragStartEvent } from '@dnd-kit/core/dist/types/events';
import { createPortal } from 'react-dom';
import { styled, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import clsx from 'clsx';
import { SundayPaymentMethodSettingsPanel } from './SundayPaymentMethodSettingPanel';
import {
  GenericPaymentMethodSettingsPanel,
  PaymentMethodSettingsPanelProps,
} from './GenericPaymentMethodSettingsPanel';
import { AddPaymentMethodSettingPanel } from './AddPaymentMethodSettingPanel';
import { PaymentMethodSettings } from '../../domain/ReportSettings';
import { ReportSettingsInputs } from './ReportSettingsInputs';

type Props = {
  paymentMethods: PaymentMethodSettings[],
  form: UseFormReturn<ReportSettingsInputs>,
  addPaymentMethod: () => void,
  deletePaymentMethod: (id: string) => void,
  onReorderMethods: (recipe: (src: PaymentMethodSettings[]) => PaymentMethodSettings[]) => void,
};

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};

const DraggableItem = styled('div')`
  width: 100%;

  &.dragging {
    opacity: 0.5;
  }
`;

export function PaymentMethodsSettings({
  paymentMethods, form, addPaymentMethod, deletePaymentMethod, onReorderMethods,
}: Props) {
  const overlayForm = useForm<ReportSettingsInputs>();

  const [draggedMethodId, setDraggedMethodId] = useState<string>();
  const draggedMethod = paymentMethods.find((m) => m.id === draggedMethodId);

  function handleDragStart(event: DragStartEvent) {
    const newDraggedMethodId = event.active.id as string;
    const draggedMethodPath = `paymentMethodsById.${newDraggedMethodId}` as keyof ReportSettingsInputs;
    const draggedMethodValues = form.getValues(draggedMethodPath);

    overlayForm.setValue(draggedMethodPath, draggedMethodValues);
    setDraggedMethodId(newDraggedMethodId);
  }

  function handleDragEnd(event: DragEndEvent) {
    setDraggedMethodId(undefined);
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activeIndex = paymentMethods.findIndex((m) => m.id === active.id);
      const overIndex = paymentMethods.findIndex((m) => m.id === over?.id);

      onReorderMethods((settings) => arrayMove(settings, activeIndex, overIndex));
    }
  }

  function PaymentMethodPanel({ methodForm, methodSettings }: {
    methodForm: UseFormReturn<ReportSettingsInputs>
    methodSettings: PaymentMethodSettings,
  }) {
    const methodId = methodSettings.id;

    const commonProps: PaymentMethodSettingsPanelProps & { key: React.Key } = ({
      id: methodId,
      key: methodId,
      form: methodForm,
      deleteEnabled: methodSettings.removable,
      defaultValues: methodSettings.posPaymentMethodIds,
    });

    switch (methodSettings.type) {
      case 'SUNDAY':
        return <SundayPaymentMethodSettingsPanel {...commonProps} />;
      default:
        return (
          <GenericPaymentMethodSettingsPanel
            {...commonProps}
            onDelete={() => deletePaymentMethod(methodId)}
          />
        );
    }
  }

  return (
    <>
      <Typography variant="h5" sx={{ margin: '1em 0 1em 0' }}>
        <FormattedMessage id="reconciliation.settings.payment_methods" />
      </Typography>

      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <>
          <SortableContext
            items={paymentMethods.map((m) => m.id)}
            strategy={verticalListSortingStrategy}
          >
            {paymentMethods.map((method) => {
              const dragging = method.id === draggedMethodId;
              return (
                <DraggableItem key={method.id} className={clsx({ dragging })}>
                  <PaymentMethodPanel
                    key={method.id}
                    methodForm={form}
                    methodSettings={method}
                  />
                </DraggableItem>
              );
            })}
          </SortableContext>
          {createPortal(
            <DragOverlay dropAnimation={dropAnimationConfig}>
              {draggedMethod && (
                <PaymentMethodPanel
                  key="overlay"
                  methodForm={overlayForm}
                  methodSettings={draggedMethod}
                />
              )}
            </DragOverlay>,
            document.body,
          )}
        </>
      </DndContext>
      <AddPaymentMethodSettingPanel addPaymentMethod={addPaymentMethod} />
    </>
  );
}
