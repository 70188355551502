import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import { PaymentMethodsProps, PaymentsMethodsFields } from './types';
import { VposVenueRepositoryContext } from 'src/app/contexts/vposvenue/VposVenueRepositoryContextProvider';
import { PosConnectionId } from 'src/pos/domain/PosConnection';

export const usePaymentMethods = (posConnectionId: PosConnectionId) => {
  const queryClient = useQueryClient();

  const { getPaymentMethods, postPaymentMethods } = useContext(VposVenueRepositoryContext);

  const { isLoading, data, error } = useQuery({
    queryKey: ['posPaymentMethods', posConnectionId],
    queryFn: (): Promise<void | PaymentMethodsProps[]> =>
      getPaymentMethods(posConnectionId)
        .then((response) =>
          response.map(({ allowedValues, mappedValues, preferredValue, readOnly, sundayCode }) => ({
            sundayCode,
            readOnly,
            preferredValue,
            mappedValues,
            allowedValues,
          })),
        )
        .catch((e) => datadogLogs.logger.warn(`impossible to retrieve payment methods ${e}`)),
  });

  const submitPaymentsMethods = useMutation({
    mutationFn: (form: PaymentsMethodsFields[]) =>
      postPaymentMethods(
        posConnectionId,
        form.map(({ mappedValues, preferredValue, sundayCode }) => ({
          sundayCode,
          preferredValue,
          mappedValues,
        })),
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['posPaymentMethods'] });
    },
  });

  return {
    isLoading,
    paymentMethods: data,
    submitPaymentsMethods,
    error,
  };
};
