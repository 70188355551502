import { useMutation, useQueryClient } from '@tanstack/react-query';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { TipsSuggestionRepositoryHttp } from '../infrastructure/TipsSuggestionRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { TipsSuggestion } from '../domain/TipsSuggestion';
import { queryKeys } from 'src/app/queries/utils';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const useSetTipsSuggestion = (enrollmentId: EnrollmentId) => {
  const queryClient = useQueryClient();
  const configuration = ConfigurationLoader.load();
  const snackbar = useSnackbar();
  const intl = useIntl();
  const repository = new TipsSuggestionRepositoryHttp(configuration.tipsSuggestionUrl);
  const mutation = useMutation({
    mutationFn: (tipsSuggestion: TipsSuggestion) => repository.setTipsSuggestion(enrollmentId, tipsSuggestion),
    onSuccess: () => {
      snackbar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      queryClient.invalidateQueries({ queryKey: [queryKeys.tips.GET_TIPS_SUGGESTIONS, { enrollmentId }] });
    },
    onError: () => {
      snackbar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    },
  });
  return {
    mutation,
    saveTips: (tipsSuggestion: TipsSuggestion) => mutation.mutateAsync(tipsSuggestion),
  };
};
