import React, { useState } from 'react';
import { tShirt } from '../../Size';
import { GuestExperienceHeader } from './GuestExperienceHeader';
import { Column } from '../../Column';
import { OverallSatisfaction } from './overallSatisfaction/OverallSatisfaction';
import { DateRange } from '../domain/DateRange';
import { DimensionsAnalysis } from './dimension/DimensionsAnalysis';
import { useDimensionsAnalysisInTimeRange } from './infrastructure/useDimensionsAnalysisInTimeRange';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

export const GuestExperience = () => {
  const [dateRange, setDateRange] = useState<DateRange>(DateRange.lastSevenDays());
  const businessId = useBusinessIdOrThrow();

  const dimensionAnalysisQueryResult = useDimensionsAnalysisInTimeRange(
    businessId,
    dateRange.startDate(),
    dateRange.endDate(),
  );

  return (
    <>
      <Column gap={tShirt('l')}>
        <GuestExperienceHeader dateRange={dateRange} onDateRangeChange={setDateRange} />
        <OverallSatisfaction dateRange={dateRange} />
        <DimensionsAnalysis dateRange={dateRange} dimensionAnalysisQueryResult={dimensionAnalysisQueryResult} />
      </Column>
    </>
  );
};
