import { useCallback, useEffect, useState } from 'react';
import { Payment } from '../../domain/Payment';
import { convertToCents } from 'src/app/component/Money/service';
import { PaymentAdapter } from '../../infrastructure/PaymentAdapter';
import { FailedRequest } from 'src/domain/payment/Refund';
import { Business } from 'src/business/domain/Business';
import { HttpPaymentTerminalRepository } from 'src/payment-terminal/infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Money } from '@sundayapp/web-money';

export enum RefundType {
  FULL = 'full',
  PARTIAL = 'partial',
}

export type UseRefundState = {
  isValid: boolean;
  refundType: RefundType;
  setRefundType: (refundType: RefundType) => void;
  refundErrorMessage?: string;
  refundAmount: number;
  setRefundAmount: (refundAmount: number) => void;
  refundComment: string;
  setRefundComment: (refundComment: string) => void;
  refundTerminalId: string;
  setRefundTerminalId: (refundTerminalId: string) => void;
  isRefundInProgress: boolean;
  refundPayment: () => Promise<void>;
  partiallyRefundable: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const useRefund = (
  payment: Payment,
  onClose: () => void,
  paymentAdapter: Pick<PaymentAdapter, 'refund'>,
  business: Business,
): UseRefundState => {
  const { posReference } = business;
  const partiallyRefundable = payment.isPartiallyRefundable(posReference);
  const [isOpen, setIsOpen] = useState(false);
  const [refundType, setRefundType] = useState<RefundType>(RefundType.FULL);
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [refundComment, setRefundComment] = useState<string>('');
  const [refundTerminalId, setRefundTerminalId] = useState<string>('');
  const [refundErrorMessage, setRefundErrorMessage] = useState<string>();
  const [isRefundInProgress, setIsRefundInProgress] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const paymentTerminalRepository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);

  useEffect(() => {
    function isRefundable() {
      return (refundType === RefundType.FULL && payment.refundableAmount.amount > 0) ||
        (refundType === RefundType.PARTIAL &&
          refundAmount > 0 &&
          refundAmount <= Math.min(payment.refundableAmount.amount, payment.totalPaidByCustomer.amount) / 1_00_000);
    }

    function isTerminalOK() {
      if (payment.paymentProvider !== 'DNA') {
        return true;
      }
      return refundTerminalId.length > 0;
    }

    const newIsValid = payment.status === 'succeeded' && isRefundable() && isTerminalOK();
    setIsValid(newIsValid);
  }, [refundType, refundAmount, refundTerminalId]);

  async function handleRefund(amount: number, comment: string, terminalId: string) {
    setIsRefundInProgress(true);

    let refundResult;
    if (payment.paymentProvider === 'PAYROC' || payment.paymentProvider === 'DNA') {
      if (business.pdqEnrollment) {
        const money: Money = { amount, currency: payment.totalPaidByCustomer.currency };
        refundResult = await paymentTerminalRepository.refund(business.pdqEnrollment.id, payment.id, money, comment, terminalId);
      }
    } else {
      refundResult = await paymentAdapter.refund(payment, amount, comment);
    }
    setIsRefundInProgress(false);

    if (!refundResult.success) {
      setRefundErrorMessage(`Unable to refund the payment - Reason: ${(refundResult as FailedRequest).error}`);
    } else {
      onClose();
    }
  }

  const refundPayment = useCallback(async () => {
    if (!isRefundInProgress && isValid) {
      const amountToRefund =
        partiallyRefundable && refundType === RefundType.PARTIAL
          ? convertToCents(refundAmount)
          : payment.refundableAmount.amount;
      await handleRefund(amountToRefund, refundComment, refundTerminalId);
      setRefundAmount(0);
      setRefundComment('');
      setRefundTerminalId('');
    }
  }, [refundAmount, refundComment, refundTerminalId, refundType, isValid]);

  return {
    isValid,
    refundType,
    setRefundType,
    refundErrorMessage,
    refundAmount,
    setRefundAmount,
    refundComment,
    setRefundComment,
    refundTerminalId,
    setRefundTerminalId,
    isRefundInProgress,
    refundPayment,
    partiallyRefundable,
    isOpen,
    setIsOpen,
  };
};
