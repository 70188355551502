import { IconButton, Typography } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { themeV5 } from 'src/app/theme/ThemeV5';

export const DownloadInvoice = ({ onClick }: { onClick: () => void }) => {
  return (
    <IconButton
      disableRipple
      onClick={onClick}
      sx={{ gap: '0.1rem', alignContent: 'center', color: themeV5.palette.text.primary }}
    >
      <FileDownloadOutlinedIcon fontSize={'large'} />
      <Typography sx={{ textDecoration: 'underline' }}>
        <FormattedMessage id="accounting.invoice_tab.download" defaultMessage={'Download'} />
      </Typography>
    </IconButton>
  );
};
