import { BusinessId, BusinessIdSchema } from 'src/business/domain/Business';
import { z } from 'zod';

export type FindUserRoleResponse = {
  user: {
    id: string;
    email: string;
    last_name: string;
    first_name: string;
    phone_number: string;
    roles: [{ business_id: BusinessId; business_name: string; role: string }];
    relations: [{ relation: string; object_type: string; object_id: string }];
  };
};
const roleSchema = z.object({
  business_id: BusinessIdSchema,
  business_name: z.string(),
  role: z.string(),
});

const relationType = {
  can_access_financials: 'can_access_financials' as const,
} as const;

const relationSchema = z.object({
  relation: z.string(),
  object_type: z.nativeEnum(relationType),
  object_id: z.string(),
});

export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  last_name: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val ?? '')
    .default(''),
  first_name: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val ?? '')
    .default(''),
  phone_number: z
    .string()
    .optional()
    .nullable()
    .transform((val) => val ?? '')
    .default(''),
  roles: z.array(roleSchema),
  relations: z.array(relationSchema),
});
export const findUserRoleResponseSchema = z.object({
  user: userSchema,
});
