import { AverageRatingsViewModel, isLackOfData, isTimeFrameTooShort } from '../AverageRatingsViewModel';
import React, { ReactNode } from 'react';
import { TrendsNotAvailable } from './TrendsNotAvailable';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import TimelineIcon from '@mui/icons-material/Timeline';
import { Typography } from '@mui/material';
import { colorPalette } from 'src/ordering/stylesheet';

export const WithChartIntegrityCheck = ({ viewModel, children }: {
  viewModel: AverageRatingsViewModel,
  children: ReactNode
}) => {
  if (isLackOfData(viewModel)) {
    return (
      <TrendsNotAvailable>
        <Typography color={colorPalette.grey500}>
          <FormattedMessage id="reviews.guest-experience.dimensions.graph_with_lack_of_data"
                            defaultMessage="Not enough data for trends" />
        </Typography>
        <TimelineIcon style={{ color:colorPalette.grey500 }}/>
      </TrendsNotAvailable>
    );
  }

  if (isTimeFrameTooShort(viewModel)) {
    return (
      <TrendsNotAvailable>
        <Typography color={colorPalette.grey500}>
        <FormattedMessage id="reviews.guest-experience.dimensions.graph_with_short_time_frame"
                          defaultMessage="Time frame too short for trends" />
        </Typography>
        <TimelineIcon style={{ color:colorPalette.grey500 }}/>
      </TrendsNotAvailable>
    );
  }
  return children;
};
