import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';
import { customLauncherId } from '../components/IntercomLauncher';
import { userType } from 'src/auth/domain/user';
import { useClaimForVenue } from 'src/auth/hooks/useClaimForVenue';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { Business, getFirstEnrollmentNotPDQ } from 'src/business/domain/Business';
import axios from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpWatchTowerRepository } from '../infrastructure/HttpWatchTowerRepository';
import { datadogLogs } from '@datadog/browser-logs';
import { usePaymentAccount } from 'src/payments/queries/paymentAccount';
import { hasAccessToMultiLocations } from 'src/multi-locations/domain/User';

declare global {
  interface Window {
    Intercom: any;
  }
}

export const useSundayIntercom = (business: Business | undefined, isLoadingBusiness: boolean) => {
  const businessId = business?.id;
  const { boot, update, shutdown } = useIntercom();
  const currentUser = useCurrentUser();

  const claim = useClaimForVenue(businessId);
  const [hasBooted, setHasBooted] = useState(false);


  const configuration = ConfigurationLoader.load();
  const repository = new HttpWatchTowerRepository(axios, configuration);

  const enrollmentId = getFirstEnrollmentNotPDQ(business)?.id;
  const { data: account } = usePaymentAccount(enrollmentId);
  const checkoutIsActive = account?.isCheckoutMigrationToPlatformDone();

  useEffect(() => {
    if (window.Intercom) {
      if (currentUser.authenticated && currentUser.userType !== userType.OPS && !currentUser.impersonated && window.Intercom) {
        boot({ customLauncherSelector: `#${customLauncherId}` });
        setHasBooted(true);
      }
      if (
        !currentUser ||
        (currentUser.authenticated && (currentUser.userType === userType.OPS || currentUser.impersonated))
      ) {
        shutdown();
        setHasBooted(false);
      }
    }
  }, [boot, shutdown, currentUser, window.Intercom]);
  useEffect(() => {
    if (!claim || !hasBooted || !claim || isLoadingBusiness || !business) return;
    const companies = [{ companyId: claim.businessId, name: claim.businessName }];
    const customAttributes = {
      venue_name: claim.businessName,
      business_name: claim.businessName,
      venue_id: claim.businessId,
      business_id: claim.businessId,
      product_type: business.patEnrollment ? ServiceType.PAY_AT_TABLE : ServiceType.ORDER_AND_PAY,
      role: claim.userRole,
      checkoutIsActive: checkoutIsActive,
      'Has multi-locations access': hasAccessToMultiLocations(currentUser),
    };
    repository.getOnboardingBusinessInfo(businessId!).then(
      (onboardingBusinessInfo) => {
        update({
          companies,
          customAttributes: {
            ...customAttributes,
            is_google_setup_done: onboardingBusinessInfo.isGoogleAccountSetupDone,
            is_pos_setup_done: onboardingBusinessInfo.isPosSetupDone,
            is_floor_plan_setup_done: onboardingBusinessInfo.isFloorPlanSetupDone,
            can_accept_payments: onboardingBusinessInfo.canAcceptPayments,
            has_payments: onboardingBusinessInfo.hasPayments,
            is_staff_trained: onboardingBusinessInfo.isStaffTrained,
            'POS Name': business.posReference || '', // already exist in Intercom and doesn't respect naming convention
            Has_PAT: !!business.patEnrollment, // already exist in Intercom and doesn't respect naming convention
            Has_OAP: !!business.oapEnrollment, // already exist in Intercom and doesn't respect naming convention
            has_PDQ: !!business.pdqEnrollment, // already exist in Intercom and doesn't respect naming convention
            business_created_at: business.createdAt, // created_at is reserved in Intercom
          },
        });
      },
    ).catch((error) => {
      datadogLogs.logger.error('An error occurred while trying to get onboarding business info', { businessId, error });
      update({ companies, customAttributes });
    });
  }, [claim, hasBooted, update, isLoadingBusiness, checkoutIsActive]);

  useEffect(() => {
    if (currentUser.authenticated && hasBooted) {
      update({
        email: currentUser.email,
        userId: currentUser.userId,
        name: currentUser.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : currentUser.email,
      });
    }
  }, [hasBooted, update, currentUser]);
};
