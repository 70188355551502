import {
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  borderRadius, colorUsage, spaceUsages, Text, zIndex,
} from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import styled from 'styled-components';
import TimeLine from './HourLine';

type NumberSelectorProps = {
  time: string;
  invalidDate: boolean;
  values: string[];
  onSelection: (value: string) => void;
};

const NumberTextWrapper = styled.div`
  cursor: pointer;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const NumberPanelContainer = styled.div`
  position: relative;
`;

const NumberPanelContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-radius: ${borderRadius.medium};
  border: 1px solid ${colorUsage.secondaryBorder};
  background: ${colorUsage.fogBackground};
  padding-top: ${spaceUsages.mediumLarge};
  padding-bottom: ${spaceUsages.mediumLarge};
  width: 96px;
  overflow-y: auto;
  height: 164px;
  z-index: ${zIndex.MODAL + 20};
`;

const OutsideDiv = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: ${zIndex.MODAL + 10};
`;

const NumberSelector = ({
  time, invalidDate, values, onSelection,
}: NumberSelectorProps) => {
  const [displayNumbers, setDisplayNumbers] = useState<boolean>(false);

  const openPanel = () => {
    setDisplayNumbers(true);
  };

  const closePanel = () => {
    setDisplayNumbers(false);
  };

  const onHourSelected = (hour: string) => {
    setDisplayNumbers(false);
    onSelection(hour);
  };

  return (
    <>
      <NumberContainer>
        <NumberTextWrapper>
          <Text size="medium" color={invalidDate ? colorUsage.error : ''} onClick={openPanel}>
            {time}
          </Text>
        </NumberTextWrapper>
        {displayNumbers && (
          <>
            <OutsideDiv onClick={closePanel} />
            <NumberPanelContainer onClick={closePanel}>
              <NumberPanelContent>
                {values.map((value, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TimeLine key={String(index)} time={value} onSelection={onHourSelected} />
                ))}
              </NumberPanelContent>
            </NumberPanelContainer>
          </>
        )}
      </NumberContainer>
    </>
  );
};

export default NumberSelector;
