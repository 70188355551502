import React, { FC, FormEvent, useState } from 'react';
import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Check } from '@mui/icons-material';
import { useCreateProductMutation } from 'src/menu/dynamic-menu/mutations/product/useProductMutation';
import { Business } from 'src/business/domain/Business';
import { useNavigate } from 'react-router';
import { createMoneyWithCurrency } from '@sundayapp/web-money';
import { productTypes } from 'src/menu/dynamic-menu/domain/Product';
import { lowercase } from 'src/menu/dynamic-menu/pages/CreateProductPage/CreateProductPage';

type Form = {
  name: string;
  price: string;
};
type Nullable<T> = { [K in keyof T]: T[K] | null };

export const ProductForm: FC<{
  business: Business,
  productType: (typeof productTypes.PRODUCT | typeof productTypes.BUNDLE)
}> = ({ business, productType }) => {
  const navigate = useNavigate();

  const [ form, setForm ] = useState<Nullable<Form>>({ name: null, price: '0' });
  const createProduct = useCreateProductMutation(business.id);

  const isFormComplete = (f: Nullable<Form>): f is Form => {
    return !!form.name && !!form.price;
  };

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormComplete(form)) {
      // handle error
      return;
    }

    createProduct.mutateAsync({
      name: form.name,
      description: '',
      type: productType,
      price: createMoneyWithCurrency(form.price, business.currency),
    })
      .then((createdProductId) => {
        navigate(`../${createdProductId}`, { replace: true });
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Box display="flex" flexDirection="column" gap="24px" margin="auto">

        <Box display="flex" flexDirection="column" gap="24px">
          <Typography variant="h5">
            <FormattedMessage id={`menus.catalog.products.form.${lowercase(productType)}.label`} />
          </Typography>
          <Typography variant="subtitle2" color={themeV5.palette.text.secondary}>
            <FormattedMessage id={`menus.catalog.products.form.${lowercase(productType)}.label.subtitle`} />
          </Typography>
        </Box>

        <Box display="flex" gap={2}>
          <TextField
            variant="outlined"
            size="medium"
            required
            label={<FormattedMessage id="menus.product.details.name" />}
            sx={{ width: '100%' }}
            value={form.name}
            onChange={(e) => setForm((f) => ({ ...f, name: e.target.value }))}
          />

          <TextField
            variant="outlined"
            size="medium"
            required
            label={<FormattedMessage id="menus.product.details.price" />}
            sx={{ width: 250 }}
            type="number"
            value={form.price}
            onChange={(e) => setForm((f) => ({
              ...f,
              price: e.target.value,
            }))}
            InputProps={{
              inputProps: {
                min: 0,
                step: 0.01,
              },
              endAdornment: <InputAdornment position="end">{business.currency}</InputAdornment>,
            }}
          />
        </Box>

        <Box>
          <Button type="submit" size="large" variant="contained" disabled={!isFormComplete(form)}>
            <Box display="flex" justifyContent="center" alignItems="center" gap="8px" padding="8px 22px">
              <Check sx={{ width: '24px', height: '24px' }} />
              <Typography variant="subtitle1">
                <FormattedMessage id={`menus.catalog.products.create.${lowercase(productType)}`} />
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </form>
  );
};
