import * as React from 'react';
import { useState } from 'react';
import { Button, Card, CardContent, styled, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Block } from '../../domain/Block';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BlockDescription } from './components/BlockDescription';
import { Language } from 'src/app/i18n/AllSupportedLanguage';
import { useBlockMutation } from '../../mutations/block/useBlockMutation';
import { BlockTitle } from './components/BlockTitle';
import { BlockLinkLabel } from './components/BlockLinkLabel';
import { BlockLinkUrl } from './components/BlockLinkUrl';
import { UpdateBlockDto } from '../../repositories/block/BlockDto';
import { useBlockTranslationMutation } from '../../mutations/block/useBlockTranslationMutation';
import { BlockColorPicker } from './components/BlockColorPicker';
import { BusinessId } from 'src/business/domain/Business';

type Props = {
  businessId: BusinessId;
  block: Block;
  currentLanguage: Language | undefined;
  translationMode: boolean;
};

const DetailsCardContent = styled(CardContent)`
  flex-direction: column;
  display: flex;
  padding: 32px;
  gap: 24px;
`;

const ColorsContainer = styled('div')`
  flex-direction: row;
  display: flex;
  gap: 24px;
`;
export const BlockDetailsPanel = ({ businessId, block, currentLanguage, translationMode }: Props) => {
  const intl = useIntl();

  const [needSave, setNeedSave] = useState(false);
  const [blockUpdateDto, setBlockUpdateDto] = useState<UpdateBlockDto>({});
  const updateBlock = useBlockMutation(businessId, block.id);
  const updateBlockTranslation = useBlockTranslationMutation(businessId, block.id);

  const snackBar = useSnackbar();

  function checkNeedSave(blockUpdate: UpdateBlockDto) {
    if (Object.values(blockUpdate).every((el) => el === undefined)) {
      setNeedSave(false);
    } else {
      setNeedSave(true);
    }
  }

  const showUpdateError = () => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({
        id: 'menus.block.update.notification.error',
        defaultMessage: 'unable to update block',
      }),
    });
  };
  const showUpdateSuccess = () => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({ id: 'menus.block.update.notification.success', defaultMessage: 'block updated' }),
    });
  };

  const updateBlockUpdateDto = (updateDto: UpdateBlockDto) => {
    setBlockUpdateDto(updateDto);
    checkNeedSave(updateDto);
  };

  const sendBlockUpdate = () => {
    if (translationMode && currentLanguage) {
      updateBlockTranslation
        .mutateAsync({ language: currentLanguage.id, updateBlock: blockUpdateDto })
        .then(() => {
          updateBlockUpdateDto({});
          showUpdateSuccess();
        })
        .catch(() => showUpdateError());
    } else {
      updateBlock
        .mutateAsync(blockUpdateDto)
        .then(() => {
          updateBlockUpdateDto({});
          showUpdateSuccess();
        })
        .catch(() => showUpdateError());
    }
  };

  return (
    <Card>
      <DetailsCardContent>
        <Typography fontSize="large">
          <FormattedMessage id="menus.block.details.header" />
        </Typography>

        <BlockTitle block={block} blockUpdate={blockUpdateDto} setBlockUpdate={updateBlockUpdateDto} />

        <BlockLinkLabel block={block} blockUpdate={blockUpdateDto} setBlockUpdate={updateBlockUpdateDto} />

        <BlockLinkUrl
          block={block}
          blockUpdate={blockUpdateDto}
          setBlockUpdate={updateBlockUpdateDto}
          translationMode={translationMode}
        />
        <BlockDescription block={block} blockUpdate={blockUpdateDto} setBlockUpdate={updateBlockUpdateDto} />

        <ColorsContainer>
          <BlockColorPicker
            defaultValue={block.textColor}
            fieldNameToUpdate="textColor"
            label={intl.formatMessage({ id: 'menus.block.details.colors.textColor' })}
            disabled={translationMode}
            blockUpdate={blockUpdateDto}
            setBlockUpdate={updateBlockUpdateDto}
          />
          <BlockColorPicker
            defaultValue={block.backgroundColor}
            fieldNameToUpdate="backgroundColor"
            label={intl.formatMessage({ id: 'menus.block.details.colors.backgroundColor' })}
            disabled={translationMode}
            blockUpdate={blockUpdateDto}
            setBlockUpdate={updateBlockUpdateDto}
          />
        </ColorsContainer>
        <Button variant="contained" disabled={!needSave} onClick={sendBlockUpdate}>
          <FormattedMessage id="menus.block.details.save_changes" />
        </Button>
      </DetailsCardContent>
    </Card>
  );
};
