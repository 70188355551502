import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useLocation } from 'react-router';
import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { defaultReplyTemplate } from '../../browse/allReviews/components/ReviewCards';
import { ReplyTemplate, ReplyTemplateDraft } from '../domain/ReplyTemplate';
import { HttpReplyTemplateRepository } from '../infrastructure/HttpReplyTemplateRepository';
import { ReplyTemplateInteractiveMenu } from './ReplyTemplateInteractiveMenu';
import { ReplyTemplateModal } from './ReplyTemplateModal';
import { SettingContainer, TextContainer } from '../../settings/ReviewsSettings.styles';
import { Google, LogoWrapper } from '../../settings/setup/google/components/GoogleReviewSetupTile';
import { ReplyTemplateCsvExporterButton } from './ReplyTemplateCsvExporterButton';
import { ImportReplyTemplateCsvButton } from './ImportReplyTemplateCsvButton';
import { Row } from '../../../Row';
import { rem } from '../../../Size';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import {
  ConfirmResetToDefaultsDialog,
} from 'src/sundayplus/reviews/reply-template/components/ConfirmResetToDefaultsDialog';

type ReplyTemplateProps = {
  businessId: string;
};

type Action = {
  title: string;
  action: string;
};

type AddAction = Action & {
  type: 'add';
  template: ReplyTemplateDraft;
};

type UpdateAction = Action & {
  type: 'update';
  initialTemplate: ReplyTemplate;
};

type DuplicateAction = Action & {
  type: 'duplicate';
  initialTemplate: ReplyTemplate;
};

type PayloadAction = AddAction | UpdateAction | DuplicateAction;

export const ReplyTemplatePanel = ({ businessId }: ReplyTemplateProps) => {
  const { hash } = useLocation();
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const repository = new HttpReplyTemplateRepository(ConfigurationLoader.load().venueFeedbackBaseUrl);
  const snackbar = useSnackbar();

  const [replyTemplates, setReplyTemplates] = useState<ReplyTemplate[]>([]);
  const [selectedAction, setSelectedAction] = useState<PayloadAction | undefined>(undefined);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const listTemplates: () => Promise<ReplyTemplate[]> = async () => repository.list(businessId);

  const reloadTemplates = () => listTemplates().then((templates) => setReplyTemplates(templates));

  function addDefaultTemplate() {
    const defaultReplyTemplates = defaultReplyTemplate(business.name, intl);
    Promise.all(defaultReplyTemplates.map((replyTemplate) => repository.add(businessId, replyTemplate)))
      .then(reloadTemplates);
  }

  const initTemplates: () => void = () => {
    listTemplates().then((templates) => {
      if (templates && templates.length === 0) {
        addDefaultTemplate();
        snackbar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.default_template_added' }),
        });
      } else {
        setReplyTemplates(templates);
      }
    });
  };

  function scrollToAnAnchor() {
    if (hash === '') {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 500);
    }
  }

  function successSnackBar() {
    snackbar.addNotification({
      variant: 'success',
      // TODO i18n
      text: 'templates imported successfully',
    });
  }

  function errorSnackBar() {
    snackbar.addNotification({
      variant: 'error',
      // TODO i18n
      text: 'Something went wrong. This may be due to cvs format',
    });
  }

  useEffect(() => {
    scrollToAnAnchor();
    initTemplates();
  }, []);

  const onCreateReplyTemplateClicked = () => {
    const defaultReplyTemplateDraft = {
      name: '',
      content: '',
      applicableRatings: [],
      prefilled: false,
    };
    const action: AddAction = {
      type: 'add',
      template: defaultReplyTemplateDraft,
      title: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.add_new.title' }),
      action: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.add_new.button' }),
    };
    setSelectedAction(action);
    ampli.createReplyTemplateClicked();
  };

  const onUpdateReplyTemplateClicked = (replyTemplate: ReplyTemplate) => {
    const action: UpdateAction = {
      type: 'update',
      initialTemplate: replyTemplate,
      title: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.update.title' }),
      action: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.update.button' }),
    };
    setSelectedAction(action);
    ampli.editReplyTemplateClicked({
      templateId: replyTemplate.id,
      templateName: replyTemplate.name,
    });
  };

  const onDuplicateReplyTemplateClicked = (replyTemplate: ReplyTemplate) => {
    const duplicateAction: DuplicateAction = {
      type: 'duplicate',
      initialTemplate: replyTemplate,
      title: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.duplicate.title' }),
      action: intl.formatMessage({ id: 'settings.reviews_and_loyalty.reply_template.modal.duplicate.button' }),
    };
    setSelectedAction(duplicateAction);
    ampli.duplicateReplyTemplateClicked({
      templateId: replyTemplate.id,
      templateName: replyTemplate.name,
    });
  };

  const onReplyTemplateAction =
    (action: PayloadAction) =>
      async (replyTemplateDraft: ReplyTemplateDraft): Promise<void> => {
        switch (action.type) {
          case 'add':
            await repository.add(businessId, replyTemplateDraft);
            ampli.replyTemplateCreated({ templateName: replyTemplateDraft.name });
            break;
          case 'update':
            const replyTemplate = { ...action.initialTemplate!, ...replyTemplateDraft };
            await repository.update(businessId, replyTemplate);
            ampli.replyTemplateEdited({
              templateId: replyTemplate.id,
              templateName: replyTemplate.name,
            });
            break;
          case 'duplicate':
            await repository.add(businessId, replyTemplateDraft);
            ampli.replyTemplateDuplicated({
              templateName: replyTemplateDraft.name,
            });
            break;
        }
      };

  const onDeleteReplyTemplate = async (replyTemplate: ReplyTemplate) => {
    ampli.deleteReplyTemplateClicked({
      templateId: replyTemplate.id,
      templateName: replyTemplate.name,
    });
    await repository.delete(businessId, replyTemplate.id);
    reloadTemplates();
  };

  const toReplyTemplateDraft = (action: PayloadAction): ReplyTemplateDraft => {
    if (action.type === 'add') {
      return action.template;
    }
    return {
      name: action.initialTemplate.name,
      content: action.initialTemplate.content,
      applicableRatings: action.initialTemplate.applicableRatings,
      prefilled: action.initialTemplate.prefilled,
    };
  };

  const resetToDefaultTemplates = async () => {
    await repository.replaceAll(businessId, defaultReplyTemplate(business.name, intl));
    await reloadTemplates();
  };

  return (
    <Box id="reply-template">
      <Stack direction="column" gap={3} mt={4}>
        <TextContainer>
          <LogoWrapper>
            <Google />
            <Typography variant="h6">
              <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.title" />
            </Typography>
          </LogoWrapper>
        </TextContainer>
        <SettingContainer>
          <Row justifyContent="flex-end" gap={rem(1)} fullWidth>
            <ReplyTemplateCsvExporterButton replyTemplates={replyTemplates} />
            <ImportReplyTemplateCsvButton
              onFileSelectionSuccess={(drafts) =>
                repository
                  .replaceAll(businessId, drafts)
                  .then(setReplyTemplates)
                  .then(successSnackBar)
                  .catch(errorSnackBar)
              }
              onFileSelectionError={errorSnackBar}
            />
            <Button size="medium" variant="outlined" startIcon={<RestartAltIcon/>} onClick={() => setShowConfirmDialog(true)}>
              <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.reset_to_default" defaultMessage="Reset all" />
            </Button>
            <Button size="medium" variant="contained" onClick={() => onCreateReplyTemplateClicked()}>
              <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.add_new" />
            </Button>
          </Row>
        </SettingContainer>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.name_column" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="settings.reviews_and_loyalty.reply_template.list.content_column" />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {replyTemplates.map((template) => (
                <TableRow key={template.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    <Typography>{`${template.name} [${template.applicableRatings.join(',')}]`}</Typography>
                  </TableCell>
                  <TableCell>
                    {template.content.split('\n').map((line, index) => (
                      <Typography key={index}>{line}</Typography>
                    ))}
                  </TableCell>
                  <TableCell>
                    <ReplyTemplateInteractiveMenu
                      replyTemplate={template}
                      onDelete={() => onDeleteReplyTemplate(template)}
                      onDuplicate={() => onDuplicateReplyTemplateClicked(template)}
                      onUpdate={() => onUpdateReplyTemplateClicked(template)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      {selectedAction !== undefined && (
        <ReplyTemplateModal
          title={selectedAction.title}
          submitLabel={selectedAction.action}
          onReplyTemplateAction={onReplyTemplateAction(selectedAction)}
          template={toReplyTemplateDraft(selectedAction)}
          useCase={selectedAction.type}
          isOpen
          handleClose={() => {
            setSelectedAction(undefined);
          }}
          handleConfirm={async () => {
            setSelectedAction(undefined);
            reloadTemplates();
          }}
        />
      )}
      {showConfirmDialog && (
        <ConfirmResetToDefaultsDialog
          onClose={() => setShowConfirmDialog(false)}
          onConfirm={resetToDefaultTemplates}
        />
      )}
    </Box>
  );
};
