import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Product, ProductSource } from '../../../domain/Product';
import { UpdateProductDto } from '../../../repositories/ProductDto';

type Props = {
  product: Product
  productUpdate: UpdateProductDto
  setProductUpdate: (productDto: UpdateProductDto) => void
};
export const ProductDescription = ({
  product, productUpdate, setProductUpdate,
}: Props) => {
  const intl = useIntl();

  const [value, setValue] = useState(product.description);

  useLayoutEffect(() => {
    setValue(product.description ?? '');
  }, [product.description]);

  const updateValue = (newValue: string) => {
    let newProductDto = productUpdate;
    if (newValue !== (product.description ?? '')) {
      newProductDto = {
        ...newProductDto,
        description: newValue,
      };
    } else {
      newProductDto = {
        ...newProductDto,
        description: undefined,
      };
    }
    setProductUpdate(newProductDto);
    setValue(newValue);
  };

  const canRestoreValue = () => product.source === ProductSource.PARTNER && value !== product.partnerDescription;
  const restorePosName = () => {
    updateValue(product.partnerDescription);
  };
  return (
    <Box component="div" display="flex" flexDirection="row" alignItems="center" width="100%">
      <TextField
        variant="outlined"
        size="medium"
        multiline
        fullWidth
        minRows={3}
        label={intl.formatMessage({ id: 'menus.product.details.description' })}
        value={value}
        onChange={(e) => updateValue(e.target.value)}
      />
      {canRestoreValue() && (
        <Tooltip
          title={intl.formatMessage({ id: 'menus.product.details.restore_pos_value' })}
          placement="top"
          sx={{ fontSize: '30px' }}
        >
          <IconButton size="large" sx={{ height: 'fit-content' }} onClick={restorePosName}>
            <SettingsBackupRestoreIcon
              fontSize="inherit"
            />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
