import axios from 'axios';
import { ReplyTemplate, ReplyTemplateDraft } from '../domain/ReplyTemplate';
import { ReplyTemplateRepository } from '../domain/ReplyTemplateRepository';

export type AddReplyTemplatePayload = {
  businessId: string;
  applicableRatings: number[];
  name: string;
  content: string
  prefilled: boolean;
};

export type UpdateReplyTemplatePayload = {
  applicableRatings: number[];
  name: string;
  content: string
  prefilled: boolean;
};

export type ListReplyTemplatesResponsePart = {
  id: string;
  businessId: string;
  applicableRatings: number[];
  name: string;
  content: string
  prefilled: boolean;
};

export class HttpReplyTemplateRepository implements ReplyTemplateRepository {
  constructor(private readonly baseUrl: string) {
  }

  async list(businessId: string): Promise<ReplyTemplate[]> {
    const response = await axios.get(`${this.baseUrl}/businesses/${businessId}/reply-templates`);
    return response.data.replyTemplates.map((responsePart: ListReplyTemplatesResponsePart) => this.fromListReplyTemplatesResponse(responsePart));
  }

  add(businessId: string, replyTemplateDraft: ReplyTemplateDraft): Promise<void> {
    return axios.post(`${this.baseUrl}/businesses/${businessId}/reply-templates`, this.toAddReplyTemplatePayload(businessId, replyTemplateDraft));
  }

  update(businessId: string, replyTemplate: ReplyTemplate): Promise<void> {
    return axios.put(`${this.baseUrl}/businesses/${businessId}/reply-templates/${replyTemplate.id}`,
      this.toUpdateReplyTemplatePayload(replyTemplate));
  }

  delete(businessId: string, replyTemplateId: string): Promise<void> {
    return axios.delete(`${this.baseUrl}/businesses/${businessId}/reply-templates/${replyTemplateId}`, {});
  }

  async replaceAll(businessId: string, drafts: ReplyTemplateDraft[]): Promise<ReplyTemplate[]> {
    const response = await axios.put(`${this.baseUrl}/businesses/${businessId}/reply-templates/replace-all`, { replyTemplates: drafts });
    return response.data.map(this.fromListReplyTemplatesResponse);
  }

  fromListReplyTemplatesResponse = (response: ListReplyTemplatesResponsePart): ReplyTemplate => ({
    id: response.id,
    applicableRatings: response.applicableRatings,
    name: response.name,
    content: response.content,
    prefilled: response.prefilled,
  });

  toAddReplyTemplatePayload = (businessId: string, replyTemplateDraft: ReplyTemplateDraft): AddReplyTemplatePayload => (
    {
      businessId,
      applicableRatings: replyTemplateDraft.applicableRatings,
      name: replyTemplateDraft.name,
      content: replyTemplateDraft.content,
      prefilled: replyTemplateDraft.prefilled,
    }
  );

  toUpdateReplyTemplatePayload = (replyTemplate: ReplyTemplate): UpdateReplyTemplatePayload => (
    {
      applicableRatings: replyTemplate.applicableRatings,
      name: replyTemplate.name,
      content: replyTemplate.content,
      prefilled: replyTemplate.prefilled,
    }
  );
}
