import * as React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ListPaymentTerminals from '../components/ListPaymentTerminals';
import TabPanel from '../../app/component/Tab/TabPanel';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import NoPdq from '../components/NoPdq';
import { ListConfigurations } from '../components/settings/ListConfigurations';
import { NetptingConfigurationForm } from '../components/nepting/NeptingConfigurationForm';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { PayrocConfigurationForm } from 'src/payment-terminal/components/payroc/PayrocConfigurationForm';
import { DnaConfigurationForm } from 'src/payment-terminal/components/dna/DnaConfigurationForm';
import { StripeConfigurationForm } from 'src/payment-terminal/components/stripe/StripeConfigurationForm';


function PaymentTerminal() {
  const intl = useIntl();
  const [value, setValue] = React.useState(0);
  const business = useCurrentBusinessOrThrow();
  const currentUser = useAuthenticatedUserOrThrow();
  const isImpersonated = currentUser.impersonated;
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const pdqEnrollment = business.pdqEnrollment;

  if (pdqEnrollment?.status !== 'ACTIVE') {
    return <NoPdq />;
  }

  return (
    <>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={intl.formatMessage({ id: 'payment.terminal.tab.manage' })} />
        <Tab label={intl.formatMessage({ id: 'payment.terminal.tab.settings' })} />
        {isImpersonated && business.address.countryCode === 'FR' && (
          <Tab label={intl.formatMessage({ id: 'payment.terminal.tab.nepting.configuration' })} />
        )}
        {isImpersonated && business.address.countryCode === 'US' && (
          <Tab label={intl.formatMessage({ id: 'payment.terminal.tab.payroc.configuration' })} />
        )}
        {isImpersonated && business.address.countryCode === 'GB' && (
          <Tab label={intl.formatMessage({ id: 'payment.terminal.tab.dna.configuration' })} />
        )}
        {isImpersonated && business.address.countryCode === 'DE' && (
          <Tab label={intl.formatMessage({ id: 'payment.terminal.tab.stripe.configuration' })} />
        )}
      </Tabs>
      <TabPanel value={value.toString()} index="0">
        <ListPaymentTerminals enrollmentId={pdqEnrollment.id} />
      </TabPanel>

      <TabPanel value={value.toString()} index="1">
        <ListConfigurations enrollmentId={pdqEnrollment.id} currency={business.currency} />
      </TabPanel>

      {isImpersonated && business.address.countryCode === 'FR' && (
        <TabPanel value={value.toString()} index="2">
          <NetptingConfigurationForm enrollmentId={pdqEnrollment.id} />
        </TabPanel>
      )}

      {isImpersonated && business.address.countryCode === 'US' && (
        <TabPanel value={value.toString()} index="2">
          <PayrocConfigurationForm enrollmentId={pdqEnrollment.id} />
        </TabPanel>
      )}

      {isImpersonated && business.address.countryCode === 'GB' &&  (
        <TabPanel value={value.toString()} index="2">
          <DnaConfigurationForm enrollmentId={pdqEnrollment.id} />
        </TabPanel>
      )}

      {isImpersonated && business.address.countryCode === 'DE' && (
        <TabPanel value={value.toString()} index="2">
          <StripeConfigurationForm enrollmentId={pdqEnrollment.id} />
        </TabPanel>
      )}
    </>
  );
}

export default PaymentTerminal;
