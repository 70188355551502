import { Stack, Typography } from '@mui/material';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { InfoOutlined } from '@mui/icons-material';
import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const DispatchPrerequisites = () => <Stack
  sx={{ background: themeV5.palette.info.main, color: themeV5.palette.info.contrastText }}
  direction="row" spacing={1} justifyContent={'center'} alignItems={'center'} padding={1}>
  <InfoOutlined />
  <Typography>
    <FormattedMessage id={'tips.sunday_pooling.dispatch.prerequisites'} />
  </Typography>
</Stack>;
