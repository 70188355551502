import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { pongoOnboardingPath } from 'src/app/navigation/pathHelpers';
import { useNavigate } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { datadogLogs } from '@datadog/browser-logs';
import { useUpdatePongoConfiguration } from './mutations/useSavePongoBusinessConfiguration';
import { LoyaltyBusiness } from 'src/loyalty/domain/LoyaltyBusiness';
import SundayTooltip from 'src/app/component/tooltip/SundayTooltip';
import MenuItem from '@mui/material/MenuItem';
import { PongoValidationBurnModal } from 'src/loyalty/PongoValidationBurnModal';

type OnboardingInputs = {
  initialConfiguration: LoyaltyBusiness;
  rewardCode?: string;
  burnEnabled: boolean;
  theme: string;
};

export const PongoOnboardingForm = () => {
  const businessId = useBusinessIdOrThrow();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const {
    data: loyaltyBusiness,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);

  const saveConfiguration = useUpdatePongoConfiguration(businessId);
  const [onboardingError, setOnboardingError] = useState<string>('');
  const [visible, setVisible] = useState(false);
  const {
    getValues,
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
    },
  } = useForm<OnboardingInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const snackBar = useSnackbar();
  const onSubmit: SubmitHandler<OnboardingInputs> = async ({
    rewardCode,
    burnEnabled,
    theme,
  }) => {
    setOnboardingError('');
    try {
      saveConfiguration.mutate({
        loyaltyConfiguration: {
          rewardCode,
          burnEnabled,
          theme,
        },
      });
      snackBar.addNotification({
        variant: 'success',
        text: formatMessage({ id: 'loyalty.onboarding.success', defaultMessage: 'Configuration saved' }),
      });
    } catch (error: any) {
      snackBar.addNotification({ variant: 'error', text: formatMessage({ id: 'loyalty.onboarding.errors.other' }) });
      datadogLogs.logger.error('An error occurred while trying to configure a Pongo business', { error });
      setOnboardingError(formatMessage({ id: 'loyalty.onboarding.errors.other' }));
    }
  };

  if (loyaltyReferenceIsLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack gap="16px" maxWidth="576px">
      <Typography variant="h5">
        {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Pongo' })}
      </Typography>

      {loyaltyBusiness && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gap="16px" maxWidth="576px">
            <FormControl>
              <InputLabel id="theme">Theme</InputLabel>
              <Select
                label="Program"
                defaultValue={loyaltyBusiness?.loyaltyConfiguration?.theme || 'default'}
                id="theme"
                {...register('theme')}
              >
                <MenuItem value="default">Default Theme</MenuItem>
                <MenuItem value="GRUPPOMIMO">Gruppomimo</MenuItem>
              </Select>
            </FormControl>
            <SundayTooltip
              title={formatMessage({
                id: 'loyalty.onboarding.available_pos',
              }, { pos: 'IKENTOO' })}
              placement="top-start"
            >
              <FormControlLabel
                label={formatMessage({
                  id: 'loyalty.onboarding.enable_burn',
                })}
                control={
                  <Checkbox
                    id="burnEnabled"
                    defaultChecked={loyaltyBusiness?.burnEnabled}
                    {...register('burnEnabled')} />}
              />
            </SundayTooltip>

            <TextField
              label="Reward Code"
              error={!!errors.rewardCode}
              id="rewardCode"
              defaultValue={loyaltyBusiness?.loyaltyConfiguration?.rewardCode}
              helperText={errors.rewardCode?.message}
              {...register('rewardCode')}
            />

            {onboardingError && (
              <Alert severity="error">{onboardingError}</Alert>
            )}

            <Button
              variant="contained"
              disabled={!isValid || isSubmitting}
              onClick={() => {
                if (getValues('burnEnabled')) {
                  setVisible(true);
                } else {
                  handleSubmit(onSubmit)();
                }
              }}
            >
              {formatMessage({ id: 'loyalty.onboarding.save' })}
            </Button>
          </Stack>

          <PongoValidationBurnModal
            visible={visible}
            disabled={!isValid || isSubmitting}
            onClick={() => {
              handleSubmit(onSubmit)();
              setVisible(false);
            }}
            onClose={() => {
              setVisible(false);
            }}
          />
        </form>
      )}
      {!loyaltyBusiness && (<Button
        variant="contained"
        onClick={() => {
          navigate(pongoOnboardingPath(businessId));
        }}
      >
        {'Go to'} {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Pongo' })}
      </Button>)}

    </Stack>
  );
};
