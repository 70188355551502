import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { floorPlanReducer } from '../floorplan/store';
import { IRootState } from './types';
// eslint-disable-next-line import/no-cycle
import { eReputationReducer } from './e-reputation';
import { getOrderingReducers } from '../ordering/common/redux/reducers';

type BuildStore = () => EnhancedStore<IRootState>;

const withStore: BuildStore = () =>
  configureStore({
    reducer: combineReducers({
      floorPlan: floorPlanReducer,
      ordering: getOrderingReducers(),
      eReputationPageChoices: eReputationReducer,
    }),
    devTools: true,
  });

export default withStore;
