/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Venue, VenueDetails } from '../types';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export interface VenueState {
  currentVenue?: Venue;
  venues?: Venue[];
  venueDetails?: VenueDetails;
}

const initialState: VenueState = {};

export const venueSlice = createSlice({
  name: 'venue',
  initialState,
  reducers: {
    setVenues: (state, action: PayloadAction<Venue[]>) => {
      state.venues = action.payload;
      if (action.payload.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        state.currentVenue = action.payload[0];
      }
    },
    setCurrentVenue: (state, action: PayloadAction<Venue>) => {
      state.currentVenue = action.payload;
    },
    setVenueDetails: (state, action: PayloadAction<VenueDetails>) => {
      state.venueDetails = action.payload;
    },
    updateVenue: (state, action: PayloadAction<{ enrollmentId: EnrollmentId; update: Partial<Venue> }>) => {
      if (action.payload.enrollmentId === state.currentVenue?.id) {
        state.currentVenue = {
          ...state.currentVenue,
          ...action.payload.update,
        };
      }
      if (action.payload.enrollmentId === state.venueDetails?.id) {
        state.venueDetails = {
          ...state.venueDetails,
          ...action.payload.update,
        };
      }
      state.venues = state.venues!.map((venue) =>
        (venue.id === action.payload.enrollmentId ? { ...venue, ...action.payload.update } : venue));
    },
  },
});

export const {
  setVenues, setCurrentVenue, setVenueDetails, updateVenue,
} = venueSlice.actions;
export default venueSlice.reducer;
