import React from 'react';
import styled from 'styled-components';
import { Button, Tab, Tabs } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { AreaSettingContainer } from '../../../components/Settings.style';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { getFulfillmentMethodText } from '../../../formatting';
import { AreaBoxMenuSelection } from './AreaBoxMenuSelection';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { ServiceMenuAssociations } from '../../ServiceMenuAssociation';
import { usePushNotification } from '../../../../common/components/Notifications.hook';
import { useUpdateAreaConfigurations } from '../../../hooks';
import { AreaConfiguration } from '../../../domain/AreaConfiguration';
import { areaConfigurationMenuId } from 'src/ordering/area/pages/AreaDetailsSettings/components/AreaDetailsServicePanel';

type AreaBoxCreationMenuSelectionProps = {
  areaId: string;
  services: FulfillmentType[];
  menus: OrderingMenu[];
  selectedMenus: ServiceMenuAssociations;
  onMenuSelectionUpdated: (associations: ServiceMenuAssociations) => void;
  onBackButton: () => void;
  onMenuConfigured: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${getSpacing(3)};
  padding-right: ${getSpacing(3)};
  padding-top: ${getSpacing(3)};
  padding-bottom: ${getSpacing(2)};
  border: 1px solid ${colorPalette.grey300};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  background-color: ${colorPalette.defaultBackgroundColor};
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: ${getSpacing(1)};
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${colorPalette.grey500};
  margin-bottom: ${getSpacing(3)};
`;

const ActionLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

export const AreaBoxCreationMenuSelection = ({
  areaId,
  services,
  menus,
  selectedMenus,
  onMenuSelectionUpdated,
  onBackButton,
  onMenuConfigured,
}: AreaBoxCreationMenuSelectionProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [updateAreaConfigurationState, updateAreaConfigurations] = useUpdateAreaConfigurations();

  const onNextStep = async () => {
    const areaConfigurations: AreaConfiguration[] = [];
    selectedMenus.associations.forEach((association) => association.menus.forEach((menu) => {
      areaConfigurations.push({
        fulfillmentMethod: association.service,
        menuId: areaConfigurationMenuId(menu),
      });
    }));

    await updateAreaConfigurations(areaId, areaConfigurations);

    pushNotification(
      intl.formatMessage({
        id: 'venue.settings.create.menu-selection.notification',
        defaultMessage: 'menu association done',
      }),
    );

    onMenuConfigured();
  };

  const getSelectedMenus = (service: FulfillmentType): OrderingMenu[] => {
    const association = selectedMenus.associations.find((item) => item.service === service);
    if (association === undefined) {
      return [];
    }
    return association.menus;
  };

  const onMenuToggled = (service: FulfillmentType, menu: OrderingMenu, activated: boolean) => {
    const associationId = selectedMenus.associations.findIndex((item) => item.service === service);
    if (associationId < 0) {
      if (activated) {
        selectedMenus.associations.push({ service, menus: [menu] });
      } else {
        selectedMenus.associations.push({ service, menus: [] });
      }
      onMenuSelectionUpdated({ ...selectedMenus });
      return;
    }

    const association = selectedMenus.associations[associationId];

    if (activated) {
      // eslint-disable-next-line no-param-reassign
      selectedMenus.associations[associationId] = { service, menus: [...association.menus, menu] };
    } else {
      // eslint-disable-next-line no-param-reassign
      selectedMenus.associations[associationId] = { service, menus: association.menus.filter((m) => m.id !== menu.id) };
    }
    onMenuSelectionUpdated({ ...selectedMenus });
  };

  return (
    <AreaSettingContainer>
      <Container>
        <Title>
          <FormattedMessage
            id="settings.area.create.menu-selection.title"
            defaultMessage="select the menus for your services"
          />
        </Title>
        <Description>
          <FormattedMessage
            id="settings.area.create.menu-selection.description"
            defaultMessage="select 1 or more for each service"
          />
        </Description>
        <Tabs initialSelected={services[0]}>
          {services.map((service) => (
            <Tab
              key={service}
              id={service}
              title={getFulfillmentMethodText(intl, service)}
              disabled={updateAreaConfigurationState.loading}
            >
              <AreaBoxMenuSelection
                service={service}
                menus={menus}
                selectedMenus={getSelectedMenus(service)}
                onMenuToggled={onMenuToggled}
              />
            </Tab>
          ))}
        </Tabs>
      </Container>
      <ActionLine>
        <Button
          variant="tertiary"
          size="large"
          marginTop="8px"
          onClick={onBackButton}
          disabled={updateAreaConfigurationState.loading}
        >
          <FormattedMessage id="settings.area.create.back-btn" defaultMessage="back" />
        </Button>
        <Button
          variant="primary"
          size="large"
          marginTop="8px"
          onClick={onNextStep}
          disabled={updateAreaConfigurationState.loading}
        >
          <FormattedMessage id="settings.area.create.associate-menu-btn" defaultMessage="associate menus" />
        </Button>
      </ActionLine>
    </AreaSettingContainer>
  );
};
