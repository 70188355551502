import React from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { DayOfWeek } from '../domain/TimePeriod';

const dayOfWeekLabels: Record<DayOfWeek, React.ReactNode> = {
  [DayOfWeek.MONDAY]: <FormattedMessage id="days.monday" defaultMessage="monday" />,
  [DayOfWeek.TUESDAY]: <FormattedMessage id="days.tuesday" defaultMessage="tuesday" />,
  [DayOfWeek.WEDNESDAY]: <FormattedMessage id="days.wednesday" defaultMessage="wednesday" />,
  [DayOfWeek.THURSDAY]: <FormattedMessage id="days.thursday" defaultMessage="thursday" />,
  [DayOfWeek.FRIDAY]: <FormattedMessage id="days.friday" defaultMessage="friday" />,
  [DayOfWeek.SATURDAY]: <FormattedMessage id="days.saturday" defaultMessage="saturday" />,
  [DayOfWeek.SUNDAY]: <FormattedMessage id="days.sunday" defaultMessage="sunday" />,
};

export const renderDay = (day: DayOfWeek) => dayOfWeekLabels[day];
