import { Button, InputText, Modal, spaceUsages } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { Business } from 'src/business/domain/Business';
import { useCreateAllergenMutation } from 'src/menu/dynamic-menu/mutations/allergen/useAllergenMutation';

type AllergenCreationModalProps = {
  business: Business;
  isOpened: boolean;
  onClose: () => void;
};

type AllergenCreationModalInputs = {
  name: string;
};

export const AllergenCreationModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const AllergenCreationModal = ({ business, isOpened, onClose }: AllergenCreationModalProps) => {
  const createAllergen = useCreateAllergenMutation(business.id);
  const snackBar = useSnackbar();
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<AllergenCreationModalInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  useEffect(() => {
    reset({
      name: '',
    });
  }, [reset, isSubmitSuccessful]);

  const onClickCreateAllergen = async (name: string) => {
    try {
      const allergenRequest = {
        name,
      };
      const allergenCreatedId = await createAllergen.mutateAsync(allergenRequest);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.allergens.creation.success' }),
      });
      return allergenCreatedId;
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'menus.allergens.creation.error' }),
      });
    }
  };

  const onSubmit: SubmitHandler<AllergenCreationModalInputs> = async ({ name }) => {
    onClickCreateAllergen(name).finally(onClose);
  };

  const onCloseThenResetName = async () => {
    onClose();
    reset({
      name: '',
    });
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'menus.allergens.creation.modal.name' })}
      cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
      isOpened={isOpened}
      onClose={onCloseThenResetName}
    >
      <AllergenCreationModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={intl.formatMessage({ id: 'menus.allergens.creation.form.name' })}
          placeholder={intl.formatMessage({ id: 'menus.allergens.creation.form.placeholder' })}
          id="menuName"
          {...register('name', {
            required: intl.formatMessage({ id: 'menus.allergens.creation.form.required' }),
            pattern: { value: /.*\S.*/, message: intl.formatMessage({ id: 'menus.allergens.creation.form.required' }) },
          })}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          disabled={!isValid || isSubmitting}
          marginTop={spaceUsages.large}
        >
          <FormattedMessage id="menus.allergens.creation.form.submit" />
        </Button>
      </AllergenCreationModalForm>
    </Modal>
  );
};
