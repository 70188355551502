/* eslint-disable no-param-reassign */
import { useCallback, useEffect, useRef, useState } from 'react';
import { CurrencyCode, differenceMoney, Money, money, sumMoneys } from '@sundayapp/web-money';
import { BoxOrderSummary, OrderId } from '../orders/types';
import { Payment } from '../payment/types';
import { usePaymentRepository } from '../payment/hook';
import { ReportingDigestPerBox } from './types';
import { buildReporting } from './reportingUsecase';
import { computeRefunds } from './computeRefunds';
import { GetOrdersQuery } from '../orders/repositories/OrderingOrderRepository';
import { useOrderRepository } from '../orders/hooks';
import { BusinessId } from 'src/business/domain/Business';

interface BoxOrdersByBox {
  boxId: string;
  boxOrder: BoxOrderSummary[];
}

const groupBoxOrdersByOrderId = (acc: Record<OrderId, BoxOrderSummary[]>, boxOrder: BoxOrderSummary) => {
  const accElement = acc[boxOrder.orderId];
  if (accElement) {
    accElement.push(boxOrder);
  } else {
    acc[boxOrder.orderId] = [boxOrder];
  }
  return acc;
};

const groupAllBoxOrdersByOrderId = (
  boxOrderByOrderId: Record<OrderId, BoxOrderSummary[]>,
  borOrderSummary: BoxOrdersByBox,
) => borOrderSummary.boxOrder.reduce(groupBoxOrdersByOrderId, boxOrderByOrderId);

const sortBoxOrderPerIdAlphabetical = (boxOrder1: BoxOrderSummary, boxOrder2: BoxOrderSummary) =>
  boxOrder1.id.localeCompare(boxOrder2.id);

const deduceRefundsFromBoxOrders = (
  allPaymentsSummary: Payment[],
  allBoxOrderSummary: BoxOrdersByBox[],
): Money | null => {
  const boxOrdersByOrderId = allBoxOrderSummary.reduce(groupAllBoxOrdersByOrderId, {});
  let grandTotalRefunded: Money | null = null;
  allPaymentsSummary.forEach((payment) => {
    const orderId = payment.metadata.order_id;
    const boxOrders = boxOrdersByOrderId[orderId];
    if (boxOrders) {
      const totalRefunds = computeRefunds(payment);
      if (totalRefunds.amount > 0) {
        let remainingAmountToRefund = totalRefunds;
        let refundOnOrderAmount = money(0, totalRefunds.currency);
        let refundOnTipsAmount = money(0, totalRefunds.currency);

        const totalOrder = boxOrders
          .map((boxOrder) => boxOrder.totalPrice.amount - (boxOrder.discount?.amount || 0))
          .reduce((acc, curr) => acc + curr, 0);
        const refundedOnOrder = Math.min(totalOrder, totalRefunds.amount); // Exclude digital fees or tips
        const refundedOnOrderMoney = money(refundedOnOrder, totalRefunds.currency);
        grandTotalRefunded = grandTotalRefunded
          ? sumMoneys(grandTotalRefunded, refundedOnOrderMoney)
          : refundedOnOrderMoney;

        const currency =
          boxOrders.length > 0 && boxOrders[0]?.totalPaid ? boxOrders[0].totalPaid.currency : CurrencyCode.EUR;

        const zeroMoney = money(0, currency);

        boxOrders.sort(sortBoxOrderPerIdAlphabetical).forEach((boxOrder: BoxOrderSummary) => {
          const boxOrderAmount = differenceMoney(boxOrder.totalPaid ?? zeroMoney, boxOrder.tipsAmount ?? zeroMoney);
          const boxOrderRefund =
            boxOrderAmount.amount > remainingAmountToRefund.amount ? remainingAmountToRefund : boxOrderAmount;
          refundOnOrderAmount = sumMoneys(refundOnOrderAmount, boxOrderRefund);
          remainingAmountToRefund = differenceMoney(remainingAmountToRefund, boxOrderRefund);
        });

        if (remainingAmountToRefund.amount > 0) {
          boxOrders.sort(sortBoxOrderPerIdAlphabetical).forEach((boxOrder: BoxOrderSummary) => {
            const boxOrderTipsRefund =
              (boxOrder?.tipsAmount?.amount ?? 0) > remainingAmountToRefund.amount
                ? remainingAmountToRefund
                : boxOrder.tipsAmount;
            refundOnTipsAmount = sumMoneys(refundOnTipsAmount, boxOrderTipsRefund ?? zeroMoney);
            remainingAmountToRefund = differenceMoney(remainingAmountToRefund, boxOrderTipsRefund ?? zeroMoney);
          });
        }

        remainingAmountToRefund = totalRefunds;
        boxOrders.sort(sortBoxOrderPerIdAlphabetical).forEach((boxOrder: BoxOrderSummary) => {
          const tipsToRefund =
            refundOnTipsAmount.amount > (boxOrder?.tipsAmount?.amount ?? 0) ? boxOrder.tipsAmount! : refundOnTipsAmount;
          refundOnTipsAmount = differenceMoney(refundOnTipsAmount, tipsToRefund);
          boxOrder.tipsAmount = differenceMoney(boxOrder.tipsAmount ?? zeroMoney, tipsToRefund);
          boxOrder.totalPaid = differenceMoney(boxOrder.totalPaid ?? zeroMoney, tipsToRefund);

          const boxOrderAmount = differenceMoney(boxOrder.totalPaid, boxOrder.tipsAmount);
          const amountToRefundOnOrder =
            refundOnOrderAmount.amount > boxOrderAmount.amount ? boxOrderAmount : refundOnOrderAmount;
          boxOrder.totalPaid = differenceMoney(boxOrder.totalPaid, amountToRefundOnOrder);
          refundOnOrderAmount = differenceMoney(refundOnOrderAmount, amountToRefundOnOrder);
        });
      }
    }
  });
  return grandTotalRefunded;
};

export const useReportingDigest = (countryCode: string, businessId: BusinessId, boxIds: string[], query: GetOrdersQuery) => {
  const orderRepository = useOrderRepository();
  const paymentRepository = usePaymentRepository();

  const [reportingDigest, setReportingDigest] = useState<ReportingDigestPerBox | undefined>();
  const fetchingId = useRef<number>(0);
  const fetchOrders = useCallback(async () => {
    const currentFetchingId = fetchingId.current + 1;
    fetchingId.current = currentFetchingId;

    const allPaymentsQuery = paymentRepository.getPayments(businessId, {
      startDate: query.startDate.getTime(),
      endDate: query.endDate.getTime(),
    });

    const [allPaymentsSummary, ...allBoxOrderSummary] = await Promise.all([
      allPaymentsQuery,
      ...boxIds.map(async (boxId) => {
        const result = await orderRepository.getBoxOrders(boxId, query);
        return { boxId, boxOrder: result };
      }),
    ]);

    const grandTotalRefunded = deduceRefundsFromBoxOrders(allPaymentsSummary, allBoxOrderSummary);

    const reporting = buildReporting(countryCode, allBoxOrderSummary, grandTotalRefunded);
    if (currentFetchingId === fetchingId.current) {
      setReportingDigest(reporting);
    }
  }, [orderRepository, boxIds, query, setReportingDigest, fetchingId, countryCode, paymentRepository, businessId]);
  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);
  return reportingDigest;
};
