import styled from 'styled-components';
import React from 'react';
import { SundayDevice } from '../../../area/domain/SundayDevice';
import { QrCodeInfo } from './QrCodeInfo';
import { colorPalette, getSpacing } from '../../../stylesheet';

type QrCodesProps = {
  devices: SundayDevice[];
  title: string;
};

const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${getSpacing(3)};
  padding-bottom: ${getSpacing(1)};
  border-radius: ${getSpacing(2)};
  border: 1px solid ${colorPalette.grey300};
  background-color: ${colorPalette.defaultBackgroundColor};
  margin-bottom: ${getSpacing(1)};
  width: 100%;
`;

export const QrCodes = ({ devices, title }: QrCodesProps) => {
  if (devices.length === 0) {
    return <></>;
  }

  return (
    <QrCodeContainer>
      {devices.map((device) => (
        <QrCodeInfo key={device.id} url={device.url} title={title} />
      ))}
    </QrCodeContainer>
  );
};
