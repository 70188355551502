import { z } from 'zod';
import { MoneyZ } from './MoneyZ';

export const PosPaymentMethodId = z.object({
  id: z.string(),
});

export const OrphanPosLine = z.object({
  posPaymentMethodId: PosPaymentMethodId,
  amount: MoneyZ.optional(),
});

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type PosPaymentMethodId = z.infer<typeof PosPaymentMethodId>;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type OrphanPosLine = z.infer<typeof OrphanPosLine>;
