import { ServiceType } from 'src/domain/venue/ServiceType';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Controller, useFormContext } from 'react-hook-form';
import { TipsSuggestion } from 'src/pages/Settings/AppSettings/tips/domain/TipsSuggestion';
import { Alert, Button, Chip, Select, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import { SettingLine } from 'src/pages/Settings/AppSettings/components/TipsGeneralConfiguration';

export const BusinessCustomerExperience = ({
  product,
  tipAreTheSameError,
}: { product: ServiceType, tipAreTheSameError: boolean }) => {
  const intl = useIntl();
  const {
    watch,
    setValue,
    formState: { errors },
    control,
  } = useFormContext<TipsSuggestion>();
  const noZeroErrorMessage = intl.formatMessage({ id: 'settings.app_settings.tips.configure_percentages.error.no_zero' });

  return <Stack spacing={3}>
    <Typography variant={'h6'}>
      <FormattedMessage id={'settings.app_settings.tips.customer_experience.title'} />
    </Typography>
    <Divider />
    <SettingLine labelsKeys={{
      mainLabel: (
        <Stack direction={'row'} spacing={2} alignItems={'center'}>
          <Typography>
            <FormattedMessage id={'settings.app_settings.tips.custom_configuration.main_label'} />
          </Typography>
          <Chip color={'warning'} label={intl.formatMessage({ id: 'settings.app_settings.tips.custom_configuration.chip' })} />
        </Stack>
      )
      ,
      subLabel: 'settings.app_settings.tips.custom_configuration.sub_label',
    }}>
      <Tooltip title={intl.formatMessage({ id: 'settings.app_settings.tips.tooltip.switch_to_smart' })} arrow placement={'top'}>
        <Button
          size={'small'}
          startIcon={<AutoAwesomeIcon />}
          sx={{
            minWidth: 'auto',
            whiteSpace: 'nowrap',
          }}
          color={'secondary'} onClick={() => setValue('tipsConfigurationMode', 'SUNDAY', {
            shouldDirty:
            watch('tipsConfigurationMode') !== 'SUNDAY',
          })}>
          <FormattedMessage id={'settings.app_settings.tips.button.switch_to_smart'} />
        </Button>
      </Tooltip>
    </SettingLine>

    <Divider />

    <SettingLine labelsKeys={{
      mainLabel: 'settings.app_settings.tips.allow_no_tips.main_label',
      subLabel: 'settings.app_settings.tips.allow_no_tips.sub_label',
    }}>
      <Controller
        render={({ field }) =>
          <Switch checked={field.value} {...field} />}
        name={'allowNoTips'}
        control={control}
      />
    </SettingLine>

    <SettingLine labelsKeys={{
      mainLabel: 'settings.app_settings.tips.show_emojis.main_label',
      subLabel: 'settings.app_settings.tips.show_emojis.sub_label',
    }}>
      <Controller
        render={({ field }) =>
          <Switch checked={field.value} {...field} />}
        name={'emojisEnabled'}
        control={control}
      />
    </SettingLine>

    <SettingLine labelsKeys={{
      mainLabel: 'settings.app_settings.tips.tips_display.main_label',
      subLabel: 'settings.app_settings.tips.tips_display.sub_label',
    }}>
      <Controller
        name="tippingDisplayStrategy"
        control={control}
        render={({ field }) => (
          <Select
            sx={{ width: 200 }}
            id="tippingDisplayStrategy"
            disabled={product === ServiceType.ORDER_AND_PAY}
            {...field}
          >
            <MenuItem value={'TIP_LABEL_TIP_AMOUNT'}><FormattedMessage id={'settings.app_settings.tips.tips_display.amount_example'} /></MenuItem>
            <MenuItem value={'TIP_PERCENTAGE_TIP_AMOUNT'}><FormattedMessage id={'settings.app_settings.tips.tips_display.percentage_example'} /></MenuItem>
          </Select>
        )}
      />
    </SettingLine>


    <SettingLine labelsKeys={{
      mainLabel: 'settings.app_settings.tips.configure_percentages.main_label',
      subLabel: 'settings.app_settings.tips.configure_percentages.sub_label',
    }}>
      <Stack direction={'row'} spacing={2} justifyContent="flex-end">
          <Controller
            name={'tippingPercentageFirst'}
            control={control}
            rules={{
              validate(value) {
                if (value == 0) return 'ZERO_TIP';
              },
            }}
            render={({ field }) => <TextField
              {...field}
              sx={{ width: 80 }}
              disabled={product === ServiceType.ORDER_AND_PAY}
              type={'number'}
              label={<Typography><FormattedMessage id={'settings.app_settings.tips.preselect_tip.left'} /> (%)</Typography>}
              error={errors.tippingPercentageFirst?.message == 'ZERO_TIP'}
              helperText={errors.tippingPercentageFirst !== undefined ? noZeroErrorMessage : ''}
            />} />
          <Controller
            name={'tippingPercentageSecond'}
            control={control}
            rules={{
              validate(value) {
                if (value == 0) return 'ZERO_TIP';
              },
            }}
            render={({ field }) => <TextField
              {...field}
              sx={{ width: 80 }}
              disabled={product === ServiceType.ORDER_AND_PAY}
              type={'number'}
              label={<Typography><FormattedMessage id={'settings.app_settings.tips.preselect_tip.center'} /> (%)</Typography>}
              error={errors.tippingPercentageSecond?.message == 'ZERO_TIP'}
              helperText={errors.tippingPercentageSecond !== undefined ? noZeroErrorMessage : ''}
            />} />
          <Controller
            name={'tippingPercentageThird'}
            control={control}
            rules={{
              validate(value) {
                if (value == 0) return 'ZERO_TIP';
              },
            }}
            render={({ field }) => <TextField
              {...field}
              sx={{ width: 80 }}
              disabled={product === ServiceType.ORDER_AND_PAY}
              type={'number'}
              label={<Typography><FormattedMessage id={'settings.app_settings.tips.preselect_tip.right'} /> (%)</Typography>}
              error={errors.tippingPercentageThird?.message == 'ZERO_TIP'}
              helperText={errors.tippingPercentageThird !== undefined ? noZeroErrorMessage : ''}
            />} />
      </Stack>
    </SettingLine>

    {tipAreTheSameError && <Alert severity="error"><FormattedMessage id={'settings.app_settings.tips.configure_percentages.error.tips_cant_be_equals'} /></Alert>}

    <SettingLine labelsKeys={{
      mainLabel: 'settings.app_settings.tips.preselect_tip.main_label',
      subLabel: 'settings.app_settings.tips.preselect_tip.sub_label',
    }}>
      <Controller
        name="preSelectedTips"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            sx={{ width: 200 }}
            disabled={product === ServiceType.ORDER_AND_PAY}
          >
            <MenuItem value={'NONE'}><FormattedMessage id={'settings.app_settings.tips.preselect_tip.no_preselection'} /></MenuItem>
            <MenuItem value={'FIRST'}><FormattedMessage id={'settings.app_settings.tips.preselect_tip.left'} /></MenuItem>
            <MenuItem value={'SECOND'}><FormattedMessage id={'settings.app_settings.tips.preselect_tip.center'} /></MenuItem>
            <MenuItem value={'THIRD'}><FormattedMessage id={'settings.app_settings.tips.preselect_tip.right'} /></MenuItem>
          </Select>
        )}
      />
    </SettingLine>
  </Stack>;
};
