export class Comparator<T> {
  private constructor(private comparisonFunction: (t1: T, t2: T) => number) {
  }

  static from<T>(comparisonFunction: (t1: T, t2: T) => number): Comparator<T> {
    return new Comparator<T>(comparisonFunction);
  }

  static usingKey<T, K>(keyMapper: (t: T) => K, keyComparator: Comparator<K>): Comparator<T> {
    return new Comparator<T>((t1, t2) => keyComparator.compare(keyMapper(t1), keyMapper(t2)));
  }

  static sortingBy<T>(f: (t: T) => number): Comparator<T> {
    return Comparator.from<T>((t1: T, t2: T) => f(t1) - f(t2));
  }

  static sortByFirst<T>(f: (t: T) => boolean): Comparator<T> {
    return Comparator.sortingBy((t) => (f(t) ? 0 : 1));
  }

  static fromArray<T>(ts: T[]): Comparator<T> {
    return Comparator.from((t1: T, t2: T) => ts.indexOf(t1) - ts.indexOf(t2));
  }

  compare(t1: T, t2: T): number {
    return this.comparisonFunction(t1, t2);
  }

  then(other: Comparator<T>): Comparator<T> {
    return new Comparator<T>((t1: T, t2: T) => {
      const comparisonResult = this.compare(t1, t2);
      return (comparisonResult !== 0 ? comparisonResult : other.compare(t1, t2));
    });
  }

  reverse(): Comparator<T> {
    return new Comparator<T>((t1, t2) => this.comparisonFunction(t1, t2) * -1);
  }

  sort(ts: T[]): T[] {
    return ts.sort((t1, t2) => this.compare(t1, t2));
  }
}
