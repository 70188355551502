import React, { useState } from 'react';
import { Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ElementSortingModal } from '../../ElementSortingModal/ElementSortingModal';
import { Menu } from '../../../domain/Menu';
import { SortElement } from '../../ElementSortingModal/SortElement';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useUpdateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { BusinessId } from 'src/business/domain/Business';

type SortCategoryButtonProps = {
  businessId: BusinessId;
  menu: Menu;
};

export const SortCategoryButton = ({ menu, businessId }: SortCategoryButtonProps) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const updateMenu = useUpdateMenuMutation(businessId, menu.id);

  const [isCategorySortingModalOpen, setIsCategorySortingModalOpen] = useState(false);

  const onCategoryListUpdated = async (categories: SortElement[]) => {
    try {
      await updateMenu.mutateAsync({ categoryIds: categories.map((c) => c.id) });
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    } finally {
      setIsCategorySortingModalOpen(false);
    }
  };

  const noCategories = () => menu.categories.length === 0;

  return (
    <>
      <Button
        disabled={noCategories()}
        startIcon={<SortIcon />}
        onClick={() => {
          setIsCategorySortingModalOpen(true);
        }}
      >
        <FormattedMessage id="menus.edition.sort_category" />
      </Button>

      <ElementSortingModal
        title={intl.formatMessage({ id: 'menus.category.sort' })}
        elements={menu.categories.map((c) => ({ id: c.id, label: c.name, type: 'CATEGORY' }))}
        onElementReorderFinished={onCategoryListUpdated}
        isOpen={isCategorySortingModalOpen}
        setIsOpen={setIsCategorySortingModalOpen}
      />
    </>
  );
};
