import { Card, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';

export const AllReviewsWereReplied = () => (
  <Grid container justifyContent="center">
    <Grid item md={6} xs={8}>
      <Card sx={{ padding: '2rem', textAlign: 'center' }}>
        <Typography><FormattedMessage id="venue.reviews.reply.all_replied" /></Typography>
      </Card>
    </Grid>
  </Grid>
);
