import { PageSection } from '@organisms/Body/Section/PageSection';
import { GridConfigurable } from '@components/molecules/Grid/GridConfigurable';
import { ColumnWidth } from 'src/home2/types/grid_layout_types';
import { HomeCardProps } from '@constants/HomeConstants';
import { Tips } from '../../../components/Sections/Payment/Tips/Tips';
import { CustomerData } from '../../../components/Sections/Payment/CustomerData/CustomerData';
import { PaymentStats } from '../../../components/Sections/Payment/PaymentStats/PaymentStats';

export const PaymentSection = ({
  venueKpi,
  selectedTimeFrame,
}: HomeCardProps) => {
  return (
    <PageSection title="orders.table.payments">
      <GridConfigurable grid={{
        columnDefs: [
          {
            key: 1,
            components: [
              <PaymentStats 
                venueKpi={venueKpi} 
                key="col-revenue" 
                selectedTimeFrame={selectedTimeFrame} 
              />,
            ],
            order: 1,
            layout: {
              direction: 'row',
              size: ColumnWidth.LARGE,
            },
          },
          {
            key: 2,
            components: [
              <Tips 
                key="col-tips" 
                venueKpi={venueKpi} 
                selectedTimeFrame={selectedTimeFrame} 
              />,
              <CustomerData key="col-customer" />,
            ],
            order: 2,
            layout: {
              direction: 'column',
              size: ColumnWidth.SMALL,
            },
          },
        ],
      }} />
    </PageSection>
  );
};
