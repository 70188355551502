import { OPProductAvailability } from './AvailabilityList';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const OPProductAvailabilityPage = () => {
  const business = useCurrentBusinessOrThrow();

  if (!business.oapEnrollment) {
    return <>Order and pay not enabled</>;
  }

  return <OPProductAvailability enrollmentId={business.oapEnrollment.id} />;
};
