import { Money } from '@sundayapp/web-money';
import { Business } from 'src/business/domain/Business';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { TipsSummaryByWaiter } from './TipsSummaryByWaiter';
import { LocalisationKey } from 'src/lang/en';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export type Tips = {
  tipsId: string;
  waiterName: string;
  pooledTip: Money;
  directTip: Money;
};

export type TipsView = {
  totalTips: Money;
  totalDirectTips: Money;
  totalPooledTips: Money;
  tips: TipsSummaryByWaiter[];
};

type SourceFilter = {
  value: string;
  translations: LocalisationKey;
  displayFor: ServiceType;
};

type SourceFilters = {
  QR_CODE: SourceFilter;
  PDQ: SourceFilter;
};

export const sourceFilters: SourceFilters = {
  QR_CODE: {
    value: 'QR',
    translations: 'tips.summary.filters.payment_method.qr_code',
    displayFor: ServiceType.PAY_AT_TABLE,
  },
  PDQ: {
    value: 'PDQ',
    translations: 'tips.summary.filters.payment_method.pdq',
    displayFor: ServiceType.PAYMENT_TERMINAL,
  },
} as const;

type SourceFilterKey = keyof typeof sourceFilters;
export type SourceFilterValue = {
  [K in SourceFilterKey]: (typeof sourceFilters)[K]['value'];
}[SourceFilterKey];

export const isSourceFilter = (some?: string): some is SourceFilterValue =>
  !!some &&
  Object.values(sourceFilters)
    .map((v) => v.value)
    .includes(some as SourceFilterValue);

export const isAllowed = (business: Business) => (source: SourceFilter) =>
  business.enrollments.some((e) => source.displayFor === e.product);

export const isAllowedForDirectTippingConfiguration =
  (business: Business, directTippingConfiguration: Record<EnrollmentId, boolean>) => (source: SourceFilter) =>
    business.enrollments.some((e) => source.displayFor === e.product && directTippingConfiguration[e.id]);
