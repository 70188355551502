import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { useDeletePromoCode } from '../hooks';
import { getSpacing } from '../../stylesheet';
import { usePushNotification } from '../../common/components/Notifications.hook';

type PromoCodeDeletionModalProps = {
  isOpen: boolean;
  voucherId: string;
  voucherCode: string;
  onClose: () => void;
  onPromoCodeDeleted: (voucherId: string) => void;
};

const ModalContent = styled.div`
  max-width: 400px;
  margin-bottom: ${getSpacing(4)};
`;

export const PromoCodeDeletionModal = ({
  isOpen,
  voucherId,
  voucherCode,
  onClose,
  onPromoCodeDeleted,
}: PromoCodeDeletionModalProps) => {
  const { formatMessage } = useIntl();
  const [, doDeletePromoCode] = useDeletePromoCode();
  const [, pushNotification] = usePushNotification();

  const deletePromoCode = async () => {
    if (voucherId) {
      const res = await doDeletePromoCode(voucherId);
      if (res) {
        onPromoCodeDeleted(voucherId);
        await pushNotification(
          formatMessage({ id: 'venue.promoCode.deleted', defaultMessage: 'voucher code successfully deleted' }),
        );
      }
    }
    onClose();
  };

  return (
    <Modal
      title={formatMessage(
        { id: 'promocode.edition.delete_title', defaultMessage: 'delete voucher code {code} ?' },
        { code: voucherCode },
      )}
      cancelButtonMessage={formatMessage({ id: 'promocode.modal.edition.cancel', defaultMessage: 'cancel' })}
      isOpened={isOpen}
      onClose={onClose}
    >
      <ModalContent>
        <FormattedMessage
          id="promocode.edition.delete_info"
          defaultMessage="deleting a voucher code cannot be undone, although you can always re-create a new voucher code from ‘voucher codes’ page."
        />
      </ModalContent>
      <Button
        variant="warning"
        size="medium"
        fullWidth
        onClick={deletePromoCode}
        data-testid="confirm-delete-promocode"
      >
        <FormattedMessage
          id="promocode.edition.delete_button"
          defaultMessage="yes, I want to delete this voucher code"
        />
      </Button>
    </Modal>
  );
};
