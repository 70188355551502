import axios from 'axios';
import { ReviewRepository } from '../domain/ReviewRepository';
import { RatingStatistics } from '../domain/RatingStatistics';
import { createReviews, Reviews } from '../domain/Reviews';
import { convertReviewResponseToReview, ReviewResponse } from './ReviewResponse';
import { Platform } from '../domain/Review';
import { DataAnalyticsUrlResponse } from './DataAnalyticsUrlResponse';

export class HttpReviewRepository implements ReviewRepository {
  constructor(private readonly baseUrl: string) {
  }

  replyToReview(businessId: string, reviewId: string, reply: string, platform: Platform, replyTo: string): Promise<void> {
    return axios.put(`${this.baseUrl}/businesses/${businessId}/review-reply`,
      {
        reviewId,
        reply,
        platform: platform.toUpperCase(),
        replyTo,
      });
  }

  async suggestReply(venueName: string, replyLanguage: string, guessReplyLanguage: boolean, comment: string, rating: number): Promise<string | undefined> {
    try {
      const response = await axios.post(`${this.baseUrl}/gpt/suggest-reply`, {
        comment,
        rating,
      }, { params: { venueName, replyLanguage, guessReplyLanguage } });
      return response.data.replies[0];
    } catch (e) {
      return undefined;
    }
  }

  async listReviews(businessId: string, timeRange: { endDate: Date; startDate: Date }): Promise<Reviews> {
    try {
      const params = timeRange ? {
        startDate: timeRange?.startDate,
        endDate: timeRange?.endDate,
      } : {};
      const axiosResponse = await axios.get<{ reviews: ReviewResponse[] }>(`${this.baseUrl}/businesses/${businessId}/reviews`, { params });
      const reviews = axiosResponse.data.reviews.map((reviewResponse: ReviewResponse) => convertReviewResponseToReview(reviewResponse));
      return createReviews(reviews.flatMap((r) => r)
        .sort(Reviews.sortByMostRecent));
    } catch (e) {
      return new Reviews([]);
    }
  }

  async getRatingStatistics(
    businessId: string,
    filters: {
      tableNumber?: string
      waiter?: string,
      startDate: Date,
      endDate?: Date,
    },
  ): Promise<RatingStatistics> {
    try {
      const { data } = await axios.get(`${this.baseUrl}/v1/businesses/${businessId}/venue-feedback-statistics`, {
        headers: {
          'x-timezone': Intl.DateTimeFormat()
            .resolvedOptions().timeZone,
        },
        params: {
          tableName: filters.tableNumber,
          waiterName: filters.waiter,
          startDate: filters.startDate?.getTime(),
          endDate: filters.endDate?.getTime(),
        },
      });
      return new RatingStatistics(
        data.nbRatings,
        HttpReviewRepository.truncateToOneDecimal(data.averageRating),
        data.ratingsByStars,
        data.ratingsByDays,
      );
    } catch (e) {
      throw new Error('error getting rating statistics');
    }
  }

  async getDataAnalyticsUrl(
    enrollmentId: string,
  ): Promise<string> {
    try {
      const response = await axios.get<DataAnalyticsUrlResponse>(`${this.baseUrl}/data-analytics/${enrollmentId}/`);
      return response.data.url;
    } catch (e) {
      throw new Error('error getting data analytics url');
    }
  }

  async getDataAnalyticsUrlForBusinesses(
    businessId: string,
    businessIds: string[],
  ): Promise<string> {
    try {
      const response = await axios.post<DataAnalyticsUrlResponse>(`${this.baseUrl}/data-analytics/${businessId}/dashboard-url`, businessIds.map(b => { return { id: b }; }));
      return response.data.url;
    } catch (e) {
      throw new Error('error getting data analytics url');
    }
  }

  static truncateToOneDecimal(n: number) {
    return parseFloat(n.toFixed(2)
      .slice(0, -1));
  }
}
