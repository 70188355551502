import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useQuery } from '@tanstack/react-query';
import { GlobalConfigurationRepositoryHttp } from './GlobalConfigurationRepositoryHttp';
import { GlobalConfiguration } from '../domain/GlobalConfiguration';
import { queryKeys } from 'src/app/queries/utils';
import { BusinessId } from 'src/business/domain/Business';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const getGlobalConfigurationQuery = (
  globalConfigurationRepository: GlobalConfigurationRepositoryHttp,
  businessId: BusinessId,
  countryCode: string,
) => ({
  queryKey: [queryKeys.GET_GLOBAL_CONFIGURATION, { businessId, countryCode }],
  queryFn: (): Promise<GlobalConfiguration> => {
    return globalConfigurationRepository.getGlobalConfiguration(businessId, countryCode);
  },
});

export const useGlobalConfiguration = () => {
  const business = useCurrentBusinessOrThrow();
  const globalConfigurationRepository = new GlobalConfigurationRepositoryHttp(
    ConfigurationLoader.load().globalConfigurationUrl,
  );
  return useQuery(
    getGlobalConfigurationQuery(globalConfigurationRepository, business.id, business.address.countryCode),
  );
};
