import { IntercomProvider } from 'react-use-intercom';
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { Suspense } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { FirebaseAppProvider } from 'reactfire';
import { useAuth0 } from '@auth0/auth0-react';
import { ErrorBoundary } from 'react-error-boundary';
import { datadogRum } from '@datadog/browser-rum';
import { SnackBarProvider } from './contexts/snackbars/SnackBarProvider';
import { ConfigurationLoader } from '../configuration/ConfigurationLoader';
import withStore from '../store';
import { I18nProvider } from './i18n/I18nProvider';
import FirebasePreloader from './firebase/FirebasePreloader';
import { OrderAndPayContextProvider } from '../order-and-pay/context/OrderAndPayContextProvider';
import { IntercomConfiguration } from '../configuration/IntercomConfiguration';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import { ConfigurationContext } from './contexts/configuration/ConfigurationProvider';
import { AppLogProvider } from '../utils/logs/AppLogProvider';
import { themeV5 } from './theme/ThemeV5';
import { ReviewProvider } from '../sundayplus/reviews/configuration/ReviewProvider';
import {
  ReviewRepositoryProvider,
} from '../sundayplus/reviews/browse/infrastructure/configuration/ReviewRepositoryProvider';
import { dataDogErrorHandler } from '../monitoring/DataDogErrorHandler';
import ErrorPage from '../auth/pages/ErrorPage/ErrorPage';
import { UserProvider } from '../auth/component/UserProvider';
import { initChartJs } from './initChartJs';
import { ampli } from '../ampli';
import { VposVenueRepositoryProvider } from './contexts/vposvenue/VposVenueRepositoryContextProvider';
import { initializeAmplitude } from './tracking/tracking';
import { initFavicon } from 'src/app/initFavicon';

const configuration = ConfigurationLoader.load();
const firebaseApp = initializeApp(configuration.venueFirebaseConfig);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.log(error);
      datadogRum.addError(error);
    },
  }),
});

initChartJs();
initializeAmplitude();
initFavicon(configuration);

export const App = () => {
  const { isLoading } = useAuth0();
  if (isLoading) {
    return <CircularProgress />;
  }
  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorPage} onError={dataDogErrorHandler}>
        <UserProvider>
          <FirebaseAppProvider firebaseApp={firebaseApp} suspense>
            <Suspense fallback={<CircularProgress />}>
              <AppLogProvider>
                <QueryClientProvider client={queryClient}>
                  <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-right" />
                  <Provider store={withStore()}>
                    <I18nProvider>
                      <ThemeProvider theme={themeV5}>
                        <SnackBarProvider>
                          <ConfigurationContext.Provider value={configuration}>
                            <FirebasePreloader>
                              <ReviewProvider>
                                <OrderAndPayContextProvider>
                                  <ReviewRepositoryProvider>
                                    <VposVenueRepositoryProvider>
                                      <IntercomProvider
                                        appId={IntercomConfiguration.appId}
                                        autoBootProps={{ alignment: 'left' }}
                                        shouldInitialize
                                      >
                                        <CssBaseline />
                                        <AuthenticatedRoutes />
                                      </IntercomProvider>
                                    </VposVenueRepositoryProvider>
                                  </ReviewRepositoryProvider>
                                </OrderAndPayContextProvider>
                              </ReviewProvider>
                            </FirebasePreloader>
                          </ConfigurationContext.Provider>
                        </SnackBarProvider>
                      </ThemeProvider>
                    </I18nProvider>
                  </Provider>
                </QueryClientProvider>
              </AppLogProvider>
            </Suspense>
          </FirebaseAppProvider>
        </UserProvider>
      </ErrorBoundary>
    </React.StrictMode>
  );
};

window.addEventListener('appinstalled', () => {
  ampli.pwaAppInstalled();
});
