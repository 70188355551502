import { TableCell, TableRow } from '@sundayapp/b2b-react-component-library';
import { OrphanPosLine } from '../../domain/OrphanPosLine';
import { OrphanPosAmountCell } from '../amountcell/OrphanPosAmountCell';
import { Button, Stack } from '@mui/material';
import { venueReconciliationTabPath } from 'src/app/navigation/pathHelpers';
import { useNavigate } from 'react-router';
import { BusinessId } from 'src/business/domain/Business';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';

type Props = {
  line: OrphanPosLine,
  businessId: BusinessId
};

export const OrphanPosLineRow = ({ line, businessId }: Props) => {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell />
      <>
        <TableCell>{line.posPaymentMethodId.id}</TableCell>
        <TableCell>
          <OrphanPosAmountCell amount={line.amount!!} />
        </TableCell>
        <TableCell>
          <Button
            size="small"
            variant="contained"
            onClick={() => navigate(venueReconciliationTabPath(businessId, 'settings'))}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <FormattedMessage id="reconciliation.report_table.orphan_pos_line_configure" defaultMessage="Configure" />
              <HomeRepairServiceIcon />
            </Stack>

          </Button>
        </TableCell>
        <TableCell>
        </TableCell>
      </>
    </TableRow>
  );
};
