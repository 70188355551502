import { datadogLogs } from '@datadog/browser-logs';

export interface LogPlugin {
  log(level: Level, message: string, error?: Error): void;
}

export enum Level {
  INFO,
  WARNING,
  ERROR,
}

export const consolePlugin: LogPlugin = {
  log: (level: Level, message: string, error?: Error): void => {
    switch (level) {
      case Level.INFO:
        console.log(message, error);
        break;
      case Level.WARNING:
        console.warn(message, error);
        break;
      case Level.ERROR:
        console.error(message, error);
        break;
    }
  },
};

export const datadogPlugin: LogPlugin = {
  log(level: Level, message: string, error?: Error): void {
    switch (level) {
      case Level.INFO:
        datadogLogs.logger.info(message, { error }, error);
        break;
      case Level.WARNING:
        datadogLogs.logger.warn(message, { error }, error);
        break;
      case Level.ERROR:
        datadogLogs.logger.error(message, { error }, error);
        break;
    }
  },
};

export const noLogPlugin: LogPlugin = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  log(level: Level, message: string, error?: Error): void {},
};
