import { Business } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { EndOfServiceClient } from 'src/operations/end-of-service/infrastructure/EndOfServiceClient';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { ReconciliationReport } from 'src/operations/end-of-service/model/NonReconciledPayment';


export const reconciliationReport = (business: Business, startDate: Date, endDate: Date) => ({
  queryKey: [
    queryKeys.endOfService.reconciliationReport,
    business.id,
    business.currency,
    {
      startDate,
      endDate,
    },
  ],
  queryFn: (): Promise<ReconciliationReport> => {
    const reconciliationClient = new EndOfServiceClient(axios);
    return reconciliationClient.getReconciliationReport(business.id, startDate, endDate);
  },
});

export const useReconciliationReport = ({
  business,
  startDate,
  endDate,
}: {
  business: Business;
  startDate: Date;
  endDate: Date;
}) => {
  const query = reconciliationReport(business, startDate, endDate);
  return useQuery<ReconciliationReport>({ queryKey: query.queryKey, queryFn: query.queryFn });
};
