import { Payment } from './Payments';

export const NO_REVENUE_CENTER = 'NO REVENUE CENTER';


export const listRevenueCenters = (payments: Payment[]) => {
  const set = new Set(payments.map(payment => payment.revenueCenter || NO_REVENUE_CENTER));

  let hasNoRevenueCenter = set.has(NO_REVENUE_CENTER);
  if (hasNoRevenueCenter) {
    set.delete(NO_REVENUE_CENTER);
  }

  const result = Array.from(set).sort();

  if (hasNoRevenueCenter) {
    result.push(NO_REVENUE_CENTER);
  }

  return result;
};

