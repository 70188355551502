export const CardTheme = {
  styleOverrides: {
    root: {
      borderRadius: '16px',
      display:'flex',
      flexDirection:'column' as 'column',
      gap:'16px',
      width:'100%',
    },
  },
};
