import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Money } from '@sundayapp/web-money';
import { ReconciliationReport } from '../domain/ReconciliationReport';
import { invalidateReport } from './updateReconciliationLine';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const useUpdateTotalOnPosMutation = (report: ReconciliationReport) => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();

  return useMutation({
    mutationFn(amount: Money) {
      const repository = new ReconciliationRepositoryHttp();
      return repository.updateTotalOnPos(business.id, report, amount);
    },

    onSuccess: () => invalidateReport(queryClient, business.id, report),
  });
};
