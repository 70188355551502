import { ShiftHour } from './ShiftHour';

export class Shift {
  constructor(
    public businessId: string,
    public startShiftHour: ShiftHour,
    public endShiftHour: ShiftHour,
    public shiftType: 'LUNCH' | 'DINNER',
    public id?: string,
  ) {
  }

  newShiftWithNewStartShiftHour(newStartShiftHour: ShiftHour) {
    return new Shift(
      this.businessId,
      newStartShiftHour,
      this.endShiftHour,
      this.shiftType,
      this.id,
    );
  }

  newShiftWithNewEndShiftHour(newEndShiftHour: ShiftHour) {
    return new Shift(
      this.businessId,
      this.startShiftHour,
      newEndShiftHour,
      this.shiftType,
      this.id,
    );
  }
}
