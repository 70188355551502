import { CircularProgress } from '@mui/material';
import React from 'react';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Box } from '../../domain/Box';
import { useGetVenueBoxes } from '../../hooks';
import { BoxStatsContainer } from './BoxStatsContainer';

export const BoxStatsPage = () => {
  const business = useCurrentBusinessOrThrow();

  const { isLoading, isError, data: loadedBoxes } = useGetVenueBoxes(business.oapEnrollment?.id);

  if (!business.oapEnrollment) {
    return (<>Order and pay not enabled</>);
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError) {
    return (<>Failed to load the boxes</>);
  }

  const boxes = (loadedBoxes ?? [])
    .map((box) => ({ id: box.id, name: box.name, displayName: box.displayName } as Box));

  if (boxes.length === 0) {
    return (<>No box found</>);
  }

  return <BoxStatsContainer boxes={boxes} />;
};
