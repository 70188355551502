import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { HttpPaymentTerminalRepository } from '../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const QUERY_KEY_PAYMENT_TERMINAL_ONBOARDING_CODE = 'payment_terminal_onboarding_code';
const buildQueryKey = (enrollmentId: EnrollmentId, paymentTerminalId: string | undefined) => [QUERY_KEY_PAYMENT_TERMINAL_ONBOARDING_CODE, enrollmentId, paymentTerminalId] as const;
const getPaymentTerminalOnboardingCodeQuery = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<string> => {
  const [, enrollmentId, paymentTerminalId] = queryKey;
  if (!paymentTerminalId) {
    return Promise.resolve('');
  }
  const configuration = ConfigurationLoader.load();
  const repository = new HttpPaymentTerminalRepository(configuration.paymentTerminalBaseUrl);
  return repository.onboardingCode(enrollmentId, paymentTerminalId!!);
};

export const useGetPaymentTerminalOnboardingCode = (enrollmentId: EnrollmentId, paymentTerminalId: string | undefined, enabled: boolean) =>
  useQuery({
    queryKey: buildQueryKey(enrollmentId, paymentTerminalId),
    queryFn: getPaymentTerminalOnboardingCodeQuery,
    enabled: enabled,
  });
