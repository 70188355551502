import React, { FC, FormEvent, useState } from 'react';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Check } from '@mui/icons-material';
import { Business } from 'src/business/domain/Business';
import { useNavigate } from 'react-router';
import { ProductsSelectionModal } from 'src/menu/dynamic-menu/pages/ProductSelectionModal/ProductSelectionModal';
import { productTypes } from 'src/menu/dynamic-menu/domain/Product';
import { ProductSummary } from 'src/menu/dynamic-menu/domain/ProductSummary';
import { useCreateVariantGroupMutation } from 'src/menu/dynamic-menu/mutations/variantGroup/useVariantGroupMutation';
import { ProductSimpleList } from 'src/menu/dynamic-menu/components/products/ProductSimpleList';
import { EditProductsSelectionButton } from 'src/menu/dynamic-menu/components/products/EditProductsSelectionButton';
import { AddProductsButton } from 'src/menu/dynamic-menu/components/products/AddProductsButton';

type Form = {
  name: string;
  variants: ProductSummary[];
};
type Nullable<T> = { [K in keyof T]: T[K] | null };

export const VariantGroupForm: FC<{
  business: Business;
}> = ({ business }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const createVariantGroup = useCreateVariantGroupMutation(business.id);

  const [form, setForm] = useState<Nullable<Form>>({ name: null, variants: [] });
  const [showProductSelectionModal, setShowProductSelectionModal] = useState<boolean>(false);

  const isFormComplete = (f: Nullable<Form>): f is Form => {
    return !!f.name && !!f.variants && f.variants.length > 1;
  };

  const openProductSelection = () => setShowProductSelectionModal(true);

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!isFormComplete(form)) {
      return;
    }

    createVariantGroup
      .mutateAsync({
        name: form.name,
        variants: form.variants.map((v) => v.id),
        businessCurrency: business.currency,
      })
      .then((variantGroupId) => navigate(`../${variantGroupId}`, { replace: true }))
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const onVariantsSelected = (variants: ProductSummary[]) => {
    setForm((f) => ({
      ...f,
      variants,
    }));

    setShowProductSelectionModal(false);
  };

  const filterVariantsEligible = (product: ProductSummary) => {
    return product.type === productTypes.PRODUCT;
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Box display="flex" flexDirection="column" gap="24px" margin="auto">
        <Box display="flex" flexDirection="column" gap="24px">
          <Typography variant="h5">
            <FormattedMessage id={'menus.catalog.products.form.variant_group.label'} />
          </Typography>
          <Typography variant="subtitle2" color={themeV5.palette.text.secondary}>
            <FormattedMessage id={'menus.catalog.products.form.variant_group.label.subtitle'} />
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <TextField
            variant="outlined"
            size="medium"
            required
            label={<FormattedMessage id="menus.product.details.name" />}
            sx={{ width: '100%' }}
            value={form.name}
            onChange={(e) => setForm((f) => ({ ...f, name: e.target.value }))}
          />

          <Typography variant="subtitle2" color={themeV5.palette.text.secondary}>
            <FormattedMessage id={'menus.catalog.products.form.variant_group.add_variants.label'} />
          </Typography>

          {form.variants && form.variants.length < 2 && (
            <Alert severity="warning">
              <FormattedMessage id="menus.catalog.products.form.variant_group.variants.warning" />
            </Alert>
          )}

          {!!form.variants?.length
            ? <EditProductsSelectionButton onClick={openProductSelection} />
            : <AddProductsButton onClick={openProductSelection} />}

          <ProductSimpleList products={form.variants || []} />

          <ProductsSelectionModal
            title={intl.formatMessage({ id: 'menus.product.related_products.manage_button' })}
            productIds={form.variants?.map((p) => p.id) || []}
            onChange={() => undefined}
            onProductsSelected={onVariantsSelected}
            visible={showProductSelectionModal}
            customFilter={filterVariantsEligible}
            onClose={() => setShowProductSelectionModal(false)}
          />
        </Box>

        <Box>
          <Button type="submit" size="large" variant="contained" disabled={!isFormComplete(form)}>
            <Box display="flex" justifyContent="center" alignItems="center" gap="8px" padding="8px 22px">
              <Check sx={{ width: '24px', height: '24px' }} />
              <Typography variant="subtitle1">
                <FormattedMessage id="menus.catalog.products.create.variant_group" />
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
    </form>
  );
};

