/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function ChequeVacancesIcon(props: PaymentMethodIconProps) {
  return (
  <svg viewBox="0 0 110 79" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16.6407 62.3387L15.1959 62.3787C15.1959 62.2987 15.2159 62.1786 15.2159 62.0986C15.2159 61.6583 15.0559 61.2981 14.5316 61.2981C13.3749 61.2981 12.6225 63.2032 12.6225 64.4399C12.6225 65.2843 13.0467 65.7446 13.8912 65.7446C14.6011 65.7073 15.2899 65.4897 15.8923 65.1122L15.6602 66.2088C15.0186 66.5884 14.2884 66.7926 13.543 66.8012C12.9293 66.8275 12.3267 66.6314 11.8461 66.2489C11.6235 66.0221 11.4513 65.7509 11.341 65.4529C11.2306 65.1549 11.1845 64.837 11.2057 64.5199C11.2057 62.4908 12.5425 60.2695 14.6836 60.2695C15.7883 60.2695 16.6848 60.8218 16.6848 62.0185C16.6848 62.1106 16.6848 62.2706 16.6648 62.3387" fill="#003F7D"/>
    <path d="M23.1329 60.3616L21.7881 66.7171H20.3953L20.9437 64.0957H18.6864L18.1341 66.7171H16.7373L18.0821 60.3616H19.4788L18.9185 62.991H21.1758L21.7401 60.3616H23.1329Z" fill="#003F7D"/>
    <path d="M27.3753 59.8575C27.2313 59.6856 27.0537 59.5447 26.8535 59.4436C26.6533 59.3424 26.4345 59.2831 26.2107 59.2692C26.0809 59.2694 25.9522 59.2925 25.8304 59.3372C25.7711 59.2293 25.7395 59.1082 25.7384 58.985C25.7375 58.9153 25.7513 58.8461 25.779 58.7821C25.8066 58.718 25.8474 58.6605 25.8988 58.6133C25.9502 58.5661 26.0109 58.5303 26.077 58.5081C26.1432 58.486 26.2133 58.478 26.2827 58.4848C26.851 58.4848 27.2552 58.957 27.5754 59.4973L27.3753 59.8575ZM28.576 60.3618L28.3759 61.3343H25.7304L25.3662 62.9913H27.5354L27.3353 63.9558H25.1661L24.7658 65.7448H27.6475L27.4473 66.7173H23.189L24.5337 60.3618H28.576Z" fill="#003F7D"/>
    <path d="M31.9138 61.3023C30.5891 61.3023 29.9487 63.4434 29.9487 64.5961C29.9487 65.4205 30.3209 65.7727 30.8732 65.7727C32.198 65.7727 32.8383 63.6356 32.8383 62.4789C32.8383 61.6544 32.4661 61.3023 31.9138 61.3023ZM30.9813 66.7973C30.9027 66.9014 30.858 67.0272 30.8532 67.1575C30.8532 67.3896 31.0533 67.5097 31.3255 67.5097C31.8355 67.4576 32.3214 67.2666 32.7303 66.9574L32.5302 67.8819C32.0398 68.2704 31.4347 68.4857 30.8092 68.4943C30.1368 68.4943 29.6085 68.094 29.6085 67.4897C29.6143 67.1943 29.7249 66.9106 29.9207 66.6893C29.0362 66.4091 28.5239 65.6567 28.5239 64.6001C28.5239 62.571 29.7606 60.2737 32.0899 60.2737C32.3852 60.2513 32.6818 60.2932 32.9594 60.3965C33.2369 60.4998 33.4888 60.662 33.6976 60.872C33.9065 61.0819 34.0673 61.3347 34.1691 61.6128C34.2708 61.8909 34.3111 62.1877 34.2871 62.4829C34.2871 64.432 33.1425 66.6292 30.9933 66.8013" fill="#003F7D"/>
    <path d="M40.7866 60.3616L39.9861 64.1677C39.8952 64.8611 39.5998 65.5118 39.1377 66.0367C38.856 66.3104 38.5207 66.5228 38.1528 66.6606C37.785 66.7984 37.3927 66.8585 37.0005 66.8372C35.8638 66.8372 34.8833 66.3449 34.8833 65.1002C34.9035 64.7512 34.9531 64.4044 35.0314 64.0636L35.8038 60.3896H37.2206L36.3761 64.3438C36.3353 64.5495 36.3126 64.7584 36.3081 64.9681C36.3081 65.5204 36.7083 65.7686 37.2006 65.7686C37.3788 65.774 37.556 65.741 37.7203 65.672C37.8847 65.6029 38.0323 65.4994 38.1531 65.3684C38.3719 65.0868 38.5177 64.7554 38.5774 64.4038L39.4298 60.4016L40.7866 60.3616Z" fill="#003F7D"/>
    <path d="M46.1893 60.3616L45.9892 61.3341H43.3358L42.9756 62.991H45.1448L44.9447 63.9556H42.7754L42.3912 65.7446H45.2768L45.0727 66.7171H40.7983L42.1431 60.3616H46.1893Z" fill="#003F7D"/>
    <path d="M48.8992 63.7874L48.6871 64.7799H46.0576L46.2657 63.7874H48.8992Z" fill="#003F7D"/>
    <path d="M55.2828 60.3618L51.7768 66.7213H50.332L49.6597 60.3618H51.1245L51.4767 64.46C51.4807 64.5307 51.4807 64.6015 51.4767 64.6722C51.4771 64.8672 51.457 65.0617 51.4166 65.2525C51.4863 64.9752 51.587 64.7067 51.7168 64.452L53.786 60.3538L55.2828 60.3618Z" fill="#003F7D"/>
    <path d="M57.8723 64.4919L57.6321 62.4347C57.6079 62.2583 57.5946 62.0805 57.5921 61.9024C57.5845 61.7691 57.5845 61.6355 57.5921 61.5022C57.5029 61.8233 57.378 62.1334 57.2199 62.4267L56.1593 64.4559L57.8723 64.4919ZM59.5772 66.7211H58.1524L57.9843 65.4364H55.671L55.0227 66.7211H53.6299L56.5395 61.3381H55.8391L56.0392 60.3616H58.6207L59.5772 66.7211Z" fill="#003F7D"/>
    <path d="M65.7526 62.3427L64.3078 62.3827C64.3137 62.2894 64.3137 62.1958 64.3078 62.1026C64.3078 61.6583 64.1478 61.3021 63.6235 61.3021C62.4708 61.3021 61.7184 63.2112 61.7184 64.4479C61.7184 65.2883 62.1386 65.7526 62.9831 65.7526C63.6928 65.7141 64.3812 65.4966 64.9842 65.1202L64.7521 66.2129C64.1112 66.5941 63.3806 66.7985 62.6349 66.8052C62.0208 66.8345 61.4172 66.638 60.938 66.2529C60.71 66.0273 60.5328 65.7557 60.4182 65.4561C60.3037 65.1566 60.2543 64.836 60.2736 64.5159C60.2736 62.4868 61.6103 60.2695 63.7475 60.2695C64.8521 60.2695 65.7486 60.8218 65.7486 62.0145C65.7486 62.1229 65.7419 62.2311 65.7286 62.3387" fill="#003F7D"/>
    <path d="M69.4507 64.4919L69.2025 62.4507C69.175 62.2746 69.1602 62.0967 69.1585 61.9184C69.151 61.7851 69.151 61.6515 69.1585 61.5182C69.0707 61.8392 68.9472 62.1493 68.7903 62.4427L67.7257 64.4719L69.4507 64.4919ZM71.1596 66.7211H69.7348L69.5627 65.4364H67.2374L66.5851 66.7211H65.2363L68.15 61.3381H67.4456L67.6457 60.3616H70.2271L71.1596 66.7211Z" fill="#003F7D"/>
    <path d="M78.0953 60.3616L76.7505 66.7212H75.9501L73.969 63.2352C73.8422 63.0151 73.7478 62.7779 73.6888 62.5308L72.8043 66.7092H71.6396L72.9844 60.3496H73.8089L75.81 63.8756C75.9211 64.0717 76.0059 64.2816 76.0621 64.4999L76.9466 60.3496L78.0953 60.3616Z" fill="#003F7D"/>
    <path d="M83.767 62.3427L82.3221 62.3827C82.3221 62.3027 82.3422 62.1786 82.3422 62.1026C82.3422 61.6583 82.1821 61.3021 81.6578 61.3021C80.5051 61.3021 79.7527 63.2112 79.7527 64.4479C79.7527 65.2883 80.1529 65.7526 81.0174 65.7526C81.7274 65.7153 82.4161 65.4977 83.0185 65.1202L82.7904 66.2129C82.1479 66.5938 81.4161 66.7981 80.6692 66.8052C80.0553 66.8334 79.452 66.637 78.9723 66.2529C78.745 66.0269 78.5686 65.7552 78.4547 65.4556C78.3408 65.1561 78.2921 64.8357 78.3119 64.5159C78.3119 62.4868 79.6447 60.2695 81.7859 60.2695C82.8905 60.2695 83.787 60.8218 83.787 62.0145C83.787 62.1066 83.787 62.2666 83.787 62.3387" fill="#003F7D"/>
    <path d="M89.2538 60.3616L89.0537 61.3381H86.4042L86.044 62.995H88.2132L88.0131 63.9596H85.8439L85.4597 65.7446H88.3413L88.1412 66.7211H83.8628L85.2075 60.3616H89.2538Z" fill="#003F7D"/>
    <path d="M93.8763 61.5663C93.4324 61.3497 92.9454 61.2362 92.4515 61.2341C91.7191 61.2341 91.1548 61.5543 91.1548 62.0865C91.1548 62.5188 91.627 62.7269 92.1713 62.971C93.0158 63.3432 93.7282 63.7435 93.7282 64.696C93.7282 66.2649 92.3234 66.8052 90.9467 66.8052C90.2702 66.8035 89.6033 66.646 88.9976 66.3449L89.2177 65.2603C89.7392 65.6249 90.3584 65.8243 90.9947 65.8326C91.7471 65.8326 92.2994 65.4804 92.2994 64.9401C92.2994 64.5399 91.8992 64.3478 91.3269 64.1037C90.4023 63.7034 89.726 63.3032 89.726 62.3387C89.726 61.102 90.8626 60.2695 92.4675 60.2695C93.0145 60.2764 93.5561 60.3779 94.0684 60.5697L93.8763 61.5663Z" fill="#003F7D"/>
    <path d="M59.4129 48.0268V48.1108V48.1709V48.1949C59.0887 49.6877 58.6885 50.7803 58.1122 51.4407C57.8405 51.7832 57.494 52.059 57.0993 52.247C56.7046 52.435 56.2721 52.5302 55.8349 52.5253C55.5477 52.5426 55.2605 52.4923 54.9963 52.3782C54.7322 52.2642 54.4985 52.0898 54.3141 51.8689C53.9147 51.3052 53.7211 50.6213 53.7658 49.9318C53.7909 48.3222 54.364 46.7693 55.3907 45.5294C55.8456 44.933 56.4265 44.4444 57.0921 44.0985C57.7576 43.7525 58.4913 43.5578 59.2408 43.5283C59.6309 43.5296 60.017 43.6071 60.3775 43.7564L59.4129 48.0268ZM62.1425 52.1451C61.8254 52.3126 61.4759 52.4095 61.1179 52.4292C61.0137 52.4424 60.9083 52.4167 60.8217 52.3572C60.7737 52.3172 60.7457 52.2651 60.7417 52.1251C60.7583 51.8885 60.7971 51.654 60.8577 51.4247L62.6988 42.9559L62.6027 42.8919C61.599 42.2428 60.4282 41.8993 59.2328 41.9034C54.8544 41.9034 51.5085 45.9056 51.5045 50.0079C51.4514 51.127 51.823 52.2249 52.5451 53.0816C52.9137 53.4645 53.3594 53.7649 53.8527 53.9629C54.3459 54.1609 54.8756 54.252 55.4067 54.2302C56.614 54.2322 57.7744 53.7626 58.6405 52.9215C58.7057 53.211 58.8579 53.4736 59.0767 53.6739C59.4282 53.9875 59.8868 54.1537 60.3575 54.1382H60.3895C60.971 54.1253 61.5402 53.9685 62.0464 53.6819H62.0824L62.1104 53.6619L62.4706 51.969L62.1425 52.1451Z" fill="#003F7D"/>
    <path d="M72.9407 52.5292C72.8366 52.5395 72.7322 52.5141 72.6445 52.4572C72.6149 52.4268 72.5924 52.3902 72.5785 52.3501C72.5647 52.3099 72.5599 52.2673 72.5645 52.2251C72.5811 51.9885 72.62 51.754 72.6805 51.5247L73.585 47.3783C73.7809 46.6354 73.8937 45.8729 73.9212 45.1051C73.9394 44.6485 73.8513 44.1939 73.6638 43.7771C73.4762 43.3604 73.1945 42.9929 72.8406 42.7037C72.0919 42.1535 71.1798 41.8716 70.2512 41.9033H70.1311C69.1381 41.913 68.1585 42.134 67.2575 42.5517C66.4819 42.9139 65.8009 43.4513 65.2682 44.1215C64.7355 44.7917 64.3657 45.5764 64.1878 46.4138V46.4859L64.1678 46.5699L62.5669 54.0141H64.7561L65.9048 48.7071C66.2369 47.1622 66.6172 45.8495 67.2775 45.009C67.5756 44.6108 67.9605 44.2857 68.4029 44.0582C68.8453 43.8308 69.3336 43.707 69.8309 43.6963C70.3248 43.6666 70.8138 43.808 71.2157 44.0965C71.3812 44.2538 71.5105 44.4451 71.5949 44.6572C71.6792 44.8694 71.7164 45.0973 71.704 45.3252C71.6877 45.9004 71.6085 46.4721 71.4679 47.0302L70.5393 51.2685C70.4467 51.6885 70.3905 52.1156 70.3713 52.5452C70.3592 52.7899 70.406 53.0338 70.5077 53.2566C70.6094 53.4795 70.7629 53.6747 70.9556 53.8259C71.3192 54.0937 71.7608 54.2343 72.2123 54.2262C72.7952 54.2177 73.3665 54.0621 73.8732 53.7739L73.9052 53.7539H73.9372L74.2614 52.069L73.9332 52.2611C73.6322 52.429 73.2969 52.5262 72.9527 52.5452" fill="#003F7D"/>
    <path d="M77.9194 45.7656C78.6918 44.5009 79.7964 43.5764 81.2012 43.5804C81.4287 43.5594 81.6579 43.5919 81.8705 43.6753C82.0832 43.7587 82.2733 43.8906 82.4259 44.0606C82.6058 44.2928 82.7192 44.5695 82.7541 44.8611L84.8192 44.4609C84.7042 43.8247 84.3919 43.2409 83.9267 42.7919C83.2207 42.1806 82.3061 41.8652 81.3733 41.9114C80.3986 41.924 79.4402 42.1639 78.5744 42.612C77.7087 43.0602 76.9595 43.7042 76.3865 44.4929C75.1999 46.0543 74.5525 47.9588 74.5415 49.9199C74.5415 51.3367 74.9978 52.4293 75.8102 53.1537C76.743 53.9117 77.9238 54.2968 79.1241 54.2343C80.4828 54.2449 81.8156 53.8627 82.9622 53.1337L82.9902 53.1137H83.0182L83.4185 51.1406L83.0703 51.3647C81.9853 52.0831 80.7277 52.4977 79.4282 52.5654C79.0659 52.5939 78.7016 52.5483 78.3576 52.4314C78.0135 52.3144 77.6969 52.1285 77.4271 51.885C76.9472 51.3331 76.7054 50.6136 76.7547 49.8839C76.7888 48.4481 77.1912 47.0451 77.9234 45.8096" fill="#003F7D"/>
    <path d="M58.2603 28.4159C57.82 28.4159 56.5873 28.3438 54.9064 26.2947C53.2255 24.2455 49.2272 17.2296 47.082 15.6327C44.9368 14.0358 39.5138 11.1542 17.9058 11.2063C17.9058 11.2063 18.0859 12.1108 20.3791 12.7031C22.6724 13.2954 44.9288 16.8094 48.6949 32.0539C48.6949 32.0539 48.9871 32.9304 47.9385 33.3986C47.054 33.7989 40.3943 36.6525 38.9535 38.1733C38.9535 38.1733 38.8054 38.3094 39.0095 38.5735C39.0776 38.6791 39.1776 38.7602 39.2949 38.8048C39.4123 38.8494 39.5409 38.8554 39.6619 38.8217C41.6329 38.4822 43.6259 38.2856 45.6252 38.2333C48.827 38.1493 49.8036 38.6336 55.915 38.4615C62.0264 38.2894 66.1367 34.4192 69.0783 32.17C69.9588 31.4976 73.2006 31.8778 73.2006 31.8778C72.3121 31.2685 71.2915 30.8789 70.223 30.7412C69.7764 30.6469 69.3624 30.4371 69.0223 30.1328C69.0223 30.1328 67.0212 28.2678 63.111 28.724C63.111 28.724 70.223 18.0181 86.6521 15.6928C86.6521 15.6928 69.8628 14.4601 59.2608 27.8636C59.141 28.0205 58.9905 28.1515 58.8185 28.2485C58.6465 28.3455 58.4566 28.4065 58.2603 28.4279" fill="#D4021D"/>
    <path d="M93.1798 42.1637L88.9375 49.7399C88.6933 50.1681 88.4052 50.6804 88.173 51.1607C88.149 50.7364 88.097 50.2922 88.0409 49.8479L87.0924 42.1196H84.8472L86.6041 54.0303H88.6053L95.4731 42.1276H93.2118L93.1798 42.1637Z" fill="#003F7D"/>
  </svg>
  );
}
