import { Button, InputText, Modal, spaceUsages } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Category } from '../../../domain/Category';
import { useUpdateCategoryMutation } from '../../../mutations/menu/useCategoryMutation';
import { businessId } from 'src/bills/domain/Bill.fixture';

export const CategoryEditionModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

type CategoryEditionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  menuId: string;
  category: Category;
};

type CategoryEditionModalInputs = {
  name: string;
  description: string;
};

const CategoryEditionModal = ({
  isOpen, onClose, menuId, category,
}: CategoryEditionModalProps) => {
  const { formatMessage } = useIntl();
  const updateCategoryUseCase = useUpdateCategoryMutation(businessId, menuId, category.id);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting },
  } = useForm<CategoryEditionModalInputs>({
    defaultValues: { name: category.name, description: category.description },
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<CategoryEditionModalInputs> = async ({ name, description }) => {
    await updateCategoryUseCase.mutateAsync({ name, description });
    onClose();
  };

  const onCloseThenResetFields = async () => {
    onClose();
    reset({
      name: category.name,
      description: category.description,
    });
  };

  return (
    <Modal
      title={formatMessage({ id: 'menus.edition.edit_category' })}
      cancelButtonMessage={formatMessage({ id: 'menus.modal.edition.cancel' })}
      isOpened={isOpen}
      onClose={onCloseThenResetFields}
    >
      <CategoryEditionModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={formatMessage({ id: 'menus.edition.category_name' })}
          id="categoryName"
          maxLength={45}
          {...register('name', { required: formatMessage({ id: 'menus.category.creation.form.required' }) })}
        />
        <br />
        <InputText
          label={formatMessage({ id: 'menus.edition.category_description' })}
          id="categoryDescription"
          maxLength={800}
          {...register('description')}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          marginTop={spaceUsages.large}
          disabled={!isValid || isSubmitting}
        >
          <FormattedMessage id="menus.creation.form.submit" />
        </Button>
      </CategoryEditionModalForm>
    </Modal>
  );
};

export default CategoryEditionModal;
