import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { VenueDetails } from '../../../../types';
import { useUpdateVenueDetails } from '../../../../hook';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type ForbidsMultiboxOrdersVenueSettingProps = {
  venue: VenueDetails;
  setVenue: (venue: VenueDetails) => void;
};

export const ForbidsMultiboxOrdersVenueSetting = ({ venue, setVenue }: ForbidsMultiboxOrdersVenueSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateVenueDetails] = useUpdateVenueDetails(venue.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateVenue = async (multiboxOrdersForbidden: boolean) => {
    makeUpdating(true);
    await updateVenueDetails({
      multiboxOrdersForbidden,
    });
    setVenue({
      ...venue,
      multiboxOrdersForbidden,
    });
    await pushNotification(
      intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }),
    );
    makeUpdating(false);
  };

  if (!venue.isFoodCourt) {
    return null;
  }

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.forbidsMultiboxOrders',
        defaultMessage: 'forbids multibox orders',
        description: 'forbids multibox orders title',
      })}
      description={intl.formatMessage({
        id: 'venue.forbidsMultiboxOrdersDescription',
        defaultMessage:
          'When activated, multibox orders are not allowed. Customer can place orders with products belonging to only one box',
      })}
      toggleValue={venue.multiboxOrdersForbidden}
      toggleOnChange={updateVenue}
      disabled={isUpdating}
    />
  );
};
