import { Page } from '@components/templates/PageTemplate';
import { useRenderMoney } from '@sundayapp/web-money';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { Business } from 'src/business/domain/Business';
import { VenueKpi } from 'src/home/domain/VenueKpi';
import { TitleFormat } from '../../../types/layout_types';
import { HomeContent } from './HomePage/HomeContent';
import { HomeFooter } from './HomePage/HomeFooter';

export type HomeContentProps = {
  business: Business;
  venueKpi: VenueKpi;
  adoptionRate?: number | undefined;
  totalRevenue?: string; //TODO::Find what this prop is useful for
};

export const sundayTitle: TitleFormat = {
  id: 'sunday',
  hasEmphasis: false,
};

export const HomePage = ({
  business,
  venueKpi,
}: HomeContentProps) => {
  const user = useAuthenticatedUserOrThrow();
  const renderMoney = useRenderMoney(true, true);
  const { adoptionRate } = venueKpi;

  const titleWithAdoption: TitleFormat[] = [
    {
      id: 'venue.home.title',
      values: {
        username: user.firstName,
        adoptionRate,
      },
    },
  ];

  const titleWithoutAdoption: TitleFormat[] = [
    {
      id: 'venue.home.no-adoption.title.part1',
      hasEmphasis: false,
    },
    {
      id: 'venue.home.no-adoption.title.part2',
      hasEmphasis: true,
    },
  ];

  const homeContent = {
    adoptionRate,
    business,
    venueKpi,
    totalRevenue: renderMoney(venueKpi.totalRevenue),
  };

  return (
    <Page
      hasActions
      hasHeadTitle={{
        postLogoText: [sundayTitle],
        preLogoText: adoptionRate ? titleWithAdoption : titleWithoutAdoption,
        variant: 'h4',
        hasLogo: true,
        showTitle: true,
      }}
    >
      <HomeContent {...homeContent} />
      <HomeFooter business={business} />
    </Page>
  );
};
