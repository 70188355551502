/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function GooglePayIcon({ ...props }: PaymentMethodIconProps) {
  return (
    <svg viewBox="0 0 20 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.46 3.908v2.345h-.74V.463h1.96a1.76 1.76 0 0 1 1.268.5 1.611 1.611 0 0 1 .53 1.223 1.615 1.615 0 0 1-.53 1.229c-.343.329-.765.493-1.268.493H9.46Zm0-2.733v2.022h1.238a.97.97 0 0 0 .73-.3.988.988 0 0 0 .22-1.092.988.988 0 0 0-.22-.323.963.963 0 0 0-.73-.307H9.459Zm4.72.987c.546 0 .977.147 1.293.44.316.294.473.697.473 1.21v2.441h-.706v-.55h-.033c-.305.453-.712.68-1.22.68-.433 0-.796-.13-1.087-.388a1.239 1.239 0 0 1-.438-.97c0-.41.154-.737.461-.979.308-.242.719-.364 1.233-.364.438 0 .8.08 1.083.242v-.17a.852.852 0 0 0-.305-.658 1.041 1.041 0 0 0-.713-.271c-.412 0-.739.175-.98.526l-.65-.413c.358-.518.888-.776 1.589-.776Zm-.956 2.878a.598.598 0 0 0 .245.486.9.9 0 0 0 .574.194c.312 0 .61-.126.831-.348a1.09 1.09 0 0 0 .367-.816c-.23-.185-.551-.278-.963-.278-.3 0-.55.073-.75.219-.203.148-.304.328-.304.543Zm6.777-2.749L17.535 8h-.762l.915-1.998-1.622-3.711h.803l1.172 2.846h.016l1.14-2.846h.804Z"
        fill="#5F6368"
      />
      <path
        d="M6.475 3.403c0-.227-.019-.453-.057-.677H3.303v1.282h1.784a1.547 1.547 0 0 1-.66 1.01v.832h1.065c.624-.579.983-1.435.983-2.447Z"
        fill="#4285F4"
      />
      <path
        d="M3.303 6.654c.891 0 1.642-.295 2.19-.803l-1.066-.832c-.296.202-.678.318-1.124.318-.861 0-1.593-.585-1.854-1.373H.352v.857a3.318 3.318 0 0 0 1.217 1.338 3.285 3.285 0 0 0 1.734.495Z"
        fill="#34A853"
      />
      <path
        d="M1.449 3.964a2.007 2.007 0 0 1 0-1.274v-.857H.352a3.347 3.347 0 0 0 0 2.988l1.097-.857Z"
        fill="#FBBC04"
      />
      <path
        d="M3.303 1.317c.471-.008.926.171 1.267.499l.943-.95A3.161 3.161 0 0 0 3.303 0c-.612 0-1.212.171-1.734.495A3.318 3.318 0 0 0 .352 1.833l1.097.857c.261-.788.992-1.373 1.854-1.373Z"
        fill="#EA4335"
      />
    </svg>
  );
}
