import React, { Context, createContext, ReactNode } from 'react';
import { CircularProgress } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { usePosPaymentMethods, useReportSettings, useUpdateReportSettings } from '../queries/ReportSettings';
import { PosPaymentMethod } from 'src/domain/venue/PosPaymentMethods';
import { ReportSettings } from '../domain/ReportSettings';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

interface ReconciliationContext {
  posPaymentMethods: PosPaymentMethod[];
  reportSettings: ReportSettings;
  saveReportSettings: (reportSettingsToSave: ReportSettings) => void;
}

export const ReconciliationConfigurationContext: Context<ReconciliationContext> = createContext(
  {} as ReconciliationContext,
);

const ReconciliationContextProvider = ({ children }: { children: ReactNode }) => {
  const { formatMessage } = useIntl();
  const useSnackBar = useSnackbar();

  const { posPaymentMethods, isLoading: isLoadingPosPaymentMethods } = usePosPaymentMethods();
  const { data: reportSettings, isLoading: isLoadingReportSetting } = useReportSettings();
  const { mutateAsync: saveReport } = useUpdateReportSettings();
  const saveReportSettings = (reportSettingsToSave: ReportSettings) => {
    saveReport({ reportSettings: reportSettingsToSave })
      .then(() =>
        useSnackBar.addNotification({
          variant: 'success',
          text: formatMessage({ id: 'settings.app_settings.success_saving' }),
        }),
      )
      .catch(() => {
        useSnackBar.addNotification({
          variant: 'error',
          text: formatMessage({ id: 'error.generic_error' }),
        });
      });
  };

  if (isLoadingPosPaymentMethods || isLoadingReportSetting || !reportSettings) {
    return <CircularProgress />;
  }
  return (
    <ReconciliationConfigurationContext.Provider
      value={{
        posPaymentMethods: posPaymentMethods ?? [],
        reportSettings,
        saveReportSettings,
      }}
    >
      {children}
    </ReconciliationConfigurationContext.Provider>
  );
};

export default ReconciliationContextProvider;
