import { Box, Button, Card, Typography, styled } from '@mui/material';
import React from 'react';

type StatusCardProps = {
  button: string,
  media: React.ReactNode,
  subtitles: string[],
  title: string,
};

export const StatusWrapper = styled(Card)(({ theme }) => ({
  display: 'flex', 
  alignItems: 'center', 
  justifyContent: 'center', 
  padding: `${theme.spacing(5)} 0px`,
  minHeight: '400px',
  gap: theme.spacing(2),
  marginTop: theme.spacing(4),
  
  '.MuiBox-root': {
    display: 'flex',
    flexDirection: 'column',
  },
  
  '@media(max-width:480px)': {
    margin: '0 auto',
    minHeight: '272px',
    width: '343px',
    padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
    textAlign: 'center',
  
    '.MuiTypography-body1': {
      letterSpacing: theme.spacing(-0.07),
    },
  
    '.MuiBox-root': {
      display: 'inline-block',
      width: '100%',
    },
  },
}));
  
export const StatusCard = ({
  button,
  media, 
  subtitles,
  title,
}: StatusCardProps) => {
  return (
    <StatusWrapper>
        {media}
        <Typography sx={{ marginBottom: 2, marginTop: 4 }} variant='h5'>{title}</Typography>
        <Box>
          {subtitles.map((subText: string, index) => (
            <Typography 
                key={index} 
                variant='body1' 
                sx={{ color: 'text.secondary' }} 
                component="span"
            >
                {subText}
            </Typography>
          ))}
        </Box>
        {button !== '' && (
          <Button variant='contained' color='primary'>{button}</Button>
        )}
    </StatusWrapper>
  );
};