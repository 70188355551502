import * as React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { isProductPriceTimeOverride, PricingStrategy } from 'src/menu/dynamic-menu/domain/Product';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ProductPromotionsList } from 'src/menu/dynamic-menu/pages/ProductPage/components/ProductPromotionsList';

type Props = {
  pricingStrategies: PricingStrategy[];
};

const ProductPromotions = ({ pricingStrategies }: Props) => {
  const promotionStrategies = pricingStrategies.filter(isProductPriceTimeOverride);
  if (!promotionStrategies?.length) {
    return null;
  }

  return (
    <Card>
      <CardContent sx={{ gap: 0, padding: '32px' }}>
        <Box display="flex" flexDirection="column">
          <Typography fontSize="large">
            <FormattedMessage id="menus.product.details.promotions.title" defaultMessage="Promotions" />
          </Typography>
          <Typography color={themeV5.palette.text.secondary} variant="body2">
            <FormattedMessage
              id="menus.product.details.promotions.subtitle"
              defaultMessage="Products will be discounted for a limited time" />
          </Typography>
        </Box>
        <ProductPromotionsList pricingStrategies={pricingStrategies} />
      </CardContent>
    </Card>
  );
};

export { ProductPromotions };
