import React from 'react';
import { Stack } from '@mui/material';
import { PendingTipsDispatchLoader } from 'src/tips/dispatch/PendingTipsDispatchLoader';
import { TipsDispatchedLoader } from 'src/tips/dispatch/TipsDispatchedLoader';

export const DispatchSundayPoolingTipsPage = () => {
  return (
    <Stack spacing={4} >
      <PendingTipsDispatchLoader />
      <TipsDispatchedLoader />
    </Stack>
  );
};
