import React from 'react';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { userRoles } from 'src/domain/user/UserRole';
import UserPermissionsComponent from './components/UserPermissionsComponent';

function UserPermissions() {
  return (
    <UserPermissionsComponent />
  );
}

export default withRestrictedAccess([userRoles.ADMIN], UserPermissions);
