import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import MenuItem from '@mui/material/MenuItem';
import { SubmitHandler, useForm } from 'react-hook-form';
import { datadogLogs } from '@datadog/browser-logs';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useSaveComoConfiguration } from './mutations/useSaveComoBusinessConfiguration';

type OnboardingInputs = {
  posId: string;
  apiKey: string;
  branchId: string;
  theme: string;
};
export const ComoOnboardingForm = () => {
  const businessId = useBusinessIdOrThrow();
  const { formatMessage } = useIntl();
  const {
    data: loyaltyBusiness,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);

  const saveConfiguration = useSaveComoConfiguration(businessId);
  const [onboardingError, setOnboardingError] = useState<string>('');

  const {
    register,
    handleSubmit,
    formState: {
      errors,
      isValid,
      isSubmitting,
    },
  } = useForm<OnboardingInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const snackBar = useSnackbar();
  const onSubmit: SubmitHandler<OnboardingInputs> = async ({ posId, apiKey, branchId, theme }) => {
    setOnboardingError('');
    try {
      saveConfiguration.mutate({
        loyaltyReference: 'COMO',
        loyaltyConfiguration: {
          posId,
          apiKey,
          branchId,
          theme,
        },
      });
      snackBar.addNotification({
        variant: 'success',
        text: formatMessage({ id: 'loyalty.onboarding.success', defaultMessage: 'Configuration saved' }),
      });
    } catch (error: any) {
      snackBar.addNotification({ variant: 'error', text: formatMessage({ id: 'loyalty.onboarding.errors.other' }) });
      datadogLogs.logger.error('An error occurred while trying to configure a Como business', { error });
      setOnboardingError(formatMessage({ id: 'loyalty.onboarding.errors.other' }));
    }
  };

  if (loyaltyReferenceIsLoading) {
    return <CircularProgress />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="16px" maxWidth="576px">
        <Typography variant="h5">
          {formatMessage({ id: 'loyalty.onboarding.title' }, { loyaltyReference: 'Como' })}
        </Typography>
        <TextField
          label="pos id"
          required
          error={!!errors.posId}
          id="posId"
          defaultValue={loyaltyBusiness?.loyaltyConfiguration?.posId}
          helperText={errors.posId?.message}
          {...register('posId', {
            required: formatMessage({ id: 'loyalty.onboarding.errors.field' }, { field: 'posId' }),
          })}
        />
        <TextField
          label="api key"
          required
          error={!!errors.apiKey}
          id="apiKey"
          defaultValue={loyaltyBusiness?.loyaltyConfiguration?.apiKey}
          helperText={errors.apiKey?.message}
          {...register('apiKey', {
            required: formatMessage({
              id: 'loyalty.onboarding.errors.field',
            }, { field: 'apiKey' }),
          })}
        />
        <TextField
          label="branch id"
          required
          error={!!errors.branchId}
          id="branchId"
          defaultValue={loyaltyBusiness?.loyaltyConfiguration?.branchId}
          helperText={errors.branchId?.message}
          {...register('branchId', {
            required: formatMessage({ id: 'loyalty.onboarding.errors.field' }, { field: 'branchId' }),
          })}
        />
        <FormControl>
          <InputLabel id="theme">Theme</InputLabel>
          <Select
            label="Program"
            defaultValue={loyaltyBusiness?.loyaltyConfiguration?.theme || 'default'}
            id="theme"
            {...register('theme')}
          >
            <MenuItem value="default">Default Theme</MenuItem>
            <MenuItem value="DS_CAFE">DS_CAFE</MenuItem>
            <MenuItem value="LEON">LEON</MenuItem>
          </Select>
        </FormControl>
        {onboardingError && (
          <Alert severity="error">{onboardingError}</Alert>
        )}
        <Button type="submit" variant="contained" disabled={!isValid || isSubmitting}>
          {formatMessage({ id: 'loyalty.onboarding.save', defaultMessage: 'Save' })}
        </Button>
      </Stack>
    </form>
  );
};
