import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { translation, Translation } from '../../common/translation';

export const revenueSources = {
  QR_CODE: 'QR_CODE',
  OTHER: 'OTHER',
  TOAST: 'TOAST',
  PAYMENT_TERMINAL: 'PAYMENT_TERMINAL',
} as const;

export type RevenueSource = ValuesOf<typeof revenueSources>;

export const revenueSourceTranslations: { [key in RevenueSource]: Translation } = {
  OTHER: translation('accounting.revenue_tab.table.row_group_title.other'),
  TOAST: translation('Toast'),
  QR_CODE: translation('accounting.revenue_tab.table.row_group_title.qr_codes'),
  PAYMENT_TERMINAL: translation('accounting.revenue_tab.table.row_group_title.payment_terminal'),
};

export const revenueSourceTypes = {
  CARDS: 'CARDS',
  OTHER_REVENUES: 'OTHER_REVENUES',
  MEAL_VOUCHERS: 'MEAL_VOUCHERS',
  PAYMENT_LINKS: 'PAYMENT_LINKS',
  AMEX: 'AMEX',

} as const;
export type RevenueSourceType = ValuesOf<typeof revenueSourceTypes>;

export const revenueSourceTypeTranslations: { [key in RevenueSourceType]: Translation } = {
  CARDS: translation('accounting.revenue_tab.table.cell.cards'),
  OTHER_REVENUES: translation('accounting.revenue_tab.table.cell.other_revenues'),
  MEAL_VOUCHERS: translation('accounting.revenue_tab.table.cell.meal_vouchers'),
  PAYMENT_LINKS: translation('accounting.revenue_tab.table.cell.payment_links'),
  AMEX: translation('accounting.revenue_tab.table.cell.amex'),
};
