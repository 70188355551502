import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { SectionComponent } from './SectionComponent';
import { InsightPerDimension } from './operationalPerformance/InsightPerDimension';
import { BasketSize } from './operationalPerformance/BasketSize';
import { StaffPerformance } from './operationalPerformance/StaffPerformance';
import { OperationalData } from '../domain/TemplatesData';

export const OperationalPerformance = ({
  operationalData,
  countryCode,
}: {
  operationalData: OperationalData;
  countryCode: string;
}) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <SectionComponent
        breakBefore
        title={formatMessage({ id: 'venuekpi.weeklyreport.multi.email.operational_performance' })}
        legend={formatMessage({ id: 'venuekpi.weeklyreport.multi.email.legend' })}
        imagePath={operationalData.imagePath}
      >
        <InsightPerDimension insights={operationalData.insights} countryCode={countryCode} />
        <BasketSize basket={operationalData.basket} countryCode={countryCode} />
        {operationalData.staffs.waiterCount > 0 && (
          <StaffPerformance staffVenueKpis={operationalData.staffs} countryCode={countryCode} />
        )}
      </SectionComponent>
    </>
  );
};
