import React, { useCallback } from 'react';
import IconButton from '../../../common/components/IconButton';
import { CheckIcon, PlusIcon } from '../../../common/components/Icons';
import { OrderItem as OrderItemType } from '../../types';
import OrderItem from '../OrderItem/OrderItem';
import NumberSelector from '../../../common/components/NumberSelector';

interface Props {
  item: OrderItemType;
  selectedQuantity: number;
  setSelectedQuantity: (quantity: number) => void;
}

const OrderItemForRefund: React.FC<Props> = ({ item, selectedQuantity, setSelectedQuantity }) => {
  const onClickPlus = useCallback(() => setSelectedQuantity(1), [setSelectedQuantity]);
  const onClickCheck = useCallback(() => setSelectedQuantity(0), [setSelectedQuantity]);
  const Quantity = () => {
    if (item.quantity > 1) {
      return (
        <NumberSelector
          value={selectedQuantity}
          setValue={setSelectedQuantity}
          minNumber={0}
          maxNumber={item.quantity}
        />
      );
    } if (selectedQuantity === 0) {
      return <IconButton Icon={PlusIcon} onClick={onClickPlus} />;
    }
    return <IconButton Icon={CheckIcon} onClick={onClickCheck} active />;
  };

  return (
    <OrderItem item={item}>
      <Quantity />
    </OrderItem>
  );
};

export default OrderItemForRefund;
