import React, { FormEvent, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useRouting } from 'src/ordering/common/hooks';
import { useCreateMenuMutation } from '../../../mutations/menu/useMenuMutation';
import { BusinessId } from 'src/business/domain/Business';
import { MenuAssetsSource, MenuAssetsSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { MenuAssetsSourceSelector } from 'src/menu/dynamic-menu/pages/MenusPage/components/MenuAssetsSourceSelector';
import { Nullable } from 'src/utils/typescript/nullable';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { Check } from '@mui/icons-material';

type MenuCreationModalProps = {
  businessId: BusinessId;
  onClose: () => void;
  onError: (error: boolean) => void;
  currentMenuAssetsSource: MenuAssetsSource | undefined;
  menuAssetsSources: MenuAssetsSources;
};
type Form = {
  name: string;
  menuAssetsSource: MenuAssetsSource | undefined;
};

export const MenuCreationModal = ({
  businessId,
  onClose,
  onError,
  currentMenuAssetsSource,
  menuAssetsSources,
}: MenuCreationModalProps) => {
  const createMenuMutation = useCreateMenuMutation(businessId);
  const menuNavigation = useRouting();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<Nullable<Form>>({
    name: null,
    menuAssetsSource: currentMenuAssetsSource || menuAssetsSources.sources[0],
  });

  const isFormValid = (f: Nullable<Form>): f is Form => {
    return f.name !== null && f.name.replace(' ', '').length > 0 && (!menuAssetsSources.sources.length || f.menuAssetsSource !== undefined);
  };

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!isFormValid(form)) {
      return;
    }

    setLoading(true);
    createMenuMutation.mutateAsync(form)
      .then((menuId) => {
        menuNavigation.goToMenuDetails(businessId, menuId);
      })
      .catch(onError)
      .finally(() => {
        onClose();
        setLoading(false);
      });

  };

  return (
    <Modal open>
      <Box sx={{ background: 'white', height: '100%', overflow: 'auto' }} display="flex" flexDirection="column">
        <form onSubmit={onSubmit}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding="20px 24px"
            borderBottom={`1px solid ${themeV5.palette.secondary.light}`}
          >
            <Box minWidth="50px"></Box>
            <Box flexGrow={1} display="flex" justifyContent={'center'}>
              <Typography>
                <FormattedMessage id="menus.creation.modal.name" />
              </Typography>
            </Box>
            <Box minWidth="50px" display="flex" alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={onClose}>
              <CrossIcon />
            </Box>
          </Box>
          <Box
            sx={{ width: '100%', marginLeft: 'auto', marginRight: 'auto', marginTop: '200px' }}
            display="flex"
            padding="16px"
            flexDirection="column"
            maxWidth={700}
            gap={3}
          >
            <TextField
              autoFocus
              id="outlined-controlled"
              label={<FormattedMessage id="menus.creation.form.name" />}
              value={form.name}
              required
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setForm((f) => ({ ...f, name: event.target.value }));
              }}
            />

            <MenuAssetsSourceSelector menuAssetsSources={menuAssetsSources}
                                      value={form.menuAssetsSource || undefined}
                                      onChange={(value) => setForm((f) => ({ ...f, menuAssetsSource: value }))} />
            <Button type="submit" size="large" variant="contained" disabled={!isFormValid(form) || isLoading}>
              <Box display="flex" justifyContent="center" alignItems="center" gap="8px" padding="8px 22px">
                <Check sx={{ width: '24px', height: '24px' }} />
                <Typography variant="subtitle1">
                  <FormattedMessage id="menus.creation.form.submit" />
                </Typography>
              </Box>
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
