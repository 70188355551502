import { styled } from '@mui/material';

export const TitleContent = styled('span')`
  margin-bottom: 32px;
  font-size: 34px;
  line-height: 37.5px;
  letter-spacing: -2px;
`;

export const TitleEmphasis = styled('span')(({ theme }) => ({
  paddingLeft: '6px',
  color: `${theme.palette.primary.main}`,
}));
