import { GridCellParams, GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import React from 'react';
import { Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { Action, ActionsButton } from 'src/components/actions-button/ActionsButton';
import { IntlShape } from 'src/app/i18n/TypedIntl';
import { Block } from 'src/menu/dynamic-menu/domain/Block';
import { ProductName } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductName';
import { ImgContainer } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductImageContainer';
import { ProductImage } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/MenuImage';
import { ProductNameInfo } from '../products/ProductNameInfo';
import { ContentCopy } from 'src/app/component/icons/ContentCopySvg';
import { Delete } from 'src/app/component/icons/DeleteSvg';

export const buildBlockColumns = (
  intl: IntlShape,
  onBlockActionClicked: (block: Block, actionName: 'copy' | 'delete') => void,
): GridColDef<Block>[] => {
  return [
    {
      field: 'id',
      display: 'flex',
      headerName: intl.formatMessage({ id: 'menus.blocks.header.product' }),
      disableColumnMenu: true,
      flex: 1,
      sortComparator: gridStringOrNumberComparator,
      renderCell: ({ row }: GridCellParams<Block>) => (
        <ProductName>
          <ImgContainer>{row.imageUrl && <ProductImage $imageUrl={row.imageUrl} />}</ImgContainer>
          <ProductNameInfo>
            <Typography>{row.title}</Typography>
          </ProductNameInfo>
          <ChevronRight fontSize="large" style={{ marginLeft: 1 }} />
        </ProductName>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      headerAlign: 'left',
      display: 'flex',
      align: 'left',
      flex: 1,
      disableColumnMenu: true,
      renderCell: ({ row }: GridCellParams<Block>) => row.description,
    },
    {
      field: 'actions',
      headerAlign: 'right',
      display: 'flex',
      sortable: false,
      align: 'right',
      headerName: intl.formatMessage({ id: 'menus.products.header.actions' }),
      flex: 1,
      renderCell: ({ row }) => {
        const actions: Action[] = [
          {
            icon: <ContentCopy />,
            visible: true,
            intlKey: 'menus.product.copy.cta',
            action: (event) => {
              event.stopPropagation();
              onBlockActionClicked(row, 'copy');
            },
          },
          {
            icon: <Delete />,
            visible: true,
            intlKey: 'menus.product.delete.cta',
            action: (event) => {
              event.stopPropagation();
              onBlockActionClicked(row, 'delete');
            },
          },
        ];
        return <ActionsButton actions={actions} popperPlacement="left-end" sx={{ paddingRight: '10px' }} />;
      },
    },
  ];
};
