import { QueryFunctionContext, useQueries, useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Product } from '../../domain/Product';
import { ProductRepositoryHttp } from '../../repositories/ProductRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_MENU_PRODUCT = 'menu_product';

const buildQueryKey = (businessId: BusinessId, productId: string, language?: string) =>
  [QUERYKEY_MENU_PRODUCT, businessId, productId, language] as const;

export const getProductByIdQuery = async ({
  queryKey: [, businessId, productId, language],
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<Product | null> => {
  const configuration = ConfigurationLoader.load();
  const productRepositoryHttp = new ProductRepositoryHttp(configuration.menuApiBaseUrl);
  return productRepositoryHttp.findByProductId(businessId, productId, language);
};

export const useGetProductById = (
  businessId: BusinessId,
  productId: string,
  language: string | undefined,
  {
    enabled,
  }: {
    enabled: boolean;
  } = { enabled: false },
) =>
  useQuery({
    queryKey: buildQueryKey(businessId, productId, language),
    queryFn: getProductByIdQuery,
    enabled,
  });

export const useGetProductsById = (businessId: BusinessId, productIds: string[]) =>
  useQueries({
    queries: productIds.map((id) => ({
      queryKey: buildQueryKey(businessId, id),
      queryFn: getProductByIdQuery,
    })),
  });
