import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { VenueConfigContext } from './configuration/VenueContextProvider';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import { setVenueDetails } from './redux';
import { CrmAuthenticationDetails, VenueDetails, VenueDetailsFields } from './types';
import { Table } from './domain/Table';
import { OrderStatus } from '../orders/types';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const useVenueRepository = () => {
  const { venueRepository } = useContext(VenueConfigContext);
  return venueRepository!;
};

export const useGetVenueDetails = () => {
  const venueRepository = useVenueRepository();
  const dispatch = useDispatch();
  return useAsyncFn<VenueDetails, [enrollmentId: EnrollmentId]>(
    async (enrollmentId) => {
      const venue = await venueRepository.getVenue(enrollmentId);
      dispatch(setVenueDetails(venue));
      return venue;
    },
    [venueRepository, dispatch],
  );
};

export const useUpdateVenueDetails = (enrollmentId: EnrollmentId) => {
  const venueRepository = useVenueRepository();
  const dispatch = useDispatch();
  return useAsyncFn<void, [update: Partial<VenueDetailsFields>]>(
    async (update) => {
      await venueRepository.updateVenue(enrollmentId, update);
    },
    [venueRepository, dispatch, enrollmentId],
  );
};

export const useStoreCrmAuthenticationDetails = (enrollmentId: EnrollmentId) => {
  const venueRepository = useVenueRepository();
  const dispatch = useDispatch();
  return useAsyncFn<void, [update: Partial<CrmAuthenticationDetails>]>(
    async (update) => {
      await venueRepository.storeCrmAuthentication(enrollmentId, update);
    },
    [venueRepository, dispatch, enrollmentId],
  );
};

export const useGetVenueTables = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn<Table[], [enrollmentId: EnrollmentId]>(
    async (enrollmentId: EnrollmentId) =>
      (await venueRepository.getVenueTables(enrollmentId)).sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      ),
    [venueRepository],
  );
};

export const useUpdateBoxOrderStatus = () => {
  const venueRepository = useVenueRepository();
  return useAsyncFn<void, [boxOrderId: string, boxOrderStatus: OrderStatus, reason: string]>(
    async (boxOrderId: string, boxOrderStatus: OrderStatus, reason: string) =>
      venueRepository.updateBoxOrderStatus(boxOrderId, boxOrderStatus, reason),
    [venueRepository],
  );
};

export const useGetPacingData = (boxId: string) => {
  const venueRepository = useVenueRepository();

  return useAsyncFn(async (boxIdParam) => venueRepository.getPacingData(boxIdParam), [venueRepository, boxId]);
};

export const useGetBoxStats = () => {
  const venueRepository = useVenueRepository();

  return (boxId: string) => venueRepository.getBoxStats(boxId);
};
