import React, { ChangeEvent, useCallback } from 'react';
import styled from 'styled-components';
import { StyledTextarea } from './Textarea.style';
import { borderRadius, colorPalette, getSpacing } from '../../../stylesheet';

interface Props {
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
}

const Container = styled.div`
  border: 1px solid ${colorPalette.lightBorderColor};
  border-radius: ${borderRadius.default};
  padding: ${getSpacing(1)};
  height: 140px;
`;

const Textarea: React.FC<Props> = ({ onValueChange, value, disabled = false }) => {
  const onTextareaChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onValueChange(event.currentTarget.value);
    },
    [onValueChange],
  );
  return (
    <Container>
      <StyledTextarea value={value} onChange={onTextareaChange} disabled={disabled} />
    </Container>
  );
};

export default Textarea;
