// eslint-disable-next-line @typescript-eslint/no-redeclare
import { CSS } from '@dnd-kit/utilities';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { HttpPaymentTerminalRepository } from '../../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { NeptingConfiguration, NeptingIlot } from '../../domain/PaymentTerminalRepository';
import { PlusIcon } from 'src/ordering/common/components/Icons';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Input,
  InputLabel,
  Stack,
  SwipeableDrawer,
  Switch,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';
import { useSortable } from '@dnd-kit/sortable';
import { ChevronRight, DragIndicator } from '@mui/icons-material';
import { IlotItemName } from './IlotItemName';
import IlotForm from './IlotForm';


const IlotsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};
`;

const IlotItemLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-radius: ${spaceUsages.largeSmall};
  padding: 8px;
  height: 125px;
  background-color: white;
`;

type IlotItemProps = {
  ilot: NeptingIlot;
  onClick: (id: string) => void;
};

const IlotItem = ({ ilot, onClick }: IlotItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: ilot.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
  };

  const onConfigurationClick = () => {
    onClick(ilot.id);
  };

  return (
    <IlotItemLineContainer
      ref={setNodeRef} {...attributes}
      style={style}
      onClick={onConfigurationClick}
    >
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        width={350}
      >
        <DragIndicator fontSize="large" {...listeners} style={{ cursor: 'grab', opacity: '0.5', marginRight: 16 }} />
        <IlotItemName name={ilot.name} />
        <ChevronRight fontSize="large" style={{ marginLeft: 1, opacity: 1 }} />
      </Box>
    </IlotItemLineContainer>
  );
};

type Props = {
  enrollmentId: EnrollmentId
};

export const NetptingConfigurationForm = ({ enrollmentId }: Props) => {
  const intl = useIntl();
  const repository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);
  const [configuration, setConfiguration] = useState<NeptingConfiguration>();
  const [neptingMerchantId, setNeptingMerchantId] = useState<string>();
  const [neptingUrl, setNeptingUrl] = useState<string>();
  const [softpos, setSoftpos] = useState<boolean>();

  const [loading, setLoading] = useState<boolean>(true);
  const [ilotDrawerOpen, setIlotDrawerOpen] = React.useState(false);
  //const [contractId, setContractId] = React.useState<string | undefined>(undefined);
  const [ilot, setIlot] = useState<NeptingIlot>({ id: '', name: '' });
  // @ts-ignore
  const envName = import.meta.env.VITE_ENV_NAME ?? window.sundayEnv.VITE_ENV_NAME;

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setIlotDrawerOpen(open);
      };

  async function refreshIlots() {
    const neptingConfiguration = await repository.getNeptingConfiguration(enrollmentId);
    setConfiguration(neptingConfiguration);
    setNeptingMerchantId(neptingConfiguration.merchantId);

    const webServiceUrl = neptingConfiguration.webServiceUrl;
    setNeptingUrl(webServiceUrl);
    setSoftpos(neptingConfiguration.softposEnabled);
    setLoading(false);
  }

  const updateIlot = (update: NeptingIlot, conf: NeptingConfiguration) : NeptingConfiguration => {
    const updatedIlot = configuration?.neptingIlots?.find((il) => il.id === update.id);
    const newIlots = updatedIlot === undefined
      ? conf?.neptingIlots?.concat(update)
      : conf?.neptingIlots?.map(il =>
        il.id === update.id ? { id: il.id, name: update.name } : il,
      );
    return { ...conf, neptingIlots: newIlots };
  };

  const deleteIlot = (ilotId: string, conf: NeptingConfiguration) : NeptingConfiguration => {
    const newIlots = conf?.neptingIlots?.filter((il) => il.id !== ilotId);
    return { ...conf, neptingIlots: newIlots };
  };

  const saveNeptingConfiguration = (neptingConfiguration: NeptingConfiguration) => {
    repository.saveNeptingConfiguration(enrollmentId, neptingConfiguration);
    setConfiguration(neptingConfiguration);
  };

  const updateNeptingConfiguration = () => {
    const updatedConfiguration: NeptingConfiguration = { ...configuration, merchantId: neptingMerchantId, webServiceUrl: neptingUrl, softposEnabled: softpos };
    saveNeptingConfiguration(updatedConfiguration);
  };

  const onEditIlot = (update: NeptingIlot) => {
    setIlotDrawerOpen(false);
    const updatedConfiguration = updateIlot(update, configuration!!);
    saveNeptingConfiguration(updatedConfiguration);
  };

  const onDeleteIlot = (contractId: string) => {
    setIlotDrawerOpen(false);
    const updatedConfiguration = deleteIlot(contractId, configuration!!);
    saveNeptingConfiguration(updatedConfiguration);
  };

  useEffect(() => {
    refreshIlots().catch(console.error);
  }, [enrollmentId]);

  useEffect(() => {
    if (envName === 'alpha' && (neptingUrl === '' || neptingUrl === undefined)) {
      setNeptingUrl('https://qualif.nepting.com/nepweb/ws?wsdl');
    }
  }, [neptingUrl]);

  const onOpenNewIlotClick = async () => {
    setIlot({ id: '', name: '' });
    setIlotDrawerOpen(true);
  };

  const onIlotClick = (id: string) => {
    const clickedContract = configuration?.neptingIlots?.find((il) => il.id === id);
    console.log('onContractClick', clickedContract);
    setIlot(clickedContract!!);
    setIlotDrawerOpen(true);
  };

  if (loading || !configuration) return <CircularProgress />;

  const onCompleted = async () => {
    setIlotDrawerOpen(false);
    await refreshIlots();
  };

  return <div>

    <Typography variant="h5" marginBottom="16px">
      {intl.formatMessage({ id: 'payment.terminal.nepting.configuration' })}
    </Typography>

    <Card sx={{ padding: '16px' }}>
      <CardContent>
        <Stack direction="column" justifyContent="space-between" spacing="16px " width="100%">
          <InputLabel>{intl.formatMessage({ id: 'payment.terminal.nepting.merchantId' })}</InputLabel>
          <Input
            type="text"
            value={neptingMerchantId}
            onChange={(event) => {
              setNeptingMerchantId(event.target.value);
            }}
          />
          <InputLabel>{intl.formatMessage({ id: 'payment.terminal.nepting.webServiceUrl' })}</InputLabel>
          <Input
            type="text"
            value={neptingUrl}
            onChange={(event) => {
              setNeptingUrl(event.target.value);
            }}
          />
          <InputLabel>{intl.formatMessage({ id: 'payment.terminal.nepting.softpos' })}</InputLabel>
          <Switch
            checked={softpos}
            onChange={(event) => {
              setSoftpos(event.target.checked);
            }}
          />
          <Button
            sx={{ marginLeft: 'auto', marginBottom: '16px' }}
            variant="contained"
            onClick={updateNeptingConfiguration}
          >
            <FormattedMessage id="payment.terminal.nepting.configuration.save" />
          </Button>

        </Stack>
      </CardContent>
    </Card>

    <Typography variant="h5" marginTop="16px">
      {intl.formatMessage({ id: 'payment.terminal.nepting.ilots' })}
    </Typography>

    <Button
      sx={{ marginLeft: 'auto', marginBottom: '16px' }}
      startIcon={<PlusIcon color="white" />}
      variant="contained"
      onClick={onOpenNewIlotClick}
    >
      <FormattedMessage id="payment.terminal.nepting.ilot.create" />
    </Button>

    <IlotsContainer>
      {configuration?.neptingIlots?.map((il) => {
        return <IlotItem key={il.id} ilot={il} onClick={onIlotClick} />;
      })}

      <SwipeableDrawer
        anchor={'right'}
        open={ilotDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <IlotForm onEditIlot={onEditIlot}
                  onDeleteIlot={onDeleteIlot}
                  ilot={ilot}
                  onCompleted={onCompleted} />
      </SwipeableDrawer>

    </IlotsContainer>
  </div>;
};
