import { useGetDispatchDetails } from 'src/tips/queries/useGetDispatchDetails';
import React, { FC } from 'react';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Divider, Modal, Stack, Typography, useTheme } from '@mui/material';
import { CrossIcon } from 'src/ordering/common/components/Icons';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PeriodDisplay } from 'src/tips/dispatch/components/PeriodDisplay';
import { useRenderMoney } from '@sundayapp/web-money';

type Props = {
  dispatchId: string;
  onClose: () => void;
};

export const DispatchDetailsModal: FC<Props> = ({ dispatchId, onClose }) => {
  const business = useCurrentBusinessOrThrow();
  const { data: dispatchDetails } = useGetDispatchDetails(business.id, dispatchId);
  const theme = useTheme();
  const renderMoney = useRenderMoney(true, false);

  if (!dispatchDetails) {
    return <></>;
  }

  return (
    <Modal open aria-labelledby="tips-dispatch-details-modal">
      <Stack
        sx={{ background: 'white', height: '100%', overflow: 'auto', paddingBottom: '1rem' }}
        flexDirection="column"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={'center'}
          padding="1rem 1.5rem 1rem 1.5rem"
          borderBottom={`1px solid ${theme.palette.divider}`}
        >
          <Stack sx={{ cursor: 'pointer' }} onClick={onClose}>
            <CrossIcon />
          </Stack>
          <Stack direction="column" alignItems={'center'} gap={0.5}>
            <Typography variant="h6" color="text.primary">
              <FormattedMessage id={'tips.sunday_pooling.dispatch.details.header.title'} />
            </Typography>
            <Typography color="text.secondary">
              <PeriodDisplay
                start={dispatchDetails.startInclusive}
                end={dispatchDetails.endExclusive}
                variant={'body2'}
              />
            </Typography>
          </Stack>
          <Stack alignItems={'flex-end'} />
        </Stack>

        <Stack
          sx={{ width: '100%', height: '100%', overflow: 'auto' }}
          padding="3rem 0 3rem 0"
          direction="column"
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Stack maxWidth={'50rem'} height="100%" spacing={3}>
            <Stack direction={'column'} gap={1}>
              <Typography variant="h6" color="text.primary">
                <FormattedMessage id={'tips.sunday_pooling.dispatch.details.total_amount'} />
              </Typography>

              <Typography variant="h4" color="text.primary">
                {renderMoney(dispatchDetails.amount)}
              </Typography>
            </Stack>

            <Divider />

            <Stack direction={'column'} gap={1}>
              {dispatchDetails.allocations.map((allocation) => (
                <Typography color="text.secondary" variant="body1" key={allocation.name}>
                  <FormattedMessage id="tips.sunday_pooling.dispatch.details.sent_to" values={{
                    amount: <Typography display="inline"
                                        color="text.primary">&nbsp;{renderMoney(allocation.amount)}&nbsp;</Typography>,
                    staffName: <Typography display="inline"
                                           color="text.primary">&nbsp;{allocation.name}&nbsp;</Typography>,
                    phoneNumber: <Typography display="inline"
                                             color="text.primary">&nbsp;{allocation.phoneNumber}&nbsp;</Typography>,
                  }} />
                </Typography>
              ))}
            </Stack>

            <Divider />

            <Typography variant="body1" color="text.secondary">
              <FormattedMessage id="tips.sunday_pooling.dispatch.details.dispatched_by" values={{
                by: <Typography display="inline" color="text.primary">&nbsp;sunday&nbsp;</Typography>,
                at: <Typography display="inline" color="text.primary">
                  &nbsp;{dispatchDetails.dispatchedAt.toLocaleString(undefined, {
                  dateStyle: 'short',
                  timeStyle: 'short',
                })}&nbsp;
                </Typography>,
              }} />
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};
