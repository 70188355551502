import * as React from 'react';
import { styled } from '@mui/material';
import { navigationDrawerItemKey, NavigationItem } from '../NavigationItems';
import { SubLevelMenuItem } from './SubLevelMenuItem';
import { SubLevelMenuGroup } from './SubLevelMenuGroup';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import LeftArrowSvg from '../../../component/icons/LeftArrowSvg';

type Props = {
  item: NavigationItem;
  closeSubLevelDrawer: () => void;
  onItemSelected: (item: NavigationItem) => void;
};

const SubLevelDrawerContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
`;


const SubLevelHeader = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px 0 20px 8px;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
`;

const SubLevelSections = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BackIconContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  color: #70707B;

  &:hover {
    background-color: ${() => PaletteTheme.menu.hover};
  }
`;

const buildItem = (menuItem: NavigationItem, onItemSelected: (item: NavigationItem) => void) => {
  const menuItemKey = navigationDrawerItemKey(menuItem);
  if (menuItem.subItems && menuItem.subItems?.length > 0) {

    return (
      <SubLevelMenuGroup
        key={menuItemKey}
        menuItem={menuItem}
        onItemSelected={onItemSelected}
      />
    );
  }

  return (
    <SubLevelMenuItem
      key={menuItemKey}
      menuItem={menuItem}
      onItemSelected={onItemSelected}
    />
  );
};

export const SubLevelDrawer = ({ item, closeSubLevelDrawer, onItemSelected }: Props) => {
  return (
    <SubLevelDrawerContainer>
      <SubLevelHeader onClick={closeSubLevelDrawer}>
        <BackIconContainer>
          <LeftArrowSvg />
        </BackIconContainer>
        <FormattedMessage id={item.text} />
      </SubLevelHeader>
      <SubLevelSections>
        {item.subLevelItems?.filter((value) => !value.hiddenInDrawer).map((menuItem) => buildItem(menuItem, onItemSelected))}
      </SubLevelSections>
    </SubLevelDrawerContainer>
  );

};
