import { useFormContext } from 'react-hook-form';
import { Box, FormControl, FormGroup, FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { Role } from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/Role';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const AuthorisedSignatoryExtraRoles = ({ representativeIndex }: { representativeIndex: number }) => {
  const business = useCurrentBusinessOrThrow();
  const countryCode = business.address.countryCode;
  const {
    formState: { errors },
  } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors?.representatives?.[representativeIndex]?.roles;
  const intl = useIntl();
  return (
    <FormControl component="fieldset">
      <Stack spacing={2}>
        <FormGroup>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Role
              role={'ubo'}
              representativeIndex={representativeIndex}
              label={intl.formatMessage({
                id: 'settings.payments.checkout.onboarding.representatives.authorisedSignatory.extraRole.ubo',
              })}
            />
            {countryCode == 'FR' && (
              <Role
                role={'legalRepresentative'}
                representativeIndex={representativeIndex}
                label={intl.formatMessage({
                  id: 'settings.payments.checkout.onboarding.representatives.authorisedSignatory.extraRole.legalRepresentative',
                })}
              />
            )}
            {countryCode == 'GB' && (
              <>
                <Role
                  role={'control'}
                  representativeIndex={representativeIndex}
                  label={intl.formatMessage({
                    id: 'settings.payments.checkout.onboarding.representatives.authorisedSignatory.extraRole.control',
                  })}
                />
                <Role
                  role={'director'}
                  representativeIndex={representativeIndex}
                  label={intl.formatMessage({
                    id: 'settings.payments.checkout.onboarding.representatives.authorisedSignatory.extraRole.director',
                  })}
                />
              </>
            )}
            {countryCode == 'US' && (
              <>
                <Role
                  role={'control'}
                  representativeIndex={representativeIndex}
                  label={intl.formatMessage({
                    id: 'settings.payments.checkout.onboarding.representatives.authorisedSignatory.extraRole.control',
                  })}
                />
              </>
            )}
          </Box>
        </FormGroup>
      </Stack>
      <FormHelperText error={!!error}>{!!error ? error?.message : ''}</FormHelperText>
    </FormControl>
  );
};
