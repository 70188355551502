import { z } from 'zod';
import { MoneyZ } from 'src/operations/pos-reconciliation/domain/MoneyZ';

export const InvoiceZ = z.object({
  id: z.string(),
  name: z.string(),
  date: z.number(),
  type: z.string(),
  category: z.string(),
  totalAmount: MoneyZ,
  taxAmount: MoneyZ,
});

export const InvoicesPerYearMonthZ = z.object( {
  yearMonth: z.string(),
  summary: z.object({
    totalAmount: MoneyZ,
    taxAmount: MoneyZ,
    numberOfInvoices: z.number(),
  }),
  invoices: z.array(InvoiceZ),
});

export const GroupOfInvoicesZ = z.object({
  invoicesPerYearMonth: z.array(InvoicesPerYearMonthZ),
  cateringInvoicesPerYearMonth: z.array(InvoicesPerYearMonthZ),
});

export type GroupOfInvoices = z.infer<typeof GroupOfInvoicesZ>;
export type InvoicesPerYearMonth = z.infer<typeof InvoicesPerYearMonthZ>;
export type Invoice = z.infer<typeof InvoiceZ>;

export const invoicesYears = (invoicesPerYearMonth: InvoicesPerYearMonth[]): string[] => {
  return invoicesPerYearMonth
    .map((item) => item.yearMonth.split('-')[0])
    .filter((elem, index, self) => index === self.indexOf(elem));
};

export const invoiceYear = (invoicePerYearMonth: InvoicesPerYearMonth): string => {
  return invoicePerYearMonth.yearMonth.split('-')[0];
};

export const invoiceMonth = (invoicePerYearMonth: InvoicesPerYearMonth): string => {
  return invoicePerYearMonth.yearMonth.split('-')[1];
};


