import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { useGetVenueBoxes } from '../../box';
import { FixedPanel } from '../../common/components/FixedPanel';
import { OpeningHoursPanel } from '../../common/components/OpeningHoursPanel';
import Spinner from '../../common/components/Spinner';
import { TimePeriod } from '../../common/domain/TimePeriod';
import { PromoCodesStyledCustomScroll } from '../pages/PromoCodesList/PromoCodesList.style';
import { areSamePromoCodes } from '../services/PromoCodeService';
import { PromoCode } from '../types';
import { PromoCodeBox } from './PromoCodeBox/PromoCodeBox';
import { PromoCodeCode } from './PromoCodeCode/PromoCodeCode';
import {
  PromoCodeBoxes,
  PromoCodeDateRange,
  PromoCodeDetail,
  PromoCodeDetailsContainer,
  PromoCodeDetailsLineContainer,
  PromoCodeDetailsLineContent,
  PromoCodeDetailsLineTitle,
  PromoCodeStyledDatePicker,
  PromoCodeTitle,
} from './PromoCodeDetails.style';
import { PromoCodeEditButtons } from './PromoCodeEditButtons/PromoCodeEditButtons';
import { PromoCodeRate } from './PromoCodeRate/PromoCodeRate';

interface Props {
  promoCode: PromoCode;
  enrollmentId: EnrollmentId;
  onPromoCodeUpdated: (promoCodeUpdated: PromoCode) => void;
  onPromoCodeDeleted: (voucherId: string) => void;
  closePanel: () => void;
  allPromoCodes: PromoCode[];
}

export const PromoCodeDetails: React.FC<Props> = ({
  promoCode,
  enrollmentId,
  onPromoCodeUpdated,
  onPromoCodeDeleted,
  closePanel,
  allPromoCodes,
}) => {
  const [currentPromoCode, setCurrentPromoCode] = useState<PromoCode>(promoCode);

  const setPromoCodeDateRange = useCallback(
    (startDate: Date, endDate: Date) => {
      setCurrentPromoCode({
        ...currentPromoCode,
        availableFromEpochMilli: startDate.getTime(),
        availableUntilEpochMilli: endDate.getTime(),
      });
    },
    [currentPromoCode, setCurrentPromoCode],
  );

  const { data: boxes } = useGetVenueBoxes(promoCode.enrollmentId);

  const valueChanged = areSamePromoCodes(currentPromoCode, promoCode);

  if (!promoCode) {
    return (
      <div>
        <PromoCodeDetailsContainer>
          <Spinner />
        </PromoCodeDetailsContainer>
      </div>
    );
  }
  return (
    <FixedPanel isOpened onClosed={closePanel}>
      <PromoCodesStyledCustomScroll>
        <PromoCodeDetailsContainer>
          <PromoCodeTitle>
            {promoCode.code ? (
              promoCode.code
            ) : (
              <FormattedMessage id="promoCodes.title.new" defaultMessage="new voucher code" />
            )}
          </PromoCodeTitle>
          <PromoCodeDetail>
            <PromoCodeCode
              promoCodeCode={currentPromoCode.code}
              voucherId={currentPromoCode.voucherId}
              allPromoCodes={allPromoCodes}
              setPromoCodeCode={(code: string) => setCurrentPromoCode({ ...currentPromoCode, code })}
            />
          </PromoCodeDetail>
          <PromoCodeDetail>
            <PromoCodeRate
              promoCodeRate={currentPromoCode.rate}
              setPromoCodeRate={(rate: number) => setCurrentPromoCode({ ...currentPromoCode, rate })}
            />
          </PromoCodeDetail>
          <PromoCodeDateRange>
            <PromoCodeDetailsLineContainer>
              <PromoCodeDetailsLineTitle>
                <FormattedMessage id="promoCode.startDateEndDateLabel" defaultMessage="start date - end date" />
              </PromoCodeDetailsLineTitle>
              <PromoCodeDetailsLineContent>
                <PromoCodeStyledDatePicker
                  value={[
                    new Date(currentPromoCode.availableFromEpochMilli),
                    new Date(currentPromoCode.availableUntilEpochMilli),
                  ]}
                  onChange={setPromoCodeDateRange}
                  type="range"
                  maxDate={new Date(2100, 12, 31)}
                />
              </PromoCodeDetailsLineContent>
            </PromoCodeDetailsLineContainer>
          </PromoCodeDateRange>
          {boxes && boxes.length > 1 && (
            <PromoCodeBoxes>
              <PromoCodeDetailsLineTitle>
                <FormattedMessage id="promoCode.concernedBoxes" defaultMessage="concerned boxes" />
              </PromoCodeDetailsLineTitle>
              <PromoCodeBox
                boxes={boxes}
                excludedBoxIds={currentPromoCode.excludedBoxIds}
                setExcludedBoxIds={(excludedBoxIds: string[]) =>
                  setCurrentPromoCode({ ...currentPromoCode, excludedBoxIds })}
              />
            </PromoCodeBoxes>
          )}
          <PromoCodeBoxes>
            <PromoCodeDetailsLineTitle>
              <FormattedMessage id="promoCode.availabilities" defaultMessage="availabilities" />
            </PromoCodeDetailsLineTitle>
            {currentPromoCode.availabilities && (
              <OpeningHoursPanel
                openingHours={currentPromoCode.availabilities}
                updateOpeningHours={(newAvailabilities: TimePeriod[]) => {
                  setCurrentPromoCode({ ...currentPromoCode, availabilities: newAvailabilities });
                }}
                customWarningMessage={(
                  <FormattedMessage
                    id="promocode.noOpeningDaysSoAlwaysOpen"
                    defaultMessage="no opening days have been selected, the voucher code is considered as always available"
                  />
                )}
              />
            )}
          </PromoCodeBoxes>
        </PromoCodeDetailsContainer>
        <PromoCodeEditButtons
          valueChanged={valueChanged}
          currentPromoCode={currentPromoCode}
          enrollmentId={enrollmentId}
          onPromoCodeUpdated={(updated) => {
            setCurrentPromoCode(updated);
            onPromoCodeUpdated(updated);
          }}
          onPromoCodeDeleted={onPromoCodeDeleted}
          closePanel={closePanel}
        />
      </PromoCodesStyledCustomScroll>
    </FixedPanel>
  );
};
