/* eslint-disable max-len */
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Button, colorUsage, spaceUsages } from '@sundayapp/b2b-react-component-library';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { getStringAsCountryCode } from 'src/utils/GetStringAsCountryCode';
import { Business } from 'src/business/domain/Business';
import { CountryCode } from 'src/domain/CountryCode';
import { ButtonContainer, LineWrapper, NotificationContainer } from '../AppSettings/AppSettings.styles';
import { useForm } from 'react-hook-form';
import { usePrompt } from 'src/app/component/Prompt/prompt';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useGetReceiptDisplaySettings } from 'src/payments/queries/getReceiptDisplaySettings';
import { useReceiptDisplaySettingsMutation } from 'src/payments/mutations/useReceiptDisplaySettingsMutation';
import { Alert, Card, CardContent, Switch, Typography } from '@mui/material';

interface ReceiptDisplaySettingsPanelsProps {
  business: Business;
}

interface ReceiptDisplaySettingsInputs {
  shouldForceCollapseTaxesOnReceipt: boolean;
}

export const ReceiptDisplaySettingsPanel = ({ business }: ReceiptDisplaySettingsPanelsProps) => {
  const intl = useIntl();
  const venueCountryCode = getStringAsCountryCode(business.address?.countryCode);
  const useSnackBar = useSnackbar();
  const { data: receiptDisplaySettings } = useGetReceiptDisplaySettings(business.id);
  const [receiptDisplaySettingsUpdateError, setReceiptDisplaySettingsUpdateError] = useState('');
  const mutation = useReceiptDisplaySettingsMutation(business.id);
  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { isValid, isSubmitting, isDirty },
  } = useForm<ReceiptDisplaySettingsInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });
  const watchShouldForceCollapseTaxesOnReceipt = watch('shouldForceCollapseTaxesOnReceipt');

  const onSubmit = async (inputs: ReceiptDisplaySettingsInputs) => {
    setReceiptDisplaySettingsUpdateError('');
    try {
      await mutation.mutateAsync(inputs);

      useSnackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
      reset(inputs, { keepDirty: false });
    } catch (e) {
      setReceiptDisplaySettingsUpdateError(intl.formatMessage({ id: 'settings.app_settings.error_saving' }));
    }
  };

  useEffect(() => {
    reset({
      shouldForceCollapseTaxesOnReceipt: receiptDisplaySettings?.shouldForceCollapseTaxesOnReceipt ?? false,
    });
  }, [business, receiptDisplaySettings]);

  usePrompt(isDirty, intl.formatMessage({ id: 'settings.confirm_leaving_tab' }));

  if (venueCountryCode !== CountryCode.US) {
    return <></>;
  }
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage id="settings.payments.receipt_display_settings"
                            defaultMessage={'Receipt display settings'} />
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <LineWrapper style={{ marginBottom: spaceUsages.mediumSmall }}>

            <Typography>
              <FormattedMessage
                id="settings.payments.receipt_display_settings.should_force_collapsing_taxes_on_receipt"
                defaultMessage={'Consolidate receipt taxes'}
              />
            </Typography>
            <Switch
              id="shouldForceCollapseTaxesOnReceipt"
              onChange={(e) => setValue('shouldForceCollapseTaxesOnReceipt', e.target.checked, { shouldDirty: true })}
              checked={watchShouldForceCollapseTaxesOnReceipt}
            />
          </LineWrapper>

          <Typography
            variant={'subtitle2'}
            color={colorUsage.tertiaryText}
          >
            <FormattedMessage
              id="settings.payments.receipt_display_settings.should_force_collapsing_taxes_on_receipt.description"
              defaultMessage={
                'enabling this will ensure that all taxes applied to the receipt from your POS such as alcohol, food, local city/state, and other taxes are combined into a single line item "taxes" on the receipt'
              }
            />
          </Typography>
          {receiptDisplaySettingsUpdateError && (
            <NotificationContainer>
              <Alert severity="error">{receiptDisplaySettingsUpdateError}</Alert>
            </NotificationContainer>
          )}
          {isValid && isDirty && (
            <ButtonContainer>
              <Button type="submit" variant="primary" size="xSmall" disabled={!isValid || isSubmitting || !isDirty}>
                <FormattedMessage id="settings.save_changes" />
              </Button>
            </ButtonContainer>
          )}
        </form>
      </CardContent>

    </Card>
  );
};
