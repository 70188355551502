import { MoneyView } from '@sundayapp/web-money';
import { FormattedMessage, FormattedTime } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@sundayapp/b2b-react-component-library';
import { Payment } from '../../domain/Payment';
import RefundStatusBadge from './RefundStatusBadge';

interface Props {
  payment: Payment,
}

function RefundsHistory({ payment }: Props) {
  return (
    <>
      {payment.refunds.length > 0 && (
        <Table size="small" width="100%">
          <TableHead>
            <TableRow>
              <TableHeader><FormattedMessage id="payments.refund.date" /></TableHeader>
              <TableHeader><FormattedMessage id="payments.refund.amount" /></TableHeader>
              <TableHeader><FormattedMessage id="payments.refund.status" /></TableHeader>
              <TableHeader><FormattedMessage id="payments.refund.reason" /></TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {payment.refunds.map((refund) => (
              <TableRow key={refund.id}>
                <TableCell>
                  <FormattedTime
                    value={new Date(refund.timestamp)}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                  />
                </TableCell>
                <TableCell><MoneyView value={refund.amount} /></TableCell>
                <TableCell><RefundStatusBadge refund={refund} /></TableCell>
                <TableCell>
                  <div style={{ wordBreak: 'break-all' }}>{refund.comment}</div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>

  );
}

export default RefundsHistory;
