// eslint-disable-next-line @typescript-eslint/no-redeclare
import { Button, Modal, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Stack } from '@mui/material';


type ConfigurationDeletionConfirmationModalProps = {
  configurationName: string;
  isOpened: boolean;
  onClose: () => void;
  numberOfTerminals: number;
  onConfirmation: () => void;
};

const ConfigurationDeletionConfirmationModal = ({
  configurationName,
  isOpened,
  onClose,
  numberOfTerminals,
  onConfirmation,
}: ConfigurationDeletionConfirmationModalProps) => {
  const intl = useIntl();

  if (numberOfTerminals === 0) {
    return (
      <Modal
        title={intl.formatMessage({ id: 'payment.terminal.settings.delete.confirmation' })}
        cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
        isOpened={isOpened}
        onClose={onClose}
      >
        <Text size="small">{configurationName}</Text>

        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          marginTop={spaceUsages.largeMedium}
          onClick={onConfirmation}
        >
          <FormattedMessage id="payment.terminal.manage.menu.delete" />
        </Button>
      </Modal>
    );
  } else {
    return (
      <Modal
        title=""
        cancelButtonMessage={intl.formatMessage({ id: 'modal.cancel' })}
        isOpened={isOpened}
        onClose={onClose}
      >
        <Stack direction="column" alignItems="center" gap={3}>
          <img src="/warning.svg" alt="forbidden" />
          <Text size="large"><FormattedMessage id="payment.terminal.manage.menu.delete.forbidden.count"
                                               values={{ 'count': numberOfTerminals }} /></Text>
          <Text size="medium"><FormattedMessage id="payment.terminal.manage.menu.delete.forbidden.explain" /></Text>
        </Stack>
      </Modal>
    );
  }
};

export default ConfigurationDeletionConfirmationModal;
