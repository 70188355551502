export type ReplyTemplateDraft = {
  name: string;
  content: string;
  applicableRatings: number[];
  prefilled: boolean;
};

export type ReplyTemplate = { id: string; } & ReplyTemplateDraft;

export const isApplicableTo5Stars = (template: ReplyTemplate) => template.applicableRatings.includes(5) || !template.applicableRatings;
