import { Box, TableBody, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { EmailCell, EmailTable } from '../EmailTable';
import { KpiString } from '../KpiString';
import { palette } from '../../design/palette';
import { dimensionsPx } from '../../design/dimensions';
import { BasketData } from '../../domain/TemplatesData';
import warning from '../../assets/venueKpi/organization/warning.png';

export const BasketSize = ({ basket, countryCode }: { basket: BasketData; countryCode: string }) => {
  const { formatMessage } = useIntl();

  const venuesWithWarnings = basket.venues
    .filter((v) => v.sharedOrdersAvailable && v.sharedOrdersAvailable < 10)
    .map((v) => v.name);
  const getWarningMessage = () =>
    (venuesWithWarnings.length > 0
      ? {
        message: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.basket_warning' }),
        imagePath: warning,
      }
      : undefined);

  return (
    <>
      <EmailTable
        legend={{
          title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.avg_basket_size' }),
          legends: [
            {
              imagePath: basket.lunchImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.lunch' }),
            },
            {
              imagePath: basket.dinnerImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.dinner' }),
            },
            {
              imagePath: basket.weekImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.weekdays' }),
            },
            {
              imagePath: basket.weekendImgPath,
              title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.weekend' }),
            },
          ],
        }}
        warningMessage={getWarningMessage()}
        headCellTitle={[
          { title: formatMessage({ id: 'venuekpi.weeklyreport.multi.email.basket_size' }), width: '40%' },
          { title: '' },
          { title: '' },
        ]}
      >
        <TableBody>
          {basket.venues.map((data, index) => (
            <TableRow key={data.name} style={{ breakInside: 'avoid' }}>
              <EmailCell>
                <>
                  <Typography color={palette.primary.electricPink}>{`${index + 1}`.padStart(2, '0')}</Typography>
                  <Typography color={palette.primary.black}>{data.name}</Typography>
                  {venuesWithWarnings.includes(data.name) && (
                    <img
                      src={warning}
                      height={dimensionsPx.section_separator.s}
                      style={{ alignSelf: 'center' }}
                      alt=""
                    />
                  )}
                </>
              </EmailCell>
              <EmailCell>
                <Box>
                  <KpiString
                    imagePath={basket.lunchImgPath}
                    value={data.basketSizeLunch}
                    fluctuation={data.basketSizeLunchFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                  <KpiString
                    imagePath={basket.dinnerImgPath}
                    value={data.basketSizeDinner}
                    fluctuation={data.basketSizeDinnerFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                </Box>
              </EmailCell>
              <EmailCell>
                <Box>
                  <KpiString
                    imagePath={basket.weekImgPath}
                    value={data.basketSizeWeek}
                    fluctuation={data.basketSizeWeekFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                  <KpiString
                    imagePath={basket.weekendImgPath}
                    value={data.basketSizeWeekend}
                    fluctuation={data.basketSizeWeekendFluctuation}
                    countryCode={countryCode}
                    color={palette.primary.black}
                    fluctuationType="%"
                  />
                </Box>
              </EmailCell>
            </TableRow>
          ))}
        </TableBody>
      </EmailTable>
    </>
  );
};
