import React, { useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FixedPanel, FixedPanelContainer } from '../../../common/components/FixedPanel';
import { colorPalette, getSpacing } from '../../../stylesheet';
import Input from '../../../common/components/Input';
import { useCreateBox } from '../../../box/hooks';
import { Box } from '../../../box/domain/Box';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type BoxCreationPanelProps = {
  enrollmentId: EnrollmentId;
  isOpened: boolean;
  onClosed: () => void;
  onBoxCreated: (box: Box) => void;
};

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: ${getSpacing(4)};
`;

const InputLabel = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey500};
  margin-bottom: ${getSpacing(1)};
`;

export const BoxCreationSidePanel = ({
  enrollmentId, isOpened, onClosed, onBoxCreated,
}: BoxCreationPanelProps) => {
  const [boxName, setBoxName] = useState<string>('');
  const [createBoxState, createBox] = useCreateBox();

  const onBoxCreation = () => {
    createBox(enrollmentId, boxName).then(onBoxCreated);
  };

  return (
    <FixedPanel isOpened={isOpened} onClosed={onClosed}>
      <FixedPanelContainer>
        <Title>
          <FormattedMessage id="foodcourt.box.creation.title" defaultMessage="create a new box" />
        </Title>

        <InputLabel>
          <FormattedMessage id="foodcourt.box.creation.label" defaultMessage="name of your box" />
        </InputLabel>
        <Input value={boxName} onValueChange={setBoxName} />

        <Button
          variant="primary"
          size="medium"
          fullWidth
          marginTop={getSpacing(2)}
          onClick={onBoxCreation}
          disabled={boxName.trim().length === 0 || createBoxState.loading}
        >
          <FormattedMessage id="foodcourt.box.creation.button" defaultMessage="create" />
        </Button>
      </FixedPanelContainer>
    </FixedPanel>
  );
};
