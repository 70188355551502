/**
 * Forces the browser to refresh the image by adding a timestamp to the URL.
 * @param imageUrl
 */
export const refreshedImageUrl = (imageUrl?: string) => {
  if (!imageUrl) return undefined;
  const url = new URL(imageUrl);
  url.searchParams.append('timestamp', Date.now().toString());
  return url.toString();
};
