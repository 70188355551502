import { useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

export const useShowReceiptErrorNotification = () => {
  const intl = useIntl();
  const snackbar = useSnackbar();

  return () => {
    snackbar.addNotification({
      variant: 'error',
      text: intl.formatMessage({
        id: 'orders.column.receipt.download.error',
        defaultMessage: 'Receipt is not longer available',
      }),
    });
  };
};
