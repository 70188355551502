export type BlobWithMetadata = {
  blob: Blob,
  contentDisposition?: string,
};

export const getFilename = (blobWithMetadata: BlobWithMetadata, defaultFilename: string): string => {
  if (!blobWithMetadata.contentDisposition) {
    return defaultFilename;
  }

  const regExp = /attachment; filename="(.*)"/;

  const regExpMatchArray = blobWithMetadata.contentDisposition.match(regExp);

  if (!regExpMatchArray) {
    return defaultFilename;
  }

  return regExpMatchArray[1];
};
