import * as React from 'react';
import { Box } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Product } from '../../domain/Product';
import { ProductPartnerPropertyField } from './components/ProductPartnerPropertyField';

type Props = {
  product: Product;
};

export const ProductPartnerInformation = ({ product }: Props) => {
  const intl = useIntl();
  return (
    <Box component="div" display="flex" flexDirection="column" width="100%" gap="4px" sx={{ opacity: 0.5 }}>
      <ProductPartnerPropertyField label="SKU" value={product.sku} />
      <ProductPartnerPropertyField
        label={intl.formatMessage({ id: 'menus.product.details.partner_name' })}
        value={product.partnerName}
      />
      <ProductPartnerPropertyField
        label={intl.formatMessage({ id: 'menus.product.details.partner_description' })}
        value={`${product.partnerDescription}`}
      />
    </Box>
  );
};
