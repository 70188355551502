import { IntlShape } from 'src/app/i18n/TypedIntl';
import { GridColDef, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { renderMoney } from '@sundayapp/web-money';
import { Box, Button, Chip, Typography } from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { gridMoneyComparator } from 'src/app/theme/DatagridTheme';
import { ProductSummary } from '../../domain/ProductSummary';
import { translationByProductType } from 'src/menu/dynamic-menu/domain/Product';
import { MenuAssetsSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';
import { MenuAssetSourceIcon } from 'src/menu/dynamic-menu/components/MenuAssetSourceIcon';

const MenuImg = styled('img')`
  width: 100%;
  height: 100%;
  border-radius: 8px;
`;
const ImgContainer = styled('div')`
  border-radius: 8px;
  background-color: #d9d9d9;
  width: 34px;
  height: 34px;
`;

const ProductName = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: auto;
  cursor: pointer;
`;

export const buildProductSelectionColumns = (
  intl: IntlShape,
  isSelected: (productId: string) => boolean,
  menuAssetsSources: MenuAssetsSources,
): GridColDef<ProductSummary>[] => {
  return [
    {
      field: 'product',
      headerName: '',
      disableColumnMenu: true,
      display: 'flex',
      flex: 3,
      sortComparator: gridStringOrNumberComparator,
      valueGetter: (_value, row) => row.name,
      renderCell: ({ row }) => (
        <ProductName>
          <ImgContainer>{row.pictureUrl && <MenuImg src={row.pictureUrl} />}</ImgContainer>
          <Typography>{row.name}</Typography>
        </ProductName>
      ),
    },
    {
      field: 'type',
      headerName: '',
      flex: 1.5,
      renderCell: ({ row }) => {
        return (
          <Box display={'flex'} gap={1} alignItems={'center'} height={'100%'}>
            <Chip color={'secondary'} label={<FormattedMessage id={translationByProductType[row.type].single} />} />
            <MenuAssetSourceIcon isSunday={row.source === 'SUNDAY'} menuAssetsSources={menuAssetsSources} posConnection={row.posConnection || null} />
          </Box>
        );
      },
    },
    {
      field: 'price',
      headerName: '',
      flex: 0.5,
      sortComparator: gridMoneyComparator,
      renderCell: ({ row }) => renderMoney(row.price, intl.locale),
    },
    {
      field: 'actions',
      headerName: '',
      display: 'flex',
      width: 150,
      renderCell: ({ row }) => (
        <Box display="flex" flexDirection="row" gap={2} alignItems="center" width="auto">
          {isSelected(row.id) ? (
            <Button size="small" variant="contained" endIcon={<CheckOutlinedIcon />}>
              <FormattedMessage id="menus.product.selection.button_added" defaultMessage="added" />
            </Button>
          ) : (
            <Button size="small" variant="outlined">
              <FormattedMessage id="menus.product.selection.button_add" defaultMessage="add" />
            </Button>
          )}
        </Box>
      ),
    },
  ];
};
