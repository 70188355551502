import { queryKeys } from 'src/app/queries/utils';
import { BusinessId } from 'src/business/domain/Business';
import { StaticMenu } from '../../domain/StaticMenus';
import { StaticMenuContext } from '../../context/MenuContextProvider';
import { useContext } from 'react';
import { StaticMenuRepository } from '../StaticMenuRepository';
import { useQuery } from '@tanstack/react-query';

const listStaticMenusQuery = (staticMenuRepository: StaticMenuRepository, businessId: BusinessId) => {
  return {
    queryKey: [queryKeys.staticMenus.LIST_MENUS, { businessId }],
    queryFn: async (): Promise<Array<StaticMenu>> => staticMenuRepository.listMenus(businessId),
  };
};

export const useListStaticMenus = (businessId: BusinessId) => {
  const { menuRepository } = useContext(StaticMenuContext);
  return useQuery(listStaticMenusQuery(menuRepository, businessId));
};
