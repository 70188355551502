import { Checkbox, Input, ListItemText, MenuItem, useTheme } from '@mui/material';
import { ApplicableRatingsSelect, ApplicableRatingsWrapper, MenuProps } from './ApplicableRatingsSelector.style';
import Star from '../../assets/star.svg?react';

interface Props {
  value: number[];
  onChange: (...event: any[]) => void;
  error: boolean;
}

export const ApplicableRatingsSelector = ({ value, onChange, error }: Props) => {
  const ratings = [ 5, 4, 3, 2, 1 ];
  const theme = useTheme();
  return (
    <ApplicableRatingsWrapper>
      <Star fill={theme.palette.primary.main} />
      <ApplicableRatingsSelect
        disableUnderline={!error}
        multiple
        value={value}
        onChange={onChange}
        input={<Input />}
        renderValue={(selected) => (selected as string[]).join(', ')}
        MenuProps={MenuProps}
        error={error}
      >
        {ratings.map((rating) => (
          <MenuItem key={rating} value={rating}>
            <Star key={rating} fill={theme.palette.primary.contrastText} />
            &nbsp;
            <ListItemText primary={rating} />
            <Checkbox checked={value.indexOf(rating) > -1} />
          </MenuItem>
        ))}
      </ApplicableRatingsSelect>
    </ApplicableRatingsWrapper>
  );
};
