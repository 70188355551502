import React from 'react';
import { SourceIcon } from 'src/menu/common/SourceIcon';
import { findMenuAssetsSourceNameFrom, MenuAssetsSources } from '../domain/MenuAssetsSource';

import { PosConnection } from 'src/menu/dynamic-menu/domain/PosConnection';

type MenuAssetSourceIconProps = {
  isSunday: boolean;
  posConnection: PosConnection | null;
  menuAssetsSources: MenuAssetsSources
};

const MenuAssetSourceIcon = ({ isSunday, posConnection, menuAssetsSources }: MenuAssetSourceIconProps) => {
  const menuAssetsSourceName = (posConnection && findMenuAssetsSourceNameFrom(menuAssetsSources, posConnection)) ?? '';
  return <SourceIcon isSunday={isSunday} name={menuAssetsSourceName} />;
};

export { MenuAssetSourceIcon };
