import { Menu, styled } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';
import { LoggedUser } from './LoggedUser';
import { UserMenuContent } from './UserMenuContent';

const Footer = styled('div')`
  display: flex;
  margin-top: 30px;
  min-height: 60px;
  flex-direction: column;
`;

const UserMenu = styled(Menu)({
  '& .MuiList-root': {
    padding: '0px',
  },

  '& .MuiPopover-paper': {
    borderRadius: '10px',
    boxShadow: undefined,
    width: '260px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '0px solid #000',
  },
});

const Anchor = styled('div')({
  position: 'absolute',
  bottom: 72,
});

export type NavigationMenuFooterProps = {
  compact: boolean;
};

export function NavigationMenuFooter({ compact }: NavigationMenuFooterProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    if (!compact) {
      setAnchorEl(anchorRef.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Footer>
      <Anchor ref={anchorRef} />
      <LoggedUser compact={compact} onClick={handleClick} />
      <UserMenu
        marginThreshold={0}
        elevation={3}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <UserMenuContent onClose={handleClose} />
      </UserMenu>
    </Footer>
  );
}
