import { Button, InputText, Modal, spaceUsages } from '@sundayapp/b2b-react-component-library';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Menu } from '../../../domain/Menu';
import { useCreateCategoryMutation } from '../../../mutations/menu/useCategoryMutation';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

export const CategoryCreationModalForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
`;

type CategoryCreationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  menu: Menu;
};

type CategoryCreationModalInputs = {
  name: string;
  description: string;
};

const CategoryCreationModal = ({
  isOpen, onClose, menu,
}: CategoryCreationModalProps) => {
  const businessId = useBusinessIdOrThrow();

  const { formatMessage } = useIntl();
  const createCategory = useCreateCategoryMutation(businessId, menu.id);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = useForm<CategoryCreationModalInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<CategoryCreationModalInputs> = async ({ name, description }) => {
    await createCategory.mutateAsync({ name, description });
    onClose();
  };

  const onCloseThenResetFields = async () => {
    onClose();
    reset({
      name: '',
      description: '',
    });
  };

  useEffect(() => {
    reset({
      name: '',
    });
  }, [isSubmitSuccessful]);

  return (
    <Modal
      title={formatMessage({ id: 'menus.edition.add_category' })}
      cancelButtonMessage={formatMessage({ id: 'menus.modal.edition.cancel' })}
      isOpened={isOpen}
      onClose={onCloseThenResetFields}
    >
      <CategoryCreationModalForm onSubmit={handleSubmit(onSubmit)}>
        <InputText
          label={formatMessage({ id: 'menus.edition.category_name' })}
          id="categoryName"
          maxLength={45}
          {...register('name', { required: formatMessage({ id: 'menus.category.creation.form.required' }) })}
        />
        <br />
        <InputText
          label={formatMessage({ id: 'menus.edition.category_description' })}
          id="categoryDescription"
          maxLength={800}
          {...register('description')}
        />
        <Button
          type="submit"
          variant="primary"
          size="large"
          fullWidth
          marginTop={spaceUsages.large}
          disabled={!isValid || isSubmitting}
          data-testid="confirm-category-creation"
        >
          <FormattedMessage id="menus.creation.form.submit" />
        </Button>
      </CategoryCreationModalForm>
    </Modal>
  );
};

export default CategoryCreationModal;
