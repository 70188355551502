import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { Chip, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { AllowedValue } from 'src/domain/venue/PosPaymentMethods';
import { FormValues } from './types';
import { getLabel } from './utils';

export const SelectPreferredValue = ({
  allowedValues,
  index,
  setValue,
  getValues,
  value,
}: {
  allowedValues: AllowedValue[],
  index: number,
  setValue: UseFormSetValue<FormValues>,
  getValues: UseFormGetValues<FormValues>,
  value: string,
}) => {
  const { formatMessage } = useIntl();
  const onChangePreferredValue = (e: SelectChangeEvent) => {
    const selected = e.target.value;
    if (selected == 'clear') {
      setValue(`paymentMethods.${index}.preferredValue`, '');
      setValue(`paymentMethods.${index}.mappedValues`, []);
    } else {
      setValue(`paymentMethods.${index}.preferredValue`, selected);
      const set: Set<string> = new Set(getValues(`paymentMethods.${index}.mappedValues`));
      set.add(selected);
      setValue(`paymentMethods.${index}.mappedValues`, Array.from(set));
    }
  };

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="preferredValue">
          <FormattedMessage id="settings.posPaymentMethod.primaryValue" />
        </InputLabel>
        <Select
          labelId="preferredValue"
          label={formatMessage({ id: 'settings.posPaymentMethod.primaryValue' })}
          renderValue={(selected) => (<Chip label={getLabel(selected, allowedValues)} />)}
          value={value}
          onChange={onChangePreferredValue}
          sx={{ minWidth: '130px' }}
        >
          <MenuItem key="clear" value="clear">{formatMessage({ id: 'settings.posPaymentMethod.select.clearSelection' })}</MenuItem>
          {allowedValues.map((method: AllowedValue) => (
            <MenuItem key={`preferredValue-${index}-${method.value}`} value={method.value}>
              {`${method.label} (${method.value})`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
