import {
  Box, LinearProgress, styled, Typography,
} from '@mui/material';
import React from 'react';
import { SundayPaymentsRate } from './StaffPerformanceViewModel';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 6,
  borderRadius: 0,
}));

export const SundayPaymentsRateColumn = ({ sundayPaymentsRate }: { sundayPaymentsRate: SundayPaymentsRate }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
    {sundayPaymentsRate?.rate
      && (
        <Box sx={{ minWidth: 60 }}>
          <BorderLinearProgress
            sx={{ marginRight: '8px', width: '80px' }}
            variant="determinate"
            value={sundayPaymentsRate.rate}
          />
        </Box>
      )}
    <Typography
      align="center"
      variant="body2"
    >
      {sundayPaymentsRate?.label ?? ''}
    </Typography>
  </Box>
);
