import { useCurrentUser } from 'src/auth/component/UserProvider';
import { useEffect } from 'react';
import { venuePath } from 'src/app/navigation/pathHelpers';
import { RouteDefinitions } from 'src/app/RouteDefinitions';
import { useNavigate } from 'react-router';

export const FinalizeCheckoutOnboardingRedirect = () => {
  const navigate = useNavigate();
  const { claims } = useCurrentUser();
  useEffect(() => {
    if (claims.length === 0) {
      navigate('/');
      return;
    }
    navigate(`${venuePath(claims[0].businessId)}${RouteDefinitions.PaymentsFinalizeCheckoutOnboarding.path}`);
  }, [navigate]);
  return null;
};
