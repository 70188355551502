import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../common/components/Button';
import Spinner from '../../../common/components/Spinner';
import { useAcceptOrder } from '../../hooks';
import { Order, OrderStatus } from '../../types';
import Tag from '../../../common/components/Tag';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { AcceptOrderDrawer } from './AcceptOrderDrawer';
import { AcceptationMode } from '../../repositories/OrderingOrderRepository';

interface Props {
  order: Order;
}

const OrderAcceptedTag = styled(Tag)`
  background-color: ${colorPalette.peach};
`;
const AcceptOrderMessage = styled.div`
  margin: ${getSpacing(2)} 0;
`;

export const AcceptOrderButton: React.FC<Props> = ({ order }) => {
  const [acceptState, acceptOrder] = useAcceptOrder(order.id);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const clickAccept = (a: AcceptationMode) => {
    acceptOrder(a).then(() => setDrawerVisible(false));
  };

  if (!order.paidBySunday) {
    if (order.status === OrderStatus.PLACED) {
      // order not yet accepted
      if (acceptState.loading) {
        return <Spinner />;
      }
      return (
        <>
          <AcceptOrderMessage>
            <FormattedMessage id="orders.acceptOrderInfo" defaultMessage="accept order to confirm payment with cash" />
          </AcceptOrderMessage>
          <Button variant={ButtonVariant.PRIMARY} onClick={() => setDrawerVisible(true)}>
            <FormattedMessage id="orders.acceptOrder" defaultMessage="accept order" />
          </Button>

          <AcceptOrderDrawer
            order={order}
            onAcceptOrder={clickAccept}
            onClose={() => setDrawerVisible(false)}
            visibility={drawerVisible}
          />
        </>
      );
    }

    if (order.status === OrderStatus.CANCELED || order.status === OrderStatus.CANCEL) {
      return (
        <OrderAcceptedTag>
          <FormattedMessage id="orders.canceled" defaultMessage="order canceled" />
        </OrderAcceptedTag>
      );
    }

    return (
      <OrderAcceptedTag>
        <FormattedMessage id="orders.accepted" defaultMessage="order accepted" />
      </OrderAcceptedTag>
    );
  }
  return null;
};
