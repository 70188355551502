import { Theme } from '@mui/material';

export const ToggleButtonGroupTheme = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) =>
      ({
        '& .Mui-selected': {
          backgroundColor: 'white !important',
          color: theme.palette.text.primary,
        },
        backgroundColor: 'transparent',
        color: theme.palette.text.secondary,
        '& .MuiToggleButtonGroup-grouped': {
          margin: 0,

        },
        '& .MuiToggleButtonGroup-grouped:first-of-type': {
          borderTopLeftRadius: '8px !important',
          borderBottomLeftRadius: '8px !important',
        },
        '& .MuiToggleButtonGroup-grouped:last-of-type': {
          borderTopRightRadius: '8px !important',
          borderBottomRightRadius: '8px !important',
        },
      }),
  },
};
