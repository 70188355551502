import React, { ReactNode } from 'react';
import { Badge, Typography } from '@mui/material';
import RightArrowSvg from 'src/app/component/icons/RightArrowSvg';
import { ButtonWrapper, IconButtonTitle, IconButtonWrapper, IconWrapper } from 'src/home2/shared/styles/Button/StyledLinkButton';

type LinkButtonProps = {
  title: string;
  icon: ReactNode;
  onClick: () => void;
  withBadge?: boolean;
};

export const LinkButton = ({ 
  title, 
  icon, 
  onClick, 
  withBadge = false, 
}: LinkButtonProps) => (
  <ButtonWrapper onClick={onClick}>
    <IconButtonTitle>
      <IconButtonWrapper>
        <Badge color="primary" badgeContent=" " variant="dot" invisible={!withBadge}>
          {icon}
        </Badge>
      </IconButtonWrapper>
      <Typography variant="body2">
        {title}
      </Typography>
    </IconButtonTitle>
    <IconWrapper>
      <RightArrowSvg size={24} />
    </IconWrapper>
  </ButtonWrapper>
);
