import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Box, Theme } from '@mui/material';
import { PanelMainData } from './components/PanelMainData';
import { Variation } from '../../../components/Variation';
import { PanelTitle } from './components/PanelTitle';
import { PanelFooter } from './components/PanelFooter';
import { GlobalKpis } from '../domain/EReputationKpis';
import { formatNumber } from '../../../components/formatNumbers';
import { formatGlobalKpiFooter } from './formatGlobalKpiFooter';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

const baseStyle = {
  cursor: 'pointer',
  padding: '1rem',
  borderRadius: '1rem',
};

const selectedStyle = (theme: Theme) => ({
  ...baseStyle,
  backgroundColor: 'white',
  boxShadow: `0 0.5rem 1.5rem ${theme.palette.secondary.light}`,

  '.chip': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
});

const notSelectedStyle = (theme: Theme) => ({
  ...baseStyle,
  border: '1px solid #D8D9DC',
  '&:hover': {
    backgroundColor: '#EDEDF2',
  },
  '&:hover .chip': {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
  },
  '.chip': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
});

type Props = {
  selectedKpiType: KpiName;
  initialKpiType: KpiName;
  globalKpis: GlobalKpis;
};
export const EReputationPanel = ({
  initialKpiType,
  selectedKpiType,
  globalKpis,
}: Props) => {
  const intl = useIntl();

  const title = () => {
    switch (initialKpiType) {
      case 'TOTAL_FIVE_STARS':
        return intl.formatMessage({ id: 'reviews.ereputation.kpi.name.five_stars' });
      case 'AVG_RATING':
        return intl.formatMessage({ id: 'reviews.ereputation.kpi.name.average_rating' });
      case 'TOTAL_REVIEWS':
        return intl.formatMessage({ id: 'reviews.ereputation.kpi.name.total_reviews' });
    }
  };

  const isSelected = () => selectedKpiType === initialKpiType;

  return (
    <Box
      alignItems="stretch"
      sx={isSelected() ? selectedStyle : notSelectedStyle}
    >
      <Variation differenceWithPreviousTimeframe={globalKpis.differenceWithPreviousTimeframe} />
      <PanelMainData value={formatNumber(globalKpis.value, 2)} />
      <PanelTitle title={title()} />
      <PanelFooter content={formatGlobalKpiFooter(initialKpiType, globalKpis)} kpiType={initialKpiType} />
    </Box>
  );
};
