import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { useQuery } from '@tanstack/react-query';
import { useDependencies } from 'src/tips/dispatch/useDependencies';
import { SundayPoolingRepository } from 'src/tips/dispatch/model/SundayPoolingRepository';
import { DoneTipsDispatch } from 'src/tips/dispatch/model/DoneTipsDispatch';

const getAllDoneTipsDispatchQuery = (
  businessId: BusinessId,
  from: Date,
  to: Date,
  sundayPoolingRepository: SundayPoolingRepository,
) => ({
  queryKey: [queryKeys.sundayTipsPooling.GET_DONE_TIPS_DISPATCH, { businessId, from, to }],
  queryFn: (): Promise<DoneTipsDispatch[]> => sundayPoolingRepository.getAllDoneTipsDispatch(businessId, from, to),
});

export const useGetAllDoneTipsDispatch = (businessId: BusinessId, from: Date, to: Date) => {
  const dependencies = useDependencies();
  return useQuery(getAllDoneTipsDispatchQuery(businessId, from, to, dependencies.sundayPoolingRepository));
};
