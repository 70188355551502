import { Box, Card, Typography } from '@mui/material';
import NoProducts from '../../../../../../../public/img/menu/product.png';
import { themeV5 } from 'src/app/theme/ThemeV5';
import React, { FC } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { MenuElementType } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/ProductsList';

export const NoProductCard: FC<{ productFamily: MenuElementType }> = ({ productFamily }) => (
  <Card sx={{ height: '386px' }}>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      {productFamily === 'PRODUCT' && (
        <>
          <img src={NoProducts} alt="No products" />
          <Typography variant={'h4'}>
            <FormattedMessage id={'menu.product.empty.header'} />
          </Typography>
          <Typography color={themeV5.palette.text.secondary} variant={'body1'}>
            <FormattedMessage id={'menu.product.empty.header.text'} />
          </Typography>
        </>
      )}

      {productFamily === 'MODIFIER' && (
        <>
          <img src={NoProducts} alt="No modifiers" />
          <Typography variant={'h4'}>
            <FormattedMessage id={'menu.modifier.empty.header'} />
          </Typography>
          <Typography
            textAlign={'center'}
            sx={{ wordWrap: 'break-word', maxWidth: '420px' }}
            color={themeV5.palette.text.secondary}
            variant={'body1'}
          >
            <FormattedMessage id={'menu.modifier.empty.header.text'} />
          </Typography>
        </>
      )}
    </Box>
  </Card>
);
