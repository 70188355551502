import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { aiSettingsQueryKey } from './AiSettingsQueryKey';
import { AiReplySettings } from './AiReplySettings';
import { HttpReviewAiSettingsRepository } from './HttpReviewAiSettingsRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

const repository = new HttpReviewAiSettingsRepository(ConfigurationLoader.load().venueFeedbackBaseUrl);

export const useAiSettingsMutation = () => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();

  return useMutation({
    mutationFn: (settings: AiReplySettings) => repository.save(business.id, settings),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: aiSettingsQueryKey(business.id) });
    },
  });
};
