import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, { useState } from 'react';
import { styleUploadButtonOnError } from 'src/payments/components/Checkout/UploadDocuments/styleUploadButtonOnError';
import { downloadCheckoutDocument, UploadPurpose, useUploadCheckoutDocument } from 'src/payments/mutations/useUploadCheckoutDocument';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';

export const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


export const UploadDocument = ({
  error,
  documentTitle,
  onFileUploaded,
  onDelete,
  onRegistered,
  purpose,
  helperText = '',
  documentId,
}: {
  documentId: string | undefined,
  error: FieldError | undefined,
  documentTitle: string,
  onDelete: () => void,
  onRegistered: UseFormRegisterReturn,
  onFileUploaded: (documentId: string) => void,
  purpose: UploadPurpose,
  helperText?: string
}) => {
  const businessId = useBusinessIdOrThrow();
  const user = useAuthenticatedUserOrThrow();
  const [href, setHref] = useState('');
  const { mutateAsync: uploadFile, isPending } = useUploadCheckoutDocument(businessId);
  const snackbar = useSnackbar();
  const fileUploaded = !!documentId;
  const theme = useTheme();
  const linkRef = React.useRef<HTMLAnchorElement>(null);

  const downloadFile = () => {
    // Only available for impersonated users
    console.log('Download file', user.impersonated, documentId);
    if (user.impersonated && documentId) {
      downloadCheckoutDocument(businessId, documentId).then((link) => {
        setHref(link);
        setTimeout(() => {
          linkRef?.current?.click();
        }, 1000);
      });
    }
  };

  return <FormControl>
    <Stack spacing={2} direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
      <Stack spacing={0.5} maxWidth={'50%'}>
        <Typography>{documentTitle}</Typography>
        <Typography variant={'body2'} color={theme.palette.text.secondary}><FormattedMessage
          id={'settings.payments.checkout.onboarding.upload_document.subtitle'} /></Typography>
      </Stack>
      <TextField
        style={{ width: '0%' }}
        fullWidth
        autoComplete={'off'}
        disabled
        InputProps={{ disableUnderline: true }}
        variant={'standard'}
        {...onRegistered}
        type="text" />
      {fileUploaded ?
        <Box display={'flex'} gap={2} alignItems={'flex-start'} flexDirection={'row'}>
          <Box display={'flex'} gap={1} alignItems={'center'} flexDirection={'row'}>
            <ArticleOutlinedIcon />
            <a ref={linkRef} href={href} target="_blank" rel="noreferrer" style={{ display: 'none' }}></a>
            <Typography maxWidth={'150px'} noWrap sx={{ textDecoration: 'underline' }} onClick={downloadFile}>{documentId}</Typography>
          </Box>
          <DeleteOutlineIcon
            onClick={onDelete}
            color={'error'}
            fontSize={'large'}
          />
        </Box> :
        <Button
          fullWidth
          disabled={isPending}
          style={{ ...styleUploadButtonOnError(!!error) }}
          component="label"
          color={'secondary'}
          variant="contained"
          tabIndex={-1}
          startIcon={isPending ? <CircularProgress /> : <UploadIcon />}
        >
          <FormattedMessage id={'settings.payments.checkout.onboarding.upload_document.button_title'} />
          <VisuallyHiddenInput
            autoComplete={'off'}
            onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
              const document = event.target.files?.[0];
              if (document) {
                try {
                  onFileUploaded(await uploadFile({ document, purpose }));
                } catch (e: string | unknown) {
                  const message = e as string;
                  snackbar.addNotification({
                    text: message ? message : 'Chargement du fichier a échoué',
                    variant: 'error',
                  });
                }
              }
            }}
            type="file"
            accept="image/png, image/jpeg,image/jpg, .pdf"
          />
        </Button>
      }
    </Stack>
    {!fileUploaded && <FormHelperText error={!!error}>{!!error ? error.message : ''}</FormHelperText>}
    <Typography variant={'body2'} color={theme.palette.text.secondary}>{helperText}</Typography>
  </FormControl>;
};
