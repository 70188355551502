import { CurrencyCode, differenceMoneys, Money, MoneyView, sumMoneys } from '@sundayapp/web-money';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import { PaymentSummary as PaymentSummaryType, Refund } from '../../../payment/types';
import { isPaymentSuccessful, isRefundSuccessful } from '../../../payment/utils/utils';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';
import { TotalLine } from '../TotalWithTaxSummary/TotalWithTaxSummary';

interface Props {
  payments: PaymentSummaryType[];
  currency: CurrencyCode;
  serviceChargeAmount?: Money;
  discount?: Money;
  orderTotal?: Money;
}

const PaymentSummaryContainer = styled.div`
  margin: 0 0 ${getSpacing(3)};
`;

const RefundLine = styled(TotalLine)`
  color: ${colorPalette.green};
`;

const TotalPaidLine = styled(TotalLine)`
  ${textStyles.big}
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${getSpacing(3)} 0 ${getSpacing(4)};
  color: black;
`;

const getPaymentAmount = (payment: PaymentSummaryType): Money =>
  (payment.totalAmount.amount === 0 ? payment.orderAmount : payment.totalAmount);
const getTipsAmount = (payment: PaymentSummaryType): Money => payment.tipsAmount;
const getUserFeeAmount = (payment: PaymentSummaryType): Money => payment.userFeeAmount;
const sumMoney = (acc: Money, value: Money): Money => sumMoneys(acc, value);
const getSuccessfulRefunds = (payment: PaymentSummaryType): Refund[] => payment.refunds.filter(isRefundSuccessful);
const concatRefunds = (acc: Refund[], refunds: Refund[]): Refund[] => acc.concat(refunds);
const getRefundedAmount = (refund: Refund): Money => refund.amount;

const PaymentSummary: React.FC<Props> = ({
  payments, currency, serviceChargeAmount, discount, orderTotal,
}) => {
  const getAmountOrZero = useCallback(
    (amount: Money) => {
      if (amount === undefined || amount === null) {
        return {
          amount: 0,
          currency,
        };
      }
      return amount;
    },
    [currency],
  );

  const successfulPayments = useMemo(() => payments.filter(isPaymentSuccessful), [payments]);
  const totalPaidAmount = useMemo(() => {
    const zeroAmount: Money = { amount: 0, currency };
    return successfulPayments.map(getPaymentAmount).reduce(sumMoney, zeroAmount);
  }, [successfulPayments, currency]);

  const totalTipsAmount = useMemo(() => {
    const zeroAmount: Money = { amount: 0, currency };
    return successfulPayments.map(getTipsAmount).reduce(sumMoney, zeroAmount);
  }, [successfulPayments, currency]);

  const totalUserFeeAmount = useMemo(() => {
    const zeroAmount: Money = { amount: 0, currency };
    return successfulPayments.map(getUserFeeAmount).map(getAmountOrZero).reduce(sumMoney, zeroAmount);
  }, [currency, successfulPayments, getAmountOrZero]);

  const discountAmount = useMemo(() => {
    const zeroAmount: Money = { amount: 0, currency };
    return discount ? differenceMoneys(zeroAmount, discount) : zeroAmount;
  }, [currency, discount]);
  const totalRefundedAmount = useMemo(
    () =>
      differenceMoneys(
        { amount: 0, currency },
        successfulPayments
          .map(getSuccessfulRefunds)
          .reduce(concatRefunds, [])
          .map(getRefundedAmount)
          .reduce(sumMoney, { amount: 0, currency }),
      ),
    [successfulPayments, currency],
  );
  const totalPaidWithRefunds = useMemo(
    () => sumMoneys(totalPaidAmount, totalRefundedAmount),
    [totalPaidAmount, totalRefundedAmount],
  );
  const leftToPay = useMemo(
    () => (orderTotal ? differenceMoneys(sumMoneys(orderTotal, discountAmount, totalTipsAmount, totalUserFeeAmount), totalPaidWithRefunds) : { amount: 0, currency }),
    [orderTotal, totalPaidWithRefunds, discountAmount, currency],
  );

  return (
    <PaymentSummaryContainer>
      {serviceChargeAmount && serviceChargeAmount.amount > 0 && (
        <TotalLine>
          <FormattedMessage id="orders.serviceCharge" defaultMessage="service charge" />
          <MoneyView value={serviceChargeAmount} />
        </TotalLine>
      )}
      {discountAmount.amount < 0 && (
        <RefundLine>
          <FormattedMessage
            id="orders.discount"
            defaultMessage="discount"
            description="label for discount line in order totals"
          />
          <MoneyView value={discountAmount} />
        </RefundLine>
      )}

      {totalUserFeeAmount.amount > 0 && (
        <TotalLine>
          <FormattedMessage id="orders.userFee" defaultMessage="digital fee" />
          <MoneyView value={totalUserFeeAmount} />
        </TotalLine>
      )}

      {totalTipsAmount.amount > 0 && (
        <TotalLine>
          <FormattedMessage id="orders.tips" defaultMessage="tips" />
          <MoneyView value={totalTipsAmount} />
        </TotalLine>
      )}
      {totalRefundedAmount.amount < 0 && (
        <>
          <TotalLine>
            <FormattedMessage id="orders.paid" defaultMessage="paid" />
            <MoneyView value={totalPaidAmount} />
          </TotalLine>
          <RefundLine>
            <FormattedMessage id="orders.refunded" defaultMessage="refunded" />
            <MoneyView value={totalRefundedAmount} />
          </RefundLine>
        </>
      )}
      <TotalPaidLine>
        <FormattedMessage id="orders.totalPaid" defaultMessage="total paid" />
        <MoneyView value={totalPaidWithRefunds} />
      </TotalPaidLine>

      {leftToPay.amount > 0 && totalRefundedAmount.amount === 0 && (
        <TotalLine>
          <FormattedMessage id="orders.leftToPay" defaultMessage="left to pay" />
          <MoneyView value={leftToPay} />
        </TotalLine>
      )}
    </PaymentSummaryContainer>
  );
};

export default PaymentSummary;
