import { z } from 'zod';
import { MoneyZ } from 'src/operations/pos-reconciliation/domain/MoneyZ';


export const DoneTipsDispatchZ = z.object({
  id: z.string(),
  amount: MoneyZ,
  startInclusive: z.coerce.date(),
  endExclusive: z.coerce.date(),
  dispatchedAt: z.coerce.date(),
  triggeredBy: z.string(),
});

export const DoneTipsDispatchZArray = z.array(DoneTipsDispatchZ);

export type DoneTipsDispatch = z.infer<typeof DoneTipsDispatchZ>;
