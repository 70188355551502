import { getCurrencySymbol, Money, money, moneyToUnit, MoneyView } from '@sundayapp/web-money';
import React, { useState } from 'react';
import { Box, styled } from '@mui/material';
import { CheckSvg, CrossSvg, IconButton, IconWrapper, InputText } from '@sundayapp/b2b-react-component-library';
import { useForm } from 'react-hook-form';
import { CashPayments } from '../../domain/PaymentData';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const AmountForm = styled('form')`
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 5px;
`;

export const EditableAmountCell = ({
  amount,
  editable,
  onSave,
// eslint-disable-next-line react/require-default-props
}: {
  amount?: Money,
  editable: boolean,
  onSave: (newAmount: Money, metadata?: CashPayments) => void
}) => {
  const { currency } = useCurrentBusinessOrThrow();
  const [ editMode, setEditMode ] = useState(false);
  const amountOrZero = amount ?? money(0, currency);

  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<{ value: number | undefined }>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const cancel = () => {
    setEditMode(false);
    reset({});
  };

  const viewComponent = (
    <>
      <MoneyView value={amountOrZero} />
      {editable && (
        <IconWrapper
          size="small"
          cursor="pointer"
          onClick={() => {
            setEditMode(true);
          }}
        >
          <img src="/edit-icon.svg" alt="edit" />
        </IconWrapper>
      )}
    </>
  );

  const onSubmitForm = handleSubmit((data) => {
    if (data.value === undefined) {
      cancel();
    } else {
      const newAmountValue = money(data.value * 100000, currency);
      setEditMode(false);
      reset({});
      onSave(newAmountValue);
    }
  });

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      cancel();
    }
  };

  const editComponent = (
    <>
      <AmountForm onSubmit={onSubmitForm}>
        <InputText
          onKeyDown={handleKeyDown}
          type="number"
          inputProps={{
            step: '0.00001',
            style: {
              width: '100px',
              textAlign: 'end',
              paddingRight: '5px',
            },
          }}
          placeholder={moneyToUnit(amountOrZero).toString()}
          {...register('value', { valueAsNumber: true })}
        />
        {getCurrencySymbol(currency)}

        <IconButton type="submit" disabled={!isValid} size="small">
          <CheckSvg />
        </IconButton>
        <IconButton type="reset" onClick={cancel} size="small">
          <CrossSvg />
        </IconButton>

      </AmountForm>
    </>
  );

  return (
    <Box sx={{
      display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 2,
    }}
    >
      {editMode ? editComponent : viewComponent}
    </Box>
  );
};
