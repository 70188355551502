import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Area } from '../../../domain/Area';
import Input from '../../../../common/components/Input';
import { colorPalette, getSpacing } from '../../../../stylesheet';
import { useUpdateAreaName } from '../../../hooks';
import { AreaDetailsGroup } from './AreaDetailsGroup';

type AreaDetailsNameSettingProps = {
  area: Area;
  setArea: (updatedArea: Area) => void;
};

const Container = styled.div`
  padding-bottom: ${getSpacing(1)};
`;

const ButtonLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const NameInput = styled(Input)`
  height: ${getSpacing(7)};
  margin-bottom: ${getSpacing(1)};

  input {
    font-size: 21px;
    line-height: 22px;
    padding-left: ${getSpacing(3)};
    padding-right: ${getSpacing(3)};
    color: ${colorPalette.defaultTextColor};
  }
`;

export const AreaDetailsNameSetting = ({ area, setArea }: AreaDetailsNameSettingProps) => {
  const intl = useIntl();
  const [areaName, setAreaName] = useState<string>(area.name);
  const [updateAreaStatus, updateAreaName] = useUpdateAreaName();

  const isValidName = (): boolean => areaName.trim().length > 0;

  const hasChanged = area.name.localeCompare(areaName) !== 0 && isValidName();

  const onNameUpdate = async () => {
    await updateAreaName(area, areaName);

    setArea({ ...area, name: areaName });
  };

  return (
    <AreaDetailsGroup
      title={intl.formatMessage({
        id: 'settings.area.details.name-group.title',
        defaultMessage: 'name of your area',
      })}
    >
      <Container>
        <NameInput onValueChange={setAreaName} value={areaName} />
        {hasChanged && (
          <ButtonLine>
            <Button variant="primary" size="xSmall" onClick={onNameUpdate} disabled={updateAreaStatus.loading}>
              <FormattedMessage id="settings.area.details.save-name-btn" defaultMessage="save" />
            </Button>
          </ButtonLine>
        )}
      </Container>
    </AreaDetailsGroup>
  );
};
