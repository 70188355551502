import { HomeTipsAwardWithPerformance } from './HomeTipsAwardWithPerformance';
import { Business } from 'src/business/domain/Business';

type HomeTipsAwardProps = {
  business: Business;
};

export const HomeTipsAward = ({ business }: HomeTipsAwardProps) => {
  const hasStaffPerformance = !(business.posReference === 'TRIVEC' || business.posReference === 'PI_ELECTRONIQUE');
  if (!hasStaffPerformance) {
    return <></>;
  }

  return <HomeTipsAwardWithPerformance businessId={business.id} currency={business.currency} timezone={business.timezone} />;
};
