import React, { createContext, ReactNode } from 'react';
import { OrderingNavigation } from './common/OrderingNavigation';

export interface OrderingRoutingConfig {
  routing: OrderingNavigation;
}

export const OrderingRoutingConfigContext = createContext<Partial<OrderingRoutingConfig>>({});

type ProviderConfig = OrderingRoutingConfig & {
  children: ReactNode;
};

const ContextProvider: React.FC<ProviderConfig> = ({ children, ...config }) => <OrderingRoutingConfigContext.Provider value={config}>{children}</OrderingRoutingConfigContext.Provider>;

export const OrderingRoutingContextProvider: React.FC<ProviderConfig> = ({ children, ...config }) => <ContextProvider routing={config.routing}>{children}</ContextProvider>;
