import axios from 'axios';
import { InvoicePort } from '../domain/InvoicePort';
import { GroupOfInvoicesZ } from '../domain/Invoice';
import { BusinessId } from 'src/business/domain/Business';

export const InvoiceHttpPort = (invoiceUrl: string): InvoicePort => ({
  getInvoices: (businessId) =>
    axios.get(`${invoiceUrl}/accounting/businesses/V2/${businessId}/invoices`, {
      headers: {
        accept: 'application/json',
      },
    }).then((response) => {
      const safeParse = GroupOfInvoicesZ.safeParse(response.data);
      if (safeParse.success) {
        return safeParse.data;
      }
      throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
    }),

  downloadAllInvoicesOfMonth: (businessId: BusinessId, year: number, month: number) =>
    axios.get<Blob>(`${invoiceUrl}/accounting/businesses/${businessId}/invoices/${year}/${month}`, {
      headers: {
        accept: 'application/octet-stream',
      },
      responseType: 'blob',
    }).then((response) => (new Blob([response.data], { type: 'application/zip' }))),

  getInvoicesForYear: (businessId, year) =>
    axios.get(`${invoiceUrl}/accounting/businesses/V2/${businessId}/invoices`, {
      headers: {
        accept: 'application/json',
      },
      params: { year },
    }).then((response) => {
      const safeParse = GroupOfInvoicesZ.safeParse(response.data);
      if (safeParse.success) {
        return safeParse.data;
      }
      throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
    }),

  getInvoice: (businessId, invoiceId) =>
    axios.get<Blob>(`${invoiceUrl}/accounting/businesses/V2/${businessId}/invoices/${invoiceId}`, {
      headers: {
        accept: 'application/octet-stream',
      },
      responseType: 'blob',
    }).then((response) => (new Blob([response.data], { type: 'application/pdf' }))),
});
