import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import { Alert, Box, Button, Stack, styled } from '@mui/material';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { Page } from 'src/home2/components/templates/PageTemplate';
import { shouldCheckChargebeePaymentMethodStatus } from 'src/payments/domain/Chargebee';
import { ampli } from 'src/ampli';
import { chargebeePaymentMethodStatus } from 'src/payments/infrastructure/BookkeepingRepositoryHttp';
import { useEnrollmentForPayment } from 'src/payments/infrastructure/useEnrollmentForPayment';
import { useGetChargebeePaymentMethodStatus } from 'src/payments/queries/getChargebeePaymentMethodStatus';
import { GoogleSetupStage } from 'src/sundayplus/reviews/settings/setup/google/domain/GoogleSetupStage';
import { useGoogleSetupStage } from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStage';
import navigationItems, { emptyDrawer, findItem } from '../navigation/navigationMenu/NavigationItems';
import { NavigationMenu } from '../navigation/navigationMenu/NavigationMenu';
import { settingsReviewsPath, venueSettingsPaymentsPath } from '../navigation/pathHelpers';
import useNavigationMenu from 'src/app/navigation/navigationMenu/useNavigationMenu';

interface Props {
  children: ReactNode;
}

const BannerContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('tablet')]: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
  marginTop: theme.spacing(1),
}));

export const MainLayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  maxWidth: '100%',
  flexGrow: 1,
  padding: theme.spacing(4),
})) as typeof Box;

const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const business = useCurrentBusinessOrThrow();
  const businessId = business.id;
  const intl = useIntl();
  const currentRoute = useLocation().pathname;
  const menuItems = useNavigationMenu();
  const navigate = useNavigate();
  const itemsForVenue = businessId ? navigationItems(businessId) : emptyDrawer;
  const currentItem = findItem(itemsForVenue.items, currentRoute);
  const titleKey = currentItem?.text;
  const badgeKey = currentItem?.badge;
  const { data: chargebeeStatus } = useGetChargebeePaymentMethodStatus(businessId);

  const showTitle = !currentItem?.hideTitle;
  const title = titleKey && intl.formatMessage({ id: titleKey });
  const googleAccountStage = useGoogleSetupStage(businessId);
  const { data: enrollmentForPayment } = useEnrollmentForPayment();
  const isInvalidChargebeePaymentMethod =
    chargebeeStatus === chargebeePaymentMethodStatus.INVALID ||
    chargebeeStatus === chargebeePaymentMethodStatus.NO_PAYMENT_METHOD ||
    chargebeeStatus === chargebeePaymentMethodStatus.EXPIRED ||
    chargebeeStatus === chargebeePaymentMethodStatus.UNKNOWN ||
    chargebeeStatus === chargebeePaymentMethodStatus.EXPIRING;
  document.title = title ?? 'sunday dashboard';

  const displayChargebeeBanner = shouldCheckChargebeePaymentMethodStatus(business) && isInvalidChargebeePaymentMethod;

  if (displayChargebeeBanner) {
    ampli.chargbeeBannerViewed({
      businessId: businessId,
    });
  }
  const onClickChargebeeBanner = () => {
    ampli.chargbeeBannerClicked({
      businessId: businessId,
    });
    navigate(venueSettingsPaymentsPath(businessId));
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {businessId && <NavigationMenu menuItems={menuItems} />}

        <MainLayoutContainer component="main">
          {currentItem && currentItem.banner && <BannerContainer>{currentItem?.banner}</BannerContainer>}
          <Page
            hasHeadTitle={{
              preLogoText: [
                {
                  id: titleKey,
                },
              ],
              postLogoText: [
                {
                  id: 'sunday',
                },
              ],
              variant: 'h5',
              hasBadge: !!currentItem?.badge,
              badgeLabel: badgeKey ? `${intl.formatMessage({ id: badgeKey })}` : '',
              showTitle: showTitle,
            }}
            noBanner={!currentItem?.badge}
          >
            {enrollmentForPayment && !enrollmentForPayment.paymentsEnabled && (
              <Alert severity="error" sx={{ marginBottom: '16px' }}>
                <FormattedMessage
                  id={'main.sunday_payment_disabled'}
                  defaultMessage="Payment by sunday has been suspended on your account we detected an issue.
                    Customer can still use sunday to view the menu but not to pay the bill."
                />
              </Alert>
            )}
            {displayChargebeeBanner && (
              <Alert
                severity="error"
                sx={{
                  marginBottom: '16px',
                  cursor: 'pointer',
                }}
                onClick={onClickChargebeeBanner}
              >
                <FormattedMessage id={'settings.payments.sunday_subscriptions.global_error'} />
              </Alert>
            )}
            {googleAccountStage.data?.status === GoogleSetupStage.Unhealthy && (
              <Alert severity="warning" sx={{ marginBottom: '16px' }}>
                <Stack direction="row" alignItems="center" gap={1}>
                  <FormattedMessage
                    id="venue.alert.unhealthy_google_account"
                    defaultMessage="The google account setup does not allow us to retrieve Google reviews for your restaurant."
                  />
                  <Button size="small" variant="outlined" onClick={() => navigate(settingsReviewsPath(businessId))}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <FormattedMessage id={'settings.reviews_and_loyalty.google_setup.fix_cta'} />
                      <HomeRepairServiceIcon />
                    </Stack>
                  </Button>
                </Stack>
              </Alert>
            )}
            {children}
          </Page>
        </MainLayoutContainer>
      </Box>
    </>
  );
};
export default MainLayout;
