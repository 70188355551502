import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { BoxDetails, PickupAddressType } from '../../../../domain/BoxDetails';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { SettingExpandablePanel } from 'src/ordering/common/components/Settings/SettingExpandablePanel';
import { SettingLine } from 'src/ordering/common/components/Settings/SettingLine';
import Input from '../../../../../common/components/Input';
import { useUpdateBoxDetails } from '../../../../hooks';
import { ClickAndCollectAddress } from './ClickAndCollectAddress';
import { VenueDetails } from 'src/ordering/venues/types';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type ClickAndCollectBoxSettingProps = {
  venue: VenueDetails;
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

type ClickAndCollectSettingValues = {
  enableClickAndCollect: boolean;
  minimumMinutesBeforeCollecting?: number;
  maximumDaysBeforeCollecting?: number;
  maxOrdersByTimeSlot?: number;
  preparationTime?: number;
  pickupAddress: PickupAddressType;
};

const emptyPickupAddress: PickupAddressType = {};

export const ClickAndCollectBoxSetting = ({ venue, box, setBox }: ClickAndCollectBoxSettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);
  const [expanded, setExpansion] = useState<boolean>(false);

  const currentSettings: ClickAndCollectSettingValues = {
    enableClickAndCollect: box.enableClickAndCollect ?? false,
    minimumMinutesBeforeCollecting: box.minimumMinutesBeforeCollecting ?? 0,
    maximumDaysBeforeCollecting: box.maximumDaysBeforeCollecting ?? 0,
    maxOrdersByTimeSlot: box.maxOrdersByTimeSlot ?? 0,
    preparationTime: box.preparationTime ?? 0,
    pickupAddress: box.pickupAddress ?? emptyPickupAddress,
  };

  const boxUpdated = async (values: ClickAndCollectSettingValues) => {
    makeUpdating(true);
    await updateBoxDetails({
      ...values,
    });
    setBox({
      ...box,
      ...values,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  const clickAndCollectToggled = async (value: boolean) => {
    await boxUpdated({ ...currentSettings, enableClickAndCollect: value });

    if (value) {
      setExpansion(true);
    }
  };

  const updateMinimumMinutesBeforeCollecting = async (value: string) => {
    if (!value) {
      await boxUpdated({ ...currentSettings, minimumMinutesBeforeCollecting: undefined });
      return;
    }

    const minutes = parseInt(value, 10);
    await boxUpdated({ ...currentSettings, minimumMinutesBeforeCollecting: minutes });
  };

  const updateMaximumDaysBeforeCollecting = async (value: string) => {
    if (!value) {
      await boxUpdated({ ...currentSettings, maximumDaysBeforeCollecting: undefined });
      return;
    }

    const days = parseInt(value, 10);
    await boxUpdated({ ...currentSettings, maximumDaysBeforeCollecting: days });
  };

  const updateMaxOrderPerTimeFrame = async (value: string) => {
    if (!value) {
      await boxUpdated({ ...currentSettings, maxOrdersByTimeSlot: undefined });
      return;
    }

    const slots = parseInt(value, 10);
    await boxUpdated({ ...currentSettings, maxOrdersByTimeSlot: slots });
  };

  const updatePreparationTime = async (value: string) => {
    if (!value) {
      await boxUpdated({ ...currentSettings, preparationTime: undefined });
      return;
    }

    const slots = parseInt(value, 10);
    await boxUpdated({ ...currentSettings, preparationTime: slots });
  };

  const updatePickupAddress = async (pickupAddress: PickupAddressType) => {
    await boxUpdated({ ...currentSettings, pickupAddress });
  };

  if (venue.isFoodCourt) {
    return <></>;
  }

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'box.settings.click-and-collect.title',
        defaultMessage: 'click and collect',
      })}
      description={intl.formatMessage({
        id: 'box.settings.click-and-collect.description',
        defaultMessage:
          'Active this option to enable click and collect. You need to create an area with the “Click & Collect” service type to have a working link/QR.',
        description: 'Click and collect settings description',
      })}
      toggleValue={box.enableClickAndCollect}
      toggleOnChange={clickAndCollectToggled}
      disabled={isUpdating}
    >
      <SettingExpandablePanel
        visible={currentSettings.enableClickAndCollect}
        expanded={expanded}
        expandChange={setExpansion}
      >
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.click-and-collect.modal.minimum-minutes-before-collecting.label',
            defaultMessage: 'minimum delay (in minutes) before first time slot',
          })}
          description={intl.formatMessage({
            id: 'venue.click-and-collect.modal.minimum-minutes-before-collecting.info',
            defaultMessage:
              'ensure that the customer will not be able to place an order to be collected before that delay. provide enough time to organise and prepare the orders.',
          })}
        >
          <Input
            type="number"
            value={currentSettings.minimumMinutesBeforeCollecting}
            onValueChange={updateMinimumMinutesBeforeCollecting}
            disabled={isUpdating}
            autoFocus
          />
        </SettingLine>
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.click-and-collect.modal.maximum-days-before-collecting.label',
            defaultMessage: 'maximum delay (in days) before last time slot',
          })}
          description={intl.formatMessage({
            id: 'venue.click-and-collect.modal.maximum-days-before-collecting.info',
            defaultMessage:
              'upper limit of the calendar of dates proposed to the customers.',
          })}
        >
          <Input
            type="number"
            value={currentSettings.maximumDaysBeforeCollecting}
            onValueChange={updateMaximumDaysBeforeCollecting}
            disabled={isUpdating}
            autoFocus
          />
        </SettingLine>
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.click-and-collect.modal.max-order-per-timeframe.label',
            defaultMessage: 'max number of orders per 15 minutes slot',
          })}
          description={intl.formatMessage({
            id: 'venue.click-and-collect.modal.max-order-per-timeframe.info',
            defaultMessage:
              'when a 15 minutes slot is full of orders, consumers will only be allowed to order in an other slot.',
          })}
        >
          <Input
            type="number"
            value={currentSettings.maxOrdersByTimeSlot}
            onValueChange={updateMaxOrderPerTimeFrame}
            disabled={isUpdating}
            autoFocus
          />
        </SettingLine>
        <SettingLine
          title={intl.formatMessage({
            id: 'venue.click-and-collect.modal.preparation-time.label',
            defaultMessage: 'preparation time in minutes',
          })}
          description={intl.formatMessage({
            id: 'venue.click-and-collect.modal.preparation-time.info',
            defaultMessage:
              'This will define when Sunday sent the order to your POS. If you set 20 minutes of preparation time and the pick up is for 1pm, we will send the order to your POS at 12:40pm.',
          })}
        >
          <Input
            type="number"
            value={currentSettings.preparationTime}
            onValueChange={updatePreparationTime}
            disabled={isUpdating}
          />
        </SettingLine>
        <ClickAndCollectAddress
          pickupAddress={currentSettings.pickupAddress}
          onPickupAddressChanged={updatePickupAddress}
          disabled={isUpdating}
        />
      </SettingExpandablePanel>
    </SettingPanel>
  );
};
