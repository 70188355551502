import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { StepRef } from 'src/initial-setup/components/steps/StepRef';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import GroupTableIcon from '@mui/icons-material/Queue';
import SingleTableIcon from '@mui/icons-material/AddBox';
import { StepProps } from 'src/initial-setup/components/steps/StepProps';
import DeviceGroupForm from 'src/initial-setup/components/steps/DeviceGroupForm';
import SingleDeviceForm from 'src/initial-setup/components/steps/SingleDeviceForm';
import { DeviceGroup } from 'src/initial-setup/domain/DeviceGroup';
import { fromSingleTableToRange } from 'src/initial-setup/domain/fromDeviceNamesToRanges';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { TableDeviceRepositoryHttp } from 'src/floorplan/infrastructure/TableDeviceRepositoryHttp';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { fromRangesToDeviceNames } from 'src/initial-setup/domain/fromRangesToDeviceNames';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const deviceLabel = ({ prefix, from, to }: DeviceGroup) => {
  if (from === undefined) return `${prefix}`;   // Example: DJ
  if (from === to) return `${prefix}${from}`;   // Example: B5, 1
  return `${prefix}${from} - ${prefix}${to}`;   // Example: B7 - B10, 1 - 12
};

const EnterYourTablesStep = forwardRef<StepRef, StepProps>(({ onValidChanged }, ref) => {

  const [deviceGroups, setDeviceGroups] = useState<DeviceGroup[]>([]);

  const [value, setValue] = useState(0);

  const snackBar = useSnackbar();

  useEffect(() => {
    onValidChanged(deviceGroups.length > 0);
  }, [deviceGroups]);

  const configuration = ConfigurationLoader.load();
  const deviceRepository = new TableDeviceRepositoryHttp(configuration.sundayDeviceManagerUrl);
  const businessId = useBusinessIdOrThrow();
  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    submit: () => {
      deviceRepository.createDevices(businessId, fromRangesToDeviceNames(deviceGroups))
        .catch(() => {
          snackBar.addNotification({
            variant: 'error',
            text: intl.formatMessage({ id: 'initial_setup.enter_tables.table_group.alert.error' }),
          });
        });
    },
  }));

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleDelete = (deviceGroup: DeviceGroup) => () => {
    setDeviceGroups((previous) => previous.filter((group) => group !== deviceGroup));
  };

  const addDeviceGroup = (deviceGroup: DeviceGroup) => setDeviceGroups([...deviceGroups, deviceGroup]);

  const addSingleTable = (tableNumber: string) => setDeviceGroups([...deviceGroups, fromSingleTableToRange(tableNumber)]);

  return (<Box m={2}>
    <Typography variant="body1" sx={{ marginTop: 2, marginBottom: 1, marginRight: 2 }}>
      <FormattedMessage id="initial_setup.enter_tables.total_tabel_number" />
      {' ' + deviceGroups.reduce((acc, { from, to }) => acc + ((to ?? 0) - (from ?? 0) + 1), 0)}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {deviceGroups.length === 0 &&
        <Chip
          color="default"
          label={<FormattedMessage id="initial_setup.enter_tables.no_table" />}
          sx={{ margin: 0.5 }}
        />
      }
      {deviceGroups.map((deviceGroup, index) => {
        return (
          <ListItem key={deviceLabel(deviceGroup)}>
            <Chip
              color={index % 2 === 0 ? 'default' : 'secondary'}
              label={deviceLabel(deviceGroup)}
              onDelete={handleDelete(deviceGroup)}
            />
          </ListItem>
        );
      })}
    </Box>

    <Tabs
      value={value}
      onChange={handleChange}
      variant="fullWidth"
      sx={{ paddingBottom: '16px' }}
    >
      <Tab icon={<GroupTableIcon />} iconPosition="start" label="Add group" />
      <Tab icon={<SingleTableIcon />} iconPosition="start" label="Add single table" />
    </Tabs>
    {value === 0 && <DeviceGroupForm addDeviceGroup={addDeviceGroup} deviceGroups={deviceGroups} />}
    {value === 1 && <SingleDeviceForm addSingleDevice={addSingleTable} deviceGroups={deviceGroups} />}
  </Box>);
});

EnterYourTablesStep.displayName = 'EnterYourTablesStep';

export default EnterYourTablesStep;
