import { useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { venueSundayPlusReviewsPath } from 'src/app/navigation/pathHelpers';
import { DateRange } from '../../domain/DateRange';
import { ampli } from 'src/ampli';
import { BusinessId } from 'src/business/domain/Business';
import MessageAlertSvg from '../../../../app/component/icons/MessageAlertSvg';
import { HomeCtaButton } from 'src/home/components/HomeCtaButton';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material';

export const RedirectToBadReviews = ({
  businessId,
  period,
  numberOfRatings,
  numberOfBadRatings,
}: {
  businessId: BusinessId;
  period: DateRange;
  numberOfRatings: number;
  numberOfBadRatings: number
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const timerange = (): string => {
    if (period.isYesterday()) return 'YESTERDAY';
    if (period.isLastSevenDays()) return 'LAST_7_DAYS';
    if (period.isLastThirtyDays()) return 'LAST_30_DAYS';
    return 'LAST_7_DAYS';
  };

  if (numberOfRatings < 1 || period.isCustom()) return <></>;

  const onClick = () => {
    ampli.fromGuestExperienceToFeedback({ timerange: timerange() });
    navigate(`${venueSundayPlusReviewsPath(businessId)}?timerange=${timerange()}&rating=BAD`);
  };

  return <HomeCtaButton
    icon={<MessageAlertSvg size={24} color={theme.palette.text.secondary} />}
    label={intl.formatMessage({ id: 'review.guest_experience.reviews' })}
    value={numberOfBadRatings.toString()}
    onClick={onClick}
  />;
};
