import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { InputText } from '@sundayapp/b2b-react-component-library';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import { DetailedProductAvailability } from '../../types';
import InfoPopover from '../../../common/components/InfoPopover';

interface Props {
  product: DetailedProductAvailability;
  setAvailability: (value?: number) => void;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface AvailabilityProps {
  outOfStock?: boolean;
}

const StyledInputText = styled(InputText)<AvailabilityProps>`
  input {
    text-align: right;
  }
  ${({ outOfStock }) =>
    outOfStock
    && css`
      input {
        color: #ee184b;
      }
    `}
`;

const NoGrow = styled.div`
  flex-grow: 0;
`;

const isValidPositiveNumber = (s: string) => {
  const numeric = Number(s.trim());
  // Allow empty to reset field
  return s.trim().length === 0 || (!Number.isNaN(numeric) && Number.isFinite(numeric) && numeric >= 0);
};

const productAvailabilityToString = (product: DetailedProductAvailability) =>
  (product.stock !== undefined && product.stock !== null ? String(product.stock) : '');

export const ProductSetAvailabilityInput: React.FC<Props> = ({ product, setAvailability }) => {
  const intl = useIntl();
  const [availabilityString, setAvailabilityString] = useState<string>(productAvailabilityToString(product));

  const debouncedUpdater = useMemo(
    () => debounce((val) => setAvailability(val === '' ? undefined : Number(val)), 800),
    [setAvailability],
  );

  useEffect(() => {
    setAvailabilityString(productAvailabilityToString(product));
  }, [product.stock]);

  return (
    <InputContainer>
      {availabilityString === '0' && (
        <NoGrow>
          <InfoPopover
            message={intl.formatMessage({
              id: 'product.availability.resetAtNight',
              defaultMessage:
                'This product is not being displayed in the menu. Stock is reset every day at 2am. Tomorrow it will be automatically set to ‘unlimited’.',
            })}
            color="#ee184b"
          />
        </NoGrow>
      )}
      <StyledInputText
        value={availabilityString}
        placeholder={intl.formatMessage({ id: 'product.availability.unlimited', defaultMessage: 'unlimited' })}
        outOfStock={availabilityString === '0'}
        onChange={(e: any) => {
          if (isValidPositiveNumber(e.target.value)) {
            setAvailabilityString(e.target.value);
            debouncedUpdater(e.target.value);
          }
        }}
      />
    </InputContainer>
  );
};
