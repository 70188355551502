import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import _ from 'lodash';
import { Box, Card, Typography } from '@mui/material';
import CategoryPanel from './CategoryPanel';
import { CategoriesWrapper } from '../DynamicMenuDetailsPage.styles';
import { Menu } from '../../../domain/Menu';
import { isProduct } from '../../../repositories/ProductDto';
import { isBlock } from '../../../repositories/block/BlockDto';
import { AddCategoryButton } from './AddCategoryButton';
import { SortCategoryButton } from './SortCategoryButton';
import { BusinessId } from 'src/business/domain/Business';

export type CategoriesProps = {
  menu: Menu;
  businessId: BusinessId;
};

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmptyCategoryPanel = styled(Card)`
  display: flex;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center;
`;

export const Categories = ({ menu, businessId }: CategoriesProps) => {
  const contextProductList = _.uniq(menu.categories.flatMap((c) => c.elements).filter((p) => isProduct(p.type)).map((p) => p.id));
  const contextBlockList = _.uniq(menu.categories.flatMap((c) => c.elements).filter((p) => isBlock(p.type)).map((p) => p.id));

  return (
    <>
      <CategoriesWrapper>
        <HeaderWrapper>
          <Typography variant="h5">Categories</Typography>
          {menu?.sundayMenu && (
          <Box display="flex" flexDirection="row">
            <AddCategoryButton menu={menu} />
            <SortCategoryButton businessId={businessId} menu={menu} />
          </Box>
          )}
        </HeaderWrapper>

        {menu.categories.length === 0
          ? (
            <EmptyCategoryPanel>
              <Typography variant="h6">
                <FormattedMessage id="menus.edition.categories.empty" defaultMessage="Start by adding a new category" />
              </Typography>
            </EmptyCategoryPanel>
          )
          : (
            <>
              {menu.categories.map((category, index) => (
                <div key={String(`${index}-${category.id}`)}>
                  <CategoryPanel
                    isEditable={menu.sundayMenu}
                    businessId={businessId}
                    menuId={menu.id}
                    category={category}
                    contextProductList={contextProductList}
                    contextBlockList={contextBlockList}
                  />
                </div>
              ))}
            </>
          )}

      </CategoriesWrapper>
    </>
  );
};
