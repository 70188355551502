import axios, { AxiosResponse } from 'axios';
import { TableDeviceRepository } from '../domain/TableDeviceRepository';
import { Table } from '../domain/Table';
import { TableDevice } from '../domain/TableDevice';
import { TablesResponse } from './TablesResponse';
import { TablesResponseMapper } from './TablesResponseMapper';
import { BusinessId } from 'src/business/domain/Business';
import { v4 as uuid } from 'uuid';

export class TableDeviceRepositoryHttp implements TableDeviceRepository {
  constructor(private sundayDeviceManagerUrl: string) {
  }

  async createDevices(businessId: BusinessId, devices: string[]): Promise<void> {
    await axios.put(
      `${this.sundayDeviceManagerUrl}/v1/${businessId}/sunday-devices`,
      { serviceType: 'PAY_AT_TABLE', labels: devices.map((t) => t) },
      {
        headers: {
          correlation_id: uuid(),
        },
      },
    );
  }

  async listTableDevices(businessId: BusinessId): Promise<TableDevice[]> {
    const response = await this.listTableDevicesAndTables(businessId);
    return TablesResponseMapper.toTableDevices(response.data);
  }

  async listTables(businessId: BusinessId): Promise<Table[]> {
    const response = await this.listTableDevicesAndTables(businessId);
    return TablesResponseMapper.toTables(response.data);
  }

  listTableDevicesAndTables(businessId: BusinessId): Promise<AxiosResponse<TablesResponse>> {
    return axios.get<TablesResponse>(`${this.sundayDeviceManagerUrl}/v1/businesses/${businessId}/tables`, {
      headers: {
        contentType: 'application/json',
      },
    });
  }

}
