import React, { FC, useState } from 'react';
import { Business } from 'src/business/domain/Business';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { ConfirmActionModal } from 'src/menu/dynamic-menu/pages/ProductsPage/components/ConfirmActionModal';
import AddIcon from '@mui/icons-material/Add';
import { useGetAllAllergensByBusinessId } from 'src/menu/dynamic-menu/queries/allergen/getAllAllergensByBusinessIdQuery';
import { useGetAllAllergenTranslationsByBusinessId } from 'src/menu/dynamic-menu/queries/allergen/getAllAllergenTranslationsByBusinessIdQuery';
import { AllergenTranslations } from 'src/menu/dynamic-menu/domain/AllergenTranslations';
import {
  useDeleteAllergenMutation,
  useEditAllergenMutation,
} from 'src/menu/dynamic-menu/mutations/allergen/useAllergenMutation';
import { useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import { useAllergenTranslationsMutation } from 'src/menu/dynamic-menu/mutations/allergen/useAllergenTranslationsMutation';
import { buildAllergensColumns } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/buildAllergensColumns';
import { EditAllergenModal } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/EditAllergenModal';
import { AllergenCreationModal } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/AllergenCreationModal';
import { NoAllergenOverlay } from 'src/menu/dynamic-menu/pages/ProductsPage/components/allergens/NoAllergenOverlay';

type Props = {
  business: Business;
};

export type AllergenAction = {
  allergen: AllergenTranslations;
  actionName: 'edit' | 'delete';
  targetName: AllergenTranslations['name'];
};

export const AllergensList: FC<Props> = ({ business }) => {
  const intl = useIntl();
  const snackBar = useSnackbar();
  const { data: menuBusiness } = useGetMenuBusiness(business.id);
  const { data: allergens, isLoading: areAllergensLoading } = useGetAllAllergensByBusinessId(business.id);
  const { data: translations, isLoading: areTranslationsLoading } = useGetAllAllergenTranslationsByBusinessId(
    business.id,
  );

  const [selectedAction, setSelectedAction] = useState<AllergenAction | undefined>(undefined);
  const [showCreateAllergenModal, setShowCreateAllergenModal] = useState(false);

  const showFeedbackError = (actionName: AllergenAction['actionName']) => {
    snackBar.addNotification({
      variant: 'error',
      text: intl.formatMessage({
        id: `menus.allergen.${actionName}.notification.error`,
      }),
    });
  };
  const showFeedbackSuccess = (actionName: AllergenAction['actionName']) => {
    snackBar.addNotification({
      variant: 'success',
      text: intl.formatMessage({
        id: `menus.allergen.${actionName}.notification.success`,
      }),
    });
  };

  const editAllergen = useEditAllergenMutation(business.id);
  const updateAllergenTranslations = useAllergenTranslationsMutation(business.id);
  const deleteAllergen = useDeleteAllergenMutation(business.id);

  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const onActionClicked = (allergen: AllergenTranslations, actionName: AllergenAction['actionName']) => {
    if (actionName === 'edit') {
      setShowEditModal(true);
    }
    setSelectedAction({
      allergen,
      actionName,
      targetName: allergen.name,
    });
  };

  const onActionConfirmed = (allergenAction: AllergenAction) => {
    const { allergen, actionName } = allergenAction;
    if (actionName !== 'delete') {
      return;
    }

    deleteAllergen
      .mutateAsync(allergen.id)
      .then(() => {
        showFeedbackSuccess(actionName);
      })
      .catch(() => {
        showFeedbackError(actionName);
      })
      .finally(() => setSelectedAction(undefined));
  };

  const handleClose = () => setSelectedAction(undefined);

  if (areAllergensLoading || areTranslationsLoading || !allergens || !translations) {
    return <CircularProgress />;
  }

  const supportedLanguages = menuBusiness?.translatedLanguages ?? [];
  const allergensTranslations = allergens.map((allergen) => ({
    ...allergen,
    translations: translations.filter((translation) => translation.allergenId === allergen.id),
  }));

  const onEditAllergenConfirmed = (allergen: {
    allergenId: string;
    allergenName: string;
    allergenTranslations: Record<string, string>;
  }) =>
    Promise.all([
      editAllergen.mutateAsync({ allergenId: allergen.allergenId, name: allergen.allergenName }),
      updateAllergenTranslations.mutateAsync({
        allergenId: allergen.allergenId,
        translations: allergen.allergenTranslations,
      }),
    ])
      .then(() => {
        showFeedbackSuccess('edit');
        setShowEditModal(false);
      })
      .catch(() => {
        showFeedbackError('edit');
      })
      .finally(() => setSelectedAction(undefined));

  return (
    <>
      <Box display="flex" gap={2} alignItems="center" justifyContent={'end'}>
        <Button
          startIcon={<AddIcon />}
          variant={'contained'}
          size="small"
          onClick={() => setShowCreateAllergenModal(true)}
        >
          <Typography variant={'body1'}>
            <FormattedMessage id="menus.allergens.new_allergen" defaultMessage="Add new allergen" />
          </Typography>
        </Button>
      </Box>

      <AllergenCreationModal
        business={business}
        isOpened={showCreateAllergenModal}
        onClose={() => setShowCreateAllergenModal(false)}
      />

      <DataGrid
        rows={allergensTranslations}
        columns={buildAllergensColumns(intl, supportedLanguages, onActionClicked)}
        pageSizeOptions={[]}
        rowHeight={70}
        pagination
        disableRowSelectionOnClick
        autoHeight
        getRowId={(row) => row.id}
        slots={{
          noRowsOverlay: NoAllergenOverlay,
        }}
      />

      {selectedAction?.actionName === 'delete' && (
        <ConfirmActionModal
          action={selectedAction}
          elementType="allergen"
          onActionConfirmed={(action) => onActionConfirmed(action)}
          onClose={handleClose}
        />
      )}

      {showEditModal && selectedAction?.actionName === 'edit' && (
        <EditAllergenModal
          allergen={selectedAction!.allergen}
          supportedLanguages={supportedLanguages}
          onActionConfirmed={(allergen) => onEditAllergenConfirmed(allergen)}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};
