export const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: '1rem',
  padding: '1.5rem',
};

export const styleButton = {
  width: '100%',
  justifyContent: 'center',
  fontSize: '1rem',
};
