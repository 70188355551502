import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ReconciliationReport } from '../domain/ReconciliationReport';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const reconciliationReportQuery = (businessId: BusinessId, reportId: string) => ({
  queryKey: [queryKeys.BUSINESSES, businessId, queryKeys.RECONCILIATION_REPORT, reportId],
  queryFn: async (): Promise<ReconciliationReport> => new ReconciliationRepositoryHttp().getReport(businessId, reportId),
});

export const useReport = (reportId: string) => {
  const business = useCurrentBusinessOrThrow();
  const query = reconciliationReportQuery(business.id, reportId);
  return useQuery({ queryKey: query.queryKey, queryFn: query.queryFn, enabled: !!business });
};
