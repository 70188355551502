import React, { useState } from 'react';
import { FormControl, InputLabel, Select, Stack, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CalendarTodayOutlined } from '@mui/icons-material';
import { defaultInvoiceFilters } from 'src/accounting/invoices/domain/InvoiceFilters';

export const PeriodFilter = ({ years, onPeriodChanged }:
{
  years: string[],
  onPeriodChanged: (year: string | undefined) => void
}) => {
  const intl = useIntl();
  const [selectedYear, setSelectedYear] = useState(defaultInvoiceFilters.year ? defaultInvoiceFilters.year : 'ALL');

  const handleChange = (year: string) => {
    setSelectedYear(year);
    onPeriodChanged(year === 'ALL' ? undefined : year);
  };

  const filterLabel = (year: string) => year === 'ALL' ? intl.formatMessage({
    id: 'accounting.invoice_tab.period_all',
    defaultMessage: 'All',
  }) : year;

  return (
    <>
      <FormControl>
        <InputLabel id="invoice-period-select-label">
          <FormattedMessage id={'accounting.invoice_tab.period'} defaultMessage={'Period'} />
        </InputLabel>
        <Select
          labelId="invoice-period-select-label"
          id="invoice-period-select"
          value={selectedYear}
          label={<FormattedMessage id={'accounting.invoice_tab.period'} defaultMessage={'Period'} />}
          onChange={event => handleChange(event.target.value)}
          sx={{ width: '8rem' }}
          renderValue={(year: string) => (
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              <Typography>{filterLabel(year)}</Typography>
              <CalendarTodayOutlined />
            </Stack>
          )}
        >
          <MenuItem key={'all'} value={'ALL'}>{filterLabel('ALL')}</MenuItem>
          {years.map((year) => <MenuItem key={year} value={year}><Typography>{year}</Typography></MenuItem>)}
        </Select>
      </FormControl>
    </>
  )
  ;
};
