import { useTheme } from '@mui/material';
import { createMoneyWithCurrency, useRenderMoney } from '@sundayapp/web-money';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { barChartData, barChartOptionsV2, BarGraphDefinition } from 'src/components/graph/bar/BarChartUtils';
import { sumDataLabels } from 'src/components/graph/bar/sumDataLabels';
import { VenueKpi } from 'src/home/domain/VenueKpi';
import { EReputationPageChoicesState } from 'src/store/e-reputation';
import { KpiTimeframeName } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

type Props = {
  venueKpi: VenueKpi;
  selectedTimeFrame: KpiTimeframeName;
};

const keys = [ 'otherRevenue', 'cashRevenue', 'pdqRevenue', 'qrCodeRevenue' ] as const;

type DataKey = (typeof keys)[number];

export const RevenuesChart = (revenueProps: Props) => {
  const theme = useTheme();
  const intl = useIntl();

  const business = useCurrentBusinessOrThrow();
  const renderMoney = useRenderMoney(true, true);

  const data: BarGraphDefinition<DataKey> = {
    keys: keys,

    dataTypes: {
      pdqRevenue: {
        label: 'PDQ',
        color: '#8E0B81',
      },
      qrCodeRevenue: {
        label: 'QR Code',
        color: theme.palette.primary.main,
      },
      cashRevenue: {
        label: 'Cash',
        color: theme.palette.grey[300],
      },
      otherRevenue: {
        label: 'Other',
        color: theme.palette.grey[500],
      },
    },

    data: {
      pdqRevenue: [ 181000, 138400, 201000, 127000 ],
      qrCodeRevenue: [ 100000, 122000, 113000, 154000 ],
      cashRevenue: [ 20200, 34000, 40000, 27000 ],
      otherRevenue: [ 8023, 9230, 7908, 3400 ],
    },
  };

  const pageStatus: EReputationPageChoicesState = {
    timeFrame: revenueProps.selectedTimeFrame,
    panel: 'TOTAL_REVIEWS',
  };

  const renderLabels = (value: number) => {
    return renderMoney(createMoneyWithCurrency(value, business.currency));
  };

  return (
    <Chart
      type="bar"
      options={barChartOptionsV2(renderLabels)}
      data={barChartData(pageStatus, data, intl)}
      plugins={[ ChartDataLabels, sumDataLabels(renderLabels) ]}
    />
  );
};
