import { BoxOrderSummary, OrderStatus } from '../../orders/types';
import { Button, CircularProgress, styled } from '@mui/material';
import React, { useState } from 'react';
import OrderStatusLabel from '../../orders/components/OrderStatusLabel/OrderStatusLabel';
import { breakpoints, isMobileScreen } from 'src/app/theme/ThemeV5';
import { colorPalette } from '../../stylesheet';
import { useUpdateBoxOrderStatus } from '../../venues/hook';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { canDoFinalize, canDoReadyForPickup } from './KdsBoxStatusUtil';


type KdsBoxOrderTableProps = {
  orders: BoxOrderSummary[];
  orderStatusUpdated: (orderId: string, updatedStatus: OrderStatus) => void;
};


const OrderTable = styled('table')`
  border-collapse: collapse;
  width: 100%;
`;

const TableRow = styled('tr')`
  width: 100%;
`;

const TableHeader = styled('th')`
  color: ${colorPalette.rhino};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 0 12px 0;
`;

const TableCell = styled('td')`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  text-align: center;
  padding: 12px;
  border-top: solid 1px ${colorPalette.rhino};

  @media (max-width: ${breakpoints.mobile}) {
    padding-right: 0;
  }
`;

const CellContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const getButtonSize = (): 'small' | 'medium' => {
  return isMobileScreen() ? 'small' : 'medium';
};

export const KdsBoxOrderTable = ({ orders, orderStatusUpdated }: KdsBoxOrderTableProps) => {
  const [updateBoxOrderStatusState, updateBoxOrderStatus] = useUpdateBoxOrderStatus();
  const [isProcessing, updateProcessingState] = useState<boolean>(false);

  const onPrepared = (boxOrders: BoxOrderSummary) => {
    updateProcessingState(true);
    updateBoxOrderStatus(boxOrders.id, OrderStatus.READY_FOR_PICKUP, 'automatic update from Sunday KDS')
      .then(() => {
        orderStatusUpdated(boxOrders.id, OrderStatus.READY_FOR_PICKUP);
      })
      .finally(() => {
        updateProcessingState(false);
      });
  };

  const onFinalized = (boxOrders: BoxOrderSummary) => {
    updateProcessingState(true);
    updateBoxOrderStatus(boxOrders.id, OrderStatus.FINALIZED, 'automatic update from Sunday KDS')
      .then(() => {
        orderStatusUpdated(boxOrders.id, OrderStatus.FINALIZED);
      })
      .finally(() => {
        updateProcessingState(false);
      });
  };

  return (
      <OrderTable>
        <TableRow>
          <TableHeader>
            <FormattedMessage id="kds.orders.number"/>
          </TableHeader>
          <TableHeader>
            <FormattedMessage id="kds.orders.status"/>
          </TableHeader>
          <TableHeader>
            <FormattedMessage id="kds.orders.actions"/>
          </TableHeader>
        </TableRow>
        {orders.map((order) => (
          <TableRow key={order.id}>
            <TableCell>
              {order.displayId}
            </TableCell>
            <TableCell>
              <CellContainer>
                <OrderStatusLabel status={order.status}/>
              </CellContainer>
            </TableCell>
            <TableCell>
              {updateBoxOrderStatusState.loading
                ? <CircularProgress size="large" />
                : (
                  <CellContainer>
                    {canDoReadyForPickup(order) && (
                      <Button variant="contained" size={getButtonSize()} onClick={() => onPrepared(order)} disabled={isProcessing}>
                        <FormattedMessage id="order.READY_FOR_PICKUP"/>
                      </Button>
                    )}
                    {canDoFinalize(order) && (
                      <Button variant="contained" size={getButtonSize()} onClick={() => onFinalized(order)} disabled={isProcessing}>
                        <FormattedMessage id="order.FINALIZED"/>
                      </Button>
                    )}
                  </CellContainer>
                )}
            </TableCell>
          </TableRow>
        ))}
      </OrderTable>
  );
};
