// eslint-disable-next-line @typescript-eslint/no-redeclare
import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Product, productTypes } from '../../../domain/Product';
import { useGetProductsById } from '../../../queries/product/getProductByIdQuery';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { BusinessId } from 'src/business/domain/Business';
import { EditProductsSelectionButton } from 'src/menu/dynamic-menu/components/products/EditProductsSelectionButton';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { ProductLinksBox } from 'src/menu/dynamic-menu/components/products/ProductLinksBox';
import { useUpdateVariantGroupMutation } from 'src/menu/dynamic-menu/mutations/variantGroup/useVariantGroupMutation';
import { isVariantGroupVariantError } from 'src/menu/dynamic-menu/repositories/VariantGroupRepository';

const HeaderActions = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

type Props = {
  businessId: BusinessId;
  product: Product;
};

export const VariantGroupVariantsSelection = ({ businessId, product }: Props) => {
  const intl = useIntl();
  const snackBar = useSnackbar();

  const [isProductSelectionVisible, setIsProductSelectionVisible] = useState(false);

  const updateVariantGroup = useUpdateVariantGroupMutation(businessId, product.id);
  const subProductQueries = useGetProductsById(businessId, product.subProductIds);

  const subProducts = subProductQueries
    .map(({ data }) => data)
    .filter((sbp): sbp is Product => !!sbp);
  const areSubProductsLoading = subProductQueries
    .map(({ isLoading }) => isLoading)
    .reduce((loading, current) => loading || current, false);

  const openProductSelection = () => {
    setIsProductSelectionVisible(true);
  };

  async function updateSubProducts(newProductIds: string[]) {
    await updateVariantGroup.mutateAsync({
      ...product,
      variants: newProductIds.map((id) => ({ id })),
    }, {
      onSuccess: () => {
        snackBar.addNotification({
          variant: 'success',
          text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
        });
        setIsProductSelectionVisible(false);
      },
      onError: (e) => {
        const variantGroupVariantError = isVariantGroupVariantError(e);
        snackBar.addNotification({
          variant: variantGroupVariantError ? 'warning' : 'error',
          text: intl.formatMessage({
            id: variantGroupVariantError
              ? 'menus.catalog.products.form.variant_group.variants.warning'
              : 'settings.app_settings.error_saving',
          }),
        });
      },
    });
  }

  return <ProductLinksBox
    products={subProducts || []}
    isLoading={areSubProductsLoading}
    businessId={businessId}
    filterSelectableProducts={(p) => p.type === productTypes.PRODUCT}
    onProductsSorted={updateSubProducts}
    onProductSelected={updateSubProducts}
    isSelectionVisible={isProductSelectionVisible}
    setIsSelectionVisible={setIsProductSelectionVisible}
    slots={{
      header: <>
        <Box display="flex" flexDirection="column">
          <Typography fontSize="large">
            <FormattedMessage id="menus.product.variant.header" />
          </Typography>
          <Typography fontSize="small" color={themeV5.palette.text.secondary}>
            <FormattedMessage id="menu.catalog.product.selection.variant_group.rule" />
          </Typography>
        </Box>
        <HeaderActions>
          <EditProductsSelectionButton onClick={openProductSelection} />
        </HeaderActions>
      </>,
      emptyListState: <FormattedMessage id="menu.catalog.product.selection.variants" />,
    }}
  />;
};

