import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { ReconciliationReport } from '../domain/ReconciliationReport';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { BusinessId } from 'src/business/domain/Business';

export const useFinalizeReconciliationReportMutation = (businessId: BusinessId, report: ReconciliationReport) => {
  const queryClient = useQueryClient();
  const reconciliationRepository = new ReconciliationRepositoryHttp();

  return useMutation({
    mutationFn: async (): Promise<void> => reconciliationRepository.finalizeReport(businessId, report.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKeys.BUSINESSES, businessId, queryKeys.RECONCILIATION_REPORT] });
    },
  });
};
