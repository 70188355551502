import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { language, SupportedLanguage } from 'src/app/i18n/I18nProvider';
import { MRT_Localization } from 'material-react-table';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_FR } from 'material-react-table/locales/fr';
import { MRT_Localization_PT } from 'material-react-table/locales/pt';
import { MRT_Localization_NL } from 'material-react-table/locales/nl';
import { MRT_Localization_IT } from 'material-react-table/locales/it';
import { MRT_Localization_DE } from 'material-react-table/locales/de';

const mrtLocales: Record<SupportedLanguage, MRT_Localization> = {
  en: MRT_Localization_EN,
  ca: MRT_Localization_EN,
  es: MRT_Localization_ES,
  fr: MRT_Localization_FR,
  pt: MRT_Localization_PT,
  nl: MRT_Localization_NL,
  it: MRT_Localization_IT,
  de: MRT_Localization_DE,
  uz: MRT_Localization_FR, // for testing purposes
};

export const getMrtLocale = (): MRT_Localization => mrtLocales[language()];
