import { useQuery } from '@tanstack/react-query';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpGuestExperienceRepository } from './HttpGuestExperienceRepository';
import { queryKeys } from 'src/app/queries/utils';
import { DateRange } from '../../domain/DateRange';
import { DimensionsAnalysis } from '../domain/DimensionsAnalysis';
import { BusinessId } from 'src/business/domain/Business';

export const dimensionsAnalysisQuery = (businessId: BusinessId, startingAt: Date, endingAt: Date) => ({
  queryKey: [
    queryKeys.REVIEWS_DIMENSIONS_ANALYSIS_IN_TIMERANGE,
    {
      businessId,
      startingAt,
      endingAt,
    },
  ],
  queryFn: (): Promise<DimensionsAnalysis> => {
    const configuration = ConfigurationLoader.load();
    const repository = new HttpGuestExperienceRepository(configuration.venueFeedbackBaseUrl);
    return repository.ratingDimensionsAnalysis(businessId, DateRange.fromDates(startingAt, endingAt));
  },
});

export const useDimensionsAnalysisInTimeRange = (businessId: BusinessId, startingAt: Date, endingAt: Date) =>
  useQuery(dimensionsAnalysisQuery(businessId, startingAt, endingAt));
