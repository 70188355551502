import React, { useCallback, useEffect, useState } from 'react';
import { useDocumentRepositoryFirebaseStorage } from 'src/infrastructure/document/DocumentRepositoryFirebaseStorage';
import { CropPictureData } from '../Cropping/CropTool';
import { CropperService } from 'src/domain/venue/CropperService';
import PictureContainer from './PictureContainer';

interface CustomizationPictureProps {
  venueId: string;
  imageRatio: number;
  title: string;
  imgUrl: string;
  filenameForSaving: string;
  allowTransparency?: boolean;
  onSaveCustomizationImageUrl: (imageUrl: string) => void;
  previewBackgroundColor: string | undefined;
}

const cropperService = new CropperService();

const CustomizationPicture = ({
  venueId,
  imageRatio,
  title,
  imgUrl,
  onSaveCustomizationImageUrl,
  filenameForSaving,
  allowTransparency,
  previewBackgroundColor,
}: CustomizationPictureProps) => {
  const [loading, setLoading] = useState(false);
  const documentRepositoryStorage = useDocumentRepositoryFirebaseStorage();
  const [tempPicture, setTempPicture] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const extension = allowTransparency ? 'png' : 'jpeg';
  const fileName = `${filenameForSaving}.${extension}`;
  useEffect(() => {
    setImageUrl(imgUrl);
  }, [imgUrl]);

  const uploadPicture = async (aVenueId: string, file: File) => {
    const pictureIsBiggerThan5mo = file.size / (1024 * 1024) > 5;
    if (pictureIsBiggerThan5mo) {
      throw new Error();
    }
    const savedPicture = await documentRepositoryStorage.saveFile(
      aVenueId,
      `customization/${fileName}`,
      file,
    );
    setTempPicture(savedPicture);
    setImageUrl(savedPicture);
    onSaveCustomizationImageUrl(savedPicture);
    return savedPicture;
  };
  const deletePicture = async () => {
    setLoading(true);
    if (imageUrl) {
      await documentRepositoryStorage.deleteRefFromUrl(imageUrl);
      setImageUrl('');
      onSaveCustomizationImageUrl('');
    }
    setLoading(false);
    return true;
  };

  const editPicture = useCallback(() => {
    if (imageUrl) {
      setTempPicture(imageUrl);
    }
  }, [imageUrl]);
  const removeTempPicture = () => {
    setTempPicture('');
  };
  const saveCroppedPicture = async (crop: CropPictureData) => {
    setLoading(true);
    setTempPicture('');

    const base64Image = cropperService.getCroppedPicture(crop, extension);
    const url = await documentRepositoryStorage.saveImage(
      venueId,
      'customization',
      fileName,
      base64Image,
    );
    setImageUrl(url);
    onSaveCustomizationImageUrl(url);
    setLoading(false);
  };

  return (
    <PictureContainer
      inputId={title}
      title={title}
      url={imageUrl || ''}
      onCloseCropTool={removeTempPicture}
      tempPicture={tempPicture}
      uploadFile={(file) => uploadPicture(venueId, file)}
      deletePicture={deletePicture}
      saveCroppedPicture={saveCroppedPicture}
      editPicture={editPicture}
      aspect={imageRatio}
      colorSelector={false}
      cropShape="rect"
      loading={loading}
      previewWidth={'142px'}
      previewHeight={142 / imageRatio + 'px'}
      previewBackgroundColor={previewBackgroundColor}
    />
  );
};

export default CustomizationPicture;
