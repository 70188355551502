import { HomeCardProps } from '@constants/HomeConstants';
import { useGetTipsContent } from '../hooks/useGetTipsContent';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { InfoCard } from '@molecules/Card/InfoCard';
import { useGetTrendValue } from '@hooks/useGetTrendValue';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const Tips = (tipsProps: HomeCardProps) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();

  const {
    totalTips,
    totalTipsLastWeek,
    totalTipsPaymentTerminal,
    totalTipsPaymentTerminalLastWeek,
    tipsPercentage,
    tipsPercentagePaymentTerminal,
  } =
    tipsProps.venueKpi;

  const {
    tipsTitle,
    tips,
    tipsTrend,
    subItems: tipsByProvenance,
  } = useGetTipsContent({
    countryCode: business.address.countryCode,
    totalTips,
    totalTipsLastWeek,
    totalTipsPaymentTerminal,
    totalTipsPaymentTerminalLastWeek,
    tipsPercentage,
    business: business,
    tipsPercentagePaymentTerminal,
  });

  const { trendValue } = useGetTrendValue({
    trend: tipsTrend,
    isPercentageTrend: true,
  });

  return (
    <InfoCard
      hasTitle
      title={tipsTitle}
      trend={tipsTrend}
      subTitle={tips}
      hasContent
      content={tipsByProvenance}
      buttonText={intl.formatMessage({ id: 'venue.home.tips.button' })}
      trendValue={trendValue}
    />
  );
};
