import DataAnalyticsIframeComponent from 'src/multi-locations/pages/data-analytics/DataAnalyticsIframeComponent';
import { language } from 'src/app/i18n/I18nProvider';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsOverview = () => {
  // useEffect(() => {
  //   ampli.dataAnalyticsOverviewClicked({
  //     businessId: business.id,
  //     businessName: business.name,
  //     clickDate: new Date().toISOString(),
  //   });
  // }, []);

  return (
    <DataAnalyticsIframeComponent
      dashboard={language() === 'fr' ? MetabaseDashboard.OVERVIEW_FRENCH : MetabaseDashboard.OVERVIEW_ENGLISH}
    />
  );
};
