import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { ReviewStar } from './ReviewStar';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const RatingNumberSkeleton = styled.div`
  height: 12px;
  width: 20px;
  background-color: #ededf2;
  border-radius: 4px;
`;

export const RatingSkeleton = () => {
  return (
    <>
      <Container>
        <Tooltip placement="top" arrow title="">
          <ReviewStar style={{ color: '#EDEDF2' }} />
        </Tooltip>
        <Tooltip placement="top" arrow title="">
          <RatingNumberSkeleton />
        </Tooltip>
      </Container>
    </>
  );
};
