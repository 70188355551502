import React, { useMemo } from 'react';
import { Badge } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Refund, RefundStatuses } from '../../domain/Payment';

interface Props {
  refund: Refund,
}

const RefundStatusBadge: React.FC<Props> = ({ refund }: Props) => {
  type RefundStatusColor = { [n in RefundStatuses] : string };

  const badgeVariant: RefundStatusColor = {
    unspecified: 'grey',
    pending: 'grey',
    succeeded: 'green',
    failed: 'red',
  };

  const status = useMemo(() => refund.status, [refund]);

  return (
    <Badge size="large" variant={badgeVariant[status]}>
      <FormattedMessage id={`payments.status.${status}`} />
    </Badge>
  );
};

export default RefundStatusBadge;
