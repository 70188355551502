import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { MenuItemContent } from './NavigationMenuItem';
import { NavigationMenuItemIcon } from './NavigationMenuItemIcon';
import { LocalisationKey } from 'src/lang/en';

type NavigationExternalLinkProps = {
  isMobile: boolean;
  isCompacted: boolean;
  currentIcon: ReactNode;
  translatedMessageKey: LocalisationKey;
  externalLink: string;
  beforeSubmit?: () => void;
};

const Container = styled('div')`
  cursor: pointer;
`;

export const NavigationExternalLink = ({
  isMobile,
  currentIcon,
  translatedMessageKey,
  isCompacted,
  externalLink,
  beforeSubmit,
}: NavigationExternalLinkProps) => {
  const onClick = () => {
    if (beforeSubmit) {
      beforeSubmit();
    }
    window.open(externalLink, '_blank');
  };

  if (isCompacted) {
    return <NavigationMenuItemIcon icon={currentIcon} />;
  }

  return (
    <Container onClick={onClick}>
      <MenuItemContent
        selected={false}
        rootItem
        isGeneralItem
        isMobile={isMobile}
        disabled={false}
        currentIcon={currentIcon}
        translatedMessageKey={translatedMessageKey}
      />
    </Container>
  );
};
