import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';
import { HttpSharedDeviceRepository } from '../infrastructure/HttpSharedDeviceRepository';

export const useListSharedDevices = (businessId: string) => {
  const configuration = ConfigurationLoader.load();
  const userRepository = new HttpSharedDeviceRepository(configuration.waiterAppBackendUrl);

  return useQuery({
    queryKey: [queryKeys.waiters.LIST_SHARED_DEVICES, { businessId }],
    queryFn: () => userRepository.listSharedDevices(businessId),
  });
};
