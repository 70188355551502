import { Money, MoneyView } from '@sundayapp/web-money';
import React from 'react';
import { EmptyTableCellValue } from './EmptyTableCellValue';

interface Props {
  amount: [initialAmount: Money, remainingAmount: Money]
}

const PaymentAmount: React.FC<Props> = ({ amount }: Props) => {
  const [initialAmount, remainingAmount] = amount;
  const noMoneyWasRefunded = (initialAmount.amount - remainingAmount.amount) === 0;
  const hasBeenFullyRefunded = remainingAmount.amount === 0;
  const hasBeenPartiallyRefunded = !noMoneyWasRefunded && !hasBeenFullyRefunded;
  if (initialAmount.amount === 0) {
    return <EmptyTableCellValue />;
  }

  return (
    <>
      {noMoneyWasRefunded && (
        <span>
          <MoneyView value={initialAmount} />
        </span>
      )}

      {hasBeenFullyRefunded && (
        <span style={{ textDecorationLine: 'line-through' }}>
          <MoneyView value={initialAmount} />
        </span>
      )}

      {hasBeenPartiallyRefunded && (
        <>
          <span>
            <MoneyView value={remainingAmount} />
          </span>
          <span style={{ textDecorationLine: 'line-through', color: 'grey', paddingLeft: '3px' }}>
            <MoneyView value={initialAmount} />
          </span>
        </>
      )}
    </>
  );
};

export default PaymentAmount;
