import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '../../box/domain/Box';
import { useGetOrders } from '../../orders/hooks';
import { BoxOrderSummary, OrderStatus, OrderSummary } from '../../orders/types';
import { KdsBoxOrderTable } from './KdsBoxOrderTable';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { displayableBoxOrder } from './KdsBoxStatusUtil';
import { BoxTitle, Container, NoOrderYetPanel } from './KdsContainer';
import { EnrollmentId } from 'src/business/domain/Enrollment';

type KdsBoxComponentProps = {
  enrollmentId: EnrollmentId;
  box: Box;
  displayTitle: boolean;
};

const REFRESH_TIME_IN_MILLI = 10000;   // 10 seconds

const FourHoursAgo = () => {
  return new Date(Date.now() - 4 * 60 * 60 * 1000);
};




export const KdsBoxComponent = ({ enrollmentId, box, displayTitle }: KdsBoxComponentProps) => {
  const [, getOrders ] = useGetOrders(enrollmentId, box.id);

  const [boxOrders, updateBoxOrders] = useState<BoxOrderSummary[]>([]);

  const filterAndUpdateOrders = useCallback((allOrders: OrderSummary[]) => {
    updateBoxOrders(allOrders
      .flatMap((order) => order.boxOrders)
      .filter((boxOrder) => boxOrder.boxId === box.id)
      .filter(displayableBoxOrder),
    );
  }, []);

  useEffect(() => {
    getOrders(FourHoursAgo(), new Date())
      .then(filterAndUpdateOrders);

    const intervalId = setInterval(() => {
      getOrders(FourHoursAgo(), new Date())
        .then(filterAndUpdateOrders);
    }, REFRESH_TIME_IN_MILLI);

    return () => {
      clearInterval(intervalId);
    };

  }, [enrollmentId, box, getOrders, filterAndUpdateOrders]);

  const orderStatusUpdated = (boxOrderId: string, updatedStatus: OrderStatus) => {
    updateBoxOrders(boxOrders.map((boxOrder) => {
      if (boxOrder.id === boxOrderId) {
        return {
          ...boxOrder,
          status: updatedStatus,
        };
      }

      return boxOrder;
    }));
  };

  return (
    <Container>
      {displayTitle && (<BoxTitle>
        {box.name}
      </BoxTitle>)}

      {boxOrders.length === 0
        ? (<NoOrderYetPanel>
          <FormattedMessage id="kds.no-orders-yet" defaultMessage="No orders yet"/>
        </NoOrderYetPanel>)
        : <KdsBoxOrderTable orders={boxOrders} orderStatusUpdated={orderStatusUpdated}/>
      }
    </Container>
  );
};
