import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Typography } from '@mui/material';
import { MenuCreationModal } from './MenuCreationModal';
import { MenuToolBox } from './MenuToolBox';
import PlusSvg from '../../../../../app/component/icons/PlusSvg';
import { BusinessId } from 'src/business/domain/Business';
import { MenuAssetsSource, MenuAssetsSources } from 'src/menu/dynamic-menu/domain/MenuAssetsSource';

export type CreateSundayMenuFormProps = {
  businessId: BusinessId;
  setShowError: (open: boolean) => void;
  currentMenuAssetsSource: MenuAssetsSource | undefined;
  menuAssetsSources: MenuAssetsSources;
};

export const CreateSundayMenuForm = ({
  businessId,
  setShowError,
  menuAssetsSources,
  currentMenuAssetsSource,
}: CreateSundayMenuFormProps) => {
  const [createMenuDialogOpened, setCreateMenuDialogOpened] = useState<boolean>(false);

  return (<>
      <MenuToolBox onClick={() => setCreateMenuDialogOpened(true)}>
        <PlusSvg fontSize="large" opacity={0.6} />
        <Typography fontSize="medium" sx={{ opacity: 0.6 }}>
          <FormattedMessage id="menus.new_menu" defaultMessage="Create new menu" />
        </Typography>
      </MenuToolBox>
      {createMenuDialogOpened && <MenuCreationModal
        businessId={businessId}
        onClose={() => setCreateMenuDialogOpened(false)}
        onError={setShowError}
        menuAssetsSources={menuAssetsSources}
        currentMenuAssetsSource={currentMenuAssetsSource}
      />}
    </>
  );
};
