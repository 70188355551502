import { StripeSvg, ToastSvg } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { checkoutMigration, venueSettingsPaymentsPath, venueStripeSetupDonePath } from 'src/app/navigation/pathHelpers';
import { usePaymentAccount } from 'src/payments/queries/paymentAccount';
import { usePaymentAdapter } from 'src/payments/page/usePaymentAdapter';
import { Business, BusinessId } from 'src/business/domain/Business';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { Button, Card, CardContent, Chip, CircularProgress, Typography, useTheme } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { PaymentAccount, cardOptionsMapping, mealVoucherMapping } from 'src/payments/domain/PaymentAccount';
import { useNavigate } from 'react-router';
import { LoadingButtonPromise } from '../components/LoadingButtonPromise';
import CheckoutSvg from 'src/app/component/icons/CheckoutSvg';

const SmallStack = styled.div`
  > * + * {
    margin-block-start: 0.5rem;
  }
`;

const Stack = styled.div`
  > * + * {
    margin-block-start: 1rem;
  }
`;

const SubTitle = styled.div`
  font-family: 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.02em;
`;

const Splitter = styled('div')`
  margin: 8px 0 8px 0;
  height: 0;
  border-top: 1px solid black;
  opacity: 0.1;
`;

interface PSPPanelProps {
  businessId: BusinessId;
  enrollmentId: EnrollmentId;
  business: Business;
}

function capitalizeFirstLetter(str: string): string {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function IconPSP({ psp }: { psp: string }) {
  switch (psp) {
    case 'stripe':
      return <StripeSvg width="24" height="24" />;
    case 'toast':
      return <ToastSvg width="24" height="24" />;
    case 'checkout':
      return <CheckoutSvg width="24" height="24" />;
    default:
      return <></>;
  }
}

function ChipPaymentMethod({ card, enabled }: { card: string; enabled: boolean }) {
  return (
    <Chip
      icon={enabled ? <DoneIcon color="success" /> : <CloseIcon color="error" />}
      label={card}
      color="secondary"
      variant="outlined"
      style={{ backgroundColor: 'white', color: enabled ? 'black' : 'grey' }}
      size="small"
    />
  );
}

function CardPaymentMethod({
  card,
  label,
  paymentAccount,
}: {
  card: string;
  label: string;
  paymentAccount: PaymentAccount;
}) {
  const enabled = paymentAccount.isPaymentMethodEnabled(card);
  return <ChipPaymentMethod card={label} enabled={enabled} />;
}

const CardPSP = ({ paymentAccount }: { paymentAccount: PaymentAccount }) => {
  const mainPSP = paymentAccount.mainPSP();
  const intl = useIntl();
  if (mainPSP) {
    return (
      <Stack>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <IconPSP psp={mainPSP} />
          <span>
            <FormattedMessage id="settings.payments.psp.connected" values={{ psp: capitalizeFirstLetter(mainPSP) }} />
          </span>
        </div>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <span>
            <FormattedMessage id="settings.payments.psp.allowed_cards" />:
          </span>
          {Object.entries(cardOptionsMapping).map(([label, card]) => (
            <CardPaymentMethod key={card} card={card} label={label} paymentAccount={paymentAccount} />
          ))}
        </div>
      </Stack>
    );
  }
  return (
    <Chip
      icon={<WarningAmberIcon />}
      label={intl.formatMessage({ id: 'settings.payments.psp.not_connected' })}
      color="warning"
    />
  );
};

function MealVoucherPSP({ paymentAccount }: { paymentAccount: PaymentAccount }) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        color: theme.palette.text.secondary,
        marginTop: '1rem',
      }}
    >
      <span>
        <FormattedMessage id="settings.payments.psp.allowed_cards" />:
      </span>
      {Object.entries(mealVoucherMapping).map(([label, card]) => (
        <CardPaymentMethod key={card} card={card} label={label} paymentAccount={paymentAccount} />
      ))}
    </div>
  );
}

function RenderStripeSetup({ businessId }: { businessId: BusinessId }) {
  const paymentAdapter = usePaymentAdapter();
  const configuration = ConfigurationLoader.load();

  const getStripeConfigurationUrl = () => {
    return paymentAdapter
      .generateConfigurationURL(
        businessId,
        `${configuration.frontendUrl}${venueSettingsPaymentsPath(businessId)}`,
        `${configuration.frontendUrl}${venueStripeSetupDonePath(businessId)}`,
      )
      .then((configurationUrl) => window.location.assign(configurationUrl));
  };

  return (
    <LoadingButtonPromise color="primary" variant="contained" handle={getStripeConfigurationUrl}>
      <FormattedMessage id="settings.payments.psp.connect_stripe" />
    </LoadingButtonPromise>
  );
}

function RenderCheckoutSetup({ businessId }: { businessId: BusinessId }) {
  const navigate = useNavigate();
  return (
    <Button color="primary" variant="contained" onClick={() => navigate(checkoutMigration(businessId))}>
      <FormattedMessage id="settings.payments.psp.connect_checkout" />
    </Button>
  );
}

function ConnectToPSP({ paymentAccount, business }: { paymentAccount: PaymentAccount; business: Business }) {
  const mainPSP = paymentAccount.mainPSP();
  const checkout = paymentAccount.pspAccounts.checkout;
  const country = business.address.countryCode;
  if (paymentAccount.stripeOnboardingAllowed) {
    return <RenderStripeSetup businessId={business.id} />;
  }
  if (mainPSP === 'toast') {
    return <RenderStripeSetup businessId={business.id} />;
  }
  // migration to Checkout Platform
  if ((country === 'FR' || country === 'GB') && !(checkout.active && checkout.type == 'PLATFORM')) {
    return <RenderCheckoutSetup businessId={business.id} />;
  }
  return <></>;
}

export function PSPPanel({ enrollmentId, business }: PSPPanelProps) {
  const { data } = usePaymentAccount(enrollmentId);
  const theme = useTheme();

  if (!data) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          <FormattedMessage id="settings.payments.psp.title" />
        </Typography>
        <Splitter />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SmallStack>
            <SubTitle>
              <FormattedMessage id="settings.payments.psp.title.cards" />
            </SubTitle>
            <div style={{ color: theme.palette.text.secondary }}>{<CardPSP paymentAccount={data} />}</div>
          </SmallStack>
          <div>
            <ConnectToPSP paymentAccount={data} business={business} />
          </div>
        </div>
        {business.address.countryCode == 'FR' && (
          <>
            <Splitter />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <SmallStack>
                <SubTitle>
                  <FormattedMessage id="settings.payments.psp.title.meal_voucher" />
                </SubTitle>
                <MealVoucherPSP paymentAccount={data} />
              </SmallStack>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
}
