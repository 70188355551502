import React, {
  // eslint-disable-next-line @typescript-eslint/no-redeclare
  Dispatch, MouseEvent, SetStateAction, useCallback, useEffect,
} from 'react';
import styled from 'styled-components';
import { MinusIcon, PlusIcon } from '../Icons';
import RoundButton from '../RoundButton';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  minNumber?: number;
  maxNumber?: number;
  value: number;
  setValue: Dispatch<SetStateAction<number>> | ((value: number) => void);
}

const NumberSelectorContainer: React.FC = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getSpacing(6)};
  border: 4px solid transparent;
  margin: -4px 0;
  background-color: ${colorPalette.primaryBackgroundColor};
  color: ${colorPalette.primaryForegroundColor};
`;

const NumberSelectorLabel = styled.span`
  min-width: ${getSpacing(1)};
  text-align: center;
  margin: 0 ${getSpacing(1.5)};
  letter-spacing: -0.03em;
  ${textStyles.default}
`;

const NumberSelector: React.FC<Props> = ({
  value, setValue, minNumber = 0, maxNumber, ...props
}) => {
  const reduceNumber = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setValue(value - 1);
    },
    [setValue, value],
  );
  const increaseNumber = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      setValue(value + 1);
    },
    [setValue, value],
  );
  useEffect(() => {
    if (value < minNumber) {
      setValue(minNumber);
    }
    if (maxNumber !== undefined && value > maxNumber) {
      setValue(maxNumber);
    }
  }, [minNumber, maxNumber, value, setValue]);
  return (
    <NumberSelectorContainer {...props}>
      <RoundButton
        size={32}
        onClick={reduceNumber}
        disabled={value === minNumber}
        data-testid="number-selector-minus-btn"
      >
        <MinusIcon color={value === minNumber ? colorPalette.grey600 : undefined} />
      </RoundButton>
      <NumberSelectorLabel>{value}</NumberSelectorLabel>
      <RoundButton
        size={32}
        onClick={increaseNumber}
        disabled={value === maxNumber}
        data-testid="number-selector-plus-btn"
      >
        <PlusIcon color={value === maxNumber ? colorPalette.grey600 : undefined} />
      </RoundButton>
    </NumberSelectorContainer>
  );
};
export default NumberSelector;
