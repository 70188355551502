import React, { ReactNode } from 'react';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { LogProvider } from './LogProviderContext';
import { consolePlugin, datadogPlugin } from './plugins';

interface Props {
  children: ReactNode
}

export const AppLogProvider: React.FC<Props> = ({ children }: Props) => {
  const configuration = ConfigurationLoader.load();
  if (configuration.isDevelopment) {
    return <LogProvider plugin={consolePlugin}>{children}</LogProvider>;
  }

  return <LogProvider plugin={datadogPlugin}>{children}</LogProvider>;
};
