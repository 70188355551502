import React from 'react';
import { Box, Button, Card, IconButton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { PicturePicker } from 'src/ordering/common/components/PicturePicker/PicturePicker';
import { Product, ProductSource } from '../../../domain/Product';
import {
  useDeleteProductPictureMutation,
  useProductPictureMutation,
  useRevertToPartnerPictureProductMutation,
} from '../../../mutations/product/useProductMutation';
import { BusinessId } from 'src/business/domain/Business';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

type ProductImagePickerProps = {
  businessId: BusinessId;
  product: Product;
};

const ImageCard = styled(Card)`
  width: 100%;
  cursor: pointer;
  padding: 24px;
`;

const ImageCardContent = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Title = styled('div')`
  margin-bottom: 24px;
`;

export const ProductImagePicker = ({ businessId, product }: ProductImagePickerProps) => {
  const intl = useIntl();

  const productHasImage = !!product.pictureUrl;
  const productPictureDiffersFromPartnerPicture =
    (!!product.pictureUrl && !!product.partnerPictureUrl && product.pictureUrl !== product.partnerPictureUrl)
    || (!product.pictureUrl && !!product.partnerPictureUrl);

  const updateProductPicture = useProductPictureMutation(businessId, product.id);
  const deleteProductPicture = useDeleteProductPictureMutation(businessId, product.id);
  const revertToPartnerPicture = useRevertToPartnerPictureProductMutation(businessId, product.id);

  const onImageChanged = (canvasBase64: string) => {
    updateProductPicture.mutateAsync(canvasBase64);
  };

  const clearProductPicture = () => {
    deleteProductPicture.mutateAsync();
  };

  const revertToPartnerProductPicture = () => {
    revertToPartnerPicture.mutateAsync();
  };

  const canRevertToPartnerProductPicture = () =>
    product.source === ProductSource.PARTNER && productPictureDiffersFromPartnerPicture;

  return (
    <ImageCard>
      <Title>
        <Typography variant="h6" component="div">
          <FormattedMessage id="menus.product.image.label" defaultMessage="picture" />
        </Typography>
      </Title>
      <ImageCardContent>
        <PicturePicker
          imageUrl={product.pictureUrl}
          updating={false}
          disabled={false}
          onImageChanged={onImageChanged}
          width={200}
          height={200}
          exportWidth={750}
          exportHeight={750}
        />
        <Box component="div" display="flex" flexDirection="row" alignItems="center">
          <Button variant="contained" disabled={!productHasImage} onClick={clearProductPicture}>
            <FormattedMessage id="menus.product.details.clear_product_picture" />
          </Button>
          {canRevertToPartnerProductPicture() && (
            <Tooltip
              title={intl.formatMessage({ id: 'menus.product.details.restore_pos_value' })}
              placement="top"
              sx={{ fontSize: '30px' }}
            >
              <IconButton size="large" sx={{ height: 'fit-content' }} onClick={revertToPartnerProductPicture}>
                <SettingsBackupRestoreIcon
                  fontSize="inherit"
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </ImageCardContent>
    </ImageCard>
  );
};
