import { queryKeys } from 'src/app/queries/utils';
import { useContext } from 'react';
import { StaticMenuContext } from '../../context/MenuContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { StaticMenuId } from '../../domain/StaticMenus';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useSetStaticMenuContent = () => {
  const queryClient = useQueryClient();
  const { menuRepository } = useContext(StaticMenuContext);
  const mutation = useMutation({
    mutationFn: ({ businessId, menuId, file }: { businessId: BusinessId; menuId: StaticMenuId; file: File }) =>
      menuRepository.setMenuContent(businessId, menuId, file),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.staticMenus.LIST_MENUS] }),
  });
  return {
    mutation,
    setStaticMenuContent: (businessId: BusinessId, menuId: StaticMenuId, file: File) =>
      mutation.mutateAsync({
        businessId,
        menuId,
        file,
      }),
  };
};
