type DataTrendProps = {
  trend: number;
  isPercentageTrend?: boolean;
  hasTrend?: boolean
};

export const useGetTrendValue = (data: DataTrendProps) => {
  if (data.hasTrend === false) return { trendValue: null };

  const signSymbol = data.trend >= 0 ? '+' : '';
  const percentSymbol = data.isPercentageTrend ? '%' : '';
  const trendValue = data.trend === Number.POSITIVE_INFINITY ? '' : signSymbol + data.trend + percentSymbol;

  return {
    trendValue,
  };
};