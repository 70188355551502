import { useIntl } from 'src/app/i18n/TypedIntl';
import { TipsContentProps } from '../types/tips_types';
import { sumMoneys, useRenderMoney } from '@sundayapp/web-money';
import { computeEvolutionPercentage } from 'src/home/components/HomeTools';
import { isPaymentTerminalAvailable } from 'src/business/domain/Business';

export const useGetTipsContent = (props: TipsContentProps) => {
  const {
    countryCode,
    business,
    totalTipsLastWeek,
    totalTipsPaymentTerminal,
    totalTipsPaymentTerminalLastWeek,
    tipsPercentage,
    totalTips,
    tipsPercentagePaymentTerminal,
  } = props;

  const intl = useIntl();
  const renderMoney = useRenderMoney(true, true);

  const tipsTitle = countryCode === 'US'
    ? intl.formatMessage({ id: 'venue.home.tips.title.with_servicecharge' })
    : intl.formatMessage({ id: 'menu.analytics.tips' });

  const totalSundayTips = sumMoneys(totalTips, totalTipsPaymentTerminal);
  const totalSundayTipsLastWeek = sumMoneys(totalTipsLastWeek, totalTipsPaymentTerminalLastWeek);

  const tips = renderMoney(totalSundayTips);
  const tipsTrend = computeEvolutionPercentage(totalSundayTips.amount, totalSundayTipsLastWeek.amount);

  const qrCodeTips = renderMoney(totalTips);
  const pdqTips = renderMoney(totalTipsPaymentTerminal);

  const subItems =
    isPaymentTerminalAvailable(business)
      ? [
        {
          headLine: {
            left: intl.formatMessage({ id: 'venue.home.tips.qr_codes' }),
            right: `${qrCodeTips}`,
          },
          subHeadLine: {
            left: intl.formatMessage({ id: 'venue.home.tips.rate' }),
            right: `${tipsPercentage.toFixed(2)}%`,
          },
        },
        {
          headLine: {
            left: intl.formatMessage({ id: 'venue.home.tips.pdq' }),
            right: `${pdqTips}`,
          },
          subHeadLine: {
            left: intl.formatMessage({ id:  'venue.home.tips.rate' }),
            right: `${tipsPercentagePaymentTerminal.toFixed(2)}%`,
          },
        },
      ]
      : [
        {
          headLine: {
            left: intl.formatMessage({ id: 'venue.home.tips.percentage' }),
            right: `${tipsPercentage.toFixed(2)}%`,
          },
          subHeadLine: {
            left: intl.formatMessage({ id:  'venue.home.tips.rate' }),
            right: `${tipsPercentage.toFixed(2)}%`,
          },
        },
      ];

  return {
    tipsTitle,
    tips,
    tipsTrend,
    qrCodeTips,
    pdqTips,
    subItems,
  };
};
