import styled from 'styled-components';
import { spaceUsages } from '@sundayapp/b2b-react-component-library';

export const AppSettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  margin-top: 16px;
`;

export const LineWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${spaceUsages.mediumXLarge};
  justify-content: space-between;
  width: 100%;
`;

export const NotificationContainer = styled.div`
  margin-bottom: ${spaceUsages.mediumLarge};
  width: 100%;
`;

export const WaiterLine = styled.div`
  overflow-x:'scroll'
`;
