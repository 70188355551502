import React from 'react';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { ReplyTemplatePanel } from '../reply-template/components/ReplyTemplatePanel';
import { ExternalReviewsSettings } from './ExternalReviewsSettings';
import { EnableReviewsSettings } from './EnableReviewsSettings';
import { userRoles } from 'src/domain/user/UserRole';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import styled from 'styled-components';
import { SettingsPageContainer } from 'src/pages/Settings/components/SettingsPageContainer';
import { featureReviewsAiSuggestions } from '../reply/featureReviewsAiSuggestions';
import { AiReplySettingsPanel } from '../reply/ai/AiReplySettingsPanel';
import {
  useGoogleSetupStageTermination,
} from 'src/sundayplus/reviews/settings/setup/google/infrastruscture/useGoogleSetupStageTermination';

export const Wrapper = styled('div')`
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

function ReviewsSettings() {
  const business = useCurrentBusinessOrThrow();

  const featureAiSuggestions = featureReviewsAiSuggestions();
  const { isFinished: googleSetupFinished } = useGoogleSetupStageTermination(business.id);

  return (
    <SettingsPageContainer>
      <Wrapper>
        <EnableReviewsSettings />
        { featureAiSuggestions && <AiReplySettingsPanel />}
        <ExternalReviewsSettings />
        {googleSetupFinished && <ReplyTemplatePanel businessId={business.id} />}
      </Wrapper>
    </SettingsPageContainer>
  );
}

export default withRestrictedAccess([userRoles.ADMIN], ReviewsSettings);
