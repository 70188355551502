import { useQuery } from '@tanstack/react-query';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { TipsSuggestion } from '../domain/TipsSuggestion';
import { TipsSuggestionRepositoryHttp } from '../infrastructure/TipsSuggestionRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { queryKeys } from 'src/app/queries/utils';

const query = (enrollmentId: EnrollmentId) => {
  const configuration = ConfigurationLoader.load();
  const repository = new TipsSuggestionRepositoryHttp(configuration.tipsSuggestionUrl);
  return {
    queryKey: [queryKeys.tips.GET_TIPS_SUGGESTIONS, { enrollmentId }],
    queryFn: async (): Promise<TipsSuggestion> => {
      return repository.getTipsSuggestion(enrollmentId);
    },
  };
};

export const useTipsSuggestion = (enrollmentId: EnrollmentId) => useQuery(query(enrollmentId));
