import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const Iban = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();

  return <TextField
    required
    error={!!errors.bankAccount?.iban}
    type={'text'}
    InputLabelProps={{ shrink: true }}
    {...register('bankAccount.iban', {
      required: 'Iban est requis',
      pattern: { value: /^[A-Z0-9 ]{27,40}$/, message: ' Iban invalide' },
      maxLength: { value: 40, message: 'Iban invalide' },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.bankAccount.iban' })}
    helperText={errors.bankAccount?.iban?.message || ''}
  />;
};
