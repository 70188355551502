import { RestaurantShiftTimesOptions } from 'src/operations/restaurant-shifts/hooks/useRestaurantShiftTimesOptions';
import { RestaurantShiftTimesOption } from 'src/operations/restaurant-shifts/models/RestaurantShiftTimesOption';
import { restaurantShiftDateRange } from 'src/operations/restaurant-shifts/models/RestaurantShiftDateRange';
import { Business, Timezone } from 'src/business/domain/Business';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment-timezone';

export const getStartAndEndDateForChosenDateAndShiftOptions =
  (options: RestaurantShiftTimesOptions, restaurantShiftTimesOption: RestaurantShiftTimesOption, reportingDate: Date, business: Business) => {
    const currentOption = options[restaurantShiftTimesOption.type];
    const {
      startAt,
      endAt,
    } = restaurantShiftDateRange(currentOption, reportingDate);

    const convertDateToLocalBusiness = (date: Date, timezone: Timezone) => {
      const dateTimeString = moment(date).format('YYYY-MM-DD HH:mm:ss');
      const dateTimeInTimeZone = moment.tz(dateTimeString, 'YYYY-MM-DD HH:mm:ss', timezone);
      return new Date(dateTimeInTimeZone.format());
    };

    const startAtLocalBusiness = convertDateToLocalBusiness(startAt, business.timezone);
    const endAtLocalBusiness = convertDateToLocalBusiness(endAt, business.timezone);
    return { startAtLocalBusiness, endAtLocalBusiness };
  };
