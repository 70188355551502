import { queryKeys } from 'src/app/queries/utils';
import { useContext } from 'react';
import { StaticMenuContext } from '../../context/MenuContextProvider';
import { BusinessId } from 'src/business/domain/Business';
import { StaticMenuId } from '../../domain/StaticMenus';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useReorderStaticMenus = () => {
  const queryClient = useQueryClient();
  const { menuRepository } = useContext(StaticMenuContext);
  const mutation = useMutation({
    mutationFn: ({ businessId, orderOfMenus }: { businessId: BusinessId; orderOfMenus: StaticMenuId[] }) =>
      menuRepository.reorderMenus(businessId, orderOfMenus),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: [queryKeys.staticMenus.LIST_MENUS] }),
  });
  return {
    mutation,
    reorderStaticMenus: (businessId: BusinessId, orderOfMenus: StaticMenuId[]) =>
      mutation.mutateAsync({ businessId, orderOfMenus }),
  };
};
