// eslint-disable-next-line no-restricted-imports
import moment, { Moment } from 'moment-timezone';

import { DateRange } from 'src/accounting/revenues/domain/RevenuesDatePresets';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { translation, Translation } from 'src/accounting/common/translation';


export const paymentsListDatePresetsTypes = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  CUSTOM: 'CUSTOM',

} as const;

export type PaymentsListDatePreset = ValuesOf<typeof paymentsListDatePresetsTypes>;

export const paymentsListDatePresets: () => Record<PaymentsListDatePreset, {
  range: DateRange,
  translation: Translation
}> = () => {
  const now = (): Moment => moment();
  const shiftStartOf = (aMoment: Moment) => aMoment.hour(4);
  const shiftEndOf = (aMoment: Moment) => aMoment.hour(3).minute(59).second(59);

  return ({
    [paymentsListDatePresetsTypes.TODAY]: {
      range: {
        startDate: shiftStartOf(now().startOf('day')),
        endDate: shiftEndOf(now().add(1, 'days')),
      },
      translation: translation('accounting.revenue_tab.today_filter'),
    },
    [paymentsListDatePresetsTypes.YESTERDAY]: {
      range: {
        startDate: shiftStartOf(now().subtract(1, 'd').startOf('day')),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.revenue_tab.yesterday_filter'),
    },
    [paymentsListDatePresetsTypes.CUSTOM]: {
      range: {
        startDate: shiftStartOf(now()),
        endDate: shiftEndOf(now()),
      },
      translation: translation('accounting.revenue_tab.custom_filter'),
    },
  });
};
