import { StatsCard } from '@molecules/Card/StatsCard';
import { ChevronRight } from '@mui/icons-material';
import { Button, useTheme } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { BarGraphDefinition } from 'src/components/graph/bar/BarChartUtils';
import { VenueKpi } from 'src/home/domain/VenueKpi';
import { HomeStatsButtonType } from 'src/home2/constants/HomeConstants';
import { KpiTimeframeName } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { useGetRevenueContent } from '../hooks/useGetRevenueContent';
import { RevenuesChart } from './Charts/RevenuesChart';

type Props = {
  venueKpi: VenueKpi;
  selectedTimeFrame: KpiTimeframeName;
};

const keys = ['otherRevenue', 'cashRevenue', 'pdqRevenue', 'qrCodeRevenue'] as const;

type DataKey = (typeof keys)[number];

export const PaymentStats = (revenueProps: Props) => {
  const theme = useTheme();
  const intl = useIntl();

  const {
    totalRevenue,
    totalRevenueLastWeek,
    totalAmountPaymentTerminal,
    totalAmountPaymentTerminalLastWeek,
    adoptionRate,
  } = revenueProps.venueKpi;

  const [statToDisplay, setStatToDisplay] = useState<string>(HomeStatsButtonType.REVENUE);

  const data: BarGraphDefinition<DataKey> = {
    keys: keys,

    dataTypes: {
      pdqRevenue: {
        label: intl.formatMessage({ id: 'venue.home.revenue.pdq' }),
        color: '#8E0B81',
      },
      qrCodeRevenue: {
        label: intl.formatMessage({ id: 'venue.home.revenue.qrcode' }),
        color: theme.palette.primary.main,
      },
      cashRevenue: {
        label: intl.formatMessage({ id: 'venue.home.revenue.cash' }),
        color: theme.palette.grey[300],
      },
      otherRevenue: {
        label: 'Other',
        color: theme.palette.grey[500],
      },
    },

    data: {
      pdqRevenue: [181000, 138400, 201000, 127000],
      qrCodeRevenue: [100000, 122000, 113000, 154000],
      cashRevenue: [20200, 34000, 40000, 27000],
      otherRevenue: [8023, 9230, 7908, 3400],
    },
  };

  const {
    revenueTitle,
    revenue,
    revenueTrend,
    subItems,
  } = useGetRevenueContent({
    totalRevenue,
    totalAmountPaymentTerminal,
    totalAmountPaymentTerminalLastWeek,
    totalRevenueLastWeek,
    adoptionRate,
  });

  return (
    <StatsCard
      actions={
        {
          toggleButtons: [
            {
              id: 'insights.revenue',
              value: HomeStatsButtonType.REVENUE,
            },
            {
              id: 'venue.home.sunday.adoption',
              value: HomeStatsButtonType.ADOPTION,
              isDisabled: true,
            },
          ],
          toggleSettings: {
            selectedOption: statToDisplay,
            setSelectedOption: setStatToDisplay,
          },
        }
      }
      chart={{
        element: statToDisplay === HomeStatsButtonType.REVENUE
          ?
          <RevenuesChart venueKpi={revenueProps.venueKpi} selectedTimeFrame={revenueProps.selectedTimeFrame} />
          : <></>,
        labels: data.dataTypes,
      }}
      content={{
        title: revenueTitle,
        value: revenue,
        trend: revenueTrend,
        subItems,
      }}
      footer={
        <Button variant="outlined" sx={{
          border: `1px solid ${theme.palette.grey[300]}`,
          padding: '8px 12px',
        }} endIcon={<ChevronRight />}>
          <FormattedMessage id='venue.home.revenue.button' />
        </Button>
      }
    />
  );
};
