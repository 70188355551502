import { PayoutPdqPort } from '../domain/PayoutPdqPort';
import { BlobWithMetadata } from '../domain/BlobWithMetadata';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const PayoutPdqInMemoryPort = (): PayoutPdqPort => ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPayoutsPdqExport: (enrollmentId: EnrollmentId, startDate: Date, endDate: Date) =>
    Promise.resolve<BlobWithMetadata>(
      {
        blob: new Blob(['export;csv;fake;']),
        contentDisposition: 'attachment; filename="pdq-export.csv"',
      },
    ),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getPayoutsPdqDetailsExport: (enrollmentId: EnrollmentId, startDate: Date, endDate: Date) =>
    Promise.resolve<BlobWithMetadata>(
      {
        blob: new Blob(['export;csv;fake;']),
        contentDisposition: 'attachment; filename="payouts-pdq-details-export.csv"',
      },
    ),
});
