import { ExpandableTableRow, Table, TableBody, TableCell, TableRow } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import React, { useMemo, useState } from 'react';
import { Payment, SpecificPayment } from '../domain/Payment';
import { PaymentRow } from './PaymentRow';
import { PaymentsHeader } from './PaymentsHeader';
import { PaymentNavigation } from './PaymentNavigation';
import { useClaimForVenue } from 'src/auth/hooks/useClaimForVenue';
import { hasAccessToRefund } from 'src/domain/user/Role';
import { RefundSidePanel } from '../components/RefundDialog/RefundSidePanel';
import { Business } from 'src/business/domain/Business';

const PaymentsDataTable = styled(Table)`
  ${TableCell} {
    padding: 9px 0 9px 16px;
    font-size: 16px;
  }
`;

type PaymentsTableContainerProps = {
  collapsed: boolean;
};

const PaymentsTableContainer = styled('div')<PaymentsTableContainerProps>`
  background: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 16px 0 16px 0;
  overflow-y: auto;

  table {
    min-width: 1127px;
  }
`;

type PaymentsTableProps = {
  payments: SpecificPayment[][];
  currentPage: number;
  lastPage: number;
  previousPage: () => void;
  nextPage: () => void;
  releasePaymentAuthorization: (payment: Payment) => void;
  handleRefreshPayment: () => Promise<void>;
  business: Business;
  tpeVenue: boolean;
};

export const PaymentsTable = ({
  payments,
  currentPage,
  lastPage,
  previousPage,
  nextPage,
  releasePaymentAuthorization,
  handleRefreshPayment,
  business,
  tpeVenue,
}: PaymentsTableProps) => {
  const [refundDialogIsOpen, setRefundDialogIsOpen] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<SpecificPayment | undefined>(undefined);
  const currentUserClaim = useClaimForVenue(business.id);

  const onDialogClose = () => {
    setRefundDialogIsOpen(false);
    setSelectedPayment(undefined);
  };

  const isDigitalFeeDisplayed = useMemo(() => {
    const sum = payments
      .reduce((acc, curr) => [...acc, ...curr], [])
      .reduce((acc, curr) => acc + curr.payment.digitalFeeAmount.amount, 0);
    return sum > 0;
  }, [payments]);

  const displayExpandableTableRow = (groupedPayments: SpecificPayment[]) => {
    if (groupedPayments.length === 1) {
      return (
        <TableRow key={`${groupedPayments[0].payment.id}`}>
          <PaymentRow
            specificPayment={groupedPayments[0]}
            withExtraColumn
            hasAccessToRefund={hasAccessToRefund(currentUserClaim) ?? false}
            releasePaymentAuthorization={releasePaymentAuthorization}
            setRefundDialogIsOpen={setRefundDialogIsOpen}
            setSelectedPayment={setSelectedPayment}
            isGrouped={false}
            tpeVenue={tpeVenue}
            isDigitalFeeDisplayed={isDigitalFeeDisplayed}
          />
        </TableRow>
      );
    }
    return (
      <ExpandableTableRow
        data-testid={groupedPayments[0].payment.id}
        key={`grouped-${groupedPayments[0].payment.id}`}
        expandableComponent={(
          <>
            {groupedPayments
              .filter((p, i) => i !== 0)
              .map((specificPayment) => (
                <TableRow style={{ color: 'dimgrey' }} key={specificPayment.payment.id}>
                  <PaymentRow
                    specificPayment={specificPayment}
                    withExtraColumn
                    hasAccessToRefund={hasAccessToRefund(currentUserClaim) ?? false}
                    releasePaymentAuthorization={releasePaymentAuthorization}
                    setRefundDialogIsOpen={setRefundDialogIsOpen}
                    setSelectedPayment={setSelectedPayment}
                    isGrouped
                    tpeVenue={tpeVenue}
                    isDigitalFeeDisplayed={isDigitalFeeDisplayed}
                  />
                </TableRow>
              ))}
          </>
        )}
      >
        <PaymentRow
          specificPayment={groupedPayments[0]}
          withExtraColumn={false}
          hasAccessToRefund={hasAccessToRefund(currentUserClaim) ?? false}
          releasePaymentAuthorization={releasePaymentAuthorization}
          setRefundDialogIsOpen={setRefundDialogIsOpen}
          setSelectedPayment={setSelectedPayment}
          isGrouped
          tpeVenue={tpeVenue}
          isDigitalFeeDisplayed={isDigitalFeeDisplayed}
        />
      </ExpandableTableRow>
    );
  };

  return (
    <>
      {selectedPayment?.payment && (
        <RefundSidePanel
          onClose={onDialogClose}
          isOpen={refundDialogIsOpen}
          payment={selectedPayment.payment}
          handleRefreshPayment={handleRefreshPayment}
        />
      )}
      <PaymentsTableContainer collapsed={refundDialogIsOpen}>
        <PaymentsDataTable size="small" width="100%">
          <PaymentsHeader tpeVenue={tpeVenue} isDigitalFeeDisplayed={isDigitalFeeDisplayed} />
          <TableBody>{payments.map((groupedPayments) => displayExpandableTableRow(groupedPayments))}</TableBody>
        </PaymentsDataTable>
      </PaymentsTableContainer>
      <PaymentNavigation
        lastPage={lastPage}
        previousPage={previousPage}
        currentPage={currentPage}
        nextPage={nextPage}
      />
    </>
  );
};
