import { BusinessId } from 'src/business/domain/Business';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { useBulkDeleteProductsMutation } from 'src/menu/dynamic-menu/mutations/product/useBulkProductsMutation';
import { useEffect, useState } from 'react';
import { usePollTask } from 'src/menu/tasks/queries/pollTask';
import { isTaskOngoing, Task } from 'src/menu/tasks/domain/Task';
import { QUERYKEY_MENU_PRODUCTS } from 'src/menu/dynamic-menu/queries/product/getAllProductByBusinessIdQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const useBulkDeleteProducts = (businessId: BusinessId) => {
  const snackBar = useSnackbar();
  const intl = useIntl();
  const deleteProducts = useBulkDeleteProductsMutation(businessId);
  const [deletingProducts, setDeletingProducts] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [currentTaskId, setTaskId] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const enablePollTask = deletingProducts && !!currentTaskId;
  const { data: currentTask } = usePollTask(currentTaskId!!, enablePollTask);

  const onTaskDone = () => {
    setDeletingProducts(false);
    setTaskId(null);
    queryClient.invalidateQueries({ queryKey: [QUERYKEY_MENU_PRODUCTS] });
  };

  useEffect(() => {
    if (!currentTask || !selectedProductIds) {
      return;
    }

    if (isTaskOngoing(currentTask.status)) {
      snackBar.addNotification({
        variant: 'info',
        text: intl.formatMessage({ id: 'menus.products.bulk.delete.on_going' }),
      });
      return;
    }

    onTaskDone();
  }, [currentTask]);

  const deleteBulkProducts = (productIds: string[]) => {
    setDeletingProducts(true);
    setSelectedProductIds(productIds);
    deleteProducts
      .mutateAsync(productIds)
      .then((task: Task) => setTaskId(task.id))
      .catch(() => {
        setDeletingProducts(false);
        snackBar.addNotification({
          variant: 'error',
          text: intl.formatMessage({ id: 'menus.products.bulk.delete.failure' }),
        });
      });
  };

  return { deleteBulkProducts, currentTaskStatus: currentTask?.status };
};
