/* eslint-disable max-len */
import * as React from 'react';
import { PaymentMethodIconProps } from './IconProps';

export function ApplePayIcon({ ...props }: PaymentMethodIconProps) {
  return (
    <svg viewBox="0 0 21 9" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.868 1.977c.187-.227.32-.536.32-.848a.58.58 0 0 0-.013-.121c-.304.012-.671.204-.89.462-.171.196-.331.508-.33.82 0 .048.007.095.011.11a.45.45 0 0 0 .082.008c.274 0 .617-.185.82-.43Zm.216.496c-.457 0-.828.278-1.066.279-.254 0-.587-.261-.985-.26-.754 0-1.519.626-1.517 1.802a3.71 3.71 0 0 0 .635 2.007c.302.422.564.769.943.768.375 0 .539-.25 1.003-.251.473 0 .579.241.993.24.41 0 .683-.375.94-.747.289-.426.41-.84.413-.86-.023-.007-.809-.327-.81-1.225-.001-.777.616-1.126.65-1.153-.406-.586-1.028-.6-1.2-.6Zm4.05 4.545.874-.001-.002-1.926 1.32-.002c1.105-.001 1.87-.756 1.868-1.858v-.008c-.001-1.105-.768-1.846-1.873-1.845l-2.195.003.007 5.637Zm1.97-4.901c.757-.001 1.199.408 1.2 1.111v.008c0 .707-.44 1.118-1.198 1.12H9.005l-.003-2.238 1.101-.001Zm3.895 4.963c.562 0 1.007-.243 1.257-.674h.066l.001.602.84-.001-.004-2.903c-.001-.89-.604-1.42-1.67-1.42-.965.002-1.632.468-1.733 1.163l-.004.028.817-.001.004-.016c.1-.3.41-.473.878-.474.574 0 .867.257.868.722v.375l-1.148.068c-1.011.064-1.581.506-1.58 1.264v.007c.001.77.6 1.261 1.408 1.26Zm-.564-1.296v-.008c0-.383.264-.594.85-.63l1.035-.068v.364c.002.546-.463.961-1.095.962-.457 0-.79-.23-.79-.62Zm3.959 2.713c.86-.001 1.288-.318 1.646-1.33l1.572-4.409-.89.002-1.054 3.392h-.074l-1.068-3.39-.914.002 1.545 4.244-.062.219c-.124.43-.362.598-.757.598-.098 0-.203-.003-.285-.015v.668c.11.012.235.02.34.02Z"
        fill="#000"
      />
    </svg>
  );
}
