export const CoinsIconSvg = () => <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20"
                                       fill="none">
  <g clipPath="url(#clip0_1981_32061)">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.97378 6.00221C7.65381 3.04129 10.3041 0.833344 13.4714 0.833344C17.1533 0.833344 20.138
          3.81811 20.138 7.50001C20.138 10.6672 17.9301 13.3176 14.9691 13.9976C14.2896 16.9584 11.6383 19.1667
          8.47135 19.1667C4.78946 19.1667 1.80469 16.1819 1.80469 12.5C1.80469 9.33302 4.013 6.68181 6.97378 6.00221ZM7.78849
          7.54625C7.79691 7.54525 7.8053 7.54413 7.81366 7.54288C8.02888 7.5146 8.24841 7.50001 8.47135 7.50001C11.2328 7.50001
          13.4714 9.73859 13.4714 12.5C13.4714 12.7229 13.4568 12.9425 13.4285 13.1577C13.4272 13.1661 13.4261 13.1745 13.4251
          13.1829C13.0921 15.6212 11.0011 17.5 8.47135 17.5C5.70993 17.5 3.47135 15.2614 3.47135 12.5C3.47135 9.97022 5.35012 7.87925
          7.78849 7.54625ZM15.1322 12.2178C14.9882 8.76038 12.211 5.9832 8.75357 5.83921C9.43817 3.89434 11.2924 2.50001 13.4714
          2.50001C16.2328 2.50001 18.4714 4.73859 18.4714 7.50001C18.4714 9.67893 17.077 11.5332 15.1322 12.2178ZM8.86457 9.26528C9.13553
          9.4103 9.30469 9.69268 9.30469 10V13.75H9.72135C10.1816 13.75 10.5547 14.1231 10.5547 14.5833C10.5547 15.0436 10.1816 15.4167 9.72135
          15.4167H7.22135C6.76112 15.4167 6.38802 15.0436 6.38802 14.5833C6.38802 14.1231 6.76112 13.75 7.22135 13.75H7.63802V11.5551C7.26054
          11.7735 6.77327 11.6635 6.52798 11.2956C6.27268 10.9127 6.37616 10.3953 6.7591 10.14L8.0091
          9.30663C8.26482 9.13616 8.5936 9.12027 8.86457 9.26528Z"
          fill="#70707B" />
  </g>
  <defs>
    <clipPath id="clip0_1981_32061">
      <rect width="20" height="20" fill="white" transform="translate(0.970703)" />
    </clipPath>
  </defs>
</svg>;
