import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type SettingsCategoryProps = {
  title: string;
  description?: string;
  children: ReactNode;
};

export const SettingPanel = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${getSpacing(3)};
  padding-left: ${getSpacing(3)};
  padding-right: ${getSpacing(3)};
  background-color: ${colorPalette.defaultBackgroundColor};
  border-radius: ${getSpacing(2)};
  margin-bottom: ${getSpacing(2)};
  box-shadow: rgb(0 0 0 / 3%) 0 1px 8px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${getSpacing(4)};
`;

export const Title = styled.div`
  font-size: 21px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${colorPalette.grey400};
  margin-top: ${getSpacing(2)};
`;

export const SettingsCategory = ({ title, description, children }: SettingsCategoryProps) => (
  <SettingPanel>
    <Header>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </Header>
    {children}
  </SettingPanel>
);
