import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';
import { KpiTimeframe } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

export type StarDistribution = {
  nbRatings: number;
  percentage: number;
  rating: number;
};

type GenericTemporalKpi<T> = {
  unit: T;
  value: number;
};

export type TemporalKpi = GenericTemporalKpi<Date>;
export type TemporalKpiDto = GenericTemporalKpi<string>;

type GenericTemporalAverageKpi<T> = {
  unit: T;
  value: number;
  starsDistribution: StarDistribution[];
};

export type TemporalAverageKpi = GenericTemporalAverageKpi<Date>;
export type TemporalAverageKpiDto = GenericTemporalAverageKpi<string>;

type GenericGraphKpis<T> = {
  fromGoogle: GenericTemporalKpi<T>[];
  fromSunday: GenericTemporalKpi<T>[] | GenericTemporalAverageKpi<T>[];
};

export type GraphKpis = GenericGraphKpis<Date>;
export type GraphKpisDto = GenericGraphKpis<string>;

export type GlobalKpis = {
  differenceWithPreviousTimeframe: number;
  value: number;
  withSunday: {
    number: number;
    percentage: number;
  }
};

type GenericEReputationKpi<T> = {
  kpiType: KpiName;
  globalKpis: GlobalKpis;
  graphKpis: GenericGraphKpis<T>;
};

export type EReputationKpiDto = GenericEReputationKpi<string>;
export type EReputationKpi = GenericEReputationKpi<Date>;

type GenericEReputationKpisByTimeframe<Date, TimeFrame> = {
  timeframeKpiType: TimeFrame;
  eReputationKpis: GenericEReputationKpi<Date>[];
};

export type EReputationKpisByTimeframe = GenericEReputationKpisByTimeframe<Date, KpiTimeframe>;
export type EReputationKpisByTimeframeDto = GenericEReputationKpisByTimeframe<string, string>;

type GenericEReputationKpis<Date, TimeFrame> = {
  eReputationKpisByTimeframes?: GenericEReputationKpisByTimeframe<Date, TimeFrame>[] | undefined;
};

export type EReputationKpisDto = GenericEReputationKpis<string, string>;
export type EReputationKpis = GenericEReputationKpis<Date, KpiTimeframe>;

export const getEReputationKpiByType = (eReputationKpisByTimeframe: EReputationKpisByTimeframe, kpiType: KpiName): EReputationKpi | undefined =>
  eReputationKpisByTimeframe.eReputationKpis.filter((v) => v.kpiType === kpiType)[0];
