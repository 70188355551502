import { Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { ContactUsButton } from 'src/sundayplus/loyalty/subscription/status/component/ContactUsButton';
import styled from 'styled-components';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import AddPaymentTerminalModal from './AddPaymentTerminalModal';

const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  width: 100%;
  padding: 16px;
`;
interface Props {
  enrollmentId?: EnrollmentId;
}
function NoPdq({ enrollmentId }: Props) {
  const intl = useIntl();
  const local = intl.locale.includes('fr') ? 'FR' : 'EN';

  const [showProvisionPaymentTerminal, setShowProvisionPaymentTerminal] = useState(false);

  const onMoreClick = () => {
    window.open(intl.formatMessage({ id: 'payment.terminal.manage.ads.more.redirect-link' }), '_blank');
  };

  return (
    <Container>
      <Grid container sm={12} md={9} justifyContent="center" alignItems="center" spacing={5} direction="column">
        <Grid item md={8} xs={12}>
          <img
            src={`/img/home/PDQAds_${local}.png`}
            alt="PDQ Ads"
            style={{ width: '516px', height: '320px', objectFit: 'contain' }}
          />
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography variant="h2" textAlign="center">
            <FormattedMessage id="payment.terminal.manage.ads.title" />
          </Typography>
        </Grid>
        <Grid item md={8} xs={12}>
          <Typography textAlign="center">
            <FormattedMessage id="payment.terminal.manage.ads.subtitle" />
          </Typography>
        </Grid>
        <Grid item md={8} xs={12} paddingTop="32px">
          <Grid container justifyContent="center" alignItems="center" gap={3} direction="row">
            <Button variant="outlined" onClick={onMoreClick}>
              <FormattedMessage id="payment.terminal.manage.ads.more" />
            </Button>
            <ContactUsButton
              title={intl.formatMessage({ id: 'payment.terminal.manage.ads.discover' })}
              source="no_pdq"
              reason="payment terminal"
            />
          </Grid>
        </Grid>
        {enrollmentId &&
          <Grid item>
            <Button color="secondary" onClick={() => setShowProvisionPaymentTerminal(!showProvisionPaymentTerminal)}>
              {intl.formatMessage({ id: 'payment.terminal.manage.ads.onboard' })}
            </Button>
          </Grid>
        }
        {showProvisionPaymentTerminal && <Grid item>
          <AddPaymentTerminalModal
            enrollmentId={enrollmentId!!}
            isOpen={showProvisionPaymentTerminal}
            onClose={() => setShowProvisionPaymentTerminal(false)}
          />
        </Grid>}
      </Grid>
    </Container>
  );
}

export default NoPdq;
