import React from 'react';
import styled, { css } from 'styled-components';
import { MoneyView } from '@sundayapp/web-money';
import { DetailedProductAvailability, UpdateProductAvailability } from '../../types';
import { ProductSetAvailabilityInput } from '../ProductAvailabilityInput/ProductSetAvailabilityInput';
import { colorPalette, getSpacing } from '../../../stylesheet';
import { ProductUpdateAvailabilityInput } from '../ProductAvailabilityInput/ProductUpdateAvailabilityInput';
import { SnoozeDetailContainer } from './SnoozeDetailContainer';

interface Props {
  productDetails: DetailedProductAvailability;
  updateAvailability: (updateProductAvailability: UpdateProductAvailability) => void;
}

const Cell = styled.div`
  display: table-cell;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-transform: lowercase;
  height: 68px;
  vertical-align: middle;
  padding: 0 ${getSpacing(2)};
  border-top: 1px solid ${colorPalette.cement};
  box-sizing: border-box;
`;

interface LineProps {
  outOfStock?: boolean;
}

const SecondaryCell = styled(Cell)<LineProps>`
  color: ${colorPalette.rhino};
  ${({ outOfStock }) =>
    outOfStock
    && css`
      color: #f68ba5;
    `}
`;

const NumberCell = styled(Cell)`
  width: 220px;
  display: flex;
`;

const Line = styled.div<LineProps>`
  display: table-row;
  ${({ outOfStock }) =>
    outOfStock
    && css`
      color: #ee184b;
    `}
`;

export const AvailabilityLineDetails: React.FC<Props> = ({
  productDetails,
  updateAvailability,
}) => {
  const outOfStock = productDetails.stock === 0;
  return (
    <Line outOfStock={outOfStock}>
      <Cell>
        {productDetails.name}
        <SnoozeDetailContainer snoozeStart={productDetails.snoozeStart} snoozeEnd={productDetails.snoozeEnd} />
      </Cell>
      <SecondaryCell outOfStock={outOfStock}>
        {productDetails.categoryNames.sort()
          .join(', ')}
      </SecondaryCell>
      <SecondaryCell outOfStock={outOfStock}>{productDetails.sku}</SecondaryCell>
      <SecondaryCell outOfStock={outOfStock}>
        <MoneyView value={productDetails.price} />
      </SecondaryCell>
      <NumberCell>
        <ProductSetAvailabilityInput
          product={productDetails}
          setAvailability={(quantity) => {
            updateAvailability({
              sku: productDetails.sku,
              quantity,
              previousKnownQuantity: productDetails.stock,
            });
          }}
        />
        <ProductUpdateAvailabilityInput
          product={productDetails}
          updateAvailability={(updateQuantity) => {
            updateAvailability({
              sku: productDetails.sku,
              updateQuantity,
              previousKnownQuantity: productDetails.stock,
            });
          }}
        />
      </NumberCell>
    </Line>
  );
};
