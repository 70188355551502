import { Box } from 'src/ordering/box/domain/Box';
import { DirectIntegration } from 'src/ordering/box/domain/PosIntegration';
import { PosConnectionWithRevenueCenters } from 'src/pos/queries/getPosConnectionsWithRevenueCenters';
import { PosOnboardingStatus } from 'src/pos/domain/PosConnection';

import { PosConnection } from './PosConnection';

export type MenuAssetsSources = {
  sources: OrderAndPayMenuAssetsSource[];
  forEnrollment: 'OAP';
} | {
  sources: PatMenuAssetsSource[];
  forEnrollment: 'PAT';
};

export type MenuAssetsSource = OrderAndPayMenuAssetsSource | PatMenuAssetsSource;
type BaseMenuAssetsSource = {
  name: string;
  posConnectionId: string;
  externalRevenueCenterId?: string;
};

type OrderAndPayMenuAssetsSource = BaseMenuAssetsSource;

type PatMenuAssetsSource = BaseMenuAssetsSource & {
  posReference: string
};

// TODO menu assets source could be filtered by the pos reference
const posesWithSyncProducts = [
  'ZELTY',
  'TRIVEC',
  'IKENTOO',
  'LADDITION',
  'TOAST',
  'SIMPHONY',
  'NCR_ALOHA_OMNIVORE',
  'RES3700_5_7_OMNIVORE',
  'CASHPAD',
  'TEVALIS',
  'TISSL',
  'ZONAL',
  'PI_ELECTRONIQUE',
  'CARREPOS',
  'DUMMY_1_0_SUNDAY',
];

const posesWithSyncMenus = ['ZELTY', 'TRIVEC', 'IKENTOO', 'LADDITION', 'TOAST', 'CASHPAD', 'TISSL', 'ZONAL', 'PI_ELECTRONIQUE', 'CARREPOS'];
const posesWhichSupportsEmptyRevenueCenter = ['TRIVEC', 'TOAST'];
const posesWhichDoesNotCareAboutRevenueCenters = ['TOAST'];

const isPatMenuAssetsSource = (menuAssetsSource: MenuAssetsSource): menuAssetsSource is PatMenuAssetsSource => 'posReference' in menuAssetsSource;

export const menuAssetsSourceId = (menuAssetsSource: MenuAssetsSource): string => {
  if (!menuAssetsSource.externalRevenueCenterId) {
    return menuAssetsSource.posConnectionId;
  }
  return `${menuAssetsSource.posConnectionId}-${menuAssetsSource.externalRevenueCenterId}`;
};

type BoxDirectIntegration = Box & { posIntegration: DirectIntegration };

export function convertBoxesToMenuAssetsSources(boxes: Box[]): OrderAndPayMenuAssetsSource[] {
  return boxes
    .filter((box): box is BoxDirectIntegration => box.posIntegration.type === 'DIRECT_INTEGRATION')
    .map((box) => ({
      name: box.displayName || box.name,
      posConnectionId: box.posIntegration.posConnectionId,
      externalRevenueCenterId: box.posIntegration.externalRevenueCenterId,
      type: 'ORDERING_BOX',
    }));
}

export function convertPosConnectionsToMenuAssetsSources(posConnections: PosConnectionWithRevenueCenters[]): PatMenuAssetsSource[] {
  return posConnections
    .filter((posConnection) => posConnection.status === PosOnboardingStatus.ACTIVE)
    .flatMap((posConnection): PatMenuAssetsSource[] => {
      if (posConnection.revenueCenters.length === 0) {
        return [{
          name: `${posConnection.name && posConnection.name !== '' ? posConnection.name : 'Default'} (${posConnection.posReference})`,
          posConnectionId: posConnection.id,
          posReference: posConnection.posReference,
        }];
      }

      const listWithNoRevenueCenter : PatMenuAssetsSource[] = posesWhichSupportsEmptyRevenueCenter.includes(posConnection.posReference) ? [{
        name: 'No Revenue Center',
        posConnectionId: posConnection.id,
        externalRevenueCenterId: undefined,
        posReference: posConnection.posReference,
      }] : [];

      if (posesWhichDoesNotCareAboutRevenueCenters.includes(posConnection.posReference)) {
        return listWithNoRevenueCenter;
      }

      return listWithNoRevenueCenter.concat(posConnection.revenueCenters
        .filter((revenueCenter) => revenueCenter.supported)
        .map((revenueCenter) => ({
          name: revenueCenter.name,
          posConnectionId: posConnection.id,
          externalRevenueCenterId: revenueCenter.externalId,
          posReference: posConnection.posReference,
        })));
    });
}

export const determineMenuAssetsSources = (posConnections: PosConnectionWithRevenueCenters[], boxes: Box[]): MenuAssetsSources => {
  return boxes.length ? {
    sources: convertBoxesToMenuAssetsSources(boxes),
    forEnrollment: 'OAP',
  } : { sources: convertPosConnectionsToMenuAssetsSources(posConnections), forEnrollment: 'PAT' };
};


export const canRefreshProductsFromPos = (menuAssetsSource: MenuAssetsSource) => !isPatMenuAssetsSource(menuAssetsSource) || posesWithSyncProducts.includes(menuAssetsSource.posReference);
export const canRefreshMenusFromPos = (menuAssetsSource: MenuAssetsSource) => !isPatMenuAssetsSource(menuAssetsSource) || posesWithSyncMenus.includes(menuAssetsSource.posReference);

export const findMenuAssetsSourceNameFrom = (menuAssetsSource: MenuAssetsSources, posConnection: PosConnection): string | undefined => {
  return menuAssetsSource.sources
    .find((mas) => mas.posConnectionId === posConnection.posConnectionId && (!mas.externalRevenueCenterId || mas.externalRevenueCenterId === posConnection.externalRevenueCenterId))
    ?.name;
};

export const hasMultiSources = (menuAssetsSources: MenuAssetsSources): boolean => menuAssetsSources.sources.length > 1;
