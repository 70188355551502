import { isCancelablePdqAuthorization, isCancelableTab, Payment, SpecificPayment } from '../domain/Payment';
import { AuthenticatedUser } from 'src/auth/domain/user';
import React, { FC, useState } from 'react';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { ServiceType } from 'src/domain/venue/ServiceType';
import CardRefreshSvg from '../../app/component/icons/CardRefreshSvg';
import { DownloadSvg, SvgMail, TrashSvg } from '@sundayapp/b2b-react-component-library';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copyPaste } from 'src/utils/CopyPaste';
import { EmailReceiptAndRefundModal } from '../page/EmailReceiptAndRefundModal';
import { EmailReceiptModal } from '../page/EmailReceiptModal';
import { EmptyTableCellValue } from './EmptyTableCellValue';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Action, ActionsButton } from 'src/components/actions-button/ActionsButton';
import { useShowReceiptErrorNotification } from '../page/useShowReceiptErrorNotification';

type PaymentActionsProps = {
  specificPayment: SpecificPayment;
  hasAccessToRefund: boolean;
  setRefundDialogIsOpen: (value: boolean) => void;
  setSelectedPayment: (payment: SpecificPayment) => void;
  user: AuthenticatedUser;
  releasePaymentAuthorization: (payment: Payment) => void;
  isGrouped: boolean;
};

const refundTabDisabled = (payment: Payment, hasAccessToRefund: boolean): boolean =>
  !hasAccessToRefund ||
  payment.paymentProvider === 'TOAST' ||
  payment.paymentProvider === 'NEPTING' ||
  payment.status !== 'succeeded';


const downloadReceiptAvailable = (payment: Payment): boolean => payment.status === 'succeeded' && !!payment.orderId;

export const PaymentActions: FC<PaymentActionsProps> = ({
  specificPayment,
  hasAccessToRefund,
  setRefundDialogIsOpen,
  setSelectedPayment,
  user,
  releasePaymentAuthorization,
  isGrouped,
}) => {
  const { receiptApiBaseUrl } = ConfigurationLoader.load();
  const [receiptModalVisible, setReceiptModalVisible] = useState<boolean>(false);

  const businessId = useBusinessIdOrThrow();
  const paymentHasRefunds = (specificPayment.payment?.refunds || []).length > 0;
  const showReceiptErrorNotification = useShowReceiptErrorNotification();

  const serviceType = 'ordering' === specificPayment.type ? ServiceType.ORDER_AND_PAY : ServiceType.PAY_AT_TABLE;

  const allActions: Action[] = [
    {
      icon: <CardRefreshSvg />,
      action: () => {
        setRefundDialogIsOpen(true);
        setSelectedPayment(specificPayment);
      },
      visible: !refundTabDisabled(specificPayment.payment, hasAccessToRefund),
      intlKey: 'payments.table.tooltip.refund',
    },
    {
      href: `${receiptApiBaseUrl}/receipts/v2/payments/${specificPayment.payment.id}`,
      icon: <DownloadSvg />,
      visible: downloadReceiptAvailable(specificPayment.payment),
      intlKey: 'payments.table.tooltip.export',
    },
    {
      icon: <SvgMail />,
      action: () => setReceiptModalVisible(true),
      visible: downloadReceiptAvailable(specificPayment.payment) && !isGrouped,
      intlKey: 'payments.table.tooltip.email',
    },
    {
      icon: <ContentCopyIcon />,
      action: () => copyPaste(specificPayment.payment.orderId),
      visible: user.impersonated && specificPayment.payment.orderId !== undefined,
      intlKey: 'copy order id',
    },
    {
      icon: <ContentCopyIcon />,
      action: () => copyPaste(specificPayment.payment.id),
      visible: user.impersonated,
      intlKey: 'copy payment id',
    },
    {
      icon: <TrashSvg />,
      action: () => releasePaymentAuthorization(specificPayment.payment),
      visible: (user.impersonated && isCancelableTab(specificPayment)) || isCancelablePdqAuthorization(specificPayment),
      intlKey: 'release payment authorization',
    },
  ];

  const visibleActions = allActions.filter((action) => action.visible);

  if (visibleActions.length === 0) {
    return <EmptyTableCellValue />;
  }

  return (
    <>
      <ActionsButton actions={visibleActions} />
      {receiptModalVisible &&
        (paymentHasRefunds ? (
          <EmailReceiptAndRefundModal
            businessId={businessId}
            payment={specificPayment.payment}
            serviceType={serviceType}
            onClose={() => setReceiptModalVisible(false)}
            onSendReceiptError={showReceiptErrorNotification}
          />
        ) : (
          <EmailReceiptModal
            payment={specificPayment.payment}
            serviceType={serviceType}
            onClose={() => setReceiptModalVisible(false)}
            onSendReceiptError={showReceiptErrorNotification}
          />
        ))}
    </>
  );
};
