import { ReactNode } from 'react';
import { styled } from '@mui/material';

type NavigationMenuItemIconProps = {
  icon: ReactNode
};

const MenuIcon = styled('div')`
  padding: 0;
  height: 40px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 4px;
`;

export const NavigationMenuItemIcon = ({ icon }: NavigationMenuItemIconProps) => {
  if (icon) {
    return <MenuIcon>{icon}</MenuIcon>;
  }

  return <></>;
};
