import { useCustomizationRepositoryHttp } from 'src/pages/Settings/Customization/infrastructure/useCustomizationRepositoryHttp';
import { useQuery } from '@tanstack/react-query';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const QUERY_KEY_CHARGES_DISPLAY = 'customization_charges_display';
export const useGetChargesDisplay = (enrollmentId: EnrollmentId) => {
  const customizationRepository = useCustomizationRepositoryHttp();

  return useQuery({
    queryKey: [QUERY_KEY_CHARGES_DISPLAY, enrollmentId],
    queryFn: () => customizationRepository.getChargesDisplay(enrollmentId),
  });
};
