import React, { useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Typography } from '@mui/material';
import { EMPTY, Empty, Review } from '../../../domain/Review';

const truncate = (feedback: string, maxSize: number) => {
  if (feedback && feedback.length > maxSize) return `${feedback.substring(0, maxSize)}...`;

  return feedback;
};

// eslint-disable-next-line max-len
const displayedFeedback = (feedback: string | Empty, maxLength: number, showMore: boolean): string =>
  (feedback === EMPTY || feedback.length < maxLength || showMore ? feedback : truncate(feedback, maxLength));

export const ReviewFeedback = ({ review }: { review: Review }) => {
  const [showMore, setShowMore] = useState(false);
  const maxLength = 400;

  return (
    <div>
      <Typography>{displayedFeedback(review.feedback, maxLength, showMore)}</Typography>
      {review.feedback.length > maxLength && (
      <>
        <br />
        <span
          style={{ cursor: 'pointer' }}
          role="button"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          <Typography color="textSecondary">
            {showMore ? (
              <FormattedMessage id="venue.reviews.feedback.show.less" />
            ) : (
              <FormattedMessage id="venue.reviews.feedback.show.more" />
            )}
          </Typography>
        </span>
      </>
      )}
    </div>
  );
};
