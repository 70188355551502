import { differenceMoney, Money, renderMoney } from '@sundayapp/web-money';
import { OrganizationKpi } from './WeeklyReportOrganization';
import lighting from '../assets/venueKpi/organization/lighting.png';
import star from '../assets/venueKpi/organization/star.png';
import receipt_check from '../assets/venueKpi/organization/receipt_check.png';
import fork from '../assets/venueKpi/organization/fork.png';
import ring from '../assets/venueKpi/organization/ring.png';
import service from '../assets/venueKpi/organization/service.png';
import piggy_bank from '../assets/venueKpi/organization/piggy_bank.png';
import lunch from '../assets/venueKpi/organization/lunch.png';
import dinner from '../assets/venueKpi/organization/dinner.png';
import week from '../assets/venueKpi/organization/week.png';
import weekend from '../assets/venueKpi/organization/weekend.png';
import median from '../assets/venueKpi/organization/median.png';
import p10 from '../assets/venueKpi/organization/p10.png';
import p90 from '../assets/venueKpi/organization/p90.png';
import {
  FinancialData,
  OperationalData,
  SocialData,
  StaffVenueKpi,
  VenuesBasketKpis,
  VenuesInsightKpis,
} from './TemplatesData';

const getLocale = (countryCode: string): string => (countryCode === 'FR' ? 'fr' : 'en');

export const safeRenderMoney = (money: Money | null, countryCode: string): string => {
  if (money && money.amount > 0) {
    return renderMoney(money, getLocale(countryCode));
  }
  return 'N/A';
};

export const safeRenderMoneyOrNull = (money: Money | null, countryCode: string): string | null => {
  if (money && money.amount > 0) {
    return renderMoney(money, getLocale(countryCode));
  }
  return null;
};

export const safeSubMoneyOrNull = (money1: Money | null, money2: Money | null): Money | null => {
  if (!money1 || !money2) {
    return null;
  }
  return differenceMoney(money1, money2);
};
export const safeDivideMoneyOrNull = (money1: Money | null, money2: Money | null): number | null => {
  if (!money1 || !money2) {
    return null;
  }
  return (money1.amount / money2.amount) * 100;
};

export const renderNumber = (kpi: number | null, countryCode: string): string | null => {
  if (!kpi && kpi !== 0) {
    return null;
  }

  return `${kpi.toLocaleString(getLocale(countryCode), {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
};

export const renderCount = (kpi: number | null, countryCode: string): string => {
  if (kpi && kpi > 0) {
    return `${kpi.toLocaleString(getLocale(countryCode), { style: 'decimal', maximumFractionDigits: 0 })}`;
  }
  return '0';
};

export const renderGtvLine = (message: String, gtv: String | null) => (gtv !== null ? `${message} : ${gtv}` : '');
export const buildFinancialData = (organizationKpi: OrganizationKpi[]): FinancialData => {
  const venuesKpis = organizationKpi.map((venueKpi) => {
    const otherGtv = safeSubMoneyOrNull(venueKpi.turnoverAmount.lastWeek, venueKpi.totalGtvSunday.lastWeek);
    return {
      name: venueKpi.venueName.value,
      adoption: renderNumber(venueKpi.adoption.lastWeek, venueKpi.countryCode),
      adoptionFluctuation: venueKpi.adoption.fluctuation,
      gtv: safeRenderMoneyOrNull(
        venueKpi.turnoverAmount.lastWeek ?? venueKpi.totalGtvSunday.lastWeek,
        venueKpi.countryCode,
      ),
      gtvFluctuation: venueKpi.turnoverAmount.lastWeek
        ? venueKpi.turnoverAmount.fluctuation
        : venueKpi.totalGtvSunday.fluctuation,
      otherGtv: safeRenderMoneyOrNull(otherGtv, venueKpi.countryCode),
      qr: safeRenderMoneyOrNull(venueKpi.gtvQrCodesSunday.lastWeek, venueKpi.countryCode),
      pdqr: safeRenderMoneyOrNull(venueKpi.gtvPaymentTerminalSunday.lastWeek, venueKpi.countryCode),
      tips: renderNumber(venueKpi.sundayTipsRate.lastWeek, venueKpi.countryCode),
      sundayTipsRateWhenTips: renderNumber(venueKpi.sundayTipsRateWhenTips.lastWeek, venueKpi.countryCode),
      tipsFluctuation: venueKpi.sundayTipsRate.fluctuation,
    };
  });
  return {
    lightingImgPath: lighting,
    venuesKpis,
  };
};

export const buildSocialData = (organizationKpi: OrganizationKpi[]): SocialData => {
  const venuesKpis = organizationKpi.map((venueKpi) => ({
    name: venueKpi.venueName.value,
    averageRatingGoogleReviews: renderNumber(venueKpi.averageRatingGoogleReviews.lastWeek, venueKpi.countryCode),
    googleReviewsFluctuation: venueKpi.averageRatingGoogleReviews.fluctuation,
    shareGoogleReviewsComingFromSunday: renderNumber(
      venueKpi.shareGoogleReviewsComingFromSunday.lastWeek,
      venueKpi.countryCode,
    ),
    googleRatingCount: renderCount(venueKpi.googleRatingCount.lastWeek, venueKpi.countryCode),
    averageRatingSunday: renderNumber(venueKpi.averageRatingSunday.lastWeek, venueKpi.countryCode),
    ratingSundayFluctuation: venueKpi.averageRatingSunday.fluctuation,
    sundayRatingCount: renderCount(venueKpi.sundayRatingCount.lastWeek, venueKpi.countryCode),
  }));
  return {
    starImgPath: star,
    venuesKpis,
  };
};

export const buildOperationalData = (organizationKpi: OrganizationKpi[]): OperationalData => {
  const basketsVenues = organizationKpi.map(
    (venueKpi): VenuesBasketKpis => ({
      name: venueKpi.venueName.value,
      basketSize: safeRenderMoneyOrNull(venueKpi.basketSize.lastWeek, venueKpi.countryCode),
      basketSizeFluctuation: venueKpi.basketSize.fluctuation,
      basketSizeWeek: safeRenderMoneyOrNull(venueKpi.basketSizeWeek.lastWeek, venueKpi.countryCode),
      basketSizeWeekFluctuation: venueKpi.basketSizeWeek.fluctuation,
      basketSizeWeekend: safeRenderMoneyOrNull(venueKpi.basketSizeWeekend.lastWeek, venueKpi.countryCode),
      basketSizeWeekendFluctuation: venueKpi.basketSizeWeekend.fluctuation,
      basketSizeLunch: safeRenderMoneyOrNull(venueKpi.basketSizeLunch.lastWeek, venueKpi.countryCode),
      basketSizeLunchFluctuation: venueKpi.basketSizeLunch.fluctuation,
      basketSizeDinner: safeRenderMoneyOrNull(venueKpi.basketSizeDinner.lastWeek, venueKpi.countryCode),
      basketSizeDinnerFluctuation: venueKpi.basketSizeDinner.fluctuation,
      sharedOrdersAvailable: venueKpi.shareOrdersAvailable.lastWeek,
    }),
  );
  const insightVenues: VenuesInsightKpis[] = organizationKpi.map((venueKpi) => ({
    name: venueKpi.venueName.value,
    foodAndDrinkRating: renderNumber(venueKpi.foodAndDrinkRating.lastWeek, venueKpi.countryCode),
    foodAndDrinkRatingCount: renderCount(venueKpi.foodAndDrinkRatingCount.lastWeek, venueKpi.countryCode),
    foodAndDrinkRatingFluctuation: venueKpi.foodAndDrinkRating.fluctuation,
    ambianceRating: renderNumber(venueKpi.ambianceRating.lastWeek, venueKpi.countryCode),
    ambianceRatingCount: renderCount(venueKpi.ambianceRatingCount.lastWeek, venueKpi.countryCode),
    ambianceRatingFluctuation: venueKpi.ambianceRating.fluctuation,
    serviceRating: renderNumber(venueKpi.serviceRating.lastWeek, venueKpi.countryCode),
    serviceRatingCount: renderCount(venueKpi.serviceRatingCount.lastWeek, venueKpi.countryCode),
    serviceRatingFluctuation: venueKpi.serviceRating.fluctuation,
    valueForMoneyRating: renderNumber(venueKpi.valueForMoneyRating.lastWeek, venueKpi.countryCode),
    valueForMoneyRatingCount: renderCount(venueKpi.valueForMoneyRatingCount.lastWeek, venueKpi.countryCode),
    valueForMoneyRatingFluctuation: venueKpi.valueForMoneyRating.fluctuation,
  }));
  const staffs = organizationKpi.map(
    (venueKpi): StaffVenueKpi => ({
      name: venueKpi.venueName.value,
      p10AdoptionWaiter: renderNumber(venueKpi.p10AdoptionWaiter.lastWeek, venueKpi.countryCode),
      p50AdoptionWaiter: renderNumber(venueKpi.p50AdoptionWaiter.lastWeek, venueKpi.countryCode),
      p90AdoptionWaiter: renderNumber(venueKpi.p90AdoptionWaiter.lastWeek, venueKpi.countryCode),
      p10BasketWaiter: safeRenderMoneyOrNull(venueKpi.p10BasketWaiter.lastWeek, venueKpi.countryCode),
      p50BasketWaiter: safeRenderMoneyOrNull(venueKpi.p50BasketWaiter.lastWeek, venueKpi.countryCode),
      p90BasketWaiter: safeRenderMoneyOrNull(venueKpi.p90BasketWaiter.lastWeek, venueKpi.countryCode),
      p10RatingWaiter: renderNumber(venueKpi.p10RatingWaiter.lastWeek, venueKpi.countryCode),
      p50RatingWaiter: renderNumber(venueKpi.p50RatingWaiter.lastWeek, venueKpi.countryCode),
      p90RatingWaiter: renderNumber(venueKpi.p90RatingWaiter.lastWeek, venueKpi.countryCode),
      p10AdoptionWaiterFluctuation: venueKpi.p10AdoptionWaiter.fluctuation,
      p50AdoptionWaiterFluctuation: venueKpi.p50AdoptionWaiter.fluctuation,
      p90AdoptionWaiterFluctuation: venueKpi.p90AdoptionWaiter.fluctuation,
      p10BasketWaiterFluctuation: venueKpi.p10BasketWaiter.fluctuation,
      p50BasketWaiterFluctuation: venueKpi.p50BasketWaiter.fluctuation,
      p90BasketWaiterFluctuation: venueKpi.p90BasketWaiter.fluctuation,
      p10RatingWaiterFluctuation: venueKpi.p10RatingWaiter.fluctuation,
      p50RatingWaiterFluctuation: venueKpi.p50RatingWaiter.fluctuation,
      p90RatingWaiterFluctuation: venueKpi.p90RatingWaiter.fluctuation,
      waiterCount: renderCount(venueKpi.waiterCount.lastWeek, venueKpi.countryCode),
    }),
  );
  return {
    imagePath: receipt_check,
    insights: {
      forkImgPath: fork,
      ringImgPath: ring,
      serviceImgPath: service,
      piggyBankImgPath: piggy_bank,
      venues: insightVenues,
    },
    basket: {
      venues: basketsVenues,
      lunchImgPath: lunch,
      dinnerImgPath: dinner,
      weekImgPath: week,
      weekendImgPath: weekend,
    },
    staffs: {
      medianImgPath: median,
      p10ImgPath: p10,
      p90ImgPath: p90,
      staffs,
      waiterCount: organizationKpi
        .map((w) => (w.waiterCount.lastWeek ? w.waiterCount.lastWeek : 0))
        .reduce((a, b) => a + b),
    },
  };
};
