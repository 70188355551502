/* eslint-disable */
export const ProductTypeProductImage = () => (
  <svg
    viewBox="0 0 215 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
  >
    <g clipPath="url(#clip0_6744_53760)">
      <rect width="330" height="220" transform="translate(-57.6665)" fill="#F6F6F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.908 26.4486H64.2815C56.4015 26.4486 50.0136 32.8366 50.0136 40.7166V257.283C50.0136 265.163 56.4015 271.551 64.2815 271.551H150.908C158.788 271.551 165.176 265.163 165.176 257.283V40.7166C165.176 32.8366 158.788 26.4486 150.908 26.4486ZM64.2815 22.3721C54.1501 22.3721 45.937 30.5852 45.937 40.7166V257.283C45.937 267.415 54.1501 275.628 64.2815 275.628H150.908C161.04 275.628 169.253 267.415 169.253 257.283V40.7166C169.253 30.5852 161.04 22.3721 150.908 22.3721H64.2815Z"
        fill="black"
      />
      <g clipPath="url(#clip1_6744_53760)">
        <rect x="50.0132" y="26.4492" width="115.163" height="245.103" rx="14.2679" fill="white" />
        <g filter="url(#filter0_dd_6744_53760)">
          <rect width="115.672" height="250.469" transform="translate(49.5034 24.9204)" fill="#F6F6F9" />
          <rect width="100" height="34.4225" transform="translate(57.3335 68.1382)" fill="#F6F6F9" />
          <rect x="57.3335" y="68.1382" width="33.363" height="4.5495" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.3335" y="75.1465" width="60.66" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.3335" y="81.2124" width="36.396" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <g clipPath="url(#clip2_6744_53760)">
            <rect
              x="122.911"
              y="68.1382"
              width="34.4225"
              height="34.4225"
              rx="4.9175"
              fill="#0E071D"
              fillOpacity="0.14"
            />
          </g>
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_6744_53760"
        x="37.1651"
        y="16.2836"
        width="140.349"
        height="275.145"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.70151" />
        <feGaussianBlur stdDeviation="6.16918" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6744_53760" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.616918" />
        <feGaussianBlur stdDeviation="1.23384" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_6744_53760" result="effect2_dropShadow_6744_53760" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6744_53760" result="shape" />
      </filter>
      <clipPath id="clip0_6744_53760">
        <rect width="330" height="200" fill="white" transform="translate(-57.6665)" />
      </clipPath>
      <clipPath id="clip1_6744_53760">
        <rect x="50.0132" y="26.4492" width="115.163" height="245.103" rx="14.2679" fill="white" />
      </clipPath>
      <clipPath id="clip2_6744_53760">
        <rect width="34.4225" height="34.4225" fill="white" transform="translate(122.911 68.1382)" />
      </clipPath>
    </defs>
  </svg>
);
export const ProductTypeBundleImage = () => (
  <svg
    viewBox="0 0 216 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
  >
    <g clipPath="url(#clip0_6744_53783)">
      <rect width="330" height="200" transform="translate(-57)" fill="#F6F6F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.573 26.4486H64.946C57.0661 26.4486 50.6781 32.8366 50.6781 40.7166V257.283C50.6781 265.163 57.0661 271.551 64.946 271.551H151.573C159.453 271.551 165.841 265.163 165.841 257.283V40.7166C165.841 32.8366 159.453 26.4486 151.573 26.4486ZM64.946 22.3721C54.8147 22.3721 46.6016 30.5852 46.6016 40.7166V257.283C46.6016 267.415 54.8147 275.628 64.946 275.628H151.573C161.704 275.628 169.917 267.415 169.917 257.283V40.7166C169.917 30.5852 161.704 22.3721 151.573 22.3721H64.946Z"
        fill="black"
      />
      <g clipPath="url(#clip1_6744_53783)">
        <rect x="50.6777" y="26.4492" width="115.163" height="245.103" rx="14.2679" fill="white" />
        <g filter="url(#filter0_dd_6744_53783)">
          <rect width="115.672" height="250.469" transform="translate(50.168 24.9204)" fill="#F6F6F9" />
          <rect width="100" height="34.4225" transform="translate(57.9961 110.138)" fill="#F6F6F9" />
          <rect x="57.9961" y="110.138" width="33.363" height="4.5495" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.9961" y="117.146" width="60.66" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.9961" y="123.212" width="36.396" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <g clipPath="url(#clip2_6744_53783)">
            <rect
              x="123.574"
              y="110.138"
              width="34.4225"
              height="34.4225"
              rx="4.9175"
              fill="#0E071D"
              fillOpacity="0.14"
            />
          </g>
          <rect width="100" height="34.4225" transform="translate(57.9961 153)" fill="#F6F6F9" />
          <rect x="57.9961" y="153" width="33.363" height="4.5495" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.9961" y="160.009" width="60.66" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.9961" y="166.074" width="36.396" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <g clipPath="url(#clip3_6744_53783)">
            <rect x="123.574" y="153" width="34.4225" height="34.4225" rx="4.9175" fill="#0E071D" fillOpacity="0.14" />
          </g>
          <rect width="100" height="34.4225" transform="translate(57.9961 68.0002)" fill="#F6F6F9" />
          <rect x="57.9961" y="68.0002" width="33.363" height="4.5495" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.9961" y="75.0085" width="60.66" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <rect x="57.9961" y="81.0745" width="36.396" height="3.033" fill="#0E071D" fillOpacity="0.14" />
          <g clipPath="url(#clip4_6744_53783)">
            <rect
              x="123.574"
              y="68.0002"
              width="34.4225"
              height="34.4225"
              rx="4.9175"
              fill="#0E071D"
              fillOpacity="0.14"
            />
          </g>
          <rect x="57.9961" y="46.0002" width="54" height="8" fill="#0E071D" fillOpacity="0.14" />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_6744_53783"
        x="37.8296"
        y="16.2836"
        width="140.349"
        height="275.145"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.70151" />
        <feGaussianBlur stdDeviation="6.16918" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6744_53783" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.616918" />
        <feGaussianBlur stdDeviation="1.23384" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_6744_53783" result="effect2_dropShadow_6744_53783" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6744_53783" result="shape" />
      </filter>
      <clipPath id="clip0_6744_53783">
        <rect width="330" height="200" fill="white" transform="translate(-57)" />
      </clipPath>
      <clipPath id="clip1_6744_53783">
        <rect x="50.6777" y="26.4492" width="115.163" height="245.103" rx="14.2679" fill="white" />
      </clipPath>
      <clipPath id="clip2_6744_53783">
        <rect width="34.4225" height="34.4225" fill="white" transform="translate(123.574 110.138)" />
      </clipPath>
      <clipPath id="clip3_6744_53783">
        <rect width="34.4225" height="34.4225" fill="white" transform="translate(123.574 153)" />
      </clipPath>
      <clipPath id="clip4_6744_53783">
        <rect width="34.4225" height="34.4225" fill="white" transform="translate(123.574 68.0002)" />
      </clipPath>
    </defs>
  </svg>
);
export const ProductTypeVariantGroupImage = () => (
  <svg
    viewBox="0 0 215 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: '100%', borderTopLeftRadius: '16px', borderTopRightRadius: '16px' }}
  >
    <g clipPath="url(#clip0_6744_53823)">
      <rect width="330" height="200" transform="translate(-57.333)" fill="#F6F6F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M151.24 26.4486H64.613C56.7331 26.4486 50.3451 32.8366 50.3451 40.7166V257.283C50.3451 265.163 56.7331 271.551 64.613 271.551H151.24C159.12 271.551 165.508 265.163 165.508 257.283V40.7166C165.508 32.8366 159.12 26.4486 151.24 26.4486ZM64.613 22.3721C54.4817 22.3721 46.2686 30.5852 46.2686 40.7166V257.283C46.2686 267.415 54.4817 275.628 64.613 275.628H151.24C161.371 275.628 169.584 267.415 169.584 257.283V40.7166C169.584 30.5852 161.371 22.3721 151.24 22.3721H64.613Z"
        fill="black"
      />
      <g clipPath="url(#clip1_6744_53823)">
        <rect x="50.3447" y="26.4492" width="115.163" height="245.103" rx="14.2679" fill="white" />
        <g clipPath="url(#clip2_6744_53823)" filter="url(#filter0_dd_6744_53823)">
          <rect width="115.672" height="250.469" transform="translate(49.835 24.9204)" fill="#F6F6F9" />
          <g clipPath="url(#clip3_6744_53823)">
            <rect x="50" y="25" width="116" height="87" fill="#0E071D" fillOpacity="0.14" />
          </g>
          <rect x="58" y="130" width="61" height="8" fill="#0E071D" fillOpacity="0.14" />
          <rect x="58" y="150" width="61" height="8" fill="#0E071D" fillOpacity="0.14" />
          <rect x="58" y="170" width="61" height="8" fill="#0E071D" fillOpacity="0.14" />
          <circle cx="152" cy="134" r="6" fill="#D9D9D9" />
          <circle cx="152" cy="154" r="5.5" stroke="#D9D9D9" />
          <circle cx="152" cy="174" r="5.5" stroke="#D9D9D9" />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_6744_53823"
        x="37.4966"
        y="16.2836"
        width="140.349"
        height="275.145"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.70151" />
        <feGaussianBlur stdDeviation="6.16918" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6744_53823" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.616918" />
        <feGaussianBlur stdDeviation="1.23384" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_6744_53823" result="effect2_dropShadow_6744_53823" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6744_53823" result="shape" />
      </filter>
      <clipPath id="clip0_6744_53823">
        <rect width="330" height="200" fill="white" transform="translate(-57.333)" />
      </clipPath>
      <clipPath id="clip1_6744_53823">
        <rect x="50.3447" y="26.4492" width="115.163" height="245.103" rx="14.2679" fill="white" />
      </clipPath>
      <clipPath id="clip2_6744_53823">
        <rect width="115.672" height="250.469" fill="white" transform="translate(49.835 24.9204)" />
      </clipPath>
      <clipPath id="clip3_6744_53823">
        <rect width="116" height="87" fill="white" transform="translate(50 25)" />
      </clipPath>
    </defs>
  </svg>
);
