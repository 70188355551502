import React from 'react';
import { IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { styled } from '@mui/material/styles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Block } from '../../domain/Block';
import { Language } from 'src/app/i18n/AllSupportedLanguage';
import { venueMenuBlockPath } from 'src/app/navigation/pathHelpers';
import { SelectLanguage } from 'src/components/select/SelectLanguage';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';

type BlockTitleProps = {
  block: Block;
  supportedLanguages: Language[];
  defaultLanguage: Language;
  currentLanguage?: Language;
  setCurrentLanguage: (language: Language) => void;
  contextBlockList: string[];
};

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-left: -16px;
`;

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavigationContainer = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 16px;
`;

const NavigationButton = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  min-width: 150px;
`;
const EmptyNavigationButton = styled('div')`
  width: 150px;
`;
export const BlockHeader = ({
  block,
  supportedLanguages,
  currentLanguage,
  setCurrentLanguage,
  defaultLanguage,
  contextBlockList,
}: BlockTitleProps) => {
  const businessId = useBusinessIdOrThrow();
  const navigate = useNavigate();

  const findPreviousAndNextBlock = () => {
    const i = contextBlockList.indexOf(block.id);
    const previous = i > 0 ? contextBlockList[i - 1] : undefined;
    const next = i < contextBlockList.length ? contextBlockList[i + 1] : undefined;
    return { previous, next };
  };
  const onBack = async () => {
    navigate(-1);
  };

  const nextAndPreviousIds = findPreviousAndNextBlock();
  const navigateToBlock = (blockId: string) => {
    navigate(venueMenuBlockPath(businessId, blockId), { state: { contextBlockList } });
  };
  return (
    <Container>
      <TitleContainer>
        <IconButton size="large" onClick={onBack} sx={{ height: 'fit-content', margin: '8px' }}>
          <ArrowBackIosNewIcon fontSize="inherit" />
        </IconButton>
        <Typography fontSize="xx-large">
          {block.title}
        </Typography>
      </TitleContainer>
      <NavigationContainer>
        {nextAndPreviousIds.previous ? (
          <NavigationButton onClick={() => navigateToBlock(nextAndPreviousIds.previous!)}>
            <NavigateBeforeIcon />
            <FormattedMessage id="menus.block.details.navigation.previous" defaultMessage="Previous block" />
          </NavigationButton>
        ) : <EmptyNavigationButton />}

        {nextAndPreviousIds.next ? (
          <NavigationButton onClick={() => navigateToBlock(nextAndPreviousIds.next!)}>
            <FormattedMessage id="menus.block.details.navigation.next" defaultMessage=" Next block" />
            <NavigateNextIcon />
          </NavigationButton>
        ) : <EmptyNavigationButton />}
      </NavigationContainer>
      {supportedLanguages.length > 0 && (
        <SelectLanguage
          defaultLanguage={defaultLanguage}
          supportedLanguages={supportedLanguages}
          currentLanguage={currentLanguage}
          setCurrentLanguage={setCurrentLanguage}
        />
      )}
    </Container>
  );
};
