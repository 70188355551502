import { IntlShape } from 'src/app/i18n/TypedIntl';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { mapDayjsDate } from 'src/payments/infrastructure/mapDayjsDate';
import { getRequirementDueFieldLabel } from 'src/payments/infrastructure/getRequirementDueFieldLabel';
import { getRequirementsReasonTranslation } from 'src/payments/infrastructure/getRequirementsReasonTranslation';
import { CheckoutOnboardingInformationDto } from 'src/payments/infrastructure/CheckoutOnboardingInformationDto';

const mapRegisteredAddressTheSameAsPrimary = (checkoutOnboardingInformation: CheckoutOnboardingInformationDto) => {
  if (!checkoutOnboardingInformation.registeredAddress) return undefined;
  return checkoutOnboardingInformation.primaryAddress?.addressLine1 === checkoutOnboardingInformation.registeredAddress?.addressLine1 &&
    checkoutOnboardingInformation.primaryAddress?.city === checkoutOnboardingInformation.registeredAddress?.city &&
    checkoutOnboardingInformation.primaryAddress?.zip === checkoutOnboardingInformation.registeredAddress?.zip &&
    checkoutOnboardingInformation.primaryAddress?.country === checkoutOnboardingInformation.registeredAddress?.country;
};
const mapTradingNameTheSameAsLegalName = (checkoutOnboardingInformation: CheckoutOnboardingInformationDto) => {
  if (!checkoutOnboardingInformation.legalName) return undefined;
  return checkoutOnboardingInformation.tradingName === checkoutOnboardingInformation.legalName;
};
export const mapCheckoutInformationDtoToDomain = (intl: IntlShape) => (dto: CheckoutOnboardingInformationDto): CheckoutOnboardingInformationDraft => ({
  businessRegistrationNumber: dto.businessRegistrationNumber,
  dateOfIncorporation: mapDayjsDate({ date: dto.dateOfIncorporation, format: 'MM/YYYY' }),
  businessType: dto.businessType,
  legalName: dto.legalName,
  isTradingNameTheSameAsLegalName: mapTradingNameTheSameAsLegalName(dto),
  tradingName: dto.tradingName,
  registeredAddress: {
    addressLine1: dto.registeredAddress?.addressLine1,
    city: dto.registeredAddress?.city,
    zip: dto.registeredAddress?.zip,
    state: dto.registeredAddress?.state,
    country: dto.registeredAddress?.country,
  },
  isPrimaryAddressTheSameAsRegistered: mapRegisteredAddressTheSameAsPrimary(dto),
  primaryAddress: {
    addressLine1: dto.primaryAddress?.addressLine1,
    city: dto.primaryAddress?.city,
    zip: dto.primaryAddress?.zip,
    state: dto.primaryAddress?.state,
    country: dto.primaryAddress?.country,
  },
  contact: {
    phone: dto.contact?.phone,
    email: dto.contact?.email,
  },
  representatives: (dto.representatives ?? []).filter(r => r.type === 'individual').map(representative => {
    return {
      firstName: representative.firstName,
      lastName: representative.lastName,
      address: {
        addressLine1: representative.address?.addressLine1,
        city: representative.address?.city,
        zip: representative.address?.zip,
        state: representative.address?.state,
        country: representative.address?.country,
      },
      identificationType: representative.identificationType,
      identificationFrontDocument: representative.identificationFrontDocument,
      identificationBackDocument: representative.identificationBackDocument,
      dateOfBirth: mapDayjsDate({ date: representative.dateOfBirth, format: 'DD/MM/YYYY' }),
      countryOfBirth: representative.countryOfBirth,
      nationalIdNumber: representative.nationalIdNumber,
      ownershipPercentage: representative.ownershipPercentage,
      email: representative.email,
      companyPosition: representative.companyPosition,
      certifiedAuthorisedSignatoryDocument: representative.certifiedAuthorisedSignatoryDocument,
      roles: {
        ubo: representative?.roles?.includes('ubo'),
        legalRepresentative: representative?.roles?.includes('legal_representative'),
        authorisedSignatory: representative?.roles?.includes('authorised_signatory'),
        control: representative?.roles?.includes('control_person'),
        director: representative?.roles?.includes('director'),
      },
    };
  }),
  controllingCompanies: (dto.representatives ?? []).filter(r => r.type === 'company').map(representative => {
    return {
      ownershipPercentage: representative.ownershipPercentage,
      address: {
        country: representative.address?.country,
      },
      legalName: representative.legalName,
      tradingName: representative.tradingName,
      isTradingNameTheSameAsLegalName: representative.legalName === representative.tradingName,
    };
  }),
  bankAccount: {
    bic: dto.bankAccount?.bic,
    iban: dto.bankAccount?.iban,
    accountNumber: dto.bankAccount?.accountNumber,
    bankCode: dto.bankAccount?.bankCode,
    bankStatement: dto.bankAccount?.bankStatement,
  },
  annualProcessingVolume: dto.annualProcessingVolume,
  averageTransactionValue: dto.averageTransactionValue,
  highestTransactionValue: dto.highestTransactionValue,
  incorporationDocument: dto.incorporationDocument,
  articlesOfAssociationDocument: dto.articlesOfAssociationDocument,
  shareholderStructureDocument: dto.shareholderStructureDocument,
  proofOfPrincipalAddressDocument: dto.proofOfPrincipalAddressDocument,
  einDocument: dto.einDocument,
  consent: dto.consent,
  requirementsDue: dto.requirementsDue?.map(requirement => {
    const fieldLabel = getRequirementDueFieldLabel(intl)(requirement.field, dto.representatives ?? []);
    return {
      field: requirement.field,
      fieldLabel: fieldLabel,
      reason: getRequirementsReasonTranslation(intl)(requirement.reason),
      message: requirement.message,
    };
  },
  ),
  status: dto.status,
});
