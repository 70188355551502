import React from 'react';
import { IconButton, Tooltip } from '@sundayapp/b2b-react-component-library';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { FormattedMessage, IntlShape, useIntl } from 'src/app/i18n/TypedIntl';
import { PaymentForPat } from '../domain/Payment';
import { Box, Chip } from '@mui/material';
import { EmptyTableCellValue } from './EmptyTableCellValue';

interface Props {
  payment: PaymentForPat;
}

const computePosStatus = (payment: PaymentForPat): BadgePosStatus => {
  if (!payment.posStatus || payment.payment.status !== 'succeeded') {
    return 'NOT_APPLICABLE';
  }
  switch (payment.posStatus?.status) {
    case 'ENGAGED':
    case 'REFUSED':
    case 'SUCCEEDED':
    case 'UNFULFILLED':
      return 'NOT_NOTIFIED';
    case 'REGISTERED':
      return 'NOTIFIED';
    case 'NOTIFIED':
      return 'PENDING';
    default:
      return 'NOT_APPLICABLE';
  }
};

type BadgePosStatus = 'NOTIFIED' | 'PENDING' | 'NOT_NOTIFIED' | 'NOT_APPLICABLE';

const knownPosStatuses = [
  'AMOUNT_TOO_BIG',
  'DEVELOPER_ERROR',
  'ORDER_CLOSED',
  'ORDER_LOCKED',
  'ORDER_NOT_FOUND',
  'POS_MISCONFIGURED',
  'POS_NOT_AVAILABLE',
  'RATE_LIMIT',
  'SUNDAY_PAYMENT_METHOD_NOT_FOUND',
] as const;

function getCauseMessage(intl: IntlShape, payment: PaymentForPat) {
  const key = knownPosStatuses.find((status) => status === payment.posStatus?.cause) ?? 'UNRECOGNIZED';
  return intl.formatMessage({ id: `payment.pos_status.error.${key}` });
}

const PaymentPosStatusBadge: React.FC<Props> = ({ payment }: Props) => {
  const intl = useIntl();
  const status = computePosStatus(payment);

  if (status === 'NOT_APPLICABLE') {
    return <EmptyTableCellValue />;
  }

  const badge = <Box display="flex" alignItems="center">
    <Chip color={status === 'NOTIFIED' ? 'success' : 'warning'} data-testid="pos-status-badge"
          label={<FormattedMessage id={`payments.pos_status.${status}`} />} />
    {status === 'NOT_NOTIFIED' && (
      <IconButton size="small" variant="withoutBorders">
        <InfoIcon />
      </IconButton>
    )}
  </Box>;

  if (status === 'NOT_NOTIFIED') {
    return (
      <Tooltip title={getCauseMessage(intl, payment)} direction="left">
        {badge}
      </Tooltip>
    );
  }

  return badge;
};

export default PaymentPosStatusBadge;
