/* eslint-disable class-methods-use-this */
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { EnrichmentPlugin, Event, SpecialEventType } from '@amplitude/analytics-types';

type Business = {
  venueCountryCode: string,
  venueName: string,
  venueId: string,
  businessId: string,
  businessName: string,
};

export class TrackingContextEventEnrichmentPlugin implements EnrichmentPlugin {
  name = 'context-event-enrichment-plugin';

  type = 'enrichment' as any;

  private static business?: Business = undefined;

  async setup(): Promise<void> {
    return undefined;
  }

  async execute(event: Event): Promise<Event | null> {
    if (
      event.event_type === SpecialEventType.IDENTIFY ||
        event.event_type === SpecialEventType.REVENUE ||
        event.event_type === SpecialEventType.GROUP_IDENTIFY
    ) {
      return event;
    }

    if (TrackingContextEventEnrichmentPlugin.business) {
      // eslint-disable-next-line no-param-reassign
      event.event_properties = { ...event.event_properties, ...TrackingContextEventEnrichmentPlugin.business };
    }

    // Allow other events to be processed and sent to destination plugins
    return event;
  }

  static setVenue(business: Business) {
    TrackingContextEventEnrichmentPlugin.business = { ...business };
  }
}
