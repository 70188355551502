import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpSharedDeviceRepository } from '../infrastructure/HttpSharedDeviceRepository';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from 'src/app/queries/utils';


export const useGetInvitations = (businessId: string) => {
  const configuration = ConfigurationLoader.load();
  const httpBusinessesRepository = new HttpSharedDeviceRepository(configuration.waiterAppBackendUrl);

  return useQuery({
    queryKey: [queryKeys.waiters.LIST_INVITATIONS, { businessId }],
    queryFn: () => httpBusinessesRepository.listInvitations(businessId),
  });
};
