import React from 'react';
import { styled } from '@mui/material';
import { ProductItem } from './ProductItem';
import { ProductSummary } from '../../../domain/ProductSummary';
import { ElementSummary } from '../../../domain/Category';
import { isProduct } from '../../../repositories/ProductDto';
import { Block } from '../../../domain/Block';
import { BlockItem } from './BlockItem';
import { BusinessId } from 'src/business/domain/Business';

const ProductsTableWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 24px;
  align-items: center;
`;

const ElementContainer = styled('div')`
  width: 339px;
`;

type ProductsTableProps = {
  isSundayMenu: boolean;
  menuId: string;
  categoryId: string;
  onProductDeleted: (productId: string) => void;
  businessId: BusinessId;
  elements: ElementSummary[];
  contextProductList: string[];
  contextBlockList: string[];
};

const ElementsTable = ({
  menuId,
  elements,
  businessId,
  isSundayMenu,
  onProductDeleted,
  contextBlockList,
  contextProductList,
}: ProductsTableProps) => {
  return (
    <ProductsTableWrapper>
      {elements.map((element) => (
        <ElementContainer key={element.id}>
          {isProduct(element.type) ? (
            <ProductItem
              isDeletable={isSundayMenu}
              onProductDeleted={onProductDeleted}
              menuId={menuId}
              product={element as ProductSummary}
              businessId={businessId}
              contextProductList={contextProductList}
            />
          ) : (
            <BlockItem businessId={businessId} block={element as Block} contextBlockList={contextBlockList} />
          )}
        </ElementContainer>
      ))}
    </ProductsTableWrapper>
  );
};

export default ElementsTable;
