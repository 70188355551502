import { combineReducers } from 'redux';
import { ordersReducer } from '../../orders/redux';
import { venueReducer } from '../../venues/redux';
import { default as areasReducer } from '../../area/redux/slice';

export const getOrderingReducers = () => combineReducers({
  areas: areasReducer,
  orders: ordersReducer,
  venue: venueReducer,
});
