import * as React from 'react';
import { useEffect, useState } from 'react';
import { useListPaymentTerminalConfigurations } from '../queries/listPaymentTerminalConfigurationsQuery';
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { usePaymentTerminalProvisioningUpdateMutation } from '../mutations/usePaymentTerminalProvisioningMutation';
import { PaymentTerminalModal } from './PaymentTerminalModal';

interface Props {
  enrollmentId: EnrollmentId
  terminalId: string
  terminalName: string
  configurationId: string
  isOpen: boolean
  onClose: () => void
}

const ModifyPaymentTerminalModal = ({
  enrollmentId,
  terminalId,
  terminalName,
  configurationId,
  isOpen,
  onClose,
}: Props) => {
  const paymentTerminalConfigurations = useListPaymentTerminalConfigurations(enrollmentId).data;
  const [paymentTerminalName, setPaymentTerminalName] = useState<string>(terminalName);
  const [paymentTerminalConfiguration, setPaymentTerminalConfiguration] = useState<string>('');
  const updateProvision = usePaymentTerminalProvisioningUpdateMutation(enrollmentId, terminalId);

  useEffect(() => {
    setPaymentTerminalConfiguration(configurationId!!);
  }, [isOpen]);

  const doUpdate = () => {
    updateProvision.mutateAsync({
      name: paymentTerminalName,
      configurationId: paymentTerminalConfiguration,
    }).then(() => onClose());
  };

  return (
    <PaymentTerminalModal terminalName={paymentTerminalName}
                          setPaymentTerminalName={setPaymentTerminalName}
                          paymentTerminalConfigurations={paymentTerminalConfigurations!!}
                          setPaymentTerminalConfiguration={setPaymentTerminalConfiguration}
                          paymentTerminalConfiguration={paymentTerminalConfiguration}
                          onClose={onClose}
                          isOpen={isOpen}
                          action={doUpdate}
                          edit
    />
  );
};

export default ModifyPaymentTerminalModal;
