import { IconButton, SvgMail, Tooltip } from '@sundayapp/b2b-react-component-library';
import React, { useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { Payment } from '../domain/Payment';
import { EmailReceiptAndRefundModal } from './EmailReceiptAndRefundModal';
import { EmailReceiptModal } from './EmailReceiptModal';
import { useShowReceiptErrorNotification } from './useShowReceiptErrorNotification';

type EmailReceiptModalProps = {
  payment: Payment | null;
  isGrouped: boolean;
  orderId?: string;
  serviceType: ServiceType;
};

export const EmailReceipt = ({ payment, isGrouped, orderId, serviceType }: EmailReceiptModalProps) => {
  const intl = useIntl();
  const business = useCurrentBusinessOrThrow();
  const [isModalOpened, updateModalVisibility] = useState<boolean>(false);
  const showReceiptErrorNotification = useShowReceiptErrorNotification();

  const handleEmailReceipt = () => {
    updateModalVisibility(true);
  };

  if (
    serviceType === ServiceType.ORDER_AND_PAY &&
    ((payment?.orderId === undefined && orderId === undefined) || isGrouped)
  ) {
    return <></>;
  }

  const paymentHasRefunds = payment && payment.refunds.length > 0;
  const paymentHasNoRefunds = (payment?.refunds || []).length === 0;

  return (
    <>
      <Tooltip direction="left" title={intl.formatMessage({ id: 'payments.table.tooltip.email' })}>
        <IconButton data-testid={`arrow-${payment?.id ?? orderId}`} size="medium" onClick={handleEmailReceipt}>
          <SvgMail />
        </IconButton>
      </Tooltip>
      {isModalOpened && paymentHasNoRefunds && (
        <EmailReceiptModal
          payment={payment}
          orderId={orderId}
          serviceType={serviceType}
          onClose={() => updateModalVisibility(false)}
          onSendReceiptError={showReceiptErrorNotification}
        />
      )}
      {isModalOpened && paymentHasRefunds && (
        <EmailReceiptAndRefundModal
          businessId={business.id}
          payment={payment}
          orderId={orderId}
          serviceType={serviceType}
          onClose={() => updateModalVisibility(false)}
          onSendReceiptError={showReceiptErrorNotification}
        />
      )}
    </>
  );
};
