import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import ErrorContainer from '../../../common/components/ErrorContainer';
import Spinner from '../../../common/components/Spinner';
import {
  AvailabilityGridContainer,
  AvailabilityLayout,
  AvailabilityListContainer,
  AvailabilityStyledCustomScroll,
} from './AvailabilityList.style';
import {
  useGetMenus,
  useGetMenuWithProducts,
  useGetProductDetailsWithAvailabilities,
  useUpdateProductAvailabilities,
} from '../../../orderingMenus/hooks';
import { DetailedProductAvailability, ProductAvailability, UpdateProductAvailability } from '../../types';
import {
  AvailabilityFilterTags,
  LIMITED_STOCK_FILTER,
  OUT_OF_STOCK_FILTER,
} from '../../components/AvailabilityFilterTags/AvailabilityFilterTags';
import { AvailabilityDataGrid } from '../../components/AvailabilityDataGrid/AvailabilityDataGrid';
import { OrderingMenu } from '../../../orderingMenus/domain/OrderingMenu';
import { usePushNotification } from '../../../common/components/Notifications.hook';

export type AvailabilityListProps = {
  boxId: string;
};

const A_WEEK_IN_MS = 7 * 24 * 3600 * 1000;

export const AvailabilityList = ({ boxId }: AvailabilityListProps) => {
  const [, getMenuProducts] = useGetMenuWithProducts();
  const [, getProductDetailsWithAvailabilities] = useGetProductDetailsWithAvailabilities();
  const [, updateProductAvailabilities] = useUpdateProductAvailabilities(boxId);
  const [productDetails, setProductsDetails] = useState<DetailedProductAvailability[]>();
  const [menus, setMenus] = useState<OrderingMenu[]>();
  const [, getMenus] = useGetMenus();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error>();
  const [currentFilter, setCurrentFilter] = useState<string | undefined>();
  const { formatMessage } = useIntl();
  const [, pushNotification] = usePushNotification();
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date().getMinutes());

  const timeout = setTimeout(
    () => {
      setLastRefreshTime(new Date().getSeconds());
    },
    5 * 60 * 1000,
  );

  const filteredData = useMemo(() => {
    clearTimeout(timeout);
    if (!productDetails) return undefined;
    if (!currentFilter) return productDetails;
    if (currentFilter === LIMITED_STOCK_FILTER) {
      return productDetails.filter((p) => p.stock !== undefined && p.stock !== null);
    }
    if (currentFilter === OUT_OF_STOCK_FILTER) {
      return productDetails.filter((p) => p.stock === 0);
    }
    // Else filter by menu
    return productDetails.filter((p) => p.menuIds.indexOf(currentFilter) !== -1);
  }, [currentFilter, productDetails]);

  const numberOfOutOfStockProducts = useMemo(
    () => (productDetails ? productDetails.filter((p) => p.stock === 0).length : 0),
    [productDetails],
  );

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    setProductsDetails(undefined);
    getProductDetailsWithAvailabilities(boxId)
      .then((availabilities) => {
        getMenus(boxId).then((boxMenus) => {
          // Keep only active menus or menus published this week
          const recentMenus = boxMenus.filter(
            (m) => m.published || (m.lastPublishedAt && m.lastPublishedAt > Date.now() - A_WEEK_IN_MS),
          );
          setMenus(recentMenus);
          // Display only products of recentMenus
          const newAvailabilities = availabilities.filter((p) =>
            p.menuIds.some((menuId) => recentMenus.find((m) => m.id === menuId)),
          );
          newAvailabilities.sort((p1, p2) => p1.name.localeCompare(p2.name));
          setProductsDetails(newAvailabilities);
        });
      })
      .catch(() => setError({ name: 'Load error', message: 'Failed to load products' }))
      .finally(() => setLoading(false));
  }, [
    boxId,
    getMenuProducts,
    getMenus,
    setProductsDetails,
    setLoading,
    setError,
    getProductDetailsWithAvailabilities,
    lastRefreshTime, // force the refresh of the stocks
  ]);

  const updateAvailability = useCallback(
    (updateProductAvailability: UpdateProductAvailability) => {
      updateProductAvailabilities([updateProductAvailability]).then(
        async (updatedAvailabilities: ProductAvailability[]) => {
          if (productDetails && updateProductAvailability.sku) {
            const updatedQuantity = updatedAvailabilities
              .filter((p) => p.sku === updateProductAvailability.sku)[0]?.quantity;
            productDetails
              .filter((p) => p.sku === updateProductAvailability.sku)
              .forEach((p) => {
                // eslint-disable-next-line no-param-reassign
                p.stock = updatedQuantity;
              });
            setProductsDetails([...productDetails]); // Force refresh of memos
            await pushNotification(
              formatMessage({ id: 'product.availability.updated', defaultMessage: 'item availability updated' }),
            );
          }
        },
      );
    },
    [formatMessage, productDetails, pushNotification, updateProductAvailabilities],
  );

  return (
    <AvailabilityLayout>
      <AvailabilityFilterTags
        menus={menus}
        setCurrentFilter={setCurrentFilter}
        numberOfOutOfStockProducts={numberOfOutOfStockProducts}
      />
      <AvailabilityStyledCustomScroll>
        <AvailabilityListContainer>
          <ErrorContainer error={error} />
          {loading ? (
            <Spinner />
          ) : (
            <AvailabilityGridContainer>
              {filteredData && (
                <AvailabilityDataGrid productDetails={filteredData} updateAvailability={updateAvailability} />
              )}
            </AvailabilityGridContainer>
          )}
        </AvailabilityListContainer>
      </AvailabilityStyledCustomScroll>
    </AvailabilityLayout>
  );
};
