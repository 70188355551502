import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const TradingBusinessName = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const tradingNameError = errors.tradingName;
  const intl = useIntl();

  return <TextField
    required
    error={!!tradingNameError}
    {...register('tradingName', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.tradingName.error.required' }) })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.tradingName' })}
    helperText={tradingNameError ? tradingNameError.message : ''}
  />;
};
