import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
  thick?: number;
  strokeSize?: number;
}

export const CircleLineSvg: React.FC<Props> = ({ size = 17, thick = 8, strokeSize = 2, color = themeV5.palette.text.primary, ...props }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={thick} viewBox="0 0 12 12" fill="none" {...props}>
        <circle cx="8.33301" cy="4" r="3.5" fill={color} stroke={color}/>
        <path d="M0.333008 4H16.333" stroke={color} strokeWidth={strokeSize}/>
    </svg>
);