import React, { useMemo } from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Area } from '../../../domain/Area';
import { AreaDetailsService } from './AreaDetailsService';
import { areaConfigurationToMenuAssociations } from '../../../utils/areaConfigurationToMenuAssociations';
import { FulfillmentType } from '../../../domain/FulfillmentType';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import { AreaDetailsGroup } from './AreaDetailsGroup';
import { BusinessId } from 'src/business/domain/Business';
import { useGetMenuBusiness } from 'src/menu/common/queries/getMenuBusinessQuery';
import { CircularProgress } from '@mui/material';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

type AreaDetailsNameSettingProps = {
  businessId: BusinessId;
  menus: OrderingMenu[];
  area: Area;
  setArea: (updatedArea: Area) => void;
};

const configuration = ConfigurationLoader.load();

export const AreaDetailsServiceSettings = ({
  businessId, menus, area, setArea,
}: AreaDetailsNameSettingProps) => {
  const intl = useIntl();

  const { data: menuBusiness, isLoading } = useGetMenuBusiness(businessId);

  const serviceAssociations = useMemo((): Map<FulfillmentType, string[]> => areaConfigurationToMenuAssociations(area.configurations), [area]);

  const clickAndCollectDisabledMessage = useMemo((): string | undefined => {
    const isClickAndCollectDisabled = Array.from(serviceAssociations.keys()).find((item) => item !== FulfillmentType.CLICK_AND_COLLECT) !== undefined;
    return isClickAndCollectDisabled
      ? intl.formatMessage({
        id: 'settings.area.cc-not-compatible',
        defaultMessage: 'Non compatible with other services',
      })
      : undefined;
  }, [intl, serviceAssociations]);

  const nonClickAndCollectDisabledMessage = useMemo((): string | undefined => {
    const isClickAndCollectDisabled = serviceAssociations.has(FulfillmentType.CLICK_AND_COLLECT);
    return isClickAndCollectDisabled
      ? intl.formatMessage({
        id: 'settings.area.not-cc-not-compatible',
        defaultMessage: 'Not compatible with Click & Collect',
      })
      : undefined;
  }, [intl, serviceAssociations]);

  const hybridOrderingNotAvailableMessage = useMemo((): string | undefined => {
    if (menuBusiness?.orderingAllowed) {
      return undefined;
    }

    return intl.formatMessage({
      id: 'settings.area.hybrid-not-available',
      defaultMessage: 'Hybrid ordering is not available',
    });
  }, [intl, menuBusiness]);

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <AreaDetailsGroup
      title={intl.formatMessage({
        id: 'settings.area.details.services-group.title',
        defaultMessage: 'services of your area',
      })}
    >
      <AreaDetailsService
        businessId={businessId}
        area={area}
        setArea={setArea}
        key={FulfillmentType.EAT_IN_SERVICE_AT_TABLE}
        service={FulfillmentType.EAT_IN_SERVICE_AT_TABLE}
        menus={menus}
        menuIds={serviceAssociations.get(FulfillmentType.EAT_IN_SERVICE_AT_TABLE) || []}
        disabledMessage={nonClickAndCollectDisabledMessage}
      />
      {(configuration.enableHybridOrderingAreas || serviceAssociations.has(FulfillmentType.HYBRID_ORDERING)) && (
        <AreaDetailsService
          businessId={businessId}
          area={area}
          setArea={setArea}
          key={FulfillmentType.HYBRID_ORDERING}
          service={FulfillmentType.HYBRID_ORDERING}
          menus={menus}
          menuIds={serviceAssociations.get(FulfillmentType.HYBRID_ORDERING) || []}
          disabledMessage={hybridOrderingNotAvailableMessage ?? nonClickAndCollectDisabledMessage}
        />
      )}
      <AreaDetailsService
        businessId={businessId}
        area={area}
        setArea={setArea}
        key={FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER}
        service={FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER}
        menus={menus}
        menuIds={serviceAssociations.get(FulfillmentType.EAT_IN_PICK_UP_AT_COUNTER) || []}
        disabledMessage={nonClickAndCollectDisabledMessage}
      />
      <AreaDetailsService
        businessId={businessId}
        area={area}
        setArea={setArea}
        key={FulfillmentType.TAKE_AWAY}
        service={FulfillmentType.TAKE_AWAY}
        menus={menus}
        menuIds={serviceAssociations.get(FulfillmentType.TAKE_AWAY) || []}
        disabledMessage={nonClickAndCollectDisabledMessage}
      />
      <AreaDetailsService
        businessId={businessId}
        area={area}
        setArea={setArea}
        key={FulfillmentType.CLICK_AND_COLLECT}
        service={FulfillmentType.CLICK_AND_COLLECT}
        menus={menus}
        menuIds={serviceAssociations.get(FulfillmentType.CLICK_AND_COLLECT) || []}
        disabledMessage={clickAndCollectDisabledMessage}
      />
      <AreaDetailsService
        businessId={businessId}
        area={area}
        setArea={setArea}
        key={FulfillmentType.PRE_ORDERING}
        service={FulfillmentType.PRE_ORDERING}
        menus={menus}
        menuIds={serviceAssociations.get(FulfillmentType.PRE_ORDERING) || []}
        disabledMessage={nonClickAndCollectDisabledMessage}
      />
    </AreaDetailsGroup>
  );
};
