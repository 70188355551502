import { WaiterPort } from '../domain/WaiterPort';
import axios from 'axios';
import {
  DirectTippingConfiguration, DirectTippingConfigurationZ,
  Waiter,
  WaiterHistory,
  WaiterHistoryZ,
  AllWaiters,
  AllWaitersZ,
  WaiterWithHistory, WaiterWithHistoryZ,
  WaiterZ,
} from '../domain/AllWaiters';

export class WaiterHttpPort implements WaiterPort {
  constructor(private waiterUrl: string) {
  }

  async getConfiguration(businessId: string): Promise<DirectTippingConfiguration> {
    return axios
      .get(`${this.waiterUrl}/direct-tipping/${businessId}`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = DirectTippingConfigurationZ.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  }

  async getHistory(businessId: string, waiterId: string): Promise<WaiterHistory[]> {
    return axios
      .get(`${this.waiterUrl}/direct-tipping/${businessId}/waiters/${waiterId}/history`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = WaiterHistoryZ.array()
          .safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  }

  async getWaiters(businessId: string): Promise<AllWaiters> {
    return axios
      .get(`${this.waiterUrl}/direct-tipping/${businessId}/waiters`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = AllWaitersZ.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  }

  async updateRate(businessId: string, waiterId: string, rate: number, enrollmentId: string): Promise<Waiter> {
    return axios
      .post(`${this.waiterUrl}/direct-tipping/${businessId}/waiters/${waiterId}/rate`, { rate, enrollmentId })
      .then((response) => {
        const safeParse = WaiterZ.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  }

  async getWaiterWithHistory(businessId: string, waiterId: string): Promise<WaiterWithHistory> {
    return axios
      .get(`${this.waiterUrl}/direct-tipping/${businessId}/waiters/${waiterId}`, {
        headers: {
          accept: 'application/json',
        },
      })
      .then((response) => {
        const safeParse = WaiterWithHistoryZ.safeParse(response.data);
        if (safeParse.success) {
          return safeParse.data;
        }
        throw Error(`fail parsing ${JSON.stringify(response.data)}, parsing error=${safeParse.error}`);
      });
  }
}
