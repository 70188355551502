import React, { useCallback, useState } from 'react';
import { BoxSelector } from '../../../box/components';
import { Box } from '../../../box/domain/Box';
import { AvailabilityList } from './AvailabilityList';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export type AvailabilityListProps = {
  enrollmentId: EnrollmentId;
};

export const OPProductAvailability = ({ enrollmentId }: AvailabilityListProps) => {
  const [selectedBoxId, setSelectedBoxId] = useState<string>();
  const onBoxChanged = useCallback(
    (box: Box) => {
      setSelectedBoxId(box ? box.id : undefined);
    },
    [setSelectedBoxId],
  );

  return (
    <>
      <BoxSelector enrollmentId={enrollmentId} onBoxSelected={onBoxChanged} onBoxUnselected={() => setSelectedBoxId(undefined)} />
      {selectedBoxId && <AvailabilityList boxId={selectedBoxId} />}
    </>
  );
};
