import * as React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import TabPanel from '../../app/component/Tab/TabPanel';
import { HowtoAccess } from './HowtoAccess';
import { SundayForRestaurantsAppSettings } from './SundayForRestaurantsAppSettings';

export const SundayForRestaurants = () => {
  const intl = useIntl();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label={intl.formatMessage({ id: 'sunday_for_restaurants.tab.howtoaccess' })} style={{ fontSize: '16px' }} />
        <Tab
          label={intl.formatMessage({ id: 'sunday_for_restaurants.tab.settings' })}
          style={{ fontSize: '16px' }}
          iconPosition="end"
        />
      </Tabs>
      <Divider />

      <TabPanel value={value.toString()} index="0">
        <HowtoAccess />
      </TabPanel>

      <TabPanel value={value.toString()} index="1">
        <SundayForRestaurantsAppSettings />
      </TabPanel>
    </>
  );
};
