import styled from 'styled-components';
import { FoodCourt as OPFoodCourt } from '../../ordering/foodCourt/pages/FoodCourt';
import { PAGE_CONTENT_WIDTH } from 'src/app/pages/constants';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

const PageContainer = styled.div`
  max-width: ${PAGE_CONTENT_WIDTH}px;
`;

export const FoodCourt = () => {
  const business = useCurrentBusinessOrThrow();

  if (!business.oapEnrollment) {
    return <PageContainer></PageContainer>;
  }

  return (
    <PageContainer>
      <OPFoodCourt enrollmentId={business.oapEnrollment.id} />
    </PageContainer>
  );
};
