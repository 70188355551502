import { CircularProgress, Paper, Stack } from '@mui/material';
import React from 'react';
import { useBusinessIdOrThrow } from '../business/hooks/useBusinessId';
import { useGetBusinessLoyaltyReference } from './queries/useGetBusinessLoyaltyReference';
import { LoyaltyOnboardingForms } from './LoyaltyOnboardingForms';
import { OnboardingHeader } from './OnboardingHeader';

export const LoyaltyOnboarding = () => {
  const businessId = useBusinessIdOrThrow();
  const {
    data: loyaltyBusiness,
    isLoading: loyaltyReferenceIsLoading,
  } = useGetBusinessLoyaltyReference(businessId);

  if (loyaltyReferenceIsLoading) {
    return <CircularProgress />;
  }

  return (
    <Paper style={{ borderRadius: '1.5rem', maxWidth: '1000px' }} elevation={0}>
      <Stack margin="24px" gap={3} width="inherit">
        {loyaltyBusiness && (
          <OnboardingHeader
            businessId={businessId}
            loyaltyReference={loyaltyBusiness.loyaltyReference}
            theme={loyaltyBusiness.theme}
          />
        )}
        <LoyaltyOnboardingForms loyaltyReference={loyaltyBusiness?.loyaltyReference} />
      </Stack>
    </Paper>
  );
};
