import React, { useState } from 'react';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Menu } from '../../../domain/Menu';
import CategoryCreationModal from './CategoryCreationModal';

type Props = { menu: Menu };
export const AddCategoryButton = ({ menu }: Props) => {
  const [isCategoryCreationModalOpen, setIsCategoryCreationModalOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        startIcon={<AddIcon />}
        onClick={() => {
          setIsCategoryCreationModalOpen(true);
        }}
      >
        <FormattedMessage id="menus.edition.add_category" />
      </Button>
      <CategoryCreationModal
        isOpen={isCategoryCreationModalOpen}
        onClose={() => setIsCategoryCreationModalOpen(false)}
        menu={menu}
      />
    </>
  );
};
