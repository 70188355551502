import axios, { AxiosResponse } from 'axios';
import { format, formatISO } from 'date-fns';
import { CurrencyCode, Money } from '@sundayapp/web-money';
import { CashupStatus, ReconciliationReport, ReportId } from '../domain/ReconciliationReport';
import { PaymentOrigin } from '../domain/PaymentOrigin';
import { PaymentData } from '../domain/PaymentData';
import { Method } from '../domain/Reconciliation';
import { ReportSettings } from '../domain/ReportSettings';
import { BlobWithMetadata } from 'src/accounting/payout/domain/BlobWithMetadata';
import { sleep } from 'src/utils/AsyncUtils';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { ReconciliationReportRestaurantShiftRequest } from '../domain/ReconciliationReportRestaurantShiftRequest';
import { ReconciliationConfiguration } from '../domain/ReconciliationConfiguration';

const jsonHeaders = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const baseUrl = ConfigurationLoader.load().reconciliationApiBaseUrl;

export class ReconciliationRepositoryHttp {
  private static async retry<T = any>(call: () => Promise<AxiosResponse<T>>, count: number, retryCondition: (response: AxiosResponse<T>) => boolean, delay: number) {
    let retryCount = 0;
    let response: AxiosResponse<T>;
    while (retryCount <= count) {
      // eslint-disable-next-line no-await-in-loop
      response = await call();
      if (retryCondition(response)) {
        // eslint-disable-next-line no-await-in-loop
        await sleep(delay);
        retryCount += 1;
      } else {
        break;
      }
    }
    return response!;
  }

  async createReport(businessId: BusinessId, currency: CurrencyCode, reportRestaurantShift: ReconciliationReportRestaurantShiftRequest, reporter: string): Promise<ReportId> {
    const request = {
      shift: {
        name: reportRestaurantShift.name,
        dateOfService: formatISO(reportRestaurantShift.startAt, { representation: 'date' }),
        start: reportRestaurantShift.startAt,
        end: reportRestaurantShift.endAt,
      },
      currency: currency,
      reporter,
    };

    const response = await axios.post(`${baseUrl}/businesses/${businessId}/reports`, request, jsonHeaders);
    return ReportId.parse(response.data);
  }

  async getReport(businessId: BusinessId, reportId: string): Promise<ReconciliationReport> {
    const response = await axios.get(`${baseUrl}/businesses/${businessId}/reports/${reportId}`, jsonHeaders);
    return ReconciliationReport.parse(response.data);
  }

  async listAllReports(businessId: BusinessId): Promise<ReconciliationReport[]> {
    const response = await axios.get(`${baseUrl}/businesses/${businessId}/reports`, jsonHeaders);
    return ReconciliationReport.array().parse(response.data);
  }

  async updateTotalOnPos(businessId: BusinessId, report: ReconciliationReport, amount: Money): Promise<void> {
    await axios.patch(`${baseUrl}/businesses/${businessId}/reports/${report.id}/totalOnPos`, amount, jsonHeaders);
  }

  async updatePaymentData(businessId: BusinessId, report: ReconciliationReport, method: Method, origin: PaymentOrigin, amount: PaymentData): Promise<void> {
    await axios.patch(`${baseUrl}/businesses/${businessId}/reports/${report.id}/${method.name}/${origin}`, amount, jsonHeaders);
  }

  async finalizeReport(businessId: BusinessId, reportId: string): Promise<void> {
    await axios.put(`${baseUrl}/businesses/${businessId}/reports/${reportId}/finalize`, jsonHeaders);
  }

  async getReportSettings(businessId: BusinessId): Promise<ReportSettings | undefined> {
    const response = await axios.get(`${baseUrl}/businesses/${businessId}/settings/`, {
      ...jsonHeaders,
      validateStatus: (status) => status < 500,
    });
    return response.status === 200 ? ReportSettings.parse(response.data) : undefined;
  }

  async createDefaultReportSettings(businessId: BusinessId): Promise<ReportSettings> {
    const reportSettings = {
      useDefault: true,
    };
    const response = await axios.put(`${baseUrl}/businesses/${businessId}/settings/`, reportSettings, jsonHeaders);
    return ReportSettings.parse(response.data);
  }

  async updateReportSettings(businessId: BusinessId, reportSettings: ReportSettings): Promise<ReportSettings> {
    const response = await axios.put(`${baseUrl}/businesses/${businessId}/settings/`, reportSettings, jsonHeaders);
    return ReportSettings.parse(response.data);
  }

  async addComment(businessId: BusinessId, reportId: string, comment: string): Promise<void> {
    const reportComment = { comment };
    await axios.patch(`${baseUrl}/businesses/${businessId}/reports/${reportId}/comment`, reportComment, jsonHeaders);
  }

  async exportByShift(businessId: BusinessId, start: Date, end: Date, status?: CashupStatus): Promise<BlobWithMetadata> {
    const customJsonParams = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        start: format(start, 'yyyy-MM-dd'),
        end: format(end, 'yyyy-MM-dd'),
        status,
      },
    };
    const response = await axios.get<Blob>(`${baseUrl}/businesses/${businessId}/csv/`, customJsonParams);
    return ({
      blob: new Blob([response.data]),
    });
  }

  async exportByPaymentMethod(businessId: BusinessId, start: Date, end: Date, status?: CashupStatus): Promise<BlobWithMetadata> {
    const customJsonParams = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        start: format(start, 'yyyy-MM-dd'),
        end: format(end, 'yyyy-MM-dd'),
        status,
      },
    };
    const response = await axios.get<Blob>(`${baseUrl}/businesses/${businessId}/paymentmethodcsv`, customJsonParams);
    return ({
      blob: new Blob([response.data]),
    });
  }

  delete(businessId: BusinessId, reportId: string): Promise<void> {
    return axios.delete(`${baseUrl}/businesses/${businessId}/reports/${reportId}`, jsonHeaders);
  }

  async reopen(businessId: BusinessId, reportId: string): Promise<void> {
    await axios.put(`${baseUrl}/businesses/${businessId}/reports/${reportId}/reopen`, jsonHeaders);
  }

  async refreshTotalOnPosUsingPosData(businessId: BusinessId, reportId: string): Promise<void> {
    await ReconciliationRepositoryHttp.retry(
      () => axios.post(`${baseUrl}/businesses/${businessId}/reports/${reportId}/totalOnPos/refreshUsingPosData`,
        undefined,
        {
          ...jsonHeaders,
          validateStatus: (status) => status < 500,
        }),
      1,
      (resp) => resp.status === 425,
      2000,
    );
  }

  async getReconciliationConfiguration(businessId: BusinessId): Promise<ReconciliationConfiguration> {
    const response = await axios.get(`${baseUrl}/businesses/${businessId}/activation-status`, {
      ...jsonHeaders,
      validateStatus: (status) => status < 500,
    });
    return response.status === 200 ? ReconciliationConfiguration.parse(response.data) : { isActivated: false, orphanPosLinesDisplayed: false };
  }
}
