import { Stack, Typography, useTheme } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { AccessTime, Money } from '@mui/icons-material';
import { DispatchStrategy } from 'src/tips/dispatch/model/DispatchStrategy';
import CloseIcon from '@mui/icons-material/Close';

const DistributionStrategyView = ({
  icon,
  title,
  description,
  onClick,
}: {
  icon: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  onClick: () => void;
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      padding={'0.5rem'}
      border={'1px solid'}
      borderRadius={'1rem'}
      width={'100%'}
      borderColor={theme.palette.secondary.light}
      sx={{
        ':hover': {
          borderColor: theme.palette.primary.main,
          backgroundColor: 'rgba(255, 23, 233, 0.04)',
        },
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {icon}
      <Stack direction="column" gap={1}>
        <Typography variant={'body1'} fontWeight={500}>
          {title}
        </Typography>
        <Typography variant={'body2'} color={'textSecondary'}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  );
};

type Props = {
  onClose: () => void;
  onStrategySelected: (strategy: DispatchStrategy) => void;
};

export const SelectDispatchStrategy = ({ onClose, onStrategySelected }: Props) => (
  <Stack
    sx={{
      background: 'white',
      height: '100%',
      overflow: 'auto',
      paddingBottom: '1rem',
    }}
    flexDirection="column"
  >
    <Stack direction="row" justifyContent="space-between" alignItems={'center'} padding="1rem 1.5rem 1rem 1.5rem">
      <Stack sx={{ cursor: 'pointer' }} onClick={onClose}>
        <CloseIcon />
      </Stack>
      <Stack alignItems={'center'}>
        <Typography>
          <FormattedMessage id={'tips.sunday_pooling.dispatch.title'} />
        </Typography>
      </Stack>
      <Stack></Stack>
    </Stack>

    <Stack
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'auto',
      }}
      padding="3rem 0 3rem 0"
      direction="column"
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Stack width="auto" height="100%" justifyContent={'space-between'} spacing={3}>
        <Stack direction="column" spacing={3}>
          <Typography variant={'h4'}>
            <FormattedMessage id={'tips.dispatch.strategy_selection.title'} />
          </Typography>
          <Stack direction="column" spacing={2}>
            <DistributionStrategyView
              icon={<Money fontSize="large" />}
              title={<FormattedMessage id={'tips.dispatch.strategy_selection.by_amount.title'} />}
              description={<FormattedMessage id={'tips.dispatch.strategy_selection.by_amount.sub_title'} />}
              onClick={() => onStrategySelected('byAmounts')}
            />
            <DistributionStrategyView
              icon={<AccessTime fontSize="large" />}
              title={<FormattedMessage id={'tips.dispatch.strategy_selection.by_hours.title'} />}
              description={<FormattedMessage id={'tips.dispatch.strategy_selection.by_hours.sub_title'} />}
              onClick={() => onStrategySelected('byHours')}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
