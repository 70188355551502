import {
  Box, Checkbox, Chip, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Typography,
} from '@mui/material';
import React from 'react';

export const MultipleChipSelect = ({
  label, options, onChangeMappedValues, selectedValues, disabledValues,
}: {
  label: string,
  options: { id: string, label: string }[],
  onChangeMappedValues: (e: SelectChangeEvent<string[]>) => void,
  selectedValues: string[],
  disabledValues?: string[],
}) => (
  <>
    <InputLabel id="mapped-value">
      <Typography>{label}</Typography>
    </InputLabel>
    <Select
      labelId="mapped-value"
      label={label}
      multiple
      fullWidth
      renderValue={(selected: string[]) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map((method: string) => (
            <Chip
              key={`mappedValues-${method}}`}
              label={options.find((element) => element.id === method)?.label ?? ''}
            />
          ))}
        </Box>
      )}
      onChange={(e: SelectChangeEvent<string[]>) => onChangeMappedValues(e)}
      value={selectedValues}
      sx={{ minWidth: '130px' }}
    >
      {options.map((method) => (
        <MenuItem
          key={`mappedValues.${method.id}`}
          value={method.id}
          disabled={disabledValues?.includes(method.id)}
        >
          <Checkbox checked={selectedValues.indexOf(method.id) > -1} />
          <ListItemText primary={`${method.label} (${method.id})`} />
        </MenuItem>
      ))}
    </Select>
  </>
);
