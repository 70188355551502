import { Money } from '@sundayapp/web-money';
import { useCallback, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  addFreshOrders,
  addOrders,
  addRefundToOrder,
  setOrderPaymentStatus,
  setOrders,
  setOrderStatus,
  setSelectedBoxOrder,
  setSelectedForDetails,
  setSelectedForRefund,
  setSelectedOrder,
} from './redux';

import { BoxOrderDetails, Order, OrderId, OrderPaymentStatus, OrderStatus, OrderSummary } from './types';
import { useAsyncFn } from '../common/utils/useAsyncFn';
import { PaymentSummaryStatus } from '../payment/types';
import { useOrderingPaymentRepository, usePaymentRepository } from '../payment/hook';
import { getVenueOrders } from './redux/selectors';
import { AcceptationMode } from './repositories/OrderingOrderRepository';
import { OrderConfigContext } from './configuration/OrderContextProvider';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const useOrderRepository = () => {
  const { orderRepository } = useContext(OrderConfigContext);
  return orderRepository!;
};

export const useGetPaginatedOrders = (enrollmentId: EnrollmentId, limit: number, boxId?: string, areaId?: string, orderDisplayId?: string) => {
  const orderRepository = useOrderRepository();
  const dispatch = useDispatch();
  return useAsyncFn<OrderSummary[], [startDate: Date, endDate: Date]>(
    async (startDate, endDate) => {
      const orders = await orderRepository.getOrders(
        enrollmentId,
        {
          startDate,
          endDate,
          boxId,
          areaId,
          orderDisplayId,
        },
        limit,
      );
      dispatch(setOrders(orders));
      return orders;
    },
    [orderRepository, dispatch, boxId],
  );
};

const computeNextPaginatedEndDate = (existingOrders: OrderSummary[], endDate: Date) => {
  if (existingOrders.length > 0) {
    const lastDate = existingOrders[existingOrders.length - 1].placedAtEpochMilli ?? Date.now();
    return new Date(lastDate - 1);
  }
  return endDate;
};

const computeRefreshOrderStartDate = (existingOrders: OrderSummary[], startDate: Date) => {
  if (existingOrders.length > 0) {
    const lastDate = existingOrders[0].placedAtEpochMilli ?? Date.now();
    return new Date(lastDate + 1);
  }

  return startDate;
};

export const useGetNextPaginatedOrders = (enrollmentId: EnrollmentId, limit: number, boxId?: string, areaId?: string, orderDisplayId?: string) => {
  const orderRepository = useOrderRepository();
  const dispatch = useDispatch();
  const existingOrders = useSelector(getVenueOrders);

  return useAsyncFn<OrderSummary[], [startDate: Date, endDate: Date]>(
    async (startDate, endDate) => {
      const additionalOrders = await orderRepository.getOrders(
        enrollmentId,
        {
          startDate,
          endDate: computeNextPaginatedEndDate(existingOrders, endDate),
          boxId,
          areaId,
          orderDisplayId,
        },
        limit,
      );

      if (additionalOrders.length > 0) {
        dispatch(addOrders(additionalOrders));
      }

      return additionalOrders;
    },
    [orderRepository, dispatch, boxId, existingOrders],
  );
};

export const useHasAdditionalOrders = (enrollmentId: EnrollmentId, boxId?: string, areaId?: string, orderDisplayId?: string) => {
  const orderRepository = useOrderRepository();
  const existingOrders = useSelector(getVenueOrders);

  return useAsyncFn<boolean, [startDate: Date]>(
    async (startDate) => {
      let endDate: Date;

      if (existingOrders.length > 0) {
        const lastDate = existingOrders[existingOrders.length - 1].placedAtEpochMilli ?? Date.now();
        endDate = new Date(lastDate - 1);
      } else {
        return false;
      }

      const additionalOrders = await orderRepository.getOrders(
        enrollmentId,
        {
          startDate,
          endDate,
          boxId,
          areaId,
          orderDisplayId,
        },
        1,
      );

      return additionalOrders.length > 0;
    },
    [orderRepository, boxId, existingOrders],
  );
};

export const useGetOrders = (enrollmentId: EnrollmentId, boxId: string) => {
  const orderRepository = useOrderRepository();

  return useAsyncFn<OrderSummary[], [startDate: Date, endDate: Date]>(
    async (startDate: Date, endDate: Date) => {
      return orderRepository.getOrders(enrollmentId, {
        startDate,
        endDate,
        boxId,
      });
    },
    [orderRepository, boxId],
  );
};

export const useGetUnpaidOrders = (enrollmentId: EnrollmentId) => {
  const orderRepository = useOrderRepository();

  return useAsyncFn<OrderSummary[], [startDate: Date, endDate: Date]>(
    async (startDate: Date, endDate: Date) => {
      return orderRepository.getOrders(enrollmentId, {
        startDate,
        endDate,
        orderPaymentStatus: 'TO_BE_PAID',
      });
    },
    [orderRepository],
  );
};

export const useGetFreshOrders = (enrollmentId: EnrollmentId, boxId?: string, areaId?: string, orderDisplayId?: string) => {
  const orderRepository = useOrderRepository();
  const dispatch = useDispatch();
  const existingOrders = useSelector(getVenueOrders);

  return useAsyncFn<OrderSummary[], [startDate: Date]>(
    async (startDate) => {
      const newOrders = await orderRepository.getOrders(enrollmentId, {
        startDate: computeRefreshOrderStartDate(existingOrders, startDate),
        endDate: new Date(Date.now()),
        boxId,
        areaId,
        orderDisplayId,
      });
      dispatch(addFreshOrders(newOrders));

      return newOrders;
    },
    [orderRepository, dispatch, boxId, existingOrders],
  );
};

export const useGetOrder = () => {
  const orderRepository = useOrderRepository();

  return useAsyncFn<Order, [orderId: OrderId]>(
    (orderId): Promise<Order> => {
      return orderRepository.getOrder(orderId);
    },
    [orderRepository],
  );
};

export const useSelectOrder = () => {
  const orderRepository = useOrderRepository();
  const dispatch = useDispatch();

  return useAsyncFn<void, [orderId: OrderId]>(
    async (orderId) => {
      const selectedOrder = await orderRepository.getOrder(orderId);
      dispatch(setSelectedOrder(selectedOrder));
    },
    [orderRepository, dispatch],
  );
};

export const useSelectBoxOrder = () => {
  const orderRepository = useOrderRepository();
  const dispatch = useDispatch();

  return useAsyncFn<BoxOrderDetails, [orderId: string]>(
    async (orderId) => {
      const selectedOrder = await orderRepository.getBoxOrder(orderId);
      dispatch(setSelectedBoxOrder(selectedOrder));
      return selectedOrder;
    },
    [orderRepository, dispatch],
  );
};

export const useSelectOrderForRefund = (orderId: OrderId) => {
  const dispatch = useDispatch();
  const [, selectOrder] = useSelectOrder();

  return useAsyncFn<void, []>(async () => {
    dispatch(setSelectedForRefund(orderId));
    return selectOrder(orderId);
  }, [dispatch, orderId, selectOrder]);
};

export const useAcceptOrder = (orderId: OrderId) => {
  const orderRepository = useOrderRepository();
  const [, selectOrder] = useSelectOrder();
  const dispatch = useDispatch();
  return useAsyncFn<void, [acceptationMode: AcceptationMode]>(
    async (acceptationMode: AcceptationMode) => {
      await orderRepository.acceptOrder(orderId, acceptationMode);
      dispatch(setOrderStatus({ orderId, status: OrderStatus.NOTIFIED_TO_DELIVERECT }));
    },
    [orderRepository, dispatch, orderId, selectOrder],
  );
};

export const useCancelOrder = (orderId: OrderId) => {
  const orderRepository = useOrderRepository();
  const dispatch = useDispatch();
  return useAsyncFn<void, []>(async () => {
    await orderRepository.cancelOrder(orderId, 'cancel from B2B');
    dispatch(setOrderStatus({ orderId, status: OrderStatus.CANCELED }));
  }, [orderRepository, dispatch, orderId]);
};

export const useSelectOrderForDetails = () => {
  const dispatch = useDispatch();
  const [, selectOrder] = useSelectOrder();
  const [, selectBoxOrder] = useSelectBoxOrder();

  return useAsyncFn<void, [orderId: OrderId]>(
    async (orderId) => {
      dispatch(setSelectedForDetails(orderId));
      await selectOrder(orderId);
    },
    [dispatch, selectOrder, selectBoxOrder],
  );
};

export const useUnselectOrderForDetails = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    batch(() => {
      dispatch(setSelectedBoxOrder());
      dispatch(setSelectedOrder());
      dispatch(setSelectedForDetails());
    });
  }, [dispatch]);
};

export const useUnselectOrderForRefund = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    batch(() => {
      dispatch(setSelectedForRefund());
    });
  }, [dispatch]);
};

export const useRefundPayment = () => {
  const dispatch = useDispatch();
  const paymentRepository = usePaymentRepository();
  const orderingPaymentsRepository = useOrderingPaymentRepository();
  return useAsyncFn<void, [paymentId: string, totalToRefund: Money, paidBySunday: boolean]>(
    async (paymentId, totalToRefund, paidBySunday) => {
      if (paidBySunday) {
        await paymentRepository.stripeRefund(paymentId, totalToRefund, 'refund', uuidv4());
      } else {
        await orderingPaymentsRepository.refundPayWithCash(paymentId, totalToRefund);
      }

      dispatch(
        addRefundToOrder({
          id: uuidv4(),
          paymentId,
          amount: totalToRefund,
          status: PaymentSummaryStatus.CREATED,
        }),
      );
      dispatch(setSelectedForRefund());
    },
    [dispatch, paymentRepository, orderingPaymentsRepository],
  );
};

export const useUpdatePaymentStatus = () => {
  const dispatch = useDispatch();

  return useAsyncFn<void, [orderId: string, paymentStatus: OrderPaymentStatus]>(
    async (orderId, paymentStatus) => {
      dispatch(setOrderPaymentStatus({ orderId, status: paymentStatus }));
    },
    [dispatch],
  );
};

export const useGetOrderHistory = () => {
  const orderRepository = useOrderRepository();
  return useAsyncFn(async (orderId: OrderId) => orderRepository.getOrderHistory(orderId), [orderRepository]);
};
