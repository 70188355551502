import { Experiment } from '@amplitude/experiment-js-client';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';

export const experiment = Experiment.initializeWithAmplitudeAnalytics(
  ConfigurationLoader.load().experimentDeploymentKey || '',
  {
    retryFetchOnFailure: false,
    fetchTimeoutMillis: 2000,
  },
);
