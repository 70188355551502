import { Product } from 'src/menu/dynamic-menu/domain/Product';
import React, { FC, useEffect } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import Link from '@mui/material/Link';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { isTaskFailed, isTaskOngoing, isTaskSucceeded, TaskStatus } from 'src/menu/tasks/domain/Task';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';

type DeleteModalProps = {
  selectedProducts: Product[],
  onActionConfirmed: (selectedProducts: Product[]) => void,
  onClose: () => void,
  currentTaskStatus?: TaskStatus | undefined,
  onActionDone: () => void
};

export const DeleteProductsModal: FC<DeleteModalProps> = ({
  selectedProducts,
  onActionConfirmed,
  onClose,
  currentTaskStatus,
  onActionDone,
}) => {
  const snackbar = useSnackbar();
  const intl = useIntl();
  const taskIsOngoing = !!currentTaskStatus && isTaskOngoing(currentTaskStatus);

  useEffect(() => {
    if (!currentTaskStatus) return;

    if (isTaskSucceeded(currentTaskStatus)) {
      snackbar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'menus.products.bulk.modal.delete.success' }, {
          nb_products: selectedProducts.length,
          plural: selectedProducts.length > 1 ? 's' : '',
        }),
      });
      onActionDone();

    } else if (isTaskFailed(currentTaskStatus)) {
      snackbar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'menus.products.bulk.modal.delete.failure' }),
      });
      onActionDone();

    }
  }, [currentTaskStatus]);

  return (
    <Dialog open={true} onClose={() => !taskIsOngoing && onClose()}>
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h6">
          <FormattedMessage id="menus.products.bulk.modal.delete.title" values={{
            'nb_products': selectedProducts.length,
            'plural': selectedProducts.length > 1 ? 's' : '',
          }} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage id="menus.products.bulk.modal.delete.content" values={{
            'nb_products': selectedProducts.length,
            'plural': selectedProducts.length > 1 ? 's' : '',
          }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3, gap: 2 }}>
        <Link
          onClick={() => !taskIsOngoing && onClose()}
          sx={{
            cursor: 'pointer',
            color: themeV5.palette.text.primary,
            textDecorationColor: themeV5.palette.text.primary,
          }}>
          <Typography variant="body1">
            <FormattedMessage id="menus.products.bulk.modal.delete.cancel" />
          </Typography>
        </Link>
        <Button
          onClick={() => {
            onActionConfirmed(selectedProducts);
          }}
          autoFocus
          color={'error'}
          variant="contained">
          {taskIsOngoing ? <CircularProgress /> :
            <span>
              <FormattedMessage id="menus.products.bulk.modal.delete.confirm" values={{
                'nb_products': selectedProducts.length,
                'plural': selectedProducts.length > 1 ? 's' : '',
              }} />
            </span>
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
};
