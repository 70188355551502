import { Box, Input, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAddCommentsOnReport } from '../mutations/useAddCommentsOnReport';
import { useReport } from '../queries/getReport';

export const ReportCommentEdition = () => {
  const mutation = useAddCommentsOnReport();
  const { reportId } = useParams();
  const { data: currentReport } = useReport(reportId!);
  const [commentUpdated, setCommentUpdate] = useState<string>(currentReport?.comment ?? '');

  useEffect(() => {
    if (commentUpdated !== currentReport?.comment) {
      const handler = setTimeout(() => {
        mutation.mutate(commentUpdated);
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [commentUpdated]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
        justifyContent: 'space-between',
        margin: '20px',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <Typography variant="body2">
            <FormattedMessage id="reconciliation.report.comment" />
          </Typography>
        </Box>

        <Input
          value={commentUpdated}
          multiline
          onChange={(e) => setCommentUpdate(e.currentTarget.value)}
          fullWidth
          disabled={currentReport?.status !== 'OPEN'}
        />
      </Box>
    </Box>
  );
};
