import React from 'react';
import { IconButton, Tooltip } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { getSpacing } from '../../../stylesheet';
import { InfoIcon } from '../Icons';

type Props = {
  direction?: 'top' | 'bottom' | 'left' | 'right';
  message: string;
  color?: string;
};

const TooltipWrapper = styled.div`
  flex: 1;
  padding: 0 ${getSpacing(1)};
  display: flex;
  justify-content: left;
`;

const StyledInfoIcon = styled(InfoIcon)`
  cursor: pointer;
  flex: 0 0 16px;
`;

const InfoPopover: React.FC<Props> = ({ direction = 'bottom', message, color }) => (
  <TooltipWrapper>
    <Tooltip direction={direction} title={message}>
      <IconButton size="small">
        <StyledInfoIcon color={color} />
      </IconButton>
    </Tooltip>
  </TooltipWrapper>
);

export default InfoPopover;
