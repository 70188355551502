import {
  useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState,
} from 'react';
import { CropperService } from 'src/domain/venue/CropperService';

const cropperService = new CropperService();

// eslint-disable-next-line max-len
export function useCropTool(shape: 'round' | 'rect', picture: string, isPickingColor: boolean, setPickingColor: (color: string, alpha: number) => void, background: { color: string, alpha: number }) {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const imageContainerRef = useRef<HTMLDivElement>(null);
  const [pictureImage, setPicture] = useState<HTMLImageElement>(new Image());
  const croperAreaRef = useRef<HTMLDivElement | undefined>(undefined);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (picture) {
      CropperService.downloadImage(picture).then(setPicture);
    }
  }, [picture]);
  const [cropArea, setCropArea] = useState<any>(null);
  const onCropComplete = useCallback((_: any, croppedAreaPixels: any) => {
    setCropArea(croppedAreaPixels);
  }, []);

  const onCropperToolLoaded = useCallback((mediaSize: any) => {
    if (imageContainerRef.current) {
      if (shape === 'round') {
        const { width } = imageContainerRef.current.getBoundingClientRect();
        const initialZoom = (width / (mediaSize.width / 0.7));
        setZoom(initialZoom);
      }
      const elements = imageContainerRef.current.getElementsByClassName('reactEasyCrop_CropArea');
      setTimeout(() => {
        if (elements[0]) {
          croperAreaRef.current = elements[0] as HTMLDivElement;
          setIsLoaded(true);
        }
      }, 1000);
    }
  }, [shape]);
  const cropData = useMemo(() => ({
    image: pictureImage,
    shape,
    area: cropArea,
    background,
  }), [pictureImage, shape, cropArea, background]);

  const onMouseMove = useCallback(async (e: any) => {
    if (
      croperAreaRef.current && isPickingColor
    ) {
      const rect = e.currentTarget.getBoundingClientRect();
      const { color, alpha } = cropperService.getPickedColor(
        cropData,
        (e.clientX - rect.left) * (cropData.area.width / rect.width),
        (e.clientY - rect.top) * (cropData.area.height / rect.height),
      );
      setPickingColor(color, alpha);
    }
  }, [cropData, isPickingColor]);

  useLayoutEffect(() => {
    if (isLoaded && croperAreaRef.current) {
      croperAreaRef.current.style.cursor = isPickingColor ? 'crosshair' : 'move';
      croperAreaRef.current.addEventListener('mousemove', onMouseMove);
    }
    return () => {
      if (isLoaded && croperAreaRef.current) {
        croperAreaRef.current.removeEventListener('mousemove', onMouseMove);
      }
    };
  }, [isLoaded, onMouseMove]);
  return {
    pictureImage,
    imageContainerRef,
    crop,
    setCrop,
    zoom,
    setZoom,
    onCropComplete,
    onCropperToolLoaded,
    cropData,
  };
}
