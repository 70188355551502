import styled from 'styled-components';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';

const Tag = styled.span`
  height: ${getSpacing(4)};
  border-radius: ${getSpacing(4)};
  padding: 0 ${getSpacing(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  ${textStyles.small}
  color: ${colorPalette.grey600};
  background-color: ${colorPalette.peach};
`;

export default Tag;
