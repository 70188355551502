import { useQuery } from '@tanstack/react-query';
import { PosConnectionRepositoryHttp } from '../infrastructure/PosConnectionRepositoryHttp';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { BusinessId } from 'src/business/domain/Business';
import { queryKeys } from 'src/app/queries/utils';
import { useGetFirstActivePosConnectionForBusiness } from 'src/pos/queries/getPosConnections';
import { PosConnectionId } from 'src/pos/domain/PosConnection';

const configuration = ConfigurationLoader.load();
const posConnectionRepository = new PosConnectionRepositoryHttp(configuration.vposVenueUrl);

export function useGetSinglePosConnectionOptions(businessId: BusinessId) {
  const posConnectionResult = useGetFirstActivePosConnectionForBusiness(businessId);
  const posConnectionId = posConnectionResult.data?.id ?? 'none' as PosConnectionId;

  const optionsResult = useQuery({
    queryKey: queryKeys.pos_connections.options(businessId, posConnectionId),
    queryFn: async () => {
      return posConnectionRepository.getPosConnectionOptions(posConnectionId);
    },
    select: (data) => {
      if (data === null) {
        return null;
      }
      return {
        posConnectionId: posConnectionId,
        ...data,
      };
    },
    enabled: !!posConnectionResult.data,
  });

  return {
    data: optionsResult.data,
    isLoading: posConnectionResult.isLoading || optionsResult.isLoading,
    error: posConnectionResult.error ?? optionsResult.error,
  };
}
