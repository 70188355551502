import { GridDirection } from '@mui/material';
import React from 'react';

export enum ColumnWidth {
  SMALL = 'small',
  LARGE = 'large',
  EVEN = 'even',
}

type GridVariant = ColumnWidth.SMALL | ColumnWidth.LARGE | ColumnWidth.EVEN;

export type GridItemRowsProps = {
  rows: number,
};

export type GridItemLayoutProps = {
  order: number,
  layout: {
    direction: GridDirection | 'row',
    size: GridVariant,
  }
};

export type GridItemProps = GridItemLayoutProps & {
  components: React.ReactNode[],
  key: number | string,
};

export type GridConfigurableProps = {
  grid: {
    columnDefs: GridItemProps[],
  }
};
