import React, { FC } from 'react';
import { PaymentLink } from '../../domain/PaymentLink';
import { EmptyTableCellValue } from 'src/payments/components/EmptyTableCellValue';
import { Box, Typography } from '@mui/material';
import { FormattedDate, FormattedTime } from 'src/app/i18n/TypedIntl';
import { themeV5 } from 'src/app/theme/ThemeV5';

export const EventDateTableCell: FC<{ paymentLink: PaymentLink }> = ({ paymentLink }) => {
  const eventDate = paymentLink.qrCode.eventDate;
  if (!eventDate) {
    return <EmptyTableCellValue />;
  }

  return <Box display="flex" flexDirection="column">
    <Typography variant="body2">
      <FormattedDate
        value={eventDate}
        day="2-digit"
        month="2-digit"
        year="numeric"
      />
    </Typography>
    <Typography variant="subtitle2" color={themeV5.palette.text.secondary}>
      <FormattedTime
        value={eventDate}
        hour="2-digit"
        minute="2-digit"
      />
    </Typography>
  </Box>;
};


export const DatePanelField: FC<{ date: Date | undefined }> = ({ date }) => {
  if (!date) {
    return <EmptyTableCellValue />;
  }

  return <Box>
    <FormattedDate
      value={date}
      day="2-digit"
      month="2-digit"
      year="numeric"
    />
    <span style={{ margin: '0 4px' }}>
        ·
      </span>
    <FormattedTime
      value={date}
      hour="2-digit"
      minute="2-digit"
    />
  </Box>;
};
