import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { OpeningHoursPanel } from 'src/ordering/common/components/OpeningHoursPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { TimePeriod } from 'src/ordering/common/domain/TimePeriod';
import { useUpdateBoxOpenHours } from '../../../../hooks';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type BoxOpenHourSettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const BoxOpeningHourSetting = ({ box, setBox }: BoxOpenHourSettingProps) => {
  const intl = useIntl();
  const [, updateBoxOpenHours] = useUpdateBoxOpenHours(box.id);
  const [, pushNotification] = usePushNotification();

  const onOpeningHoursChanged = async (update: TimePeriod[]) => {
    const updatedBox = { ...box, boxOpeningHours: update };
    setBox(updatedBox);

    try {
      await updateBoxOpenHours(update);
      pushNotification(
        intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }),
      );
    } catch {
      setBox(box);
      pushNotification(
        intl.formatMessage({ id: 'settings.app_settings.error_saving', defaultMessage: 'something went wrong' }),
        'error',
      );
    }
  };

  return <OpeningHoursPanel openingHours={box.boxOpeningHours} updateOpeningHours={onOpeningHoursChanged} />;
};
