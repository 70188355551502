import React, { createContext, ReactNode, useMemo } from 'react';
import { PaymentConfig } from './PaymentConfig';
import { PaymentRepositoryHttp } from '../repositories/PaymentRepositoryHttp';

type PaymentContextProviderProps = {
  paymentBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = PaymentConfig & {
  children: ReactNode;
};

export const PaymentConfigContext = createContext<Partial<ProviderConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => <PaymentConfigContext.Provider value={config}>{children}</PaymentConfigContext.Provider>;

export const PaymentContextProvider = ({
  paymentBackendUrl,
  children,
}: PaymentContextProviderProps) => {
  // Repo
  const paymentRepository = useMemo(() => new PaymentRepositoryHttp(paymentBackendUrl), [paymentBackendUrl]);

  return <Provider paymentRepository={paymentRepository}>{children}</Provider>;
};
