import styled from 'styled-components';

export const UnlinkPosUsernameForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 356px;
`;

export const UserNamesContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
`;

export const UserNameColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const UserNameTitle = styled.div`
  color: #70707B;
`;
