import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { KpiTimeframeName, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';
import { KpiName } from 'src/sundayplus/reviews/insights/domain/Kpi';

export type EReputationPageChoicesState = Readonly<{
  timeFrame: KpiTimeframeName;
  panel: KpiName;
}>;

const initialState: EReputationPageChoicesState = {
  timeFrame: TWELVE_MONTHS.name(),
  panel: 'TOTAL_REVIEWS',
};

const slice = createSlice({
  name: 'e-reputation-page-choices',
  initialState,
  reducers: {
    setTimeframeChosen: (state, action: PayloadAction<KpiTimeframeName>) => ({
      ...state,
      timeFrame: action.payload,
    }),
    setPanelChosen: (state, action: PayloadAction<KpiName>) => ({
      ...state,
      panel: action.payload,
    }),
  },
});

export const {
  setTimeframeChosen,
  setPanelChosen,
} = slice.actions;
export default slice.reducer;
