import { useFormContext } from 'react-hook-form';
import { Address } from 'src/payments/components/Checkout/CheckoutOnboarding/BusinessDetails/Address';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const RegisteredBusinessAddress = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const registeredAddressErrors = errors.registeredAddress;
  const intl = useIntl();
  return <Address
    addressTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.registeredAddress' })}
    addressType={'registeredAddress'}
    addressErrors={{
      addressLine1: registeredAddressErrors?.addressLine1,
      zip: registeredAddressErrors?.zip,
      city: registeredAddressErrors?.city,
      state: registeredAddressErrors?.state,
    }} register={register} />;
};
