/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const QuestionMarkSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_624_30)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10 2.49999C5.8579 2.49999 2.50004 5.85786 2.50004 9.99999C2.50004 14.1421 5.8579 17.5 10 17.5C14.1422 17.5 17.5 14.1421 17.5 9.99999C17.5 5.85786 14.1422 2.49999 10 2.49999ZM0.833374 9.99999C0.833374 4.93738 4.93743 0.833328 10 0.833328C15.0626 0.833328 19.1667 4.93738 19.1667 9.99999C19.1667 15.0626 15.0626 19.1667 10 19.1667C4.93743 19.1667 0.833374 15.0626 0.833374 9.99999ZM10.2151 6.68693C9.8272 6.62039 9.42824 6.69329 9.0889 6.89272C8.74957 7.09215 8.49177 7.40523 8.36115 7.77653C8.20843 8.21069 7.73266 8.43883 7.2985 8.28611C6.86435 8.13338 6.6362 7.65762 6.78893 7.22346C7.05016 6.48087 7.56576 5.85469 8.24443 5.45583C8.9231 5.05697 9.72103 4.91117 10.4969 5.04425C11.2728 5.17733 11.9765 5.58071 12.4835 6.18293C12.9903 6.78504 13.2678 7.54706 13.2667 8.33409C13.2663 9.60938 12.3208 10.4516 11.6456 10.9017C11.2826 11.1437 10.9255 11.3217 10.6624 11.4386C10.5297 11.4976 10.418 11.5423 10.3375 11.573C10.2971 11.5884 10.2644 11.6003 10.2406 11.6087L10.2117 11.6189L10.2025 11.622L10.1992 11.6231L10.1979 11.6235C10.1977 11.6236 10.1969 11.6239 9.93337 10.8333L10.1969 11.6239C9.76028 11.7694 9.28834 11.5335 9.1428 11.0969C8.99731 10.6604 9.23307 10.1886 9.66941 10.0429L9.68227 10.0384C9.69509 10.0338 9.7161 10.0262 9.74413 10.0155C9.80032 9.99412 9.88389 9.96075 9.98555 9.91557C10.1912 9.82415 10.4591 9.6896 10.7211 9.51495C11.2958 9.13184 11.6 8.72427 11.6 8.33333L11.6 8.33209C11.6006 7.93849 11.4619 7.55738 11.2084 7.25627C10.9549 6.95515 10.6031 6.75347 10.2151 6.68693ZM9.16671 14.1667C9.16671 13.7064 9.5398 13.3333 10 13.3333H10.0084C10.4686 13.3333 10.8417 13.7064 10.8417 14.1667C10.8417 14.6269 10.4686 15 10.0084 15H10C9.5398 15 9.16671 14.6269 9.16671 14.1667Z" fill={color} />
    </g>
    <defs>
      <clipPath id="clip0_624_30">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
