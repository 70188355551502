import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { MouseEvent, useLayoutEffect, useState } from 'react';
import {
  Box, InputAdornment, styled, TextField,
} from '@mui/material';
import { UpdateBlockDto } from '../../../repositories/block/BlockDto';
import { ColorPickerModal } from './ColorPickerModal';

type Props = {
  defaultValue: string
  fieldNameToUpdate: string
  label: string
  blockUpdate: UpdateBlockDto
  setBlockUpdate: (blockDto: UpdateBlockDto) => void
  disabled: boolean,
};

type ColorPreviewProps = {
  color: string
};
const ColorPreview = styled('div')<ColorPreviewProps>`
  background-color: ${(props) => props.color};
  width: 30px;
  height: 30px;
  border-radius: 25px;
  border: 1px solid #cccccc;
`;

export const BlockColorPicker = ({
  defaultValue, fieldNameToUpdate, label, blockUpdate, setBlockUpdate, disabled,
}: Props) => {
  const [colorPickerOpened, setColorPickerOpened] = useState(false);
  const [value, setValue] = useState(defaultValue);

  useLayoutEffect(() => {
    setValue(defaultValue ?? '');
  }, [defaultValue]);

  function isColorValid(strColor: string) {
    const s = new Option().style;
    s.color = strColor;
    return s.color !== '';
  }
  const updateValue = (newValue: string) => {
    let newBlockDto = blockUpdate;
    if (isColorValid(newValue)) {
      if (newValue !== (defaultValue ?? '')) {
        newBlockDto = {
          ...newBlockDto,
          [fieldNameToUpdate]: newValue,
        };
      } else {
        newBlockDto = {
          ...newBlockDto,
          [fieldNameToUpdate]: undefined,
        };
      }
      setBlockUpdate(newBlockDto);
    }
    setValue(newValue);
  };

  const onClickFocus = (e: MouseEvent<HTMLElement>) => {
    if (disabled) e.stopPropagation();
  };

  const handleCloseColorPicker = () => {
    setColorPickerOpened(false);
  };
  return (
    <Box onClick={onClickFocus}>
      <TextField
        variant="outlined"
        size="medium"
        disabled={disabled}
        label={label}
        sx={{ width: '100%', input: { cursor: 'pointer' } }}
        value={value}
        onClick={() => setColorPickerOpened(true)}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: <InputAdornment position="end" sx={{ cursor: 'pointer' }}><ColorPreview color={value} /></InputAdornment>,
        }}
        onChange={(e) => updateValue(e.target.value)}
      />

      <ColorPickerModal
        title={label}
        isOpened={colorPickerOpened}
        close={handleCloseColorPicker}
        defaultColor={defaultValue}
        onColorChanged={updateValue}
      />

    </Box>
  );
};
