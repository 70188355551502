import React, { useState } from 'react';
import { PencilSvg } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useRenderMoney } from '@sundayapp/web-money';
import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import ElementsTable from './ElementsTable';
import CategoryDeletionModal from './CategoryDeletionModal';
import { Category, ElementSummary } from '../../../domain/Category';
import { productTypes } from '../../../domain/Product';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';
import {
  useUpdateBlocksInCategoryMutation,
  useUpdateElementsInCategoryMutation,
  useUpdateProductsInCategoryMutation,
} from '../../../mutations/menu/useCategoryMutation';
import { ElementSortingModal } from '../../ElementSortingModal/ElementSortingModal';
import { ProductsSelectionModal } from '../../ProductSelectionModal/ProductSelectionModal';
import { BlocksSelectionModal } from '../../BlockSelectionModal/BlockSelectionModal';
import { isProduct } from '../../../repositories/ProductDto';
import { isBlock } from '../../../repositories/block/BlockDto';
import { SortElement } from '../../ElementSortingModal/SortElement';
import { BusinessId } from 'src/business/domain/Business';
import { themeV5 } from 'src/app/theme/ThemeV5';
import { Delete } from '@mui/icons-material';
import CategoryEditionModal from 'src/menu/dynamic-menu/pages/MenuDetailsPage/components/CategoryEditionModal';

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 16px;
`;

const Information = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 8px;
`;

type CategoryProps = {
  isEditable: boolean;
  menuId: string;
  category: Category;
  businessId: BusinessId;
  contextProductList: string[];
  contextBlockList: string[];
};

const CategoryPanel = ({
  isEditable,
  menuId,
  category,
  businessId,
  contextProductList,
  contextBlockList,
}: CategoryProps) => {
  const [isCategoryEditionModalOpen, setIsCategoryEditionModalOpen] = useState<boolean>(false);
  const [isProductSelectionModalOpen, setIsProductSelectionModalOpen] = useState<boolean>(false);
  const [isBlockSelectionModalOpen, setIsBlockSelectionModalOpen] = useState<boolean>(false);
  const [isElementSortingModalOpen, setIsElementSortingModalOpen] = useState<boolean>(false);
  const [isCategoryDeletionModalOpen, setIsCategoryDeletionModalOpen] = useState<boolean>(false);
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, true);
  const snackBar = useSnackbar();

  const updateProductsInCategory = useUpdateProductsInCategoryMutation(businessId, menuId, category.id);
  const updateBlocksInCategory = useUpdateBlocksInCategoryMutation(businessId, menuId, category.id);
  const updateElementsInCategory = useUpdateElementsInCategoryMutation(businessId, menuId, category.id);

  const onElementListUpdated = async (newElements: SortElement[]) => {
    try {
      await updateElementsInCategory.mutateAsync(newElements.map((e) => ({ id: e.id, type: e.type })));
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    } finally {
      setIsElementSortingModalOpen(false);
    }
  };

  const onProductListUpdated = async (newProductIds: string[]) => {
    try {
      await updateProductsInCategory.mutateAsync(newProductIds);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    } finally {
      setIsProductSelectionModalOpen(false);
    }
  };

  const onBlockListUpdated = async (newBlockIds: string[]) => {
    try {
      await updateBlocksInCategory.mutateAsync(newBlockIds);
      snackBar.addNotification({
        variant: 'success',
        text: intl.formatMessage({ id: 'settings.app_settings.success_saving' }),
      });
    } catch (e) {
      snackBar.addNotification({
        variant: 'error',
        text: intl.formatMessage({ id: 'settings.app_settings.error_saving' }),
      });
    } finally {
      setIsBlockSelectionModalOpen(false);
    }
  };

  const mapToElement = (element: ElementSummary) => {
    switch (element.type) {
      case 'BLOCK':
        return { id: element.id, label: element.title, type: 'BLOCK' };
      case productTypes.PRODUCT:
      case productTypes.BUNDLE:
      case productTypes.MODIFIER_GROUP:
      case productTypes.MODIFIER:
      case productTypes.VARIANT:
      case productTypes.VARIANT_GROUP:
        return {
          id: element.id,
          label: `${element.name ?? element.name} (${renderMoney(element.price)})`,
          type: 'PRODUCT',
        };
    }
  };
  return (
    <Card>
      <CardContent>
        <Header>
          <Information>
            <Box display="flex" flexDirection="row" alignItems={'center'}>
              <Typography variant={'h5'}>{category.name}</Typography>
              {isEditable && (
                <IconButton onClick={() => setIsCategoryEditionModalOpen(true)}>
                  <PencilSvg title="edit category" />
                </IconButton>
              )}
            </Box>
            <Typography variant={'subtitle1'} color={themeV5.palette.text.secondary}>
              {category.description}
            </Typography>
          </Information>
          {isEditable && (
            <Box display="flex" gap={2} alignItems="center" justifyContent="center">
              <Button variant={'outlined'} size="small" onClick={() => setIsProductSelectionModalOpen(true)}>
                <FormattedMessage id="menus.edition.category_select_items" />
              </Button>
              <Button variant={'outlined'} size="small" onClick={() => setIsBlockSelectionModalOpen(true)}>
                <FormattedMessage id="menus.edition.category_select_blocks" />
              </Button>
              <Button variant={'outlined'} size="small" onClick={() => setIsElementSortingModalOpen(true)}>
                <FormattedMessage id="menus.edition.category_sort_items" />
              </Button>
              <IconButton size="medium" onClick={() => setIsCategoryDeletionModalOpen(true)}>
                <Delete fontSize="inherit" />
              </IconButton>
            </Box>
          )}
        </Header>

        <ElementsTable
          isSundayMenu={isEditable}
          categoryId={category.id}
          menuId={menuId}
          elements={category.elements}
          businessId={businessId}
          contextProductList={contextProductList}
          contextBlockList={contextBlockList}
          onProductDeleted={(productId) => {
            const updatedProductIds = category.elements.map((a) => a.id).filter((id) => id !== productId);
            return onProductListUpdated(updatedProductIds);
          }}
        />
        {isEditable && (
          <>
            <CategoryDeletionModal
              isOpen={isCategoryDeletionModalOpen}
              onClose={() => setIsCategoryDeletionModalOpen(false)}
              categoryId={category.id}
              categoryName={category.name}
              menuId={menuId}
            />
            <CategoryEditionModal
              isOpen={isCategoryEditionModalOpen}
              onClose={() => setIsCategoryEditionModalOpen(false)}
              menuId={menuId}
              category={category}
            />
            <ElementSortingModal
              title={intl.formatMessage({ id: 'menus.products.category.sort' }, { category: category.name })}
              elements={category.elements.map((p) => mapToElement(p))}
              onElementReorderFinished={onElementListUpdated}
              isOpen={isElementSortingModalOpen}
              setIsOpen={setIsElementSortingModalOpen}
            />

            <ProductsSelectionModal
              title={intl.formatMessage({ id: 'menus.products.category.add_label' }, { category: category.name })}
              productIds={category.elements.filter((e) => isProduct(e.type)).map((p) => p.id)}
              onChange={onProductListUpdated}
              customFilter={(p) => p.type === productTypes.PRODUCT || p.type === productTypes.VARIANT_GROUP || p.type === productTypes.VARIANT || p.type === productTypes.BUNDLE}
              visible={isProductSelectionModalOpen}
              onClose={() => setIsProductSelectionModalOpen(false)}
            />

            <BlocksSelectionModal
              title={intl.formatMessage({ id: 'menus.blocks.category.add_label' }, { category: category.name })}
              defaultSelectedBlocks={category.elements.filter((e) => isBlock(e.type)).map((p) => p.id)}
              onBlockSelectionFinished={onBlockListUpdated}
              isOpen={isBlockSelectionModalOpen}
              setIsOpen={setIsBlockSelectionModalOpen}
            />
          </>
        )}
      </CardContent>
    </Card>
  )
  ;
};

export default CategoryPanel;
