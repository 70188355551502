import { InfoOutlined } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { DownloadSvg, IconButton } from '@sundayapp/b2b-react-component-library';
import { MoneyView } from '@sundayapp/web-money';
import dayjs from 'dayjs';
import { IntlShape } from 'src/app/i18n/TypedIntl';
import SundayTooltip from 'src/app/component/tooltip/SundayTooltip';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { BusinessId } from 'src/business/domain/Business';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { BusinessDate } from 'src/components/date/BusinessDate';
import { ServiceType } from 'src/domain/venue/ServiceType';
import { EmailReceipt } from 'src/payments/page/EmailReceipt';
import { CopyPaste } from 'src/utils/CopyPaste';
import { FulfillmentType } from '../../../area/domain/FulfillmentType';
import { getFulfillmentMethodLabel } from '../../../area/formatting';
import { DataGridAlign } from '../../../common/components/DataGrid/DataGrid';
import { getSpacing } from '../../../stylesheet';
import DetailsButton from '../../components/DetailsButton';
import OrderPaymentStatusLabel from '../../components/OrderPaymentStatusLabel';
import { OrderPaymentStatusContainer } from '../../components/OrderPaymentStatusLabel/OrderPaymentStatusLabel';
import OrderStatusLabel from '../../components/OrderStatusLabel/OrderStatusLabel';
import { isAnyServiceAtTable, isHybrid, OrderStatus, OrderSummary } from '../../types';
import { SmallDetailLabel, SmsSentLabel } from './OrdersList.style';

export const isDateBeforeToday = (date: Date) => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return date < today;
};

export const isDateAfterToday = (date: Date) => {
  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);
  return date > today;
};

const FormatDate = ({ dateAtEpochMilli }: { dateAtEpochMilli: number }) => {
  const { timezone: businessTz } = useCurrentBusinessOrThrow();
  const date = new Date(dateAtEpochMilli);
  const isToday = dayjs(date).tz(businessTz).isSame(dayjs(), 'day');

  const format = isToday ? 'LT' : 'L LT';
  return <BusinessDate format={format} date={date} />;
};

const getSmsSentAtLabel = (smsSentAtEpochMilli: number) => (
  <SmsSentLabel>
    <FormattedMessage id="orders.list.smsSentAt" defaultMessage="SMS sent at" />
    &nbsp;
    <FormatDate dateAtEpochMilli={ smsSentAtEpochMilli } />
  </SmsSentLabel>
);

const getPickupTimestampLabel = (pickupTimestamp: number) => (
  <div>
    <FormattedMessage id="order.pickupAt" defaultMessage="pick up at" />
    &nbsp;
    <FormatDate dateAtEpochMilli={pickupTimestamp} />
  </div>
);

const displayServiceWithTableName = (fulfillmentMethod: FulfillmentType, tableName: string) => {
  if (fulfillmentMethod === FulfillmentType.HYBRID_ORDERING) {
    return (
      <FormattedMessage
        id="order.hybridAtTableWithTableName"
        defaultMessage="Hybrid order [{tableName}]"
        values={{ tableName }}
      />
    );
  }
  return (
    <FormattedMessage
      id="order.serviceAtTableWithTableName"
      defaultMessage="service at {tableName}"
      values={{ tableName }}
    />
  );
};

const ActionRow = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const OrderStatusContainer = styled('div')`
  padding: ${getSpacing(0.5)} 0;
`;

const HybridOrderPaymentStatusContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: ${getSpacing(0.5)};
`;

export const buildColumns = (businessId: BusinessId,
  intl: IntlShape,
  user: AuthenticatedUser,
  handleDownloadReceipt: (order: OrderSummary) => Promise<void>) => [
  {
    id: 'displayId',
    label: intl.formatMessage({
      id: 'orders.column.displayId',
      defaultMessage: 'order',
    }),
    renderCell: (order: OrderSummary) => <span data-testid="order-display-id">{order.displayId}</span>,
  },
  {
    id: 'behaviour',
    label: intl.formatMessage({
      id: 'orders.column.behaviour',
      defaultMessage: 'service',
    }),
    renderCell: (order: OrderSummary) => (
      <span data-testid="order-fulfillment-method">
        {(isAnyServiceAtTable(order) && order.tableName)
          ? displayServiceWithTableName(order.fulfillmentMethod, order.tableName)
          : getFulfillmentMethodLabel(order.fulfillmentMethod)}
      </span>
    ),
  },
  {
    id: 'time',
    label: intl.formatMessage({
      id: 'orders.column.time',
      defaultMessage: 'time',
    }),
    renderCell: (order: OrderSummary) => (
      <>
        <FormatDate dateAtEpochMilli={order.placedAtEpochMilli ?? 0} />
        {order.pickupTimestamp && getPickupTimestampLabel(order.pickupTimestamp)}
        {order.bookingEventName}
      </>
    ),
  },
  {
    id: 'customerName',
    label: intl.formatMessage({
      id: 'orders.column.customerName',
      defaultMessage: 'name',
    }),
    renderCell: (order: OrderSummary) => <span data-testid="order-customer-name">{order.customerName}</span>,
  },
  {
    id: 'total',
    label: intl.formatMessage({
      id: 'orders.column.total',
      defaultMessage: 'total',
    }),
    renderCell: (order: OrderSummary) => (
      <MoneyView
        value={order.totalPaid && order.totalPaid.amount > 0 ? order.totalPaid : order.totalPrice}
        data-testid="order-total"
      />
    ),
  },
  {
    id: 'status',
    label: intl.formatMessage({
      id: 'orders.column.orderStatus',
      defaultMessage: 'order status',
    }),
    renderCell: (order: OrderSummary) => {
      if (order.boxOrders.length === 0) {
        return (
          <Box>
            <OrderStatusLabel status={order.status} />
            {order.smsSent && (order.smsSentAtEpochMilli ?? 0) > 0 && getSmsSentAtLabel(order.smsSentAtEpochMilli ?? 0)}
          </Box>
        );
      }
      return (
        <>
          {order.boxOrders.map((boxOrder) => (
            <OrderStatusContainer key={boxOrder.id}>
              <OrderStatusLabel status={boxOrder.status} />
              {boxOrder.smsSent
                && (boxOrder.smsSentAtEpochMilli ?? 0) > 0
                && getSmsSentAtLabel(boxOrder.smsSentAtEpochMilli ?? 0)}
              {boxOrder.reason && (boxOrder.status === OrderStatus.CANCELED || boxOrder.status === OrderStatus.CANCEL || boxOrder.status === OrderStatus.FAILED)
                && <SmallDetailLabel>{boxOrder.reason.substring(0, 50)}</SmallDetailLabel>}
            </OrderStatusContainer>
          ))}
        </>
      );
    },
  },
  {
    id: 'paymentStatus',
    label: intl.formatMessage({
      id: 'orders.column.paymentStatus',
      defaultMessage: 'payment status',
    }),
    renderCell: (order: OrderSummary) =>
      (order.paidBySunday ? (
        <OrderPaymentStatusLabel businessId={businessId} order={order} />
      ) : (isHybrid(order) ? (
        <HybridOrderPaymentStatusContainer>
          <SundayTooltip title={intl.formatMessage({
            id: 'order.hybridPaymentStatus.detailed',
            defaultMessage: 'Go to bills tab to see the payment status for hybrid orders',
          })}>
            <InfoOutlined />
          </SundayTooltip>
          <FormattedMessage id="order.hybridPaymentStatus" defaultMessage="See bills tab" />
        </HybridOrderPaymentStatusContainer>
      ) : (
        <OrderPaymentStatusContainer paymentStatus={order.paymentStatus} data-testid="order-payment-status">
          <FormattedMessage id="order.payingWithCash" defaultMessage="paying with cash" />
        </OrderPaymentStatusContainer>
      ))),
  },
  {
    id: 'details',
    label: ' ',
    align: DataGridAlign.RIGHT,
    renderCell: (order: OrderSummary) => <DetailsButton order={order} />,
  },
  {
    id: 'actions',
    label: ' ',
    align: DataGridAlign.RIGHT,
    renderCell: (order: OrderSummary) => (
      <ActionRow onClick={(event) => event.stopPropagation() }>
        {order.paidBySunday &&
          <SundayTooltip placement="left" title={intl.formatMessage({ id: 'payments.table.tooltip.export' })}>
            <IconButton
              data-testid={`download-receipt-${order.id}`}
              size="medium"
              onClick={() => handleDownloadReceipt(order)}
            >
              <DownloadSvg />
            </IconButton>
          </SundayTooltip>}
        {order.paidBySunday && <EmailReceipt payment={null} orderId={order.id} isGrouped={order.regroupedOrderSummaries.length > 0} serviceType={ServiceType.ORDER_AND_PAY} />}
        {user.impersonated && (
          <SundayTooltip placement="left" title="copy orderId">
            <CopyPaste value={order.id} />
          </SundayTooltip>
        )}
      </ActionRow>
    ),
  },
];
