import axios from 'axios';
import { Enrollment, EnrollmentId, EnrollmentStatus } from '../domain/Enrollment';
import { Business, BusinessId } from '../domain/Business';
import { ServiceType } from 'src/domain/venue/ServiceType';
import _ from 'lodash';
import { BusinessDtoSchema, EnterpriseDtoSchema } from 'src/business/infrastructure/BusinessDto';

const activeFirstComparison = (e: Enrollment) => (e.status === EnrollmentStatus.ACTIVE ? 1 : 2);

export class BusinessReferentialRepositoryHttp {
  constructor(private baseUrl: string) {}

  async getById(businessId: BusinessId): Promise<Business | undefined> {
    const response = await axios.get(`${this.baseUrl}/v2/businesses/${businessId}`, {
      validateStatus: (status) => [200, 404].includes(status),
    });
    return response.status === 200 ? this.mapBusiness(BusinessDtoSchema.parse(response.data)) : undefined;
  }

  async getByIds(businessIds: BusinessId[]): Promise<Business[] | undefined> {
    const response = await axios.post(
      `${this.baseUrl}/v2/businesses/by-ids`,
      {
        businessIds,
      },
      {
        validateStatus: (status) => [200, 404].includes(status),
      },
    );
    return response.status === 200 ? this.mapBusinesses(response.data) : undefined;
  }

  async completeInitialSetup(businessId: BusinessId): Promise<void> {
    await axios.patch(`${this.baseUrl}/v2/businesses/${businessId}/complete-initial-setup`);
  }

  async getByEnrollmentId(enrollmentId: EnrollmentId): Promise<Business | undefined> {
    const response = await axios.get(`${this.baseUrl}/v2/enrollments/${enrollmentId}/business`, {
      validateStatus: (status) => [200, 404].includes(status),
    });
    return response.status === 200 ? this.mapBusiness(BusinessDtoSchema.parse(response.data)) : undefined;
  }

  async completeStripeAccount(venueId: string): Promise<void> {
    await axios.post(`${this.baseUrl}/v1/venues/${venueId}/stripe-account`, {});
  }

  private mapBusinesses(data: Business[]): Business[] {
    const result: Business[] = [];
    data.map((business: Business) => {
      result.push(this.mapBusiness(EnterpriseDtoSchema.parse(business)));
    });
    return result;
  }

  private mapBusiness(parsed: any): Business {
    const enrollmentsSortedActiveFirsts: Enrollment[] = _.chain(parsed.enrollments)
      .groupBy((e) => e.product)
      .map((sameProductEnrollments) => _.sortBy(sameProductEnrollments, activeFirstComparison))
      .flatMap((sameProductEnrollments) => sameProductEnrollments[0])
      .sortBy(activeFirstComparison)
      .value();

    function getEnrollmentForServiceType(serviceType: ServiceType) {
      return enrollmentsSortedActiveFirsts.find((e) => e.product === serviceType);
    }

    return {
      id: parsed.id,
      name: parsed.name,
      legalName: parsed.legalName,
      address: parsed.address,
      timezone: parsed.timezone,
      metadata: parsed.metadata,
      currency: parsed.currency,
      posReference: parsed.posReference,
      tags: parsed.tags,
      status: parsed.status,
      isInitialSetupCompleted: parsed.isInitialSetupCompleted,
      enrollments: enrollmentsSortedActiveFirsts,
      patEnrollment: getEnrollmentForServiceType(ServiceType.PAY_AT_TABLE),
      oapEnrollment: getEnrollmentForServiceType(ServiceType.ORDER_AND_PAY),
      pdqEnrollment: getEnrollmentForServiceType(ServiceType.PAYMENT_TERMINAL),
      smbEnrollment: getEnrollmentForServiceType(ServiceType.SMB_PAY),
      createdAt: parsed.createdAt,
    };
  }
}
