import { FormControl, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { DateRange } from '../domain/DateRange';
import { ampli } from 'src/ampli';

export type TimeframeSelection = {
  key: string;
  range: DateRange;
  numberOfDays: number;
};
export const LAST_7_DAYS: TimeframeSelection = {
  key: 'LAST_7_DAYS',
  range: DateRange.lastSevenDays(),
  numberOfDays: 7,
};
export const LAST_14_DAYS: TimeframeSelection = {
  key: 'LAST_14_DAYS',
  range: DateRange.lastTwoWeeks(),
  numberOfDays: 14,
};
export const LAST_30_DAYS: TimeframeSelection = {
  key: 'LAST_30_DAYS',
  range: DateRange.lastThirtyDays(),
  numberOfDays: 30,
};

type Props = {
  timeframe: TimeframeSelection,
  onChange: (e: TimeframeSelection) => void,
};

export const TimeframePicker = ({
  timeframe,
  onChange,
}: Props) => (

  <FormControl>
    <Select
      labelId="timeframe-filter-select-label"
      id="timeframe-filter-select"
      value={timeframe.key}
      onChange={(event) => {
        switch (event.target.value) {
          case 'LAST_7_DAYS':
            ampli.reviewsReplyTimeframeSelected({ period: event.target.value });
            onChange(LAST_7_DAYS);
            break;
          case 'LAST_14_DAYS':
            ampli.reviewsReplyTimeframeSelected({ period: event.target.value });
            onChange(LAST_14_DAYS);
            break;
          case 'LAST_30_DAYS':
            ampli.reviewsReplyTimeframeSelected({ period: event.target.value });
            onChange(LAST_30_DAYS);
            break;
          default:
            ampli.reviewsReplyTimeframeSelected({ period: 'LAST_7_DAYS' });
            onChange(LAST_7_DAYS);
            break;
        }
      }}
    >
      {[LAST_7_DAYS, LAST_14_DAYS, LAST_30_DAYS]
        .map((tframe) => (
          <MenuItem value={tframe.key} key={tframe.key}>
            <FormattedMessage
              id="venue.reviews.last_x_days"
              values={{ days: tframe.numberOfDays }}
              defaultMessage={`last ${tframe.numberOfDays} days`}
            />
          </MenuItem>
        ))}
    </Select>
  </FormControl>
);
