import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { VenueDetails } from '../../../types';
import { BoxDetails } from '../../../../box/domain/BoxDetails';
import { SettingsCategory } from './SettingsCategory';
import { DisplayVenueInfoSetting } from './VenueInfo/DisplayVenueInfoSetting';
import { VenuePictureSetting } from './VenuePicture/venuePictureSetting';
import { WebsiteUrlVenueSetting } from './WebsiteUrl/WebsiteUrlVenueSetting';
import { BoxOpeningHourSetting } from '../../../../box/pages/BoxSettings/components/OpeningHour/BoxOpeningHourSetting';
import { VenueEmailSetting } from './VenueEmail/VenueEmailSetting';

type MonoBoxSettingsProps = {
  venue: VenueDetails;
  box: BoxDetails;
  setVenue: (venue: VenueDetails) => void;
  setBox: (box: BoxDetails) => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const MonoBoxSettings = ({
  venue, box, setVenue, setBox,
}: MonoBoxSettingsProps) => {
  const intl = useIntl();
  return (
    <Container>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'settings.app_settings.app_design.title',
          defaultMessage: 'app design',
        })}
      >
        <DisplayVenueInfoSetting venue={venue} setVenue={setVenue} />
        <VenuePictureSetting venue={venue} setVenue={setVenue} />
        <WebsiteUrlVenueSetting venue={venue} setVenue={setVenue} />
        <VenueEmailSetting venue={venue} setVenue={setVenue} />
      </SettingsCategory>
      <SettingsCategory
        title={intl.formatMessage({
          id: 'venue.setOpeningHoursTitle',
          defaultMessage: 'opening hours',
        })}
        description={intl.formatMessage({
          id: 'venue.setOpeningHoursDescription',
          defaultMessage: 'Customize the opening hours here',
        })}
      >
        <BoxOpeningHourSetting box={box} setBox={setBox} />
      </SettingsCategory>
    </Container>
  );
};
