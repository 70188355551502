import * as React from 'react';
import { useEffect } from 'react';
import { Autocomplete, Chip, CircularProgress, TextField } from '@mui/material';
import { red } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Allergen } from '../../../domain/Allergen';
import { UpdateProductDto } from '../../../repositories/ProductDto';
import { Product } from '../../../domain/Product';
import { useGetAllergensByBusiness } from '../../../queries/product/getAllergensByBusinessQuery';
import { BusinessId } from 'src/business/domain/Business';

type Props = {
  businessId: BusinessId,
  product: Product,
  productUpdateDto: UpdateProductDto,
  setProductUpdateDto: (productUpdate: UpdateProductDto) => void
};

const buildNewAllergenList = (availableAllergens: Allergen[], values: readonly string[]) => values.map((allergenName) => {
  const existingAllergen = availableAllergens.find((t) => t.name.toLowerCase().trim() === allergenName.toLowerCase().trim());
  if (existingAllergen) {
    return existingAllergen;
  }
  return {
    id: uuidv4().toString(),
    name: allergenName,
  };
});

const checkChangesAndMarkAsChanged = (newSelectedAllergens: Allergen[], previouslySelectedAllergens: Allergen[]) => {
  if (newSelectedAllergens.length !== previouslySelectedAllergens.length) {
    return true;
  }

  newSelectedAllergens.forEach((newAllergen) => {
    if (!previouslySelectedAllergens.find((t) => t.id === newAllergen.id)) {
      return true;
    }
  });

  return false;
};

export const ProductAllergensInput = ({
  product, businessId, productUpdateDto, setProductUpdateDto,
}: Props) => {
  const intl = useIntl();
  const [allergens, setAllergens] = React.useState<Allergen[]>(product.allergens);

  useEffect(() => {
    setAllergens(product.allergens);
  }, [product, product.allergens]);

  const allergensList = [
    intl.formatMessage({ id: 'menus.allergens.gluten' }),
    intl.formatMessage({ id: 'menus.allergens.celery' }),
    intl.formatMessage({ id: 'menus.allergens.crustaceans' }),
    intl.formatMessage({ id: 'menus.allergens.eggs' }),
    intl.formatMessage({ id: 'menus.allergens.lupin' }),
    intl.formatMessage({ id: 'menus.allergens.milk' }),
    intl.formatMessage({ id: 'menus.allergens.molluscs' }),
    intl.formatMessage({ id: 'menus.allergens.mustard' }),
    intl.formatMessage({ id: 'menus.allergens.peanuts' }),
    intl.formatMessage({ id: 'menus.allergens.sesame' }),
    intl.formatMessage({ id: 'menus.allergens.sulphites' }),
    intl.formatMessage({ id: 'menus.allergens.treenuts' }),
  ];

  const {
    data: existingAllergens,
    isLoading: allergensLoading,
  } = useGetAllergensByBusiness(businessId);

  const getAllAllergens = () => Array.from(new Set([...allergensList, ...(existingAllergens?.map((t) => t.name) ?? [])]));

  function updateSelectedAllergens(values: readonly string[]) {
    const newAllergens = buildNewAllergenList(existingAllergens ?? [], values);
    setAllergens(newAllergens);

    const hasChanged = checkChangesAndMarkAsChanged(newAllergens, allergens);
    if (hasChanged) {
      setProductUpdateDto({
        ...productUpdateDto,
        allergens: newAllergens,
      });
    } else {
      setProductUpdateDto({
        ...productUpdateDto,
        allergens: undefined,
      });
    }
  }

  if (allergensLoading) return <CircularProgress />;

  return (
    <>
      <Autocomplete
        sx={{ backgroundColor: red }}
        multiple
        freeSolo
        options={getAllAllergens()}
        value={allergens.map((t) => t.name)}
        defaultValue={allergens.map((t) => t.name)}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            // eslint-disable-next-line react/jsx-key
            <Chip variant="outlined" color="default" label={option} {...getTagProps({ index })} />
          ))}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={intl.formatMessage({ id: 'menus.product.allergens.label' })}
            placeholder=""
          />
        )}
        onChange={(event, value) => {
          updateSelectedAllergens(value);
        }}
      />

    </>

  );
};
