import { Chart } from 'chart.js';
import _ from 'lodash';


export const sumDataLabels = (renderLabel?: (value:number) => string) => {

  return ({
    id: 'sumDataLabels',
    afterDatasetsDraw:
      (chart: Chart) => {
        const { ctx } = chart;

        const datasets = chart.data.datasets;
        const datasetsAndMeta = datasets.map((dataset, i) => ({
          datasetMeta: chart.getDatasetMeta(i),
          dataset: dataset,
        }));
        const visibleDatasetsAndMeta = datasetsAndMeta.filter(d => !d.datasetMeta.hidden);

        const indexes = datasets[0].data;
        indexes.forEach((ignored, x) => {
          const allDataPointsForCurrentX = visibleDatasetsAndMeta.map(d => d.datasetMeta.data[x]);
          if (allDataPointsForCurrentX.length === 0) return;

          const sumValues = _.sum(visibleDatasetsAndMeta.map((d) => d.dataset.data[x]));
          const drawX = allDataPointsForCurrentX[0].x;
          const drawY = Math.min(...allDataPointsForCurrentX.map(dp => dp.y)); //0,0 is on top left so we use min to find the highest point

          ctx.save();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'bottom';

          const label = renderLabel ? renderLabel(sumValues) : _.round(sumValues, 2).toString();
          ctx.fillText(label, drawX, drawY);

        });
      },
  });
};
