import { AxiosStatic } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { Payments } from '../model/Payments';
import { BusinessId } from 'src/business/domain/Business';
import { EndOfServiceConfiguration } from '../model/EndOfServiceConfiguration';
import { Walkouts } from 'src/operations/end-of-service/model/Walkouts';
import { CountryCode } from 'src/domain/CountryCode';
import { CardBrand } from 'src/payments/domain/Payment';
import { Money } from '@sundayapp/web-money';

import {
  NonReconciledPayment,
  PaymentProductOrigin,
  ReconciliationReport,
} from 'src/operations/end-of-service/model/NonReconciledPayment';


export interface PaymentSourceOfDiscrepanciesDTO {
  sundayPaymentDate: number;
  paymentId: {
    value: string;
  };
  sundayPaymentOrigin: PaymentProductOrigin;
  amount: Money;
  tipsNotifiedToPos: Money;
  tableNumber?: string;
  waiterName?: string;
  billCode?: string;
  cardInfos?: {
    countryCode?: CountryCode;
    brand: CardBrand
    last4?: string;
    network?: string;
    isAmex: boolean;
  },
}

interface ReconciliationReportDTO {
  nonReconciledFailedToBeNotifiedPayments: PaymentSourceOfDiscrepanciesDTO[];
  nonReconciledPdqPaymentsWithoutAssociatedTable: PaymentSourceOfDiscrepanciesDTO[];
}

const toDomain = (data: PaymentSourceOfDiscrepanciesDTO): NonReconciledPayment => {
  const timestampInMilliseconds = data.sundayPaymentDate * 1000;
  return {
    createdAt: new Date(timestampInMilliseconds),
    paymentId: data.paymentId.value,
    sales: data.amount,
    posPooledTips: data.tipsNotifiedToPos,
    productOrigin: data.sundayPaymentOrigin,
    tableNumber: data.tableNumber,
    waiterName: data.waiterName,
    billCode: data.billCode,
    cardInfos: data.cardInfos && {
      countryCode: data.cardInfos.countryCode,
      brand: data.cardInfos.brand,
      last4: data.cardInfos.last4,
      network: data.cardInfos.network,
      isAmex: data.cardInfos.isAmex,
    },
  };
};


export class EndOfServiceClient {

  private readonly accountingApiBaseUrl: string;

  private readonly bookkeepingApiBaseUrl: string;

  constructor(private httpClient: AxiosStatic) {
    const configuration = ConfigurationLoader.load();
    this.accountingApiBaseUrl = configuration.accountingApiBaseUrl;
    this.bookkeepingApiBaseUrl = configuration.bookkeepingApiBaseUrl;
  }

  async summaryOnAPeriod(businessId: BusinessId, startDate: Date, endDate: Date): Promise<Payments> {
    return this.getEOSDataFromProductPayment(businessId, startDate, endDate)
      .then(it => it.data);
  }

  async walkoutsOnAPeriod(businessId: BusinessId, startDate: Date, endDate: Date): Promise<Walkouts> {
    return this.getEOSDataFromWalkout(businessId, startDate, endDate)
      .then(it => it.data);
  }

  async getEndOfServiceConfiguration(businessId: BusinessId): Promise<EndOfServiceConfiguration> {
    return this.httpClient.get<EndOfServiceConfiguration>(`${this.bookkeepingApiBaseUrl}/eos/businesses/${businessId}/configuration`, {
      headers: {
        contentType: 'application/json',
      },
    })
      .then(it => it.data)
      .catch(() => {
        return ({
          bourdoncleModeEnabled: false,
          displayPrintMode: false,
          displayRevenueCenters: false,
        } as EndOfServiceConfiguration);
      },
      );
  }

  async reconcilePOSOperationsWithSundayPayments(businessId: BusinessId, startDate: Date, endDate: Date): Promise<String | null> {

    return this.httpClient.get<String>(`${this.bookkeepingApiBaseUrl}/reconciliation/${businessId}/export`, {
      headers: {
        contentType: 'text/csv',
      },
      params: {
        startDate: startDate,
        endDate: endDate,
      },
    })
      .then(it => it.data)
      .catch(() => {
        return null;
      },
      );
  }

  private getEOSDataFromProductPayment(businessId: BusinessId, startDate: Date, endDate: Date) {
    return this.httpClient.get<Payments>(`${this.accountingApiBaseUrl}/eos/businesses/${businessId}/payments`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    });
  }

  private getEOSDataFromWalkout(businessId: BusinessId, startDate: Date, endDate: Date) {
    return this.httpClient.get<Walkouts>(`${this.accountingApiBaseUrl}/eos/businesses/${businessId}/manual-walkouts`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        from: startDate,
        to: endDate,
      },
    });
  }

  async getReconciliationReport(businessId: BusinessId, startDate: Date, endDate: Date): Promise<ReconciliationReport> {
    const emptyReport = { nonNotifiedPayments: [], fastPaymentPdq: [] };
    return this.httpClient.get<ReconciliationReportDTO>(`${this.bookkeepingApiBaseUrl}/reconciliation/${businessId}/report`, {
      headers: {
        contentType: 'application/json',
      },
      params: {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    })
      .then(response => {
        if (!response.data) return emptyReport;
        return {
          nonNotifiedPayments: response.data.nonReconciledFailedToBeNotifiedPayments.map(toDomain),
          fastPaymentPdq: response.data.nonReconciledPdqPaymentsWithoutAssociatedTable.map(toDomain),
        };
      })
      .catch(() => {
        return emptyReport;
      },
      );

  }


}

