import { MoneyView } from '@sundayapp/web-money';
import React, { ReactNode, useMemo } from 'react';
import styled from 'styled-components';
import { OrderItem as OrderItemType } from '../../types';
import OrderItemModifiersList from './OrderItemModifiersList';
import { computeItemTotalPrice } from '../../services';
import { colorPalette, getSpacing, textStyles } from '../../../stylesheet';

interface Props {
  item: OrderItemType;
  children?: ReactNode;
}

const OrderItemContainer = styled.div`
  margin: 0 0 -1px 0;
  padding: ${getSpacing(3)} 0;
  align-items: flex-start;
  border-bottom: 1px solid ${colorPalette.lightBorderColor};
`;

const OrderItemLabelAndAction = styled.div`
  display: flex;
  align-items: flex-start;
`;

const OrderItemLabel = styled.div`
  flex: 1;
`;

const OrderItemName = styled.div`
  ${textStyles.default};
  padding-top: ${getSpacing(1)};
`;

const OrderItemUnitPrice = styled.div`
  display: flex;
  margin-top: ${getSpacing(3)};
  justify-content: flex-end;
  ${textStyles.small}
  color: ${colorPalette.grey600};
`;

const OrderItemQuantity = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${getSpacing(4)};
  height: ${getSpacing(4)};
  border: 1px solid ${colorPalette.lightBorderColor};
  border-radius: ${getSpacing(4)};
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.03em;
  color: ${colorPalette.grey600};
`;

const OrderItem: React.FC<Props> = ({ item, children }) => {
  const totalPrice = useMemo(() => computeItemTotalPrice(item), [item]);
  return (
    <OrderItemContainer>
      <OrderItemLabelAndAction>
        <OrderItemLabel>
          <OrderItemName>{item.name}</OrderItemName>
          <OrderItemModifiersList subItems={item.subItems} />
        </OrderItemLabel>
        {children || <OrderItemQuantity>{item.quantity}</OrderItemQuantity>}
      </OrderItemLabelAndAction>
      <OrderItemUnitPrice>
        <MoneyView value={totalPrice} />
      </OrderItemUnitPrice>
    </OrderItemContainer>
  );
};

export default OrderItem;
