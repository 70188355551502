import React, { createContext, ReactNode, useMemo } from 'react';
import { OrderingPaymentConfig } from './OrderingPaymentConfig';
import { OrderingPaymentRepositoryHttp } from '../repositories/OrderingPaymentRepositoryHttp';

type OrderingPaymentContextProviderProps = {
  orderingBackendUrl: string;
  children: ReactNode;
};

type ProviderConfig = OrderingPaymentConfig & {
  children: ReactNode;
};

export const OrderingPaymentConfigContext = createContext<Partial<ProviderConfig>>({});

const Provider: React.FC<ProviderConfig> = ({ children, ...config }) => <OrderingPaymentConfigContext.Provider value={config}>{children}</OrderingPaymentConfigContext.Provider>;

export const OrderingPaymentContextProvider = ({ orderingBackendUrl, children }: OrderingPaymentContextProviderProps) => {
  // Repo
  const orderingPaymentRepository = useMemo(() => new OrderingPaymentRepositoryHttp(orderingBackendUrl), [orderingBackendUrl]);

  return <Provider orderingPaymentRepository={orderingPaymentRepository}>{children}</Provider>;
};
