import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { useUpdateBoxDetails } from '../../../../hooks';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type SwitchOffTableNameInNotesForServiceAtTableProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const SwitchOffTableNameInNotesForServiceAtTable = ({ box, setBox }: SwitchOffTableNameInNotesForServiceAtTableProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateSwitchOffTableNameInNotesForServiceAtTable = async (switchOffTableNameInNotesForServiceAtTable: boolean) => {
    makeUpdating(true);
    await updateBoxDetails({
      switchOffTableNameInNotesForServiceAtTable,
    });
    setBox({
      ...box,
      switchOffTableNameInNotesForServiceAtTable,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'venue.switchOffTableNameInNotesForServiceAtTable',
        defaultMessage: 'Do not add table name to order notes, in service at table orders',
      })}
      description={intl.formatMessage({
        id: 'venue.switchOffTableNameInNotesForServiceAtTableMessage',
        defaultMessage:
          'When active, table name will not be added in the order notes, for service at table and hybrid orders',
      })}
      toggleValue={box.switchOffTableNameInNotesForServiceAtTable}
      toggleOnChange={updateSwitchOffTableNameInNotesForServiceAtTable}
      disabled={isUpdating}
    />
  );
};
