// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';

type MenuDailyScheduleProps = {
  active: boolean;
  messages: string[];
};

const MenuDailySchedule = ({ active, messages }: MenuDailyScheduleProps) => {
  const fontColor = active ? colorUsage.secondaryText : colorUsage.disabledText;

  return (
    <>
      {messages.map((message) => (
        <Text size="medium" color={fontColor} paddingLeft={spaceUsages.small} key={message}>
          {message}
        </Text>
      ))}
    </>
  );
};

export default MenuDailySchedule;
