import axios, { isAxiosError } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ProductDto } from './ProductDto';
import { BusinessId } from 'src/business/domain/Business';
import {
  VariantGroupRepository,
  VariantGroupVariantError,
} from 'src/menu/dynamic-menu/repositories/VariantGroupRepository';
import {
  VariantGroupCreationRequest,
  VariantGroupUpdateRequest,
} from 'src/menu/dynamic-menu/repositories/VariantGroupDto';

export class VariantGroupRepositoryHttp implements VariantGroupRepository {
  constructor(private menuBackendUrl: string) {
  }

  async createVariantGroup(businessId: BusinessId, request: VariantGroupCreationRequest): Promise<string> {
    const response = await axios.post<ProductDto>(
      `${this.menuBackendUrl}/businesses/${businessId}/product-catalog/variant-groups`,
      {
        name: request.name,
        currency: request.businessCurrency,
        variants: request.variants.map((variant) => ({ id: variant })),
      },
      {
        withCredentials: true,
        headers: {
          correlation_id: uuidv4(),
        },
      },
    );
    return response.data.id;
  }

  async updateVariantGroup(businessId: BusinessId, variantGroupId: string, request: VariantGroupUpdateRequest): Promise<void> {
    try {

      return await axios.put(
        `${this.menuBackendUrl}/businesses/${businessId}/product-catalog/variant-groups/${variantGroupId}`,
        request,
        {
          withCredentials: true,
          headers: {
            correlation_id: uuidv4(),
          },
        },
      );
    } catch (e) {
      const variantsError = isAxiosError(e) && e.response?.status === 400;
      if (variantsError) {
        throw new VariantGroupVariantError();
      } else {
        throw e;
      }
    }
  }
}
