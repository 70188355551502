import { ShiftsResponse } from './ShiftsResponse';
import { Shift } from '../domain/Shift';
import { ShiftHour } from '../domain/ShiftHour';

export class ShiftsResponseMapper {
  static toShift(response: ShiftsResponse): Shift[] {
    return response.shifts
      .map((shift) => (new Shift(
        shift.venue_id,
        new ShiftHour(shift.start_shift_hour.hour, shift.start_shift_hour.minute),
        new ShiftHour(shift.end_shift_hour.hour, shift.end_shift_hour.minute),
        shift.shift_type === 'DINNER' ? 'DINNER' : 'LUNCH',
        shift.id,
      )));
  }
}
