export const TableTheme = {
  styleOverrides: {
    root: {
      borderColor: 'transparent',
      borderRadius: '16px',
      backgroundColor: 'white',
      boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.04), 0px 1px 3px 0px rgba(0, 0, 0, 0.04)',

      '& .MuiTableHead-root': {
        color: '#70707B',
        borderBottom: '1px solid rgba(66, 66, 72, 0.14)',

        '& .MuiTableCell-root': {
          color: '#70707B',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '16px',
          letterSpacing: '-0.3px',
          padding: '6px 16px',
        },

        '& .MuiTableRow-root': {
          '&:first-of-type': {
            '& .MuiTableCell-root': {
              padding: '24px 16px 6px 16px',
            },
          },
        },
      },

      '& .MuiTableBody-root': {
        '& .MuiTableRow-root': {
          '& > .MuiTableCell-root': {
            borderBottom: '1px solid rgba(66, 66, 72, 0.14)',
            borderTop: '1px solid rgba(66, 66, 72, 0.14)',
            padding: '16px',
          },

          '&:first-of-type': {
            '& .MuiTableCell-root': {
              borderTop: 0,
            },
          },

          '&:last-of-type': {
            '& .MuiTableCell-root': {
              borderBottom: 0,
            },
          },
        },
      },
    },
  },
};
