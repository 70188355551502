// eslint-disable-next-line @typescript-eslint/no-redeclare
import { IconWrapper, PlusSvg, spaceUsages, Text } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import MenuTimeRange from './MenuTimeRange';
import { Hours } from '../../domain/StaticMenus';

const Container = styled.div`
  margin-top: ${spaceUsages.largeMedium};
  padding-left: ${spaceUsages.mediumSmall};
  padding-right: ${spaceUsages.mediumSmall};
  width: 100%;
`;

const Header = styled.div`
  margin-bottom: ${spaceUsages.largeSmall};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

type MenuTimeRangesProps = {
  hours: Hours[];
  setHours: (hours: Hours[]) => void;
};

const MenuTimeRanges = ({ hours, setHours }: MenuTimeRangesProps) => {
  const MAX_TIME_RANGE = 5;

  const onNewTimeRange = () => {
    setHours(hours.concat({ start: '00:00', end: '24:00' }));
  };

  const removeTimeRange = (index: number) => {
    setHours(hours.filter((item, idx) => index !== idx));
  };

  const onTimeRangeUpdated = (index: number, updatedHour: Hours) => {
    const updatedHours = hours.map((hour, idx) => {
      if (index === idx) {
        return updatedHour;
      }
      return hour;
    });

    setHours(updatedHours);
  };

  return (
    <Container>
      <Header>
        <Text size="large">
          <FormattedMessage id="menus.schedule_selector.modal.time_range.title" />
        </Text>
        {hours.length < MAX_TIME_RANGE && (
          <IconWrapper size="small" cursor="pointer" onClick={onNewTimeRange}>
            <PlusSvg />
          </IconWrapper>
        )}
      </Header>
      {hours.map((hour, index) => (
        <MenuTimeRange
          key={String(index)}
          startTime={hour.start}
          endTime={hour.end}
          onUpdate={(time) => onTimeRangeUpdated(index, time)}
          onDelete={() => removeTimeRange(index)}
        />
      ))}
    </Container>
  );
};

export default MenuTimeRanges;
