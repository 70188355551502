import React, { ReactNode, useState } from 'react';
import { Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@sundayapp/b2b-react-component-library';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const copyPaste = async (text: string | undefined) => {
  if (!text) return;
  await navigator.clipboard
    .writeText(text);
};

export const CopyPaste: React.FC<{
  value: string | undefined,
  style?: any,
  className?: any,
  content?: ReactNode
}> = ({
  value,
  style,
  className,
  content,
}) => {
  const [open, setOpen] = useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);
  const intl = useIntl();

  return (
    <Tooltip
      style={style}
      className={className}
      PopperProps={{
        disablePortal: true,
      }}
      placement="right-start"
      onClose={handleTooltipClose}
      open={open}
      disableInteractive
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={intl.formatMessage({ id: 'common.copied' })}
    >
      <div onClick={async () => {
        copyPaste(value).then(() => {
          handleTooltipOpen();
          setTimeout(() => handleTooltipClose(), 1000);
        });
      }}>
        {content ? content : <IconButton size="medium"><ContentCopyIcon /></IconButton>}
      </div>
    </Tooltip>
  );
};
