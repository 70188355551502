import * as React from 'react';
import { styled } from '@mui/material';
import { UserInfo } from 'src/user/components/UserInfo';
import { PaletteTheme } from 'src/app/theme/PaletteTheme';
import DoubleChevronSvg from '../../../component/icons/DoubleChevronSvg';

type LoggedUserProps = {
  compact: boolean;
  onClick: () => void;
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  border-radius: 8px;
  padding: 8px;

  &:hover {
    background-color: ${() => PaletteTheme.menu.hover};
  }
`;

const DoubleChevronIcon = styled(DoubleChevronSvg)`
  min-width: 16px;
`;

export const LoggedUser = ({ compact, onClick }: LoggedUserProps) => (
  <Container onClick={onClick} sx={{ justifyContent: compact ? 'center' : 'space-between' }}>
    <UserInfo compact={compact} />
    {!compact && <DoubleChevronIcon color="#B4B4BB" />}
  </Container>
);
