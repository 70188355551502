import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { ReceiptDisplaySettings } from '../domain/ReceiptDisplaySettings';
import { httpBillReceiptRepository } from 'src/infrastructure/payment/HttpBillReceiptRepository';
import { BusinessId } from 'src/business/domain/Business';

export const QUERYKEY_RECEIPT_DISPLAY_SETTINGS = 'receipt_display_settings';
const buildQueryKey = (businessId: BusinessId) => [QUERYKEY_RECEIPT_DISPLAY_SETTINGS, businessId] as const;
export const getReceiptDisplaySettings = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<typeof buildQueryKey>>): Promise<ReceiptDisplaySettings> => {
  const [, businessId] = queryKey;
  return httpBillReceiptRepository.getReceiptDisplaySettings(businessId);
};

export const useGetReceiptDisplaySettings = (businessId: BusinessId) => useQuery({ queryKey: buildQueryKey(businessId), queryFn: getReceiptDisplaySettings });
