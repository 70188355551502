import { useQuery } from '@tanstack/react-query';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { HttpReviewAiSettingsRepository } from './HttpReviewAiSettingsRepository';
import { aiSettingsQueryKey } from './AiSettingsQueryKey';
import { getBrowserLocaleFullNameInEnglish } from 'src/app/i18n/I18nProvider';

const repository = new HttpReviewAiSettingsRepository(ConfigurationLoader.load().venueFeedbackBaseUrl);

export const useAiSettings = () => {
  const business = useCurrentBusinessOrThrow();

  return useQuery({
    queryKey: aiSettingsQueryKey(business.id),
    queryFn: async () => {
      return await repository.get(business.id) ?? {
        guessLanguage: true,
        replyLanguage: getBrowserLocaleFullNameInEnglish(),
      };
    },
  },
  );
};
