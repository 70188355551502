import { Money } from '@sundayapp/web-money';

export enum PaymentSummaryStatus {
  CREATED = 'CREATED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum PaymentStatus {
  UNSPECIFIED = 'Unspecified',
  CREATED = 'Created',
  SUCCEEDED = 'Succeeded',
  FAILED = 'Failed',
  CANCELED = 'Canceled',
  ACTION_REQUIRED = 'ActionRequired',

  UNKNOWN = 'UNKNOWN',
}

export const PaymentStatusRecord: Record<PaymentStatus, null> = {
  [PaymentStatus.UNSPECIFIED]: null,
  [PaymentStatus.CREATED]: null,
  [PaymentStatus.SUCCEEDED]: null,
  [PaymentStatus.FAILED]: null,
  [PaymentStatus.CANCELED]: null,
  [PaymentStatus.ACTION_REQUIRED]: null,

  [PaymentStatus.UNKNOWN]: null,
};

export enum RefundStatus {
  UNSPECIFIED = 'UNSPECIFIED',
  CREATED = 'CREATED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',

  UNKNOWN = 'UNKNOWN',
}

export const RefundStatusRecord: Record<RefundStatus, null> = {
  [RefundStatus.UNSPECIFIED]: null,
  [RefundStatus.CREATED]: null,
  [RefundStatus.SUCCEEDED]: null,
  [RefundStatus.FAILED]: null,
  [RefundStatus.CANCELED]: null,
  [RefundStatus.PENDING]: null,

  [RefundStatus.UNKNOWN]: null,
};

export type Refund = {
  id: string;
  paymentId: string;
  amount: Money;
  status: PaymentSummaryStatus;
};

export type PaymentSummary = {
  id: string;
  orderId: string;
  orderAmount: Money;
  status: PaymentSummaryStatus;
  tipsAmount: Money;
  userFeeAmount: Money;
  totalAmount: Money;
  refunds: Refund[];
};

export type TaxSummary = {
  taxes: Record<string, Money>;
};

type PaymentFailDetails = {
  failCode: string;
  declineCode: string;
  nextStep: string;
  rawFailMessage: string;
};

export type PaymentRefund = {
  amount: Money;
  createdAt: number;
  description: string;
  refundId: string;
  status: string;
};

export type Payment = {
  paymentId: string;
  status: string;
  createdAt: number;
  totalAmount: Money;
  orderAmount: Money;
  tipsAmount: Money;
  serviceChargeAmount: Money;
  refunds: PaymentRefund[];
  paymentProvider: string;
  paymentMethodType: string;
  cardBrand: string | null;
  paymentMethodReference: string | null;
  voucher: string | null;
  payoutSource: string;
  failDetails: PaymentFailDetails | null;
  metadata: Record<string, string>;
};

export type PaymentProvider = 'STRIPE' | 'EDENRED' | 'TOAST' | 'THUNES' | 'PAY_CRITICAL' | 'PAY_GREEN' | 'CHECKOUT';
