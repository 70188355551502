import { Panel } from '@sundayapp/b2b-react-component-library';
import styled from 'styled-components';
import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import MenuTableHeader from './components/MenuTableHeader';
import MenuCreationModal from './components/MenuCreationModal';
import MenuPriorityPanel from './components/MenuPriorityPanel';
import MenuItems from './components/MenuItems';
import { DraggableMenuContainer } from './components/DraggableMenuContainer';
import MenuLinkHandler from '../../../pages/Settings/Customization/components/MenuLinkHandler';
import { useListStaticMenus } from '../infrastructure/queries/useListStaticMenus';
import { useBusinessIdOrThrow } from 'src/business/hooks/useBusinessId';
import { StaticMenu } from '../domain/StaticMenus';

const MenuItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const StaticMenusPage = () => {
  const businessId = useBusinessIdOrThrow();
  const [orderedMenus, setOrderedMenus] = useState<StaticMenu[]>([]);
  const [createMenuDialogOpened, setCreateMenuDialogOpened] = useState<boolean>(false);
  const [updateMenuPriority, setUpdateMenuPriority] = useState<boolean>(false);

  const { data: menus } = useListStaticMenus(businessId);

  if (!menus) {
    return null;
  }

  return (
    <Stack gap={'1em'}>
      <Typography sx={{ opacity: 0.6, marginBottom: '24px' }}>
        <FormattedMessage id="menu.static-menu.explanation" />
      </Typography>
      <MenuLinkHandler businessId={businessId} />
      <Panel>
        <MenuTableHeader
          showCreateButton={!updateMenuPriority}
          showDragButton={!updateMenuPriority && menus.length > 1}
          onCreateMenu={() => setCreateMenuDialogOpened(true)}
          onUpdateMenuPriority={() => {
            setUpdateMenuPriority(true);
          }}
        />
        <MenuItemsContainer>
          {!updateMenuPriority && <MenuItems menus={menus} />}
          {updateMenuPriority && (
            <DraggableMenuContainer
              menus={menus}
              setMenus={(updatedMenus: StaticMenu[]) => setOrderedMenus(updatedMenus)}
            />
          )}
        </MenuItemsContainer>

        {updateMenuPriority && (
          <MenuPriorityPanel orderedMenu={orderedMenus} closePriorityMode={() => setUpdateMenuPriority(false)} />
        )}
      </Panel>
      <MenuCreationModal isOpened={createMenuDialogOpened} onClose={() => setCreateMenuDialogOpened(false)} />
    </Stack>
  );
};
