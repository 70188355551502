import React, { useMemo, useState } from 'react';
import { InputText } from '@sundayapp/b2b-react-component-library';
import styled, { css } from 'styled-components';
import { debounce } from 'lodash';
import { DetailedProductAvailability } from '../../types';

interface Props {
  product: DetailedProductAvailability;
  updateAvailability: (value?: number) => void;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface AvailabilityProps {
  outOfStock?: boolean;
}

const StyledInputText = styled(InputText)<AvailabilityProps>`
  input {
    text-align: right;
  }
  ${({ outOfStock }) =>
    outOfStock
    && css`
      input {
        color: #ee184b;
      }
    `}
`;

const allTrim = (s: string) => s.trim().split(' ').join('');

const isSign = (s: string) => (s === '-' || s === '+');

const isValidNumber = (s: string) => {
  const trimmed = allTrim(s);
  if (isSign(trimmed)) return true;
  const numeric = Number(trimmed);
  // Allow empty to reset field
  return s.trim().length === 0 || (!Number.isNaN(numeric) && Number.isFinite(numeric));
};

export const ProductUpdateAvailabilityInput: React.FC<Props> = ({ product, updateAvailability }) => {
  const [availabilityString, setAvailabilityString] = useState<string>('');

  const debouncedUpdater = useMemo(
    () => debounce((val) => {
      const trimmed = allTrim(val);
      if (trimmed !== '' && !isSign(trimmed) && isValidNumber(trimmed)) {
        updateAvailability(Number(trimmed));
      }
      setAvailabilityString('');
    }, 800),
    [updateAvailability, setAvailabilityString],
  );

  return (
    <InputContainer>
      <StyledInputText
        value={availabilityString}
        placeholder="&#177;"
        outOfStock={product.stock === 0}
        onChange={(e: any) => {
          if (isValidNumber(e.target.value)) {
            const trimmed = allTrim(e.target.value);
            setAvailabilityString(trimmed);
            if (!isSign(trimmed)) {
              debouncedUpdater(trimmed);
            }
          }
        }}
      />
    </InputContainer>
  );
};
