import { useFormContext } from 'react-hook-form';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Stack } from '@mui/material';
import React from 'react';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';

export const Consent = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const error = errors.consent;

  const intl = useIntl();
  return <FormControl component="fieldset">
    <Stack spacing={2}>
      <FormControlLabel
        control={
          <Checkbox {...register('consent', { required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.termsAndConditions.error' }) })}
                    size={'large'} />}
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.termsAndConditions' })}
        labelPlacement="end"
      />
      <FormHelperText error>{error?.message}</FormHelperText>
    </Stack>
  </FormControl>;
};
