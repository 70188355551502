import React from 'react';
import { Money, moneyToUnit, sumMoneys } from '@sundayapp/web-money';
import CsvDownloader from 'react-csv-downloader';
import { Download } from '@mui/icons-material';
import { Payment } from '../model/Payments';
import { Button } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

interface Props {
  payments: Payment[];
}

function moneyToUnitString(money: Money): string {
  return moneyToUnit(money).toFixed(2);
}

const PaymentCsvExportButton = ({ payments }: Props) => {
  const exportPaymentsToCsv = () =>
    payments.map((payment) => {
      return {
        id: payment.id,
        provider: payment.provider,
        scheme: payment.scheme,
        mealVoucher: payment.isTrd ? 'Yes' : 'No',
        totalAmount: moneyToUnitString(sumMoneys(payment.salesAmount, payment.tipsAmount)),
        salesAmount: moneyToUnitString(payment.salesAmount),
        tipsAmount: moneyToUnitString(payment.tipsAmount),
        processedAt: payment.processedAt,
        cardEntryMode: payment.cardEntryMode,
        acquisitionType: payment.acquisitionType,
        paymentContract: payment.contratMonetique,
        origin: payment.origin,
        notifyToPos: payment.notifiedToPos ? 'Yes' : 'No',
        pdqFastPayment: payment.isPdqFastPayment ? 'Yes' : 'No',
      };
    });

  const columnsDefinition = [
    {
      id: 'id',
      displayName: 'id',
    },
    {
      id: 'provider',
      displayName: 'provider',
    },
    {
      id: 'scheme',
      displayName: 'scheme',
    },
    {
      id: 'mealVoucher',
      displayName: 'meal voucher (TR)',
    },
    {
      id: 'totalAmount',
      displayName: 'total',
    },
    {
      id: 'salesAmount',
      displayName: 'sales',
    },
    {
      id: 'tipsAmount',
      displayName: 'tips',
    },
    {
      id: 'processedAt',
      displayName: 'processed at',
    },
    {
      id: 'cardEntryMode',
      displayName: 'card entry mode',
    },
    {
      id: 'acquisitionType',
      displayName: 'acquisition type',
    },
    {
      id: 'paymentContract',
      displayName: 'payment contract',
    },
    {
      id: 'origin',
      displayName: 'origin',
    },
    {
      id: 'notifyToPos',
      displayName: 'notified to POS',
    },
    {
      id: 'pdqFastPayment',
      displayName: 'PDQ fast payment',
    },
  ];

  return (
    <CsvDownloader
      filename={'payment-end-of-shift-export.csv'}
      wrapColumnChar='"'
      columns={columnsDefinition}
      datas={exportPaymentsToCsv}
    >
      <Button size="small" variant="contained" startIcon={<Download />}>
        <FormattedMessage id="reporting.bank_transfers.modal.button" />
      </Button>
    </CsvDownloader>
  );
};

export default PaymentCsvExportButton;
