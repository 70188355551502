import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { Staff } from 'src/tips/dispatch/model/Staff';
import { StaffAllocation } from 'src/tips/dispatch/model/StaffAllocation';
import { PendingTipsDispatch } from 'src/tips/dispatch/model/PendingTipsDispatch';

import { DispatchTips } from 'src/tips/dispatch/components/dispatchSteps/DispatchTips';
import { ValidateDispatch } from 'src/tips/dispatch/components/dispatchSteps/ValidateDispatch';
import { SelectDispatchStrategy } from 'src/tips/dispatch/components/dispatchSteps/SelectDispatchStrategy';
import { DispatchStrategy } from 'src/tips/dispatch/model/DispatchStrategy';

export const CreateDispatchModal = ({
  opened,
  onClose,
  tipsToDispatch,
  staffs,
}: {
  opened: boolean;
  onClose: (dispatched: boolean) => void;
  tipsToDispatch: PendingTipsDispatch;
  staffs: Staff[];
}) => {
  const [currentStep, setCurrentStep] = useState<'DISPATCH' | 'VALIDATE_DISPATCH' | 'SELECT_STRATEGY'>(
    'SELECT_STRATEGY',
  );
  const [selectedStrategy, setSelectedStrategy] = React.useState<DispatchStrategy>('byAmounts');
  const [allocations, setAllocations] = useState<StaffAllocation[]>([]);

  const onCloseSignDispatchModal = (dispatched: boolean) => {
    setCurrentStep('DISPATCH');
    if (dispatched) {
      setAllocations([]);
      onClose(dispatched);
    }
  };

  return (
    <Modal open={opened} aria-labelledby="tips-dispatch-modal">
      <>
        {currentStep === 'SELECT_STRATEGY' && (
          <SelectDispatchStrategy
            onClose={() => onClose(false)}
            onStrategySelected={(strategy) => {
              setSelectedStrategy(strategy);
              setCurrentStep('DISPATCH');
            }}
          />
        )}

        {currentStep === 'DISPATCH' && (
          <DispatchTips
            onClose={() => setCurrentStep('SELECT_STRATEGY')}
            nextStep={(alloc: StaffAllocation[]) => {
              setAllocations(alloc);
              setCurrentStep('VALIDATE_DISPATCH');
            }}
            tipsToDispatch={tipsToDispatch}
            staffs={staffs}
            strategy={selectedStrategy}
          />
        )}

        {currentStep === 'VALIDATE_DISPATCH' && (
          <ValidateDispatch
            onClose={onCloseSignDispatchModal}
            pendingTipsDispatch={tipsToDispatch}
            staffAllocations={allocations}
            strategy={selectedStrategy}
          />
        )}
      </>
    </Modal>
  );
};
