import { ThemeTooltip } from 'src/app/component/ThemeTooltip';
import React from 'react';
import { CurrentName, Item, MultiLocationsItemProps, Name } from 'src/business/components/MultiLocationsSelectionItem';
import { MultiLocationsLogo, IconStackWrapper } from 'src/multi-locations/components/MultiLocationsLogo';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';
import { useBusinessesQuery } from 'src/business/hooks/useBusinessesQuery';
import { BusinessId } from 'src/business/domain/Business';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { Badge } from 'src/app/component/Badge/Badge';

export function MultilocationsSelectionItem({
  isCompact = false,
  isCurrent = false,
  iconSize = 24,
}: MultiLocationsItemProps) {
  const user = useAuthenticatedUserOrThrow();
  const intl = useIntl();
  const { data: businesses } = useBusinessesQuery(user.relations.map((r) => r.objectId as BusinessId));
  const name = intl.formatMessage({ id: 'enterprise.all_locations' });
  const getCurrentName = () => (
    <ThemeTooltip title={name} placement="right">
      <CurrentName>{name}</CurrentName>
    </ThemeTooltip>
  );

  const getName = () => <Name>{name}</Name>;
  return (
    <Item isCurrent={isCurrent}>
      {!!businesses ? <MultiLocationsLogo iconSize={iconSize} /> : <IconStackWrapper size={iconSize} offset={6} />}
      {!isCompact && <>{isCurrent ? getCurrentName() : getName()}</>}
      {!isCompact && !isCurrent && (
        <Badge
          label={intl.formatMessage({ id: 'badge.new', defaultMessage: 'New' })}
          color={'primary'}
          size="small"
        />
      )}
    </Item>
  );
}
