/* eslint-disable max-len */
import React from 'react';
import { IconProps } from './interface';

export const CopySvg: React.FC<IconProps> = ({ size = 24, color = 'black', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_470_6278)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3333 10.5C10.8731 10.5 10.5 10.8731 10.5 11.3333V17.3333C10.5 17.7936 10.8731 18.1667 11.3333 18.1667H17.3333C17.7936 18.1667 18.1667 17.7936 18.1667 17.3333V11.3333C18.1667 10.8731 17.7936 10.5 17.3333 10.5H11.3333ZM9.5 11.3333C9.5 10.3208 10.3208 9.5 11.3333 9.5H17.3333C18.3459 9.5 19.1667 10.3208 19.1667 11.3333V17.3333C19.1667 18.3459 18.3459 19.1667 17.3333 19.1667H11.3333C10.3208 19.1667 9.5 18.3459 9.5 17.3333V11.3333Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66659 5.83325C6.44557 5.83325 6.23361 5.92105 6.07733 6.07733C5.92105 6.23361 5.83325 6.44557 5.83325 6.66659V12.6666C5.83325 12.8876 5.92105 13.0996 6.07733 13.2558C6.23361 13.4121 6.44557 13.4999 6.66659 13.4999H7.33325C7.60939 13.4999 7.83325 13.7238 7.83325 13.9999C7.83325 14.2761 7.60939 14.4999 7.33325 14.4999H6.66659C6.18035 14.4999 5.71404 14.3068 5.37022 13.9629C5.02641 13.6191 4.83325 13.1528 4.83325 12.6666V6.66659C4.83325 6.18036 5.02641 5.71404 5.37022 5.37022C5.71404 5.02641 6.18036 4.83325 6.66659 4.83325H12.6666C13.1528 4.83325 13.6191 5.02641 13.9629 5.37022C14.3068 5.71404 14.4999 6.18035 14.4999 6.66659V7.33325C14.4999 7.60939 14.2761 7.83325 13.9999 7.83325C13.7238 7.83325 13.4999 7.60939 13.4999 7.33325V6.66659C13.4999 6.44557 13.4121 6.23361 13.2558 6.07733C13.0996 5.92105 12.8876 5.83325 12.6666 5.83325H6.66659Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_470_6278">
        <rect width="16" height="16" fill="white" transform="translate(4 4)" />
      </clipPath>
    </defs>
  </svg>
);
