import React from 'react';
import styled from 'styled-components';
import { getSpacing } from '../../../../stylesheet';
import { OrderingMenu } from '../../../../orderingMenus/domain/OrderingMenu';
import IconButton from '../../../../common/components/IconButton';
import { CheckIcon, PlusIcon } from '../../../../common/components/Icons';
import { ServiceMenu } from '../../AreaListSettings/components/ServiceMenu';

type AreaDetailsServiceMenuProps = {
  menu: OrderingMenu;
  selected: boolean;
  onMenuSelection: (menu: OrderingMenu, selected: boolean) => void;
};

const MenuHeader = styled.div`
  margin-top: ${getSpacing(2)};
  padding-right: ${getSpacing(1)};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonAnchor = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: ${getSpacing(2)};
  top: ${getSpacing(-2)};
`;

export const AreaDetailsServiceMenu = ({ menu, selected, onMenuSelection }: AreaDetailsServiceMenuProps) => {
  const buttons = () => {
    if (selected) {
      return (
        <IconButton
          Icon={CheckIcon}
          active
          onClick={() => {
            onMenuSelection(menu, false);
          }}
        />
      );
    }

    return (
      <IconButton
        Icon={PlusIcon}
        onClick={() => {
          onMenuSelection(menu, true);
        }}
      />
    );
  };

  return (
    <MenuHeader>
      <ServiceMenu menu={menu} withSeparator={false} />
      <ButtonAnchor>
        <ButtonContainer>{buttons()}</ButtonContainer>
      </ButtonAnchor>
    </MenuHeader>
  );
};
