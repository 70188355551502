import { Typography } from '@mui/material';
import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Panel } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { CustomSelect, Line } from './GenericPaymentMethodSettingsPanel';
import { ReportSettingsInputs } from './ReportSettingsInputs';
import { SundayFetchMode } from '../../domain/SundayFetchMode';
import { Settings } from './styles';
import { LocalisationKey } from 'src/lang/en';

type Props = {
  register: UseFormRegister<ReportSettingsInputs>,
};

const sundayFetchModelLabels: Record<SundayFetchMode, LocalisationKey> = {
  [SundayFetchMode.WITH_TIPS]: 'reconciliation.settings.sunday_fetch_mode.with_tips',
  [SundayFetchMode.WITHOUT_TIPS]: 'reconciliation.settings.sunday_fetch_mode.without_tips',
};

export const ReportSettingsGeneralConfiguration = ({ register }: Props) => {
  const intl = useIntl();
  return (
    <Panel flexDirection="column" sx={{ marginBottom: '50px' }}>
      <Typography variant="h5" marginTop="5px">
        <FormattedMessage id="reconciliation.settings.general" />
      </Typography>
      <Settings>
        <Line>
          <Typography variant="body1" marginTop="5px">
            <FormattedMessage id="reconciliation.settings.total_on_pos_editable" />
          </Typography>
          <input
            type="checkbox"
            style={{ width: '20px', height: '20px' }}
            {...register('totalEditableOnPos')}
          />
        </Line>
        <Line>
          <Typography variant="body1" marginTop="5px">
            <FormattedMessage id="reconciliation.settings.sunday_fetch_mode" />
          </Typography>
          <CustomSelect
            {...register('sundayFetchMode')}
          >
            {Object.values(SundayFetchMode).map((fetchMode) => (
              <option
                key={fetchMode}
                value={fetchMode}
                defaultValue={fetchMode}
              >
                {intl.formatMessage({ id: sundayFetchModelLabels[fetchMode] })}
              </option>
            ))}
          </CustomSelect>
        </Line>
      </Settings>
    </Panel>
  );
};
