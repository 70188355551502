import styled from 'styled-components';
import React from 'react';
import { colorPalette, getSpacing } from '../../../stylesheet';
import Tag from '../../../common/components/Tag';

type BoxTagProps = {
  tag: string;
  removeTag: (tag: string) => void;
};

const StyledTag = styled(Tag)`
  margin-right: ${getSpacing(1)};
  background-color: ${colorPalette.cement};
  color: ${colorPalette.dust};
  margin: ${getSpacing(0.5)};
`;

const Close = styled.a`
  color: ${colorPalette.cement};
  background-color: ${colorPalette.dust};
  border-radius: ${getSpacing(3)};
  min-width: 18px;
  min-height: 18px;
  font-weight: bold;
  font-size: 14px;
  margin-left: ${getSpacing(1)};
  margin-right: ${getSpacing(-1.5)};
  text-align: center;
  &:after {
    content: 'x';
  }
`;

export const BoxTag = ({ tag, removeTag }: BoxTagProps) => (
  <StyledTag>
    {tag}
    <Close onClick={() => removeTag(tag)} />
  </StyledTag>
);
