import { Stack } from '@mui/material';
import { AccountNumber } from 'src/payments/components/Checkout/CheckoutOnboarding/BankAccount/AccountNumber';
import { SortCode } from 'src/payments/components/Checkout/CheckoutOnboarding/BankAccount/SortCode';
import React from 'react';

export const UkBankInfo = () => (
  <Stack direction="column" spacing={3}>
    <AccountNumber country="GB" />
    <SortCode />
  </Stack>
);
