const toDate = (yearMonth: string) => {
  const strings = yearMonth.split('-');
  const year = strings[0];
  const month = strings[1];
  return new Date(Date.UTC(Number(year), Number(month) - 1, 5, 0, 0, 0, 0));
};
const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const formatYearMonth = (yearMonth: string, locale: string) => capitalizeFirstLetter(toDate(yearMonth)
  .toLocaleDateString(locale, { year: 'numeric', month: 'long' }));
