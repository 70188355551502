import { Card, CardContent, styled, Typography } from '@mui/material';
import React from 'react';
import { breakpoints } from 'src/app/theme/ThemeV5';
import { Business } from 'src/business/domain/Business';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import RightArrowSvg from '../../../app/component/icons/RightArrowSvg';
import { ampli } from 'src/ampli';
import { dollars, euros, Money, pounds, useRenderMoney } from '@sundayapp/web-money';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { useAuthenticatedUserOrThrow } from 'src/auth/hooks/useAuthenticatedUserOrThrow';

type ReferralBlockProps = {
  business: Business;
};

declare global {
  interface Window {
    _trustlead: any;
  }
}

function getFormUrlDependingCountryCode(countryCode: string) {
  const configuration = ConfigurationLoader.load();
  const referralFormFRUrl = `${configuration.referralFormFRUrl}`;
  const referralFormUSUrl = `${configuration.referralFormUSUrl}`;
  const referralFormUKUrl = `${configuration.referralFormUKUrl}`;
  const referralFormDEUrl = `${configuration.referralFormDEUrl}`;
  switch (countryCode) {
    case 'US':
      return referralFormUSUrl;
    case 'GB':
      return referralFormUKUrl;
    case 'DE':
      return referralFormDEUrl;
    default :
      return referralFormFRUrl;
  }
}

const referralFormPath = (business: Business, user: AuthenticatedUser): string => {

  const url = getFormUrlDependingCountryCode(business.address.countryCode);
  return url.replace('{restaurant}', business.name ?? '')
    .replace('{email}', user.email ?? '')
    .replace('{username}', user.firstName ?? '' + ' ' + user.lastName ?? '');
};

const referralImage = (countryCode: string): string => {
  switch (countryCode) {
    case 'US':
      return '/img/home/referral_thousand_dollar.png';
    case 'GB':
      return '/img/home/referral_500_pound.png';
    default :
      return '/img/home/referral_500_euro.png';
  }
};

const referralAward = (countryCode: string): Money => {
  switch (countryCode) {
    case 'US':
      return dollars(1000);
    case 'GB':
      return pounds(500);
    default :
      return euros(500);
  }
};

type ReferralCardProps = {
  country: string;
};

const ReferralCard = styled(Card)<ReferralCardProps>`
  margin-top: 24px;
  background-color: #242424;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 100%;
  cursor: pointer;
  box-sizing: border-box;
  background-image: url(${(props) => referralImage(props.country)});
  background-position: right;
  background-size: auto 100%;
  background-repeat: no-repeat;
  @media (max-width: ${breakpoints.homeTablet}) {
    width: 100%;
    margin-top: 0;
  }
`;

const ImgSpacer = styled('div')`
  width: 224px;
`;

const FillForm = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 5px;
`;
export const ReferralBlock = ({ business }: ReferralBlockProps) => {
  const intl = useIntl();
  const renderMoney = useRenderMoney(true, true);
  const currentUser = useAuthenticatedUserOrThrow();


  const onClick = () => {
    ampli.referralCampaignClicked({
      businessId: business.id,
    });
    if (business.address.countryCode === 'FR') {
      const configuration = ConfigurationLoader.load();
      window._trustlead?.init({
        organisationId: configuration.referralTrustLeadId, //TODO use config
        id: currentUser.userId,
        email: currentUser.email,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        company: business.name,
        country: business.address.countryCode,
        activated: true,
      }).then(() => {
        window._trustlead?.show();
      });

    } else {
      window.open(referralFormPath(business, currentUser), '_blank');
    }
  };

  const message = intl.formatMessage({ id: 'venue.home.referral.description' }, { award: renderMoney(referralAward(business.address.countryCode)) });
  return (
    <ReferralCard onClick={onClick} country={business.address.countryCode}>
      <CardContent sx={{ justifyContent: 'space-between' }}>
        <Typography>{message}</Typography>
        <FillForm>
          <Typography variant="subtitle2"><FormattedMessage id="venue.home.referral.action" /></Typography>
          <RightArrowSvg size={18} color={'white'} />
        </FillForm>
      </CardContent>
      <ImgSpacer />
    </ReferralCard>
  );
};

