import { Grid } from '@mui/material';
import React from 'react';
import { DimensionCard } from './DimensionCard';
import { ampli } from 'src/ampli';
import { DimensionAnalysis } from '../domain/DimensionsAnalysis';
import { comparatorByDimension } from '../../browse/domain/Dimension';

interface Props {
  dimensions: DimensionAnalysis[];
  selectedDimensionState: [DimensionAnalysis | undefined, (dimension: DimensionAnalysis) => void];
}
export function AllDimensionCards({
  dimensions,
  selectedDimensionState: [selectedDimension, setSelectDimension],
}: Props) {
  return (
    <>
      {comparatorByDimension((da: DimensionAnalysis) => da.name)
        .sort(dimensions)
        .map((dimension) => (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} key={dimension.name}>
            <DimensionCard
              selected={selectedDimension?.name === dimension.name}
              onSelectedDimension={() => {
                ampli.dimensionSelected({ dimensionName: dimension.name });
                setSelectDimension(dimension);
              }}
              dimension={dimension}
            />
          </Grid>
        ))}
    </>
  );
}
