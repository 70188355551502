import { Box, Stack, Typography, useTheme } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import React from 'react';

export const AddRepresentative = ({ onAddRepresentative, buttonTitle, isDisabled = false }: {
  onAddRepresentative: () => void,
  buttonTitle: string,
  isDisabled?: boolean
}) => {
  const theme = useTheme();
  let onClick = !isDisabled ? onAddRepresentative : () => {
    /*empty*/
  };
  return <Stack spacing={1}>
    <Box
      padding={'24px 16px'}
      bgcolor={theme.palette.grey['100']}
      border={'1px dashed'}
      borderColor={theme.palette.grey['300']}
      borderRadius={'24px'}
      onClick={onClick}
    >
      <Stack onClick={onClick} direction={'row'} spacing={2} alignItems={'center'}>
        <PersonAddAltIcon fontSize={'large'} color={isDisabled ? 'disabled' : 'inherit'} />
        <Typography color={isDisabled ? theme.palette.grey[500] : theme.palette.secondary.main}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    variant={'body1'}>{buttonTitle}</Typography>
      </Stack>
    </Box>
  </Stack>;
};
