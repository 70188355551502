import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { venueMenuProductPath } from 'src/app/navigation/pathHelpers';
import { NoProductOverlay } from 'src/menu/dynamic-menu/pages/ProductPage/components/NoProductOverlay';
import React, { FC, useState } from 'react';
import { PaginatedProducts } from 'src/menu/dynamic-menu/domain/PaginatedProducts';
import { useNavigate } from 'react-router';
import { Product } from 'src/menu/dynamic-menu/domain/Product';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { MenuBusiness } from 'src/menu/common/domain/MenuBusiness';
import { useProductActions } from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useProductActions';
import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { ConfirmActionModal } from 'src/menu/dynamic-menu/pages/ProductsPage/components/ConfirmActionModal';
import { NoProductCard } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/NoProductCard';
import { buildProductsColumns } from 'src/menu/dynamic-menu/pages/ProductsPage/components/products/buildProductColums';
import { Business } from 'src/business/domain/Business';
import { useMenuAssetsSources } from 'src/menu/dynamic-menu/pages/MenusPage/DigitalMenusContext';
import { Pagination } from 'src/menu/dynamic-menu/pages/ProductsPage/hooks/useSearchProductsFilters';

export type ProductActionName = 'delete' | 'copy' | 'snooze' | 'unsnooze';

export type ProductAction = {
  product: Product;
  actionName: ProductActionName;
  targetName: Product['name'];
};

export const menuProductElementTypes = {
  PRODUCT: 'PRODUCT',
  MODIFIER: 'MODIFIER',
} as const;


export const menuElementTypes = {
  ...menuProductElementTypes,
  BLOCK: 'BLOCK',
  ALLERGEN: 'ALLERGEN',
} as const;

export type MenuElementType = ValuesOf<typeof menuElementTypes>;
export type MenuProductElementType = ValuesOf<typeof menuProductElementTypes>;

type Props = {
  business: Business,
  productFamily: MenuElementType,
  menuBusiness: MenuBusiness | undefined | null,
  paginatedProducts: PaginatedProducts,
  pagination: Pagination,
  filter: string | undefined,
  onPaginationChanged: (newPagination: { filter: string } & Pagination) => void,
  multiSelectedItems: Product[],
  onSelectAllClick: () => void,
  onSelectProduct: (row: Product, checked: boolean) => void,
  isProductSelected: (row: Product) => boolean
};

const getRowSelectionModel = (multiSelectedItems: Product[]) => multiSelectedItems.map((p) => p.id);

export const ProductsList: FC<Props> = ({
  business,
  productFamily,
  menuBusiness,
  paginatedProducts,
  onPaginationChanged,
  filter,
  pagination,
  multiSelectedItems,
  onSelectAllClick,
  onSelectProduct,
  isProductSelected,
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedProductAction, setSelectedProductAction] = useState<ProductAction | undefined>();
  const handleClose = () => setSelectedProductAction(undefined);
  const menuAssetsSource = useMenuAssetsSources();

  const onActionConfirmed = useProductActions(business.id, handleClose);

  const buildProductListParam = () => ({ state: { contextProductList: paginatedProducts?.products.map((p) => p.id) } });

  const filterIsEligibleForOrderColumn = (col: GridColDef) =>
    !menuBusiness?.orderingAllowed ? col.field !== 'isEligibleForOrder' : true;

  if (paginatedProducts.totalCount === 0 && !filter) {
    return <NoProductCard productFamily={productFamily} />;
  }

  const bulkUpdateSelection = {
    areAllSelected: multiSelectedItems.length === paginatedProducts.products.length,
    onSelectAllClick,
    onSelectProduct,
    isProductSelected,
  };

  const columns = buildProductsColumns(
    business.id,
    intl,
    setSelectedProductAction,
    menuAssetsSource,
    bulkUpdateSelection,
  ).filter(filterIsEligibleForOrderColumn);


  return (
    <>
      <DataGrid
        rows={paginatedProducts?.products || []}
        columns={columns}
        rowCount={paginatedProducts?.totalCount || 0}
        pageSizeOptions={[]}
        rowHeight={70}
        pagination
        disableAutosize
        disableColumnMenu
        paginationModel={pagination}
        paginationMode="server"
        onPaginationModelChange={(newPagination) => {
          onPaginationChanged({
            filter: filter || '',
            pageSize: newPagination.pageSize,
            page: newPagination.page,
          });
        }}
        disableRowSelectionOnClick
        autoHeight
        getRowId={(row) => row.id}
        onCellClick={(params) => {
          if (params.field === 'name') {
            return navigate(venueMenuProductPath(business.id, params.id.toString()), buildProductListParam());
          }
        }}
        slots={{
          noRowsOverlay: NoProductOverlay,
        }}
        rowSelectionModel={getRowSelectionModel(multiSelectedItems)}
        checkboxSelection
      />

      {selectedProductAction && (
        <ConfirmActionModal
          elementType="product"
          onActionConfirmed={onActionConfirmed}
          onClose={handleClose}
          action={selectedProductAction}
        />
      )}
    </>
  );
};
