import { MerchantClawbacks, MerchantClawbacksSchema } from 'src/accounting/clawbacks/domain/MerchantClawbacks';
import { BusinessId } from 'src/business/domain/Business';
import { AxiosStatic } from 'axios';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import { datadogLogs } from '@datadog/browser-logs';
import { DateRange } from 'src/accounting/revenues/domain/RevenuesDatePresets';
// eslint-disable-next-line no-restricted-imports
import { Moment } from 'moment-timezone';

export type ListMerchantClawbacksQueryParams = {
  from: string;
  to: string;
  page: number;
  size: number;
};

export type ClawbackReportFile = { fileName: string; data: ArrayBuffer };
type ClawbackReportGenerationError = { error: unknown };
export type ClawbackReportResult = ClawbackReportFile | ClawbackReportGenerationError;
export const isClawbackReportFile = (result: ClawbackReportResult): result is ClawbackReportFile => 'fileName' in result && 'data' in result;

export class ClawbackRepository {
  private bookkeepingApiBaseUrl: string;

  constructor(private httpClient: AxiosStatic) {
    const configuration = ConfigurationLoader.load();
    this.bookkeepingApiBaseUrl = configuration.bookkeepingApiBaseUrl;
  }

  async list(businessId: BusinessId, params: ListMerchantClawbacksQueryParams): Promise<MerchantClawbacks> {
    try {
      const result = await this.httpClient.get<MerchantClawbacks>(
        `${this.bookkeepingApiBaseUrl}/businesses/${businessId}/clawbacks`,
        {
          headers: {
            contentType: 'application/json',
          },
          params,
        },
      );

      return MerchantClawbacksSchema.parse(result.data);
    } catch (e) {
      datadogLogs.logger.error('Error fetching clawbacks', { error: e });
      return { totalCharged: null, clawbacks: { items: [], totalCount: 0 } };
    }
  }

  async downloadReport(businessId: BusinessId, dateRange: DateRange): Promise<ClawbackReportResult> {
    try {
      const result = await this.httpClient.get<ArrayBuffer>(
        `${this.bookkeepingApiBaseUrl}/businesses/${businessId}/clawbacks-report`,
        {
          headers: {
            contentType: 'application/json',
          },
          responseType: 'arraybuffer',
          params: {
            from: dateRange.startDate.utc().format(),
            to: dateRange.endDate.utc().format(),
          },
        },
      );
     
      const frenchFormat = (date: Moment) => date.format('DD_MM_YYYY');
      const fileName = `sunday_rapport_prelevements_${frenchFormat(dateRange.startDate)}-${frenchFormat(dateRange.endDate)}.pdf`;

      return {
        fileName,
        data: result.data,
      };
    } catch (e) {
      return { error: e };
    }
  }
}
