import React from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Link, Stack, Typography, useTheme } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ampli } from 'src/ampli';

type Props = {
  englishReviewHintUrl: string;
  frenchReviewHintUrl: string;
};

export const ReviewHint = ({ englishReviewHintUrl, frenchReviewHintUrl }: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  return (
    <Link
      href={intl.locale.startsWith('fr') ? frenchReviewHintUrl : englishReviewHintUrl}
      target="_blank"
      onClick={() => {
        ampli.reviewsHintClicked();
      }}
      rel="noreferrer"
      underline="none"
    >
      <Stack direction="row" alignItems="center">
        <InfoIcon sx={{ color: theme.palette.text.secondary }} />
        <Typography color={theme.palette.text.secondary} marginLeft="0.5rem">
          <FormattedMessage id="review.hint" defaultMessage="Reviews: How does it work?" />
        </Typography>
      </Stack>
    </Link>
  );
};
