import * as React from 'react';
import { FC } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { NO_REVENUE_CENTER } from '../model/RevenueCenter';

type RevenueCentersMultipleSelectProps = {
  revenueCentersOptions: string[],
  values: string[],
  onChange: (values: string[]) => void,
};
export const RevenueCentersMultipleSelect: FC<RevenueCentersMultipleSelectProps> = ({
  revenueCentersOptions,
  values,
  onChange,
}) => {
  const intl = useIntl();
  const handleChange = (event: SelectChangeEvent<typeof values>) => {
    const {
      target: { value: eventValue },
    } = event;
    onChange(
      typeof eventValue === 'string' ? eventValue.split(',') : eventValue,
    );
  };

  const displayValue = (key: string): string => {
    if (key === NO_REVENUE_CENTER) return intl.formatMessage({ id: 'sales_summary.revenue_centers.no_revenue_center' });
    return key;
  };

  function renderValue(selected: string[]) {
    if (selected.length === revenueCentersOptions.length) {
      return intl.formatMessage({
        id: 'sales_summary.revenue_centers.all_selected',
        defaultMessage: 'All',
      });
    }

    return `${selected.length} ${intl.formatMessage({
      id: 'sales_summary.revenue_centers.selected',
      defaultMessage: 'selected',
    })}`;
  }

  return <FormControl sx={{ minWidth: '13rem' }}>
    <InputLabel id="rvc-multiple-checkbox-label">
      <FormattedMessage id='sales_summary.revenue_centers.label' defaultMessage='Revenue Centers'/>
    </InputLabel>
    <Select
      labelId="rvc-multiple-checkbox-label"
      id="rvc-multiple-checkbox"
      multiple
      sx={{ minHeight: '58px' }}
      value={values}
      onChange={handleChange}
      input={<OutlinedInput label={intl.formatMessage({ id: 'sales_summary.revenue_centers.label', defaultMessage: 'Revenue Centers' })}/>}
      renderValue={(selected) => renderValue(selected)}
      disabled={revenueCentersOptions.length === 0}
    >
      {revenueCentersOptions.map((revenueCenterOption) => (
        <MenuItem key={revenueCenterOption} value={revenueCenterOption} sx={{ minWidth: '13rem' }}>
          <Checkbox checked={values.indexOf(revenueCenterOption) > -1} />
          <ListItemText primary={displayValue(revenueCenterOption)} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>;
};
