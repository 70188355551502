import { ValuesOf } from 'src/utils/typescript/valuesOf';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { PaymentLink, PaymentLinkStatus, paymentLinkStatuses } from '../domain/PaymentLink';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { SearchIcon } from 'src/ordering/common/components/Icons';
import { MenuProps } from 'src/sundayplus/reviews/reply-template/components/ApplicableRatingsSelector.style';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { money, MoneyView } from '@sundayapp/web-money';
import { useCurrentUser } from 'src/auth/component/UserProvider';
import { isAuthorized } from 'src/domain/user/Role';
import { userRoles } from 'src/domain/user/UserRole';
import { AuthenticatedUser } from 'src/auth/domain/user';
import { TableEmptyState } from 'src/components/table/TableEmptyState';
import { translation } from 'src/accounting/common/translation';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { useNavigate } from 'react-router';
import {
  displayableStatusTranslationKey,
  paymentLinkDisplayableStatus,
  paymentLinkStatusesToDisplayableStatus,
} from '../domain/PaymentLinkStatus';
import { ChipPaymentLinkStatus } from './components/ChipPaymentLinkStatus';
import { EventDateTableCell } from './components/DateDisplays';

const TooltipWrapper: FC<{ content: string | undefined } & PropsWithChildren> = ({ content, children }) =>
  !!content ? <Tooltip title={content}><span>{children}</span>
  </Tooltip> : <>{children}</>;


type PaymentLinkStatusFilter = ValuesOf<typeof paymentLinkDisplayableStatus>;

type PaymentLinkTableFilters = {
  search: string,
  statuses: string[];
};

const formatDate = (date: Date, timeZone: string) => new Date(date).toLocaleDateString(navigator.language, {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone,
});

const statusOrdering: { [key in PaymentLinkStatus]: number } = {
  [paymentLinkStatuses.DROPPED]: 0,
  [paymentLinkStatuses.CREATED]: 1,
  [paymentLinkStatuses.IN_PROGRESS]: 2,
  [paymentLinkStatuses.READY]: 3,
  [paymentLinkStatuses.DONE]: 4,
} as const;

function sortPaymentLinks(orderBy: { id: string; direction: 'asc' | 'desc' }) {
  return (a: PaymentLink, b: PaymentLink) => {
    const isAsc = orderBy.direction === 'asc';
    const orderCoef = isAsc ? 1 : -1;
    switch (orderBy.id) {
      case 'created_at':
        return orderCoef * (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      case 'title':
        return orderCoef * (a.qrCode.title.localeCompare(b.qrCode.title));
      case 'description':
        return orderCoef * (a.qrCode.description.localeCompare(b.qrCode.description));
      case 'amount':
        return orderCoef * (a.qrCode.amountCents - b.qrCode.amountCents);
      case 'status':
        return orderCoef * (statusOrdering[a.status] - statusOrdering[b.status]);
      case 'event_date':
        return orderCoef * ((!!a.qrCode.eventDate ? new Date(a.qrCode.eventDate).getTime() : 0) - (!!b.qrCode.eventDate ? new Date(b.qrCode.eventDate).getTime() : 0));
      default:
        return 0;
    }
  };
}


const columns = [
  { id: 'created_at', numeric: true, visible: false },
  { id: 'event_date', numeric: true, visible: true },
  { id: 'title', numeric: false, visible: true },
  { id: 'description', numeric: false, visible: true },
  { id: 'amount', numeric: true, visible: true },
  { id: 'status', numeric: false, visible: true },
] as const;
type Props = {
  allPaymentLinks: PaymentLink[]
  openCreateLinkModal: () => void
};
export const PaymentLinksTable: FC<Props> = ({
  allPaymentLinks,
  openCreateLinkModal,
}) => {
  const navigate = useNavigate();
  const user = useCurrentUser() as AuthenticatedUser;
  const intl = useIntl();

  const [filter, setFilters] = useState<PaymentLinkTableFilters>({
    search: '',
    statuses: [paymentLinkDisplayableStatus.PAID, paymentLinkDisplayableStatus.UNPAID],
  });
  const [orderBy, setOrderBy] = useState<{ id: string, direction: 'asc' | 'desc' }>({
    id: 'created_at',
    direction: 'desc',
  });

  const business = useCurrentBusinessOrThrow();

  const isCurrentUserAdmin = isAuthorized(user.claims, [userRoles.ADMIN, userRoles.GENERAL_MANAGER, userRoles.MANAGER], business.id);
  const createLinkButtonTooltipContent = isCurrentUserAdmin ? undefined : intl.formatMessage({ id: 'payment_link.table.create_link_button.tooltip' });


  const pageFilters = useCallback((paymentLink: PaymentLink) => {
    return [
      paymentLink.qrCode.title,
      paymentLink.qrCode.description,
      paymentLink.qrCode.amountCents.toString(),
      formatDate(paymentLink.createdAt, business.timezone),
      formatDate(paymentLink.qrCode.eventDate || paymentLink.createdAt, business.timezone),
    ].some((value) => {
      const searchPredicate = value.toUpperCase().includes(filter.search.toUpperCase());
      const statusPredicate = filter.statuses.includes(paymentLinkStatusesToDisplayableStatus[paymentLink.status]);
      return searchPredicate && statusPredicate;
    });
  }, [filter]);

  const rows = useMemo(() => (allPaymentLinks || []).filter(pageFilters).sort(sortPaymentLinks(orderBy)), [pageFilters, allPaymentLinks, orderBy]);

  const onStatusFilterChanged = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value;

    setFilters((filters) => ({
      ...filters,
      statuses: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  return <>
    <Box display="flex" justifyContent="space-between" paddingBottom="16px">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} display="flex" flexDirection="column">
          <FormControl size="small">
            <TextField
              label={intl.formatMessage({ id: 'payment_link.table.filters.search' })}
              value={filter.search}
              size="small"
              onChange={(event) => setFilters((filters) => ({ ...filters, search: event.target.value }))}
              InputProps={{
                endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} display="flex" flexDirection="column">
          <FormControl size="small">
            <InputLabel id="status-filter">
              <FormattedMessage id="payment_link.table.filters.status" />
            </InputLabel>
            <Select
              labelId="status-filter"
              label={
                <FormattedMessage id="payment_link.table.filters.status" />
              }
              value={filter.statuses}
              onChange={onStatusFilterChanged}
              input={<OutlinedInput label={
                <FormattedMessage id="payment_link.table.filters.status" />
              } />}
              MenuProps={MenuProps}
              multiple
              renderValue={(selected) => {
                if (selected.length === Object.values(paymentLinkDisplayableStatus).length) {
                  return <FormattedMessage id="payment_link.table.status.ALL" />;
                }
                return selected
                  .map((status) => intl.formatMessage({ id: displayableStatusTranslationKey(status as PaymentLinkStatusFilter) }))
                  .join(', ')
                ;
              }}
            >
              {Object.values(paymentLinkDisplayableStatus).map((status: PaymentLinkStatusFilter) => (
                <MenuItem
                  sx={{ padding: '8px 16px' }}
                  key={status}
                  value={status}
                >
                  <Checkbox checked={filter.statuses.indexOf(status) > -1} />
                  <FormattedMessage id={displayableStatusTranslationKey(status)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item display="flex" alignItems="center" justifyContent="right" xs={12} md={6}>
          <TooltipWrapper content={createLinkButtonTooltipContent}>
            <Button size="small" variant="contained" onClick={openCreateLinkModal} disabled={!isCurrentUserAdmin}>
              <Box display="flex" alignItems="center" justifyContent="center" gap="8px">
                <AddCircleOutlineOutlined />
                <Typography variant="subtitle1" alignItems="center">
                  <FormattedMessage id="payment_link.landing.cta.create_link" />
                </Typography>
              </Box>
            </Button>
          </TooltipWrapper>
        </Grid>
      </Grid>
    </Box>

    {rows.length === 0
      ? <TableEmptyState
        title={translation('payment_link.table.empty_state.title')}
        subtitle={translation('payment_link.table.empty_state.subtitle')}
      />
      : <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.filter(c => c.visible).map((column) =>
                <TableCell key={column.id}>
                  <TableSortLabel
                    active={orderBy.id === column.id}
                    direction={orderBy.id === column.id ? orderBy.direction : 'asc'}
                    onClick={() => setOrderBy({
                      id: column.id,
                      direction: orderBy.direction === 'asc' ? 'desc' : 'asc',
                    })}
                  >
                    <FormattedMessage id={`payment_link.table.headers.${column.id}`} />

                    {orderBy.id === column.id ? (
                      <Box component="span" sx={{ display: 'none' }}>
                        {orderBy.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>,
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((paymentLink) => (
              <TableRow
                hover
                onClick={() => navigate(`./${paymentLink.sessionId}`, { state: { paymentLink } })}
                key={paymentLink.sessionId}
                sx={{ cursor: 'pointer' }}
              >
                <TableCell><EventDateTableCell paymentLink={paymentLink} /></TableCell>
                <TableCell>{paymentLink.qrCode.title}</TableCell>
                <TableCell>
                  <p style={{
                    maxWidth: '200px',
                    maxHeight: '81px',
                    overflow: 'overlay',
                  }}>{paymentLink.qrCode.description}</p>
                </TableCell>
                <TableCell>
                  <MoneyView value={money(paymentLink.qrCode.amountCents * 1000, paymentLink.qrCode.currency)} />
                </TableCell>
                <TableCell>
                  <ChipPaymentLinkStatus paymentLink={paymentLink} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}
  </>;
};
