/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const AccountingPlainSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.primary.main, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.31406 1.16699H12.6911C13.3727 1.16699 13.9147 1.16699 14.352 1.20271C14.7998 1.2393 15.1816 1.31583 15.5312 1.49397C16.0957 1.78159 16.5547 2.24053 16.8423 2.80502C17.0204 3.15464 17.097 3.53648 17.1335 3.98428C17.1693 4.42156 17.1693 4.9636 17.1693 5.6451V11.4404C16.6866 11.5091 16.2214 11.7291 15.8501 12.1004L13.75 14.2005L13.7332 14.1837C12.822 13.2725 11.3446 13.2725 10.4334 14.1837C9.52219 15.095 9.52219 16.5723 10.4334 17.4836L11.7835 18.8337H7.31391C6.63248 18.8337 6.09048 18.8337 5.65323 18.7979C5.20543 18.7614 4.82359 18.6848 4.47397 18.5067C3.90948 18.2191 3.45054 17.7601 3.16292 17.1956C2.98478 16.846 2.90825 16.4642 2.87166 16.0164C2.83593 15.5791 2.83593 15.0371 2.83594 14.3555V5.6451C2.83593 4.96358 2.83593 4.42157 2.87166 3.98428C2.90825 3.53648 2.98478 3.15464 3.16292 2.80502C3.45054 2.24054 3.90948 1.78159 4.47397 1.49397C4.82359 1.31583 5.20543 1.2393 5.65323 1.20271C6.09052 1.16699 6.63254 1.16699 7.31406 1.16699ZM5.83594 5.83431C5.83594 5.37407 6.20903 5.00098 6.66927 5.00098H13.3359C13.7962 5.00098 14.1693 5.37407 14.1693 5.83431C14.1693 6.29455 13.7962 6.66764 13.3359 6.66764H6.66927C6.20903 6.66764 5.83594 6.29455 5.83594 5.83431ZM5.83594 9.16764C5.83594 8.70741 6.20903 8.33431 6.66927 8.33431H11.6693C12.1295 8.33431 12.5026 8.70741 12.5026 9.16764C12.5026 9.62788 12.1295 10.001 11.6693 10.001H6.66927C6.20903 10.001 5.83594 9.62788 5.83594 9.16764ZM5.83594 12.501C5.83594 12.0407 6.20903 11.6676 6.66927 11.6676H8.33594C8.79618 11.6676 9.16927 12.0407 9.16927 12.501C9.16927 12.9612 8.79618 13.3343 8.33594 13.3343H6.66927C6.20903 13.3343 5.83594 12.9612 5.83594 12.501Z" fill={color} />
    <path d="M18.0893 14.3396C18.4147 14.0141 18.4147 13.4865 18.0893 13.1611C17.7638 12.8356 17.2362 12.8356 16.9107 13.1611L13.75 16.3218L12.6726 15.2444C12.3472 14.919 11.8195 14.919 11.4941 15.2444C11.1686 15.5698 11.1686 16.0975 11.4941 16.4229L13.1607 18.0896C13.317 18.2459 13.529 18.3337 13.75 18.3337C13.971 18.3337 14.183 18.2459 14.3393 18.0896L18.0893 14.3396Z" fill={color} />
  </svg>
);

export default AccountingPlainSvg;
