import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type AreaDetailsDeletionWarningModalProps = {
  isOpen: boolean;
  areaName: string;
  onCancel: () => void;
  onConfirm: () => void;
};

const MessageContainer = styled.div`
  color: ${colorPalette.placeholderColor};
  margin: 0 0 ${getSpacing(3)};
`;

export const AreaDetailsDeletionWarningModal = ({
  isOpen,
  areaName,
  onCancel,
  onConfirm,
}: AreaDetailsDeletionWarningModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      maxWidth="407px"
      isOpened={isOpen}
      onClose={onCancel}
      title={intl.formatMessage(
        {
          id: 'settings.area.details.delete-modal.title',
          defaultMessage: 'delete area {areaName}',
          description: 'modal title to confirm the user want to delete an area',
        },
        { areaName },
      )}
      cancelButtonMessage={intl.formatMessage({ id: 'menus.modal.edition.cancel' })}
    >
      <MessageContainer>
        <FormattedMessage
          id="settings.area.details.delete-modal.description"
          defaultMessage="deleting the area {areaName} cannot be undone, although you can always create a new area from the area view."
          description="text to reminder the services are mandatory to make an area works"
          values={{ areaName }}
        />
      </MessageContainer>
      <Button variant="warning" size="medium" fullWidth onClick={onConfirm}>
        <FormattedMessage
          id="settings.area.details.delete-modal.confirm"
          defaultMessage="yes, i want to delete this area"
        />
      </Button>
    </Modal>
  );
};
