declare module '@mui/material' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    homeTablet: true;
  }
}


export const BreakpointsTheme = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 480,
    tablet: 1024,
    homeTablet: 1080,
  },
};
  
