import { Money } from '@sundayapp/web-money';
import { GroupOfInvoices, InvoicesPerYearMonth, invoiceYear } from 'src/accounting/invoices/domain/Invoice';

const merge = (one: Money, two: Money): Money => {
  if (one.currency !== two.currency) {
    throw new Error('Cannot merge money with different currencies');
  }
  return {
    amount: one.amount + two.amount,
    currency: one.currency,
  };
};

export const mergeInvoices = (one: InvoicesPerYearMonth[], two: InvoicesPerYearMonth[]): InvoicesPerYearMonth[] => {
  return one.map((item) => {
    const twoItem = two.find((elem) => elem.yearMonth === item.yearMonth);
    if (twoItem) {
      return {
        ...item,
        summary: {
          totalAmount: merge(item.summary.totalAmount, twoItem.summary.totalAmount),
          taxAmount: merge(item.summary.taxAmount, twoItem.summary.taxAmount),
          numberOfInvoices: item.summary.numberOfInvoices + twoItem.summary.numberOfInvoices,
        },
        invoices: item.invoices.concat(twoItem.invoices),
      };
    }
    return item;
  });
};

export interface InvoiceFilters {
  year: string | undefined;
  showCatering: boolean;
}

export const defaultInvoiceFilters: InvoiceFilters = {
  year: undefined,
  showCatering: false,
};
export const applyInvoiceFilters = (invoices: GroupOfInvoices, filters: InvoiceFilters): InvoicesPerYearMonth[] => {
  let filteredByYear = invoices.invoicesPerYearMonth;
  if (filters.year) {
    filteredByYear = invoices.invoicesPerYearMonth.filter((item) => invoiceYear(item) === filters.year);
  }

  return filters.showCatering
    ? mergeInvoices(filteredByYear, invoices.cateringInvoicesPerYearMonth)
    : filteredByYear;
};
