import React from 'react';
import { Typography } from '@mui/material';
import {  AuthenticationPage, AuthenticationPart, IllustrationPart } from '../component/Authentication.styles';

export const CheckoutLanding = () => {
  return (
    <AuthenticationPage>
      <IllustrationPart />
      <AuthenticationPart>
        <div>
          <div>
            <Typography fontSize={'x-large'}>You should receive an email shortly from our partner Checkout with a link to start the onboarding.</Typography>
          </div>
        </div>
      </AuthenticationPart>
    </AuthenticationPage>
  );
};