import { datadogLogs } from '@datadog/browser-logs';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { useNavigate } from 'react-router';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import Authentication from '../../component/Authentication';
import { SignUpFormBody } from './CompleteSignUp.styles';
import { HttpUserRepository } from 'src/infrastructure/user/HttpUserRepository';
import { CompleteSignUpUseCase } from 'src/usecase/signup/CompleteSignUpUseCase';
import { Alert, Button, Stack, TextField, Typography } from '@mui/material';
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input';
import { parsePhoneNumber } from 'libphonenumber-js';

type CompleteSignUpInputs = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

const CompleteSignUp = () => {
  const configuration = ConfigurationLoader.load();
  const intl = useIntl();
  const userRepository = new HttpUserRepository(configuration.merchantAccountsBaseUrl);
  const navigate = useNavigate();
  const signUpUseCase = new CompleteSignUpUseCase(userRepository, navigate);
  const [signUpError, setSignUpError] = useState<string>('');

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<CompleteSignUpInputs>({
    mode: 'all',
    criteriaMode: 'all',
  });

  const onSubmit: SubmitHandler<CompleteSignUpInputs> = async ({ firstName, lastName, phoneNumber }) => {
    setSignUpError('');
    try {
      await signUpUseCase.handle('', lastName, firstName, parsePhoneNumber(phoneNumber).number);
    } catch (error: any) {
      datadogLogs.logger.error('An error occurred while trying to sign in a venue owner', { error });
      setSignUpError(intl.formatMessage({ id: 'signin_callback.errors.other' }));
    }
  };

  return (
    <Authentication>
      <Stack spacing={2}>
        <Stack direction={'row'} justifyContent={'center'}>
          <Typography>
            <FormattedMessage id="signup.title" />
          </Typography>
        </Stack>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack alignItems={'center'} spacing={2}>
            <SignUpFormBody>
              <Stack spacing={2}>
                <Typography fontSize={'x-large'}>
                  <FormattedMessage id="signup.form.title" />
                </Typography>
                <TextField
                  variant={'standard'}
                  fullWidth
                  label={intl.formatMessage({ id: 'signup.form.firstname' })}
                  placeholder={intl.formatMessage({ id: 'signup.form.firstname.placeholder' })}
                  id="firstName"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  {...register('firstName', {
                    required: intl.formatMessage({ id: 'signup.form.firstname.required' }),
                    pattern: {
                      value: /.*\S.*/,
                      message: intl.formatMessage({ id: 'signup.form.firstname.required' }),
                    },
                  })}
                />
                <TextField
                  variant={'standard'}
                  fullWidth
                  label={intl.formatMessage({ id: 'signup.form.lastname' })}
                  placeholder={intl.formatMessage({ id: 'signup.form.lastname.placeholder' })}
                  id="lastName"
                  error={!!errors.lastName}
                  helperText={errors.lastName?.message}
                  {...register('lastName', {
                    required: intl.formatMessage({ id: 'signup.form.lastname.required' }),
                    pattern: {
                      value: /.*\S.*/,
                      message: intl.formatMessage({ id: 'signup.form.lastname.required' }),
                    },
                  })}
                />
                <Controller
                  control={control}
                  name="phoneNumber"
                  rules={{
                    validate: (value) => {
                      if (!value) return false;
                      return matchIsValidTel(value);
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <MuiTelInput
                      placeholder={intl.formatMessage({ id: 'signup.form.phone.placeholder' })}
                      fullWidth
                      defaultCountry={'FR'}
                      variant={'standard'}
                      {...field}
                      label={intl.formatMessage({ id: 'signup.form.phone' })}
                      helperText={fieldState.invalid ? intl.formatMessage({ id: 'signup.form.phone.required' }) : ''}
                      error={fieldState.invalid}
                    />
                  )}
                />
              </Stack>
            </SignUpFormBody>
            {signUpError && <Alert severity="error">{signUpError}</Alert>}
            <Button type="submit" variant={'contained'} size={'large'} disabled={!isValid || isSubmitting}>
              <FormattedMessage id="signup.form.submit" />
            </Button>
          </Stack>
        </form>
      </Stack>
    </Authentication>
  );
};

export default CompleteSignUp;
