import { ValuesOf } from 'src/utils/typescript/valuesOf';
import { Enrollment } from 'src/business/domain/Enrollment';
import { ServiceType } from 'src/domain/venue/ServiceType';

export const paymentProductOrigins = {
  OP: 'op',
  PAT: 'pat',
  TPE: 'tpe',
  PAYMENT_LINK: 'payment_link',
  SMB: 'smb',
  UNKNOWN: 'UNKNOWN',
} as const;

export type PaymentProductOrigin = ValuesOf<typeof paymentProductOrigins>;

export const paymentProductOriginFromEnrollment = (enrollment: Enrollment) => {
  switch (enrollment.product) {
    case ServiceType.ORDER_AND_PAY:
      return paymentProductOrigins.OP;
    case ServiceType.PAY_AT_TABLE:
      return paymentProductOrigins.PAT;
    case ServiceType.PAYMENT_TERMINAL:
      return paymentProductOrigins.TPE;
    case ServiceType.SMB_PAY:
      return paymentProductOrigins.SMB;
    default:
      return paymentProductOrigins.UNKNOWN;
  }
};
