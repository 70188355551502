import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

import { FOUR_WEEKS, KpiTimeframeName, SEVEN_DAYS, TWELVE_MONTHS } from 'src/sundayplus/reviews/insights/domain/KpiTimeframe';

type Props = {
  timeframeKpiType: KpiTimeframeName;
  onChange: (newTimeframeKpiType: KpiTimeframeName) => void;
};

export const TimeframeChoice = ({
  timeframeKpiType,
  onChange,
}: Props) => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
  }}>
    <ToggleButtonGroup
      value={timeframeKpiType}
      exclusive
      onChange={(event: React.MouseEvent<HTMLElement>, newTimeframe: KpiTimeframeName) => {
        const toggleIsClickedTwice = newTimeframe === null;
        onChange(toggleIsClickedTwice ? timeframeKpiType : newTimeframe);
      }}
    >
      <ToggleButton value={SEVEN_DAYS.name()}><FormattedMessage id="reviews.ereputation.timeframe.last_seven_days" /></ToggleButton>
      <ToggleButton value={FOUR_WEEKS.name()}><FormattedMessage id="reviews.ereputation.timeframe.last_four_weeks" /></ToggleButton>
      <ToggleButton value={TWELVE_MONTHS.name()}><FormattedMessage id="reviews.ereputation.timeframe.last_twelve_months" /></ToggleButton>
    </ToggleButtonGroup>
  </div>
);
