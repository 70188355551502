import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

export const EditOutlinedSvg: React.FC<Props> = ({ size = 20, color = '#70707B' }: Props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
        <path 
            fillRule="evenodd" 
            clipRule="evenodd" 
            d="M13.1622 2.32727C14.1779 1.31148 15.8249 1.31148 16.8407 2.32727C17.8565 3.34307 17.8565 4.98999 16.8407 6.00578L6.37171 
            16.4747C6.35521 16.4912 6.33886 16.5076 6.32265 16.5239C6.08311 16.7638 5.87191 16.9754 5.6173 17.1315C5.39362 17.2685 5.14976 
            17.3695 4.89467 17.4308C4.6043 17.5005 4.30534 17.5002 3.96627 17.4999C3.94331 17.4999 3.92017 17.4999 3.89684 17.4999H2.50139C2.04115 17.4999 
            1.66805 17.1268 1.66805 16.6665V15.2711C1.66805 15.2478 1.66803 15.2246 1.66801 15.2017C1.6677 14.8626 1.66742 14.5636 1.73713 14.2733C1.79837 14.0182 
            1.89938 13.7743 2.03645 13.5506C2.19248 13.296 2.40407 13.0848 2.64406 12.8453C2.66031 12.8291 2.67668 12.8127 2.69318 12.7962L13.1622 2.32727ZM15.6622 
            3.50578C15.2972 3.14087 14.7056 3.14087 14.3407 3.50578L3.87169 13.9747C3.55539 14.291 3.49707 14.3569 3.45752 14.4215C3.41183 14.496 3.37816 14.5773 3.35775 
            14.6623C3.34008 14.7359 3.33472 14.8238 3.33472 15.2711V15.8332H3.89684C4.34416 15.8332 4.43199 15.8278 4.50559 15.8102C4.59062 15.7898 4.67191 15.7561 4.74647 
            15.7104C4.811 15.6708 4.8769 15.6125 5.1932 15.2962L15.6622 4.82727C16.0271 4.46235 16.0271 3.8707 15.6622 3.50578ZM9.16803 16.6665C9.16803 16.2063 9.54113 15.8332 
            10.0014 15.8332H17.5014C17.9616 15.8332 18.3347 16.2063 18.3347 16.6665C18.3347 17.1268 17.9616 17.4999 17.5014 17.4999H10.0014C9.54113 17.4999 9.16803 17.1268 9.16803 16.6665Z" 
            fill={color}
        />
    </svg>
);