import { Stack, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

export const StatCard = ({ stat, description }: { stat: string, description: ReactNode }) => (
  <Stack direction="column" gap="1rem">
    <Typography variant="subtitle1" color="textSecondary">
      {description}
    </Typography>
    <Stack direction="column" gap="0.5rem">
      <Typography variant="h5" gutterBottom>
        {stat}
      </Typography>
    </Stack>
  </Stack>
);
