import { BlobWithMetadata } from 'src/accounting/payout/domain/BlobWithMetadata';
import { downloadBlob } from 'src/accounting/payout/pages/utils';
import { useLog } from 'src/utils/logs/LogProviderContext';
import { Level } from 'src/utils/logs/plugins';
import { useSnackbar } from 'src/app/contexts/snackbars/SnackBarContext';

export const prepareDownload =
  <TArgs extends unknown>({
    onDownloadStarted,
    fetchBlobWithMetadata,
    onDownloadFinished,
    onError,
    filename,
  }: {
    onDownloadStarted: () => void;
    fetchBlobWithMetadata: (args: any) => Promise<BlobWithMetadata | Blob>;
    onDownloadFinished: () => void;
    onError: (args: TArgs, filename: string, error: Error) => void;
    filename: string;
  }) =>
    (args: TArgs) => {
      onDownloadStarted();
      fetchBlobWithMetadata(args)
        .then((blobWithMetadata) => {
          downloadBlob(blobWithMetadata, filename);
        })
        .catch((error) => {
          onError(args, filename, error);
        })
        .finally(() => onDownloadFinished());
    };

export const usePrepareLogAndNotifyError = (notificationMessage: string) => {
  const log = useLog();
  const snackbar = useSnackbar();
  return <TArgs>(args: TArgs, filename: string, error: Error) => {
    log(Level.ERROR, `failed to download file ${filename} out of args ${args}`, error);
    snackbar.addNotification({
      variant: 'error',
      text: notificationMessage,
    });
  };
};
