import styled from 'styled-components';

export const FoodCourtMapContainer = styled.figure`
  margin: 0;
  padding: 0;
`;

export const FoodCourtMapImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
`;

export const FoodCourtImagePlaceHolder = styled.div``;
