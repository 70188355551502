import React from 'react';
import SplitSettings from './SplitSettings';
import { useUpdateGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useUpdateGlobalConfiguration';
import { useGlobalConfiguration } from 'src/venueconfiguration/infrastructure/useGlobalConfiguration';
import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { ChargeDisplayCustomization } from 'src/ops-tooling/PaT/ChargeDisplayCustomization';

const InternalSettingsPaT = () => {
  const intl = useIntl();
  const { updateGlobalConfiguration } = useUpdateGlobalConfiguration();
  const { data: globalConfiguration } = useGlobalConfiguration();
  const { patEnrollment, address } = useCurrentBusinessOrThrow();

  return (
    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
      <Card>
        <CardHeader title={intl.formatMessage({ id: 'settings.internal_settings_pat.split_settings.title' })} />
        <CardContent>
          <SplitSettings
            globalConfiguration={globalConfiguration}
            updateGlobalConfiguration={updateGlobalConfiguration}
          />
        </CardContent>
      </Card>
      {address.countryCode === 'US' && patEnrollment?.id && (
        <Card>
          <CardHeader title={intl.formatMessage({ id: 'settings.internal_settings_pat.charges_display.title' })} />
          <CardContent>
            <ChargeDisplayCustomization enrollmentId={patEnrollment.id} />
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default InternalSettingsPaT;
