import React from 'react';
import { withRestrictedAccess } from 'src/app/component/permission/withRestrictedAccess';
import { PSPPanel } from './PSPPanel';
import { TaxInformationPanel } from './TaxInformationPanel';
import { userRoles } from 'src/domain/user/UserRole';
import { NoPos } from '../../NoPos/NoPos';
import { useBusinessIsNoPos } from 'src/business/hooks/useBusinessIsNoPos';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';
import { SettingsPageContainer } from '../components/SettingsPageContainer';
import { ReceiptDisplaySettingsPanel } from './ReceiptDisplaySettingsPanel';
import ChargebeePanel from './ChargebeePanel';
import { shouldCheckChargebeePaymentMethodStatus } from 'src/payments/domain/Chargebee';
import { isPDQOnly } from 'src/business/domain/Business';

function PaymentsSettings() {
  const business = useCurrentBusinessOrThrow();
  const venueIsNoPos = useBusinessIsNoPos();
  const useChargebeeFeature = shouldCheckChargebeePaymentMethodStatus(business);
  const enrollment = (business.enrollments.length > 0 && !isPDQOnly(business)) ? business.enrollments[0] : undefined;

  if (venueIsNoPos) {
    return <NoPos />;
  }

  return (
    <SettingsPageContainer>
      {enrollment && (
        <PSPPanel key={enrollment.id} businessId={business.id} enrollmentId={enrollment.id} business={business} />
      )}
      {business.address.countryCode !== 'US' && <TaxInformationPanel business={business} />}
      {useChargebeeFeature && <ChargebeePanel business={business} />}
      <ReceiptDisplaySettingsPanel business={business} />
    </SettingsPageContainer>
  );
}

export default withRestrictedAccess([userRoles.ADMIN], PaymentsSettings);
