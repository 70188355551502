import React from 'react';
import { Link } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { EMPTY, Review } from '../../domain/Review';
import { useGetTicketLink } from 'src/bills/pages/getTicketLink';
import { ampli } from 'src/ampli';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export function ReviewBill({ review, fontSize = '16px' }: { review: Review; fontSize?: string }) {
  const business = useCurrentBusinessOrThrow();
  const getTicketLink = useGetTicketLink();

  if (!business || review.orderId === EMPTY || business.patEnrollment === undefined) return <></>;

  return (
    <>
      <Link
        fontSize={fontSize}
        href={getTicketLink(business.patEnrollment.id, review.orderId)}
        target="_blank"
        rel="noreferrer"
        variant="body1"
        onClick={() => ampli.viewBillClicked()}
        color="textSecondary"
      >
        <FormattedMessage id="reviews.review.card.bill.link" defaultMessage="view the bill" />
      </Link>
    </>
  );
}
