import { datesAreEqual, last12Months, last4Weeks, lastWeekDays, weeksAreEqual } from 'src/sundayplus/reviews/insights/DatesManipulation';

export class KpiTimeframe {

  private readonly timeFrameName: KpiTimeframeName;

  private readonly dateRange: () => Date[];

  private readonly compareDates: (a: Date, b: Date) => boolean;

  constructor(name: KpiTimeframeName, dateRange: () => Date[], compareDates: (a: Date, b: Date) => boolean) {
    this.timeFrameName = name;
    this.dateRange = dateRange;
    this.compareDates = compareDates;
  }

  public map<R>(mapper: (dateComparator: (a: Date, b: Date) => boolean) => (date: Date) => R): R[] {
    return this.dateRange()
      .reverse()
      .map(mapper(this.compareDates));
  }

  public name(): KpiTimeframeName {
    return this.timeFrameName;
  }

  public dates(): Date[] {
    return this.dateRange();
  }
}

export const SEVEN_DAYS: KpiTimeframe = new KpiTimeframe('SEVEN_DAYS', lastWeekDays, datesAreEqual);

export const FOUR_WEEKS: KpiTimeframe = new KpiTimeframe('FOUR_WEEKS', last4Weeks, weeksAreEqual);

export const TWELVE_MONTHS: KpiTimeframe = new KpiTimeframe('TWELVE_MONTHS', last12Months, datesAreEqual);

export const ALL_KPI_TIMEFRAMES: KpiTimeframe[] = [SEVEN_DAYS, FOUR_WEEKS, TWELVE_MONTHS];

export type KpiTimeframeName = 'SEVEN_DAYS' | 'FOUR_WEEKS' | 'TWELVE_MONTHS';

export const getKpiTimeframe = (name: string): KpiTimeframe => {
  switch (name) {
    case 'SEVEN_DAYS':
      return SEVEN_DAYS;
    case 'FOUR_WEEKS':
      return FOUR_WEEKS;
    case 'TWELVE_MONTHS':
      return TWELVE_MONTHS;
    default:
      throw new Error(`unknown time frame : ${name}`);
  }
};