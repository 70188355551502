import React from 'react';
import { DataGrid, GridCellParams, GridColDef, GridRowHeightParams, GridSortCellParams } from '@mui/x-data-grid';
import { FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'src/app/i18n/TypedIntl';
import { MoneyView } from '@sundayapp/web-money';
import dayjs from 'dayjs';
import { Box, Chip, styled, Tooltip, useTheme } from '@mui/material';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import { InfoOutlined } from '@mui/icons-material';
import { Payout } from '../domain/Payout';
import { DatesTooltip } from '../../revenues/pages/components/filter/RevenuesDates';
import { LocalisationKey } from 'src/lang/en';

export type Props = {
  payouts: Payout[];
  timezone: string
};

const HeaderCell = styled('span')`
  color: #70707b;
`;

const dateComparator = (field: string) => (v1: any, v2: any, param1: GridSortCellParams, param2: GridSortCellParams) =>
  dayjs(param1.api.getCellValue(param1.id, field))
    .unix() - dayjs(param2.api.getCellValue(param2.id, field))
    .unix();

const getColor = (status: string) => (status === 'paid' ? 'success' : 'warning');

const knownStatuses = [
  'failed',
  'in transit',
  'paid',
  'pending',
  'scheduled',
] as const;

const statusLocalisationKey = (status: string): LocalisationKey => {
  const statusKey = knownStatuses.find(v => v === status);
  return `accounting.payout_tab.status.${statusKey!}`;
};

export const PayoutsTable = ({
  payouts,
  timezone,
}: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const formattedTimezone = moment()
    .tz(timezone)
    .zoneAbbr();

  const buildColumns = (): GridColDef[] => [
    {
      field: 'included_date',
      disableColumnMenu: true,
      sortComparator: (
        v1: any,
        v2: any,
        param1: GridSortCellParams,
        param2: GridSortCellParams,
      ) => {
        const first = param1.api.getCellValue(param1.id, 'operationDates');
        const second = param2.api.getCellValue(param2.id, 'operationDates');
        const firstDate = first && first.length ? Math.min(...first.map((date: string) => dayjs(date)
          .unix())) : 0;
        const secondDate = second && second.length ? Math.min(...second.map((date: string) => dayjs(date)
          .unix())) : 0;
        return dayjs(firstDate)
          .unix() - dayjs(secondDate)
          .unix();
      },
      minWidth: 250,
      renderHeader: () => (
        <HeaderCell>
          <FormattedMessage id="accounting.payout_tab.operation_included_date" />
          {` (${formattedTimezone})`}
        </HeaderCell>
      ),
      // eslint-disable-next-line no-nested-ternary
      renderCell: ({ row }: GridCellParams) => {
        const operationDatesRange = (operationDates: string[]): { min: string, max: string } => {
          const min = operationDates[0];
          const max = operationDates[operationDates.length - 1];
          return {
            min,
            max,
          };
        };

        if (row.operationDates && row.operationDates.length) {
          const {
            min,
            max,
          } = operationDatesRange(row.operationDates);

          const dateRange = {
            startDate: moment(min),
            endDate: moment(max),
          };

          return (

            <Box sx={{
              display: 'flex',
              gap: '6px',
            }}
            >
              <div>
                <FormattedDate
                  value={dayjs(min)
                    .toDate()}
                  weekday="long"
                  day="2-digit"
                  month="short"
                />
              </div>
              <div>
                <Tooltip
                  title={<DatesTooltip dateRange={dateRange} timezone={timezone} datesWithTimezoneAlready />}
                  placement="right"
                >
                  <InfoOutlined sx={{ color: theme.palette.text.primary }} />
                </Tooltip>
              </div>
            </Box>
          );
        }

        return (
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
          >
            N/A
          </Box>
        );
      },
    },
    {
      field: 'amount',
      disableColumnMenu: true,
      sortComparator: (v1, v2, param1, param2) => param1.api.getCellValue(param1.id as number, 'amount').amount
        - param2.api.getCellValue(param2.id as number, 'amount').amount,
      minWidth: 100,
      renderHeader: () => (
        <HeaderCell>
          <FormattedMessage id="accounting.payout_tab.amount" />
        </HeaderCell>
      ),
      renderCell: ({ row }: GridCellParams) => <MoneyView value={row.amount} />,
    },
    {
      field: 'status',
      disableColumnMenu: true,
      minWidth: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }: GridCellParams) => (
        <div>
          <Chip color={getColor(row.status)}
                label={intl.formatMessage({ id: statusLocalisationKey(row.status), defaultMessage: row.status })}>
          </Chip>
        </div>
      ),
      renderHeader: () => (
        <HeaderCell>
          <FormattedMessage id="accounting.payout_tab.status" />
        </HeaderCell>
      ),
    },
    {
      field: 'created_at',
      disableColumnMenu: true,
      sortComparator: dateComparator('createdAt'),
      minWidth: 160,
      renderHeader: () => (
        <HeaderCell>
          <FormattedMessage id="accounting.payout_tab.created_at" />
          {` (${formattedTimezone})`}
        </HeaderCell>
      ),
      renderCell: ({ row }: GridCellParams) => (
        <FormattedTime
          value={row.createdAt}
          day="2-digit"
          month="2-digit"
          year="numeric"
          hour="2-digit"
          minute="2-digit"
          timeZone={timezone}
        />
      ),
    },
    {
      field: 'arrival_date',
      disableColumnMenu: true,
      sortComparator: dateComparator('arrivalDate'),
      minWidth: 160,
      renderHeader: () => (
        <HeaderCell>
          <FormattedMessage id="accounting.payout_tab.arrival_date" />
          {` (${formattedTimezone})`}
        </HeaderCell>
      ),
      renderCell: ({ row }: GridCellParams) =>
        (row.arrivalDate ? (
          <FormattedDate
            value={dayjs(row.arrivalDate)
              .toDate()}
            day="2-digit"
            month="2-digit"
            year="numeric"
          />
        ) : (
          <div>N/A</div>
        )),
    },
  ];

  const payoutsDensityById = Object.assign({}, ...payouts.map((x) => ({ [x.id]: x.operationDates ? x.operationDates.length : 1 })));

  return (
    <DataGrid
      autoHeight
      columns={buildColumns()}
      disableRowSelectionOnClick
      rows={payouts}
      initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
      pageSizeOptions={[10]}
      getRowHeight={({
        id,
        densityFactor,
      }: GridRowHeightParams) => 40 * densityFactor + 12 * payoutsDensityById[id]}
      rowHeight={52}
    />
  );
};
