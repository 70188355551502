import { useFormContext } from 'react-hook-form';
import React from 'react';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  AddRepresentative,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/AddRepresentative';
import {
  CheckoutOnboardingInformationDraft,
  RepresentativeDraft,
} from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import {
  EditLegalRepresentatives,
} from 'src/payments/components/Checkout/CheckoutOnboarding/KeyStakeholders/IndividualRepresentatives/LegalRepresentatives/EditLegalRepresentatives';

export const LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH = 999;

export const LegalRepresentatives = () => {
  const intl = useIntl();
  const theme = useTheme();
  const { watch, formState: { errors }, setValue, clearErrors } = useFormContext<CheckoutOnboardingInformationDraft>();
  const representatives = watch('representatives');
  const legalRepresentativesWithIndexes = representatives?.map((rep, index) => ({ rep, index })).filter(({
    rep,
  }) => rep.roles.legalRepresentative);
  return <Stack spacing={3}>
    <Stack spacing={1}>
      <Typography variant={'h6'}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.individualRepresentatives.legalRepresentative'} />
      </Typography>
      <Typography variant={'subtitle2'} color={theme.palette.text.secondary}>
        <FormattedMessage id={'settings.payments.checkout.onboarding.representatives.roles.legalRepresentative.hint'} />
      </Typography>
    </Stack>

    {legalRepresentativesWithIndexes?.map(({ rep, index }) => {
      return <EditLegalRepresentatives
        key={index + 'legalRepresentative'}
        representative={rep}
        index={index}
        onDelete={() => {
          setValue('representatives', representatives?.filter((_, i) => i !== index));
          clearErrors(`representatives.${LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
        }}
      />;
    })}
    <AddRepresentative
      onAddRepresentative={() => {
        clearErrors(`representatives.${LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH}`);
        const emptyLegalRepresentative: RepresentativeDraft = {
          type: 'individual',
          roles: {
            ubo: false,
            authorisedSignatory: false,
            legalRepresentative: true,
          },
        };
        setValue('representatives', [...(representatives ?? []), emptyLegalRepresentative]);
      }}
      buttonTitle={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.individualRepresentatives.legalRepresentative.add' })}
    />
    <Typography marginLeft={'14px'} marginTop={'3px'} variant={'caption'} color={theme.palette.error.main}>
      {errors.representatives?.[LEGAL_REPRESENTATIVES_GLOBAL_ERROR_INDEX_PATH]?.message}
    </Typography>
  </Stack>;
};
