import React from 'react';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';

export const BusinessEmail = () => {
  const { formState: { errors }, register } = useFormContext<CheckoutOnboardingInformationDraft>();
  const emailError = errors.contact?.email;
  const intl = useIntl();

  return <TextField
    required
    type={'email'}
    error={!!emailError}
    InputLabelProps={{ shrink: true }}
    {...register('contact.email', {
      required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.email.error' }),
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'Saisie invalide',
      },
    })}
    label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.contact.email' })}
    helperText={emailError?.message || ''}
  />;
};
