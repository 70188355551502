import { WhiteLabelCustomization } from 'src/domain/customization/WhiteLabelCustomization';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { pixels } from 'src/sundayplus/Size';
import { Column } from 'src/sundayplus/Column';
import React from 'react';
import { Customization } from './domain/Customization';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import { MarketingBannersColorCustomizer } from 'src/pages/Settings/Customization/components/ColorCustomizers/MarketingBannersColorCustomizer';
import { Business } from 'src/business/domain/Business';

interface Props {
  whiteLabelCustomization: WhiteLabelCustomization;
  customization: Customization | undefined;
  business: Business;
}

export function ColorCustomization({ customization, whiteLabelCustomization, business }: Props) {
  const theme = useTheme();
  return (
    <Card>
      <CardContent>
        <Column gap={pixels(16)}>
          <Typography variant="h6">
            <FormattedMessage id="settings.customization.color.title" defaultMessage="Color customization" />
          </Typography>
          <Typography variant="body2" color={theme.palette.text.secondary}>
            <FormattedMessage id="settings.customization.color.description" />
          </Typography>
          <MarketingBannersColorCustomizer
            business={business}
            customization={customization}
            whiteLabelCustomization={whiteLabelCustomization}
          />
        </Column>
      </CardContent>
    </Card>
  );
}
