import DataAnalyticsIframeComponent from './DataAnalyticsIframeComponent';
import { language } from 'src/app/i18n/I18nProvider';
import { MetabaseDashboard } from 'src/data-analytics/domain/MetabaseDashboard';

export const MultiLocationDataAnalyticsReviews = () => {
  // useEffect(() => {
  //   ampli.dataAnalyticsReviewsClicked({
  //     businessId: business.id,
  //     businessName: business.name,
  //   });
  // }, []);

  return (
    <DataAnalyticsIframeComponent
      dashboard={language() === 'fr' ? MetabaseDashboard.REVIEWS_FRENCH : MetabaseDashboard.REVIEWS_ENGLISH}
    />
  );
};
