import { css } from 'styled-components';
import { Spacing, spacingInCss } from './Spacing';
import { Size } from './Size';

export type StackProps = {
  tag?: 'div' | 'span';
  gap?: Size;
  padding?: Spacing;
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'end'
  center?: boolean;
  alignItems?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
};

export const baseStackStyle = css<StackProps>`
  display: flex;

  ${({ justifyContent }) => (
    justifyContent ? css`
          justify-content: ${justifyContent};
        `
      : '')};

  ${({ padding }) => (padding
    ? css`
          padding: ${spacingInCss(padding)};
        `
    : '')};

  ${({ center }) => (center
    ? css`
          align-items: center;
        `
    : '')};

  ${({ alignItems }) => (
    alignItems ? css`
          align-items: ${alignItems};
        `
      : '')};
`;
