import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { UseFormReturn } from 'react-hook-form';
import { Panel } from '@sundayapp/b2b-react-component-library';
import { Box, Input, Switch, Typography } from '@mui/material';
import React from 'react';
import { getCurrencySymbol } from '@sundayapp/web-money';
import { Line } from './GenericPaymentMethodSettingsPanel';
import { ReportSettingsInputs } from './ReportSettingsInputs';
import { Settings } from './styles';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const ForceCommentIfDelta = ({ form }: { form: UseFormReturn<ReportSettingsInputs> }) => {
  const { register, watch } = form;
  const { currency } = useCurrentBusinessOrThrow();

  const deltaActive = watch('forceCommentIfDelta.active', true);

  return (
    <Panel flexDirection="column" sx={{ marginBottom: '50px' }}>
      <Line sx={{ width: '100%', display: 'flex', marginBottom: '15px' }}>
        <Typography variant="h5" marginTop="5px" marginBottom="5px">
          <FormattedMessage id="reconciliation.settings.force_comment.title" />
        </Typography>
        <Switch
          {...register('forceCommentIfDelta.active')}
        />
      </Line>
      <Settings>
        <Line>
          <Typography variant="body1" marginTop="5px">
            <FormattedMessage id="reconciliation.settings.force_comment.minimal_amount" />
          </Typography>
          <Box>
            <Input
              type="number"
              inputProps={{
                step: '1.00',
                style: {
                  width: '5em',
                  textAlign: 'end',
                  paddingRight: '5px',
                },
              }}
              {...register('forceCommentIfDelta.allowedAmountBeforeForcing', {
                valueAsNumber: true,
                disabled: !deltaActive,
              })}
            />
            {getCurrencySymbol(currency)}
          </Box>
        </Line>
      </Settings>
    </Panel>
  );
};
