import React from 'react';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import Star from '../assets/star.svg?react';
import { ProgressBar } from './ProgressBar';
import { EmptyReviewsRating } from './EmptyReviewsRating';
import { RatingStatistics } from '../../browse/domain/RatingStatistics';
import { colorForRating } from '../../RatingColor';

type Props = {
  ratingStatistics: RatingStatistics;
};

const ReviewsRatingAsProgressBars = ({ ratingStatistics }: Props) => (
  <Stack gap="1rem" paddingLeft="1.5rem" paddingTop="1.5rem" paddingRight="1.5rem" paddingBottom="2.5rem" width="100%">
    <Typography variant="subtitle1" color="textSecondary">
      <FormattedMessage id="review.guest_experience.stats.progress_bar.title" />
    </Typography>
    <Stack direction="column" gap={1}>
      {ratingStatistics.ratingsPercentageOrderedByStar().map(({ star, percentage, count }) => (
        <Stack direction="row" gap={2} key={`${star}-star`} alignItems="center">
          <div style={{
            gap: '0.25rem', flexDirection: 'row', display: 'flex', alignItems: 'center',
          }}
          >
            <Typography color={colorForRating(star)}>{star}</Typography>
            <Star key={star} fill={colorForRating(star)} />
          </div>
          <ProgressBar star={star} progress={percentage} />
          <div style={{
            gap: '0.25rem', flexDirection: 'row', display: 'flex', alignItems: 'center', width: '7em',
          }}
          >
            <Typography variant="body1" color="textSecondary">{`${count}`}</Typography>
            <Typography variant="body1" color="textSecondary">{`(${percentage}%)`}</Typography>
          </div>
        </Stack>
      ))}
    </Stack>
  </Stack>
);

export const ReviewsRating = ({ ratingStatistics }: Props) => (
  <>
    {ratingStatistics.hasRatings() && <ReviewsRatingAsProgressBars ratingStatistics={ratingStatistics} />}
    {!ratingStatistics.hasRatings() && <EmptyReviewsRating />}
  </>
);
