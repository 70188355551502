// @ts-ignore
import { CountryCode } from '../domain/CountryCode';

export const getStringAsCountryCode = (countryCode: string | undefined): CountryCode => {
  if (!countryCode) {
    return CountryCode.UNSPECIFIED;
  }

  const result = Object.entries(CountryCode).find((name) => (name[1] === countryCode));

  if (!result) {
    return CountryCode.UNSPECIFIED;
  }

  return parseInt(result[0] as string, 10);
};
