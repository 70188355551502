// eslint-disable-next-line @typescript-eslint/no-redeclare
import { EnrollmentId } from 'src/business/domain/Enrollment';
import { HttpPaymentTerminalRepository } from '../../infrastructure/HttpPaymentTerminalRepository';
import { ConfigurationLoader } from 'src/configuration/ConfigurationLoader';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DnaConfiguration } from '../../domain/PaymentTerminalRepository';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { Button, Card, CardContent, CircularProgress, Input, InputLabel, Stack, Typography } from '@mui/material';

type Props = {
  enrollmentId: EnrollmentId
};

export const DnaConfigurationForm = ({ enrollmentId }: Props) => {
  const intl = useIntl();
  const repository = new HttpPaymentTerminalRepository(ConfigurationLoader.load().paymentTerminalBaseUrl);
  const [configuration, setConfiguration] = useState<DnaConfiguration>();
  const [merchantId, setMerchantId] = useState<string>();

  const [loading, setLoading] = useState<boolean>(true);

  async function getDnaConfiguration() {
    const payrocConfiguration = await repository.getDnaConfiguration(enrollmentId);
    setConfiguration(payrocConfiguration);
    setMerchantId(payrocConfiguration.merchantId);

    setLoading(false);
  }

  useEffect(() => {
    getDnaConfiguration().catch(console.error);
  }, [enrollmentId]);

  const saveDnaConfiguration = (dnaConfiguration: DnaConfiguration) => {
    repository.saveDnaConfiguration(enrollmentId, dnaConfiguration);
    setConfiguration(dnaConfiguration);
  };

  const updateDnaConfiguration = () => {
    const updatedConfiguration: DnaConfiguration = { merchantId: merchantId };
    saveDnaConfiguration(updatedConfiguration);
  };

  if (loading || !configuration) return <CircularProgress />;

  return <div>

    <Typography variant="h5" marginBottom="16px">
      {intl.formatMessage({ id: 'payment.terminal.dna.configuration' })}
    </Typography>

    <Card sx={{ padding: '16px' }}>
      <CardContent>
        <Stack direction="column" justifyContent="space-between" spacing="16px " width="100%">
          <InputLabel>{intl.formatMessage({ id: 'payment.terminal.dna.merchantId' })}</InputLabel>
          <Input
            type="text"
            value={merchantId}
            onChange={(event) => {
              setMerchantId(event.target.value);
            }}
          />

          <Button
            sx={{ marginLeft: 'auto', marginBottom: '16px' }}
            variant="contained"
            onClick={updateDnaConfiguration}
          >
            <FormattedMessage id="payment.terminal.dna.configuration.save" />
          </Button>

        </Stack>
      </CardContent>
    </Card>
  </div>;
};
