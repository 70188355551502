import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

export type AsyncState<ReturnedType, ErrorType = Error> = {
  loading: boolean;
  error?: ErrorType;
  result?: ReturnedType;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export type FunctionReturningPromise<ReturnedType, ArgsType extends any[]> = (
  ...args: ArgsType
) => Promise<ReturnedType>;
export function useAsyncFn<
  ReturnedType,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  ArgsType extends any[],
  ErrorType = Error,
>(
  fn: FunctionReturningPromise<ReturnedType, ArgsType>,
  deps: unknown[],
): [AsyncState<ReturnedType, ErrorType>, FunctionReturningPromise<ReturnedType, ArgsType>] {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ErrorType>();
  const [result, setResult] = useState<ReturnedType>();
  const mounted = useRef<boolean>(true);
  const fnRef = useRef<FunctionReturningPromise<ReturnedType, ArgsType>>(fn);
  const state = useMemo<AsyncState<ReturnedType, ErrorType>>(
    () => ({ loading, error, result }),
    [loading, error, result],
  );

  useEffect(() => {
    fnRef.current = fn;
  }, [fn, fnRef]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, [mounted]);

  const callback = useCallback(
    async (...args: ArgsType): Promise<ReturnedType> => {
      if (mounted.current) setLoading(true);
      try {
        const res = await fnRef.current(...args);
        if (mounted.current) setResult(res);
        return res;
      } catch (e: any) {
        if (mounted.current) setError(e);
        throw e;
      } finally {
        if (mounted.current) setLoading(false);
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    deps,
  );
  return [state, callback];
}
