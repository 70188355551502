import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { Money } from '@sundayapp/web-money';
import { findAmountByOrigin, ReconciliationReport } from '../domain/ReconciliationReport';
import { queryKeys } from 'src/app/queries/utils';
import { Method } from '../domain/Reconciliation';
import { PaymentOrigin } from '../domain/PaymentOrigin';
import { CashPayments, PaymentData } from '../domain/PaymentData';
import { ReconciliationRepositoryHttp } from '../infrastructure/ReconciliationRepositoryHttp';
import { useCurrentReportOrThrow } from '../hooks/useCurrentReportOrThrow';
import { BusinessId } from 'src/business/domain/Business';
import { useCurrentBusinessOrThrow } from 'src/business/hooks/useCurrentBusinessOrThrow';

export const invalidateReport = (queryClient: QueryClient, businessId: BusinessId, report: ReconciliationReport) => {
  queryClient.invalidateQueries({ queryKey: [queryKeys.BUSINESSES, businessId, queryKeys.RECONCILIATION_REPORT, report.id] });
};

export const updateReconciliationLineAmount = (businessId: BusinessId, report: ReconciliationReport, method: Method, origin: PaymentOrigin, newAmount: PaymentData) =>
  new ReconciliationRepositoryHttp().updatePaymentData(businessId, report, method, origin, newAmount);

export const useUpdateReconciliationLineAmount = (method: Method) => {
  const queryClient = useQueryClient();
  const business = useCurrentBusinessOrThrow();
  const report = useCurrentReportOrThrow();

  return useMutation({
    mutationFn({ origin, newAmount, metadata }: {
      origin: PaymentOrigin,
      newAmount: Money,
      metadata?: CashPayments,
    }) {
      const paymentData = findAmountByOrigin(report, method, origin);
      const newPaymentData = produce(paymentData, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.amount = newAmount;
        // eslint-disable-next-line no-param-reassign
        draft.metadata = metadata;
      });
      return updateReconciliationLineAmount(business.id, report, method, origin, newPaymentData);
    },

    onSuccess: () => invalidateReport(queryClient, business.id, report),
  });
};
