import { Checkbox, FormControl, FormControlLabel, Stack, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';

export const IsCompanyTradingNameSameAsLegalName = ({ representativeIndex }: { representativeIndex: number }) => {
  const { register, watch, unregister } = useFormContext<CheckoutOnboardingInformationDraft>();
  const isTradingNameTheSameAsLegalName = watch(
    `controllingCompanies.${representativeIndex}.isTradingNameTheSameAsLegalName`,
  );
  return (
    <FormControl component="fieldset">
      <Stack spacing={2}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={!!isTradingNameTheSameAsLegalName}
              {...register(`controllingCompanies.${representativeIndex}.isTradingNameTheSameAsLegalName`, {
                onChange(event) {
                  if (event.target.checked) {
                    unregister(`controllingCompanies.${representativeIndex}.tradingName`);
                  }
                },
              })}
              size={'large'}
            />
          }
          label={
            <Stack>
              <Typography>
                <FormattedMessage
                  id={'settings.payments.checkout.onboarding.controllingCompanies.isTradingNameTheSameAsLegalName'}
                />
              </Typography>
            </Stack>
          }
          labelPlacement="end"
        />
      </Stack>
    </FormControl>
  );
};
