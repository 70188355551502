import { StyledGrid, StyledGridBox, getGridAttributes } from '@styles/Grid/StyledGrid';
import { GridItemProps, GridConfigurableProps } from 'src/home2/types/grid_layout_types';
import React from 'react';

export const GridConfigurable = (configurableGrid: GridConfigurableProps) => {
  if (!configurableGrid.grid) {
    return null;
  }

  return (
    <StyledGridBox>
      {configurableGrid.grid.columnDefs.map((item: GridItemProps) => {
        const size = item.layout.size;

        const attributes = getGridAttributes(size);

        const custom = {
          order: item.order,
          layout: { ...item.layout },
          size,
          rows: item.components.length,
        };

        return (
          <StyledGrid
            key={item.key}
            container
            item
            direction={item.layout.direction}
            {...custom}
            {...attributes}
          >
            {item.components.map((component: React.ReactNode, index) => (
              <StyledGrid
                key={index}
                item
                {...custom}
              >
                {component}
              </StyledGrid>
            ))}
          </StyledGrid>
        );
      })}
    </StyledGridBox>
  );
};
