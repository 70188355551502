import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import styled from 'styled-components';
import Input from '../../../common/components/Input';
import {
  PromoCodeDetailsLineContainer,
  PromoCodeDetailsLineContent,
  PromoCodeDetailsLineTitle,
} from '../PromoCodeDetails.style';
import { PromoCode } from '../../types';
import { colorPalette } from '../../../stylesheet';

interface Props {
  promoCodeCode: string;
  voucherId: string;
  allPromoCodes: PromoCode[];
  setPromoCodeCode: (promoCodeCode: string) => void;
}

const ErrorMessage = styled.div`
  display: flex;
  color: ${colorPalette.red};
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
`;

export const PromoCodeCode: React.FC<Props> = ({
  promoCodeCode, voucherId, allPromoCodes, setPromoCodeCode,
}) => {
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const sameCodeAlreadyExists: boolean = useMemo(() =>
    // Same code but different id => duplicate code in two vouchers
    !!allPromoCodes.find((p) => p.code === promoCodeCode && p.voucherId !== voucherId),
  [allPromoCodes, promoCodeCode, voucherId]);
  const emptyPromoCode: boolean = useMemo(() => hasChanged && promoCodeCode.trim().length === 0, [hasChanged, promoCodeCode]);

  return (
    <PromoCodeDetailsLineContainer>
      <PromoCodeDetailsLineTitle>
        <FormattedMessage id="promoCode.nameLabel" defaultMessage="voucher code name" />
      </PromoCodeDetailsLineTitle>
      <PromoCodeDetailsLineContent>
        <Input
          value={promoCodeCode}
          onValueChange={(s: string) => {
            setPromoCodeCode(s.trim().toUpperCase());
            setHasChanged(true);
          }}
          hasError={sameCodeAlreadyExists || emptyPromoCode}
        />
        {sameCodeAlreadyExists && (
          <ErrorMessage>
            <FormattedMessage
              id="promocode.duplicate.code.error"
              defaultMessage="this voucher code name already exists"
            />
          </ErrorMessage>
        )}
        {emptyPromoCode && (
          <ErrorMessage>
            <FormattedMessage
              id="promocode.empty.code.error"
              defaultMessage="the voucher code name should not be empty"
            />
          </ErrorMessage>
        )}
      </PromoCodeDetailsLineContent>
    </PromoCodeDetailsLineContainer>
  );
};
