/* eslint-disable max-len */
import React, { SVGProps } from 'react';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
}

const BlueGoogle: React.FC<Props> = ({
  size = 20,
  ...props
}: Props) => (

  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="20" height="20" rx="10" fill="#EBEDFB" />
    <path
      d="M16.4 10.1481C16.4 9.6 16.3555 9.2 16.2593 8.78519H10V11.2592H13.674C13.6 11.8741 13.2 12.8 12.3111 13.4222L12.2986 13.505L14.2777 15.0382L14.4148 15.0519C15.674 13.8889 16.4 12.1778 16.4 10.1481Z"
      fill="#4285F4"
    />
    <path
      d="M10 16.6667C11.8 16.6667 13.3111 16.0741 14.4148 15.0519L12.3111 13.4222C11.7481 13.8148 10.9926 14.0889 10 14.0889C8.23705 14.0889 6.74077 12.9259 6.20738 11.3185L6.1292 11.3252L4.07135 12.9178L4.04443 12.9926C5.14072 15.1703 7.39258 16.6667 10 16.6667Z"
      fill="#34A853"
    />
    <path
      d="M6.20742 11.3185C6.06668 10.9037 5.98523 10.4593 5.98523 10C5.98523 9.54074 6.06668 9.09631 6.20002 8.6815L6.19629 8.59316L4.11264 6.97498L4.04447 7.0074C3.59264 7.91112 3.33337 8.92595 3.33337 10C3.33337 11.0741 3.59264 12.0889 4.04447 12.9926L6.20742 11.3185Z"
      fill="#FBBC05"
    />
    <path
      d="M10 5.9111C11.2518 5.9111 12.0963 6.45184 12.5778 6.90372L14.4592 5.06668C13.3037 3.99261 11.8 3.33334 10 3.33334C7.39258 3.33334 5.14072 4.82963 4.04443 7.00739L6.19998 8.68149C6.74077 7.07408 8.23705 5.9111 10 5.9111Z"
      fill="#EB4335"
    />
  </svg>

);

export default BlueGoogle;
