// eslint-disable-next-line @typescript-eslint/no-redeclare
import { colorUsage, Text } from '@sundayapp/b2b-react-component-library';
import React from 'react';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { MenuItemColumn } from './MenuItemColumn';

export type MenuItemLastUpdatedProps = {
  lastUpdated: number;
  active: boolean;
};

const MenuItemLastUpdated = ({ lastUpdated, active }: MenuItemLastUpdatedProps) => {
  const intl = useIntl();

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as Intl.DateTimeFormatOptions;

  return (
    <MenuItemColumn>
      {lastUpdated && (
        <Text size="medium" color={active ? colorUsage.secondaryText : colorUsage.disabledText}>
          {new Date(lastUpdated).toLocaleDateString(intl.locale, options)}
        </Text>
      )}
    </MenuItemColumn>
  );
};
export default MenuItemLastUpdated;
