/* eslint-disable max-len */
import React, { SVGProps } from 'react';
import { themeV5 } from '../../theme/ThemeV5';

export interface Props extends SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const PdqSvg: React.FC<Props> = ({ size = 20, color = themeV5.palette.text.primary, ...props }: Props) => (
  <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M6.82161 3.12507H13.6691C14.3199 3.12507 14.8974 3.53757 15.1174 4.15174L16.8683 9.23007H3.62244L5.37327 4.15174C5.5841 3.53757 6.1616 3.12507 6.82161 3.12507ZM3.37494 13.0526C3.37494 13.8959 4.06244 14.5834 4.90577 14.5834H6.2441V16.1142H4.90577C3.2191 16.1142 1.85327 14.7484 1.85327 13.0617V10.2292C1.85327 9.89007 1.90827 9.5509 2.01827 9.23007L3.9341 3.65674C4.35577 2.4284 5.51994 1.59424 6.82161 1.59424H13.6691C14.9708 1.59424 16.1349 2.41924 16.5566 3.65674L18.4816 9.2209C18.5916 9.54174 18.6466 9.8809 18.6466 10.2201V13.0526C18.6466 14.7392 17.2808 16.1051 15.5941 16.1051H14.2558V14.5742H15.5941C16.4374 14.5742 17.1249 13.8867 17.1249 13.0434V10.7517H3.37494V13.0526Z"
          fill={color} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M4.90577 12.2917C4.90577 11.8701 5.24494 11.5309 5.6666 11.5309H14.8333C15.2549 11.5309 15.5941 11.8701 15.5941 12.2917C15.5941 12.7134 15.2549 13.0526 14.8333 13.0526H5.6666C5.24494 13.0526 4.90577 12.7134 4.90577 12.2917Z"
          fill={color} />
    <path fillRule="evenodd" clipRule="evenodd"
          d="M13.3024 13.0526H7.18827V16.8751H13.3024V13.0526ZM5.6666 11.5217V16.8659C5.6666 17.7092 6.3541 18.3967 7.19744 18.3967H13.3116C14.1549 18.3967 14.8424 17.7092 14.8424 16.8659V11.5217H5.6666Z"
          fill={color} />
  </svg>
);

export default PdqSvg;



