import React, { useEffect, useState } from 'react';
import Spinner from '../ordering/common/components/Spinner';
import styled from 'styled-components';
import { colorPalette } from '@sundayapp/b2b-react-component-library';
import { breakpoints } from '../app/theme/ThemeV5';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import { MetabaseDashboardUrlBuilder } from './infrastructure/MetabaseDashboardUrlBuilder';
import { ConfigurationLoader } from '../configuration/ConfigurationLoader';
import { MetabaseDashboard } from './domain/MetabaseDashboard';
import { ampli } from '../ampli';
import { useUserPayAtTableBusinesses } from './hooks/useUserPayAtTableBusinesses';
import _ from 'lodash';
import { useAuthenticatedUserOrThrow } from '../auth/hooks/useAuthenticatedUserOrThrow';
import { BusinessId } from '../business/domain/Business';
import { useFixedLocalStorage } from 'src/store/localStorage/useFixedLocalStorage';

const IframeContainer = styled.div`
  border: 1px solid ${colorPalette.grey300};
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  display: flex;
  min-height: 1200px;
  @media (max-width: ${breakpoints.tablet}) {
    height: calc(100vh - 160px);
  }
`;
const SelectContainer = styled.div`
  margin-bottom: 8px;
`;

const MenuProps = {
  autoFocus: false, // to prevent the scroll to end of list when all items are selected
  PaperProps: {
    style: {
      maxHeight: '25em',
    },
  },
};


const DataAnalyticsIframeComponent = ({ dashboard }: { dashboard: MetabaseDashboard }) => {
  const intl = useIntl();
  const user = useAuthenticatedUserOrThrow();
  const [ businessIds, setBusinessIds ] = useFixedLocalStorage<BusinessId[]>(`${user.userId}_data-analytics-business-ids`, []);

  const userPatBusinesses = useUserPayAtTableBusinesses();
  const [ analyticsUrl, setAnalyticsUrl ] = useState('');
  const [ forceRefresh, setForceRefresh ] = useState(new Date());
  const metabaseDashboardUrlBuilder = new MetabaseDashboardUrlBuilder(ConfigurationLoader.load());
  const patBusinesses = _.chain(userPatBusinesses)
    .map(({ id, name }) => ({ id, name }))
    .orderBy(b => b.name.toLowerCase())
    .value();

  useEffect(() => {
    if (!userPatBusinesses.length) {
      // not ready yet
      return;
    }

    if (businessIds.length) {
      // check if all businesses are still valid for the user
      const availableBusinessesIds = userPatBusinesses.map(({ id }) => id);
      const validBusinessIds = businessIds.filter(id => availableBusinessesIds.includes(id));
      if (validBusinessIds.length !== businessIds.length) {
        setBusinessIds(validBusinessIds);
      }
    }

    if (!businessIds.length) {
      // first time loading, no local storage value
      // => set all businesses as default
      setBusinessIds(patBusinesses.map(({ id }) => id));
    }
  }, [ userPatBusinesses ]);

  useEffect(() => {
    ampli.analyticsHomeViewed();
    if (businessIds.length) {
      metabaseDashboardUrlBuilder.build(dashboard, businessIds).then((url) => setAnalyticsUrl(url));
    }
  }, [ forceRefresh, businessIds ]); // Dependency to force refresh token every hour (as expiration time is 1h)

  setTimeout(() => setForceRefresh(new Date()), 1000 * 60 * 60); // 1 hour

  const onChangeValues = (e: SelectChangeEvent<string[]>) => {
    const selected = e.target.value;
    let businesses = (typeof selected === 'string' ? selected.split(',') : selected) as BusinessId[];
    setBusinessIds(businesses);
    ampli.dataAnalyticsBusinessChanged({
      businessesIds: businesses,
      pageName: dashboard.toString(),
      date: new Date().toISOString(),
    });
  };

  const getLabel = (id: string) => {
    const business = patBusinesses.find((b) => b.id === id);
    return business ? business.name : '';
  };

  function selectAll() {
    setBusinessIds(patBusinesses.map(({ id }) => id));
  }

  function clearSelection() {
    setBusinessIds(businessIds.length > 0 ? [ businessIds[0] ] : []);
  }

  if (!analyticsUrl) {
    return <Spinner />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <FormControl sx={{ m: 1 }} size="small">
        <Stack direction="row" spacing={1} alignItems="center">
          <SelectContainer>
            <InputLabel id="select-businesses-label" color="secondary">
              <FormattedMessage id={'analytics.venue_filter.label'} />
            </InputLabel>
            <Select
              id="select-businesses"
              labelId="select-businesses-label"
              color="secondary"
              multiple
              input={
                <OutlinedInput
                  id="select-multiple-businesses"
                  label={intl.formatMessage({ id: 'analytics.venue_filter.label' })}
                />
              }
              renderValue={(ids) => (
                _.chain(ids)
                  .map(id => getLabel(id))
                  .join(', ')
                  .value()
              )}
              onChange={(e: SelectChangeEvent<string[]>) => onChangeValues(e)}
              value={businessIds}
              defaultValue={businessIds}
              sx={{ width: '30em' }}
              MenuProps={MenuProps}
              endAdornment={
                <>
                  {businessIds.length > 0 && (
                    <Chip label={<Typography variant="body2">{businessIds.length}</Typography>}
                          sx={{ mr: 1 }} />
                  )}
                </>
              }
            >
              {patBusinesses.map((allowed) => (
                <MenuItem key={`businesses.${allowed.id}`} value={allowed.id}>
                  <Checkbox checked={businessIds.indexOf(allowed.id) > -1} />
                  <ListItemText primary={`${allowed.name}`} />
                </MenuItem>
              ))}
            </Select>
          </SelectContainer>
          <Button onClick={() => selectAll()} variant="contained" size="small"
                  disabled={businessIds.length === patBusinesses.length}>
            <Typography variant="body2">
              <FormattedMessage id={'analytics.venue_filter.select_all'} defaultMessage="select all" />
            </Typography>
          </Button>
          <Button onClick={() => clearSelection()} variant="contained" size="small" disabled={businessIds.length < 2}>
            <Typography variant="body2">
              <FormattedMessage id={'analytics.venue_filter.clear'} defaultMessage="clear" />
            </Typography>
          </Button>
        </Stack>
      </FormControl>

      {!businessIds.length && (
        <Typography variant="subtitle1">
          <FormattedMessage id={'analytics.no_selected_venues'} />
        </Typography>
      )}

      {businessIds.length > 0 && (
        <IframeContainer>
          <iframe
            src={analyticsUrl}
            style={{
              border: 'none',
              width: '100%',
              height: '100%',
              overflow: 'auto',
            }}
          />
        </IframeContainer>
      )}
    </Box>
  );
};

export default DataAnalyticsIframeComponent;
