import React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { FieldError, useFormContext, UseFormRegister } from 'react-hook-form';
import { CheckoutOnboardingInformationDraft } from 'src/payments/domain/CheckoutOnboardingInformationDraft';
import { useIntl } from 'src/app/i18n/TypedIntl';
import { states } from 'src/payments/components/Checkout/CheckoutOnboarding/States';
import MenuItem from '@mui/material/MenuItem';

export const getZipCodeRegex = (country: string | undefined) => {
  switch (country) {
    case 'FR':
      return /^(?:0[1-9]|[1-8]\d|9[0-8])\d{3}$/;
    case 'GB':
      return /^(GIR 0AA|[A-PR-UWYZ]([0-9]{1,2}|([A-HK-Y][0-9]|[A-HK-Y][0-9]([0-9]|[ABEHMNPRV-Y]))|[0-9][A-HJKPS-UW]) [0-9][ABD-HJLNP-UW-Z]{2})$/;
    case 'US':
      return /^\d{5}(-\d{4})?$/;
    default:
      return /.*/;
  }
};

export const Address = ({ addressErrors, register, addressType, addressTitle }: {
  addressType: 'registeredAddress' | 'primaryAddress',
  addressErrors: {
    addressLine1: FieldError | undefined,
    city: FieldError | undefined,
    zip: FieldError | undefined,
    state: FieldError | undefined,
  },
  register: UseFormRegister<CheckoutOnboardingInformationDraft>
  addressTitle: string
}) => {
  const { watch } = useFormContext<CheckoutOnboardingInformationDraft>();
  const intl = useIntl();
  const chosenCountry = watch(`${addressType}.country`);
  const zipCodeRegex = getZipCodeRegex(chosenCountry);
  const isUS = chosenCountry === 'US';
  const state = watch(`${addressType}.state`, '');

  return <Stack direction="column" spacing={3}>
    <Typography variant={'h6'}>{addressTitle}</Typography>
    <TextField
      required
      InputLabelProps={{ shrink: true }}
      error={!!addressErrors.addressLine1}
      {...register(`${addressType}.addressLine1`, {
        required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.addressLine1.error.required' }),
        minLength: {
          value: 5,
          message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.addressLine1.error.invalid' }),
        },
      })}
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.addressLine1' })}
      helperText={addressErrors.addressLine1?.message || ''}
    />
    <TextField
      required
      InputLabelProps={{ shrink: true }}
      error={!!addressErrors.city}
      {...register(`${addressType}.city`, {
        required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.city.error' }),
      })}
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.city' })}
      helperText={addressErrors.city?.message || ''}
    />
    {isUS && (
      <TextField
        select
        required
        InputLabelProps={{ shrink: true }}
        defaultValue={state}
        error={!!addressErrors?.state}
        id="state-select"
        label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.state' })}
        inputProps={register(`${addressType}.state`, {
          required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.state.error' }),
        })}
        helperText={addressErrors?.state?.message || ''}
      >
        {states.map(({ code, name }) => (
          <MenuItem key={code} value={code}>
            {name}
          </MenuItem>
        ))}
      </TextField>
    )}
    <TextField
      required
      InputLabelProps={{ shrink: true }}
      error={!!addressErrors.zip}
      {...register(`${addressType}.zip`, {
        required: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.zip.error.required' }),
        pattern: {
          value: zipCodeRegex,
          message: intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.zip.error.invalid' }),
        },
      })}
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.zip' })}
      helperText={addressErrors.zip?.message || ''}
    />
    <TextField
      InputLabelProps={{ shrink: true }}
      required
      disabled
      {...register(`${addressType}.country`, { value: 'FR' })}
      label={intl.formatMessage({ id: 'settings.payments.checkout.onboarding.address.country' })}
    />
  </Stack>;
};
