import { useIntl } from 'src/app/i18n/TypedIntl';
import React, { useState } from 'react';
import { useUpdateBoxDetails } from '../../../../hooks';
import { SettingPanel } from 'src/ordering/common/components/Settings/SettingPanel';
import { BoxDetails } from '../../../../domain/BoxDetails';
import { usePushNotification } from 'src/ordering/common/components/Notifications.hook';

type BusySettingProps = {
  box: BoxDetails;
  setBox: (box: BoxDetails) => void;
};

export const BusySetting = ({ box, setBox }: BusySettingProps) => {
  const intl = useIntl();
  const [, pushNotification] = usePushNotification();
  const [, updateBoxDetails] = useUpdateBoxDetails(box.id);
  const [isUpdating, makeUpdating] = useState<boolean>(false);

  const updateNumberOfOrderToBeBusy = async (numberOfOrder: string) => {
    const updatedValue = parseInt(numberOfOrder, 10) || 0;

    makeUpdating(true);
    await updateBoxDetails({
      waitingTimeThreshold: updatedValue,
    });
    setBox({
      ...box,
      waitingTimeThreshold: updatedValue,
    });
    pushNotification(intl.formatMessage({ id: 'venue.settings.updated', defaultMessage: 'venue settings updated' }));
    makeUpdating(false);
  };

  return (
    <SettingPanel
      title={intl.formatMessage({
        id: 'box.numberOfOrdersBeforeDisplayingBusierThanUsual',
        defaultMessage: 'number of orders before displaying busier than usual',
        description: 'label for number of orders before displaying busier than usual',
      })}
      description={intl.formatMessage({
        id: 'box.waitingTimeThresholdDisclaimerForNonFoodcourtVenue',
        defaultMessage:
          // eslint-disable-next-line max-len
          'these settings change the way we display how busy the restaurant is. We will display a busier than usual message in the main page after the amount of orders reaches the number defined here.',
        description:
          'text displayed to explain how the waitingTimeThreshold works to display a busier than usual message to the end user for a non foodcourt venue',
      })}
      inputValue={box.waitingTimeThreshold.toString()}
      inputOnChange={updateNumberOfOrderToBeBusy}
      inputType="number"
      disabled={isUpdating}
    />
  );
};
