import styled from 'styled-components';
// eslint-disable-next-line @typescript-eslint/no-redeclare
import { breakpoints, colorUsage, spaceUsages, Stats, Text } from '@sundayapp/b2b-react-component-library';
import { CurrencyCode, Money, MoneyView, sumMoneys } from '@sundayapp/web-money';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import { CustomMoneyView } from './CustomMoneyView';
import { InconsistencyWarning } from './InconsistencyWarning/InconsistencyWarning';
import { safeSumMoney } from 'src/utils/MoneyUtils';
import { getSpacing } from 'src/ordering/stylesheet';
import { Card, CardContent } from '@mui/material';

const RevenueAndTipsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: ${spaceUsages.mediumLarge};

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

const RevenuesAndTipsSummaryWrapper = styled.div`

  @media (max-width: ${breakpoints.medium}) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;`;

const AmountWidgetContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsRevenues = styled.div`
  margin: ${getSpacing(1)};
`;

export interface RevenueDetails {
  label: string,
  revenue: Money,
}

export interface RevenueAndTipsProps {
  name: string,
  revenue: Money,
  revenueDescription?: string,
  tips: Money
  tipsDescription?: string,
  inconsistency?: Money,
  revenueDetailsBreakdown?: RevenueDetails[],
}

export interface RevenuesAndTipsSummaryProps {
  revenuesAndTips: RevenueAndTipsProps[],
  title: string,
  currency: CurrencyCode
}

const toDataTestId = (unformattedId: string) => unformattedId.toLowerCase().replace(' ', '-');

const getAmountWidget = (revenue: Money, revenueDetailsBreakdown: RevenueDetails[]) => {
  const nonEmptyDetailsRevenues = revenueDetailsBreakdown.filter((r) => r.revenue.amount > 0);
  return (
    <AmountWidgetContainer>
      <CustomMoneyView value={revenue} />
      <Text size="medium">
        {nonEmptyDetailsRevenues && nonEmptyDetailsRevenues.length > 0 &&
          <DetailsRevenues><FormattedMessage id="finance.detailsRevenues" defaultMessage="dont: " /></DetailsRevenues>}
        {nonEmptyDetailsRevenues.map((r) => (
          <DetailsRevenues key={r.label}>
            {r.label}
            :
            <CustomMoneyView value={r.revenue} />
          </DetailsRevenues>
        ))}
      </Text>
    </AmountWidgetContainer>
  );
};

export const RevenueAndTips = ({
  name,
  revenue,
  tips,
  revenueDescription,
  tipsDescription,
  inconsistency,
  revenueDetailsBreakdown,
}: RevenueAndTipsProps) => {
  const { formatMessage } = useIntl();
  return (
    <RevenueAndTipsWrapper>
      <Stats
        dataLabel={name}
        dataProvider={revenueDescription}
        amount={revenueDetailsBreakdown ? getAmountWidget(revenue, revenueDetailsBreakdown) :
          <CustomMoneyView value={revenue} />}
        padding={spaceUsages.mediumLarge}
        data-testid={`${toDataTestId(name)}-revenue`}
      >
        <InconsistencyWarning inconsistency={inconsistency} />
      </Stats>
      <Stats
        dataLabel={`${name} ${formatMessage({ id: 'accounting.tips' })}`}
        dataProvider={tipsDescription}
        amount={<CustomMoneyView value={tips} />}
        padding={spaceUsages.mediumLarge}
        data-testid={`${toDataTestId(name)}-tips`}
      />
    </RevenueAndTipsWrapper>
  );
};

export const RevenuesAndTipsSummary = ({
  currency, revenuesAndTips, title,
}: RevenuesAndTipsSummaryProps) => {
  const total = safeSumMoney(revenuesAndTips
    .map((financeProp) => sumMoneys(financeProp.revenue, financeProp.tips)), currency);

  return (
    <RevenuesAndTipsSummaryWrapper>
      <Card>
        <CardContent>
          <Text
            size="medium"
            color={colorUsage.secondaryText}
            mr={spaceUsages.small}
            marginBottom={spaceUsages.mediumLarge}
            data-testid={`${toDataTestId(title)}-stats`}
          >
            {title}
          </Text>

          <Text size="xxlarge" marginBottom={spaceUsages.mediumLarge}>
            <MoneyView value={total} data-testid={`${toDataTestId(title)}-total-revenue`} />
          </Text>
          <ContentWrapper>
            {
              revenuesAndTips.map((revenueAndTips) => (
                <RevenueAndTips
                  name={revenueAndTips.name}
                  revenue={revenueAndTips.revenue}
                  tips={revenueAndTips.tips}
                  revenueDescription={revenueAndTips.revenueDescription}
                  tipsDescription={revenueAndTips.tipsDescription}
                  inconsistency={revenueAndTips.inconsistency}
                  key={revenueAndTips.name}
                />
              ))
            }
          </ContentWrapper>
        </CardContent>
      </Card>
    </RevenuesAndTipsSummaryWrapper>
  );
};
