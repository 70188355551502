import { Button, Modal } from '@sundayapp/b2b-react-component-library';
import { FormattedMessage, useIntl } from 'src/app/i18n/TypedIntl';
import React from 'react';
import styled from 'styled-components';
import { colorPalette, getSpacing } from '../../../../stylesheet';

type AreaWithoutServiceDisclaimerModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

const MessageContainer = styled.div`
  color: ${colorPalette.placeholderColor};
  margin: 0 0 ${getSpacing(3)};
`;

export const AreaWithoutServiceDisclaimerModal = ({
  isOpen,
  onCancel,
  onConfirm,
}: AreaWithoutServiceDisclaimerModalProps) => {
  const intl = useIntl();

  return (
    <Modal
      maxWidth="407px"
      isOpened={isOpen}
      onClose={onCancel}
      title={intl.formatMessage({
        id: 'settings.area.create.service-selection.no-service-modal.title',
        defaultMessage: 'add services to your area is required',
        description: 'modal title to confirm the user want to create an area without service',
      })}
      cancelButtonMessage={intl.formatMessage({ id: 'menus.modal.edition.cancel' })}
    >
      <MessageContainer>
        <FormattedMessage
          id="settings.area.create.service-selection.no-service-modal.description"
          defaultMessage="In order to have your venue ready you will need a type of service activated to be able to operate"
          description="text to reminder the services are mandatory to make an area works"
        />
      </MessageContainer>
      <Button variant="primary" size="medium" fullWidth onClick={onConfirm}>
        <FormattedMessage
          id="settings.area.create.service-selection.no-service-modal.confirm"
          defaultMessage="got it, i’ll add my service later"
        />
      </Button>
    </Modal>
  );
};
