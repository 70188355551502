import { useContext } from 'react';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export const getTicketLink = (b2cAppBaseUrl: string, enrollmentId: EnrollmentId, orderId: string) =>
  `${b2cAppBaseUrl}/venues/${enrollmentId}/bills/${orderId}`;

export const useGetTicketLink = () => {
  const configuration = useContext(ConfigurationContext);
  return (enrollmentId: EnrollmentId, orderId: string) => getTicketLink(configuration.b2cAppBaseUrl, enrollmentId, orderId);
};
