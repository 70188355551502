import React, { useContext } from 'react';
import { FormattedMessage } from 'src/app/i18n/TypedIntl';
import { Typography } from '@mui/material';
import { QRCodeSVG } from 'qrcode.react';
import { MenuToolBox } from './MenuToolBox';
import { ConfigurationContext } from 'src/app/contexts/configuration/ConfigurationProvider';
import { EnrollmentId } from 'src/business/domain/Enrollment';

export type PreviewMenuProps = {
  enrollmentId: EnrollmentId;
};

export const PreviewMenu = ({ enrollmentId }: PreviewMenuProps) => {
  const configuration = useContext(ConfigurationContext);
  const menuLink = `${configuration.b2cAppBaseUrl}/venues/${enrollmentId}/menu-previews`;

  return (
    <MenuToolBox onClick={() => window.open(menuLink, '_blank')} sx={{ padding: '8px' }}>
      <Typography fontSize="medium" sx={{ opacity: 0.6 }}>
        <FormattedMessage id="menus.preview_menu" defaultMessage="Preview your menu" />
      </Typography>
      <QRCodeSVG size={100} fgColor="#000000" level="M" value={menuLink} />
    </MenuToolBox>
  );
};
